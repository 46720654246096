import React, { useEffect, useState, useRef, useCallback } from "react";
import BeeFreeSDK from "@mailupinc/bee-plugin";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import SavedRowOverlay from "./SavedRowOverlay";
import { toastError } from "../../../shared/toastHelper";

const editorStyle = {
  height: "80vh",
};

const useContentDialog = () => {
  const defaultModalConfig = {
    isOpen: false,
    close: null,
    save: null,
    key: null
  };

  const [config, setConfig] = useState(defaultModalConfig)

  const asyncModal = useCallback(async (args) => {
    try {
      return await new Promise((resolve, reject) => {
        const handleReject = () => {
          setConfig(defaultModalConfig);
          resolve({});
        };
        const handleResolve = (results) => {
          setConfig(defaultModalConfig);
          resolve({ ...results });
        };
        setConfig({
          isOpen: true,
          reject: handleReject,
          resolve: handleResolve,
          args,
        });
      });
    } catch (error) {
      return console.error(error);
    }
  }, [])

  return {
    config,
    asyncModal,
  }
};

const TemplateEditor = ({ mergeTags, templatableContext }) => {
  const {
    csrfToken,
    team,
    beefreeToken,
    beefreeUid,
    template,
    setTemplate,
    processedTemplate,
  } = templatableContext();

  const { config, asyncModal } = useContentDialog();

  const ref = useRef();
  const [rows, setRows] = useState([]);

  const getRows = async () => ref.current?.rows;

  const handleOnSaveRow = async (rowTemplate) => {
    const row = JSON.parse(rowTemplate);
    await saveRow(row);
  };

  const handleDeleteRow = async (rowToDelete) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    await axios.delete(`/teams/${team.id}/saved_rows/${rowToDelete.metadata.guid}`);
    setRows((prevRows) => prevRows.filter((row) => row?.metadata?.guid !== rowToDelete?.metadata?.guid));
  };

  const handleEditRow = async (row, name) => {
    row.metadata.name = name;
    await saveRow(row);
  };

  const handleGetRows = async (resolve, reject, args) => {
    const rows = await getRows(args.handle);
    resolve(rows);
  };

  const updateRows = (rowToSave) => {
    setRows((prevRows) => {
      const index = prevRows.findIndex((row) => row.metadata.guid === rowToSave.metadata.guid);
      if (index !== -1) {
        prevRows[index] = rowToSave;
        return [...prevRows];
      } else {
        return [...prevRows, rowToSave];
      }
    });
  };

  const saveRow = async (rowToSave) => {
    try {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      const { data } = await axios.post(`/teams/${team.id}/saved_rows`, {
        saved_row: {
          template: rowToSave,
        },
      });
      updateRows(data.template);
    } catch (error) {
      toastError("Failed to save row");
    }
  };

  useEffect(() => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios
      .get(`/teams/${team.id}/saved_rows`)
      .then(({ data }) => {
        setRows(data.map(({ template }) => template));
      })
      .catch((error) => {
        toastError("Failed to load saved rows");
      });
  }, [csrfToken, team.id]);

  useEffect(() => {
    ref.current = { rows };
  }, [rows]);

  const handleSaveRow = async (resolve, reject, row) => {
    const { name = "", synced = false } = await asyncModal(row);
    if (name) {
      resolve({ name, guid: row?.metadata?.guid ?? uuidv4() }, { synced });
    } else {
      reject("");
    }
  };

  const handleOnDeleteRow = async (resolve, reject, { row }) => {
    if (!row) return;
    await handleDeleteRow(row);
    resolve(true);
  };

  const handleOnEditRow = async (resolve, reject, { row }) => {
    try {
      if (!row) throw new Error("No row provided");
      const { name = "" } = await asyncModal(row);
      if (name) {
        await handleEditRow(row, name);
        resolve(true);
      } else {
        reject("Name is required");
      }
    } catch (error) {
      reject(error);
    }
  };

  const handleChange = (template) => {
    setTemplate(template);
  };

  const clientConfig = {
    uid: beefreeUid,
    container: "beefree-sdk-container",
    onChange: handleChange,
    trackChanges: true,
    saveRows: true,
    onSaveRow: handleOnSaveRow,
    rowsConfiguration: {
      emptyRows: true,
      defaultRows: false,
      externalContentURLs: [
        {
          name: "Saved Rows",
          value: "saved-rows",
          handle: "saved-rows",
          isLocal: true,
          behaviors: { canEdit: true, canDelete: true },
        },
      ],
    },
    hooks: { getRows: { handler: handleGetRows } },
    loadingSpinnerDisableOnSave: false,
    loadingSpinnerDisableOnDialog: true,
    contentDialog: {
      saveRow: { handler: handleSaveRow },
      onDeleteRow: { handler: handleOnDeleteRow },
      onEditRow: { handler: handleOnEditRow },
    },
    contentDefaults: {
      row: {
        styles: {
          columnsPadding: "5px",
        },
      },
    },
    mergeTags,
  };

  useEffect(() => {
    const sdk = new BeeFreeSDK(beefreeToken);
    sdk.start(clientConfig, template);
  }, []);

  useEffect(() => {
    if (processedTemplate !== template) {
      setTemplate(processedTemplate);

      const sdk = new BeeFreeSDK(beefreeToken);
      sdk.start(clientConfig, processedTemplate);
    }
  }, [processedTemplate]);

  return (
    <>
      <div id="beefree-sdk-container" style={editorStyle} />
      <SavedRowOverlay config={config} />
    </>
  );
};

export default TemplateEditor;
