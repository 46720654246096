import { createStore, applyMiddleware } from 'redux';
import ArtistPipelinesReducer from '../reducers/ArtistPipelinesReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(ArtistPipelinesReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
