import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import { SuperClusterAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";

const MarkerCluster = (props) => {
  const {map, google, geoCoordinate} = props

  useEffect(() => {
    if (map && geoCoordinate) {
      const markers = geoCoordinate.map((marker) => {
        const entry = new google.maps.Marker({
          position: {
            lat: marker.lat,
            lng: marker.lng
          },
          map: map
        })
        
        return entry
      })
      
      const algorithm = new SuperClusterAlgorithm({maxZoom: 8});
      new MarkerClusterer({markers, map, algorithm});
      
      return () => {
        clusterer.clearMarkers()
      }
    }
  }, [map, google, geoCoordinate])

  return (null)
}

MarkerCluster.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  geoCoordinate: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  })),
}

export default MarkerCluster
