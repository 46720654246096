import React, { useEffect, useState } from "react";
import axios from "axios";

import FanFilterGroupForm from "./FanFilters/FanFilterGroupForm";
import { toastError } from "../../../shared/toastHelper";

const nameInputStyle = {
  width: 250,
  height: 40,
  borderRadius: 8,
  padding: "0 10px",
  border: "1px solid #ccc",
  color: "#333333",
  marginBottom: 8,
};

const DataDrivenSegmentForm = ({
  csrfToken,
  team,
  segment,
  setSegment,
  showSegmentNameInput,
  fanFilterGroupId,
  setFanFilterGroupId,
  onValidChangeCallback=() => {},
}) => {
  const [fanFilterGroup, setFanFilterGroup] = useState({});

  useEffect(() => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    if (fanFilterGroupId) {
      axios.get(`/teams/${team.id}/fan_filter_groups/${fanFilterGroupId}`)
        .then((data) => {
          setFanFilterGroup(data["data"]);
          setFanFilterGroupId(data["data"]["id"]);
        })
    } else {
      axios.post(`/teams/${team.id}/fan_filter_groups`, { fan_filter_group: { match_condition: "All" } })
        .then((data) => {
          setFanFilterGroup(data["data"]);
          setFanFilterGroupId(data["data"]["id"]);
        })
        .catch((error) => {
          const errors = error.response.data;
          toastError(errors.join(", "));
        });
    }
  }, []);

  return (
    <div className="rounded border p-4" style={{ backgroundColor: "#F9F9F9" }}>
      {showSegmentNameInput ? (
        <>
          <div style={{
            color: "#333333",
          }}>Name your segment</div>
          <input
            type="text"
            style={nameInputStyle}
            value={segment.name || ""}
            onChange={(e) => {
              setSegment({
                ...segment,
                name: e.target.value
              });
            }}
          />
        </>
      ) : null}
      {!!fanFilterGroup.id ? (
        <FanFilterGroupForm
          csrfToken={csrfToken}
          team={team}
          fanFilterGroup={fanFilterGroup}
          onValidChangeCallback={onValidChangeCallback}
        />
      ) : null}
    </div>
  );
};

export default DataDrivenSegmentForm;
