import React from "react";
import axios from "axios";

import { useBroadcastEditContext } from "./BroadcastEditContainer";
import BroadcastSidebar from "./BroadcastSidebar";
import SegmentFilters from "./SegmentFilters";
import DataDrivenSegmentForm from "../Segments/DataDrivenSegmentForm";

import { toastError } from "../../../shared/toastHelper";

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const RecipientsTab = () => {
  const {
    csrfToken,
    team,
    setTab,
    broadcast,
    updateBroadcast,
    scheduledSend,
    segmentFilters,
    visibleSegmentFilters,
    fetchSegmentFilteredFansCount,
    recipientsCount,
    segment,
    setSegment,
    fanFilterGroupId,
    setFanFilterGroupId,
  } = useBroadcastEditContext();

  const saveDisabled = () => {
    if (broadcast.recipient_type === null) {
      return true;
    } else if (broadcast.segment_type === "existing") {
      return !segmentFilters[0]?.set;
    } else if (broadcast.segment_type === "new") {
      return !fanFilterGroupId || !segment.name;
    }
  };

  const handleNext = (e) => {
    e.preventDefault();

    if (broadcast.segment_type === "new" && segment.id) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.put(`/teams/${team.id}/segments/${segment.id}`, {
        segment: {
          name: segment.name,
        }
      })
        .then(() => {
          if(!!scheduledSend) {
            window.location.search = "tab=details";
          } else {
            setTab("from");
          }
        })
        .catch((error) => {
          const errors = error.response.data;
          toastError(errors.join(", "));
        });
    } else if(!!scheduledSend) {
      window.location.search = "tab=details";
    } else {
      setTab("from");
    }
  };

  return (
    <BroadcastSidebar>
      <div
        className="mt-3 ml-5 d-flex flex-column justify-content-between"
        style={{ minHeight: "300px" }}
      >
        <div>
          <h3 style={{ fontSize: "20px" }}>
            <strong>
              Recipients
            </strong>
          </h3>
          <p>Who are you sending this email to?</p>
          <div className="d-flex">
            <div>
              <div className="d-flex flex-wrap">
                <button
                  type="button"
                  className="d-flex align-items-center mr-4 mb-4"
                  style={{
                    width: "300px",
                    height: "60px",
                    backgroundColor: broadcast.recipient_type === "conditional" ? "#eef7fd" : "#fff",
                    border: broadcast.recipient_type === "conditional" ? "2px solid #1982C4" : "2px solid #ccc",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    updateBroadcast({
                      recipient_type: "conditional",
                    }, false);
                  }}
                >
                  <p className="m-3" style={{ fontSize: "16px" }}>
                    <strong>
                      Fans matching conditions
                    </strong>
                  </p>
                </button>
                <button
                  type="button"
                  className="d-flex align-items-center mr-4 mb-4"
                  style={{
                    width: "300px",
                    height: "60px",
                    backgroundColor: broadcast.recipient_type === "all_fans" ? "#eef7fd" : "#fff",
                    border: broadcast.recipient_type === "all_fans" ? "2px solid #1982C4" : "2px solid #ccc",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    updateBroadcast({
                      recipient_type: "all_fans",
                    }, false);
                  }}
                >
                  <p className="m-3" style={{ fontSize: "16px" }}>
                    <strong>
                      Every fan in my workspace
                    </strong>
                  </p>
                </button>
              </div>
              <div>
                {broadcast.recipient_type === "conditional" ? (
                  <>
                    <div>
                      <p>Target the fans that who should receive this broadcast.</p>
                    </div>
                    <div className="d-flex flex-wrap">
                      <button
                        type="button"
                        className="d-flex align-items-center mr-3 mb-4"
                        style={{
                          width: "260px",
                          height: "50px",
                          backgroundColor: broadcast.segment_type === "existing" ? "#eef7fd" : "#fff",
                          border: broadcast.segment_type === "existing" ? "2px solid #1982C4" : "2px solid #ccc",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          updateBroadcast({
                            segment_type: "existing"
                          }, false);
                        }}
                      >
                        <p className="w-100 m-3 text-center" style={{ fontSize: "14px" }}>
                          <strong>
                            Choose an existing segment
                          </strong>
                        </p>
                      </button>
                      <button
                        type="button"
                        className="d-flex align-items-center mb-4"
                        style={{
                          width: "260px",
                          height: "50px",
                          backgroundColor: broadcast.segment_type === "new" ? "#eef7fd" : "#fff",
                          border: broadcast.segment_type === "new" ? "2px solid #1982C4" : "2px solid #ccc",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          if (!segment.id) {
                            updateBroadcast({
                              segment_type: "new",
                              broadcast_segment_attributes: {
                                broadcast_id: broadcast.id,
                                segment_attributes: {
                                  name: `${broadcast.name} - Custom Segment`,
                                  type: "Segments::DataDriven",
                                  team_id: team.id,
                                  fan_filter_group_attributes: {
                                    match_condition: "All",
                                    team_id: team.id,
                                  }
                                }
                              }
                            }, false);
                          } else {
                            updateBroadcast({
                              segment_type: "new",
                            }, false);
                          }
                        }}
                      >
                        <p className="w-100 m-3 text-center" style={{ fontSize: "14px" }}>
                          <strong>
                            Create a new segment
                          </strong>
                        </p>
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {recipientsCount !== "--" ? (
              <div
                className="p-3 rounded align-self-start"
                style={{
                  width: "300px",
                  backgroundColor: "#eef7fd",
                }}
              >
                <p className="mb-2">
                  <strong>
                    Recipients
                  </strong>
                </p>
                <p className="mb-2" style={{ fontSize: "24px" }}>
                  <strong>
                    {recipientsCount}
                  </strong>
                </p>
                {broadcast.recipient_type === "all_fans" ? (
                  <p className="mb-2">Total fans in this workspace</p>
                ) : (
                  <p className="mb-2">Total fans matching conditions</p>
                )}
                {broadcast.recipient_type === "all_fans" ? (
                  <div className="w-100 mt-3 p-3 rounded" style={{ backgroundColor: "#fff" }}>
                    <p className="mb-1">
                      <i className="mr-2 fas fa-bolt text-primary" />
                      <strong>
                        Best Practices
                      </strong>
                    </p>
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      Sending emails to everyone in your database can damage your sending reputation and deliverability.
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {broadcast.recipient_type === "conditional" && broadcast.segment_type === "existing" ? (
            <SegmentFilters
              editable={true}
              showAddButton={visibleSegmentFilters.length === 0}
            />
          ) : broadcast.recipient_type === "conditional" && broadcast.segment_type === "new" && fanFilterGroupId ? (
            <DataDrivenSegmentForm
              csrfToken={csrfToken}
              team={team}
              segment={segment}
              setSegment={setSegment}
              fanFilterGroupId={fanFilterGroupId}
              setFanFilterGroupId={setFanFilterGroupId}
              showSegmentNameInput={true}
              onValidChangeCallback={fetchSegmentFilteredFansCount}
            />
          ) : null}
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "40px" }}
        >
          <button
            disabled={saveDisabled()}
            type="button"
            className="ml-2 btn btn-primary"
            onClick={handleNext}
          >
            {!!scheduledSend ? "Save" : "Next"}
          </button>
        </div>
      </div>
    </BroadcastSidebar>
  );
};

export default RecipientsTab;
