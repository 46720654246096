import React from "react";

import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";
import("./Fonts.css");

const labelStyle = {
  fontSize: 14,
  color: "#333333",
  fontWeight: 700,
  marginBottom: 8,
};

const selectStyle = {
  width: 250,
  height: 40,
  padding: 8,
  fontSize: 14,
  color: "#333333",
  border: "1px solid #B3B3B3",
  borderRadius: 4,
};

const rowStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 20,
};

const FontSelect = () => {
  const {
    formState,
    setFormState,
  } = useMarketingSettingsContext();

  const handleFontChange = (e) => {
    setFormState((prev) => ({
      ...prev,
      font_family: e.target.value,
    }));
  };

  const availableFonts = [
    "Arial",
    "Helvetica",
    "Roboto",
    "Open Sans",
    "Poppins",
    "Lato",
    "Montserrat",
    "Raleway",
    "Ubuntu",
  ];

  return (
    <>
      <div style={labelStyle}>Font</div>
      <div style={rowStyle}>
        <select value={formState.font_family || ""} onChange={handleFontChange} style={selectStyle}>
          {availableFonts.map(font => (
            <option value={font} key={font}>
              {font}  
            </option>
          ))}
        </select>
        <div className={`${formState.font_family?.toLowerCase()}-font`}>
          <h3>Sample Header</h3>
          <p>Sample text</p>
        </div>
      </div>
    </>
  );
};

export default FontSelect;
