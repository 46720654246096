import React, { useEffect, useState } from "react";

import { useActionContext } from "./ActionContainer";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  fontSize: 14,
  gap: 15,
};

const headerStyle = {
  borderBottom: "1px solid #EEEEEE",
  color: "#33333388",
  fontSize: 10,
  paddingBottom: 5,
  width: "100%",
};

const rowStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  textWrap: "nowrap",
  width: "100%",
};

const pathStyle = {
  color: "#808080",
};

const valueStyle = {
  color: "#1982C4",
};

const SampleDataContainer = () => {
  const { sampleData } = useActionContext();

  const [flattenedSampleData, setFlattenedSampleData] = useState([]);

  const flattenData = (obj, parent = "") => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const path = `${parent}${parent ? "." : ""}${key}`;
      if (value && typeof value === "object" && !Array.isArray(value) && !(value instanceof Date)) {
        acc.push(...flattenData(value, path));
      } else {
        acc.push({ path, value });
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    setFlattenedSampleData(flattenData(sampleData));
  }, [sampleData])

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        ATTRIBUTES
      </div>
      {flattenedSampleData.map(({ path, value }) => (
        !!value ? (
          <div key={path} style={rowStyle}>
            <div style={pathStyle}>{path}</div>
            <div style={valueStyle}>{`"${value}"`}</div>
          </div>
        ) : null
      ))}
    </div>
  );
};

export default SampleDataContainer;
