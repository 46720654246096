import React, { createContext, useContext, useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';

import CampaignShow from "./CampaignShow";

const CampaignContext = createContext();

export function useCampaignContext() {
  return useContext(CampaignContext);
}

const CampaignProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
    venueOwnership,
    campaign,
    ahoyMessagesDataTableProps,
    sendDomain,
    previouslySent,
  } = props;

  const [ahoyMessageId, setAhoyMessageId] = useState(null);

  const value = {
    csrfToken,
    user,
    team,
    venueOwnership,
    campaign,
    ahoyMessagesDataTableProps,
    ahoyMessageId,
    setAhoyMessageId,
    sendDomain,
    previouslySent,
  };

  return (
    <CampaignContext.Provider value={value}>
      {props.children}
    </CampaignContext.Provider>
  );
};

const CampaignContainer = (props) => (
  <CampaignProvider {...props}>
    <ToastContainer />
    <CampaignShow />
  </CampaignProvider>
);

export default CampaignContainer;
