// Simple example of a React "smart" component

import { connect } from 'react-redux';
import EventDetails from '../components/EventDetails';
import * as actions from '../actions/eventDetailsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  confirm: state.confirm,
  buyers: state.buyers,
  promoters: state.promoters,
  eventDetails: state.eventDetails,
  currentUser: state.currentUser,
  financeItemsChanged: state.financeItemsChanged,
  holdGroupDates: state.holdGroupDates
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    confirmSaved: confirm => ownProps.confirmSaved(confirm),
    updateConfirm: (csrfToken, team, confirm, eventDetails, successCallback) => dispatch(actions.updateConfirm(dispatch, csrfToken, team, confirm, eventDetails, successCallback)),
    buyersChanged: (buyers) => dispatch(actions.buyersChanged(buyers)),
    promotersChanged: (promoters) => dispatch(actions.promotersChanged(promoters)),
    fetchBuyers: (team, inputValue, callback) => dispatch(actions.fetchBuyers(dispatch, team, inputValue, callback)),
    createBuyer: (team, buyer, successCallback, errorCallback) => dispatch(actions.createBuyer(dispatch, team, buyer, successCallback, errorCallback)),
    fetchPromoters: (team, inputValue, callback) => dispatch(actions.fetchPromoters(dispatch, team, inputValue, callback)),
    createPromoter: (team, promoter, successCallback, errorCallback) => dispatch(actions.createPromoter(dispatch, team, promoter, successCallback, errorCallback)),
    eventDetailsChanged: (eventDetails) => dispatch(actions.eventDetailsChanged(eventDetails)),
    fetchEventDetails: (team, confirm) => dispatch(actions.fetchEventDetails(dispatch, team, confirm)),
    holdGroupHoldPositionUpdated: (date, position) => dispatch(actions.holdGroupHoldPositionUpdated(date, position)),
    holdGroupHoldRemoved: (date) => dispatch(actions.holdGroupHoldRemoved(date)),
    holdGroupCalendarDateClicked: (date, selected) => dispatch(actions.holdGroupCalendarDateClicked(date, selected)),
    fetchHoldAvailability: (team, venueId, date) => dispatch(actions.fetchHoldAvailability(dispatch, team, venueId, date)),
    deletePromoter: (team, promoterId, successCallback, errorCallback) => dispatch(actions.deletePromoter(team, promoterId, successCallback, errorCallback))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
