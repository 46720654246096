import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import Iframe from 'react-iframe'

const popupStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "24px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "240px",
  "padding": "0px"
}

const canSubmitForm = (showAdvanceSend) => {
  return (
    showAdvanceSend.to
      && showAdvanceSend.to.length > 0
      && showAdvanceSend.subject
      && showAdvanceSend.subject.length > 0
  );
}

const iFrameUrl = (team, confirm, showAdvanceSend) => {
  var baseUrl = ("/teams/" + team.id + "/confirms/" + confirm.id + "/print_show_details");

  if(showAdvanceSend.note_ids){
    var noteIdParams = showAdvanceSend.note_ids.map((noteId) =>
      "note_ids[]=" + noteId
    ).join("&");

    return baseUrl + "?" + noteIdParams;
  } else {
    return baseUrl;
  }
}

const ShowAdvanceSendPopup = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  showAdvanceSend,
  showAdvanceSendChanged,
  showAdvanceSendToChanged,
  showAdvanceSendSubjectChanged,
  showAdvanceSendBodyChanged,
  createShowAdvanceSend,
  notes,
  showAdvanceSendNoteClicked
}) => (
  <div>
    <Popup
      open={(Object.keys(showAdvanceSend).length > 0)}
      contentStyle={popupStyle}
      closeOnDocumentClick={false}
      className={"popup-modal"}
      onOpen={() => {
        showAdvanceSendSubjectChanged(confirm.human_start_date  + " - " + confirm.name + " at " + confirm.venue.name + " Show Advance");
      }}
      onClose={() => showAdvanceSendChanged({})}
    >
      <div className="popup-modal-container">
        <div className="row">
          <div className="col-12">
            <h3 style={{
                  "fontSize": "20px",
                  "marginBottom": "25px"
                }}>
              <strong>Send Show Advance</strong>
            </h3>
            <p className="text-muted">
              Your Show Advance email contains event details, artist lineup, and show details. You can also add notes from this event as well.
            </p>
            {notes.length > 0 ? (

              <Popup arrow={false}
                     offsetY={-10}
                     offsetX={1}
                     position="bottom left"
                     contentStyle={popupContentStyle}
                     onOpen={
                       (e) => {
                         e.stopPropagation();
                         e.preventDefault();
                       }
                     }
                     trigger={open => (
                       <div className="row m-0">
                         <div className="col-6 col-md-4 px-2 py-1 text-muted d-flex justify-content-between clickable"
                              style={{
                                "border": "2px solid #f5f5f5",
                                "borderRadius": "8px",
                                "marginBottom": "12px"
                              }}>
                           <div>Include notes</div>
                           <i className="fas fa-caret-down align-self-center"></i>
                         </div>
                       </div>
                     )} >
                {close => (
                  <div className='row text-left m-0'>
                    <div className="col-12 py-1">
                      {notes.map((note, index) =>
                        <div key={index}
                             className="custom-control custom-checkbox">
                          <input type="checkbox"
                                 className="custom-control-input"
                                 checked={(showAdvanceSend.note_ids || []).some((noteId) => noteId === note.id)}
                                 onChange={
                                   (e) => {
                                     showAdvanceSendNoteClicked(note.id);
                                   }
                                 }
                                 id={("show-advance-send-" + index)} />
                          <label className="custom-control-label"
                                 htmlFor={("show-advance-send-" + index)}>
                            { note.title }
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Popup>

            ) : null}
            <div className="row m-0">
              <div className="col-12">
                <form onSubmit={
                        (e) => {
                          e.preventDefault();

                          if (!canSubmitForm(showAdvanceSend)){
                            return false;
                          }

                          createShowAdvanceSend(csrfToken, team, showAdvanceSend);
                        }
                      }>
                  <div className="row"
                       style={{
                         "border": "2px solid #f5f5f5",
                         "borderRadius": "4px"
                       }}>
                    <div className="col-12 py-2">
                      <div className="row">
                        <div className="col-12 pr-0">
                          <div className="input-group">
                            <label className="text-muted align-self-center mb-0 mr-1"
                                   htmlFor="show-advance-send-to">
                              To:
                            </label>
                            <input type="email"
                                   multiple={true}
                                   className="form-control form-control-sm border-0 small"
                                   style={{
                                     "borderTopRightRadius": "0px",
                                     "borderBottomRightRadius": "0px"
                                   }}
                                   value={showAdvanceSend.to || ""}
                                   onChange={
                                     (e) => {
                                       showAdvanceSendToChanged(e.target.value);
                                     }
                                   }
                                   id="show-advance-send-to" />
                          </div>
                          <hr className="m-0"
                              style={{"borderTop": "2px solid #f5f5f5"}} />
                        </div>
                        <div className="col-12 pr-0">
                          <div className="input-group">
                            <label className="text-muted align-self-center mb-0 mr-1"
                                   htmlFor="show-advance-send-subject">
                              Subject:
                            </label>
                            <input type="text"
                                   className="form-control form-control-sm border-0 small"
                                   style={{
                                     "borderTopRightRadius": "0px",
                                     "borderBottomRightRadius": "0px"
                                   }}
                                   value={showAdvanceSend.subject || ""}
                                   onChange={
                                     (e) => {
                                       showAdvanceSendSubjectChanged(e.target.value);
                                     }
                                   }
                                   id="show-advance-send-subject" />
                          </div>
                          <hr className="m-0"
                              style={{"borderTop": "2px solid #f5f5f5"}} />
                        </div>
                        <div className="col-12 p-0">
                          <div className="input-group">
                            <textarea className="form-control form-control-sm border-0 small"
                                      placeholder={"Customize your message here."}
                                      style={{
                                        "resize": "none",
                                        "borderRadius": "0px",
                                        "padding": "20px 15px 15px 15px"
                                      }}
                                      value={showAdvanceSend.body || ""}
                                      onChange={
                                        (e) => {
                                          showAdvanceSendBodyChanged(e.target.value);
                                        }
                                      }
                                      rows="5">
                            </textarea>
                          </div>
                          <hr style={{
                                "margin": "0 15px",
                                "borderTop": "2px solid #cccccc"
                              }} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 p-0">
                      <div className="embed-responsive" style={{"minHeight": "300px"}}>
                        <Iframe url={iFrameUrl(team, confirm, showAdvanceSend)}
                                className="embed-responsive-item" />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{"marginTop": "30px"}}>
                    <div className="col-12 text-right">
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             showAdvanceSendChanged({});
                           }
                         }>
                        <strong>Cancel</strong>
                      </a>
                      <input type="submit"
                             style={{"borderRadius": "8px"}}
                             className="btn btn-primary btn-sm ml-3 strong"
                             disabled={!canSubmitForm(showAdvanceSend)}
                             value="Send" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  </div>
);

ShowAdvanceSendPopup.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  showAdvanceSend: PropTypes.object,
  showAdvanceSendChanged: PropTypes.func.isRequired,
  showAdvanceSendToChanged: PropTypes.func.isRequired,
  showAdvanceSendSubjectChanged: PropTypes.func.isRequired,
  showAdvanceSendBodyChanged: PropTypes.func.isRequired,
  createShowAdvanceSend: PropTypes.func.isRequired,
  notes: PropTypes.array,
  showAdvanceSendNoteClicked: PropTypes.func.isRequired
};

export default ShowAdvanceSendPopup;
