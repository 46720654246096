import PropTypes from 'prop-types';
import React from 'react';

import LineItemsTable from '../../ProfitAndLoss/components/LineItemsTable';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatCurrency = function(value){
  var abs = Math.abs(value);

  if(value < 0){
    return ("(" + formatter.format(abs) + ")");
  } else {
    return formatter.format(abs);
  }
}

function tableColgroup() {
  return (
    <colgroup>
      <col span={"1"} width={"16.666666667%"} />
      <col span={"1"} width={"16.666666667%"} />
      <col span={"1"} width={"16.666666667%"} />
      <col span={"1"} width={"16.666666667%"} />
      <col span={"1"} width={"16.666666667%"} />
      <col span={"1"} width={"16.666666667%"} />
    </colgroup>
  );
}

const ProfitAndLossTable = ({
  confirm,
  incomeItems,
  incomeTotal,
  incomeEstimateTotal,
  incomeForecastTotal,
  expenseItems,
  expenseTotal,
  expenseEstimateTotal,
  expenseForecastTotal,
  profitLoss,
  profitLossEstimate,
  profitLossForecast,
  ticketsSold,
  ticketsSoldForecast,
}) => {
  return(
    <div style={{"padding": "0px 7px"}}>
      <table style={{
              "width": "100%",
              "fontFamily": "Open Sans",
              "fontSize": "14px"
            }}>
        <tbody>
          <tr>
            {confirm.venue_ownership && confirm.venue_ownership.logo_thumb_url ? (
              <td style={{"textAlign": "left", "verticalAlign": "middle"}}>
                <div style={{
                              "width": "56px",
                              "height": "56px",
                              "overflow": "hidden",
                              "borderRadius": "8px",
                              "marginRight": "12px"
                            }}>
                  <img src={confirm.venue_ownership.logo_thumb_url}
                        style={{"height": "100%"}}>
                  </img>
                </div>
              </td>
            ) : null}
            <td style={{"verticalAlign": "middle", "width": "100%"}}>
              <p style={{"margin": "0px", "fontSize": "18px", "textAlign": "left", "display": "inline-block", "float": "left"}}>
                <strong>PROFIT & LOSS</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p style={{"margin": "0px", "fontSize": "24px"}}>
                <strong>{confirm.title}</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p style={{"margin": "-3px 0px 16px 0px", "fontSize": "16px"}}>
                {`${confirm.human_start_date ? confirm.human_start_date : "TBD"} at ${confirm.venue.name} in ${confirm.venue.city_and_state}`}
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{"background": "#333333"}}>
              <p style={{"fontSize": "16px", "color": "white", "margin": "8px 15px"}}>
                <strong>Profit & Loss</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div style={{"marginTop": "3px"}}>
                <LineItemsTable
                  title={""}
                  items={[
                    {
                      name: "Tickets Sold",
                      category: "",
                      forecast: ticketsSoldForecast,
                      estimate: ticketsSold,
                      actual: ticketsSold
                    }
                  ]}
                  tableColgroup={tableColgroup()}
                  formatCurrency={value => value}
                  totalRowLeftHand={""}
                  totalRowEstimateTotal={""}
                  totalRowForecastTotal={""}
                  totalRowTotal={""}
                >
                </LineItemsTable>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div style={{"marginTop": "3px"}}>
                <LineItemsTable
                  title={"Income"}
                  items={incomeItems}
                  tableColgroup={tableColgroup()}
                  formatCurrency={formatCurrency}
                  totalRowLeftHand={"Total Income"}
                  totalRowEstimateTotal={formatCurrency(incomeEstimateTotal)}
                  totalRowForecastTotal={formatCurrency(incomeForecastTotal)}
                  totalRowTotal={formatCurrency(incomeTotal)}
                >
                </LineItemsTable>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div style={{"marginTop": "3px"}}>
                <LineItemsTable
                  title={"Expenses"}
                  items={expenseItems}
                  tableColgroup={tableColgroup()}
                  formatCurrency={formatCurrency}
                  totalRowLeftHand={"Total Expenses"}
                  totalRowEstimateTotal={formatCurrency(expenseEstimateTotal)}
                  totalRowForecastTotal={formatCurrency(expenseForecastTotal)}
                  totalRowTotal={formatCurrency(expenseTotal)}
                >
                </LineItemsTable>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <table style={{"width": "100%", "marginTop": "-8px", "borderSpacing": "0px 2px", "borderCollapse": "separate"}}>
                {tableColgroup()}
                <tbody style={{"fontSize": "12px"}}>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td style={{"padding": "3px 0 3px 15px", "textAlign": "right"}}>
                      <strong style={{"fontSize": "14px"}}>Show Profit (Loss)</strong>
                    </td>
                    <td style={{"padding": "3px 0 3px 15px", "textAlign": "right"}}>
                      <strong style={{"fontSize": "14"}}>{formatCurrency(profitLossForecast)}</strong>
                    </td>
                    <td style={{"padding": "3px 0 3px 15px", "textAlign": "right"}}>
                      <strong style={{"fontSize": "14"}}>{formatCurrency(profitLossEstimate)}</strong>
                    </td>
                    <td style={{"padding": "3px 15px 3px 15px", "textAlign": "right"}}>
                      <strong style={{"fontSize": "14"}}>{formatCurrency(profitLoss)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ProfitAndLossTable.propTypes = {
  confirm: PropTypes.object,
  incomeItems: PropTypes.array,
  incomeTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  incomeEstimateTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  incomeForecastTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expenseItems: PropTypes.array,
  expenseTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expenseEstimateTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expenseForecastTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  profitLoss: PropTypes.string,
  profitLossEstimate: PropTypes.string,
  profitLossForecast: PropTypes.string,
  ticketsSold: PropTypes.number,
  ticketsSoldForecast: PropTypes.number,
};

export default ProfitAndLossTable;
