import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";

const overlayStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "0px",
  width: "fit-content",
};

const contentStyle = {
  padding: "15px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const formStyle = {
  width: "300px",
  display: "inline-block",
  position: "relative",
  padding: "5px",
  textAlign: "left",
};

const inputStyle = {
  border: "1px solid #EEEEEE",
  width: "100%",
  padding: "5px",
}

const footerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: "10px",
};

const cancelButtonStyle = {
  border: "1px solid #B2DBF5",
  borderRadius: "5px",
  color: "#1982C4",
  cursor: "pointer",
  border: "none",
  padding: "5px 10px",
};

const SavedRowOverlay = ({ config }) => {
  const { isOpen = false, args, resolve, reject } = config;

  const [name, setName] = useState("");

  useEffect(() => {
    setName(args?.metadata?.name || "");
  }, [args?.metadata?.name]);

  const handleSave = () => {
    resolve({ name });
  };

  const handleOnChange = (event) => setName(event?.target?.value);

  const saveButtonStyle = {
    background: "#1982C4",
    borderRadius: "5px",
    border: "none",
    color: "white",
    cursor: !!name ? "pointer" : "not-allowed",
    opacity: !!name ? 1 : 0.5,
    padding: "5px 10px",
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={reject}
      contentStyle={overlayStyle}
    >
      <div style={contentStyle}>
        <div style={formStyle}>
          <input
            value={name}
            onChange={handleOnChange}
            placeholder="Row name"
            style={inputStyle}
          />
        </div>
        <div style={footerStyle}>
          <button style={cancelButtonStyle} onClick={reject}>
            Cancel
          </button>
          <button style={saveButtonStyle} onClick={handleSave} type="submit">
            Save
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default SavedRowOverlay;