import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask'
import Popup from 'reactjs-popup';
import Tippy from '@tippyjs/react';
import { toast } from 'react-toastify';

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const axios = require("axios").default;

function isEditingItemSettlementLineItem(item, itemToEdit) {
  return (
    itemToEdit
      && itemToEdit.overridable_type
      && itemToEdit.overridable_type === item.overridable_type
      && itemToEdit.overridable_id
      && itemToEdit.overridable_id === item.overridable_id
      && itemToEdit.overridable_type === "SettlementLineItem"
  )
}

function isEditingSettlementOverride(item, itemToEdit) {
  var overridableTypes = [
    "Budget",
    "BudgetBonus",
    "BudgetLineItem",
    "EventbriteTicketClass",
    "FinanceLineItem",
    "ManualTax",
    "ManualTicketCount",
    "Offer",
    "TicketType"
  ];

  return (
    itemToEdit
      && itemToEdit.overridable_type
      && itemToEdit.overridable_type === item.overridable_type
      && itemToEdit.overridable_id
      && itemToEdit.overridable_id === item.overridable_id
      && itemToEdit.calculatedLeftHand === item.calculatedLeftHand
      && itemToEdit.section === item.section
      && overridableTypes.includes(itemToEdit.overridable_type)
  )
}

function canEditRow(item) {
  return [
    "Budget",
    "BudgetBonus",
    "BudgetLineItem",
    "EventbriteTicketClass",
    "FinanceLineItem",
    "ManualTax",
    "ManualTicketCount",
    "Offer",
    "SettlementLineItem",
    "TicketType"
  ].includes(item.overridable_type);
}

function canDeleteRow(item, itemToEdit) {
  return (
    isEditingItemSettlementLineItem(item, itemToEdit)
      || (item.settlementOverride && item.settlementOverride.id)
  );
}

const inlineMenuContentStyle = {
  textAlign: "left",
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  width: "150px",
  padding: "0px"
};

const listButtonStyle = {
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  padding: "0px",
  textAlign: "left",
  width: "100%",
};

const BudgetLineItemsTableRow = ({
  title,
  formatCurrency,
  numberWithCommas,
  csrfToken,
  team,
  itemToEdit,
  itemToEditChanged,
  item,
  deleteSettlementLineItem,
  deleteSettlementOverride,
  isSettling,
  requestFormat,
  offerId,
  setBudgetState,
  editedDropCountChanged,
  showCurrencyConversion,
  globalCurrency
}) => {
  const { settlementVisibility } = item;

  const showInSettlement = !!settlementVisibility
    ? settlementVisibility.visible
    : item.show_in_offer;

  const toggleShowInSettlement = (shouldShow) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    if (!!settlementVisibility) {
      axios.patch("/teams/" + team.id + "/settlement_visibilities/" + settlementVisibility.id, {
        settlement_visibility: {
          visible: shouldShow,
        }
      }).then(data => {
        setBudgetState((current) => {
          return {
            ...current,
            settlement_visibilities: current.settlement_visibilities.map((visibility) => {
              if (visibility.id === settlementVisibility.id) {
                return data.data;
              } else {
                return visibility;
              }
            })
          };
        });
      }).catch(err => {
        toast.error(err.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
        console.log(err);
      });
    } else {
      var originalAmount = item.settlementOverride ? item.settlementOverride.amount : item.left_hand;

      axios.post("/teams/" + team.id + "/settlement_visibilities/", {
        settlement_visibility: {
          amount: originalAmount,
          offer_id: offerId,
          section: item.section,
          settleable_type: item.overridable_type,
          settleable_id: item.overridable_id,
          visible: shouldShow,
        }
      }).then(data => {
        setBudgetState((current) => {
          return {
            ...current,
            settlement_visibilities: [
              ...current.settlement_visibilities,
              data.data
            ]
          };
        });
      }).catch(err => {
        toast.error(err.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
    }
  }

  return (
    <tr style={{"background": "#f2f2f2"}}>
      {isEditingItemSettlementLineItem(item, itemToEdit) || isEditingSettlementOverride(item, itemToEdit) ? (
        <>
          <td style={{"padding": "3px 9px"}}>
            <input type="text"
                    style={{
                      "fontSize": "12px",
                      "border": "none",
                      "width": "100%",
                      "padding": "2px 5px"
                    }}
                    autoFocus={true}
                    onChange={
                      (e) => {
                        itemToEditChanged({
                          ...itemToEdit,
                          name: e.target.value
                        });
                      }
                    }
                    value={itemToEdit.name || ""} />
          </td>
          <td style={{"textAlign": "right", "padding": "3px 9px"}}>
            <MaskedInput style={{
                            "fontSize": "12px",
                            "border": "none",
                            "width": "100%",
                            "padding": "2px 5px",
                            "textAlign": "right"
                          }}
                          mask={item.calc_type === "fixed" ? (
                            createNumberMask({
                              prefix: "",
                              decimalLimit: '0'
                            })
                          ) : (
                            createNumberMask({
                              prefix: "",
                              allowDecimal: true,
                              decimalLimit: '5'
                            })
                          )}
                          onChange={
                            (e) => {
                                itemToEditChanged({
                                  ...itemToEdit,
                                  quantity: e.target.value
                                });
                            }
                          }
                          value={itemToEdit.quantity || ""} />
          </td>
          <td style={{"textAlign": "right", "padding": "3px 9px"}}>
            <CurrencyInput style={{
                            "fontSize": "12px",
                            "border": "none",
                            "width": "100%",
                            "padding": "2px 5px",
                            "textAlign": "right"
                          }}
                          value={itemToEdit.amount || ""}
                          onChange={
                            (e) => {
                                itemToEditChanged({
                                  ...itemToEdit,
                                  amount: e.target.value
                                });
                            }
                          }
                          maskOptions={item.calc_type === "fixed" ? ({
                            prefix: "",
                            integerLimit: null
                          }) : ({
                            prefix: "",
                            allowDecimal: true,
                            decimalLimit: '5'
                          })} />
          </td>
          {showCurrencyConversion ? (
            <td style={{"textAlign": "right", "padding": "3px 9px"}}>
              {globalCurrency.formatted(item.calculatedTotal)}
            </td>
          ) : null}
          <td style={{"textAlign": "right", "padding": "3px 15px 3px 0px"}}>
            <strong>
              {formatCurrency(item.calculatedTotal)}
            </strong>
          </td>
          <td style={{"textAlign": "center", "padding": "3px 0px"}}>
            <a href="#"
                style={{"color": "#999999", "marginRight": "3px"}}
                title={"Cancel"}
                onClick={
                  (e) => {
                    e.preventDefault();
                    itemToEditChanged({});
                  }
                }>
              <i aria-hidden className="fas fa-times-circle fa-fw"></i>
            </a>
            {canDeleteRow(item, itemToEdit) ? (
              <a href="#"
                  style={{"color": "#FF595E", "marginRight": "3px"}}
                  title={isEditingItemSettlementLineItem(item, itemToEdit) ? "Delete" : "Delete Override"}
                  onClick={
                    (e) => {
                      e.preventDefault();

                      if(isEditingItemSettlementLineItem(item, itemToEdit)){
                        deleteSettlementLineItem(csrfToken, team, item);
                      } else {
                        deleteSettlementOverride(csrfToken, team, item.settlementOverride);
                      }
                    }
                  }>
                <i aria-hidden className="fas fa-trash-alt fa-fw"></i>
              </a>
            ) : null}
            <button style={{
                      "border": "none",
                      "cursor": "pointer",
                      "padding": "0px"
                    }}
                    title={"Save"}
                    type="submit">
              <i aria-hidden
                  style={{"color": "#1982C4"}}
                  className="fas fa-check-circle fa-fw"></i>
            </button>
          </td>
        </>
      ) : (
        <>
          <td style={{"padding": "3px 0px 3px 15px"}}>
            {item.name && item.name.length > 0 ? (
              <span>
                {item.name}
              </span>
            ) : (
              <span style={{"opacity": "0.5"}}>
                --
              </span>
            )}
            {showInSettlement || (
              <Tippy content="Hidden from settlement PDF">
                <span className="badge">
                  <i aria-hidden className="text-muted fas fa-eye-slash fa-fw mx-2"></i>
                </span>
              </Tippy>
            )}
          </td>
          {!isSettling && title === "Tickets" ? (
            <>
              <td style={{"textAlign": "right", "padding": "3px 9px 3px 0px"}}>
                {numberWithCommas(item.available || 0)}
              </td>
              <td style={{"textAlign": "right", "padding": "3px 9px 3px 0px"}}>
                {numberWithCommas(item.comps || 0)}
              </td>
              <td style={{"textAlign": "right", "padding": "3px 9px 3px 0px"}}>
                {numberWithCommas(item.kills || 0)}
              </td>
            </>
          ) : null}
          <td style={{"textAlign": "right", "padding": "3px 9px 3px 0px"}}>
              {["fixed", "per_tickets_sold"].includes(item.calc_type) ? (
                numberWithCommas(item.calculatedRightHand)
              ) : (
                formatCurrency(item.calculatedRightHand)
              )}
          </td>
          <td style={{"textAlign": "right", "padding": "3px 9px 3px 0px"}}>
            {["fixed", "per_tickets_sold"].includes(item.calc_type) ? (
              formatCurrency(item.calculatedLeftHand)
            ) : (
              `${item.calculatedLeftHand}\%`
            )}
          </td>
          {showCurrencyConversion ? (
            <td style={{"textAlign": "right", "padding": "3px 9px 3px 0px"}}>
              {globalCurrency.formatted(item.calculatedTotal)}
            </td>
          ) : null}
          <td style={{"textAlign": "right", "padding": "3px 15px 3px 0px"}}>
            <strong>
              {formatCurrency(item.calculatedTotal)}
            </strong>
          </td>
          <td style={{"textAlign": "center", "padding": "3px 0px"}}>
            {isSettling && requestFormat === "html" && canEditRow(item) ? (
              <Popup
                trigger={
                  <a href="#"
                      className="text-muted">
                    <i aria-hidden className="fas fa-ellipsis-v"></i>
                  </a>
                }
                onOpen={
                  (e) => {
                    e.preventDefault();
                  }
                }
                arrow={false}
                offsetY={5}
                position="bottom right"
                contentStyle={inlineMenuContentStyle}
                closeOnDocumentClick
              >
                {close => (
                  <div>
                    <div className="row text-left">
                      <div className="col">
                        <ul className="list-group offer-inline-menu highlight-primary">
                          <li className="list-group-item">
                            <button
                              style={listButtonStyle}
                              onClick={
                                (e) => {
                                  e.preventDefault();

                                  var itemToEdit;

                                  if(item.overridable_type === "SettlementLineItem") {
                                    itemToEdit = Object.assign({}, item, {
                                      amount: item.amount ? parseFloat(item.amount).toFixed(2) : item.amount,
                                    });
                                  } else {
                                    itemToEdit = Object.assign({}, item, {
                                      amount: item.calculatedLeftHand,
                                      quantity: item.calculatedRightHand
                                    });
                                  }

                                  itemToEditChanged(itemToEdit);
                                  editedDropCountChanged(null);
                                  close();
                                }
                              }
                              className="text-dark"
                            >
                              <i aria-hidden className="text-muted fas fa-pencil fa-fw mx-2"></i>
                              <span style={{"fontWeight": 'normal'}}>
                                Edit
                              </span>
                            </button>
                          </li>
                          <li className="list-group-item">
                            <button
                              style={listButtonStyle}
                              onClick={(e) => {
                                e.preventDefault();
                                toggleShowInSettlement(!showInSettlement);
                                close();
                              }}
                            >
                              <i aria-hidden className="text-muted fas fa-eye-slash fa-fw mx-2"></i>
                              <span style={{"fontWeight": 'normal'}}>
                                {showInSettlement ? "Hide from PDF" : "Show in PDF"}
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            ) : (
              <span>&nbsp;</span>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

BudgetLineItemsTableRow.propTypes = {
  title: PropTypes.string.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  numberWithCommas: PropTypes.func.isRequired,
  csrfToken: PropTypes.string,
  team: PropTypes.object.isRequired,
  itemToEdit: PropTypes.object,
  itemToEditChanged: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  deleteSettlementLineItem: PropTypes.func.isRequired,
  deleteSettlementOverride: PropTypes.func.isRequired,
  isSettling: PropTypes.bool.isRequired,
  requestFormat: PropTypes.string,
  offerId: PropTypes.string.isRequired,
  setBudgetState: PropTypes.func.isRequired,
  editedDropCountChanged: PropTypes.func.isRequired,
  showCurrencyConversion: PropTypes.bool,
  globalCurrency: PropTypes.object
};

export default BudgetLineItemsTableRow;
