import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

var ReactDOM = require('react-dom');

import FilterPanel from '../../TableView/components/FilterPanel';

import Popup from "reactjs-popup";
import SlidingPane from "react-sliding-pane";
import DataTable from '../../DataTable/components/DataTable';
import ReadMore from '../../ReadMore/components/ReadMore';
import TagManagerApp from '../../TagManager/startup/TagManagerApp';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "0px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "197px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const reduceFilters = (filters) => {
  return filters.filter((filter) => {
    return filter.value.length > 0;
  }).reduce((params, filter) => {
    var newParam = {};
    newParam[filter.paramName] = filter.value;

    return Object.assign(params, newParam);
  }, {});
}

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const inboundSubmissionArtistHasImage = (inboundSubmission) => {
  return (
    inboundSubmission
      && inboundSubmission.artist
      && inboundSubmission.artist.image_url
  );
};

const hasListenPreviewCapability = (inboundSubmission) => {
  return (
    inboundSubmission
      && inboundSubmission.spotify_id
      && inboundSubmission.spotify_id.length > 0
  );
};

const hasSocialFollowing = (inboundSubmission) => {
  return (
    inboundSubmission
      && (
        !!inboundSubmission.spotify_url
          || !!inboundSubmission.facebook_url
          || !!inboundSubmission.twitter_url
          || !!inboundSubmission.instagram_url
          || !!inboundSubmission.youtube
          || !!inboundSubmission.tiktok
      )
  );
};


const hasContactInfo = (inboundSubmission) => {
  return (
    inboundSubmission
      && (
        (inboundSubmission.name && inboundSubmission.name.length > 0)
          || (inboundSubmission.email && inboundSubmission.email.length > 0)
          || (inboundSubmission.phone && inboundSubmission.phone.length > 0)
          || (inboundSubmission.location && inboundSubmission.location.length > 0)
      )
  );
};

const hasMessage = (inboundSubmission) => {
  return (
    inboundSubmission
      && inboundSubmission.message
      && inboundSubmission.message.length > 0
  );
};

const signupLink = (venueOwnership, team) => {
  return (
    `https://inbound.opendate.io/?venueid=${venueOwnership.id}&team_id=${team.id}`
  );
};

const embedCode = (venueOwnership, team) => {
  return `<script src="https://inbound-web.app/Inboundiframe.js"></script>
<iframe id="inbound04" title="Venue" style="border:none; width: 1px; min-width: 100%;"></iframe>
<script type="text/javascript">
  embedVenueUrl("${signupLink(venueOwnership, team)}");
</script>
`
};

const InboundSubmissions = ({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  dataTableElChanged,
  inboundSubmissionExists,
  openInboundSubmission,
  openInboundSubmissionChanged,
  venueOwnerships,
  venueOwnershipsChanged,
  gettingFormLinks,
  gettingFormLinksChanged,
  filters,
  updateFilter,
  clearFilterValues,
  debouncedUpdateFilter,
  deleteInboundSubmission,
  canViewArtist,
  tagManagerOpen,
  tagManagerOpenChanged,
  tagManagerFilter,
  tagManagerFilterChanged,
  getActiveTags,
  getAvailableTags,
  availableTags,
  availableTagsChanged,
}) => {
  const memoReducedFilters = useMemo(() => {
    return (
      reduceFilters(filters)
    );
  }, [
    filters
  ]);

  return (
    <div>
      <ToastContainer />
      {!inboundSubmissionExists ? (
        <div className='row'>
          <div className='col-12'>
            <div className='card border-0 shadow-2'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-8 d-flex align-items-center'>
                    <p style={{'fontSize': '20px'}} className='mb-0'>
                      <strong>
                        Artist Submissions&nbsp;
                        <span className='text-muted'>(0)</span>
                      </strong>
                    </p>
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-end'>
                    <a href="#"
                       onClick={
                          (e) => {
                            e.preventDefault();
                            gettingFormLinksChanged(true);
                          }
                       }
                       style={{
                        "borderRadius": "4px",
                        "borderWidth": "1px"
                       }}
                       className='btn btn-outline-primary small btn-sm'>
                      <i className="far fa-link mr-1"></i>
                      <strong>Form Links</strong>
                    </a>
                  </div>
                </div>
              </div>
              <div className='card-body text-center' style={{'paddingBottom': '456px', "paddingTop": "11px"}}>
                <div className='dot d-flex justify-content-center align-items-center'
                      style={{'margin': '0px auto 10px auto', 'width': '88px', 'height': '88px', 'background': '#F3F9FC', 'borderRadius': '50%', 'border': '4px solid #BBE0F6'}}>
                  <img src="/inbox-in-ftux.png" style={{"width": "48px"}} />
                </div>
                <p className='mb-1' style={{'fontSize': '20px'}}>
                  <strong>No Submissions Yet</strong>
                </p>
                <p className='text-muted mb-3' style={{'maxWidth': '245px', 'margin': '0 auto'}}>
                  Make sure your Inbound form is linked or embedded on your website.
                </p>
                <a href={"#"}
                    onClick={
                      (e) => {
                        e.preventDefault();
                        gettingFormLinksChanged(true);
                      }
                    }
                    className='btn btn-primary'
                    style={{'borderRadius': '4px', 'fontSize': '14px'}}>
                  <strong>Get Link/Embed Code</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <FilterPanel filters={filters}
                      clearFilterValues={clearFilterValues}
                      debouncedUpdateFilter={debouncedUpdateFilter}
                      updateFilter={updateFilter} />
          <div className='position-relative'>
            <div className='position-absolute' style={{"top": "20px", "right": "24px"}}>
              <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      gettingFormLinksChanged(true);
                    }
                  }
                  style={{
                  "borderRadius": "4px",
                  "borderWidth": "1px"
                  }}
                  className='btn btn-outline-primary small btn-sm'>
                <i className="far fa-link mr-1"></i>
                <strong>Form Links</strong>
              </a>
            </div>
            <DataTable title={dataTableTitle}
                        dataSource={dataTableDataSource}
                        columns={dataTableColumns}
                        colGroup={
                          <colgroup>
                            <col span={1} style={{"width": "28%"}} />
                            <col span={1} style={{"width": "16%"}} />
                            <col span={1} style={{"width": "10%"}} />
                            <col span={1} style={{"width": "23%"}} />
                            <col span={1} style={{"width": "20%"}} />
                            <col span={1} style={{"width": "3%"}} />
                          </colgroup>
                        }
                        reducedFilters={memoReducedFilters}
                        onTableElementSet={
                          (el) => {
                            dataTableElChanged(el);
                          }
                        }
                        rowCallback={
                          function(row, data, displayNum, displayIndex, dataIndex){
                            let dataTable = this;
                            let api = dataTable.api();
                            let inboundSubmission = JSON.parse(decodeHtml(data.json));

                            var mountReact = row.getElementsByClassName("inbound-submission-mount-react")[0];
                            ReactDOM.render((
                              <div className='row'>
                                <div className='col-12 d-flex align-items-center'>
                                  <img src={inboundSubmission.artist_img_thumb_url}
                                      style={{"marginRight": "13px"}}
                                      className='img-fluid rounded inline-img' />
                                  <p className='mb-0 text-truncate'
                                     title={inboundSubmission.artist_name}
                                     style={{"maxWidth": "200px"}}>
                                    <a href="#"
                                      onClick={
                                          (e) => {
                                            e.preventDefault();
                                            openInboundSubmissionChanged(inboundSubmission);
                                          }
                                      }>
                                      <strong>{inboundSubmission.artist_name}</strong>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            ), mountReact);

                            var mountReactMenu = row.getElementsByClassName("inbound-submission-menu-mount-react")[0];
                            ReactDOM.render((
                              <div className='row'>
                                <div className='col-12 d-flex align-items-center'>
                                  {currentUser.can_manage_inbound_submissions ? (
                                    <Popup arrow={false}
                                          offsetY={5}
                                          position="bottom right"
                                          contentStyle={popupContentStyle}
                                          onOpen={
                                            (e) => {
                                              e.preventDefault();
                                            }
                                          }
                                          trigger={open => (
                                            <a href="#"
                                                className="text-muted">
                                              <i className="fas fa-ellipsis-v"></i>
                                            </a>
                                          )} >
                                      {close => (
                                        <div className='row text-left'>
                                          <div className="col-12">
                                            <ul className="list-group offer-inline-menu highlight-primary">
                                              <li className="list-group-item" style={{"padding": "12px"}}>
                                                <Popup
                                                    trigger={
                                                      <a href="#" className="text-dark d-flex align-items-center">
                                                        <span className="d-inline-block text-center"
                                                              style={{
                                                                "width": "24px",
                                                                "fontSize": "20px",
                                                                "marginRight": "14px"
                                                              }}>
                                                          <i className="far fa-trash"></i>
                                                        </span>
                                                        Delete Submission
                                                      </a>
                                                    }
                                                    modal
                                                    onOpen={
                                                      (e) => {
                                                        e.preventDefault();
                                                      }
                                                    }
                                                    contentStyle={deleteModalContentStyle}
                                                    closeOnDocumentClick
                                                  >
                                                  {closeDeletePopup => (
                                                    <div>
                                                      <div className="row">
                                                        <div className="col">
                                                          <div className="row">
                                                            <div className="col">
                                                              <h5 style={{"marginBottom": "16px"}}>
                                                                <div className="strong">Remove submission?</div>
                                                              </h5>
                                                            </div>
                                                            <div className="col-xs-auto">
                                                              <a href="#"
                                                                className="text-dark"
                                                                style={{"paddingRight": "15px"}}
                                                                onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                                <i className="fa fa-times"></i>
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <p>You're about to remove this submission.</p>
                                                          <p>This cannot be undone.</p>
                                                          <p>Are you sure?</p>
                                                          <div className="text-right">
                                                            <a href="#"
                                                              onClick={
                                                                (e) => {
                                                                  e.preventDefault();
                                                                  closeDeletePopup();
                                                                }
                                                              }>
                                                              Cancel
                                                            </a>
                                                            <a href="#"
                                                              className="btn btn-danger ml-3"
                                                              onClick={
                                                                (e) => {
                                                                  e.preventDefault();
                                                                  closeDeletePopup();
                                                                  close();
                                                                  deleteInboundSubmission(csrfToken, team, inboundSubmission, dataTable);
                                                                }
                                                              }>
                                                              Remove
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Popup>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  ) : null}
                                </div>
                              </div>
                            ), mountReactMenu);
                          }
                        }
                        order={dataTableOrder} />
          </div>
        </React.Fragment>
      )}
      <SlidingPane
        isOpen={Object.keys(openInboundSubmission).length > 0}
        className="sliding-pane-container"
        width={"416px"}
        hideHeader={true}
        onRequestClose={() => {
          openInboundSubmissionChanged({});
          tagManagerOpenChanged(false);
        }}
      >
        <React.Fragment>
          <div className="row">
            <div className="col-12 position-relative">
              <a href="#"
                style={{
                  "fontSize": "28px",
                  "top": "3px",
                  "right": "30px",
                  "zIndex": "100"
                }}
                className={"position-absolute " + (inboundSubmissionArtistHasImage(openInboundSubmission) ? "text-white" : "text-dark")}
                onClick={(e) => {
                  e.preventDefault();
                  openInboundSubmissionChanged({});
                }} >
                <i className="fa fa-times"></i>
              </a>
              {inboundSubmissionArtistHasImage(openInboundSubmission) ? (
                <div className="card no-border shadow-2 mb-0">
                  <div className="card-header hero-image"
                      style={{
                        "height": "180px",
                        "borderTopRightRadius": "0",
                        "backgroundImage": "linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.75)), url('" + openInboundSubmission.artist.image_url + "')"
                      }} >
                  {openInboundSubmission.artist && canViewArtist ? (
                    <div className="bottom-lead">
                      <p className="mb-0 small">
                        <a className="text-white"
                          href={"/artists/" + openInboundSubmission.artist.permalink}>
                          <strong>
                            Artist Details <i className="fas fa-arrow-right ml-1"></i>
                          </strong>
                        </a>
                      </p>
                    </div>
                  ) : null}
                  </div>
                </div>
              ) : openInboundSubmission.artist && canViewArtist ? (
                <div className="card shadow-0 mb-0"
                     style={{
                      "borderTop": "0",
                      "borderRight": "0",
                      "borderLeft": "0",
                      "borderBottom": "1px solid #e7e7e7",
                      "borderRadius": "0",
                      "borderTopLeftRadius": "8px"
                     }}>
                  <div className="card-body small"
                       style={{"padding": "14px 20px"}}>
                    <a href={"/artists/" + openInboundSubmission.artist.permalink}>
                      <strong>
                        Artist Details <i className="fas fa-arrow-right ml-1"></i>
                      </strong>
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {currentUser.can_read_tagged_items && Object.keys(openInboundSubmission).length > 0 && openInboundSubmission.artist ? (
            <div className="mt-2 px-3">
              <TagManagerApp
                csrfToken={csrfToken}
                team={team}
                currentUser={currentUser}
                scope="Artist"
                title={`Tags for ${openInboundSubmission.artist.name}`}
                taggable={openInboundSubmission.artist}
              />
            </div>
          ) : null}
          <div className='row m-0'>
            <div className='col-12 p-0'>
              <div className="card shadow-0 mb-0"
                    style={{
                    "borderTop": "0",
                    "borderRight": "0",
                    "borderLeft": "0",
                    "borderBottom": "1px solid #e7e7e7",
                    "borderRadius": "0",
                    "borderTopLeftRadius": "8px"
                    }}>
                <div className="card-body small"
                      style={{"padding": "6px 20px 6px 20px"}}>
                  <div className='row'>
                    <div className='col-8'>
                      <p className='text-muted mb-0'
                         style={{
                            "fontSize": "10px",
                            "color": "#808080"
                          }}>
                        SENT TO
                      </p>
                      <p className='text-dark mb-0 small' style={{"marginTop": "-2px"}}>
                        {openInboundSubmission.venue && openInboundSubmission.venue.name ? (
                          <strong>{openInboundSubmission.venue.name}</strong>
                        ) : null}
                      </p>
                    </div>
                    <div className='col-4'>
                      <p className='text-muted mb-0'
                         style={{
                            "fontSize": "10px",
                            "color": "#808080"
                          }}>
                        DATE
                      </p>
                      <p className='text-dark mb-0 small' style={{"marginTop": "-2px"}}>
                        <strong>{openInboundSubmission.human_created_at}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row m-0'>
            <div className='col-12' style={{"padding": "18px 20px"}}>
              <div className='row'>
                <div className='col-12 d-flex align-items-center'>
                  <p className='mb-0 mr-2' style={{"fontSize": "20px"}}>
                    <strong>{openInboundSubmission.artist_name}</strong>
                  </p>
                  {!openInboundSubmission.cover ? (
                    <span className="badge badge-success small"
                          style={{
                            "background": "#98c648",
                            "padding": "5px 9px"
                          }}>
                      Original
                    </span>
                  ) : null}
                </div>
                {openInboundSubmission.formatted_website && openInboundSubmission.formatted_website.length > 0 ? (
                  <div className='col-12'>
                    <a href={openInboundSubmission.formatted_website} target="_blank">
                      {openInboundSubmission.formatted_website}
                    </a>
                  </div>
                ) : null}
                {openInboundSubmission.configuration && openInboundSubmission.configuration.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "10px"}}>
                    <p className='mb-0' style={{"fontSize": "10px", "color": "#808080"}}>
                      CONFIGURATION
                    </p>
                    <p className='mb-0' style={{"marginTop": "-1px"}}>
                      {openInboundSubmission.configuration}
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.record_label && openInboundSubmission.record_label.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "12px"}}>
                    <p className='mb-0' style={{"fontSize": "10px", "color": "#808080"}}>
                      RECORD LABEL
                    </p>
                    <p className='mb-0' style={{"marginTop": "-1px"}}>
                      {openInboundSubmission.record_label}
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.headlining_artist && openInboundSubmission.headlining_artist.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "12px"}}>
                    <p className='mb-0' style={{"fontSize": "10px", "color": "#808080"}}>
                      REQUESTED HEADLINING ARTIST
                    </p>
                    <p className='mb-0' style={{"marginTop": "-1px"}}>
                      {openInboundSubmission.headlining_artist}
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.bio && openInboundSubmission.bio.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "12px"}}>
                    <p className='mb-0' style={{"fontSize": "10px", "color": "#808080"}}>
                      BIO
                    </p>
                    <ReadMore lines={3}
                              more="More"
                              less="Less"
                              content={openInboundSubmission.bio.replace(/\n/g, '<br/>')} />
                  </div>
                ) : null}
                {openInboundSubmission.genre_record_names && openInboundSubmission.genre_record_names.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "7px"}}>
                    {openInboundSubmission.genre_record_names.map((genre, index) =>
                      <span key={index}
                            style={{
                              "background": "#f3f3f3",
                              "fontSize": "10px",
                              "padding": "3px 10px",
                              "marginRight": "3px"
                            }}
                            className="badge badge-light">
                        {genre}
                      </span>
                    )}
                  </div>
                ) : null}
                {hasListenPreviewCapability(openInboundSubmission) ? (
                  <div className='col-12' style={{"marginTop": "17px"}}>
                    <p className='mb-0' style={{"fontSize": "16px"}}>
                      <strong>Listen</strong>
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.spotify_id && openInboundSubmission.spotify_id.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "7px"}}>
                    <div className="embed-responsive" style={{"height": "80px"}}>
                      <iframe allow="encrypted-media"
                              allowtransparency="true"
                              className="embed-responsive-item"
                              frameBorder="0"
                              height="80"
                              src={`https://open.spotify.com/embed/artist/${openInboundSubmission.spotify_id}`}>
                      </iframe>
                    </div>
                  </div>
                ) : null}
                {openInboundSubmission.soundcloud_id && openInboundSubmission.soundcloud_id.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "7px"}}>
                    <div className="embed-responsive" style={{"height": "80px"}}>
                      <iframe id="soundcloud"
                              src={`https://w.soundcloud.com/player/?url=https%3A%2F%2Fsoundcloud.com%2F${openInboundSubmission.soundcloud_id}&amp;color=%23ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true`}
                              width="100%"
                              height="152"
                              frameBorder="0"
                              allowtransparency="true"
                              allow="encrypted-media"
                              style={{"height": "166px"}}>
                      </iframe>
                    </div>
                  </div>
                ) : null}
                {openInboundSubmission.bandcamp_id && openInboundSubmission.bandcamp_id.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "7px"}}>
                    <div className="embed-responsive" style={{"height": "120px"}}>
                      <iframe id="bandcamp-embed"
                              src={`https://bandcamp.com/EmbeddedPlayer/album=${openInboundSubmission.bandcamp_id}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/`}
                              width="100%"
                              height="120"
                              frameBorder="0"
                              allowtransparency="true"
                              allow="encrypted-media"
                              style={{"height": "120px"}}>
                      </iframe>
                    </div>
                  </div>
                ) : null}
                {hasSocialFollowing(openInboundSubmission) ? (
                  <div className='col-12' style={{"marginTop": "17px"}}>
                    <p className='mb-0' style={{"fontSize": "16px"}}>
                      <strong>Social</strong>
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.spotify_url ? (
                  <div className='col-12 d-flex align-items-center' style={{"marginTop": "7px"}}>
                    <div className='d-flex'>
                      <i className="fab fa-spotify fa-fw"
                         style={{
                            "color": "#98c648",
                            "fontSize": "28px"
                          }}>
                      </i>
                    </div>
                    <div style={{"marginLeft": "7px"}}>
                      <p className='mb-0' style={{"fontSize": "16px"}}>
                        <a href={openInboundSubmission.spotify_url} target="_blank" className='text-dark stretched-link'>
                          <strong>{openInboundSubmission.approx_spotify_followers || ''}</strong>
                        </a>
                      </p>
                      <p className='mb-0' style={{"fontSize": "10px", "marginTop": "-4px", "whiteSpace": "pre-line"}}>
                        {openInboundSubmission.approx_spotify_followers ? 'FOLLOWERS' : ''}
                      </p>
                    </div>
                  </div>
                ) : null}
                {openInboundSubmission.facebook_url ? (
                  <div className='col-12 d-flex align-items-center' style={{"marginTop": "17px"}}>
                    <div className='d-flex'>
                      <i className="fab fa-facebook-f fa-fw"
                         style={{
                            "color": "#415a93",
                            "fontSize": "28px"
                          }}>
                      </i>
                    </div>
                    <div style={{"marginLeft": "7px"}}>
                      <p className='mb-0' style={{"fontSize": "16px"}}>
                        <a href={openInboundSubmission.facebook_url} target="_blank" className='text-dark stretched-link'>
                          <strong>{openInboundSubmission.approx_facebook_followers || ''}</strong>
                        </a>
                      </p>
                      <p className='mb-0' style={{"fontSize": "10px", "marginTop": "-4px", "whiteSpace": "pre-line"}}>
                        {openInboundSubmission.approx_facebook_followers ? 'FOLLOWERS' : ''}
                      </p>
                    </div>
                  </div>
                ) : null}
                {openInboundSubmission.twitter_url ? (
                  <div className='col-12 d-flex align-items-center' style={{"marginTop": "17px"}}>
                    <div className='d-flex'>
                      <i className="fab fa-twitter fa-fw"
                         style={{
                            "color": "#4cacd2",
                            "fontSize": "28px"
                          }}>
                      </i>
                    </div>
                    <div style={{"marginLeft": "7px"}}>
                      <p className='mb-0' style={{"fontSize": "16px"}}>
                        <a href={openInboundSubmission.twitter_url} target="_blank" className='text-dark stretched-link'>
                          <strong>{openInboundSubmission.approx_twitter_followers || ''}</strong>
                        </a>
                      </p>
                      <p className='mb-0' style={{"fontSize": "10px", "marginTop": "-4px", "whiteSpace": "pre-line"}}>
                        {openInboundSubmission.approx_twitter_followers ? 'FOLLOWERS' : ''}
                      </p>
                    </div>
                  </div>
                ) : null}
                {openInboundSubmission.instagram_url ? (
                  <div className='col-12 d-flex align-items-center' style={{"marginTop": "17px"}}>
                    <div className='d-flex'>
                      <i className="fab fa-instagram fa-fw fa-instagram-gradient"
                        style={{
                          "fontSize": "28px"
                        }}>
                      </i>
                    </div>
                    <div style={{"marginLeft": "7px"}}>
                      <p className='mb-0' style={{"fontSize": "16px"}}>
                        <a href={openInboundSubmission.instagram_url} target="_blank" className='text-dark stretched-link'>
                          <strong>{openInboundSubmission.approx_instagram_followers || ''}</strong>
                        </a>
                      </p>
                      <p className='mb-0' style={{"fontSize": "10px", "marginTop": "-4px", "whiteSpace": "pre-line"}}>
                        {openInboundSubmission.approx_instagram_followers ? 'FOLLOWERS' : ''}
                      </p>
                    </div>
                  </div>
                ) : null}
                {openInboundSubmission.youtube ? (
                  <div className='col-12 d-flex align-items-center' style={{"marginTop": "17px"}}>
                    <div className='d-flex'>
                      <i className="fab fa-youtube fa-fw"
                        style={{
                          "color": "#FF0000",
                          "fontSize": "28px"
                        }}>
                      </i>
                    </div>
                    <div style={{"marginLeft": "7px"}}>
                      <p className='mb-0' style={{"fontSize": "16px"}}>
                        <a href={openInboundSubmission.youtube} target="_blank" className='text-dark stretched-link'>
                          <strong>{openInboundSubmission.approx_youtube_subscribers || ''}</strong>
                        </a>
                      </p>
                      {openInboundSubmission.approx_youtube_subscribers && (
                        <p className='mb-0' style={{"fontSize": "10px", "marginTop": "-4px", "whiteSpace": "pre-line"}}>
                          'SUBSCRIBERS'
                        </p>
                      )}
                    </div>
                  </div>
                ) : null}
                {openInboundSubmission.tiktok ? (
                  <div className='col-12 d-flex align-items-center' style={{"marginTop": "17px"}}>
                    <div className='d-flex'>
                      <i className="fab fa-tiktok fa-fw"
                        style={{
                          "color": "#000000",
                          "fontSize": "28px"
                        }}>
                      </i>
                    </div>
                    <div style={{"marginLeft": "7px"}}>
                      <p className='mb-0' style={{"fontSize": "16px"}}>
                        <a href={openInboundSubmission.tiktok} target="_blank" className='text-dark stretched-link'>
                          <strong>{openInboundSubmission.approx_tiktok_followers || ''}</strong>
                        </a>
                      </p>
                      {openInboundSubmission.approx_tiktok_followers && (
                        <p className='mb-0' style={{"fontSize": "10px", "marginTop": "-4px", "whiteSpace": "pre-line"}}>
                          'FOLLOWERS'
                        </p>
                      )}
                    </div>
                  </div>
                ) : null}
                {hasContactInfo(openInboundSubmission) ? (
                  <div className='col-12' style={{"marginTop": "17px"}}>
                    <p className='mb-0' style={{"fontSize": "16px"}}>
                      <strong>Contact Info</strong>
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.name && openInboundSubmission.name.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "10px"}}>
                    <p className='mb-0' style={{"fontSize": "10px", "color": "#808080"}}>
                      CONTACT NAME
                    </p>
                    <p className='mb-0' style={{"marginTop": "-1px"}}>
                      {openInboundSubmission.name}
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.email && openInboundSubmission.email.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "10px"}}>
                    <p className='mb-0' style={{"fontSize": "10px", "color": "#808080"}}>
                      EMAIL
                    </p>
                    <p className='mb-0' style={{"marginTop": "-1px"}}>
                      <a href={`mailto:${openInboundSubmission.email}`} className='text-dark'>
                        {openInboundSubmission.email}
                      </a>
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.phone && openInboundSubmission.phone.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "10px"}}>
                    <p className='mb-0' style={{"fontSize": "10px", "color": "#808080"}}>
                      PHONE
                    </p>
                    <p className='mb-0' style={{"marginTop": "-1px"}}>
                      <a href={`tel:${openInboundSubmission.phone}`} className='text-dark'>
                        {openInboundSubmission.formatted_phone_number}
                      </a>
                    </p>
                  </div>
                ) : null}
                {openInboundSubmission.location && openInboundSubmission.location.length > 0 ? (
                  <div className='col-12' style={{"marginTop": "10px"}}>
                    <p className='mb-0' style={{"fontSize": "10px", "color": "#808080"}}>
                      LOCATION
                    </p>
                    <p className='mb-0' style={{"marginTop": "-1px"}}>
                      {openInboundSubmission.location}
                    </p>
                  </div>
                ) : null}
                {hasMessage(openInboundSubmission) ? (
                  <React.Fragment>
                    <div className='col-12' style={{"marginTop": "17px"}}>
                      <p className='mb-0' style={{"fontSize": "16px"}}>
                        <strong>Message</strong>
                      </p>
                    </div>
                    <div className='col-12'>
                      <div className='card border-0 mb-0'
                            style={{
                              "background": "#f2f2f2",
                              "borderRadius": "4px",
                              "marginTop": "9px"
                            }}>
                        <div className='card-body'
                             style={{
                              "padding": "12px 15px"
                             }}>
                          <p className='mb-0' style={{"whiteSpace": "pre-wrap"}}>
                            {openInboundSubmission.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </React.Fragment>
      </SlidingPane>
      <Popup
        open={gettingFormLinks}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={true}
        className={"popup-modal"}
        onClose={() => gettingFormLinksChanged(false)}
      >
        <div className="popup-modal-container">
          <div className="row m-0"
                style={{
                  "padding": "16px 7px",
                  "borderBottom": "2px solid #f6f6f6"
                }}>
            <div className="col d-flex align-items-center">
              <p className="mb-0"
                style={{"fontSize": "20px"}}>
                <strong>Form Links</strong>
              </p>
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              <a href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    gettingFormLinksChanged(false)
                  }
                }
                style={{"fontSize": "24px"}}
                className="text-muted">
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <div className="m-0 px-4">
            {venueOwnerships.sort((a, b) => a.venueable.name.localeCompare(b.venueable.name)).map((venueOwnership, index) =>
              <div key={index}>
                <div
                  className="d-flex align-items-center py-4"
                  style={{"cursor": "pointer"}}
                  onClick={(e) => {
                    venueOwnershipsChanged(venueOwnerships.map((vo) => {
                      var open;

                      if (vo.id === venueOwnership.id) {
                        open = !vo.isViewingInboundFormLinks
                      } else {
                        open = false;
                      }

                      return {...vo, isViewingInboundFormLinks: open}
                    }));
                  }}
                >
                  <div className="mr-2">
                    {venueOwnership.logo_thumb_url ? (
                      <img src={venueOwnership.logo_thumb_url}
                        className="rounded-sm"
                        style={{ "width": "40px", "height": "40px" }}
                      />
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center rounded-sm"
                        style={{ "width": "40px", "height": "40px", "backgroundColor":"#f2f2f2" }}
                      >
                        <span className="strong" style={{"fontSize":"20px"}}>
                          {venueOwnership.venueable.name.charAt(0)}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <p className='mb-0' style={{"fontSize": "18px"}}>
                      {venueOwnership.venueable.name}
                    </p>
                  </div>
                  <div className="ml-auto">
                    <i
                      className={`far ${venueOwnership.isViewingInboundFormLinks ? 'fa-chevron-down' : 'fa-chevron-right'}`}
                      style={{"fontSize":"16px"}}
                    ></i>
                  </div>
                </div>
                <div className={`${venueOwnership.isViewingInboundFormLinks ? null : 'd-none'} pb-4 ml-5`}>
                  <p style={{
                        "fontSize": "16px",
                        "marginTop": "12px",
                        "marginBottom": "3px"
                      }}>
                    <strong>Signup Link</strong>
                  </p>
                  <pre style={{
                    "border": "1px solid #e0e0e0",
                    "background": "#ececec",
                    "resize": "none",
                    "borderRadius": "0px",
                    "fontSize": "14px",
                    "padding": "3px 14px",
                    "marginBottom": "7px"
                  }}>
                    <code>
                      {signupLink(venueOwnership, team)}
                    </code>
                  </pre>
                  <div className="text-right">
                    <CopyToClipboard text={signupLink(venueOwnership, team)}
                                    onCopy={
                                      () => {
                                        toast.success("Copied to clipboard", {
                                          position: toast.POSITION.TOP_CENTER,
                                          draggable: false,
                                          closeOnClick: false,
                                          autoClose: 5000,
                                          hideProgressBar: true
                                        });
                                      }
                                    }>
                      <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                          }
                        }
                        style={{
                          "borderWidth": "1px",
                          "borderRadius": "4px"
                        }}
                        className="btn btn-outline-primary small">
                        <strong>Copy Code</strong>
                      </a>
                    </CopyToClipboard>
                  </div>
                  <p style={{
                        "fontSize": "16px",
                        "marginTop": "7px",
                        "marginBottom": "3px"
                      }}>
                    <strong>Embed Code</strong>
                  </p>
                  <p style={{
                        "fontSize": "14px",
                        "marginTop": "2px",
                        "marginBottom": "5px"
                      }}>
                    Paste this snippet into your website to embed the form.
                  </p>
                  <pre style={{
                    "border": "1px solid #e0e0e0",
                    "background": "#ececec",
                    "resize": "none",
                    "borderRadius": "0px",
                    "fontSize": "14px",
                    "padding": "3px 14px",
                    "marginBottom": "7px"
                  }}>
                    <code>
                      {embedCode(venueOwnership, team)}
                    </code>
                  </pre>
                  <div className="text-right">
                    <CopyToClipboard text={embedCode(venueOwnership, team)}
                                    onCopy={
                                      () => {
                                        toast.success("Copied to clipboard", {
                                          position: toast.POSITION.TOP_CENTER,
                                          draggable: false,
                                          closeOnClick: false,
                                          autoClose: 5000,
                                          hideProgressBar: true
                                        });
                                      }
                                    }>
                      <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                          }
                        }
                        style={{
                          "borderWidth": "1px",
                          "borderRadius": "4px"
                        }}
                        className="btn btn-outline-primary small">
                        <strong>Copy Code</strong>
                      </a>
                    </CopyToClipboard>
                  </div>
                </div>
                {index < venueOwnerships.length - 1 ? (
                  <hr style={{
                    "borderTop": "1px solid #ececec",
                    "margin": "0"
                  }} />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </Popup>
    </div>
  )
};

InboundSubmissions.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  inboundSubmissionExists: PropTypes.bool,
  openInboundSubmission: PropTypes.object,
  openInboundSubmissionChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array,
  venueOwnershipsChanged: PropTypes.func.isRequired,
  gettingFormLinks: PropTypes.bool,
  gettingFormLinksChanged: PropTypes.func.isRequired,
  filters: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
  clearFilterValues: PropTypes.func.isRequired,
  debouncedUpdateFilter: PropTypes.func.isRequired,
  deleteInboundSubmission: PropTypes.func.isRequired,
  canViewArtist: PropTypes.bool,
  tagManagerOpen: PropTypes.bool,
  tagManagerOpenChanged: PropTypes.func.isRequired,
  tagManagerFilter: PropTypes.string,
  tagManagerFilterChanged: PropTypes.func.isRequired,
  getActiveTags: PropTypes.func.isRequired,
  getAvailableTags: PropTypes.func.isRequired,
  availableTags: PropTypes.array,
  availableTagsChanged: PropTypes.func.isRequired,
};

export default InboundSubmissions;
