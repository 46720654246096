import { combineReducers } from 'redux';

import {
  TEAM_MENU_OPEN_CHANGED
} from '../constants/userMenuConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const teamMemberships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const teamMenuOpen = (state = false, action) => {
  switch (action.type) {
    case TEAM_MENU_OPEN_CHANGED:
      return action.teamMenuOpen;
    default:
      return state;
  }
};

const userMenuReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  teamMemberships,
  teamMenuOpen
});

export default userMenuReducer;
