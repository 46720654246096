import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {DebounceInput} from 'react-debounce-input';

import RecursiveFolders from "./RecursiveFolders";
import FindFolderRow from "./FindFolderRow";

const SelectTargetFolder = ({
  csrfToken,
  team,
  confirm,
  fetchCalendarEventAttachment,
  currentUser,
  folder,
  selectedFolderedItems,
  togglefolderedItemSelected,
  stageFolderedItemsForDestroy,
  copiedFolderedItem,
  copiedFolderedItemChanged,
  copiedFolderedItemNameChanged,
  updateFolderedItemName,
  stageFolderedItemsForMove,
  fetchPermissionSet,
  folderedItemUpdated,
  targetFolder,
  targetFolderChanged,
  folderCopy,
  fetchFolderedItemsForFolderCopy,
  toggleFolderCopy,
  depthWidth,
  canSelectRootFolderAsTarget,
  targetFolderSearchTerm,
  targetFolderSearchTermChanged,
  context
}) => {
  return (
    <div className="row">
      <div className='col-6 d-flex align-items-center mb-2'>
        <div className="small">
          <p className='text-muted mb-0'>
            Select folder location
          </p>
        </div>
      </div>
      <div className='col-6 d-flex align-items-center justify-content-end mb-2'>
        <form style={{"zIndex": "2", "flexGrow": "1"}}>
          <div className="form-group mb-0">
            <div className="input-group mb-0">
              <div className="input-group-prepend">
                <span className="input-group-text bg-white"
                      style={{
                        "fontSize": "12px",
                        "border": "2px solid #f7f7f7",
                        "borderRight": "none"
                      }}>
                  <i className="far fa-search text-muted"></i>
                </span>
              </div>
              <DebounceInput type="text"
                            className="form-control pl-0"
                            minLength={2}
                            debounceTimeout={300}
                            value={targetFolderSearchTerm}
                            onChange={
                              (e) => {
                                targetFolderSearchTermChanged(e.target.value);
                              }
                            }
                            style={{
                              "fontSize": "12px",
                              "border": "2px solid #f7f7f7",
                              "borderLeft": "none"
                            }}
                            placeholder="Search folders..." />
            </div>
          </div>
        </form>
      </div>
      <div className="col-12">
        <div id="find-folder-container"
              style={{
                "height": "270px",
                "overflowY": "scroll",
                "overflowX": "hidden",
                "border": "1px solid #ececec",
                "padding": "0 15px"
              }}>
          {canSelectRootFolderAsTarget ? (
            <FindFolderRow csrfToken={csrfToken}
                            team={team}
                            currentUser={currentUser}
                            depth={0}
                            depthWidth={depthWidth}
                            toggleFolder={toggleFolderCopy}
                            targetFolder={targetFolder}
                            targetFolderChanged={targetFolderChanged}
                            context={context}
                            foldered={{
                              id: 0,
                              folder_id: undefined,
                              item_id: folder.id,
                              item_type: "Folder",
                              item: folder
                            }} />
          ) : null}
          <RecursiveFolders csrfToken={csrfToken}
                        team={team}
                        fetchCalendarEventAttachment={fetchCalendarEventAttachment}
                        currentUser={currentUser}
                        scrollableTarget={"find-folder-container"}
                        depth={(canSelectRootFolderAsTarget ? 1 : 0)}
                        toggleFolder={toggleFolderCopy}
                        selectedFolderedItems={selectedFolderedItems}
                        togglefolderedItemSelected={togglefolderedItemSelected}
                        stageFolderedItemsForDestroy={stageFolderedItemsForDestroy}
                        copiedFolderedItem={copiedFolderedItem}
                        copiedFolderedItemChanged={copiedFolderedItemChanged}
                        copiedFolderedItemNameChanged={copiedFolderedItemNameChanged}
                        updateFolderedItemName={updateFolderedItemName}
                        stageFolderedItemsForMove={stageFolderedItemsForMove}
                        rootFolder={folderCopy}
                        fetchPermissionSet={fetchPermissionSet}
                        folderedItemUpdated={folderedItemUpdated}
                        fetchFolderedItemsForFolder={fetchFolderedItemsForFolderCopy}
                        searchTerm={targetFolderSearchTerm}
                        parentFolderedItems={[]}
                        folder={folderCopy}>
            {(foldered, depth, rootFolder) => (
              !selectedFolderedItems.map((fi) => fi.id).includes(foldered.id) ? (
                <FindFolderRow csrfToken={csrfToken}
                  team={team}
                  currentUser={currentUser}
                  depth={depth}
                  depthWidth={depthWidth}
                  toggleFolder={toggleFolderCopy}
                  targetFolder={targetFolder}
                  targetFolderChanged={targetFolderChanged}
                  context={context}
                  foldered={foldered} />
              ) : null
            )}
          </RecursiveFolders>
        </div>
      </div>
    </div>
  );
};

SelectTargetFolder.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  fetchCalendarEventAttachment: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  folder: PropTypes.object.isRequired,
  selectedFolderedItems: PropTypes.array,
  togglefolderedItemSelected: PropTypes.func.isRequired,
  stageFolderedItemsForDestroy: PropTypes.func.isRequired,
  copiedFolderedItem: PropTypes.object,
  copiedFolderedItemChanged: PropTypes.func.isRequired,
  copiedFolderedItemNameChanged: PropTypes.func.isRequired,
  updateFolderedItemName: PropTypes.func.isRequired,
  stageFolderedItemsForMove: PropTypes.func.isRequired,
  fetchPermissionSet: PropTypes.func.isRequired,
  folderedItemUpdated: PropTypes.func.isRequired,
  targetFolder: PropTypes.object,
  targetFolderChanged: PropTypes.func.isRequired,
  folderCopy: PropTypes.object,
  fetchFolderedItemsForFolderCopy: PropTypes.func.isRequired,
  toggleFolderCopy: PropTypes.func.isRequired,
  depthWidth: PropTypes.func.isRequired,
  canSelectRootFolderAsTarget: PropTypes.bool,
  targetFolderSearchTerm: PropTypes.string,
  targetFolderSearchTermChanged: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired
};

export default SelectTargetFolder;
