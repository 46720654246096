import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import RecursiveFolders from "./RecursiveFolders";
import FolderedItemRow from "./FolderedItemRow";
import SelectTargetFolder from "./SelectTargetFolder";
import SetPermissionsPopup from "../../SetPermissionsPopup/components/SetPermissionsPopup";

import Popup from "reactjs-popup";
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import ContentEditable from 'react-contenteditable'

import {
  MOVE_TO_FOLDER_CONTEXT,
  NEW_FOLDER_CONTEXT,
  UPLOAD_FILES_CONTEXT,
  COPY_FILE_CONTEXT
} from '../constants/folderViewConstants';

const popupStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "20px"
}

const deletePopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "490px",
  "padding": "14px 16px"
}

const movePopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "560px",
  "padding": "25px"
}

const copyFilePopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "560px",
  "padding": "25px"
}

const newFolderPopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "560px",
  "padding": "25px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const calculateNumberOfFilesToDestroy = (folderedItemsToDestroy) => {
  return folderedItemsToDestroy
    .filter((fi) => !fi.item.is_folder)
    .length;
}

const calculateNumberOfFoldersToDestroy = (folderedItemsToDestroy) => {
  return folderedItemsToDestroy
    .filter((fi) => fi.item.is_folder)
    .length;
}

const canMoveStagedFolderedItems = (folderedItemsToMove, targetFolder) => {
  return (
    folderedItemsToMove.length > 0
      && targetFolder
      && targetFolder.id
  );
};

const canCreateNewFolder = (targetFolder, newFolderName) => {
  return (
    targetFolder
      && targetFolder.id
      && newFolderName.length > 0
  );
};

const canUploadFiles = (targetFolder, selectedFilesToUpload, isLoadingUploadedFiles) => {
  return (
    targetFolder
      && targetFolder.id
      && selectedFilesToUpload.length > 0
      && !isLoadingUploadedFiles
  );
};

const canCopyCalendarEventAttachment = (targetFolder, calendarEventAttachmentToCopy, isCopyingCalendarEventAttachment) => {
  return (
    targetFolder
      && targetFolder.id
      && calendarEventAttachmentToCopy
      && calendarEventAttachmentToCopy.human_file_name
      && calendarEventAttachmentToCopy.human_file_name.length > 0
      && !isCopyingCalendarEventAttachment
  );
};

const depthWidth = (depth) => {
  var firstGutter = 49;

  switch(depth) {
    case 0:
      return 0;
      break;
    case 1:
      return firstGutter;
      break;
    default:
      var depthAfterFirst = (depth - 1);
      var incrementSpace  = 28;

      return firstGutter + (incrementSpace * depthAfterFirst);
  }
}

const FolderView = ({
  csrfToken,
  team,
  confirm,
  openCalendarEventAttachment,
  closeCalendarEventAttachmentModal,
  deleteCalendarEventAttachment,
  fetchCalendarEventAttachment,
  openCalendarEventAttachmentPdfNumberOfPages,
  openCalendarEventAttachmentPdfNumberOfPagesChanged,
  editOpenCalendarEventAttachmentName,
  updateCalendarEventAttachment,
  setOpenCalendarEventAttachmentNameEl,
  endEditOpenCalendarEventAttachmentName,
  currentUser,
  folder,
  createFolder,
  toggleFolder,
  selectedFolderedItems,
  togglefolderedItemSelected,
  folderedItemsToDestroy,
  stageFolderedItemsForDestroy,
  deleteStagedFolderedItems,
  copiedFolderedItem,
  copiedFolderedItemChanged,
  copiedFolderedItemNameChanged,
  updateFolderedItemName,
  folderedItemsToMove,
  stageFolderedItemsForMove,
  moveStagedFolderedItems,
  fetchPermissionSet,
  folderedItemUpdated,
  fetchFolderedItemsForFolder,
  targetFolder,
  targetFolderChanged,
  folderCopy,
  folderCopyChanged,
  fetchFolderedItemsForFolderCopy,
  toggleFolderCopy,
  onFolderChanged,
  permissionSet,
  permissionSetChanged,
  permissionRuleEnabledClicked,
  selectAllPermissionRulesClicked,
  submitPermissionSet,
  onPermissionSetSaved,
  guestTeamMemberships,
  promotersWithPermission,
  headerComponent,
  isAddingFolder,
  isAddingFolderChanged,
  newFolderName,
  newFolderNameChanged,
  isUploadingFiles,
  isUploadingFilesChanged,
  selectedFilesToUpload,
  selectedFilesToUploadChanged,
  uploadFilesToFolder,
  isLoadingUploadedFiles,
  canSelectRootFolderAsTarget,
  fetchGuestsAndPromotersForPermissions,
  searchTerm,
  targetFolderSearchTerm,
  targetFolderSearchTermChanged,
  calendarEventAttachmentToCopy,
  calendarEventAttachmentToCopyChanged,
  createCalendarEventAttachmentCopy,
  isCopyingCalendarEventAttachment,
  teamRootFolder,
  draggedFilesToUpload,
  draggedFilesToUploadChanged,
  showConfirmIdColumn,
  folderChanged
}) => {
  var numberOfFilesToDestroy = calculateNumberOfFilesToDestroy(folderedItemsToDestroy);
  var numberOfFoldersToDestroy = calculateNumberOfFoldersToDestroy(folderedItemsToDestroy);

  const [sortBy, setSortBy] = useState({});
  const fileInputRef = useRef();

  return (
    <div>
      <div className="card-body">
        <div className={"row"}>
          <div className={"col d-flex align-items-center " + (folder.calendar_event_attachments && folder.calendar_event_attachments.length > 0 ? "pb-4" : "pb-3")}>
            {headerComponent()}
          </div>
          {currentUser.can_manage_calendar_event_attachments ? (
            <div className={"col-xs-auto pb-3"}
                 style={{"paddingRight": "15px"}}>
              {selectedFolderedItems && selectedFolderedItems.length > 0 ? (
                <React.Fragment>
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         stageFolderedItemsForDestroy(selectedFolderedItems);
                       }
                     }
                     className="text-danger">
                    <i className="fas fa-trash"></i>
                    <strong className="ml-2">Delete</strong>
                  </a>
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         stageFolderedItemsForMove(selectedFolderedItems);
                       }
                     }
                     className="text-primary ml-3">
                    <i className="fas fa-arrows-alt"></i>
                    <strong className="ml-2">Move</strong>
                  </a>
                </React.Fragment>
              ) : null}
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     isAddingFolderChanged(true);
                   }
                 }
                 className="btn btn-external btn-sm ml-3">
                <i className="fas fa-folder-plus"></i>
                &nbsp;
                <strong>New Folder</strong>
              </a>
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     isUploadingFilesChanged(true);
                   }
                 }
                 className="btn btn-external btn-sm ml-2">
                <i className="fas fa-cloud-upload"></i>
                &nbsp;
                <strong>Upload Files</strong>
              </a>
            </div>
          ) : null}
        </div>
        <div className='row' style={{"marginBottom": "170px"}}>
          <div className='col-12'>
            <div className="row m-0">
              <div className="col-12 p-0 d-flex align-items-center small"
                  style={{"borderBottom": "1px solid #dadada"}}>
                {showConfirmIdColumn ? (
                  <>
                    <div style={{"width": "40%", "padding": "12px 12px 12px 32px"}}>
                      <a onClick={
                            (e) => {
                              e.preventDefault();

                              if(Object.keys(sortBy).length === 0 || sortBy.column !== "item_name"){
                                setSortBy({
                                  column: "item_name",
                                  direction: "asc"
                                });
                              } else if(sortBy.column === "item_name" && sortBy.direction === "asc"){
                                setSortBy({
                                  column: "item_name",
                                  direction: "desc"
                                });
                              } else if(sortBy.column === "item_name" && sortBy.direction === "desc"){
                                setSortBy({});
                              }

                              var updated = Object.assign({}, folder, {
                                isLoadingPaginatedData: undefined,
                                lastRequestData: undefined,
                                foldered_items: []
                              });

                              folderChanged(updated);
                            }
                          }
                          className="text-dark"
                          href="#">
                        <strong className='mr-1'>
                          Name
                        </strong>
                        {sortBy.column === "item_name" && sortBy.direction === "asc" ? (
                          <i className="fas fa-sort-up"></i>
                        ) : sortBy.column === "item_name" && sortBy.direction === "desc" ? (
                          <i className="fas fa-sort-down"></i>
                        ) : (
                          <i className="fas fa-sort"></i>
                        )}
                      </a>
                    </div>
                    <div style={{"width": "10%", "padding": "12px"}}>
                      <a onClick={
                            (e) => {
                              e.preventDefault();

                              if(Object.keys(sortBy).length === 0 || sortBy.column !== "calendar_event_id"){
                                setSortBy({
                                  column: "calendar_event_id",
                                  direction: "asc"
                                });
                              } else if(sortBy.column === "calendar_event_id" && sortBy.direction === "asc"){
                                setSortBy({
                                  column: "calendar_event_id",
                                  direction: "desc"
                                });
                              } else if(sortBy.column === "calendar_event_id" && sortBy.direction === "desc"){
                                setSortBy({});
                              }

                              var updated = Object.assign({}, folder, {
                                isLoadingPaginatedData: undefined,
                                lastRequestData: undefined,
                                foldered_items: []
                              });

                              folderChanged(updated);
                            }
                          }
                          className="text-dark"
                          href="#">
                        <strong className='mr-1'>
                          Event ID
                        </strong>
                        {sortBy.column === "calendar_event_id" && sortBy.direction === "asc" ? (
                          <i className="fas fa-sort-up"></i>
                        ) : sortBy.column === "calendar_event_id" && sortBy.direction === "desc" ? (
                          <i className="fas fa-sort-down"></i>
                        ) : (
                          <i className="fas fa-sort"></i>
                        )}
                      </a>
                    </div>
                    <div style={{"width": "15%", "padding": "12px"}}>
                      <strong>Date Modified</strong>
                    </div>
                    <div style={{"width": "15%", "padding": "12px"}}>
                      <strong>Size</strong>
                    </div>
                    <div style={{"width": "15%", "padding": "12px"}}>
                      <strong>Type</strong>
                    </div>
                    <div style={{"width": "5%", "padding": "12px 0px 12px 12px"}}>
                      &nbsp;
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{"width": "45%", "padding": "12px 12px 12px 32px"}}>
                      <strong>Name</strong>
                    </div>
                    <div style={{"width": "20%", "padding": "12px"}}>
                      <strong>Date Modified</strong>
                    </div>
                    <div style={{"width": "15%", "padding": "12px"}}>
                      <strong>Size</strong>
                    </div>
                    <div style={{"width": "15%", "padding": "12px"}}>
                      <strong>Type</strong>
                    </div>
                    <div style={{"width": "5%", "padding": "12px 0px 12px 12px"}}>
                      &nbsp;
                    </div>
                  </>
                )}
              </div>
            </div>
            <RecursiveFolders csrfToken={csrfToken}
                          team={team}
                          fetchCalendarEventAttachment={fetchCalendarEventAttachment}
                          currentUser={currentUser}
                          depth={0}
                          toggleFolder={toggleFolder}
                          selectedFolderedItems={selectedFolderedItems}
                          togglefolderedItemSelected={togglefolderedItemSelected}
                          stageFolderedItemsForDestroy={stageFolderedItemsForDestroy}
                          copiedFolderedItem={copiedFolderedItem}
                          copiedFolderedItemChanged={copiedFolderedItemChanged}
                          copiedFolderedItemNameChanged={copiedFolderedItemNameChanged}
                          updateFolderedItemName={updateFolderedItemName}
                          stageFolderedItemsForMove={stageFolderedItemsForMove}
                          rootFolder={folder}
                          fetchPermissionSet={fetchPermissionSet}
                          folderedItemUpdated={folderedItemUpdated}
                          fetchFolderedItemsForFolder={fetchFolderedItemsForFolder}
                          parentFolderedItems={[]}
                          searchTerm={searchTerm}
                          sortBy={sortBy}
                          folder={folder}>
              {(foldered, depth, rootFolder, parentFolderedItems) => (
                <FolderedItemRow csrfToken={csrfToken}
                    team={team}
                    fetchCalendarEventAttachment={fetchCalendarEventAttachment}
                    currentUser={currentUser}
                    folder={folder}
                    depth={depth}
                    depthWidth={depthWidth}
                    toggleFolder={toggleFolder}
                    selectedFolderedItems={selectedFolderedItems}
                    togglefolderedItemSelected={togglefolderedItemSelected}
                    stageFolderedItemsForDestroy={stageFolderedItemsForDestroy}
                    copiedFolderedItem={copiedFolderedItem}
                    copiedFolderedItemChanged={copiedFolderedItemChanged}
                    copiedFolderedItemNameChanged={copiedFolderedItemNameChanged}
                    updateFolderedItemName={updateFolderedItemName}
                    stageFolderedItemsForMove={stageFolderedItemsForMove}
                    rootFolder={rootFolder}
                    fetchGuestsAndPromotersForPermissions={fetchGuestsAndPromotersForPermissions}
                    fetchPermissionSet={fetchPermissionSet}
                    folderedItemUpdated={folderedItemUpdated}
                    parentFolderedItems={parentFolderedItems}
                    calendarEventAttachmentToCopyChanged={calendarEventAttachmentToCopyChanged}
                    uploadFilesToFolder={uploadFilesToFolder}
                    showConfirmIdColumn={showConfirmIdColumn}
                    foldered={foldered} />
              )}
            </RecursiveFolders>
            {folder.lastRequestData && folder.foldered_items.length === 0 ? (
              searchTerm.length > 0 && folder.foldered_items_count > 0 ? (
                <div className="row" style={{"marginTop": "44px"}}>
                  <div className="col-12 text-center">
                    <div className='dot d-flex justify-content-center align-items-center'
                          style={{'margin': '0px auto 10px auto', 'width': '88px', 'height': '88px', 'background': '#F3F9FC', 'borderRadius': '50%', 'border': '4px solid #BBE0F6'}}>
                      <i className='far fa-search text-primary'
                        style={{'fontSize': '42px'}}></i>
                    </div>
                    <p className='mb-2'
                      style={{"fontSize": "20px"}}>
                      <strong>No search results</strong>
                    </p>
                    <p className='mb-0 text-muted'
                        style={{
                          "fontSize": "14px",
                          "margin": "0px auto"
                        }}>
                      No document or folder names match your search
                    </p>
                  </div>
                </div>
              ) : (
                <div className="row" style={{"marginTop": "44px"}}>
                  <div className="col-12 text-center">
                    <div className='dot d-flex justify-content-center align-items-center'
                          style={{'margin': '0px auto 10px auto', 'width': '88px', 'height': '88px', 'background': '#F3F9FC', 'borderRadius': '50%', 'border': '4px solid #BBE0F6'}}>
                      <i className='fas fa-folder-open text-primary'
                        style={{'fontSize': '42px', "marginLeft": "5px"}}></i>
                    </div>
                    <p className='mb-2'
                      style={{"fontSize": "20px"}}>
                      <strong>Nothing here yet!</strong>
                    </p>
                    <p className='mb-0 text-muted'
                        style={{
                          "maxWidth": "284px",
                          "fontSize": "14px",
                          "margin": "0px auto"
                        }}>
                      Create folders and add files to manage your teams assets.
                    </p>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
      <Popup
        open={draggedFilesToUpload.length > 0}
        contentStyle={movePopupContentStyle}
        closeOnDocumentClick={false}
      >
        <div>
          <div className="row">
            <div className="col">
              <h5 className="mb-0">
                <strong>
                  <i className="fas fa-file-plus text-primary mr-2"></i>
                  Uploading Files...
                </strong>
              </h5>
            </div>
            <div className="col">
              <img src="/uploading-loading.gif" className="float-right" style={{width: "20px"}} />
            </div>
          </div>
          <div
            className="mt-4"
            style={{
              "width": "100%",
              "maxHeight": "270px",
              "overflowY": "scroll",
              "border": "1px solid #ececec",
              "padding": "0 15px"
            }}
          >
            {draggedFilesToUpload.map((file, index) => (
              <span key={file.name} className="my-2 d-block">{file.name}</span>
            ))}
          </div>
        </div>
      </Popup>
      <Popup
        open={(Object.keys(openCalendarEventAttachment).length > 0)}
        contentStyle={popupStyle}
        closeOnDocumentClick
        className={"popup-modal"}
        onClose={() => closeCalendarEventAttachmentModal()}
      >
        <div className="popup-modal-container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col">
                  <h5 className="pt-2">
                    <strong>
                      <i className={"text-primary fas " + openCalendarEventAttachment.fontawesome_icon_class}></i>
                      &nbsp;
                      {openCalendarEventAttachment.editName ? (
                        <ContentEditable
                          html={openCalendarEventAttachment.human_file_name}
                          innerRef={
                            (el) => {
                              if(el && !openCalendarEventAttachment.nameSpanEl){
                                setOpenCalendarEventAttachmentNameEl(el);
                                el.focus();

                                setTimeout(() => {
                                  var selection = window.getSelection();
                                  var range = document.createRange();
                                  range.selectNodeContents(el);
                                  selection.removeAllRanges();
                                  selection.addRange(range);
                                }, 1)
                              }
                            }
                          }
                          className={"single-line"}
                          onKeyDown={
                            (e) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();

                                var newName = openCalendarEventAttachment.nameSpanEl.textContent;
                                updateCalendarEventAttachment(csrfToken, team, openCalendarEventAttachment, newName);
                              }
                            }
                          }
                          onBlur={
                            (e) => {
                              endEditOpenCalendarEventAttachmentName();
                            }
                          }
                          tagName='span'
                        />
                      ) : (
                        <span>{openCalendarEventAttachment.human_file_name}</span>
                      )}
                      {currentUser.can_manage_calendar_event_attachments && !openCalendarEventAttachment.editName ? (
                        <span>
                          &nbsp;
                          &nbsp;
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 editOpenCalendarEventAttachmentName();
                               }
                             }
                             className="text-muted small">
                            <i className="fas fa-pencil"></i>
                          </a>
                        </span>
                      ) : null}
                    </strong>
                  </h5>
                </div>
                <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
                  {currentUser.can_manage_calendar_event_attachments ? (
                    <Popup
                        trigger={
                          <a href="#"
                             className="btn btn-muted hover-with-text-danger">
                            <i className="fas fa-trash"></i>
                          </a>
                        }
                        onOpen={
                          (e) => {
                            e.preventDefault();
                          }
                        }
                        modal
                        contentStyle={deleteModalContentStyle}
                        closeOnDocumentClick
                      >
                      {close => (
                        <div>
                          <div className="row">
                            <div className="col">
                              <div className="row">
                                <div className="col">
                                  <h5 style={{"marginBottom": "16px"}}>
                                    <strong>Delete asset?</strong>
                                  </h5>
                                </div>
                                <div className="col-xs-auto">
                                  <a href="#"
                                     className="text-dark"
                                     style={{"paddingRight": "15px"}}
                                     onClick={(e) => {e.preventDefault(); close();}} >
                                    <i className="fa fa-times"></i>
                                  </a>
                                </div>
                              </div>
                              <p>Are you sure you want to remove this asset?</p>
                              <div className="text-right">
                                <a href="#"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();
                                       close();
                                     }
                                   }>
                                  Cancel
                                </a>
                                <a href="#"
                                   className="btn btn-danger ml-3"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();
                                       close();
                                       closeCalendarEventAttachmentModal();
                                       deleteCalendarEventAttachment(csrfToken, team, openCalendarEventAttachment);
                                     }
                                   }>
                                  Remove
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Popup>
                  ) : null}
                  <a href={"/teams/" + team.id + "/calendar_event_attachments/" + openCalendarEventAttachment.id + "/download"}
                     className="btn btn-muted ml-3">
                    <i className="fas fa-download"></i>
                  </a>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-12">
                  <strong>Details</strong>
                  <div className="row pt-2">
                    <div className="col-12 col-md-6 col-lg-4">
                      <table className="table table-borderless table-sm mb-0" style={{"fontSize": "10px", tableLayout: "fixed"}}>
                        <tbody>
                          <tr>
                            <td className="p-0">
                              <span className="text-muted">
                                Uploaded by
                              </span>
                            </td>
                            <td className="p-0">
                              {openCalendarEventAttachment.team_membership ? (
                                openCalendarEventAttachment.team_membership.name
                              ) : (
                                <span>SYSTEM</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0">
                              <span className="text-muted">
                                Created
                              </span>
                            </td>
                            <td className="p-0">
                              {openCalendarEventAttachment.human_created_at}
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0">
                              <span className="text-muted">
                                Modified
                              </span>
                            </td>
                            <td className="p-0">
                              {openCalendarEventAttachment.human_updated_at}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <table className="table table-borderless table-sm mb-0" style={{"fontSize": "10px", tableLayout: "fixed"}}>
                        <tbody>
                          <tr>
                            <td className="p-0">
                              <span className="text-muted">
                                Type
                              </span>
                            </td>
                            <td className="p-0">
                              {openCalendarEventAttachment.human_extension}
                            </td>
                          </tr>
                          {openCalendarEventAttachment.is_image ? (
                            <tr>
                              <td className="p-0">
                                <span className="text-muted">
                                  Dimensions
                                </span>
                              </td>
                              <td className="p-0">
                                {openCalendarEventAttachment.human_dimensions}
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td className="p-0">
                              <span className="text-muted">
                                Size
                              </span>
                            </td>
                            <td className="p-0">
                              {openCalendarEventAttachment.human_file_size}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-12 text-center">
                  {openCalendarEventAttachment.is_image ? (
                    <img src={openCalendarEventAttachment.resolved_file_url} className="img-fluid" />
                  ) : openCalendarEventAttachment.is_pdf ? (
                    <Document file={openCalendarEventAttachment.download_url}
                              onLoadSuccess={({ numPages }) => {
                                openCalendarEventAttachmentPdfNumberOfPagesChanged(numPages);
                              }}>
                      {
                        Array.from(
                          new Array(openCalendarEventAttachmentPdfNumberOfPages),
                          (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          ),
                        )
                      }
                    </Document>
                  ) : (
                    <div className="py-4">
                      <i className={"text-primary fal pb-2 " + openCalendarEventAttachment.fontawesome_icon_class}
                         style={{"fontSize": "120px"}}></i>
                      <p className="small mb-0">Preview not available.</p>
                      <p className="small mb-0">Download file to view.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={folderedItemsToDestroy.length > 0}
        contentStyle={deletePopupContentStyle}
        closeOnDocumentClick={false}
        className={""}
        onClose={() => {
            stageFolderedItemsForDestroy([]);
          }
        }
      >
        <div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <h5 style={{"marginBottom": "16px"}}>
                    {numberOfFilesToDestroy === 1 && numberOfFoldersToDestroy === 0 ? (
                      <strong>Delete file?</strong>
                    ) : numberOfFilesToDestroy === 0 && numberOfFoldersToDestroy === 1 ? (
                      <strong>Delete folder?</strong>
                    ) : (
                      <strong>Delete files & folders?</strong>
                    )}
                  </h5>
                </div>
                <div className="col-xs-auto">
                  <a href="#"
                     className="text-dark"
                     style={{"paddingRight": "15px", "fontSize": "22px"}}
                     onClick={
                       (e) => {
                         e.preventDefault();
                         stageFolderedItemsForDestroy([]);
                       }
                      } >
                    <i className="far fa-times"></i>
                  </a>
                </div>
              </div>
              {numberOfFilesToDestroy === 1 && numberOfFoldersToDestroy === 0 ? (
                <p>You're about to delete this file and its contents.</p>
              ) : numberOfFilesToDestroy === 0 && numberOfFoldersToDestroy === 1 ? (
                <p>You're about to delete this folder and its contents.</p>
              ) : (
                <p>You're about to delete {numberOfFilesToDestroy} file(s) and {numberOfFoldersToDestroy} folder(s).</p>
              )}
              <p>This cannot be undone.</p>
              <p>Are you sure?</p>
              <div className="text-right">
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       stageFolderedItemsForDestroy([]);
                     }
                   }>
                  Cancel
                </a>
                <a href="#"
                   className="btn btn-danger ml-3"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       deleteStagedFolderedItems(csrfToken, team, folderedItemsToDestroy);
                     }
                   }>
                  {numberOfFilesToDestroy === 1 && numberOfFoldersToDestroy === 0 ? (
                    "Yes, delete file"
                  ) : numberOfFilesToDestroy === 0 && numberOfFoldersToDestroy === 1 ? (
                    "Yes, delete folder"
                  ) : (
                    "Yes, delete files & folders"
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={folderedItemsToMove.length > 0}
        contentStyle={movePopupContentStyle}
        closeOnDocumentClick={false}
        className={""}
        onOpen={() => {
          var updated = Object.assign({}, teamRootFolder, {
            lastRequestData: undefined,
            foldered_items: []
          });

          folderCopyChanged(updated);
        }}
        onClose={() => {
            targetFolderChanged({});
            stageFolderedItemsForMove([]);
            folderCopyChanged({});
            targetFolderSearchTermChanged("");
          }
        }
      >
        <div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col d-flex align-items-center">
                  <h5 className="mb-0">
                    <strong>
                      <i className="fas fa-arrows-alt text-primary mr-2"></i>
                      Move to...
                    </strong>
                  </h5>
                </div>
                <div className="col-xs-auto d-flex align-items-center">
                  <a href="#"
                     className="text-dark"
                     style={{"paddingRight": "15px", "fontSize": "22px"}}
                     onClick={
                       (e) => {
                         e.preventDefault();
                         stageFolderedItemsForMove([], folder);
                       }
                      } >
                    <i className="far fa-times"></i>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SelectTargetFolder csrfToken={csrfToken}
                                      team={team}
                                      confirm={confirm}
                                      fetchCalendarEventAttachment={fetchCalendarEventAttachment}
                                      currentUser={currentUser}
                                      folder={folder}
                                      selectedFolderedItems={folderedItemsToMove}
                                      togglefolderedItemSelected={togglefolderedItemSelected}
                                      stageFolderedItemsForDestroy={stageFolderedItemsForDestroy}
                                      copiedFolderedItem={copiedFolderedItem}
                                      copiedFolderedItemChanged={copiedFolderedItemChanged}
                                      copiedFolderedItemNameChanged={copiedFolderedItemNameChanged}
                                      updateFolderedItemName={updateFolderedItemName}
                                      stageFolderedItemsForMove={stageFolderedItemsForMove}
                                      fetchPermissionSet={fetchPermissionSet}
                                      folderedItemUpdated={folderedItemUpdated}
                                      targetFolder={targetFolder}
                                      targetFolderChanged={targetFolderChanged}
                                      folderCopy={folderCopy}
                                      fetchFolderedItemsForFolderCopy={fetchFolderedItemsForFolderCopy}
                                      depthWidth={depthWidth}
                                      context={MOVE_TO_FOLDER_CONTEXT}
                                      targetFolderSearchTerm={targetFolderSearchTerm}
                                      targetFolderSearchTermChanged={targetFolderSearchTermChanged}
                                      canSelectRootFolderAsTarget={false}
                                      toggleFolderCopy={toggleFolderCopy} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(canMoveStagedFolderedItems(folderedItemsToMove, targetFolder)){
                        moveStagedFolderedItems(csrfToken, team, folderedItemsToMove, targetFolder);
                      }
                    }
                  }>
                    <div className="form-row mt-3">
                      <div className="col text-right">
                        <a href="#"
                           onClick={
                             (e) => {
                               e.preventDefault();
                               stageFolderedItemsForMove([]);
                             }
                           }>
                          <strong>
                            Cancel
                          </strong>
                        </a>
                        <button type="submit"
                                disabled={!canMoveStagedFolderedItems(folderedItemsToMove, targetFolder)}
                                className="btn btn-primary ml-3">
                          <strong>Move</strong>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={isAddingFolder}
        contentStyle={newFolderPopupContentStyle}
        closeOnDocumentClick={false}
        className={""}
        onOpen={() => {
          var updated = Object.assign({}, folder, {
            lastRequestData: undefined,
            foldered_items: []
          });

          folderCopyChanged(updated);
          newFolderNameChanged("New Folder");
        }}
        onClose={
          () => {
            targetFolderChanged({});
            isAddingFolderChanged(false);
            newFolderNameChanged("");
            targetFolderSearchTermChanged("");
          }
        }
      >
        <div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col d-flex align-items-center">
                  <h5 className="mb-0">
                    <strong>
                      <i className="fas fa-folder-plus text-primary mr-2"></i>
                      New Folder
                    </strong>
                  </h5>
                </div>
                <div className="col-xs-auto d-flex align-items-center">
                  <a href="#"
                     className="text-dark"
                     style={{"paddingRight": "15px", "fontSize": "22px"}}
                     onClick={
                       (e) => {
                         e.preventDefault();
                         isAddingFolderChanged(false);
                       }
                      } >
                    <i className="far fa-times"></i>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SelectTargetFolder csrfToken={csrfToken}
                                      team={team}
                                      confirm={confirm}
                                      fetchCalendarEventAttachment={fetchCalendarEventAttachment}
                                      currentUser={currentUser}
                                      folder={folder}
                                      selectedFolderedItems={folderedItemsToMove}
                                      togglefolderedItemSelected={togglefolderedItemSelected}
                                      stageFolderedItemsForDestroy={stageFolderedItemsForDestroy}
                                      copiedFolderedItem={copiedFolderedItem}
                                      copiedFolderedItemChanged={copiedFolderedItemChanged}
                                      copiedFolderedItemNameChanged={copiedFolderedItemNameChanged}
                                      updateFolderedItemName={updateFolderedItemName}
                                      stageFolderedItemsForMove={stageFolderedItemsForMove}
                                      fetchPermissionSet={fetchPermissionSet}
                                      folderedItemUpdated={folderedItemUpdated}
                                      targetFolder={targetFolder}
                                      targetFolderChanged={targetFolderChanged}
                                      folderCopy={folderCopy}
                                      fetchFolderedItemsForFolderCopy={fetchFolderedItemsForFolderCopy}
                                      depthWidth={depthWidth}
                                      context={NEW_FOLDER_CONTEXT}
                                      targetFolderSearchTerm={targetFolderSearchTerm}
                                      targetFolderSearchTermChanged={targetFolderSearchTermChanged}
                                      canSelectRootFolderAsTarget={canSelectRootFolderAsTarget}
                                      toggleFolderCopy={toggleFolderCopy} />
                </div>
              </div>
              <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(canCreateNewFolder(targetFolder, newFolderName)){
                        createFolder(csrfToken, team, confirm, targetFolder, newFolderName);
                      }
                    }
                  }>
                <div className="row mt-1">
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-start">
                    <div className="form-group mb-0"
                         style={{"flexGrow": "1"}}>
                      <label htmlFor="new-folder-name"
                            style={{"fontSize": "10px"}}
                            className='mb-0 text-muted small'>
                        Folder Name
                      </label>
                      <input type="text"
                            className="form-control form-control-lg"
                            style={{"fontSize": "14px", "border": "1px solid #eff0f0"}}
                            id="new-folder-name"
                            value={newFolderName}
                            onChange={
                              (e) => {
                                newFolderNameChanged(e.target.value);
                              }
                            } />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div className="form-row mt-3">
                      <div className="col text-right">
                        <a href="#"
                          onClick={
                            (e) => {
                              e.preventDefault();
                              isAddingFolderChanged(false);
                            }
                          }>
                          <strong>
                            Cancel
                          </strong>
                        </a>
                        <button type="submit"
                                disabled={!canCreateNewFolder(targetFolder, newFolderName)}
                                className="btn btn-primary ml-3">
                          <strong>Create</strong>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={isUploadingFiles}
        contentStyle={movePopupContentStyle}
        closeOnDocumentClick={false}
        className={""}
        onOpen={() => {
          var updated = Object.assign({}, folder, {
            lastRequestData: undefined,
            foldered_items: []
          });

          folderCopyChanged(updated);
          selectedFilesToUploadChanged({});
        }}
        onClose={() => {
            targetFolderChanged({});
            isUploadingFilesChanged(false);
            selectedFilesToUploadChanged({});
            targetFolderSearchTermChanged("");
          }
        }
      >
        <div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col d-flex align-items-center">
                  <h5 className="mb-0">
                    <strong>
                      <i className="fas fa-file-plus text-primary mr-2"></i>
                      Upload Files
                    </strong>
                  </h5>
                </div>
                <div className="col-xs-auto d-flex align-items-center">
                  <a href="#"
                     className="text-dark"
                     style={{"paddingRight": "15px", "fontSize": "22px"}}
                     onClick={
                       (e) => {
                         e.preventDefault();
                         isUploadingFilesChanged(false);
                       }
                      } >
                    <i className="far fa-times"></i>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SelectTargetFolder csrfToken={csrfToken}
                                      team={team}
                                      confirm={confirm}
                                      fetchCalendarEventAttachment={fetchCalendarEventAttachment}
                                      currentUser={currentUser}
                                      folder={folder}
                                      selectedFolderedItems={folderedItemsToMove}
                                      togglefolderedItemSelected={togglefolderedItemSelected}
                                      stageFolderedItemsForDestroy={stageFolderedItemsForDestroy}
                                      copiedFolderedItem={copiedFolderedItem}
                                      copiedFolderedItemChanged={copiedFolderedItemChanged}
                                      copiedFolderedItemNameChanged={copiedFolderedItemNameChanged}
                                      updateFolderedItemName={updateFolderedItemName}
                                      stageFolderedItemsForMove={stageFolderedItemsForMove}
                                      fetchPermissionSet={fetchPermissionSet}
                                      folderedItemUpdated={folderedItemUpdated}
                                      targetFolder={targetFolder}
                                      targetFolderChanged={targetFolderChanged}
                                      folderCopy={folderCopy}
                                      fetchFolderedItemsForFolderCopy={fetchFolderedItemsForFolderCopy}
                                      depthWidth={depthWidth}
                                      context={UPLOAD_FILES_CONTEXT}
                                      targetFolderSearchTerm={targetFolderSearchTerm}
                                      targetFolderSearchTermChanged={targetFolderSearchTermChanged}
                                      canSelectRootFolderAsTarget={canSelectRootFolderAsTarget}
                                      toggleFolderCopy={toggleFolderCopy} />
                </div>
              </div>
              <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(canUploadFiles(targetFolder, selectedFilesToUpload, isLoadingUploadedFiles)){
                        uploadFilesToFolder(csrfToken, team, confirm, selectedFilesToUpload, targetFolder);
                      }
                    }
                  }>
                <div className="row mt-3">
                  <div className='col-12 d-flex'>
                    <div>
                      <input type="file"
                            ref={fileInputRef}
                            onChange={
                              (e) => {
                                selectedFilesToUploadChanged(e.target.files);
                              }
                            }
                            style={{ display: "none" }}
                            multiple={true} />
                      <a href="#"
                        className='btn btn-primary'
                        style={{"fontSize": "14px"}}
                        onClick={
                            (e) => {
                              e.preventDefault();
                              fileInputRef.current.click();
                            }
                        }>
                        <strong>Select File(s)</strong>
                      </a>
                    </div>
                    <div style={{
                            "flexGrow": "1",
                            "padding": "0px 15px",
                            "maxHeight": "47px",
                            "overflowY": "auto"
                          }}>
                      {selectedFilesToUpload.length > 0 && (
                        Array.from(selectedFilesToUpload).map((file, index) => (
                          <div key={index}
                                className='text-muted small text-truncate'
                                style={{"maxWidth": "200px"}}>
                            {file.name}
                          </div>
                        ))
                      )}
                    </div>
                    <div>
                      {!isLoadingUploadedFiles ? (
                        <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                isUploadingFilesChanged(false);
                              }
                            }>
                          <strong>
                            Cancel
                          </strong>
                        </a>
                      ) : null}
                      <button type="submit"
                              disabled={!canUploadFiles(targetFolder, selectedFilesToUpload, isLoadingUploadedFiles)}
                              className="btn btn-primary ml-3">
                        {isLoadingUploadedFiles ? (
                          <React.Fragment>
                            <img src="/uploading-loading.gif"
                                  className="mr-1"
                                  style={{width: "16px"}} />
                            <strong>Uploading...</strong>
                          </React.Fragment>
                        ) : (
                          <strong>Upload</strong>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={Object.keys(calendarEventAttachmentToCopy).length > 0}
        contentStyle={copyFilePopupContentStyle}
        closeOnDocumentClick={false}
        className={""}
        onOpen={() => {
          var updated = Object.assign({}, teamRootFolder, {
            lastRequestData: undefined,
            foldered_items: []
          });

          folderCopyChanged(updated);
        }}
        onClose={() => {
            targetFolderChanged({});
            targetFolderSearchTermChanged("");
            calendarEventAttachmentToCopyChanged({});
          }
        }
      >
        <div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col d-flex align-items-center">
                  <h5 className="mb-0">
                    <strong>
                      <i className="fas fa-copy text-primary mr-2"></i>
                      Copy File
                    </strong>
                  </h5>
                </div>
                <div className="col-xs-auto d-flex align-items-center">
                  <a href="#"
                     className="text-dark"
                     style={{"paddingRight": "15px", "fontSize": "22px"}}
                     onClick={
                       (e) => {
                         e.preventDefault();
                         calendarEventAttachmentToCopyChanged({});
                       }
                      } >
                    <i className="far fa-times"></i>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SelectTargetFolder csrfToken={csrfToken}
                                      team={team}
                                      confirm={confirm}
                                      fetchCalendarEventAttachment={fetchCalendarEventAttachment}
                                      currentUser={currentUser}
                                      folder={folder}
                                      selectedFolderedItems={folderedItemsToMove}
                                      togglefolderedItemSelected={togglefolderedItemSelected}
                                      stageFolderedItemsForDestroy={stageFolderedItemsForDestroy}
                                      copiedFolderedItem={copiedFolderedItem}
                                      copiedFolderedItemChanged={copiedFolderedItemChanged}
                                      copiedFolderedItemNameChanged={copiedFolderedItemNameChanged}
                                      updateFolderedItemName={updateFolderedItemName}
                                      stageFolderedItemsForMove={stageFolderedItemsForMove}
                                      fetchPermissionSet={fetchPermissionSet}
                                      folderedItemUpdated={folderedItemUpdated}
                                      targetFolder={targetFolder}
                                      targetFolderChanged={targetFolderChanged}
                                      folderCopy={folderCopy}
                                      fetchFolderedItemsForFolderCopy={fetchFolderedItemsForFolderCopy}
                                      depthWidth={depthWidth}
                                      context={COPY_FILE_CONTEXT}
                                      targetFolderSearchTerm={targetFolderSearchTerm}
                                      targetFolderSearchTermChanged={targetFolderSearchTermChanged}
                                      canSelectRootFolderAsTarget={false}
                                      toggleFolderCopy={toggleFolderCopy} />
                </div>
              </div>
              <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(canCopyCalendarEventAttachment(targetFolder, calendarEventAttachmentToCopy, isCopyingCalendarEventAttachment)){
                        createCalendarEventAttachmentCopy(csrfToken, team, targetFolder, calendarEventAttachmentToCopy);
                      }
                    }
                  }>
                <div className="row mt-1">
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-start">
                    <div className="form-group mb-0"
                         style={{"flexGrow": "1"}}>
                      <label htmlFor="copy-file-name"
                            style={{"fontSize": "10px"}}
                            className='mb-0 text-muted small'>
                        File Name
                      </label>
                      <input type="text"
                            className="form-control form-control-lg"
                            style={{"fontSize": "14px", "border": "1px solid #eff0f0"}}
                            id="copy-file-name"
                            value={calendarEventAttachmentToCopy.human_file_name || ""}
                            onChange={
                              (e) => {
                                var updated = Object.assign({}, calendarEventAttachmentToCopy, {
                                  human_file_name: e.target.value
                                });

                                calendarEventAttachmentToCopyChanged(updated);
                              }
                            } />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div className="form-row mt-3">
                      <div className="col text-right">
                        {!isCopyingCalendarEventAttachment ? (
                          <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                calendarEventAttachmentToCopyChanged({});
                              }
                            }>
                            <strong>
                              Cancel
                            </strong>
                          </a>
                        ) : null}
                        <button type="submit"
                                disabled={!canCopyCalendarEventAttachment(targetFolder, calendarEventAttachmentToCopy, isCopyingCalendarEventAttachment)}
                                className="btn btn-primary ml-3">
                          {isCopyingCalendarEventAttachment ? (
                            <React.Fragment>
                              <img src="/uploading-loading.gif"
                                    className="mr-1"
                                    style={{width: "16px"}} />
                              <strong>Copying...</strong>
                            </React.Fragment>
                          ) : (
                            <strong>Copy</strong>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
      <SetPermissionsPopup csrfToken={csrfToken}
                           team={team}
                           confirm={confirm}
                           permissionSet={permissionSet}
                           permissionSetChanged={permissionSetChanged}
                           permissionRuleEnabledClicked={permissionRuleEnabledClicked}
                           selectAllPermissionRulesClicked={selectAllPermissionRulesClicked}
                           submitPermissionSet={submitPermissionSet}
                           onPermissionSetSaved={onPermissionSetSaved}
                           guestTeamMemberships={guestTeamMemberships}
                           promotersWithPermission={promotersWithPermission}
                           currentUser={currentUser} />
    </div>
  );
};

FolderView.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  openCalendarEventAttachment: PropTypes.object,
  closeCalendarEventAttachmentModal: PropTypes.func.isRequired,
  deleteCalendarEventAttachment: PropTypes.func.isRequired,
  fetchCalendarEventAttachment: PropTypes.func.isRequired,
  openCalendarEventAttachmentPdfNumberOfPages: PropTypes.number,
  openCalendarEventAttachmentPdfNumberOfPagesChanged: PropTypes.func.isRequired,
  editOpenCalendarEventAttachmentName: PropTypes.func.isRequired,
  updateCalendarEventAttachment: PropTypes.func.isRequired,
  setOpenCalendarEventAttachmentNameEl: PropTypes.func.isRequired,
  endEditOpenCalendarEventAttachmentName: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  folder: PropTypes.object.isRequired,
  createFolder: PropTypes.func.isRequired,
  toggleFolder: PropTypes.func.isRequired,
  selectedFolderedItems: PropTypes.array,
  togglefolderedItemSelected: PropTypes.func.isRequired,
  folderedItemsToDestroy: PropTypes.array,
  stageFolderedItemsForDestroy: PropTypes.func.isRequired,
  deleteStagedFolderedItems: PropTypes.func.isRequired,
  copiedFolderedItem: PropTypes.object,
  copiedFolderedItemChanged: PropTypes.func.isRequired,
  copiedFolderedItemNameChanged: PropTypes.func.isRequired,
  updateFolderedItemName: PropTypes.func.isRequired,
  folderedItemsToMove: PropTypes.array,
  stageFolderedItemsForMove: PropTypes.func.isRequired,
  moveStagedFolderedItems: PropTypes.func.isRequired,
  fetchPermissionSet: PropTypes.func.isRequired,
  folderedItemUpdated: PropTypes.func.isRequired,
  fetchFolderedItemsForFolder: PropTypes.func.isRequired,
  targetFolder: PropTypes.object,
  targetFolderChanged: PropTypes.func.isRequired,
  folderCopy: PropTypes.object,
  folderCopyChanged: PropTypes.func.isRequired,
  fetchFolderedItemsForFolderCopy: PropTypes.func.isRequired,
  toggleFolderCopy: PropTypes.func.isRequired,
  onFolderChanged: PropTypes.func.isRequired,
  permissionSet: PropTypes.object,
  permissionSetChanged: PropTypes.func.isRequired,
  permissionRuleEnabledClicked: PropTypes.func.isRequired,
  selectAllPermissionRulesClicked: PropTypes.func.isRequired,
  submitPermissionSet: PropTypes.func.isRequired,
  onPermissionSetSaved: PropTypes.func.isRequired,
  guestTeamMemberships: PropTypes.array,
  promotersWithPermission: PropTypes.array,
  headerComponent: PropTypes.func.isRequired,
  isAddingFolder: PropTypes.bool,
  isAddingFolderChanged: PropTypes.func.isRequired,
  newFolderName: PropTypes.string,
  newFolderNameChanged: PropTypes.func.isRequired,
  isUploadingFiles: PropTypes.bool,
  isUploadingFilesChanged: PropTypes.func.isRequired,
  selectedFilesToUpload: PropTypes.object,
  selectedFilesToUploadChanged: PropTypes.func.isRequired,
  uploadFilesToFolder: PropTypes.func.isRequired,
  isLoadingUploadedFiles: PropTypes.bool,
  canSelectRootFolderAsTarget: PropTypes.bool,
  fetchGuestsAndPromotersForPermissions: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  targetFolderSearchTerm: PropTypes.string,
  targetFolderSearchTermChanged: PropTypes.func.isRequired,
  calendarEventAttachmentToCopy: PropTypes.object,
  calendarEventAttachmentToCopyChanged: PropTypes.func.isRequired,
  createCalendarEventAttachmentCopy: PropTypes.func.isRequired,
  isCopyingCalendarEventAttachment: PropTypes.bool,
  teamRootFolder: PropTypes.object.isRequired,
  draggedFilesToUpload: PropTypes.array,
  draggedFilesToUploadChanged: PropTypes.func.isRequired,
  showConfirmIdColumn: PropTypes.bool,
  folderChanged: PropTypes.func.isRequired
};

export default FolderView;
