import React from "react";
import Tippy from "@tippyjs/react";

const containerStyle = {
  alignItems: "center",
  backgroundColor: "#F9F9F9",
  display: "flex",
  flex: 1,
  flexDirection: "row",
  height: "25px",
  justifyContent: "space-between",
  margin: "10x",
  padding: "5px 15px",
  width: "100%",
};

const leftStyle = {
  display: "flex",
  flexDirection: "column",
};

const revenueTitleStyle = {
  fontSize: "10px",
};

const revenueContainerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "5px",
};

const revenueAmountStyle = {
  fontSize: "16px",
  fontWeight: "700",
};

const revenueSuffixStyle = {
  color: "#666",
  fontSize: "10px",
};

const tooltipCopyStyle = {
  fontSize: "12px"
};

const tooltipIconStyle = {
  color: "#999999"
};

const CardFooter = ({
  estimatedRevenue,
  tooltipCopy,
}) => {
  return (
    <div style={containerStyle}>
      <div style={leftStyle}>
        <div style={revenueTitleStyle}>
          Estimated Revenue
        </div>
        <div style={revenueContainerStyle}>
          <div style={revenueAmountStyle}>
            ${estimatedRevenue.toLocaleString(0)}
          </div>
          <div style={revenueSuffixStyle}>
            ANNUALLY
          </div>
        </div>
      </div>
      <div>
      <Tippy
        content={
          <div style={tooltipCopyStyle}>
            {tooltipCopy}
          </div>
        }
        placement={"top"}
        maxWidth={200}
        animation={"shift-away"}
        theme="material"
      >
        <i 
          className="fas fa-info-circle"
          style={tooltipIconStyle}
        />
      </Tippy>
      </div>
    </div>
  );
};

export default CardFooter;
