// Simple example of a React "smart" component

import { connect } from 'react-redux';
import LinkOfferModal from '../components/LinkOfferModal';
import * as actions from '../actions/linkOfferModalActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  team: state.team,
  csrfToken: state.csrfToken,
  currentUser: state.currentUser,
  offer: state.offer,
  performers: state.performers,
  calendarEvent: state.calendarEvent,
  onClose: state.onClose,
  selectedPerformer: state.selectedPerformer,
  onSuccess: state.onSuccess
});

const mapDispatchToProps = (dispatch) => {
  return {
    calendarEventChanged: (calendarEvent) => dispatch(actions.calendarEventChanged(calendarEvent)),
    fetchCalendarEventPerformers: (team, calendarEventId) => dispatch(actions.fetchCalendarEventPerformers(dispatch, team, calendarEventId)),
    selectedPerformerChanged: (performer) => dispatch(actions.selectedPerformerChanged(performer)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(LinkOfferModal);
