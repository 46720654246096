import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

import $ from 'jquery';
require('datatables.net-bs4');

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const ExportCSV = ({
  csvDataSource,
  dataTableEl,
  dataTableFilters,
  team,
  confirm,
  currentUser
}) => (
  <Popup arrow={false}
        offsetY={7}
        position="top right"
        contentStyle={popupContentStyle}
        onOpen={
          (e) => {
            e.preventDefault();
          }
        }
        trigger={open => (
          <a href="#"
             className="btn btn-external"
             style={{"fontSize": "14px", "borderRadius": "8px", "paddingLeft": "11px", "paddingRight": "11px"}}>
            <i className="fas fa-ellipsis-v"></i>
          </a>
        )} >
   {close => (
     <div className='row text-left'>
       <div className="col-12">
         <ul className="list-group offer-inline-menu highlight-primary">
           {currentUser.can_manage_refunds ? (
             <li className="list-group-item">
               <a className="text-muted"
                  href={"/teams/" + team.id + "/confirms/" + confirm.id + "/bulk_refunds/new"}>
                 <span className="d-inline-block text-center" style={{width: "28px"}}>
                   <i className="fas fa-hand-holding-usd"></i>
                 </span>
                 Issue Multiple Refunds
               </a>
             </li>
           ) : null}
           <li className="list-group-item">
             <a onClick={
                  (e) => {
                    e.preventDefault();

                    var dataTable = $(dataTableEl).DataTable();
                    var params = Object.assign({},
                      dataTable.ajax.params(),
                      dataTableFilters,
                      {
                        csv_target: "orders"
                      }
                    );

                    var urlWithParams = (csvDataSource + '?' + $.param(params));
                    window.location.href = urlWithParams;
                  }
                }
                className="text-muted"
                href="#">
               <span className="d-inline-block text-center" style={{width: "28px"}}>
                 <i className="fas fa-download"></i>
               </span>
               Export Orders CSV
             </a>
           </li>
           <li className="list-group-item">
             <a className="text-muted"
                onClick={
                  (e) => {
                    e.preventDefault();

                    var dataTable = $(dataTableEl).DataTable();
                    var params = Object.assign({},
                      dataTable.ajax.params(),
                      dataTableFilters,
                      {
                        csv_target: "tickets"
                      }
                    );

                    var urlWithParams = (csvDataSource + '?' + $.param(params));
                    window.location.href = urlWithParams;
                  }
                }
                href="#">
               <span className="d-inline-block text-center" style={{width: "28px"}}>
                 <i className="fas fa-download"></i>
               </span>
               Export Tickets CSV
             </a>
           </li>
           <li className="list-group-item">
             <a className="text-muted"
                onClick={
                  (e) => {
                    e.preventDefault();

                    var dataTable = $(dataTableEl).DataTable();
                    var params = Object.assign({},
                      dataTable.ajax.params(),
                      dataTableFilters,
                      {
                        csv_target: "tickets",
                        ticket_filters: ["is_admission", "manifested"],
                        filename_suffix: "manifested-only"
                      }
                    );

                    var urlWithParams = (csvDataSource + '?' + $.param(params));
                    window.location.href = urlWithParams;
                  }
                }
                href="#">
               <span className="d-inline-block text-center" style={{width: "28px"}}>
                 <i className="fas fa-download"></i>
               </span>
               Manifested Tickets CSV
             </a>
           </li>
           <li className="list-group-item">
             <a onClick={
                  (e) => {
                    e.preventDefault();

                    var dataTable = $(dataTableEl).DataTable();
                    var params = Object.assign({},
                      dataTable.ajax.params(),
                      dataTableFilters,
                      {
                        csv_target: "orders",
                        filename_suffix: "cash-only",
                        dtq: {
                          cash_true: true
                        }
                      }
                    );

                    var urlWithParams = (csvDataSource + '?' + $.param(params));
                    window.location.href = urlWithParams;
                  }
                }
                className="text-muted"
                href="#">
               <span className="d-inline-block text-center" style={{width: "28px"}}>
                 <i className="fas fa-download"></i>
               </span>
               Cash Orders Report
             </a>
           </li>
           <li className="list-group-item">
             <a className="text-muted"
                onClick={
                  (e) => {
                    e.preventDefault();

                    var dataTable = $(dataTableEl).DataTable();
                    var params = Object.assign({},
                      dataTable.ajax.params(),
                      dataTableFilters,
                      {
                        csv_target: "tickets",
                        ticket_filters: ["is_add_on"],
                        filename_suffix: "add-ons-only"
                      }
                    );

                    var urlWithParams = (csvDataSource + '?' + $.param(params));
                    window.location.href = urlWithParams;
                  }
                }
                href="#">
               <span className="d-inline-block text-center" style={{width: "28px"}}>
                 <i className="fas fa-download"></i>
               </span>
               Premium Buyers
             </a>
           </li>
           <li className="list-group-item">
             <a className="text-muted" href={"/teams/" + team.id + "/confirms/" + confirm.id + "/ticket_audit_csv"}>
               <span className="d-inline-block text-center" style={{width: "28px"}}>
                 <i className="fas fa-download"></i>
               </span>
               Ticket Audit
             </a>
           </li>
           <li className="list-group-item">
             <a className="text-muted" href={"/teams/" + team.id + "/confirms/" + confirm.id + "/add_on_audit_csv"}>
               <span className="d-inline-block text-center" style={{width: "28px"}}>
                 <i className="fas fa-download"></i>
               </span>
               Add-On Audit
             </a>
           </li>
         </ul>
       </div>
     </div>
   )}
  </Popup>
);

ExportCSV.propTypes = {
  csvDataSource: PropTypes.string.isRequired,
  dataTableEl: PropTypes.object,
  dataTableFilters: PropTypes.object,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default ExportCSV;
