import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import emailNotificationSettingsReducer from '../reducers/emailNotificationSettingsReducer';

const configureStore = (railsProps) => (
  createStore(emailNotificationSettingsReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
