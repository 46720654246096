import React from "react";

import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";
import SendDomainRow from "./SendDomainRow";

const labelStyle = {
  color: "#3333333",
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 8,
};

const subtextStyle = {
  color: "#333333",
  fontSize: 12,
  opacity: 0.5,
  marginBottom: 10,
};

const submitButtonStyle = {
  backgroundColor: "white",
  border: "#1982C4 1px solid",
  borderRadius: 5,
  color: "#1982C4",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 700,
  height: 40,
};

const SendDomainsContainer = () => {
  const {
    sendDomains,
    sendDomainAdded,
  } = useMarketingSettingsContext();

  return (
    <div>
      <div style={labelStyle}>Send Domain</div>
      <div style={subtextStyle}>
        Configure your Send Domain to ensure that marketing emails come from you.
      </div>
      {sendDomains.length === 0 ? (
        <button onClick={sendDomainAdded} style={submitButtonStyle}>
          Add domain
        </button>
      ) : (
        sendDomains.map((sendDomain) => (
          <SendDomainRow key={sendDomain.id} sendDomain={sendDomain} />
        ))
      )}
    </div>
  );
};

export default SendDomainsContainer;
