import PropTypes from 'prop-types';
import React from 'react';

import SemiCircle from '../../SemiCircle/components/SemiCircle';

const EventSidebarView = ({ event, closeEventModal }) => (
  <div className="row">
    <div className="col-12">
      <div className="card no-border">
        <div className="card-header hero-image"
             style={{"backgroundImage": "linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.75)), url('" + event.primary_artist.image_url + "')"}} >
          <a href="#"
              style={{"fontSize": "24px", right:10}}
              className="text-light position-absolute"
              onClick={(e) => {
                e.preventDefault();
                closeEventModal();
              }} >
            <i className="fal fa-times"></i>
          </a>
          <div className="bottom-lead" style={{"marginBottom": "16px"}}>
            <h3 className="pl-0 mb-1">{event.primary_artist.name}</h3>
            <span className="badge badge-transparent">
              Headliner
            </span>
          </div>
        </div>
        <div className="card-body">
          <ul className="fa-ul">
            <li>
              <span className="fa-li"><i className="fa fa-calendar"></i></span>
              <strong>{event.human_start_date}</strong>
            </li>
            <li>
              <span className="fa-li"><i className="fa fa-map-marker"></i></span>
              <strong>{event.venue.generalized_region}</strong>
            </li>
            <li>
              <span className="fa-li"><i className="fa fa-ticket"></i></span>
              {event.venue.virtual ? (
                <strong>Unlimited Capacity</strong>
              ) : (
                <strong>{event.venue.capacity} cap</strong>
              )}
            </li>
          </ul>
          <br />
          <div>
            <div className="text-muted small">
              <strong>
                Full Lineup
              </strong>
            </div>
            <hr style={{"margin": "0.1em 0"}} />
            <table className="table table-borderless" style={{"fontSize": "10px"}}>
              <tbody>
                <tr>
                  <td className="p-0">
                    <a style={{"textDecoration": "underline"}} className="text-dark" href={"/artists/" + event.primary_artist.permalink}>
                      {event.primary_artist.name}
                    </a>
                    <span className="text-muted ml-2">
                      Headliner
                    </span>
                  </td>
                </tr>
                {event.support_artists.map((artist, index) => (
                  <tr key={index}>
                    <td className="p-0">
                      <a style={{"textDecoration": "underline"}} className="text-dark" href={"/artists/" + artist.permalink}>
                        {artist.name}
                      </a>
                      <span className="text-muted ml-2">
                        Support
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {event.other_events_in_market_on_same_date_as_json.length > 0 ? (
            <div>
              <div className="text-muted small">
                <strong>
                  Events in market on this date
                </strong>
              </div>
              <hr style={{"margin": "0.1em 0"}} />
              <table className="table table-borderless" style={{"fontSize": "10px", "textDecoration": "underline"}}>
                <tbody>
                  {event.other_events_in_market_on_same_date_as_json.map((otherEvent, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-0">
                          <a className="text-dark" href={"/artists/" + otherEvent.primary_artist.permalink}>
                            {otherEvent.primary_artist.name}
                          </a>
                        </td>
                        <td className="p-0">
                          <a className="text-dark" href={"/venues/" + otherEvent.venue.permalink}>
                            {otherEvent.venue.name}
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <br />
            </div>
          ) : null}
          {event.tickets_sold > 0 ? (
            <div>
              <div className="text-muted small">
                <strong>Ticket Sales</strong>
              </div>
              <hr style={{"margin": "0.1em 0"}} />
              <div className="row" style={{"paddingTop": "40px"}}>
                <div className="col-12 col-md-4">
                  <dl>
                    <dt className="text-muted">
                      <strong>Sold</strong>
                    </dt>
                    <dd>
                      <strong className="large-number">
                        {event.tickets_sold}
                      </strong>
                    </dd>
                  </dl>
                  <dl>
                    <dt className="text-muted">
                      <strong>Capacity</strong>
                    </dt>
                    <dd>
                      <strong className="large-number">
                        {event.venue.capacity}
                      </strong>
                    </dd>
                  </dl>
                </div>
                <div className="col-12 col-md-8">
                  <SemiCircle percentage={event.tickets_sold_percentage} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  </div>
);

EventSidebarView.propTypes = {
  event: PropTypes.object.isRequired,
  closeEventModal: PropTypes.func.isRequired
};

export default EventSidebarView;
