import { combineReducers } from 'redux';
import {
  INDEX_CHANGED
} from '../constants/agentsTableConstants';

const agents = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const index = (state = 0, action) => {
  switch (action.type) {
    case INDEX_CHANGED:
      return action.index;
    default:
      return state;
  }
};

const agentsTableReducer = combineReducers({ agents, index });

export default agentsTableReducer;
