import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Popup from "reactjs-popup";

import AvailableCampaignCard from "./AvailableCampaignCard";
import { useCampaignsContext } from "./CampaignsContainer";
import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";
import { Line, LineChart } from "recharts";

const selectStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  color: "#333333",
  fontSize: 14,
  height: 40,
  margin: "10px 0",
  padding: 8,
  width: 250,
};

const titleStyle = {
  color: "#333333",
  fontSize: "16px",
  fontWeight: 600,
}

const headerStyle = {
  color: "#333333",
  fontSize: "16px",
  marginBottom: "15px",
};

const campaignsContainerStyle = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "repeat(auto-fill, minmax(350px, 350px))",
};

const dataTableContainerStyle = {
  background: "white",
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "grid",
  width: "100%",
  padding: "20px",
  borderRadius: 4,
};

const metricContainerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
};

const metricDataStyle = {
  color: "#333333",
  fontSize: "14px",
};

const popupContentStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "0px",
  width: "fit-content",
};

const menuLineStyle = {
  display: "flex",
  flexDirection: "row",
  fontSize: "12px",
  fontWeight: "normal",
  margin: "5px",
  paddingRight: "10px",
};

const menuOptionStyle = {
  width: "28px"
};

const formatChartData = (data) => {
  return JSON.parse(data).map(value => {
    return {
      value: value,
    }
  });
};

const formatPercentage = (number) => {
  return `${number.toFixed(1)}%`
};

const renderCountCell = (element, count, occuredData) => {
  ReactDOM.render((
    <div style={metricContainerStyle}>
      <LineChart width={50} height={25} data={formatChartData(occuredData)}>
        <Line
          animationDuration={500}
          type="monotone"
          dataKey="value"
          stroke="#94C12B"
          strokeWidth={1}
          dot={false}
        />
      </LineChart>        
      <div style={metricDataStyle}>
        {Number(count).toLocaleString()}
      </div>
    </div>
  ), element);
};

const renderRateCell = (element, rate, occuredData) => {
  ReactDOM.render((
    <div style={metricContainerStyle}>
      <LineChart width={50} height={25} data={formatChartData(occuredData)}>
        <Line
          animationDuration={500}
          type="monotone"
          dataKey="value"
          stroke="#94C12B"
          strokeWidth={1}
          dot={false}
        />
      </LineChart>        
      <div style={metricDataStyle}>
        {formatPercentage(rate)}
      </div>
    </div>
  ), element);
};

const CampaignIndex = () => {
  const {
    csrfToken,
    venueOwnerships,
    venueOwnership,
    setVenueOwnership,
    isLoading,
    setIsLoading,
    fetchCampaigns,
    activeCampaigns,
    availableCampaignTemplates,
    dataTableProps,
    team,
    updateCampaign,
    startCampaign,
  } = useCampaignsContext();
  const { dataSource, ...modifiedDataTableProps } = dataTableProps;
  
  const [dataTableSource, setDataTableSource] = useState(dataSource);
  const [dataTableRefreshedAt, setDataTableRefreshedAt] = useState(new Date());

  const refreshDataTable = () => {
    setDataTableRefreshedAt(new Date());
  }

  const handleVenueOwnershipChange = (e) => {
    e.preventDefault();

    setVenueOwnership(venueOwnerships[e.target.value])
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCampaigns(venueOwnership.id);
    setDataTableSource(`${dataSource}?venue_ownership_id=${venueOwnership.id}`);
    refreshDataTable();
  }, [venueOwnership]);

  return (
    <div>
      <div style={titleStyle}>Campaigns</div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}>
        <select value={venueOwnerships.findIndex(o => o.id === venueOwnership.id)} onChange={handleVenueOwnershipChange} style={selectStyle}>
          {venueOwnerships.map((option, index) => (
            <option key={`${option.venueable.name}-${index}`} value={index}>
              {option.venueable.name}
            </option>
          ))}
        </select>
        {!isLoading && activeCampaigns.length > 0 ? (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => window.location.href = `/teams/${team.id}/campaigns/new?venue_ownership_id=${venueOwnership.id}`}
            >
              New Campaign
            </button>
          </div>
        ) : null}
      </div>
      {isLoading ? (
        <img src="/uploading-loading.gif" className="mr-1" style={{width: "16px"}} />
      ) : activeCampaigns.length === 0 ? (
        <>
          <div style={headerStyle}>Available Campaigns</div>
          <div style={campaignsContainerStyle}>
            {availableCampaignTemplates.map((campaignTemplate, index) => (
              <AvailableCampaignCard
                key={index}
                campaignTemplate={campaignTemplate}
                handleClick={() => startCampaign(campaignTemplate)}
              />
            ))}
          </div>
        </>
      ) : (
        <div style={dataTableContainerStyle}>
          <ContainerlessTableViewApp
            key={dataTableRefreshedAt}
            dataSource={dataTableSource}
            {...modifiedDataTableProps}
            rowCallback={(row, data) => {
              const deliveredElement = row.getElementsByClassName("delivered-cell")[0];
              const deliveredCount = data.delivered_count;
              renderCountCell(deliveredElement, deliveredCount, data.delivered_data);
            
              const openedElement = row.getElementsByClassName("opened-cell")[0];
              const openRate = deliveredCount > 0 ? (data.opened_count / deliveredCount) * 100 : 0;
              renderRateCell(openedElement, openRate, data.opened_data);

              const clickedElement = row.getElementsByClassName("clicked-cell")[0];
              const clickRate = deliveredCount > 0 ? (data.clicked_count / deliveredCount) * 100 : 0;
              renderRateCell(clickedElement, clickRate, data.clicked_data);

              const convertedElement = row.getElementsByClassName("converted-cell")[0];
              const convertRate = deliveredCount > 0 ? (data.converted_count / deliveredCount) * 100 : 0;
              renderRateCell(convertedElement, convertRate, data.converted_data);
              
              const toolsElement = row.getElementsByClassName("tools-cell")[0];
              ReactDOM.render((
                <Popup arrow={false}
                  offsetY={5}
                  position="bottom right"
                  contentStyle={popupContentStyle}
                  onOpen={
                    (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }
                  trigger={_open => (
                    <a href="#"
                      onClick={(e) => { e.preventDefault(); }}
                      className="text-muted"
                    >
                      <i className="fas fa-ellipsis-v"></i>
                    </a>
                  )} >
                  {close => (
                    <div className='row text-left'>
                      <div className="col-12">
                        <ul className="list-group offer-inline-menu highlight-primary">
                          {data.state === "active" ? (
                            <li className="list-group-item">
                              <a className="text-muted"
                                style={menuLineStyle}
                                href="#"
                                 onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                    updateCampaign(
                                      data.id,
                                      { state: "paused" },
                                      refreshDataTable
                                    );
                                  }
                                }
                              >
                                <span className="d-inline-block text-center" style={menuOptionStyle}>
                                  <i className="fas fa-pause"></i>
                                </span>
                                Pause Campaign
                              </a>
                            </li>
                          ) : (
                            <li className="list-group-item">
                              <a className="text-muted"
                                style={menuLineStyle}
                                href="#"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                    updateCampaign(
                                      data.id,
                                      { state: "active" },
                                      refreshDataTable
                                    );
                                  }
                                }
                              >
                                <span className="d-inline-block text-center" style={menuOptionStyle}>
                                  <i className="fas fa-play"></i>
                                </span>
                                Resume Campaign
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </Popup>
              ), toolsElement);            
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignIndex;
