/* eslint-disable import/prefer-default-export */

export const DATA_TABLE_EL_CHANGED = 'DATA_TABLE_EL_CHANGED';
export const OPEN_INBOUND_SUBMISSION_CHANGED = 'OPEN_INBOUND_SUBMISSION_CHANGED';
export const GETTING_FORM_LINKS_CHANGED = 'GETTING_FORM_LINKS_CHANGED';

export const CLEAR_FILTER_VALUES = 'CLEAR_FILTER_VALUES';
export const FILTER_UPDATED = 'FILTER_UPDATED';

export const TAG_MANAGER_OPEN_CHANGED = 'TAG_MANAGER_OPEN_CHANGED';
export const TAG_MANAGER_FILTER_CHANGED = 'TAG_MANAGER_FILTER_CHANGED';
export const AVAILABLE_TAGS_CHANGED = 'AVAILABLE_TAGS_CHANGED';
export const VENUE_OWNERSHIPS_CHANGED = 'VENUE_OWNERSHIPS_CHANGED';
