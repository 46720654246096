import { combineReducers } from 'redux';

import {
  LAST_PIPELINE_EVENT_CHANGED,
  PIPELINE_EVENTS_CHANGED,
  UNSEEN_PIPELINE_EVENTS_CHANGED
} from '../constants';

const fetchPipelineEventsForArtistOnInit = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const unseenPipelineEvents = (state = [], action) => {
  switch (action.type) {
    case UNSEEN_PIPELINE_EVENTS_CHANGED:
      return action.pipelineEvents;
    default:
      return state;
  }
};

const artist = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const lastPipelineEvent = (state = {}, action) => {
  switch (action.type) {
    case LAST_PIPELINE_EVENT_CHANGED:
      return action.pipelineEvent;
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pipelineEvents = (state = [], action) => {
  switch (action.type) {
    case PIPELINE_EVENTS_CHANGED:
      return action.pipelineEvents;
    default:
      return state;
  }
};

const activityFeedReducer = combineReducers({ csrfToken, currentUser, pipelineEvents, team, lastPipelineEvent, artist, fetchPipelineEventsForArtistOnInit, unseenPipelineEvents });

export default activityFeedReducer;
