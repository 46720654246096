import PropTypes from 'prop-types';
import React from 'react';

import { ToastContainer } from 'react-toastify';
var _ = require('lodash');

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const canSubmitForm = (user, originalUser) => {
  var baseConditions = (
    user
      && user.first_name
      && user.first_name.length > 0
      && user.last_name
      && user.last_name.length > 0
      && user.email
      && user.email.length > 0
      && validateEmail(user.email)
  );

  var passwordConditions = (
    user
      && (
        (
          !user.current_password
            && !user.password
            && !user.password_confirmation
        ) || (
          user.current_password
            && user.current_password.length > 0
            && user.password
            && user.password.length > 0
            && user.password_confirmation
            && user.password_confirmation.length > 0
            && user.password === user.password_confirmation
        )
      )
  );

  return (
    baseConditions
      && userHasChanges(originalUser, user)
      && passwordConditions
  );
}

const userHasChanges = (originalUser, user) => {
  return (
    originalUser.first_name !== user.first_name
      || originalUser.last_name !== user.last_name
      || originalUser.email !== user.email
      || originalUser.email !== user.email
      || user.avatarFile
      || (
        (user.current_password && user.current_password.length > 0)
          || (user.password && user.password.length > 0)
          || (user.password_confirmation && user.password_confirmation.length > 0)
      )
  );
}

const checkIfPasswordMatchesConfirmation = (user, userChanged) => {
  var passwordConfirmationDoesNotMatch = (
    user
      && user.password
      && user.password.length > 0
      && user.password_confirmation
      && user.password_confirmation.length > 0
      && user.password !== user.password_confirmation
  );

  var updated = Object.assign({}, user, {
    passwordConfirmationDoesNotMatch: passwordConfirmationDoesNotMatch
  });

  userChanged(updated);
};
const debouncedCheckIfPasswordMatchesConfirmation = _.debounce(checkIfPasswordMatchesConfirmation, 500);

const UserSettings = ({
  csrfToken,
  originalUser,
  user,
  userChanged,
  updateUser,
  fileEl,
  fileElChanged,
  updateUserHomepage,
  updateUserTimezone,
  team,
  myMobileApps,
  isVenueManager,
  availableTimezones,
}) => (
  <div className="row"
       style={{
         "marginTop": "15px",
         "marginBottom": "15px"
       }}>
    <ToastContainer />
    <div className="col-12">
      <form onSubmit={
              (e) => {
                e.preventDefault();

                if(!canSubmitForm(user, originalUser)){
                  return false;
                }

                updateUser(csrfToken, user);
              }
            }>
        <div className="row">
          <div className="col-xs-auto d-flex align-items-start"
               style={{"paddingLeft": "15px"}}>
            {user.preview_url ? (
              <img src={user.preview_url}
                   className="rounded-circle"
                   style={{
                     "objectFit": "cover"
                   }}
                   width="80"
                   height="80" />
            ) : (
              <img src={user.avatar_thumb_url}
                   className="rounded-circle"
                   width="80"
                   height="80" />
            )}
          </div>
          <div className="col d-flex align-items-start flex-column">
            <p className="mb-0 text-muted small">
              Images must be at least 200 x 200px
            </p>
            <input
              ref={el => {
                if(el && Object.keys(fileEl).length === 0){
                  fileElChanged(el);
                }
              }}
              onChange={
                (e) => {
                  var file = e.target.files[0];

                  if(file){
                    var updated = Object.assign({}, user, {
                      avatarFile: file,
                      preview_url: URL.createObjectURL(file)
                    });

                    userChanged(updated);
                  }

                  e.target.value = null;
                }
              }
              accept="image/*"
              type="file"
              style={{ display: "none" }} />
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();
                   fileEl.click();
                 }
               }
               style={{
                 "borderRadius": "4px",
                 "padding": "6px 9px",
                 "border": "1px solid #f2f2f2",
                 "background": "#f9f9f9"
               }}
               className="btn btn-light small text-dark mt-2">
              <strong>
                Choose Photo
              </strong>
            </a>
            {user.avatarFile ? (
              <a className="small mt-2"
                 onClick={
                   (e) => {
                     e.preventDefault();

                     var updated = Object.assign({}, user);
                     delete updated.avatarFile;
                     delete updated.preview_url;

                     userChanged(updated);
                   }
                 }
                 href="#">
                <strong>Remove</strong>
              </a>
            ) : null}
          </div>
        </div>
        <div className="form-row"
             style={{"marginTop": "50px"}}>
          <div className="col-6 col-md-4">
            <div className="form-group">
              <label className="small mb-1 text-muted"
                     htmlFor="user-first-name">
                First Name
              </label>
              <input type="text"
                     style={{
                       "borderRadius": "4px",
                       "border": "2px solid #f5f5f5",
                       "fontSize": "14px"
                     }}
                     value={user.first_name || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, user, {
                           first_name: e.target.value
                         });

                         userChanged(updated);
                       }
                     }
                     className="form-control form-control-lg"
                     id="user-first-name" />
            </div>
          </div>
          <div className="col-6 col-md-4">
            <div className="form-group">
              <label className="small mb-1 text-muted"
                     htmlFor="user-last-name">
                Last Name
              </label>
              <input type="text"
                     style={{
                       "borderRadius": "4px",
                       "border": "2px solid #f5f5f5",
                       "fontSize": "14px"
                     }}
                     value={user.last_name || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, user, {
                           last_name: e.target.value
                         });

                         userChanged(updated);
                       }
                     }
                     className="form-control form-control-lg"
                     id="user-last-name" />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <label className="small mb-1 text-muted"
                     htmlFor="user-email">
                Email
              </label>
              <input type="email"
                     style={{
                       "borderRadius": "4px",
                       "border": "2px solid #f5f5f5",
                       "fontSize": "14px"
                     }}
                     value={user.email || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, user, {
                           email: e.target.value
                         });

                         userChanged(updated);
                       }
                     }
                     className="form-control form-control-lg"
                     id="user-email" />
            </div>
          </div>
        </div>
        {user.unconfirmed_email ? (
          <div className="form-row">
            <div className="col-12 col-md-8">
              <div className="alert alert-warning border-0"
                   style={{"borderRadius": "4px"}}>
                <p className="mb-0">
                  <strong>Email address update in progress</strong>
                </p>
                <p className="mb-0">
                Please check your inbox to confirm the email update.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-row">
          <div className="col-6 col-md-4">
            <div className="form-group" style={{"marginBottom": "7px"}}>
              <label className="small mb-1 text-muted"
                     htmlFor="user-current-password">
                Current Password
              </label>
              <input type="password"
                     style={{
                       "borderRadius": "4px",
                       "border": "2px solid #f5f5f5",
                       "fontSize": "14px"
                     }}
                     value={user.current_password || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, user, {
                           current_password: e.target.value
                         });

                         userChanged(updated);
                       }
                     }
                     className="form-control form-control-lg"
                     id="user-current-password" />
            </div>
          </div>
          <div className="col-6 col-md-4">
            <div className="form-group" style={{"marginBottom": "7px"}}>
              <label className="small mb-1 text-muted"
                     htmlFor="user-password">
                New Password
              </label>
              <input type="password"
                     style={{
                       "borderRadius": "4px",
                       "border": "2px solid #f5f5f5",
                       "fontSize": "14px"
                     }}
                     value={user.password || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, user, {
                           password: e.target.value
                         });

                         userChanged(updated);
                         debouncedCheckIfPasswordMatchesConfirmation(updated, userChanged);
                       }
                     }
                     className={"form-control form-control-lg " + (user.passwordConfirmationDoesNotMatch ? "highlight" : "")}
                     id="user-password" />
            </div>
          </div>
          <div className="col-6 col-md-4">
            <div className="form-group" style={{"marginBottom": "7px"}}>
              <label className="small mb-1 text-muted"
                     htmlFor="user-password-confirmation">
                Confirm Password
              </label>
              <input type="password"
                     style={{
                       "borderRadius": "4px",
                       "border": "2px solid #f5f5f5",
                       "fontSize": "14px"
                     }}
                     value={user.password_confirmation || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, user, {
                           password_confirmation: e.target.value
                         });

                         userChanged(updated);
                         debouncedCheckIfPasswordMatchesConfirmation(updated, userChanged);
                       }
                     }
                     className={"form-control form-control-lg " + (user.passwordConfirmationDoesNotMatch ? "highlight" : "")}
                     id="user-password-confirmation" />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            <p className="mb-0 extra-small text-muted">
              * Must be at least 12 characters and include a number (0-9), an uppercase letter (A-Z), and one symbol
            </p>
          </div>
        </div>
        <div className="form-row"
             style={{"marginTop": "25px"}}>
          <div className="col-12">
            <button type="submit"
                    style={{
                      "fontSize": "14px",
                      "borderRadius": "4px",
                      "padding": "7px 21px"
                    }}
                    disabled={!canSubmitForm(user, originalUser)}
                    className="btn btn-primary">
              <strong>Update</strong>
            </button>
          </div>
        </div>
      </form>
    </div>
    {team.setup_complete ? (
      <div className="col-12">
        <hr style={{
              "borderTop": "2px solid #f9f9f9",
              "marginTop": "30px",
              "marginBottom": "14px"
            }} />
        <p className="mb-0" style={{"fontSize": "16px"}}>
          <strong>Homepage</strong>
        </p>
        <p style={{"fontSize": "14px"}}>
          Set your default homepage for when you log in to Opendate.
        </p>
        <form>
          <div className="form-row">
            <div className="col-12 col-md-6 col-lg-3 mb-2">
              <select className="custom-select"
                      value={user.homepage || ""}
                      onChange={
                        (e) => {
                          var updated = Object.assign({}, user, {
                            homepage: e.target.value
                          });

                          userChanged(updated);
                          updateUserHomepage(csrfToken, updated);
                        }
                      }
                      style={{
                        "fontSize": "16px",
                        "border": "1px solid #e7e7e7"
                      }}>
                <option value="My Tickets">My Tickets</option>
                <option value="Venue Manager">Venue Manager</option>
                <option value="Support">Help Page</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    ) : null}
    <div className="col-12">
      <hr style={{
            "borderTop": "2px solid #f9f9f9",
            "marginTop": "30px",
            "marginBottom": "14px"
          }} />
      <p className="mb-0" style={{"fontSize": "16px"}}>
        <strong>Timezone</strong>
      </p>
      <p style={{"fontSize": "14px"}}>
        Specify your local timezone.
      </p>
      <form>
        <div className="form-row">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="form-group">
              <select className="custom-select"
                      value={user.time_zone || ""}
                      onChange={
                        (e) => {
                          var updated = Object.assign({}, user, {
                            time_zone: e.target.value
                          });

                          userChanged(updated);
                          updateUserTimezone(csrfToken, updated)
                        }
                      }
                      style={{
                        "fontSize": "16px",
                        "border": "1px solid #e7e7e7"
                      }}>
                { availableTimezones.map(timezone => (
                  <option value={timezone} key={timezone}>{timezone}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
    {myMobileApps.length > 0 ? (
      <div className="col-12">
        <hr style={{
              "borderTop": "2px solid #f9f9f9",
              "marginTop": "30px",
              "marginBottom": "14px"
            }} />
        <p className="mb-0" style={{"fontSize": "16px"}}>
          <strong>Mobile Apps</strong>
        </p>
        <p style={{"fontSize": "14px"}}>
          Download mobile apps for your favorite venues to manage tickets and explore upcoming shows.
        </p>
        <div className="form-row">
          {myMobileApps.sort((a, b) => a.name.localeCompare(b.name)).map((mobileApp, index) =>
            <div className={"mb-2 " + (isVenueManager ? "col-12 col-md-6" : "col-12 col-md-6 col-lg-4")}
                 key={index}>
              <div className="row">
                <div className="col-xs-auto" style={{"paddingLeft": "15px"}}>
                  <img src={mobileApp.icon_thumb_url}
                       style={{
                         "width": "60px",
                         "height": "60px",
                         "borderRadius": "16px"
                       }}
                       className="img-fluid" />
                </div>
                <div className="col ml-1">
                  <p className="mb-0">
                    <strong>{mobileApp.name}</strong>
                  </p>
                  {mobileApp.human_registered_at ? (
                    <p className="mb-0 small">
                      Installed on {mobileApp.human_registered_at}
                    </p>
                  ) : (
                    <div className="mt-1">
                      {mobileApp.app_store_url && mobileApp.app_store_url.length > 0 ? (
                        <a href={mobileApp.app_store_url}
                           className="mr-2"
                           target="_blank">
                          <img src="/app_store.png" style={{width: "96px", height: "32px"}} />
                        </a>
                      ) : null}
                      {mobileApp.google_play_url && mobileApp.google_play_url.length > 0 ? (
                        <a href={mobileApp.google_play_url}
                           className="mr-2"
                           target="_blank">
                          <img src="/google_play.png" style={{width: "110.22px", height: "32px"}} />
                        </a>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    ) : null}
  </div>
);

UserSettings.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  originalUser: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userChanged: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  fileEl: PropTypes.object,
  fileElChanged: PropTypes.func.isRequired,
  updateUserHomepage: PropTypes.func.isRequired,
  updateUserTimezone: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  myMobileApps: PropTypes.array,
  isVenueManager: PropTypes.bool,
  availableTimezones: PropTypes.array,
};

export default UserSettings;
