/* eslint-disable import/prefer-default-export */

import {
  SELECTED_VENUE_OWNERSHIP_CHANGED,
  SELECTED_VENUE_OWNERSHIP_EDITOR_STATE_CHANGED,
  VENUE_OWNERSHIP_UPDATED
} from '../constants/ticketingSettingsConstants';

const axios = require('axios').default;

import {stateToHTML} from 'draft-js-export-html';
import { toast } from 'react-toastify';

export const selectedVenueOwnershipChanged = (venueOwnership) => ({
  type: SELECTED_VENUE_OWNERSHIP_CHANGED,
  venueOwnership
});

export const selectedVenueOwnershipEditorStateChanged = (editorState) => ({
  type: SELECTED_VENUE_OWNERSHIP_EDITOR_STATE_CHANGED,
  editorState
});

export const updateVenueOwnership = (dispatch, csrfToken, team, venueOwnership) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var venueOwnershipParams = {
      ticket_confirmation_info: stateToHTML(venueOwnership.ticket_confirmation_info.getCurrentContent()),
      statement_descriptor_suffix: venueOwnership.statement_descriptor_suffix,
      max_tickets_per_email: venueOwnership.max_tickets_per_email || 0,
      google_tracking_id: venueOwnership.google_tracking_id,
      facebook_pixel_id: venueOwnership.facebook_pixel_id,
      default_ticketing_arrangement: venueOwnership.default_ticketing_arrangement,
      custom_fields_attributes: venueOwnership.custom_fields.map((cf) => {
        return {
          id: cf.id,
          enabled: cf.enabled,
          required: cf.required
        }
      })
    };

    return axios.patch("/teams/" + team.id + "/venue_ownerships/" + venueOwnership.id, {
        venue_ownership: venueOwnershipParams
      })
      .then(({ data }) => {
        dispatch(venueOwnershipUpdated(data));
        dispatch(selectedVenueOwnershipChanged(data));

        toast.success("Settings successfully updated", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const venueOwnershipUpdated = (venueOwnership) => ({
  type: VENUE_OWNERSHIP_UPDATED,
  venueOwnership
});
