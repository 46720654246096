/* eslint-disable import/prefer-default-export */

import {
  FOLLOW_UP_MODAL_CLOSED,
  FOLLOW_UP_CHANGED,
  FOLLOW_UP_ARTIST_CHANGED,
  FOLLOW_UP_REMIND_AT_CHANGED,
  FOLLOW_UP_NOTE_CHANGED
} from '../constants/followUpModalConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import $ from 'jquery';
require('datatables.net-bs4');

export const followUpChanged = (followUp) => ({
  type: FOLLOW_UP_CHANGED,
  followUp
});

export const followUpModalClosed = (dispatch, onClose) => {
  return dispatch => {
    onClose();
    dispatch(broadcastFollowUpModalClosed());
  };
};

export const broadcastFollowUpModalClosed = () => ({
  type: FOLLOW_UP_MODAL_CLOSED
});

export const followUpArtistChanged = (artist) => ({
  type: FOLLOW_UP_ARTIST_CHANGED,
  artist
});

export const followUpRemindAtChanged = (date) => ({
  type: FOLLOW_UP_REMIND_AT_CHANGED,
  date
});

export const followUpNoteChanged = (note) => ({
  type: FOLLOW_UP_NOTE_CHANGED,
  note
});

export const createNewFollowUp = (dispatch, team, followUp, dataTableEl, csrfToken, onClose, onCreate) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var followUpParams = {
      follow_up: {
        remind_at: followUp.remind_at,
        artist_id: followUp.artist.value,
        note: followUp.note,
        last_contact_id: followUp.last_contact_id,
        last_contact_type: followUp.last_contact_type
      }
    };

    if(followUp.pipeline && followUp.pipeline.id){
      followUpParams.follow_up.update_pipeline_attributes = {
        id: followUp.pipeline.id,
        state: "follow_up"
      }
    } else {
      followUpParams.follow_up.pipeline_attributes = {
        team_id: team.id,
        state: "follow_up"
      }
    }

    return axios.post("/teams/" + team.id + "/follow_ups", followUpParams)
      .then(({ data }) => {
        if(Object.keys(dataTableEl).length > 0){
          $(dataTableEl).DataTable().ajax.reload();
        }

        dispatch(followUpModalClosed(dispatch, onClose));
        onCreate(data);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const updateFollowUp = (dispatch, team, followUp, csrfToken, dataTableEl, onClose) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/follow_ups/" + followUp.id, {
        follow_up: {
          remind_at: followUp.remind_at,
          note: followUp.note
        }
      })
      .then(({ data }) => {
        dispatch(followUpModalClosed(dispatch, onClose));
        $(dataTableEl).DataTable().ajax.reload();
      });
  };
};

export const submitFollowUpForm = (dispatch, team, followUp, csrfToken, dataTableEl, onClose, onCreate) => {
  return dispatch => {
    if(followUp.id){
      dispatch(updateFollowUp(dispatch, team, followUp, csrfToken, dataTableEl, onClose));
    } else {
      dispatch(createNewFollowUp(dispatch, team, followUp, dataTableEl, csrfToken, onClose, onCreate));
    }
  };
};
