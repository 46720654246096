import React, { useState } from 'react';
import styled from "styled-components";
import SlidingPane from "react-sliding-pane";
import EventSidebarView from '../../DataTableWithSidebarView/components/EventSidebarView';

const NoRecordWrapper = styled.div`
  background: #F4F9FB;
  color: #1982C4;
  border-radius: 8px;
`;

const sideContentStyle = (browser) => {
  return {
    "paddingLeft": (browser.lessThan.medium ? "15px" : "12px")
  }
}

const ArtistEventLocalShow = ({title, event, browser}) => {
  const [showEventModal, setShowEventModal] = useState(false);

  const handleOnShowEventModal = () => {
    setShowEventModal(true);
  }

  const handleOnCloseEventModal = () => {
    setShowEventModal(false);
  }

  return (
    <div className="row">
      {showEventModal && (
        <SlidingPane
          isOpen={Object.keys(event).length > 0}
          className="sliding-pane-container"
          width={"480px"}
          hideHeader={true}
          onRequestClose={handleOnCloseEventModal}
        >
          {Object.keys(event).length > 0 && <>
            <div style={sideContentStyle(browser)} className="col-12 order-md-12 p-0">
              <EventSidebarView event={event} closeEventModal={handleOnCloseEventModal} />
            </div>
          </>}
        </SlidingPane>
      )}
      <div className="col-12 title text-muted text-uppercase mb-3">
        <i className="fa fa-map-marker mr-2 text-primary"></i>
        { title }
      </div>
      {event ? (
        <div className="col-12 row">
          <div className="col-10">
            <div className="col-12 font-weight-bold">
              { event.human_start_date }
            </div>
            <div className="col-12 mt-1">
              { event.venue.name } - { event.venue.location }
            </div>
          </div>
          <div className="col-2 d-flex justify-content-center align-items-center">
            <a onClick={handleOnShowEventModal}>
              <i className="fa fa-chevron-right text-muted"></i>
            </a>
          </div>
        </div>
      ) : (
        <div className="row col-12 d-flex justify-content-center align-items-center">
          <NoRecordWrapper className="px-3 py-1">
            <i className="fa fa-calendar mr-2"></i>
            Nothing on Record
          </NoRecordWrapper>
        </div>
      )}
    </div>
  )
};

export default ArtistEventLocalShow;
