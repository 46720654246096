import React from "react";

import { messageStyle } from "./Actions/SmsTab";
import GoToEditorButton from "./GoToEditorButton";
import PillContainer from "./PillContainer";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const headerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const boxStyle = {
  border: "1px solid #E6E6E6",
  borderRadius: "5px",
  padding: "15px",
  maxWidth: "500px",
};

const SmsPreview = ({
  action,
}) => {
  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <PillContainer
          label={"SMS Text Message 1"}
          background={"#FF585E"}
        />
        <GoToEditorButton action={action} />
      </div>
      <div style={boxStyle}>
        <div style={messageStyle}>
          {action.body}
        </div>
      </div>
    </div>
  );
};

export default SmsPreview;
