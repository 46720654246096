export class RealtimeDataMutator {
  constructor(message, confirm, ticketTypes, ticketReservation, addOns, promoCode = null) {
    this.message = message;

    this.confirm = confirm;
    this.confirmChanged = false;

    this.ticketTypes = ticketTypes;
    this.ticketTypesSoldOut = false;
    this.ticketTypesChanged = false;
    this.ticketTypeQuantityOptionsChanged = false;

    this.ticketReservation = ticketReservation;
    this.ticketReservationChanged = false;

    this.addOns = addOns;
    this.addOnsChanged = false;
    this.addOnQuantityOptionsChanged = false;

    this.currentPromoCode = promoCode;
    this.promoCodeRemoved = false;

    this.cartChanged = false;
  }

  process() {
    if(this.message.sold_out || !this.message.manifested_available) {
      this.processConfirmSoldOut();
    } else {
      this.processConfirmOnSale();
      this.processTicketTypes();
      this.processAddOns();
      this.processPromoCodes();
    }

    let newStates = {}

    if(this.confirmChanged) {
      newStates.confirm = this.confirm;
    }

    if(this.ticketReservationChanged) {
      newStates.ticketReservation = this.ticketReservation;
    }

    if(this.ticketTypeQuantityOptionsChanged) {
      newStates.ticketTypes = this.ticketTypes;
    }

    if(this.ticketTypesChanged) {
      if(this.ticketTypesSoldOut) {
        newStates.ticketTypesSoldOut = true;
      }

      newStates.ticketTypes = this.ticketTypes;
    }

    if(this.addOnsChanged || this.addOnQuantityOptionsChanged) {
      newStates.addOns = this.addOns;
    }

    if(this.promoCodeRemoved) {
      newStates.promoCodeRemoved = true;
    }

    if(this.cartChanged) {
      newStates.cartChanged = true;
    }

    return newStates;
  }

  processConfirmOnSale() {
    if(this.confirm.sold_out) {
      this.confirm.sold_out = false;
      this.confirmChanged = true;
    }
  }

  processConfirmSoldOut() {
    if(this.confirm.sold_out) {
      this.confirm.sold_out = true;
    } else {
      this.confirm.sold_out = true;
      this.confirmChanged = true;

      this.ticketReservation = {};
      this.ticketReservationChanged = true;

      this.ticketTypes.forEach((ticketType) => {
        if(ticketType.quantity) {
          delete ticketType.quantity;
          this.ticketTypesChanged = true;
          this.cartChanged = true;
        }
      });

      this.addOns.forEach((addOn) => {
        if(addOn.require_ticket_type && addOn.quantity && addOn.quantity > 0) {
          delete addOn.quantity;
          this.addOnsChanged = true;
          this.cartChanged = true;
        }
      });
    }
  }

  processTicketTypes() {
    this.message.ticket_types.forEach((ticketTypeData) => {
      var ticketType = this.ticketTypes.find(t => (t.id === ticketTypeData.id));

      if(!ticketType) {
        return;
      }

      // Update quantity options
      var quantityMax = Math.max(...ticketType.quantity_options);
      var currentQuantityMax = ticketType.current_quantity_options
        ? Math.max(...ticketType.current_quantity_options)
        : quantityMax;

      var shouldChangeQuantityOptions = (
        currentQuantityMax > ticketTypeData.available_tickets
        || (
          quantityMax > currentQuantityMax
          && ticketTypeData.available_tickets > currentQuantityMax
        )
      );

      var shouldRemoveCurrentQuantityOptions = (
        ticketType.current_quantity_options
        && ticketTypeData.available_tickets >= quantityMax
      );

      if(shouldRemoveCurrentQuantityOptions) {
        ticketType.sold_out = false;
        delete ticketType.current_quantity_options;

        this.ticketTypeQuantityOptionsChanged = true;
      } else if(shouldChangeQuantityOptions) {
        ticketType.current_quantity_options =
          ticketType.quantity_options.filter((q) => {
            return (q <= ticketTypeData.available_tickets);
          });

        this.ticketTypeQuantityOptionsChanged = true;
      }

      // Update cart quantities
      if(ticketTypeData.available_tickets < ticketType.quantity) {
        ticketType.quantity = ticketTypeData.available_tickets;

        this.ticketTypesChanged = true;
        this.cartChanged = true;
      }

      // Update ticket sold out status
      if(ticketType.sold_out && !ticketTypeData.sold_out) {
        ticketType.sold_out = false;
        this.ticketTypesChanged = true;
      } else if(ticketTypeData.sold_out || ticketTypeData.available_tickets === 0) {
        ticketType.sold_out = true;
        this.ticketTypesSoldOut = true;

        if(ticketType.quantity > -1) {
          delete ticketType.quantity;
          this.cartChanged = true;
        }

        this.ticketTypesChanged = true;
      }
    });
  }

  processAddOns() {
    this.message.add_ons.forEach((addOnData) => {
      var addOn = this.addOns.find(a => (a.id === addOnData.id));

      var stillHasRequiredTicketType = this.ticketTypes.filter((ticketType) => {
        return addOn.ticket_type_ids.includes(ticketType.id);
      }).some((ticketType) => {
        return (ticketType.quantity && ticketType.quantity > 0);
      });

      if(addOn.quantity && addOn.require_ticket_type && !stillHasRequiredTicketType) {
        delete addOn.quantity;
        this.addOnsChanged = true;
        this.cartChanged = true;
      }

      // Update quantity options
      var quantityMax = Math.max(...addOn.quantity_options);
      var currentQuantityMax = addOn.current_quantity_options
        ? Math.max(...addOn.current_quantity_options)
        : quantityMax;

      var shouldChangeQuantityOptions = (
        currentQuantityMax > addOnData.available_tickets
        || (
          quantityMax > currentQuantityMax
          && addOnData.available_tickets > currentQuantityMax
        )
      );

      var shouldRemoveCurrentQuantityOptions = (
        addOn.current_quantity_options
        && addOnData.available_tickets >= quantityMax
      );

      if(shouldRemoveCurrentQuantityOptions) {
        addOn.sold_out = false;
        delete addOn.current_quantity_options;

        this.addOnQuantityOptionsChanged = true;
      } else if(shouldChangeQuantityOptions) {
        addOn.current_quantity_options =
          addOn.quantity_options.filter((q) => {
            return (q <= addOnData.available_tickets);
          });

        this.addOnQuantityOptionsChanged = true;
      }

      // Update cart quantities
      if(addOnData.available_tickets < addOn.quantity) {
        addOn.quantity = addOnData.available_tickets;

        this.addOnsChanged = true;
        this.cartChanged = true;
      }

      // Update add_on sold out status
      if(addOn.sold_out && !addOnData.sold_out) {
        addOn.sold_out = false;
        this.addOnsChanged = true;
      } else if(addOnData.sold_out || addOnData.available_tickets === 0) {
        addOn.sold_out = true;
        this.addOnsSoldOut = true;

        if(addOn.quantity > -1) {
          delete addOn.quantity;
          this.cartChanged = true;
        }

        this.addOnsChanged = true;
      }
    });
  }

  processPromoCodes() {
    if(this.currentPromoCode === null) { return; }

    this.message.promo_codes.forEach((promoCodeData) => {
      if(promoCodeData.available_tickets === true) { return; }

      if(this.currentPromoCode.id === promoCodeData.id) {
        this.promoCodeRemoved = true;
        this.cartChanged = true;
      }
    });
  }
}
