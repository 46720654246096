import { combineReducers } from 'redux';
import {
  CONFIRM_CHANGED,
  DATA_TABLE_EL_CHANGED,
  REFUND_CHANGED,
  ALL_ORDER_IDS_CHANGED,
  SUBMITTING_REFUND_CHANGED,
  CREATING_REFUND_CHANGED,
  BUILDING_REFUND_CHANGED
} from '../constants/bulkRefundConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CHANGED:
      return action.confirm;
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const refund = (state = {}, action) => {
  switch (action.type) {
    case REFUND_CHANGED:
      return action.refund;
    default:
      return state;
  }
};

const allOrderIds = (state = [], action) => {
  switch (action.type) {
    case ALL_ORDER_IDS_CHANGED:
      return action.allOrderIds;
    default:
      return state;
  }
};

const submittingRefund = (state = false, action) => {
  switch (action.type) {
    case SUBMITTING_REFUND_CHANGED:
      return action.submittingRefund;
    default:
      return state;
  }
};

const creatingRefund = (state = false, action) => {
  switch (action.type) {
    case CREATING_REFUND_CHANGED:
      return action.creatingRefund;
    default:
      return state;
  }
};

const buildingRefund = (state = false, action) => {
  switch (action.type) {
    case BUILDING_REFUND_CHANGED:
      return action.buildingRefund;
    default:
      return state;
  }
};

const buyers = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const promoters = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const canBypassRefundRestrictions = (state = false, action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const bulkRefundReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  confirm,
  dataTableEl,
  refund,
  allOrderIds,
  submittingRefund,
  creatingRefund,
  buildingRefund,
  buyers,
  promoters,
  canBypassRefundRestrictions
});

export default bulkRefundReducer;
