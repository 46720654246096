import PropTypes from 'prop-types';
import React from 'react';

import {
  CHART_COLORS
} from '../constants/fanDashboardConstants';

import Tippy from '@tippyjs/react';
import {
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  LabelList
} from 'recharts';

const TopGenresByFans = ({
  csrfToken,
  team,
  currentUser,
  topGenres,
  isLoadingTopGenres
}) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col d-flex align-items-center">
          <p className="mb-0"
             style={{"fontSize": "20px"}}>
            <strong>
              Top Genres By Fans
            </strong>
            <Tippy content="The top genres your fans are interested in.">
              <i className="fas fa-info-circle text-muted ml-2"
                 style={{"fontSize": "16px"}}></i>
            </Tippy>
          </p>
        </div>
        {/*
        <div className="col-xs-auto d-flex align-items-center justify-content-end"
             style={{"paddingRight": "15px"}}>
          <a href="#"
             style={{"fontSize": "14px"}}
             onClick={(e) => e.preventDefault()}>
            See all
            <i className="far fa-chevron-right ml-2"></i>
          </a>
        </div>
      */}
      </div>
      {isLoadingTopGenres ? (
        <div className="row">
          <div className="col-12 text-center my-4">
            <img src="/uploading-loading.gif"
                  className="mr-1"
                  style={{width: "16px"}} />
          </div>
        </div>
      ) : (
        topGenres.length === 0 ? (
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center flex-column text-center"
                 style={{"height": "200px"}}>
              <div className="dot d-flex justify-content-center align-items-center"
                   style={{
                     "width": "44px",
                     "height": "44px",
                     "background": "#F3F9FC",
                     "borderRadius": "50%",
                     "border": "4px solid #BBE0F6"
                   }}>
                <i className="fa-regular fa-list-music text-primary" style={{"fontSize": "21px"}}></i>
              </div>
              <p className="mb-0 mt-2 text-muted"
                 style={{
                   "fontSize": "14px"
                 }}>
                <strong>The top genres your fans are interested in.</strong>
              </p>
              <p className="small mb-0 text-muted">
                No Data Yet
              </p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div style={{
                    "height": "210px",
                    "borderBottom": "1px solid #dadada"
                  }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart width={500}
                            height={300}
                            margin={{
                              top: 22,
                              right: 5,
                              bottom: 0,
                              left: 5 }}
                            data={topGenres}>
                    <Bar dataKey="count"
                        barSize={40}
                        radius={4}>
                      <LabelList
                        dataKey="approximate_count"
                        position="top"
                        fill="#333333"
                      />
                      {CHART_COLORS.map((color, index) =>
                        <Cell key={`cell-${index}`} fill={color} />
                      )}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-12"
                style={{"marginTop": "20px"}}>
              {topGenres.map((data, index) =>
                <div key={index}
                    style={{
                      "marginRight": "15px",
                      "marginBottom": "5px"
                    }}
                    className="d-inline-flex align-items-center">
                  <div className="d-inline-block"
                      style={{
                        "width": "16px",
                        "height": "16px",
                        "borderRadius": "2px",
                        "background": CHART_COLORS[index]
                      }}>
                  </div>
                  <span className="ml-2">
                    {data.name}
                  </span>
                </div>
              )}
            </div>
          </div>
        )
      )}
    </React.Fragment>
  );
};

TopGenresByFans.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  topGenres: PropTypes.array,
  isLoadingTopGenres: PropTypes.bool
};

export default TopGenresByFans;
