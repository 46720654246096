import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import {SettlementMath} from '../../SettlementMath/SettlementMath';
import SettlementTable from './SettlementTable';
import PageFrame from './PageFrame';

const getCalculated = (budget, isSettling) => {
  var calculator = new SettlementMath(budget, isSettling);
  return calculator.calculate();
}

var _ = require('lodash');

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const Settlement = ({
  budget,
  offer,
  confirm,
  createSettlementLineItem,
  csrfToken,
  team,
  currentUser,
  offerId,
  deleteSettlementLineItem,
  updateSettlementLineItem,
  itemToEdit,
  itemToEditChanged,
  submitSettlementOverrideForm,
  deleteSettlementOverride,
  requestFormat,
  isSettling,
  editedDropCount,
  editedDropCountChanged,
  editedCompCount,
  editedCompCountChanged,
  editedKillCount,
  editedKillCountChanged,
  saveOverrides,
  isSaving,
  showPageFrame,
  offerChanged,
  isSavingChanged,
  getActiveTags,
  getAvailableTags,
}) => {
  const [budgetState, setBudgetState] = useState(budget);

  useEffect(() => {
    setBudgetState((current) => {
      return {
        ...budget,
        settlement_visibilities: current.settlement_visibilities
      };
    });
  }, [budget])

  const confirmObj = confirm ? confirm : {
    name: offer.event_name,
    calendar_classification: "offer",
    human_metadata: offer.human_metadata
  };

  return (
    <PageFrame  csrfToken={csrfToken}
                currentUser={currentUser}
                confirm={confirmObj}
                showPageFrame={showPageFrame}
                offerId={offerId}
                isSettling={isSettling}
                requestFormat={requestFormat}
                isSaving={isSaving}
                team={team}>
      <>
        <ToastContainer />
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(editedDropCount !== null) {
                    var dropCount = (
                      editedDropCount === "" ? "0" : parseFloatFromString(editedDropCount)
                    );

                    saveOverrides(csrfToken, team, offerId, budget, { drop_count_override: dropCount });
                  } else if(editedCompCount !== null){
                    var compCount = (
                      editedCompCount === "" ? "0" : parseFloatFromString(editedCompCount)
                    );

                    saveOverrides(csrfToken, team, offerId, budget, { comp_count_override: compCount });
                  } else if(editedKillCount !== null){
                    var killCount = (
                      editedKillCount === "" ? "0" : parseFloatFromString(editedKillCount)
                    );

                    saveOverrides(csrfToken, team, offerId, budget, { kill_count_override: killCount });
                  }else if(itemToEdit.overridable_type === "SettlementLineItem"){
                    updateSettlementLineItem(csrfToken, team, itemToEdit);
                  } else {
                    submitSettlementOverrideForm(csrfToken, team, offerId, itemToEdit);
                  }
                }
              }>
          <SettlementTable budget={budget}
                          setBudgetState={setBudgetState}
                          offer={offer}
                          calculated={getCalculated(budgetState, isSettling)}
                          isSettling={isSettling}
                          editedDropCount={editedDropCount}
                          editedDropCountChanged={editedDropCountChanged}
                          editedCompCount={editedCompCount}
                          editedCompCountChanged={editedCompCountChanged}
                          editedKillCount={editedKillCount}
                          editedKillCountChanged={editedKillCountChanged}
                          saveOverrides={saveOverrides}
                          confirm={confirm}
                          createSettlementLineItem={createSettlementLineItem}
                          deleteSettlementLineItem={deleteSettlementLineItem}
                          requestFormat={requestFormat}
                          csrfToken={csrfToken}
                          team={team}
                          currentUser={currentUser}
                          offerId={offerId}
                          itemToEdit={itemToEdit}
                          itemToEditChanged={itemToEditChanged}
                          isSaving={isSaving}
                          offerChanged={offerChanged}
                          isSavingChanged={isSavingChanged}
                          deleteSettlementOverride={deleteSettlementOverride}
                          getActiveTags={getActiveTags}
                          getAvailableTags={getAvailableTags} />
        </form>
        <table style={{width: "100%", fontFamily: "Open Sans", fontSize: "14px"}}>
          <tbody>
            <tr>
              <td>
                {offer.additional_terms_no_tags.length > 0 ? (
                  <>
                    <hr style={{
                      height: "1px",
                      borderTop: "none",
                      borderBottom: "2px solid #e5e5e5",
                      borderLeft: "none",
                      borderRight: "none",
                      margin: "0 0 20px 0",
                      paddingTop: "20px",
                      background: "white",
                      clear: "both",
                    }} />
                    <h3>
                      <strong>Additional Terms</strong>
                    </h3>
                    {/* Additional terms are sanitized on the server */}
                    <div dangerouslySetInnerHTML={{__html: offer.sanitized_additional_terms}} />
                  </>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-3">
          <p>
            <strong>
              It is confirmed that we have read, understood and approve of the terms and conditions set forth here.
            </strong>
          </p>
          <table style={{width: "100%", fontSize: "14px"}}>
            <colgroup>
              <col style={{width: "50%"}} />
              <col style={{width: "50%"}} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <div style={{paddingRight: "10px"}}>
                    <label>Name / Company</label>
                    <input
                      type="text"
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        height: "38px",
                        border: "none",
                        borderBottom: "1px solid #a0a0a0",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div style={{paddingLeft: "10px"}}>
                    <label>Name / Company</label>
                    <input
                      type="text"
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        height: "38px",
                        border: "none",
                        borderBottom: "1px solid #a0a0a0",
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{paddingRight: "10px"}}>
                    <label>Phone / Email</label>
                    <input
                      type="text"
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        height: "38px",
                        border: "none",
                        borderBottom: "1px solid #a0a0a0",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div style={{paddingLeft: "10px"}}>
                    <label>Phone / Email</label>
                    <input
                      type="text"
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        height: "38px",
                        border: "none",
                        borderBottom: "1px solid #a0a0a0",
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{paddingRight: "10px"}}>
                    <label>Signature</label>
                    <input
                      type="text"
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        height: "38px",
                        border: "none",
                        borderBottom: "1px solid #a0a0a0",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div style={{paddingLeft: "10px"}}>
                    <label>Signature</label>
                    <input
                      type="text"
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        height: "38px",
                        border: "none",
                        borderBottom: "1px solid #a0a0a0",
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </PageFrame>
  );
};

Settlement.propTypes = {
  budget: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  confirm: PropTypes.object,
  createSettlementLineItem: PropTypes.func.isRequired,
  csrfToken: PropTypes.string,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  offerId: PropTypes.string.isRequired,
  deleteSettlementLineItem: PropTypes.func.isRequired,
  updateSettlementLineItem: PropTypes.func.isRequired,
  itemToEdit: PropTypes.object,
  itemToEditChanged: PropTypes.func.isRequired,
  submitSettlementOverrideForm: PropTypes.func.isRequired,
  deleteSettlementOverride: PropTypes.func.isRequired,
  requestFormat: PropTypes.string,
  isSettling: PropTypes.bool,
  editedDropCount: PropTypes.string,
  editedDropCountChanged: PropTypes.func.isRequired,
  editedCompCount: PropTypes.string,
  editedCompCountChanged: PropTypes.func.isRequired,
  editedKillCount: PropTypes.string,
  editedKillCountChanged: PropTypes.func.isRequired,
  saveOverrides: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  showPageFrame: PropTypes.bool,
  offerChanged: PropTypes.func.isRequired,
  isSavingChanged: PropTypes.func.isRequired,
  getActiveTags: PropTypes.func.isRequired,
  getAvailableTags: PropTypes.func.isRequired,
};

export default Settlement;
