/* eslint-disable import/prefer-default-export */

import {
  INDEX_CHANGED
} from '../constants/agentsTableConstants';

export const indexChanged = (index) => ({
  type: INDEX_CHANGED,
  index
});
