/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import {
  CLOSE_CALENDAR_EVENT_ATTACHMENT_MODAL,
  SET_OPEN_CALENDAR_EVENT_ATTACHMENT,
  OPEN_CALENDAR_EVENT_ATTACHMENT_PDF_NUMBER_OF_PAGES_CHANGED,
  EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME,
  SET_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME_EL,
  OPEN_CALENDAR_EVENT_ATTACHMENT_UPDATE_SUCCESS,
  END_EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME,
  TOGGLE_FOLDERER_ITEM_SELECTED,
  STAGE_FOLDERED_ITEMS_FOR_DESTROY,
  COPIED_FOLDERED_ITEM_CHANGED,
  REMOVE_FOLDERED_ITEMS_FROM_SELECTED,
  COPIED_FOLDERED_ITEM_NAME_CHANGED,
  STAGE_FOLDERED_ITEMS_FOR_MOVE,
  PERMISSION_SET_CHANGED,
  PERMISSION_RULE_ENABLED_CLICKED,
  SELECT_ALL_PERMISSION_RULES_CLICKED,
  ON_PERMISSION_SET_SAVED_CHANGED,
  TARGET_FOLDER_CHANGED,
  FOLDER_COPY_CHANGED,
  FOLDER_COPY_PAGINATED_DATA_FETCHED,
  TOGGLE_FOLDER_COPY,
  FOLDER_COPY_LOADING_PAGINATED_DATA_CHANGED,
  IS_ADDING_FOLDER_CHANGED,
  NEW_FOLDER_NAME_CHANGED,
  IS_UPLOADING_FILES_CHANGED,
  SELECTED_FILES_TO_UPLOAD_CHANGED,
  IS_LOADING_UPLOADED_FILES_CHANGED,
  GUEST_TEAM_MEMBERSHIPS_CHANGED,
  PROMOTERS_WITH_PERMISSION_CHANGED,
  TARGET_FOLDER_SEARCH_TERM_CHANGED,
  CALENDAR_EVENT_ATTACHMENT_TO_COPY_CHANGED,
  IS_COPYING_CALENDAR_EVENT_ATTACHMENT_CHANGED,
  DRAGGED_FILES_TO_UPLOAD_CHANGED,
  FOLDER_CHANGED
} from '../constants/folderViewConstants';

const traverseFolderAndFolderedItems = (item, transform) => {
  var updates = {};
  var transformed = transform(item);

  if(transformed.foldered_items){
    updates.foldered_items = [...transformed.foldered_items].map((folderedItem) => {
      var updatedItem = traverseFolderAndFolderedItems(folderedItem.item, transform);
      return Object.assign({}, folderedItem, {item: updatedItem});
    });
  }

  return Object.assign({}, transformed, updates);
}

export const closeCalendarEventAttachmentModal = () => ({
  type: CLOSE_CALENDAR_EVENT_ATTACHMENT_MODAL
});

export const setOpenCalendarEventAttachment = (calendarEventAttachment) => ({
  type: SET_OPEN_CALENDAR_EVENT_ATTACHMENT,
  calendarEventAttachment
});

export const deleteCalendarEventAttachment = (dispatch, csrfToken, team, calendarEventAttachment) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/foldered_items/bulk_destroy", {
        data: {
          ids: [calendarEventAttachment.foldered_item.id]
        }
      })
      .then(({ data }) => {
        dispatch(folderedItemsDeleted(dispatch, [calendarEventAttachment.foldered_item]));
        dispatch(removeFolderedItemsFromSelected([calendarEventAttachment.foldered_item]));
      })
      .catch((error) => {
        // dispatch(updateEventToEditErrors(error.response.data));
      });
  };
};

export const fetchCalendarEventAttachment = (dispatch, team, calendarEventAttachmentId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/calendar_event_attachments/" + calendarEventAttachmentId)
      .then(({ data }) => {
        dispatch(setOpenCalendarEventAttachment(data));
      })
  };
};

export const openCalendarEventAttachmentPdfNumberOfPagesChanged = (numPages) => ({
  type: OPEN_CALENDAR_EVENT_ATTACHMENT_PDF_NUMBER_OF_PAGES_CHANGED,
  numPages
});

export const editOpenCalendarEventAttachmentName = () => ({
  type: EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME
});

export const updateCalendarEventAttachment = (dispatch, csrfToken, team, calendarEventAttachment, newName) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/calendar_event_attachments/" + calendarEventAttachment.id, {
        calendar_event_attachment: {
          human_file_name: newName
        }
      })
      .then(({ data }) => {
        dispatch(openCalendarEventAttachmentUpdateSuccess(data));
        dispatch(updateCalendarEventAttachmentInFolder(dispatch, data));
      });
  };
};

export const setOpenCalendarEventAttachmentNameEl = (el) => ({
  type: SET_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME_EL,
  el
});

export const openCalendarEventAttachmentUpdateSuccess = (calendarEventAttachment) => ({
  type: OPEN_CALENDAR_EVENT_ATTACHMENT_UPDATE_SUCCESS,
  calendarEventAttachment
});

export const updateCalendarEventAttachmentInFolder = (dispatch, calendarEventAttachment) => {
  return (dispatch, getState) => {
    var updated = traverseFolderAndFolderedItems(getState().folder, (item) => {
      if(!item.is_folder && item.id === calendarEventAttachment.id){
        return Object.assign({}, item, {
          human_file_name: calendarEventAttachment.human_file_name
        });
      } else {
        return item;
      }
    });

    getState().onFolderChanged(updated);
  };
};

export const folderChanged = (folder) => ({
  type: FOLDER_CHANGED,
  folder
});

export const endEditOpenCalendarEventAttachmentName = () => ({
  type: END_EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME
});

export const createFolder = (dispatch, csrfToken, team, confirm, folder, newFolderName) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/folders", {
        folder: {
          human_file_name: newFolderName,
          calendar_event_id: confirm.id,
          parent_foldered_item_attributes: {
            folder_id: folder.id,
          }
        }
      })
      .then(({ data }) => {
        var folderedItem = data;

        toast.success("Folder has been added", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        dispatch(isAddingFolderChanged(false));
        dispatch(folderedItemsCreated(dispatch, [folderedItem], folder));
        dispatch(copiedFolderedItemChanged(folderedItem));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const toggleFolder = (dispatch, folder) => {
  return (dispatch, getState) => {
    var updated = traverseFolderAndFolderedItems(getState().folder, (item) => {
      if(item.is_folder && item.id === folder.id){
        return Object.assign({}, item, {
          extended: !item.extended
        });
      } else {
        return item;
      }
    });

    getState().onFolderChanged(updated);
  };
};

export const togglefolderedItemSelected = (folderedItem) => ({
  type: TOGGLE_FOLDERER_ITEM_SELECTED,
  folderedItem
});

export const folderedItemsCreated = (dispatch, folderedItemsToAdd, targetFolder) => {
  return (dispatch, getState) => {
    var updated = traverseFolderAndFolderedItems(getState().folder, (item) => {
      if(item.is_folder && item.id === targetFolder.id){
        var newFolderedItems = [...item.foldered_items, ...folderedItemsToAdd];

        return Object.assign({}, item, {
          foldered_items_count: (item.foldered_items_count + folderedItemsToAdd.length),
          foldered_items: newFolderedItems
        });
      } else {
        return item;
      }
    });

    getState().onFolderChanged(updated);
  };
};

export const stageFolderedItemsForDestroy = (folderedItems) => ({
  type: STAGE_FOLDERED_ITEMS_FOR_DESTROY,
  folderedItems
});

export const deleteStagedFolderedItems = (dispatch, csrfToken, team, folderedItemsToDestroy) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/foldered_items/bulk_destroy", {
        data: {
          ids: folderedItemsToDestroy.map((fi) => fi.id)
        }
      })
      .then(({ data }) => {
        dispatch(folderedItemsDeleted(dispatch, folderedItemsToDestroy));
        dispatch(stageFolderedItemsForDestroy([]));
        dispatch(removeFolderedItemsFromSelected(folderedItemsToDestroy));
      })
      .catch((error) => {
        // dispatch(updateEventToEditErrors(error.response.data));
      });
  };
};

export const copiedFolderedItemChanged = (folderedItem) => ({
  type: COPIED_FOLDERED_ITEM_CHANGED,
  folderedItem
});

export const removeFolderedItemsFromSelected = (folderedItems) => ({
  type: REMOVE_FOLDERED_ITEMS_FROM_SELECTED,
  folderedItems
});

export const copiedFolderedItemNameChanged = (name) => ({
  type: COPIED_FOLDERED_ITEM_NAME_CHANGED,
  name
});

export const updateFolderedItemName = (dispatch, csrfToken, team, folderedItem) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var url;
    if(folderedItem.item.is_folder){
      url = "/teams/" + team.id + "/folders/" + folderedItem.item.id;
    } else {
      url = "/teams/" + team.id + "/calendar_event_attachments/" + folderedItem.item.id;
    }

    var params;
    if(folderedItem.item.is_folder){
      params = {
        folder: {
          human_file_name: folderedItem.item.human_file_name
        }
      };
    } else {
      params = {
        calendar_event_attachment: {
          human_file_name: folderedItem.item.human_file_name
        }
      };
    }

    return axios.patch(url, params)
      .then(({ data }) => {
        // CalendarEventAttachmentsController#update returns
        // as_json instead of to_list_view...
        if(!folderedItem.item.is_folder){
          var item = Object.assign({}, folderedItem.item, {human_file_name: data.human_file_name});
          data = Object.assign({}, folderedItem, {item: item});
        }

        dispatch(folderedItemUpdated(dispatch, data));
        dispatch(copiedFolderedItemChanged({}));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const folderedItemUpdated = (dispatch, folderedItem) => {
  return (dispatch, getState) => {
    var updated = traverseFolderAndFolderedItems(getState().folder, (item) => {
      var hasFolderedItem = (
        item.foldered_items
          && item.foldered_items.some((fi) => fi.id === folderedItem.id)
      );

      if(hasFolderedItem){
        var folderedItems = [...item.foldered_items].map((fi) => {
          if(fi.id === folderedItem.id){
            return folderedItem;
          } else {
            return fi;
          }
        });

        return Object.assign({}, item, {
          foldered_items: folderedItems
        });
      } else {
        return item;
      }
    });

    getState().onFolderChanged(updated);
  };
};

export const stageFolderedItemsForMove = (folderedItems) => ({
  type: STAGE_FOLDERED_ITEMS_FOR_MOVE,
  folderedItems
});

export const moveStagedFolderedItems = (dispatch, csrfToken, team, folderedItemsToMove, targetFolder) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/foldered_items/bulk_update", {
        ids: folderedItemsToMove.map((fi) => fi.id),
        foldered_item: {
          folder_id: targetFolder.id
        }
      })
      .then(({ data }) => {
        toast.success("Move was successful...", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        dispatch(stageFolderedItemsForMove([]));
        dispatch(removeFolderedItemsFromSelected(folderedItemsToMove));
        dispatch(folderedItemsMoved(dispatch, folderedItemsToMove, targetFolder));
      });
  };
};

export const folderedItemsMoved = (dispatch, folderedItems, targetFolder) => {
  return (dispatch, getState) => {
    var movedIds = [...folderedItems.map((fi) => fi.id)];

    var deletePass = traverseFolderAndFolderedItems(getState().folder, (item) => {
      if (item.foldered_items){
        var numberOfDeletions = item.foldered_items.filter((fi) => movedIds.includes(fi.id)).length;
        var folderedItems = item.foldered_items.filter((fi) => !movedIds.includes(fi.id));

        return Object.assign({}, item, {
          foldered_items_count: (item.foldered_items_count - numberOfDeletions),
          foldered_items: folderedItems
        });
      } else {
        return item;
      }
    });

    var addPass = traverseFolderAndFolderedItems(deletePass, (item) => {
      if (item.is_folder && item.id === targetFolder.id){
        return Object.assign({}, item, {
          foldered_items_count: (item.foldered_items_count + folderedItems.length),
          foldered_items: [...item.foldered_items, ...folderedItems]
        });
      } else {
        return item;
      }
    });

    getState().onFolderChanged(addPass);
  };
};

export const folderedItemsDeleted = (dispatch, folderedItems) => {
  return (dispatch, getState) => {
    var deletedIds = folderedItems.map((fi) => fi.id);

    var updated = traverseFolderAndFolderedItems(getState().folder, (item) => {
      if(item.foldered_items){
        var numberOfDeletions = item.foldered_items.filter((fi) => deletedIds.includes(fi.id)).length;
        var folderedItems = item.foldered_items.filter((fi) => !deletedIds.includes(fi.id));

        return Object.assign({}, item, {
          foldered_items_count: (item.foldered_items_count - numberOfDeletions),
          foldered_items: folderedItems
        });
      } else {
        return item;
      }
    });

    getState().onFolderChanged(updated);
  };
};

export const permissionSetChanged = (permissionSet) => ({
  type: PERMISSION_SET_CHANGED,
  permissionSet
});

export const fetchPermissionSet = (dispatch, team, permissionSet, onPermissionSetSaved) => {
  return dispatch => {
    var endpoint;

    dispatch(onPermissionSetSavedChanged(onPermissionSetSaved));

    if(permissionSet && permissionSet.id){
      endpoint = "/teams/" + team.id + "/permission_sets/" + permissionSet.id + "/edit";
    } else {
      endpoint = "/teams/" + team.id + "/permission_sets/new";
    }

    return axios.get(endpoint)
      .then(({ data }) => {
        permissionSet = Object.assign({}, data, permissionSet);
        dispatch(permissionSetChanged(permissionSet));
      });
  };
};

export const onPermissionSetSavedChanged = (onPermissionSetSaved) => ({
  type: ON_PERMISSION_SET_SAVED_CHANGED,
  onPermissionSetSaved
});

export const permissionRuleEnabledClicked = (permissionRule) => ({
  type: PERMISSION_RULE_ENABLED_CLICKED,
  permissionRule
});

export const selectAllPermissionRulesClicked = (checked) => ({
  type: SELECT_ALL_PERMISSION_RULES_CLICKED,
  checked
});

export const submitPermissionSet = (dispatch, csrfToken, team, permissionSet, onPermissionSetSaved) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var updating = (permissionSet && permissionSet.id);
    var method = (updating ? "patch" : "post");
    var url = (updating ? (
      "/teams/" + team.id + "/permission_sets/" + permissionSet.id
    ) : (
      "/teams/" + team.id + "/permission_sets"
    ));

    var permissionRulesAttributes = permissionSet.permission_rules.map((permissionRule) => {
      return {
        id: permissionRule.id,
        enabled: permissionRule.enabled,
        rulable_type: permissionRule.rulable_type,
        rulable_id: permissionRule.rulable_id
      };
    });

    return axios.request({
        method: method,
        url: url,
        data: {
          permission_set: {
            element_id: permissionSet.element_id,
            element_type: permissionSet.element_type,
            permission_rules_attributes: permissionRulesAttributes
          }
        }
      })
      .then(({ data }) => {
        onPermissionSetSaved(data);
        dispatch(permissionSetChanged({}));

        toast.success("Permissions saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const fetchFolderedItemsForFolder = (dispatch, team, folder, searchTerm, sortBy) => {
  return (dispatch, getState) => {
    var nextPage = 1;

    if(folder.lastRequestData) {
      nextPage = (folder.lastRequestData.current_page + 1);
    }

    var params = {
      page: nextPage
    };

    if(searchTerm){
      params = {...params, rsearch: searchTerm};
    }

    if(Object.keys(sortBy).length > 0){
      params = {
        ...params,
        sort_by_column: sortBy.column,
        sort_by_direction: sortBy.direction
      };
    }

    dispatch(folderLoadingPaginatedDataChanged(dispatch, folder, true));

    return axios.get("/teams/" + team.id + "/folders/" + folder.id + "/foldered_items", {
        params: params
      })
      .then(({ data }) => {
        var updated = traverseFolderAndFolderedItems(getState().folder, (item) => {
          if(item.id === folder.id){
            var folderedItems = _.uniqBy([...item.foldered_items, ...data.collection], "id");

            return Object.assign({}, item, {
              foldered_items: folderedItems,
              lastRequestData: data
            });
          } else {
            return item;
          }
        });

        getState().onFolderChanged(updated);
      });
  };
};

export const targetFolderChanged = (targetFolder) => ({
  type: TARGET_FOLDER_CHANGED,
  targetFolder
});

export const folderCopyChanged = (folderCopy) => ({
  type: FOLDER_COPY_CHANGED,
  folderCopy
});

export const fetchFolderedItemsForFolderCopy = (dispatch, team, folder, searchTerm) => {
  return dispatch => {
    var nextPage = 1;

    if(folder.lastRequestData) {
      nextPage = (folder.lastRequestData.current_page + 1);
    }

    var params = {
      page: nextPage,
      "q[item_type_eq]": "Folder"
    };

    if(searchTerm){
      params = {...params, rsearch: searchTerm};
    }

    dispatch(folderCopyLoadingPaginatedDataChanged(folder, true));

    return axios.get("/teams/" + team.id + "/folders/" + folder.id + "/foldered_items", {
        params: params
      })
      .then(({ data }) => {
        dispatch(folderCopyPaginatedDataFetched(folder, data));
      })
      .finally(() => {
        dispatch(folderCopyLoadingPaginatedDataChanged(folder, false));
      });
  };
};

export const folderCopyPaginatedDataFetched = (folder, data) => ({
  type: FOLDER_COPY_PAGINATED_DATA_FETCHED,
  folder,
  data
});

export const toggleFolderCopy = (folder) => ({
  type: TOGGLE_FOLDER_COPY,
  folder
});

export const folderLoadingPaginatedDataChanged = (dispatch, folder, isLoadingPaginatedData) => {
  return (dispatch, getState) => {
    var updated = traverseFolderAndFolderedItems(getState().folder, (item) => {
      if(item.is_folder && item.id === folder.id){
        return Object.assign({}, item, {
          isLoadingPaginatedData: isLoadingPaginatedData
        });
      } else {
        return item;
      }
    });

    getState().onFolderChanged(updated);
  };
};

export const folderCopyLoadingPaginatedDataChanged = (folder, isLoadingPaginatedData) => ({
  type: FOLDER_COPY_LOADING_PAGINATED_DATA_CHANGED,
  folder,
  isLoadingPaginatedData
});

export const isAddingFolderChanged = (isAddingFolder) => ({
  type: IS_ADDING_FOLDER_CHANGED,
  isAddingFolder
});

export const newFolderNameChanged = (newFolderName) => ({
  type: NEW_FOLDER_NAME_CHANGED,
  newFolderName
});

export const isUploadingFilesChanged = (isUploadingFiles) => ({
  type: IS_UPLOADING_FILES_CHANGED,
  isUploadingFiles
});

export const selectedFilesToUploadChanged = (selectedFilesToUpload) => ({
  type: SELECTED_FILES_TO_UPLOAD_CHANGED,
  selectedFilesToUpload
});

export const uploadFilesToFolder = (dispatch, csrfToken, team, confirm, files, folder, dragged=false, directoryPresent=false) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    if(directoryPresent) {
      toast.error("Sorry, uploading folders is not supported", {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    }

    if(files.length === 0) {
      return;
    }

    if(dragged) {
      dispatch(draggedFilesToUploadChanged(files));
    } else {
      dispatch(isLoadingUploadedFilesChanged(true));
    }

    var params = new FormData();
    Array.from(files).forEach((file) => {
      params.append("calendar_event_attachment[files][]", file);
    });
    if(confirm && confirm.id) {
      params.set('calendar_event_attachment[calendar_event_id]', confirm.id);
    }
    params.set('calendar_event_attachment[foldered_item_attributes][folder_id]', folder.id);

    return axios.post("/teams/" + team.id + "/calendar_event_attachments/bulk_create", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        toast.success("Files have been uploaded", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        if(dragged) {
          dispatch(draggedFilesToUploadChanged([]));
        } else {
          dispatch(isUploadingFilesChanged(false));
        }

        dispatch(folderedItemsCreated(dispatch, data, folder));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .then(() => {
        if(dragged) {
          dispatch(draggedFilesToUploadChanged([]));
        } else {
          dispatch(isUploadingFilesChanged(false));
        }
      });
  };
};

export const isLoadingUploadedFilesChanged = (isLoadingUploadedFiles) => ({
  type: IS_LOADING_UPLOADED_FILES_CHANGED,
  isLoadingUploadedFiles
});

export const fetchGuestsAndPromotersForPermissions = (dispatch, team, confirmId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/confirms/" + confirmId + "/guests_and_promoters_for_permissions")
      .then(({ data }) => {
        dispatch(guestTeamMembershipsChanged(data.guests));
        dispatch(promotersWithPermissionChanged(data.promoters));
      })
  };
};

export const guestTeamMembershipsChanged = (guestTeamMemberships) => ({
  type: GUEST_TEAM_MEMBERSHIPS_CHANGED,
  guestTeamMemberships
});

export const promotersWithPermissionChanged = (promotersWithPermission) => ({
  type: PROMOTERS_WITH_PERMISSION_CHANGED,
  promotersWithPermission
});

export const targetFolderSearchTermChanged = (targetFolderSearchTerm) => ({
  type: TARGET_FOLDER_SEARCH_TERM_CHANGED,
  targetFolderSearchTerm
});

export const calendarEventAttachmentToCopyChanged = (calendarEventAttachmentToCopy) => ({
  type: CALENDAR_EVENT_ATTACHMENT_TO_COPY_CHANGED,
  calendarEventAttachmentToCopy
});

export const createCalendarEventAttachmentCopy = (dispatch, csrfToken, team, folder, calendarEventAttachment) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    dispatch(isCopyingCalendarEventAttachmentChanged(true));

    return axios.post("/teams/" + team.id + "/calendar_event_attachments/" + calendarEventAttachment.id + "/copy", {
        calendar_event_attachment: {
          human_file_name: calendarEventAttachment.human_file_name,
          foldered_item_attributes: {
            folder_id: folder.id,
          }
        }
      })
      .then(({ data }) => {
        var folderedItem = data;

        toast.success("File has been copied", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        dispatch(calendarEventAttachmentToCopyChanged({}));
        dispatch(folderedItemsCreated(dispatch, [folderedItem], folder));
        dispatch(copiedFolderedItemChanged(folderedItem));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        dispatch(isCopyingCalendarEventAttachmentChanged(false));
      });
  };
};

export const isCopyingCalendarEventAttachmentChanged = (isCopyingCalendarEventAttachment) => ({
  type: IS_COPYING_CALENDAR_EVENT_ATTACHMENT_CHANGED,
  isCopyingCalendarEventAttachment
});

export const draggedFilesToUploadChanged = (draggedFilesToUpload) => ({
  type: DRAGGED_FILES_TO_UPLOAD_CHANGED,
  draggedFilesToUpload
});
