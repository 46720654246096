import React from "react";
import axios from "axios";

import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";
import SendDomainStatus from "./SendDomainStatus";
import EmailFromAddressInput from "./EmailFromAddressInput";
import RowKebabMenu from "./RowKebabMenu";
import SetupInstructions from "./SetupInstructions";
import { toastSuccess, toastError } from "../../../../shared/toastHelper";

const columnStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 20,
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 10,
  paddingBottom: 10,
  paddingTop: 10,
};

const ctaButtonStyle = {
  backgroundColor: "white",
  border: "#1982C4 1px solid",
  borderRadius: 5,
  color: "#1982C4",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  height: "fit-content",
  padding: "10px 12px",
};

const labelStyle = {
  color: "#333333",
  fontWeight: 700,
};

const rowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const inputStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  color: "#3333333",
  fontSize: 14,
  height: 40,
  marginRight: 15,
  padding: 8,
  width: 250,
};

const SendDomainRow = ({
  sendDomain
}) => {
  const {
    csrfToken,
    team,
    venueOwnership,
    sendDomainModified,
    sendDomainCreated,
    updateSendDomain,
  } = useMarketingSettingsContext();
  const persistedSendDomain = !!sendDomain.id;
  const verifyDomain = (e) => {
    e.preventDefault();

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/venue_ownerships/${venueOwnership.id}/send_domains/${sendDomain.id}/verify`)
      .then(({ data }) => {
        sendDomainModified(data);

        if (data.verified) {
          toastSuccess("Domain verified, you can now test the email from address.");
        } else {
          toastError("Domain verification failed, please wait a few minutes and try again.");
        }
      })
      .catch((_error) => {
        toastError("Domain verification failed");
      });
  };

  const createSendDomain = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/venue_ownerships/${venueOwnership.id}/send_domains`, {
      send_domain: {
        domain: sendDomain.domain,
        from_address: sendDomain.from_address
      }
    })
      .then(({ data }) => {
        toastSuccess("Send domain added successfully");
        sendDomainCreated(venueOwnership, data);
      })
      .catch(({ response }) => {
        toastError(response.data.errors.join(", "));
      });
  };

  return (
    <div style={containerStyle}>
      {sendDomain.verified ? (
        <>
          <div style={rowStyle}>
            <SendDomainStatus sendDomain={sendDomain} />
            <div style={rowStyle}>
              <button onClick={() => updateSendDomain(sendDomain)} style={ctaButtonStyle}>Update</button>
              <RowKebabMenu sendDomain={sendDomain}/>
            </div>
          </div>
          <div style={labelStyle}>Sender Name:</div>
          <input
            placeholder="Your Venue"
            type="text"
            value={sendDomain.name || ""}
            onChange={(e) => sendDomainModified({ ...sendDomain, name: e.target.value })}
            style={inputStyle}
          />
          <EmailFromAddressInput
            sendDomain={sendDomain}
          />
        </>
      ) : persistedSendDomain ? (
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={rowStyle}>
              <SendDomainStatus sendDomain={sendDomain} />
              <div style={rowStyle}>
                <button onClick={verifyDomain} style={ctaButtonStyle}>Verify Domain</button>
                <RowKebabMenu sendDomain={sendDomain}/>
              </div>
            </div>
            <SetupInstructions
              sendDomain={sendDomain}
            />
          </div>
        </div>
      ) : (
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div>
              <div style={labelStyle}>Domain Address:</div>
              <input
                type="text"
                style={inputStyle}
                value={sendDomain.domain || ""}
                onChange={(e) => sendDomainModified({ ...sendDomain, domain: e.target.value })}
                placeholder="yourvenue.com"
              />
            </div>
            <EmailFromAddressInput
              sendDomain={sendDomain}
            />
            <div>
              <button onClick={createSendDomain} style={ctaButtonStyle}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendDomainRow;
