import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";

import AvailableCampaignCard from "./AvailableCampaignCard";
import { useCampaignNewContext } from "./CampaignNewContainer";

const containerStyle = {
  background: "white",
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "grid",
  width: "100%",
  padding: "20px",
  borderRadius: 4,
  display: "flex",
  flexDirection: "column",
  gap: "40px",
};

const selectStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  color: "#333333",
  fontSize: 14,
  height: 40,
  margin: "10px 0",
  padding: 8,
  width: 250,
};

const titleStyle = {
  color: "#333333",
  fontSize: "16px",
  fontWeight: 600,
}

const headerStyle = {
  color: "#333333",
  fontSize: "16px",
  fontWeight: 600,
};

const createCampaignHeaderStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const comingSoonStyle = {
  borderRadius: 4,
  background: "#8AC926",
  color: "white",
  padding: "2px 5px",
  fontSize: 12,
  fontWeight: 700,
};

const subHeaderStyle = {
  color: "#333333",
  opacity: 0.8,
  fontSize: "12px",
  fontWeight: 400,
  marginBottom: 15,
};

const newCampaignOptionsStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "20px",
};

const comingSoonButtonStyle = {
  width: "300px",
  minHeight: "96px",
  backgroundColor: "#fff",
  border: "2px solid #ccc",
  borderRadius: "10px",
};

const campaignsContainerStyle = {
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "repeat(auto-fill, minmax(350px, 350px))",
};

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const CampaignNew = () => {
  const {
    team,
    venueOwnerships,
    venueOwnership,
    setVenueOwnership,
    isLoading,
    setIsLoading,
    fetchCampaigns,
    availableCampaignTemplates,
    startCampaign,
  } = useCampaignNewContext();
  const [selectedCampaignTemplate, setSelectedCampaignTemplate] = useState(null);

  const handleVenueOwnershipChange = (e) => {
    e.preventDefault();

    setVenueOwnership(venueOwnerships[e.target.value])
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCampaigns(venueOwnership.id);
  }, [venueOwnership]);

  return (
    <div>
      <div style={titleStyle}>Campaigns</div>
      <select value={venueOwnerships.findIndex(o => o.id === venueOwnership.id)} onChange={handleVenueOwnershipChange} style={selectStyle}>
        {venueOwnerships.map((option, index) => (
          <option key={`${option.venueable.name}-${index}`} value={index}>
            {option.venueable.name}
          </option>
        ))}
      </select>
      {isLoading ? (
        <img src="/uploading-loading.gif" className="mr-1" style={{width: "16px"}} />
      ) : (
        <div style={containerStyle}>
          <div>
            <div style={createCampaignHeaderStyle}>
              <div style={headerStyle}>Create a Campaign From Scratch</div>
              <div style={comingSoonStyle}>Coming Soon!</div>
            </div>
            <div style={subHeaderStyle}>
              Build your own campaign based on behaviors or events
            </div>
            <div style={newCampaignOptionsStyle}>
              <Tippy content="Coming soon!">
                <span>
                  <button
                    type="button"
                    disabled
                    className="d-flex align-items-center p-3 text-muted"
                    style={comingSoonButtonStyle}
                    onClick={() => setBroadcastType("sms")}
                  >
                    <div style={{ fontSize: "16px" }}> <i className="far fa-envelope mr-1" /></div>
                    <div className="ml-3 text-left">
                      <p className="mb-0" style={{ fontSize: "16px" }}>
                        <strong>
                          Email
                        </strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        Make announcements about upcoming events or new announcements.
                      </p>
                    </div>
                  </button>
                </span>
              </Tippy>
              <Tippy content="Coming soon!">
                <span>
                  <button
                    type="button"
                    disabled
                    className="d-flex align-items-center p-3 text-muted"
                    style={comingSoonButtonStyle}
                    onClick={() => setBroadcastType("sms")}
                  >
                    <div style={{ fontSize: "16px" }}>
                      <i className="far fa-comment mr-1" />
                    </div>
                    <div className="ml-3 text-left">
                      <p className="mb-0" style={{ fontSize: "16px" }}>
                        <strong>
                          SMS
                        </strong>
                      </p>
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        Send important text updates to fans.
                      </p>
                    </div>
                  </button>
                </span>
              </Tippy>
            </div>
          </div>
          <div>
            <div style={headerStyle}>Run a campaign playbook</div>
            <div style={subHeaderStyle}>Choose from some prebuilt automated campaigns</div>
            <div style={campaignsContainerStyle}>
              {availableCampaignTemplates.map((campaignTemplate, index) => (
                <AvailableCampaignCard
                  key={index}
                  campaignTemplate={campaignTemplate}
                  handleClick={() => setSelectedCampaignTemplate(campaignTemplate)}
                  styleOverrides={{
                    border: selectedCampaignTemplate?.id === campaignTemplate.id ? "2px solid #1982C4" : "2px solid #CCCCCC",
                  }}
                />
              ))}
            </div>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 10,
          }}>
            <a href={`/teams/${team.id}/campaigns?venue_ownership_id=${venueOwnership.id}`} style={backButtonStyle}>
              Back
            </a>
            <button
              disabled={!selectedCampaignTemplate}
              type="button"
              className="ml-2 btn btn-primary"
              onClick={e => {
                e.preventDefault();
                startCampaign(selectedCampaignTemplate);
              }}
            >
              Create
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignNew;
