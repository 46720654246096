/* eslint-disable import/prefer-default-export */

import {
  USER_CHANGED,
  FILE_EL_CHANGED,
  ORIGINAL_USER_CHANGED
} from '../constants/userSettingsConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const userChanged = (user) => ({
  type: USER_CHANGED,
  user
});

export const updateUser = (dispatch, csrfToken, user) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var params = new FormData();
    params.append('user[first_name]', user.first_name);
    params.append('user[last_name]', user.last_name);
    params.append('user[email]', user.email);

    if(user.password && user.password.length > 0){
      params.append('user[current_password]', user.current_password);
      params.append('user[password]', user.password);
      params.append('user[password_confirmation]', user.password_confirmation);
    }

    if(user.avatarFile){
      params.append("user[avatar]", user.avatarFile);
    }

    return axios.patch("/settings/current", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        dispatch(userChanged(data));
        dispatch(originalUserChanged(data));

        toast.success("Settings have been saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const fileElChanged = (fileEl) => ({
  type: FILE_EL_CHANGED,
  fileEl
});

export const originalUserChanged = (originalUser) => ({
  type: ORIGINAL_USER_CHANGED,
  originalUser
});

export const updateUserHomepage = (dispatch, csrfToken, user) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/settings/current", {
        user: {
          homepage: user.homepage
        }
      })
      .then(({ data }) => {
        toast.success("Homepage has been saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const updateUserTimezone = (dispatch, csrfToken, user) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/settings/current", {
        user: {
          time_zone: user.time_zone
        }
      })
      .then(({ data }) => {
        toast.success("Timezone has been saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};
