import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import Flatpickr from "react-flatpickr";
import Tippy from '@tippyjs/react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import ManageFinanceItemsTable from './ManageFinanceItemsTable';

const axios = require('axios').default;

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "150px",
  "padding": "8px"
}

const canAddNewFinanceItem = (financeItems) => {
  return !financeItems.some((fi) => !fi.id);
}

const sortedFinanceItems = (financeItems) => {
  return financeItems.sort(
    (a, b) => {
      return (
        ((a.id != null ? Infinity : 0) - (b.id != null ? Infinity : 0))
          ||  new Date(b.issued_on)) - (new Date(a.issued_on)
      );
    }
  )
}

const incomeFinanceItems = (financeItems) => {
  return sortedFinanceItems(financeItems.filter(financeItem => financeItem.is_income));
};

const expenseFinanceItems = (financeItems) => {
  return sortedFinanceItems(financeItems.filter(financeItem => !financeItem.is_income));
};

const ManageFinanceItems = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  financeItems,
  financeItemsChanged,
  fetchNewFinanceItem,
  deleteFinanceItem,
  financeItemToEdit,
  financeItemToEditChanged,
  financeItemForForm,
  financeItemForFormChanged,
  financeCategories,
  financeCategoriesChanged,
  performers,
  createFinanceItem,
  updateFinanceItem,
  offers,
  offerChanged,
  updateDeposit,
}) => {
  useEffect(() => {
    if(Object.keys(financeItemToEdit).length === 0) {
      financeItemsChanged(financeItems.filter(financeItem => financeItem.id));
    }
  }, [financeItemToEdit]);

  return (
    <div>
      <div className={"card-body " + (financeItems.length > 0 ? "pb-0" : "")}>
        <div className={"row " + (financeItems.length > 0 ? "pb-0" : "pb-3")}>
          <div className="col">
            <h5 className="strong">Finances</h5>
          </div>
          {currentUser.can_manage_finance_items && financeItems.length > 0 ? (
            <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
              <Popup arrow={false}
                     offsetY={5}
                     position="bottom right"
                     contentStyle={popupContentStyle}
                     onOpen={
                       (e) => {
                         e.preventDefault();
                       }
                     }
                     trigger={open => (
                        <a href="#"
                           className={"btn btn-external btn-sm " + (canAddNewFinanceItem(financeItems) ? "" : "disabled")}>
                          <strong>New Item</strong>
                        </a>
                     )} >
                {close => (
                  <div className='row text-left'>
                    <div className="col-12">
                      <ul className="list-group offer-inline-menu highlight-primary">
                        <li className="list-group-item">
                          <a href="#"
                             className="text-muted"
                             onClick={e => {
                               e.preventDefault();
                               fetchNewFinanceItem(team, true);
                               close();
                             }
                          }>
                            <span className="d-inline-block text-center" style={{width: "28px"}}>
                              <i className="fas fa-hand-holding-dollar"></i>
                            </span>
                            Income
                          </a>
                        </li>
                        <li className="list-group-item">
                          <a href="#"
                             className="text-muted"
                             onClick={e => {
                               e.preventDefault();
                               fetchNewFinanceItem(team, false);
                               close();
                             }
                          }>
                            <span className="d-inline-block text-center" style={{width: "28px"}}>
                              <i className="fas fa-receipt"></i>
                            </span>
                            Expense
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
          ) : null }
        </div>
        {financeItems.length === 0 ? (
          <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 order-md-2 text-left text-md-center d-flex justify-content-center align-items-center">
                  <i className="fal fa-dollar-sign"
                     style={{"fontSize": "120px", "color": "rgba(25, 130, 196, .5)"}}></i>
                </div>
                <div className="col-12 col-md-6 col-lg-8 order-md-1">
                  <h5>Keep track of income and expenses</h5>
                  <p className="text-muted">
                    Schedule and track deposits, artist payments, and other income to keep your show on budget.
                    Use Event Templates to created default income items.
                  </p>
                  <div className="d-flex">
                    {currentUser.can_manage_finance_items ? (
                      <Popup arrow={false}
                             offsetY={5}
                             position="bottom left"
                             contentStyle={popupContentStyle}
                             onOpen={
                               (e) => {
                                 e.preventDefault();
                               }
                             }
                             trigger={open => (

                                <a href="#"
                                   className={"btn btn-primary " + (canAddNewFinanceItem(financeItems) ? "" : "disabled")}>
                                  <strong>Add Income or Expense</strong>
                                </a>
                             )} >
                        {close => (
                          <div className='row text-left'>
                            <div className="col-12">
                              <ul className="list-group offer-inline-menu highlight-primary">
                                <li className="list-group-item">
                                  <a href="#"
                                     className="text-muted"
                                     onClick={e => {
                                       e.preventDefault();
                                       fetchNewFinanceItem(team, false);
                                       close();
                                     }
                                  }>
                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                      <i className="fas fa-receipt"></i>
                                    </span>
                                    Add Expense
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a href="#"
                                     className="text-muted"
                                     onClick={e => {
                                       e.preventDefault();
                                       fetchNewFinanceItem(team, true);
                                       close();
                                     }
                                  }>
                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                      <i className="fas fa-hand-holding-dollar"></i>
                                    </span>
                                    Add Income
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </Popup>
                    ) : null}
                    <a href={`/teams/${team.id}/show_activity_settings`}
                      className="ml-2 btn text-primary"
                      style={{"boxShadow": "none"}}
                    >
                      <strong>Create Event Template</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="row">
        {offers.map(offer => offer.budget).map(budget => budget.deposits).flat().length > 0 ? (
          <div className="col-12 mb-4">
            <h6 className="mt-3 mb-0 strong" style={{"paddingLeft": "30px", "fontSize":"16px"}}>DEPOSITS</h6>
            <div className="mt-3 form-row" style={{"padding": "0 30px"}}>
              {offers.map((offer, offerIdx) => (
                offer.budget.deposits.map((deposit, depositIdx) => (
                  <div key={depositIdx} className="col-12 col-lg-6 mb-2">
                    <div className="card no-border mb-0">
                      <div className="pb-0 card-body rounded" style={{'background': '#f4f9fc'}}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-wrap">
                            <div className="mr-3 mb-3 d-flex flex-column" style={{"width": "150px"}}>
                              <span
                                className="text-muted text-uppercase"
                                style={{"fontSize": "12px"}}
                              >
                                {offer.primary_performer ? "Artist" : "Offer Name"}
                              </span>
                              <span
                                className="w-100"
                                style={{
                                  "whiteSpace": "nowrap",
                                  "overflow": "hidden",
                                  "textOverflow": "ellipsis"
                                }}
                              >
                                {offer.primary_performer ? offer.primary_performer?.artistable.name : offer.event_name}
                              </span>
                            </div>
                            <div className="mr-3 mb-3 d-flex flex-column" style={{"width": "150px"}}>
                              <span
                                className="text-muted text-uppercase"
                                style={{"fontSize": "12px"}}
                              >
                                Due Date
                              </span>
                              <span
                                style={{
                                  "whiteSpace": "nowrap",
                                  "overflow": "hidden",
                                  "textOverflow": "ellipsis"
                                }}
                              >
                                {deposit.due_date_type === "relative" ? (
                                  deposit.relative_due_date + " days before show"
                                ) : (
                                  new Date(deposit.specific_due_date)
                                    .toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
                                )}
                              </span>
                            </div>
                            <div className="mr-3 mb-3 d-flex flex-column" style={{"width": "150px"}}>
                              <span
                                className="text-muted text-uppercase"
                                style={{"fontSize": "12px"}}
                              >
                                Type
                              </span>
                              <span
                                className="text-capitalize"
                                style={{
                                  "whiteSpace": "nowrap",
                                  "overflow": "hidden",
                                  "textOverflow": "ellipsis"
                                }}
                              >
                                {deposit.deposit_type}
                              </span>
                            </div>
                            <div className="mb-3 mr-3 d-flex flex-column" style={{"width": "150px"}}>
                              <span
                                className="text-muted text-uppercase"
                                style={{"fontSize": "12px"}}
                              >
                                Amount
                              </span>
                              <span
                                style={{
                                  "whiteSpace": "nowrap",
                                  "overflow": "hidden",
                                  "textOverflow": "ellipsis"
                                }}
                              >
                                {USDollar.format(deposit.amount)}
                                {deposit.paid ? (
                                  <div
                                    className="px-2 py-1 ml-2 badge badge-success"
                                    style={{"marginTop": "-2px", "fontSize": "12px"}}
                                  >
                                    PAID
                                  </div>
                                ) : null}
                              </span>
                            </div>
                          </div>
                          {currentUser.can_manage_offers ? (
                            <Popup
                              arrow={false}
                              offsetY={-70}
                              position="top right"
                              contentStyle={popupContentStyle}
                              onOpen={
                                (e) => {
                                  e.preventDefault();
                                }
                              }
                              trigger={open => (
                                <a href="#" className="pl-3">
                                  <i className="fas fa-ellipsis-v"></i>
                                </a>
                              )}
                            >
                              {close => (
                                <div className='row text-left'>
                                  <div className="col-12">
                                    <ul className="list-group offer-inline-menu">
                                      <li className="list-group-item">
                                        <a
                                          href=""
                                          target="_blank"
                                          className="text-dark"
                                          onClick={e => {
                                            e.preventDefault();

                                            updateDeposit(csrfToken, team, deposit, !deposit.paid, (data) => {
                                              offerChanged({
                                                ...offer,
                                                budget: {
                                                  ...offer.budget,
                                                  deposits: offer.budget.deposits.map((d) => {
                                                    if (d.id === data.id) {
                                                      return data;
                                                    } else {
                                                      return d;
                                                    }
                                                  })
                                                }
                                              })
                                            });

                                            close();
                                          }}
                                        >
                                          {deposit.paid ? "Mark as Unpaid" : "Mark as Paid"}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ))}
            </div>
          </div>
        ) : null}
        {financeItems.length > 0 ? (
          <>
            <ManageFinanceItemsTable
              title="INCOME"
              csrfToken={csrfToken}
              team={team}
              confirm={confirm}
              currentUser={currentUser}
              financeItems={incomeFinanceItems(financeItems)}
              deleteFinanceItem={deleteFinanceItem}
              financeItemToEdit={financeItemToEdit}
              financeItemToEditChanged={financeItemToEditChanged}
              financeItemForForm={financeItemForForm}
              financeItemForFormChanged={financeItemForFormChanged}
              financeCategories={financeCategories}
              financeCategoriesChanged={financeCategoriesChanged}
              performers={performers}
              createFinanceItem={createFinanceItem}
              updateFinanceItem={updateFinanceItem}
              offers={offers}
              offerChanged={offerChanged}
            />
            <ManageFinanceItemsTable
              title="EXPENSES"
              csrfToken={csrfToken}
              team={team}
              confirm={confirm}
              currentUser={currentUser}
              financeItems={expenseFinanceItems(financeItems)}
              deleteFinanceItem={deleteFinanceItem}
              financeItemToEdit={financeItemToEdit}
              financeItemToEditChanged={financeItemToEditChanged}
              financeItemForForm={financeItemForForm}
              financeItemForFormChanged={financeItemForFormChanged}
              financeCategories={financeCategories}
              financeCategoriesChanged={financeCategoriesChanged}
              performers={performers}
              createFinanceItem={createFinanceItem}
              updateFinanceItem={updateFinanceItem}
              offers={offers}
              offerChanged={offerChanged}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

ManageFinanceItems.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  financeItems: PropTypes.array,
  financeItemsChanged: PropTypes.func.isRequired,
  fetchNewFinanceItem: PropTypes.func.isRequired,
  deleteFinanceItem: PropTypes.func.isRequired,
  financeItemToEdit: PropTypes.object,
  financeItemToEditChanged: PropTypes.func.isRequired,
  financeItemForForm: PropTypes.object,
  financeItemForFormChanged: PropTypes.func.isRequired,
  financeCategories: PropTypes.array,
  financeCategoriesChanged: PropTypes.func.isRequired,
  performers: PropTypes.array,
  createFinanceItem: PropTypes.func.isRequired,
  updateFinanceItem: PropTypes.func.isRequired,
  offers: PropTypes.array,
  offerChanged: PropTypes.func.isRequired,
  updateDeposit: PropTypes.func.isRequired,
};

export default ManageFinanceItems;
