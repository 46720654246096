import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import RenderVideo from './RenderVideo'

var debouncedAutoSavePageDetail;

const VideoPageDetail = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  pageDetail,
  pageDetailChanged,
  autoSavePageDetail
}) => {
  useEffect(() => {
    debouncedAutoSavePageDetail = _.debounce((csrfToken, team, pageDetail) => {
      autoSavePageDetail(csrfToken, team, pageDetail);
    }, 600);
  }, []);

  return (
    <div>
      <div className="form-group mb-4">
        <label className="text-muted mb-1"
               style={{"fontSize": "14px"}}
               htmlFor={"page-detail-video-url-" + pageDetail.id}>
          Video Link
        </label>
        <input type="text"
               className={"form-control " + (pageDetail.video_url && pageDetail.video_url.length && !pageDetail.renderable ? "highlight" : "")}
               value={pageDetail.video_url || ""}
               onChange={
                 (e) => {
                   var updated = Object.assign({}, pageDetail, {
                     video_url: e.target.value,
                     renderable: true
                   });

                   pageDetailChanged(updated);
                   debouncedAutoSavePageDetail(csrfToken, team, updated);
                 }
               }
               style={{
                 "border": "1px solid #e6e6e6"
               }}
               id={"page-detail-video-url-" + pageDetail.id}
               placeholder="Paste YouTube or Vimeo link" />
      </div>
      <RenderVideo pageDetail={pageDetail} />
    </div>
  );
};

VideoPageDetail.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  pageDetail: PropTypes.object.isRequired,
  pageDetailChanged: PropTypes.func.isRequired,
  autoSavePageDetail: PropTypes.func.isRequired
};

export default VideoPageDetail;
