import React, { useState, useEffect } from 'react';
import SlidingPane from "react-sliding-pane";
import axios from "axios";
import { useBroadcastEditContext } from "./BroadcastEditContainer";
import Flatpickr from "react-flatpickr";
import Select from "react-select";

import { offsetDateFromTimeZone, offsetDateToTimezone } from "../../../shared/timeZoneLogic";
import { toastSuccess, toastError } from "../../../shared/toastHelper";

var moment = require('moment-timezone');

const paneStyle = {
  height: 600,
  width: 415,
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const headerStyle = {
  background: "#1A82C4",
  borderBottom: "2px solid #fafafa",
  borderRadius: "5px 0 0 0",
};

const headerTextStyle = {
  color: "white",
  fontSize: 18,
};

const headerRightStyle = {
  paddingRight: 40,
};

const closeIconStyle = {
  color: "white",
  fontSize: 18,
};

const formContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 20,
  padding: 25,
};

const headerLabelStyle = {
  color: "#333333",
  fontSize: 12,
  opacity: 0.8,
};

const labelStyle = {
  color: "#808080",
  fontSize: 14,
  marginBottom: 2,
};

const footerContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 20,
  justifyContent: "flex-start",
  padding: 25,
};

const cancelButtonStyle = {
  color: "#FF595E",
  cursor: "pointer",
  fontSize: 14,
  fontWeight: 600,
  padding: "5px 10px",
};

const createButtonStyle = {
  background: "#1982C4",
  borderRadius: 5,
  color: "white",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  padding: "5px 10px",
  border: "none",
};

const timeZonePickerStyle = {
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "8px",
  width: "100%",
};

const flatPickrFormat = "n/j/y at h:iK"

const ScheduledSendPane = () => {
  const {
    csrfToken,
    team,
    broadcast,
    scheduledSend,
    setScheduledSend,
    scheduledSendPaneOpen,
    setScheduledSendPaneOpen,
    timeZone,
    setTimeZone,
  } = useBroadcastEditContext();

  const [scheduledAt, setScheduledAt] = useState(
    scheduledSend ? offsetDateToTimezone(scheduledSend.scheduled_at, timeZone) : null
  );

  useEffect(() => {
    setScheduledAt(scheduledSend ? offsetDateToTimezone(scheduledSend.scheduled_at, timeZone) : null);
  }, [scheduledSend, timeZone]);

  const timeZones = moment.tz.names();

  const handleSubmit = (e) => {
    e.preventDefault();
    setScheduledSendPaneOpen(false);

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    const axiosRequest = scheduledSend ?
      axios.put(`/teams/${team.id}/scheduled_sends/${scheduledSend.id}`, {
        scheduled_send: {
          scheduled_at: offsetDateFromTimeZone(scheduledAt, timeZone)
        }
      }) :
      axios.post(`/teams/${team.id}/scheduled_sends`, {
        scheduled_send: {
          schedulable_id: broadcast.id,
          schedulable_type: "Broadcast",
          scheduled_at: offsetDateFromTimeZone(scheduledAt, timeZone)
        }
      });

      axiosRequest
        .then(({ data }) => {
          setScheduledSend(data);
          toastSuccess("Broadcast successfully scheduled");
        })
        .catch((error) => {
          setScheduledAt(scheduledSend ? offsetDateToTimezone(scheduledSend.scheduled_at, timeZone) : null);
          const errors = error.response.data;
          toastError(errors.join(", "));
        });
  };

  const cancelScheduledSend = (e) => {
    e.preventDefault();
    setScheduledAt(null);
    setScheduledSendPaneOpen(false);

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put(`/teams/${team.id}/scheduled_sends/${scheduledSend.id}/cancel`)
      .then(() => {
        setScheduledSend(null);
        toastSuccess("Broadcast cancelled");
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  return (
    <SlidingPane
      isOpen={scheduledSendPaneOpen}
      className="sliding-pane-container dynamic-height"
      hideHeader={true}
      onRequestClose={() => setScheduledSendPaneOpen(false) }
      style={paneStyle}
    >
      <React.Fragment>
        <form style={containerStyle} onSubmit={handleSubmit}>
          <div>
            <div className="row m-0 py-4" style={headerStyle}>
              <div className="col pl-4">
                <h3 className="mb-0" style={headerTextStyle}>
                  <strong>Schedule Broadcast</strong>
                </h3>
              </div>
              <div className="col-xs-auto" style={headerRightStyle}>
                <a href="#" className="text-muted"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      setScheduledSendPaneOpen(false);
                    }
                  }
                >
                  <i className="fas fa-times" style={closeIconStyle}/>
                </a>
              </div>
            </div>
            <div style={formContainerStyle}>
              <div style={headerLabelStyle}>When do you want to send this broadcast?</div>
              <div>
                <div style={labelStyle}>Date / Time</div>
                <div className="flatpickr-date-input px-2 d-flex align-items-center">
                  <div className="pr-2" style={{
                    fontSize: "14px",
                    color: "#1982C4",
                  }}>
                    <i className="far fa-calendar"></i>
                  </div>
                  <Flatpickr
                    key={`${timeZone}`}
                    options={{
                      enableTime: true,
                      dateFormat: flatPickrFormat,
                      minDate: new Date(),
                      timeZone: timeZone,
                    }}
                    placeholder={"Select date"}
                    value={scheduledAt}
                    onClose={dates => {
                      setScheduledAt(dates[0]);
                    }}
                  />
                </div>
              </div>
              <div>
                <div style={labelStyle}>Timezone</div>
                <select
                  value={timeZone}
                  onChange={(e) => setTimeZone(e.target.value)}
                  style={timeZonePickerStyle}
                >
                  {timeZones.map(tz => (
                    <option key={tz} value={tz}>{tz}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div style={footerContainerStyle}>
            <button disabled={!scheduledAt} type="submit" style={createButtonStyle}>
              Schedule Broadcast
            </button>
            {scheduledSend ? (
              <div onClick={cancelScheduledSend} style={cancelButtonStyle}>
                Cancel Send
              </div>
            ) : null}
          </div>
        </form>
      </React.Fragment>
    </SlidingPane>
  );
};

export default ScheduledSendPane;
