import PropTypes from 'prop-types';
import React from 'react';

import PageDetailFrame from './PageDetails/PageDetailFrame';
import TextPageDetail from './PageDetails/TextPageDetail';
import ImagePageDetail from './PageDetails/ImagePageDetail';
import VideoPageDetail from './PageDetails/VideoPageDetail';

import Dropzone from 'react-dropzone'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  TICKETS_MENU_ITEM
} from '../constants/confirmTicketingConstants';

const Details = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  activeMenuItemChanged,
  pageDetails,
  createPageDetail,
  deletePageDetail,
  pageDetailChanged,
  autoSavePageDetail,
  pageDetailDragged,
  imageCalendarEventAttachments,
  uploadPageDetailImage,
  uploadPageDetailImageUrl,
  uploadConfirmMainImageFile,
  uploadConfirmMainImageUrl,
  mainEventImageLoading,
  rootUrl,
  artistBios,
  artistBiosChanged,
  pageDetailTemplates,
  deletePageDetailTemplate,
  pageDetailTemplate,
  pageDetailTemplateChanged,
  createPageDetailTemplate
}) => (
  <div className="row">
    <div className="col-12">
      <h3 style={{"fontSize": "20px"}}>
        <strong>
          Main Event Image
        </strong>
      </h3>
      <p className="mb-4">
        This is the first image attendees will see at the top of your listing.<br />
        Use a high quality image: 2160x1080px (2:1 ratio).<br />
        Images may not exceed 25MB.
      </p>
      <form onSubmit={
              (e) => {
                e.preventDefault();
              }
            }>
        <div className="form-row mb-3 pt-2">
          <div className="col-12">
            {mainEventImageLoading ? (
              <div className="mb-4 position-relative">
                <div className="">
                  <div style={{
                         "width": "100%",
                         "height": "195px",
                         "border": "2px dashed #cccccc"
                       }}>
                  </div>
                </div>
                <img className="img-fluid position-absolute absolute-position-center"
                     src="/uploading-loading.gif"
                     style={{width: "25px"}} />
              </div>
            ) : confirm.has_main_event_image ? (
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     uploadConfirmMainImageUrl(csrfToken, team, confirm, "");
                   }
                 }>
                <div className="mb-4 position-relative background-tint-on-hover-wrapper">
                  <div className="background-tint-on-hover">
                    <img src={confirm.compressed_main_event_image_url}
                         width="100%" />
                  </div>
                  <i className="fas fa-trash text-white position-absolute absolute-position-center"
                     style={{"fontSize": "20px"}}></i>
                </div>
              </a>
            ) : (
              <React.Fragment>
                <Dropzone maxFiles={1}
                          accept="image/*"
                          onDrop={
                            (files) => {
                              uploadConfirmMainImageFile(csrfToken, team, confirm, files[0]);
                            }
                          }>
                  {({getRootProps, getInputProps}) => (
                    <section className="d-flex justify-content-center align-items-center text-center"
                             style={{
                               "height": "195px",
                               "border": "2px dashed #cccccc"
                             }}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <i className="fas fa-cloud-upload-alt mb-2"
                           style={{
                             "color": "#cccccc",
                             "fontSize": "36px"
                           }}></i>
                        <p className="mb-0">
                          Drag-and-drop a file, or <a className="text-danger" onClick={(e) => e.preventDefault()} href="#">browse to upload</a>
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {imageCalendarEventAttachments.length > 0 ? (
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="card border-0 rounded-0"
                           style={{"background": "#f4f9fc"}}>
                        <div className="card-body p-3">
                          <p className="mb-0">
                            <strong>
                              Choose from existing event assets
                            </strong>
                          </p>
                          {imageCalendarEventAttachments.map((calendarEventAttachment, index) =>
                            <a key={index}
                               onClick={
                                 (e) => {
                                   e.preventDefault();
                                   uploadConfirmMainImageUrl(csrfToken, team, confirm, calendarEventAttachment.resolved_file_url);
                                 }
                               }
                               href="#">
                              <div title={calendarEventAttachment.human_file_name}
                                   className="d-inline-block mr-3 mt-3"
                                   style={{
                                     "width": "55px",
                                     "height": "55px",
                                     "borderRadius": "2px",
                                     "backgroundSize": "cover",
                                     "backgroundImage": "url(" + calendarEventAttachment.compressed_file_url + ")"
                                   }}>
                              </div>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="col-12">
            <h3 style={{"fontSize": "20px"}}>
              <strong>
                Description
              </strong>
            </h3>
            <p>
              Add more details to your event like your schedule, sponsors, additional photos or featured guests.
            </p>
          </div>
        </div>
        <DragDropContext onDragEnd={
                           (result) => {
                             if (!result.destination) {
                               return false;
                             }

                             var itemIndex = result.source.index;
                             var moveIndex = result.destination.index;
                             var updated = Object.assign({}, pageDetails[itemIndex], {
                               position: (moveIndex + 1)
                             });

                             autoSavePageDetail(csrfToken, team, updated);
                             pageDetailDragged(result);
                           }
                         }>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {pageDetails.sort((a, b) => a.position - b.position).map((pageDetail, index) =>
                  <Draggable key={"" + pageDetail.id} draggableId={"" + pageDetail.id} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <PageDetailFrame csrfToken={csrfToken}
                                         confirm={confirm}
                                         pageDetail={pageDetail}
                                         currentUser={currentUser}
                                         deletePageDetail={deletePageDetail}
                                         provided={provided}
                                         team={team}>
                          {pageDetail.type === "PageDetails::Text" ? (
                            <TextPageDetail csrfToken={csrfToken}
                                            key={index}
                                            team={team}
                                            currentUser={currentUser}
                                            confirm={confirm}
                                            pageDetailChanged={pageDetailChanged}
                                            autoSavePageDetail={autoSavePageDetail}
                                            artistBios={artistBios}
                                            artistBiosChanged={artistBiosChanged}
                                            pageDetailTemplates={pageDetailTemplates}
                                            deletePageDetailTemplate={deletePageDetailTemplate}
                                            pageDetailTemplate={pageDetailTemplate}
                                            pageDetailTemplateChanged={pageDetailTemplateChanged}
                                            createPageDetailTemplate={createPageDetailTemplate}
                                            pageDetail={pageDetail} />
                          ) : pageDetail.type === "PageDetails::Image" ? (
                            <ImagePageDetail csrfToken={csrfToken}
                                             key={index}
                                             team={team}
                                             currentUser={currentUser}
                                             confirm={confirm}
                                             imageCalendarEventAttachments={imageCalendarEventAttachments}
                                             uploadPageDetailImage={uploadPageDetailImage}
                                             uploadPageDetailImageUrl={uploadPageDetailImageUrl}
                                             rootUrl={rootUrl}
                                             pageDetail={pageDetail} />
                          ) : pageDetail.type === "PageDetails::Video" ? (
                            <VideoPageDetail csrfToken={csrfToken}
                                             key={index}
                                             team={team}
                                             currentUser={currentUser}
                                             confirm={confirm}
                                             pageDetailChanged={pageDetailChanged}
                                             autoSavePageDetail={autoSavePageDetail}
                                             pageDetail={pageDetail} />
                          ) : null}
                        </PageDetailFrame>
                      </div>
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="form-row mt-3">
          <div className="col-12">
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();

                   var pageDetail = {
                     calendar_event_id: confirm.id,
                     type: "PageDetails::Text",
                     position: (pageDetails.length + 1)
                   };

                   createPageDetail(csrfToken, team, confirm, pageDetail);
                 }
               }
               className="btn btn-external small mr-2">
              <i className="fas fa-plus-circle mr-2"></i>
              <strong>
                Text
              </strong>
            </a>
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();

                   var pageDetail = {
                     calendar_event_id: confirm.id,
                     type: "PageDetails::Image",
                     position: (pageDetails.length + 1)
                   };

                   createPageDetail(csrfToken, team, confirm, pageDetail);
                 }
               }
               className="btn btn-external small mr-2">
              <i className="fas fa-plus-circle mr-2"></i>
              <strong>
                Image
              </strong>
            </a>
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();

                   var pageDetail = {
                     calendar_event_id: confirm.id,
                     type: "PageDetails::Video",
                     position: (pageDetails.length + 1)
                   };

                   createPageDetail(csrfToken, team, confirm, pageDetail);
                 }
               }
               className="btn btn-external small">
              <i className="fas fa-plus-circle mr-2"></i>
              <strong>
                Video
              </strong>
            </a>
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-12 d-flex justify-content-end align-items-end"
             style={{"height": "130px"}}>
          <a href="#"
             onClick={
               (e) => {
                 e.preventDefault();
                 activeMenuItemChanged(TICKETS_MENU_ITEM);
               }
             }
             className="btn btn-primary">
            <strong>Next</strong>
          </a>
        </div>
      </div>
    </div>
  </div>
);

Details.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  activeMenuItemChanged: PropTypes.func.isRequired,
  pageDetails: PropTypes.array,
  createPageDetail: PropTypes.func.isRequired,
  deletePageDetail: PropTypes.func.isRequired,
  pageDetailChanged: PropTypes.func.isRequired,
  autoSavePageDetail: PropTypes.func.isRequired,
  pageDetailDragged: PropTypes.func.isRequired,
  imageCalendarEventAttachments: PropTypes.array,
  uploadPageDetailImage: PropTypes.func.isRequired,
  uploadPageDetailImageUrl: PropTypes.func.isRequired,
  uploadConfirmMainImageFile: PropTypes.func.isRequired,
  uploadConfirmMainImageUrl: PropTypes.func.isRequired,
  mainEventImageLoading: PropTypes.bool,
  rootUrl: PropTypes.string.isRequired,
  artistBios: PropTypes.object,
  artistBiosChanged: PropTypes.func.isRequired,
  pageDetailTemplates: PropTypes.array,
  deletePageDetailTemplate: PropTypes.func.isRequired,
  pageDetailTemplate: PropTypes.object,
  pageDetailTemplateChanged: PropTypes.func.isRequired,
  createPageDetailTemplate: PropTypes.func.isRequired
};

export default Details;
