import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import htmlToDraft from 'html-to-draftjs';
import {stateToHTML} from 'draft-js-export-html';
import { ContentState, EditorState, Modifier } from 'draft-js';
import Popup from "reactjs-popup";

import Provider from "./Provider";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "220px",
  "padding": "0px",
  "zIndex": "3000"
}

// https://ourcodeworld.com/articles/read/376/how-to-strip-html-from-a-string-extract-only-text-content-in-javascript
const stripHtml = (html) => {
  var temporalDivElement = document.createElement("div");
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

const canSubmitForm = (pageDetailTemplate, editorState) => {
  var stateHTML = stateToHTML(editorState.getCurrentContent());
  var content = stripHtml(stateHTML);

  return(
    pageDetailTemplate
      && pageDetailTemplate.name
      && pageDetailTemplate.name.length > 0
      && content.length > 0
  );
}

const submitForm = (createPageDetailTemplate, csrfToken, team, pageDetailTemplate, editorState, close) => {
  if(!canSubmitForm(pageDetailTemplate, editorState)){
    return false;
  }

  var stateHTML = stateToHTML(editorState.getCurrentContent());
  createPageDetailTemplate(csrfToken, team, pageDetailTemplate, stateHTML, () => {
    close();
  });
}

const insertHtmlAtCursor = (editorState, onChange, html) => {
  let { contentBlocks, entityMap } = htmlToDraft(html);
  let contentState = Modifier.replaceWithFragment(
     editorState.getCurrentContent(),
     editorState.getSelection(),
     ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
   )

  onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
}

const noInsertableTemplates = (artistBios, pageDetailTemplates) => {
  return (
    artistBios.lastfm_bios.count === 0
      && artistBios.opendate_bios.count === 0
      && pageDetailTemplates.length === 0
  );
}

const PageDetailTemplates = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  onChange,
  editorState,
  artistBios,
  artistBiosChanged,
  pageDetailTemplates,
  deletePageDetailTemplate,
  pageDetailTemplate,
  pageDetailTemplateChanged,
  createPageDetailTemplate
}) => {
  useEffect(() => {
    // ...
  }, []);

  return (
    <span style={{
            "margin": "-3px -2px 2px auto"
          }}>
      <Popup arrow={false}
             offsetY={3}
             position="bottom right"
             contentStyle={popupContentStyle}
             onClose={
               (e) => {
                 var lastFMBios = Object.assign({}, artistBios.lastfm_bios, {open: false});
                 var opendateBios = Object.assign({}, artistBios.opendate_bios, {open: false});

                 var updated = Object.assign({}, artistBios, {
                   lastfm_bios: lastFMBios,
                   opendate_bios: opendateBios
                 });

                 pageDetailTemplateChanged({});
                 artistBiosChanged(updated);
               }
             }
             onOpen={
               (e) => {
                 e.preventDefault();
               }
             }
             trigger={open => (
         <a href="#"
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{"padding": "2px 8px"}}
            className='btn btn-muted small btn-sm'>
           Templates
           <i className="fas fa-caret-down ml-2"></i>
         </a>
      )} >
        {close => (
          <div className='row'>
            <div className="col-12"
                 style={{
                   "padding": "13px 30px 0px 30px"
                 }}>
              <p style={{
                   "color": "#999999",
                   "fontSize": "10px",
                   "marginBottom": "13px"
                 }}>
                INSERT
              </p>
              {noInsertableTemplates(artistBios, pageDetailTemplates) ? (
                <div className="row">
                  <div className="col-12 text-center">
                    <p className="small text-muted"
                       style={{"marginBottom": "20px", "color": "#999999"}}>
                      No saved templates yet.
                    </p>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <Provider csrfToken={csrfToken}
                            title={"Last.fm Bio"}
                            logoImgSrc={"/lastfm@2x.png"}
                            team={team}
                            currentUser={currentUser}
                            confirm={confirm}
                            bioData={artistBios.lastfm_bios}
                            onChange={onChange}
                            editorState={editorState}
                            toggleOpen={
                              (e) => {
                                e.preventDefault();

                                var lastFMBios = Object.assign({}, artistBios.lastfm_bios, {
                                  open: !artistBios.lastfm_bios.open
                                });

                                var updated = Object.assign({}, artistBios, {lastfm_bios: lastFMBios});
                                artistBiosChanged(updated);
                              }
                            }
                            closePopup={close}
                            insertHtmlAtCursor={insertHtmlAtCursor} />
                  <Provider csrfToken={csrfToken}
                            title={"Opendate Bio"}
                            logoImgSrc={"/OD@2x.png"}
                            team={team}
                            currentUser={currentUser}
                            confirm={confirm}
                            bioData={artistBios.opendate_bios}
                            onChange={onChange}
                            editorState={editorState}
                            toggleOpen={
                              (e) => {
                                e.preventDefault();

                                var opendateBios = Object.assign({}, artistBios.opendate_bios, {
                                  open: !artistBios.opendate_bios.open
                                });

                                var updated = Object.assign({}, artistBios, {opendate_bios: opendateBios});
                                artistBiosChanged(updated);
                              }
                            }
                            closePopup={close}
                            insertHtmlAtCursor={insertHtmlAtCursor} />
                  {pageDetailTemplates.length > 0 ? (
                    <div className="row page-detail-templates"
                         style={{
                           "borderTop": "1px dashed #F2F2F2",
                           "maxHeight": "110px",
                           "overflowY": "auto"
                         }}>
                      {pageDetailTemplates.sort((a, b) => a.name.localeCompare(b.name)).map((template, index) =>
                        <div key={index}
                             className="col-12 page-detail-template"
                             style={{"paddingTop": "10px", "paddingBottom": "10px"}}>
                          <div className="row">
                            <div className="col d-flex align-items-center">
                              <div style={{"paddingLeft": "32px"}}>
                                <p className="mb-0 small text-overflow-ellipsis"
                                   style={{"maxWidth": "125px"}}
                                   title={template.name}>
                                  <a href="#"
                                     onClick={
                                       (e) => {
                                         e.preventDefault();
                                         insertHtmlAtCursor(editorState, onChange, template.sanitized_body);
                                         close();
                                       }
                                     }
                                     className="text-dark page-detail-template-link">
                                    {template.name}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="col-xs-auto d-flex align-items-center"
                                 style={{"paddingRight": "15px"}}>
                              <a href="#"
                                 className="delete-page-detail-template-link"
                                 onClick={
                                   (e) => {
                                     e.preventDefault();
                                     deletePageDetailTemplate(csrfToken, team, template);
                                   }
                                 }>
                                <i className="fas fa-trash"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </React.Fragment>
              )}
              <div className="row"
                   style={{
                     "borderTop": "1px solid #F2F2F2",
                     "paddingTop": "4px",
                     "paddingBottom": "6px"
                   }}>
                {Object.keys(pageDetailTemplate).length === 0 ? (
                  <div className="col-12 text-center"
                       style={{"paddingTop": "5px", "paddingBottom": "7px"}}>
                    <a href="#"
                       style={{"fontSize": "10px", "fontWeight": "600"}}
                       onClick={
                         (e) => {
                           e.preventDefault();
                           pageDetailTemplateChanged({name: ""});
                         }
                       }>
                      Save New Template
                    </a>
                  </div>
                ) : (
                  <div className="col-12"
                       style={{"paddingLeft": "6px", "paddingRight": "6px"}}>
                    <div className="input-group input-group-sm">
                      <input type="text"
                             autoFocus={true}
                             className="form-control border-0"
                             style={{
                               "background": "#f9f9f9",
                               "fontSize": "12px",
                               "borderTopLeftRadius": "4px",
                               "borderBottomLeftRadius": "4px"
                             }}
                             value={pageDetailTemplate.name || ""}
                             onKeyDown={
                               (e) => {
                                 if (e.key === 'Enter') {
                                   e.preventDefault();
                                   submitForm(createPageDetailTemplate, csrfToken, team, pageDetailTemplate, editorState, close);
                                 }
                               }
                             }
                             onChange={
                               (e) => {
                                 var updated = Object.assign({}, pageDetailTemplate, {name: e.target.value});
                                 pageDetailTemplateChanged(updated);
                               }
                             }
                             placeholder="Enter a name" />
                      <div className="input-group-append">
                        <button className="btn btn-muted btn-muted-primary border-0"
                                disabled={!canSubmitForm(pageDetailTemplate, editorState)}
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    submitForm(createPageDetailTemplate, csrfToken, team, pageDetailTemplate, editorState, close);
                                  }
                                }
                                style={{
                                  "fontSize": "12px",
                                  "borderTopRightRadius": "4px",
                                  "borderBottomRightRadius": "4px"
                                }}
                                type="button">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Popup>
    </span>
  );
};

PageDetailTemplates.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  editorState: PropTypes.object,
  artistBios: PropTypes.object,
  artistBiosChanged: PropTypes.func.isRequired,
  pageDetailTemplates: PropTypes.array,
  deletePageDetailTemplate: PropTypes.func.isRequired,
  pageDetailTemplate: PropTypes.object,
  pageDetailTemplateChanged: PropTypes.func.isRequired,
  createPageDetailTemplate: PropTypes.func.isRequired
};

export default PageDetailTemplates;
