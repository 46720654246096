import React, { createContext, useContext } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import MailkickSubscriptionIndex from "./MailkickSubscriptionIndex";

const MailkickSubscriptionIndexContext = createContext();

export function useMailkickSubscriptionIndexContext() {
  return useContext(MailkickSubscriptionIndexContext);
}

const MailkickSubscriptionIndexProvider = (props) => {
  const {
    csrfToken,
    team,
    fan,
    mailkickSubscriptionsByVenueOwnership,
    venueOwnerships,
  } = props;

  const toggleSubscription = (list, subscribed) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    !!subscribed ?  (
      axios.put(`/teams/${team.id}/fans/${fan.id}/unsubscribe_from_list`, {
        list: list
      })
    ) : (
      axios.put(`/teams/${team.id}/fans/${fan.id}/subscribe_to_list`, {
        list: list
      })
    )
  };

  const value = {
    csrfToken,
    team,
    fan,
    mailkickSubscriptionsByVenueOwnership,
    venueOwnerships,
    toggleSubscription,
  };

  return (
    <MailkickSubscriptionIndexContext.Provider value={value}>
      {props.children}
    </MailkickSubscriptionIndexContext.Provider>
  );
};

const MailkickSubscriptionIndexContainer = (props) => (
  <MailkickSubscriptionIndexProvider {...props}>
    <ToastContainer />
    <MailkickSubscriptionIndex />
  </MailkickSubscriptionIndexProvider>
);

export default MailkickSubscriptionIndexContainer;
