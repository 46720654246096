import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import OrderTicketTableRow from './OrderTicketTableRow';

import {
  RESEND_EMAIL_SLIDING_STATE
} from '../constants/confirmDashboardConstants';

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ShowOrderSlidingState = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  order,
  orderChanged,
  sendOrderReceipt,
  fetchNewRefund,
  slidingPaneStateChanged,
  setObjToPrint,
  sendOrderCheckIn,
  sendOrderCheckOut,
}) => (
  <React.Fragment>
    <div className="row"
         style={{
           "padding": "26px 22px",
           "borderBottom": "2px solid #f5f5f5"
         }}>
      <div className="col">
        <p className="mb-0"
           style={{"fontSize": "20px"}}>
          <strong>Order #{order.id}</strong>
        </p>
      </div>
      <div className="col-xs-auto d-flex align-items-center"
           style={{"paddingRight": "15px"}}>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               orderChanged({});
             }
           }
           className="text-muted">
          <i className="fal fa-times"
             style={{"fontSize": "24px"}}></i>
        </a>
      </div>
    </div>
    <div className="row"
         style={{
           "padding": "10px 22px 24px 22px"
         }}>
      <div className="col">
        <p className="text-dark mb-0"
           style={{
             "fontSize": "16px",
             "fontWeight": "600"
           }}>
          Delivery Method: {order.should_send_ticket_email ? "eTicket" : "Skip Delivery"}
        </p>
      </div>
      <div className="col-xs-auto"
           style={{"paddingRight": "15px"}}>
        <Popup arrow={false}
              offsetY={5}
              position="bottom right"
              contentStyle={popupContentStyle}
              onOpen={
                (e) => {
                  e.preventDefault();
                }
              }
              trigger={open => (
                <a href="#"
                   className="text-muted">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
              )} >
         {close => (
           <div className='row text-left'>
             <div className="col-12">
               <ul className="list-group offer-inline-menu highlight-primary"
                   style={{"fontSize": "14px"}}>
                 {team.package.printing && confirm.venue_ownership.can_print ? (
                   <li className="list-group-item pl-2">
                     <a
                       href="#"
                       className="text-dark"
                       onClick={e => {
                         e.preventDefault();
                         setObjToPrint({ type: "order", obj: order });
                         close();
                       }}>
                       Print Tickets
                     </a>
                   </li>
                 ) : null}
                 <li className="list-group-item pl-2">
                   <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          fetchNewRefund(csrfToken, team, order);
                        }
                      }
                      className="text-dark">
                     Refund Order
                   </a>
                 </li>
                 {order.email ? (
                   <li className="list-group-item pl-2">
                     <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            close();
                            slidingPaneStateChanged(RESEND_EMAIL_SLIDING_STATE);
                          }
                        }
                        className="text-dark">
                       Resend Order Confirmation
                     </a>
                   </li>
                 ) : null}
                  <li className="list-group-item pl-2">
                   <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          if (order.checked_in) {
                            sendOrderCheckOut(csrfToken, team, order);
                          } else {
                            sendOrderCheckIn(csrfToken, team, order);
                          }
                        }}
                      className="text-dark">
                     {order.checked_in ? 'Check Out Order' : 'Check In Order'}
                   </a>
                 </li>
                 {team.enable_download_order_pdf && (
                   <li className="list-group-item pl-2">
                     <a href={`/teams/${team.id}/orders/${order.id}/tickets.pdf`}
                        target="_blank"
                        className="text-dark">
                        Download Order PDF
                     </a>
                   </li>
                 )}
               </ul>
             </div>
           </div>
         )}
        </Popup>
      </div>
    </div>
    <div className="row"
         style={{
           "padding": "0px 22px"
         }}>
      <div className="col-12">
        <p className="mb-0">
          {order.name ? (
            <span>Purchased by {order.name}</span>
          ) : (
            <span>Purchased at the door</span>
          )}
          {order.email && order.email.length > 0 ? (
            <span> ({order.email}{order.human_phone_number ? `, ${order.human_phone_number}` : null})</span>
          ) : null}
        </p>
        <p className="mb-0">
          <span>{order.human_purchased_at}</span>
        </p>
        {parseFloat(order.total) === 0.00 ? (
          <p className="mb-0">
            <span>FREE</span>
          </p>
        ) : order.stripe_required ? (
          <React.Fragment>
            <p className="mb-0">
              <span>{formatter.format(parseFloat(order.total))} paid by {order.titlecased_card_brand}</span>
            </p>
            <p className="mb-0">
              <span>Last 4 digits: {order.card_last4}</span>
            </p>
          </React.Fragment>
        ) : order.manual ? (
          <p className="mb-0">
            <span>{formatter.format(parseFloat(order.total))} {order.manual_type}</span>
          </p>
        ) : (
          <p className="mb-0">
            <span>{formatter.format(parseFloat(order.total))} paid by cash</span>
          </p>
        )}
        {order.promo_code && order.promo_code.name && (
          <p className="mb-0">
            <span>Promo Code: {order.promo_code.name}</span>
          </p>
        )}
      </div>
    </div>
    <div className="row"
         style={{
           "padding": "10px 15px"
         }}>
      <div className="col-12 p-0">
        <table className="table">
          <colgroup>
            <col span={1} style={{"width": "10%"}} />
            <col span={1} style={{"width": "55%"}} />
            <col span={1} style={{"width": "30%"}} />
            <col span={1} style={{"width": "5%"}} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" style={{"borderBottom": "1px solid #D0DDEA"}} className="text-center">QTY</th>
              <th scope="col" style={{"borderBottom": "1px solid #D0DDEA"}}>Tickets</th>
              <th scope="col" style={{"borderBottom": "1px solid #D0DDEA"}}>Paid</th>
              <th scope="col" style={{"borderBottom": "1px solid #D0DDEA"}}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {(order.tickets || []).map((ticket, index) =>
              <OrderTicketTableRow key={index}
                csrfToken={csrfToken}
                team={team}
                currentUser={currentUser}
                confirm={confirm}
                order={order}
                ticket={ticket}
                fetchNewRefund={fetchNewRefund}
                setObjToPrint={setObjToPrint} />
            )}
            {(order.add_on_tickets || []).map((ticket, index) =>
              <OrderTicketTableRow key={index}
                csrfToken={csrfToken}
                team={team}
                currentUser={currentUser}
                confirm={confirm}
                order={order}
                ticket={ticket}
                fetchNewRefund={fetchNewRefund}
                setObjToPrint={setObjToPrint} />
            )}
            {(order.abstract_tickets || []).map((ticket, index) =>
              <OrderTicketTableRow key={index}
                csrfToken={csrfToken}
                team={team}
                currentUser={currentUser}
                confirm={confirm}
                order={order}
                ticket={ticket}
                fetchNewRefund={fetchNewRefund} />
            )}
            <tr>
              <td>&nbsp;</td>
              <td className="text-right">
                <span style={{"fontWeight": "600"}}>Total</span>
              </td>
              <td>
                <span style={{"fontWeight": "600"}}>
                  {formatter.format(parseFloat(order.total))}
                </span>
              </td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </React.Fragment>
);

ShowOrderSlidingState.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  order: PropTypes.object,
  orderChanged: PropTypes.func.isRequired,
  sendOrderReceipt: PropTypes.func.isRequired,
  fetchNewRefund: PropTypes.func.isRequired,
  slidingPaneStateChanged: PropTypes.func.isRequired,
  setObjToPrint: PropTypes.func.isRequired,
  sendOrderCheckIn: PropTypes.func.isRequired,
  sendOrderCheckOut: PropTypes.func.isRequired,
};

export default ShowOrderSlidingState;
