/* eslint-disable import/prefer-default-export */

export const CONFIRM_CHANGED = 'CONFIRM_CHANGED';
export const TYPED_SEARCH_TERM_CHANGED = 'TYPED_SEARCH_TERM_CHANGED';
export const SEARCHED_SEARCH_TERM_CHANGED = 'SEARCHED_SEARCH_TERM_CHANGED';
export const DATA_TABLE_EL_CHANGED = 'DATA_TABLE_EL_CHANGED';
export const ORDER_CHANGED = 'ORDER_CHANGED';
export const WIDGET_TAB_CHANGED = 'WIDGET_TAB_CHANGED';
export const REFUND_CHANGED = 'REFUND_CHANGED';
export const SUBMITTING_REFUND_CHANGED = 'SUBMITTING_REFUND_CHANGED';
export const SLIDING_PANE_STATE_CHANGED = 'SLIDING_PANE_STATE_CHANGED';
export const RESEND_EMAIL_CHANGED = 'RESEND_EMAIL_CHANGED';
export const SENDING_CONFIRMATION_EMAIL_CHANGED = 'SENDING_CONFIRMATION_EMAIL_CHANGED';
export const FAKE_SALES_DATA_CHANGED = 'FAKE_SALES_DATA_CHANGED';
export const TICKET_BUYERS_DATA_CHANGED = 'TICKET_BUYERS_DATA_CHANGED';



export const TICKETS_SOLD_WIDGET_TAB = 'TICKETS_SOLD_WIDGET_TAB';
export const ADMISSION_WIDGET_TAB = 'ADMISSION_WIDGET_TAB';

export const SHOW_ORDER_SLIDING_STATE = 'SHOW_ORDER_SLIDING_STATE';
export const RESEND_EMAIL_SLIDING_STATE = 'RESEND_EMAIL_SLIDING_STATE';
