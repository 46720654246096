/* eslint-disable import/prefer-default-export */

export const CONFIRM_CHANGED = 'CONFIRM_CHANGED';

export const PERFORMERS_CHANGED = 'PERFORMERS_CHANGED';
export const PERFORMER_REMOVED = 'PERFORMER_REMOVED';
export const STAGE_PERFORMER_FOR_UPDATE = 'STAGE_PERFORMER_FOR_UPDATE';
export const CANCEL_STAGE_PERFORMER_FOR_UPDATE = 'CANCEL_STAGE_PERFORMER_FOR_UPDATE';
export const PERFORMER_ACT_TYPE_CHANGED = 'PERFORMER_ACT_TYPE_CHANGED';
export const PERFORMER_CHANGED = 'PERFORMER_CHANGED';
export const PERFORMER_START_TIME_CHANGED = 'PERFORMER_START_TIME_CHANGED';
export const PERFORMER_END_TIME_CHANGED = 'PERFORMER_END_TIME_CHANGED';
export const PERFORMER_ADDED = 'PERFORMER_ADDED';
export const PERFORMER_ARTIST_CHANGED = 'PERFORMER_ARTIST_CHANGED';
export const PERFORMER_CREATED = 'PERFORMER_CREATED';
export const CANCEL_ADD_PERFORMER = 'CANCEL_ADD_PERFORMER';
export const CANCEL_EDIT_PERFORMER = 'CANCEL_EDIT_PERFORMER';
export const CLOSE_PERFORMER_OFFER_MODAL = 'CLOSE_PERFORMER_OFFER_MODAL';
export const OPEN_PERFORMER_OFFER_MODAL = 'OPEN_PERFORMER_OFFER_MODAL';
export const SET_OFFER_PERFORMER = 'SET_OFFER_PERFORMER';

export const OPEN_NOTE_CHANGED = 'OPEN_NOTE_CHANGED';
export const CLOSE_OPEN_NOTE = 'CLOSE_OPEN_NOTE';
export const OPEN_NOTE_TITLE_CHANGED = 'OPEN_NOTE_TITLE_CHANGED';
export const OPEN_NOTE_BODY_CHANGED = 'OPEN_NOTE_BODY_CHANGED';
export const OPEN_NOTE_LOADING = 'OPEN_NOTE_LOADING';
export const OPEN_NOTE_STOPPED_LOADING = 'OPEN_NOTE_STOPPED_LOADING';
export const NOTE_REMOVED = 'NOTE_REMOVED';
export const NOTE_CREATED = 'NOTE_CREATED';
export const NOTE_UPDATED = 'NOTE_UPDATED';
export const DISABLE_OPEN_NOTE_PREVIEW_MODE = 'DISABLE_OPEN_NOTE_PREVIEW_MODE';

export const OPEN_NOTE_ORIGINAL_CHANGED = 'OPEN_NOTE_ORIGINAL_CHANGED';

export const NOTE_TEMPLATES_CHANGED = 'NOTE_TEMPLATES_CHANGED';

export const ADD_CALENDAR_EVENT_CONTACT = 'ADD_CALENDAR_EVENT_CONTACT';
export const CALENDAR_EVENT_CONTACT_REMOVED = 'CALENDAR_EVENT_CONTACT_REMOVED';
export const REMOVE_UNSAVED_CALENDAR_EVENT_CONTACT = 'REMOVE_UNSAVED_CALENDAR_EVENT_CONTACT';
export const CALENDAR_EVENT_CONTACT_CREATED = 'CALENDAR_EVENT_CONTACT_CREATED';
export const EDIT_CALENDAR_EVENT_CONTACT = 'EDIT_CALENDAR_EVENT_CONTACT';
export const CANCEL_EDIT_CALENDAR_EVENT_CONTACT = 'CANCEL_EDIT_CALENDAR_EVENT_CONTACT';
export const UPDATE_CALENDAR_EVENT_CONTACT_NAME = 'UPDATE_CALENDAR_EVENT_CONTACT_NAME';
export const UPDATE_CALENDAR_EVENT_CONTACT_EMAIL = 'UPDATE_CALENDAR_EVENT_CONTACT_EMAIL';
export const UPDATE_CALENDAR_EVENT_CONTACT_PHONE = 'UPDATE_CALENDAR_EVENT_CONTACT_PHONE';
export const UPDATE_CALENDAR_EVENT_CONTACT_CONTACT_TYPE = 'UPDATE_CALENDAR_EVENT_CONTACT_CONTACT_TYPE';
export const UPDATE_CALENDAR_EVENT_CONTACT_TITLE = 'UPDATE_CALENDAR_EVENT_CONTACT_TITLE';
export const UPDATE_CALENDAR_EVENT_CONTACT_COMPANY_NAME = 'UPDATE_CALENDAR_EVENT_CONTACT_COMPANY_NAME';
export const CALENDAR_EVENT_CONTACT_CHANGED = 'CALENDAR_EVENT_CONTACT_CHANGED';
export const EDIT_UNSAVED_CALENDAR_EVENT_CONTACT = 'EDIT_UNSAVED_CALENDAR_EVENT_CONTACT';
export const CALENDAR_EVENT_CONTACTS_CHANGED = 'CALENDAR_EVENT_CONTACTS_CHANGED';

export const OFFER_CHANGED = 'OFFER_CHANGED';
export const OFFERS_CHANGED = 'OFFERS_CHANGED';
export const OFFER_DELETED = 'OFFER_DELETED';
export const OFFER_ADDED = 'OFFER_ADDED';

export const SENDING_CUSTOMER_IO_EMAIL_CHANGED = 'SENDING_CUSTOMER_IO_EMAIL_CHANGED';
export const CUSTOMER_IO_EMAIL_PURPOSE_CHANGED = 'CUSTOMER_IO_EMAIL_PURPOSE_CHANGED';
export const CUSTOMER_IO_EMAIL_TEMPLATE_CHANGED = 'CUSTOMER_IO_EMAIL_TEMPLATE_CHANGED';

export const MODAL_OFFER_CHANGED = 'MODAL_OFFER_CHANGED';

export const MARKETING_SECTION_ACTIVE_TAB_CHANGED = 'MARKETING_SECTION_ACTIVE_TAB_CHANGED';
export const ADDING_NEW_SOCIAL_ACCOUNT_CHANGED = 'ADDING_NEW_SOCIAL_ACCOUNT_CHANGED';
export const COMPOSING_POST_CHANGED = 'COMPOSING_POST_CHANGED';
export const POST_CREATED = 'POST_CREATED';
export const POST_DELETED = 'POST_DELETED';
export const POST_UPDATED = 'POST_UPDATED';
export const POST_TO_EDIT_CHANGED = 'POST_TO_EDIT_CHANGED';

export const SHOW_ACTIVITIES_CHANGED = 'SHOW_ACTIVITIES_CHANGED';
export const SHOW_ACTIVITY_DELETED = 'SHOW_ACTIVITY_DELETED';
export const EDIT_SHOW_ACTIVITY = 'EDIT_SHOW_ACTIVITY';
export const CANCEL_EDIT_SHOW_ACTIVITY = 'CANCEL_EDIT_SHOW_ACTIVITY';
export const ADD_NEW_SHOW_ACTIVITY = 'ADD_NEW_SHOW_ACTIVITY';
export const SHOW_ACTIVITY_NAME_CHANGED = 'SHOW_ACTIVITY_NAME_CHANGED';
export const SHOW_ACTIVITY_START_DATE_CHANGED = 'SHOW_ACTIVITY_START_DATE_CHANGED';
export const SHOW_ACTIVITY_START_TIME_CHANGED = 'SHOW_ACTIVITY_START_TIME_CHANGED';
export const SHOW_ACTIVITY_CREATED = 'SHOW_ACTIVITY_CREATED';
export const SHOW_ACTIVITY_UPDATED = 'SHOW_ACTIVITY_UPDATED';

export const SHOW_ADVANCE_SEND_CHANGED = 'SHOW_ADVANCE_SEND_CHANGED';
export const SHOW_ADVANCE_SEND_TO_CHANGED = 'SHOW_ADVANCE_SEND_TO_CHANGED';
export const SHOW_ADVANCE_SEND_SUBJECT_CHANGED = 'SHOW_ADVANCE_SEND_SUBJECT_CHANGED';
export const SHOW_ADVANCE_SEND_BODY_CHANGED = 'SHOW_ADVANCE_SEND_BODY_CHANGED';
export const SHOW_ADVANCE_SEND_NOTE_CLICKED = 'SHOW_ADVANCE_SEND_NOTE_CLICKED';
export const SHOW_ADVANCE_SEND_CREATED = 'SHOW_ADVANCE_SEND_CREATED';

export const SHARE_SETTINGS_POPUP_CLOSED = 'SHARE_SETTINGS_POPUP_CLOSED';
export const CONFIRM_INVITATION_CHANGED = 'CONFIRM_INVITATION_CHANGED';
export const CONFIRM_INVITATION_MEMO_CHANGED = 'CONFIRM_INVITATION_MEMO_CHANGED';
export const EMAILS_TO_INVITE_CHANGED = 'EMAILS_TO_INVITE_CHANGED';
export const CONFIRM_INVITATIONS_CREATED = 'CONFIRM_INVITATIONS_CREATED';
export const CONFIRM_INVITATIONS_DELETED = 'CONFIRM_INVITATIONS_DELETED';
export const IS_VIEWING_GUESTS_CHANGED = 'IS_VIEWING_GUESTS_CHANGED';
export const GUEST_TEAM_MEMBERSHIP_REMOVED = 'GUEST_TEAM_MEMBERSHIP_REMOVED';

export const PERMISSION_SET_CHANGED = 'PERMISSION_SET_CHANGED';
export const PERMISSION_RULE_ENABLED_CLICKED = 'PERMISSION_RULE_ENABLED_CLICKED';
export const SELECT_ALL_PERMISSION_RULES_CLICKED = 'SELECT_ALL_PERMISSION_RULES_CLICKED';
export const ON_PERMISSION_SET_SAVED_CHANGED = 'ON_PERMISSION_SET_SAVED_CHANGED';
export const CONFIRM_PAGE_SECTION_CHANGED = 'CONFIRM_PAGE_SECTION_CHANGED';

export const OFFER_FOR_SETTLEMENT_CHANGED = 'OFFER_FOR_SETTLEMENT_CHANGED';

export const ADD_SOCIAL_MARKETING_BUDGET_ITEM = 'ADD_SOCIAL_MARKETING_BUDGET_ITEM';
export const ADD_TRADITIONAL_MARKETING_BUDGET_ITEM = 'ADD_TRADITIONAL_MARKETING_BUDGET_ITEM';

export const MARKETING_BUDGET_ITEM_CREATED = 'MARKETING_BUDGET_ITEM_CREATED';
export const MARKETING_BUDGET_ITEM_DESTROY = 'MARKETING_BUDGET_ITEM_DESTROY';
export const EDIT_MARKETING_BUDGET_ITEM = 'EDIT_MARKETING_BUDGET_ITEM';
export const MARKETING_BUDGET_ITEM_UPDATED = 'MARKETING_BUDGET_ITEM_UPDATED';

export const MARKETING_BUDGET_ITEM_TO_EDIT_CHANGED = 'MARKETING_BUDGET_ITEM_TO_EDIT_CHANGED';
export const SHOW_WIDGET_CODE_CHANGED = 'SHOW_WIDGET_CODE_CHANGED';

export const FINANCE_ITEMS_CHANGED = 'FINANCE_ITEMS_CHANGED';

export const FINANCE_ITEM_TO_EDIT_CHANGED = 'FINANCE_ITEM_TO_EDIT_CHANGED';
export const FINANCE_ITEM_TO_EDIT_CANCELED = 'FINANCE_ITEM_TO_EDIT_CANCELED';
export const FINANCE_ITEM_DELETED = 'FINANCE_ITEM_DELETED';
export const FINANCE_ITEM_ADDED = 'FINANCE_ITEM_ADDED';
export const FINANCE_ITEM_CREATED = 'FINANCE_ITEM_CREATED';
export const FINANCE_ITEM_UPDATED = 'FINANCE_ITEM_UPDATED';

export const FINANCE_ITEM_FOR_FORM_CHANGED = 'FINANCE_ITEM_FOR_FORM_CHANGED';

export const FINANCE_CATEGORIES_CHANGED = 'FINANCE_CATEGORIES_CHANGED';

export const EDITING_CONFIRM_WEBSITE_CHANGED = 'EDITING_CONFIRM_WEBSITE_CHANGED';
export const EDITING_CONFIRM_WEBSITE_VALUE_CHANGED = 'EDITING_CONFIRM_WEBSITE_VALUE_CHANGED';

export const FOLDER_CHANGED = 'FOLDER_CHANGED';

export const PROFIT_AND_LOSS_CHANGED = 'PROFIT_AND_LOSS_CHANGED';
export const HOLD_GROUP_DELETION_CHANGED = 'HOLD_GROUP_DELETION_CHANGED';
export const HOLD_TO_CONFIRM_CHANGED = 'HOLD_TO_CONFIRM_CHANGED';
export const USER_CHANGED_DOORS_TIME_CHANGED = 'USER_CHANGED_DOORS_TIME_CHANGED';
export const USER_CHANGED_EVENT_END_TIME_CHANGED = 'USER_CHANGED_EVENT_END_TIME_CHANGED';
export const IS_CONVERTING_HOLD_TO_CONFIRM_CHANGED = 'IS_CONVERTING_HOLD_TO_CONFIRM_CHANGED';

export const POSSIBLE_MATCHING_OFFERS_CHANGED = 'POSSIBLE_MATCHING_OFFERS_CHANGED';
export const POSSIBLE_MATCHING_OFFER_REMOVED = 'POSSIBLE_MATCHING_OFFER_REMOVED';
