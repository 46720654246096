import PropTypes from 'prop-types';
import React from 'react';

const SocialPostStatusBars = ({
  post
}) => (
  <div>
    {post.social_posts.map((socialPost, index) =>
      <span key={index} className={"social-post d-inline-block py-1 px-2 mr-2 mb-2 " + socialPost.demodulized_type}>
        {socialPost.demodulized_type === "facebook" ? (
          <span>
            <span className="mr-3">
              <i className="fab fa-facebook-f"></i>
            </span>
            <span className="mr-3">
              <i className="far fa-thumbs-up mr-1"></i>
              {socialPost.likes_count}
            </span>
            <span className="mr-1">
              <i className="far fa-comment-alt mr-1"></i>
              {socialPost.comments_count}
            </span>
          </span>
        ) : socialPost.demodulized_type === "instagram" ? (
          <span>
            <span className="mr-3">
              <i className="fab fa-instagram"></i>
            </span>
            <span className="mr-3">
              <i className="far fa-heart mr-1"></i>
              {socialPost.likes_count}
            </span>
            <span className="mr-1">
              <i className="far fa-comment-alt mr-1"></i>
              {socialPost.comments_count}
            </span>
          </span>
        ) : socialPost.demodulized_type === "twitter" ? (
          <span>
            <span className="mr-3">
              <i className="fab fa-twitter"></i>
            </span>
            <span className="mr-3">
              <i className="far fa-heart mr-1"></i>
              {socialPost.likes_count}
            </span>
            <span className="mr-1">
              <i className="fas fa-retweet mr-1"></i>
              {socialPost.reposts_count}
            </span>
          </span>
        ) : null}
      </span>
    )}
  </div>
);

SocialPostStatusBars.propTypes = {
  post: PropTypes.object.isRequired
};

export default SocialPostStatusBars;
