import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Popup from "reactjs-popup";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "215px",
  "padding": "0px"
}

const TicketingSystemsWrapper = styled.div`
  .card {
    height: 75px;
    border: 1px solid #ebebeb;

    .subtitle {
      font-size: 12px;
    }
  }
`;

const TicketingSystems = ({eventbriteAccessId}) => {
  return (
    <TicketingSystemsWrapper className="row">
      <div className="col-12">
        <div className="row">
          <div className="col">
            <p className="mb-0 text-dark"
              style={{ "fontSize": "16px" }}>
              <strong>Event and Ticketing Systems</strong>
            </p>
            <p className="mb-0 text-muted small">
              Connect to various event and ticketing systems.
            </p>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 mb-2">
            <div className="card text-center">
              <div className="card-body px-3 py-2">
                <div className="row h-100">
                  <div className="col-xs-auto mx-3 d-flex align-items-center">
                    <img className="integration-logo img-fluid" src="/logo-white-circle.png" />
                  </div>
                  <div className="col text-left pl-0 d-flex flex-column justify-content-center">
                    <strong>Eventbrite</strong>
                    <p className="text-muted m-0 subtitle">Sync calendar and ticket sales</p>
                    {!eventbriteAccessId && (
                      <p class="m-0">
                        <a
                          data-method="post"
                          href="/users/auth/eventbrite"
                        >
                          Connect
                        </a>
                      </p>
                    )}
                  </div>
                  <div className="col-xs-auto pr-3">
                    {eventbriteAccessId && (
                      <>
                        <Popup arrow={false}
                                offsetY={7}
                                position="bottom right"
                                contentStyle={popupContentStyle}
                                onOpen={
                                  (e) => {
                                    e.preventDefault();
                                  }
                                }
                                trigger={open => (
                                  <a href="#"
                                    style={{"color": "#cccccc"}}
                                    className="align-self-center">
                                    <i className="fas fa-ellipsis-v small"></i>
                                  </a>
                                )} >
                          {close => (
                            <div className='row text-left'>
                              <div className="col-12">
                                <ul className="list-group offer-inline-menu highlight-primary">
                                  <li className="list-group-item p-3">
                                    <a href="/eventbrite_accesses/${eventbriteAccessId}/edit">Configure</a>
                                  </li>
                                  <li className="list-group-item p-3">
                                    <a
                                      data-confirm="Are you sure? Any data that is associated to Eventbrite (such as events) will be immediately deleted from Opendate."
                                      className="text-muted"
                                      data-method="delete"
                                      href="/eventbrite_accesses/${eventbriteAccessId}
                                      ">
                                      Disconnect
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-2">
            <div className="card text-center">
              <div className="card-body px-3 py-2">
                <div className="row h-100">
                  <div className="col-xs-auto mx-3 d-flex align-items-center">
                    <img className="integration-logo img-fluid" src="/toast-logo.png" />
                  </div>
                  <div className="col text-left pl-0 d-flex flex-column justify-content-center">
                    <strong>Toast</strong>
                    <p className="text-muted m-0 subtitle">Contact Us to Get Started</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-2">
            <div className="card text-center">
              <div className="card-body px-3 py-2">
                <div className="row h-100">
                  <div className="col-xs-auto mx-3 d-flex align-items-center">
                    <img className="integration-logo img-fluid" src="/toneden-logo.png" />
                  </div>
                  <div className="col text-left pl-0 d-flex flex-column justify-content-center">
                    <strong>ToneDen</strong>
                    <p className="text-muted m-0 subtitle">Coming Soon!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TicketingSystemsWrapper>
  );
}

export default TicketingSystems;

