import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SlidingPane from "react-sliding-pane";
import axios from "axios";
import { toast } from "react-toastify";

const paneStyle = {
  height: 600,
  width: 415,
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const headerStyle = {
  background: "#1A82C4",
  borderBottom: "2px solid #fafafa",
  borderRadius: "5px 0 0 0",
};

const headerTextStyle = {
  color: "white",
  fontSize: 18,
};

const headerRightStyle = {
  paddingRight: 40,
};

const closeIconStyle = {
  color: "white",
  fontSize: 18,
};

const formContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 20,
  padding: 25,
};

const labelStyle = {
  color: "#808080",
  fontSize: 14,
  marginBottom: 2,
};

const inputStyle = {
  border: "1px solid #80808055",
  borderRadius: 5,
  color: "#333333",
  fontSize: 14,
};

const footerContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 20,
  justifyContent: "flex-end",
  padding: 25,
};

const cancelButtonStyle = {
  color: "#1982C4",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  padding: "5px 10px",
};

const createButtonStyle = {
  background: "#1982C4",
  borderRadius: 5,
  color: "white",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  padding: "5px 10px",
  border: "none",
};

const AddFanSlidingPane = ({
  csrfToken,
  team,
  addAFanIsOpen,
  setAddAFanIsOpen,
}) => {
  const [formState, setFormState] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setAddAFanIsOpen(false);

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/fans`, {
      fan: formState,
    })
      .then(() => {
        setFormState({});

        toast.success("Fan successfully created", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        setFormState({});
        const { errors } = error.response.data;

        toast.error(errors.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };

  return (
    <SlidingPane
      isOpen={addAFanIsOpen}
      className="sliding-pane-container dynamic-height"
      hideHeader={true}
      onRequestClose={() => setAddAFanIsOpen(false) }
      style={paneStyle}
    >
      <React.Fragment>
        <form style={containerStyle} onSubmit={handleSubmit}>
          <div>
            <div className="row m-0 py-4" style={headerStyle}>
              <div className="col pl-4">
                <h3 className="mb-0" style={headerTextStyle}>
                  <strong>Add a Fan</strong>
                </h3>
              </div>
              <div className="col-xs-auto" style={headerRightStyle}>
                <a href="#" className="text-muted"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      setAddAFanIsOpen(false);
                    }
                  }
                >
                  <i className="fas fa-times" style={closeIconStyle}/>
                </a>
              </div>
            </div>
            <div style={formContainerStyle}>
              <div>
                <div style={labelStyle}>First Name</div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter first name"
                  value={formState.first_name}
                  style={inputStyle}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      first_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <div style={labelStyle}>Last Name</div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter last name"
                  value={formState.last_name}
                  style={inputStyle}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      last_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <div style={labelStyle}>Email</div>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email address"
                  value={formState.email}
                  style={inputStyle}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div style={footerContainerStyle}>
            <div onClick={() => setAddAFanIsOpen(false)} style={cancelButtonStyle}>
              Cancel
            </div>
            <button type="submit" style={createButtonStyle}>
              Create
            </button>
          </div>
        </form>
      </React.Fragment>
    </SlidingPane>
  );
};

AddFanSlidingPane.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  addAFanIsOpen: PropTypes.bool.isRequired,
  setAddAFanIsOpen: PropTypes.func.isRequired,
};

export default AddFanSlidingPane;
