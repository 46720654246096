import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "470px",
  "padding": "15px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "215px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const SocialManager = ({
  csrfToken,
  team,
  twitterProviderId,
  facebookProviderId,
  onepostPublicKey,
  authorizedPages,
  deleteAuthorizedPage,
  addingAccount,
  addingAccountChanged
}) => (
  <div className="row">
    <div className="col-12">
      <div className="row">
        <div className="col">
          <p className="mb-0 text-dark"
            style={{"fontSize": "16px"}}>
            <strong>Social Accounts</strong>
          </p>
          <p className="mb-0 text-muted small">
            Connect your social accounts to enable social posting.
          </p>
        </div>
      </div>
      <div className="row mt-3">
        {authorizedPages.length === 0 ? (
          <div className="col-12">
            <div className="card"
                  style={{
                    "borderRadius": "4px",
                    "borderLeft": "4px solid #3c82bf"
                  }}>
              <div className="card-body px-3 py-2">
                <p className="mb-0 d-flex">
                  <i className="fas fa-exclamation-circle mr-3 align-self-center"
                      style={{
                        "color": "#3c82bf",
                        "fontSize": "18px"
                      }}></i>
                  <span className="d-inline-block align-self-center">
                    No social accounts have been added yet.
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          authorizedPages.map((authorizedPage, index) =>
            <div key={index} className="col-12 col-md-6 col-lg-4">
              <div className="card"
                    style={{
                      "border": "1px solid #ececec",
                      "borderRadius": "4px"
                    }}>
                <div className="card-body py-1 px-2">
                  <div className="row">
                    <div className="col-xs-auto"
                          style={{"paddingLeft": "15px"}}>
                      <span className={"avatar-wrapper " + authorizedPage.demodulized_type}>
                        <div className={"social-icon d-flex justify-content-center " + authorizedPage.demodulized_type}>
                          {authorizedPage.demodulized_type === "twitter" ? (
                            <i className="fab fa-twitter align-self-center"></i>
                          ) : authorizedPage.demodulized_type === "facebook" ? (
                            <i className="fab fa-facebook-f align-self-center"></i>
                          ) : authorizedPage.demodulized_type === "instagram" ? (
                            <i className="fab fa-instagram align-self-center"></i>
                          ) : null}
                        </div>
                        <span className="dot">
                          <img src={authorizedPage.profile_image_url} />
                        </span>
                      </span>
                    </div>
                    <div className="col">
                      <p className="mb-0">
                        <strong>{authorizedPage.name}</strong>
                      </p>
                      <p className="mb-0 small text-muted"
                          style={{"marginTop": "-3px"}}>
                        @{authorizedPage.nickname}
                      </p>
                    </div>
                    <div className="col-xs-auto d-flex justify-content-center"
                          style={{"paddingRight": "15px"}}>
                      <Popup arrow={false}
                              offsetY={7}
                              position="bottom right"
                              contentStyle={popupContentStyle}
                              onOpen={
                                (e) => {
                                  e.preventDefault();
                                }
                              }
                              trigger={open => (
                                <a href="#"
                                  style={{"color": "#cccccc"}}
                                  className="align-self-center">
                                  <i className="fas fa-ellipsis-v small"></i>
                                </a>
                              )} >
                        {close => (
                          <div className='row text-left'>
                            <div className="col-12">
                              <ul className="list-group offer-inline-menu highlight-primary">
                                <li className="list-group-item">
                                  <Popup
                                      trigger={
                                        <a href="#" className="text-dark">
                                          <span className="text-danger d-inline-block text-center" style={{width: "28px"}}>
                                            <i className="fas fa-trash"></i>
                                          </span>
                                          <span className="text-dark">
                                            Remove from Opendate
                                          </span>
                                        </a>
                                      }
                                      modal
                                      contentStyle={deleteModalContentStyle}
                                      onOpen={
                                        (e) => {
                                          e.preventDefault();
                                        }
                                      }
                                      closeOnDocumentClick
                                    >
                                    {closeDeletePopup => (
                                      <div>
                                        <div className="row">
                                          <div className="col">
                                            <div className="row">
                                              <div className="col">
                                                <h5 style={{"marginBottom": "16px"}}>
                                                  <div className="strong">Delete social account?</div>
                                                </h5>
                                              </div>
                                              <div className="col-xs-auto">
                                                <a href="#"
                                                    className="text-dark"
                                                    style={{"paddingRight": "15px"}}
                                                    onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                  <i className="fa fa-times"></i>
                                                </a>
                                              </div>
                                            </div>
                                            <p>You're about to delete this social account.</p>
                                            <p>This cannot be undone.</p>
                                            <p>Are you sure?</p>
                                            <div className="text-right">
                                              <a href="#"
                                                  onClick={
                                                    (e) => {
                                                      e.preventDefault();
                                                      closeDeletePopup();
                                                    }
                                                  }>
                                                Cancel
                                              </a>
                                              <a href="#"
                                                  className="btn btn-danger ml-3"
                                                  onClick={
                                                    (e) => {
                                                      e.preventDefault();
                                                      closeDeletePopup();
                                                      close();
                                                      deleteAuthorizedPage(team, csrfToken, authorizedPage);
                                                    }
                                                  }>
                                                Yes, delete social account
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div className="row">
        <div className="col">
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();
                  addingAccountChanged(true);
                }
              }
              className="btn btn-primary small">
            <strong>
              Add Account
            </strong>
          </a>
        </div>
      </div>
    </div>
    <Popup
      open={addingAccount}
      contentStyle={modalContentStyle}
      closeOnDocumentClick={true}
      onClose={() => addingAccountChanged(false)}
    >
      <div>
        <div className="row mb-5">
          <div className="col d-flex">
            <h5 className="mb-0 align-self-center">
              <strong>Add an account to Opendate</strong>
            </h5>
          </div>
          <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
            <a href="#"
               style={{"fontSize": "24px"}}
               className="text-dark"
               onClick={(e) => {
                 e.preventDefault();
                 addingAccountChanged(false);
               }} >
              <i className="fal fa-times"></i>
            </a>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col d-none">
            <a className="text-dark" href={"https://api.getonepost.com/users/auth/twitter?provider_id=" + twitterProviderId + "&public_key=" + onepostPublicKey}>
              <div className="card mb-0 h-100">
                <div className="card-body text-center py-4 px-0">
                  <div style={{"height": "87px"}}>
                    <img className="img-fluid pb-2" src="/twitter@2x.png" style={{maxWidth: "64px"}} />
                  </div>
                  <strong className="d-block">Twitter</strong>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a className="text-dark" href={"https://api.getonepost.com/users/auth/facebook?provider_id=" + facebookProviderId + "&public_key=" + onepostPublicKey}>
              <div className="card mb-0 h-100">
                <div className="card-body text-center py-4 px-0">
                  <img className="img-fluid pb-2" src="/fb-insta@2x.png" style={{maxWidth: "113px"}} />
                  <strong className="d-block">Facebook & Instagram</strong>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Popup>
  </div>
);

SocialManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  twitterProviderId: PropTypes.number.isRequired,
  facebookProviderId: PropTypes.number.isRequired,
  addingAccount: PropTypes.bool,
  addingAccountChanged: PropTypes.func.isRequired,
  onepostPublicKey: PropTypes.string.isRequired,
  authorizedPages: PropTypes.array,
  deleteAuthorizedPage: PropTypes.func.isRequired
};

export default SocialManager;
