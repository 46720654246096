import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Tippy from '@tippyjs/react';
import MapWithMarkerClusters from '../../MapWithMarkerClusters/components/MapWithMarkerClusters';

import AsyncSelect from 'react-select/async';
const axios = require('axios').default;

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(newOptions);
      })
  });
}, 250);

const PotentialArtistFans = ({
  csrfToken,
  team,
  currentUser,
  selectedArtist,
  selectedArtistChanged,
  potentialArtistFanData,
  fetchPotentialArtistFanData,
  isLoadingPotentialArtistFanData
}) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col d-flex align-items-center">
          <p style={{
              "fontSize": "20px",
              "marginBottom": "6px"
            }}>
            <strong>
              Potential Artist Fans
            </strong>
            <Tippy content="Search an artist and Opendate will display the number of potential fans based on artist, similar artists, and genre.">
              <i className="fas fa-info-circle text-muted ml-2"
                 style={{"fontSize": "16px"}}></i>
            </Tippy>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card border-0"
               style={{
                 "borderRadius": "4px",
                 "background": "#f9f9f9"
               }}>
            <div className="card-body"
                 style={{"padding": "30px 20px"}}>
              <p style={{"fontSize": "16px"}}>
                Search for an artist and Opendate will return all potential fans.
              </p>
              <AsyncSelect cacheOptions
                           styles={selectStyles}
                           menuPortalTarget={document.querySelector('body')}
                           defaultOptions
                           onChange={
                             (option) => {
                                selectedArtistChanged(option);
                                fetchPotentialArtistFanData(team, option.value);
                             }
                           }
                           value={ selectedArtist }
                           loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback)} />
              {isLoadingPotentialArtistFanData || Object.keys(potentialArtistFanData).length === 0 ? (
                <div className="row">
                  <div className="col-12 text-center my-4">
                    <img src="/uploading-loading.gif"
                            className="mr-1"
                            style={{width: "16px"}} />
                  </div>
                </div>
              ) : (
                <div className="form-row"
                     style={{
                       "marginTop": "40px"
                     }}>
                  <div className="col-12 col-lg-6"
                       style={{"marginBottom": "20px"}}>
                    <div className="row">
                      <div className="col-12 d-flex align-items-center">
                        <img src={potentialArtistFanData.artist.image_thumb_url_for_json}
                                      style={{
                                        "height": "48px",
                                        "width": "48px",
                                        "borderRadius": "4px",
                                        "marginRight": "14px"
                                      }} />
                        <p className="mb-0"
                          style={{"fontSize": "14px"}}>
                          <strong>{potentialArtistFanData.artist.name}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="row">
                      <div className="col-12" style={{"marginBottom": "10px"}}>
                        <p className="mb-0" style={{"fontSize": "32px"}}>
                          <strong>{potentialArtistFanData.potential_fans.toLocaleString()}</strong>
                        </p>
                        <p className="mb-0 text-muted" style={{"fontSize": "10px", "marginTop": "-7px"}}>
                          POTENTIAL FANS
                        </p>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col" style={{"marginBottom": "10px"}}>
                        <p className="mb-0" style={{"fontSize": "20px"}}>
                          <strong>{potentialArtistFanData.artist_fans.toLocaleString()}</strong>
                        </p>
                        <p className="mb-0 text-muted text-nowrap" style={{"fontSize": "10px", "marginTop": "-2px"}}>
                          ARTIST FANS
                        </p>
                      </div>
                      <div className="col" style={{"marginBottom": "10px"}}>
                        <p className="mb-0" style={{"fontSize": "20px"}}>
                          <strong>{potentialArtistFanData.similar_artist_fans.toLocaleString()}</strong>
                        </p>
                        <p className="mb-0 text-muted text-nowrap" style={{"fontSize": "10px", "marginTop": "-2px"}}>
                          SIMILAR ARTIST FANS
                        </p>
                      </div>
                      <div className="col" style={{"marginBottom": "10px"}}>
                        <p className="mb-0" style={{"fontSize": "20px"}}>
                          <strong>{potentialArtistFanData.genre_fans.toLocaleString()}</strong>
                        </p>
                        <p className="mb-0 text-muted text-nowrap" style={{"fontSize": "10px", "marginTop": "-2px"}}>
                          GENRE FANS
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right"
                       style={{
                         "borderTop": "1px solid #e8e8e8",
                         "marginTop": "10px",
                         "paddingTop": "20px"
                       }}>
                    <a href={`/teams/${team.id}/fans/potential_artist_fans.csv?artist_id=${potentialArtistFanData.artist.id}`}
                       style={{"fontSize": "14px"}}>
                      View the list
                      <i className="far fa-chevron-right ml-2"></i>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PotentialArtistFans.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedArtist: PropTypes.object.isRequired,
  selectedArtistChanged: PropTypes.func.isRequired,
  potentialArtistFanData: PropTypes.object,
  fetchPotentialArtistFanData: PropTypes.func.isRequired,
  isLoadingPotentialArtistFanData: PropTypes.bool
};

export default PotentialArtistFans;
