import { combineReducers } from 'redux';

import {
  TEAM_MEMBERSHIP_CHANGED,
  ORIGINAL_TEAM_MEMBERSHIP_CHANGED
} from '../constants/emailNotificationSettingsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const teamMembership = (state = {}, action) => {
  switch (action.type) {
    case TEAM_MEMBERSHIP_CHANGED:
      return action.teamMembership;
    default:
      return state;
  }
};

const originalTeamMembership = (state = {}, action) => {
  switch (action.type) {
    case ORIGINAL_TEAM_MEMBERSHIP_CHANGED:
      return action.teamMembership;
    default:
      return state;
  }
};

const emailNotificationSettingsReducer = combineReducers({
  csrfToken,
  currentUser,
  team,
  teamMembership,
  originalTeamMembership
});

export default emailNotificationSettingsReducer;
