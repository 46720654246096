import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  COPY_FILE_CONTEXT,
  MOVE_TO_FOLDER_CONTEXT,
  NEW_FOLDER_CONTEXT,
  UPLOAD_FILES_CONTEXT
} from '../constants/folderViewConstants';

const FindFolderRow = ({
  csrfToken,
  team,
  currentUser,
  foldered,
  depth,
  toggleFolder,
  targetFolder,
  targetFolderChanged,
  depthWidth,
  context
}) => {
  return (
    foldered.item.is_folder ? (
      <div className="row m-0">
        <div className="col-12 p-0 d-flex align-items-center small">
          <div className='d-flex align-items-center'
              style={{
                  "padding": "7px 12px 7px 0px",
                  "paddingLeft": (depthWidth(depth) + "px")
                }}>
            <div className="table-gutter checkbox-table-gutter">
              <div className="custom-control custom-checkbox custom-checkbox-table">
                <input type="checkbox"
                      checked={
                        targetFolder && targetFolder.id === foldered.item.id
                      }
                      onChange={
                        (e) => {
                          targetFolderChanged(foldered.item);
                        }
                      }
                      disabled={
                        [MOVE_TO_FOLDER_CONTEXT, COPY_FILE_CONTEXT].includes(context)
                          && !foldered.item.can_be_move_target
                      }
                      id={"calendar-event-attachment-target-" + foldered.id}
                      className="custom-control-input" />
                <label htmlFor={"calendar-event-attachment-target-" + foldered.id}
                      className="custom-control-label">
                  &nbsp;
                </label>
              </div>
            </div>
            <div className="table-gutter folder-toggle-table-gutter">
              <a href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    toggleFolder(foldered.item);
                  }
                }
                className="text-dark">
                {foldered.item.extended ? (
                  <i className="far fa-chevron-down"></i>
                ) : (
                  <i className="far fa-chevron-right"></i>
                )}
              </a>
            </div>
            <div className="d-flex justify-content-center mr-1"
                style={{
                  "width": "24px"
                }}>
              {foldered.item.foldable_type === "CalendarEvent" ? (
                <div className="square d-flex align-content-center justify-content-center">
                  <p className="align-self-center mb-0">
                    <i className={"far fa-calendar-star"}
                        style={{
                          "fontSize": "20px",
                          "color": "#1982C4"
                        }}>
                    </i>
                  </p>
                </div>
              ) : (
                <div className="square d-flex align-content-center justify-content-center">
                  <p className="align-self-center mb-0">
                    <i className={"fas " + foldered.item.fontawesome_icon_class}
                        style={{
                          "fontSize": "20px",
                          "color": "#75b5e6"
                        }}>
                    </i>
                  </p>
                </div>
              )}
            </div>
            <div className="text-overflow-ellipsis"
                title={foldered.item.human_file_name}>
              <a className="text-dark"
                onClick={
                  (e) => {
                    e.preventDefault();
                    toggleFolder(foldered.item);
                  }
                }
                href="#">
                <strong>
                  {foldered.item.human_file_name}
                </strong>
              </a>
              {foldered.permission_set_id ? (
                <i className="ml-2 far fa-lock small text-danger"></i>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    ) : null
  );
};

FindFolderRow.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  foldered: PropTypes.object.isRequired,
  depth: PropTypes.number.isRequired,
  toggleFolder: PropTypes.func.isRequired,
  targetFolder: PropTypes.object,
  targetFolderChanged: PropTypes.func.isRequired,
  depthWidth: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired
};

export default FindFolderRow;
