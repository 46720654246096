import PropTypes from 'prop-types';
import React from 'react';

import SingleSelectFilter from './filters/SingleSelectFilter';
import MultiSelectFilter from './filters/MultiSelectFilter';
import DateRangeFilter from './filters/DateRangeFilter';
import RangeFilter from './filters/RangeFilter';
import CheckBoxesFilter from './filters/CheckBoxesFilter';

const numberOfFiltersWithValue = (filters) => {
  return filters.reduce(function(total, filter){
    if(filter.value.length > 0){
      total = (total + 1);
    }

    return total;
  }, 0);
}

const components = {
  SingleSelectFilter: SingleSelectFilter,
  MultiSelectFilter: MultiSelectFilter,
  DateRangeFilter: DateRangeFilter,
  RangeFilter: RangeFilter,
  CheckBoxesFilter: CheckBoxesFilter
};

const FilterPanel = ({ filters, updateFilter, debouncedUpdateFilter, clearFilterValues }) => (
  <div>
    <div className="row dataTable-filter-row">
      {filters.length > 0 ? (
        <div className="col-12 col-md px-3 pb-3 pt-0 filters-col">
          {filters.map((filter, i) =>
            React.createElement(components[filter.type], {key: i, filter: filter, updateFilter: updateFilter, debouncedUpdateFilter: debouncedUpdateFilter})
          )}
        </div>
      ) : null}
      <div className="col-12 col-md-auto px-3 pb-3 pt-0 clear-filters-col">
        <div style={{"marginTop": "8px"}}>
          <a href="#"
            style={{
              "fontSize": "14px",
              "padding": "6px 5px"
            }}
            onClick={(e) => {e.preventDefault(); clearFilterValues();}}
            className={"btn btn-external strong " + (numberOfFiltersWithValue(filters) === 0 ? "disabled" : "")}>
            Clear all filters ({numberOfFiltersWithValue(filters)})
          </a>
        </div>
      </div>
    </div>
  </div>
);

FilterPanel.propTypes = {
  filters: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
  debouncedUpdateFilter: PropTypes.func.isRequired,
  clearFilterValues: PropTypes.func.isRequired
};

export default FilterPanel;
