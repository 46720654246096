import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

const sortedFolderedItems = (folder, sortBy) => {
  if(folder.foldable_type === "VenueOwnership"){
    return folder.foldered_items
      .sort((a, b) => {
        if (sortBy.column === "calendar_event_id" && sortBy.direction === "asc"){
          if(a.calendar_event_id === b.calendar_event_id){
            return a.item.human_file_name.localeCompare(b.item.human_file_name);
          } else {
            return a.calendar_event_id - b.calendar_event_id;
          }
        } else if (sortBy.column === "calendar_event_id" && sortBy.direction === "desc") {
          if(a.calendar_event_id === b.calendar_event_id){
            return a.item.human_file_name.localeCompare(b.item.human_file_name);
          } else {
            return b.calendar_event_id - a.calendar_event_id;
          }
        } else if (sortBy.column === "item_name" && sortBy.direction === "asc") {
          return a.item_name.localeCompare(b.item_name);
        } else if (sortBy.column === "item_name" && sortBy.direction === "desc") {
          return b.item_name.localeCompare(a.item_name);
        } else {
          if(a.item.calendar_event && b.item.calendar_event){
            if(a.item.calendar_event.start_date === b.item.calendar_event.start_date){
              return a.item.human_file_name.localeCompare(b.item.human_file_name);
            } else {
              return new Date(b.item.calendar_event.start_date) - new Date(a.item.calendar_event.start_date);
            }
          }
        }
      });
  } else {
    return folder.foldered_items
      .sort((a, b) => a.item.human_file_name.localeCompare(b.item.human_file_name));
  }
}

const RecursiveFolders = ({
  csrfToken,
  team,
  fetchCalendarEventAttachment,
  currentUser,
  folder,
  depth,
  toggleFolder,
  selectedFolderedItems,
  togglefolderedItemSelected,
  stageFolderedItemsForDestroy,
  parentFolderedItems,
  copiedFolderedItem,
  copiedFolderedItemChanged,
  copiedFolderedItemNameChanged,
  updateFolderedItemName,
  stageFolderedItemsForMove,
  rootFolder,
  fetchPermissionSet,
  folderedItemUpdated,
  fetchFolderedItemsForFolder,
  children,
  scrollableTarget,
  searchTerm,
  sortBy
}) => {
  useEffect(() => {
    if(!folder.lastRequestData && folder.isLoadingPaginatedData === undefined){
      fetchFolderedItemsForFolder(team, folder, searchTerm, sortBy);
    }
  }, [folder]);

  return (
    <InfiniteScroll dataLength={folder.foldered_items.length}
                    next={
                      () => {
                        fetchFolderedItemsForFolder(team, folder, searchTerm, sortBy);
                      }
                    }
                    style={{"overflow": "visible"}}
                    scrollableTarget={scrollableTarget}
                    hasMore={
                      folder.lastRequestData
                        && folder.foldered_items.length < folder.lastRequestData.total_entries
                    }>
      <React.Fragment>
        {sortedFolderedItems(folder, sortBy).map((foldered, index) =>
          <React.Fragment key={index}>
            {children(foldered, depth, rootFolder, parentFolderedItems)}
            {foldered.item.is_folder && foldered.item.extended ? (
              <RecursiveFolders csrfToken={csrfToken}
                        team={team}
                        fetchCalendarEventAttachment={fetchCalendarEventAttachment}
                        currentUser={currentUser}
                        depth={(depth + 1)}
                        toggleFolder={toggleFolder}
                        selectedFolderedItems={selectedFolderedItems}
                        togglefolderedItemSelected={togglefolderedItemSelected}
                        stageFolderedItemsForDestroy={stageFolderedItemsForDestroy}
                        parentFolderedItems={[...parentFolderedItems, foldered]}
                        copiedFolderedItem={copiedFolderedItem}
                        copiedFolderedItemChanged={copiedFolderedItemChanged}
                        copiedFolderedItemNameChanged={copiedFolderedItemNameChanged}
                        updateFolderedItemName={updateFolderedItemName}
                        stageFolderedItemsForMove={stageFolderedItemsForMove}
                        rootFolder={rootFolder}
                        fetchPermissionSet={fetchPermissionSet}
                        folderedItemUpdated={folderedItemUpdated}
                        fetchFolderedItemsForFolder={fetchFolderedItemsForFolder}
                        sortBy={sortBy}
                        searchTerm={searchTerm}
                        children={children}
                        folder={foldered.item} />
            ) : null}
          </React.Fragment>
        )}
        {folder.isLoadingPaginatedData ? (
          <div className="row m-0 my-1">
            <div className='col-12 d-flex align-items-center'
                 style={{"paddingLeft": ((24 * depth) + "px")}}>
              <img src="/uploading-loading.gif"
                    style={{width: "16px"}} />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    </InfiniteScroll>
  );
};

RecursiveFolders.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  fetchCalendarEventAttachment: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  folder: PropTypes.object.isRequired,
  depth: PropTypes.number.isRequired,
  toggleFolder: PropTypes.func.isRequired,
  selectedFolderedItems: PropTypes.array,
  togglefolderedItemSelected: PropTypes.func.isRequired,
  stageFolderedItemsForDestroy: PropTypes.func.isRequired,
  parentFolderedItems: PropTypes.array,
  copiedFolderedItem: PropTypes.object,
  copiedFolderedItemChanged: PropTypes.func.isRequired,
  copiedFolderedItemNameChanged: PropTypes.func.isRequired,
  updateFolderedItemName: PropTypes.func.isRequired,
  stageFolderedItemsForMove: PropTypes.func.isRequired,
  rootFolder: PropTypes.object.isRequired,
  fetchPermissionSet: PropTypes.func.isRequired,
  folderedItemUpdated: PropTypes.func.isRequired,
  fetchFolderedItemsForFolder: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  scrollableTarget: PropTypes.string,
  searchTerm: PropTypes.string,
  sortBy: PropTypes.object
};

export default RecursiveFolders;
