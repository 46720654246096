import React from "react";

const peopleCardStyle = {
  backgroundColor: "#F9F9F9",
  borderRadius: "10px",
  padding: "15px",
  width: "fit-content",
};

const peopleContentStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
};

const peopleHeaderStyle = {
  fontSize: "14px",
  fontWeight: "bold",
};

const peopleRowStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "5px",
};

const faIconStyle = {
  color: "#1982C4"
};

const fanCountStyle = {
  fontSize: "16px",
  fontWeight: "bold",
};

const fanCopyStyle = {
  color: "#666",
  fontSize: "12px",
};

const PeopleCard = ({
  segment,
}) => {
  return (
    <div style={peopleCardStyle}>
      <div style={peopleContentStyle}>
        <div style={peopleHeaderStyle}>People</div>
        <div style={peopleRowStyle}>
          <i style={faIconStyle}className="far fa-users" />
          <div style={fanCountStyle}>{segment.fans_count.toLocaleString()}</div>
          <div style={fanCopyStyle}>people in segment</div>
        </div>
        <div style={peopleRowStyle}>
          <i style={faIconStyle}className="far fa-envelope" />
          <div>{segment.email_count.toLocaleString()}</div>
          <div style={{...fanCopyStyle, marginRight: "10px"}}>have emails</div>
          <i style={faIconStyle}className="far fa-mobile" />
          <div>{segment.phone_number_count.toLocaleString()}</div>
          <div style={fanCopyStyle}>have phone numbers</div>
        </div>
      </div>
    </div>
  );
};

export default PeopleCard;
