import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FeeRuleRow from './FeeRuleRow';
import { toast } from "react-toastify";

const axios = require("axios").default;

const FeeRuleTable = ({
  csrfToken,
  confirm,
  team,
  feeRuleSets
}) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [feeRules, setFeeRules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [feeRulesToCreate, setFeeRulesToCreate] = useState({});
  const [feeRulesToUpdate, setFeeRulesToUpdate] = useState({});

  const [onlineFeeRuleSet, setOnlineFeeRuleSet] = useState(
    () => feeRuleSets.find(feeRuleSet => feeRuleSet.use_case === "ONLINE")
  );

  const [atDoorCardFeeRuleSet, setAtDoorCardFeeRuleSet] = useState(
    () => feeRuleSets.find(feeRuleSet => feeRuleSet.use_case === "AT_DOOR_CARD")
  );

  const [atDoorCashFeeRuleSet, setAtDoorCashFeeRuleSet] = useState(
    () => feeRuleSets.find(feeRuleSet => feeRuleSet.use_case === "AT_DOOR_CASH")
  );

  const [selectedFeeRuleSet, setSelectedFeeRuleSet] = useState(onlineFeeRuleSet);

  const showToastError = (err) => {
    toast.error(err.response.data.join(", "), {
      position: toast.POSITION.TOP_CENTER,
      draggable: false,
      closeOnClick: false,
      autoClose: 5000,
      hideProgressBar: true
    });
  }

  const getCalendarEventFeeRules = (feeRules) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.get("/teams/" + team.id + "/calendar_events/" + confirm.id + "/calendar_event_fee_rules")
      .then(data => {
        setFeeRules(feeRules.map(feeRule => {
          feeRule.calendar_event_fee_rule = data.data.find(calendarEventFeeRule => {
            return calendarEventFeeRule.fee_rule_id === feeRule.id
          });
          return feeRule;
        }));
        setIsLoading(false);
        setIsProcessing(false);
      })
      .catch(err => {
        showToastError(err);
        setIsLoading(false);
        setIsProcessing(false);
      });
  };

  const getFeeRules = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.get("/teams/" + team.id + "/fee_rules", {
        params: {
          "q[fee_rule_set_id_eq]": selectedFeeRuleSet.id
        }
      })
      .then(data => {
        getCalendarEventFeeRules(data.data);
      })
      .catch(err => {
        showToastError(err);
        setIsLoading(false);
        setIsProcessing(false);
      });
  };

  const reInitializeState = () => {
    setCanSubmit(false);
    setFeeRulesToCreate({});
    setFeeRulesToUpdate({});
    getFeeRules();
  };

  useEffect(() => {
    reInitializeState();
  }, [selectedFeeRuleSet]);

  useEffect(() => {
    Object.keys(feeRulesToUpdate).length + Object.keys(feeRulesToCreate).length > 0 ? (
      setCanSubmit(true)
    ) : (
      setCanSubmit(false)
    );
  }, [feeRulesToCreate, feeRulesToUpdate]);

  const onRowChange = (feeRule, value) => {
    if (feeRule.calendar_event_fee_rule) {
      value === parseFloat(feeRule.calendar_event_fee_rule.organizer_service_fee) ? (
        setFeeRulesToUpdate(current => {
          const {[feeRule.id]: value, ...rest} = current;
          return rest;
        })
      ) : (
        setFeeRulesToUpdate({
          ...feeRulesToUpdate,
          [feeRule.calendar_event_fee_rule.id]: value
        })
      );
    } else {
      value === parseFloat(feeRule.organizer_service_fee) ? (
        setFeeRulesToCreate(current => {
          const {[feeRule.id]: value, ...rest} = current;
          return rest;
        })
      ) : (
        setFeeRulesToCreate({
          ...feeRulesToCreate,
          [feeRule.id]: value
        })
      )
    }
  }

  const handleSubmit = () => {
    setIsProcessing(true);
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    var requestPromises = [];

    for(var calendarEventFeeRuleID in feeRulesToUpdate) {
      requestPromises.push(
        axios.put("/teams/" + team.id + "/calendar_events/" + confirm.id + "/calendar_event_fee_rules/" + calendarEventFeeRuleID, {
          calendar_event_fee_rule: {
            organizer_service_fee: feeRulesToUpdate[calendarEventFeeRuleID]
          }
        }).catch(err => {
          showToastError(err);
        })
      );
    }

    for (var feeRuleID in feeRulesToCreate) {
      requestPromises.push(
        axios.post("/teams/" + team.id + "/calendar_events/" + confirm.id + "/calendar_event_fee_rules", {
          calendar_event_fee_rule: {
            calendar_event_id: confirm.id,
            fee_rule_id: feeRuleID,
            organizer_service_fee: feeRulesToCreate[feeRuleID]
          }
        }).catch(err => {
          showToastError(err);
        })
      );
    }

    axios.all(requestPromises).then(() => {
      reInitializeState();
    });
  };

  const handleReset = (calendarEventFeeRuleId) => {
    setIsProcessing(true);

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.delete("/teams/" + team.id + "/calendar_events/" + confirm.id + "/calendar_event_fee_rules/" + calendarEventFeeRuleId)
      .then(data => {
        reInitializeState();
      })
      .catch(err => {
        showToastError(err);
        reInitializeState();
      });
  };

  const containerStyle = {
    marginTop: "40px",
  };

  const tableStyle = {
    "marginBottom": "25px",
    "marginTop": "15px",
  };

  const thStyle= {
    "fontSize": "10px",
    "fontWeight": "100",
    "margin": "2px",
    "opacity": "0.5",
    "padding": "4px",
  };

  return (
    <div style={containerStyle}>
      <div className="row">
        <div className="col-12 col-md">
          <p className="mb-2">
            <strong>Organizer Fees</strong>
          </p>
          <p className="text-muted small mb-1">
            You will earn the following fees on each ticket sale, based on individual ticket price.
          </p>
          <p className="text-muted small mb-1">
            You may customize your fees for this show by modifiying the Fee column below.
          </p>
        </div>
        < div className="col-12">
          <form>
            <div className="form-group mb-0 mt-2">
              <select className="form-control form-control-sm"
                      value={selectedFeeRuleSet.id || ""}
                      onChange={
                        (e) => {
                          setSelectedFeeRuleSet(
                            feeRuleSets.find(feeRuleSet => feeRuleSet.id === parseInt(e.target.value))
                          );
                        }
                      }
                      style={{
                        "maxWidth": "200px",
                        "borderWidth": "1px",
                        "borderRadius": "4px"
                      }}
                      id="selected-fee-rule-set">
                <option value={onlineFeeRuleSet.id}>Online</option>
                <option value={atDoorCardFeeRuleSet.id}>At the door - Card</option>
                <option value={atDoorCashFeeRuleSet.id}>At the door - Cash</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      {
        isLoading ? (
          <div className="text-center">
            <img src="/uploading-loading.gif"
                  style={{width: "50px"}} />
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-md">
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>TICKET PRICE</th>
                    <th style={thStyle}>YOUR FEE</th>
                  </tr>
                </thead>
                <tbody>
                  {feeRules.map((feeRule, index) => (
                    <FeeRuleRow
                      key={feeRule.id}
                      feeRule={feeRule}
                      handleChange={(value) => {
                        onRowChange(feeRule, value)
                      }}
                      handleReset={handleReset}
                      isDisabled={isProcessing || feeRule.maximum === "0.0"}
                      isFirst={index === 0}
                      isLast={index === feeRules.length - 1}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
      <div className="row">
        <div className="col-12 col-md">
          <button
            className="btn btn-primary btn-sm"
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}

FeeRuleTable.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  feeRuleSets: PropTypes.array
};

export default FeeRuleTable;
