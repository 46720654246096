import { combineReducers } from 'redux';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const title = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const scope = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const taggable = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tagFilter = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tagFilterChanged = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onChange = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onTagCreate = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const popupClasses = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const popupStyles = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tagsButtonPosition = (state = "right", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const activeTagsAlignment = (state = "left", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tagManagerReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  scope,
  title,
  taggable,
  tagFilterChanged,
  onChange,
  onTagCreate,
  popupClasses,
  popupStyles,
  tagsButtonPosition,
  activeTagsAlignment,
});

export default tagManagerReducer;
