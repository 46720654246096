import PropTypes from 'prop-types';
import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import Flatpickr from "react-flatpickr";
var moment = require('moment');

import {CopyToClipboard} from 'react-copy-to-clipboard';

const dayOnChange = (e, checkAvailsSettings, checkAvailsSettingsChanged, dayNum) => {
  var updatedDaysOfWeek;

  if(e.target.checked) {
    updatedDaysOfWeek = [...checkAvailsSettings.onDaysOfWeek, dayNum];
  } else {
    updatedDaysOfWeek = checkAvailsSettings.onDaysOfWeek.filter((dow) => dow !== dayNum);
  }

  var updated = Object.assign({}, checkAvailsSettings, {
    onDaysOfWeek: updatedDaysOfWeek
  });

  checkAvailsSettingsChanged(updated);
}

const calendarMonthChanged = (instance, calendarRef) => {
  var approxMidMonth = new Date(instance.currentYear, instance.currentMonth, 15);
  calendarRef.getApi().gotoDate(approxMidMonth);
}

const CheckAvailsPopup = ({
    checkAvailsIsOpenChanged,
    csrfToken,
    team,
    checkAvailsSettings,
    checkAvailsSettingsChanged,
    availableDates,
    currentTeamMembership,
    calendarRef,
    checkAvailsDates,
    checkAvailsDatesChanged,
    checkAvailsLoading
  }) => (
  <div className="popup-modal-container">
    <div className="row">
      <div className="col d-flex align-items-center">
        <p className="mb-0" style={{"fontSize": "20px"}}>
          <strong>Check Avails</strong>
        </p>
      </div>
      <div className="col-xs-auto d-flex align-items-center"
           style={{"paddingRight": "15px"}}>
        <a href="#"
           className="text-dark"
           style={{"fontSize": "20px"}}
           onClick={
             (e) => {
               e.preventDefault();
               checkAvailsIsOpenChanged(false);
             }
           } >
          <i className="fa fa-times"></i>
        </a>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <p className="mb-0 text-muted"
           style={{"fontSize": "16px"}}>
          Search for available dates on your calendar.
        </p>
      </div>
    </div>
    <div className="row" style={{"marginTop": "20px"}}>
      <div className="col-12 col-md-6">
        <div className="card mb-0 border-0 h-100">
          <div className="card-body p-0">
            <form>
              <div className="form-group">
                <p className="mb-0">
                  <strong>Show me dates with:</strong>
                </p>
                <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table">
                  <input type="checkbox"
                         className="custom-control-input"
                         checked={checkAvailsSettings.noConfirms}
                         onChange={
                            (e) => {
                              var updated = Object.assign({}, checkAvailsSettings, {
                                noConfirms: e.target.checked
                              });

                              checkAvailsSettingsChanged(updated);
                            }
                         }
                         id="check-avails-no-confirms" />
                  <label className="custom-control-label"
                         style={{"fontSize": "14px"}}
                         htmlFor="check-avails-no-confirms">
                    No Confirms
                  </label>
                </div>
                <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table">
                  <input type="checkbox"
                         className="custom-control-input"
                         checked={checkAvailsSettings.noHolds}
                         onChange={
                            (e) => {
                              var updated = Object.assign({}, checkAvailsSettings, {
                                noHolds: e.target.checked
                              });

                              checkAvailsSettingsChanged(updated);
                            }
                         }
                         id="check-avails-no-holds" />
                  <label className="custom-control-label"
                         style={{"fontSize": "14px"}}
                         htmlFor="check-avails-no-holds">
                    No Holds
                  </label>
                </div>
              </div>
              <div className="form-group">
                <p className="mb-0">
                  <strong>On these days:</strong>
                </p>
                <div className="d-inline-block">
                  <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table mr-0">
                    <input type="checkbox"
                          className="custom-control-input"
                          checked={checkAvailsSettings.onDaysOfWeek.includes(0)}
                          onChange={
                            (e) => dayOnChange(e, checkAvailsSettings, checkAvailsSettingsChanged, 0)
                          }
                          id="check-avails-sunday" />
                    <label className="custom-control-label"
                          style={{"fontSize": "14px"}}
                          htmlFor="check-avails-sunday">
                      &nbsp;
                    </label>
                  </div>
                  <p className="mb-0" style={{"fontSize": "14px"}}>
                    Su
                  </p>
                </div>
                <div className="d-inline-block">
                  <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table mr-0">
                    <input type="checkbox"
                          className="custom-control-input"
                          checked={checkAvailsSettings.onDaysOfWeek.includes(1)}
                          onChange={
                            (e) => dayOnChange(e, checkAvailsSettings, checkAvailsSettingsChanged, 1)
                          }
                          id="check-avails-monday" />
                    <label className="custom-control-label"
                          style={{"fontSize": "14px"}}
                          htmlFor="check-avails-monday">
                      &nbsp;
                    </label>
                  </div>
                  <p className="mb-0" style={{"fontSize": "14px", "marginLeft": "2px"}}>
                    M
                  </p>
                </div>
                <div className="d-inline-block">
                  <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table mr-0">
                    <input type="checkbox"
                          className="custom-control-input"
                          checked={checkAvailsSettings.onDaysOfWeek.includes(2)}
                          onChange={
                            (e) => dayOnChange(e, checkAvailsSettings, checkAvailsSettingsChanged, 2)
                          }
                          id="check-avails-tuesday" />
                    <label className="custom-control-label"
                          style={{"fontSize": "14px"}}
                          htmlFor="check-avails-tuesday">
                      &nbsp;
                    </label>
                  </div>
                  <p className="mb-0" style={{"fontSize": "14px", "marginLeft": "4px"}}>
                    T
                  </p>
                </div>
                <div className="d-inline-block">
                  <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table mr-0">
                    <input type="checkbox"
                          className="custom-control-input"
                          checked={checkAvailsSettings.onDaysOfWeek.includes(3)}
                          onChange={
                            (e) => dayOnChange(e, checkAvailsSettings, checkAvailsSettingsChanged, 3)
                          }
                          id="check-avails-wednesday" />
                    <label className="custom-control-label"
                          style={{"fontSize": "14px"}}
                          htmlFor="check-avails-wednesday">
                      &nbsp;
                    </label>
                  </div>
                  <p className="mb-0" style={{"fontSize": "14px", "marginLeft": "1px"}}>
                    W
                  </p>
                </div>
                <div className="d-inline-block">
                  <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table mr-0">
                    <input type="checkbox"
                          className="custom-control-input"
                          checked={checkAvailsSettings.onDaysOfWeek.includes(4)}
                          onChange={
                            (e) => dayOnChange(e, checkAvailsSettings, checkAvailsSettingsChanged, 4)
                          }
                          id="check-avails-thursday" />
                    <label className="custom-control-label"
                          style={{"fontSize": "14px"}}
                          htmlFor="check-avails-thursday">
                      &nbsp;
                    </label>
                  </div>
                  <p className="mb-0" style={{"fontSize": "14px"}}>
                    Th
                  </p>
                </div>
                <div className="d-inline-block">
                  <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table mr-0">
                    <input type="checkbox"
                          className="custom-control-input"
                          checked={checkAvailsSettings.onDaysOfWeek.includes(5)}
                          onChange={
                            (e) => dayOnChange(e, checkAvailsSettings, checkAvailsSettingsChanged, 5)
                          }
                          id="check-avails-friday" />
                    <label className="custom-control-label"
                          style={{"fontSize": "14px"}}
                          htmlFor="check-avails-friday">
                      &nbsp;
                    </label>
                  </div>
                  <p className="mb-0" style={{"fontSize": "14px", "marginLeft": "4px"}}>
                    F
                  </p>
                </div>
                <div className="d-inline-block">
                  <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table mr-0">
                    <input type="checkbox"
                          className="custom-control-input"
                          checked={checkAvailsSettings.onDaysOfWeek.includes(6)}
                          onChange={
                            (e) => dayOnChange(e, checkAvailsSettings, checkAvailsSettingsChanged, 6)
                          }
                          id="check-avails-saturday" />
                    <label className="custom-control-label"
                          style={{"fontSize": "14px"}}
                          htmlFor="check-avails-saturday">
                      &nbsp;
                    </label>
                  </div>
                  <p className="mb-0" style={{"fontSize": "14px", "marginLeft": "1px"}}>
                    Sa
                  </p>
                </div>
              </div>
              <div className="form-group">
                <p className="mb-0">
                  <strong>Between these dates:</strong>
                </p>
                <Flatpickr
                  options={{
                    dateFormat: "m/d/y",
                    mode: "range"
                  }}
                  placeholder={"Choose a date range"}
                  className={"form-control form-control-flatpickr"}
                  value={checkAvailsDates}
                  onOpen={
                    (selectedDates, dateStr, instance) => {
                      var startDate = new Date(currentTeamMembership.calendar_starts_at);
                      instance.jumpToDate(startDate, false);
                    }
                  }
                  onMonthChange={
                    (selectedDates, dateStr, instance) => {
                      calendarMonthChanged(instance, calendarRef);
                    }
                  }
                  onYearChange={
                    (selectedDates, dateStr, instance) => {
                      calendarMonthChanged(instance, calendarRef);
                    }
                  }
                  onChange={
                    (selectedDates) => {
                      checkAvailsDatesChanged(selectedDates);
                    }
                  }
                  style={{
                    "fontSize": "14px",
                    "border": "2px solid #f5f5f5"
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="card mb-0 border-0 h-100"
             style={{
               "background": "#f4f9fc",
               "borderRadius": "4px"
             }}>
          <div className="card-body" style={{"padding": "20px 15px 15px 15px"}}>
            {checkAvailsLoading ? (
              <div className="row"
                   style={{"marginTop": "15px"}}>
                <div className="col-12">
                  <p className="mb-0 text-center"
                      style={{
                        "width": "200px",
                        "margin": "0px auto"
                      }}>
                    <img src="/uploading-loading.gif"
                             className="mr-1"
                             style={{width: "16px"}} />
                  </p>
                </div>
              </div>
            ) : checkAvailsDates.length === 2 ? (
              <div className="row">
                <div className="col-12">
                  <p className="mb-0 small">
                    {`${availableDates.length} available dates between`}
                  </p>
                  <p className="mb-0"
                     style={{"fontSize": "18px"}}>
                    <strong>
                      {`${moment(checkAvailsDates[0]).format('M/D/YY')} and ${moment(checkAvailsDates[1]).format('M/D/YY')}`}
                    </strong>
                  </p>
                </div>
              </div>
            ) : (
              <div className="row"
                   style={{"marginTop": "15px"}}>
                <div className="col-12">
                  <p className="mb-0 text-center"
                      style={{
                        "width": "200px",
                        "margin": "0px auto"
                      }}>
                    <strong>Choose a date range to see available dates.</strong>
                  </p>
                </div>
              </div>
            )}
            <div className="row" style={{"marginTop": "15px"}}>
              <div className="col-12">
                <div className="fade-out-scroll"
                     style={{
                       "height": "280px",
                       "overflowY": "scroll",
                       "overflowX": "hidden"
                     }}>
                  {availableDates.map((availableDate, index) =>
                    <div className="row" key={index}>
                      <div className="col-12">
                        <span className="mb-0 small">
                          {`H${availableDate.position} - ${moment(availableDate.date).format('dddd, MMMM D, YYYY')}`}
                        </span>
                        <br />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row" style={{"marginTop": "10px"}}>
              <div className="col-12 text-right">
                {availableDates.length > 0 ? (
                  <CopyToClipboard
                    text={availableDates.map(availableDate => `H${availableDate.position} - ${moment(availableDate.date).format('dddd, MMMM D, YYYY')}`).join("\n")}
                    onCopy={
                      () => {
                        toast.success("Copied dates to clipboard", {
                          position: toast.POSITION.TOP_CENTER,
                          draggable: false,
                          closeOnClick: false,
                          autoClose: 5000,
                          hideProgressBar: true
                        });
                      }
                    }>
                    <a href="#"
                      onClick={
                        (e) => e.preventDefault()
                      }
                      className={"text-primary small"}>
                      <i className="far fa-clipboard mr-1"></i>
                      Copy all dates to clipboard
                    </a>
                  </CopyToClipboard>
                ) : (
                  <a href="#"
                     onClick={
                      (e) => e.preventDefault()
                     }
                     className="text-primary small disabled">
                    <i className="far fa-clipboard mr-1"></i>
                    Copy all dates to clipboard
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

CheckAvailsPopup.propTypes = {
  checkAvailsIsOpenChanged: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  checkAvailsSettings: PropTypes.object,
  checkAvailsSettingsChanged: PropTypes.func.isRequired,
  availableDates: PropTypes.array,
  currentTeamMembership: PropTypes.object,
  calendarRef: PropTypes.object,
  checkAvailsDates: PropTypes.array,
  checkAvailsDatesChanged: PropTypes.func.isRequired,
  checkAvailsLoading: PropTypes.bool
};

export default CheckAvailsPopup;
