import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import {responsiveStoreEnhancer} from 'redux-responsive'
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

import calendarViewReducer from '../reducers/calendarViewReducer';
import eventFormReducer from '../../EventForm/reducers/eventFormReducer';

const rootReducer = combineReducers({
  calendarView: calendarViewReducer,
  eventForm: eventFormReducer
});

const configureStore = (railsProps) => (
  createStore(rootReducer, railsProps, compose(
    applyMiddleware(createDebounce(), thunk),
    responsiveStoreEnhancer
  ))
);

export default configureStore;
