import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import ForecastColumn from './ForecastColumn';

import Popup from "reactjs-popup";
import Tippy from '@tippyjs/react';
import Select from 'react-select';

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

import { SettlementMath } from '../../SettlementMath/SettlementMath';

var _ = require('lodash');
var debouncedAutoSaveBudgetLineItem;

const inlineMenuContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "215px",
  "padding": "0px"
}

const operatorMenuContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "130px",
  "padding": "0px"
}

const iconForOperator = (operator) => {
  if(operator === "*"){
    return <i className="fas fa-asterisk extra-small"></i>;
  } else if (operator === "+") {
    return <i className="fas fa-plus extra-small"></i>;
  } else if (operator === "-") {
    return <i className="fas fa-minus extra-small"></i>;
  } else if (operator === "/") {
    return <i className="fas fa-divide extra-small"></i>;
  } else if (operator === "of") {
    return <span>of</span>;
  }
}

const calcTypeLabel = (calcType) => {
  switch(calcType){
    case "fixed": return "Fixed";
    case "per_tickets_sold": return "Per Tickets Sold";
    case "percentage_of_gross": return "% of Gross";
    case "percentage_of_net_gross": return "% of Net Gross";
    case "percentage_of_net": return "% of Net";
    case "percentage_of_expenses": return "% of Expenses";
    default: return "";
  }
}

const updatedBudgetWithConvertedTicket = (budget, budgetLineItem) => {
  return {
    ...budget,
    ticket_line_items: [
      ...budget.ticket_line_items,
      budgetLineItem
    ],
    income_line_items: budget.income_line_items.filter((item) => {
      return item.uuid !== budgetLineItem.uuid
    })
  };
}

const updatedBudgetWithBudgetLineItem = (budget, budgetLineItem) => {
  var ticketLineItems = [...budget.ticket_line_items].map((item) => {
    if(item.uuid === budgetLineItem.uuid){
      return budgetLineItem;
    } else {
      return item;
    }
  });

  var incomeLineItems = [...budget.income_line_items].map((item) => {
    if(item.uuid === budgetLineItem.uuid){
      return budgetLineItem;
    } else {
      return item;
    }
  });

  var expenseLineItems = [...budget.expense_line_items].map((item) => {
    if(item.uuid === budgetLineItem.uuid){
      return budgetLineItem;
    } else {
      return item;
    }
  });

  var beforeAdjustedDeductionsLineItems =
    [...budget.before_adjusted_deductions_line_items].map((item) => {
      if(item.uuid === budgetLineItem.uuid){
        return budgetLineItem;
      } else {
        return item;
      }
    });

  return Object.assign({}, budget, {
    ticket_line_items: ticketLineItems,
    income_line_items: incomeLineItems,
    expense_line_items: expenseLineItems,
    before_adjusted_deductions_line_items: beforeAdjustedDeductionsLineItems,
  });
}

const showCalcType = (header) => ["Expenses", "Before Adjusted Deductions"].includes(header);

const TicketTotalRow = ({
  onAddRow,
  settlementMath,
  total,
  showCurrencyConversion,
  globalCurrency,
  formatCalculatedNumber,
  showForecast,
  forecastColgroup
}) => {
  return(
    <tr className='border-0 total-row'>
      <td className='text-left'>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               onAddRow();
             }}>
           <strong>
             <i className="far fa-plus-circle mr-2"></i>
             Add Ticket
           </strong>
        </a>
        <div className="d-inline float-right">
          <strong>
            Total
          </strong>
        </div>
      </td>
      <td className='text-right'>
        <strong>
          {settlementMath.ticketsSection.items.reduce((sum, i) => sum + Number(i.available), 0).toLocaleString()}
        </strong>
      </td>
      <td className='text-right'>
        <strong>
          {settlementMath.ticketsSection.items.reduce((sum, i) => sum + Number(i.comps), 0).toLocaleString()}
        </strong>
      </td>
      <td className='text-right'>
        <strong>
          {settlementMath.ticketsSection.items.reduce((sum, i) => sum + Number(i.kills), 0).toLocaleString()}
        </strong>
      </td>
      <td className='text-right'>
        <strong>
          {settlementMath.ticketsSection.rightTotal.toLocaleString()}
        </strong>
      </td>
      <td className='text-center'>&nbsp;</td>
      {showCurrencyConversion ? (
        <td className='text-right strong pl-0'>
          <strong>{globalCurrency.formatted(total)}</strong>
        </td>
      ) : null}
      <td className='text-right strong'>
        <strong>{formatCalculatedNumber(total, {prefix: "$"})}</strong>
      </td>
      <ForecastColumn
        show={showForecast}
        showCurrencyConversion={showCurrencyConversion}
      >
        <table
          className="border-0"
          style={{
            margin: "0 10px",
            width: showCurrencyConversion ? "435px" : "315px"
          }}
        >
          {forecastColgroup}
          <tbody>
            <tr>
              <td className="text-right position-relative p-0 borderless">
              </td>
              <td className="text-right position-relative borderless">
                <strong>
                  {settlementMath.ticketsSection.rightForecastTotal.toLocaleString()}
                </strong>
              </td>
              {showCurrencyConversion ? (
                <td className="text-right borderless">
                  <strong>
                    {globalCurrency.formatted(settlementMath.ticketsSection.forecastTotal)}
                  </strong>
                </td>
              ) : null}
              <td className="text-right strong position-relative total-td borderless">
                <strong>
                  {formatCalculatedNumber(settlementMath.ticketsSection.forecastTotal, {prefix: "$"})}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </ForecastColumn>
      <td className='text-right px-0 inline-menu-td'>&nbsp;</td>
    </tr>
  );
}

const TicketRow = ({
  index,
  csrfToken,
  team,
  currentUser,
  budget,
  budgetLineItem,
  calculatedBudget,
  calculatedRow,
  updateBudget,
  namePrefix,
  onDeleteRow,
  autoSaveBudgetLineItem,
  debouncedAutoSaveBudgetLineItem,
  showCurrencyConversion,
  globalCurrency,
  formatCalculatedNumber,
  showForecast,
  forecastColgroup,
  lastRow
}) => {
  return (
    <tr className='border-0' key={index}>
      <td className='text-left position-relative p-0'>
        <input
          type="text"
          style={{"fontSize": "14px"}}
          className="form-control form-control-sm border-0"
          onChange={(e) => {
            var updatedBudgetLineItem = {
              ...budgetLineItem,
              name: e.target.value
            };

            var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

            updateBudget(updatedBudget);
            debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
          }}
          onBlur={e => {
            if(e.target.value.length < 1){
              var updatedBudgetLineItem = {
                ...budgetLineItem,
                name: (namePrefix + (index + 1))
              };

              var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

              updateBudget(updatedBudget);
              autoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
            }
          }}
          value={budgetLineItem.name || ""}
          placeholder="Enter item label" />
        {!budgetLineItem.show_in_offer ? (
          <div className="do-not-show-in-offer-icon">
            <Tippy
              placement={'top-start'}
              maxWidth={200}
              animation={'shift-away'}
              theme='material'
              content={
                <div style={{ "fontSize": "10px" }}>
                  This row will be hidden in the offer sent to the artist but values will still be included in any calculations.
                </div>
              }>
              <i
                className="fas fa-eye-slash extra-small"
                style={{padding: "13px 8px 13px 0"}}>
              </i>
            </Tippy>
          </div>
        ) : null}
      </td>
      <td className='text-right position-relative p-0'>
        <CurrencyInput
          key={`${budgetLineItem.calc_type}-available-${budgetLineItem.uuid}`}
          className="form-control form-control-sm border-0 text-right"
          style={{ fontSize: "14px", paddingLeft: "12px", paddingRight: "12px"}}
          maskOptions={{ prefix: "", integerLimit: null, allowDecimal: false }}
          placeholder={"0"}
          value={budgetLineItem.available}
          onChange={e => {
            var available = e.target.value.length > 0
              ? Number(e.target.value.replace(/,/g, '')) : '';
            var rightHand = available !== 0
              ? Number(available) - Number(budgetLineItem.comps) - Number(budgetLineItem.kills) : 0;

            var updatedBudgetLineItem = {
              ...budgetLineItem,
              available: available,
              right_hand: rightHand < 0 ? 0 : rightHand
            };

            var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

            updateBudget(updatedBudget);
            debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
          }}
        />
      </td>
      <td className='text-right position-relative p-0'>
        <CurrencyInput
          key={`${budgetLineItem.calc_type}-comps-${budgetLineItem.uuid}`}
          className="form-control form-control-sm border-0 text-right"
          style={{ fontSize: "14px", paddingLeft: "12px", paddingRight: "12px"}}
          maskOptions={{ prefix: "", integerLimit: null, allowDecimal: false }}
          placeholder={"0"}
          value={budgetLineItem.comps}
          onChange={e => {
            var comps = e.target.value.length > 0
              ? Number(e.target.value.replace(/,/g, '')) : '';

            var rightHand = budgetLineItem.available !== 0
              ? Number(budgetLineItem.available) - Number(comps) - Number(budgetLineItem.kills) : 0;

            var updatedBudgetLineItem = {
              ...budgetLineItem,
              comps: comps,
              right_hand: rightHand < 0 ? 0 : rightHand
            };

            var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

            updateBudget(updatedBudget);
            debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
          }}
        />
      </td>
      <td className='text-right position-relative p-0'>
        <CurrencyInput
          key={`${budgetLineItem.calc_type}-kills-${budgetLineItem.uuid}`}
          className="form-control form-control-sm border-0 text-right"
          style={{ fontSize: "14px", paddingLeft: "12px", paddingRight: "12px"}}
          maskOptions={{ prefix: "", integerLimit: null, allowDecimal: false }}
          placeholder={"0"}
          value={budgetLineItem.kills}
          onChange={e => {
            var kills = e.target.value.length > 0
              ? Number(e.target.value.replace(/,/g, '')) : '';
            var rightHand = budgetLineItem.available !== 0
              ? Number(budgetLineItem.available) - Number(budgetLineItem.comps) - Number(kills) : 0;

            var updatedBudgetLineItem = {
              ...budgetLineItem,
              kills: kills,
              right_hand: rightHand < 0 ? 0 : rightHand
            };

            var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

            updateBudget(updatedBudget);
            debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
          }}
        />
      </td>
      <td className='text-right position-relative p-0'>
        <CurrencyInput
          disabled
          key={`${budgetLineItem.calc_type}-right-hand-${budgetLineItem.uuid}`}
          className="form-control form-control-sm border-0 text-right"
          style={{
            height: "35px",
            fontSize: "14px",
            paddingLeft: "12px",
            paddingRight: "12px",
            background: "#F0F0F0"
          }}
          maskOptions={{ prefix: "", integerLimit: null, allowDecimal: false }}
          value={budgetLineItem.right_hand < 0 ? 0 : Number(budgetLineItem.right_hand)}
        />
      </td>
      <td className='text-right position-relative p-0'>
        <CurrencyInput
          key={`${budgetLineItem.calc_type}-left-hand-${budgetLineItem.uuid}`}
          className="form-control form-control-sm border-0 text-right"
          style={{ fontSize: "14px" }}
          maskOptions={{ prefix: "", integerLimit: null }}
          placeholder={"0.0"}
          value={budgetLineItem.left_hand}
          onChange={e => {
            var leftHand = e.target.value.length > 0
              ? e.target.value.replace(/,/g, '') : '';

            var updatedBudgetLineItem = {
              ...budgetLineItem,
              left_hand: leftHand,
              left_hand_forecast: (
                budgetLineItem.left_hand_forecast === budgetLineItem.left_hand
                  ? leftHand
                  : budgetLineItem.left_hand_forecast
              )
            }

            var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

            updateBudget(updatedBudget);
            debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
          }}
        />
        <div className="position-absolute"
             style={{
               "left": "10px",
               "top": "7px"
             }}>
          <i className="fas fa-dollar-sign"></i>
        </div>
      </td>
      {showCurrencyConversion ? (
        <td className='text-right strong text-muted pl-0'>
          <strong>{globalCurrency.formatted(calculatedRow ? calculatedRow.calculatedTotal : 0.00)}</strong>
        </td>
      ) : null}
      <td className='text-right strong position-relative total-td'>
        {formatCalculatedNumber(calculatedRow ? calculatedRow.calculatedTotal : 0.00)}
        <div className="position-absolute"
             style={{
               "left": "10px",
               "top": "7px"
             }}>
          <i className="fas fa-dollar-sign"></i>
        </div>
      </td>
      <ForecastColumn
        show={showForecast}
        showCurrencyConversion={showCurrencyConversion}
        globalCurrency={globalCurrency}
        colgroup={forecastColgroup}
        item={budgetLineItem}
        calculatedBudget={calculatedBudget}
        calculatedRow={calculatedRow}
        csrfToken={csrfToken}
        team={team}
        budget={budget}
        lastRow={lastRow}
        updatedBudgetWithBudgetLineItem={updatedBudgetWithBudgetLineItem}
        updateBudget={updateBudget}
        debouncedAutoSaveBudgetLineItem={debouncedAutoSaveBudgetLineItem}
        formatCalculatedNumber={formatCalculatedNumber}
      />
      <td className='text-right strong px-0 inline-menu-td'>
        <Popup
            trigger={
              <a href="#"
                 className="text-muted">
                <i className="fas fa-ellipsis-v"></i>
              </a>
            }
            onOpen={
              (e) => {
                e.preventDefault();
              }
            }
            arrow={false}
            offsetY={5}
            position="bottom right"
            contentStyle={inlineMenuContentStyle}
            closeOnDocumentClick
          >
          {close => (
            <div>
              <div className="row text-left">
                <div className="col">
                  <ul className="list-group offer-inline-menu highlight-primary">
                    {budgetLineItem.show_in_offer ? (
                      <li className="list-group-item">
                        <a href="#"
                           className="text-dark"
                           onClick={
                             (e) => {
                               e.preventDefault();

                               var updatedBudgetLineItem = Object.assign({}, budgetLineItem, {show_in_offer: false});
                               var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                               updateBudget(updatedBudget);
                               debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);

                               close();
                             }
                           }>
                          <i className="text-muted fas fa-eye-slash fa-fw mx-2"></i>
                          <span style={{"fontWeight": 'normal'}}>
                            Don't show in offer
                          </span>
                        </a>
                      </li>
                    ) : (
                      <li className="list-group-item">
                        <a href="#"
                           className="text-dark"
                           onClick={
                             (e) => {
                               e.preventDefault();

                               var updatedBudgetLineItem = Object.assign({}, budgetLineItem, {show_in_offer: true});
                               var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                               updateBudget(updatedBudget);
                               debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);

                               close();
                             }
                           }>
                          <i className="text-muted fas fa-eye fa-fw mx-2"></i>
                          <span style={{"fontWeight": 'normal'}}>
                            Show in offer
                          </span>
                        </a>
                      </li>
                    )}
                    <li className="list-group-item delete-offer-li border-0">
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             onDeleteRow(budgetLineItem);
                             close();
                           }
                         }
                         className="text-danger">
                        <i className="far fa-times-circle fa-fw mx-2"></i>
                        <span style={{"fontWeight": 'normal'}}>
                          Remove row
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </Popup>
      </td>
    </tr>
  );
}

const BudgetLineItems = ({
  csrfToken,
  team,
  currentUser,
  budget,
  calculatedBudget,
  budgetLineItems,
  header,
  onAddRow,
  onDeleteRow,
  autoSaveBudgetLineItem,
  total,
  getCalculatedRow,
  updateBudget,
  namePrefix,
  formatCalculatedNumber,
  showCurrencyConversion,
  globalCurrency,
  showForecast,
  forecastColgroup
}) => {
  useEffect(() => {
    debouncedAutoSaveBudgetLineItem = _.debounce((csrfToken, team, budgetLineItem) => {
      autoSaveBudgetLineItem(csrfToken, team, budgetLineItem);
    }, 600);
  }, [])

  const forecastHeaders = (header) => (
    <table
      className="border-0"
      style={{
        margin: "0 10px",
        width: showCurrencyConversion ? "435px" : "315px"
      }}
    >
      {forecastColgroup}
      <tbody>
        <tr>
          <th className="text-right">
            {header === "Tickets" ? "SALE PRICE" : "AMOUNT"}
          </th>
          <th className="text-right">QTY</th>
          {showCurrencyConversion ? (
            <th className="text-right">
              {team.secondary_currency_symbol.toUpperCase()}
            </th>
          ) : null}
          <th className="text-right">TOTAL</th>
        </tr>
      </tbody>
    </table>
  );

  const settlementMath = new SettlementMath(budget, false);
  settlementMath.calculate();

  return (
    <>
      <tbody className="border-0">
        {["Tickets", "Other Income"].includes(header) ? (
          <tr className="border-0">
            <td className="border-0" colSpan={showCurrencyConversion ? 8 : 7}>
              <p className='mb-0' style={{"fontSize": "16px"}}>
                <strong>{header}</strong>
              </p>
            </td>
            <ForecastColumn
              show={showForecast}
              showCurrencyConversion={showCurrencyConversion}
            />
            <td className="px-0">&nbsp;</td>
          </tr>
        ) : null}
      </tbody>
      <tbody className="bordered">
        <tr className="border-0">
          {header === "Tickets" ? (
            <>
              <th className='text-left'>ITEM</th>
              <th className='text-right'>AVAILABLE</th>
              <th className='text-right'>COMPS</th>
              <th className='text-right'>KILLS</th>
              <th className='text-right'>SELLABLE</th>
              <th className='text-right'>SALE PRICE</th>
              {showCurrencyConversion ? (
                <th className='text-right'>{team.secondary_currency_symbol.toUpperCase()}</th>
              ) : null}
              <th className='text-right'>TOTAL</th>
              <ForecastColumn
                show={showForecast}
                header={true}
                showCurrencyConversion={showCurrencyConversion}
              >
                {forecastHeaders(header)}
              </ForecastColumn>
              <th className='px-0 text-center'>&nbsp;</th>
            </>
          ) : (
            <>
              {showCalcType(header) ? (
                <>
                  <th className='text-left' colSpan="2">ITEM</th>
                  <th className='text-left' colSpan="2">TYPE</th>
                </>
              ) : (
                <th className='text-left' colSpan="4">ITEM</th>
              )}
              <th className='text-right'>AMOUNT</th>
              <th className='text-right'>QTY</th>
              {showCurrencyConversion ? (
                <th className='text-right'>{team.secondary_currency_symbol.toUpperCase()}</th>
              ) : null}
              <th className='text-right'>TOTAL</th>
              <ForecastColumn
                show={showForecast}
                header={true}
                showCurrencyConversion={showCurrencyConversion}
              >
                {forecastHeaders(header)}
              </ForecastColumn>
              <th className='px-0 text-center'>&nbsp;</th>
            </>
          )}
        </tr>
        {budgetLineItems.map((budgetLineItem, index) => {
          var calculatedRow = getCalculatedRow(budgetLineItem);
          var lastRow = index === budgetLineItems.length - 1;

          return(header === "Tickets" ? (
            <TicketRow
              key={budgetLineItem.uuid}
              index={index}
              csrfToken={csrfToken}
              team={team}
              currentUser={currentUser}
              budget={budget}
              budgetLineItem={budgetLineItem}
              calculatedBudget={calculatedBudget}
              calculatedRow={calculatedRow}
              updateBudget={updateBudget}
              namePrefix={namePrefix}
              onDeleteRow={onDeleteRow}
              autoSaveBudgetLineItem={autoSaveBudgetLineItem}
              debouncedAutoSaveBudgetLineItem={debouncedAutoSaveBudgetLineItem}
              showCurrencyConversion={showCurrencyConversion}
              globalCurrency={globalCurrency}
              formatCalculatedNumber={formatCalculatedNumber}
              showForecast={showForecast}
              forecastColgroup={forecastColgroup}
              lastRow={lastRow}
            />
          ) : (
            <tr className='border-0' key={index}>
              <td className='text-left position-relative p-0' colSpan={header === "Tickets" ? 1 : showCalcType(header) ? 2 : 4}>
                <input type="text"
                       style={{"fontSize": "14px"}}
                       className="form-control form-control-sm border-0"
                       onChange={(e) => {
                         var updatedName = e.target.value;
                         var updatedBudgetLineItem = Object.assign({}, budgetLineItem, {name: updatedName});
                         var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                         updateBudget(updatedBudget);
                         debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
                       }}
                       onBlur={(e) => {
                         if(e.target.value.length < 1){
                           var updatedName = (namePrefix + (index + 1));

                           var updatedBudgetLineItem = Object.assign({}, budgetLineItem, {name: updatedName});
                           var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                           updateBudget(updatedBudget);
                           autoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
                         }
                       }}
                       value={budgetLineItem.name || ""}
                       placeholder="Enter item label" />
                {!budgetLineItem.show_in_offer ? (
                  <div className="do-not-show-in-offer-icon">
                    <Tippy content={
                             <div style={{
                                    "fontSize": "10px"
                                  }}>
                               This row will be hidden in the offer sent to the artist but values will still be included in any calculations.
                             </div>
                           }
                           placement={'top-start'}
                           maxWidth={200}
                           animation={'shift-away'}
                           theme='material'>
                        <i
                          className="fas fa-eye-slash extra-small"
                          style={{padding: "13px 8px 13px 0"}}>
                        </i>
                    </Tippy>
                  </div>
                ) : null}
              </td>
              {showCalcType(header) ? (
                <td colSpan="2" className='text-left position-relative p-0'>
                  <Select
                    className="p-0 form-control form-control-sm border-0"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        border: 0,
                        boxShadow: 'none',
                        fontSize: '14px',
                        height: '14px',
                        minHeight: '0',
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        width: '200px',
                        fontSize: '14px'
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '14px'
                      })
                    }}
                    options={header === "Expenses" ? ([
                      { value: 'fixed', label: 'Fixed' },
                      { value: 'per_tickets_sold', label: 'Per Tickets Sold' },
                      { value: 'percentage_of_gross', label: '% of Gross' },
                      { value: 'percentage_of_net_gross', label: '% of Net Gross' },
                      { value: 'percentage_of_net', label: '% of Net' },
                      { value: 'percentage_of_expenses', label: '% of Expenses' }
                    ]) : ([
                      { value: 'fixed', label: 'Fixed' },
                      { value: 'per_tickets_sold', label: 'Per Tickets Sold' },
                      { value: 'percentage_of_gross', label: '% of Gross' },
                    ])}
                    value={{ value: budgetLineItem.calc_type, label: calcTypeLabel(budgetLineItem.calc_type) }}
                    onChange={(selectedOption) => {
                      var updatedBudgetLineItem = {
                        ...budgetLineItem,
                        calc_type: selectedOption.value,
                        operator: ["fixed", "per_tickets_sold"].includes(selectedOption.value) ? "*" : "of"
                      };

                      var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                      updateBudget(updatedBudget);
                      debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
                    }}
                  />
                </td>
              ) : null}
              <td className='text-right position-relative p-0'>
                <CurrencyInput
                  key={`${budgetLineItem.calc_type}-left-hand-${budgetLineItem.uuid}`}
                  className="form-control form-control-sm border-0 text-right"
                  style={["fixed", "per_tickets_sold"].includes(budgetLineItem.calc_type) ? (
                    { fontSize: "14px" }
                  ) : (
                    { fontSize: "14px", paddingRight: "26px" }
                  )}
                  maskOptions={["fixed", "per_tickets_sold"].includes(budgetLineItem.calc_type) ? (
                    { prefix: "", integerLimit: null }
                  ) : (
                    { prefix: "", integerLimit: null, allowDecimal: true, decimalLimit: 5 }
                  )}
                  placeholder="0.0"
                  value={budgetLineItem.left_hand}
                  onChange={e => {
                    var leftHand = e.target.value.length > 0
                      ? e.target.value.replace(/,/g, '') : '';

                    var updatedBudgetLineItem = Object.assign({}, budgetLineItem, {left_hand: leftHand});
                    var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                    updateBudget(updatedBudget);
                    debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
                  }}
                />
                {["fixed", "per_tickets_sold"].includes(budgetLineItem.calc_type) ? (
                  <div className="position-absolute"
                       style={{
                         "left": "10px",
                         "top": "7px"
                       }}>
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                ) : (
                  <div className="position-absolute"
                       style={{
                         "right": "10px",
                         "top": "7px"
                       }}>
                    <i className="fas fa-percent"></i>
                  </div>
                )}
              </td>
              <td className='text-right p-0'>
                {budgetLineItem.calc_type === "fixed" ? (
                  <CurrencyInput
                    className="form-control form-control-sm border-0 text-right"
                    style={{"fontSize": "14px", "background": "white", "paddingLeft": "12px", "paddingRight": "12px"}}
                    maskOptions={{ prefix: "", integerLimit: null, allowDecimal: false }}
                    placeholder="0"
                    value={budgetLineItem.right_hand === ''
                      ? budgetLineItem.right_hand
                      : Number(budgetLineItem.right_hand)
                    }
                    onChange={e => {
                      var rightHand = e.target.value.length > 0
                        ? e.target.value.replace(/,/g, '') : '';

                      var updatedBudgetLineItem = Object.assign({}, budgetLineItem, {right_hand: rightHand});
                      var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                      updateBudget(updatedBudget);
                      debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
                    }}
                  />
                ) : (
                  <CurrencyInput
                    disabled
                    className="form-control form-control-sm border-0 text-right"
                    style={{"height": "35px", "fontSize": "14px", "background": "#F0F0F0"}}
                    maskOptions={{ prefix: "", allowDecimal: true, decimalLimit: 2 }}
                    placeholder="0.0"
                    value={Object.keys(calculatedBudget).length === 0 ? (
                      0
                    ) : calculatedBudget && budgetLineItem.calc_type === "per_tickets_sold" ? (
                      calculatedBudget.numberOfPaidTickets()
                    ) : calculatedBudget && budgetLineItem.calc_type === "percentage_of_gross" ? (
                      (calculatedBudget.ticketsSection.total + calculatedBudget.revenueSection.total).toFixed(2)
                    ) : budgetLineItem.calc_type === "percentage_of_net_gross" ? (
                      calculatedBudget.showSummary.netBoxOfficeReceipts.toFixed(2)
                    ) : budgetLineItem.calc_type === "percentage_of_net" ? (
                      calculatedBudget.showSummary.showNetAfterExpenses.toFixed(2)
                    ) : budgetLineItem.calc_type === "percentage_of_expenses" ? (
                      calculatedBudget.expensesSection.total.toFixed(2)
                    ) : 0}
                  />
                )}
              </td>
              {showCurrencyConversion ? (
                <td className='text-right text-muted pl-0'>
                  {globalCurrency.formatted(calculatedRow ? calculatedRow.calculatedTotal : 0.00)}
                </td>
              ) : null}
              <td className='text-right strong position-relative total-td'>
                {formatCalculatedNumber(calculatedRow ? calculatedRow.calculatedTotal : 0.00)}
                <div className="position-absolute"
                     style={{
                       "left": "10px",
                       "top": "7px"
                     }}>
                  <i className="fas fa-dollar-sign"></i>
                </div>
              </td>
              <ForecastColumn
                show={showForecast}
                showCurrencyConversion={showCurrencyConversion}
                globalCurrency={globalCurrency}
                colgroup={forecastColgroup}
                item={budgetLineItem}
                calculatedBudget={calculatedBudget}
                calculatedRow={calculatedRow}
                csrfToken={csrfToken}
                team={team}
                budget={budget}
                lastRow={lastRow}
                updatedBudgetWithBudgetLineItem={updatedBudgetWithBudgetLineItem}
                updateBudget={updateBudget}
                debouncedAutoSaveBudgetLineItem={debouncedAutoSaveBudgetLineItem}
                formatCalculatedNumber={formatCalculatedNumber}
              />
              <td className='text-right strong px-0 inline-menu-td'>
                <Popup
                    trigger={
                      <a href="#"
                         className="text-muted">
                        <i className="fas fa-ellipsis-v"></i>
                      </a>
                    }
                    onOpen={
                      (e) => {
                        e.preventDefault();
                      }
                    }
                    arrow={false}
                    offsetY={5}
                    position="bottom right"
                    contentStyle={inlineMenuContentStyle}
                    closeOnDocumentClick
                  >
                  {close => (
                    <div>
                      <div className="row text-left">
                        <div className="col">
                          <ul className="list-group offer-inline-menu highlight-primary">
                            {header === "Other Income" ? (
                              <li className="list-group-item">
                                <a href="#"
                                  className="text-dark"
                                  onClick={ e => {
                                    e.preventDefault();

                                    const updatedBudgetLineItem = {
                                      ...budgetLineItem,
                                      category: "TICKET",
                                      available: Number(budgetLineItem.right_hand),
                                      comps: 0,
                                      kills: 0,
                                    }

                                    var updatedBudget = updatedBudgetWithConvertedTicket(budget, updatedBudgetLineItem);

                                    updateBudget(updatedBudget);
                                    autoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);

                                    close();
                                  }}>
                                  <i className="text-muted fas fa-ticket fa-fw mx-2"></i>
                                  <span style={{"fontWeight": 'normal'}}>
                                    Convert to ticket
                                  </span>
                                </a>
                              </li>
                            ) : null}
                            {budgetLineItem.show_in_offer ? (
                              <li className="list-group-item">
                                <a href="#"
                                   className="text-dark"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();

                                       var updatedBudgetLineItem = Object.assign({}, budgetLineItem, {show_in_offer: false});
                                       var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                                       updateBudget(updatedBudget);
                                       debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);

                                       close();
                                     }
                                   }>
                                  <i className="text-muted fas fa-eye-slash fa-fw mx-2"></i>
                                  <span style={{"fontWeight": 'normal'}}>
                                    Don't show in offer
                                  </span>
                                </a>
                              </li>
                            ) : (
                              <li className="list-group-item">
                                <a href="#"
                                   className="text-dark"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();

                                       var updatedBudgetLineItem = Object.assign({}, budgetLineItem, {show_in_offer: true});
                                       var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                                       updateBudget(updatedBudget);
                                       debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);

                                       close();
                                     }
                                   }>
                                  <i className="text-muted fas fa-eye fa-fw mx-2"></i>
                                  <span style={{"fontWeight": 'normal'}}>
                                    Show in offer
                                  </span>
                                </a>
                              </li>
                            )}
                            <li className="list-group-item delete-offer-li border-0">
                              <a href="#"
                                 onClick={
                                   (e) => {
                                     e.preventDefault();
                                     onDeleteRow(budgetLineItem);
                                     close();
                                   }
                                 }
                                 className="text-danger">
                                <i className="far fa-times-circle fa-fw mx-2"></i>
                                <span style={{"fontWeight": 'normal'}}>
                                  Remove row
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              </td>
            </tr>
          ));
        })}
        {header === "Tickets" ? (
          <TicketTotalRow
            onAddRow={onAddRow}
            settlementMath={settlementMath}
            total={total}
            showCurrencyConversion={showCurrencyConversion}
            globalCurrency={globalCurrency}
            formatCalculatedNumber={formatCalculatedNumber}
            showForecast={showForecast}
            forecastColgroup={forecastColgroup}
          />
        ) : (
          <tr className='border-0 total-row'>
            <td className='text-left' colSpan={showCalcType(header) ? 3 : 4}>
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     onAddRow();
                   }}>
                 <strong>
                   <i className="far fa-plus-circle mr-2"></i>
                   {header === "Other Income" ? "Add Income" : `Add ${header.slice(0, -1)}`}
                 </strong>
              </a>
            </td>
            {showCalcType(header) ? (
              <td className='text-right'>&nbsp;</td>
            ) : null}
            <td className='text-center'>&nbsp;</td>
            <td className='text-right'>
              <strong>
                Total
              </strong>
            </td>
            {showCurrencyConversion ? (
              <td className='text-right strong pl-0'>
                <strong>{globalCurrency.formatted(total)}</strong>
              </td>
            ) : null}
            <td className='text-right strong'>
              <strong>{formatCalculatedNumber(total, {prefix: "$"})}</strong>
            </td>
            <ForecastColumn
              show={showForecast}
              showCurrencyConversion={showCurrencyConversion}
            >
              <table
                className="border-0"
                style={{
                  margin: "0 10px",
                  width: showCurrencyConversion ? "435px" : "315px"
                }}
              >
                {forecastColgroup}
                <tbody>
                  <tr>
                    <td className="text-right position-relative p-0 borderless">
                    </td>
                    <td className="text-right position-relative borderless">
                    </td>
                    {showCurrencyConversion ? (
                      <td className="text-right borderless">
                        <strong>
                          {
                            {
                              "Other Income": globalCurrency.formatted(settlementMath.revenueSection.forecastTotal),
                              "Expenses": globalCurrency.formatted(settlementMath.expensesSection.forecastTotal),
                              "Before Adjusted Deductions": globalCurrency.formatted(settlementMath.beforeAdjustedDeductionsSection.forecastTotal),
                            }[header]
                          }
                        </strong>
                      </td>
                    ) : null}
                    <td className="text-right strong position-relative total-td borderless">
                      <strong>
                        {
                          {
                            "Other Income": formatCalculatedNumber(settlementMath.revenueSection.forecastTotal, {prefix: "$"}),
                            "Expenses": formatCalculatedNumber(settlementMath.expensesSection.forecastTotal, {prefix: "$"}),
                            "Before Adjusted Deductions": formatCalculatedNumber(settlementMath.beforeAdjustedDeductionsSection.forecastTotal, {prefix: "$"}),
                          }[header]
                        }
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ForecastColumn>
            <td className='text-right px-0 inline-menu-td'>&nbsp;</td>
          </tr>
        )}
      </tbody>
    </>
  );
};

BudgetLineItems.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  budget: PropTypes.object.isRequired,
  calculatedBudget: PropTypes.object.isRequired,
  budgetLineItems: PropTypes.array,
  header: PropTypes.string,
  onAddRow: PropTypes.func.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  autoSaveBudgetLineItem: PropTypes.func.isRequired,
  total: PropTypes.number,
  getCalculatedRow: PropTypes.func.isRequired,
  updateBudget: PropTypes.func.isRequired,
  namePrefix: PropTypes.string,
  formatCalculatedNumber: PropTypes.func.isRequired,
  showCurrencyConversion: PropTypes.bool,
  globalCurrency: PropTypes.object,
  showForecast: PropTypes.bool.isRequired,
  forecastColgroup: PropTypes.object.isRequired
};

export default BudgetLineItems;
