import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import TotalsTable from './TotalsTable';
import CustomFieldValueFields from './../../CustomFieldValues/components/CustomFieldValueFields';
import {canSubmitCustomFieldValue} from './../../CustomFieldValues/actions/customFieldValuesActionCreators';

var _ = require('lodash');

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const CheckoutForm = ({
  team,
  csrfToken,
  confirm,
  ticketReservation,
  ticketReservationChanged,
  updateTicketReservation,
  rootUrl,
  ticketTypes,
  isPlacingOrder,
  isPlacingOrderChanged,
  addOns,
  deleteTicketReservation,
  customFieldValues,
  customFieldValueChanged
}) => {
  const canCheckout = (ticketReservation, isPlacingOrder) => {
    return (
      Object.keys(ticketReservation).length > 0
        && ticketReservation.token
        && ticketReservation.token.length > 0
        && ticketReservation.first_name
        && ticketReservation.first_name.length > 0
        && ticketReservation.last_name
        && ticketReservation.last_name.length > 0
        && ticketReservation.email
        && ticketReservation.email.length > 0
        && validateEmail(ticketReservation.email)
        && !isPlacingOrder
        && customFieldValues.every((cfv) => canSubmitCustomFieldValue(cfv))
    );
  }

  const handleSubmit = async (event) => {
    isPlacingOrderChanged(true);

    var completeUrl = (
      rootUrl + "/teams/" + team.id + "/manual_ticket_reservations/" + ticketReservation.token + "/complete"
    );

    updateTicketReservation(csrfToken, team, ticketReservation, (ticketReservation) => {
      window.location.replace(completeUrl);
    }, customFieldValues);
  };

  return (
    <form onSubmit={
            (e) => {
              e.preventDefault();

              if(!canCheckout(ticketReservation, isPlacingOrder)){
                return false;
              }

              handleSubmit(e);
            }
          }>
      <div className="row">
        <div className="col-12">
          <p style={{
               "fontSize": "16px",
               "marginBottom": "12px"
             }}>
            <strong>Contact Info</strong>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-row mb-3">
            <div className="col">
              <input type="text"
                     placeholder="First name"
                     style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                     value={ticketReservation.first_name || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, ticketReservation, {first_name: e.target.value});
                         ticketReservationChanged(updated);
                       }
                     }
                     className="form-control" />
            </div>
            <div className="col">
              <input type="text"
                     placeholder="Last name"
                     style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                     value={ticketReservation.last_name || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, ticketReservation, {last_name: e.target.value});
                         ticketReservationChanged(updated);
                       }
                     }
                     className="form-control" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              <input type="email"
                     placeholder="Email address"
                     style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                     value={ticketReservation.email || ""}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, ticketReservation, {email: e.target.value});

                         ticketReservationChanged(updated);
                       }
                     }
                     className={"form-control " + (ticketReservation.confirmEmailDoesNotMatch ? "highlight" : "")} />
            </div>
          </div>
          <div className="form-row mt-3">
            <CustomFieldValueFields team={team}
                                csrfToken={csrfToken}
                                customFieldValues={customFieldValues.filter((cfv) => cfv.type !== "CustomFieldValues::Address")}
                                customFieldValueChanged={customFieldValueChanged}
                                confirm={confirm} />
          </div>
        </div>
      </div>
      <CustomFieldValueFields team={team}
                              csrfToken={csrfToken}
                              customFieldValues={customFieldValues.filter((cfv) => cfv.type === "CustomFieldValues::Address")}
                              customFieldValueChanged={customFieldValueChanged}
                              confirm={confirm} />
      <div className="row"
           style={{"marginTop": "20px"}}>
        <div className="col-12">
          <div className="custom-control custom-checkbox custom-checkbox-table">
            <input type="checkbox"
                   className="custom-control-input"
                   checked={ticketReservation.should_send_ticket_email || false}
                   onChange={
                     (e) => {
                       var updated = Object.assign({}, ticketReservation, {should_send_ticket_email: e.target.checked});
                       ticketReservationChanged(updated);
                     }
                   }
                   id="order-subscribe-to-venue" />
            <label className="custom-control-label"
                   style={{"fontSize": "14px"}}
                   htmlFor="order-subscribe-to-venue">
              Send a confirmation email
            </label>
          </div>
        </div>
      </div>
      <div className="row" style={{"marginTop": "30px"}}>
        <div className="col d-lg-none"
             style={{"marginBottom": "15px"}}>
          <TotalsTable ticketTypes={ticketTypes}
                       ticketReservation={ticketReservation}
                       addOns={addOns} />
        </div>
      </div>
      <div className="row"
           style={{"paddingTop": "5px"}}>
        <div className="col text-right">
          {!isPlacingOrder ? (
            <a href="#"
               className="mr-2"
               onClick={
                 (e) => {
                   e.preventDefault();
                   deleteTicketReservation(csrfToken, team, ticketReservation);
                 }
               }>
              Cancel
            </a>
          ) : null}
          <button type="submit"
                  disabled={!canCheckout(ticketReservation, isPlacingOrder)}
                  className="btn btn-danger">
            {isPlacingOrder ? (
              <React.Fragment>
                <img src="/uploading-loading.gif"
                     className="mr-1"
                     style={{width: "16px"}} />
                <strong>Placing Order...</strong>
              </React.Fragment>
            ) : (
              <strong>Place Order</strong>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

CheckoutForm.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketReservation: PropTypes.object,
  ticketReservationChanged: PropTypes.func.isRequired,
  updateTicketReservation: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  isPlacingOrder: PropTypes.bool,
  isPlacingOrderChanged: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  deleteTicketReservation: PropTypes.func.isRequired,
  customFieldValues: PropTypes.array,
  customFieldValueChanged: PropTypes.func.isRequired
};

export default CheckoutForm;
