import { combineReducers } from 'redux';

import {
  PREVIEW,
  UPDATE_UI_STATE,
  OFFER_TO_CHANGED,
  OFFER_BODY_CHANGED
} from '../constants/offerV2PreviewConstants';

export const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const offer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_TO_CHANGED:
      return Object.assign({}, state, {last_sent_to: action.to});
    case OFFER_BODY_CHANGED:
      return Object.assign({}, state, {email_body: action.body});
    default:
      return state;
  }
};

export const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const uiState = (state = PREVIEW, action) => {
  switch (action.type) {
    case UPDATE_UI_STATE:
      return action.uiState;
    default:
      return state;
  }
};

export const onSuccess = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerV2PreviewReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  offer,
  onClose,
  uiState,
  onSuccess
});

export default offerV2PreviewReducer;
