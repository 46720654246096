import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const axios = require('axios').default;
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";

const canSubmitFollowUp = (followUp) => {
  return (
    followUp.artist
      && followUp.remind_at
  );
};

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(newOptions);
      })
  });
}, 250);

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const FollowUpModal = ({
  followUp,
  team,
  dataTableEl,
  csrfToken,
  followUpRemindAtChanged,
  followUpNoteChanged,
  followUpArtistChanged,
  followUpModalClosed,
  submitFollowUpForm,
  canChangeArtist,
  onClose,
  onCreate
}) => {
  return (
    <div>
      <div className="row">
        <div className='col-12'>
          <div className="row">
            <div className="col">
              <h5 style={{"marginBottom": "16px"}}>
                {canChangeArtist ? (
                  followUp.id ? (
                    <strong>Edit Follow-up Date</strong>
                  ) : (
                    <strong>New follow-up</strong>
                  )
                ) : (
                  <strong>Follow-up with this artist later</strong>
                )}
              </h5>
            </div>
            <div className="col-xs-auto">
              <a href="#"
                 className="text-dark"
                 style={{"paddingRight": "15px"}}
                 onClick={
                   (e) => {
                     e.preventDefault();
                     followUpModalClosed(onClose);
                   }
                 } >
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {canChangeArtist ? (
                <p>Select an artist and let us know when you'd like to follow-up with them.</p>
              ) : (
                <p>Let us know you want to follow-up and we'll be sure to remind you.</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(canSubmitFollowUp(followUp)){
                    submitFollowUpForm(team, followUp, csrfToken, dataTableEl, onClose, onCreate);
                  }
                }
              }>
                {canChangeArtist ? (
                  followUp.id ? (
                    <div className="form-row">
                      <div className="col-12">
                        <div className="card no-border mb-2"
                             style={{"background": "#f4f9fc"}}>
                          <div className="card-body" style={{"padding": "5px 0"}}>
                            <div className="row">
                              <div className="col-xs-auto" style={{"paddingLeft": "30px"}}>
                                <img src={followUp.artist.image_thumb_url_for_json}
                                     style={{"height": "35px", "width": "35px"}}
                                     className="img-fluid rounded-circle" />
                              </div>
                              <div className="col d-flex align-items-center">
                                <strong>
                                  {followUp.artist.name}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <label htmlFor="new-follow-up-artist" className="text-muted">
                        Artist
                      </label>
                      <AsyncSelect cacheOptions
                                   styles={selectStyles}
                                   menuPortalTarget={document.querySelector('body')}
                                   defaultOptions
                                   id="new-follow-up-artist"
                                   onChange={(option) => { followUpArtistChanged(option); }}
                                   value={ followUp.artist }
                                   loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback)} />
                    </div>
                  )
                ) : null}
                <div className="form-group">
                  <label htmlFor="new-follow-up-remind-at" className="text-muted">
                    Date
                  </label>
                  <div className="d-block">
                    <DatePicker
                      selected={followUp.remind_at}
                      onChange={date => {
                        followUpRemindAtChanged(date);
                      }}
                      className="form-control rounded-div"
                      showPopperArrow={false}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="new-follow-up-note" className="text-muted">
                    Note
                  </label>
                  <textarea className="form-control form-control-sm"
                            style={
                              {
                                "borderRadius": "8px"
                              }
                            }
                            value={followUp.note || ""}
                            onChange={
                              (e) => {
                                followUpNoteChanged(e.target.value);
                              }
                            }
                            id="new-follow-up-note"
                            rows="3">
                  </textarea>
                </div>
                <div className="form-row">
                  <div className="col-12 text-right">
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           followUpModalClosed(onClose);
                         }
                       } >
                      <strong>Cancel</strong>
                    </a>
                    <button type="submit"
                            disabled={!canSubmitFollowUp(followUp)}
                            style={{"borderRadius": "8px"}}
                            className="btn btn-primary ml-3 btn-sm">
                      Schedule follow-up
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

FollowUpModal.propTypes = {
  followUp: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  dataTableEl: PropTypes.object,
  csrfToken: PropTypes.string.isRequired,
  followUpRemindAtChanged: PropTypes.func.isRequired,
  followUpNoteChanged: PropTypes.func.isRequired,
  followUpArtistChanged: PropTypes.func,
  followUpModalClosed: PropTypes.func.isRequired,
  submitFollowUpForm: PropTypes.func.isRequired,
  canChangeArtist: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
};

export default FollowUpModal;
