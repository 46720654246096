import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px",
  "zIndex": "1999"
}

const canSubmitForm = (copiedFolderedItem) => {
  return (
    copiedFolderedItem
      && copiedFolderedItem.item
      && copiedFolderedItem.item.human_file_name
      && copiedFolderedItem.item.human_file_name.length > 0
  );
}

const formattedChildrenCount = (folder) => {
  var count = folder.foldered_items_count;

  if(count === 1){
    return  "(" + count + " item)"
  } else {
    return  "(" + count + " items)"
  }
}

const folderedItemTitle = (folderedItem) => {
  var assetName = folderedItem.item.human_file_name;

  if(folderedItem.item.is_folder){
    return (assetName + " " + formattedChildrenCount(folderedItem.item));
  } else {
    return assetName;
  }
}

const folderedItemIsDisabled = (parentFolderedItems, selectedFolderedItems) => {
  var pathIds = parentFolderedItems.map((fi) => fi.id);
  var selectedIds = selectedFolderedItems.map((fi) => fi.id);
  const filtered = pathIds.filter(id => selectedIds.includes(id));

  return filtered.length > 0;
}

const folderedItemIsChecked = (foldered, parentFolderedItems, selectedFolderedItems) => {
  var pathIds = [...parentFolderedItems.map((fi) => fi.id), foldered.id];
  var selectedIds = selectedFolderedItems.map((fi) => fi.id);
  const filtered = pathIds.filter(id => selectedIds.includes(id));

  return filtered.length > 0;
}

let dropZoneTarget = null;
let dropCount = 0;

const onFileDragEnter = e => {
  e.preventDefault();

  // HACK: Drag enter will fire before drag leave, we need to detect if we're
  // dragging over a new element and if so, reset the drop count and remove the
  // drag-over class from the previous element.
  if(dropZoneTarget !== null && dropZoneTarget !== e.currentTarget) {
    dropCount = 0;
    dropZoneTarget.classList.remove("drag-over");
  }

  dropCount++;
  dropZoneTarget = e.currentTarget;
  e.currentTarget.classList.add("drag-over");

  if(!e.currentTarget.classList.contains("drag-over")) {
    e.currentTarget.classList.add("drag-over");
  }
}

const onFileDragLeave = e => {
  e.preventDefault();

  // HACK: drag leave happens after drag enter, so we need to make sure not
  // to decrease the drop count if we've already entered a new element.
  if(e.currentTarget === dropZoneTarget) {
    dropCount--;
  }

  if(dropCount === 0 && dropZoneTarget === e.currentTarget) {
    dropZoneTarget = null;
    e.currentTarget.classList.remove("drag-over");
  }
}

const onFileDrop = (e, csrfToken, team, confirm, uploadFilesToFolder, foldered) => {
  e.preventDefault();

  dropZoneTarget.classList.remove("drag-over");
  dropZoneTarget = null;
  dropCount = 0;

  if (e.dataTransfer.items) {
    let files = [];
    let directoryPresent = false;

    [...e.dataTransfer.items].filter(i => (i.kind === "file")).forEach(item => {
      if (item.webkitGetAsEntry().isFile) {
        const file = item.getAsFile();
        files.push(file);
      } else {
        directoryPresent = true;
      }
    });

    uploadFilesToFolder(csrfToken, team, confirm, files, foldered.item, true, directoryPresent);
  }
}

const FolderedItemRow = ({
  csrfToken,
  team,
  fetchCalendarEventAttachment,
  currentUser,
  foldered,
  depth,
  toggleFolder,
  selectedFolderedItems,
  togglefolderedItemSelected,
  stageFolderedItemsForDestroy,
  parentFolderedItems,
  copiedFolderedItem,
  copiedFolderedItemChanged,
  copiedFolderedItemNameChanged,
  updateFolderedItemName,
  stageFolderedItemsForMove,
  rootFolder,
  fetchPermissionSet,
  folderedItemUpdated,
  depthWidth,
  fetchGuestsAndPromotersForPermissions,
  calendarEventAttachmentToCopyChanged,
  uploadFilesToFolder,
  showConfirmIdColumn
}) => {
  return (
    <div
      className="row m-0 control-on-hover-row"
      onDragOver={foldered.item_type === 'Folder' ? (e => e.preventDefault()) : null}
      onDragEnter={foldered.item_type === 'Folder' ? (onFileDragEnter) : null}
      onDragLeave={foldered.item_type === 'Folder' ? (onFileDragLeave) : null}
      onDrop={foldered.item_type === 'Folder' ? (
        e => onFileDrop(e, csrfToken, team, confirm, uploadFilesToFolder, foldered)
      ) : null}
    >
      <div className="col-12 p-0 d-flex align-items-center small">
        <div className='d-flex align-items-center'
             style={{
                "width": (showConfirmIdColumn ? "40%" : "45%"),
                "padding": "8px 12px 8px 0px",
                "paddingLeft": (depthWidth(depth) + "px")
              }}>
          <div className="table-gutter checkbox-table-gutter">
            {currentUser.can_manage_calendar_event_attachments && foldered.item.user_can_change ? (
              <div className="custom-control custom-checkbox custom-checkbox-table">
                <input type="checkbox"
                      disabled={
                        folderedItemIsDisabled(parentFolderedItems, selectedFolderedItems)
                      }
                      checked={
                        folderedItemIsChecked(foldered, parentFolderedItems, selectedFolderedItems)
                      }
                      onChange={
                        (e) => {
                          togglefolderedItemSelected(foldered);
                        }
                      }
                      id={"calendar-event-attachment-" + foldered.id}
                      className="custom-control-input" />
                <label htmlFor={"calendar-event-attachment-" + foldered.id}
                      className="custom-control-label">
                  &nbsp;
                </label>
              </div>
            ) : null}
          </div>
          <div className="table-gutter folder-toggle-table-gutter">
            {foldered.item.is_folder ? (
              <a href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    toggleFolder(foldered.item);
                  }
                }
                className="text-dark">
                {foldered.item.extended ? (
                  <i className="far fa-chevron-down"></i>
                ) : (
                  <i className="far fa-chevron-right"></i>
                )}
              </a>
            ) : null}
          </div>
          <div className="d-flex justify-content-center mr-1"
              style={{
                "width": "24px"
              }}>
            {foldered.item.is_image ? (
              <div title={foldered.item.human_file_name}
                  className="d-inline-block"
                  style={{
                    "width": "24px",
                    "height": "24px",
                    "borderRadius": "2px",
                    "backgroundSize": "cover",
                    "backgroundImage": "url(" + foldered.item.compressed_file_url + ")"
                  }}>
              </div>
            ) : foldered.item.foldable_type === "CalendarEvent" ? (
              <div className="square d-flex align-content-center justify-content-center">
                <p className="align-self-center mb-0">
                  <i className={"far fa-calendar-star"}
                      style={{
                        "fontSize": "20px",
                        "color": "#1982C4"
                      }}>
                  </i>
                </p>
              </div>
            ) : (
              <div className="square d-flex align-content-center justify-content-center">
                <p className="align-self-center mb-0">
                  <i className={"fas " + foldered.item.fontawesome_icon_class}
                      style={{
                        "fontSize": "20px",
                        "color": (foldered.item.is_folder ? "#75b5e6" : "rgba(51, 51, 51, 0.75)")
                      }}>
                  </i>
                </p>
              </div>
            )}
          </div>
          {copiedFolderedItem.id === foldered.id ? (
            <form onSubmit={
              (e) => {
                e.preventDefault();

                if(!canSubmitForm(copiedFolderedItem)){
                  return false;
                }

                updateFolderedItemName(csrfToken, team, copiedFolderedItem);
              }
            }>
              <div className="input-group">
                <input type="text"
                      autoFocus={true}
                      className="form-control form-control-sm small"
                      style={{
                        "borderLeft": "1px solid #ededed",
                        "borderTop": "1px solid #ededed",
                        "borderRight": "0px",
                        "borderBottom": "1px solid #ededed"
                      }}
                      value={copiedFolderedItem.item.human_file_name || ""}
                      onChange={
                        (e) => {
                          copiedFolderedItemNameChanged(e.target.value);
                        }
                      }
                      placeholder="Enter a name" />
                <div className="input-group-append">
                  <button className="btn btn-light btn-sm small text-primary"
                          disabled={!canSubmitForm(copiedFolderedItem)}
                          style={{
                            "background": "white",
                            "borderLeft": "2px solid white",
                            "borderTop": "1px solid #ededed",
                            "borderRight": "1px solid #ededed",
                            "borderBottom": "1px solid #ededed"
                          }}
                          type="submit">
                    <strong>Save</strong>
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div className="text-overflow-ellipsis"
                title={folderedItemTitle(foldered)}>
              <a className="text-dark"
                onClick={
                  (e) => {
                    e.preventDefault();

                    if(foldered.item.is_folder){
                      toggleFolder(foldered.item);
                    } else {
                      fetchCalendarEventAttachment(team, foldered.item.id);
                    }
                  }
                }
                href="#">
                <strong>
                  {foldered.item.human_file_name}
                </strong>
              </a>
              {foldered.item.is_folder ? (
                <span className="folder-count ml-2">
                  {formattedChildrenCount(foldered.item)}
                </span>
              ) : null}
              {foldered.permission_set_id ? (
                <i className="ml-2 far fa-lock small text-danger"></i>
              ) : null}
            </div>
          )}
        </div>
        {showConfirmIdColumn ? (
          <div style={{
                "width": "10%",
                "padding": "8px 12px"
                }}>
            {foldered.calendar_event_id}
          </div>
        ) : null}
        <div style={{
              "width": (showConfirmIdColumn ? "15%" : "20%"),
              "padding": "8px 12px"
              }}>
          {foldered.item.human_updated_at}
        </div>
        <div style={{"width": "15%", "padding": "8px 12px"}}>
          {foldered.item.human_file_size}
        </div>
        <div style={{"width": "15%", "padding": "8px 12px"}}>
          {foldered.item.human_extension}
        </div>
        <div className='text-right' style={{"width": "5%", "padding": "0px"}}>
          {currentUser.can_manage_calendar_event_attachments && foldered.item.user_can_change ? (
            <Popup arrow={false}
                  offsetY={5}
                  position="bottom right"
                  contentStyle={popupContentStyle}
                  onOpen={
                    (e) => {
                      e.preventDefault();
                    }
                  }
                  trigger={open => (
                    <a className="btn btn-external btn-sm control-on-hover-control small"
                        style={{"borderRadius": "8px"}}
                        onClick={
                          (e) => {
                            e.preventDefault();
                          }
                        }
                        href="#">
                      <i className="far fa-ellipsis-v"></i>
                    </a>
                  )} >
              {close => (
                <div className='row text-left'>
                  <div className="col-12">
                    <ul className="list-group offer-inline-menu"
                        style={{"fontSize": "14px"}}>
                      {!foldered.item.is_folder ? (
                        <React.Fragment>
                          <li className="list-group-item border-0 py-2 pl-3">
                            <a href={"/teams/" + team.id + "/calendar_event_attachments/" + foldered.item.id + "/download"}
                              className="text-dark">
                              <span className="d-inline-block text-center mr-2"
                                    style={{width: "28px", fontSize: "19px", color: "#CCC"}}>
                                <i className="far fa-arrow-to-bottom fa-fw"></i>
                              </span>
                              Download
                            </a>
                          </li>
                          <li className="list-group-item border-0 py-2 pl-3">
                            <a href="#"
                              onClick={
                                (e) => {
                                  e.preventDefault();
                                  close();
                                  calendarEventAttachmentToCopyChanged(foldered.item);
                                }
                              }
                              className="text-dark">
                              <span className="d-inline-block text-center mr-2"
                                    style={{width: "28px", fontSize: "19px", color: "#CCC"}}>
                                <i className="far fa-copy fa-fw"></i>
                              </span>
                              Copy
                            </a>
                          </li>
                        </React.Fragment>
                      ) : null}
                      <li className="list-group-item border-0 py-2 pl-3">
                        <a href="#"
                          onClick={
                            (e) => {
                              e.preventDefault();
                              close();
                              stageFolderedItemsForMove([foldered]);
                            }
                          }
                          className="text-dark">
                          <span className="d-inline-block text-center mr-2"
                                style={{width: "28px", fontSize: "19px", color: "#CCC"}}>
                            <i className="fas fa-arrows-alt fa-fw"></i>
                          </span>
                          Move
                        </a>
                      </li>
                      <li className="list-group-item border-0 py-2 pl-3">
                        <a href="#"
                          onClick={
                            (e) => {
                              e.preventDefault();
                              close();
                              copiedFolderedItemChanged(foldered);
                            }
                          }
                          className="text-dark">
                          <span className="d-inline-block text-primary text-center mr-2"
                                style={{width: "28px", fontSize: "19px"}}>
                            <i className="fas fa-pencil fa-fw"></i>
                          </span>
                          Rename
                        </a>
                      </li>
                      {currentUser.can_manage_permission_sets ? (
                        <li className="list-group-item border-0 py-2 pl-3">
                          <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                close();

                                var permissionSetToFetch;
                                if(foldered.permission_set_id){
                                  permissionSetToFetch = {
                                    id: foldered.permission_set_id
                                  };
                                } else {
                                  permissionSetToFetch = {
                                    element_id: foldered.id,
                                    element_type: "FolderedItem"
                                  };
                                }

                                permissionSetToFetch = Object.assign({}, permissionSetToFetch, {
                                  _element_type_human_name: "Asset"
                                });

                                if(foldered.calendar_event_id){
                                  fetchGuestsAndPromotersForPermissions(team, foldered.calendar_event_id);
                                }

                                fetchPermissionSet(team, permissionSetToFetch, (permissionSet) => {
                                  var updated = Object.assign({}, foldered, {permission_set_id: permissionSet.id});
                                  folderedItemUpdated(updated);
                                });
                              }
                            }
                            className="text-dark">
                            <span className="d-inline-block text-muted text-center mr-2"
                                  style={{width: "28px", fontSize: "19px"}}>
                              <i className="far fa-user-lock fa-fw"></i>
                            </span>
                            Set Permissions
                          </a>
                        </li>
                      ) : null}
                      <li className="list-group-item delete-offer-li border-0 py-2 pl-3">
                        <a href="#"
                          onClick={
                            (e) => {
                              e.preventDefault();
                              close();
                              stageFolderedItemsForDestroy([foldered]);
                            }
                          }
                          className="text-dark">
                          <span className="d-inline-block text-danger text-center mr-2"
                                style={{width: "28px", fontSize: "19px"}}>
                            <i className="fas fa-trash fa-fw"></i>
                          </span>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </Popup>
          ) : (
            <div>&nbsp;</div>
          )}
        </div>
      </div>
    </div>
  );
};

FolderedItemRow.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  fetchCalendarEventAttachment: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  foldered: PropTypes.object.isRequired,
  depth: PropTypes.number.isRequired,
  toggleFolder: PropTypes.func.isRequired,
  selectedFolderedItems: PropTypes.array,
  togglefolderedItemSelected: PropTypes.func.isRequired,
  stageFolderedItemsForDestroy: PropTypes.func.isRequired,
  parentFolderedItems: PropTypes.array,
  copiedFolderedItem: PropTypes.object,
  copiedFolderedItemChanged: PropTypes.func.isRequired,
  copiedFolderedItemNameChanged: PropTypes.func.isRequired,
  updateFolderedItemName: PropTypes.func.isRequired,
  stageFolderedItemsForMove: PropTypes.func.isRequired,
  rootFolder: PropTypes.object.isRequired,
  fetchPermissionSet: PropTypes.func.isRequired,
  folderedItemUpdated: PropTypes.func.isRequired,
  depthWidth: PropTypes.func.isRequired,
  fetchGuestsAndPromotersForPermissions: PropTypes.func.isRequired,
  calendarEventAttachmentToCopyChanged: PropTypes.func.isRequired,
  uploadFilesToFolder: PropTypes.func.isRequired,
  showConfirmIdColumn: PropTypes.bool
};

export default FolderedItemRow;
