import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const TimeLimitReached = ({
  team,
  csrfToken,
  confirm,
  timeLimitReachedChanged
}) => (
  <div className="row"
       style={{"paddingTop": "200px", "paddingBottom": "200px"}}>
    <div className="col-12 text-center">
      <p className="mb-2" style={{"fontSize": "21px"}}>
        <strong>Time Limit Reached</strong>
      </p>
      <p className="mb-3 text-muted" style={{"fontSize": "14px"}}>
        Your reservation has been released. Please re-start your purchase.
      </p>
      <p className="mb-0" style={{"fontSize": "14px"}}>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               timeLimitReachedChanged(false);
             }
           }>
          <strong>Back to Tickets</strong>
        </a>
      </p>
    </div>
  </div>
);

TimeLimitReached.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  timeLimitReachedChanged: PropTypes.func.isRequired
};

export default TimeLimitReached;
