import { combineReducers } from 'redux';

import {
  FOLDER_CHANGED,
  SEARCH_TERM_CHANGED
} from '../constants/globalAssetCenterConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const folder = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_TERM_CHANGED:
      return Object.assign({}, state, {
        foldered_items: [],
        lastRequestData: undefined,
        isLoadingPaginatedData: undefined
      });
    case FOLDER_CHANGED:
      return action.folder;
    default:
      return state;
  }
};

const guestTeamMemberships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const promotersWithPermission = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const searchTerm = (state = "", action) => {
  switch (action.type) {
    case SEARCH_TERM_CHANGED:
      return action.searchTerm;
    default:
      return state;
  }
};

const teamRootFolder = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const globalAssetCenterReducer = combineReducers({
  csrfToken,
  currentUser,
  team,
  folder,
  guestTeamMemberships,
  promotersWithPermission,
  searchTerm,
  teamRootFolder
});

export default globalAssetCenterReducer;
