/* eslint-disable import/prefer-default-export */

import {
  FOLDER_CHANGED,
  SEARCH_TERM_CHANGED
} from '../constants/globalAssetCenterConstants';

export const folderChanged = (folder) => ({
  type: FOLDER_CHANGED,
  folder
});

export const searchTermChanged = (searchTerm) => ({
  type: SEARCH_TERM_CHANGED,
  searchTerm
});
