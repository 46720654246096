import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import StripeAccount from "./StripeAccount";
import { ToastContainer, toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

const RefundSettings = ({
  csrfToken,
  team,
  currentUser,
  stripePublishableKey,
  createStripeFinancialConnectionSession,
  financialConnectionAccounts,
  deleteFinancialConnectionAccount
}) => {
  var stripePromise = loadStripe(stripePublishableKey);

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <Elements stripe={stripePromise}>
            <StripeAccount csrfToken={csrfToken}
                           team={team}
                           currentUser={currentUser}
                           createStripeFinancialConnectionSession={createStripeFinancialConnectionSession}
                           deleteFinancialConnectionAccount={deleteFinancialConnectionAccount}
                           financialConnectionAccounts={financialConnectionAccounts} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

RefundSettings.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
  createStripeFinancialConnectionSession: PropTypes.func.isRequired,
  financialConnectionAccounts: PropTypes.array,
  deleteFinancialConnectionAccount: PropTypes.func.isRequired
};

export default RefundSettings;
