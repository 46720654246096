import React from "react";

import { useBroadcastEditContext } from "./BroadcastEditContainer";
import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";

const containerStyle = {
  background: "white",
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "grid",
  width: "100%",
};

const EmailSentTab = () => {
  const {
    ahoyMessagesDataTableProps,
  } = useBroadcastEditContext();

  return (
    <div style={containerStyle}>
      <ContainerlessTableViewApp
        {...ahoyMessagesDataTableProps}
      />
    </div>
  )
};

export default EmailSentTab;
