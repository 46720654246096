import { combineReducers } from 'redux';

import {
  DATA_TABLE_EL_CHANGED,
  POST_TO_EDIT_CHANGED,
  NEW_POST_CHANGED,
  HAS_POSTS_CHANGED
} from '../constants/scheduledPostsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const searchedSearchTerm = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const postToEdit = (state = {}, action) => {
  switch (action.type) {
    case POST_TO_EDIT_CHANGED:
      return action.post;
    default:
      return state;
  }
};

const onepostPublicKey = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const authorizedPages = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const newPost = (state = {}, action) => {
  switch (action.type) {
    case NEW_POST_CHANGED:
      return action.post;
    default:
      return state;
  }
};

const hasPosts = (state = false, action) => {
  switch (action.type) {
    case HAS_POSTS_CHANGED:
      return action.hasPosts;
    default:
      return state;
  }
};

const openNewPost = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const scheduledPostsReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  searchedSearchTerm,
  postToEdit,
  onepostPublicKey,
  authorizedPages,
  newPost,
  hasPosts,
  openNewPost
});

export default scheduledPostsReducer;
