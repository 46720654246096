import { combineReducers } from 'redux';

import {
  OUTREACH_CHANGED,
  LAST_PIPELINE_EVENT_CHANGED,
  PIPELINE_EVENTS_CHANGED,
  UNSEEN_PIPELINE_EVENTS_CHANGED
} from '../constants/addOutreachConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const venuesOptions = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
const venueSelected = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const lastPipelineEvent = (state = {}, action) => {
  switch (action.type) {
    case LAST_PIPELINE_EVENT_CHANGED:
      return action.pipelineEvent;
    default:
      return state;
  }
};

const artist = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const outreach = (state = {}, action) => {
  switch (action.type) {
    case OUTREACH_CHANGED:
      return action.outreach;
    default:
      return state;
  }
};

const outreachTemplates = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pipelineEvents = (state = [], action) => {
  switch (action.type) {
    case PIPELINE_EVENTS_CHANGED:
      return action.pipelineEvents;
    default:
      return state;
  }
};

const fetchPipelineEventsForArtistOnInit = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const unseenPipelineEvents = (state = [], action) => {
  switch (action.type) {
    case UNSEEN_PIPELINE_EVENTS_CHANGED:
      return action.pipelineEvents;
    default:
      return state;
  }
};

const addOutreachReducer = combineReducers({ csrfToken, venuesOptions, venueSelected, currentUser, team, venueOwnerships, lastPipelineEvent, artist, outreach, outreachTemplates, pipelineEvents, fetchPipelineEventsForArtistOnInit, unseenPipelineEvents });

export default addOutreachReducer;
