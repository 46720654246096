/* eslint-disable import/prefer-default-export */

export const canSubmitCustomFieldValue = (customFieldValue) => {
  var canSubmit = false;

  switch (customFieldValue.type) {
    case 'CustomFieldValues::Address':
      var values = Object.values(customFieldValue.value);
      var hasAnyValues = values.some((v) => v && v.length > 0);
      var zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/

      if(hasAnyValues){
        canSubmit = (
          customFieldValue.value.address1
            && customFieldValue.value.address1.length > 0
            && customFieldValue.value.city
            && customFieldValue.value.city.length > 0
            && customFieldValue.value.state
            && customFieldValue.value.state.length > 0
            && customFieldValue.value.zip
            && customFieldValue.value.zip.length > 0
            && customFieldValue.value.zip.match(zipRegex)
        );
      } else {
        canSubmit = (
          !customFieldValue.custom_field.required
        );
      }

      break;
    case 'CustomFieldValues::Phone':
      var nonDigit = /\D/ig;
      var phoneNumber = customFieldValue.value.replaceAll(nonDigit, "");

      if(phoneNumber.length > 0){
        canSubmit = (
          phoneNumber.length === 10
        );
      } else {
        canSubmit = (
          !customFieldValue.custom_field.required
        );
      }

      break;
    default:
      console.log(`Unable to validate customFieldValue for submission`);
  }

  return canSubmit;
}

export const customFieldValueToParams = (customFieldValue) => {
  var params = {
    id: customFieldValue.id,
    custom_field_id: customFieldValue.custom_field.id,
    type: customFieldValue.type
  };

  switch (customFieldValue.type) {
    case 'CustomFieldValues::Address':
      params = Object.assign({}, params, {
        address1: customFieldValue.value.address1,
        address2: customFieldValue.value.address2,
        city: customFieldValue.value.city,
        state: customFieldValue.value.state,
        zip: customFieldValue.value.zip
      });
      break;
    case 'CustomFieldValues::Phone':
      var nonDigit = /\D/ig;

      params = Object.assign({}, params, {
        value: customFieldValue.value.replaceAll(nonDigit, "")
      });
      break;
    default:
      console.log(`Unable to convert customFieldValue to params`);
  }

  return params;
}
