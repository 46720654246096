import PropTypes from 'prop-types';
import React from 'react';

const canSubmitForm = (originalTeamManageable, teamManageable, roles) => {
  var isDifferent = (
    originalTeamManageable.first_name !== teamManageable.first_name
      || originalTeamManageable.last_name !== teamManageable.last_name
      || originalTeamManageable.email !== teamManageable.email
      || originalTeamManageable.role_id !== teamManageable.role_id
      || originalTeamManageable.company !== teamManageable.company
      || JSON.stringify(originalTeamManageable.venue_ownership_ids.sort()) !== JSON.stringify(teamManageable.venue_ownership_ids.sort())
  );

  var role = roles.find((r) => r.id === teamManageable.role_id);

  if(role.name === "Promoter"){
    return (
      isDifferent
        && teamManageable.first_name
        && teamManageable.last_name
        && teamManageable.email
        && teamManageable.role_id
        && teamManageable.company
    );
  } else {
    return (
      isDifferent
        && teamManageable.first_name
        && teamManageable.last_name
        && teamManageable.email
        && teamManageable.role_id
    );
  }
}

const TeamManageableInlineForm = ({ teamManageable, cancelEditTeamManageable, roles, venueOwnerships, teamManageableToEditUpdateFirstName, teamManageableToEditUpdateLastName, teamManageableToEditUpdateEmail, teamManageableToEditUpdateRoleId, teamManageableToEditVenueOwnershipClicked, originalTeamManageable, teamManageableToEditEnableAllVenues, teamManageableToEditEnableAllVenuesClicked, teamManageableToEditDisableAllVenuesClicked, submitUpdateTeamManageable, csrfToken, team, teamManageableToEditCompanyChanged }) => (
  <tr className="inline-tr-form disable-vertical-middle">
    <td colSpan={2} style={{"paddingLeft": "20px"}}>
      <div className="row">
        <div className="col">
          <form>
            {teamManageable.errors && teamManageable.errors.length > 0 ? (
              <div className="form-group">
                <div className="p-3 mb-2 bg-danger text-white rounded">
                  <ul className="mb-0 no-bullets">
                    {teamManageable.errors.map((error, key) =>
                      <li key={key}>{error}</li>
                    )}
                  </ul>
                </div>
              </div>
            ) : null}
            <div className="form-row">
              <div className="col-12 col-md-6">
                <input type="text"
                       className="form-control my-2"
                       defaultValue={teamManageable.first_name}
                       onChange={
                         (e) => {
                           teamManageableToEditUpdateFirstName(e.target.value);
                         }
                       }
                       placeholder="First name..." />
              </div>
              <div className="col-12 col-md-6">
                <input type="text"
                       className="form-control my-2"
                       defaultValue={teamManageable.last_name}
                       onChange={
                         (e) => {
                           teamManageableToEditUpdateLastName(e.target.value);
                         }
                       }
                       placeholder="Last name..." />
              </div>
              <div className="col-12">
                <input type="email"
                       className="form-control my-2"
                       defaultValue={teamManageable.email}
                       onChange={
                         (e) => {
                           teamManageableToEditUpdateEmail(e.target.value);
                         }
                       }
                       placeholder="Email address..." />
              </div>
              {roles.find((r) => r.id === teamManageable.role_id && r.name === "Promoter") ? (
                <div className="col-12">
                  <input type="text"
                         className="form-control my-2"
                         defaultValue={teamManageable.company || ""}
                         onChange={
                           (e) => {
                             teamManageableToEditCompanyChanged(e.target.value);
                           }
                         }
                         placeholder="Company..." />
                </div>
              ) : null}
            </div>
            <div className="form-row pt-3">
              <div className="col-12">
                <table className="table table-sm table-borderless" style={{tableLayout: "fixed"}}>
                  <thead>
                    <tr>
                      <th className="strong text-muted pl-0">Venue</th>
                      <th className="strong text-muted">
                        Access
                        &nbsp;
                        {teamManageableToEditEnableAllVenues ? (
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 teamManageableToEditEnableAllVenuesClicked(venueOwnerships);
                               }
                             }
                             className="text-muted">
                            <u>Enable All</u>
                          </a>
                        ) : (
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 teamManageableToEditDisableAllVenuesClicked();
                               }
                             }
                             className="text-muted">
                            <u>Disable All</u>
                          </a>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {venueOwnerships.map((venueOwnership, index) => (
                      <tr key={index}>
                        <td className="p-0">
                          <label htmlFor={"new-invitation-venue-ownership-" + venueOwnership.id} >
                            <strong>{venueOwnership.venueable.name}</strong>
                          </label>
                        </td>
                        <td className="p-0">
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                   checked={
                                     ((teamManageable.venue_ownership_ids && teamManageable.venue_ownership_ids.includes(venueOwnership.id)) ? true : false)
                                   }
                                   id={"new-invitation-venue-ownership-" + venueOwnership.id}
                                   onChange={
                                     (e) => {
                                       teamManageableToEditVenueOwnershipClicked(venueOwnership.id);
                                     }
                                   }
                                   className="custom-control-input" />
                            <label className="custom-control-label"
                                   htmlFor={"new-invitation-venue-ownership-" + venueOwnership.id} >
                              &nbsp;
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </td>
    <td>
      <div className="row">
        <div className="col">
          <form>
            <div className="form-row">
              <div className="col-12">
                <select defaultValue={teamManageable.role_id}
                        onChange={
                          (e) => {
                            teamManageableToEditUpdateRoleId(parseInt(e.target.value));
                          }
                        }
                        className="form-control">
                  {roles.map((role, index) => (
                    (role.name === "Guest" && teamManageable.role_id !== roles.find(r => r.name === "Guest").id)
                      ? null
                      : (
                    <option key={index} value={role.id}>
                      {role.display_name}
                    </option>
                    )
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    </td>
    <td className="text-right" style={{"paddingRight": "20px"}}>
      <a href="#"
         onClick={
           (e) => {
             e.preventDefault();
             cancelEditTeamManageable(teamManageable);
           }
         } >
        Cancel
      </a>
      <a href="#"
         className={"btn btn-primary ml-3 " + (!canSubmitForm(originalTeamManageable, teamManageable, roles) ? "disabled" : "")}
         onClick={
           (e) => {
             if(canSubmitForm(originalTeamManageable, teamManageable, roles)){
               submitUpdateTeamManageable(csrfToken, team, teamManageable);
             }

             e.preventDefault();
           }
         } >
        Save
      </a>
    </td>
  </tr>
);

TeamManageableInlineForm.propTypes = {
  teamManageable: PropTypes.object.isRequired,
  cancelEditTeamManageable: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  venueOwnerships: PropTypes.array.isRequired,
  teamManageableToEditUpdateFirstName: PropTypes.func.isRequired,
  teamManageableToEditUpdateLastName: PropTypes.func.isRequired,
  teamManageableToEditUpdateEmail: PropTypes.func.isRequired,
  teamManageableToEditUpdateRoleId: PropTypes.func.isRequired,
  teamManageableToEditVenueOwnershipClicked: PropTypes.func.isRequired,
  originalTeamManageable: PropTypes.object.isRequired,
  teamManageableToEditEnableAllVenues: PropTypes.bool,
  teamManageableToEditEnableAllVenuesClicked: PropTypes.func.isRequired,
  teamManageableToEditDisableAllVenuesClicked: PropTypes.func.isRequired,
  submitUpdateTeamManageable: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  teamManageableToEditCompanyChanged: PropTypes.func.isRequired
};

export default TeamManageableInlineForm;
