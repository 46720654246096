/* eslint-disable import/prefer-default-export */

import {
  OUTREACH_CHANGED,
  LAST_PIPELINE_EVENT_CHANGED,
  PIPELINE_EVENTS_CHANGED,
  UNSEEN_PIPELINE_EVENTS_CHANGED
} from '../constants/addOutreachConstants';

import {EditorState} from 'draft-js';

const axios = require('axios').default;

export const fetchNewOutreach = (dispatch, team, artist) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/outreaches/new", {
        params: {
          artist_id: artist.permalink
        }
      })
      .then(({ data }) => {
        var outreach = Object.assign({}, data, {
          body: EditorState.createEmpty()
        });

        dispatch(outreachChanged(outreach));
      })
  };
};

export const outreachChanged = (outreach) => ({
  type: OUTREACH_CHANGED,
  outreach
});

export const lastPipelineEventChanged = (pipelineEvent) => ({
  type: LAST_PIPELINE_EVENT_CHANGED,
  pipelineEvent
});

export const fetchPipelineEventsForArtist = (dispatch, team, artist) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/pipeline_events", {
        params: {
          artist_id: artist.permalink
        }
      })
      .then(({ data }) => {
        dispatch(pipelineEventsChanged(data));
        dispatch(unseenPipelineEventsChanged([]));
      })
  };
};

export const pipelineEventsChanged = (pipelineEvents) => ({
  type: PIPELINE_EVENTS_CHANGED,
  pipelineEvents
});

export const fetchUnseenPipelineEvents = (dispatch, team, artist) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/pipeline_events/unseen", {
        params: {
          artist_id: artist.permalink
        }
      })
      .then(({ data }) => {
        dispatch(unseenPipelineEventsChanged(data));
      })
  };
};

export const unseenPipelineEventsChanged = (pipelineEvents) => ({
  type: UNSEEN_PIPELINE_EVENTS_CHANGED,
  pipelineEvents
});
