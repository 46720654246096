import * as React from "react";

import { createComponent } from "@lit-labs/react";
import { EventParticipationCancellationInsurance } from "@vertical-insure/web-components/legacy";

const VerticalInsure = createComponent({
  tagName: "event-participation-cancellation-insurance",
  elementClass: EventParticipationCancellationInsurance,
  react: React,
  events: {
    onChange: "change",
    onQuoteChange: "quote-change",
    onOfferStateChange: "offer-state-change",
  },
});

export default VerticalInsure;