import { combineReducers } from 'redux';

import {
  OFFER_VENUE_CHANGED,
  OFFER_PRIMARY_ARTIST_CHANGED,
  OFFER_START_DATE_CHANGED,
  OFFER_DEAL_TERM_LINE_ITEM_ADDED,
  OFFER_DEAL_TERM_LINE_ITEM_REMOVED,
  OFFER_DEAL_TERM_LINE_ITEM_LABEL_CHANGED,
  OFFER_DEAL_TERM_LINE_ITEM_DESCRIPTION_CHANGED,
  OFFER_ADDITIONAL_TERMS_CHANGED,
  OFFER_CONTACT_DETAILS_CHANGED,
  OFFER_PERFORMER_REMOVED,
  OFFER_AGENT_NAME_CHANGED,
  OFFER_AGENT_EMAIL_CHANGED,
  OFFER_TEMPLATE_NAME_CHANGED,
  SELECTED_OFFER_TEMPLATE_CHANGED,
  OFFER_ARTIST_SUGGESTION_ADDED,
  OFFER_CALENDAR_EVENT_CHANGED,
  OFFER_PERFORMER_SELECTED,
  OFFER_PERFORMER_UNSELECTED,
  OFFER_START_TIME_CHANGED,
  OFFER_FRONT_END_STATE_CHANGED,
  OFFER_EMAIL_BODY_CHANGED,
  OFFER_LAST_SENT_TO_CHANGED,
  OFFER_CALENDAR_EVENT_PERFORMERS_CHANGED,
  APPLY_OFFER_TEMPLATE,
  OFFER_ARTIST_PREVIEW_CHANGED,
  OFFER_CHANGED,
  OFFER_ERRORS_CHANGED,
  NEW_OFFER_TEMPLATE_CREATED,
  OFFER_EVENT_NAME_CHANGED,
  OFFER_DOLLAR_OR_PERCENTAGE_OPERATOR_CHANGED,
  OFFER_DOLLAR_AMOUNT_CHANGED,
  OFFER_UPSIDE_PERCENTAGE_CHANGED,
  HIDDEN_FILE_UPLOAD_INPUT_EL_CHANGED,
  ATTACHMENT_ADDED,
  ATTACHMENT_REMOVED
} from '../../OfferModal/constants/offerModalConstants';

export const offer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_EVENT_NAME_CHANGED:
      return Object.assign({}, state, {
        eventName: action.eventName,
        userChangedEventName: true
      });
    case OFFER_CALENDAR_EVENT_CHANGED:
      return Object.assign({}, state, {
        calendarEvent: action.calendarEvent,
        createCalendarEvent: (action.calendarEvent && action.calendarEvent.value === 0),
        startTime: null,
        performer: null,
        calendarEventPerformers: null
      });
    case OFFER_CALENDAR_EVENT_PERFORMERS_CHANGED:
      return Object.assign({}, state, {calendarEventPerformers: action.performers});
    case OFFER_START_TIME_CHANGED:
      return Object.assign({}, state, {startTime: action.startTime});
    case OFFER_PERFORMER_UNSELECTED:
      var primaryPerformer = Object.assign({}, state.primaryPerformer);
      primaryPerformer.artist = undefined;
      primaryPerformer.artistSuggestion = undefined;
      primaryPerformer._destroy = true;

      return Object.assign({}, state, {
        artistPreview: undefined,
        primaryPerformer: primaryPerformer,
        performer: null,
        agentName: null,
        agentEmail: null,
        lastSentTo: null
      });
    case OFFER_PERFORMER_SELECTED:
      return Object.assign({}, state, {
        performer: action.performer,
        agentName: (action.performer.artistable.primary_agent ? action.performer.artistable.primary_agent.name : null),
        agentEmail: (action.performer.artistable.primary_agent ? action.performer.artistable.primary_agent.email : null),
        lastSentTo: (action.performer.artistable.primary_agent ? action.performer.artistable.primary_agent.email : null)
      });
    case OFFER_VENUE_CHANGED:
      return Object.assign({}, state, {venueSelected: action.venue});
    case OFFER_START_DATE_CHANGED:
      return Object.assign({}, state, {startDate: action.date});
    case OFFER_AGENT_NAME_CHANGED:
      return Object.assign({}, state, {agentName: action.agentName});
    case OFFER_DOLLAR_OR_PERCENTAGE_OPERATOR_CHANGED:
      return Object.assign({}, state, {dollar_or_percentage_operator: action.operator});
    case OFFER_DOLLAR_AMOUNT_CHANGED:
      return Object.assign({}, state, {dollar_amount: action.amount});
    case OFFER_UPSIDE_PERCENTAGE_CHANGED:
      return Object.assign({}, state, {upside_percentage: action.percentage});
    case APPLY_OFFER_TEMPLATE:
      var existingDealTermLineItems = [...state.dealTermLineItems].map((dealTermLineItem) => {
        return Object.assign({}, dealTermLineItem, {_destroy: true})
      });
      var dealTermLineItems = existingDealTermLineItems.concat(action.offerTemplate.dealTermLineItems);

      return Object.assign({}, state, {
        dealTermLineItems: dealTermLineItems,
        additionalTerms: action.offerTemplate.additionalTerms,
        contactDetails: action.offerTemplate.contactDetails
      });
    case OFFER_AGENT_EMAIL_CHANGED:
      return Object.assign({}, state, {
        agentEmail: action.agentEmail,
        lastSentTo: action.agentEmail
      });
    case OFFER_LAST_SENT_TO_CHANGED:
      return Object.assign({}, state, {lastSentTo: action.lastSentTo});
    case OFFER_EMAIL_BODY_CHANGED:
      return Object.assign({}, state, {emailBody: action.emailBody});
    case OFFER_ARTIST_SUGGESTION_ADDED:
      var primaryPerformer = Object.assign({}, state.primaryPerformer);
      primaryPerformer.artist = undefined;
      primaryPerformer.artistSuggestion = {
        name: action.artistSuggestion,
        user_id: action.user.id,
        team_id: action.team.id
      };
      primaryPerformer._destroy = false;

      return Object.assign({}, state, {
        primaryPerformer: primaryPerformer,
        agentName: null,
        agentEmail: null,
        lastSentTo: null
      });
    case OFFER_PERFORMER_REMOVED:
      var primaryPerformer = Object.assign({}, state.primaryPerformer);
      primaryPerformer.artist = undefined;
      primaryPerformer.artistSuggestion = undefined;
      primaryPerformer._destroy = true;

      return Object.assign({}, state, {
        artistPreview: undefined,
        primaryPerformer: primaryPerformer,
        agentName: null,
        agentEmail: null,
        lastSentTo: null
      });
    case OFFER_ARTIST_PREVIEW_CHANGED:
      var newState = {
        artistPreview: action.artist,
        agentName: (action.artist.primary_agent ? action.artist.primary_agent.name : null),
        agentEmail: (action.artist.primary_agent ? action.artist.primary_agent.email : null),
        lastSentTo: (action.artist.primary_agent ? action.artist.primary_agent.email : null)
      };

      var userChangedEventName = !!state.userChangedEventName;
      if(action.offer.id === undefined && !userChangedEventName){
        newState.eventName = action.artist.name;
      }

      return Object.assign({}, state, newState);
    case OFFER_PRIMARY_ARTIST_CHANGED:
      var primaryPerformer = Object.assign({}, (state.primaryPerformer || {}));

      primaryPerformer.artist = action.artist;
      primaryPerformer.artistSuggestion = undefined;
      primaryPerformer._destroy = false;

      return Object.assign({}, state, {
        primaryPerformer: primaryPerformer,
        agentName: null,
        agentEmail: null,
        lastSentTo: null
      });
    case OFFER_ADDITIONAL_TERMS_CHANGED:
      return Object.assign({}, state, {additionalTerms: action.editorState});
    case OFFER_CONTACT_DETAILS_CHANGED:
      return Object.assign({}, state, {contactDetails: action.editorState});
    case OFFER_CHANGED:
      return action.offer;
    case OFFER_ERRORS_CHANGED:
      return Object.assign({}, state, {errors: action.errors});
    case OFFER_FRONT_END_STATE_CHANGED:
      return Object.assign({}, state, {frontEndState: action.state});
    case OFFER_DEAL_TERM_LINE_ITEM_ADDED:
      var dealTermLineItems = state.dealTermLineItems ? state.dealTermLineItems : [];
      dealTermLineItems = [...dealTermLineItems, {}];

      return Object.assign({}, state, {dealTermLineItems: dealTermLineItems});
    case OFFER_DEAL_TERM_LINE_ITEM_REMOVED:
      var dealTermLineItems = state.dealTermLineItems.map((dealTermLineItem, index) => {
        if (index === action.index){
          dealTermLineItem._destroy = true;
        }

        return dealTermLineItem;
      });

      return Object.assign({}, state, {dealTermLineItems: dealTermLineItems});
    case OFFER_DEAL_TERM_LINE_ITEM_LABEL_CHANGED:
      var dealTermLineItems = state.dealTermLineItems.map((dealTermLineItem, index) => {
        if (index === action.index){
          dealTermLineItem.label = action.label;
        }

        return dealTermLineItem;
      });

      return Object.assign({}, state, {dealTermLineItems: dealTermLineItems});
    case OFFER_DEAL_TERM_LINE_ITEM_DESCRIPTION_CHANGED:
      var dealTermLineItems = state.dealTermLineItems.map((dealTermLineItem, index) => {
        if (index === action.index){
          dealTermLineItem.description = action.description;
        }

        return dealTermLineItem;
      });

      return Object.assign({}, state, {dealTermLineItems: dealTermLineItems});
    case ATTACHMENT_ADDED:
      var attachments = (state.attachments || []);
      attachments = [...attachments, action.attachment];

      return Object.assign({}, state, {attachments: attachments});
    case ATTACHMENT_REMOVED:
      var attachments = (state.attachments || []);
      attachments = attachments.filter((attachment) => {
        return attachment.id !== action.attachment.id;
      });

      return Object.assign({}, state, {attachments: attachments});
    default:
      return state;
  }
};

export const selectedOfferTemplate = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_OFFER_TEMPLATE_CHANGED:
      return action.offerTemplate;
    case NEW_OFFER_TEMPLATE_CREATED:
      return action.offerTemplate;
    default:
      return state;
  }
};

export const offerTemplateName = (state = "", action) => {
  switch (action.type) {
    case OFFER_TEMPLATE_NAME_CHANGED:
      return action.offerTemplateName;
    case NEW_OFFER_TEMPLATE_CREATED:
      return "";
    default:
      return state;
  }
};

export const offerTemplates = (state = [], action) => {
  switch (action.type) {
    case NEW_OFFER_TEMPLATE_CREATED:
      return [...state, action.offerTemplate];
    default:
      return state;
  }
};

export const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const defaultOffer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const closeModal = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const hiddenFileUploadInputEl = (state = {}, action) => {
  switch (action.type) {
    case HIDDEN_FILE_UPLOAD_INPUT_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const offerModalReducer = combineReducers({ offer, selectedOfferTemplate, offerTemplateName, offerTemplates, csrfToken, team, currentUser, defaultOffer, closeModal, venuesOptions, dataTableEl, hiddenFileUploadInputEl });

export default offerModalReducer;
