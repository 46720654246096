import { combineReducers } from 'redux';

import {
  SELECTED_VENUE_OWNERSHIP_CHANGED,
  FOLDER_CHANGED,
  SEARCH_TERM_CHANGED
} from '../constants/eventsAssetCenterConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedVenueOwnership = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_VENUE_OWNERSHIP_CHANGED:
      return action.selectedVenueOwnership;
    default:
      return state;
  }
};

const folder = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_TERM_CHANGED:
      return Object.assign({}, state, {
        foldered_items: [],
        lastRequestData: undefined,
        isLoadingPaginatedData: undefined
      });
    case FOLDER_CHANGED:
      return action.folder;
    default:
      return state;
  }
};

const folderOptions = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const searchTerm = (state = "", action) => {
  switch (action.type) {
    case SEARCH_TERM_CHANGED:
      return action.searchTerm;
    default:
      return state;
  }
};

const teamRootFolder = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const eventsAssetCenterReducer = combineReducers({
  csrfToken,
  currentUser,
  team,
  venueOwnerships,
  selectedVenueOwnership,
  folder,
  folderOptions,
  searchTerm,
  teamRootFolder
});

export default eventsAssetCenterReducer;
