// Simple example of a React "smart" component

import { connect } from 'react-redux';
import FolderView from '../components/FolderView';
import * as actions from '../actions/folderViewActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  confirm: state.confirm,
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  folder: state.folder,
  selectedFolderedItems: state.selectedFolderedItems,
  folderedItemsToDestroy: state.folderedItemsToDestroy,
  copiedFolderedItem: state.copiedFolderedItem,
  folderedItemsToMove: state.folderedItemsToMove,
  permissionSet: state.permissionSet,
  onPermissionSetSaved: state.onPermissionSetSaved,
  targetFolder: state.targetFolder,
  folderCopy: state.folderCopy,
  openCalendarEventAttachment: state.openCalendarEventAttachment,
  onFolderChanged: state.onFolderChanged,
  guestTeamMemberships: state.guestTeamMemberships,
  promotersWithPermission: state.promotersWithPermission,
  openCalendarEventAttachmentPdfNumberOfPages: state.openCalendarEventAttachmentPdfNumberOfPages,
  headerComponent: state.headerComponent,
  isAddingFolder: state.isAddingFolder,
  newFolderName: state.newFolderName,
  isUploadingFiles: state.isUploadingFiles,
  selectedFilesToUpload: state.selectedFilesToUpload,
  isLoadingUploadedFiles: state.isLoadingUploadedFiles,
  canSelectRootFolderAsTarget: state.canSelectRootFolderAsTarget,
  searchTerm: state.searchTerm,
  targetFolderSearchTerm: state.targetFolderSearchTerm,
  calendarEventAttachmentToCopy: state.calendarEventAttachmentToCopy,
  isCopyingCalendarEventAttachment: state.isCopyingCalendarEventAttachment,
  teamRootFolder: state.teamRootFolder,
  draggedFilesToUpload: state.draggedFilesToUpload,
  showConfirmIdColumn: state.showConfirmIdColumn
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeCalendarEventAttachmentModal: () => dispatch(actions.closeCalendarEventAttachmentModal()),
    deleteCalendarEventAttachment: (csrfToken, team, calendarEventAttachment) => dispatch(actions.deleteCalendarEventAttachment(dispatch, csrfToken, team, calendarEventAttachment)),
    fetchCalendarEventAttachment: (team, calendarEventAttachmentId) => dispatch(actions.fetchCalendarEventAttachment(dispatch, team, calendarEventAttachmentId)),
    openCalendarEventAttachmentPdfNumberOfPagesChanged: (numPages) => dispatch(actions.openCalendarEventAttachmentPdfNumberOfPagesChanged(numPages)),
    editOpenCalendarEventAttachmentName: () => dispatch(actions.editOpenCalendarEventAttachmentName()),
    updateCalendarEventAttachment: (csrfToken, team, calendarEventAttachment, newName) => dispatch(actions.updateCalendarEventAttachment(dispatch, csrfToken, team, calendarEventAttachment, newName)),
    setOpenCalendarEventAttachmentNameEl: (el) => dispatch(actions.setOpenCalendarEventAttachmentNameEl(el)),
    endEditOpenCalendarEventAttachmentName: () => dispatch(actions.endEditOpenCalendarEventAttachmentName()),
    createFolder: (csrfToken, team, confirm, folder, newFolderName) => dispatch(actions.createFolder(dispatch, csrfToken, team, confirm, folder, newFolderName)),
    toggleFolder: (folder) => dispatch(actions.toggleFolder(dispatch, folder)),
    togglefolderedItemSelected: (folderedItem) => dispatch(actions.togglefolderedItemSelected(folderedItem)),
    stageFolderedItemsForDestroy: (folderedItems) => dispatch(actions.stageFolderedItemsForDestroy(folderedItems)),
    deleteStagedFolderedItems: (csrfToken, team, folderedItemsToDestroy) => dispatch(actions.deleteStagedFolderedItems(dispatch, csrfToken, team, folderedItemsToDestroy)),
    copiedFolderedItemChanged: (folderedItem) => dispatch(actions.copiedFolderedItemChanged(folderedItem)),
    copiedFolderedItemNameChanged: (name) => dispatch(actions.copiedFolderedItemNameChanged(name)),
    updateFolderedItemName: (csrfToken, team, folderedItem) => dispatch(actions.updateFolderedItemName(dispatch, csrfToken, team, folderedItem)),
    stageFolderedItemsForMove: (folderedItems) => dispatch(actions.stageFolderedItemsForMove(folderedItems)),
    moveStagedFolderedItems: (csrfToken, team, folderedItemsToMove, targetFolder) => dispatch(actions.moveStagedFolderedItems(dispatch, csrfToken, team, folderedItemsToMove, targetFolder)),
    permissionSetChanged: (permissionSet) => dispatch(actions.permissionSetChanged(permissionSet)),
    fetchPermissionSet: (team, permissionSet, onPermissionSetSaved) => dispatch(actions.fetchPermissionSet(dispatch, team, permissionSet, onPermissionSetSaved)),
    permissionRuleEnabledClicked: (permissionRule) => dispatch(actions.permissionRuleEnabledClicked(permissionRule)),
    selectAllPermissionRulesClicked: (checked) => dispatch(actions.selectAllPermissionRulesClicked(checked)),
    submitPermissionSet: (csrfToken, team, permissionSet, onPermissionSetSaved) => dispatch(actions.submitPermissionSet(dispatch, csrfToken, team, permissionSet, onPermissionSetSaved)),
    folderedItemUpdated: (folderedItem) => dispatch(actions.folderedItemUpdated(dispatch, folderedItem)),
    fetchFolderedItemsForFolder: (team, folder, searchTerm, sortBy) => dispatch(actions.fetchFolderedItemsForFolder(dispatch, team, folder, searchTerm, sortBy)),
    targetFolderChanged: (targetFolder) => dispatch(actions.targetFolderChanged(targetFolder)),
    folderCopyChanged: (folderCopy) => dispatch(actions.folderCopyChanged(folderCopy)),
    fetchFolderedItemsForFolderCopy: (team, folder, searchTerm) => dispatch(actions.fetchFolderedItemsForFolderCopy(dispatch, team, folder, searchTerm)),
    toggleFolderCopy: (folder) => dispatch(actions.toggleFolderCopy(folder)),
    isAddingFolderChanged: (isAddingFolder) => dispatch(actions.isAddingFolderChanged(isAddingFolder)),
    newFolderNameChanged: (newFolderName) => dispatch(actions.newFolderNameChanged(newFolderName)),
    isUploadingFilesChanged: (isUploadingFiles) => dispatch(actions.isUploadingFilesChanged(isUploadingFiles)),
    selectedFilesToUploadChanged: (selectedFilesToUpload) => dispatch(actions.selectedFilesToUploadChanged(selectedFilesToUpload)),
    uploadFilesToFolder: (csrfToken, team, confirm, files, folder, dragged=false, directoryPresent=false) => dispatch(actions.uploadFilesToFolder(dispatch, csrfToken, team, confirm, files, folder, dragged, directoryPresent)),
    fetchGuestsAndPromotersForPermissions: (team, confirmId) => dispatch(actions.fetchGuestsAndPromotersForPermissions(dispatch, team, confirmId)),
    targetFolderSearchTermChanged: (targetFolderSearchTerm) => dispatch(actions.targetFolderSearchTermChanged(targetFolderSearchTerm)),
    calendarEventAttachmentToCopyChanged: (calendarEventAttachmentToCopy) => dispatch(actions.calendarEventAttachmentToCopyChanged(calendarEventAttachmentToCopy)),
    createCalendarEventAttachmentCopy: (csrfToken, team, folder, calendarEventAttachment) => dispatch(actions.createCalendarEventAttachmentCopy(dispatch, csrfToken, team, folder, calendarEventAttachment)),
    draggedFilesToUploadChanged: (draggedFilesToUpload) => dispatch(actions.draggedFilesToUploadChanged(draggedFilesToUpload)),
    folderChanged: (folder) => dispatch(actions.folderChanged(folder))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(FolderView);
