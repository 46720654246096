import React from "react";
import Tippy from '@tippyjs/react';

import {
  PUBLISH_MENU_ITEM,
  CUSTOMIZE_MENU_ITEM
} from "../constants/confirmTicketingConstants";

const headerStyle = {
  fontSize: "22px",
  fontWeight: "strong",
  marginBottom: "20px"
};

const footerStyle = {
  width: "100%"
};

const buttonContainerStyle = {
  height: "130px"
};

const Promotions = ({
  csrfToken,
  team,
  confirm,
  confirmChanged,
  autoSaveConfirm,
  performers,
  activeMenuItemChanged
}) => {
  const isDisabled = (
    performers.length === 0
      || !confirm.is_public
  );

  return (
    <div className="row">
      <div className="col-12"
            style={{
              "borderBottom": "1px solid #F6F6F6",
              "marginBottom": "40px",
            }}>
        <h3 style={headerStyle}>
          <strong>
            Promote Your Event
          </strong>
        </h3>
      </div>
      <div className="col-12 d-flex justify-content-between align-items-center"
            style={{
              cursor: (isDisabled ? "not-allowed" : "pointer")
            }}>
        <label htmlFor="confirm-promote-on-bandsintown">
          <p className="mb-0" style={{"fontSize": "14px"}}>
            <strong>Bandsintown</strong>
          </p>
          <p className="mb-0 small">
            Send this event to Bandsintown, allowing fans of this artist to discover this event.
          </p>
        </label>
        {isDisabled ? (
          <Tippy content={
                    <div style={{
                          "fontSize": "12px"
                        }}>
                      Only public events with performers can be promoted on Bandsintown.
                    </div>
                  }
                  placement={'top'}
                  maxWidth={200}
                  animation={'shift-away'}
                  theme='material'>
            <div className="custom-control custom-switch custom-switch-md">
              <input type="checkbox"
                      className="custom-control-input"
                      disabled={true}
                      checked={false}
                      id="confirm-promote-on-bandsintown" />
              <label className="custom-control-label"
                      htmlFor="confirm-promote-on-bandsintown">
                &nbsp;
              </label>
            </div>
          </Tippy>
        ) : (
          <div className="custom-control custom-switch custom-switch-md">
            <input type="checkbox"
                    className="custom-control-input"
                    checked={
                      confirm.promote_on_bandsintown
                    }
                    onChange={
                      (e) => {
                        var updated = {
                          ...confirm,
                          promote_on_bandsintown: e.target.checked
                        };

                        confirmChanged(updated);
                        autoSaveConfirm(csrfToken, team, updated);
                      }
                    }
                    id="confirm-promote-on-bandsintown" />
            <label className="custom-control-label"
                    htmlFor="confirm-promote-on-bandsintown">
              &nbsp;
            </label>
          </div>
        )}
      </div>
      <div className="row" style={footerStyle}>
        <div className="col-12 d-flex justify-content-end align-items-end" style={buttonContainerStyle}>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();

                  if(team.can_use_festival_widget){
                    activeMenuItemChanged(CUSTOMIZE_MENU_ITEM);
                  } else {
                    activeMenuItemChanged(PUBLISH_MENU_ITEM);
                  }
                }
              }
              className={"btn btn-primary"}>
            <strong>Next</strong>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
