/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';
import {stateToHTML} from 'draft-js-export-html';
import { v4 as uuidv4 } from 'uuid';

import {SettlementMath} from "../../SettlementMath/SettlementMath";

import {
  OFFER_ADDITIONAL_TERMS_CHANGED_FROM_HTML,
  ADD_TICKET_LINE_ITEM,
  ADD_INCOME_LINE_ITEM,
  ADD_BEFORE_ADJUSTED_DEDUCTIONS_LINE_ITEM,
  ADD_EXPENSES_LINE_ITEM,
  UPDATE_OFFER,
  UPDATE_BUDGET,
  UPDATE_CALCULATED,
  UPDATE_MODAL_UI_STATE,
  IS_SAVING_CHANGED,
  IS_CREATING_TEMPLATE_CHANGED,
  TEMPLATE_SAVED,
  TEMPLATE_UPDATED,
  TEMPLATE_APPLIED,
  TEMPLATE_REMOVED,
  BUDGET_TEMPLATE_NAME_CHANGED,
  BUDGET_TEMPLATE_CHANGED,
  OFFER_TO_LINK_CHANGED,
  BUDGET_BONUS_ADDED,
} from '../constants/offerEditUIConstants';

const parseFloatFromString = (value) => {
  if(typeof(value) === "number") {
    return value;
  } else if(value === "") {
    return 0.0
  }

  return parseFloat(value.replace(/,/g, ''));
}

export const updateOfferAdditionalTermsFromHTML = (html) => ({
  type: OFFER_ADDITIONAL_TERMS_CHANGED_FROM_HTML,
  html
});

export const updateOffer = (updated) => ({
  type: UPDATE_OFFER,
  updated
});

const performerAttributes = (offer) => {
  if(!offer.savePerformer) {
    return {};
  }

  if(offer.calendar_event?.performers?.some(p => p.id === offer.performer?.id)) {
    return {
      performer_id: offer.performer.id,
      performer_attributes: {
        id: offer.performer.id,
        act_type: offer.performer.act_type,
      }
    }
  } else if(offer.performer?._destroy) {
    return {
      performer_id: null,
      primary_performer_attributes: {
        id: offer.primary_performer?.id,
        _destroy: true
      }
    }
  } else if(offer.calendar_event) {
    return {
      performer_attributes: {
        artistable_id: offer.performer?.artistable?.id,
        artistable_type: "Artist",
        performable_id: offer.calendar_event?.id,
        performable_type: "CalendarEvent",
        act_type: offer.performer?.act_type
      }
    }
  } else {
    return {
      primary_performer_attributes: {
        id: offer.primary_performer?.id,
        artistable_id: offer.primary_performer?.artistable?.id,
        artistable_type: "Artist",
        performable_id: offer.id,
        performable_type: "Offer",
        act_type: offer.primary_performer?.act_type,
        _destroy: offer.primary_performer?._destroy
      }
    }
  }
}

export const autoSaveOffer = (dispatch, csrfToken, team, offer, additionalOfferTerms) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.patch("/teams/" + team.id + "/offers/" + offer.id, {
        offer: {
          ...performerAttributes(offer),
          additional_terms: stateToHTML(additionalOfferTerms.getCurrentContent()),
          display_currency_conversion_for_offer: offer.display_currency_conversion_for_offer,
          display_forecast: offer.display_forecast,
        }
      })
      .then(({ data }) => {
        var updated = {
          ...offer,
          performer_id: data.performer_id,
          performer: data.performer,
          primary_performer: data.primary_performer
        }

        if(offer.calendar_event) {
          updated.calendar_event = {
            ...offer.calendar_event,
            performers: data.calendar_event?.performers
          }
        }

        dispatch(updateOffer(updated));

        toast.success("The offer has been automatically saved.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
        // dispatch(attachmentAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const createTicketBudgetLineItem = (dispatch, csrfToken, team, budget, position) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/budget_line_items", {
        budget_line_item: {
          budget_id: budget.id,
          category: "TICKET",
          name: ("Ticket #" + position),
          uuid: uuidv4(),
          operator: "*",
          left_hand: 0,
          left_hand_forecast: 0,
          right_hand: 0,
          right_hand_forecast: 0,
          available: 0,
          comps: 0,
          kills: 0,
        }
      })
      .then(({ data }) => {
        dispatch(addTicketLineItem(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const addTicketLineItem = (ticketLineItem) => ({
  type: ADD_TICKET_LINE_ITEM,
  ticketLineItem
});

export const createIncomeBudgetLineItem = (dispatch, csrfToken, team, budget, position) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/budget_line_items", {
        budget_line_item: {
          budget_id: budget.id,
          category: "INCOME",
          name: ("Income #" + position),
          uuid: uuidv4(),
          operator: "*",
          left_hand: 0,
          left_hand_forecast: 0,
          right_hand: 0,
          right_hand_forecast: 0
        }
      })
      .then(({ data }) => {
        dispatch(addIncomeLineItem(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const addIncomeLineItem = (incomeLineItem) => ({
  type: ADD_INCOME_LINE_ITEM,
  incomeLineItem
});

export const createBeforeAdjustedDeductionsBudgetLineItem = (dispatch, csrfToken, team, budget, position) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/budget_line_items", {
        budget_line_item: {
          budget_id: budget.id,
          category: "BEFORE_ADJUSTED_DEDUCTIONS",
          name: ("Before Adjusted Deduction #" + position),
          uuid: uuidv4(),
          calc_type: "per_tickets_sold",
          operator: "*",
          left_hand: 0,
          left_hand_forecast: 0,
          right_hand: 0,
          right_hand_forecast: 0
        }
      })
      .then(({ data }) => {
        dispatch(addBeforeAdjustedDeductionsLineItem(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const addBeforeAdjustedDeductionsLineItem = (beforeAdjustedDeductionsLineItem) => ({
  type: ADD_BEFORE_ADJUSTED_DEDUCTIONS_LINE_ITEM,
  beforeAdjustedDeductionsLineItem
});

export const createExpensesBudgetLineItem = (dispatch, csrfToken, team, budget, position) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/budget_line_items", {
        budget_line_item: {
          calc_type: "fixed",
          budget_id: budget.id,
          category: "EXPENSES",
          name: ("Expense #" + position),
          uuid: uuidv4(),
          operator: "*",
          left_hand: 0,
          left_hand_forecast: 0,
          right_hand: 0,
          right_hand_forecast: 0
        }
      })
      .then(({ data }) => {
        dispatch(addExpensesLineItem(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const addExpensesLineItem = (expensesLineItem) => ({
  type: ADD_EXPENSES_LINE_ITEM,
  expensesLineItem
});

export const deleteBudgetLineItem = (dispatch, csrfToken, team, budgetLineItem) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.delete("/teams/" + team.id + "/budget_line_items/" + budgetLineItem.id)
      .then(({ data }) => {
        // ...
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const autoSaveBudgetLineItem = (dispatch, csrfToken, team, budgetLineItem) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.patch("/teams/" + team.id + "/budget_line_items/" + budgetLineItem.id, {
        budget_line_item: {
          category: budgetLineItem.category,
          show_in_offer: budgetLineItem.show_in_offer,
          name: budgetLineItem.name,
          left_hand: parseFloatFromString(budgetLineItem.left_hand),
          left_hand_forecast: parseFloatFromString(budgetLineItem.left_hand_forecast),
          right_hand: parseFloatFromString(budgetLineItem.right_hand),
          right_hand_forecast: parseFloatFromString(budgetLineItem.right_hand_forecast),
          operator: budgetLineItem.operator,
          calc_type: budgetLineItem.calc_type,
          available: budgetLineItem.available || 0,
          comps: budgetLineItem.comps || 0,
          kills: budgetLineItem.kills || 0,
        }
      })
      .then(({ data }) => {
        // dispatch(attachmentAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const updateBudget = (dispatch, updated) => {
  return dispatch => {
    var calculator = new SettlementMath(updated);
    var calculated = calculator.calculate();

    dispatch(broadcastUpdateBudget(updated));
    dispatch(updateCalculated(calculated));
  };
};

export const broadcastUpdateBudget = (updated) => ({
  type: UPDATE_BUDGET,
  updated
});

export const autoSaveBudget = (dispatch, csrfToken, team, budget) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.patch("/teams/" + team.id + "/budgets/" + budget.id, {
        budget: {
          dollar_amount: (budget.dollar_amount ? parseFloatFromString(budget.dollar_amount) : ""),
          dollar_or_percentage_operator: budget.dollar_or_percentage_operator,
          upside_percentage: (budget.upside_percentage ? parseFloatFromString(budget.upside_percentage) : ""),
          has_facility_maintenance_fee: budget.has_facility_maintenance_fee,
          facility_maintenance_fee: (budget.facility_maintenance_fee_with_two_decimals ? parseFloatFromString(budget.facility_maintenance_fee_with_two_decimals) : 0),
          has_charity_deduction: budget.has_charity_deduction,
          charity_deduction: (budget.charity_deduction_with_two_decimals ? parseFloatFromString(budget.charity_deduction_with_two_decimals) : 0),
          has_merchandising: !!(budget.soft_merch_rate || budget.hard_merch_rate),
          soft_merch_rate: (budget.soft_merch_rate ? parseFloatFromString(budget.soft_merch_rate) : ""),
          soft_merch_sold_by: budget.soft_merch_sold_by,
          soft_merch_notes: budget.soft_merch_notes,
          hard_merch_rate: (budget.hard_merch_rate ? parseFloatFromString(budget.hard_merch_rate) : ""),
          hard_merch_sold_by: budget.hard_merch_sold_by,
          hard_merch_notes: budget.hard_merch_notes,
          addl_merch_notes: budget.addl_merch_notes,
          promoter_profit: budget.promoter_profit,
          promoter_profit_percentage: budget.promoter_profit ? budget.promoter_profit_percentage : 0.0,
          deposits_attributes: budget.deposits.map(deposit => {
            return {
              id: typeof(deposit.id) === 'number' ? deposit.id : null,
              team_id: team.id,
              deposit_type: deposit.deposit_type,
              value: parseFloat(deposit.value?.replace(/,/g, '')),
              due_date_type: deposit.due_date_type,
              specific_due_date: deposit.specific_due_date,
              relative_due_date: deposit.relative_due_date,
              _destroy: deposit._destroy
            }
          }),
        }
      })
      .then(({ data }) => {
        dispatch(
          updateBudget(dispatch, {
            ...budget,
            dollar_amount: data.dollar_amount,
            dollar_or_percentage_operator: data.dollar_or_percentage_operator,
            has_merchandising: data.has_merchandising,
            soft_merch_rate: data.soft_merch_rate,
            soft_merch_sold_by: data.soft_merch_sold_by,
            soft_merch_notes: data.soft_merch_notes,
            hard_merch_rate: data.hard_merch_rate,
            hard_merch_sold_by: data.hard_merch_sold_by,
            hard_merch_notes: data.hard_merch_notes,
            addl_merch_notes: data.addl_merch_notes,
            promoter_profit: data.promoter_profit,
            promoter_profit_percentage: data.promoter_profit_percentage,
            deposits: budget.deposits.map((deposit, i) => {
              return {
                ...deposit,
                id: data.deposits[i].id
              }
            })
          })
        )
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const acceptOffer = (dispatch, team, offerId, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/offers/" + offerId + "/accept")
      .then(({ data }) => {
        dispatch(updateOffer(data));
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const declineOffer = (dispatch, team, offerId, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/offers/" + offerId + "/decline")
      .then(({ data }) => {
        dispatch(updateOffer(data));
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const deleteOffer = (dispatch, team, offerId, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/offers/" + offerId)
      .then(({ data }) => {
        window.location.replace("/teams/" + team.id + "/offers");
      })
  };
};

export const updateCalculated = (updated) => ({
  type: UPDATE_CALCULATED,
  updated
});

export const updateModalUIState = (modalUIState) => ({
  type: UPDATE_MODAL_UI_STATE,
  modalUIState
});

export const isSavingChanged = (isSaving) => ({
  type: IS_SAVING_CHANGED,
  isSaving
});

export const isCreatingTemplateChanged = (isCreatingTemplate) => ({
  type: IS_CREATING_TEMPLATE_CHANGED,
  isCreatingTemplate
});

export const submitBudgetTemplate = (dispatch, csrfToken, team, offer, budget, budgetTemplate, additionalOfferTerms) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    var ticketLineItems = budget.ticket_line_items.map((tli) => {
      return {
        uuid: uuidv4(),
        show_in_offer: tli.show_in_offer,
        name: tli.name,
        operator: tli.operator,
        available: Number(tli.available),
        comps: Number(tli.comps),
        kills: Number(tli.kills),
        left_hand: parseFloatFromString(tli.left_hand),
        left_hand_forecast: parseFloatFromString(tli.left_hand_forecast),
        right_hand: parseFloatFromString(tli.right_hand),
        right_hand_forecast: parseFloatFromString(tli.right_hand_forecast),
      };
    });

    var incomeLineItems = budget.income_line_items.map((ili) => {
      return {
        uuid: uuidv4(),
        show_in_offer: ili.show_in_offer,
        name: ili.name,
        operator: ili.operator,
        left_hand: parseFloatFromString(ili.left_hand),
        left_hand_forecast: parseFloatFromString(ili.left_hand_forecast),
        right_hand: parseFloatFromString(ili.right_hand),
        right_hand_forecast: parseFloatFromString(ili.right_hand_forecast)
      };
    });

    var beforeAdjustedDeductionsLineItems = budget.before_adjusted_deductions_line_items.map((badi) => {
      return {
        uuid: uuidv4(),
        show_in_offer: badi.show_in_offer,
        name: badi.name,
        operator: badi.operator,
        left_hand: parseFloatFromString(badi.left_hand),
        left_hand_forecast: parseFloatFromString(badi.left_hand_forecast),
        right_hand: parseFloatFromString(badi.right_hand),
        right_hand_forecast: parseFloatFromString(badi.right_hand_forecast),
        calc_type: badi.calc_type
      };
    });

    var expenseLineItems = budget.expense_line_items.map((eli) => {
      return {
        uuid: uuidv4(),
        show_in_offer: eli.show_in_offer,
        name: eli.name,
        operator: eli.operator,
        left_hand: parseFloatFromString(eli.left_hand),
        left_hand_forecast: parseFloatFromString(eli.left_hand_forecast),
        right_hand: parseFloatFromString(eli.right_hand),
        right_hand_forecast: parseFloatFromString(eli.right_hand_forecast),
        calc_type: eli.calc_type
      };
    });

    var budgetBonuses = budget.budget_bonuses.map((budgetBonus) => {
      return {
        ticket_count_requirement: (budgetBonus.ticket_count_requirement ? parseFloatFromString(budgetBonus.ticket_count_requirement) : ""),
        dollar_or_percentage_operator: budgetBonus.dollar_or_percentage_operator,
        dollar_amount: (budgetBonus.dollar_amount ? parseFloatFromString(budgetBonus.dollar_amount) : ""),
        upside_percentage: (budgetBonus.upside_percentage ? parseFloatFromString(budgetBonus.upside_percentage) : "")
      };
    });

    var deposits = budget.deposits.map((deposit) => {
      return {
        team_id: team.id,
        deposit_type: deposit.deposit_type,
        value: parseFloat(deposit.value?.replace(/,/g, '')),
        due_date_type: deposit.due_date_type,
        specific_due_date: deposit.specific_due_date,
        relative_due_date: deposit.relative_due_date,
        _destroy: deposit._destroy
      }
    });

    var updating = (budgetTemplate && budgetTemplate.id);
    var method = (updating ? "patch" : "post");
    var url = (updating ? (
      "/teams/" + team.id + "/budget_templates/" + budgetTemplate.id
    ) : (
      "/teams/" + team.id + "/budget_templates"
    ));

    return axios.request({
        method: method,
        url: url,
        data: {
          budget: {
            budget_id_to_use: budget.id,
            name: budgetTemplate.name,
            dollar_amount: (budget.dollar_amount ? parseFloatFromString(budget.dollar_amount) : ""),
            dollar_or_percentage_operator: budget.dollar_or_percentage_operator,
            upside_percentage: (budget.upside_percentage ? parseFloatFromString(budget.upside_percentage) : ""),
            has_facility_maintenance_fee: budget.has_facility_maintenance_fee,
            facility_maintenance_fee: (budget.facility_maintenance_fee_with_two_decimals ? parseFloatFromString(budget.facility_maintenance_fee_with_two_decimals) : 0),
            has_charity_deduction: budget.has_charity_deduction,
            charity_deduction: (budget.charity_deduction_with_two_decimals ? parseFloatFromString(budget.charity_deduction_with_two_decimals) : 0),
            additional_terms: stateToHTML(additionalOfferTerms.getCurrentContent()),
            has_merchandising: !!(budget.soft_merch_rate || budget.hard_merch_rate),
            addl_merch_notes: budget.addl_merch_notes,
            soft_merch_rate: budget.soft_merch_rate,
            soft_merch_sold_by: budget.soft_merch_sold_by,
            soft_merch_notes: budget.soft_merch_notes,
            hard_merch_rate: budget.hard_merch_rate,
            hard_merch_sold_by: budget.hard_merch_sold_by,
            hard_merch_notes: budget.hard_merch_notes,
            ticket_line_items_attributes: ticketLineItems,
            income_line_items_attributes: incomeLineItems,
            before_adjusted_deductions_line_items_attributes: beforeAdjustedDeductionsLineItems,
            expense_line_items_attributes: expenseLineItems,
            budget_bonuses_attributes: budgetBonuses,
            deposits_attributes: deposits,
            promoter_profit: budget.promoter_profit,
            promoter_profit_percentage: (budget.promoter_profit ? parseFloatFromString(budget.promoter_profit_percentage) : 0.0),
          }
        }
      })
      .then(({ data }) => {
        (updating ? (
          dispatch(templateUpdated(data))
        ) : (
          dispatch(templateSaved(data))
        ))

        var updated = Object.assign({}, budget, {template_id: data.id});
        dispatch(broadcastUpdateBudget(updated));

        toast.success("Template saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const templateSaved = (budgetTemplate) => ({
  type: TEMPLATE_SAVED,
  budgetTemplate
});

export const templateUpdated = (budgetTemplate) => ({
  type: TEMPLATE_UPDATED,
  budgetTemplate
});

export const useBudgetTemplateWithBudget = (dispatch, csrfToken, team, budget, offer, budgetTemplateId) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/budgets/" + budget.id + "/apply_template", {
        budget_template_id: budgetTemplateId
      })
      .then(({ data }) => {
        var calculator = new SettlementMath(data);
        var calculated = calculator.calculate();

        dispatch(templateApplied(data, calculated));
        dispatch(updateBudget(dispatch, data));
        dispatch(updateOffer({
          ...offer,
          additional_terms: data.additional_terms,
        }));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const templateApplied = (budget, calculated) => ({
  type: TEMPLATE_APPLIED,
  budget,
  calculated
});

export const budgetTemplateNameChanged = (name) => ({
  type: BUDGET_TEMPLATE_NAME_CHANGED,
  name
});

export const budgetTemplateChanged = (budgetTemplate) => ({
  type: BUDGET_TEMPLATE_CHANGED,
  budgetTemplate
});

export const offerToLinkChanged = (offer) => ({
  type: OFFER_TO_LINK_CHANGED,
  offer
});

export const deleteBudgetTemplate = (dispatch, csrfToken, team, budgetTemplateId) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/budget_templates/" + budgetTemplateId)
      .then(({ data }) => {
        toast.success("Template has been deleted", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        dispatch(budgetTemplateRemoved(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const createBudgetBonus = (dispatch, csrfToken, team, budget) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/budget_bonuses", {
        budget_bonus: {
          budget_id: budget.id
        }
      })
      .then(({ data }) => {
        dispatch(budgetBonusAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const budgetBonusAdded = (budgetBonus) => ({
  type: BUDGET_BONUS_ADDED,
  budgetBonus
});

export const deleteBudgetBonus = (dispatch, csrfToken, team, budgetBonus) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.delete("/teams/" + team.id + "/budget_bonuses/" + budgetBonus.id)
      .then(({ data }) => {
        // ...
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const autoSaveBudgetBonus = (dispatch, csrfToken, team, budgetBonus) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.patch("/teams/" + team.id + "/budget_bonuses/" + budgetBonus.id, {
        budget_bonus: {
          ticket_count_requirement: (budgetBonus.ticket_count_requirement ? parseFloatFromString(budgetBonus.ticket_count_requirement) : ""),
          dollar_or_percentage_operator: budgetBonus.dollar_or_percentage_operator,
          dollar_amount: (budgetBonus.dollar_amount ? parseFloatFromString(budgetBonus.dollar_amount) : ""),
          upside_percentage: (budgetBonus.upside_percentage ? parseFloatFromString(budgetBonus.upside_percentage) : "")
        }
      })
      .then(({ data }) => {
        // ...
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};
