import PropTypes from 'prop-types';
import React from 'react';

const Canceled = ({
}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="card border-0"
             style={{
               "marginTop": "160px",
               "background": "#f9f9f9"
             }}>
          <div className="card-body text-center"
               style={{
                 "paddingTop": "66px",
                 "paddingBottom": "61px"
               }}>
            <p className="text-dark mb-2"
               style={{"fontSize": "16px"}}>
              <strong>Canceled</strong>
            </p>
            <p>
              This event is no longer available online.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex align-items-center"
               style={{
                 "marginTop": "110px",
                 "marginBottom": "70px"
               }}>
            <p className="mb-0"
               style={{"fontSize": "14px", "color": "#686868"}}>
              Powered by
              <a href="https://www.opendate.io/"
                 target="_blank">
                <img src="/opendate_logo.png"
                     className="ml-2"
                     style={{"width": "88px"}} />
              </a>
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
);

Canceled.propTypes = {
};

export default Canceled;
