import PropTypes from 'prop-types';
import React from 'react';

import {
  PREVIEW_STATE
} from '../constants/offerModalConstants';

const canSubmitForm = (offer) => {
  return (
    offer.lastSentTo
      && offer.lastSentTo.length > 0
  );
};

const OfferCustomize = ({
  team,
  offer,
  updateOfferFrontEndState,
  updateOfferEmailBody,
  csrfToken,
  sendOffer,
  closeModal,
  defaultOffer,
  dataTableEl,
  updateOfferLastSentTo
}) => (
  <div>
    <div className="row" style={{'borderBottom': "3px solid #f5f5f5", 'padding': "10px"}}>
      <div className="col-12">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     updateOfferFrontEndState(PREVIEW_STATE);
                   }
                 }
                 className="text-muted mt-2 d-inline-block">
                <i className="fa fa-long-arrow-left mr-2"></i>
                <strong>Back to preview</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className='row'>
        <div className='col-12 py-4'>
          <form onSubmit={
            (e) => {
              e.preventDefault();

              if(canSubmitForm(offer)){
                sendOffer(csrfToken, team, offer, () => {
                  closeModal(defaultOffer, dataTableEl);
                });
              }
            }
          }>
            <div className="form-group">
              <label htmlFor="offer-agent-email" className="text-muted">
                To
              </label>
              <input type="email"
                     id="offer-agent-email"
                     value={offer.lastSentTo || ""}
                     multiple={true}
                     className={"form-control"}
                     style={{'border': '2px solid #f5f5f5'}}
                     onChange={
                       (e) => updateOfferLastSentTo(e.target.value)
                     } />
            </div>
            <h5>
              <strong>Customize your message</strong>
            </h5>
            <div className="form-group">
              <textarea className="form-control"
                        value={offer.emailBody || ""}
                        onChange={
                          (e) => {
                            updateOfferEmailBody(e.target.value);
                          }
                        }
                        style={{'border': '2px solid #f5f5f5'}}
                        rows="7">
              </textarea>
            </div>
            <div className="form-group text-right">
              <button type="submit"
                      disabled={!canSubmitForm(offer)}
                      className="btn btn-primary">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
);

OfferCustomize.propTypes = {
  team: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  updateOfferFrontEndState: PropTypes.func.isRequired,
  updateOfferEmailBody: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  sendOffer: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultOffer: PropTypes.object.isRequired,
  dataTableEl: PropTypes.object,
  updateOfferLastSentTo: PropTypes.func.isRequired
};

export default OfferCustomize;
