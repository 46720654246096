import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AddToCalendar from '@culturehq/add-to-calendar';

const AddConfirmToCalendar = ({
  confirm
}) => {
  const event = {
    name: confirm.title,
    details: "", 
    location: `${confirm.venue.name}, ${confirm.venue.address_from_components}`,
    startsAt: confirm.start_time,
    endsAt: confirm.end_time_for_calendar
  };

  return (
    <AddToCalendar event={event}>
      Add to Calendar
    </AddToCalendar>
  );
};

AddConfirmToCalendar.propTypes = {
  confirm: PropTypes.object.isRequired
};

export default AddConfirmToCalendar;