import PropTypes from 'prop-types';
import React from 'react';

var _ = require('lodash');
const axios = require('axios').default;
import AsyncSelect from 'react-select/async';
import {stateToHTML} from 'draft-js-export-html';
import Popup from "reactjs-popup";
import RichEditor from '../../RichEditor/components/RichEditor';
import Tippy from '@tippyjs/react';

const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "320px",
  "padding": "0px"
}

const agentPreviewContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "420px",
  "padding": "20px 15px"
}

// https://ourcodeworld.com/articles/read/376/how-to-strip-html-from-a-string-extract-only-text-content-in-javascript
const stripHtml = (html) => {
  var temporalDivElement = document.createElement("div");
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

const canSubmitNewOutreach = (outreach) => {
  var outreachBodyHTMLText;
  if(outreach.body){
    outreachBodyHTMLText = stripHtml(stateToHTML(outreach.body.getCurrentContent()));
  }

  var canSubmitPendingOutreach = (
    outreach.venue_ownership_id
      && outreach.artist_id
      && outreach.to.length > 0
      && outreach.subject.length > 0
      && outreachBodyHTMLText
      && outreachBodyHTMLText.length > 0
  );

  var canSubmitManuallyAddedOutreach = (
    outreach.venue_ownership_id
      && outreach.artist_id
  );

  return (
    outreach.state === "pending" ? canSubmitPendingOutreach : canSubmitManuallyAddedOutreach
  );
}

const canSubmitNewOutreachTemplate = (outreach, outreachTemplateName) => {
  var outreachBodyHTMLText;
  if(outreach.body){
    outreachBodyHTMLText = stripHtml(stateToHTML(outreach.body.getCurrentContent()));
  }

  return (
    outreachBodyHTMLText
      && outreachBodyHTMLText.length > 0
      && outreachTemplateName.length > 0
  );
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        callback(response.data);
      })
  });
}, 250);

const debouncedAgentPreviewsFetch = _.debounce((fetchAgentPreviews, team, to) => {
  fetchAgentPreviews(team, to);
}, 250);

const OutreachModal = ({
  csrfToken,
  team,
  outreach,
  artist,
  outreachChanged,
  outreachBodyChanged,
  venueOwnerships,
  outreachVenueOwnershipIdChanged,
  outreachToChanged,
  outreachCcChanged,
  outreachBccChanged,
  outreachSubjectChanged,
  submitOutreachForm,
  addAgentEmailToTos,
  removeAgentEmailFromTos,
  outreachTemplates,
  selectedOutreachTemplate,
  selectedOutreachTemplateChanged,
  fetchOutreachTemplate,
  outreachTemplateName,
  outreachTemplateNameChanged,
  createOutreachTemplate,
  canChangeArtist,
  outreachArtistChanged,
  onOutreachCreate,
  outreachStateChanged,
  agentPreviews,
  fetchAgentPreviews,
  onClose
}) => {
  return (
    <div className="popup-modal-container">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-8">
          <h4>
            <strong>
              {canChangeArtist ? (
                "Create new outreach"
              ) : (
                <span>Outreach to {artist.name}</span>
              )}
            </strong>
          </h4>
          <p className="text-muted">
            {outreach.state === "added_manually" ? (
              "Select an artist to log your manual outreach."
            ) : canChangeArtist ? (
              "Select an artist and compose a message. Opendate will track opens and clicks for this message."
            ) : (
              <span>Compose a message that will be sent to representation for {artist.name}. Opendate will track opens and clicks for this message.</span>
            )}
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-4 pb-3">
          <div className="row">
            <div className="col-12">
              <div className="custom-control custom-checkbox float-left float-md-right">
                <h4>
                  <input type="checkbox"
                         className="custom-control-input"
                         value={outreach.state === "added_manually"}
                         onChange={
                           (e) => {
                             if(e.target.checked){
                               outreachStateChanged("added_manually");
                             } else {
                               outreachStateChanged("pending");
                             }
                           }
                         }
                         id="outreach-log-manual-outreach" />
                  <label className="custom-control-label"
                         htmlFor="outreach-log-manual-outreach">
                    Log manual outreach
                    &nbsp;
                    <Tippy content={
                             "Select this option when you want to record an outreach to an artist that has happened outside of Opendate."
                           }
                           animation={'shift-away'}>
                      <i className="fas fa-info-circle text-muted"></i>
                    </Tippy>
                  </label>
                </h4>
              </div>
            </div>
            {outreach.state === "pending" ? (
              <div className="col-12">
                <select className="form-control form-control-sm text-muted small float-left float-md-right"
                        style={{"borderRadius": "8px", "border": "2px solid #f5f5f5"}}
                        value={Object.keys(selectedOutreachTemplate).length > 0 ? selectedOutreachTemplate.id : ""}
                        onChange={
                          (e) => {
                            var outreachTemplateId = parseInt(e.target.value);
                            var outreachTemplate = outreachTemplates.find((outreachTemplate) => {
                              return outreachTemplate.id === outreachTemplateId;
                            });

                            fetchOutreachTemplate(team, outreachTemplate);
                            selectedOutreachTemplateChanged(outreachTemplate);
                          }
                        }
                        id="outreach-template">
                  <option value="" disabled={true}>
                    Choose Outreach template
                  </option>
                  {outreachTemplates.sort((a, b) => a.name.localeCompare(b.name)).map((outreachTemplate, index) => (
                    <option key={index} value={outreachTemplate.id}>
                      {outreachTemplate.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form onSubmit={
            (e) => {
              e.preventDefault();

              if(canSubmitNewOutreach(outreach)){
                submitOutreachForm(csrfToken, team, outreach, onOutreachCreate);
              }
            }
          }>
            {canChangeArtist ? (
              <div className="form-row">
                <div className="col-12 pb-1">
                  <label className="text-muted"
                         htmlFor="outreach-artist">
                    Artist
                  </label>
                  <AsyncSelect cacheOptions
                               styles={selectStyles}
                               menuPortalTarget={document.querySelector('body')}
                               defaultOptions
                               id="outreach-artist"
                               onChange={(option) => {
                                 var venueOwnership = venueOwnerships.find((venueOwnership) => {
                                   return venueOwnership.id === outreach.venue_ownership_id;
                                 });

                                 outreachArtistChanged(option, venueOwnership);
                               }}
                               value={ artist && artist.id ? artist : null }
                               placeholder={"Search artists..."}
                               loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback)} />
                </div>
              </div>
            ) : null}
            {outreach.state === "added_manually" && artist && artist.id ? (
              <div className="form-row py-3">
                <div className="col-12">
                  <div className='card venue-card no-border mb-0'>
                    <div className='card-body p-2'>
                      <div className="row">
                        <div className='col'>
                          <img src={artist.image_thumb_url_for_json}
                               className="img-fluid rounded-circle mr-2"
                               style={{"height": "40px", "width": "40px"}} />
                          <strong title={artist.name}>
                            {artist.name}
                          </strong>
                        </div>
                        <div className="col-xs-auto d-flex" style={{"paddingRight": "15px"}}>
                          {canChangeArtist ? (
                            <a href="#"
                               className="text-muted align-self-center"
                               onClick={
                                 (e) => {
                                   e.preventDefault();

                                   var venueOwnership = venueOwnerships.find((venueOwnership) => {
                                     return venueOwnership.id === outreach.venue_ownership_id;
                                   });

                                   outreachArtistChanged({}, venueOwnership);
                                 }
                               }>
                              <i className="far fa-times-circle"></i>
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {outreach.state === "pending" || (outreach.state === "added_manually" && artist && artist.id) ? (
              <div className="form-row">
                <div className="col-12 col-md-6 col-lg-4">
                  <label className="text-muted"
                         htmlFor="outreach-from">
                    {outreach.state === "pending" ? "From" : "Venue"}
                  </label>
                  <select className="form-control form-control-sm"
                          style={
                            {
                              "borderRadius": "8px",
                              "border": "2px solid #e9f3f8",
                              "fontWeight": "bold",
                              "background": "#f4f9fc",
                              "color": "#1982C4"
                            }
                          }
                          value={outreach.venue_ownership_id || ""}
                          onChange={
                            (e) => {
                              var venueOwnershipId = parseInt(e.target.value);
                              var venueOwnership = venueOwnerships.find((venueOwnership) => {
                                return venueOwnership.id === venueOwnershipId;
                              });

                              outreachVenueOwnershipIdChanged(venueOwnership, artist);
                            }
                          }
                          id="outreach-from">
                    {venueOwnerships.sort((a, b) => a.venueable.name.localeCompare(b.venueable.name)).map((venueOwnership, index) => (
                      <option key={index} value={venueOwnership.id}>
                        {venueOwnership.venueable.name}
                      </option>
                    ))}
                  </select>
                </div>
                {outreach.state !== "added_manually" ? (
                  <div className="col-12 col-md-6 col-lg-8 text-left text-md-right">
                    <label className="d-block">&nbsp;</label>
                    {agentPreviews.map((agent, index) => (
                      <Popup arrow={true}
                             key={index}
                             offsetY={5}
                             position="bottom right"
                             contentStyle={agentPreviewContentStyle}
                             on={"hover"}
                             trigger={open => (
                         <span style={{"borderRadius": "8px", "fontSize": "12px"}}
                               className="badge badge-genre p-2 m-1">
                           <span className="text-muted">
                             {agent.name}
                           </span>
                           <i className="fas fa-info-circle pl-2"></i>
                         </span>
                      )} >
                        {close => (
                          <div className='row text-left' style={{"maxHeight": "230px", "overflowY": "scroll"}}>
                            <div className='col-6'>
                              <p className="strong mb-0 text-overflow-ellipsis"
                                 title={agent.name}>
                                {agent.name}
                              </p>
                              <p className="mb-0 text-overflow-ellipsis"
                                 title={agent.email}>
                                {agent.email}
                              </p>
                              <p className="mb-0 text-overflow-ellipsis"
                                 title={agent.agency.name}>
                                {agent.agency.name}
                              </p>
                            </div>
                            <div className="col-6"
                                 style={{"paddingRight": "15px"}}>
                              <div className="card no-border no-shadow mb-0 h-100 d-flex"
                                   style={{"background": "#f5f5f5", "borderRadius": "4px"}}>
                                <div className="card-body p-3 align-self-center">
                                  <p className="text-muted mb-0 extra-small">LAST OUTREACH</p>
                                  <p className="mb-0">
                                    {agent.human_last_outreach_at ? agent.human_last_outreach_at : "--"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <hr className="card-separator my-2" />
                              <p className="text-muted extra-small">ARTISTS REPRESENTED</p>
                              {agent.artists.map((artist, index) => (
                                <span key={index}
                                      className="pt-1 d-inline-block"
                                      style={{"paddingRight": "0.5rem", "paddingBottom": "0.5rem"}}>
                                  <img className="img-fluid rounded-circle tiny-avatar mr-1"
                                       src={artist.image_thumb_url_for_json} />
                                  <a className="text-dark small strong"
                                     target={"_blank"}
                                     href={"/artists/" + artist.permalink}>
                                    {artist.name}
                                  </a>
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </Popup>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
            {outreach.state === "pending" ? (
              <div>
                {artist.agents && artist.agents.length > 1 ? (
                  <div className="form-row pt-3 pb-1">
                    <div className="col-12 small">
                      Multiple agents found.
                      <Popup arrow={false}
                             offsetY={5}
                             position="bottom left"
                             contentStyle={settingsContentStyle}
                             onOpen={
                               (e) => {
                                 e.preventDefault();
                               }
                             }
                             trigger={open => (
                         <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();
                              }
                            }
                            className="btn btn-outline-secondary btn-outline-secondary-dropdown btn-sm ml-3 shadow-none">
                           Select an Agent
                           <i className="fas fa-caret-down pl-2"></i>
                         </a>
                      )} >
                        {close => (
                          <div className='row'>
                            <div className="col-12">
                              <table className="table table-borderless mb-0 artist-agent-table">
                                <tbody>
                                  {artist.agents.map((agent, index) => (
                                    <tr key={index}>
                                      <td style={{"padding": "6px 12px"}}>
                                        <div className="custom-control custom-checkbox">
                                          <input type="checkbox"
                                                 className="custom-control-input"
                                                 checked={agent.inToEmails || false}
                                                 onChange={
                                                   (e) => {
                                                     var changedToChecked = e.target.checked;

                                                     if(changedToChecked){
                                                       addAgentEmailToTos(agent);
                                                     } else {
                                                       removeAgentEmailFromTos(agent);
                                                     }

                                                     close();
                                                   }
                                                 }
                                                 id={"agent-" + agent.id} />
                                          <label className="custom-control-label pt-1"
                                                 htmlFor={"agent-" + agent.id}>
                                            {agent.name} - {agent.email}
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </div>
                  </div>
                ) : null}
                <div className="form-row pt-2">
                  <div className="col-12">
                    <div className="row m-0">
                      <div className="col-12"
                           style={
                             {
                               "border": "2px solid #ebebeb",
                               "borderTopLeftRadius": "8px",
                               "borderTopRightRadius": "8px"
                             }
                           }>
                        <div className="row d-flex">
                          <label htmlFor="outreach-to"
                                 style={{"paddingLeft": "15px"}}
                                 className="col-xs-auto col-form-label text-muted small align-self-end">
                            To:
                          </label>
                          <div className="col pl-1 pr-0">
                            <input type="text"
                                   value={outreach.to || ""}
                                   onChange={
                                     (e) => {
                                       outreachToChanged(e.target.value);
                                       debouncedAgentPreviewsFetch(fetchAgentPreviews, team, e.target.value);
                                     }
                                   }
                                   style={{"borderTopRightRadius": "8px"}}
                                   className="form-control border-0 shadow-none form-control-sm"
                                   id="outreach-to" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12"
                           style={
                             {
                               "borderRight": "2px solid #ebebeb",
                               "borderLeft": "2px solid #ebebeb",
                               "borderBottom": "2px solid #ebebeb"
                             }
                           }>
                        <div className="row d-flex">
                          <label htmlFor="outreach-cc"
                                 style={{"paddingLeft": "15px"}}
                                 className="col-xs-auto col-form-label text-muted small align-self-end">
                            Cc:
                          </label>
                          <div className="col pl-1 pr-0">
                            <input type="email"
                                   multiple={true}
                                   value={outreach.cc || ""}
                                   onChange={
                                     (e) => {
                                       outreachCcChanged(e.target.value);
                                     }
                                   }
                                   style={{"borderTopRightRadius": "8px"}}
                                   className="form-control border-0 shadow-none form-control-sm"
                                   id="outreach-cc" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12"
                           style={
                             {
                               "borderRight": "2px solid #ebebeb",
                               "borderLeft": "2px solid #ebebeb",
                               "borderBottom": "2px solid #ebebeb"
                             }
                           }>
                        <div className="row d-flex">
                          <label htmlFor="outreach-bcc"
                                 style={{"paddingLeft": "15px"}}
                                 className="col-xs-auto col-form-label text-muted small align-self-end">
                            Bcc:
                          </label>
                          <div className="col pl-1 pr-0">
                            <input type="email"
                                   multiple={true}
                                   value={outreach.bcc || ""}
                                   onChange={
                                     (e) => {
                                       outreachBccChanged(e.target.value);
                                     }
                                   }
                                   style={{"borderTopRightRadius": "8px"}}
                                   className="form-control border-0 shadow-none form-control-sm"
                                   id="outreach-bcc" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12"
                           style={
                             {
                               "borderRight": "2px solid #ebebeb",
                               "borderLeft": "2px solid #ebebeb",
                               "borderBottom": "2px solid #ebebeb"
                             }
                           }>
                        <div className="row d-flex">
                          <label htmlFor="outreach-subject"
                                 style={{"paddingLeft": "15px"}}
                                 className="col-xs-auto col-form-label text-muted small align-self-end">
                            Subject:
                          </label>
                          <div className="col pl-1 pr-0">
                            <input type="text"
                                   value={outreach.subject || ""}
                                   onChange={
                                     (e) => {
                                       outreachSubjectChanged(e.target.value);
                                     }
                                   }
                                   style={{"borderTopRightRadius": "8px"}}
                                   className="form-control border-0 shadow-none form-control-sm"
                                   id="outreach-subject" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <RichEditor
                      wrapperClassName={"no-top-border"}
                      editorStyle={
                        {
                          "minHeight": "160px",
                          "maxHeight": "220px"
                        }
                      }
                      editorState={outreach.body}
                      onEditorStateChange={
                        (editorState) => {
                          outreachBodyChanged(editorState);
                        }
                      } />
                  </div>
                </div>
              </div>
            ) : null}
            {outreach.errors && outreach.errors.length > 0 ? (
              <div className="form-group pt-2">
                <div className="p-3 bg-danger text-white rounded">
                  <ul className="mb-0">
                    {outreach.errors.map((error, key) =>
                      <li key={key}>{error}</li>
                    )}
                  </ul>
                </div>
              </div>
            ) : null}
            <div className="form-row pt-3">
              {outreach.state === "pending" ? (
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="input-group input-group-sm mb-3 mb-md-0">
                    <input type="text"
                           className="form-control text-muted-placeholder"
                           value={outreachTemplateName}
                           onChange={
                             (e) => {
                               outreachTemplateNameChanged(e.target.value);
                             }
                           }
                           style={
                             {
                               "border": "2px solid #f2f2f2",
                               "borderRight": "none",
                               "borderTopLeftRadius": "8px",
                               "borderBottomLeftRadius": "8px"
                             }
                           }
                           placeholder="Save as a template" />
                    <div className="input-group-append">
                      <button className="btn"
                              type="button"
                              disabled={!canSubmitNewOutreachTemplate(outreach, outreachTemplateName)}
                              onClick={
                                (e) => {
                                  e.preventDefault();

                                  if(canSubmitNewOutreachTemplate(outreach, outreachTemplateName)){
                                    createOutreachTemplate(team, outreach, outreachTemplateName, csrfToken);
                                  }
                                }
                              }
                              style={
                                {
                                  "border": "2px solid #f2f2f2",
                                  "borderLeft": "none",
                                  "borderTopRightRadius": "8px",
                                  "borderBottomRightRadius": "8px"
                                }
                              }>
                        <span style={{'color': "#1982C4"}}>Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={outreach.state === "pending" ? "col-12 col-sm-6 col-lg-8 text-right" : "col-12 text-right"}>
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       outreachChanged({});
                       onClose();
                     }
                   } >
                  <strong>Cancel</strong>
                </a>
                <button type="submit"
                        disabled={!canSubmitNewOutreach(outreach)}
                        style={{"borderRadius": "8px"}}
                        className="btn btn-primary ml-3 btn-sm">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};

OutreachModal.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  outreach: PropTypes.object,
  artist: PropTypes.object.isRequired,
  outreachChanged: PropTypes.func.isRequired,
  outreachBodyChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array,
  outreachVenueOwnershipIdChanged: PropTypes.func.isRequired,
  outreachToChanged: PropTypes.func.isRequired,
  outreachCcChanged: PropTypes.func.isRequired,
  outreachBccChanged: PropTypes.func.isRequired,
  outreachSubjectChanged: PropTypes.func.isRequired,
  submitOutreachForm: PropTypes.func.isRequired,
  addAgentEmailToTos: PropTypes.func.isRequired,
  removeAgentEmailFromTos: PropTypes.func.isRequired,
  outreachTemplates: PropTypes.array,
  selectedOutreachTemplate: PropTypes.object,
  selectedOutreachTemplateChanged: PropTypes.func.isRequired,
  fetchOutreachTemplate: PropTypes.func.isRequired,
  outreachTemplateName: PropTypes.string,
  outreachTemplateNameChanged: PropTypes.func.isRequired,
  createOutreachTemplate: PropTypes.func.isRequired,
  canChangeArtist: PropTypes.bool,
  outreachArtistChanged: PropTypes.func,
  onOutreachCreate: PropTypes.func,
  outreachStateChanged: PropTypes.func.isRequired,
  agentPreviews: PropTypes.array,
  fetchAgentPreviews: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default OutreachModal;
