import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";
import DatePicker from "react-datepicker";
import AsyncCreatableSelect from 'react-select/async-creatable';
const axios = require('axios').default;

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "190px",
  "padding": "0px",
  "zIndex": "1999"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "#FFFFFF",
    border: "2px solid #cfd4d9",
    borderRadius: "8px",
    height: "33px",
    overflowY: "hidden"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" }),
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      border: "none",
      boxShadow: 'none',
      borderRadius: "8px",
      marginTop: "-4px"
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      color: "#333333"
    };
  },
  indicatorSeparator: (styles, { data }) => {
    return {
      ...styles,
      display: "none"
    };
  },
  indicatorsContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      color: "#1982C4",
      fontWeight: "bold",
      fontSize: "16px",
      borderRadius: "8px",
      overflow: "hidden",
      ':hover': {
        cursor: "pointer"
      }
    };
  },
  dropdownIndicator: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      color: "rgba(51,51,51,.5)",
      fontWeight: "bold"
    };
  }
};

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name, permalink: data.permalink };
        });

        callback(newOptions);
      })
  });
}, 250);

const canCreatePerformer = (performer) => {
  return (
    performer
      && performer.artist
  );
}

const canAddPerformer = (performers) => {
  return !performers.some((performer) => {
    return performer.id === undefined;
  });
}

const ManageLineup = ({
  performers,
  deletePerformer,
  csrfToken,
  team,
  stagePerformerForUpdate,
  updatePerformer,
  updatePerformerActType,
  performerStartTimeChanged,
  performerEndTimeChanged,
  addNewPerformer,
  setArtistOnNewPerformer,
  createPerformer,
  confirm,
  cancelAddPerformer,
  originalPerformer,
  cancelEditPerformer,
  currentUser
}) => {
  return (
    <div>
      <div className="card-body">
        <h5 className="strong pb-3">Lineup</h5>
        {performers.length === 0 ? (
          <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 order-md-2 text-left text-md-center">
                  <i className="fal fa-microphone-alt"
                     style={{"fontSize": "120px", "color": "rgba(25, 130, 196, .5)"}}></i>
                </div>
                <div className="col-12 col-md-6 col-lg-8 order-md-1">
                  <h5>Add an Artist</h5>
                  <p className="text-muted">
                    Add an artist to your event, create an offer, and build the show schedule.
                  </p>
                  {currentUser.can_manage_performers ? (
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           addNewPerformer();
                         }
                       }
                       className="btn btn-primary">
                      <strong>Add Artist</strong>
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {performers.length > 0 ? (
        <table className="table table-borderless vertical-middle table-sm" style={{tableLayout: "fixed"}}>
          <colgroup>
            <col span={1} style={{"width": "60%"}} />
            <col span={1} style={{"width": "20%"}} />
            <col span={1} style={{"width": "20%"}} />
          </colgroup>
          <thead>
            <tr>
              <th style={{'paddingBottom': '5px', 'paddingLeft': '24px'}}>Artist</th>
              <th style={{'paddingBottom': '5px'}}>Type</th>
              <th style={{'paddingBottom': '5px', 'paddingRight': '24px'}}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {performers.sort((a, b) => a.position - b.position).map((performer, index) => (
              !performer.id ? (
                <tr key={index} style={{"background": "#f4f9fc"}}>
                  <td style={{'paddingLeft': '24px'}}>
                    <AsyncCreatableSelect cacheOptions
                                          styles={selectStyles}
                                          placeholder={"Search Artists"}
                                          menuPortalTarget={document.querySelector('body')}
                                          defaultOptions
                                          formatCreateLabel={
                                            (label) => ("Add \"" + label + "\"")
                                          }
                                          onCreateOption={
                                            (label) => {
                                              var option = {
                                                value: null,
                                                label: "Add \"" + label + "\"",
                                                name: label,
                                                permalink: null
                                              };

                                              setArtistOnNewPerformer(index, option);
                                            }
                                          }
                                          value={
                                            (performer && performer.artist && performer.artist.label ? (
                                              performer.artist
                                            ) : "")
                                          }
                                          onChange={
                                            (option) => {
                                              setArtistOnNewPerformer(index, option);
                                            }
                                          }
                                          loadOptions={
                                            (inputValue, callback) => debouncedFetch(inputValue, callback)
                                          } />
                  </td>
                  <td>
                    <select className="custom-select custom-select-sm"
                            onChange={
                              (e) => {
                                updatePerformerActType(index, e.target.value);
                              }
                            }
                            value={performer.act_type || ""}>
                      <option value="headline">Headliner</option>
                      <option value="support">Support</option>
                    </select>
                  </td>
                  <td style={{'paddingRight': '24px'}} className="text-right">
                    <a href='#'
                       onClick={
                         (e) => {
                           e.preventDefault();
                           cancelAddPerformer();
                         }
                       }>
                      Cancel
                    </a>
                    <a href='#'
                       onClick={
                         (e) => {
                           e.preventDefault();

                           if(canCreatePerformer(performer)){
                             createPerformer(csrfToken, team, confirm, performer, index);
                           }
                         }
                       }
                       style={{'borderRadius': '8px'}}
                       className={"btn btn-primary btn-sm ml-2 " + (!canCreatePerformer(performer) ? "disabled" : "")}>
                      Save
                    </a>
                  </td>
                </tr>
              ) : performer.stagedForUpdate ? (
                <tr key={index} style={{"background": "#f4f9fc"}}>
                  <td className="overflow-ellipsis" style={{'paddingLeft': '24px'}}>
                    <img src={performer.artistable.image_thumb_url_for_json}
                         className="img-fluid rounded mr-2"
                         style={{"height": "45px", "width": "45px"}} />
                    <strong title={performer.artistable.name}>
                      <a className="text-dark" href={"/artists/" + performer.artistable.permalink}>
                        {performer.artistable.name}
                      </a>
                    </strong>
                  </td>
                  <td>
                    <select className="custom-select custom-select-sm"
                            onChange={
                              (e) => {
                                updatePerformerActType(index, e.target.value);
                              }
                            }
                            value={performer.act_type || ""}>
                      <option value="headline">Headliner</option>
                      <option value="support">Support</option>
                    </select>
                  </td>
                  <td style={{'paddingRight': '24px'}} className="text-right">
                    <a href='#'
                       onClick={
                         (e) => {
                           e.preventDefault();
                           cancelEditPerformer(performer, originalPerformer);
                         }
                       }>
                      Cancel
                    </a>
                    <a href='#'
                       onClick={
                         (e) => {
                           e.preventDefault();
                           updatePerformer(csrfToken, team, performer);
                         }
                       }
                       style={{'borderRadius': '8px'}}
                       className="btn btn-primary btn-sm ml-2">
                      Save
                    </a>
                  </td>
                </tr>
              ) : (
                <tr key={index}>
                  <td className="overflow-ellipsis" style={{'paddingLeft': '24px'}}>
                    <img src={performer.artistable.image_thumb_url_for_json}
                         className="img-fluid rounded mr-2"
                         style={{"height": "45px", "width": "45px"}} />
                    <strong title={performer.artistable.name}>
                      <a className="text-dark" href={"/artists/" + performer.artistable.permalink}>
                        {performer.artistable.name}
                      </a>
                    </strong>
                  </td>
                  <td>
                    {performer.act_type ? (
                      performer.humanized_act_type
                    ) : (
                      <span className="text-muted">
                        {performer.humanized_act_type}
                      </span>
                    )}
                  </td>
                  <td style={{'paddingRight': '24px'}} className="text-right">
                    {currentUser.can_manage_performers ? (
                      <Popup arrow={false}
                             offsetY={5}
                             position="bottom right"
                             contentStyle={popupContentStyle}
                             onOpen={
                               (e) => {
                                 e.preventDefault();
                               }
                             }
                             trigger={open => (
                               <a href="#"
                                  className="btn btn-external btn-sm note-card-inline-menu">
                                 <i className="far fa-ellipsis-v"></i>
                               </a>
                             )} >
                        {close => (
                          <div className='row text-left'>
                            <div className="col-12">
                              <ul className="list-group offer-inline-menu">
                                <li className="list-group-item">
                                  <a href="#"
                                     onClick={
                                       (e) => {
                                         e.preventDefault();
                                         close();
                                         stagePerformerForUpdate(performer);
                                       }
                                     }
                                     className="text-muted">
                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                      <i className="fas fa-pencil"></i>
                                    </span>
                                    <span className="text-dark">
                                      Edit Artist
                                    </span>
                                  </a>
                                </li>
                                <li className="list-group-item delete-offer-li">
                                  <Popup
                                      trigger={
                                        <a href="#"
                                           className="text-danger">
                                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                                            <i className="fas fa-trash"></i>
                                          </span>
                                          Delete
                                        </a>
                                      }
                                      modal
                                      contentStyle={deleteModalContentStyle}
                                      closeOnDocumentClick
                                    >
                                    {closeDeleteModal => (
                                      <div>
                                        <div className="row">
                                          <div className="col">
                                            <div className="row">
                                              <div className="col">
                                                <h5 style={{"marginBottom": "16px"}}>
                                                  <strong>Delete artist?</strong>
                                                </h5>
                                              </div>
                                              <div className="col-xs-auto">
                                                <a href="#"
                                                   className="text-dark"
                                                   style={{"paddingRight": "15px"}}
                                                   onClick={
                                                     (e) => {
                                                       e.preventDefault();

                                                       close();
                                                       closeDeleteModal();
                                                     }
                                                   } >
                                                  <i className="fa fa-times"></i>
                                                </a>
                                              </div>
                                            </div>
                                            <p>Are you sure you want to remove this artist?</p>
                                            <div className="text-right">
                                              <a href="#"
                                                 onClick={
                                                   (e) => {
                                                     e.preventDefault();
                                                     close();
                                                     closeDeleteModal();
                                                   }
                                                 }>
                                                Cancel
                                              </a>
                                              <a href="#"
                                                 className="btn btn-danger ml-3"
                                                 onClick={
                                                   (e) => {
                                                     e.preventDefault();
                                                     close();
                                                     closeDeleteModal();
                                                     deletePerformer(csrfToken, team, performer);
                                                   }
                                                 }>
                                                Remove
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </Popup>
                    ) : null}
                  </td>
                </tr>
              )
            ))}
          </tbody>
        </table>
      ) : null}
      {currentUser.can_manage_performers && performers.length > 0 ? (
        <div className="card-body pt-3">
          <a href="#"
             className={canAddPerformer(performers) ? "" : "disabled"}
             onClick={
               (e) => {
                 e.preventDefault();

                 if(canAddPerformer(performers)){
                   addNewPerformer();
                 }
               }
             }>
            <i className="fas fa-plus-circle"></i>
            &nbsp;
            Add Artist
          </a>
        </div>
      ) : null}
    </div>
  )
};

ManageLineup.propTypes = {
  performers: PropTypes.array,
  deletePerformer: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  stagePerformerForUpdate: PropTypes.func.isRequired,
  updatePerformer: PropTypes.func.isRequired,
  updatePerformerActType: PropTypes.func.isRequired,
  performerStartTimeChanged: PropTypes.func.isRequired,
  performerEndTimeChanged: PropTypes.func.isRequired,
  addNewPerformer: PropTypes.func.isRequired,
  setArtistOnNewPerformer: PropTypes.func.isRequired,
  createPerformer: PropTypes.func.isRequired,
  confirm: PropTypes.object.isRequired,
  cancelAddPerformer: PropTypes.func.isRequired,
  originalPerformer: PropTypes.object,
  cancelEditPerformer: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default ManageLineup;
