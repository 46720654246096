import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const AddOnSeatingChart = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  addOns,
  visibleAddOns,
  promoCode,
  addOnsChanged,
  ticketReservation,
  postToParent,
  formatItemsForGoogleTagManager,
  seatsIOPublicKey,
  selectedObjectLabel,
  selectedObjectType,
  calculateDiscountedAddOnPrice,
  buildTicketReservation,
  addOnsToSell,
  sharedInventoryMode,
  userContext,
  discountedAddOnPrice,
  showDescription,
  displayChartText,
  displayChartButtonClass
}) => {
  const [addOnSeatsIOChart, addOnSeatsIOChartChanged] = useState({});
  const [seatsSelected, setSeatsSelected] = useState([]);
  const [displayChart, setDisplayChart] = useState(!sharedInventoryMode);
  const primaryAddOn = (sharedInventoryMode && addOnsToSell.length === 1 ? addOnsToSell[0] : null);

  const seatsIOObjectSelected = (object) => {
    setSeatsSelected((prevSeatsSelected) => {
      var updatedAddOn = addOns.find((addOn) =>
        addOn.seating_chart_category_key === object.category.key.toString()
      );

      var items = formatItemsForGoogleTagManager(
        confirm, promoCode, [], [updatedAddOn]
      );

      postToParent("ga.add_to_cart", items)
      return [...prevSeatsSelected, object];
    });
  };

  const seatsIOObjectDeselected = (object) => {
    setSeatsSelected((prevSeatsSelected) => {
      return prevSeatsSelected.filter((o) =>
        o.id !== object.id
      );
    });
  };

  useEffect(() => {
    var addOnIdsToSell = addOnsToSell.map((ao) => ao.id);

    var updatedAddOns = [...addOns].map((ao) => {
      if(!ao.seating_chart_category_key){
        return ao;
      }

      if(!addOnIdsToSell.includes(ao.id)){
        return ao;
      }

      var filteredObjects = seatsSelected.filter((o) =>
        o.category.key.toString() === ao.seating_chart_category_key
      );

      return Object.assign({}, ao, {
        seatsIOObjects: filteredObjects.map((object) => {
          return {
            seatsio_object_id: object.id,
            seat_assignment: selectedObjectLabel(object),
            seat_type: selectedObjectType(object),
            hold_token: addOnSeatsIOChart.holdToken
          }
        }),
        quantity: filteredObjects.length
      });
    });

    addOnsChanged(updatedAddOns);
    buildTicketReservation(updatedAddOns);
  }, [seatsSelected]);

  const chartAvailableCategories = (addOnsToSell, visibleAddOns) => {
    var categoryIdsToSell = addOnsToSell
      .map((ao) => ao.seating_chart_category_key);

    var visibleCategoryIds = visibleAddOns
      .filter((ao) => categoryIdsToSell.includes(ao.seating_chart_category_key))
      .map((ao) => ao.seating_chart_category_key);

    // Empty array means everything is available
    if(visibleCategoryIds.length === 0){
      var mockCategoryId = uuidv4();
      visibleCategoryIds.push(mockCategoryId);
    }

    return visibleCategoryIds;
  }

  const chartPricing = (addOnsToSell, promoCode) => {
    return addOnsToSell.map((ao) => {
      var addOnPrice = Object.keys(promoCode).length > 0 ? (
        calculateDiscountedAddOnPrice(ao, promoCode)
      ) : (
        parseFloat(ao.price)
      );

      return {
        category: ao.seating_chart_category_key,
        price: addOnPrice
      };
    })
  }

  useEffect(() => {
    if(addOnSeatsIOChart && addOnSeatsIOChart.holdToken){
      addOnSeatsIOChart.changeConfig({
        pricing: chartPricing(addOnsToSell, promoCode)
      });
    }
  }, [promoCode]);

  return (
    <div>
      <div className="card border-0"
            style={{"background": "#f9f9f9", "marginBottom": "15px"}}>
        <div className="card-body"
            style={{
              "paddingLeft": "15px",
              "paddingRight": "15px"
            }}>
          <div className="row">
            <div className="col">
              {primaryAddOn ? (
                <>
                  <p className="mb-0"
                    style={{"fontSize": "16px"}}>
                    <strong>{primaryAddOn.name}</strong>
                  </p>
                  <p className="mb-0"
                      style={{"fontSize": "16px"}}>
                    {Object.keys(promoCode).length > 0 && (calculateDiscountedAddOnPrice(primaryAddOn, promoCode) !== parseFloat(primaryAddOn.price)) ? (
                      <span>
                        <span style={{"textDecoration": "line-through"}}>{primaryAddOn.price_to_currency}</span>
                        <span className="ml-3">{discountedAddOnPrice(primaryAddOn, promoCode)}</span>
                      </span>
                    ) : (
                      <span>
                        {primaryAddOn.price_to_currency}
                      </span>
                    )}
                  </p>
                  {showDescription && primaryAddOn.description && primaryAddOn.description.length > 0 ? (
                    <div className="mb-0 mt-2 small last-p-margin-0"
                          dangerouslySetInnerHTML={{__html: primaryAddOn.simple_format_description}}
                          style={{"maxWidth": "400px"}}>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
            {sharedInventoryMode ? (
              (primaryAddOn.available_tickets == 0 || primaryAddOn.sold_out) ? (
                <div className="col d-flex align-items-center justify-content-end">
                  <p className="mb-0"
                    style={{"fontSize": "14px", "color": "#686868"}}>
                    Sold out
                  </p>
                </div>
              ) : (
                <div className='col-xs-auto'
                      style={{"paddingRight": "15px"}}>
                  {displayChart ? (
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            var requestPromises = [];

                            // In theory clearSelection should be enough, but there is some sort
                            // of order of operations problem here where the buildTicketReservation
                            // request still thinks there are items selected when it gets sent.
                            if(seatsSelected.length > 0){
                              setSeatsSelected([]);
                            }

                            var shouldDeselect = (
                              addOnSeatsIOChart
                                && addOnSeatsIOChart.clearSelection
                                && (
                                  addOnSeatsIOChart.selectedObjects.length > 0
                                    || addOnSeatsIOChart.selectedSeats.length > 0
                                )
                            );

                            if (shouldDeselect){
                              requestPromises.push(
                                addOnSeatsIOChart.clearSelection()
                              );
                            }

                            Promise.all(requestPromises)
                              .then(() => {
                                addOnSeatsIOChart.destroy();
                                setDisplayChart(!displayChart);
                              });
                          }
                        }
                        style={{
                          "fontSize": "14px",
                          "borderWidth": "1px",
                          "borderRadius": "4px"
                        }}
                        className={displayChartButtonClass}>
                      <strong>Deselect</strong>
                    </a>
                  ) : (
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            setDisplayChart(!displayChart);
                          }
                        }
                        style={{
                          "fontSize": "14px",
                          "borderWidth": "1px",
                          "borderRadius": "4px"
                        }}
                        className={displayChartButtonClass}>
                      <strong>{displayChartText}</strong>
                    </a>
                  )}
                </div>
              )
            ) : null}
          </div>
          <div className="row">
            <div className="col-12">
              {displayChart ? (
                <div className="card mb-0 mt-3"
                    style={{
                      "border": "1px solid #f3f3f3",
                      "height": "515px"
                    }}>
                  {confirm.add_on_seatsio_event_id && confirm.add_on_seatsio_event_id.length > 0 ? (
                    <SeatsioSeatingChart workspaceKey={seatsIOPublicKey}
                                        event={confirm.add_on_seatsio_event_id}
                                        availableCategories={chartAvailableCategories(addOnsToSell, visibleAddOns)}
                                        fitTo={'widthAndHeight'}
                                        maxSelectedObjects={
                                          addOnsToSell.map((ao) => {
                                            return {
                                              category: ao.seating_chart_category_key,
                                              quantity: ao.maximum_per_order
                                            };
                                          })
                                        }
                                        onRenderStarted={
                                          chart => {
                                            addOnSeatsIOChartChanged(chart);

                                            chart.changeConfig({
                                              pricing: chartPricing(addOnsToSell, promoCode)
                                            });

                                            chart.config.session = "start";
                                            chart.config.onObjectSelected = (object) => seatsIOObjectSelected(object);
                                            chart.config.onObjectDeselected = (object) => seatsIOObjectDeselected(object);
                                          }
                                        }
                                        priceFormatter={
                                          price => formatter.format(price)
                                        }
                                        region="na" />
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <div className="card border-0">
                          <div className="card-body d-flex align-items-center justify-content-center">
                            <div className="text-center">
                              <p className="mb-2"
                                style={{"fontSize": "16px"}}>
                                <strong>An error occurred.</strong>
                              </p>
                              <p className="mb-0">
                                Please try again later.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AddOnSeatingChart.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  addOns: PropTypes.array.isRequired,
  visibleAddOns: PropTypes.array,
  promoCode: PropTypes.object,
  addOnsChanged: PropTypes.func.isRequired,
  ticketReservation: PropTypes.object,
  postToParent: PropTypes.func.isRequired,
  formatItemsForGoogleTagManager: PropTypes.func.isRequired,
  seatsIOPublicKey: PropTypes.string.isRequired,
  selectedObjectLabel: PropTypes.func.isRequired,
  selectedObjectType: PropTypes.func.isRequired,
  calculateDiscountedAddOnPrice: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  addOnsToSell: PropTypes.array.isRequired,
  sharedInventoryMode: PropTypes.bool.isRequired,
  userContext: PropTypes.object,
  discountedAddOnPrice: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
  displayChartText: PropTypes.string,
  displayChartButtonClass: PropTypes.string
};

AddOnSeatingChart.defaultProps = {
  showDescription: true,
  displayChartText: "Choose your seat",
  displayChartButtonClass: "btn btn-outline-danger"
};

export default AddOnSeatingChart;
