import React, { useState } from 'react';

const ArtistPipelines = ({title, pipelines}) => {
  const [showMore, setShowMore] = useState(pipelines.length > 5 ? true : false);

  const offer_badget_status = (offer) => {
    switch(offer.state) {
      case 'draft':
        return (<span className='badge badge-info px-2 py-1'>Draft</span>)
      case 'declined':
        return (<span className='badge badge-danger px-2 py-1'>Declined</span>)
      case 'accepted':
        return (<span className='badge badge-success px-2 py-1'>Accepted</span>)
      case 'sent':
        return (<span className='badge badge-primary px-2 py-1'>Sent</span>)
    }
  }

  const pipelineItem = (pipeline, i) => {
    return (
      <React.Fragment key={i}>
        { title === 'Holds' ? (
          <div className='d-flex justify-content-between align-items-center w-100 pb-1 position-relative'>
            <span className='normal-medium-text'>
              <a href={`/teams/${pipeline.team_id}/confirms/${pipeline.id}`} className='stretched-link'>
                <div className='text-dark'>
                  { pipeline.start_date }
                </div>
              </a>
            </span>
            <div className='d-flex align-items-center'>
              <div className='font-700 p-1 px-3 text-dark'>
                H{ pipeline.position }
              </div>
              <i className='fa fa-caret-right text-primary ml-3' />
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between align-items-center w-100 pb-1 position-relative'>
            <span className='normal-medium-text'>
              <a href={ pipeline.offer_url } className='stretched-link'>
                <div className='text-dark'>
                  { pipeline.start_date }
                </div>
              </a>
            </span>
            <div className='d-flex align-items-center'>
              <div className='font-700 p-1 px-3 text-dark'>
                { offer_badget_status(pipeline) }
              </div>
              <i className='fa fa-caret-right text-primary ml-3' />
            </div>
          </div>
        )}
        { i < pipelines.length - 1 && (
          <hr className='my-1' />
        )}
      </React.Fragment>
    )
  }

  const showMoreOrLess = () => {
    setShowMore(!showMore);
  }

  return (
    <div className='card p-2'>
      <strong className="normal-number mb-3">
        { title }
      </strong>
      <div className='d-flex flex-column'>
        {pipelines.slice(0, 5).map((pipeline, i) => pipelineItem(pipeline, i)) }
        { !showMore && pipelines.slice(5).map((pipeline, i) => pipelineItem(pipeline, i)) }
        { pipelines.length > 5 && (
          <div className="row col-12">
            <button className="btn btn-link btn-sm normal-text pl-0" onClick={showMoreOrLess}>
              Show {showMore ? 'More' : 'Less'} { title }
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ArtistPipelines;
