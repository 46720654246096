/* eslint-disable import/prefer-default-export */

export const DEFAULT_TOP_HOLD_CHANGED = 'DEFAULT_TOP_HOLD_CHANGED';
export const SHOW_ACTIVITY_DEFAULTS_CHANGED = 'SHOW_ACTIVITY_DEFAULTS_CHANGED';
export const EVENT_TEMPLATES_CHANGED = 'EVENT_TEMPLATES_CHANGED';
export const EVENT_TEMPLATE_FOR_FORM_CHANGED = 'EVENT_TEMPLATE_FOR_FORM_CHANGED';
export const FINANCES_VISIBLE_CHANGED = 'FINANCES_VISIBLE_CHANGED';
export const FINANCE_CATEGORIES_CHANGED = 'FINANCE_CATEGORIES_CHANGED';
export const NOTES_VISIBLE_CHANGED = 'NOTES_VISIBLE_CHANGED';
export const NOTE_TEMPLATES_CHANGED = 'NOTE_TEMPLATES_CHANGED';
