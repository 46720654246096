import { combineReducers } from 'redux';
import {
  CONFIRM_CHANGED,
  TYPED_SEARCH_TERM_CHANGED,
  SEARCHED_SEARCH_TERM_CHANGED,
  DATA_TABLE_EL_CHANGED,
  ORDER_CHANGED,
  WIDGET_TAB_CHANGED,
  TICKETS_SOLD_WIDGET_TAB,
  REFUND_CHANGED,
  SUBMITTING_REFUND_CHANGED,
  SHOW_ORDER_SLIDING_STATE,
  SLIDING_PANE_STATE_CHANGED,
  RESEND_EMAIL_CHANGED,
  SENDING_CONFIRMATION_EMAIL_CHANGED,
  FAKE_SALES_DATA_CHANGED,
  TICKET_BUYERS_DATA_CHANGED
} from '../constants/confirmDashboardConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CHANGED:
      return action.confirm;
    default:
      return state;
  }
};

const ticketTypes = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const typedSearchTerm = (state = "", action) => {
  switch (action.type) {
    case TYPED_SEARCH_TERM_CHANGED:
      return action.typedSearchTerm;
    default:
      return state;
  }
};

const searchedSearchTerm = (state = "", action) => {
  switch (action.type) {
    case SEARCHED_SEARCH_TERM_CHANGED:
      return action.searchedSearchTerm;
    default:
      return state;
  }
};

const csvDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const order = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CHANGED:
      return action.order;
    default:
      return state;
  }
};

const widgetTab = (state = TICKETS_SOLD_WIDGET_TAB, action) => {
  switch (action.type) {
    case WIDGET_TAB_CHANGED:
      return action.widgetTab;
    default:
      return state;
  }
};

const addOnsByPrice = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const refund = (state = {}, action) => {
  switch (action.type) {
    case REFUND_CHANGED:
      return action.refund;
    default:
      return state;
  }
};

const refundReasons = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const submittingRefund = (state = false, action) => {
  switch (action.type) {
    case SUBMITTING_REFUND_CHANGED:
      return action.submittingRefund;
    default:
      return state;
  }
};

const slidingPaneState = (state = SHOW_ORDER_SLIDING_STATE, action) => {
  switch (action.type) {
    case SLIDING_PANE_STATE_CHANGED:
      return action.slidingPaneState;
    default:
      return state;
  }
};

const resendEmail = (state = "", action) => {
  switch (action.type) {
    case RESEND_EMAIL_CHANGED:
      return action.resendEmail;
    default:
      return state;
  }
};

const sendingConfirmationEmail = (state = false, action) => {
  switch (action.type) {
    case SENDING_CONFIRMATION_EMAIL_CHANGED:
      return action.sendingConfirmationEmail;
    default:
      return state;
  }
};

const cumulativeSalesData = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const fakeSalesData = (state = [], action) => {
  switch (action.type) {
    case FAKE_SALES_DATA_CHANGED:
      return action.fakeSalesData;
    default:
      return state;
  }
};

const refundOrder = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ticketBuyersData = (state = [], action) => {
  switch (action.type) {
    case TICKET_BUYERS_DATA_CHANGED:
      return action.ticketBuyersData;
    default:
      return state;
  }
}

const buyers = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const promoters = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const ticketTypesByPrice = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const canBypassRefundRestrictions = (state = false, action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const payoutInfo = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const salesData = (state = {}, action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const confirmDashboardReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  confirm,
  ticketTypes,
  typedSearchTerm,
  searchedSearchTerm,
  csvDataSource,
  dataTableEl,
  order,
  widgetTab,
  addOnsByPrice,
  refund,
  refundReasons,
  submittingRefund,
  slidingPaneState,
  resendEmail,
  sendingConfirmationEmail,
  cumulativeSalesData,
  fakeSalesData,
  refundOrder,
  ticketBuyersData,
  buyers,
  promoters,
  ticketTypesByPrice,
  canBypassRefundRestrictions,
  payoutInfo,
  salesData
});

export default confirmDashboardReducer;
