import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import DataTable from '../../DataTable/components/DataTable';
const axios = require('axios').default;
import AsyncSelect from 'react-select/async';

import jsxToString from 'jsx-to-string';
var ReactDOM = require('react-dom');
import Popup from "reactjs-popup";
import { ToastContainer } from 'react-toastify';

import $ from 'jquery';
require('datatables.net-bs4');

var _ = require('lodash');
var debouncedFetchEstimatedNumberOfSends;

const canSubmitForm = (pushNotification, isSubmitting) => {
  return (
    pushNotification
      && pushNotification.venue_ownership_id
      && pushNotification.calendar_events.length > 0
      && pushNotification.message
      && pushNotification.message.length > 0
      && !isSubmitting
  );
}

const debouncedConfirmFetch = _.debounce((inputValue, callback, team, venueOwnershipId) => {
  new Promise(resolve => {
    axios.get('/teams/' + team.id + '/calendar_events/autocomplete', {
        params: {
          name: inputValue,
          venue_ownership_id: venueOwnershipId,
          confirm_only: true,
          only_future: false
        }
      })
      .then(function (response) {
        var options = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(options);
      })
  });
}, 250);

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "15px"
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999",
    fontSize: "16px"
  }),
  control: base => ({
    ...base,
    borderRadius: "8px",
    border: "1px solid #e6e6e6"
  }),
  placeholder: base => ({ ...base, color: "#495057" }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const PushNotifications = ({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  dataTableElChanged,
  searchedSearchTerm,
  pushNotification,
  fetchNewPushNotification,
  pushNotificationChanged,
  venueOwnerships,
  createPushNotification,
  isSubmitting,
  fetchEstimatedNumberOfSends,
  estimatedNumberOfSends,
  estimatedNumberOfSendsChanged,
  hasPushNotifications
}) => {
  useEffect(() => {
    debouncedFetchEstimatedNumberOfSends = _.debounce((team, pushNotification) => {
      fetchEstimatedNumberOfSends(team, pushNotification);
    }, 250);
  }, []);

  return (
    <div>
      <ToastContainer />
      {!hasPushNotifications ? (
        <div className='row'>
          <div className='col-12'>
            <div className='card border-0 shadow-2'>
              <div className='card-body'>
                <p style={{'fontSize': '20px'}}>
                  <strong>
                    Push Messages&nbsp;
                    <span className='text-muted'>(0)</span>
                  </strong>
                </p>
              </div>
              <div className='card-body text-center' style={{'paddingBottom': '456px', "paddingTop": "11px"}}>
                <div className='dot d-flex justify-content-center align-items-center'
                      style={{'margin': '0px auto 10px auto', 'width': '88px', 'height': '88px', 'background': '#F3F9FC', 'borderRadius': '50%', 'border': '4px solid #BBE0F6'}}>
                  <i className='far fa-message-xmark text-primary' style={{'fontSize': '42px'}}></i>
                </div>
                <p className='mb-1' style={{'fontSize': '20px'}}>
                  <strong>No Push Messages</strong>
                </p>
                <p className='text-muted mb-3' style={{'maxWidth': '245px', 'margin': '0 auto'}}>
                  Create your first push message to your venue app users.
                </p>
                {currentUser.can_manage_push_notifications ? (
                  <a href="#"
                     onClick={(e) => {
                       e.preventDefault();
                       fetchNewPushNotification(csrfToken, team);
                     }}
                     className="btn btn-primary"
                     style={{'borderRadius': '4px', 'fontSize': '14px'}}>
                    &nbsp;
                    <strong>Create a Push Notification</strong>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="datatable-right-header-section">
            <a href="#"
               onClick={(e) => {
                 e.preventDefault();
                 fetchNewPushNotification(csrfToken, team);
               }}
               className="btn btn-primary">
              <i className="fal fa-plus"></i>
              &nbsp;
              <strong>New Push Notification</strong>
            </a>
          </div>
          <DataTable title={dataTableTitle}
                     dataSource={dataTableDataSource}
                     columns={dataTableColumns}
                     colGroup={
                       <colgroup>
                         <col span={1} style={{"width": "20%"}} />
                         <col span={1} style={{"width": "10%"}} />
                         <col span={1} style={{"width": "40%"}} />
                         <col span={1} style={{"width": "30%"}} />
                       </colgroup>
                     }
                     onTableElementSet={
                       (el) => {
                         dataTableElChanged(el);
                       }
                     }
                     searchedSearchTerm={searchedSearchTerm}
                     order={dataTableOrder} />
        </React.Fragment>
      )}
      <Popup
       open={Object.keys(pushNotification).length > 0}
       contentStyle={modalContentStyle}
       className={"popup-modal"}
       closeOnDocumentClick={false}
       onOpen={
         () => {
           estimatedNumberOfSendsChanged(0);
         }
       }
       onClose={() => pushNotificationChanged({})}
      >
        <div className="row popup-modal-container">
          <div className="col-12">
            <div className="row">
              <div className="col d-flex align-items-center">
                <p className="mb-0"
                   style={{"fontSize": "24px"}}>
                  <strong>New Push Notification</strong>
                </p>
              </div>
              <div className="col-xs-auto d-flex align-items-center"
                   style={{"paddingRight": "15px"}}>
                <a onClick={
                     (e) => {
                       e.preventDefault();
                       pushNotificationChanged({});
                     }
                   }
                   className="text-dark"
                   href="#">
                  <i className="fal fa-times" style={{"fontSize": "24px"}}></i>
                </a>
              </div>
            </div>
            <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(!canSubmitForm(pushNotification, isSubmitting)){
                        return false;
                      }

                      createPushNotification(csrfToken, team, pushNotification, dataTableEl);
                    }
                  }>
              <div className="row" style={{"marginTop": "28px"}}>
                <div className="col-12 col-md-6 col-lg-8">
                  <div className="form-group">
                    <label htmlFor={"push-notification-venue-ownership"}
                           className="text-muted mb-1"
                           style={{"fontSize": "14px"}}>
                      Venue
                      <span className="text-danger">&nbsp;*</span>
                    </label>
                    <select className="form-control"
                            style={{"border": "1px solid #e6e6e6"}}
                            onChange={
                              (e) => {
                                var updated = Object.assign({}, pushNotification, {
                                  venue_ownership_id: e.target.value,
                                  calendar_events: []
                                });

                                debouncedFetchEstimatedNumberOfSends(team, updated);
                                pushNotificationChanged(updated);
                              }
                            }
                            id={"push-notification-venue-ownership"}
                            value={pushNotification.venue_ownership_id || ""}>
                      <option value="" disabled>Select venue</option>
                      {venueOwnerships.map((venueOwnership, index) =>
                        <option key={index} value={venueOwnership.id}>
                          {venueOwnership.venueable.name}
                        </option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-8">
                  <div className="form-group">
                    <label htmlFor={"push-notification-confirm"}
                           className="text-muted mb-1"
                           style={{"fontSize": "14px"}}>
                      Events
                      <span className="text-danger">&nbsp;*</span>
                    </label>
                    <AsyncSelect isMulti={true}
                                 cacheOptions={false}
                                 placeholder={"Enter an event name..."}
                                 isClearable={true}
                                 styles={selectStyles}
                                 isDisabled={!pushNotification.venue_ownership_id}
                                 menuPortalTarget={document.querySelector('body')}
                                 defaultOptions={false}
                                 id="push-notification-confirm"
                                 onChange={
                                   (option) => {
                                     var updated = {...pushNotification, calendar_events: (option || [])}

                                     debouncedFetchEstimatedNumberOfSends(team, updated);
                                     pushNotificationChanged(updated);
                                   }
                                 }
                                 value={pushNotification.calendar_events || ""}
                                 loadOptions={
                                   (inputValue, callback) => {
                                     return debouncedConfirmFetch(inputValue, callback, team, pushNotification.venue_ownership_id);
                                   }
                                 } />
                    <p className="mb-0 small text-muted">
                      {estimatedNumberOfSends.toLocaleString("en-US")} device(s)
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-8">
                  <div className="form-group">
                    <label htmlFor={"push-notification-subtitle"}
                           className="text-muted mb-1"
                           style={{"fontSize": "14px"}}>
                      Subtitle
                    </label>
                    <input text="email"
                           className="form-control"
                           style={{"border": "1px solid #e6e6e6"}}
                           value={pushNotification.subtitle || ""}
                           onChange={
                             (e) => {
                               var updated = Object.assign({}, pushNotification, {
                                 subtitle: e.target.value
                               });

                               pushNotificationChanged(updated);
                             }
                           }
                           id="push-notification-subtitle" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={"push-notification-message"}
                       className="text-muted mb-1"
                       style={{"fontSize": "14px"}}>
                  Message
                  <span className="text-danger">&nbsp;*</span>
                </label>
                <textarea className="form-control"
                          style={{"border": "1px solid #e6e6e6"}}
                          value={pushNotification.message || ""}
                          onChange={
                            (e) => {
                              var updated = Object.assign({}, pushNotification, {
                                message: e.target.value
                              });

                              pushNotificationChanged(updated);
                            }
                          }
                          id="push-notification-message"
                          rows="3">
                </textarea>
              </div>
              <div className="row mt-3">
                <div className="col-12 text-right">
                  <button type="submit"
                          disabled={!canSubmitForm(pushNotification, isSubmitting)}
                          className="btn btn-primary">
                    {isSubmitting ? (
                      <React.Fragment>
                        <img src="/uploading-loading.gif"
                             className="mr-1"
                             style={{width: "16px"}} />
                        <strong>Sending...</strong>
                      </React.Fragment>
                    ) : (
                      <strong>Send</strong>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Popup>
    </div>
  )
};

PushNotifications.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  searchedSearchTerm: PropTypes.string,
  pushNotification: PropTypes.object,
  fetchNewPushNotification: PropTypes.func.isRequired,
  pushNotificationChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array,
  createPushNotification: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  fetchEstimatedNumberOfSends: PropTypes.func.isRequired,
  estimatedNumberOfSends: PropTypes.number,
  estimatedNumberOfSendsChanged: PropTypes.func.isRequired,
  hasPushNotifications: PropTypes.bool.isRequired,
};

export default PushNotifications;
