import { combineReducers } from 'redux';

import {
  DATA_TABLE_EL_CHANGED,
  PUSH_NOTIFICATION_CHANGED,
  IS_SUBMITTING_CHANGED,
  ESTIMATED_NUMBER_OF_SENDS_CHANGED
} from '../constants/pushNotificationsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const searchedSearchTerm = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pushNotification = (state = {}, action) => {
  switch (action.type) {
    case PUSH_NOTIFICATION_CHANGED:
      return action.pushNotification;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isSubmitting = (state = false, action) => {
  switch (action.type) {
    case IS_SUBMITTING_CHANGED:
      return action.isSubmitting;
    default:
      return state;
  }
};

const estimatedNumberOfSends = (state = 0, action) => {
  switch (action.type) {
    case ESTIMATED_NUMBER_OF_SENDS_CHANGED:
      return action.estimatedNumberOfSends;
    default:
      return state;
  }
};

const hasPushNotifications = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pushNotificationsReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  searchedSearchTerm,
  pushNotification,
  venueOwnerships,
  isSubmitting,
  estimatedNumberOfSends,
  hasPushNotifications,
});

export default pushNotificationsReducer;
