// Simple example of a React "smart" component

import { connect } from 'react-redux';
import AgentsTable from '../components/AgentsTable';
import * as actions from '../actions/agentsTableActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  agents: state.agents,
  index: state.index
});

const mapDispatchToProps = (dispatch) => {
  return {
    indexChanged: (index) => dispatch(actions.indexChanged(index)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(AgentsTable);
