import React from "react";

import { useMarketingSettingsContext } from "./MarketingSettingsIndexContainer";
import VenueOwnershipForm from "./VenueOwnerships/VenueOwnershipForm";
import SendDomainsContainer from "./SendDomains/SendDomainsContainer";

const pageStyle = {
  color: "#333333",
  display: "flex",
  flexDirection: "column",
  gap: 15,
};

const headerStyle = {
  fontSize: 16,
  fontWeight: 600,
};

const cardStyle = {
  backgroundColor: "white",
  borderRadius: 10,
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  padding: 25,
};

const cardHeaderStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const saveButtonStyle = {
  backgroundColor: "#1982C4",
  border: "none",
  borderRadius: 5,
  color: "white",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  height: 40,
  padding: "10px 12px",
  width: 100,
};

const labelStyle = {
  fontSize: 14,
  color: "#333333",
  fontWeight: 700,
  marginBottom: 8,
};

const subtextStyle = {
  color: "#333333",
  fontSize: 12,
  opacity: 0.5,
};

const selectStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  color: "#333333",
  fontSize: 14,
  height: 40,
  marginBottom: 30,
  padding: 8,
  width: 250,
};

const MarketingSettingsIndex = () => {
  const {
    venueOwnerships,
    setVenueOwnership,
    venueOwnership,
    updateMarketingSettings,
  } = useMarketingSettingsContext();

  const handleVenueOwnershipChange = (e) => {
    e.preventDefault();

    setVenueOwnership(venueOwnerships[e.target.value])
  };

  return (
    <div style={pageStyle}>
      <div style={headerStyle}>Settings</div>
      <div style={cardStyle}>
        <div style={cardHeaderStyle}>
          <div>
            <div style={labelStyle}>Venue</div>
            <div style={subtextStyle}>Select a venue to modify its marketing settings.</div>
            <select value={venueOwnerships.findIndex(o => o.id === venueOwnership.id)} onChange={handleVenueOwnershipChange} style={selectStyle}>
            {venueOwnerships.map((option, index) => (
              <option key={`${option.venueable.name}-${index}`} value={index}>
                {option.venueable.name}
              </option>
            ))}
          </select>
          </div>
          <button onClick={updateMarketingSettings} style={saveButtonStyle}>
            Save
          </button>
        </div>
        <SendDomainsContainer />
        <VenueOwnershipForm />
      </div>
    </div>
  );
};

export default MarketingSettingsIndex;
