import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";
import UseCSV from "@usecsv/react";

import DataTable from '../../DataTable/components/DataTable';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import Popup from "reactjs-popup";
var moment = require('moment');

import CalendarEventConflictPopup from './CalendarEventConflictPopup';
import CheckAvailsPopup from './CheckAvailsPopup';
import EventForm from '../../EventForm/components/EventForm';
import CalendarSearch from './CalendarSearch';
import CalendarSyncPopup from './CalendarSyncPopup';
import TagFilter from './TagFilter';

import $ from 'jquery';
require('datatables.net-bs4');

import {
  offsetDateForBrowser,
  setGonTimeZoneFromVenue
} from '../../../shared/timeZoneLogic';
import CSVImporter from '../../CSVImporter/CSVImporter';


const newContentStyle = (browser) => {
  var styles = {
    "border": "none",
    "borderRadius": "8px",
    "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
    "width": "594px",
    "maxWidth": ((browser.is.small || browser.is.extraSmall) ? "100vw" : "594px"),
    "marginTop": "10px",
    "zIndex": '200'
  }

  if (browser.greaterThan.small){
    styles["minWidth"] = "530px";
  }

  return styles;
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "250px",
  "padding": "0px",
  "marginTop": "10px",
  "zIndex": "3000"
}

const calendarEventConflictsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "650px",
  "padding": "30px"
}

const checkAvailsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "698px",
  "padding": "18px 32px"
}

const calendarSyncContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "18px 0px"
}

const datesAreOnSameDay = (firstDate, secondDate) => {
  return (
    firstDate.getFullYear() === secondDate.getFullYear()
      && firstDate.getMonth() === secondDate.getMonth()
      && firstDate.getDate() === secondDate.getDate()
  );
};

const getAvailableDates = (events, checkAvailsSettings, checkAvailsDates, defaultTopHold) => {
  var foundDates = [];

  if(events === null){
    return foundDates;
  }

  if(checkAvailsDates.length !== 2){
    return foundDates;
  }

  var notCanceledEvents = events
    .filter((e) => e.classification !== "canceled");

  var startDate = checkAvailsDates[0];
  var endDate = checkAvailsDates[1];
  var currentDate = new Date(startDate);

  while(currentDate <= endDate){
    var isAvailable = (
      checkAvailsSettings.onDaysOfWeek.includes(currentDate.getDay())
        && (
          !checkAvailsSettings.noConfirms
            || notCanceledEvents
                .filter((e) => e.type === "Confirm")
                .filter((e) => {
                  return e.date_range
                    .some((d) => datesAreOnSameDay(new Date(d), currentDate))
                }).length === 0
        )
        && (
          !checkAvailsSettings.noHolds
            || notCanceledEvents
                .filter((e) => e.type === "Hold")
                .filter((e) => {
                  return e.date_range
                    .some((d) => datesAreOnSameDay(new Date(d), currentDate))
                }).length === 0
        )
    );

    if(isAvailable){
      var availablePosition = null;

      var takenPositions =
        notCanceledEvents
          .filter((e) => {
            return e.date_range
              .some((d) => datesAreOnSameDay(new Date(d), currentDate));
          })
          .map(e => e.position)
          .sort();

      // Find the first available position
      for(var i = defaultTopHold; i <= 20; i++){
        if(!takenPositions.includes(i)){
          availablePosition = i;
          break;
        }
      }

      foundDates.push({
        date: new Date(currentDate),
        position: availablePosition
      });
    }

    var nextDate = currentDate.setDate(currentDate.getDate() + 1);
    currentDate = new Date(nextDate);
  }

  return foundDates;
}

const CalendarView = ({
  browser,
  csrfToken,
  user,
  team,
  events,
  venuesOptions,
  venueSelected,
  venueOwnerships,
  updateSelectedVenue,
  submitNewEventForm,
  onInit,
  fetchTeamVenueConflicts,
  calendarEventConflicts,
  calendarEventConflictsModalOpen,
  closeCalendarEventConflictsModal,
  confirmTeamVenueConflicts,
  saveUserCalendarStartDay,
  calendarEventConflictMergeCheckboxClicked,
  holdGroup,
  confirm,
  confirmChanged,
  calendarEventTitle,
  updateCalendarEventTitle,
  calendarEventArtists,
  updateCalendarEventArtists,
  calendarEventType,
  updateCalendarEventType,
  calendarEventTicketForecast,
  calendarEventTicketForecastChanged,
  calendarEventNoteEditorState,
  updateCalendarEventNoteEditorState,
  calendarEventErrors,
  calendarEventFormClosed,
  userChangedCalendarEventTitle,
  onSaleEnabled,
  preSaleEnabled,
  announcedEnabled,
  confirmedEnabled,
  holdsEnabled,
  pastEventsEnabled,
  cancelledEventsEnabled,
  toggleOnSaleEnabled,
  togglePreSaleEnabled,
  toggleAnnouncedEnabled,
  toggleConfirmedEnabled,
  toggleHoldsEnabled,
  togglePastEventsEnabled,
  toggleCancelledEventsEnabled,
  holdGroupCalendarDateClicked,
  holdGroupDates,
  holdGroupHoldPositionUpdated,
  holdGroupHoldRemoved,
  confirmStartDateUpdated,
  confirmStartTimeUpdated,
  fetchHoldAvailability,
  calendarRef,
  calendarRefUpdated,
  calendarStartDate,
  calendarStartDateUpdated,
  monthView,
  monthViewChanged,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableFilters,
  reloadDataTable,
  dataTableEl,
  dataTableElChanged,
  selectedPromoter,
  selectedPromoterChanged,
  buyers,
  buyersChanged,
  promoters,
  promotersChanged,
  selectedBuyer,
  selectedBuyerChanged,
  holdGroupDeletion,
  holdGroupDeletionChanged,
  holdGroupDeletionReasonChanged,
  holdGroupDeletionExplanationChanged,
  holdGroupDeletionFollowUpChanged,
  holdGroupDeletionFollowUpAtChanged,
  deleteHoldGroup,
  searchTerm,
  searchTermChanged,
  currentTeamMembership,
  saveTeamMembershipCalendarState,
  submittingForm,
  doorsTimeDefault,
  eventEndTimeDefault,
  userChangedDoorsTime,
  userChangedDoorsTimeChanged,
  userChangedEventEndTime,
  userChangedEventEndTimeChanged,
  applyTimeDefaults,
  checkAvailsIsOpen,
  checkAvailsIsOpenChanged,
  checkAvailsSettings,
  checkAvailsSettingsChanged,
  calendarSyncIsOpen,
  calendarSyncIsOpenChanged,
  classificationTotals,
  checkAvailsDates,
  checkAvailsDatesChanged,
  checkAvailsCalendarEvents,
  checkAvailsCalendarEventsChanged,
  fetchCheckAvailsCalendarEvents,
  checkAvailsLoading,
  holdGroupChanged,
  fetchCalendarEventsForSelectedVenues,
  dataTableFiltersChanged
}) => {
  const canSelectMultipleVenues = team.package.name !== "Opener";
  const [selectedVenues, setSelectedVenues] = useState(
    venuesOptions.filter((vo) =>
      currentTeamMembership.calendar_venue_ids.includes(vo.id)
    ).map((vo) => {
      return { value: vo.id, label: vo.name };
    })
  );

  const [selectedTags, setSelectedTags] = useState([]);

  // Some situations require us to have a default venue, such
  // as if the user clicks on the calendar to open the event form.
  useEffect(() => {
    if(selectedVenues.length === 1){
      updateSelectedVenue(venuesOptions, selectedVenues[0]);
    } else {
      updateSelectedVenue(venuesOptions, {value: venuesOptions[0].id});
    }
  }, [selectedVenues])

  useEffect(() => {
    if(venueSelected && venueSelected.id){
      setGonTimeZoneFromVenue(venueOwnerships, venueSelected.id);
    }
  }, [venueSelected])

  useEffect(() => {
    onInit(team, selectedVenues, selectedTags, user, dataTableFilters);
    const params = (new URL(document.location)).searchParams;
    const artist = params.get("artist");

    if (artist) {
      const decodedArtist = decodeURIComponent(window.location.search.split("=")[1])
      searchTermChanged(decodedArtist)
    }
  }, [])

  useEffect(() => {
    checkAvailsCalendarEventsChanged(null);

    if(checkAvailsDates.length === 2){
      fetchCheckAvailsCalendarEvents(team, selectedVenues, checkAvailsDates);
    }
  }, [
    checkAvailsDates,
    checkAvailsCalendarEventsChanged,
    fetchCheckAvailsCalendarEvents,
    selectedVenues
  ])

  const filterVenueSelection = (previouslySelected, nowSelected) => {
    if(canSelectMultipleVenues){
      return nowSelected;
    } else {
      var previousIds = previouslySelected.map((s) => s.value);
      var selectedIds = nowSelected.map((s) => s.value);
      var newIds = selectedIds.filter((id) => !previousIds.includes(id));

      return nowSelected.filter((s) => newIds.includes(s.value));
    }
  }

  const popupRef = useRef(null);

  return (
    <div className="CalendarViewApp">
      <ToastContainer />
      {user.role.name !== "Promoter" ? (
        <div className="row mb-3">
          <div className="col-12">
            <CalendarSearch searchTerm={searchTerm}
                            searchTermChanged={searchTermChanged} />
          </div>
        </div>
      ) : null}
      <div className="row justify-content-between mt-1 mb-3 mb-md-1">
        <div
          className="col-12 col-md-auto"
          style={{width: "300px"}}
        >
          <MultiSelect
            className="calendar-view-dropdown-container"
            options={
              venuesOptions.map(function(venue){
                return { value: venue.id, label: venue.name }
              })
            }
            hasSelectAll={canSelectMultipleVenues}
            value={selectedVenues}
            onChange={
              (nowSelected) => {
                var filteredSelection = [];

                setSelectedVenues((previouslySelected) => {
                  filteredSelection = filterVenueSelection(previouslySelected, nowSelected);
                  return filteredSelection;
                });

                fetchCalendarEventsForSelectedVenues(team, filteredSelection, selectedTags);
                fetchTeamVenueConflicts(team, filteredSelection, user);

                dataTableFiltersChanged(Object.assign({}, dataTableFilters, {
                  venue_ids: filteredSelection.map((s) =>  s.value)
                }));

                saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                  calendar_venue_ids: filteredSelection.map((s) => s.value)
                });
              }
            }
            overrideStrings={{
              "selectSomeItems": "Select Venues...",
              "allItemsAreSelected": "All Venues Selected"
            }}
            labelledBy="Select"
          />
        </div>
        <div className="col-12 col-md">
          <ul className='list-inline event-counts'>
            <li onClick={
                  (e) => {
                    e.preventDefault();

                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                      calendar_on_sale_enabled: !onSaleEnabled
                    });

                    toggleOnSaleEnabled(!onSaleEnabled);
                  }
                }
                className={"list-inline-item on-sale p-1 m-1 " + (onSaleEnabled ? "" : "disabled")}>
              <i className="fa fa-circle mr-1"></i>
              <strong>On Sale ({classificationTotals.on_sale || 0})</strong>
            </li>
            <li onClick={
                  (e) => {
                    e.preventDefault();

                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                      calendar_pre_sale_enabled: !preSaleEnabled
                    });

                    togglePreSaleEnabled(!preSaleEnabled);
                  }
                }
                className={"list-inline-item pre-sale p-1 m-1 " + (preSaleEnabled ? "" : "disabled")}>
              <i className="fa fa-circle mr-1"></i>
              <strong>Pre Sale ({classificationTotals.pre_sale || 0})</strong>
            </li>
            <li onClick={
                  (e) => {
                    e.preventDefault();

                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                      calendar_announced_enabled: !announcedEnabled
                    });

                    toggleAnnouncedEnabled(!announcedEnabled);
                  }
                }
                className={"list-inline-item announced p-1 m-1 " + (announcedEnabled ? "" : "disabled")}>
              <i className="fa fa-circle mr-1"></i>
              <strong>Announced ({classificationTotals.announced || 0})</strong>
            </li>
            <li onClick={
                  (e) => {
                    e.preventDefault();

                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                      calendar_confirmed_enabled: !confirmedEnabled
                    });

                    toggleConfirmedEnabled(!confirmedEnabled);
                  }
                }
                className={"list-inline-item confirmed p-1 m-1 " + (confirmedEnabled ? "" : "disabled")}>
              <i className="fa fa-circle mr-1"></i>
              <strong>Confirmed ({classificationTotals.confirm || 0})</strong>
            </li>
            <li onClick={
                  (e) => {
                    e.preventDefault();

                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                      calendar_holds_enabled: !holdsEnabled
                    });

                    toggleHoldsEnabled(!holdsEnabled);
                  }
                }
                className={"list-inline-item holds p-1 m-1 " + (holdsEnabled ? "" : "disabled")}>
              <i className="fa fa-circle mr-1"></i>
              <strong>Holds ({classificationTotals.hold || 0})</strong>
            </li>
            <li onClick={
                  (e) => {
                    e.preventDefault();

                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                      calendar_past_events_enabled: !pastEventsEnabled
                    });

                    togglePastEventsEnabled(!pastEventsEnabled);
                  }
                }
                className={"list-inline-item past p-1 m-1 " + (pastEventsEnabled ? "" : "disabled")}>
              <i className="fa fa-circle mr-1"></i>
              <strong>Past Events ({classificationTotals.past || 0})</strong>
            </li>
            <li onClick={
                  (e) => {
                    e.preventDefault();

                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                      calendar_cancelled_events_enabled: !cancelledEventsEnabled
                    });

                    toggleCancelledEventsEnabled(!cancelledEventsEnabled);
                  }
                }
                className={"list-inline-item canceled p-1 m-1 " + (cancelledEventsEnabled ? "" : "disabled")}>
              <i className="fa fa-circle mr-1"></i>
              <strong>Canceled Events ({classificationTotals.canceled || 0})</strong>
            </li>
            <li className="list-inline-item recommendations p-1 m-1 hide">
              <i className="fa fa-circle mr-1"></i>
              <strong>Recommendations ({(events).filter((event) => event.classification === "recommendation").length})</strong>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-auto text-right">
          <Popup
            arrow={false}
            offsetY={0}
            position="bottom right"
            contentStyle={{
              border: "none",
              borderRadius: "8px",
              boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
              width: "450px",
              padding: "0px",
              marginTop: "10px",
              zIndex: "3000"
            }}
            overlayStyle={{ zIndex: "2999" }}
            onOpen={e => e.preventDefault()}
            trigger={open => (
              <a
                href="#"
                className={`mr-2 btn small font-weight-bold ${selectedTags.length > 0 ? "btn-primary" : "btn-external-inverse"}`}
                onClick={e => e.preventDefault()}
              >
                Filter by Tag
                <i className="fa fa-chevron-down ml-2"></i>
              </a>
            )}
          >
            {close => (
              <TagFilter
                team={team}
                csrfToken={csrfToken}
                scope="CalendarEvent"
                currentTags={selectedTags}
                onChange={tags => {
                  setSelectedTags(current => {

                    fetchCalendarEventsForSelectedVenues(team, selectedVenues, tags);

                    dataTableFiltersChanged({
                      ...dataTableFilters,
                      tags: tags
                    });

                    return tags;
                  });
                }}
              />
            )}
          </Popup>
          <Popup arrow={false}
                 offsetY={0}
                 position="bottom right"
                 contentStyle={settingsContentStyle}
                 overlayStyle={{ zIndex: "2999" }}
                 onOpen={
                   (e) => {
                     e.preventDefault();
                   }
                 }
                 trigger={open => (
             <a href="#"
                onClick={(e) => {e.preventDefault();}}
                className='btn btn-external-inverse small font-weight-bold'>
               Options
             </a>
          )} >
            {close => (
              <div className="row">
                <div className="col-12">
                  <div className='text-left pt-3 px-3'>
                    <a href="#"
                       className="text-dark"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           checkAvailsIsOpenChanged(true);
                           close();
                         }
                       }>
                      <i className="fas fa-calendar-check mr-2 text-primary"></i>
                      Check avails
                    </a>
                  </div>
                </div>
                {user.can_manage_csv_imports ? (
                  <div className="col-12">
                    <div className='text-left pt-2 px-3'>
                      <CSVImporter
                        csrfToken={csrfToken}
                        team={team}
                        importerKey={"3f14aa14-9ffb-4dc8-9138-02a6f005e472"}
                        type={"CSVImportRows::CalendarEvent"}
                        renderAnchor={(openModal) => (
                          <a href="#"
                            className="text-dark"
                            onClick={e => {
                              e.preventDefault();
                              openModal();
                            }}
                          >
                            <i className="far fa-arrow-from-top fa-flip-vertical mr-2 text-primary"></i>
                            Import Events
                          </a>
                        )}
                        onClose={() => fetchCalendarEventsForSelectedVenues(team, selectedVenues, selectedTags)}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedVenues.length === 1 ? (
                  <div className="col-12">
                    <div className='text-left pt-2 px-3'>
                      <a href="#"
                        className="text-dark"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            calendarSyncIsOpenChanged(true);
                            close();
                          }
                        }>
                        <i className="fa-brands fa-google mr-2 text-primary"></i>
                        Sync to Google Calendar
                      </a>
                    </div>
                  </div>
                ) : null}
                <div className="col-12">
                  <div className='text-left px-3 py-3'>
                    <strong className="pb-2">Week starts on</strong>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input type="radio"
                              className="form-check-input"
                              defaultChecked={user.calendar_start_day === 0}
                              onChange={
                                (e) => {
                                  saveUserCalendarStartDay(csrfToken, 0);
                                }
                              }
                              name="day-of-week-choice" />
                        Sunday
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input type="radio"
                              className="form-check-input"
                              defaultChecked={user.calendar_start_day === 1}
                              onChange={
                                (e) => {
                                  saveUserCalendarStartDay(csrfToken, 1);
                                }
                              }
                              name="day-of-week-choice" />
                        Monday
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Popup>
          {user.can_create_calendar_event || user.can_create_hold_group ? (
            <Popup arrow={false}
                   ref={popupRef}
                   offsetY={5}
                   offsetX={((browser.is.small || browser.is.extraSmall) ? 15 : 0)}
                   position="bottom right"
                   contentStyle={newContentStyle(browser)}
                   overlayStyle={{ zIndex: "199" }}
                   closeOnDocumentClick={false}
                   onOpen={
                     (e) => {
                       e.preventDefault();
                     }
                   }
                   onClose={
                     () => {
                       calendarEventFormClosed(() => {
                         reloadDataTable();
                       });
                     }
                   }
                   trigger={open => (
               <a href="#"
                  onClick={(e) => {e.preventDefault();}}
                  className='btn btn-primary ml-2'>
                 <i className="fa fa-plus"></i>
                 &nbsp;
                 New Event
               </a>
            )} >
              {close => (
                <div className='text-left'>
                  <EventForm formTitle={"New Event"}
                             formButtonLabel={"Create Event"}
                             csrfToken={csrfToken}
                             currentUser={user}
                             team={team}
                             venuesOptions={venuesOptions}
                             venueSelected={venueSelected}
                             submitEventForm={submitNewEventForm}
                             onSuccess={(url) => {window.location.href = url;}}
                             updateSelectedVenue={updateSelectedVenue}
                             onVenueChange={
                               (selected) => {
                                 saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                   calendar_venue_id: selected.value
                                 });
                               }
                             }
                             onClose={
                               () => {
                                 close();
                               }
                             }
                             holdGroup={holdGroup}
                             confirm={confirm}
                             confirmChanged={confirmChanged}
                             calendarEventFormClosed={calendarEventFormClosed}
                             calendarEventTitle={calendarEventTitle}
                             updateCalendarEventTitle={updateCalendarEventTitle}
                             calendarEventArtists={calendarEventArtists}
                             updateCalendarEventArtists={updateCalendarEventArtists}
                             calendarEventType={calendarEventType}
                             updateCalendarEventType={updateCalendarEventType}
                             calendarEventTicketForecast={calendarEventTicketForecast}
                             calendarEventTicketForecastChanged={calendarEventTicketForecastChanged}
                             calendarEventNoteEditorState={calendarEventNoteEditorState}
                             updateCalendarEventNoteEditorState={updateCalendarEventNoteEditorState}
                             userChangedCalendarEventTitle={userChangedCalendarEventTitle}
                             holdGroupCalendarDateClicked={holdGroupCalendarDateClicked}
                             holdGroupDates={holdGroupDates}
                             holdGroupHoldPositionUpdated={holdGroupHoldPositionUpdated}
                             holdGroupHoldRemoved={holdGroupHoldRemoved}
                             confirmStartDateUpdated={confirmStartDateUpdated}
                             confirmStartTimeUpdated={confirmStartTimeUpdated}
                             fetchHoldAvailability={fetchHoldAvailability}
                             calendarRef={calendarRef}
                             calendarStartDate={calendarStartDate}
                             calendarStartDateUpdated={calendarStartDateUpdated}
                             selectedPromoter={selectedPromoter}
                             selectedPromoterChanged={selectedPromoterChanged}
                             buyers={buyers}
                             buyersChanged={buyersChanged}
                             promoters={promoters}
                             promotersChanged={promotersChanged}
                             selectedBuyer={selectedBuyer}
                             selectedBuyerChanged={selectedBuyerChanged}
                             holdGroupDeletion={holdGroupDeletion}
                             holdGroupDeletionChanged={holdGroupDeletionChanged}
                             holdGroupDeletionReasonChanged={holdGroupDeletionReasonChanged}
                             holdGroupDeletionExplanationChanged={holdGroupDeletionExplanationChanged}
                             holdGroupDeletionFollowUpChanged={holdGroupDeletionFollowUpChanged}
                             holdGroupDeletionFollowUpAtChanged={holdGroupDeletionFollowUpAtChanged}
                             deleteHoldGroup={deleteHoldGroup}
                             submittingForm={submittingForm}
                             calendarEventErrors={calendarEventErrors}
                             doorsTimeDefault={doorsTimeDefault}
                             eventEndTimeDefault={eventEndTimeDefault}
                             userChangedDoorsTime={userChangedDoorsTime}
                             userChangedDoorsTimeChanged={userChangedDoorsTimeChanged}
                             userChangedEventEndTime={userChangedEventEndTime}
                             userChangedEventEndTimeChanged={userChangedEventEndTimeChanged}
                             holdGroupChanged={holdGroupChanged}
                             applyTimeDefaults={applyTimeDefaults} />
                </div>
              )}
            </Popup>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="datatable-right-header-section pr-0"
               style={{"zIndex": 100, "right": "30px"}}>
            {!monthView ? (
              <Popup arrow={false}
                    offsetY={7}
                    position="bottom right"
                    contentStyle={popupContentStyle}
                    onOpen={
                      (e) => {
                        e.preventDefault();
                      }
                    }
                    trigger={open => (
                      <a href="#"
                         className="btn btn-external btn-sm mr-3"
                         style={{"borderRadius": "8px", "paddingLeft": "11px", "paddingRight": "11px"}}>
                        <i className="fas fa-ellipsis-v small"></i>
                      </a>
                    )} >
               {close => (
                 <div className='row text-left'>
                   <div className="col-12">
                     <ul className="list-group offer-inline-menu highlight-primary">
                       <li className="list-group-item">
                         <a onClick={
                              (e) => {
                                e.preventDefault();

                                var dataTable = $(dataTableEl).DataTable();
                                var url = dataTable.ajax.url().replace(/\.json$/, ".csv");
                                var params = Object.assign({},
                                  dataTable.ajax.params(),
                                  dataTableFilters
                                );

                                var urlWithParams = (url + '?' + $.param(params));
                                window.location.href = urlWithParams;
                              }
                            }
                            className="text-muted"
                            href="#">
                           <span className="d-inline-block text-center" style={{width: "28px"}}>
                             <i className="fas fa-download"></i>
                           </span>
                           Export CSV
                         </a>
                       </li>
                     </ul>
                   </div>
                 </div>
               )}
              </Popup>
            ) : null}
            <div className="btn-group">
              <button type="button"
                      style={{"opacity": "1", "zIndex": 0}}
                      disabled={monthView}
                      onClick={
                        (e) => {
                          e.preventDefault();

                          saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                            calendar_view: "calendar"
                          });

                          monthViewChanged(true);
                        }
                      }
                      className={"btn btn-sm rounded-left " + (monthView ? "btn-primary" : "btn-external")}>
                <strong>Month</strong>
              </button>
              <button type="button"
                      style={{"opacity": "1", "zIndex": 0}}
                      disabled={!monthView}
                      onClick={
                        (e) => {
                          e.preventDefault();

                          saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                            calendar_view: "list"
                          });

                          monthViewChanged(false);
                        }
                      }
                      className={"btn btn-sm rounded-right " + (monthView ? "btn-external" : "btn-primary")}>
                <strong>List</strong>
              </button>
            </div>
          </div>
          {monthView ? (
            <div className="card no-border shadow-2">
              <FullCalendar buttonText={{today: "Today"}}
                            ref={(ref) => {
                              if(ref){
                                calendarRefUpdated(ref);
                              }
                            }}
                            dateClick={
                              (info) => {
                                if(popupRef.current.state.isOpen){
                                  return false;
                                };

                                popupRef.current.TriggerEl.click();

                                var date = offsetDateForBrowser(info.date);
                                date.setHours(date.getHours() + 12);

                                // Same as onDayClick in EventForm
                                holdGroupCalendarDateClicked(date, undefined);
                                fetchHoldAvailability(team, venueSelected.id, date);
                              }
                            }
                            initialDate={currentTeamMembership.calendar_starts_at}
                            datesSet={
                              (info) => {
                                var date = info.view.currentStart;
                                calendarStartDateUpdated(offsetDateForBrowser(date));

                                var approxMidMonth = moment(date).add('days', 15);
                                saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                  calendar_starts_at: approxMidMonth
                                });

                                // Date boxes were small until you resized the window
                                window.dispatchEvent(new Event('resize'));

                                fetchCalendarEventsForSelectedVenues(team, selectedVenues, selectedTags);
                              }
                            }
                            firstDay={user.calendar_start_day}
                            headerToolbar={{
                              start:   'prev,next today',
                              center: 'title',
                              end:  ''
                            }}
                            defaultTimedEventDuration={'00:00:01'}
                            events={
                              (info, successCallback, failureCallback) => {
                                if(!onSaleEnabled){
                                  events = events.filter((event) => event.classification !== "on_sale")
                                }

                                if(!preSaleEnabled){
                                  events = events.filter((event) => event.classification !== "pre_sale")
                                }

                                if(!announcedEnabled){
                                  events = events.filter((event) => event.classification !== "announced")
                                }

                                if(!confirmedEnabled){
                                  events = events.filter((event) => event.classification !== "confirm")
                                }

                                if(!holdsEnabled){
                                  events = events.filter((event) => event.classification !== "hold")
                                }

                                if(!pastEventsEnabled){
                                  events = events.filter((event) => event.classification !== "past")
                                }

                                if(!cancelledEventsEnabled){
                                  events = events.filter((event) => event.classification !== "canceled")
                                }

                                successCallback(events);
                              }
                            }
                            eventClick={
                              (eventInfo) => {
                                if(!eventInfo.event.extendedProps.authorized){
                                  eventInfo.jsEvent.preventDefault();
                                  return false;
                                }
                              }
                            }
                            eventOrder={
                              ["type_position", "position", "start"]
                            }
                            eventClassNames={
                              (info) => {
                                var extraClasses = [];
                                var classification = info.event.extendedProps.classification;

                                extraClasses.push("fc-event-" + classification);
                                return extraClasses.join(" ");
                              }
                            }
                            eventContent= {
                              (info) => (
                                <div className="d-flex flex-column w-100">
                                  <div className='d-flex justify-content-between w-100'>
                                    <div className="fc-event-title"
                                        title={info.event.title}>
                                      {info.event.title}
                                    </div>
                                    <div className="fc-event-time">
                                      {info.event.extendedProps.type === "Confirm" ? (
                                        info.timeText
                                      ) : (
                                        "H" + info.event.extendedProps.position
                                      )}
                                    </div>
                                  </div>
                                  <div className='fc-event-venue-name w-100 text-truncate'>
                                    {info.event.extendedProps.venue_name}
                                  </div>
                                </div>
                              )
                            }

                            timeZone={
                              selectedVenues.length === 1 ? gon.timeZone : "local"
                            }
                            plugins={[ dayGridPlugin, momentTimezonePlugin, interactionPlugin ]} />
            </div>
          ) : (
            <DataTable title={dataTableTitle}
                       dataSource={dataTableDataSource}
                       columns={dataTableColumns}
                       reducedFilters={dataTableFilters}
                       searchedSearchTerm={searchTerm}
                       colGroup={
                         <colgroup>
                           <col span={1} style={{"width": "1%"}} />
                           <col span={1} style={{"width": "29%"}} />
                           <col span={1} style={{"width": "15%"}} />
                           <col span={1} style={{"width": "10%"}} />
                           <col span={1} style={{"width": "15%"}} />
                           <col span={1} style={{"width": "15%"}} />
                           <col span={1} style={{"width": "15%"}} />
                         </colgroup>
                       }
                       onTableElementSet={
                         (el) => {
                           dataTableElChanged(el);
                         }
                       }
                       order={dataTableOrder} />
          )}
          <Popup
            open={calendarEventConflictsModalOpen}
            contentStyle={calendarEventConflictsContentStyle}
            className={"popup-modal"}
            closeOnDocumentClick
            onClose={() => closeCalendarEventConflictsModal()}
          >
            <CalendarEventConflictPopup calendarEventConflicts={calendarEventConflicts}
                                        team={team}
                                        csrfToken={csrfToken}
                                        confirmTeamVenueConflicts={confirmTeamVenueConflicts}
                                        selectedVenues={selectedVenues}
                                        selectedTags={selectedTags}
                                        calendarEventConflictMergeCheckboxClicked={calendarEventConflictMergeCheckboxClicked}
                                        closeModal={closeCalendarEventConflictsModal} />
          </Popup>
          <Popup
            open={checkAvailsIsOpen}
            contentStyle={checkAvailsContentStyle}
            className={"popup-modal"}
            closeOnDocumentClick={true}
            onClose={
              () => {
                checkAvailsIsOpenChanged(false);
              }
            }>
            <CheckAvailsPopup team={team}
                              checkAvailsIsOpenChanged={checkAvailsIsOpenChanged}
                              checkAvailsSettings={checkAvailsSettings}
                              checkAvailsDates={checkAvailsDates}
                              checkAvailsDatesChanged={checkAvailsDatesChanged}
                              checkAvailsSettingsChanged={checkAvailsSettingsChanged}
                              checkAvailsLoading={checkAvailsLoading}
                              csrfToken={csrfToken}
                              availableDates={getAvailableDates(checkAvailsCalendarEvents, checkAvailsSettings, checkAvailsDates, team.default_top_hold)}
                              currentTeamMembership={currentTeamMembership}
                              calendarRef={calendarRef} />
          </Popup>
          <Popup
            open={calendarSyncIsOpen}
            contentStyle={calendarSyncContentStyle}
            className={"popup-modal"}
            closeOnDocumentClick={true}
            onClose={() => { calendarSyncIsOpenChanged(false); }}
          >
            <CalendarSyncPopup
              team={team}
              venueOwnership={venueOwnerships.find((vo) => vo.venueable_id === venueSelected?.id)}
              calendarSyncIsOpenChanged={calendarSyncIsOpenChanged}
            />
          </Popup>
        </div>
      </div>
    </div>
  )
};

CalendarView.propTypes = {
  browser: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  venuesOptions: PropTypes.array,
  venueSelected: PropTypes.object,
  venueOwnerships: PropTypes.array,
  updateSelectedVenue: PropTypes.func.isRequired,
  events: PropTypes.array,
  submitNewEventForm: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  fetchTeamVenueConflicts: PropTypes.func.isRequired,
  calendarEventConflicts: PropTypes.array,
  calendarEventConflictsModalOpen: PropTypes.bool,
  closeCalendarEventConflictsModal: PropTypes.func.isRequired,
  confirmTeamVenueConflicts: PropTypes.func.isRequired,
  saveUserCalendarStartDay: PropTypes.func.isRequired,
  calendarEventConflictMergeCheckboxClicked: PropTypes.func.isRequired,
  holdGroup: PropTypes.object,
  confirm: PropTypes.object,
  confirmChanged: PropTypes.func.isRequired,
  calendarEventTitle: PropTypes.string,
  updateCalendarEventTitle: PropTypes.func.isRequired,
  calendarEventArtists: PropTypes.array,
  updateCalendarEventArtists: PropTypes.func.isRequired,
  calendarEventType: PropTypes.string,
  updateCalendarEventType: PropTypes.func.isRequired,
  calendarEventTicketForecast: PropTypes.number,
  calendarEventTicketForecastChanged: PropTypes.func.isRequired,
  calendarEventNoteEditorState: PropTypes.object,
  updateCalendarEventNoteEditorState: PropTypes.func.isRequired,
  calendarEventErrors: PropTypes.array,
  calendarEventFormClosed: PropTypes.func.isRequired,
  userChangedCalendarEventTitle: PropTypes.bool,
  onSaleEnabled: PropTypes.bool,
  preSaleEnabled: PropTypes.bool,
  announcedEnabled: PropTypes.bool,
  confirmedEnabled: PropTypes.bool,
  holdsEnabled: PropTypes.bool,
  pastEventsEnabled: PropTypes.bool,
  cancelledEventsEnabled: PropTypes.bool,
  toggleOnSaleEnabled: PropTypes.func.isRequired,
  togglePreSaleEnabled: PropTypes.func.isRequired,
  toggleAnnouncedEnabled: PropTypes.func.isRequired,
  toggleConfirmedEnabled: PropTypes.func.isRequired,
  toggleHoldsEnabled: PropTypes.func.isRequired,
  togglePastEventsEnabled: PropTypes.func.isRequired,
  toggleCancelledEventsEnabled: PropTypes.func.isRequired,
  holdGroupCalendarDateClicked: PropTypes.func.isRequired,
  holdGroupDates: PropTypes.array,
  holdGroupHoldPositionUpdated: PropTypes.func.isRequired,
  holdGroupHoldRemoved: PropTypes.func.isRequired,
  confirmStartDateUpdated: PropTypes.func.isRequired,
  confirmStartTimeUpdated: PropTypes.func.isRequired,
  fetchHoldAvailability: PropTypes.func.isRequired,
  calendarRef: PropTypes.object,
  calendarRefUpdated: PropTypes.func.isRequired,
  calendarStartDate: PropTypes.instanceOf(Date),
  calendarStartDateUpdated: PropTypes.func.isRequired,
  monthView: PropTypes.bool,
  monthViewChanged: PropTypes.func.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableFilters: PropTypes.object,
  reloadDataTable: PropTypes.func.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  selectedPromoter: PropTypes.object,
  selectedPromoterChanged: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  buyersChanged: PropTypes.func.isRequired,
  promoters: PropTypes.array,
  promotersChanged: PropTypes.func.isRequired,
  selectedBuyer: PropTypes.object,
  selectedBuyerChanged: PropTypes.func.isRequired,
  holdGroupDeletion: PropTypes.object,
  holdGroupDeletionChanged: PropTypes.func.isRequired,
  holdGroupDeletionReasonChanged: PropTypes.func.isRequired,
  holdGroupDeletionExplanationChanged: PropTypes.func.isRequired,
  holdGroupDeletionFollowUpChanged: PropTypes.func.isRequired,
  holdGroupDeletionFollowUpAtChanged: PropTypes.func.isRequired,
  deleteHoldGroup: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  searchTermChanged: PropTypes.func.isRequired,
  currentTeamMembership: PropTypes.object.isRequired,
  saveTeamMembershipCalendarState: PropTypes.func.isRequired,
  submittingForm: PropTypes.bool,
  doorsTimeDefault: PropTypes.object.isRequired,
  eventEndTimeDefault: PropTypes.object.isRequired,
  userChangedDoorsTime: PropTypes.bool,
  userChangedDoorsTimeChanged: PropTypes.func.isRequired,
  userChangedEventEndTime: PropTypes.bool,
  userChangedEventEndTimeChanged: PropTypes.func.isRequired,
  applyTimeDefaults: PropTypes.func.isRequired,
  checkAvailsIsOpen: PropTypes.bool,
  checkAvailsIsOpenChanged: PropTypes.func.isRequired,
  checkAvailsSettings: PropTypes.object,
  checkAvailsSettingsChanged: PropTypes.func.isRequired,
  calendarSyncIsOpen: PropTypes.bool,
  calendarSyncIsOpenChanged: PropTypes.func.isRequired,
  classificationTotals: PropTypes.object,
  checkAvailsDates: PropTypes.array,
  checkAvailsDatesChanged: PropTypes.func.isRequired,
  checkAvailsCalendarEvents: PropTypes.array,
  checkAvailsCalendarEventsChanged: PropTypes.func.isRequired,
  fetchCheckAvailsCalendarEvents: PropTypes.func.isRequired,
  checkAvailsLoading: PropTypes.bool,
  holdGroupChanged: PropTypes.func.isRequired,
  fetchCalendarEventsForSelectedVenues: PropTypes.func.isRequired,
  dataTableFiltersChanged: PropTypes.func.isRequired
};

export default CalendarView;
