import PropTypes from 'prop-types';
import React from 'react';

const CalendarEventConflictPopup = ({
    calendarEventConflicts,
    closeModal,
    csrfToken,
    team,
    confirmTeamVenueConflicts,
    calendarEventConflictMergeCheckboxClicked,
    selectedVenues,
    selectedTags,
  }) => (
  <div className="popup-modal-container">
    <div className="row">
      <div className="col">
        <h4>
          <strong>Duplicate events found</strong>
        </h4>
      </div>
      <div className="col-xs-auto">
        <a href="#"
           className="text-muted"
           style={{"paddingRight": "30px"}}
           onClick={(e) => {e.preventDefault(); closeModal();}} >
          <i className="fa fa-times"></i>
        </a>
      </div>
    </div>
    <div className="row pt-4">
      <div className="col">
        <p>The following events appear to be duplicates. Take action on these to keep your calendar up to date.</p>
      </div>
    </div>
    <div className="row pt-4">
      <div className="col">
        {calendarEventConflicts.map((calendarEventConflict, index) => (
          <div key={index} className="row">
            <div className="col-12">
              <div className="form-row">
                <div className="col">
                  <div className="form-row justify-content-center">
                    <div className="col-5">
                      <div className="card venue-card no-border">
                        <div className="top-right-icon">
                          <img src="/eventbrite.png" />
                        </div>
                        <div className="card-body">
                          <p className="venue-name mb-0">
                            <strong>{calendarEventConflict.connected_calendar_event.name}</strong>
                          </p>
                          <p className="mb-0">{calendarEventConflict.connected_calendar_event.human_date_and_time}</p>
                          <p className="mb-0">{calendarEventConflict.connected_calendar_event.venue.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-auto" style={{'paddingTop': "40px"}}>
                      <strong style={{color: "#3b81bf"}}><i className="fa fa-arrows-h"></i></strong>
                    </div>
                    <div className="col-5">
                      <div className="card venue-card no-border">
                        <div className="top-right-icon">
                          <span className="badge badge-venue-card less-rounded px-2 text-dark">CONFIRMED</span>
                        </div>
                        <div className="card-body" style={{'paddingTop': "25px"}}>
                          <p className="venue-name mb-0">
                            <strong>{calendarEventConflict.calendar_event.name}</strong>
                          </p>
                          <p className="mb-0">{calendarEventConflict.calendar_event.human_date_and_time}</p>
                          <p className="mb-0">{calendarEventConflict.calendar_event.venue.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-auto" style={{'paddingTop': "40px"}}>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox"
                           className="custom-control-input"
                           checked={calendarEventConflict.shouldMerge}
                           onChange={
                             (e) => {
                               calendarEventConflictMergeCheckboxClicked(calendarEventConflict, e.target.checked);
                             }
                           }
                           id={"merge-conflict" + index} />
                    <label className="custom-control-label"
                           htmlFor={"merge-conflict" + index}>
                      <strong className="pt-1 d-inline-block">Merge</strong>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="row pt-4">
      <div className="col text-right">
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               closeModal();
             }
           }>
          Cancel
        </a>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               confirmTeamVenueConflicts(csrfToken, team, calendarEventConflicts, selectedVenues, selectedTags);
               closeModal();
             }
           }
           className="btn btn-primary ml-3">
          Confirm
        </a>
      </div>
    </div>
  </div>
);

CalendarEventConflictPopup.propTypes = {
  calendarEventConflicts: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirmTeamVenueConflicts: PropTypes.func.isRequired,
  calendarEventConflictMergeCheckboxClicked: PropTypes.func.isRequired,
  selectedVenues: PropTypes.array,
  selectedTags: PropTypes.array
};

export default CalendarEventConflictPopup;
