import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";
import {DebounceInput} from 'react-debounce-input';
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import Flatpickr from "react-flatpickr";

const axios = require('axios').default;
const flatPickrFormat = "n/j/y at h:iK"
var moment = require('moment');

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const debouncedFetchEventTemplates = _.debounce((team, inputValue, callback) => {
  new Promise(resolve => {
    axios.get(`/teams/${team.id}/event_templates`).then(function (response) {
      var newOptions = response.data.map(function(data){
        return { value: data.id, label: data.name };
      });

      callback(newOptions);
    })
  });
}, 250);

const canSubmitHoldGroupDeletion = function(holdGroupDeletion){
  return (
    holdGroupDeletion.reason
      && holdGroupDeletion.reason.length > 0
      && (
        !holdGroupDeletion.followUp
          || (holdGroupDeletion.followUpAt)
      )
      && (
        holdGroupDeletion.reason !== "Other"
          || (holdGroupDeletion.explanation && holdGroupDeletion.explanation.length > 0)
      )
  );
}

const canSubmitHoldToConfirmSave = function(holdToConfirm, isConvertingHoldToConfirm){
  return (
    holdToConfirm
      && holdToConfirm.start_time
      && holdToConfirm.door_time
      && holdToConfirm.end_time
      && holdToConfirm.selectedHoldPositionId
      && !isConvertingHoldToConfirm
  );
}

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "360px",
  "padding": "15px"
}

const holdToConfirmModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "594px",
  "padding": "0px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "230px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const HoldActionMenu = ({
  confirm,
  csrfToken,
  team,
  currentUser,
  holdGroupDeletion,
  holdGroupDeletionChanged,
  deleteHoldGroup,
  holdToConfirm,
  holdToConfirmChanged,
  userChangedDoorsTimeChanged,
  userChangedEventEndTimeChanged,
  convertHoldGroupToConfirm,
  isConvertingHoldToConfirm
}) => {
  const canEnableDate = (date) => {
    const dateString = date.toDateString();

    var datesToEnable = holdToConfirm.hold_positions.map(holdPosition => {
      return new Date(holdPosition.start_time).toDateString();
    });

    if(typeof holdToConfirm.start_time !== 'undefined') {
      datesToEnable.push(holdToConfirm.start_time)
    }

    return datesToEnable.includes(dateString);
  }

  return (
    <>
      <Popup arrow={false}
            offsetY={5}
            position="bottom right"
            contentStyle={popupContentStyle}
            onOpen={
              (e) => {
                e.stopPropagation();
                e.preventDefault();
              }
            }
            trigger={open => (
              <a className="btn btn-primary btn-sm align-self-end"
                  style={{"marginBottom": "16px", "borderRadius": "8px"}}
                  href="#">
                Actions
                <i className="fas fa-caret-down pl-2"></i>
              </a>
            )} >
        {close => (
          <div className='row text-left'>
            <div className="col-12">
              <ul className="list-group offer-inline-menu">
                {currentUser.can_create_calendar_event ? (
                  <li className="list-group-item"
                      style={{"padding": "8px 12px"}}>
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();

                            var eventTemplate = (confirm.event_template && confirm.event_template.id ?
                              { value: confirm.event_template.id, label: confirm.event_template.name }
                            : null);

                            var holdToConfirm = {
                              ...confirm,
                              event_template: eventTemplate
                            };

                            holdToConfirmChanged(holdToConfirm);
                          }
                        }
                        className="text-dark">
                      <span className="d-inline-block text-center">
                        <i className="far fa-calendar-check fa-fw text-primary"
                            style={{
                              "fontSize": "16px",
                              "marginRight": "10px"
                            }}>
                        </i>
                      </span>
                      Confirm this event
                    </a>
                  </li>
                ) : null}
                {currentUser.can_destroy_calendar_event ? (
                  <li className="list-group-item"
                      style={{"padding": "8px 12px"}}>
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            holdGroupDeletionChanged({hold_group_id: confirm.id});
                          }
                        }
                        className="text-dark">
                      <span className="d-inline-block text-center">
                        <i className="far fa-calendar-plus fa-fw text-primary"
                            style={{
                              "fontSize": "16px",
                              "marginRight": "10px"
                            }}>
                        </i>
                      </span>
                      Remove from calendar
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        )}
      </Popup>
      <Popup
        open={(Object.keys(holdGroupDeletion).length > 0)}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={true}
        onClose={() => holdGroupDeletionChanged({})}
      >
        <div>
          <div className="text-center">
            <p className="strong mb-0"
               style={{"fontSize": "20px"}}>
              Are you sure?
            </p>
            <p className="text-muted mb-0">
              Confirm deletion of this hold.
            </p>
          </div>
          <form onSubmit={
            (e) => {
              e.preventDefault();

              if(!canSubmitHoldGroupDeletion(holdGroupDeletion)){
                return false;
              }

              deleteHoldGroup(csrfToken, team, confirm.id, holdGroupDeletion);
            }
          }>
            <div className="form-group mt-4">
              <select className="form-control form-control-sm"
                      style={{
                        "borderColor": "#f5f5f5",
                        "borderRadius": "8px"
                      }}
                      value={holdGroupDeletion.reason || ""}
                      onChange={
                        (e) => {
                          var updated = Object.assign({}, holdGroupDeletion, {
                            reason: e.target.value
                          });

                          holdGroupDeletionChanged(updated);
                        }
                      }
                      id="hold-group-deletion-reason">
                <option value="" disabled>Reason for deletion</option>
                <option value="Skipping the market">Skipping the market</option>
                <option value="Playing another venue in market">Playing another venue in market</option>
                <option value="Tour cancelled">Tour cancelled</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {holdGroupDeletion.reason === "Other" ? (
              <div className="form-group">
                <DebounceInput type="text"
                       debounceTimeout={250}
                       className="form-control form-control-sm"
                       style={{
                         "borderColor": "#f5f5f5",
                         "borderRadius": "8px"
                       }}
                       value={holdGroupDeletion.explanation || ""}
                       onChange={
                         (e) => {
                           var updated = Object.assign({}, holdGroupDeletion, {
                            explanation: e.target.value
                          });

                          holdGroupDeletionChanged(updated);
                         }
                       }
                       id="hold-group-deletion-explanation"
                       placeholder="Please explain..." />
              </div>
            ) : null}
            {confirm && confirm.artists && confirm.artists.length > 0 ? (
              <div className="form-group">
                <div className="form-row">
                  <div className="col-8">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox"
                             className="custom-control-input"
                             value={holdGroupDeletion.followUp || false}
                             onChange={
                               (e) => {
                                  var updated = Object.assign({}, holdGroupDeletion, {
                                    followUp: e.target.checked
                                  });
        
                                  holdGroupDeletionChanged(updated);
                               }
                             }
                             id="hold-group-deletion-follow-up" />
                      <label className="custom-control-label"
                             htmlFor="hold-group-deletion-follow-up">
                        Schedule Follow-up
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <DatePicker selected={holdGroupDeletion.followUpAt || ""}
                                className="form-control form-control-sm form-control-datepicker-sm"
                                placeholderText="Date"
                                disabled={!holdGroupDeletion.followUp}
                                onChange={
                                  (date) => {
                                    var updated = Object.assign({}, holdGroupDeletion, {
                                      followUpAt: date
                                    });
          
                                    holdGroupDeletionChanged(updated);
                                  }
                                } />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mt-3 text-right">
              <a href="#" onClick={
                (e) => {
                  e.preventDefault();
                  holdGroupDeletionChanged({});
                }
              }>
                Cancel
              </a>
              <button type="submit"
                      style={{
                        "borderRadius": "8px"
                      }}
                      disabled={!canSubmitHoldGroupDeletion(holdGroupDeletion)}
                      className="btn btn-primary btn-sm ml-2">
                 Confirm
              </button>
            </div>
          </form>
        </div>
      </Popup>
      <Popup
        open={(Object.keys(holdToConfirm).length > 0)}
        contentStyle={holdToConfirmModalContentStyle}
        closeOnDocumentClick={true}
        className={"popup-modal"}
        onClose={
          () => {
            holdToConfirmChanged({});
            userChangedDoorsTimeChanged(false);
            userChangedEventEndTimeChanged(false);
          }
        }
      >
        <div className="popup-modal-container">
          <div className="row m-0"
                style={{"borderBottom": "1px solid #f2f2f2"}}>
            <div className="col" style={{"padding": "25px"}}>
              <p className='mb-0' style={{"fontSize": "20px"}}>
                <strong>Confirm Event</strong>
              </p>
            </div>
            <div className="col-xs-auto d-flex align-items-center">
              <a href="#"
                className="text-muted"
                style={{
                  "paddingRight": "30px",
                  "fontSize": "20px"
                }}
                onClick={
                  (e) => {
                    e.preventDefault();
                    holdToConfirmChanged({})
                  }
                } >
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12" style={{"padding": "18px 25px"}}>
              {holdToConfirm.step === 2 ? (
                <form onSubmit={
                        (e) => {
                          e.preventDefault();

                          if(!canSubmitHoldToConfirmSave(holdToConfirm, isConvertingHoldToConfirm)){
                            return false;
                          }

                          convertHoldGroupToConfirm(csrfToken, team, holdToConfirm);
                        }
                      }>
                  <div className="form-group">
                    <label htmlFor="new-event-template" className="text-muted">
                      Template
                      <span className="text-optional pl-2">
                        (optional)
                      </span>
                    </label>
                    <AsyncSelect
                      cacheOptions
                      styles={selectStyles}
                      menuPortalTarget={document.querySelector('body')}
                      defaultOptions
                      id="new-event-template"
                      value={holdToConfirm.event_template ? holdToConfirm.event_template : null}
                      loadOptions={(inputValue, callback) => debouncedFetchEventTemplates(team, inputValue, callback)}
                      onChange={(option) => {
                        holdToConfirmChanged({...holdToConfirm, event_template: { value: option.value, label: option.label }})
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="start_date_and_time" className="text-muted">
                      Event Start Date/Time
                    </label>
                    <div className="flatpickr-date-input px-2 d-flex align-items-center">
                      <div className="pr-2" style={{"fontSize": "18px"}}>
                        <i className="far fa-calendar"></i>
                      </div>
                      <Flatpickr
                        options={{
                          enableTime: true,
                          dateFormat: flatPickrFormat,
                          enable: [canEnableDate]
                        }}
                        placeholder={"Select date"}
                        value={holdToConfirm.start_time}
                        onClose={dates => {
                          holdToConfirmChanged({...holdToConfirm, start_time: dates[0]});
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="doors_date_and_time" className="text-muted">
                      Doors Date/Time
                    </label>
                    <div className="flatpickr-date-input px-2 d-flex align-items-center">
                      <div className="pr-2" style={{"fontSize": "18px"}}>
                        <i className="far fa-calendar"></i>
                      </div>
                      <Flatpickr
                        options={{
                          enableTime: true,
                          dateFormat: flatPickrFormat,
                          enable: [canEnableDate]
                        }}
                        placeholder={"Select date"}
                        value={holdToConfirm.door_time}
                        onClose={dates => {
                          userChangedDoorsTimeChanged(true);
                          holdToConfirmChanged({...holdToConfirm, door_time: dates[0]});
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group" style={{"marginBottom": "20px"}}>
                    <label htmlFor="end_date_and_time" className="text-muted">
                      Event End Date/Time
                    </label>
                    <div className="flatpickr-date-input px-2 d-flex align-items-center">
                      <div className="pr-2" style={{"fontSize": "18px"}}>
                        <i className="far fa-calendar"></i>
                      </div>
                      <Flatpickr
                        options={{
                          enableTime: true,
                          dateFormat: flatPickrFormat,
                          enable: [canEnableDate]
                        }}
                        placeholder={"Select date"}
                        value={holdToConfirm.end_time}
                        onClose={dates => {
                          userChangedEventEndTimeChanged(true);
                          holdToConfirmChanged({...holdToConfirm, end_time: dates[0]});
                        }}
                      />
                    </div>
                  </div>
                  <button type="submit"
                          disabled={!canSubmitHoldToConfirmSave(holdToConfirm, isConvertingHoldToConfirm)}
                          className="btn btn-primary btn-block">
                    {isConvertingHoldToConfirm ? (
                      <React.Fragment>
                        <img src="/uploading-loading.gif"
                             className="mr-1"
                             style={{width: "16px"}} />
                        <strong>Saving...</strong>
                      </React.Fragment>
                    ) : (
                      <strong>Save</strong>
                    )}
                  </button>
                </form>
              ) : (
                <form>
                  <p className='text-muted'>Select the date you want to confirm.</p>
                  {confirm.hold_positions.sort((a, b) => {return new Date(a.start_time) - new Date(b.start_time)}).map((holdPosition, index) => (
                    <div key={index} className="custom-control custom-radio">
                      <input type="radio"
                              id={`confirm-hold-position-${holdPosition.id}`}
                              name="confirm-hold-position"
                              checked={holdToConfirm.selectedHoldPositionId === holdPosition.id}
                              onChange={
                                (e) => {
                                  var updated = Object.assign({}, holdToConfirm, {
                                    selectedHoldPositionId: holdPosition.id,
                                    start_time: new Date(holdPosition.start_time)
                                  });
        
                                  holdToConfirmChanged(updated);
                                }
                              }
                              className="custom-control-input" />
                      <label className="custom-control-label"
                              style={{"fontSize": "16px"}}
                              htmlFor={`confirm-hold-position-${holdPosition.id}`}>
                        <strong>
                          {moment(holdPosition.start_time).format("MM/DD/YY")} - {`H${holdPosition.position}`}
                        </strong>
                      </label>
                    </div>
                  ))}
                  <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();

                          if(!holdToConfirm.selectedHoldPositionId){
                            return false;
                          }

                          var updated = Object.assign({}, holdToConfirm, {
                            step: 2
                          });

                          holdToConfirmChanged(updated);
                        }
                      }
                      style={{"marginTop": "20px"}}
                      className={'btn btn-primary btn-block ' + (holdToConfirm.selectedHoldPositionId ? "" : "disabled")}>
                    <strong>Next</strong>
                  </a>
                </form>
              )}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

HoldActionMenu.propTypes = {
  confirm: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  holdGroupDeletion: PropTypes.object,
  holdGroupDeletionChanged: PropTypes.func.isRequired,
  deleteHoldGroup: PropTypes.func.isRequired,
  holdToConfirm: PropTypes.object,
  holdToConfirmChanged: PropTypes.func.isRequired,
  userChangedDoorsTimeChanged: PropTypes.func.isRequired,
  userChangedEventEndTimeChanged: PropTypes.func.isRequired,
  convertHoldGroupToConfirm: PropTypes.func.isRequired,
  isConvertingHoldToConfirm: PropTypes.bool
};

export default HoldActionMenu;
