import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import FilterPanel from '../../TableView/components/FilterPanel';
import DataTable from '../../DataTable/components/DataTable';
import { ToastContainer } from 'react-toastify';

var _ = require('lodash');

import { v4 as uuidv4 } from 'uuid';
const axios = require('axios').default;
import $ from 'jquery';
require('datatables.net-bs4');

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const PayoutManager = ({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  dataTableElChanged,
  filters,
  filtersChanged
}) => {
  const reduceFilters = (filters) => {
    return filters.filter((filter) => {
      return filter.value.length > 0;
    }).reduce((params, filter) => {
      var newParam = {};
      newParam[filter.paramName] = filter.value;
  
      return Object.assign(params, newParam);
    }, {});
  }

  const reducedFilters = useMemo(() => {
    return (
      reduceFilters(filters)
    );
  }, [
    filters
  ]);

  const updateFilter = (filter, value) => {
    var updated = filters.map((item) => {
      if (item.paramName === filter.paramName) {
        var arrayValue = Array(value).flat();
        var newItem = Object.assign({}, item, {value: arrayValue});

        return newItem;
      }

      return item;
    })

    filtersChanged(updated);
  }

  const debouncedUpdateFilter = _.debounce((filter, value) => {
    updateFilter(filter, value);
  }, 200)

  return (
    <div>
      <ToastContainer />
      <FilterPanel filters={filters}
                    clearFilterValues={
                      () => {
                        var updated = filters.map((item) => {
                          return Object.assign({}, item, {value: []})
                        })

                        filtersChanged(updated);
                      }
                    }
                    debouncedUpdateFilter={debouncedUpdateFilter}
                    updateFilter={updateFilter} />
      <DataTable title={dataTableTitle}
                  dataSource={dataTableDataSource}
                  columns={dataTableColumns}
                  colGroup={
                    <colgroup>
                      <col span={1} style={{"width": "10%"}} />
                      <col span={1} style={{"width": "8%"}} />
                      <col span={1} style={{"width": "15%"}} />
                      <col span={1} style={{"width": "18%"}} />
                      <col span={1} style={{"width": "24%"}} />
                      <col span={1} style={{"width": "10%"}} />
                      <col span={1} style={{"width": "10%"}} />
                      <col span={1} style={{"width": "5%"}} />
                    </colgroup>
                  }
                  onTableElementSet={
                    (el) => {
                      dataTableElChanged(el);
                    }
                  }
                  reducedFilters={reducedFilters}
                  order={dataTableOrder} />
    </div>
  )
};

PayoutManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  filters: PropTypes.array,
  filtersChanged: PropTypes.func.isRequired
};

export default PayoutManager;
