// Simple example of a React "smart" component

import { connect } from 'react-redux';
import AddOutreach from '../components/AddOutreach';
import * as actions from '../actions/addOutreachActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentUser: state.currentUser,
  venuesOptions: state.venuesOptions,
  venueSelected: state.venueSelected,
  team: state.team,
  venueOwnerships: state.venueOwnerships,
  lastPipelineEvent: state.lastPipelineEvent,
  artist: state.artist,
  outreach: state.outreach,
  outreachTemplates: state.outreachTemplates,
  pipelineEvents: state.pipelineEvents,
  // fetchPipelineEventsForArtistOnInit: state.fetchPipelineEventsForArtistOnInit,
  unseenPipelineEvents: state.unseenPipelineEvents
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNewOutreach: (team, artist) => dispatch(actions.fetchNewOutreach(dispatch, team, artist)),
    outreachChanged: (outreach) => dispatch(actions.outreachChanged(outreach)),
    lastPipelineEventChanged: (pipelineEvent) => dispatch(actions.lastPipelineEventChanged(pipelineEvent)),
    // fetchPipelineEventsForArtist: (team, artist) => dispatch(actions.fetchPipelineEventsForArtist(dispatch, team, artist)),
    pipelineEventsChanged: (pipelineEvents) => dispatch(actions.pipelineEventsChanged(pipelineEvents)),
    fetchUnseenPipelineEvents: (team, artist) => dispatch(actions.fetchUnseenPipelineEvents(dispatch, team, artist)),
    unseenPipelineEventsChanged: (pipelineEvents) => dispatch(actions.unseenPipelineEventsChanged(pipelineEvents))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(AddOutreach);
