import { combineReducers } from 'redux';

import {
  DATA_TABLE_EL_CHANGED,
  TEXT_MESSAGE_CHANGED,
  IS_SUBMITTING_CHANGED,
  ESTIMATED_NUMBER_OF_SENDS_CHANGED
} from '../constants/textMessagesConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const searchedSearchTerm = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const textMessage = (state = {}, action) => {
  switch (action.type) {
    case TEXT_MESSAGE_CHANGED:
      return action.textMessage;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isSubmitting = (state = false, action) => {
  switch (action.type) {
    case IS_SUBMITTING_CHANGED:
      return action.isSubmitting;
    default:
      return state;
  }
};

const estimatedNumberOfSends = (state = 0, action) => {
  switch (action.type) {
    case ESTIMATED_NUMBER_OF_SENDS_CHANGED:
      return action.estimatedNumberOfSends;
    default:
      return state;
  }
};

const hasTextMessages = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const textMessagesReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  searchedSearchTerm,
  textMessage,
  venueOwnerships,
  isSubmitting,
  estimatedNumberOfSends,
  hasTextMessages,
});

export default textMessagesReducer;
