import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {DebounceInput} from 'react-debounce-input';
import { ToastContainer, toast } from 'react-toastify';

import Select from 'react-select'
import FolderViewApp from '../../FolderView/startup/FolderViewApp';

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "white",
    minWidth: "258px",
    zIndex: "999"
  }),
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "white",
      border: "none",
      boxShadow: 'none'
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "white"
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      fontWeight: "bold",
      color: "#1982C4"
    };
  },
  indicatorSeparator: (styles, { data }) => {
    return {
      ...styles,
      display: "none"
    };
  },
  indicatorsContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: "#1982C4",
      fontWeight: "bold",
      fontSize: "16px",
      ':hover': {
        cursor: "pointer"
      }
    };
  },
  dropdownIndicator: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: "#1982C4",
      fontWeight: "bold"
    };
  }
};

const EventsAssetCenter = ({
  csrfToken,
  currentUser,
  team,
  venueOwnerships,
  selectedVenueOwnership,
  selectedVenueOwnershipChanged,
  folder,
  folderChanged,
  folderOptions,
  searchTerm,
  searchTermChanged,
  teamRootFolder
}) => {
  return (
    <div className="position-relative">
      <ToastContainer />
      {folder.foldered_items_count > 0 ? (
        <div className="row">
          <div className="col-12 d-flex justify-content-end position-absolute"
                style={{
                  "top": "-45px",
                  "right": "-15px"
                }}>
            <form style={{"zIndex": "2"}}>
              <div className="form-group mb-0"
                  style={{"width": "320px"}}>
                <div className="input-group mb-0">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white"
                          style={{
                            "fontSize": "14px",
                            "border": "2px solid #f7f7f7",
                            "borderRight": "none"
                          }}>
                      <i className="far fa-search text-muted"></i>
                    </span>
                  </div>
                  <DebounceInput type="text"
                                className="form-control pl-0"
                                minLength={2}
                                debounceTimeout={300}
                                style={{
                                  "fontSize": "14px",
                                  "border": "2px solid #f7f7f7",
                                  "borderLeft": "none"
                                }}
                                value={searchTerm}
                                onChange={
                                  (e) => {
                                    searchTermChanged(e.target.value);
                                  }
                                }
                                placeholder="Search assets..." />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      <div className="row" style={{"marginTop": "12px"}}>
        <div className="col-12">
          <div className="card no-border shadow-2">
            <FolderViewApp csrfToken={csrfToken}
                                folder={folder}
                                teamRootFolder={teamRootFolder}
                                canSelectRootFolderAsTarget={false}
                                onFolderChanged={folderChanged}
                                currentUser={{...currentUser}}
                                confirm={{}}
                                guestTeamMemberships={[]}
                                promotersWithPermission={[]}
                                searchTerm={searchTerm}
                                showConfirmIdColumn={true}
                                headerComponent={
                                  () => (
                                    <Select
                                      styles={selectStyles}
                                      components={{ ClearIndicator:() => null }}
                                      options={
                                        venueOwnerships.map(function(venueOwnership){
                                          return { value: venueOwnership.id, label: venueOwnership.venueable.name }
                                        })
                                      }
                                      value={
                                        Object.keys(selectedVenueOwnership).length > 0 ? (
                                          {value: selectedVenueOwnership.id, label: selectedVenueOwnership.venueable.name}
                                        ) : ""
                                      }
                                      onChange={
                                        (selected) => {
                                          var updated = venueOwnerships.find((vo) => vo.id == selected.value);
                                          var newFolder = folderOptions.find((f) => f.foldable_id == updated.id);

                                          selectedVenueOwnershipChanged({...updated});
                                          folderChanged({...newFolder});
                                        }
                                      }
                                    />
                                  )
                                }
                                team={team} />
          </div>
        </div>
      </div>
    </div>
  )
};

EventsAssetCenter.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  venueOwnerships: PropTypes.array,
  selectedVenueOwnership: PropTypes.object,
  selectedVenueOwnershipChanged: PropTypes.func.isRequired,
  folder: PropTypes.object,
  folderChanged: PropTypes.func.isRequired,
  folderOptions: PropTypes.array,
  searchTerm: PropTypes.string,
  searchTermChanged: PropTypes.func.isRequired,
  teamRootFolder: PropTypes.object.isRequired
};

export default EventsAssetCenter;
