import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import linkOfferModalReducer from '../reducers/linkOfferModalReducer';

const configureStore = (railsProps) => (
  createStore(linkOfferModalReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
