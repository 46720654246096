import { combineReducers } from 'redux';

import {
  EXAMPLE
} from '../constants/offerV2SettlementConstants';

export const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const offer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerV2SettlementReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  offer,
  onClose
});

export default offerV2SettlementReducer;
