import PropTypes from 'prop-types';
import React, { useEffect, forwardRef } from 'react';

import BudgetBonusRow from './BudgetBonusRow';
import FlipMove from 'react-flip-move';

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

var _ = require('lodash');
var debouncedAutoSaveBudgetBonus;

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const sortedBudgetBonuses = (budgetBonuses) => {
  return budgetBonuses.sort((first, second) => {
    var firstTicketCount = (first.ticket_count_requirement ? parseFloatFromString(first.ticket_count_requirement) : null);
    var secondTicketCount = (second.ticket_count_requirement ? parseFloatFromString(second.ticket_count_requirement) : null);

    if (firstTicketCount === null) {
      return 1;
    }

    if (secondTicketCount === null) {
      return -1;
    }

    if (firstTicketCount === secondTicketCount) {
      return 0;
    }

    return firstTicketCount < secondTicketCount ? -1 : 1;
  });
}

const FunctionalBudgetBonusRow = forwardRef((props, ref) => (
  <div ref={ref}>
    <BudgetBonusRow {...props} />
  </div>
));

const BudgetBonusManager = ({
  csrfToken,
  team,
  currentUser,
  budget,
  updateBudget,
  debouncedAutoSaveBudget,
  createBudgetBonus,
  deleteBudgetBonus,
  autoSaveBudgetBonus
}) => {
  useEffect(() => {
    debouncedAutoSaveBudgetBonus = _.debounce((csrfToken, team, budgetBonus) => {
      autoSaveBudgetBonus(csrfToken, team, budgetBonus);
    }, 600);
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <FlipMove>
          {sortedBudgetBonuses(budget.budget_bonuses).map((budgetBonus, index) =>
            <FunctionalBudgetBonusRow key={budgetBonus.id}
                                      csrfToken={csrfToken}
                                      team={team}
                                      currentUser={currentUser}
                                      budget={budget}
                                      budgetBonus={budgetBonus}
                                      deleteBudgetBonus={deleteBudgetBonus}
                                      updateBudget={updateBudget}
                                      debouncedAutoSaveBudgetBonus={debouncedAutoSaveBudgetBonus} />
          )}
        </FlipMove>
      </div>
      <div className="col-12"
           style={{
             "marginTop": budget.budget_bonuses.length === 0 ? "-5px" : "20px",
             "marginBottom": "27px"
           }}>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               createBudgetBonus(csrfToken, team, budget);
             }
           }
           className="text-primary">
          <strong><i className="fal fa-plus"></i> Add Bonus</strong>
        </a>
      </div>
    </div>
  );
};

BudgetBonusManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  budget: PropTypes.object.isRequired,
  updateBudget: PropTypes.func.isRequired,
  debouncedAutoSaveBudget: PropTypes.func,
  createBudgetBonus: PropTypes.func.isRequired,
  deleteBudgetBonus: PropTypes.func.isRequired,
  autoSaveBudgetBonus: PropTypes.func.isRequired
};

export default BudgetBonusManager;
