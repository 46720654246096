import React from "react";

import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";
import ColorSelect from "./ColorSelect";
import SocialMediaInputs from "./SocialMediaInputs";
import LogoSelect from "./LogoSelect";
import FontSelect from "./FontSelect";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 10,
};

const labelStyle = {
  fontSize: 14,
  color: "#333333",
  fontWeight: 700,
  marginBottom: 8,
};

const selectStyle = {
  width: 250,
  height: 40,
  padding: 8,
  fontSize: 14,
  color: "#333333",
  border: "1px solid #B3B3B3",
  borderRadius: 4,
};

const hrStyle = {
  borderTop: "1px solid #CCCCCC",
};

const VenueOwnershipForm = () => {
  const {
    availableTimezones,
    formState,
    setFormState,
  } = useMarketingSettingsContext();

  const handleTimezoneChange = (e) => {
    setFormState((prev) => ({
      ...prev,
      time_zone: e.target.value,
    }));
  };

  return (
    <form style={cardStyle}>
      <hr style={hrStyle} />
      <div>
        <div style={labelStyle}>Timezone</div>
        <select value={formState.time_zone || ""} onChange={handleTimezoneChange} style={selectStyle}>
          {availableTimezones.map(timezone => (
            <option value={timezone} key={timezone}>{timezone}</option>
          ))}
        </select>
      </div>
      <hr style={hrStyle} />
      <LogoSelect />
      <hr style={hrStyle} />
      <SocialMediaInputs />
      <hr style={hrStyle} />
      <ColorSelect />
      <hr style={hrStyle} />
      <FontSelect />
    </form>
  );
};

export default VenueOwnershipForm;
