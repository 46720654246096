// Simple example of a React "smart" component

import { connect } from 'react-redux';
import FollowUpManager from '../components/FollowUpManager';
import * as actions from '../actions/followUpManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  dataTableTitle: state.dataTableTitle,
  dataTableDataSource: state.dataTableDataSource,
  dataTableOrder: state.dataTableOrder,
  dataTableColumns: state.dataTableColumns,
  dataTableEl: state.dataTableEl,
  exampleArtists: state.exampleArtists,
  followUpsExists: state.followUpsExists,
  followUp: state.followUp,
  pipeline: state.pipeline,
  newOutreach: state.newOutreach,
  venueOwnerships: state.venueOwnerships,
  outreachTemplates: state.outreachTemplates
});

const mapDispatchToProps = (dispatch) => {
  return {
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    followUpExistsChanged: (followUpsExists) => dispatch(actions.followUpExistsChanged(followUpsExists)),
    followUpChanged: (followUp) => dispatch(actions.followUpChanged(followUp)),
    fetchNewFollowUp: (team) => dispatch(actions.fetchNewFollowUp(dispatch, team)),
    deleteFollowUp: (team, followUp, csrfToken, rowTableEl) => dispatch(actions.deleteFollowUp(dispatch, team, followUp, csrfToken, rowTableEl)),
    completeFollowUp: (team, followUp, csrfToken, rowTableEl) => dispatch(actions.completeFollowUp(dispatch, team, followUp, csrfToken, rowTableEl)),
    fetchEditFollowUp: (team, followUp) => dispatch(actions.fetchEditFollowUp(dispatch, team, followUp)),
    fetchPipeline: (team, pipelineId) => dispatch(actions.fetchPipeline(dispatch, team, pipelineId)),
    pipelineChanged: (pipeline) => dispatch(actions.pipelineChanged(pipeline)),
    newOutreachChanged: (outreach) => dispatch(actions.newOutreachChanged(outreach)),
    fetchNewOutreach: (team, followUp) => dispatch(actions.fetchNewOutreach(dispatch, team, followUp))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(FollowUpManager);
