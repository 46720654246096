import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import {DebounceInput} from 'react-debounce-input';
import FolderViewApp from '../../FolderView/startup/FolderViewApp';

const GlobalAssetCenter = ({
  csrfToken,
  currentUser,
  team,
  folder,
  folderChanged,
  guestTeamMemberships,
  promotersWithPermission,
  searchTerm,
  searchTermChanged,
  teamRootFolder
}) => {
  return (
    <div className="position-relative">
      <ToastContainer />
      {folder.foldered_items_count > 0 ? (
        <div className="row">
          <div className="col-12 d-flex justify-content-end position-absolute"
                style={{
                  "top": "-45px",
                  "right": "-15px"
                }}>
            <form style={{"zIndex": "2"}}>
              <div className="form-group mb-0"
                  style={{"width": "320px"}}>
                <div className="input-group mb-0">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white"
                          style={{
                            "fontSize": "14px",
                            "border": "2px solid #f7f7f7",
                            "borderRight": "none"
                          }}>
                      <i className="far fa-search text-muted"></i>
                    </span>
                  </div>
                  <DebounceInput type="text"
                                className="form-control pl-0"
                                minLength={2}
                                debounceTimeout={300}
                                style={{
                                  "fontSize": "14px",
                                  "border": "2px solid #f7f7f7",
                                  "borderLeft": "none"
                                }}
                                value={searchTerm}
                                onChange={
                                  (e) => {
                                    searchTermChanged(e.target.value);
                                  }
                                }
                                placeholder="Search assets..." />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      <div className="row" style={{"marginTop": "12px"}}>
        <div className="col-12">
          <div className="card no-border shadow-2">
            <FolderViewApp csrfToken={csrfToken}
                                folder={folder}
                                teamRootFolder={teamRootFolder}
                                onFolderChanged={folderChanged}
                                currentUser={{...currentUser}}
                                confirm={{}}
                                searchTerm={searchTerm}
                                guestTeamMemberships={guestTeamMemberships}
                                promotersWithPermission={promotersWithPermission}
                                headerComponent={
                                  () => (
                                    <span>&nbsp;</span>
                                  )
                                }
                                team={team} />
          </div>
        </div>
      </div>
    </div>
  )
};

GlobalAssetCenter.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  folder: PropTypes.object,
  folderChanged: PropTypes.func.isRequired,
  guestTeamMemberships: PropTypes.array,
  promotersWithPermission: PropTypes.array,
  searchTerm: PropTypes.string,
  searchTermChanged: PropTypes.func.isRequired,
  teamRootFolder: PropTypes.object.isRequired
};

export default GlobalAssetCenter;
