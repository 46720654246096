import { combineReducers } from 'redux';

import {
  FINANCIAL_CONNECTION_ACCOUNTS_CHANGED,
  FINANCIAL_CONNECTION_ACCOUNT_DELETED
} from '../constants/refundSettingsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const stripePublishableKey = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const financialConnectionAccounts = (state = [], action) => {
  switch (action.type) {
    case FINANCIAL_CONNECTION_ACCOUNTS_CHANGED:
      return action.financialConnectionAccounts;
    case FINANCIAL_CONNECTION_ACCOUNT_DELETED:
      return state.filter((fca) => fca.id !== action.financialConnectionAccount.id);
    default:
      return state;
  }
};

const refundSettingsReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  stripePublishableKey,
  financialConnectionAccounts
});

export default refundSettingsReducer;
