import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import MaskedInput from 'react-text-mask'

const PhoneCustomFieldValue = ({
  team,
  csrfToken,
  confirm,
  customFieldValue,
  customFieldValueChanged
}) => (
  <div className="col-6">
    <MaskedInput className="form-control"
                  style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                  placeholder={customFieldValue.custom_field.name + (customFieldValue.custom_field.required ? "" : " (optional)")}
                  guide={false}
                  onChange={
                    (e) => {
                      var updated = Object.assign({}, customFieldValue, {value: e.target.value});
                      customFieldValueChanged(updated);
                    }
                  }
                  value={customFieldValue.value || ""}
                  mask={['(',/[1-9]/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/]} />
  </div>
);

PhoneCustomFieldValue.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  customFieldValue: PropTypes.object.isRequired,
  customFieldValueChanged: PropTypes.func.isRequired
};

export default PhoneCustomFieldValue;
