import { combineReducers } from 'redux';
import {
} from '../constants/ArtistPipelinesConstants';

const title = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pipelines = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ArtistPipelinesReducer = combineReducers({ title, pipelines, });

export default ArtistPipelinesReducer;
