import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import TableSearch from '../TableSearch';
import DataTable from '../../../DataTable/components/DataTable';
import FilterPanel from '../FilterPanel';
import ExportCSV from '../ExportCSV';

import $ from 'jquery';
require('datatables.net-bs4');

const InlineLayout = ({
  title,
  searchPlaceholder,
  typedSearchTerm,
  searchedSearchTerm,
  startSearchTermUpdate,
  dataSource,
  columns,
  order,
  filters,
  updateFilter,
  debouncedUpdateFilter,
  clearFilterValues,
  tableClassNames,
  csvDataSource,
  dataTableEl,
  dataTableElChanged,
  layout,
  reduceFilters
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 mb-3 mb-md-0 d-flex align-items-center">
          <p className="mb-0" style={{"fontSize": "20px"}}>
            <strong>
              {title}
              {Object.keys(dataTableEl).length > 0 ? (
                <span className="text-muted">
                  &nbsp;({$(dataTableEl).DataTable().page.info().recordsTotal.toLocaleString("en-US")})
                </span>
              ) : null}
            </strong>
          </p>
        </div>
        <div className="col-12 col-md-6 text-left text-md-right">
          <div className="row">
            {searchPlaceholder.length ? (
              <div className="col">
                <div className="table-search-container">
                  <TableSearch searchPlaceholder={searchPlaceholder}
                              typedSearchTerm={typedSearchTerm}
                              startSearchTermUpdate={startSearchTermUpdate} />
                </div>
              </div>
            ) : null}
            {csvDataSource && csvDataSource.length > 0 ? (
              <div className="col-xs-auto"
                   style={{"paddingRight": "15px"}}>
                <div className="export-csv-container">
                  <ExportCSV csvDataSource={csvDataSource}
                             dataTableFilters={reduceFilters(filters)}
                             dataTableEl={dataTableEl.current} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {filters.length > 0 ? (
        <FilterPanel filters={filters}
                    clearFilterValues={clearFilterValues}
                    debouncedUpdateFilter={debouncedUpdateFilter}
                    updateFilter={updateFilter} />
      ) : null}
      <div className="row">
        <div className="col-12">
          <div key={filters.map(filter => filter.value)}>
            <DataTable
              title={""}
              searchedSearchTerm={searchedSearchTerm}
              dataSource={dataSource}
              columns={columns}
              reducedFilters={reduceFilters(filters)}
              tableClassNames={tableClassNames}
              onInitComplete={(settings, json) => {
                dataTableEl.current = settings.oInstance[0];
                dataTableElChanged(dataTableEl.current);
              }}
              order={order}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

InlineLayout.propTypes = {
  title: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  typedSearchTerm: PropTypes.string,
  searchedSearchTerm: PropTypes.string,
  startSearchTermUpdate: PropTypes.func.isRequired,
  dataSource: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  order: PropTypes.array,
  filters: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
  debouncedUpdateFilter: PropTypes.func.isRequired,
  clearFilterValues: PropTypes.func.isRequired,
  tableClassNames: PropTypes.string,
  csvDataSource: PropTypes.string,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  layout: PropTypes.string,
  reduceFilters: PropTypes.func.isRequired
};

export default InlineLayout;
