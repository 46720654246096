import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import SocialPostStatusBars from '../../../bundles/SocialPostStatusBars/components/SocialPostStatusBars';
import DataTable from '../../DataTable/components/DataTable';

import jsxToString from 'jsx-to-string';
var ReactDOM = require('react-dom');
import Popup from "reactjs-popup";
import { ToastContainer } from 'react-toastify';

import $ from 'jquery';
require('datatables.net-bs4');

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "190px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const PostedPosts = ({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  dataTableElChanged,
  searchedSearchTerm,
  deletePost,
  authorizedPages,
  hasPosts
}) => {
  return (
    <div>
      <ToastContainer />
      {authorizedPages.length === 0 ? (
        <div className='row'>
          <div className='col-12'>
            <div className='card border-0 shadow-2'>
              <div className='card-body'>
                <p style={{'fontSize': '20px'}}>
                  <strong>
                    Already Posted&nbsp;
                    <span className='text-muted'>(0)</span>
                  </strong>
                </p>
              </div>
              <div className='card-body text-center' style={{'paddingBottom': '456px', "paddingTop": "11px"}}>
                <div className='dot d-flex justify-content-center align-items-center'
                      style={{'margin': '0px auto 10px auto', 'width': '88px', 'height': '88px', 'background': '#F3F9FC', 'borderRadius': '50%', 'border': '4px solid #BBE0F6'}}>
                  <i className='fas fa-cog text-primary' style={{'fontSize': '42px'}}></i>
                </div>
                <p className='mb-1' style={{'fontSize': '20px'}}>
                  <strong>Set up Social Accounts</strong>
                </p>
                <p className='text-muted mb-3' style={{'maxWidth': '245px', 'margin': '0 auto'}}>
                  Your social accounts aren’t set up yet. Configure your accounts in order to schedule social posts.
                </p>
                {currentUser.can_manage_authorized_pages ? (
                  <a href={"/integrations"}
                      className='btn btn-primary'
                      style={{'borderRadius': '4px', 'fontSize': '14px'}}>
                    <strong>Configure Social Accounts</strong>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : !hasPosts ? (
        <div className='row'>
          <div className='col-12'>
            <div className='card border-0 shadow-2'>
              <div className='card-body'>
                <p style={{'fontSize': '20px'}}>
                  <strong>
                    Already Posted&nbsp;
                    <span className='text-muted'>(0)</span>
                  </strong>
                </p>
              </div>
              <div className='card-body text-center' style={{'paddingBottom': '456px', "paddingTop": "11px"}}>
                <div className='dot d-flex justify-content-center align-items-center'
                      style={{'margin': '0px auto 10px auto', 'width': '88px', 'height': '88px', 'background': '#F3F9FC', 'borderRadius': '50%', 'border': '4px solid #BBE0F6'}}>
                  <i className='far fa-comment-times text-primary' style={{'fontSize': '42px'}}></i>
                </div>
                <p className='mb-1' style={{'fontSize': '20px'}}>
                  <strong>Nothing Posted Yet</strong>
                </p>
                <p className='text-muted mb-3' style={{'maxWidth': '245px', 'margin': '0 auto'}}>
                  Already posted social posts will be listed here.
                </p>
                {currentUser.can_manage_posts ? (
                  <a href={`/teams/${team.id}/posts/scheduled?onp=true`}
                      className="btn btn-primary"
                      style={{'borderRadius': '4px', 'fontSize': '14px'}}>
                    <strong>Schedule a Post</strong>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DataTable title={dataTableTitle}
                 dataSource={dataTableDataSource}
                 columns={dataTableColumns}
                 colGroup={
                   <colgroup>
                     <col span={1} style={{"width": "20%"}} />
                     <col span={1} style={{"width": "45%"}} />
                     <col span={1} style={{"width": "10%"}} />
                     <col span={1} style={{"width": "20%"}} />
                     <col span={1} style={{"width": "5%"}} />
                   </colgroup>
                 }
                 onTableElementSet={
                   (el) => {
                     dataTableElChanged(el);
                   }
                 }
                 searchedSearchTerm={searchedSearchTerm}
                 rowCallback={
                    function(row, data, displayNum, displayIndex, dataIndex){
                      let dataTable = this;
                      let api       = dataTable.api();
                      let post      = JSON.parse(decodeHtml(data.json));
                      let postId    = ("post-datatable-row-" + post.id);
                      let child     = jsxToString(<div id={postId}></div>);
                      let tableRow  = api.row(dataIndex);

                      row.classList.add("parent-datatable-row");
                      tableRow.child(child, "child-datatable-row").show();

                      setTimeout(() => {
                        var mountSecondRow = document.getElementById(postId);
                        var secondRowTd = mountSecondRow.parentElement;
                        var secondRowTr = secondRowTd.parentElement;

                        secondRowTd.classList.add("social-post-metrics");
                        secondRowTd.classList.add("small");
                        secondRowTd.classList.add("pt-0");
                        secondRowTd.classList.add("pb-0");

                        secondRowTr.onmouseenter = () => {
                          row.classList.add("force-hover");
                        }

                        secondRowTr.onmouseleave = () => {
                          row.classList.remove("force-hover");
                        }

                        ReactDOM.render((
                          <SocialPostStatusBars post={post} />
                        ), mountSecondRow);
                      }, 1)

                      var mountPostMenuReact = row.getElementsByClassName("post-popup-mount-react")[0];
                      ReactDOM.render((
                        <div>
                          {currentUser.can_manage_posts ? (
                            <Popup arrow={false}
                                   offsetY={5}
                                   position="bottom right"
                                   contentStyle={popupContentStyle}
                                   onOpen={
                                     (e) => {
                                       e.preventDefault();
                                     }
                                   }
                                   trigger={open => (
                                     <a href="#"
                                        className="text-muted">
                                       <i className="fas fa-ellipsis-v"></i>
                                     </a>
                                   )} >
                              {close => (
                                <div className='row text-left'>
                                  <div className="col-12">
                                    <ul className="list-group offer-inline-menu highlight-primary">
                                      <li className="list-group-item delete-offer-li">
                                        <Popup
                                            trigger={
                                              <a href="#" className="text-danger">
                                                <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                  <i className="fas fa-trash"></i>
                                                </span>
                                                Remove
                                              </a>
                                            }
                                            modal
                                            onOpen={
                                              (e) => {
                                                e.preventDefault();
                                              }
                                            }
                                            contentStyle={deleteModalContentStyle}
                                            closeOnDocumentClick
                                          >
                                          {closeDeletePopup => (
                                            <div>
                                              <div className="row">
                                                <div className="col">
                                                  <div className="row">
                                                    <div className="col">
                                                      <h5 style={{"marginBottom": "16px"}}>
                                                        <div className="strong">Remove post?</div>
                                                      </h5>
                                                    </div>
                                                    <div className="col-xs-auto">
                                                      <a href="#"
                                                         className="text-dark"
                                                         style={{"paddingRight": "15px"}}
                                                         onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                        <i className="fa fa-times"></i>
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <p>You're about to remove this post.</p>
                                                  <p>This cannot be undone.</p>
                                                  <p>Are you sure?</p>
                                                  <div className="text-right">
                                                    <a href="#"
                                                       onClick={
                                                         (e) => {
                                                           e.preventDefault();
                                                           closeDeletePopup();
                                                         }
                                                       }>
                                                      Cancel
                                                    </a>
                                                    <a href="#"
                                                       className="btn btn-danger ml-3"
                                                       onClick={
                                                         (e) => {
                                                           e.preventDefault();
                                                           closeDeletePopup();
                                                           close();
                                                           deletePost(csrfToken, team, post, dataTable);
                                                         }
                                                       }>
                                                      Remove
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Popup>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          ) : null}
                        </div>
                      ), mountPostMenuReact);
                    }
                 }
                 order={dataTableOrder} />
      )}
    </div>
  )
};

PostedPosts.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  searchedSearchTerm: PropTypes.string,
  deletePost: PropTypes.func.isRequired,
  authorizedPages: PropTypes.array,
  hasPosts: PropTypes.bool
};

export default PostedPosts;
