import React from "react";
import Popup from "reactjs-popup";
import axios from "axios";

import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";
import { toastSuccess, toastError } from "../../../../shared/toastHelper";
import SendTestOverlay from "./SendTestOverlay";

const ellipsisButtonStyle = {
  backgroundColor: "transparent",
  border: "none",
  color: "#333333",
  opacity: 0.5,
};

const menuLineStyle = {
  display: "flex",
  flexDirection: "row",
  fontSize: "12px",
  fontWeight: "normal",
  margin: "5px",
  paddingRight: "10px",
};

const menuOptionStyle = {
  width: "28px",
};

const popupContentStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "0px",
  width: "fit-content",
};

const RowKebabMenu = ({ sendDomain }) => {
  const {
    csrfToken,
    team,
    venueOwnership,
    setSendDomains,
    venueOwnerships,
    setVenueOwnerships,
    setTestModalOpen
  } = useMarketingSettingsContext();

  const deleteSendDomain = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.delete(`/teams/${team.id}/venue_ownerships/${venueOwnership.id}/send_domains/${sendDomain.id}`)
      .then(() => {
        toastSuccess("Send domain deleted successfully");

        const newSendDomains = venueOwnership.send_domains.filter((domain) => {
          return domain.id !== sendDomain.id;
        });

        setSendDomains(newSendDomains);
        setVenueOwnerships(
          venueOwnerships.map((ownership) => {
            return ownership.id === venueOwnership.id
              ? { ...venueOwnership, send_domains: newSendDomains }
              : ownership;
          })
        );
      })
      .catch((_error) => {
        toastError("Could not delete send domain, please try again");
      });
  };

  return (
    <>
      <SendTestOverlay
        venueOwnership={venueOwnership}
        sendDomain={sendDomain}
      />
      <Popup
        arrow={false}
        offsetY={5}
        position="bottom right"
        contentStyle={popupContentStyle}
        onOpen={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        trigger={(_open) => (
          <button style={ellipsisButtonStyle}>
            <i className="far fa-ellipsis-v" />
          </button>
        )}
      >
        {(close) => (
          <div className="row text-left">
            <div className="col-12">
              <ul className="list-group offer-inline-menu highlight-primary">
                {sendDomain.verified ? (
                  <li className="list-group-item">
                    <a
                      className="text-muted"
                      style={menuLineStyle}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setTestModalOpen(true);
                        close();
                      }}
                    >
                      <span
                        className="d-inline-block text-center"
                        style={menuOptionStyle}
                      >
                        <i className="far fa-envelope"></i>
                      </span>
                      Send Test
                    </a>
                  </li>
                ) : null}
                <li className="list-group-item">
                  <a
                    className="text-muted"
                    style={menuLineStyle}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (window.confirm("Are you sure you want to delete this send domain?")) {
                        deleteSendDomain();
                        close();
                      }
                    }}
                  >
                    <span
                      className="d-inline-block text-center"
                      style={menuOptionStyle}
                    >
                      <i className="far fa-trash"></i>
                    </span>
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default RowKebabMenu;
