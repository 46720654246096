// Simple example of a React "smart" component

import { connect } from 'react-redux';
import TableView from '../components/TableView';
import * as actions from '../actions/tableViewActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  title: state.title,
  searchPlaceholder: state.searchPlaceholder,
  typedSearchTerm: state.typedSearchTerm,
  searchedSearchTerm: state.searchedSearchTerm,
  dataSource: state.dataSource,
  columns: state.columns,
  order: state.order,
  filters: state.filters,
  tableClassNames: state.tableClassNames,
  csvDataSource: state.csvDataSource,
  dataTableEl: state.dataTableEl,
  layout: state.layout
});

const mapDispatchToProps = (dispatch) => {
  return {
    startSearchTermUpdate: (state) => dispatch(actions.startSearchTermUpdate(dispatch, state)),
    updateSearchedSearchTerm: (state) => dispatch(actions.updateSearchedSearchTerm(state)),
    updateTypedSearchTerm: (state) => dispatch(actions.updateTypedSearchTerm(state)),
    updateFilter: (filter, value) => dispatch(actions.updateFilter(filter, value)),
    debouncedUpdateFilter: (filter, value) => dispatch(actions.debouncedUpdateFilter(dispatch, filter, value)),
    clearFilterValues: () => dispatch(actions.clearFilterValues()),
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(TableView);
