import React, { useState, useRef } from "react";

import SampleDataPicker from "./SampleDataPicker";
import { useActionContext } from "./ActionContainer";

const containerStyle = {
  background: "white",
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "grid",
};

const editColumnStyle = {
  borderLeft: "1px solid #EEEEEE",
  display: "flex",
  flexDirection: "column",
  fontSize: "14px",
};

const detailRowStyle = {
  borderBottom: "1px solid #EEEEEE",
  padding: "10px",
};

const textAreaStyle = {
  border: "none",
  height: "100%",
  width: "100%",
  padding: "10px",
  resize: "none",
  height: "100%",
};

const previewColumnStyle = {
  borderLeft: "1px solid #EEEEEE",
  display: "flex",
  flexDirection: "column",
  fontSize: "14px",
};

const previewContainerStyle = {
  display: "flex",
  flexDirection: "column",
  padding: "10px",
  gap: "5px",
};

const characterCountStyle = {
  color: "#333333",
  fontSize: "12px",
  opacity: 0.5,
  textAlign: "right",
  width: "100%",
  marginBottom: "10px",
};

export const messageStyle = {
  background: "#E6E6E6",
  borderRadius: "25px 25px 25px 0px",
  lineHeight: "16px",
  width: "85%",
  padding: "10px 20px",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
};

const dataPickerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  padding: "15px",
};

const mergeTagsButtonStyle = {
  bottom: "10px",
  color: "#1982C4",
  cursor: "pointer",
  fontWeight: 500,
  position: "absolute",
  right: "10px",
};

const mergeTagsContainerStyle = {
  backgroundColor: "#FFFFFF",
  borderRadius: "5px",
  bottom: "-105",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  height: "115px",
  overflow: "scroll",
  position: "absolute",
  right: "10px",
  width: "fit-content",
  zIndex: 1000,
};

const mergeTagStyle = {
  cursor: "pointer",
  fontSize: 10,
  fontWeight: 600,
  padding: "5px 10px",
};

const imageContainerStyle = {
  alignItems: "center",
  borderRadius: "25px 25px 25px 25px",
  display: "flex",
  height: "auto",
  justifyContent: "center",
  overflow: "hidden",
  width: "85%",
};

const imageStyle = {
  height: "auto",
  verticalAlign: "middle",
  width: "100%",
};

const SmsTab = () => {
  const {
    action,
    body,
    parsedBody,
    sampleData,
    setBody,
    sampleFanId,
    sampleEventIds,
  } = useActionContext();
  const [isFocused, setIsFocused] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const textAreaRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const mergeTags = [
    "{{fan.first_name}}",
    "{{fan.last_name}}",
    "{{fan.full_name}}",
    "{{fan.phone_number}}",
    "{{fan.email}}",
    "{{event.name}}",
    "{{event.image}}",
    "{{event.url}}",
    "{{event.announce_date}}",
    "{{event.announce_time}}",
    "{{event.announce_date_and_time}}",
    "{{event.announce_time_obj}}",
    "{{event.start_date}}",
    "{{event.start_time}}",
    "{{event.start_date_and_time}}",
    "{{event.start_time_obj}}",
    "{{event.door_date}}",
    "{{event.door_time}}",
    "{{event.door_date_and_time}}",
    "{{event.door_time_obj}}",
    "{{event.sales_start_date}}",
    "{{event.sales_start_time}}",
    "{{event.sales_start_date_and_time}}",
    "{{event.sales_start_time_obj}}",
    "{{venue.name}}",
    "{{venue.address}}",
  ];

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setShowDropdown(false);
  };

  const handleCursor = () => {
    const position = textAreaRef.current.selectionStart;
    setCursorPosition(position);
  };

  const insertTag = (tag) => {
    const textarea = textAreaRef.current;
    const text = textarea.value;
    const before = text.substring(0, cursorPosition);
    const after = text.substring(cursorPosition);

    setBody(before + tag + after);
    textarea.focus();
    setShowDropdown(false);
  };

  const sampleDataAvailable = !!sampleFanId && !!sampleEventIds.length;

  return (
    <div style={{
      ...containerStyle,
      gridTemplateColumns: sampleDataAvailable ? "repeat(3, minmax(0, 1fr))" : "repeat(2, minmax(0, 1fr))",
    }}>
      {sampleDataAvailable ? (
        <div style={dataPickerStyle}>
          <SampleDataPicker />
        </div>
      ) : null}
      <div style={editColumnStyle}>
        <div style={detailRowStyle}><b>From: </b>{action.from}</div>
        <div style={detailRowStyle}><b>To: </b>{"+1{{customer.PhoneNumber}}"}</div>
        <div style={{ position: "relative" }}>
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            rows="10"
            placeholder="Enter your text here..."
            style={textAreaStyle}
            ref={textAreaRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={handleCursor}
            onSelect={handleCursor}
          />
          {isFocused && (
            <div
              style={mergeTagsButtonStyle}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              Merge Tags
            </div>
          )}
          {showDropdown && (
            <div style={mergeTagsContainerStyle}>
              {mergeTags.map((tag, index) => (
                <div
                  key={index}
                  style={mergeTagStyle}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => insertTag(tag)}
                >
                  {tag}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div style={previewColumnStyle}>
        <div style={detailRowStyle}><b>From: </b>{action.from}</div>
        <div style={detailRowStyle}><b>To: </b>+1{sampleData.fan.phone_number}</div>
        <div style={previewContainerStyle}>
          <div style={characterCountStyle}>
            {parsedBody.length} characters
          </div>
          <div style={imageContainerStyle}>
            <img src={sampleData.event.image} style={imageStyle} />
          </div>
          <div style={messageStyle}>
            {parsedBody}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmsTab;
