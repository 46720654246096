import React, { useState } from "react";

import { useActionContext } from "./ActionContainer";
import TemplateEditor from "../../BeefreeTemplates/TemplateEditor";

const containerStyle = {
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
};

const headerRowStyle = {
  alignItems: "center",
  backgroundColor: "white",
  borderBottom: "1px solid #EEEEEE",
  display: "flex",
  flexDirection: "row",
  gap: "15px",
  padding: "15px",
};

const recipientLineStyle = {
  maxWidth: "33%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const inputStyle = {
  border: "none",
  background: "transparent",
  fontSize: "inherit",
  fontWeight: "inherit",
  width: 400,
};

const inlineFormStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

const submitButtonStyle = {
  border: "none",
  backgroundColor: "transparent",
};

const iconStyle = {
  cursor: "pointer",
  color: "#333333",
  opacity: 0.8,
};

const EmailContentTab = () => {
  const {
    action,
    updateAction,
    subject,
    setSubject,
    sendDomain,
  } = useActionContext();

  const [isEditingSubject, setIsEditingSubject] = useState(false);

  const updateSubject = () => {
    setIsEditingSubject(false);
    updateAction({
      campaign_action: {
        subject: subject
      }
    })
  };
 
  return (
    <div style={containerStyle}>
      <div style={headerRowStyle}>
        <div><b>From: </b>{sendDomain?.name_with_address}</div>
        <div style={recipientLineStyle}><b>To: </b>{action.recipient}</div>
        <form style={inlineFormStyle} onSubmit={(e) => {
          e.preventDefault();
          updateSubject();
        }}>
          <b>Subject: </b>
          {isEditingSubject ? (
            <>
              <input
                autoFocus
                style={inputStyle}
                value={subject}
                disabled={!isEditingSubject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <>
                <button type="submit" style={submitButtonStyle}>
                  <i className="far fa-check" style={iconStyle} />
                </button>
                <i className="far fa-times" style={iconStyle} onClick={() => { setSubject(action.subject); setIsEditingSubject(false); }}></i>
              </>
            </>
          ) : (
            <>
              {subject}
              <i className="far fa-pencil" style={iconStyle} onClick={() => setIsEditingSubject(true)} />
            </>
          )}
        </form>
      </div>
      <TemplateEditor
        templatableContext={useActionContext}
        mergeTags={[
          {
            name: "Fan First Name",
            value: "{{fan.first_name}}"
          },
          {
            name: "Fan Last Name",
            value: "{{fan.last_name}}"
          },
          {
            name: "Fan Full Name",
            value: "{{fan.full_name}}"
          },
          {
            name: "Fan Phone Number",
            value: "{{fan.phone_number}}"
          },
          {
            name: "Fan Email",
            value: "{{fan.email}}"
          },
          {
            name: "Event Name",
            value: "{{event.name}}"
          },
          {
            name: "Event Presenter",
            value: "{{event.presenter}}"
          },
          {
            name: "Event Image Url",
            value: "{{event.image}}"
          },
          {
            name: "Event Url",
            value: "{{event.url}}"
          },
          {
            name: "Event Announce Date",
            value: "{{event.announce_date}}"
          },
          {
            name: "Event Announce Time",
            value: "{{event.announce_time}}"
          },
          {
            name: "Event Announce Date and Time",
            value: "{{event.announce_date_and_time}}"
          },
          {
            name: "Event Announce Time Object",
            value: "{{event.announce_time_obj}}"
          },
          {
            name: "Event Start Date",
            value: "{{event.start_date}}"
          },
          {
            name: "Event Start Time",
            value: "{{event.start_time}}"
          },
          {
            name: "Event Start Date and Time",
            value: "{{event.start_date_and_time}}"
          },
          {
            name: "Event Start Time Object",
            value: "{{event.start_time_obj}}"
          },
          {
            name: "Event Door Date",
            value: "{{event.door_date}}"
          },
          {
            name: "Event Door Time",
            value: "{{event.door_time}}"
          },
          {
            name: "Event Door Date and Time",
            value: "{{event.door_date_and_time}}"
          },
          {
            name: "Event Door Time Object",
            value: "{{event.door_time_obj}}"
          },
          {
            name: "Event Sales Start Date",
            value: "{{event.sales_start_date}}"
          },
          {
            name: "Event Sales Start Time",
            value: "{{event.sales_start_time}}"
          },
          {
            name: "Event Sales Start Date and Time",
            value: "{{event.sales_start_date_and_time}}"
          },
          {
            name: "Event Sales Start Time Object",
            value: "{{event.sales_start_time_obj}}"
          },
          {
            name: "Venue Name",
            value: "{{venue.name}}"
          },
          {
            name: "Venue Address",
            value: "{{venue.address}}"
          },
          {
            name: "Venue Logo URL",
            value: "{{venue.logo}}"
          },
        ]}
      />
    </div>
  );
};

export default EmailContentTab;
