/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const getActiveTags = (dispatch, csrfToken, team, scope, taggable, onChange) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get(`/teams/${team.id}/tagged_items`, {
      params: {
        taggable_id: taggable.id,
        taggable_type: scope,
      }
    })
      .then(({ data }) => onChange(data))
      .catch((error) => {
        toast.error("Something went wrong. Try again.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
}

export const getAvailableTags = (dispatch, csrfToken, team, scope, onChange) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get("/teams/" + team.id + "/tags", {
      params: {
        scope: scope
      }
    })
      .then(({ data }) => onChange(data))
      .catch((error) => {
        toast.error("Something went wrong. Try again.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
}

export const assignTag = (dispatch, csrfToken, team, scope, taggable, tag, onChange) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${team.id}/tagged_items`, {
      tagged_item: {
        taggable_id: taggable.id,
        taggable_type: scope,
        tag_id: tag.id
      }
    })
      .then(() => onChange({ refreshAvailable: true }))
      .catch((error) => {
        toast.error("The tag could not be assigned.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
}

export const unassignTag = (dispatch, csrfToken, team, scope, tag, onChange) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete(`/teams/${team.id}/tagged_items/${tag.id}`)
      .then(() => onChange({ refreshAvailable: true }))
      .catch((error) => {
        toast.error("The tag could not be unassigned.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
}

export const createTag = (dispatch, csrfToken, team, scope, taggable, tag, onChange) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${team.id}/tagged_items`, {
      tagged_item: {
        taggable_id: taggable.id,
        taggable_type: scope,
        tag_attributes: {
          team_id: team.id,
          name: tag
        }
      }
    })
      .then(() => onChange({ refreshAvailable: true }))
      .catch((error) => {
        toast.error("The tag could not be created and assigned.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
}
