import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Pagination from "react-js-pagination";

const paginatedConfirms = (confirms, page, perPage) => {
  var startIndex = ((page - 1) * perPage);
  var endIndex = (startIndex + perPage);

  return [...confirms].slice(startIndex, endIndex);
}

const FanUpcomingEvents = ({
  csrfToken,
  team,
  currentUser,
  confirms,
  itemsCountPerPage,
  activePage,
  activePageChanged
}) => {
  return (
    <div>
      {paginatedConfirms(confirms, activePage, itemsCountPerPage).map((confirm, index) =>
        <div key={index}
             className="row"
             style={{"marginBottom": "25px"}}>
          <div className="col d-flex"
               style={{"gap": "15px"}}>
            <div>
              <img src={confirm.compressed_main_event_image_url}
                   width={"160px"}
                   style={{
                     "borderRadius": "8px"
                   }} />
            </div>
            <div className="text-truncate">
              <span className="mb-0"
                    style={{
                      "fontSize": "16px",
                      "fontWeight": "bold"
                    }}
                    title={confirm.title}>
                <a className="text-dark"
                   href={"/teams/" + team.id + "/confirms/" + confirm.id}>
                  {confirm.title}
                </a>
              </span>
              <p className="mb-0 text-muted"
                 style={{
                   "fontSize": "14px",
                   "marginTop": "2px"
                 }}>
                {confirm.human_date_and_time}
              </p>
              <p className="mb-0"
                 style={{
                   "fontSize": "14px"
                 }}>
                {confirm.venue_name}
              </p>
            </div>
          </div>
        </div>
      )}
      {confirms.length > itemsCountPerPage ? (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={confirms.length}
          pageRangeDisplayed={5}
          itemClass="page-item"
          linkClass="page-link bg-white"
          innerClass="pagination fan-show-pagination small"
          hideFirstLastPages={true}
          prevPageText={"←"}
          nextPageText={"→"}
          onChange={
            (page) => {
              activePageChanged(page);
            }
          }
        />
      ) : null}
    </div>
  );
};

FanUpcomingEvents.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirms: PropTypes.array,
  itemsCountPerPage: PropTypes.number,
  activePage: PropTypes.number,
  activePageChanged: PropTypes.func.isRequired
};

export default FanUpcomingEvents;
