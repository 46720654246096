import PropTypes from 'prop-types';
import React, { useState } from 'react';

const axios = require('axios').default;
import AsyncSelect from 'react-select/async';
import Popup from "reactjs-popup";
import RichEditor from '../../RichEditor/components/RichEditor';
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import FinanceItemTemplate from './FinanceItemTemplate';

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "1140px",
  "padding": "15px"
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const shouldDiscardLineItem = (lineItem) => {
  return (
    (lineItem.category_id === null || lineItem.category_id === "")
    && (lineItem.description === null || lineItem.description === "")
    && (lineItem.amount === null || lineItem.amount === "" || lineItem.amount === "0.00")
    && (lineItem.estimate === null || lineItem.estimate === "" || lineItem.estimate === "0.00")
    && (lineItem.actual === null || lineItem.actual === "" || lineItem.actual === "0.00")
  );
};

const canSubmitForm = (eventTemplate, eventTemplateForFormChanged) => {
  var errors = [];

  if(eventTemplate.name === null || eventTemplate.name === "") {
    errors.push("name");
  }

  eventTemplate.income_template.finance_line_items
    .filter(lineItem => (!lineItem._destroy && !shouldDiscardLineItem(lineItem)))
    .forEach((lineItem, index) => {
      if(!lineItem.category_id || lineItem.category_id === "") {
        errors.push(`incomeLineItemCategory${index}`);
      }

      if(lineItem.description === null || lineItem.description === "") {
        errors.push(`incomeLineItemDescription${index}`);
      }
    });

  eventTemplate.expense_template.finance_line_items
    .filter(lineItem => (!lineItem._destroy && !shouldDiscardLineItem(lineItem)))
    .forEach((lineItem, index) => {
      if(!lineItem.category_id || lineItem.category_id === "") {
        errors.push(`expenseLineItemCategory${index}`);
      }

      if(lineItem.description === null || lineItem.description === "") {
        errors.push(`expenseLineItemDescription${index}`);
      }
    });

  eventTemplateForFormChanged({
    ...eventTemplate,
    errors: errors
  });

  return errors.length === 0;
}

const debouncedFetchNoteTemplates = _.debounce((teamId, inputValue, callback) => {
  new Promise(resolve => {
    axios.get(`/teams/${teamId}/note_templates`, {
      params: {
        name: inputValue
      }
    })
    .then(function (response) {
      var newOptions = response.data.sort((a, b) => {
        if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
        if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
        return 0;
      }).map(function(data){
        return { value: data.id, label: data.title };
      });

      callback(newOptions);
    })
  });
}, 250);

const createNoteTemplate = (csrfToken, teamId, noteTemlate, successCallback) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  axios.post(`/teams/${teamId}/note_templates`, {
    note_template: {
      title: noteTemlate.title,
      body: noteTemlate.body
    }
  })
  .then(response => {
    successCallback(response.data);
  })
  .catch(error => {
    toast.error(error.response.data.join(", "), {
      position: toast.POSITION.TOP_CENTER,
      draggable: false,
      closeOnClick: false,
      autoClose: 5000,
      hideProgressBar: true
    });
  });
};

const NoteTemplateForm = ({
  csrfToken,
  teamId,
  noteTemplates,
  noteTemplatesChanged,
  eventTemplateForForm,
  eventTemplateForFormChanged
}) => {
  return (
    <Popup
      modal
      contentStyle={modalContentStyle}
      closeOnDocumentClick={false}
      className={"popup-modal"}
      onOpen={e => {
        e.stopPropagation();
        e.preventDefault();

        eventTemplateForFormChanged({
          ...eventTemplateForForm,
          newNoteTemplate: {}
        });
      }}
      trigger={open => (
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={e => { e.preventDefault(); }}
        >
          <i className="far fa-plus mr-2"></i>
          New Note Template
        </button>
      )}
    >
      {close => (
        <div className="popup-modal-container">
          {eventTemplateForForm.newNoteTemplate ? (
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-8">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        autoFocus={true}
                        style={{'fontSize': "24px", 'color': "#333333"}}
                        className="form-control no-border strong shadow-none pl-0 pb-0"
                        value={eventTemplateForForm.newNoteTemplate.title || ""}
                        placeholder="Untitled"
                        onChange={e => {
                          eventTemplateForFormChanged({
                            ...eventTemplateForForm,
                            newNoteTemplate: {
                              ...eventTemplateForForm.newNoteTemplate,
                              title: e.target.value
                            }
                          });
                        }} />
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-end align-items-center text-right">
                    <button
                      className="btn btn-primary ml-2"
                      disabled={false}
                      onClick={e => {
                        createNoteTemplate(csrfToken, teamId, eventTemplateForForm.newNoteTemplate, (data) => {
                          noteTemplatesChanged([
                            ...noteTemplates,
                            data
                          ]);

                          eventTemplateForFormChanged({
                            ...eventTemplateForForm,
                            notes: [
                              ...eventTemplateForForm.notes,
                              data
                            ]
                          })
                        });

                        close();
                      }}
                    >
                      Create Note Template
                    </button>
                    <a href="#"
                       onClick={e => {
                         e.preventDefault();
                         close();
                       }}
                       className="text-muted ml-4 mr-2">
                      <i className="fal fa-times"></i>
                    </a>
                  </div>
                  <div className="col-12 pt-3">
                    <RichEditor
                      editorState={eventTemplateForForm.newNoteTemplate.editorState}
                      editorStyle={{"minHeight": "320px"}}
                      onEditorStateChange={editorState => {
                        const noteBody = stateToHTML(editorState.getCurrentContent());

                        eventTemplateForFormChanged({
                          ...eventTemplateForForm,
                          newNoteTemplate: {
                            ...eventTemplateForForm.newNoteTemplate,
                            body: noteBody,
                            editorState: editorState
                          }
                        });
                      }} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </Popup>
  );
};

const EventTemplatesManager = ({
  csrfToken,
  teamId,
  eventTemplates,
  eventTemplatesChanged,
  eventTemplateForForm,
  eventTemplateForFormChanged,
  financesVisible,
  financesVisibleChanged,
  financeCategories,
  financeCategoriesChanged,
  notesVisible,
  notesVisibleChanged,
  noteTemplates,
  noteTemplatesChanged,
  createEventTemplate,
  updateEventTemplate,
  deleteEventTemplate,
  team
}) => {
  return (
    <>
      <table className={`mb-0 table table-borderless ${eventTemplates.length > 0 ? "d-table" : "d-none"}`}>
        <colgroup>
          <col style={{"width": "40%"}} />
          <col style={{"width": "40%"}} />
          <col style={{"width": "20%"}} />
        </colgroup>
        <thead>
          <tr className="text-muted text-uppercase" style={{"fontSize": "12px"}}>
            <th>Name</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {eventTemplates.map((eventTemplate, index) => (
            <tr
              key={index}
              style={index > 0 ? {
                "borderTop": "1px solid #ccc",
              } : {}}
            >
              {eventTemplate.id !== eventTemplateForForm.id ? (
                <>
                  <td>{eventTemplate.name}</td>
                  <td>{eventTemplate.description}</td>
                  <td className="text-right">
                    <a
                      href="#"
                      className="text-muted"
                      onClick={e => {
                        e.preventDefault();

                        eventTemplateForFormChanged(eventTemplate);
                      }}
                    >
                      <i className="far fa-pencil"></i>
                    </a>
                    <a
                      href="#"
                      className="text-muted"
                      onClick={e => {
                        e.preventDefault();

                        deleteEventTemplate(csrfToken, teamId, eventTemplate, eventTemplates);
                      }}
                    >
                      <i className="ml-2 far fa-trash"></i>
                    </a>
                  </td>
                </>
              ) : (
                <td colSpan="3" className="p-0">
                  <div
                    className="mb-0 p-4 w-100 form-group"
                    style={{"backgroundColor": "#f3f9fc"}}
                  >
                    <form>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <label className="mb-0 text-muted text-uppercase">Name</label>
                          <input
                            type="text"
                            className="d-block w-100 rounded-sm pl-2"
                            style={{
                              height: "38px",
                              border: eventTemplateForForm.errors?.includes('name')
                                ? "2px solid #FF595E"
                                : "1px solid #e6e6e6"
                            }}
                            value={eventTemplateForForm.name}
                            onChange={e => {
                              eventTemplateForFormChanged({
                                ...eventTemplateForForm,
                                name: e.target.value,
                                errors: eventTemplateForForm.errors?.filter(error => error !== 'name'),
                              });
                            }}
                          />
                        </div>
                        <div className="ml-4 flex-grow-1">
                          <label className="mb-0 text-muted text-uppercase">Description</label>
                          <input
                            type="text"
                            className="d-block w-100 rounded-sm pl-2"
                            style={{"height":"38px", "border":"1px solid #e6e6e6"}}
                            value={eventTemplateForForm.description}
                            onChange={e => {
                              eventTemplateForFormChanged({
                                ...eventTemplateForForm,
                                description: e.target.value
                              });
                            }}
                          />
                        </div>
                        <div className="ml-4 pt-4 d-flex flex-grow-1">
                          <button
                            type="button"
                            className="btn btn-sm ml-auto"
                            onClick={e => {
                              e.preventDefault();

                              if(eventTemplateForForm.id.toString().includes("new-")) {
                                eventTemplatesChanged(eventTemplates.filter(et => !et.id.toString().includes("new-")));
                              }

                              eventTemplateForFormChanged({});
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary ml-2"
                            onClick={e => {
                              e.preventDefault();

                              if(!canSubmitForm(eventTemplateForForm, eventTemplateForFormChanged)){
                                return false;
                              }

                              const updatedEventTemplateForForm = {
                                ...eventTemplateForForm,
                                income_template: {
                                  ...eventTemplateForForm.income_template,
                                  finance_line_items: eventTemplateForForm.income_template.finance_line_items.filter(lineItem => !shouldDiscardLineItem(lineItem))
                                },
                                expense_template: {
                                  ...eventTemplateForForm.expense_template,
                                  finance_line_items: eventTemplateForForm.expense_template.finance_line_items.filter(lineItem => !shouldDiscardLineItem(lineItem))
                                }
                              };

                              if (eventTemplateForForm.id > 0) {
                                updateEventTemplate(csrfToken, teamId, updatedEventTemplateForForm, eventTemplates);
                              } else {
                                createEventTemplate(csrfToken, teamId, updatedEventTemplateForForm, eventTemplates);
                              }
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="w-100 d-flex mt-4">
                        <div
                          className="mb-0 py-2 w-100 d-flex align-items-center justify-content-between"
                          style={{
                            "fontSize": "14px",
                            "fontWeight": "bold",
                            "borderBottom": "1px solid #e6e6e6",
                            "cursor": "pointer"
                          }}
                          onClick={e => {
                            e.preventDefault();

                            notesVisibleChanged(!notesVisible);
                          }}
                        >
                          <div>Notes</div>
                          <div>
                            {notesVisible ? (
                              <i className="fas fa-caret-up"></i>
                            ) : (
                              <i className="fas fa-caret-down"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      {notesVisible ? (
                        <div>
                          <AsyncSelect
                            id="note-templates"
                            isMulti
                            placeholder={"Select one or more note templates"}
                            styles={selectStyles}
                            menuPortalTarget={document.querySelector('body')}
                            isClearable={true}
                            defaultOptions={noteTemplates.sort((a, b) => {
                              if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
                              if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
                              return 0;
                            }).map(note => (
                              { value: note.id, label: note.title }
                            ))}
                            value={eventTemplateForForm.notes
                              .filter(note => !note._destroy)
                              .map(note => (
                                { value: note.id, label: note.title }
                              ))
                            }
                            loadOptions={(inputValue, callback) => debouncedFetchNoteTemplates(teamId, inputValue, callback)}
                            onChange={options => {
                              options = options || [];
                              const activeNoteTemplates = eventTemplateForForm.notes.filter(note => !note._destroy)
                              if(options.length > activeNoteTemplates.length) {
                                eventTemplateForFormChanged({
                                  ...eventTemplateForForm,
                                  notes: noteTemplates.filter(noteTemplate => {
                                    return options.find(option => option.value === noteTemplate.id)
                                  }).map(noteTemplate => (
                                    { id: noteTemplate.id, title: noteTemplate.title, body: noteTemplate.body }
                                  ))
                                });
                              } else if(options.length < activeNoteTemplates.length) {
                                eventTemplateForFormChanged({
                                  ...eventTemplateForForm,
                                  notes: eventTemplateForForm.notes.map(note => {
                                    if(options.find(option => option.value === note.id)) {
                                      return { id: note.id, title: note.title };
                                    } else {
                                      return { value: note.id, title: note.title, _destroy: true };
                                    }
                                  })
                                });
                              }
                            }}
                          />
                          <div className="mt-3">
                            <NoteTemplateForm
                              csrfToken={csrfToken}
                              teamId={teamId}
                              noteTemplates={noteTemplates}
                              noteTemplatesChanged={noteTemplatesChanged}
                              eventTemplateForForm={eventTemplateForForm}
                              eventTemplateForFormChanged={eventTemplateForFormChanged}
                            />
                          </div>
                        </div>
                      ): null}
                      {team.package.pipeline ? (
                        <>
                          <div className="w-100 d-flex mt-4">
                            <div
                              className="mb-0 py-2 w-100 d-flex align-items-center justify-content-between"
                              style={{
                                "fontSize": "14px",
                                "fontWeight": "bold",
                                "borderBottom": "1px solid #e6e6e6",
                                "cursor": "pointer"
                              }}
                              onClick={e => {
                                e.preventDefault();

                                financesVisibleChanged(!financesVisible);
                              }}
                            >
                              <div>Finances</div>
                              <div>
                                {financesVisible ? (
                                  <i className="fas fa-caret-up"></i>
                                ) : (
                                  <i className="fas fa-caret-down"></i>
                                )}
                              </div>
                            </div>
                          </div>
                          {financesVisible ? (
                            <div>
                              <div
                                className="my-4 font-weight-bold text-uppercase"
                                style={{"fontSize": "12px"}}
                              >
                                Income
                              </div>
                              {eventTemplateForForm.income_template ? (
                                <div className="mb-5">
                                  <FinanceItemTemplate
                                    csrfToken={csrfToken}
                                    teamId={teamId}
                                    financeItem={eventTemplateForForm.income_template}
                                    eventTemplateForForm={eventTemplateForForm}
                                    eventTemplateForFormChanged={eventTemplateForFormChanged}
                                    financeCategories={financeCategories}
                                    financeCategoriesChanged={financeCategoriesChanged}
                                  />
                                </div>
                              ) : null}
                              <div
                                className="my-4 font-weight-bold text-uppercase"
                                style={{"fontSize": "12px"}}
                              >
                                Expense
                              </div>
                              {eventTemplateForForm.expense_template ? (
                                <div className="mb-2">
                                  <FinanceItemTemplate
                                    csrfToken={csrfToken}
                                    teamId={teamId}
                                    financeItem={eventTemplateForForm.expense_template}
                                    eventTemplateForForm={eventTemplateForForm}
                                    eventTemplateForFormChanged={eventTemplateForFormChanged}
                                    financeCategories={financeCategories}
                                    financeCategoriesChanged={financeCategoriesChanged}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </form>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-3">
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={e => {
            e.preventDefault();

            const newEventTemplate = {
              id: `new-${eventTemplates.length}`,
              name: '',
              description: '',
              notes: [],
              income_template: {
                is_income: true,
                finance_line_items: []
              },
              expense_template: {
                is_income: false,
                finance_line_items: []
              }
            };

            eventTemplateForFormChanged(newEventTemplate);
            eventTemplatesChanged([...eventTemplates, newEventTemplate]);
          }}
        >
          <i className="far fa-plus"></i> New Template
        </button>
      </div>
    </>
  );
}

EventTemplatesManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  teamId: PropTypes.number.isRequired,
  eventTemplates: PropTypes.array.isRequired,
  eventTemplatesChanged: PropTypes.func.isRequired,
  eventTemplateForForm: PropTypes.object.isRequired,
  eventTemplateForFormChanged: PropTypes.func.isRequired,
  financesVisible: PropTypes.bool.isRequired,
  financesVisibleChanged: PropTypes.func.isRequired,
  financeCategories: PropTypes.array.isRequired,
  financeCategoriesChanged: PropTypes.func.isRequired,
  notesVisible: PropTypes.bool.isRequired,
  notesVisibleChanged: PropTypes.func.isRequired,
  noteTemplates: PropTypes.array.isRequired,
  noteTemplatesChanged: PropTypes.func.isRequired,
  createEventTemplate: PropTypes.func.isRequired,
  updateEventTemplate: PropTypes.func.isRequired,
  deleteEventTemplate: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired
}

export default EventTemplatesManager;
