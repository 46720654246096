import { createStore, applyMiddleware } from 'redux';
import confirmDashboardReducer from '../reducers/confirmDashboardReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(confirmDashboardReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
