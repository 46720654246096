import React, { useEffect } from "react";
import { useBroadcastEditContext } from "./BroadcastEditContainer";
import BroadcastSidebar from "./BroadcastSidebar";

import Select from "react-select";
import Tippy from "@tippyjs/react";

var _ = require('lodash');

const debouncedUpdateBroadcast = _.debounce((updateBroadcast, payload) => {
  updateBroadcast(payload);
}, 2000);

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const FromTab = () => {
  const {
    team,
    venueOwnership,
    setTab,
    updateBroadcast,
    beefreeTemplate,
    sendDomain,
    availableSendDomains,
    emailSendDomain,
    setEmailSendDomain,
    selectedSendDomain,
    setSelectedSendDomain,
  } = useBroadcastEditContext();

  return (
    <BroadcastSidebar>
      <div className="mt-3 ml-5">
        {availableSendDomains.length === 0 ? (
          <div className="alert alert-danger" role="alert">
            Your sending domain has not been setup yet. <a href={`/teams/${team.id}/marketing_settings?venue_ownership_id=${venueOwnership.id}`}>Get started</a>.
          </div>
        ) : null}
        <h3 style={{ fontSize: "20px" }}>
          <strong>
            From
          </strong>
        </h3>
        <p className="">Who is sending this email?</p>
        <div className="mt-4" style={{ width: "350px" }}>
          <label className="w-100 mb-1 px-1 d-flex justify-content-between text-muted">
            <span>Name</span>
            <span className="text-right">Required</span>
          </label>
          <Tippy
            content="We recommend using a name that your subscribers will recognize. Like your venue name."
            placement="right"
          >
            <input
              type="text"
              disabled={!emailSendDomain}
              className="form-control rounded-lg"
              id="fromName"
              value={emailSendDomain?.name || ""}
              onChange={option => {
                setEmailSendDomain({
                  ...emailSendDomain,
                  name: option.target.value
                });

                debouncedUpdateBroadcast(updateBroadcast, {
                  beefree_template_attributes: {
                    ...beefreeTemplate,
                    email_send_domain_attributes: {
                      ...emailSendDomain,
                      name: option.target.value
                    }
                  }
                });
              }}
            />
          </Tippy>
        </div>
        <div className="mt-4" style={{ width: "350px" }}>
          <label className="w-100 mb-1 px-1 d-flex justify-content-between text-muted">
            <span>Email Address</span>
            <span className="text-right">Required</span>
          </label>
          <Select
            isDisabled={!emailSendDomain && availableSendDomains.length === 0}
            options={availableSendDomains}
            value={selectedSendDomain}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.full_from_address}
            onChange={option => {
              setSelectedSendDomain(option)

              setEmailSendDomain({
                ...emailSendDomain,
                send_domain_id: option.id,
                name: option.name
              });

              debouncedUpdateBroadcast(updateBroadcast, {
                beefree_template_attributes: {
                  ...beefreeTemplate,
                  email_send_domain_attributes: {
                    ...emailSendDomain,
                    send_domain_id: option.id,
                    name: option.name
                  }
                }
              });
            }}
          />
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "250px" }}
        >
          <a
            href="#"
            style={backButtonStyle}
            onClick={e => {
              e.preventDefault();
              setTab("recipients")
            }}
          >
            Back
          </a>
          <button
            type="button"
            className="ml-2 btn btn-primary"
            onClick={e => {
              e.preventDefault();
              setTab("content-select");
            }}
          >
            Next
          </button>
        </div>
      </div>
    </BroadcastSidebar>
  );
};

export default FromTab;
