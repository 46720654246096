import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

var _ = require('lodash');
import { ToastContainer, toast } from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
var moment = require('moment');
import DayPicker, { DateUtils } from 'react-day-picker';

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

const formatDate = (time) => {
  const datetime = offsetDateForBrowser(new Date(time));

  return moment(datetime).format("MM/DD/YY");
}

const HoldPositionPicker = ({
  csrfToken,
  team,
  venueSelected,
  holdGroup,
  holdGroupCalendarDateClicked,
  holdGroupDates,
  holdGroupHoldPositionUpdated,
  holdGroupHoldRemoved,
  fetchHoldAvailability,
  calendarRef,
  calendarStartDate,
  makeUnavailable,
  setMakeUnavailable
}) => {
  return(
    <div className="form-group">
      <div className='form-row'>
        <div className="col-12 col-md-6">
          <div style={{"marginLeft": "-18px"}}>
            <DayPicker
              month={calendarStartDate}
              onMonthChange={(date) => {
                if(calendarRef && Object.keys(calendarRef).length > 0){
                  calendarRef.getApi().gotoDate(date);
                }
              }}
              selectedDays={holdGroupDates}
              onDayClick={(date, {selected}) => {
                holdGroupCalendarDateClicked(date, selected);
                fetchHoldAvailability(team, venueSelected.id, date);
              }} />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="custom-control custom-control-inline custom-checkbox custom-checkbox-table">
              <input
                  type="checkbox"
                  className="custom-control-input"
                  id="check-no-confirms"
                  checked={makeUnavailable}
                  onChange={() => setMakeUnavailable(!makeUnavailable)}
              />
              <label
                  className="custom-control-label"
                  style={{"fontSize": "14px", "marginLeft": "5px"}}
                  htmlFor="check-no-confirms">
                  Exclude dates with confirms
              </label>
          </div>
          <table style={{'marginTop': "16px", "tableLayout": "fixed"}}
                  className="table table-borderless table-sm microtable text-muted">
            <colgroup>
              <col span={1} style={{"width": "45%"}} />
              <col span={1} style={{"width": "45%"}} />
              <col span={1} style={{"width": "10%"}} />
            </colgroup>
            <thead>
              <tr>
                <td>Date</td>
                <td>Hold Priority</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {
                (holdGroup.hold_group_entries || [])
                  .filter((holdGroupEntry) => !holdGroupEntry._destroy)
                  .sort((a, b) => a.hold.start_time - b.hold.start_time)
                  .map((holdGroupEntry, index) => (
                    <tr key={index}>
                      <td>
                        <input type="text"
                                className="form-control form-control-sm"
                                style={
                                  {
                                    "fontSize": "12px",
                                    "borderRadius": "8px",
                                    "background": "white",
                                    "opacity": "0.5",
                                    "border": "2px solid #f5f5f5"
                                  }
                                }
                                value={formatDate(holdGroupEntry.hold.start_time)}
                                disabled={true} />
                      </td>
                      <td>
                        <select value={holdGroupEntry.hold.position}
                                onChange={
                                  (e) => {
                                    e.preventDefault();
                                    holdGroupHoldPositionUpdated(holdGroupEntry.hold.start_time, e.target.value);
                                  }
                                }
                                className="form-control form-control-sm"
                                style={
                                  {
                                    "fontSize": "12px",
                                    "width": "100%",
                                    "borderRadius": "8px",
                                  }
                                }>
                          {(holdGroupEntry.hold.hold_availability || []).map(function(availity, index){
                            if (makeUnavailable && availity.is_confirm) {
                              return (
                                  <option key={index}
                                          disabled={true}
                                          value={availity.position}>
                                      {`${availity.hold_title || 'Unavailable'}`}
                                  </option>
                              );
                          } else {
                            return (
                              <option key={index}
                                      disabled={availity.hold_title !== null}
                                      value={availity.position}>
                                {availity.hold_title === null ? (
                                  "Hold " + availity.position + " - Available"
                                ) : (
                                  "Hold " + availity.position + " - " + availity.hold_title
                                )}
                              </option>
                            )
                          }
                        })}
                        </select>
                      </td>
                      <td className="pt-2">
                        <a className="text-muted"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                holdGroupHoldRemoved(holdGroupEntry.hold.start_time);
                              }
                            }
                            href="#">
                          <i className="fas fa-times-circle"></i>
                        </a>
                      </td>
                    </tr>
                  ))
              }
              <tr>
                <td>
                  <input type="text"
                          className="form-control form-control-sm"
                          style={
                            {
                              "fontSize": "12px",
                              "borderRadius": "8px",
                              "background": "white",
                              "opacity": "0.5",
                              "border": "2px solid #f5f5f5"
                            }
                          }
                          placeholder="Select a date"
                          disabled={true} />
                </td>
                <td>
                  <select value={1}
                          className="form-control form-control-sm"
                          disabled={true}
                          style={
                            {
                              "background": "white",
                              "fontSize": "12px",
                              "width": "100%",
                              "border": "2px solid #f5f5f5",
                              "opacity": "0.5",
                              "borderRadius": "8px",
                            }
                          }>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(function(priority, index){
                      return <option key={index} value={priority}>{"Hold " + priority}</option>
                    })}
                  </select>
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mb-0 ml-1 text-muted" style={{"fontSize": "10px"}}>
            You can select as many dates as you want.
          </p>
          <div className="mt-2 ml-1">
            <CopyToClipboard
              text={
                (holdGroup.hold_group_entries || [])
                  .filter((holdGroupEntry) => {
                    if (holdGroupEntry._destroy){
                      return false;
                    }
                    const hasConfirmWithinHold = (holdGroupEntry.hold.hold_availability || []).some(availity => availity.is_confirm);
                    if (makeUnavailable && hasConfirmWithinHold) {
                      return false;
                    }
                    return true;
                  })
                  .sort((a, b) => a.hold.start_time - b.hold.start_time)
                  .map(holdGroupEntry => `${holdGroupEntry.hold.start_time.toDateString()} H${holdGroupEntry.hold.position}`)
                  .join("\n")
              }
              onCopy={
                () => {
                  toast.success("Copied hold dates to clipboard", {
                    position: toast.POSITION.TOP_CENTER,
                    draggable: false,
                    closeOnClick: false,
                    autoClose: 5000,
                    hideProgressBar: true
                  });
                }
              }>
              <a href="#" className="small" onClick={(e) => e.preventDefault()}>
                <i className="fal fa-clipboard"></i>&nbsp;
                Copy hold dates to clipboard
              </a>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  )
};

HoldPositionPicker.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  venueSelected: PropTypes.object,
  holdGroup: PropTypes.object,
  holdGroupCalendarDateClicked: PropTypes.func.isRequired,
  holdGroupDates: PropTypes.array,
  holdGroupHoldPositionUpdated: PropTypes.func.isRequired,
  holdGroupHoldRemoved: PropTypes.func.isRequired,
  fetchHoldAvailability: PropTypes.func.isRequired,
  calendarRef: PropTypes.object,
  calendarStartDate: PropTypes.instanceOf(Date),
  makeUnavailable: PropTypes.bool,
  setMakeUnavailable: PropTypes.func.isRequired
};

export default HoldPositionPicker;
