// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ConfirmShow from '../components/ConfirmShow';
import * as actions from '../actions/confirmShowActionCreators';
import * as eventFormActions from '../../EventForm/actions/eventFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  browser: state.confirmShow.browser,
  confirm: state.confirmShow.confirm,
  performers: state.confirmShow.performers,
  csrfToken: state.confirmShow.csrfToken,
  team: state.confirmShow.team,
  user: state.confirmShow.user,
  sendingCustomerIOEmail: state.confirmShow.sendingCustomerIOEmail,
  customerIOEmailPurpose: state.confirmShow.customerIOEmailPurpose,
  customerIOEmailTemplate: state.confirmShow.customerIOEmailTemplate,
  marketingSectionActiveTab: state.confirmShow.marketingSectionActiveTab,
  posts: state.confirmShow.posts,
  showAdvanceSend: state.confirmShow.showAdvanceSend,
  postToEdit: state.confirmShow.postToEdit,
  addingNewSocialAccount: state.confirmShow.addingNewSocialAccount,
  authorizedPages: state.confirmShow.authorizedPages,
  composingPost: state.confirmShow.composingPost,
  onepostPublicKey: state.confirmShow.onepostPublicKey,
  twitterProviderId: state.confirmShow.twitterProviderId,
  facebookProviderId: state.confirmShow.facebookProviderId,
  originalPerformer: state.confirmShow.originalPerformer,
  currentUser: state.confirmShow.currentUser,
  openNote: state.confirmShow.openNote,
  notes: state.confirmShow.notes,
  noteTemplates: state.confirmShow.noteTemplates,
  openNoteOriginal: state.confirmShow.openNoteOriginal,
  openNoteLoading: state.confirmShow.openNoteLoading,
  calendarEventContacts: state.confirmShow.calendarEventContacts,
  originalCalendarEventContact: state.confirmShow.originalCalendarEventContact,
  defaultOffer: state.confirmShow.defaultOffer,
  offerModalIsOpen: state.confirmShow.offerModalIsOpen,
  venuesOptions: state.confirmShow.venuesOptions,
  offerPerformer: state.confirmShow.offerPerformer,
  offers: state.confirmShow.offers,
  modalOffer: state.confirmShow.modalOffer,
  offerTemplates: state.confirmShow.offerTemplates,
  showActivities: state.confirmShow.showActivities,
  showActivityCopy: state.confirmShow.showActivityCopy,
  venueSelected: state.confirmShow.venueSelected,
  confirmInvitation: state.confirmShow.confirmInvitation,
  emailsToInvite: state.confirmShow.emailsToInvite,
  guestRole: state.confirmShow.guestRole,
  pendingConfirmInvitations: state.confirmShow.pendingConfirmInvitations,
  guestTeamMemberships: state.confirmShow.guestTeamMemberships,
  isViewingGuests: state.confirmShow.isViewingGuests,
  selfRegisterLink: state.confirmShow.selfRegisterLink,
  offerForSettlement: state.confirmShow.offerForSettlement,
  permissionSet: state.confirmShow.permissionSet,
  onPermissionSetSaved: state.confirmShow.onPermissionSetSaved,
  confirmPageSections: state.confirmShow.confirmPageSections,
  promotersWithPermission: state.confirmShow.promotersWithPermission,
  showAdvanceSends: state.confirmShow.showAdvanceSends,
  socialMarketingBudgetItems: state.confirmShow.socialMarketingBudgetItems,
  traditionalMarketingBudgetItems: state.confirmShow.traditionalMarketingBudgetItems,
  marketingBudgetItemToEdit: state.confirmShow.marketingBudgetItemToEdit,
  financeItems: state.confirmShow.financeItems,
  financeCategories: state.confirmShow.financeCategories,
  financeItemToEdit: state.confirmShow.financeItemToEdit,
  financeItemForForm: state.confirmShow.financeItemForForm,
  ticketTypesByPrice: state.confirmShow.ticketTypesByPrice,
  showWidgetCode: state.confirmShow.showWidgetCode,
  addOnsByPrice: state.confirmShow.addOnsByPrice,
  buyers: state.eventForm.buyers,
  selectedBuyer: state.eventForm.selectedBuyer,
  promoters: state.eventForm.promoters,
  selectedPromoter: state.eventForm.selectedPromoter,
  venueOwnerships: state.confirmShow.venueOwnerships,
  editingConfirmWebsite: state.confirmShow.editingConfirmWebsite,
  editingConfirmWebsiteValue: state.confirmShow.editingConfirmWebsiteValue,
  folder: state.confirmShow.folder,
  teamRootFolder: state.confirmShow.teamRootFolder,
  profitAndLoss: state.confirmShow.profitAndLoss,
  mayCancelConfirm: state.confirmShow.mayCancelConfirm,
  mayUncancelConfirm: state.confirmShow.mayUncancelConfirm,
  railsEnv: state.confirmShow.railsEnv,
  holdGroupDeletion: state.confirmShow.holdGroupDeletion,
  holdToConfirm: state.confirmShow.holdToConfirm,
  doorsTimeDefault: state.confirmShow.doorsTimeDefault,
  eventEndTimeDefault: state.confirmShow.eventEndTimeDefault,
  userChangedDoorsTime: state.confirmShow.userChangedDoorsTime,
  userChangedEventEndTime: state.confirmShow.userChangedEventEndTime,
  isConvertingHoldToConfirm: state.confirmShow.isConvertingHoldToConfirm,
  possibleMatchingOffers: state.confirmShow.possibleMatchingOffers,
  manualTicketCounts: state.confirmShow.manualTicketCounts,
  showUpgradeLink: state.confirmShow.showUpgradeLink,
  showDonationLink: state.confirmShow.showDonationLink,
  venuePreSalePromoCode: state.confirmShow.venuePreSalePromoCode,
  submittingForm: state.eventForm.submittingForm,
  calendarEventTitle: state.eventForm.calendarEventTitle,
  calendarEventArtists: state.eventForm.calendarEventArtists,
  calendarEventType: state.eventForm.calendarEventType,
  calendarEventTicketForecast: state.eventForm.calendarEventTicketForecast,
  calendarEventNoteEditorState: state.eventForm.calendarEventNoteEditorState,
  calendarEventErrors: state.eventForm.calendarEventErrors,
  userChangedCalendarEventTitle: state.eventForm.userChangedCalendarEventTitle,
});

const mapDispatchToProps = (dispatch) => {
  return {
    confirmChanged: (confirm) => dispatch(actions.confirmChanged(confirm)),
    refreshConfirm: (csrfToken, team) => dispatch(actions.refreshConfirm(dispatch, csrfToken, team)),
    performersChanged: (performers) => dispatch(actions.performersChanged(performers)),
    deletePerformer: (csrfToken, team, performer) => dispatch(actions.deletePerformer(dispatch, csrfToken, team, performer)),
    stagePerformerForUpdate: (performer) => dispatch(actions.stagePerformerForUpdate(performer)),
    updatePerformer: (csrfToken, team, performer) => dispatch(actions.updatePerformer(dispatch, csrfToken, team, performer)),
    updatePerformerActType: (index, actType) => dispatch(actions.updatePerformerActType(index, actType)),
    performerStartTimeChanged: (index, startTime) => dispatch(actions.performerStartTimeChanged(index, startTime)),
    performerEndTimeChanged: (index, endTime) => dispatch(actions.performerEndTimeChanged(index, endTime)),
    addNewPerformer: () => dispatch(actions.addNewPerformer()),
    setArtistOnNewPerformer: (index, artist) => dispatch(actions.setArtistOnNewPerformer(index, artist)),
    createPerformer: (csrfToken, team, confirm, performer, index) => dispatch(actions.createPerformer(dispatch, csrfToken, team, confirm, performer, index)),
    cancelAddPerformer: () => dispatch(actions.cancelAddPerformer()),
    cancelEditPerformer: (performer, originalPerformer) => dispatch(actions.cancelEditPerformer(performer, originalPerformer)),
    fetchNewNote: (csrfToken, team) => dispatch(actions.fetchNewNote(dispatch, csrfToken, team)),
    closeOpenNote: () => dispatch(actions.closeOpenNote()),
    openNoteChanged: (openNote) => dispatch(actions.openNoteChanged(openNote)),
    openNoteTitleChanged: (title) => dispatch(actions.openNoteTitleChanged(title)),
    openNoteBodyChanged: (editorState) => dispatch(actions.openNoteBodyChanged(editorState)),
    createNote: (csrfToken, team, confirm, note) => dispatch(actions.createNote(dispatch, csrfToken, team, confirm, note)),
    deleteNote: (csrfToken, team, note) => dispatch(actions.deleteNote(dispatch, csrfToken, team, note)),
    fetchNoteToEdit: (csrfToken, team, noteId) => dispatch(actions.fetchNoteToEdit(dispatch, csrfToken, team, noteId)),
    fetchNoteToPreview: (csrfToken, team, noteId) => dispatch(actions.fetchNoteToPreview(dispatch, csrfToken, team, noteId)),
    updateNote: (csrfToken, team, note) => dispatch(actions.updateNote(dispatch, csrfToken, team, note)),
    disableOpenNotePreviewMode: () => dispatch(actions.disableOpenNotePreviewMode()),
    noteTemplatesChanged: (noteTemplates) => dispatch(actions.noteTemplatesChanged(noteTemplates)),
    createCalendarEventContactFromContactId: (csrfToken, team, confirm, contactId, index) => dispatch(actions.createCalendarEventContactFromContactId(dispatch, csrfToken, team, confirm, contactId, index)),
    deleteCalendarEventContact: (csrfToken, team, calendarEventContact) => dispatch(actions.deleteCalendarEventContact(dispatch, csrfToken, team, calendarEventContact)),
    addCalendarEventContact: (originalCalendarEventContact) => dispatch(actions.addCalendarEventContact(originalCalendarEventContact)),
    removeUnsavedCalendarEventContact: () => dispatch(actions.removeUnsavedCalendarEventContact()),
    editCalendarEventContact: (index, calendarEventContact, originalCalendarEventContact) => dispatch(actions.editCalendarEventContact(index, calendarEventContact, originalCalendarEventContact)),
    cancelEditCalendarEventContact: (originalCalendarEventContact) => dispatch(actions.cancelEditCalendarEventContact(originalCalendarEventContact)),
    updateCalendarEventContactName: (index, name) => dispatch(actions.updateCalendarEventContactName(index, name)),
    updateCalendarEventContactEmail: (index, email) => dispatch(actions.updateCalendarEventContactEmail(index, email)),
    updateCalendarEventContactPhone: (index, phone) => dispatch(actions.updateCalendarEventContactPhone(index, phone)),
    updateCalendarEventContactContactType: (index, contactType) => dispatch(actions.updateCalendarEventContactContactType(index, contactType)),
    updateCalendarEventContactTitle: (index, title) => dispatch(actions.updateCalendarEventContactTitle(index, title)),
    updateCalendarEventContactCompanyName: (index, companyName) => dispatch(actions.updateCalendarEventContactCompanyName(index, companyName)),
    submitCalendarEventContactForm: (csrfToken, team, confirm, calendarEventContact, index) => dispatch(actions.submitCalendarEventContactForm(dispatch, csrfToken, team, confirm, calendarEventContact, index)),
    editUnsavedCalendarEventContact: (name, index, originalCalendarEventContact) => dispatch(actions.editUnsavedCalendarEventContact(name, index, originalCalendarEventContact)),
    createCalendarEventContactFromAgentId: (csrfToken, team, confirm, agentId, index) => dispatch(actions.createCalendarEventContactFromAgentId(dispatch, csrfToken, team, confirm, agentId, index)),
    closeOfferModal: (defaultOffer, dataTableEl) => dispatch(actions.closeOfferModal(dispatch, defaultOffer, dataTableEl)),
    openOfferModal: () => dispatch(actions.openOfferModal()),
    onManageLineupInit: (defaultOffer) => dispatch(actions.onManageLineupInit(dispatch, defaultOffer)),
    fetchOfferData: (team, offerId, frontEndState) => dispatch(actions.fetchOfferData(dispatch, team, offerId, frontEndState)),
    refreshPerformer: (team, performerId) => dispatch(actions.refreshPerformer(dispatch, team, performerId)),
    setOfferPerformer: (performer) => dispatch(actions.setOfferPerformer(performer)),
    acceptOffer: (team, offerId, csrfToken) => dispatch(actions.acceptOffer(dispatch, team, offerId, csrfToken)),
    declineOffer: (team, offerId, csrfToken) => dispatch(actions.declineOffer(dispatch, team, offerId, csrfToken)),
    refreshConfirmOffers: (team, confirm) => dispatch(actions.refreshConfirmOffers(dispatch, team, confirm)),
    deleteOffer: (team, offerId, csrfToken) => dispatch(actions.deleteOffer(dispatch, team, offerId, csrfToken)),
    updateConfirmWebsite: (csrfToken, confirm, website, successCallback) => dispatch(actions.updateConfirmWebsite(dispatch, csrfToken, confirm, website, successCallback)),
    updateConfirmTicketForecast: (csrfToken, confirm, ticketForecast, successCallback, errorCallback) => dispatch(actions.updateConfirmTicketForecast(dispatch, csrfToken, confirm, ticketForecast, successCallback, errorCallback)),
    deleteConfirm: (csrfToken, team, confirm) => dispatch(actions.deleteConfirm(dispatch, csrfToken, team, confirm)),
    cancelConfirm: (csrfToken, team, confirm) => dispatch(actions.cancelConfirm(dispatch, csrfToken, team, confirm)),
    sendingCustomerIOEmailChanged: (sendingCustomerIOEmail) => dispatch(actions.sendingCustomerIOEmailChanged(sendingCustomerIOEmail)),
    customerIOEmailPurposeChanged: (customerIOEmailPurpose) => dispatch(actions.customerIOEmailPurposeChanged(customerIOEmailPurpose)),
    customerIOEmailTemplateChanged: (customerIOEmailTemplate) => dispatch(actions.customerIOEmailTemplateChanged(customerIOEmailTemplate)),
    createCustomerIOEmail: (eventbriteEventId, customerIOEmailPurpose, customerIOEmailTemplate) => dispatch(actions.createCustomerIOEmail(dispatch, eventbriteEventId, customerIOEmailPurpose, customerIOEmailTemplate)),
    marketingSectionActiveTabChanged: (tab) => dispatch(actions.marketingSectionActiveTabChanged(tab)),
    addingNewSocialAccountChanged: (addingNewSocialAccount) => dispatch(actions.addingNewSocialAccountChanged(addingNewSocialAccount)),
    composingPostChanged: (composingPost) => dispatch(actions.composingPostChanged(composingPost)),
    createPost: (csrfToken, team, confirm, postIntentToken) => dispatch(actions.createPost(dispatch, csrfToken, team, confirm, postIntentToken)),
    deletePost: (csrfToken, team, post) => dispatch(actions.deletePost(dispatch, csrfToken, team, post)),
    postToEditChanged: (post) => dispatch(actions.postToEditChanged(post)),
    updatePost: (csrfToken, team, post, postIntentToken) => dispatch(actions.updatePost(dispatch, csrfToken, team, post, postIntentToken)),
    updateCalendarEventContact: (csrfToken, team, calendarEventContact, params, index) => dispatch(actions.updateCalendarEventContact(dispatch, csrfToken, team, calendarEventContact, params, index)),
    fetchConfirmShowActivities: (csrfToken, team, confirm) => dispatch(actions.fetchConfirmShowActivities(dispatch, csrfToken, team, confirm)),
    deleteShowActivity: (csrfToken, team, showActivity) => dispatch(actions.deleteShowActivity(dispatch, csrfToken, team, showActivity)),
    editShowActivity: (showActivity) => dispatch(actions.editShowActivity(showActivity)),
    cancelEditShowActivity: (showActivityCopy) => dispatch(actions.cancelEditShowActivity(showActivityCopy)),
    addNewShowActivity: (showActivityCopy, confirm) => dispatch(actions.addNewShowActivity(showActivityCopy, confirm)),
    showActivityNameChanged: (index, name) => dispatch(actions.showActivityNameChanged(index, name)),
    showActivityStartDateChanged: (index, date) => dispatch(actions.showActivityStartDateChanged(index, date)),
    showActivityStartTimeChanged: (index, time) => dispatch(actions.showActivityStartTimeChanged(index, time)),
    createShowActivity: (csrfToken, team, showActivity, confirm) => dispatch(actions.createShowActivity(dispatch, csrfToken, team, showActivity, confirm)),
    updateShowActivity: (csrfToken, team, showActivity) => dispatch(actions.updateShowActivity(dispatch, csrfToken, team, showActivity)),
    showAdvanceSendChanged: (showAdvanceSend) => dispatch(actions.showAdvanceSendChanged(showAdvanceSend)),
    showAdvanceSendToChanged: (to) => dispatch(actions.showAdvanceSendToChanged(to)),
    showAdvanceSendSubjectChanged: (subject) => dispatch(actions.showAdvanceSendSubjectChanged(subject)),
    showAdvanceSendBodyChanged: (body) => dispatch(actions.showAdvanceSendBodyChanged(body)),
    createShowAdvanceSend: (csrfToken, team, showAdvanceSend) => dispatch(actions.createShowAdvanceSend(dispatch, csrfToken, team, showAdvanceSend)),
    showAdvanceSendNoteClicked: (noteId) => dispatch(actions.showAdvanceSendNoteClicked(noteId)),
    confirmInvitationChanged: (confirmInvitation) => dispatch(actions.confirmInvitationChanged(confirmInvitation)),
    confirmInvitationMemoChanged: (memo) => dispatch(actions.confirmInvitationMemoChanged(memo)),
    emailsToInviteChanged: (emailsToInvite) => dispatch(actions.emailsToInviteChanged(emailsToInvite)),
    createConfirmInvitations: (csrfToken, team, confirm, confirmInvitation, emailsToInvite, guestRole) => dispatch(actions.createConfirmInvitations(dispatch, csrfToken, team, confirm, confirmInvitation, emailsToInvite, guestRole)),
    isViewingGuestsChanged: (isViewingGuests) => dispatch(actions.isViewingGuestsChanged(isViewingGuests)),
    deleteConfirmInvitation: (csrfToken, team, invitation, confirmInvitationsAndTeamMemberships) => dispatch(actions.deleteConfirmInvitation(dispatch, csrfToken, team, invitation, confirmInvitationsAndTeamMemberships)),
    removeConfirmAccess: (csrfToken, team, teamMembership, confirm, confirmInvitationsAndTeamMemberships) => dispatch(actions.removeConfirmAccess(dispatch, csrfToken, team, teamMembership, confirm, confirmInvitationsAndTeamMemberships)),
    shareSettingsPopupClosed: () => dispatch(actions.shareSettingsPopupClosed()),
    offerForSettlementChanged: (offer) => dispatch(actions.offerForSettlementChanged(offer)),
    permissionSetChanged: (permissionSet) => dispatch(actions.permissionSetChanged(permissionSet)),
    fetchPermissionSet: (team, permissionSet, onPermissionSetSaved) => dispatch(actions.fetchPermissionSet(dispatch, team, permissionSet, onPermissionSetSaved)),
    permissionRuleEnabledClicked: (permissionRule) => dispatch(actions.permissionRuleEnabledClicked(permissionRule)),
    selectAllPermissionRulesClicked: (checked) => dispatch(actions.selectAllPermissionRulesClicked(checked)),
    submitPermissionSet: (csrfToken, team, permissionSet, onPermissionSetSaved) => dispatch(actions.submitPermissionSet(dispatch, csrfToken, team, permissionSet, onPermissionSetSaved)),
    noteUpdated: (note) => dispatch(actions.noteUpdated(note)),
    updateConfirmPageSection: (confirmPageSection) => dispatch(actions.updateConfirmPageSection(confirmPageSection)),
    addSocialMarketingBudgetItem: (marketingBudgetItem) => dispatch(actions.addSocialMarketingBudgetItem(marketingBudgetItem)),
    addTraditionalMarketingBudgetItem: (marketingBudgetItem) => dispatch(actions.addTraditionalMarketingBudgetItem(marketingBudgetItem)),
    createMarketingBudgetItem: (csrfToken, team, confirm, marketingBudgetItem) => dispatch(actions.createMarketingBudgetItem(dispatch, csrfToken, team, confirm, marketingBudgetItem)),
    destroyMarketingBudgetItem: (csrfToken, team, marketingBudgetItem) => dispatch(actions.destroyMarketingBudgetItem(dispatch, csrfToken, team, marketingBudgetItem)),
    editMarketingBudgetItem: (marketingBudgetItem) => dispatch(actions.editMarketingBudgetItem(marketingBudgetItem)),
    updateMarketingBudgetItem: (csrfToken, team, marketingBudgetItem) => dispatch(actions.updateMarketingBudgetItem(dispatch, csrfToken, team, marketingBudgetItem)),
    marketingBudgetItemToEditChanged: (marketingBudgetItem) => dispatch(actions.marketingBudgetItemToEditChanged(marketingBudgetItem)),
    fetchNewFinanceItem: (team, is_income) => dispatch(actions.fetchNewFinanceItem(dispatch, team, is_income)),
    deleteFinanceItem: (csrfToken, team, confirm, financeItem, offersToUpdate) => dispatch(actions.deleteFinanceItem(dispatch, csrfToken, team, confirm, financeItem, offersToUpdate)),
    financeItemsChanged: (financeItems) => dispatch(actions.financeItemsChanged(financeItems)),
    financeItemToEditChanged: (financeItem) => dispatch(actions.financeItemToEditChanged(financeItem)),
    financeItemToEditCanceled: () => dispatch(actions.financeItemToEditCanceled()),
    financeItemForFormChanged: (financeItemForForm) => dispatch(actions.financeItemForFormChanged(financeItemForForm)),
    createFinanceItem: (csrfToken, team, confirm, financeItem, offersToUpdate) => dispatch(actions.createFinanceItem(dispatch, csrfToken, team, confirm, financeItem, offersToUpdate)),
    updateFinanceItem: (csrfToken, team, confirm, financeItem, offersToUpdate) => dispatch(actions.updateFinanceItem(dispatch, csrfToken, team, confirm, financeItem, offersToUpdate)),
    financeCategoriesChanged: (financeCategories) => dispatch(actions.financeCategoriesChanged(financeCategories)),
    showWidgetCodeChanged: (showWidgetCode) => dispatch(actions.showWidgetCodeChanged(showWidgetCode)),
    uncancelConfirm: (csrfToken, team, confirm) => dispatch(actions.uncancelConfirm(dispatch, csrfToken, team, confirm)),
    editingConfirmWebsiteChanged: (editingConfirmWebsite) => dispatch(actions.editingConfirmWebsiteChanged(editingConfirmWebsite)),
    editingConfirmWebsiteValueChanged: (editingConfirmWebsiteValue) => dispatch(actions.editingConfirmWebsiteValueChanged(editingConfirmWebsiteValue)),
    fetchOfferTotalDue: (team, offer) => dispatch(actions.fetchOfferTotalDue(dispatch, team, offer)),
    offerChanged: (offer) => dispatch(actions.offerChanged(offer)),
    updateOfferArtist: (csrfToken, team, offer, successCallback) => dispatch(actions.updateOfferArtist(dispatch, csrfToken, team, offer, successCallback)),
    folderChanged: (folder) => dispatch(actions.folderChanged(folder)),
    profitAndLossChanged: (profitAndLoss) => dispatch(actions.profitAndLossChanged(profitAndLoss)),
    fetchProfitAndLoss: (csrfToken, team, confirm) => dispatch(actions.fetchProfitAndLoss(dispatch, csrfToken, team, confirm)),
    updateDeposit: (csrfToken, team, deposit, paid, successCallback) => dispatch(actions.updateDeposit(dispatch, csrfToken, team, deposit, paid, successCallback)),
    holdGroupDeletionChanged: (holdGroupDeletion) => dispatch(actions.holdGroupDeletionChanged(holdGroupDeletion)),
    deleteHoldGroup: (csrfToken, team, holdId, holdGroupDeletion) => dispatch(actions.deleteHoldGroup(dispatch, csrfToken, team, holdId, holdGroupDeletion)),
    holdToConfirmChanged: (holdToConfirm) => dispatch(actions.holdToConfirmChanged(holdToConfirm)),
    applyTimeDefaults: (confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime, callback) => dispatch(eventFormActions.applyTimeDefaults(dispatch, confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime, callback)),
    userChangedDoorsTimeChanged: (userChangedDoorsTime) => dispatch(actions.userChangedDoorsTimeChanged(userChangedDoorsTime)),
    userChangedEventEndTimeChanged: (userChangedEventEndTime) => dispatch(actions.userChangedEventEndTimeChanged(userChangedEventEndTime)),
    convertHoldGroupToConfirm: (csrfToken, team, holdToConfirm) => dispatch(actions.convertHoldGroupToConfirm(dispatch, csrfToken, team, holdToConfirm)),
    fetchPossibleMatchingOffers: (csrfToken, team, confirm) => dispatch(actions.fetchPossibleMatchingOffers(dispatch, csrfToken, team, confirm)),
    dismissMatchingOffers: (csrfToken, team, confirm, possibleMatchingOffers) => dispatch(actions.dismissMatchingOffers(dispatch, csrfToken, team, confirm, possibleMatchingOffers)),
    acceptMatchingOffer: (csrfToken, team, confirm, offer, performer) => dispatch(actions.acceptMatchingOffer(dispatch, csrfToken, team, confirm, offer, performer)),
    buyersChanged: (buyers) => dispatch(eventFormActions.buyersChanged(buyers)),
    selectedBuyerChanged: (buyer) => dispatch(eventFormActions.selectedBuyerChanged(buyer)),
    promotersChanged: (promoters) => dispatch(eventFormActions.promotersChanged(promoters)),
    selectedPromoterChanged: (promoter) => dispatch(eventFormActions.selectedPromoterChanged(promoter)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmShow);
