import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

var _ = require('lodash');
var debouncedBuildTicketReservation;
var seatsIOObjectSelected;
var seatsIOObjectDeselected;

const ChooseSeatedTicketTypes = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  ticketTypesChanged,
  addOns,
  seatsIOPublicKey,
  seatsIOChart,
  seatsIOChartChanged,
  seatsIOSelectedObjects,
  seatsIOSelectedObjectsChanged,
  selectedObjectLabel,
  selectedObjectType,
  buildTicketReservation,
  orderType
}) => {
  useEffect(() => {
    debouncedBuildTicketReservation = _.debounce((csrfToken, team, confirm, orderType, updatedTicketTypes, addOns) => {
      buildTicketReservation(csrfToken, team, confirm, orderType, updatedTicketTypes, addOns);
    }, 300);
  }, []);

  useEffect(() => {
    seatsIOObjectSelected = (object) => {
      var updatedSeatsIOSelectedObjects = [...seatsIOSelectedObjects, object];
      var updatedTicketTypes = [...ticketTypes].map((tt) => {
        var filteredObjects = updatedSeatsIOSelectedObjects.filter((o) =>
          o.category.key.toString() === tt.seating_chart_category_key
        );

        return Object.assign({}, tt, {
          seatsIOObjects: filteredObjects.map((object) => {
            return {
              seatsio_object_id: object.id,
              seat_assignment: selectedObjectLabel(object),
              seat_type: selectedObjectType(object),
              hold_token: object.chart?.holdToken
            }
          }),
          quantity: filteredObjects.length
        });
      });

      seatsIOSelectedObjectsChanged(updatedSeatsIOSelectedObjects);
      ticketTypesChanged(updatedTicketTypes);
      debouncedBuildTicketReservation(csrfToken, team, confirm, orderType, updatedTicketTypes, addOns);
    };

    seatsIOObjectDeselected = (object) => {
      var updatedSeatsIOSelectedObjects = seatsIOSelectedObjects.filter((o) => o.id !== object.id);
      var updatedTicketTypes = [...ticketTypes].map((tt) => {
        var filteredObjects = updatedSeatsIOSelectedObjects.filter((o) =>
          o.category.key.toString() === tt.seating_chart_category_key
        );

        return Object.assign({}, tt, {
          seatsIOObjects: filteredObjects.map((object) => {
            return {
              seatsio_object_id: object.id,
              seat_assignment: selectedObjectLabel(object),
              seat_type: selectedObjectType(object),
              hold_token: object.chart?.holdToken
            }
          }),
          quantity: filteredObjects.length
        });
      });

      seatsIOSelectedObjectsChanged(updatedSeatsIOSelectedObjects);
      ticketTypesChanged(updatedTicketTypes);
      debouncedBuildTicketReservation(csrfToken, team, confirm, orderType, updatedTicketTypes, addOns);
    };
  }, [seatsIOSelectedObjects, csrfToken, confirm, ticketTypes, addOns, team]);

  useEffect(() => {
    if(Object.keys(seatsIOChart).length > 0){
      seatsIOChart.changeConfig({
        pricing: ticketTypes.map((tt) => {
          var ticketPrice = parseFloat(tt.price);

          return {
            category: tt.seating_chart_category_key,
            price: ticketPrice
          };
        })
      });
    }
  }, [seatsIOChart, ticketTypes]);

  return (
    <div>
      {confirm.seatsio_event_id && confirm.seatsio_event_id.length > 0 ? (
        <div className="row">
          <div className="col-12">
            <div className="card"
                 style={{
                   "border": "1px solid #f3f3f3",
                   "height": "515px"
                 }}>
              <SeatsioSeatingChart workspaceKey={seatsIOPublicKey}
                                   event={confirm.seatsio_event_id}
                                   onRenderStarted={
                                     chart => {
                                       seatsIOChartChanged(chart);

                                       chart.config.session = "start";
                                       chart.config.onObjectSelected = (object) => seatsIOObjectSelected(object);
                                       chart.config.onObjectDeselected = (object) => seatsIOObjectDeselected(object);
                                       chart.config.multiSelectEnabled = true;
                                     }
                                   }
                                   priceFormatter={
                                     price => formatter.format(price)
                                   }
                                   region="na" />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card border-0 h-100"
                 style={{"background": "#f9f9f9"}}>
              <div className="card-body d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <p className="mb-2"
                     style={{"fontSize": "16px"}}>
                    <strong>An error occurred.</strong>
                  </p>
                  <p className="mb-0">
                    Please try again later.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ChooseSeatedTicketTypes.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  ticketTypesChanged: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  seatsIOPublicKey: PropTypes.string.isRequired,
  seatsIOChart: PropTypes.object,
  seatsIOChartChanged: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  seatsIOSelectedObjectsChanged: PropTypes.func.isRequired,
  selectedObjectLabel: PropTypes.func.isRequired,
  selectedObjectType: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  orderType: PropTypes.string
};

export default ChooseSeatedTicketTypes;

