/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  DEFAULT_TOP_HOLD_CHANGED,
  SHOW_ACTIVITY_DEFAULTS_CHANGED,
  EVENT_TEMPLATES_CHANGED,
  EVENT_TEMPLATE_FOR_FORM_CHANGED,
  FINANCES_VISIBLE_CHANGED,
  FINANCE_CATEGORIES_CHANGED,
  NOTES_VISIBLE_CHANGED,
  NOTE_TEMPLATES_CHANGED,
} from '../constants/ShowActivitySettingsConstants';


const sortedEventTemplates = eventTemplates => {
  return eventTemplates.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name === b.name) {
      return 0;
    } else {
      return 1;
    }
  });
}

export const showActivityDefaultsChanged = (showActivityDefaults) => {
  return {
    type: SHOW_ACTIVITY_DEFAULTS_CHANGED,
    showActivityDefaults
  }
};

export const defaultTopHoldChanged = (defaultTopHold) => {
  return {
    type: DEFAULT_TOP_HOLD_CHANGED,
    defaultTopHold
  }
};

export const eventTemplatesChanged = (eventTemplates) => {
  return {
    type: EVENT_TEMPLATES_CHANGED,
    eventTemplates
  }
};

export const eventTemplateForFormChanged = (eventTemplateForForm) => {
  return {
    type: EVENT_TEMPLATE_FOR_FORM_CHANGED,
    eventTemplateForForm
  }
};

export const financesVisibleChanged = (financesVisible) => {
  return {
    type: FINANCES_VISIBLE_CHANGED,
    financesVisible
  }
};

export const financeCategoriesChanged = (financeCategories) => {
  return {
    type: FINANCE_CATEGORIES_CHANGED,
    financeCategories
  }
};

export const notesVisibleChanged = (notesVisible) => {
  return {
    type: NOTES_VISIBLE_CHANGED,
    notesVisible
  }
};

export const noteTemplatesChanged = (noteTemplates) => {
  return {
    type: NOTE_TEMPLATES_CHANGED,
    noteTemplates
  }
};

export const eventTemplateParams = (eventTemplate) => {
  var params = {
    event_template: {
      name: eventTemplate.name,
      description: eventTemplate.description,
      note_ids: eventTemplate.notes.map(n => n.id),
      income_template_attributes: {
        ...eventTemplate.income_template,
        finance_line_items_attributes: eventTemplate.income_template.finance_line_items.map(fli => {
          return { ...fli, id: fli.id.toString().includes('new-') ? null : fli.id }
        }),
      },
      expense_template_attributes: {
        ...eventTemplate.expense_template,
        finance_line_items_attributes: eventTemplate.expense_template.finance_line_items.map(fli => {
          return { ...fli, id: fli.id.toString().includes('new-') ? null : fli.id }
        }),
      }
    }
  }

  return params;
};

export const createEventTemplate = (dispatch, csrfToken, teamId, eventTemplate, eventTemplates) => {
  return (dispatch) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.post(`/teams/${teamId}/event_templates`, eventTemplateParams(eventTemplate))
      .then((response) => {
        const updatedEventTemplates = [
          ...eventTemplates.filter(et => (et.id.toString().includes('new-') === false)),
          {...response.data}
        ];

        dispatch(eventTemplateForFormChanged({}));
        dispatch(eventTemplatesChanged(sortedEventTemplates(updatedEventTemplates)));

        toast.success("Event template created", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: true,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
}

export const updateEventTemplate = (dispatch, csrfToken, teamId, eventTemplate, eventTemplates) => {
  return (dispatch) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.patch(`/teams/${teamId}/event_templates/${eventTemplate.id}`, eventTemplateParams(eventTemplate))
      .then((response) => {
        const updatedEventTemplates = eventTemplates.map(et => {
          if (et.id === response.data.id) {
            return response.data;
          } else {
            return et;
          }
        });

        dispatch(eventTemplateForFormChanged({}));
        dispatch(eventTemplatesChanged(sortedEventTemplates(updatedEventTemplates)));

        toast.success("Event template updated", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: true,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
}

export const deleteEventTemplate = (dispatch, csrfToken, teamId, eventTemplate, eventTemplates) => {
  return (dispatch) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    console.log('delete event template');

    axios.delete(`/teams/${teamId}/event_templates/${eventTemplate.id}`)
      .then((response) => {
        const updatedEventTemplates = eventTemplates.filter(et => (et.id !== eventTemplate.id));

        dispatch(eventTemplateForFormChanged({}));
        dispatch(eventTemplatesChanged(sortedEventTemplates(updatedEventTemplates)));

        toast.success("Event template deleted", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: true,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
}
