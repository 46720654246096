import React, { createContext, useContext, useState } from "react";
import { ToastContainer } from "react-toastify";

import BroadcastIndex from "./BroadcastIndex";
import SetupModal from "../MarketingSettings/SetupModal";

const BroadcastIndexContext = createContext();

export function useBroadcastIndexContext() {
  return useContext(BroadcastIndexContext);
}

const BroadcastIndexProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
    dataTableProps,
    venueOwnerships,
    lastTouchedVenueOwnership,
    sendDomains,
  } = props;

  const [venueOwnership, setVenueOwnership] = useState(lastTouchedVenueOwnership);

  const value = {
    csrfToken,
    user,
    team,
    dataTableProps,
    venueOwnerships,
    venueOwnership,
    setVenueOwnership,
  };

  return (
    <BroadcastIndexContext.Provider value={value}>
      <>
        <SetupModal 
          venueOwnership={venueOwnership}
          sendDomains={sendDomains}
        />
        {props.children}
      </>
    </BroadcastIndexContext.Provider>
  );
};

const BroadcastIndexContainer = (props) => (
  <BroadcastIndexProvider {...props}>
    <ToastContainer />
    <BroadcastIndex />
  </BroadcastIndexProvider>
);

export default BroadcastIndexContainer;
