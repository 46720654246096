import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import TableSearch from '../TableSearch';
import DataTable from '../../../DataTable/components/DataTable';
import FilterPanel from '../FilterPanel';
import ExportCSV from '../ExportCSV';

const StandardLayout = ({
  title,
  searchPlaceholder,
  typedSearchTerm,
  searchedSearchTerm,
  startSearchTermUpdate,
  dataSource,
  columns,
  order,
  filters,
  updateFilter,
  debouncedUpdateFilter,
  clearFilterValues,
  tableClassNames,
  csvDataSource,
  dataTableEl,
  dataTableElChanged,
  layout,
  reduceFilters
}) => {
  return (
    <div>
      {searchPlaceholder.length ? (
        <TableSearch searchPlaceholder={searchPlaceholder}
                    typedSearchTerm={typedSearchTerm}
                    startSearchTermUpdate={startSearchTermUpdate} />
      ) : null}
      {filters.length > 0 ? (
        <FilterPanel filters={filters}
                    clearFilterValues={clearFilterValues}
                    debouncedUpdateFilter={debouncedUpdateFilter}
                    updateFilter={updateFilter} />
      ) : (
        <div className="p-3 zero-table-view-filters"></div>
      )}
      <div className="row">
        <div className="col-12">
          {csvDataSource && csvDataSource.length > 0 ? (
            <div className="datatable-right-header-section pr-0"
                 style={{"zIndex": 100, "right": "30px"}}>
              <ExportCSV csvDataSource={csvDataSource}
                         dataTableFilters={reduceFilters(filters)}
                         dataTableEl={dataTableEl.current} />
            </div>
          ) : null}
          <div key={filters.map(filter => filter.value)}>
            <DataTable
              title={title}
              searchedSearchTerm={searchedSearchTerm}
              dataSource={dataSource}
              columns={columns}
              reducedFilters={reduceFilters(filters)}
              tableClassNames={tableClassNames}
              onInitComplete={(settings, json) => {
                dataTableEl.current = settings.oInstance[0]
                dataTableElChanged(dataTableEl.current);
              }}
              order={order}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

StandardLayout.propTypes = {
  title: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  typedSearchTerm: PropTypes.string,
  searchedSearchTerm: PropTypes.string,
  startSearchTermUpdate: PropTypes.func.isRequired,
  dataSource: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  order: PropTypes.array,
  filters: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
  debouncedUpdateFilter: PropTypes.func.isRequired,
  clearFilterValues: PropTypes.func.isRequired,
  tableClassNames: PropTypes.string,
  csvDataSource: PropTypes.string,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  layout: PropTypes.string,
  reduceFilters: PropTypes.func.isRequired
};

export default StandardLayout;
