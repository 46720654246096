/* eslint-disable import/prefer-default-export */

import {
  DATA_TABLE_EL_CHANGED,
  PUSH_NOTIFICATION_CHANGED,
  IS_SUBMITTING_CHANGED,
  ESTIMATED_NUMBER_OF_SENDS_CHANGED
} from '../constants/pushNotificationsConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import $ from 'jquery';
require('datatables.net-bs4');

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const fetchNewPushNotification = (dispatch, csrfToken, team) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get("/teams/" + team.id + "/push_notifications/new")
      .then(({ data }) => {
        dispatch(pushNotificationChanged(data));
      });
  };
};

export const pushNotificationChanged = (pushNotification) => ({
  type: PUSH_NOTIFICATION_CHANGED,
  pushNotification
});

export const createPushNotification = (dispatch, csrfToken, team, pushNotification, dataTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    dispatch(isSubmittingChanged(true));

    return axios.post("/teams/" + team.id + "/push_notifications", {
        push_notification: {
          venue_ownership_id: pushNotification.venue_ownership_id,
          title: pushNotification.title,
          subtitle: pushNotification.subtitle,
          message: pushNotification.message,
          push_notifications_calendar_events_attributes: pushNotification.calendar_events.map((calendar_event) => {
            return { calendar_event_id: calendar_event.value }
          }) || []
        }
      })
      .then(({ data }) => {
        dispatch(pushNotificationChanged({}));

        if(dataTableEl && Object.keys(dataTableEl).length > 0){
          $(dataTableEl).DataTable().ajax.reload();
        } else {
          window.location.replace(`/teams/${team.id}/push_notifications`);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .then(() => {
        dispatch(isSubmittingChanged(false));
      });
  }
};

export const isSubmittingChanged = (isSubmitting) => ({
  type: IS_SUBMITTING_CHANGED,
  isSubmitting
});

export const fetchEstimatedNumberOfSends = (dispatch, team, pushNotification) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/push_notifications/estimated_number_of_sends", {
      params: {
        venue_ownership_id: pushNotification.venue_ownership_id,
        calendar_event_ids: pushNotification.calendar_events.map(e => e.value) || []
      }
    })
    .then(({ data }) => {
      dispatch(estimatedNumberOfSendsChanged(data.count));
    });
  };
};

export const estimatedNumberOfSendsChanged = (estimatedNumberOfSends) => ({
  type: ESTIMATED_NUMBER_OF_SENDS_CHANGED,
  estimatedNumberOfSends
});
