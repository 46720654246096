import React, { useEffect, useState, useRef } from "react";

import axios from "axios";
import SampleDataPicker from "./SampleDataPicker";
import { useActionContext } from "./ActionContainer";
import { toastError } from "../../../../shared/toastHelper";

const containerStyle = {
  background: "white",
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "grid",
  width: "100%",
};

const previewColumnStyle = {
  borderLeft: "1px solid #EEEEEE",
  color: "#333333",
  display: "flex",
  flexDirection: "column",
  fontSize: "14px",
};

const detailRowStyle = {
  borderBottom: "1px solid #EEEEEE",
  padding: "10px",
};

const viewerButtonContainerStyle = {
  boxShadow: "0 2px 5px 0 rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "row",
  width: "fit-content",
};

const viewerButtonStyle = {
  padding: "10px",
  textAlign: "center",
  width: "35px",
};

const desktopViewerContainerStyle = {
  height: "60vh",
  maxHeight: "75%",
  overflow: "scroll",
  padding: "5px",
};

const desktopViewerStyle = {
  height: "100%",
  width: "100%",
};

export const htmlContentStyle = {
  border: "none",
  height: "100%",
  overflow: "scroll",
  width: "100%",
};

const mobileViewerContainerStyle = {
  background: "#999999",
  display: "grid",
  height: "60vh",
  padding: "10px",
  placeItems: "center",
};

const mobileViewerStyle = {
  background: "white",
  height: "100%",
  maxHeight: "60vh",
  overflow: "scroll",
  width: "320px",
};

const sampleDataPickerContainer = {
  padding: 15,
};

const EmailPreviewTab = () => {
  const {
    csrfToken,
    team,
    template,
    sampleData,
    parseLiquid,
    sampleFanId,
    sampleEventIds,
    subject,
    sendDomain,
    beefreeTemplate,
    campaign,
  } = useActionContext();

  const [isLoading, setIsLoading] = useState(true);
  const [htmlContent, setHtmlContent] = useState("");
  const [viewer, setViewer] = useState("desktop");
  const [parsedSubject, setParsedSubject] = useState(subject);

  useEffect(() => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post("/teams/" + team.id + "/beefree_templates/" + beefreeTemplate.id + "/render_liquid_html", {
      beefree_template: {
        template: template,
        sample_data: sampleData,
      },
    })
      .then(({ data }) => {
        setHtmlContent(data.parsedText);
        setIsLoading(false);
      })
      .catch(() => toastError("Could not fetch email preview, please try again"));

    parseLiquid(subject, sampleData, team.id, beefreeTemplate.id, csrfToken).then(({ parsedText }) => {
      setParsedSubject(parsedText);
    });
  }, [template, sampleData]);

  const sampleDataAvailable = !!sampleFanId && !!sampleEventIds.length;

  const iframeRef = useRef(null);

  const updateLinks = () => {
    const iframe = iframeRef.current;

    if (iframe && iframe.contentDocument) {
      const iframeLinks = iframe.contentDocument.querySelectorAll("a");

      iframeLinks.forEach(link => {
        link.setAttribute("target", "_blank");
      });
    }
  };

  return (
    isLoading ? (
      <div>Loading...</div>
    ) : (
      <div style={{
        ...containerStyle,
        gridTemplateColumns: sampleDataAvailable ? "minmax(0, 1fr) minmax(0, 2fr)" : "minmax(0, 1fr)"
      }}>
        {sampleDataAvailable ? (
          <div style={sampleDataPickerContainer}>
            <SampleDataPicker />
          </div>
        ) : null}
        <div style={previewColumnStyle}>
          <div style={detailRowStyle}>
            <div style={viewerButtonContainerStyle}>
              <i
                className="fas fa-display"
                style={{
                  ...viewerButtonStyle,
                  background: viewer === "desktop" ? "#1982C4" : "white",
                  color: viewer === "desktop" ? "white" : "#515659",
                }}
                onClick={() => { setViewer("desktop") }}
              />
              <i
                className="fa fa-mobile"
                style={{
                  ...viewerButtonStyle,
                  background: viewer === "mobile" ? "#1982C4" : "white",
                  color: viewer === "mobile" ? "white" : "#515659",
                }}
                onClick={() => { setViewer("mobile") }}
              />
            </div>
          </div>
          <div style={detailRowStyle}><b>From: </b>{sendDomain?.name_with_address}</div>
          <div style={detailRowStyle}><b>To: </b>{sampleData.fan?.email}</div>
          <div style={detailRowStyle}><b>Subject: </b>{parsedSubject}</div>
          <div style={viewer === "desktop" ? desktopViewerContainerStyle : mobileViewerContainerStyle}>
            <div style={viewer === "desktop" ? desktopViewerStyle : mobileViewerStyle}>
              <iframe
                ref={iframeRef}
                srcDoc={htmlContent}
                style={htmlContentStyle}
                onLoad={updateLinks}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default EmailPreviewTab;
