import { createStore, applyMiddleware, compose } from 'redux';
import dataTableWithSidebarViewReducer from '../reducers/dataTableWithSidebarViewReducer';
import {responsiveStoreEnhancer} from 'redux-responsive'
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(dataTableWithSidebarViewReducer, railsProps, compose(
    applyMiddleware(createDebounce(), thunk),
    responsiveStoreEnhancer
  ))
);

export default configureStore;
