/* eslint-disable import/prefer-default-export */

export const CHART_COLORS = [
  "#6A4C93", "#8AC926", "#FFCA3A",
  "#1982C4", "#FF595E"
];

export const SELECTED_ARTIST_CHANGED = 'SELECTED_ARTIST_CHANGED';
export const POTENTIAL_ARTIST_FAN_DATA_CHANGED = 'POTENTIAL_ARTIST_FAN_DATA_CHANGED';
export const IS_LOADING_POTENTIAL_ARTIST_FAN_DATA_CHANGED = 'IS_LOADING_POTENTIAL_ARTIST_FAN_DATA_CHANGED';
export const IS_LOADING_GEO_COORDINATES_CHANGED = 'IS_LOADING_GEO_COORDINATES_CHANGED';
export const GEO_COORDINATES_CHANGED = 'GEO_COORDINATES_CHANGED';
export const IS_LOADING_TOP_GENRES_CHANGED = 'IS_LOADING_TOP_GENRES_CHANGED';
export const TOP_GENRES_CHANGED = 'TOP_GENRES_CHANGED';
