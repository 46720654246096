import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import { toast } from 'react-toastify';

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const TeamManageableTableRow = ({ teamManageable, editTeamManageable, deleteTeamManageable, csrfToken, team, resetPasswordForEmail }) => (
  <tr>
    <td style={{"paddingLeft": "20px"}}>
      <div className="row">
        <div className="col">
          <div>{teamManageable.first_name} {teamManageable.last_name}</div>
          <div className="text-muted">{teamManageable.email}</div>
        </div>
        {teamManageable.owner ? (
          <div className="col">
            <span className="badge badge-primary p-2 mt-2">Owner</span>
          </div>
        ) : null}
      </div>
    </td>
    <td>{teamManageable.role.display_name}</td>
    <td>
      {teamManageable.all_venue_access ? (
        "All Venues"
      ) : (
        teamManageable.venue_ownership_accesses.map((voa) => voa.venue_ownership.venueable.name).join(", ")
      )}
    </td>
    <td className="text-right" style={{"paddingRight": "20px"}}>
      <ul className="list-inline no-bullets" style={{display: "inline"}}>
        <li className="list-inline-item">
          <a href="#"
             onClick={
               (e) => {
                 e.preventDefault();
                 editTeamManageable(teamManageable);
               }
             }
             className="text-muted">
            <u>Edit</u>
          </a>
        </li>
        {(teamManageable.type_for_ui === "TeamMembership" && teamManageable.user && teamManageable.user.id) ? (
          <li className="list-inline-item">
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();
                   resetPasswordForEmail(csrfToken, teamManageable, team);

                   toast.success("Password reset instructions have been sent to the user.", {
                     position: toast.POSITION.TOP_CENTER,
                     draggable: false,
                     closeOnClick: false,
                     autoClose: 5000,
                     hideProgressBar: true
                   });
                 }
               }
               className="text-muted">
              <u>Reset password</u>
            </a>
          </li>
        ) : null}
        <li className="list-inline-item">
        <Popup
            trigger={
              <a href="#"
                 onClick={(e) => e.preventDefault()}
                 className="text-danger">
                <u>Delete</u>
              </a>
            }
            modal
            contentStyle={deleteModalContentStyle}
            onOpen={
              (e) => {
                e.preventDefault();
              }
            }
            closeOnDocumentClick
          >
          {close => (
            <div>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <h5 style={{"marginBottom": "16px"}}>
                        <strong>Delete team member?</strong>
                      </h5>
                    </div>
                    <div className="col-xs-auto">
                      <a href="#"
                         className="text-dark"
                         style={{"paddingRight": "15px"}}
                         onClick={(e) => {e.preventDefault(); close();}} >
                        <i className="fa fa-times"></i>
                      </a>
                    </div>
                  </div>
                  <p>You're about to delete team member {teamManageable.name}.</p>
                  <p>This cannot be undone.</p>
                  <p>Are you sure?</p>
                  <div className="text-right">
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           close();
                         }
                       }>
                      Cancel
                    </a>
                    <a href="#"
                       className="btn btn-danger ml-3"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           deleteTeamManageable(csrfToken, team, teamManageable, close);
                         }
                       }>
                      Yes, delete team member
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>
        </li>
      </ul>
    </td>
  </tr>
);

TeamManageableTableRow.propTypes = {
  teamManageable: PropTypes.object.isRequired,
  editTeamManageable: PropTypes.func.isRequired,
  deleteTeamManageable: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  resetPasswordForEmail: PropTypes.func.isRequired
};

export default TeamManageableTableRow;
