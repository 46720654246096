import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/offerV2PreviewStore';
import OfferV2PreviewContainer from '../containers/OfferV2PreviewContainer';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const OfferV2PreviewApp = (props) => (
  <Provider store={configureStore(props)}>
    <OfferV2PreviewContainer />
  </Provider>
);

export default OfferV2PreviewApp;
