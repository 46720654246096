import React from 'react';

import UniversalPromoCodeManager from '../components/UniversalPromoCodeManager';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const UniversalPromoCodeManagerApp = ({
  csrfToken,
  team,
  currentUser,
  paginatedPromoCodes,
  search,
  ticketTypeTemplates,
}) => (
  <UniversalPromoCodeManager
    csrfToken={csrfToken}
    team={team}
    currentUser={currentUser}
    paginatedPromoCodes={paginatedPromoCodes}
    search={search}
    ticketTypeTemplates={ticketTypeTemplates}
  />
);

export default UniversalPromoCodeManagerApp;
