import { toast } from "react-toastify";

export const toastSuccess = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    draggable: false,
    closeOnClick: false,
    autoClose: 5000,
    hideProgressBar: true
  });
};

export const toastError = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    draggable: false,
    closeOnClick: false,
    autoClose: 5000,
    hideProgressBar: true
  });
};
