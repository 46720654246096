import PropTypes from 'prop-types';
import React from 'react';

const hideTeamMembership = (rolePermissionRule, permissionRule, guestTeamMemberships, promotersWithPermission) => {
  if(!rolePermissionRule){
    return false;
  }

  switch(rolePermissionRule.rulable_data.name) {
  case "Guest":
    return !guestTeamMemberships
      .map((tm) => tm.id)
      .includes(permissionRule.rulable_data.id);
    break;
  case "Promoter":
    return !promotersWithPermission
      .map((tm) => tm.id)
      .includes(permissionRule.rulable_data.id);
    break;
  default:
    return false;
  }
}

const PermissionRule = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  permissionRule,
  permissionRuleEnabledClicked,
  rolePermissionRule,
  guestTeamMemberships,
  promotersWithPermission
}) => (
  <div className={"col-12 " + (hideTeamMembership(rolePermissionRule, permissionRule, guestTeamMemberships, promotersWithPermission) ? "hide" : "")}
       style={{
         "margin": (permissionRule.rulable_type === "TeamMembership" ? "0 11px 14px 40px" : "0 11px 14px 11px")
       }}>
    <div className="custom-control custom-checkbox custom-checkbox-table">
      <input type="checkbox"
             checked={permissionRule.enabled || false}
             onChange={
               (e) => {
                 permissionRuleEnabledClicked(permissionRule);
               }
             }
             className="custom-control-input"
             id={"select-role-permission-rule-" + permissionRule.rulable_type + "-" + permissionRule.rulable_id} />
      <label className="custom-control-label"
             style={{"fontSize": "14px"}}
             htmlFor={"select-role-permission-rule-" + permissionRule.rulable_type + "-" + permissionRule.rulable_id}>
        {permissionRule.rulable_data.name}
      </label>
    </div>
  </div>
);

PermissionRule.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  permissionRule: PropTypes.object,
  permissionRuleEnabledClicked: PropTypes.func.isRequired,
  rolePermissionRule: PropTypes.object,
  guestTeamMemberships: PropTypes.array,
  promotersWithPermission: PropTypes.array
};

export default PermissionRule;
