import PropTypes from 'prop-types';
import React from 'react';
import Popup from "reactjs-popup";

import DatePicker from "react-datepicker";

const contentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "auto",
  "padding": "10px"
}

class DateRangeFilter extends React.Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    updateFilter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    var endDate;
    var startDate;

    this.state = {
      filter: this.props.filter,
      updateFilter: this.props.updateFilter,
      startDate: startDate,
      endDate: endDate
    };
  }

  setStartDate(date) {
    this.setState({startDate: date});
    this.syncDatesToFilter();
  }

  setEndDate(date) {
    this.setState({endDate: date});
    this.syncDatesToFilter();
  }

  syncDatesToFilter(){
    setTimeout(() => {
      if(this.state.startDate && this.state.endDate){
        this.state.updateFilter(this.state.filter, [this.state.startDate, this.state.endDate]);
      } else {
        this.state.updateFilter(this.state.filter, []);
      }
    }, 1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({filter: this.props.filter});
    }
  }

  render() {
    return (
      <div className="filter-pill-container mr-2 my-3">
        <Popup arrow={false}
               offsetY={5}
               position="bottom left"
               contentStyle={contentStyle}
               trigger={open => (
          <span className={"filter-pill clickable " + (open ? " opened " : "") + (this.state.filter.value.length > 0 ? " filtering " : "")}>
            <span className="px-1">{this.state.filter.label}</span>
            {this.state.filter.value.length === 0 &&
              <strong className="px-2">All</strong>
            }
            {this.state.filter.value.length > 0 &&
              <strong className="px-2">{this.state.filter.value.map((d) => d.toLocaleDateString()).join(" → ")}</strong>
            }
            <i className="fa fa-chevron-down px-1"></i>
          </span>
        )} >
          {close => (
            <div className='row'>
              <div className="col-6">
                <p className="mb-0 text-muted">Start</p>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={date => this.setStartDate(date)}
                  className="my-1 form-control"
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  calendarClassName="hide"
                  showPopperArrow={false}
                />
                <DatePicker
                  selected={this.state.startDate}
                  onChange={date => this.setStartDate(date)}
                  selectsStart
                  inline
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
              </div>
              <div className="col-6">
                <p className="mb-0 text-muted">End</p>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={date => this.setEndDate(date)}
                  className="my-1 form-control"
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                  calendarClassName="hide"
                  showPopperArrow={false}
                />
                <DatePicker
                  selected={this.state.endDate}
                  onChange={date => this.setEndDate(date)}
                  selectsEnd
                  inline
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                />
              </div>
            </div>
          )}
        </Popup>
      </div>
    );
  }
}

export default DateRangeFilter;
