import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import Popup from "reactjs-popup";

import BasicInfo from './BasicInfo';
import Details from './Details';
import Tickets from './Tickets';
import Publish from './Publish';
import Settings from './Settings';
import EventDetailsApp from '../../EventDetails/startup/EventDetailsApp';
import Automations from './Automations';
import Promotions from './Promotions';
import Customize from './Customize';

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

import {
  BASIC_INFO_MENU_ITEM,
  DETAILS_MENU_ITEM,
  TICKETS_MENU_ITEM,
  AUTOMATION_MENU_ITEM,
  PROMOTION_MENU_ITEM,
  PUBLISH_MENU_ITEM,
  SETTINGS_MENU_ITEM,
  CUSTOMIZE_MENU_ITEM
} from '../constants/confirmTicketingConstants';

const warnBeforeLeavingModalStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const beforeUnloadListener = (e) => {
  e.preventDefault();
  // Most modern browsers ignore custom messages for the beforeunload dialog now,
  // but we'll still return a value here to make sure the dialog is shown.
  e.returnValue = 'Tickets will not go on sale until the event is published';
  return e.returnValue;
};

const ConfirmTicketing = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  confirmChanged,
  activeMenuItem,
  activeMenuItemChanged,
  updateConfirm,
  autoSaveConfirm,
  venuesOptions,
  performers,
  deletePerformer,
  createPerformer,
  updatePerformer,
  pageDetails,
  createPageDetail,
  deletePageDetail,
  pageDetailChanged,
  autoSavePageDetail,
  pageDetailDragged,
  imageCalendarEventAttachments,
  uploadPageDetailImage,
  uploadPageDetailImageUrl,
  uploadConfirmMainImageFile,
  uploadConfirmMainImageUrl,
  activeTicketingMenuItem,
  activeTicketingMenuItemChanged,
  ticketTypeForForm,
  ticketTypeForFormChanged,
  fetchNewTicketType,
  ticketTypes,
  deleteTicketType,
  fetchTicketTypeForEdit,
  createTicketType,
  updateTicketType,
  ticketTypeDragged,
  mainEventImageLoading,
  publishConfirm,
  rootUrl,
  addOns,
  addOnForForm,
  fetchNewAddOn,
  addOnForFormChanged,
  createAddOn,
  fetchAddOnToEdit,
  deleteAddOn,
  updateAddOn,
  addOnDragged,
  statementDescriptorSuffix,
  statementDescriptorSuffixChanged,
  maxDescriptorLength,
  updateStatementDescriptorSuffix,
  artistBios,
  artistBiosChanged,
  pageDetailTemplates,
  deletePageDetailTemplate,
  pageDetailTemplate,
  pageDetailTemplateChanged,
  createPageDetailTemplate,
  createPerformerAndArtist,
  performerDragged,
  exampleTicket,
  buildExampleTicket,
  exampleTicketChanged,
  seatingCharts,
  createDefaultTicketTypes,
  isLoadingDefaultTicketTypes,
  addOnSeatingCharts,
  isEditingAddOnsIntro,
  isEditingAddOnsIntroChanged,
  addOnsIntroEditorState,
  addOnsIntroEditorStateChanged,
  createDefaultAddOns,
  isLoadingDefaultAddOns,
  isEditingAddOnsAlertMessage,
  isEditingAddOnsAlertMessageChanged,
  addOnsAlertMessageEditorState,
  addOnsAlertMessageEditorStateChanged,
  promoters,
  buyers,
  requirePerTicketCustomChargeTypes,
  createRequirePerTicketCustomChargeType,
  requirePerTicketCustomChargeTypeChanged,
  deleteRequirePerTicketCustomChargeType,
  autoSaveRequirePerTicketCustomChargeType,
  showActivities,
  manualTaxes,
  warnBeforeLeaving,
  warnBeforeLeavingChanged,
  warnBeforeLeavingOpen,
  warnBeforeLeavingOpenChanged,
  warnBeforeLeavingCallback,
  warnBeforeLeavingCallbackChanged,
  addOnAdded,
  feeRuleSets,
  preSaleEnabled,
  checkoutCategories,
  checkoutCategoriesChanged
}) => {
  useEffect(() => {
    var updated = Object.assign({}, confirm, {
      published_at: offsetDateForBrowser(confirm.published_at)
    });

    requirePerTicketCustomChargeTypes.forEach((cct) => {
      if(cct.default_amount){
        var updated = Object.assign({}, cct, {
          default_amount: parseFloatFromString(cct.default_amount).toFixed(2)
        });

        requirePerTicketCustomChargeTypeChanged(updated);
      }
    });

    if(requirePerTicketCustomChargeTypes.length === 0){
      createRequirePerTicketCustomChargeType(csrfToken, team, confirm);
    }

    confirmChanged(updated);
  }, []);

  useEffect(() => {
    if(confirm.published_at) {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    }
  }, [confirm]);

  const checkWarnBeforeLeaving = (callback) => {
    if(warnBeforeLeaving) {
      warnBeforeLeavingCallbackChanged(() => {
        callback();
      });

      warnBeforeLeavingOpenChanged(true);
    } else {
      callback();
    }
  };

  return (
    <div>
      <Popup
        modal
        open={warnBeforeLeavingOpen}
        contentStyle={warnBeforeLeavingModalStyle}
        closeOnDocumentClick
        onClose={() => {
          warnBeforeLeavingOpenChanged(false);
          warnBeforeLeavingCallbackChanged(null);
        }}
      >
        {close => (
          <div>
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <h5 style={{"marginBottom": "16px"}}>
                      <div className="strong">Leave event unpublished?</div>
                    </h5>
                  </div>
                  <div className="col-xs-auto">
                    <a
                      href="#"
                      className="text-dark"
                      style={{"paddingRight": "15px"}}
                      onClick={e => {
                        e.preventDefault();
                        close();
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </a>
                  </div>
                </div>
                <p>Tickets will not go on sale until the event is published.</p>
                <p>Are you sure you want to leave without publishing the event?</p>
                <div className="text-right">
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      close();
                    }}
                  >
                    Cancel
                  </a>
                  <a href="#"
                    className="btn btn-danger ml-3"
                    onClick={e => {
                      e.preventDefault();

                      window.removeEventListener("beforeunload", beforeUnloadListener);

                      if(warnBeforeLeavingCallback) {
                        warnBeforeLeavingCallback();
                      }

                      warnBeforeLeavingChanged(false);
                      close();
                    }}
                  >
                    Confirm
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
      <ToastContainer />
      <div className="row m-0">
        <div className="col-12">
          <div className="page-title d-flex align-items-center">
            { confirm.name }
            {confirm.calendar_classification === "past" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#919191", "color": "white", "fontSize": "10px"}}>
                Past
              </span>
            ) : confirm.calendar_classification === "canceled" ? (
              <span className="badge badge-danger mt-1 ml-3 p-2"
                    style={{"fontSize": "10px"}}>
                Canceled
              </span>
            ) : confirm.calendar_classification === "on_sale" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#5f5383", "color": "white", "fontSize": "10px"}}>
                On Sale
              </span>
            ) : confirm.calendar_classification === "pre_sale" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#FF7A00", "color": "white", "fontSize": "10px"}}>
                Pre Sale
              </span>
            ) : confirm.calendar_classification === "announced" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#98c648", "color": "white", "fontSize": "10px"}}>
                Announced
              </span>
            ) : (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#4c81b9", "color": "white", "fontSize": "10px"}}>
                Confirmed
              </span>
            )}
          </div>
          <EventDetailsApp
            csrfToken={csrfToken}
            team={team}
            currentUser={currentUser}
            confirm={{...confirm}}
            confirmSaved={(savedConfirm) => {
              confirmChanged(savedConfirm);
            }}
            buyers={[...buyers]}
            promoters={promoters} />
        </div>
      </div>
      <div className="row mb-4 mx-0">
        <div className="col-12">
          <div className="card border-0 shadow-2 overflow-hidden"
               style={{
                 "background": "#f4f9fc"
               }}>
            <div className="row"
                 style={{"padding": "0 15px"}}>
              <div className="col-12 col-md-auto"
                   style={{
                     "padding": "22px 15px"
                   }}>
                <h3 className="mb-5"
                    style={{"fontSize": "24px"}}>
                  <strong>
                    Ticketing
                  </strong>
                </h3>
                <ul className="no-bullets p-0 ticketing-menu small">
                  <li
                    className={"clickable p-2 mb-1 d-flex align-items-center " + (activeMenuItem === BASIC_INFO_MENU_ITEM ? "active" : "")}
                    onClick={e => {
                      e.preventDefault();

                      checkWarnBeforeLeaving(() => {
                        activeMenuItemChanged(BASIC_INFO_MENU_ITEM);
                      });
                    }}
                  >
                    <span className="dot d-flex align-items-center justify-content-center">
                      <i className="fas fa-list fa-fw"></i>
                    </span>
                    <strong>Basic Info</strong>
                  </li>
                  <li
                    className={"clickable p-2 mb-1 d-flex align-items-center " + (activeMenuItem === DETAILS_MENU_ITEM ? "active" : "")}
                    onClick={e => {
                      e.preventDefault();

                      checkWarnBeforeLeaving(() => {
                        activeMenuItemChanged(DETAILS_MENU_ITEM);
                      });
                    }}
                  >
                    <span className="dot d-flex align-items-center justify-content-center">
                      <i className="far fa-image fa-fw"></i>
                    </span>
                    <strong>Details</strong>
                  </li>
                  <li
                    className={"clickable p-2 mb-1 d-flex align-items-center " + (activeMenuItem === TICKETS_MENU_ITEM ? "active" : "")}
                    onClick={e => {
                      e.preventDefault();

                      checkWarnBeforeLeaving(() => {
                        activeMenuItemChanged(TICKETS_MENU_ITEM);
                      });
                    }}
                  >
                    <span className="dot d-flex align-items-center justify-content-center">
                      <i className="far fa-ticket-alt fa-fw"></i>
                    </span>
                    <strong>Tickets</strong>
                  </li>
                  {team.can_access_marketing_dashboard ? (
                    <li
                      className={"clickable p-2 mb-1 d-flex align-items-center " + (activeMenuItem === AUTOMATION_MENU_ITEM ? "active" : "")}
                      onClick={e => {
                        e.preventDefault();

                        checkWarnBeforeLeaving(() => {
                          activeMenuItemChanged(AUTOMATION_MENU_ITEM);
                        });
                      }}
                    >
                      <span className="dot d-flex align-items-center justify-content-center">
                        <i className="far fa-solid fa-gears"></i>
                      </span>
                      <strong>Automations</strong>
                    </li>
                  ) : null}
                  <li
                    className={"clickable p-2 mb-1 d-flex align-items-center " + (activeMenuItem === PROMOTION_MENU_ITEM ? "active" : "")}
                    onClick={e => {
                      e.preventDefault();

                      checkWarnBeforeLeaving(() => {
                        activeMenuItemChanged(PROMOTION_MENU_ITEM);
                      });
                    }}
                  >
                    <span className="dot d-flex align-items-center justify-content-center">
                      <i className="far fa-bullhorn"></i>
                    </span>
                    <strong>Promotion</strong>
                  </li>
                  {team.can_use_festival_widget ? (
                    <li
                      className={"clickable p-2 mb-1 d-flex align-items-center " + (activeMenuItem === CUSTOMIZE_MENU_ITEM ? "active" : "")}
                      onClick={e => {
                        e.preventDefault();

                        checkWarnBeforeLeaving(() => {
                          activeMenuItemChanged(CUSTOMIZE_MENU_ITEM);
                        });
                      }}
                    >
                      <span className="dot d-flex align-items-center justify-content-center">
                        <i className="far fa-wrench"></i>
                      </span>
                      <strong>Customize</strong>
                    </li>
                  ) : null}
                  <li
                    className={"clickable p-2 mb-1 d-flex align-items-center " + (activeMenuItem === PUBLISH_MENU_ITEM ? "active " : "")}
                    onClick={e => {
                      e.preventDefault();
                      activeMenuItemChanged(PUBLISH_MENU_ITEM);

                      if(!confirm.published_at) {
                        warnBeforeLeavingChanged(true);

                        window.addEventListener("beforeunload", beforeUnloadListener);

                        var atags = [...document.querySelectorAll("a")]
                          .filter(atag => !atag.onclick)
                          .filter(atag => atag.getAttribute('beforeLeaveListener') !== "true")
                          .filter(atag => atag.href !== "" && atag.href !== `${window.location}#`);

                        atags.forEach(atag => {
                          atag.setAttribute("beforeLeaveListener", "true");
                          atag.addEventListener("click", (e) => {
                            e.preventDefault();

                            warnBeforeLeavingCallbackChanged(() => {
                              window.location = atag.href;
                            });

                            warnBeforeLeavingOpenChanged(true);
                          });
                        });
                      }
                    }}
                  >
                    <span className="dot d-flex align-items-center justify-content-center">
                      <i className="far fa-cloud-upload fa-fw"></i>
                    </span>
                    <strong>Publish</strong>
                  </li>
                </ul>
                <hr style={{
                      "borderTop": "2px solid #ebf4fb",
                      "marginTop": "20px",
                      "marginBottom": "6px"
                    }} />
                <ul className="no-bullets p-0">
                  <li className={"small py-2 mb-1 d-flex align-items-center"}>
                    <a href="#"
                      className="w-100"
                      onClick={e => {
                        e.preventDefault();

                        checkWarnBeforeLeaving(() => {
                          statementDescriptorSuffixChanged(confirm.statement_descriptor_suffix);
                          activeMenuItemChanged(SETTINGS_MENU_ITEM);
                        });
                      }}
                    >
                      <div className="row">
                        <div className="col">
                          <span>Settings</span>
                        </div>
                        <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
                          <i className="far fa-angle-right text-right"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md ticketing-content"
                   style={{
                     "background": "white",
                     "paddingTop": "38px",
                     "paddingBottom": "38px"
                   }}>
                {activeMenuItem === BASIC_INFO_MENU_ITEM ? (
                  <BasicInfo csrfToken={csrfToken}
                             team={team}
                             currentUser={currentUser}
                             activeMenuItemChanged={activeMenuItemChanged}
                             updateConfirm={updateConfirm}
                             autoSaveConfirm={autoSaveConfirm}
                             venuesOptions={venuesOptions}
                             performers={performers}
                             deletePerformer={deletePerformer}
                             createPerformer={createPerformer}
                             createPerformerAndArtist={createPerformerAndArtist}
                             updatePerformer={updatePerformer}
                             performerDragged={performerDragged}
                             confirm={confirm} />
                ) : activeMenuItem === DETAILS_MENU_ITEM ? (
                  <Details csrfToken={csrfToken}
                           team={team}
                           currentUser={currentUser}
                           activeMenuItemChanged={activeMenuItemChanged}
                           pageDetails={pageDetails}
                           createPageDetail={createPageDetail}
                           deletePageDetail={deletePageDetail}
                           pageDetailChanged={pageDetailChanged}
                           autoSavePageDetail={autoSavePageDetail}
                           pageDetailDragged={pageDetailDragged}
                           imageCalendarEventAttachments={imageCalendarEventAttachments}
                           uploadPageDetailImage={uploadPageDetailImage}
                           uploadPageDetailImageUrl={uploadPageDetailImageUrl}
                           uploadConfirmMainImageFile={uploadConfirmMainImageFile}
                           uploadConfirmMainImageUrl={uploadConfirmMainImageUrl}
                           mainEventImageLoading={mainEventImageLoading}
                           rootUrl={rootUrl}
                           artistBios={artistBios}
                           artistBiosChanged={artistBiosChanged}
                           pageDetailTemplates={pageDetailTemplates}
                           pageDetailTemplate={pageDetailTemplate}
                           pageDetailTemplateChanged={pageDetailTemplateChanged}
                           createPageDetailTemplate={createPageDetailTemplate}
                           deletePageDetailTemplate={deletePageDetailTemplate}
                           confirm={confirm} />
                ) : activeMenuItem === TICKETS_MENU_ITEM ? (
                  <Tickets csrfToken={csrfToken}
                           team={team}
                           currentUser={currentUser}
                           activeMenuItemChanged={activeMenuItemChanged}
                           activeTicketingMenuItem={activeTicketingMenuItem}
                           activeTicketingMenuItemChanged={activeTicketingMenuItemChanged}
                           ticketTypeForForm={ticketTypeForForm}
                           ticketTypeForFormChanged={ticketTypeForFormChanged}
                           fetchNewTicketType={fetchNewTicketType}
                           ticketTypes={ticketTypes}
                           deleteTicketType={deleteTicketType}
                           fetchTicketTypeForEdit={fetchTicketTypeForEdit}
                           createTicketType={createTicketType}
                           updateTicketType={updateTicketType}
                           ticketTypeDragged={ticketTypeDragged}
                           addOns={addOns}
                           addOnForForm={addOnForForm}
                           fetchNewAddOn={fetchNewAddOn}
                           addOnForFormChanged={addOnForFormChanged}
                           createAddOn={createAddOn}
                           fetchAddOnToEdit={fetchAddOnToEdit}
                           deleteAddOn={deleteAddOn}
                           updateAddOn={updateAddOn}
                           addOnDragged={addOnDragged}
                           exampleTicket={exampleTicket}
                           buildExampleTicket={buildExampleTicket}
                           exampleTicketChanged={exampleTicketChanged}
                           seatingCharts={seatingCharts}
                           updateConfirm={updateConfirm}
                           autoSaveConfirm={autoSaveConfirm}
                           createDefaultTicketTypes={createDefaultTicketTypes}
                           isLoadingDefaultTicketTypes={isLoadingDefaultTicketTypes}
                           addOnSeatingCharts={addOnSeatingCharts}
                           isEditingAddOnsIntro={isEditingAddOnsIntro}
                           isEditingAddOnsIntroChanged={isEditingAddOnsIntroChanged}
                           addOnsIntroEditorState={addOnsIntroEditorState}
                           addOnsIntroEditorStateChanged={addOnsIntroEditorStateChanged}
                           createDefaultAddOns={createDefaultAddOns}
                           isLoadingDefaultAddOns={isLoadingDefaultAddOns}
                           isEditingAddOnsAlertMessage={isEditingAddOnsAlertMessage}
                           isEditingAddOnsAlertMessageChanged={isEditingAddOnsAlertMessageChanged}
                           addOnsAlertMessageEditorState={addOnsAlertMessageEditorState}
                           addOnsAlertMessageEditorStateChanged={addOnsAlertMessageEditorStateChanged}
                           confirm={confirm}
                           confirmChanged={confirmChanged}
                           manualTaxes={manualTaxes}
                           addOnAdded={addOnAdded}
                           feeRuleSets={feeRuleSets}
                           showActivities={showActivities}
                           preSaleEnabled={preSaleEnabled} />
                ) : activeMenuItem === AUTOMATION_MENU_ITEM ? (
                  <Automations csrfToken={csrfToken}
                               team={team}
                               confirm={confirm}
                               activeMenuItemChanged={activeMenuItemChanged} />
                ) : activeMenuItem === PROMOTION_MENU_ITEM ? (
                  <Promotions csrfToken={csrfToken}
                              team={team}
                              confirm={confirm}
                              confirmChanged={confirmChanged}
                              autoSaveConfirm={autoSaveConfirm}
                              performers={performers}
                              activeMenuItemChanged={activeMenuItemChanged} />
                ) : activeMenuItem === CUSTOMIZE_MENU_ITEM ? (
                  <Customize csrfToken={csrfToken}
                              team={team}
                              confirm={confirm}
                              confirmChanged={confirmChanged}
                              autoSaveConfirm={autoSaveConfirm}
                              checkoutCategories={checkoutCategories}
                              checkoutCategoriesChanged={checkoutCategoriesChanged}
                              ticketTypes={ticketTypes}
                              addOns={addOns}
                              activeMenuItemChanged={activeMenuItemChanged} />
                ) : activeMenuItem === PUBLISH_MENU_ITEM ? (
                  <Publish csrfToken={csrfToken}
                           team={team}
                           currentUser={currentUser}
                           ticketTypes={ticketTypes}
                           pageDetails={pageDetails}
                           updateConfirm={updateConfirm}
                           publishConfirm={publishConfirm}
                           rootUrl={rootUrl}
                           confirm={confirm} />
                ) : activeMenuItem === SETTINGS_MENU_ITEM ? (
                  <Settings csrfToken={csrfToken}
                            team={team}
                            currentUser={currentUser}
                            updateConfirm={updateConfirm}
                            statementDescriptorSuffix={statementDescriptorSuffix}
                            statementDescriptorSuffixChanged={statementDescriptorSuffixChanged}
                            maxDescriptorLength={maxDescriptorLength}
                            updateStatementDescriptorSuffix={updateStatementDescriptorSuffix}
                            requirePerTicketCustomChargeTypes={requirePerTicketCustomChargeTypes}
                            createRequirePerTicketCustomChargeType={createRequirePerTicketCustomChargeType}
                            requirePerTicketCustomChargeTypeChanged={requirePerTicketCustomChargeTypeChanged}
                            deleteRequirePerTicketCustomChargeType={deleteRequirePerTicketCustomChargeType}
                            autoSaveRequirePerTicketCustomChargeType={autoSaveRequirePerTicketCustomChargeType}
                            confirm={confirm} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmTicketing.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmChanged: PropTypes.func.isRequired,
  activeMenuItem: PropTypes.string,
  activeMenuItemChanged: PropTypes.func.isRequired,
  updateConfirm: PropTypes.func.isRequired,
  autoSaveConfirm: PropTypes.func.isRequired,
  venuesOptions: PropTypes.array,
  performers: PropTypes.array,
  deletePerformer: PropTypes.func.isRequired,
  createPerformer: PropTypes.func.isRequired,
  updatePerformer: PropTypes.func.isRequired,
  pageDetails: PropTypes.array,
  createPageDetail: PropTypes.func.isRequired,
  deletePageDetail: PropTypes.func.isRequired,
  pageDetailChanged: PropTypes.func.isRequired,
  autoSavePageDetail: PropTypes.func.isRequired,
  pageDetailDragged: PropTypes.func.isRequired,
  imageCalendarEventAttachments: PropTypes.array,
  uploadPageDetailImage: PropTypes.func.isRequired,
  uploadPageDetailImageUrl: PropTypes.func.isRequired,
  uploadConfirmMainImageFile: PropTypes.func.isRequired,
  uploadConfirmMainImageUrl: PropTypes.func.isRequired,
  activeTicketingMenuItem: PropTypes.string,
  activeTicketingMenuItemChanged: PropTypes.func.isRequired,
  ticketTypeForForm: PropTypes.object,
  ticketTypeForFormChanged: PropTypes.func.isRequired,
  fetchNewTicketType: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array,
  deleteTicketType: PropTypes.func.isRequired,
  fetchTicketTypeForEdit: PropTypes.func.isRequired,
  createTicketType: PropTypes.func.isRequired,
  updateTicketType: PropTypes.func.isRequired,
  ticketTypeDragged: PropTypes.func.isRequired,
  mainEventImageLoading: PropTypes.bool,
  publishConfirm: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  addOns: PropTypes.array,
  addOnForForm: PropTypes.object,
  fetchNewAddOn: PropTypes.func.isRequired,
  addOnForFormChanged: PropTypes.func.isRequired,
  createAddOn: PropTypes.func.isRequired,
  fetchAddOnToEdit: PropTypes.func.isRequired,
  deleteAddOn: PropTypes.func.isRequired,
  updateAddOn: PropTypes.func.isRequired,
  addOnDragged: PropTypes.func.isRequired,
  statementDescriptorSuffix: PropTypes.string,
  statementDescriptorSuffixChanged: PropTypes.func.isRequired,
  maxDescriptorLength: PropTypes.number,
  updateStatementDescriptorSuffix: PropTypes.func.isRequired,
  artistBios: PropTypes.object,
  artistBiosChanged: PropTypes.func.isRequired,
  pageDetailTemplates: PropTypes.array,
  deletePageDetailTemplate: PropTypes.func.isRequired,
  pageDetailTemplate: PropTypes.object,
  pageDetailTemplateChanged: PropTypes.func.isRequired,
  createPageDetailTemplate: PropTypes.func.isRequired,
  createPerformerAndArtist: PropTypes.func.isRequired,
  performerDragged: PropTypes.func.isRequired,
  exampleTicket: PropTypes.object,
  buildExampleTicket: PropTypes.func.isRequired,
  exampleTicketChanged: PropTypes.func.isRequired,
  seatingCharts: PropTypes.array,
  createDefaultTicketTypes: PropTypes.func.isRequired,
  isLoadingDefaultTicketTypes: PropTypes.bool,
  addOnSeatingCharts: PropTypes.array,
  isEditingAddOnsIntro: PropTypes.bool,
  isEditingAddOnsIntroChanged: PropTypes.func.isRequired,
  addOnsIntroEditorState: PropTypes.object,
  addOnsIntroEditorStateChanged: PropTypes.func.isRequired,
  createDefaultAddOns: PropTypes.func.isRequired,
  isLoadingDefaultAddOns: PropTypes.bool,
  isEditingAddOnsAlertMessage: PropTypes.bool,
  isEditingAddOnsAlertMessageChanged: PropTypes.func.isRequired,
  addOnsAlertMessageEditorState: PropTypes.object,
  addOnsAlertMessageEditorStateChanged: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  promoters: PropTypes.array,
  requirePerTicketCustomChargeTypes: PropTypes.array,
  createRequirePerTicketCustomChargeType: PropTypes.func.isRequired,
  requirePerTicketCustomChargeTypeChanged: PropTypes.func.isRequired,
  deleteRequirePerTicketCustomChargeType: PropTypes.func.isRequired,
  autoSaveRequirePerTicketCustomChargeType: PropTypes.func.isRequired,
  showActivities: PropTypes.array.isRequired,
  manualTaxes: PropTypes.array,
  warnBeforeLeaving: PropTypes.bool.isRequired,
  warnBeforeLeavingChanged: PropTypes.func.isRequired,
  warnBeforeLeavingOpen: PropTypes.bool.isRequired,
  warnBeforeLeavingOpenChanged: PropTypes.func.isRequired,
  warnBeforeLeavingCallback: PropTypes.func,
  warnBeforeLeavingCallbackChanged: PropTypes.func.isRequired,
  addOnAdded: PropTypes.func.isRequired,
  feeRuleSets: PropTypes.array,
  checkoutCategories: PropTypes.array,
  checkoutCategoriesChanged: PropTypes.func.isRequired
};

export default ConfirmTicketing;
