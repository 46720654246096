import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import StandardLayout from './layouts/StandardLayout';
import InlineLayout from './layouts/InlineLayout';

const reduceFilters = (filters) => {
  return filters.filter((filter) => {
    return filter.value.length > 0;
  }).reduce((params, filter) => {
    var newParam = {};
    newParam[filter.paramName] = filter.value;

    return Object.assign(params, newParam);
  }, {});
}

const TableView = ({
  title,
  searchPlaceholder,
  typedSearchTerm,
  searchedSearchTerm,
  startSearchTermUpdate,
  dataSource,
  columns,
  order,
  filters,
  updateFilter,
  debouncedUpdateFilter,
  clearFilterValues,
  tableClassNames,
  csvDataSource,
  dataTableEl,
  dataTableElChanged,
  layout
}) => {
  return (
    layout === "standard" ? (
      <StandardLayout title={title}
                      searchPlaceholder={searchPlaceholder}
                      typedSearchTerm={typedSearchTerm}
                      searchedSearchTerm={searchedSearchTerm}
                      startSearchTermUpdate={startSearchTermUpdate}
                      dataSource={dataSource}
                      columns={columns}
                      order={order}
                      filters={filters}
                      updateFilter={updateFilter}
                      debouncedUpdateFilter={debouncedUpdateFilter}
                      clearFilterValues={clearFilterValues}
                      tableClassNames={tableClassNames}
                      csvDataSource={csvDataSource}
                      dataTableEl={dataTableEl}
                      reduceFilters={reduceFilters}
                      dataTableElChanged={dataTableElChanged} />
    ) : layout === "inline" ? (
      <InlineLayout title={title}
                    searchPlaceholder={searchPlaceholder}
                    typedSearchTerm={typedSearchTerm}
                    searchedSearchTerm={searchedSearchTerm}
                    startSearchTermUpdate={startSearchTermUpdate}
                    dataSource={dataSource}
                    columns={columns}
                    order={order}
                    filters={filters}
                    updateFilter={updateFilter}
                    debouncedUpdateFilter={debouncedUpdateFilter}
                    clearFilterValues={clearFilterValues}
                    tableClassNames={tableClassNames}
                    csvDataSource={csvDataSource}
                    dataTableEl={dataTableEl}
                    reduceFilters={reduceFilters}
                    dataTableElChanged={dataTableElChanged} />
    ) : null
  );
};

TableView.propTypes = {
  title: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  typedSearchTerm: PropTypes.string,
  searchedSearchTerm: PropTypes.string,
  startSearchTermUpdate: PropTypes.func.isRequired,
  dataSource: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  order: PropTypes.array,
  filters: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
  debouncedUpdateFilter: PropTypes.func.isRequired,
  clearFilterValues: PropTypes.func.isRequired,
  tableClassNames: PropTypes.string,
  csvDataSource: PropTypes.string,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  layout: PropTypes.string
};

export default TableView;
