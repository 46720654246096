import PropTypes from 'prop-types';
import React from 'react';

import {DebounceInput} from 'react-debounce-input';

const CalendarSearch = ({ searchTerm, searchTermChanged }) => (
  <div>
    <div className="row dataTable-search-row">
      <div className="col-12">
        <form>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text"
                    id="table-search-addon">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <DebounceInput type="search"
                   debounceTimeout={250}
                   className="form-control"
                   placeholder={"Search events..."}
                   value={searchTerm}
                   onChange={
                     (e) => {
                       searchTermChanged(e.target.value);
                     }
                   }
                   onKeyPress={
                     (e) => {
                       if (e.key === 'Enter') e.preventDefault();
                     }
                   }
                   aria-label="Search"
                   aria-describedby="table-search-addon" />
          </div>
        </form>
      </div>
    </div>
  </div>
);

CalendarSearch.propTypes = {
  searchTerm: PropTypes.string,
  searchTermChanged: PropTypes.func.isRequired
};

export default CalendarSearch;
