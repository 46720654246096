import PropTypes from 'prop-types';
import React from 'react';

import Tippy from '@tippyjs/react';

const LastMinuteBuyers = ({
  csrfToken,
  team,
  currentUser,
  lastMinuteBuyers
}) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col d-flex align-items-center">
          <p className="mb-0"
             style={{"fontSize": "20px"}}>
            <strong>
              Last Minute Buyers
            </strong>
            <Tippy content="Fans who purchase tickets on the day of show.">
              <i className="fas fa-info-circle text-muted ml-2"
                 style={{"fontSize": "16px"}}></i>
            </Tippy>
          </p>
        </div>
        {/*
        <div className="col-xs-auto d-flex align-items-center justify-content-end"
             style={{"paddingRight": "15px"}}>
          <a href="#"
             style={{"fontSize": "14px"}}
             onClick={(e) => e.preventDefault()}>
            See all
            <i className="far fa-chevron-right ml-2"></i>
          </a>
        </div>
        */}
      </div>
      {lastMinuteBuyers.length === 0 ? (
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-center flex-column text-center"
              style={{"height": "200px"}}>
            <div className="dot d-flex justify-content-center align-items-center"
                style={{
                  "width": "44px",
                  "height": "44px",
                  "background": "#F3F9FC",
                  "borderRadius": "50%",
                  "border": "4px solid #BBE0F6"
                }}>
              <i className="fa-regular fa-clock text-primary" style={{"fontSize": "21px"}}></i>
            </div>
            <p className="mb-0 mt-2 text-muted"
              style={{
                "fontSize": "14px"
              }}>
              <strong>Fans who purchase tickets on the day of show.</strong>
            </p>
            <p className="small mb-0 text-muted">
              No Data Yet
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <table className="table vertical-middle table-borderless table-sm"
                  style={{"marginTop": "15px", "tableLayout": "fixed"}}>
              <colgroup>
                <col span={1} style={{"width": "15%"}} />
                <col span={1} style={{"width": "60%"}} />
                <col span={1} style={{"width": "25%"}} />
              </colgroup>
              <tbody>
                {lastMinuteBuyers.map((data, index) =>
                  <tr key={index}>
                    <td style={{"paddingLeft": "0px"}}>
                      <img src={data.fan.avatar_thumb_url}
                          alt={data.fan.name_or_email}
                          className="rounded-circle"
                          width="40"
                          height="40" />
                    </td>
                    <td>
                      {data.fan.name && data.fan.name.length > 0 ? (
                        <React.Fragment>
                          <p className="mb-0 small text-truncate"
                            title={data.fan.name}>
                            <a className="text-dark"
                              href={"/teams/" + team.id + "/fans/" + data.fan.id}>
                              <strong>{data.fan.name}</strong>
                            </a>
                          </p>
                          <p className="mb-0 small text-truncate"
                            title={data.fan.email}>
                            {data.fan.email}
                          </p>
                        </React.Fragment>
                      ) : (
                        <p className="mb-0 small text-truncate"
                          title={data.fan.email}>
                          <a className="text-dark"
                            href={"/teams/" + team.id + "/fans/" + data.fan.id}>
                            <strong>{data.fan.email}</strong>
                          </a>
                        </p>
                      )}
                    </td>
                    <td className="text-center">
                      <p className="mb-0"
                        style={{"fontSize": "20px"}}>
                        <strong>
                          {data.unique_confirms_count.toLocaleString()}
                        </strong>
                      </p>
                      <p className="mb-0 text-muted"
                        style={{
                          "fontSize": "10px",
                          "marginTop": "-6px"
                        }}>
                        EVENTS
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

LastMinuteBuyers.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  lastMinuteBuyers: PropTypes.array
};

export default LastMinuteBuyers;
