/* eslint-disable import/prefer-default-export */

import {
  FINANCIAL_CONNECTION_ACCOUNTS_CHANGED,
  FINANCIAL_CONNECTION_ACCOUNT_DELETED
} from '../constants/refundSettingsConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const createStripeFinancialConnectionSession = (dispatch, csrfToken, team, stripe) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/stripe/financial_connection_sessions", {})
      .then(({ data }) => {
        var clientSecret = data.client_secret;

        stripe.collectBankAccountToken({
          clientSecret: clientSecret
        }).then(function(result) {
          if (result.error) {
            // Inform your user that there was an error.
            console.log(result.error.message);
          } else {
            var accounts = result.financialConnectionsSession.accounts;
            dispatch(bulkCreateFinancialConnectionAccounts(dispatch, csrfToken, team, accounts));
          }
        });

      });
  };
};

export const bulkCreateFinancialConnectionAccounts = (dispatch, csrfToken, team, accounts) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var bulkParams = accounts.map((a) => {
      return {
        stripe_id: a.id,
        last4: a.last4,
        institution_name: a.institution_name
      };
    });

    return axios.post("/teams/" + team.id + "/financial_connection_accounts/bulk_create", {
        financial_connection_accounts: {
          bulk: bulkParams
        }
      })
      .then(({ data }) => {
        dispatch(financialConnectionAccountsChanged(data));
      });
  };
};

export const financialConnectionAccountsChanged = (financialConnectionAccounts) => ({
  type: FINANCIAL_CONNECTION_ACCOUNTS_CHANGED,
  financialConnectionAccounts
});

export const deleteFinancialConnectionAccount = (dispatch, csrfToken, team, financialConnectionAccount) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/financial_connection_accounts/" + financialConnectionAccount.id)
      .then(({ data }) => {
        dispatch(financialConnectionAccountDeleted(data));
      })
      .catch((error) => {
        toast.error("Something went wrong. Try again.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const financialConnectionAccountDeleted = (financialConnectionAccount) => ({
  type: FINANCIAL_CONNECTION_ACCOUNT_DELETED,
  financialConnectionAccount
});
