// Simple example of a React "smart" component

import { connect } from 'react-redux';
import RecommendationsTable from '../components/RecommendationsTable';
import * as actions from '../actions/recommendationsTableActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  venuesOptions: state.venuesOptions,
  venueSelected: state.venueSelected,
  team: state.team,
  recommendations: state.recommendations,
  activePage: state.activePage,
  pagedRecommendations: state.pagedRecommendations,
  itemsCountPerPage: state.itemsCountPerPage,
  isLoading: state.isLoading,
  csrfToken: state.csrfToken
});

const mapDispatchToProps = (dispatch) => {
  return {
    updatedSelectedVenue: (venue) => dispatch(actions.updatedSelectedVenue(venue)),
    onInit: (team, venue, itemsCountPerPage, csrfToken) => dispatch(actions.onInit(dispatch, team, venue, itemsCountPerPage, csrfToken)),
    fetchRecommendations: (team, venue, itemsCountPerPage, csrfToken) => dispatch(actions.fetchRecommendations(dispatch, team, venue, itemsCountPerPage, csrfToken)),
    updateActivePage: (page, recommendations, itemsCountPerPage) => dispatch(actions.updateActivePage(page, recommendations, itemsCountPerPage)),
    clickOnRecommendation: (team, recommendation, venueSelected, csrfToken) => dispatch(actions.clickOnRecommendation(dispatch, team, recommendation, venueSelected, csrfToken)),
    logRecommendationsView: (team, venueSelected, pageNumber, csrfToken) => dispatch(actions.logRecommendationsView(dispatch, team, venueSelected, pageNumber, csrfToken))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsTable);
