import { combineReducers } from 'redux';
import {
} from '../constants/SimilarArtistsConstants';

const similarArtists = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const SimilarArtistsReducer = combineReducers({ similarArtists, });

export default SimilarArtistsReducer;
