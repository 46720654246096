import PropTypes from 'prop-types';
import React from 'react';

import {DebounceInput} from 'react-debounce-input';

const TableSearch = ({ searchPlaceholder, typedSearchTerm, startSearchTermUpdate }) => (
  <div>
    <div className="row dataTable-search-row">
      <div className="col-12">
        <form>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text"
                    id="table-search-addon">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <DebounceInput type="search"
                   debounceTimeout={400}
                   className="form-control"
                   placeholder={searchPlaceholder}
                   value={typedSearchTerm}
                   onChange={(e) => {startSearchTermUpdate(e.target.value)}}
                   onKeyPress={e => {
                     if (e.key === 'Enter') e.preventDefault();
                   }}
                   aria-label="Search"
                   aria-describedby="table-search-addon" />
          </div>
        </form>
      </div>
    </div>
  </div>
);

TableSearch.propTypes = {
  searchPlaceholder: PropTypes.string.isRequired,
  typedSearchTerm: PropTypes.string,
  startSearchTermUpdate: PropTypes.func.isRequired
};

export default TableSearch;
