import { combineReducers } from 'redux';
import {
  VENUE_OWNERSHIP_REMOVED,
  VENUE_OWNERSHIP_ADDED,
  MANUAL_VENUE_ENTRY_CLICKED,
  VENUE_SUGGESTION_NAME_CHANGED,
  VENUE_SUGGESTION_ADDRESS_ONE_CHANGED,
  VENUE_SUGGESTION_ADDRESS_TWO_CHANGED,
  VENUE_SUGGESTION_CITY_CHANGED,
  VENUE_SUGGESTION_STATE_CHANGED,
  VENUE_SUGGESTION_ZIP_CHANGED,
  VENUE_SUGGESTION_ERRORS_CHANGED,
  VENUE_SUGGESTION_CREATION_SUCCESS,
  OPEN_MODAL,
  CLOSE_MODAL,
  CANCEL_EDIT_VENUE_OWNERSHIP,
  VENUE_OWNERSHIP_BIO_CHANGED,
  VENUE_OWNERSHIP_LOGO_EL_CHANGED,
  VENUE_OWNERSHIP_LOGO_CHANGED,
  VENUE_OWNERSHIP_TICKET_LOGO_EL_CHANGED,
  VENUE_OWNERSHIP_TICKET_LOGO_CHANGED,
  VENUE_OWNERSHIP_CHANGED,
  VENUE_OWNERSHIP_LOGO_REMOVED,
  VENUE_OWNERSHIP_TICKET_LOGO_REMOVED,
  VENUE_OWNERSHIP_ERRORS_CHANGED
} from '../constants/venueManagerConstants';

const csrfToken = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    case VENUE_OWNERSHIP_REMOVED:
      return state.filter(venueOwnership => venueOwnership.id !== action.venueOwnership.id);
    case VENUE_OWNERSHIP_ADDED:
      return [...state, action.venueOwnership];
    case CANCEL_EDIT_VENUE_OWNERSHIP:
      return [...state].map((venueOwnership, index) => {
        return Object.assign({}, venueOwnership, {isEditing: false});
      });
    case VENUE_OWNERSHIP_BIO_CHANGED:
      return [...state].map((venueOwnership, index) => {
        if(index === action.index){
          return Object.assign({}, venueOwnership, {bio: action.bio});
        } else {
          return venueOwnership;
        }
      });
    case VENUE_OWNERSHIP_LOGO_EL_CHANGED:
      return [...state].map((venueOwnership, index) => {
        if(index === action.index){
          return Object.assign({}, venueOwnership, {logoEl: action.logoEl});
        } else {
          return venueOwnership;
        }
      });
    case VENUE_OWNERSHIP_LOGO_CHANGED:
      return [...state].map((venueOwnership, index) => {
        if(index === action.index){
          return Object.assign({}, venueOwnership, {logo: action.logo});
        } else {
          return venueOwnership;
        }
      });
    case VENUE_OWNERSHIP_TICKET_LOGO_EL_CHANGED:
      return [...state].map((venueOwnership, index) => {
        if(index === action.index){
          return Object.assign({}, venueOwnership, {ticketLogoEl: action.ticketLogoEl});
        } else {
          return venueOwnership;
        }
      });
    case VENUE_OWNERSHIP_TICKET_LOGO_CHANGED:
      return [...state].map((venueOwnership, index) => {
        if(index === action.index){
          return Object.assign({}, venueOwnership, {ticketLogo: action.ticketLogo});
        } else {
          return venueOwnership;
        }
      });
    case VENUE_OWNERSHIP_CHANGED:
      return [...state].map((venueOwnership) => {
        if(venueOwnership.id === action.venueOwnership.id){
          return action.venueOwnership;
        } else {
          return venueOwnership;
        }
      });
    case VENUE_OWNERSHIP_LOGO_REMOVED:
      return [...state].map((venueOwnership, index) => {
        if(index === action.index){
          return Object.assign({}, venueOwnership, {
            destroy_logo: true,
            logo: null,
            logo_thumb_url: null
          });
        } else {
          return venueOwnership;
        }
      });
    case VENUE_OWNERSHIP_TICKET_LOGO_REMOVED:
      return [...state].map((venueOwnership, index) => {
        if(index === action.index){
          return Object.assign({}, venueOwnership, {
            destroy_ticket_logo: true,
            ticket_logo: null,
            ticket_logo_thumb_url: null
          });
        } else {
          return venueOwnership;
        }
      });
    case VENUE_OWNERSHIP_ERRORS_CHANGED:
      return [...state].map((venueOwnership, index) => {
        if(venueOwnership.id === action.venueOwnership.id){
          return Object.assign({}, venueOwnership, {errors: action.errors});
        } else {
          return venueOwnership;
        }
      });
    default:
      return state;
  }
};

const settingUpTeam = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueSuggestionFormOpen = (state = false, action) => {
  switch (action.type) {
    case VENUE_SUGGESTION_CREATION_SUCCESS:
      return false;
    case MANUAL_VENUE_ENTRY_CLICKED:
      return (state ? false : true);
    default:
      return state;
  }
};

const defaultVenueSuggestion = {errors: []};
const venueSuggestion = (state = defaultVenueSuggestion, action) => {
  switch (action.type) {
    case VENUE_SUGGESTION_CREATION_SUCCESS:
      return Object.assign({}, defaultVenueSuggestion);
    case VENUE_SUGGESTION_NAME_CHANGED:
      return Object.assign({}, state, {name: action.name});
    case VENUE_SUGGESTION_ADDRESS_ONE_CHANGED:
      return Object.assign({}, state, {address1: action.address1});
    case VENUE_SUGGESTION_ADDRESS_TWO_CHANGED:
      return Object.assign({}, state, {address2: action.address2});
    case VENUE_SUGGESTION_CITY_CHANGED:
      return Object.assign({}, state, {city: action.city});
    case VENUE_SUGGESTION_STATE_CHANGED:
      return Object.assign({}, state, {state: action.state});
    case VENUE_SUGGESTION_ZIP_CHANGED:
      return Object.assign({}, state, {zip: action.zip});
    case VENUE_SUGGESTION_ERRORS_CHANGED:
      return Object.assign({}, state, {errors: action.errors});
    default:
      return state;
  }
};

const venueStateOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const modalIsOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return true;
    case CLOSE_MODAL:
      return false;
    default:
      return state;
  }
};

const availableTimezones = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueManagerReducer = combineReducers({
  csrfToken,
  team,
  venueOwnerships,
  settingUpTeam,
  venueSuggestionFormOpen,
  venueSuggestion,
  venueStateOptions,
  modalIsOpen,
  availableTimezones
});

export default venueManagerReducer;
