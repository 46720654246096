// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ConfirmAttendees from '../components/ConfirmAttendees';
import * as actions from '../actions/confirmAttendeesActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  confirm: state.confirm,
  activeMenuItem: state.activeMenuItem,
  ticketReservation: state.ticketReservation,
  orderType: state.orderType,
  ticketTypes: state.ticketTypes,
  addOns: state.addOns,
  rootUrl: state.rootUrl,
  isPlacingOrder: state.isPlacingOrder,
  checkoutTimePercentageRemaining: state.checkoutTimePercentageRemaining,
  timeLimitReached: state.timeLimitReached,
  manualTypes: state.manualTypes,
  customFieldValues: state.customFieldValues,
  buyers: state.buyers,
  promoters: state.promoters,
  seatsIOPublicKey: state.seatsIOPublicKey,
  seatsIOChart: state.seatsIOChart,
  seatsIOSelectedObjects: state.seatsIOSelectedObjects
});

const mapDispatchToProps = (dispatch) => {
  return {
    confirmChanged: (confirm) => dispatch(actions.confirmChanged(confirm)),
    activeMenuItemChanged: (activeMenuItem) => dispatch(actions.activeMenuItemChanged(activeMenuItem)),
    orderTypeChanged: (orderType) => dispatch(actions.orderTypeChanged(orderType)),
    ticketTypesChanged: (ticketTypes) => dispatch(actions.ticketTypesChanged(ticketTypes)),
    addOnsChanged: (addOns) => dispatch(actions.addOnsChanged(addOns)),
    buildTicketReservation: (csrfToken, team, confirm, orderType, ticketTypes, addOns) => dispatch(actions.buildTicketReservation(dispatch, csrfToken, team, confirm, orderType, ticketTypes, addOns)),
    createTicketReservation: (csrfToken, team, confirm, orderType, ticketTypes, addOns, seatsIOChart) => dispatch(actions.createTicketReservation(dispatch, csrfToken, team, confirm, orderType, ticketTypes, addOns, seatsIOChart)),
    updateTicketReservation: (csrfToken, team, ticketReservation, onSuccess, customFieldValues) => dispatch(actions.updateTicketReservation(dispatch, csrfToken, team, ticketReservation, onSuccess, customFieldValues)),
    ticketReservationChanged: (ticketReservation) => dispatch(actions.ticketReservationChanged(ticketReservation)),
    isPlacingOrderChanged: (isPlacingOrder) => dispatch(actions.isPlacingOrderChanged(isPlacingOrder)),
    checkoutTimePercentageRemainingChanged: (percentage) => dispatch(actions.checkoutTimePercentageRemainingChanged(percentage)),
    timeLimitReachedChanged: (reached) => dispatch(actions.timeLimitReachedChanged(reached)),
    deleteTicketReservation: (csrfToken, team, ticketReservation) => dispatch(actions.deleteTicketReservation(dispatch, csrfToken, team, ticketReservation)),
    customFieldValueChanged: (customFieldValue) => dispatch(actions.customFieldValueChanged(customFieldValue)),
    seatsIOChartChanged: (seatsIOChart) => dispatch(actions.seatsIOChartChanged(seatsIOChart)),
    seatsIOSelectedObjectsChanged: (seatsIOSelectedObjects) => dispatch(actions.seatsIOSelectedObjectsChanged(seatsIOSelectedObjects))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAttendees);
