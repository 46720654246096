import PropTypes from 'prop-types';
import React from 'react';
import Popup from "reactjs-popup";

const contentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "auto",
  "padding": "10px"
}

class CheckBoxesFilter extends React.Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    updateFilter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: this.props.filter,
      updateFilter: this.props.updateFilter
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({filter: this.props.filter});
    }
  }

  setInitialValue(){
    setTimeout(() => {
      var checkboxesCopy = [...this.state.filter.checkboxes];
      var initialValue   = checkboxesCopy.map((d) => {
        return Object.assign({}, d, {
          value: d.defaultValue
        })
      })

      this.state.updateFilter(this.state.filter, initialValue);
    }, 1);
  }

  render() {
    return (
      <div className="filter-pill-container mr-2 my-3">
        <Popup arrow={false}
               offsetY={5}
               position="bottom left"
               contentStyle={contentStyle}
               onOpen={() => {this.setInitialValue()}}
               trigger={open => (
          <span className={"filter-pill clickable " + (open ? " opened " : "") + (this.state.filter.value.length > 0 ? " filtering " : "")}>
            <span className="px-1">{this.state.filter.label}</span>
            {(this.state.filter.value.length === 0 || this.state.filter.value.map((d) => d.value).every((value) => value)) &&
              <strong className="px-2">All</strong>
            }
            {this.state.filter.value.map((d) => d.value).some((value) => value) &&
              this.state.filter.value.map((d) => d.value).some((value) => !value) &&
              <strong className="px-2">{this.state.filter.value.filter((d) => d.value).map((d) => d.label).join(" & ")}</strong>
            }
            {this.state.filter.value.length > 0 && this.state.filter.value.map((d) => d.value).every((value) => !value) &&
              <strong className="px-2">None</strong>
            }
            <i className="fa fa-chevron-down px-1"></i>
          </span>
        )} >
          {close => (
            <div className='row'>
              <div className="col-12">
                {this.state.filter.checkboxes.map((checkbox, index) => {
                  return (
                    <div key={index} className="form-check form-check-inline">
                      <input className="form-check-input"
                             type="checkbox"
                             id={"filter-checkbox-" + index + "-" + checkbox.paramName}
                             onChange={
                               (e) => {
                                 var newFilterValue = [...this.state.filter.value].map((entry, index) => {
                                   if (entry.paramName === checkbox.paramName){
                                     return Object.assign({}, entry, {value: e.target.checked});
                                   }

                                   return entry;
                                 });

                                 this.state.updateFilter(this.state.filter, newFilterValue);
                               }
                             }
                             defaultChecked={checkbox.defaultValue} />
                      <label className="form-check-label"
                             htmlFor={"filter-checkbox-" + index + "-" + checkbox.paramName}>
                        {checkbox.label}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </Popup>
      </div>
    );
  }
}

export default CheckBoxesFilter;
