import { combineReducers } from 'redux';
import { EVENT_PERMALINK_UPDATED, EVENT_UPDATED } from '../constants/dataTableWithSidebarViewConstants';
import {responsiveStateReducer} from 'redux-responsive'

const title = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataSource = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const columns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const eventPermalink = (state = '', action) => {
  switch (action.type) {
    case EVENT_PERMALINK_UPDATED:
      return action.permalink;
    default:
      return state;
  }
};

const event = (state = {}, action) => {
  switch (action.type) {
    case EVENT_UPDATED:
      return action.event;
    default:
      return state;
  }
};

const displayShowMore = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isEventViewSliding = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const dataTableWithSidebarViewReducer = combineReducers({ title, dataSource, order, columns, eventPermalink, event, displayShowMore, isEventViewSliding, browser: responsiveStateReducer });

export default dataTableWithSidebarViewReducer;
