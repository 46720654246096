import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import PerformerCard from './PerformerCard';

import Flatpickr from "react-flatpickr";
import DatePicker from "react-datepicker";

import AsyncCreatableSelect from 'react-select/async-creatable';
const axios = require('axios').default;

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import Popup from "reactjs-popup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

var _ = require('lodash');
var debouncedAutoSaveConfirm;

import {
  DETAILS_MENU_ITEM
} from '../constants/confirmTicketingConstants';

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

const filterPassedTime = (time, startDate, startTime, endDate) => {
  var startMonth = startDate.getUTCMonth() + 1;
  var startDay   = startDate.getUTCDate();
  var startYear  = startDate.getUTCFullYear();

  var endMonth = endDate.getUTCMonth() + 1;
  var endDay   = endDate.getUTCDate();
  var endYear  = endDate.getUTCFullYear();

  var sameDate = (
    startMonth === endMonth
      && startDay === endDay
      && startYear === endYear
  );

  if(!sameDate){
    return true;
  }

  var timeOption = new Date(time);
  var copiedStartTime = new Date(startTime.getTime());

  timeOption.setFullYear(startYear, startMonth, startDay);
  copiedStartTime.setFullYear(startYear, startMonth, startDay);

  return timeOption > copiedStartTime;
};

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "190px",
  "padding": "0px"
}

const actTypePopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "100px",
  "padding": "0px"
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "#FFFFFF",
    zIndex: "999",
    border: "2px solid #f5f5f5",
    fontSize: "14px",
    borderRadius: "8px",
    height: '40px',
    overflowY: "hidden"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" }),
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      border: "none",
      boxShadow: 'none',
      borderRadius: "8px",
      marginTop: "-4px"
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      color: "#333333"
    };
  },
  indicatorSeparator: (styles, { data }) => {
    return {
      ...styles,
      display: "none"
    };
  },
  indicatorsContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      color: "#1982C4",
      fontWeight: "bold",
      fontSize: "16px",
      borderRadius: "8px",
      overflow: "hidden",
      ':hover': {
        cursor: "pointer"
      }
    };
  },
  dropdownIndicator: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      color: "rgba(51,51,51,.5)",
      fontWeight: "bold"
    };
  }
};

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name, permalink: data.permalink };
        });

        callback(newOptions);
      })
  });
}, 250);

const BasicInfo = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  activeMenuItemChanged,
  updateConfirm,
  autoSaveConfirm,
  venuesOptions,
  performers,
  deletePerformer,
  createPerformer,
  updatePerformer,
  createPerformerAndArtist,
  performerDragged
}) => {
  useEffect(() => {
    debouncedAutoSaveConfirm = _.debounce((csrfToken, team, confirm) => {
      autoSaveConfirm(csrfToken, team, confirm);
    }, 600);
  }, [])

  return (
    <div className="row">
      <div className="col-12">
        <h3 style={{"fontSize": "20px"}}>
          <strong>
            Artists & Details
          </strong>
        </h3>
        <p className="pb-2">
          Add artists to your lineup. Opendate will automatically add pics, bios, and more to your event page. You can also add your own photos and customize the copy as needed for your event.
        </p>
        <form onSubmit={
                (e) => {
                  e.preventDefault();
                }
              }>
          <div className="form-group mt-4">
            <AsyncCreatableSelect cacheOptions
                                  styles={selectStyles}
                                  placeholder={"Add Artist"}
                                  menuPortalTarget={document.querySelector('body')}
                                  defaultOptions
                                  value={""}
                                  formatCreateLabel={
                                    (label) => ("Add \"" + label + "\"")
                                  }
                                  onCreateOption={
                                    (label) => {
                                      createPerformerAndArtist(csrfToken, team, confirm, label);
                                    }
                                  }
                                  onChange={
                                    (option) => {
                                      createPerformer(csrfToken, team, confirm, option);
                                    }
                                  }
                                  loadOptions={
                                    (inputValue, callback) => debouncedFetch(inputValue, callback)
                                  } />
          </div>
          <div>
            <DragDropContext onDragEnd={
                               (result) => {
                                 if (!result.destination) {
                                   return false;
                                 }

                                 var itemIndex = result.source.index;
                                 var moveIndex = result.destination.index;
                                 var updated = Object.assign({}, performers[itemIndex], {
                                   position: (moveIndex + 1)
                                 });

                                 performerDragged(result);
                                 updatePerformer(csrfToken, team, updated);
                               }
                             }>
              <Droppable droppableId="performers-droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {performers.sort((a, b) => a.position - b.position).map((performer, index) =>
                      <Draggable key={"" + performer.id} draggableId={"" + performer.id} index={index}>
                        {(provided, snapshot) => (
                          <PerformerCard key={index}
                                         csrfToken={csrfToken}
                                         team={team}
                                         currentUser={currentUser}
                                         confirm={confirm}
                                         performer={performer}
                                         deletePerformer={deletePerformer}
                                         updatePerformer={updatePerformer}
                                         provided={provided}
                                         snapshot={snapshot} />
                        )}
                      </Draggable>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="form-group mt-4 pt-2">
            <label htmlFor="confirm-title"
                   className="text-muted mb-1"
                   style={{"fontSize": "14px"}}>
              Event Title
            </label>
            <input type="text"
                   className="form-control"
                   value={confirm.title || ""}
                   onChange={
                     (e) => {
                       var newTitle = e.target.value;

                       if (newTitle.length === 0){
                         if (performers.length > 0){
                           newTitle = performers.map((p) => p.artistable.name).join(" & ");
                         } else {
                           newTitle = "Untitled Event";
                         }
                       }

                       var updated = Object.assign({}, confirm, {
                         title: newTitle
                       });

                       updateConfirm(updated);
                       debouncedAutoSaveConfirm(csrfToken, team, updated);
                     }
                   }
                   style={{
                     "border": "1px solid #e6e6e6"
                   }}
                   id="confirm-title" />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-presenter"
                   className="text-muted mb-1"
                   style={{"fontSize": "14px"}}>
              Presenter
            </label>
            <input type="text"
                   className="form-control"
                   value={confirm.presenter || ""}
                   onChange={
                     (e) => {
                       var updated = Object.assign({}, confirm, {presenter: e.target.value});

                       updateConfirm(updated);
                       debouncedAutoSaveConfirm(csrfToken, team, updated);
                     }
                   }
                   style={{
                     "border": "1px solid #e6e6e6"
                   }}
                   id="confirm-presenter" />
          </div>
          <hr className="my-5"
              style={{"borderTop": "1px solid #ececec"}} />
          <h3 style={{"fontSize": "20px"}}>
            <strong>
              Venue
            </strong>
          </h3>
          <p className="pb-2">Confirm show venue and adjust show capacity if applicable.</p>
          <div className="form-row mt-3">
            <div className="col-6 col-md-8">
              <label htmlFor="confirm-venue"
                     className="text-muted mb-1"
                     style={{"fontSize": "14px"}}>
                Venue
              </label>
              <select className="form-control"
                      style={{"border": "1px solid #e6e6e6"}}
                      value={confirm.venue ? confirm.venue.id : ""}
                      id="confirm-venue"
                      onChange={
                        (e) => {
                          var venue = venuesOptions.find((venue) =>
                            venue.id === parseInt(e.target.value)
                          );

                          var updated = Object.assign({}, confirm, {
                            venue: venue,
                            venue_capacity: venue.capacity,
                            age_restriction: venue.confirm_age_restriction,
                            venue_ownership_id: venue.venue_ownership_id
                          });

                          updateConfirm(updated);
                          debouncedAutoSaveConfirm(csrfToken, team, updated);
                        }
                      }
                      id="outreach-template">
                {venuesOptions.sort((a, b) => a.name.localeCompare(b.name)).map((venue, index) => (
                  <option key={index} value={venue.id}>
                    {venue.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6 col-md-4">
              <div className="form-group">
                <label htmlFor="confirm-venue-capacity"
                       className="text-muted mb-1"
                       style={{"fontSize": "14px"}}>
                  Capacity
                </label>
                <MaskedInput className="form-control"
                             style={{
                               "border": "1px solid #e6e6e6"
                             }}
                             mask={createNumberMask({
                               prefix: "",
                               decimalLimit: 0
                             })}
                             placeholder={"0"}
                             id="confirm-venue-capacity"
                             onChange={
                               (e) => {
                                 var updated = Object.assign({}, confirm, {venue_capacity: e.target.value});

                                 updateConfirm(updated);
                                 debouncedAutoSaveConfirm(csrfToken, team, updated);
                               }
                             }
                             value={confirm.venue_capacity || ""} />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-6 col-md-4">
              <label htmlFor="confirm-age-restriction"
                     className="text-muted mb-1"
                     style={{"fontSize": "14px"}}>
                Age Restriction
              </label>
              <select className="form-control"
                      style={{"border": "1px solid #e6e6e6"}}
                      value={confirm.age_restriction || ""}
                      onChange={
                        (e) => {
                          var updated = Object.assign({}, confirm, {age_restriction: e.target.value});

                          updateConfirm(updated);
                          debouncedAutoSaveConfirm(csrfToken, team, updated);
                        }
                      }
                      id="confirm-age-restriction">
                <option value="All Ages">All Ages</option>
                <option value="18+">18+</option>
                <option value="21+">21+</option>
              </select>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-12 d-flex justify-content-end align-items-end"
               style={{"height": "130px"}}>
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();
                   activeMenuItemChanged(DETAILS_MENU_ITEM);
                 }
               }
               className="btn btn-primary">
              <strong>Next</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

BasicInfo.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  activeMenuItemChanged: PropTypes.func.isRequired,
  updateConfirm: PropTypes.func.isRequired,
  autoSaveConfirm: PropTypes.func.isRequired,
  venuesOptions: PropTypes.array,
  performers: PropTypes.array,
  deletePerformer: PropTypes.func.isRequired,
  createPerformer: PropTypes.func.isRequired,
  updatePerformer: PropTypes.func.isRequired,
  createPerformerAndArtist: PropTypes.func.isRequired,
  performerDragged: PropTypes.func.isRequired
};

export default BasicInfo;
