// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OfferV2Preview from '../components/OfferV2Preview';
import * as actions from '../actions/offerV2PreviewActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  offer: state.offer,
  onClose: state.onClose,
  uiState: state.uiState,
  onSuccess: state.onSuccess
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateUIState: (uiState) => dispatch(actions.updateUIState(uiState)),
    updateOfferTo: (to) => dispatch(actions.updateOfferTo(to)),
    updateOfferBody: (body) => dispatch(actions.updateOfferBody(body)),
    sendOffer: (csrfToken, team, offer, onSuccess) => dispatch(actions.sendOffer(dispatch, csrfToken, team, offer, onSuccess))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OfferV2Preview);
