import { createStore, applyMiddleware } from 'redux';
import teamManagerReducer from '../reducers/teamManagerReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(teamManagerReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
