import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

const draggablePropsWithStyle = (provided, snapshot) => {
  var props =  {...provided.draggableProps};
  var style = Object.assign({}, props.style, {
    "display": (snapshot.isDragging ? "table" : "")
  });

  return Object.assign({}, props, {style: style});
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const addOnStartTime = (addOn) => {
  if (!addOn.start_time || !addOn.end_time) {
    return "Unscheduled";
  }

  var startTimePieces = [];

  if(addOn.scheduled){
    startTimePieces.push("Scheduled");
  }

  startTimePieces.push(addOn.human_start_time);
  return startTimePieces.join(" • ");
}

const AddOnRow = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  fetchNewAddOn,
  fetchAddOnToEdit,
  deleteAddOn,
  addOn,
  provided,
  snapshot,
  addOnIsSeated,
  setDuplicatingAddOnId,
  addOns,
  isSharingInventory
}) => (
  <tr ref={provided.innerRef} {...draggablePropsWithStyle(provided, snapshot)}>
    <td className="text-muted text-center">
      <a {...provided.dragHandleProps}
         onClick={
           (e) => {
             e.preventDefault();
           }
         }
         href="#"
         style={{
           "opacity": (addOnIsSeated(addOn) ? "0" : "1")
         }}
         className={"btn btn-link btn-sm small text-muted " + (addOnIsSeated(addOn) ? "disabled" : "")}>
        <i className="fas fa-grip-vertical"></i>
      </a>
    </td>
    <td className="overflow-ellipsis">
      <strong title={addOn.name}>
        {addOn.name}
      </strong>
      {isSharingInventory(addOns, addOn) ? (
        <span className='text-primary' style={{"marginLeft": "5px"}}>
          Shared Inventory
        </span>
      ) : null}
      <br />
      <span className={(!addOn.start_time || !addOn.end_time) ? "text-red" : "text-muted"}
            title={addOnStartTime(addOn)}>
        {addOnStartTime(addOn)}
      </span>
    </td>
    <td className="text-muted text-center">
      {addOn.number_of_tickets_sold}/{addOn.available}
    </td>
    <td className="text-muted text-center">
      {formatter.format(addOn.price)}
    </td>
    <td className="text-muted text-center">
      <Popup arrow={false}
             offsetY={5}
             position="bottom right"
             contentStyle={popupContentStyle}
             onOpen={
               (e) => {
                 e.stopPropagation();
                 e.preventDefault();
               }
             }
             trigger={open => (
               <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                    }
                  }
                  className="text-muted">
                 <i className="fas fa-ellipsis-v"></i>
               </a>
             )} >
        {close => (
          <div className='row text-left'>
            <div className="col-12">
              <ul className="list-group offer-inline-menu">
                <li className={"list-group-item px-3 pb-2 " +  (addOnIsSeated(addOn) ? "pt-2" : "pt-3")}>
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         close();
                         fetchAddOnToEdit(csrfToken, team, addOn);
                       }
                     }>
                    <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                      <i className="fas fa-pencil fa-fw"></i>
                    </span>
                    <span className="text-dark ml-3">
                      Edit
                    </span>
                  </a>
                </li>
                {!addOnIsSeated(addOn) ? (
                  <li className="list-group-item px-3 py-2">
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            close();
                            fetchNewAddOn(csrfToken, team, confirm, addOn);
                          }
                        }>
                      <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                        <i className="far fa-copy fa-fw"></i>
                      </span>
                      <span className="text-dark ml-3">
                        Copy
                      </span>
                    </a>
                  </li>
                ) : (
                  <li className="list-group-item px-3 py-2">
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            close();
                            setDuplicatingAddOnId(addOn.id);
                          }
                        }>
                      <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                        <i className="far fa-copy fa-fw"></i>
                      </span>
                      <span className="text-dark ml-3">
                        Duplicate
                      </span>
                    </a>
                  </li>
                )}
                {addOn.number_of_tickets_sold === 0 && (!addOnIsSeated(addOn) || addOn.share_inventory_with_id) ? (
                  <li className="list-group-item pb-3 px-3 pt-2">
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            close();
                            deleteAddOn(csrfToken, team, addOn);
                          }
                        }
                        className="text-danger">
                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                        <i className="far fa-trash fa-fw"></i>
                      </span>
                      <span className="ml-3">
                        Delete
                      </span>
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        )}
      </Popup>
    </td>
  </tr>
);

AddOnRow.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  fetchNewAddOn: PropTypes.func.isRequired,
  fetchAddOnToEdit: PropTypes.func.isRequired,
  deleteAddOn: PropTypes.func.isRequired,
  addOn: PropTypes.object,
  provided: PropTypes.object.isRequired,
  snapshot: PropTypes.object.isRequired,
  addOnIsSeated: PropTypes.func.isRequired,
  setDuplicatingAddOnId: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  isSharingInventory: PropTypes.func.isRequired
};

export default AddOnRow;
