import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

const modalContentStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "15px",
  width: "fit-content",
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 5,
};

const headerStyle = {
  display: "flex",
  flexDirection: "row",
  fontSize: 18,
  fontWeight: "bold",
  justifyContent: "space-between",
};

const SetupModal = ({
  venueOwnership,
  sendDomains,
}) => {
  const [showSetup, setShowSetup] = useState(false);

  useEffect(() => {
    if (!!venueOwnership) {
      setShowSetup(sendDomains.filter((sendDomain) => {
        return sendDomain.venue_ownership_id === venueOwnership.id;
      }).length === 0);
    }
  }, [venueOwnership]);

  return (
    <Popup
      modal
      open={showSetup}
      contentStyle={modalContentStyle}
      onClose={() => setShowSetup(false)}
      closeOnDocumentClick
    >
      {() => (
        <div style={containerStyle}>
          <div style={headerStyle}>
            <div>
              Let's get set up!
            </div>
            <a href="#" className="text-dark" onClick={e => {e.preventDefault(); setShowSetup(false);}} >
              <i className="fa fa-times"></i>
            </a>
          </div>
          <div>
            Before you can send emails we need to configure your account.
          </div>
          <div>
            Head on over to settings and set up your sending domain.
          </div>
          <div className="col-xs-auto" style={{textAlign: "right"}}>
          <button
            type="button"
            className="ml-2 btn btn-primary"
            onClick={() => { window.location.href = `marketing_settings?venue_ownership_id=${venueOwnership.id}`; }}
          >
            Let's go
          </button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default SetupModal;
