/* eslint-disable import/prefer-default-export */

export const CONFIRM_CHANGED = 'CONFIRM_CHANGED';

export const PROMO_CODE_CHANGED = 'PROMO_CODE_CHANGED';
export const PROMO_CODE_NAME_CHANGED = 'PROMO_CODE_NAME_CHANGED';
export const PROMO_CODE_NAME_VALID_CHANGED = 'PROMO_CODE_NAME_VALID_CHANGED';

export const CHOOSING_TICKET_TYPES = 'CHOOSING_TICKET_TYPES';
export const CHOOSING_ADD_ONS = 'CHOOSING_ADD_ONS';
export const CHOOSING_DONATION = 'CHOOSING_DONATION';

export const STRIPE_PROMISE_CHANGED = 'STRIPE_PROMISE_CHANGED';
export const STRIPE_ERROR_CHANGED = 'STRIPE_ERROR_CHANGED';
export const TICKET_TYPES_CHANGED = 'TICKET_TYPES_CHANGED';
export const ADD_ONS_CHANGED = 'ADD_ONS_CHANGED';
export const TICKET_RESERVATION_CHANGED = 'TICKET_RESERVATION_CHANGED';
export const CANCEL_CHECKOUT = 'CANCEL_CHECKOUT';
export const CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED = 'CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED';
export const TIME_LIMIT_REACHED_CHANGED = 'TIME_LIMIT_REACHED_CHANGED';
export const IS_PLACING_ORDER_CHANGED = 'IS_PLACING_ORDER_CHANGED';
export const GTAG_CHANGED = 'GTAG_CHANGED';
export const CUSTOM_FIELD_VALUE_CHANGED = 'CUSTOM_FIELD_VALUE_CHANGED';

export const SEATS_IO_CHART_CHANGED = 'SEATS_IO_CHART_CHANGED';
export const SEATS_IO_SELECTED_OBJECTS_CHANGED = 'SEATS_IO_SELECTED_OBJECTS_CHANGED';
export const CUSTOMERIO_ID_CHANGED = 'CUSTOMERIO_ID_CHANGED';
export const IS_CREATING_TICKET_RESERVATION_CHANGED = 'IS_CREATING_TICKET_RESERVATION_CHANGED';

export const INSURANCE_OFFER_STATE_CHANGED = 'INSURANCE_OFFER_STATE_CHANGED';
export const TICKET_INSURANCE_CHANGED = 'TICKET_INSURANCE_CHANGED';
export const HAS_INSURANCE_QUOTE_CHANGED = 'HAS_INSURANCE_QUOTE_CHANGED';
export const DONATION_CUSTOM_CHARGE_TYPE_CHANGED = 'DONATION_CUSTOM_CHARGE_TYPE_CHANGED';
