/* eslint-disable import/prefer-default-export */

import {
  TEAM_MENU_OPEN_CHANGED
} from '../constants/userMenuConstants';

const axios = require('axios').default;

export const destroyUserSession = (dispatch, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/users/sign_out.json")
      .then(({ data }) => {
        window.location.replace("/users/sign_in");
      });
  };
};

export const teamMenuOpenChanged = (teamMenuOpen) => ({
  type: TEAM_MENU_OPEN_CHANGED,
  teamMenuOpen
});

export const switchCurrentTeam = (dispatch, csrfToken, teamId) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${teamId}/switch_current.json`)
      .then(({ data }) => {
        window.location.replace("/dashboard");
      });
  };
};
