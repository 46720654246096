/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import $ from 'jquery';
require('datatables.net-bs4');

import {
  offsetDateForServer
} from '../../../shared/timeZoneLogic';

import {
  CONFIRM_CHANGED,
  EVENT_TO_EDIT_UPDATE_SUCCESS,
  EVENT_TO_EDIT_DELETED,
  CALENDAR_EVENT_TITLE_UPDATED,
  CALENDAR_EVENT_ARTISTS_UPDATED,
  CALENDAR_EVENT_TYPE_UPDATED,
  CALENDAR_EVENT_TICKET_FORECAST_CHANGED,
  CALENDAR_EVENT_NOTE_EDITOR_STATE_UPDATED,
  CALENDAR_EVENT_ERRORS_UPDATED,
  CALENDAR_EVENT_FORM_CLOSED,
  HOLD_GROUP_CALENDAR_DATE_CLICKED,
  HOLD_GROUP_HOLD_POSITION_UPDATED,
  HOLD_GROUP_HOLD_REMOVED,
  CONFIRM_START_DATE_UPDATED,
  CONFIRM_START_TIME_UPDATED,
  HOLD_GROUP_HOLD_AVAILABILITY_UPDATED,
  HOLD_GROUP_UPDATED,
  CONFIRM_UPDATED,
  CONFIRM_HOLD_UPDATED,
  CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED,
  VENUE_SELECTED_CHANGED,
  BUYERS_CHANGED,
  PROMOTERS_CHANGED,
  SELECTED_PROMOTER_CHANGED,
  SELECTED_BUYER_CHANGED,
  USER_CHANGED_DOORS_TIME_CHANGED,
  USER_CHANGED_EVENT_END_TIME_CHANGED,
  HOLD_GROUP_DELETION_CHANGED,
  HOLD_GROUP_DELETION_REASON_CHANGED,
  HOLD_GROUP_DELETION_EXPLANATION_CHANGED,
  HOLD_GROUP_DELETION_FOLLOW_UP_CHANGED,
  HOLD_GROUP_DELETION_FOLLOW_UP_AT_CHANGED,
  SUBMITTING_FORM_CHANGED,
  HOLD_GROUP_CHANGED
} from '../constants/eventFormConstants';

import {stateToHTML} from 'draft-js-export-html';

const areDatesOnSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

const createHoldGroupParams = (team, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, holdGroup, selectedPromoter, selectedBuyer) => {
  var params = {
    team_id: team.id,
    title: calendarEventTitle,
    venue_id: venueSelected.id,
    artist_ids: calendarEventArtists.map((artist) => artist.value),
    promoter_id: (Object.keys(selectedPromoter).length > 0 ? selectedPromoter.value : ""),
    buyer_id: (Object.keys(selectedBuyer).length > 0 ? selectedBuyer.value : ""),
    ticket_forecast: ticketForecast,
    event_template_id: (holdGroup.event_template ? holdGroup.event_template.value : null),
    note_attributes: {
      body: stateToHTML(calendarEventNoteEditorState.getCurrentContent())
    },
    hold_positions_attributes: (holdGroup.hold_group_entries || []).map((holdGroupEntry) => {
      return {
        _destroy: holdGroupEntry._destroy,
        start_time: offsetDateForServer(holdGroupEntry.hold.start_time),
        position: holdGroupEntry.hold.position
      }
    })
  }

  if(holdGroup.pipeline){
    params.update_pipeline_attributes = {
      id: holdGroup.pipeline.id,
      state: "calendar_event"
    };
  } else {
    params.pipeline_attributes = {
      team_id: team.id,
      state: "calendar_event"
    };
  }

  return params;
}

const createHoldGroup = (dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
  dispatch(submittingFormChanged(true));

  return axios.post("/teams/" + team.id + "/hold_groups", {
      hold: createHoldGroupParams(team, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, holdGroup, selectedPromoter, selectedBuyer)
    })
    .then(({ data }) => {
      onSuccess(data[0].url);
      closeFunction();

      if(holdGroup.rowTableEl){
        $(holdGroup.rowTableEl).DataTable().ajax.reload();
      }
    })
    .catch((error) => {
      dispatch(updateCalendarEventErrors(error.response.data));
    })
    .then(() => {
      dispatch(submittingFormChanged(false));
    });
}

const createConfirmParams = (calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, team, selectedPromoter, selectedBuyer) => {
  var params = {
    type: calendarEventType,
    title: calendarEventTitle,
    venue_id: venueSelected.id,
    door_time: offsetDateForServer(confirm.door_time),
    end_time: offsetDateForServer(confirm.end_time),
    artist_ids: calendarEventArtists.map((artist) => artist.value),
    promoter_id: (Object.keys(selectedPromoter).length > 0 ? selectedPromoter.value : ""),
    buyer_id: (Object.keys(selectedBuyer).length > 0 ? selectedBuyer.value : ""),
    ticket_forecast: ticketForecast,
    event_template_id: (confirm.event_template ? confirm.event_template.value : null),
    note_attributes: {
      body: stateToHTML(calendarEventNoteEditorState.getCurrentContent())
    },
    hold_positions_attributes: [{
      start_time: offsetDateForServer(confirm.start_time),
      position: 1
    }]
  }

  if(confirm.pipeline){
    params.update_pipeline_attributes = {
      id: confirm.pipeline.id,
      state: "calendar_event"
    };
  } else {
    params.pipeline_attributes = {
      team_id: team.id,
      state: "calendar_event"
    };
  }

  return params;
}

const createConfirm = (dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, onSuccess, selectedPromoter, selectedBuyer) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
  dispatch(submittingFormChanged(true));

  return axios.post("/teams/" + team.id + "/calendar_events", {
      calendar_event: createConfirmParams(calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, team, selectedPromoter, selectedBuyer)
    })
    .then(({ data }) => {
      onSuccess(data[0].url);
      closeFunction();

      if(confirm.rowTableEl){
        $(confirm.rowTableEl).DataTable().ajax.reload();
      }
    })
    .catch((error) => {
      dispatch(updateCalendarEventErrors(error.response.data));
    })
    .then(() => {
      dispatch(submittingFormChanged(false));
    });
}

export const submitNewEventForm = (dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => {
  dispatch(updateCalendarEventErrors([]));

  return dispatch => {
    if(calendarEventType === "HoldGroup"){
      createHoldGroup(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, holdGroup, onSuccess, selectedPromoter, selectedBuyer);
    } else {
      createConfirm(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, onSuccess, selectedPromoter, selectedBuyer);
    }
  };
};

const updateHoldGroupParams = (team, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, holdGroup, selectedPromoter, selectedBuyer) => {
  var params = {
    team_id: team.id,
    title: calendarEventTitle,
    venue_id: venueSelected.id,
    promoter_id: (Object.keys(selectedPromoter).length > 0 ? selectedPromoter.value : ""),
    buyer_id: (Object.keys(selectedBuyer).length > 0 ? selectedBuyer.value : ""),
    artist_ids: calendarEventArtists.map((artist) => artist.value),
    ticket_forecast: ticketForecast,
    note_attributes: {
      id: (holdGroup.note && holdGroup.note.id ? holdGroup.note.id : ""),
      body: stateToHTML(calendarEventNoteEditorState.getCurrentContent())
    },
    hold_positions_attributes: (holdGroup.hold_group_entries || []).map((holdGroupEntry) => {
      return {
        id: (holdGroupEntry.hold.hold_position_id ? holdGroupEntry.hold.hold_position_id : ""),
        start_time: offsetDateForServer(holdGroupEntry.hold.start_time),
        position: holdGroupEntry.hold.position,
        _destroy: holdGroupEntry._destroy
      }
    })
  }

  return params;
}

const updateHoldGroup = (dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
  dispatch(submittingFormChanged(true));

  return axios.patch("/teams/" + team.id + "/hold_groups/" + holdGroup.id, {
      hold: updateHoldGroupParams(team, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, holdGroup, selectedPromoter, selectedBuyer)
    })
    .then(({ data }) => {
      onSuccess();
      closeFunction();
    })
    .catch((error) => {
      dispatch(updateCalendarEventErrors(error.response.data));
    })
    .then(() => {
      dispatch(submittingFormChanged(false));
    });
}

const updateConfirmParams = (team, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, selectedPromoter, selectedBuyer) => {
  var params = {
    id: confirm.id,
    title: calendarEventTitle,
    venue_id: venueSelected.id,
    start_time: offsetDateForServer(confirm.start_time),
    door_time: offsetDateForServer(confirm.door_time),
    end_time: offsetDateForServer(confirm.end_time),
    artist_ids: calendarEventArtists.map((artist) => artist.value),
    ticket_forecast: ticketForecast,
    event_template_id: (confirm.event_template ? confirm.event_template.value : null),
    note_attributes: {
      id: (confirm.note ? confirm.note.id : null),
      body: stateToHTML(calendarEventNoteEditorState.getCurrentContent())
    }
  }

  return params;
}

const updateConfirm = (dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, onSuccess, selectedPromoter, selectedBuyer) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
  dispatch(submittingFormChanged(true));

  return axios.patch("/teams/" + team.id + "/calendar_events/" + confirm.id, {
      calendar_event: updateConfirmParams(team, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, selectedPromoter, selectedBuyer)
    })
    .then(({ data }) => {
      onSuccess();
      closeFunction();
    })
    .catch((error) => {
      dispatch(updateCalendarEventErrors(error.response.data));
    })
    .then(() => {
      dispatch(submittingFormChanged(false));
    });
}

const convertHoldGroupToConfirmParams = (team, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, selectedPromoter, selectedBuyer) => {
  var params = {
    title: calendarEventTitle,
    venue_id: venueSelected.id,
    door_time: offsetDateForServer(confirm.door_time),
    end_time: offsetDateForServer(confirm.end_time),
    artist_ids: calendarEventArtists.map((artist) => artist.value),
    calendar_classification: "confirm",
    promoter_id: (Object.keys(selectedPromoter).length > 0 ? selectedPromoter.value : ""),
    buyer_id: (Object.keys(selectedBuyer).length > 0 ? selectedBuyer.value : ""),
    ticket_forecast: ticketForecast,
    event_template_id: (confirm.event_template ? confirm.event_template.value : null),
    note_attributes: {
      id: (confirm.hold.data && confirm.hold.data.note ? confirm.hold.data.note.id : null),
      body: stateToHTML(calendarEventNoteEditorState.getCurrentContent())
    },
    hold_positions_attributes: holdGroup.hold_positions.map((holdPosition) => {
      var holdPositionDate = new Date(holdPosition.start_date_midday);

      if(areDatesOnSameDay(holdPositionDate, confirm.start_time)){
        return {
          id: holdPosition.id,
          start_time: offsetDateForServer(confirm.start_time),
          position: 1
        }
      } else {
        return {
          id: holdPosition.id,
          _destroy: true
        }
      }
    })
  }

  return params;
}

const convertHoldGroupToConfirm = (dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
  dispatch(submittingFormChanged(true));

  return axios.post("/teams/" + team.id + "/hold_groups/" + holdGroup.id + "/convert_to_confirm", {
      confirm: convertHoldGroupToConfirmParams(team, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, selectedPromoter, selectedBuyer)
    })
    .then(({ data }) => {
      onSuccess();
      closeFunction();
    })
    .catch((error) => {
      dispatch(updateCalendarEventErrors(error.response.data));
    })
    .then(() => {
      dispatch(submittingFormChanged(false));
    });
}

export const submitEditEventForm = (dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => {
  dispatch(updateCalendarEventErrors([]));

  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    if(calendarEventType === "HoldGroup"){
      if(confirm.id){
        // No longer possible
      } else {
        updateHoldGroup(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, holdGroup, onSuccess, selectedPromoter, selectedBuyer);
      }
    }

    if(calendarEventType === "Confirm"){
      if(holdGroup.id){
        convertHoldGroupToConfirm(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer);
      } else {
        updateConfirm(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, onSuccess, selectedPromoter, selectedBuyer);
      }
    }
  };
};

export const deleteEvent = (dispatch, csrfToken, team, closeFunction, holdGroup, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    if(confirm.id){
      return axios.delete("/teams/" + team.id + "/calendar_events/" + confirm.id)
        .then(({ data }) => {
          dispatch(eventDeletionSuccess(data));
          closeFunction();
        })
        .catch((error) => {
          dispatch(updateCalendarEventErrors(error.response.data));
        });
    }

    if(holdGroup.id){
      return axios.delete("/teams/" + team.id + "/hold_groups/" + holdGroup.id)
        .then(({ data }) => {
          data.map((hold) => {
            dispatch(eventDeletionSuccess(hold));
          });

          closeFunction();
        })
        .catch((error) => {
          dispatch(updateCalendarEventErrors(error.response.data));
        });
    }
  };
};

export const updateEventSuccessful = (event) => ({
  type: EVENT_TO_EDIT_UPDATE_SUCCESS,
  event
});

export const eventDeletionSuccess = (event) => ({
  type: EVENT_TO_EDIT_DELETED,
  event
});

export const fetchHoldAvailability = (dispatch, team, venueId, date) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/holds/availity", {
        params: {
          venue_id: venueId,
          date: date
        }
      })
      .then(({ data }) => {
        dispatch(holdGroupHoldAvailabilityUpdated(team, date, data));
      })
  };
};

export const confirmChanged = (confirm) => ({
  type: CONFIRM_CHANGED,
  confirm
});

export const updateCalendarEventTitle = (title) => ({
  type: CALENDAR_EVENT_TITLE_UPDATED,
  title
});

export const updateCalendarEventArtists = (artists, userChangedCalendarEventTitle) => ({
  type: CALENDAR_EVENT_ARTISTS_UPDATED,
  artists,
  userChangedCalendarEventTitle
});

export const updateCalendarEventType = (calendarEventType) => ({
  type: CALENDAR_EVENT_TYPE_UPDATED,
  calendarEventType
});

export const calendarEventTicketForecastChanged = (ticketForecast) => ({
  type: CALENDAR_EVENT_TICKET_FORECAST_CHANGED,
  ticketForecast
});

export const updateCalendarEventNoteEditorState = (noteEditorState) => ({
  type: CALENDAR_EVENT_NOTE_EDITOR_STATE_UPDATED,
  noteEditorState
});

export const updateCalendarEventErrors = (errors) => ({
  type: CALENDAR_EVENT_ERRORS_UPDATED,
  errors
});

export const calendarEventFormClosed = (dispatch, onClose) => {
  return dispatch => {
    onClose();
    dispatch(broadcastCalendarEventFormClosed());
  };
};

export const broadcastCalendarEventFormClosed = () => ({
  type: CALENDAR_EVENT_FORM_CLOSED
});

export const holdGroupCalendarDateClicked = (date, selected) => ({
  type: HOLD_GROUP_CALENDAR_DATE_CLICKED,
  date,
  selected
});

export const holdGroupHoldPositionUpdated = (date, position) => ({
  type: HOLD_GROUP_HOLD_POSITION_UPDATED,
  date,
  position
});

export const holdGroupHoldRemoved = (date) => ({
  type: HOLD_GROUP_HOLD_REMOVED,
  date
})

export const confirmStartDateUpdated = (date) => ({
  type: CONFIRM_START_DATE_UPDATED,
  date
})

export const confirmStartTimeUpdated = (time) => ({
  type: CONFIRM_START_TIME_UPDATED,
  time
})

export const holdGroupHoldAvailabilityUpdated = (team, date, holdAvailability) => ({
  type: HOLD_GROUP_HOLD_AVAILABILITY_UPDATED,
  team,
  date,
  holdAvailability
})

export const holdGroupUpdated = (holdGroup) => ({
  type: HOLD_GROUP_UPDATED,
  holdGroup
})

export const confirmUpdated = (confirm) => ({
  type: CONFIRM_UPDATED,
  confirm
})

export const confirmHoldUpdated = (hold) => ({
  type: CONFIRM_HOLD_UPDATED,
  hold
})

export const updateSelectedVenue = (venues, selected) => ({
  type: VENUE_SELECTED_CHANGED,
  venues,
  selected
});

export const selectedPromoterChanged = (promoter) => ({
  type: SELECTED_PROMOTER_CHANGED,
  promoter
})

export const buyersChanged = (buyers) => ({
  type: BUYERS_CHANGED,
  buyers
})

export const promotersChanged = (promoters) => ({
  type: PROMOTERS_CHANGED,
  promoters
})

export const selectedBuyerChanged = (buyer) => ({
  type: SELECTED_BUYER_CHANGED,
  buyer
})

export const userChangedDoorsTimeChanged = (userChangedDoorsTime) => ({
  type: USER_CHANGED_DOORS_TIME_CHANGED,
  userChangedDoorsTime
})

export const userChangedEventEndTimeChanged = (userChangedEventEndTime) => ({
  type: USER_CHANGED_EVENT_END_TIME_CHANGED,
  userChangedEventEndTime
})

export const holdGroupDeletionChanged = (holdGroupDeletion) => ({
  type: HOLD_GROUP_DELETION_CHANGED,
  holdGroupDeletion
})

export const holdGroupDeletionReasonChanged = (reason) => ({
  type: HOLD_GROUP_DELETION_REASON_CHANGED,
  reason
})

export const holdGroupDeletionExplanationChanged = (explanation) => ({
  type: HOLD_GROUP_DELETION_EXPLANATION_CHANGED,
  explanation
})

export const holdGroupDeletionFollowUpChanged = (followUp) => ({
  type: HOLD_GROUP_DELETION_FOLLOW_UP_CHANGED,
  followUp
})

export const holdGroupDeletionFollowUpAtChanged = (followUpAt) => ({
  type: HOLD_GROUP_DELETION_FOLLOW_UP_AT_CHANGED,
  followUpAt
})

export const deleteHoldGroup = (dispatch, csrfToken, team, closeFunction, holdGroup, holdGroupDeletion) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/hold_groups/" + holdGroup.id, {
        data: {
          hold_group_deletion: {
            reason: holdGroupDeletion.reason,
            explanation: holdGroupDeletion.explanation,
            follow_up_at: holdGroupDeletion.followUpAt
          }
        }
      })
      .then(({ data }) => {
        data.map((hold) => {
          dispatch(eventDeletionSuccess(hold));
        });

        dispatch(holdGroupDeletionChanged({}));
        closeFunction();
      })
      .catch((error) => {
        dispatch(updateCalendarEventErrors(error.response.data));
      });
  };
};

export const submittingFormChanged = (submittingForm) => ({
  type: SUBMITTING_FORM_CHANGED,
  submittingForm
});

export const applyTimeDefaults = (dispatch, confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime, callback) => {
  return dispatch => {
    if(typeof confirm.start_time === 'undefined') { return; }
    if(confirm.start_time === null) { return; }

    const startTime = confirm.start_time;
    const startTimeMinutes = startTime.getMinutes();
    const startTimeHours = startTime.getHours();

    var changedConfirm = {...confirm};

    var doorsDefault = new Date(startTime);
    var eventEndDefault = new Date(startTime);

    if(canUpdateDoorsTime) {
      if(doorsTimeDefault.interval_unit == 'minutes') {
        doorsDefault.setMinutes(startTimeMinutes - doorsTimeDefault.interval_value);
      } else if(doorsTimeDefault.interval_unit == 'hours') {
        doorsDefault.setHours(startTimeHours - doorsTimeDefault.interval_value);
      }

      changedConfirm.door_time = doorsDefault;
    }

    if(canUpdateEventEndTime) {
      if(eventEndTimeDefault.interval_unit == 'minutes') {
        eventEndDefault.setMinutes(startTimeMinutes + eventEndTimeDefault.interval_value);
      } else if(doorsTimeDefault.interval_unit == 'hours') {
        eventEndDefault.setHours(startTimeHours + eventEndTimeDefault.interval_value);
      }

      changedConfirm.end_time = eventEndDefault;
    }

    if(typeof callback === 'function') {
      callback(changedConfirm);
    } else {
      dispatch(confirmChanged(changedConfirm));
    }
  };
};

export const holdGroupChanged = (holdGroup) => ({
  type: HOLD_GROUP_CHANGED,
  holdGroup
});
