import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import axios from "axios";

import SampleDataContainer from "./SampleDataContainer";
import { useBroadcastEditContext } from "./BroadcastEditContainer";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
};

const headerStyle = {
  color: "#333333",
  fontSize: "14px",
  fontWeight: "bold",
};

const searchBoxStyle = {
  alignItems: "center",
  border: "1px solid #EEEEEE",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "row",
  gap: "5px",
  padding: "5px",
  width: "100%",
};

const searchIconStyle = {
  color: "#333333",
  fontSize: "16px",
  opacity: "0.25",
};

const searchInputStyle = {
  border: "none",
  flexGrow: "1",
  focus: "none",
  outline: "none",
};

const popupContentStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "0px",
  width: "fit-content",
};

const menuLineStyle = {
  display: "flex",
  flexDirection: "row",
  fontSize: "12px",
  fontWeight: "normal",
  margin: "5px",
  paddingRight: "10px",
};

const fanContainerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

const initialsStyle = {
  alignItems: "center",
  background: "#33333366",
  borderRadius: "50%",
  color: "white",
  display: "flex",
  fontSize: "14px",
  fontWeight: "bold",
  height: "40px",
  justifyContent: "center",
  width: "40px",
};

const fanInfoStyle = {
  color: "#333333",
  display: "flex",
  flexDirection: "column",
  fontSize: "12px",
};

const getInitials = (firstName, lastName) => {
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

const SampleDataPicker = () => {
  const {
    team,
    csrfToken,
    setSampleFanId,
    sampleData,
  } = useBroadcastEditContext();

  const [initials, setInitials] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (sampleData.fan?.first_name && sampleData.fan?.last_name) {
      setInitials(getInitials(sampleData.fan.first_name, sampleData.fan.last_name));
    }
  }, [sampleData]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (inputValue) {
        axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
        axios.get(`/teams/${team.id}/fans/email_search?name=${inputValue}`)
          .then(response => {
            setResults(response.data);
            if (response.data.length > 0) {
              setOpen(true);
            }
          })
          .catch(error => {
            console.error("Error fetching data:", error);
            setOpen(false);
          });
      } else {
        setResults([]);
        setOpen(false);
      }
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [inputValue]);

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        Sample Data
      </div>
      <div style={searchBoxStyle}>
        <i className="fa fa-search" style={searchIconStyle} />
        <input
          type="text"
          placeholder="Find by email address..."
          style={searchInputStyle}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}  
        />
      </div>
      <Popup
        arrow={false}
        offsetX={15}
        offsetY={-10}
        position="bottom left"
        contentStyle={popupContentStyle}
        open={open}
        closeOnDocumentClick
        trigger={(open) => <div></div>}
        onClose={() => setOpen(false)}
      >
        <div className="row text-left">
          <div className="col-12">
            <ul className="list-group offer-inline-menu highlight-primary">
              {results.map((item, index) => (
                <li className="list-group-item" key={index}>
                  <a className="text-muted"
                    style={menuLineStyle}
                    href="#"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        setOpen(false);
                        setInputValue("");
                        setSampleFanId(item.id);
                      }
                    }
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Popup>
      <div style={fanContainerStyle}>
        <div style={initialsStyle}>
          {initials}
        </div>
        <div style={fanInfoStyle}>
          <div>
            <strong>{sampleData.fan?.first_name} {sampleData.fan?.last_name}</strong>
          </div>
          <div>
            {sampleData.fan?.email}
          </div>
        </div>
      </div>
      <SampleDataContainer />
    </div>
  );
};

export default SampleDataPicker;
