import React, { useEffect, useState } from "react";

import { useBroadcastEditContext } from "./BroadcastEditContainer";

const containerStyle = {
  borderRadius: "10px",
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
};

const BroadcastSidebar = ({
  children
}) => {
  const {
    tab,
    setTab,
    broadcast,
    beefreeToken,
    beefreeUid,
    template,
    setTemplate,
    subject,
    updateBroadcast,
    setSubject,
    sendDomain,
  } = useBroadcastEditContext();

  return (
    <div className="d-flex mt-4" style={containerStyle}>
      <div className="p-4 flex-shrink-0" style={{ width: "250px", borderRadius: "10px 0 0 10px", backgroundColor: "#f3f9fc" }}>
        <div style={{ fontSize: "22px" }}>
          <strong>
            Email Broadcast
          </strong>
        </div>
        <ul className="text-dark p-0">
          <li
            role="button"
            className="w-100 mt-4 p-2 d-flex align-items-center rounded-lg hover:pointer"
            style={{
              cursor: "pointer",
              backgroundColor: tab === "recipients" ? "#dff1fb" : "transparent"
            }}
            onClick={() => setTab("recipients")}
          >
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: tab === "recipients" ? "#fff" : "transparent"
              }}
            >
              <i className="far fa-list" />
            </div>
            <a href="#" className="ml-2 text-dark text-decoration-none" onClick={e => e.preventDefault()}>
              <strong>
                Recipients
              </strong>
            </a>
          </li>
          <li
            className="w-100 mt-2 p-2 d-flex align-items-center rounded-lg"
            style={{
              cursor: "pointer",
              backgroundColor: tab === "from" ? "#dff1fb" : "transparent"
            }}
            onClick={() => setTab("from")}
          >
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: tab === "from" ? "#fff" : "transparent"
              }}
            >
              <i className="far fa-envelope" />
            </div>
            <a href="#" className="ml-2 text-dark text-decoration-none" onClick={e => e.preventDefault()}>
              <strong>
                From
              </strong>
            </a>
          </li>
          <li
            className="w-100 mt-2 p-2 d-flex align-items-center rounded-lg"
            style={{
              cursor: "pointer",
              backgroundColor: tab === "content-select" ? "#dff1fb" : "transparent"
            }}
            onClick={() => setTab("content-select")}
          >
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: tab === "content-select" ? "#fff" : "transparent"
              }}
            >
              <i className="far fa-grid-2" />
            </div>
            <a href="#" className="ml-2 text-dark text-decoration-none" onClick={e => e.preventDefault()}>
              <strong>
                Content
              </strong>
            </a>
          </li>
          <li
            className="w-100 mt-2 p-2 d-flex align-items-center rounded-lg"
            style={{
              cursor: "pointer",
              backgroundColor: tab === "review-and-schedule" ? "#dff1fb" : "transparent"
            }}
            onClick={() => setTab("review-and-schedule")}
          >
            <div
              className="d-flex align-items-center justify-content-center rounded-circle"
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: tab === "review-and-schedule" ? "#fff" : "transparent"
              }}
            >
              <i className="far fa-calendar" />
            </div>
            <a href="#" className="ml-2 text-dark text-decoration-none" onClick={e => e.preventDefault()}>
              <strong>
                Review & Schedule
              </strong>
            </a>
          </li>
        </ul>
      </div>
      <div className="p-4 flex-grow-1" style={{ borderRadius: "0 10px 10px 0", backgroundColor: "#fff" }}>
        {children}
      </div>
    </div>
  );
};

export default BroadcastSidebar;
