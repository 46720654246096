import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store';
import ActivityFeedContainer from '../containers';

const ActivityFeedApp = (props) => (
  <Provider store={configureStore(props)}>
    <ActivityFeedContainer />
  </Provider>
);

export default ActivityFeedApp;
