import { combineReducers } from 'redux';

import {
  OUTREACH_MODAL_OUTREACH_CHANGED,
  OUTREACH_MODAL_OUTREACH_MODAL_CLOSED,
  OUTREACH_MODAL_OUTREACH_BODY_CHANGED_FROM_TEMPLATE,
  OUTREACH_MODAL_OUTREACH_BODY_CHANGED,
  OUTREACH_MODAL_OUTREACH_TO_CHANGED,
  OUTREACH_MODAL_OUTREACH_CC_CHANGED,
  OUTREACH_MODAL_OUTREACH_BCC_CHANGED,
  OUTREACH_MODAL_OUTREACH_SUBJECT_CHANGED,
  OUTREACH_MODAL_OUTREACH_ERRORS_CHANGED,
  OUTREACH_MODAL_OUTREACH_VENUE_OWNERSHIP_ID_CHANGED,
  OUTREACH_MODAL_OUTREACH_STATE_CHANGED,
  OUTREACH_MODAL_ADD_AGENT_EMAIL_TO_TOS,
  OUTREACH_MODAL_REMOVE_AGENT_EMAIL_FROM_TOS,
  OUTREACH_MODAL_OUTREACH_ARTIST_CHANGED,
  OUTREACH_MODAL_OUTREACH_TEMPLATE_ADDED,
  OUTREACH_MODAL_OUTREACH_TEMPLATE_NAME_CHANGED,
  OUTREACH_MODAL_SELECTED_OUTREACH_TEMPLATE_CHANGED,
  OUTREACH_MODAL_AGENT_PREVIEWS_CHANGED
} from '../constants/outreachModalConstants';

const artistOrVenueOwnershipChange = (action, state) => {
  var subject;
  if (action.artist && action.artist.id && action.venueOwnership && action.venueOwnership.id && !state.subjectManuallyChanged) {
    subject = [action.artist.name, action.venueOwnership.venueable.name].join(" and ");
  } else {
    subject = state.subject;
  }

  return Object.assign({}, state, {
    subject: subject,
    venue_ownership_id: (action.venueOwnership ? action.venueOwnership.id : null),
    venue_ownership: action.venueOwnership,
    artist_id: (action.artist ? action.artist.id : null)
  });
};

const outreach = (state = {}, action) => {
  switch (action.type) {
    case OUTREACH_MODAL_OUTREACH_ARTIST_CHANGED:
      var newState = artistOrVenueOwnershipChange(action, state);

      var newTo = "";
      if(action.artist && action.artist.agents && action.artist.agents.length === 1){
        newTo = action.artist.agents[0].email;
      }

      return Object.assign({}, newState, {to: newTo});
    case OUTREACH_MODAL_OUTREACH_CHANGED:
      return action.outreach;
    case OUTREACH_MODAL_OUTREACH_MODAL_CLOSED:
      return {};
    case OUTREACH_MODAL_OUTREACH_BODY_CHANGED_FROM_TEMPLATE:
      return Object.assign({}, state, {body: action.editorState});
    case OUTREACH_MODAL_OUTREACH_BODY_CHANGED:
      return Object.assign({}, state, {body: action.editorState});
    case OUTREACH_MODAL_OUTREACH_TO_CHANGED:
      return Object.assign({}, state, {to: action.to});
    case OUTREACH_MODAL_OUTREACH_CC_CHANGED:
      return Object.assign({}, state, {cc: action.cc});
    case OUTREACH_MODAL_OUTREACH_BCC_CHANGED:
      return Object.assign({}, state, {bcc: action.bcc});
    case OUTREACH_MODAL_OUTREACH_SUBJECT_CHANGED:
      return Object.assign({}, state, {subject: action.subject, subjectManuallyChanged: true});
    case OUTREACH_MODAL_OUTREACH_ERRORS_CHANGED:
      return Object.assign({}, state, {errors: action.errors});
    case OUTREACH_MODAL_OUTREACH_VENUE_OWNERSHIP_ID_CHANGED:
      return artistOrVenueOwnershipChange(action, state);
    case OUTREACH_MODAL_OUTREACH_STATE_CHANGED:
      return Object.assign({}, state, {state: action.state});
    case OUTREACH_MODAL_ADD_AGENT_EMAIL_TO_TOS:
      var newTos = state.to
        .split(",")
        .filter((e) => e.length !== 0)
        .concat(action.agent.email)
        .join(", ");

      return Object.assign({}, state, {to: newTos});
    case OUTREACH_MODAL_REMOVE_AGENT_EMAIL_FROM_TOS:
      var newTos = state.to
        .split(",")
        .map((e) => e.trim())
        .filter((e) => e !== action.agent.email)
        .join(", ");

      return Object.assign({}, state, {to: newTos});
    default:
      return state;
  }
};

const artist = (state = {}, action) => {
  switch (action.type) {
    case OUTREACH_MODAL_OUTREACH_TO_CHANGED:
      if(Object.keys(state).length === 0){
        return state;
      }

      var toEmails = action.to.split(",").map((address) => (
        address.trim()
      ));

      var agents = [...state.agents].map((agent) => {
        return Object.assign({}, agent, {
          inToEmails: toEmails.includes(agent.email)
        });
      });

      return Object.assign({}, state, {agents: agents});
    case OUTREACH_MODAL_ADD_AGENT_EMAIL_TO_TOS:
      var agents = [...state.agents].map((agent) => {
        if(agent.id === action.agent.id){
          return Object.assign({}, agent, {inToEmails: true});
        } else {
          return agent;
        }
      });

      return Object.assign({}, state, {agents: agents});
    case OUTREACH_MODAL_REMOVE_AGENT_EMAIL_FROM_TOS:
      var agents = [...state.agents].map((agent) => {
        if(agent.id === action.agent.id){
          return Object.assign({}, agent, {inToEmails: false});
        } else {
          return agent;
        }
      });

      return Object.assign({}, state, {agents: agents});
    case OUTREACH_MODAL_OUTREACH_MODAL_CLOSED:
      return {};
    case OUTREACH_MODAL_OUTREACH_ARTIST_CHANGED:
      return action.artist;
    default:
      return state;
  }
};

const outreachTemplates = (state = [], action) => {
  switch (action.type) {
    case OUTREACH_MODAL_OUTREACH_TEMPLATE_ADDED:
      return [...state, action.outreachTemplate];
    default:
      return state;
  }
};

const outreachTemplateName = (state = "", action) => {
  switch (action.type) {
    case OUTREACH_MODAL_OUTREACH_TEMPLATE_NAME_CHANGED:
      return action.name;
    case OUTREACH_MODAL_OUTREACH_TEMPLATE_ADDED:
      return "";
    case OUTREACH_MODAL_OUTREACH_MODAL_CLOSED:
      return "";
    default:
      return state;
  }
};

const selectedOutreachTemplate = (state = {}, action) => {
  switch (action.type) {
    case OUTREACH_MODAL_SELECTED_OUTREACH_TEMPLATE_CHANGED:
      return action.outreachTemplate;
    case OUTREACH_MODAL_OUTREACH_TEMPLATE_ADDED:
      return action.outreachTemplate;
    case OUTREACH_MODAL_OUTREACH_BODY_CHANGED:
      return {};
    case OUTREACH_MODAL_OUTREACH_MODAL_CLOSED:
      return {};
    default:
      return state;
  }
};

const agentPreviews = (state = [], action) => {
  switch (action.type) {
    case OUTREACH_MODAL_ADD_AGENT_EMAIL_TO_TOS:
      return [...state, action.agent];
    case OUTREACH_MODAL_REMOVE_AGENT_EMAIL_FROM_TOS:
      return state.filter((agent) => {
        return agent.id !== action.agent.id
      })
    case OUTREACH_MODAL_AGENT_PREVIEWS_CHANGED:
      return action.agentPreviews;
    case OUTREACH_MODAL_OUTREACH_MODAL_CLOSED:
      return [];
    case OUTREACH_MODAL_OUTREACH_ARTIST_CHANGED:
      if(action.artist && action.artist.agents && action.artist.agents.length === 1){
        return [...action.artist.agents];
      } else {
        return  [];
      }
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const canChangeArtist = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onOutreachCreate = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const outreachModalReducer = combineReducers({ outreach, outreachTemplates, outreachTemplateName, artist, selectedOutreachTemplate, agentPreviews, venueOwnerships, canChangeArtist, onOutreachCreate, team, csrfToken, onClose });

export default outreachModalReducer;
