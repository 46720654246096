// Simple example of a React "smart" component

import { connect } from 'react-redux';
import VenueManager from '../components/VenueManager';
import * as actions from '../actions/venueManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  venueOwnerships: state.venueOwnerships,
  settingUpTeam: state.settingUpTeam,
  venueSuggestionFormOpen: state.venueSuggestionFormOpen,
  venueSuggestion: state.venueSuggestion,
  venueStateOptions: state.venueStateOptions,
  modalIsOpen: state.modalIsOpen,
  availableTimezones: state.availableTimezones
});

const mapDispatchToProps = (dispatch) => {
  return {
    deleteVenueOwnership: (csrfToken, team, venueOwnership) => dispatch(actions.deleteVenueOwnership(dispatch, csrfToken, team, venueOwnership)),
    createVenueOwnership: (csrfToken, team, venueableId, venueableType) => dispatch(actions.createVenueOwnership(dispatch, csrfToken, team, venueableId, venueableType)),
    manualVenueEntryClicked: () => dispatch(actions.manualVenueEntryClicked()),
    venueSuggestionNameChanged: (name) => dispatch(actions.venueSuggestionNameChanged(name)),
    venueSuggestionAddressOneChanged: (address1) => dispatch(actions.venueSuggestionAddressOneChanged(address1)),
    venueSuggestionAddressTwoChanged: (address2) => dispatch(actions.venueSuggestionAddressTwoChanged(address2)),
    venueSuggestionCityChanged: (city) => dispatch(actions.venueSuggestionCityChanged(city)),
    venueSuggestionStateChanged: (state) => dispatch(actions.venueSuggestionStateChanged(state)),
    venueSuggestionZipChanged: (zip) => dispatch(actions.venueSuggestionZipChanged(zip)),
    createVenueSuggestion: (csrfToken, team, venueSuggestion) => dispatch(actions.createVenueSuggestion(dispatch, csrfToken, team, venueSuggestion)),
    closeModal: () => dispatch(actions.closeModal()),
    cancelEditVenueOwnership: () => dispatch(actions.cancelEditVenueOwnership()),
    venueOwnershipBioChanged: (index, bio) => dispatch(actions.venueOwnershipBioChanged(index, bio)),
    venueOwnershipLogoElChanged: (index, logoEl) => dispatch(actions.venueOwnershipLogoElChanged(index, logoEl)),
    venueOwnershipLogoChanged: (index, logo) => dispatch(actions.venueOwnershipLogoChanged(index, logo)),
    venueOwnershipTicketLogoElChanged: (index, ticketLogoEl) => dispatch(actions.venueOwnershipTicketLogoElChanged(index, ticketLogoEl)),
    venueOwnershipTicketLogoChanged: (index, ticketLogo) => dispatch(actions.venueOwnershipTicketLogoChanged(index, ticketLogo)),
    fetchVenueOwnership: (csrfToken, team, venueOwnershipId) => dispatch(actions.fetchVenueOwnership(dispatch, csrfToken, team, venueOwnershipId)),
    venueOwnershipLogoRemoved: (index) => dispatch(actions.venueOwnershipLogoRemoved(index)),
    venueOwnershipTicketLogoRemoved: (index) => dispatch(actions.venueOwnershipTicketLogoRemoved(index)),
    updateVenueOwnership: (csrfToken, team, venueOwnership) => dispatch(actions.updateVenueOwnership(dispatch, csrfToken, team, venueOwnership)),
    venueOwnershipChanged: (venueOwnership) => dispatch(actions.venueOwnershipChanged(venueOwnership))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(VenueManager);
