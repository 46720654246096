import PropTypes from 'prop-types';
import React from 'react';

const LineItemsTable = ({
  title,
  items,
  formatCurrency,
  totalRowTotal,
  totalRowEstimateTotal,
  totalRowForecastTotal,
  totalRowLeftHand,
  tableColgroup,
}) => {
  return (
    <div>
      <div style={{"marginLeft": "15px"}}>
        <strong style={{"fontSize": "16px"}}>
          {title}
        </strong>
      </div>
      <br />
      <table style={{"width": "100%", "marginTop": "-8px", "borderSpacing": "0px 2px", "borderCollapse": "separate"}}>
        {tableColgroup}
        <thead style={{"textAlign": "left"}}>
          <tr style={{"color": "#B3B3B3", "fontSize": "10px"}}>
            <th style={{"fontWeight": "normal", "paddingLeft": "15px"}}>
              NAME
            </th>
            <th style={{"fontWeight": "normal", "paddingLeft": "15px"}}>
              CATEGORY
            </th>
            <th style={{"fontWeight": "normal", "paddingLeft": "15px"}}>
              ARTIST
            </th>
            <th style={{"fontWeight": "normal", "paddingLeft": "15px", "textAlign": "right"}}>
              FORECAST
            </th>
            <th style={{"fontWeight": "normal", "paddingLeft": "15px", "textAlign": "right"}}>
              ESTIMATE
            </th>
            <th style={{"fontWeight": "normal", "paddingLeft": "15px", "paddingRight": "15px", "textAlign": "right"}}>
              ACTUAL
            </th>
          </tr>
        </thead>
        <tbody style={{"fontSize": "12px"}}>
          {items.map((item, index) => (
            <tr key={index} style={{"background": "#f2f2f2"}}>
              <td style={{"padding": "3px 0 3px 15px"}}>
                <span>{item.name}</span>
              </td>
              <td style={{"padding": "3px 0 3px 15px"}}>
                <span>{item.category}</span>
              </td>
              <td style={{"padding": "3px 0 3px 15px"}}>
                <span>{item.artist ? item.artist : ""}</span>
              </td>
              <td style={{"padding": "3px 0 3px 15px", "textAlign": "right"}}>
                <span>{formatCurrency(item.forecast)}</span>
              </td>
              <td style={{"padding": "3px 0 3px 15px", "textAlign": "right"}}>
                <span>{formatCurrency(item.estimate)}</span>
              </td>
              <td style={{"padding": "3px 15px 3px 15px", "textAlign": "right"}}>
                <span>{formatCurrency(item.actual)}</span>
              </td>
            </tr>
          ))}
          <tr key={items.length}>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td style={{"padding": "3px 0 3px 15px", "textAlign": "right"}}>
              <strong style={{"fontSize": "14px"}}>{totalRowLeftHand}</strong>
            </td>
            <td style={{"padding": "3px 0 3px 15px", "textAlign": "right"}}>
              <strong style={{"fontSize": "14"}}>{totalRowForecastTotal}</strong>
            </td>
            <td style={{"padding": "3px 0 3px 15px", "textAlign": "right"}}>
              <strong style={{"fontSize": "14"}}>{totalRowEstimateTotal}</strong>
            </td>
            <td style={{"padding": "3px 15px 3px 15px", "textAlign": "right"}}>
              <strong style={{"fontSize": "14"}}>{totalRowTotal}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

LineItemsTable.propTypes = {
  formatCurrency: PropTypes.func.isRequired,
  totalRowTotal: PropTypes.string,
  totalRowEstimateTotal: PropTypes.string,
  totalRowForecastTotal: PropTypes.string,
  totalRowLeftHand: PropTypes.string.isRequired,
};

export default LineItemsTable;
