import React, { useState } from "react";

import { useBroadcastEditContext } from "./BroadcastEditContainer";
import ErrorsContainer from "../BeefreeTemplates/ErrorsContainer";
import { offsetDateToTimezone } from "../../../shared/timeZoneLogic";
import Tippy from '@tippyjs/react';
import Popup from "reactjs-popup";

const moment = require("moment-timezone");

const headerRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const leftHeaderStyle = {
  maxWidth: "75%",
};

const nameStyle = {
  color: "#333333",
  fontSize: 16,
  fontWeight: 600,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 5,
};

const inputStyle = {
  border: "none",
  background: "transparent",
  fontSize: "inherit",
  fontWeight: "inherit",
  width: 400,
};

const iconStyle = {
  cursor: "pointer",
  color: "#333333",
  opacity: 0.8,
};

const subheaderStyle = {
  color: "#333333",
  fontSize: 12,
  fontWeight: 400,
  opacity: 0.8,
  paddingLeft: 2,
};

const scheduledSendStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

const rightHeaderStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: 10,
};

const backButtonStyle = {
  padding: "25px 12px",
  color: "#1982C4",
  fontWeight: "bold",
  fontSize: 12,
};

const broadcastModalContentStyle = {
  textAlign: "left",
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  width: "500px",
  padding: "15px"
}

const deleteButtonStyle = {
  backgroundColor: "#FF595E",
  border: "none",
  borderRadius: 5,
  color: "#FFFFFF",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 700,
  height: "fit-content",
  padding: "10px 12px",
};

const saveButtonStyle = {
  backgroundColor: "#DFF1FB",
  border: "none",
  borderRadius: 5,
  color: "#1982C4",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 700,
  height: "fit-content",
  padding: "10px 12px",
};

const scheduleButtonStyle = {
  backgroundColor: "#1982C4",
  border: "none",
  borderRadius: 5,
  color: "white",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  height: "fit-content",
  padding: "10px 12px",
};

const sendTestButtonStyle = {
  backgroundColor: "white",
  border: "#1982C4 1px solid",
  borderRadius: 5,
  color: "#1982C4",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  height: "fit-content",
  padding: "10px 12px",
};

const tabMenuStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const tabSelectStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const tabOptionStyle = {
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  padding: "15px 25px",
};

const tabRightStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "15px",
};

const BroadcastEditHeader = () => {
  const {
    csrfToken,
    team,
    venueOwnership,
    broadcast,
    subject,
    template,
    setTestModalOpen,
    tab,
    setTab,
    scheduledSend,
    setScheduledSendPaneOpen,
    timeZone,
    updateBroadcast,
    previouslySent,
  } = useBroadcastEditContext();

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(broadcast.name);

  const editScheduledSend = (e) => {
    e.preventDefault();
    setScheduledSendPaneOpen(true);
  }

  const formatDate = (date) => {
    const timezoneAbbr = moment.tz(timeZone).format("z");
    return `${moment(offsetDateToTimezone(date, timeZone)).format(`MMM D, YYYY - h:mma`)} ${timezoneAbbr}`;
  };

  const updateName = () => {
    setIsEditing(false);
    updateBroadcast({
      broadcast: {
        name: name,
      }
    });
  };

  const handleSave = (alert = true, callback = null) => {
    updateBroadcast({
      broadcast: {
        subject: subject,
        beefree_template_attributes: {
          id: broadcast.beefree_template.id,
          template: template,
        }
      }
    }, alert, callback)
  };

  return (
    <div>
      <div style={headerRowStyle}>
        <div style={leftHeaderStyle}>
          {isEditing ? (
            <div style={nameStyle}>
              Broadcasts / {venueOwnership.venueable.name} /
              <input
                autoFocus
                style={inputStyle}
                value={name}
                disabled={!isEditing}
                onChange={(e) => setName(e.target.value)}
              />
              <>
                <i className="far fa-check" style={iconStyle} onClick={updateName}></i>
                <i className="far fa-times" style={iconStyle} onClick={() => { setName(broadcast.name); setIsEditing(false); }}></i>
              </>
            </div>
          ) : (
            <div style={nameStyle}>
              <div style={{
                width: "fit-content",
              }}>
                Broadcasts / {venueOwnership.venueable.name} / {name}
              </div>
              <i className="far fa-pencil" style={iconStyle} onClick={() => setIsEditing(true)}></i>
            </div>
          )}
          {["content", "preview", "details", "sent"].includes(tab) ? (
            <div style={subheaderStyle}>
              {!!scheduledSend ? (
                <div style={scheduledSendStyle}>
                  <div>Scheduled for {formatDate(scheduledSend.scheduled_at)}</div>
                  <a href="#" onClick={editScheduledSend}>Edit</a>
                </div>
              ) : (
                "Not Scheduled"
              )}
            </div>
          ) : null}
        </div>
        {["content", "preview", "details", "sent"].includes(tab) ? (
          <div style={rightHeaderStyle}>
            {scheduledSend ? (
              <a href={`/teams/${team.id}/broadcasts`} style={backButtonStyle}>
                Back
              </a>
            ) : (
              <a href={`/teams/${team.id}/broadcasts/${broadcast.id}/edit?tab=content-select`} style={backButtonStyle}>
                Back
              </a>
            )}
            {scheduledSend && tab !== "details" ? (
              <Popup
                trigger={
                  <a className="ml-3" style={deleteButtonStyle}>
                    <Tippy content="Delete Broadcast">
                      <i className="fas fa-trash"></i>
                    </Tippy>
                  </a>
                }
                modal
                onOpen={e => e.preventDefault()}
                contentStyle={broadcastModalContentStyle}
                closeOnDocumentClick
              >
                {closeDeletePopup => (
                  <div>
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <h5 style={{"marginBottom": "16px"}}>
                              <div className="strong">Delete Broadcast?</div>
                            </h5>
                          </div>
                          <div className="col-xs-auto">
                            <a href="#"
                               className="text-dark"
                               style={{"paddingRight": "15px"}}
                               onClick={e => {e.preventDefault(); closeDeletePopup();}} >
                              <i className="fa fa-times"></i>
                            </a>
                          </div>
                        </div>
                        <p>You're about to delete this broadcast.</p>
                        <p>This cannot be undone.</p>
                        <p>Are you sure?</p>
                        <div className="d-flex align-items-center justify-content-end">
                          <a href="#"
                             onClick={e => {
                               e.preventDefault();
                               closeDuplicatePopup();
                             }}>
                            Cancel
                          </a>
                          <form method="post" action={`/teams/${team.id}/broadcasts/${broadcast.id}`}>
                            <input type="hidden" name="authenticity_token" value={csrfToken} />
                            <input type="hidden" name="_method" value="delete" />
                            <button className="btn btn-danger ml-3">
                              Delete
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            ) : null}
            {scheduledSend && tab !== "details" ? (
              <Popup
                trigger={
                  <a href="#" style={saveButtonStyle}>
                    <Tippy content="Duplicate Broadcast">
                      <i className="fas fa-copy"></i>
                    </Tippy>
                  </a>
                }
                modal
                onOpen={e => e.preventDefault()}
                contentStyle={broadcastModalContentStyle}
                closeOnDocumentClick
              >
                {closeDuplicatePopup => (
                  <div>
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <h5 style={{"marginBottom": "16px"}}>
                              <div className="strong">Duplicate Broadcast?</div>
                            </h5>
                          </div>
                          <div className="col-xs-auto">
                            <a href="#"
                               className="text-dark"
                               style={{"paddingRight": "15px"}}
                               onClick={e => {e.preventDefault(); closeDuplicatePopup();}} >
                              <i className="fa fa-times"></i>
                            </a>
                          </div>
                        </div>
                        <p>You're about to duplicate this broadcast.</p>
                        <p>Are you sure?</p>
                        <div className="d-flex align-items-center justify-content-end">
                          <a href="#"
                             onClick={e => {
                               e.preventDefault();
                               closeDuplicatePopup();
                             }}>
                            Cancel
                          </a>
                          <form method="post" action={`/teams/${team.id}/broadcasts/${broadcast.id}/duplicate`}>
                            <input type="hidden" name="authenticity_token" value={csrfToken} />
                            <button className="btn btn-primary ml-3">
                              Duplicate
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            ) : null}
            {scheduledSend && !["details", "sent"].includes(tab) ? (
              <button style={saveButtonStyle} onClick={handleSave}>
                Save
              </button>
            ) : !["details", "sent"].includes(tab) ? (
              <button
                style={scheduleButtonStyle}
                onClick={e => {
                  e.preventDefault();
                  handleSave(false, () => {
                    window.location = `/teams/${team.id}/broadcasts/${broadcast.id}/edit?tab=review-and-schedule`;
                  });
                }}
              >
                Save
              </button>
            ) : null}
            {scheduledSend && tab !== "details" ? (
              <button style={scheduleButtonStyle} onClick={() => { setScheduledSendPaneOpen(true) }}>
                Edit Schedule
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
      {["content", "preview", "details", "sent"].includes(tab) ? (
        <div style={tabMenuStyle}>
          <div style={tabSelectStyle}>
            <div
              style={{
                ...tabOptionStyle,
                color: tab === "content" ? "#1982C4" : "black",
                borderBottom: tab === "content" ? "2px solid #1982C4" : "none",
              }}
              onClick={() => setTab("content")}
            >
              Content
            </div>
            <div
              style={{
                ...tabOptionStyle,
                color: tab === "preview" ? "#1982C4" : "black",
                borderBottom: tab === "preview" ? "2px solid #1982C4" : "none",
              }}
              onClick={() => setTab("preview")}
            >
              Preview
            </div>
            {scheduledSend || previouslySent ? (
              <div
                style={{
                  ...tabOptionStyle,
                  color: tab === "details" ? "#1982C4" : "black",
                  borderBottom: tab === "details" ? "2px solid #1982C4" : "none",
                }}
                onClick={() => setTab("details")}
              >
                Details
              </div>
            ) : null}
            {previouslySent ? (
              <div
                style={{
                  ...tabOptionStyle,
                  color: tab === "sent" ? "#1982C4" : "black",
                  borderBottom: tab === "sent" ? "2px solid #1982C4" : "none",
                }}
                onClick={() => setTab("sent")}
              >
                Sent
              </div>
            ) : null}
          </div>
          {["content", "preview"].includes(tab) ? (
            <div style={tabRightStyle}>
              <ErrorsContainer templatableContext={useBroadcastEditContext} />
              <button style={sendTestButtonStyle} onClick={() => setTestModalOpen(true)}>
                Send Test
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default BroadcastEditHeader;
