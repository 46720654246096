/* eslint-disable import/prefer-default-export */

import { TYPED_SEARCH_TERM_UPDATED, SEARCHED_SEARCH_TERM_UPDATED, FILTER_UPDATED, CLEAR_FILTER_VALUES, DATA_TABLE_EL_CHANGED } from '../constants/tableViewConstants';

export const startSearchTermUpdate = (dispatch, text) => {
  dispatch(updateTypedSearchTerm(text));

  const thunk = dispatch => {
    dispatch(updateSearchedSearchTerm(text));
  };

  thunk.meta = {
    debounce: {
      time: 200,
      key: 'perform-search'
    }
  };

  return thunk;
};

export const debouncedUpdateFilter = (dispatch, filter, value) => {
  const thunk = dispatch => {
    dispatch(updateFilter(filter, value));
  };

  thunk.meta = {
    debounce: {
      time: 200,
      key: 'update-filter'
    }
  };

  return thunk;
};

export const updateSearchedSearchTerm = (text) => ({
  type: SEARCHED_SEARCH_TERM_UPDATED,
  text
});

export const updateTypedSearchTerm = (text) => ({
  type: TYPED_SEARCH_TERM_UPDATED,
  text
});

export const updateFilter = (filter, value) => ({
  type: FILTER_UPDATED,
  filter,
  value
});

export const clearFilterValues = (filter, value) => ({
  type: CLEAR_FILTER_VALUES
});

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});
