import PropTypes from 'prop-types';
import React from 'react';

import Iframe from 'react-iframe'

const OfferV2Settlement = ({
  csrfToken,
  team,
  currentUser,
  offer,
  onClose
}) => (
  <div>
    <div className="row py-2 mb-3 mx-0"
         style={{
           "borderBottom": "2px solid #fafafa"
         }}>
      <div className='col-xs-auto d-flex align-self-center'
           style={{"paddingLeft": "15px"}}>
        <a href={"/teams/" + team.id + "/offers/" + offer.id + "/settlement.pdf"}
           target="_blank"
           className="btn btn-external">
          <i className="fal fa-arrow-to-bottom"></i>
        </a>
      </div>
      <div className='col-xs-auto d-flex align-self-center'
           style={{"paddingLeft": "15px"}}>
        <a href={"/teams/" + team.id + "/offers/" + offer.id + "/settlement.csv"}
           target="_blank"
           className="btn btn-external">
          <i className="fal fa-file-csv"></i>
        </a>
      </div>
      <div className='col d-flex justify-content-center align-self-center'>
        <span style={{
          "fontWeight": "bold",
          "fontSize": "20px",
          "color": "#333333",
          "letterSpacing": "0.15px"
        }}>
          {offer.artist_name && offer.artist_name.length > 0 ? (
            <span>Final Settlement for {offer.artist_name}</span>
          ) : (
            <span>Final Settlement</span>
          )}
        </span>
      </div>
      <div className='col-xs-auto d-flex justify-content-end align-self-center'
           style={{
             "paddingRight": "15px",
             "fontSize": "24px"
           }}>
        <a href="#"
           className="text-dark mr-2"
           onClick={
             (e) => {
               e.preventDefault();
               onClose();
             }
           }>
          <i className="fal fa-times"></i>
        </a>
      </div>
    </div>
    <div className="row">
      <div className="col-12"
           style={{'padding': "0 30px"}}>
        <div className="embed-responsive" style={{"height": "80vh"}}>
          <Iframe url={"/teams/" + team.id + "/offers/" + offer.id + "/settlement?iframed=true"}
                  className="embed-responsive-item" />
        </div>
      </div>
    </div>
  </div>
);

OfferV2Settlement.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default OfferV2Settlement;
