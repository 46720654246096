// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OfferEditUI from '../components/OfferEditUI';
import * as actions from '../actions/offerEditUIActionCreators';
import * as tagManagerActions from '../../TagManager/actions/tagManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  offer: state.offer,
  budget: state.budget,
  calculated: state.calculated,
  modalUIState: state.modalUIState,
  isSaving: state.isSaving,
  isCreatingTemplate: state.isCreatingTemplate,
  budgetTemplates: state.budgetTemplates,
  budgetTemplate: state.budgetTemplate,
  offerToLink: state.offerToLink,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateOfferAdditionalTermsFromHTML: (html) => dispatch(actions.updateOfferAdditionalTermsFromHTML(html)),
    updateOffer: (updated) => dispatch(actions.updateOffer(updated)),
    autoSaveOffer: (csrfToken, team, offer, additionalOfferTerms) => dispatch(actions.autoSaveOffer(dispatch, csrfToken, team, offer, additionalOfferTerms)),
    createTicketBudgetLineItem: (csrfToken, team, budget, position) => dispatch(actions.createTicketBudgetLineItem(dispatch, csrfToken, team, budget, position)),
    createIncomeBudgetLineItem: (csrfToken, team, budget, position) => dispatch(actions.createIncomeBudgetLineItem(dispatch, csrfToken, team, budget, position)),
    createBeforeAdjustedDeductionsBudgetLineItem: (csrfToken, team, budget, position) => dispatch(actions.createBeforeAdjustedDeductionsBudgetLineItem(dispatch, csrfToken, team, budget, position)),
    createExpensesBudgetLineItem: (csrfToken, team, budget, position) => dispatch(actions.createExpensesBudgetLineItem(dispatch, csrfToken, team, budget, position)),
    deleteBudgetLineItem: (csrfToken, team, budgetLineItem) => dispatch(actions.deleteBudgetLineItem(dispatch, csrfToken, team, budgetLineItem)),
    autoSaveBudgetLineItem: (csrfToken, team, budgetLineItem) => dispatch(actions.autoSaveBudgetLineItem(dispatch, csrfToken, team, budgetLineItem)),
    updateBudget: (updated) => dispatch(actions.updateBudget(dispatch, updated)),
    autoSaveBudget: (csrfToken, team, budget) => dispatch(actions.autoSaveBudget(dispatch, csrfToken, team, budget)),
    acceptOffer: (team, offerId, csrfToken) => dispatch(actions.acceptOffer(dispatch, team, offerId, csrfToken)),
    declineOffer: (team, offerId, csrfToken) => dispatch(actions.declineOffer(dispatch, team, offerId, csrfToken)),
    deleteOffer: (team, offerId, csrfToken) => dispatch(actions.deleteOffer(dispatch, team, offerId, csrfToken)),
    updateCalculated: (updated) => dispatch(actions.updateCalculated(updated)),
    updateModalUIState: (modalUIState) => dispatch(actions.updateModalUIState(modalUIState)),
    isCreatingTemplateChanged: (isCreatingTemplate) => dispatch(actions.isCreatingTemplateChanged(isCreatingTemplate)),
    submitBudgetTemplate: (csrfToken, team, offer, budget, budgetTemplate, additionalOfferTerms) => dispatch(actions.submitBudgetTemplate(dispatch, csrfToken, team, offer, budget, budgetTemplate, additionalOfferTerms)),
    useBudgetTemplateWithBudget: (csrfToken, team, budget, offer, budgetTemplateId) => dispatch(actions.useBudgetTemplateWithBudget(dispatch, csrfToken, team, budget, offer, budgetTemplateId)),
    budgetTemplateNameChanged: (name) => dispatch(actions.budgetTemplateNameChanged(name)),
    budgetTemplateChanged: (budgetTemplate) => dispatch(actions.budgetTemplateChanged(budgetTemplate)),
    offerToLinkChanged: (offer) => dispatch(actions.offerToLinkChanged(offer)),
    deleteBudgetTemplate: (csrfToken, team, budgetTemplateId) => dispatch(actions.deleteBudgetTemplate(dispatch, csrfToken, team, budgetTemplateId)),
    createBudgetBonus: (csrfToken, team, budget) => dispatch(actions.createBudgetBonus(dispatch, csrfToken, team, budget)),
    deleteBudgetBonus: (csrfToken, team, budgetBonus) => dispatch(actions.deleteBudgetBonus(dispatch, csrfToken, team, budgetBonus)),
    autoSaveBudgetBonus: (csrfToken, team, budgetBonus) => dispatch(actions.autoSaveBudgetBonus(dispatch, csrfToken, team, budgetBonus)),
    getActiveTags: (csrfToken, team, scope, taggable, onChange) => dispatch(tagManagerActions.getActiveTags(dispatch, csrfToken, team, scope, taggable, onChange)),
    getAvailableTags: (csrfToken, team, scope, onChange) => dispatch(tagManagerActions.getAvailableTags(dispatch, csrfToken, team, scope, onChange)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OfferEditUI);
