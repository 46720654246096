import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/offerV2SettlementStore';
import OfferV2SettlementContainer from '../containers/OfferV2SettlementContainer';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const OfferV2SettlementApp = (props) => (
  <Provider store={configureStore(props)}>
    <OfferV2SettlementContainer />
  </Provider>
);

export default OfferV2SettlementApp;
