import { combineReducers } from 'redux';
import {responsiveStateReducer} from 'redux-responsive'

const title = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const event = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ArtistEventLocalShowReducer = combineReducers({ title, event, browser: responsiveStateReducer });

export default ArtistEventLocalShowReducer;
