import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import CheckoutForm from './CheckoutForm';
import SecondColumn from './SecondColumn';

import Countdown from 'react-countdown';
import {Elements} from '@stripe/react-stripe-js';

const renderer = ({ minutes, seconds }) => {
  var formattedSeconds = seconds.toString();

  if(formattedSeconds.length === 1){
    formattedSeconds = ("0" + formattedSeconds);
  }

  return <span>{minutes}:{formattedSeconds}</span>;
};

const stripePaymentRequired = (ticketReservation) => {
  return ticketReservation.stripe_required;
}

// For 8 minute timer, 37.5% is 3 minutes left
const gettingCloseToExpiration = (checkoutTimePercentageRemaining) => {
  return checkoutTimePercentageRemaining < 37.5;
}

const stripeAppearance = {
  theme: 'stripe',

  variables: {
    fontFamily: 'Open Sans, system-ui, sans-serif',
    fontSizeBase: "14px",
    borderRadius: '8px',
    colorDanger: "#FF4C00"
  },

  rules: {
      ".Input": {
        boxShadow: "none",
        border: "1px solid #e6e6e6"
      }
    }
};

const gtagItems = (confirm, ticketTypes, promoCode, calculateDiscountedTicketTypePrice) => {
  var item = {
    id: confirm.id,
    name: confirm.title
  }

  return ticketTypes
    .filter((tt) => tt.quantity && tt.quantity > 0)
    .map((tt) => {
      var price = (Object.keys(promoCode).length > 0 ? (
          calculateDiscountedTicketTypePrice(tt, promoCode)
        ) : (
          parseFloat(tt.price)
        ));

      return Object.assign({}, item, {
        quantity: tt.quantity,
        price: price
      });
    });
}

const Checkout = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  promoCode,
  ticketReservation,
  discountedTicketTypePrice,
  deleteTicketReservation,
  stripePromise,
  ticketReservationChanged,
  stripeError,
  stripeErrorChanged,
  updateTicketReservation,
  rootUrl,
  checkoutTimePercentageRemaining,
  checkoutTimePercentageRemainingChanged,
  timeLimitReachedChanged,
  isPlacingOrder,
  isPlacingOrderChanged,
  addOns,
  gtag,
  calculateDiscountedTicketTypePrice,
  customFieldValues,
  customFieldValueChanged,
  postToParent,
  formatItemsForGoogleTagManager,
  seatsIOSelectedObjects,
  autoSaveTicketReservation,
  userContext,
  discountedAddOnPrice,
  saveGeoCodedStateCode,
  verticalInsureClientID,
  registerTicketInsurance,
  insuranceOfferState,
  insuranceOfferStateChanged,
  ticketInsurance,
  deleteTicketInsurance,
  hasInsuranceQuote,
  hasInsuranceQuoteChanged
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    gtag('event', 'begin_checkout', {
      "currency": "USD",
      "value": parseFloat(ticketReservation.total),
      "coupon": (Object.keys(promoCode).length > 0 ? promoCode.name : ""),
      "items": gtagItems(confirm, ticketTypes, promoCode, calculateDiscountedTicketTypePrice)
    });

    var postData = formatItemsForGoogleTagManager(
      confirm,
      promoCode,
      ticketTypes,
      addOns
    );

    postToParent("ga.begin_checkout", postData);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col">
          <p className="mb-3 mt-1 text-dark"
             style={{"fontSize": "24px"}}>
            <strong>Checkout</strong>
          </p>
          <div className="row" style={{"marginBottom": "26px"}}>
            <div className="col-xs-auto"
                 style={{"paddingLeft": "15px"}}>
              <i className="fas fa-exclamation-circle small"
                 style={{"color": "#f8cb58"}}></i>
            </div>
            <div className="col col-md-6 pl-2">
              <p className="mb-0 small"
                 style={{"color": "#686868"}}>
                 By clicking "Place Order", I accept the <a target="_blank" href="https://www.opendate.io/terms">Terms of Service</a> and have read the <a target="_blank" href="https://www.opendate.io/privacy">Privacy Policy</a>. I agree that Opendate may share my information with the event organizer.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xs-auto"
             style={{"paddingRight": "15px"}}>
          <div className="row mt-2">
            <div className="col small">
              <p className='mb-1'>
                <strong>
                  Time left {
                    <Countdown date={new Date(ticketReservation.expires_at)}
                               onTick={
                                 () => {
                                   var expiresAt = new Date(ticketReservation.expires_at);
                                   var startsAt = new Date(ticketReservation.checkout_starts_at);
                                   var currentTime = new Date();

                                   var totalTime = (expiresAt - startsAt);
                                   var remainingTime = (expiresAt - currentTime);

                                   var percentage = (remainingTime / totalTime) * 100;
                                   checkoutTimePercentageRemainingChanged(percentage);
                                 }
                               }
                               onComplete={
                                 () => {
                                   checkoutTimePercentageRemainingChanged(0);
                                   deleteTicketReservation(csrfToken, confirm, ticketReservation);
                                   timeLimitReachedChanged(true);
                                 }
                               }
                               renderer={renderer} />
                  }
                </strong>
              </p>
              <div className="progress"
                   style={{
                     "background": (gettingCloseToExpiration(checkoutTimePercentageRemaining) ? "#f9d8ce" : "#d9edb0"),
                     "height": "8px"
                   }}>
                <div className="progress-bar animate-change-to-width"
                     style={{
                       "background": (gettingCloseToExpiration(checkoutTimePercentageRemaining) ? "#ec4926" : "#98c648"),
                       "width": (checkoutTimePercentageRemaining + "%"),
                       "borderTopRightRadius": "8px",
                       "borderBottomRightRadius": "8px"
                     }}
                     role="progressbar"
                     aria-valuenow="0"
                     aria-valuemin="0"
                     aria-valuemax="100">
                </div>
              </div>
            </div>
            <div className="col-xs-auto"
                 style={{"paddingRight": "15px"}}>
              <i className="fas fa-clock mt-1"
                 style={{
                   "fontSize": "24px",
                   "color": (gettingCloseToExpiration(checkoutTimePercentageRemaining) ? "#ec4926" : "#98c648")
                 }}></i>
            </div>
          </div>
        </div>
      </div>
      <div className="row"
           style={{"marginTop": "13px"}}>
        <div className="col-12 col-md-6 col-lg-8 mb-3">
          {stripePaymentRequired(ticketReservation) ? (
            <Elements stripe={stripePromise}
                      options={{
                        clientSecret: ticketReservation.stripe_client_secret,
                        fonts: [{
                          cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans:400,600,700"
                        }],
                        appearance: stripeAppearance
                      }}>
              <CheckoutForm team={team}
                            csrfToken={csrfToken}
                            saveGeoCodedStateCode={saveGeoCodedStateCode}
                            confirm={confirm}
                            ticketReservation={ticketReservation}
                            deleteTicketReservation={deleteTicketReservation}
                            ticketReservationChanged={ticketReservationChanged}
                            stripeError={stripeError}
                            stripeErrorChanged={stripeErrorChanged}
                            updateTicketReservation={updateTicketReservation}
                            rootUrl={rootUrl}
                            ticketTypes={ticketTypes}
                            promoCode={promoCode}
                            discountedTicketTypePrice={discountedTicketTypePrice}
                            discountedAddOnPrice={discountedAddOnPrice}
                            isPlacingOrder={isPlacingOrder}
                            isPlacingOrderChanged={isPlacingOrderChanged}
                            stripePaymentRequired={stripePaymentRequired}
                            addOns={addOns}
                            customFieldValues={customFieldValues}
                            customFieldValueChanged={customFieldValueChanged}
                            postToParent={postToParent}
                            seatsIOSelectedObjects={seatsIOSelectedObjects}
                            autoSaveTicketReservation={autoSaveTicketReservation}
                            userContext={userContext}
                            verticalInsureClientID={verticalInsureClientID}
                            registerTicketInsurance={registerTicketInsurance}
                            insuranceOfferState={insuranceOfferState}
                            insuranceOfferStateChanged={insuranceOfferStateChanged}
                            ticketInsurance={ticketInsurance}
                            deleteTicketInsurance={deleteTicketInsurance}
                            hasInsuranceQuote={hasInsuranceQuote}
                            hasInsuranceQuoteChanged={hasInsuranceQuoteChanged}
                            stripePromise={stripePromise} />
            </Elements>
          ) : (
            <CheckoutForm team={team}
                          csrfToken={csrfToken}
                          saveGeoCodedStateCode={saveGeoCodedStateCode}
                          confirm={confirm}
                          ticketReservation={ticketReservation}
                          deleteTicketReservation={deleteTicketReservation}
                          ticketReservationChanged={ticketReservationChanged}
                          stripeError={stripeError}
                          stripeErrorChanged={stripeErrorChanged}
                          updateTicketReservation={updateTicketReservation}
                          rootUrl={rootUrl}
                          ticketTypes={ticketTypes}
                          promoCode={promoCode}
                          discountedTicketTypePrice={discountedTicketTypePrice}
                          discountedAddOnPrice={discountedAddOnPrice}
                          isPlacingOrder={isPlacingOrder}
                          isPlacingOrderChanged={isPlacingOrderChanged}
                          stripePaymentRequired={stripePaymentRequired}
                          addOns={addOns}
                          customFieldValues={customFieldValues}
                          customFieldValueChanged={customFieldValueChanged}
                          postToParent={postToParent}
                          seatsIOSelectedObjects={seatsIOSelectedObjects}
                          autoSaveTicketReservation={autoSaveTicketReservation}
                          userContext={userContext}
                          verticalInsureClientID={""}
                          registerTicketInsurance={registerTicketInsurance}
                          insuranceOfferState={insuranceOfferState}
                          insuranceOfferStateChanged={insuranceOfferStateChanged}
                          ticketInsurance={ticketInsurance}
                          deleteTicketInsurance={deleteTicketInsurance}
                          hasInsuranceQuote={hasInsuranceQuote}
                          hasInsuranceQuoteChanged={hasInsuranceQuoteChanged}
                          stripePromise={stripePromise} />
          )}
        </div>
        <div className="col-12 col-md-6 col-lg-4 mb-3 order-first order-md-last">
          <SecondColumn confirm={confirm}
                        ticketTypes={ticketTypes}
                        promoCode={promoCode}
                        discountedTicketTypePrice={discountedTicketTypePrice}
                        discountedAddOnPrice={discountedAddOnPrice}
                        addOns={addOns}
                        seatsIOSelectedObjects={seatsIOSelectedObjects}
                        ticketInsurance={ticketInsurance}
                        ticketReservation={ticketReservation} />
        </div>
      </div>
    </div>
  );
};

Checkout.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  ticketReservation: PropTypes.object,
  discountedTicketTypePrice: PropTypes.func.isRequired,
  deleteTicketReservation: PropTypes.func.isRequired,
  stripePromise: PropTypes.object,
  ticketReservationChanged: PropTypes.func.isRequired,
  stripeError: PropTypes.object,
  stripeErrorChanged: PropTypes.func.isRequired,
  updateTicketReservation: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  checkoutTimePercentageRemaining: PropTypes.number,
  checkoutTimePercentageRemainingChanged: PropTypes.func.isRequired,
  timeLimitReachedChanged: PropTypes.func.isRequired,
  isPlacingOrder: PropTypes.bool,
  isPlacingOrderChanged: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  gtag: PropTypes.func,
  calculateDiscountedTicketTypePrice: PropTypes.func.isRequired,
  customFieldValues: PropTypes.array,
  customFieldValueChanged: PropTypes.func.isRequired,
  postToParent: PropTypes.func.isRequired,
  formatItemsForGoogleTagManager: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  autoSaveTicketReservation: PropTypes.func.isRequired,
  userContext: PropTypes.object,
  discountedAddOnPrice: PropTypes.func.isRequired,
  saveGeoCodedStateCode: PropTypes.func.isRequired,
  verticalInsureClientID: PropTypes.string,
  registerTicketInsurance: PropTypes.func.isRequired,
  insuranceOfferState: PropTypes.string,
  insuranceOfferStateChanged: PropTypes.func.isRequired,
  ticketInsurance: PropTypes.object,
  deleteTicketInsurance: PropTypes.func.isRequired,
  hasInsuranceQuote: PropTypes.bool,
  hasInsuranceQuoteChanged: PropTypes.func.isRequired
};

export default Checkout;
