// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OutreachModal from '../components/OutreachModal';
import * as actions from '../../OutreachModal/actions/outreachModalActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  venueOwnerships: state.venueOwnerships,
  outreach: state.outreach,
  outreachTemplates: state.outreachTemplates,
  outreachTemplateName: state.outreachTemplateName,
  canChangeArtist: state.canChangeArtist,
  artist: state.artist,
  selectedOutreachTemplate: state.selectedOutreachTemplate,
  agentPreviews: state.agentPreviews,
  onOutreachCreate: state.onOutreachCreate,
  onClose: state.onClose
});

const mapDispatchToProps = (dispatch) => {
  return {
    outreachChanged: (outreach) => dispatch(actions.outreachChanged(outreach)),
    fetchAgentPreviews: (team, to) => dispatch(actions.fetchAgentPreviews(dispatch, team, to)),
    outreachBodyChanged: (editorState) => dispatch(actions.outreachBodyChanged(editorState)),
    outreachToChanged: (to) => dispatch(actions.outreachToChanged(to)),
    outreachCcChanged: (cc) => dispatch(actions.outreachCcChanged(cc)),
    outreachBccChanged: (bcc) => dispatch(actions.outreachBccChanged(bcc)),
    outreachSubjectChanged: (subject) => dispatch(actions.outreachSubjectChanged(subject)),
    outreachModalClosed: () => dispatch(actions.outreachModalClosed()),
    selectedOutreachTemplateChanged: (outreachTemplate) => dispatch(actions.selectedOutreachTemplateChanged(outreachTemplate)),
    outreachTemplateNameChanged: (name) => dispatch(actions.outreachTemplateNameChanged(name)),
    fetchOutreachTemplate: (team, outreachTemplate) => dispatch(actions.fetchOutreachTemplate(dispatch, team, outreachTemplate)),
    createOutreachTemplate: (team, outreach, outreachTemplateName, csrfToken) => dispatch(actions.createOutreachTemplate(dispatch, team, outreach, outreachTemplateName, csrfToken)),
    submitOutreachForm: (csrfToken, team, outreach, onCreate) => dispatch(actions.submitOutreachForm(dispatch, csrfToken, team, outreach, onCreate)),
    outreachVenueOwnershipIdChanged: (venueOwnership, artist) => dispatch(actions.outreachVenueOwnershipIdChanged(venueOwnership, artist)),
    addAgentEmailToTos: (agent) => dispatch(actions.addAgentEmailToTos(agent)),
    removeAgentEmailFromTos: (agent) => dispatch(actions.removeAgentEmailFromTos(agent)),
    outreachArtistChanged: (artist, venueOwnership) => dispatch(actions.outreachArtistChanged(artist, venueOwnership)),
    outreachStateChanged: (state) => dispatch(actions.outreachStateChanged(state))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OutreachModal);
