import React from "react";

import { toastSuccess } from "../../../../shared/toastHelper";

const configurationTextStyle = {
  color: "rgba(51, 51, 51, 0.5)",
  display: "flex",
  flexDirection: "column",
  gap: 5,
  width: "100%",
};

const dnsRecordGridStyle = {
  color: "#333333",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
};

const dnsRecordLabelStyle = {
  padding: 5,
};

const dnsRecordRowStyle = {
  display: "contents",
};

const dnsRecordValueStyle = {
  border: "0.5px solid #CCCCCC",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  padding: 5,
  backgroundColor: "white",
  cursor: "pointer",
};

const checkIconStyle = {
  color: "#8AC926",
  fontSize: 16,
  marginRight: 10,
};

const xmarkIconStyle = {
  color: "#FF4B4B",
  fontSize: 16,
  marginRight: 10,
};

const UnverifiedInstructions = ({
  sendDomain
}) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toastSuccess("Copied to clipboard");
  };

  return (
    <div style={configurationTextStyle}>
      <b>Configuration:</b>
      <div>To authenticate your domain with Opendate, you need to configure specific DNS records. Follow these steps:</div>
      <div>Log in to your domain registrar's website (e.g., GoDaddy, Namecheap, Google Domains).</div>
      <div>Navigate to the DNS settings or DNS management section for your sendDomain.</div>
      <div>You need to add the following DNS records. For each record, you will have details like record_type, host, and data.</div>
      <div style={dnsRecordGridStyle}>
        <b style={dnsRecordLabelStyle}>Record Type</b>
        <b style={dnsRecordLabelStyle}>Host</b>
        <b style={dnsRecordLabelStyle}>Data</b>
        {sendDomain?.send_domain_dns_records.map((dns_record, index) => (
          <div key={index} style={dnsRecordRowStyle}>
            <div style={dnsRecordValueStyle}>
              {dns_record.verified ? (
                <i className="far fa-circle-check" style={checkIconStyle} />
              ) : (
                <i className="far fa-circle-xmark" style={xmarkIconStyle} />
              )}
              CNAME
            </div>
            <div style={dnsRecordValueStyle} onClick={() => copyToClipboard(dns_record.host)}>
              {dns_record.host}
            </div>
            <div style={dnsRecordValueStyle} onClick={() => copyToClipboard(dns_record.data)}>
              {dns_record.data}
            </div>
          </div>
        ))}
      </div>
      <b>Verification</b>
      <div>DNS changes can take some time to propagate. It may take anywhere from a few minutes to 48 hours.</div>
      <div>Once the records have propagated, complete the verification process by clicking the "Verify Domain" button above.</div>
    </div>
  );
};

export default UnverifiedInstructions;
