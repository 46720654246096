import React from 'react';
import PropTypes from 'prop-types';

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import Flatpickr from 'react-flatpickr';

const DepositManager = ({
  csrfToken,
  team,
  budget,
  updateBudget,
  currentUser,
  autoSaveOffer,
  debouncedAutoSaveBudget,
}) => {
  return (
    <div>
      {budget.deposits.filter(deposit => !deposit._destroy).map((deposit, index) => (
        <div key={deposit.id} className="mb-2 d-flex flex-wrap align-items-end">
          <div className="mr-4 text-muted">
            <span>Type</span>
            <div className="col-xs-auto">
              <div className="mt-2 btn-group btn-group-sm rounded"
                  style={{
                    "border": "1px solid #1982C4",
                    "background": "#1982C4"
                  }}
                  role="group">
                <button type="button"
                  className={`btn strong ${deposit.deposit_type === "percent" ? "btn-primary" : "btn-external-inverse"}`}
                  style={{
                    "boxShadow": "none",
                    "borderTopLeftRadius": "8px",
                    "borderBottomLeftRadius": "8px"
                  }}
                  onClick={e => {
                    e.preventDefault();

                    var updated = {
                      ...budget,
                      deposits:  budget.deposits.map(d => {
                        if (d.id === deposit.id) {
                          return {
                            ...deposit,
                            deposit_type: "percent",
                            value: "0"
                          };
                        } else {
                          return d;
                        }
                      })
                    };

                    updateBudget(updated);
                    debouncedAutoSaveBudget(csrfToken, team, updated);
                  }}
                >
                  Percent
                </button>
                <button
                  type="button"
                  className={`btn strong ${deposit.deposit_type === "flat" ? "btn-primary" : "btn-external-inverse"}`}
                  style={{
                    "boxShadow": "none",
                    "borderTopRightRadius": "8px",
                    "borderBottomRightRadius": "8px"
                  }}
                  onClick={e => {
                    e.preventDefault();

                    var updated = {
                      ...budget,
                      deposits:  budget.deposits.map(d => {
                        if (d.id === deposit.id) {
                          return {
                            ...deposit,
                            deposit_type: "flat",
                            value: "0.00"
                          };
                        } else {
                          return d;
                        }
                      })
                    };

                    updateBudget(updated);
                    debouncedAutoSaveBudget(csrfToken, team, updated);
                  }}
                >
                  Flat
                </button>
              </div>
            </div>
          </div>
          <div className="mr-4 text-muted">
            <span>{deposit.deposit_type === "percent" ? "Percent" : "Flat"}</span>
            <div
              className="mt-2 d-flex align-items-center rounded"
              style={{"border": "1px solid #e6e6e6"}}
            >
              {deposit.deposit_type === "percent" ? (
                <i className="mx-2 fas fa-percent text-muted" style={{"width": "20px", "fontSize":"18px"}}></i>
              ) : (
                <i className="mx-2 fas fa-dollar text-muted" style={{"width": "20px", "fontSize":"18px"}}></i>
              )}
              <CurrencyInput
                className="form-control form-control-sm pr-2 d-block text-right"
                placeholder={deposit.deposit_type === "flat" ? "0.00" : "0"}
                style={{"width": "120px", "border": "none"}}
                maskOptions={{
                  prefix: "",
                  integerLimit: null,
                  allowDecimal: (deposit.deposit_type === "flat"),
                  decimalLimit: deposit.deposit_type === "flat" ? 2 : null
                }}
                value={deposit.deposit_type === "flat" ? (
                  deposit.value === "0.0" ? "0.00" : deposit.value || "0.00"
                ) : (
                  parseInt(deposit.value) || "0"
                )}
                onChange={e => {
                  e.preventDefault();

                  var updated = {
                    ...budget,
                    deposits:  budget.deposits.map(d => {
                      if (d.id === deposit.id) {
                        return {
                          ...deposit,
                          value: e.target.value,
                        };
                      } else {
                        return d;
                      }
                    })
                  };

                  updateBudget(updated);
                  debouncedAutoSaveBudget(csrfToken, team, updated);
                }}
              />
            </div>
          </div>
          <div className="mr-4 text-muted">
            <span>Due Date</span>
            <div className="col-xs-auto">
              <div className="mt-2 btn-group btn-group-sm"
                  style={{
                    "border": "1px solid #1982C4",
                    "borderRadius": "9px",
                    "background": "#1982C4"
                  }}
                  role="group">
                <button type="button"
                  className={`btn strong ${deposit.due_date_type === "relative" ? "btn-primary" : "btn-external-inverse"}`}
                  style={{
                    "boxShadow": "none",
                    "borderTopLeftRadius": "8px",
                    "borderBottomLeftRadius": "8px"
                  }}
                  onClick={e => {
                    e.preventDefault();

                    var updated = {
                      ...budget,
                      deposits:  budget.deposits.map(d => {
                        if (d.id === deposit.id) {
                          return {
                            ...deposit,
                            due_date_type: "relative",
                            specific_due_date: null,
                          }
                        } else {
                          return d;
                        }
                      })
                    };

                    updateBudget(updated);
                    debouncedAutoSaveBudget(csrfToken, team, updated);
                  }}
                >
                  Relative
                </button>
                <button
                  type="button"
                  className={`btn strong ${deposit.due_date_type === "specific" ? "btn-primary" : "btn-external-inverse"}`}
                  style={{
                    "boxShadow": "none",
                    "borderTopRightRadius": "8px",
                    "borderBottomRightRadius": "8px"
                  }}
                  onClick={e => {
                    e.preventDefault();

                    var updated = {
                      ...budget,
                      deposits:  budget.deposits.map(d => {
                        if (d.id === deposit.id) {
                          return {
                            ...deposit,
                            due_date_type: "specific",
                            relative_due_date: 0,
                            specific_due_date: new Date(),
                          };
                        } else {
                          return d;
                        }
                      })
                    };

                    updateBudget(updated);
                    debouncedAutoSaveBudget(csrfToken, team, updated);
                  }}
                >
                  Specific
                </button>
              </div>
            </div>
          </div>
          <div className="mr-4 text-muted">
            <span>{deposit.due_date_type === "specific" ? "Date" : "Days before event"}</span>
            {deposit.due_date_type === "specific" ? (
              <div
                className="mt-2 flatpickr-date-input d-flex align-items-center rounded"
                style={{"height": "35px", "border": "1px solid #e6e6e6"}}
              >
                <div className="px-2" style={{"width": "30px", "fontSize": "18px"}}>
                  <i className="far fa-calendar"></i>
                </div>
                <Flatpickr
                  options={{
                    dateFormat: "F j, Y",
                  }}
                  placeholder="Select date"
                  className="border-0 rounded"
                  style={{"width": "150px"}}
                  value={Date.parse(deposit.specific_due_date)}
                  onChange={dates => {
                    var updated = {
                      ...budget,
                      deposits:  budget.deposits.map(d => {
                        if (d.id === deposit.id) {
                          return {
                            ...deposit,
                            specific_due_date: dates[0]
                          };
                        } else {
                          return d;
                        }
                      })
                    }

                    updateBudget(updated);
                    debouncedAutoSaveBudget(csrfToken, team, updated);
                  }}
                />
              </div>
            ) : (
              <input
                type="number"
                style={{"width": "142px", "height": "35px", "border": "1px solid #e6e6e6"}}
                className="mt-2 form-control form-control-sm rounded"
                placeholder="0"
                value={deposit.relative_due_date}
                onChange={e => {
                  e.preventDefault();

                  var updated = {
                    ...budget,
                    deposits:  budget.deposits.map(d => {
                      if (d.id === deposit.id) {
                        return {
                          ...deposit,
                          relative_due_date: e.target.value,
                        }
                      } else {
                        return d;
                      }
                    })
                  };

                  updateBudget(updated);
                  debouncedAutoSaveBudget(csrfToken, team, updated);
                }}
              />
            )}
          </div>
          <div className="d-flex align-items-center" style={{"height": "35px"}}>
            <a
              href=""
              className="text-muted"
              onClick={e => {
                e.preventDefault();

                var updated = {
                  ...budget,
                  deposits:  budget.deposits.map(d => {
                    if (d.id === deposit.id) {
                      return {
                        ...deposit,
                        _destroy: true
                      }
                    } else {
                      return d;
                    }
                  })
                }

                updateBudget(updated);
                debouncedAutoSaveBudget(csrfToken, team, updated);
              }}
            >
              <i className="fas fa-trash"></i>
            </a>
          </div>
        </div>
      ))}
      <div className="mt-3">
        <a
          href=""
          className="font-weight-bold"
          onClick={e => {
            e.preventDefault();

            var updated = {
              ...budget,
              deposits: [
                ...budget.deposits,
                {
                  id: `new-${budget.deposits.length}`,
                  deposit_type: "flat",
                  value: "0.00",
                  due_date_type: "relative",
                  relative_due_date: 0,
                  specific_due_date: null,
                }
              ]
            }

            updateBudget(updated);
            debouncedAutoSaveBudget(csrfToken, team, updated);
          }}
        >
          <i className="pr-2 fas fa-circle-plus"></i>
          Add Deposit
        </a>
      </div>
    </div>
  );
};

DepositManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  budget: PropTypes.object.isRequired,
  updateBudget: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  debouncedAutoSaveBudget: PropTypes.func,
};

export default DepositManager;
