import PropTypes from 'prop-types';
import React from 'react';

import Tippy from '@tippyjs/react';

const TopFans = ({
  csrfToken,
  team,
  currentUser,
  topFans
}) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col d-flex align-items-center">
          <p className="mb-0"
             style={{"fontSize": "20px"}}>
            <strong>
              Top Fans
            </strong>
            <Tippy content="Your most engaged fans based on number of events.">
              <i className="fas fa-info-circle text-muted ml-2"
                 style={{"fontSize": "16px"}}></i>
            </Tippy>
          </p>
        </div>
        {/*
        <div className="col-xs-auto d-flex align-items-center justify-content-end"
             style={{"paddingRight": "15px"}}>
          <a href="#"
             style={{"fontSize": "14px"}}
             onClick={(e) => e.preventDefault()}>
            See all
            <i className="far fa-chevron-right ml-2"></i>
          </a>
        </div>
        */}
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table vertical-middle table-borderless table-sm"
                 style={{"marginTop": "15px", "tableLayout": "fixed"}}>
            <colgroup>
              <col span={1} style={{"width": "15%"}} />
              <col span={1} style={{"width": "55%"}} />
              <col span={1} style={{"width": "25%"}} />
              <col span={1} style={{"width": "5%"}} />
            </colgroup>
            <tbody>
              {topFans.map((fan, index) =>
                <tr key={index}>
                  <td style={{"paddingLeft": "0px"}}>
                    <img src={fan.avatar_thumb_url}
                         alt={fan.name_or_email}
                         className="rounded-circle"
                         width="40"
                         height="40" />
                  </td>
                  <td>
                    {fan.name && fan.name.length > 0 ? (
                      <React.Fragment>
                        <p className="mb-0 small text-truncate"
                           title={fan.name}>
                          <a className="text-dark"
                             href={"/teams/" + team.id + "/fans/" + fan.id}>
                            <strong>{fan.name}</strong>
                          </a>
                        </p>
                        <p className="mb-0 small text-truncate"
                           title={fan.email}>
                          {fan.email}
                        </p>
                      </React.Fragment>
                    ) : (
                      <p className="mb-0 small text-truncate"
                         title={fan.email}>
                        <a className="text-dark"
                           href={"/teams/" + team.id + "/fans/" + fan.id}>
                          <strong>{fan.email}</strong>
                        </a>
                      </p>
                    )}
                  </td>
                  <td className="text-center">
                    <p className="mb-0"
                       style={{"fontSize": "20px"}}>
                      <strong>
                        {fan.calendar_events_count.toLocaleString()}
                      </strong>
                    </p>
                    <p className="mb-0 text-muted"
                       style={{
                         "fontSize": "10px",
                         "marginTop": "-6px"
                       }}>
                      EVENTS
                    </p>
                  </td>
                  <td style={{"paddingRight": "0px"}}>
                    {index === 0 ? (
                      <i className="fad fa-trophy"
                         style={{"color": "#f6c042"}}></i>
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

TopFans.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  topFans: PropTypes.array
};

export default TopFans;
