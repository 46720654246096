import React, { useRef } from "react";

import { htmlContentStyle } from "./Actions/EmailPreviewTab";
import GoToEditorButton from "./GoToEditorButton";
import PillContainer from "./PillContainer";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const headerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const subjectLineStyle = {
  fontSize: 12,
};

const boxStyle = {
  border: "1px solid #E6E6E6",
  borderRadius: 5,
  height: "50vh",
  padding: 15,
  overflow: "scroll",
};

const EmailPreview = ({
  action,
}) => {
  const iframeRef = useRef(null);

  const disableLinks = () => {
    const iframe = iframeRef.current;

    if (iframe && iframe.contentDocument) {
      const iframeLinks = iframe.contentDocument.querySelectorAll("a");

      iframeLinks.forEach(link => {
        link.setAttribute("disabled", true);
        link.setAttribute("aria-disabled", true);
        link.style.pointerEvents = "none";
      });
    }
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <PillContainer
          label={"Email 1"}
          background={"#1A82C4"}
        />
        <GoToEditorButton
          action={action}
        />
      </div>
      <div style={subjectLineStyle}>
        <b>Subject: </b>{action.subject}
      </div>
      <div style={boxStyle}>
        <iframe
          ref={iframeRef}
          srcDoc={action.renderable_body}
          style={{
            ...htmlContentStyle,
          }}
          onLoad={disableLinks}
        />
      </div>
    </div>
  );
};

export default EmailPreview;
