import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

import confirmShowReducer from '../reducers/confirmShowReducer';
import eventFormReducer from '../../EventForm/reducers/eventFormReducer';

const rootReducer = combineReducers({
  confirmShow: confirmShowReducer,
  eventForm: eventFormReducer
});

const configureStore = (railsProps) => (
  createStore(rootReducer, railsProps, compose(
    applyMiddleware(createDebounce(), thunk)
  ))
);

export default configureStore;
