import PropTypes from 'prop-types';
import React from 'react';

var _ = require('lodash');
import AsyncSelect from 'react-select/async';
const axios = require('axios').default;
import Popup from "reactjs-popup";

import { ToastContainer, toast } from 'react-toastify';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import {DebounceInput} from 'react-debounce-input';
import MaskedInput from 'react-text-mask'

var emoji = require('markdown-it-emoji');
var taskLists = require('markdown-it-task-lists');

const mdParser = require('markdown-it')()
  .use(require('markdown-it-mark'))
  .use(require('markdown-it-ins'))
  .use(require('markdown-it-abbr'))
  .use(require('markdown-it-deflist'))
  .use(require('markdown-it-footnote'))
  .use(require('markdown-it-sub'))
  .use(require('markdown-it-sup'))
  .use(taskLists)
  .use(emoji);

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "350px",
  "padding": "30px 15px 20px 15px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "195px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

export const uploadImageEmbed = (csrfToken, team, file, callback) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  var params = new FormData();
  params.append("image_embed[image]", file);

  return axios.post("/teams/" + team.id + "/image_embeds", params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(({ data }) => {
      callback(data.url);
    });
};

const canSubmitVenueSuggestionForm = (venueSuggestion) => {
  return (
    venueSuggestion.name
      && venueSuggestion.address1
      && venueSuggestion.city
      && venueSuggestion.state
      && venueSuggestion.zip
  );
}

const canSubmitVenueOwnershipForm = (venueOwnership) => {
  return (
    venueOwnership
      && isValidPhoneNumber(venueOwnership.phone)
      && isValidEmail(venueOwnership.email)
  );
}

const isValidPhoneNumber = (value) => {
  var stringValue = (value || "");
  var nonDigit = /\D/ig;
  var phoneNumber = stringValue.replaceAll(nonDigit, "");

  return (
    phoneNumber.length === 0
      || phoneNumber.length === 10
  );
}

const isValidEmail = (value) => {
  return (
    !value
      || (
        value.length > 0
          && validateEmail(value)
      )
  );
}

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const debouncedFetch = _.debounce((inputValue, callback, venueOwnerships) => {
  var omitVenueIds = venueOwnerships.filter((venueOwnership) => {
    return venueOwnership.venueable_type === "Venue";
  }).map((venueOwnership) => {
    return venueOwnership.venueable.id;
  });

  new Promise(resolve => {
    axios.get("/venues/search", {
        params: {
          name: inputValue,
          omit_ids: omitVenueIds
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data) {
          var cityState = [data.city, data.state].filter(item => item).join(', ');

          var capacityString = (data.capacity && data.capacity !== 0) ? ` (Cap: ${data.capacity})` : '';

          return { value: data.id, label: `${data.name} - ${cityState}${capacityString}` };
        });

        callback(newOptions);
      })
  });
}, 250);

const VenueManager = ({
  csrfToken,
  team,
  venueOwnerships,
  deleteVenueOwnership,
  createVenueOwnership,
  settingUpTeam,
  venueSuggestionFormOpen,
  manualVenueEntryClicked,
  venueSuggestion,
  venueSuggestionNameChanged,
  venueSuggestionAddressOneChanged,
  venueSuggestionAddressTwoChanged,
  venueSuggestionCityChanged,
  venueSuggestionStateChanged,
  venueSuggestionZipChanged,
  venueStateOptions,
  createVenueSuggestion,
  modalIsOpen,
  closeModal,
  cancelEditVenueOwnership,
  venueOwnershipBioChanged,
  venueOwnershipLogoElChanged,
  venueOwnershipLogoChanged,
  venueOwnershipTicketLogoElChanged,
  venueOwnershipTicketLogoChanged,
  fetchVenueOwnership,
  venueOwnershipLogoRemoved,
  venueOwnershipTicketLogoRemoved,
  updateVenueOwnership,
  venueOwnershipChanged,
  availableTimezones
}) => (
  <div className="row">
    <ToastContainer />
    <div className="col-12">
      <div className="row">
        <div className="col-12 col-md-8">
          <AsyncSelect placeholder={"Search venues..."}
                       defaultOptions
                       value={""}
                       onChange={(option) => { createVenueOwnership(csrfToken, team, option.value, "Venue"); }}
                       loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback, venueOwnerships)} />
        </div>
        <div className="col-12">
          <p className="text-muted pt-2 mb-0">
            Can't find your venue?&nbsp;
            <strong>
              <a href="#"
                 className="text-muted"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     manualVenueEntryClicked();
                   }
                 }
                 style={{"textDecoration": "underline"}}>
                Enter your details manually.*
              </a>
            </strong>
          </p>
        </div>
        {venueSuggestionFormOpen ? (
          <div className="col-12 col-md-8 pt-3">
            <div className="card venue-card no-border mb-0">
              <div className="card-body">
                <form onSubmit={
                  (e) => {
                    if(canSubmitVenueSuggestionForm(venueSuggestion)){
                      createVenueSuggestion(csrfToken, team, venueSuggestion);
                    }

                    e.preventDefault();
                  }
                }>
                  {venueSuggestion.errors.length > 0 ? (
                    <div className="form-group">
                      <div className="p-3 mb-2 bg-danger text-white rounded">
                        <ul className="mb-0">
                          {venueSuggestion.errors.map((error, key) =>
                            <li key={key}>{error}</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                  <div className="form-group">
                    <input type="text"
                           className="form-control no-border text-muted"
                           defaultValue={venueSuggestion.name}
                           onChange={(e) => venueSuggestionNameChanged(e.target.value)}
                           placeholder="Venue Name" />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12 col-md-6">
                      <input type="text"
                             className="form-control no-border text-muted"
                             defaultValue={venueSuggestion.address1}
                             onChange={(e) => venueSuggestionAddressOneChanged(e.target.value)}
                             placeholder="Address Line 1" />
                    </div>
                    <div className="form-group col-12 col-md-6">
                      <input type="text"
                             className="form-control no-border text-muted"
                             defaultValue={venueSuggestion.address2}
                             onChange={(e) => venueSuggestionAddressTwoChanged(e.target.value)}
                             placeholder="Address Line 2" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12 col-md-6">
                      <input type="text"
                             className="form-control no-border text-muted"
                             defaultValue={venueSuggestion.city}
                             onChange={(e) => venueSuggestionCityChanged(e.target.value)}
                             placeholder="City" />
                    </div>
                    <div className="form-group col-12 col-md-3">
                      <select className="form-control no-border text-muted custom-select"
                             defaultValue={""}
                             onChange={(e) => venueSuggestionStateChanged(e.target.value)} >
                        <option value="">State</option>
                        {venueStateOptions.map((option, index) => (
                          <option value={option}
                                  key={index}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-12 col-md-3">
                      <input type="text"
                             className="form-control no-border text-muted"
                             defaultValue={venueSuggestion.zip}
                             onChange={(e) => venueSuggestionZipChanged(e.target.value)}
                             placeholder="Zip" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col text-right">
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             manualVenueEntryClicked();
                           }
                         }
                         className="pr-4">
                        <strong>Cancel</strong>
                      </a>
                      <button type="submit"
                              disabled={!canSubmitVenueSuggestionForm(venueSuggestion)}
                              className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {settingUpTeam ? (
        <div className="py-3" />
      ) : (
        <hr className="my-3" />
      )}
      <div className="row">
        {venueOwnerships.sort((a, b) => a.venueable.name.localeCompare(b.venueable.name)).map((venueOwnership, index) => (
          <div key={index} className={venueOwnership.isEditing ? "col-12" : "col-12 col-md-6 col-lg-4"}>
            <div className="card venue-card no-border py-3 pl-4 pr-2">
              <div className='row'>
                <div className='col-12'>
                  <p className="venue-name">
                    <strong>{venueOwnership.venueable.name}</strong>
                  </p>
                  <Popup arrow={false}
                         offsetY={5}
                         position="bottom right"
                         contentStyle={popupContentStyle}
                         onOpen={
                           (e) => {
                             e.stopPropagation();
                             e.preventDefault();
                           }
                         }
                         trigger={open => (
                           <a href="#"
                              style={
                                {
                                  "top": "-5px",
                                  "right": "15px",
                                  "position": "absolute"
                                }
                              }
                              className="btn btn-external btn-sm">
                             <i className="far fa-ellipsis-v"></i>
                           </a>
                         )} >
                    {close => (
                      <div className='row text-left'>
                        <div className="col-12">
                          <ul className="list-group offer-inline-menu">
                            <li className="list-group-item">
                              <a href="#"
                                 onClick={
                                   (e) => {
                                     e.preventDefault();
                                     close();
                                     fetchVenueOwnership(csrfToken, team, venueOwnership.id);
                                   }
                                 }
                                 className="text-muted">
                                <span className="d-inline-block text-center" style={{width: "28px"}}>
                                  <i className="fas fa-pencil"></i>
                                </span>
                                <span className="text-dark">
                                  Edit Venue Profile
                                </span>
                              </a>
                            </li>
                            <li className="list-group-item delete-offer-li">
                              <Popup
                                  trigger={
                                    <a href="#" className="text-danger">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-times-circle"></i>
                                      </span>
                                      Remove
                                    </a>
                                  }
                                  modal
                                  onOpen={
                                    (e) => {
                                      e.preventDefault();
                                    }
                                  }
                                  contentStyle={deleteModalContentStyle}
                                  closeOnDocumentClick
                                >
                                {closeDeletePopup => (
                                  <div>
                                    <div className="row">
                                      <div className="col">
                                        <div className="row">
                                          <div className="col">
                                            <h5 style={{"marginBottom": "16px"}}>
                                              <strong>Delete venue?</strong>
                                            </h5>
                                          </div>
                                          <div className="col-xs-auto">
                                            <a href="#"
                                               className="text-dark"
                                               style={{"paddingRight": "15px"}}
                                               onClick={(e) => {e.preventDefault(); close();}} >
                                              <i className="fa fa-times"></i>
                                            </a>
                                          </div>
                                        </div>
                                        <p>{"Remove " + venueOwnership.venueable.name + " from your Opendate account?"}</p>
                                        <div className="text-right">
                                          <a href="#"
                                             onClick={
                                               (e) => {
                                                 e.preventDefault();
                                                 close();
                                               }
                                             }>
                                            Cancel
                                          </a>
                                          <a href="#"
                                             className="btn btn-danger ml-3"
                                             onClick={
                                               (e) => {
                                                 e.preventDefault();
                                                 close();
                                                 closeDeletePopup();
                                                 deleteVenueOwnership(csrfToken, team, venueOwnership);
                                               }
                                             }>
                                            Remove
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
              <span>
                {venueOwnership.venueable.address1}
                {(venueOwnership.venueable.address2 && venueOwnership.venueable.address2.length > 0) ? (
                  <span>, {venueOwnership.venueable.address2}</span>
                ) : null}
              </span>
              <span>{venueOwnership.venueable.city}, {venueOwnership.venueable.state_code} {venueOwnership.venueable.zip}</span>
              {venueOwnership.isEditing ? (
                <div className="row pt-4">
                  <div className="col-12 col-lg-10">
                    <form onSubmit={
                      (e) => {
                        e.preventDefault();

                        if(!canSubmitVenueOwnershipForm(venueOwnership)){
                          return false;
                        }

                        updateVenueOwnership(csrfToken, team, venueOwnership);
                      }
                    }>
                      <div className="form-row">
                        <div className='col-12 col-sm-6'>
                          <div className="form-group">
                            <label htmlFor={"venue-time-zone-" + index}>
                              <strong>Venue Timezone</strong>
                            </label>
                            <select className="custom-select form-control"
                                    id={"venue-time-zone-" + index}
                                    value={venueOwnership.time_zone || ""}
                                    onChange={
                                      (e) => {
                                        var updated = Object.assign({}, venueOwnership, {
                                          time_zone: e.target.value
                                        });

                                        venueOwnershipChanged(updated);
                                      }
                                    }
                                    style={{
                                      "fontSize": "14px",
                                      "border": "1px solid #e7e7e7"
                                    }}>
                              { availableTimezones.map(timezone => (
                                <option value={timezone} key={timezone}>{timezone}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className='col-12 col-sm-6'>
                          <div className="form-group">
                            <label htmlFor={"venue-phone-" + index}>
                              <strong>Venue Phone Number</strong>
                            </label>
                            <MaskedInput className="form-control"
                                         id={"venue-phone-" + index}
                                         placeholder="(317) 867-5309"
                                         style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                                         guide={false}
                                         onChange={
                                           (e) => {
                                             var updated = Object.assign({}, venueOwnership, {
                                               phone: e.target.value
                                             });

                                             venueOwnershipChanged(updated);
                                           }
                                         }
                                         value={venueOwnership.phone || ""}
                                         mask={['(',/[1-9]/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/]} />
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className='col-12 col-sm-6'>
                          <div className="form-group">
                            <label htmlFor={"venue-email-" + index}>
                              <strong>Venue Email</strong>
                            </label>
                            <input type="email"
                                   className="form-control"
                                   placeholder="support@venue.com"
                                   id={"venue-email-" + index}
                                   style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                                   onChange={
                                     (e) => {
                                       var updated = Object.assign({}, venueOwnership, {
                                         email: e.target.value
                                       });

                                       venueOwnershipChanged(updated);
                                     }
                                   }
                                   value={venueOwnership.email || ""} />
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className='col-12 col-lg-6'>
                          <label htmlFor={"venue-bio-" + index}>
                            <strong>Venue Logo</strong>
                          </label>
                          <div className="row">
                            <div className="col-xs-auto" style={{"paddingLeft": "15px"}}>
                              <input
                                ref={el => {
                                  if(el && !venueOwnership.logoEl){
                                    venueOwnershipLogoElChanged(index, el);
                                  }
                                }}
                                onChange={
                                  (e) => {
                                    var file = e.target.files[0];
                                    venueOwnershipLogoChanged(index, file);

                                    e.target.value = null;
                                  }
                                }
                                accept="image/*"
                                type="file"
                                style={{ display: "none" }} />
                              <div style={
                                     {
                                       "width": "80px",
                                       "height": "80px",
                                       "overflow": "hidden"
                                     }
                                   }
                                   className="rounded-circle ml-1">
                                <img src={
                                       venueOwnership.logo ? (
                                         URL.createObjectURL(venueOwnership.logo)
                                       ) : venueOwnership.logo_thumb_url ? (
                                         venueOwnership.logo_thumb_url
                                       ) : (
                                         "/venue-logoplaceholder.png"
                                       )
                                     }
                                     style={{"height": "100%"}}
                                     className="img-fluid" />
                              </div>
                            </div>
                            <div className="col">
                              <p className="mb-0 text-muted small">
                                Images must be at least 200 x 200px
                              </p>
                              <a href="#"
                                 style={{"borderRadius": "8px"}}
                                 onClick={
                                   (e) => {
                                     e.preventDefault();
                                     venueOwnership.logoEl.click();
                                   }
                                 }
                                 className="btn btn-primary btn-sm mt-2">
                                Choose Photo
                              </a>
                              {(venueOwnership.logo || venueOwnership.logo_thumb_url) ? (
                                <p className="mb-0 pt-2">
                                  <a href="#"
                                     onClick={
                                       (e) => {
                                         e.preventDefault();
                                         venueOwnershipLogoRemoved(index);
                                       }
                                     }
                                     className="text-red mt-2 small">
                                    Remove
                                  </a>
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className='mt-4 col-12 col-lg-6'>
                          <label htmlFor={"venue-bio-" + index}>
                            <strong>Ticket Logo</strong>
                          </label>
                          <div className="row">
                            <div className="col-xs-auto" style={{"paddingLeft": "15px"}}>
                              <input
                                ref={el => {
                                  if(el && !venueOwnership.ticketLogoEl){
                                    venueOwnershipTicketLogoElChanged(index, el);
                                  }
                                }}
                                onChange={
                                  (e) => {
                                    var file = e.target.files[0];
                                    venueOwnershipTicketLogoChanged(index, file);

                                    e.target.value = null;
                                  }
                                }
                                accept="image/*"
                                type="file"
                                style={{ display: "none" }} />
                              <div style={
                                     {
                                       "width": "80px",
                                       "height": "80px",
                                       "overflow": "hidden"
                                     }
                                   }
                                   className="rounded-circle ml-1">
                                <img src={
                                       venueOwnership.ticketLogo ? (
                                         URL.createObjectURL(venueOwnership.ticketLogo)
                                       ) : venueOwnership.ticket_logo_thumb_url ? (
                                         venueOwnership.ticket_logo_thumb_url
                                       ) : (
                                         "/venue-logoplaceholder.png"
                                       )
                                     }
                                     style={{"height": "100%"}}
                                     className="img-fluid" />
                              </div>
                            </div>
                            <div className="col">
                              <p className="mb-0 text-muted small">
                                Will be displayed in black and white, at 100x100 pixels
                              </p>
                              <a href="#"
                                 style={{"borderRadius": "8px"}}
                                 onClick={
                                   (e) => {
                                     e.preventDefault();
                                     venueOwnership.ticketLogoEl.click();
                                   }
                                 }
                                 className="btn btn-primary btn-sm mt-2">
                                Choose Photo
                              </a>
                              {(venueOwnership.ticket_logo || venueOwnership.ticket_logo_thumb_url) ? (
                                <p className="mb-0 pt-2">
                                  <a href="#"
                                     onClick={
                                       (e) => {
                                         e.preventDefault();
                                         venueOwnershipTicketLogoRemoved(index);
                                       }
                                     }
                                     className="text-red mt-2 small">
                                    Remove
                                  </a>
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-row mt-3">
                        <div className='col-12'>
                          <label className="w-100"
                                 htmlFor={"venue-bio-" + index}>
                            <div className="form-row">
                              <div className="col">
                                <strong>Venue Bio</strong>
                              </div>
                              <div className="col-xs-auto">
                                <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">
                                  Formatting Help
                                </a>
                              </div>
                            </div>
                          </label>
                          <MdEditor style={{
                                      height: '300px'
                                    }}
                                    view={{menu: true, md: true, html: false}}
                                    id={"venue-bio-" + index}
                                    plugins={
                                      [
                                        'header',
                                        'font-bold',
                                        'font-italic',
                                        'font-underline',
                                        'font-strikethrough',
                                        'list-unordered',
                                        'list-ordered',
                                        'block-quote',
                                        'block-wrap',
                                        'table',
                                        'image',
                                        'link',
                                        'clear',
                                        'logger',
                                        'mode-toggle',
                                        'full-screen',
                                        'tab-insert'
                                      ]
                                    }
                                    onImageUpload={
                                      (file, callback) => {
                                        uploadImageEmbed(csrfToken, team, file, callback);
                                      }
                                    }
                                    renderHTML={
                                      (text) => mdParser.render(text)
                                    }
                                    value={venueOwnership.bio || ""}
                                    onChange={
                                      ({text}) => {
                                        venueOwnershipBioChanged(index, text);
                                      }
                                    } />
                        </div>
                      </div>
                      {venueOwnership.errors && venueOwnership.errors.length > 0 ? (
                        <div className="form-group pt-3">
                          <div className="p-3 bg-danger text-white rounded">
                            <ul className="mb-0">
                              {venueOwnership.errors.map((error, key) =>
                                <li key={key}>{error}</li>
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                      <div className="form-row pt-3">
                        <div className="col-12 text-right">
                          <a onClick={
                               (e) => {
                                 e.preventDefault();
                                 cancelEditVenueOwnership();
                               }
                             }
                             href="#">
                            Cancel
                          </a>
                          <button type="submit"
                                  disabled={!canSubmitVenueOwnershipForm(venueOwnership)}
                                  className="btn btn-primary ml-3">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div className="row"
           style={{
             "paddingTop": (
               venueOwnerships.length > 0 ? (
                 "25px"
               ) : venueSuggestionFormOpen ? (
                 "0px"
               ) : (
                 "130px"
               )
             )
           }}>
        <div className="col">
          <p className="text-muted small">
            *Manually entered venues will be reviewed by the Opendate team. You'll receive a notification when your venue is available in the app.
          </p>
        </div>
        {settingUpTeam ? (
          <div className="col-xs-auto text-right">
            <form action={"/teams/" + team.id + "/setup"}
                  onSubmit={
                    (e) => {
                      if(venueOwnerships.length === 0){
                        e.preventDefault();
                      }
                    }
                  }
                  method="post">
              <input type="hidden" name="_method" value="patch" autoComplete="off" />
              <input type="hidden" name="authenticity_token" value={csrfToken} autoComplete="off" />
              <input type="submit"
                     value="Finish"
                     style={{'fontWeight': 'bold'}}
                     className={"btn btn-primary " + (venueOwnerships.length === 0 ? "disabled" : "")} />
            </form>
          </div>
        ) : null}
      </div>
    </div>
    <Popup
      open={modalIsOpen}
      contentStyle={modalContentStyle}
      closeOnDocumentClick
      onClose={() => closeModal()}
    >
      <div>
        <a href="#"
           className="text-dark"
           style={
             {
               "position": "absolute",
               "right": "15px",
               "top": "15px"
             }
           }
           onClick={(e) => {e.preventDefault(); closeModal();}} >
          <i className="fa fa-times"></i>
        </a>
        <div className="text-center">
          <h2 style={{"color": "#ed6464"}}>
            <strong>Thank you!</strong>
          </h2>
          <p>Your venue submission is being reviewed by the Opendate team. We'll let you know when the venue is available in the app.</p>
        </div>
      </div>
    </Popup>
  </div>
);

VenueManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  venueOwnerships: PropTypes.array.isRequired,
  deleteVenueOwnership: PropTypes.func.isRequired,
  createVenueOwnership: PropTypes.func.isRequired,
  settingUpTeam: PropTypes.bool,
  venueSuggestionFormOpen: PropTypes.bool,
  manualVenueEntryClicked: PropTypes.func.isRequired,
  venueSuggestion: PropTypes.object,
  venueSuggestionNameChanged: PropTypes.func.isRequired,
  venueSuggestionAddressOneChanged: PropTypes.func.isRequired,
  venueSuggestionAddressTwoChanged: PropTypes.func.isRequired,
  venueSuggestionCityChanged: PropTypes.func.isRequired,
  venueSuggestionStateChanged: PropTypes.func.isRequired,
  venueSuggestionZipChanged: PropTypes.func.isRequired,
  venueStateOptions: PropTypes.array.isRequired,
  createVenueSuggestion: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  cancelEditVenueOwnership: PropTypes.func.isRequired,
  venueOwnershipBioChanged: PropTypes.func.isRequired,
  venueOwnershipLogoElChanged: PropTypes.func.isRequired,
  venueOwnershipLogoChanged: PropTypes.func.isRequired,
  venueOwnershipTicketLogoElChanged: PropTypes.func.isRequired,
  venueOwnershipTicketLogoChanged: PropTypes.func.isRequired,
  fetchVenueOwnership: PropTypes.func.isRequired,
  venueOwnershipLogoRemoved: PropTypes.func.isRequired,
  venueOwnershipTicketLogoRemoved: PropTypes.func.isRequired,
  updateVenueOwnership: PropTypes.func.isRequired,
  venueOwnershipChanged: PropTypes.func.isRequired,
  availableTimezones: PropTypes.array.isRequired
};

export default VenueManager;
