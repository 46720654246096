import PropTypes from 'prop-types';
import React from 'react';

import { toast } from 'react-toastify';
import SemiCircle from '../../SemiCircle/components/SemiCircle';
import Popup from "reactjs-popup";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "205px",
  "padding": "0px"
}

const calculatePercentageSold = (confirm) => {
  var ticketsSold = confirm.number_of_manifested_tickets_sold;
  var venueCapacity = confirm.venue_capacity;

  if(venueCapacity === 0){
    return (ticketsSold > 0 ? 100 : 0);
  }

  return Math.ceil((ticketsSold / venueCapacity) * 100);
}

const filteredTicketTypes = (ticketTypesByPrice, currentUser) => {
  if(currentUser.can_see_unmanifested_tickets){
    return ticketTypesByPrice;
  } else {
    return ticketTypesByPrice.filter((sd) => sd.ticket_type.manifested);
  }
}

const ManageOpendateTickets = ({
  csrfToken,
  team,
  confirm,
  confirmChanged,
  updateConfirmTicketForecast,
  currentUser,
  ticketTypesByPrice,
  showWidgetCodeChanged,
  addOnsByPrice,
  manualTicketCounts
}) => (
  <div className="card no-border shadow-2">
    <div className="card-body px-md-3">
      {confirm.published_at || manualTicketCounts.length > 0 ? (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <strong style={{"fontSize": "16px"}}>
                Tickets
              </strong>
              {currentUser.can_manage_ticket_types && confirm.published_at ? (
                <a href={"/teams/" + team.id + "/confirms/" + confirm.id + "/dashboard"}
                   className="btn btn-external btn-sm rounded ml-2">
                  <i className="fas fa-arrow-right"></i>
                </a>
              ) : null}
            </div>
            <div className="col-xs-auto"
                 style={{"paddingRight": "15px"}}>
              {currentUser.can_manage_ticket_types ? (
                <Popup arrow={false}
                       offsetY={5}
                       position="bottom right"
                       contentStyle={popupContentStyle}
                       onOpen={
                         (e) => {
                           e.stopPropagation();
                           e.preventDefault();
                         }
                       }
                       trigger={open => (
                         <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();
                              }
                            }
                            className="btn btn-external btn-sm">
                           <i className="fas fa-ellipsis-v"></i>
                         </a>
                       )} >
                  {close => (
                    <div className='row text-left'>
                      <div className="col-12">
                        <ul className="list-group offer-inline-menu">
                          <li className="list-group-item px-3 pt-3 pb-2">
                            <a href={"/teams/" + team.id + "/confirms/" + confirm.id + "/ticketing"}
                               className="text-dark">
                              <span className="d-inline-block text-center" style={{width: "28px"}}>
                                <i className="fas fa-pencil fa-fw"></i>
                              </span>
                              <span className="ml-3">
                                Edit tickets
                              </span>
                            </a>
                          </li>
                          {false ? (
                              <li className="list-group-item px-3 py-2">
                                <a href="#"
                                   className="text-dark"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();
                                       close();
                                       // fetchNewTicketType(csrfToken, team, ticketType);
                                     }
                                   }>
                                  <span className="d-inline-block text-center" style={{width: "28px"}}>
                                    <i className="far fa-arrow-to-bottom fa-fw"></i>
                                  </span>
                                  <span className="ml-3">
                                    Download report
                                  </span>
                                </a>
                              </li>
                          ) : null}
                          {confirm.published_at ? (
                            <li className="list-group-item pb-3 px-3 pt-2">
                              <a href="#"
                                className="text-dark"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                    showWidgetCodeChanged(true);
                                  }
                                }>
                                <span className="d-inline-block text-center" style={{width: "28px"}}>
                                  <i className="far fa-code fa-fw"></i>
                                </span>
                                <span className="ml-3">
                                  Embed widget
                                </span>
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  )}
                </Popup>
              ) : null}
            </div>
          </div>
          <div>
            <div className="row pt-5 px-2">
              <div className="col-12 col-lg-4 d-flex justify-content-between flex-row flex-lg-column">
                <dl>
                  <dt className="text-muted">
                    <strong className="text-no-wrap">Forecast</strong>
                  </dt>
                  <dd style={{"marginTop": "-5px"}}>
                    <strong className="large-number text-no-wrap d-flex align-items-center"
                            style={{"fontSize": "24px"}}>
                      {confirm.editingTicketForecast ? (
                        <>
                          <input
                            type="number"
                            style={{
                              "color": "#333",
                              "width": "100px",
                              "border": "0px",
                              "outline": "none",
                              "boxShadow": "none",
                              "fontSize": "24px",
                              "fontWeight": "bold",
                              "padding": "0px"
                            }}
                            value={confirm.editedTicketForecast || confirm.ticket_forecast || 0}
                            onChange={e => {
                              confirmChanged({
                                ...confirm,
                                editedTicketForecast: e.target.value
                              });
                            }}
                          />
                          <a
                            href=""
                            style={{"fontSize": "14px"}}
                            className="ml-2 text-muted"
                            onClick={e => {
                              e.preventDefault();

                              updateConfirmTicketForecast(
                                csrfToken,
                                confirm,
                                confirm.editedTicketForecast || confirm.ticket_forecast,
                                data => { // Success callback
                                  confirmChanged({
                                    ...confirm,
                                    editedTicketForecast: null,
                                    editingTicketForecast: false,
                                    ticket_forecast: data.ticket_forecast,
                                  });

                                  toast.success("Ticket forecast updated.", {
                                    position: toast.POSITION.TOP_CENTER,
                                    draggable: false,
                                    closeOnClick: true,
                                    autoClose: 5000,
                                    hideProgressBar: true
                                  });
                                },
                                error => { // Error callback
                                  confirmChanged({
                                    ...confirm,
                                    editedTicketForecast: null,
                                    editingTicketForecast: false
                                  });

                                  var message = typeof error.response === 'undefined'
                                    ? "Something went wrong"
                                    : error.response.data.join(', ');

                                  toast.error(message, {
                                    position: toast.POSITION.TOP_CENTER,
                                    draggable: false,
                                    closeOnClick: false,
                                    autoClose: 5000,
                                    hideProgressBar: true
                                  });
                                }
                              );
                            }}
                          >
                            <i className="fas fa-check"></i>
                          </a>
                          <a
                            href=""
                            style={{"fontSize": "14px"}}
                            className="ml-2 text-muted"
                            onClick={e => {
                              e.preventDefault();

                              confirmChanged({
                                ...confirm,
                                editedTicketForecast: null,
                                editingTicketForecast: false
                              });
                            }}
                          >
                            <i className="fas fa-xmark"></i>
                          </a>
                        </>
                      ) : (
                        <>
                          {confirm.ticket_forecast || 0}
                          <a
                            href=""
                            style={{"fontSize": "14px"}}
                            className="ml-2 text-muted"
                            onClick={e => {
                              e.preventDefault();

                              confirmChanged({...confirm, editingTicketForecast: true});
                            }}
                          >
                            <i className="fas fa-pencil"></i>
                          </a>
                        </>
                      )}
                    </strong>
                  </dd>
                </dl>
                <dl>
                  <dt className="text-muted">
                    <strong className="text-no-wrap">Sold</strong>
                  </dt>
                  <dd style={{"marginTop": "-5px"}}>
                    <strong className="text-no-wrap"
                            style={{"fontSize": "24px"}}>
                      {confirm.number_of_manifested_tickets_sold}
                    </strong>
                  </dd>
                </dl>
                <dl>
                  <dt className="text-muted">
                    <strong className="text-no-wrap">Capacity</strong>
                  </dt>
                  <dd style={{"marginTop": "-5px"}}>
                    <strong className="text-no-wrap"
                            style={{"fontSize": "24px"}}>
                      {confirm.venue_capacity}
                    </strong>
                  </dd>
                </dl>
              </div>
              <div className="col-12 col-lg-8 d-flex justify-content-center"
                   style={{"paddingBottom": "35px"}}>
                <SemiCircle wrapperClassName="align-self-end"
                            percentage={calculatePercentageSold(confirm)} />
              </div>
            </div>
            {currentUser.can_see_unmanifested_tickets ? (
              <div className="row">
                <div className="col-12">
                  <div className="card border-0 rounded-0"
                       style={{
                         "background": "#f9f9f9",
                         "marginBottom": "11px"
                       }}>
                    <div className="card-body text-center m-0 py-3">
                      <span style={{"fontSize": "16px"}}><strong>{confirm.number_of_tickets_issued} </strong></span>
                      <span className="text-muted small">
                        <strong>
                          Tickets Issued
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 mb-3">
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <table className="table table-sm table-borderless vertical-middle mt-0"
                       style={{tableLayout: "fixed"}}>
                  <colgroup>
                    <col span={1} style={{"width": "60%"}} />
                    <col span={1} style={{"width": "20%"}} />
                    <col span={1} style={{"width": "20%"}} />
                  </colgroup>
                  <tbody>
                    {filteredTicketTypes(ticketTypesByPrice, currentUser).map((salesData, index) => (
                      <tr key={index}
                          style={{"borderBottom": "2px solid #efefef"}}
                          className={(salesData.ticket_type.scheduled ? "text-muted" : "")}>
                        <td className="py-2 overflow-ellipsis">
                          <strong title={salesData.ticket_type.name}>
                            {salesData.ticket_type.name}
                          </strong>
                          {salesData.ticket_type.scheduled ? (
                            <p className='mb-0 small text-overflow-ellipsis'
                               title={salesData.ticket_type.sales_timeline ? (salesData.ticket_type.sales_timeline) : ""}>
                              <span>Scheduled </span>
                              {salesData.ticket_type.sales_timeline ? (
                                salesData.ticket_type.sales_timeline
                              ) : null}
                            </p>
                          ) : null}
                        </td>
                        <td className="py-2 text-right">
                          {salesData.quantity}/{salesData.ticket_type.available}
                        </td>
                        <td className="py-2 text-right">
                          {parseFloat(salesData.price) !== 0 ? (
                            formatter.format(salesData.price)
                          ) : (
                            "FREE"
                          )}
                        </td>
                      </tr>
                    ))}
                    {manualTicketCounts.map((manualTicketCount, index) => (
                      <tr key={index}
                          style={{"background": "#F9F9F9"}}>
                        <td className="py-2 overflow-ellipsis">
                          <p className='text-muted mb-0' style={{"fontSize": "10px"}}>
                            EXTERNAL TICKET
                          </p>
                          <p className='mb-0 text-truncate' style={{"marginTop": "-3px"}}>
                            <strong title={manualTicketCount.name}>
                              {manualTicketCount.name}
                            </strong>
                          </p>
                        </td>
                        <td className="py-2 text-right">
                          {typeof(manualTicketCount.available) === "number" ? (
                            <span>{manualTicketCount.quantity}/{manualTicketCount.available}</span>
                          ) : (
                            <span>{manualTicketCount.quantity}</span>
                          )}
                        </td>
                        <td className="py-2 text-right">
                          {parseFloat(manualTicketCount.price) !== 0 ? (
                            formatter.format(manualTicketCount.price)
                          ) : (
                            "FREE"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {addOnsByPrice.length > 0 ? (
                  <React.Fragment>
                    <p className="text-muted"
                       style={{
                         "paddingTop": "3px",
                         "marginBottom": "10px"
                       }}>
                      <strong>
                        ADD-ONS
                      </strong>
                    </p>
                    <table className="table table-sm table-borderless vertical-middle mt-0"
                           style={{tableLayout: "fixed"}}>
                      <colgroup>
                        <col span={1} style={{"width": "60%"}} />
                        <col span={1} style={{"width": "20%"}} />
                        <col span={1} style={{"width": "20%"}} />
                      </colgroup>
                      <tbody>
                        {addOnsByPrice.sort((a, b) => a.add_on.position - b.add_on.position).map((salesData, index) => (
                          <tr key={index}
                              style={{"borderBottom": "2px solid #efefef"}}
                              className={(salesData.add_on.scheduled ? "text-muted" : "")}>
                            <td className="py-2 overflow-ellipsis">
                              <strong title={salesData.add_on.name}>
                                {salesData.add_on.name}
                              </strong>
                              {salesData.add_on.scheduled ? (
                                <p className='mb-0 small text-overflow-ellipsis'
                                   title={salesData.add_on.sales_timeline ? (salesData.add_on.sales_timeline) : ""}>
                                  <span>Scheduled </span>
                                  {salesData.add_on.sales_timeline ? (
                                    salesData.add_on.sales_timeline
                                  ) : null}
                                </p>
                              ) : null}
                            </td>
                            <td className="py-2 text-right">
                              {salesData.quantity}/{salesData.add_on.available}
                            </td>
                            <td className="py-2 text-right">
                              {formatter.format(salesData.price)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-xs-auto text-muted"
                   style={{"paddingLeft": "15px"}}>
                Event ID: <a href={"/teams/" + team.id + "/confirms/" + confirm.id}>{confirm.id}</a>
              </div>
              <div className="col text-right text-muted">
                Powered by
                <img src="/opendate-horiz-wh.png"
                     style={{"width": "85px"}}
                     className="img-fluid ml-2" />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h5 className="strong">Tickets</h5>
          <div className="row pt-3">
            <div className="col-12">
              <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 text-center">
                      <i className="fal fa-ticket-alt"
                         style={{
                           "fontSize": "60px",
                           "marginTop": "24px",
                           "color": "rgba(25, 130, 196, .5)"
                         }}>
                      </i>
                      <h5 className="pt-2"
                          style={{"fontSize": "20px"}}>
                        Create Tickets
                      </h5>
                      <p className="text-muted mt-3 mb-4">
                        You're just a few clicks away.
                      </p>
                      {currentUser.can_manage_ticket_types ? (
                        <a href={"/teams/" + team.id + "/confirms/" + confirm.id + "/ticketing"}
                           className="btn btn-primary small mb-2">
                          <strong>Create Tickets</strong>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  </div>
);

ManageOpendateTickets.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmChanged: PropTypes.func.isRequired,
  updateConfirmTicketForecast: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  ticketTypesByPrice: PropTypes.array,
  showWidgetCodeChanged: PropTypes.func.isRequired,
  addOnsByPrice: PropTypes.array,
  manualTicketCounts: PropTypes.array
};

export default ManageOpendateTickets;
