import { combineReducers } from 'redux';


const agencies = (state = [], action) => {
  switch (action.type) {
    default:
    return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const artistId = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ArtistRepresentation = combineReducers({ agencies, csrfToken, artistId, });

export default ArtistRepresentation;
