import { combineReducers } from 'redux';

import {
  DATA_TABLE_EL_CHANGED
} from '../constants/postedPostsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const searchedSearchTerm = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const authorizedPages = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const hasPosts = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const postedPostsReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  searchedSearchTerm,
  authorizedPages,
  hasPosts
});

export default postedPostsReducer;
