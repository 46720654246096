import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import MaskedInput from 'react-text-mask'

import {
  STATE_OPTIONS
} from '../constants/customFieldValuesConstants';

const AddressCustomFieldValue = ({
  team,
  csrfToken,
  confirm,
  customFieldValue,
  customFieldValueChanged
}) => (
  <div className="row mt-3" style={{"padding": "10px 0 15px 0"}}>
    <div className="col-12">
      <div className="row">
        <div className="col-12">
          <p style={{
               "fontSize": "16px",
               "marginBottom": "12px"
             }}>
            <strong>{customFieldValue.custom_field.name}</strong>{!customFieldValue.custom_field.required ? " (optional)" : null}
          </p>
        </div>
      </div>
      <div className="form-row">
        <div className="col-6">
          <input type="text"
                 placeholder="Address 1"
                 style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                 value={customFieldValue.value.address1 || ""}
                 onChange={
                   (e) => {
                     var value = Object.assign({}, customFieldValue.value, {address1: e.target.value});
                     var updated = Object.assign({}, customFieldValue, {value: value});

                     customFieldValueChanged(updated);
                   }
                 }
                 className="form-control" />
        </div>
        <div className="col-6">
          <input type="text"
                 placeholder="Address 2"
                 style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                 value={customFieldValue.value.address2 || ""}
                 onChange={
                   (e) => {
                     var value = Object.assign({}, customFieldValue.value, {address2: e.target.value});
                     var updated = Object.assign({}, customFieldValue, {value: value});

                     customFieldValueChanged(updated);
                   }
                 }
                 className="form-control" />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-6">
          <input type="text"
                 placeholder="City"
                 style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                 value={customFieldValue.value.city || ""}
                 onChange={
                   (e) => {
                     var value = Object.assign({}, customFieldValue.value, {city: e.target.value});
                     var updated = Object.assign({}, customFieldValue, {value: value});

                     customFieldValueChanged(updated);
                   }
                 }
                 className="form-control" />
        </div>
        <div className="col-3">
          <select className="form-control"
                  style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                  onChange={
                    (e) => {
                      var value = Object.assign({}, customFieldValue.value, {state: e.target.value});
                      var updated = Object.assign({}, customFieldValue, {value: value});

                      customFieldValueChanged(updated);
                    }
                  }
                  value={customFieldValue.value.state || ""}>
            <option value="">State</option>
            {STATE_OPTIONS.map((state, index) =>
              <option key={index} value={state.code}>
                {state.name}
              </option>
            )}
          </select>
        </div>
        <div className="col-3">
          <MaskedInput className="form-control"
                       style={{"border": "1px solid #e6e6e6", "fontSize": "14px"}}
                       placeholder="Postal code"
                       guide={false}
                       onChange={
                         (e) => {
                           var value = Object.assign({}, customFieldValue.value, {zip: e.target.value});
                           var updated = Object.assign({}, customFieldValue, {value: value});

                           customFieldValueChanged(updated);
                         }
                       }
                       value={customFieldValue.value.zip || ""}
                       mask={
                         (value) => {
                           if (value.length > 5) {
                             return [/\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/];
                           } else {
                             return [/\d/, /\d/, /\d/, /\d/, /\d/];
                           }
                         }
                       } />
        </div>
      </div>
    </div>
  </div>
);

AddressCustomFieldValue.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  customFieldValue: PropTypes.object.isRequired,
  customFieldValueChanged: PropTypes.func.isRequired
};

export default AddressCustomFieldValue;
