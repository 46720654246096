import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import offerV2ModalReducer from '../reducers/offerV2ModalReducer';

const configureStore = (railsProps) => (
  createStore(offerV2ModalReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
