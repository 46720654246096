import React, { createContext, useState, useContext, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import BroadcastNew from "./BroadcastNew";
import { toastSuccess, toastError } from "../../../shared/toastHelper";

const BroadcastNewContext = createContext();

export function useBroadcastNewContext() {
  return useContext(BroadcastNewContext);
}

const BroadcastNewProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
    venueOwnership,
    lastTouchedVenueOwnership,
  } = props;

  const [name, setName] = useState("");
  const [broadcastType, setBroadcastType] = useState("email");

  const createBroadcast = (payload) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/broadcasts`, payload)
      .then((data) => {
        window.location.href = `/teams/${team.id}/broadcasts/${data["data"]["id"]}/edit`;
      })
      .catch(() => toastError("Could not create broadcast, please try again"));
  };

  const value = {
    csrfToken,
    user,
    team,
    venueOwnership,
    lastTouchedVenueOwnership,
    name,
    setName,
    broadcastType,
    setBroadcastType,
    createBroadcast,
  };

  return (
    <BroadcastNewContext.Provider value={value}>
      {props.children}
    </BroadcastNewContext.Provider>
  );
};

const BroadcastNewContainer = (props) => (
  <BroadcastNewProvider {...props}>
    <ToastContainer />
    <BroadcastNew />
  </BroadcastNewProvider>
);

export default BroadcastNewContainer;
