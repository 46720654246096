import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import Flatpickr from "react-flatpickr";
import Tippy from '@tippyjs/react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import ManageFinanceItemsForm from './ManageFinanceItemsForm';

const confirmPopupStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const breakoutLineItems = (financeItems) => {
  return financeItems.map((financeItem) => {
    return financeItem.finance_line_items.map((lineItem) => {
      return {
        ...lineItem,
        finance_item_id: financeItem.id,
        finance_item: financeItem
      };
    });
  }).flat();
}

const ManageFinanceItemsTable = ({
  title,
  csrfToken,
  team,
  confirm,
  currentUser,
  financeItems,
  deleteFinanceItem,
  financeItemToEdit,
  financeItemToEditChanged,
  financeItemForForm,
  financeItemForFormChanged,
  financeCategories,
  financeCategoriesChanged,
  performers,
  createFinanceItem,
  updateFinanceItem,
  offers,
  offerChanged
}) => {
  return (
    <div className="col-12 mb-4">
      <h6 className="mt-3 mb-0 strong" style={{"paddingLeft": "30px", "fontSize":"16px"}}>{title}</h6>
      <form style={{"overflow":"hidden"}}>
        <table className="table table-borderless vertical-middle table-sm"
               style={{tableLayout: "fixed"}}>
          <colgroup>
            <col span={1} style={{"width":"auto", "minWidth":"100px"}} />
            <col span={1} style={{"width":"auto", "minWidth":"100px"}} />
            <col span={1} style={{"width":"auto", "minWidth":"100px"}} />
            <col span={1} style={{"width":"auto", "minWidth":"100px"}} />
            <col span={1} style={{"width":"100px"}} />
            <col span={1} style={{"width":"100px"}} />
            <col span={1} style={{"width":"80px"}} />
          </colgroup>
          <thead>
            <tr>
              <th className="text-muted pb-0 text-nowrap" style={{"paddingLeft": "30px"}}>
                Date
              </th>
              <th className="text-muted pb-0 text-nowrap">Category</th>
              <th className="text-muted pb-0 text-nowrap">Description</th>
              <th className="text-muted pb-0 text-nowrap text-center">Settlement?</th>
              <th className="text-muted pb-0 text-nowrap text-right">Forecast</th>
              <th className="text-muted pb-0 text-nowrap text-right">Actual</th>
              <th className="text-right"
                  style={{"paddingRight": "30px"}}>
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {breakoutLineItems(financeItems).map((financeLineItem, index) =>
              financeLineItem.id !== financeItemToEdit.id ? (
                <tr key={index}>
                  <td className="pb-0"
                      style={{"paddingLeft": "30px"}}>
                    {financeLineItem.finance_item.human_issued_on ? (
                      financeLineItem.finance_item.human_issued_on
                    ) : (
                      <span style={{"opacity": "0.5"}}>--</span>
                    )}
                  </td>
                  <td className="overflow-ellipsis pb-0">
                    <Tippy content={financeLineItem.category?.name}>
                      <span>
                        {financeLineItem.category?.name}
                      </span>
                    </Tippy>
                  </td>
                  <td className="overflow-ellipsis pb-0">
                    <Tippy content={financeLineItem.description}>
                      <span>
                        {financeLineItem.description}
                      </span>
                    </Tippy>
                  </td>
                  <td className="pb-0 text-center">
                    {financeLineItem.finance_item.on_settlement ? (
                      <span>Yes</span>
                    ) : (
                      <span>No</span>
                    )}
                  </td>
                  <td className="pb-0 text-right">
                    {financeLineItem.estimate_to_currency || `${financeLineItem.is_income ? "" : "-"}$0.00`}
                  </td>
                  <td className="pb-0 text-right">
                    {financeLineItem.actual_to_currency || `${financeLineItem.is_income ? "" : "-"}$0.00`}
                  </td>
                  <td className="pb-0 text-right"
                      style={{"paddingRight": "30px"}}>
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           financeItemToEditChanged(financeLineItem);
                           financeItemForFormChanged(financeLineItem.finance_item);
                         }
                       }
                       className="text-muted mr-2">
                      <i className="fas fa-pencil"></i>
                    </a>
                    <Popup
                      modal
                      closeOnDocumentClick
                      arrow={false}
                      position="center center"
                      contentStyle={confirmPopupStyle}
                      onOpen={e => { e.preventDefault(); }}
                      trigger={open => (
                        <a href="#"
                          className="text-muted"
                          onClick={e => e.preventDefault() }
                        >
                          <i className="fas fa-trash"></i>
                        </a>
                      )}
                    >
                      {close => (
                         <div>
                           <div className="row">
                             <div className="col">
                               <div className="row">
                                 <div className="col">
                                   <h5 style={{"marginBottom": "16px"}}>
                                     <strong>Delete this finance item?</strong>
                                   </h5>
                                 </div>
                                 <div className="col-xs-auto">
                                   <a href="#"
                                      className="text-dark"
                                      style={{"paddingRight": "15px"}}
                                      onClick={(e) => {e.preventDefault(); close();}} >
                                     <i className="fa fa-times"></i>
                                   </a>
                                 </div>
                               </div>
                               <p>Are you sure you want to delete this finance item in Opendate?</p>
                               <div className="text-right">
                                 <a href="#"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        close();
                                      }
                                    }>
                                   No thanks
                                 </a>
                                 <a href="#"
                                    className="btn btn-danger ml-3"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();

                                        var offersToUpdate = (financeLineItem.finance_item.on_settlement ? [...offers] : []);
                                        offersToUpdate.forEach((o) => {
                                          var updated = Object.assign({}, o, {
                                            total_due: undefined
                                          });

                                          offerChanged(updated);
                                        });

                                        deleteFinanceItem(csrfToken, team, confirm, financeLineItem.finance_item, offersToUpdate);
                                        close();
                                      }
                                    }>
                                   Yes, delete
                                 </a>
                               </div>
                             </div>
                           </div>
                         </div>
                      )}
                    </Popup>
                  </td>
                </tr>
              ) : (
                <tr key={index}>
                  <td colSpan="7" className="p-0">
                    <ManageFinanceItemsForm
                      csrfToken={csrfToken}
                      team={team}
                      confirm={confirm}
                      currentUser={currentUser}
                      financeItems={financeItems}
                      financeItemToEdit={financeItemToEdit}
                      financeItemToEditChanged={financeItemToEditChanged}
                      financeItemForForm={financeItemForForm}
                      financeItemForFormChanged={financeItemForFormChanged}
                      financeCategories={financeCategories}
                      financeCategoriesChanged={financeCategoriesChanged}
                      performers={performers}
                      createFinanceItem={createFinanceItem}
                      updateFinanceItem={updateFinanceItem}
                      offers={offers}
                      offerChanged={offerChanged}
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </form>
    </div>
  );
}

ManageFinanceItemsTable.propTypes = {
  title: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  financeItems: PropTypes.array,
  deleteFinanceItem: PropTypes.func.isRequired,
  financeItemToEdit: PropTypes.object,
  financeItemToEditChanged: PropTypes.func.isRequired,
  financeItemForForm: PropTypes.object,
  financeItemForFormChanged: PropTypes.func.isRequired,
  financeCategories: PropTypes.array,
  financeCategoriesChanged: PropTypes.func.isRequired,
  performers: PropTypes.array,
  createFinanceItem: PropTypes.func.isRequired,
  updateFinanceItem: PropTypes.func.isRequired,
  offers: PropTypes.array,
  offerChanged: PropTypes.func.isRequired
};

export default ManageFinanceItemsTable;
