import React from "react";

import { useBroadcastEditContext } from "./BroadcastEditContainer";
import SendTestOverlay from "../BeefreeTemplates/SendTestOverlay";
import RecipientsTab from "./RecipientsTab";
import FromTab from "./FromTab";
import ContentSelectTab from "./ContentSelectTab";
import ReviewAndScheduleTab from "./ReviewAndScheduleTab";
import EmailPreviewTab from "./EmailPreviewTab";
import EmailContentTab from "./EmailContentTab";
import DetailsTab from "./DetailsTab";
import EmailSentTab from "./EmailSentTab";
import BroadcastEditHeader from "./BroadcastEditHeader";
import ScheduledSendPane from "./ScheduledSendPane";

const BroadcastEdit = () => {
  const {
    tab,
  } = useBroadcastEditContext();

  return (
    <div>
      <SendTestOverlay templatableContext={useBroadcastEditContext} />
      <ScheduledSendPane />
      <BroadcastEditHeader />
      {tab === "recipients" ? (
        <RecipientsTab />
      ) : tab === "from" ? (
        <FromTab />
      ) : tab === "content-select" ? (
        <ContentSelectTab />
      ) : tab === "review-and-schedule" ? (
        <ReviewAndScheduleTab />
      ) : tab === "content" ? (
        <EmailContentTab />
      ) : tab === "preview" ? (
        <EmailPreviewTab />
      ) : tab === "details" ? (
        <DetailsTab />
      ) : (
        <EmailSentTab />
      )}
    </div>
  );
};

export default BroadcastEdit;
