import { createStore, applyMiddleware } from 'redux';
import confirmTicketingReducer from '../reducers/confirmTicketingReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(confirmTicketingReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
