import { combineReducers } from 'redux';

import {
  DATA_TABLE_EL_CHANGED,
  FILTERS_CHANGED,
  PAYMENT_DISPUTE_CHANGED,
  PAYMENT_DISPUTE_UPLOAD_CREATED
} from '../constants/paymentDisputeManagerConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const filters = (state = [], action) => {
  switch (action.type) {
    case FILTERS_CHANGED:
      return action.filters;
    default:
      return state;
  }
};

const paymentDispute = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_DISPUTE_CHANGED:
      return action.paymentDispute;
    case PAYMENT_DISPUTE_UPLOAD_CREATED:
      return {
        ...state,
        payment_dispute_uploads: [...state.payment_dispute_uploads, action.paymentDisputeUpload]
      }
    default:
      return state;
  }
};

const paymentDisputeManagerReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  filters,
  paymentDispute
});

export default paymentDisputeManagerReducer;
