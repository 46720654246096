import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import OnepostComposer from '../../../bundles/OnepostComposer/components/OnepostComposer';
import DataTable from '../../DataTable/components/DataTable';
import Popup from "reactjs-popup";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
var ReactDOM = require('react-dom');

import $ from 'jquery';
require('datatables.net-bs4');

const disablePostEditing = (post) => {
  return (
    post.has_retrying_facebook_social_posts
      || post.has_retrying_twitter_social_posts
      || post.has_retrying_instagram_social_posts
  );
}

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const composePostModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "880px",
  "padding": "0px",
  "overflow": "hidden"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "190px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const ScheduledPosts = ({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  dataTableElChanged,
  searchedSearchTerm,
  deletePost,
  postToEdit,
  onepostPublicKey,
  authorizedPages,
  postToEditChanged,
  updatePost,
  newPost,
  newPostChanged,
  createPost,
  hasPosts,
  hasPostsChanged,
  openNewPost
}) => {
  useEffect(() => {
    if(openNewPost && authorizedPages.length > 0 && currentUser.can_manage_posts) {
      newPostChanged({dataTableEl: dataTableEl});
    }
  }, []);

  return (
    <div>
      <ToastContainer />
      {authorizedPages.length > 0 && currentUser.can_manage_posts ? (
        <div className="datatable-right-header-section">
          <a href="#"
             onClick={(e) => {
               e.preventDefault();
               newPostChanged({dataTableEl: dataTableEl})
             }}
             className="btn btn-primary">
            <i className="fal fa-plus"></i>
            &nbsp;
            <strong>New Post</strong>
          </a>
        </div>
      ) : null}
      {authorizedPages.length === 0 ? (
        <div className='row'>
          <div className='col-12'>
            <div className='card border-0 shadow-2'>
              <div className='card-body'>
                <p style={{'fontSize': '20px'}}>
                  <strong>
                    Scheduled Posts&nbsp;
                    <span className='text-muted'>(0)</span>
                  </strong>
                </p>
              </div>
              <div className='card-body text-center' style={{'paddingBottom': '456px', "paddingTop": "11px"}}>
                <div className='dot d-flex justify-content-center align-items-center'
                      style={{'margin': '0px auto 10px auto', 'width': '88px', 'height': '88px', 'background': '#F3F9FC', 'borderRadius': '50%', 'border': '4px solid #BBE0F6'}}>
                  <i className='fas fa-cog text-primary' style={{'fontSize': '42px'}}></i>
                </div>
                <p className='mb-1' style={{'fontSize': '20px'}}>
                  <strong>Set up Social Accounts</strong>
                </p>
                <p className='text-muted mb-3' style={{'maxWidth': '245px', 'margin': '0 auto'}}>
                  Your social accounts aren’t set up yet. Configure your accounts in order to schedule social posts.
                </p>
                {currentUser.can_manage_authorized_pages ? (
                  <a href={"/integrations"}
                      className='btn btn-primary'
                      style={{'borderRadius': '4px', 'fontSize': '14px'}}>
                    <strong>Configure Social Accounts</strong>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : !hasPosts ? (
        <div className='row'>
          <div className='col-12'>
            <div className='card border-0 shadow-2'>
              <div className='card-body'>
                <p style={{'fontSize': '20px'}}>
                  <strong>
                    Scheduled Posts&nbsp;
                    <span className='text-muted'>(0)</span>
                  </strong>
                </p>
              </div>
              <div className='card-body text-center' style={{'paddingBottom': '456px', "paddingTop": "11px"}}>
                <div className='dot d-flex justify-content-center align-items-center'
                      style={{'margin': '0px auto 10px auto', 'width': '88px', 'height': '88px', 'background': '#F3F9FC', 'borderRadius': '50%', 'border': '4px solid #BBE0F6'}}>
                  <i className='far fa-calendar-times text-primary' style={{'fontSize': '42px'}}></i>
                </div>
                <p className='mb-1' style={{'fontSize': '20px'}}>
                  <strong>No Posts Scheduled</strong>
                </p>
                <p className='text-muted mb-3' style={{'maxWidth': '245px', 'margin': '0 auto'}}>
                  Once you schedule a post it will show up here.
                </p>
                {currentUser.can_manage_posts ? (
                  <a href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        newPostChanged({dataTableEl: dataTableEl})
                      }}
                      className="btn btn-primary"
                      style={{'borderRadius': '4px', 'fontSize': '14px'}}>
                    <strong>Schedule a Post</strong>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DataTable title={dataTableTitle}
                 dataSource={dataTableDataSource}
                 columns={dataTableColumns}
                 colGroup={
                   <colgroup>
                     <col span={1} style={{"width": "20%"}} />
                     <col span={1} style={{"width": "35%"}} />
                     <col span={1} style={{"width": "10%"}} />
                     <col span={1} style={{"width": "10%"}} />
                     <col span={1} style={{"width": "20%"}} />
                     <col span={1} style={{"width": "5%"}} />
                   </colgroup>
                 }
                 onTableElementSet={
                   (el) => {
                     dataTableElChanged(el);
                   }
                 }
                 rowCallback={
                    function(row, data, displayNum, displayIndex, dataIndex){
                      let dataTable          = this;
                      let post               = JSON.parse(decodeHtml(data.json));
                      var mountPostMenuReact = row.getElementsByClassName("post-popup-mount-react")[0];

                      post = Object.assign({}, post, {
                        dataTableEl: dataTable[0]
                      });

                      ReactDOM.render((
                        <div>
                          {currentUser.can_manage_posts ? (
                            <Popup arrow={false}
                                   offsetY={5}
                                   position="bottom right"
                                   contentStyle={popupContentStyle}
                                   onOpen={
                                     (e) => {
                                       e.preventDefault();
                                     }
                                   }
                                   trigger={open => (
                                     <a href="#"
                                        className="text-muted">
                                       <i className="fas fa-ellipsis-v"></i>
                                     </a>
                                   )} >
                              {close => (
                                <div className='row text-left'>
                                  <div className="col-12">
                                    <ul className="list-group offer-inline-menu highlight-primary">
                                      {!disablePostEditing(post) ? (
                                        <li className="list-group-item">
                                          <a href="#"
                                             onClick={
                                               (e) => {
                                                 e.preventDefault();
                                                 postToEditChanged(post);
                                               }
                                             }
                                             className="text-muted">
                                            <span className="d-inline-block text-center" style={{width: "28px"}}>
                                              <i className="fas fa-pencil"></i>
                                            </span>
                                            Edit post details
                                          </a>
                                        </li>
                                      ) : null}
                                      <li className="list-group-item delete-offer-li">
                                        <Popup
                                            trigger={
                                              <a href="#" className="text-danger">
                                                <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                  <i className="fas fa-trash"></i>
                                                </span>
                                                Remove
                                              </a>
                                            }
                                            modal
                                            onOpen={
                                              (e) => {
                                                e.preventDefault();
                                              }
                                            }
                                            contentStyle={deleteModalContentStyle}
                                            closeOnDocumentClick
                                          >
                                          {closeDeletePopup => (
                                            <div>
                                              <div className="row">
                                                <div className="col">
                                                  <div className="row">
                                                    <div className="col">
                                                      <h5 style={{"marginBottom": "16px"}}>
                                                        <div className="strong">Remove post?</div>
                                                      </h5>
                                                    </div>
                                                    <div className="col-xs-auto">
                                                      <a href="#"
                                                         className="text-dark"
                                                         style={{"paddingRight": "15px"}}
                                                         onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                        <i className="fa fa-times"></i>
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <p>You're about to remove this post.</p>
                                                  <p>This cannot be undone.</p>
                                                  <p>Are you sure?</p>
                                                  <div className="text-right">
                                                    <a href="#"
                                                       onClick={
                                                         (e) => {
                                                           e.preventDefault();
                                                           closeDeletePopup();
                                                         }
                                                       }>
                                                      Cancel
                                                    </a>
                                                    <a href="#"
                                                       className="btn btn-danger ml-3"
                                                       onClick={
                                                         (e) => {
                                                           e.preventDefault();
                                                           closeDeletePopup();
                                                           close();
                                                           deletePost(csrfToken, team, post, dataTable);
                                                         }
                                                       }>
                                                      Remove
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Popup>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          ) : null}
                        </div>
                      ), mountPostMenuReact);
                    }
                 }
                 searchedSearchTerm={searchedSearchTerm}
                 order={dataTableOrder} />
      )}
      <Popup
        open={Object.keys(newPost).length > 0}
        contentStyle={composePostModalContentStyle}
        closeOnDocumentClick={false}
        onClose={() => newPostChanged({})}
        >
        <div className="row">
          <div className="col-12">
            <div className="col-xs-auto position-absolute"
                 style={{
                   "right": "37px",
                   "top": "26px"
                 }}>
              <a href="#"
                 style={{"fontSize": "24px"}}
                 className="text-dark"
                 onClick={(e) => {
                   e.preventDefault();
                   newPostChanged({});
                 }} >
                <i className="fal fa-times"></i>
              </a>
            </div>
            <OnepostComposer publicKey={onepostPublicKey}
                             onSuccess={
                               (data) => {
                                  hasPostsChanged(true);
                                  createPost(csrfToken, team, data.token, newPost);
                               }
                             }
                             onFailure={
                               (error) => {
                                 var messages = Object.keys(error).map((attribute) => (
                                   error[attribute].map((message) => (
                                     (attribute + " " + message)
                                   ))
                                 )).flat().join(", ");

                                 toast.error("An error occurred while processing your request (" + messages + ")", {
                                   position: toast.POSITION.TOP_CENTER,
                                   draggable: false,
                                   closeOnClick: false,
                                   autoClose: 5000,
                                   hideProgressBar: true
                                 });
                               }
                             }
                             authorizedPageIds={authorizedPages.map((ap) => ap.onepost_id)} />
          </div>
        </div>
      </Popup>
      <Popup
       open={Object.keys(postToEdit).length > 0}
       contentStyle={composePostModalContentStyle}
       closeOnDocumentClick={false}
       onClose={() => postToEditChanged({})}
      >
       <div className="row">
         <div className="col-12">
           <div className="col-xs-auto position-absolute"
                style={{
                  "right": "37px",
                  "top": "26px"
                }}>
             <a href="#"
                style={{"fontSize": "24px"}}
                className="text-dark"
                onClick={(e) => {
                  e.preventDefault();
                  postToEditChanged({});
                }} >
               <i className="fal fa-times"></i>
             </a>
           </div>
           <OnepostComposer publicKey={onepostPublicKey}
                            onSuccess={
                              (data) => {
                                updatePost(csrfToken, team, postToEdit, data.token);
                              }
                            }
                            onFailure={
                              (error) => {
                                toast.error("An error occurred while processing your request", {
                                  position: toast.POSITION.TOP_CENTER,
                                  draggable: false,
                                  closeOnClick: false,
                                  autoClose: 5000,
                                  hideProgressBar: true
                                });
                              }
                            }
                            token={postToEdit.onepost_token}
                            imageIds={(postToEdit.calendar_event_attachments || []).map((cea) => cea.onepost_id)}
                            authorizedPageIds={authorizedPages.map((ap) => ap.onepost_id)} />
         </div>
       </div>
      </Popup>
    </div>
  )
};

ScheduledPosts.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  searchedSearchTerm: PropTypes.string,
  deletePost: PropTypes.func.isRequired,
  postToEdit: PropTypes.object,
  onepostPublicKey: PropTypes.string.isRequired,
  authorizedPages: PropTypes.array,
  postToEditChanged: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  newPost: PropTypes.object,
  newPostChanged: PropTypes.func.isRequired,
  createPost: PropTypes.func.isRequired,
  hasPosts: PropTypes.bool,
  hasPostsChanged: PropTypes.func.isRequired,
  openNewPost: PropTypes.bool
};

export default ScheduledPosts;
