/* eslint-disable import/prefer-default-export */

import {
  TEAM_MEMBERSHIP_CHANGED,
  ORIGINAL_TEAM_MEMBERSHIP_CHANGED
} from '../constants/emailNotificationSettingsConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const teamMembershipChanged = (teamMembership) => ({
  type: TEAM_MEMBERSHIP_CHANGED,
  teamMembership
});

export const originalTeamMembershipChanged = (teamMembership) => ({
  type: ORIGINAL_TEAM_MEMBERSHIP_CHANGED,
  teamMembership
});

export const updateTeamMembership = (dispatch, csrfToken, team, teamMembership) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/team_memberships/" + teamMembership.id, {
        team_membership: {
          send_daily_activity_summary: teamMembership.send_daily_activity_summary,
          send_weekly_activity_summary: teamMembership.send_weekly_activity_summary,
          send_daily_ticket_counts: teamMembership.send_daily_ticket_counts,
          send_weekly_ticket_counts: teamMembership.send_weekly_ticket_counts,
          send_event_updated_notifications: teamMembership.send_event_updated_notifications,
          send_inbound_submission_notifications: teamMembership.send_inbound_submission_notifications,
          send_financial_notifications: teamMembership.send_financial_notifications,
          user_attributes: {
            id: teamMembership.user.id,
            send_new_shared_tickets: teamMembership.user.send_new_shared_tickets,
            send_upcoming_events: teamMembership.user.send_upcoming_events,
            send_news_and_announcements: teamMembership.user.send_news_and_announcements
          }
        }
      })
      .then(({ data }) => {
        dispatch(teamMembershipChanged(data));
        dispatch(originalTeamMembershipChanged(data));

        toast.success("Email notifications saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};
