import { combineReducers } from 'redux';
import {
} from '../constants/TicketingSystemsConstants';

const eventbriteAccessId = (state = null, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const TicketingSystemsReducer = combineReducers({
  eventbriteAccessId,
})

export default TicketingSystemsReducer;
