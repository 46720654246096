import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Pagination from "react-js-pagination";

const RecommendationsTable = ({ venuesOptions, venueSelected, team, updatedSelectedVenue, onInit, recommendations, fetchRecommendations, activePage, updateActivePage, pagedRecommendations, itemsCountPerPage, isLoading, clickOnRecommendation, csrfToken, logRecommendationsView }) => {

  useEffect(() => {
    onInit(team, venueSelected, itemsCountPerPage, csrfToken);
  }, [])

  return (
    <div>
      <form>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-muted" htmlFor="venue-for-recommendations">
                Recommendations for
              </label>
            </div>
            <div className="col">
              <select className="form-control"
                      defaultValue={(venueSelected ? venueSelected.id : null)}
                      style={{"background": "#f4f9fc", "color": "#1982C4", "border": "2px solid #e8f2f8"}}
                      onChange={
                        (e) => {
                          var venueId = parseInt(e.target.value);
                          var venue = venuesOptions.find((venuesOption) => (
                            venuesOption.id === venueId
                          ));

                          fetchRecommendations(team, venue, itemsCountPerPage, csrfToken);
                          updatedSelectedVenue(venue);
                        }
                      }
                      id="venue-for-recommendations">
                {venuesOptions.map((venue, index) => (
                  <option key={index} value={venue.id}>
                    {venue.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
              <a className="btn btn-external"
                 style={{
                   "fontSize": "14px",
                   "width": "40px",
                   "height": "40px"
                 }}
                 href={"/teams/" + team.id + "/recommendations.csv?venue_id=" + venueSelected.permalink}>
                <i className="fas fa-cloud-download-alt"
                   style={{"marginLeft": "-2px"}}>
                </i>
              </a>
            </div>
          </div>
        </div>
      </form>
      {isLoading ? (
        <div className="text-center pt-3">
          <img src="/loading.gif" style={{width: "75px"}} />
        </div>
      ) : null}
      {(!isLoading && recommendations && recommendations.length > 0) ? (
        <div className="row">
          <div className="col">
            <table className="table table-borderless light-blue-selectable vertical-middle" style={{"tableLayout": "fixed"}}>
              <tbody>
                {pagedRecommendations.map((recommendation, index) => (
                  <tr key={index}>
                    <td style={{"padding": "5px"}}>
                      <div className="row">
                        <div className="col-xs-auto" style={{"paddingLeft": "15px"}}>
                          <img src={recommendation.artist.image_thumb_url_for_json}
                               className="img-fluid rounded-circle mr-2"
                               style={{"height": "40px", "width": "40px"}} />
                        </div>
                        <div className="col p-0">
                          <strong>{recommendation.artist.name}</strong>
                          <p className='mb-0'>{recommendation.date}</p>
                        </div>
                      </div>
                    </td>
                    <td className='text-right' style={{"padding": "5px"}}>
                      <div className="row">
                        <div className="col">
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 clickOnRecommendation(team, recommendation, venueSelected, csrfToken);
                               }
                             }
                             className="btn btn-primary show-on-hover">
                            Inquire
                          </a>
                          <i className="far fa-ellipsis-v show-default"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={recommendations.length}
              pageRangeDisplayed={5}
              itemClass="page-item"
              linkClass="page-link bg-white"
              innerClass="pagination justify-content-center"
              hideFirstLastPages={true}
              prevPageText={"←"}
              nextPageText={"→"}
              onChange={
                (page) => {
                  updateActivePage(page, recommendations, itemsCountPerPage);
                  logRecommendationsView(team, venueSelected, page, csrfToken);
                }
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  )
};

RecommendationsTable.propTypes = {
  venuesOptions: PropTypes.array,
  venueSelected: PropTypes.object,
  team: PropTypes.object.isRequired,
  updatedSelectedVenue: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  recommendations: PropTypes.array,
  fetchRecommendations: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  updateActivePage: PropTypes.func.isRequired,
  pagedRecommendations: PropTypes.array,
  itemsCountPerPage: PropTypes.number,
  isLoading: PropTypes.bool,
  clickOnRecommendation: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  logRecommendationsView: PropTypes.func.isRequired
};

export default RecommendationsTable;
