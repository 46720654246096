/* eslint-disable import/prefer-default-export */

import {
  DATA_TABLE_EL_CHANGED
} from '../constants/postedPostsConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import $ from 'jquery';
require('datatables.net-bs4');

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const deletePost = (dispatch, csrfToken, team, post, dataTable) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/posts/" + post.id)
      .then(({ data }) => {
        $(dataTable[0]).DataTable().ajax.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};
