import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import AddressCustomFieldValue from './AddressCustomFieldValue';
import PhoneCustomFieldValue from './PhoneCustomFieldValue';

const CustomFieldValueFields = ({
  team,
  csrfToken,
  confirm,
  customFieldValues,
  customFieldValueChanged
}) => (
  customFieldValues.sort((a, b) => a.custom_field.position - b.custom_field.position).map((customFieldValue, index) =>
    <React.Fragment key={index}>
      {customFieldValue.type === "CustomFieldValues::Address" ? (
        <AddressCustomFieldValue team={team}
                                 csrfToken={csrfToken}
                                 customFieldValue={customFieldValue}
                                 customFieldValueChanged={customFieldValueChanged}
                                 confirm={confirm} />
      ) : customFieldValue.type === "CustomFieldValues::Phone" ? (
        <PhoneCustomFieldValue team={team}
                               csrfToken={csrfToken}
                               customFieldValue={customFieldValue}
                               customFieldValueChanged={customFieldValueChanged}
                               confirm={confirm} />
      ) : null}
    </React.Fragment>
  )
);

CustomFieldValueFields.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  customFieldValues: PropTypes.array,
  customFieldValueChanged: PropTypes.func.isRequired
};

export default CustomFieldValueFields;
