// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OfferV2Modal from '../components/OfferV2Modal';
import * as actions from '../../OfferV2Modal/actions/offerV2ModalActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  venuesOptions: state.venuesOptions,
  onClose: state.onClose,
  offer: state.offer,
  venueSelected: state.venueSelected,
  calendarEvent: state.calendarEvent,
  performers: state.performers,
  selectedArtist: state.selectedArtist,
  pipeline: state.pipeline,
  venueOwnerships: state.venueOwnerships
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (onClose) => dispatch(actions.closeModal(dispatch, onClose)),
    createCalendarEventChanged: (createCalendarEvent) => dispatch(actions.createCalendarEventChanged(createCalendarEvent)),
    eventNameChanged: (name) => dispatch(actions.eventNameChanged(name)),
    eventTemplateChanged: (eventTemplate) => dispatch(actions.eventTemplateChanged(eventTemplate)),
    venueIdChanged: (venueId) => dispatch(actions.venueIdChanged(venueId)),
    startTimeChanged: (date) => dispatch(actions.startTimeChanged(date)),
    primaryArtistChanged: (artist) => dispatch(actions.primaryArtistChanged(artist)),
    calendarEventChanged: (calendarEvent) => dispatch(actions.calendarEventChanged(calendarEvent)),
    fetchCalendarEventPerformers: (team, calendarEventId) => dispatch(actions.fetchCalendarEventPerformers(dispatch, team, calendarEventId)),
    performerIdChanged: (performerId) => dispatch(actions.performerIdChanged(performerId)),
    fetchArtistData: (artistId) => dispatch(actions.fetchArtistData(dispatch, artistId)),
    clearSelectedArtist: () => dispatch(actions.clearSelectedArtist()),
    createOffer: (csrfToken, team, offer, pipeline) => dispatch(actions.createOffer(dispatch, csrfToken, team, offer, pipeline)),
    performersChanged: (performers) => dispatch(actions.performersChanged(performers))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OfferV2Modal);
