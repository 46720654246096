// Simple example of a React "smart" component

import { connect } from 'react-redux';
import PushNotifications from '../components/PushNotifications';
import * as actions from '../actions/pushNotificationsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  dataTableTitle: state.dataTableTitle,
  dataTableDataSource: state.dataTableDataSource,
  dataTableOrder: state.dataTableOrder,
  dataTableColumns: state.dataTableColumns,
  dataTableEl: state.dataTableEl,
  searchedSearchTerm: state.searchedSearchTerm,
  pushNotification: state.pushNotification,
  venueOwnerships: state.venueOwnerships,
  isSubmitting: state.isSubmitting,
  estimatedNumberOfSends: state.estimatedNumberOfSends,
  hasPushNotifications: state.hasPushNotifications
});

const mapDispatchToProps = (dispatch) => {
  return {
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    fetchNewPushNotification: (csrfToken, team) => dispatch(actions.fetchNewPushNotification(dispatch, csrfToken, team)),
    pushNotificationChanged: (pushNotification) => dispatch(actions.pushNotificationChanged(pushNotification)),
    createPushNotification: (csrfToken, team, pushNotification, dataTableEl) => dispatch(actions.createPushNotification(dispatch, csrfToken, team, pushNotification, dataTableEl)),
    fetchEstimatedNumberOfSends: (team, pushNotification) => dispatch(actions.fetchEstimatedNumberOfSends(dispatch, team, pushNotification)),
    estimatedNumberOfSendsChanged: (estimatedNumberOfSends) => dispatch(actions.estimatedNumberOfSendsChanged(estimatedNumberOfSends))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(PushNotifications);
