import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import RenderVideo from './PageDetails/RenderVideo';
import ConfirmDescription from './ConfirmDescription';
import Flatpickr from "react-flatpickr";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

import {
  offsetDateForBrowser,
} from '../../../shared/timeZoneLogic';

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

const renderablePageDetails  = (pageDetails) => {
  return pageDetails.filter((pd) => pd.renderable);
}

const ticketTypePrice = (ticketType) => {
  if(ticketType.free){
    return "FREE"
  } else {
    return formatter.format(parseFloat(ticketType.price));
  }
}

const ticketTypesSummary = (ticketTypes) => {
  var sorted = ticketTypes.sort((a, b) => {
    return parseFloat(a.price) - parseFloat(b.price)
  });

  if(sorted.length === 1){
    return <span>{ticketTypePrice(sorted[0])}</span>;
  } else {
    var firstTicketType = sorted[0];
    var lastTicketType = sorted[sorted.length - 1];

    return <span>{ticketTypePrice(firstTicketType)} - {ticketTypePrice(lastTicketType)}</span>;
  }
}

const canPublishConfirm = (confirm, ticketTypes) => {
  return (
    confirm
      && confirm.published_at
      && confirm.venue_capacity
      && parseInt(confirm.venue_capacity) >= 0
      && confirm.age_restriction
      && confirm.age_restriction.length > 0
      && (
        confirm.ticketing_arrangement === "General Admission"
          || (confirm.ticketing_arrangement === "Seated" && confirm.seating_chart_id)
      )
  );
}

const Publish = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  ticketTypes,
  pageDetails,
  updateConfirm,
  publishConfirm,
  rootUrl
}) => {
  useEffect(() => {
    if(!confirm.published_at) {
      updateConfirm(Object.assign({}, confirm, {is_public: false}));
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <h3 style={{"fontSize": "22px"}}>
          <strong>
            Publish Your Event
          </strong>
        </h3>
        <div className="row"
             style={{"marginTop": "40px"}}>
          <div className="col-12 col-xl-6 mb-4">
            {confirm.compressed_main_event_image_url ? (
              <img src={confirm.compressed_main_event_image_url}
                   width="100%" />
            ) : null}
          </div>
          <div className="col-12 col-xl-6 mb-4">
            {confirm.presenter && confirm.presenter.length > 0 ? (
              <div className="extra-small mb-1"
                   style={{
                     "fontWeight": "700",
                     "color": "#808080",
                     "letterSpacing": '1px',
                     "textTransform": "uppercase"
                   }}>
                {confirm.presenter}
              </div>
            ) : null}
            <div style={{"fontSize": "21px"}}>
              <strong>{confirm.title}</strong>
            </div>
            {confirm.support_artist_names.length > 0 ? (
              <div className="text-muted mb-1">
                with {confirm.support_artist_names}
              </div>
            ) : null}
            <div className="text-muted mb-1">
              {confirm.human_date_and_time_with_day_of_week}
            </div>
            <div className="text-muted small mb-1">
              {confirm.venue.address_from_components}
            </div>
            {ticketTypes.length > 0 ? (
              <div className="text-muted small mb-1">
                <i className="fal fa-ticket mr-2"></i>
                {ticketTypesSummary(ticketTypes)}
              </div>
            ) : null}
          </div>
        </div>
        {renderablePageDetails(pageDetails).length > 0 ? (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <hr className="mb-4 mt-0"
                    style={{"borderTop": "1px solid #ECECEC"}} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ConfirmDescription pageDetails={pageDetails}
                                    rootUrl={rootUrl} />
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div className="row">
          <div className="col-12">
            <hr className="my-3"
                style={{"borderTop": "1px solid #ececec"}} />
            <h3 className="mb-3"
                style={{"fontSize": "20px"}}>
              <strong>
                Who can see your event?
              </strong>
            </h3>
            <div className="custom-control custom-radio">
              <input type="radio"
                     id="customRadio1"
                     name="customRadio"
                     defaultChecked={confirm.published_at && confirm.is_public}
                     onChange={
                       (e) => {
                        var updated = Object.assign({}, confirm, {
                          is_public: true,
                          published_at: null
                        });
                         updateConfirm(updated);
                       }
                     }
                     className="custom-control-input" />
              <label className="custom-control-label"
                     style={{"fontSize": "14px"}}
                     htmlFor="customRadio1">
                Public
              </label>
              <p className="text-muted small"
                 style={{"marginBottom": "13px"}}>
                Shared across all networks, including Opendate and embedded.
              </p>
              {confirm.is_public ? (
                <div
                  style={{"width": "300px"}}
                  className="flatpickr-date-input px-2 d-flex align-items-center mb-3"
                >
                  <div className="pr-2" style={{"fontSize": "18px"}}>
                    <i className="far fa-calendar text-primary"></i>
                  </div>
                  <Flatpickr
                    data-enable-time
                    options={{
                      dateFormat: "F j, Y - h:i K"
                    }}
                    placeholder={"Select date and time"}
                    value={confirm.published_at}
                    onChange={dates => {
                      var updated = Object.assign({}, confirm, {published_at: dates[0]});
                      updateConfirm(updated);
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="custom-control custom-radio">
              <input type="radio"
                     id="customRadio2"
                     name="customRadio"
                     defaultChecked={confirm.published_at && !confirm.is_public}
                     onChange={
                       (e) => {
                         var updated = Object.assign({}, confirm, {
                           is_public: false,
                           published_at: offsetDateForBrowser(new Date())
                         });

                         updateConfirm(updated);
                       }
                     }
                     className="custom-control-input" />
              <label className="custom-control-label"
                     style={{"fontSize": "14px"}}
                     htmlFor="customRadio2">
                Private
              </label>
              <p className="text-muted small">
                Only available to a select audience
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end align-items-end"
               style={{"height": "130px"}}>
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();

                   if(canPublishConfirm(confirm, ticketTypes)){
                     publishConfirm(csrfToken, team, confirm);
                   }
                 }
               }
               className={"btn btn-primary " + (canPublishConfirm(confirm, ticketTypes) ? "" : "disabled")}>
              <strong>Publish</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Publish.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array,
  pageDetails: PropTypes.array,
  updateConfirm: PropTypes.func.isRequired,
  publishConfirm: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired
};

export default Publish;
