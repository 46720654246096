import React, { useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import { toastError } from "../../../shared/toastHelper";

const axios = require('axios').default;

const commonTagClasses = "small rounded-pill btn mb-2 mr-2 py-1 px-3 font-weight-bold";

const TagFilter = ({
  team,
  csrfToken,
  scope,
  currentTags,
  onChange,
}) => {
  const [tags, setTags] = useState([]);
  const [activeTags, setActiveTags] = useState(currentTags);
  const [tagFilter, setTagFilter] = useState("");

  const fetchTags = () => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get(`/teams/${team.id}/tags`, {
      params: { scope: scope }
    })
      .then(({ data }) => setTags(data))
      .catch(error => toastError("Something went wrong. Try again."));
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return(
    <div className="p-3 text-left">
      <div className="input-group">
        <div className="input-group-prepend">
          <span
            className="input-group-text bg-white border-right-0"
            style={{borderColor: "#e8e8e8"}}
          >
            <i className="fa fa-search"></i>
          </span>
        </div>
        <input
          type="text"
          id="tag-filter"
          className="form-control pl-1 small border-left-0 shadow-none"
          style={{borderColor: "#e8e8e8"}}
          placeholder="Search tags"
          value={tagFilter}
          onChange={e => setTagFilter(e.target.value)}
        />
      </div>
      <div className="mt-3">
        {tags
          .filter(tag => tag.name.toLowerCase().includes(tagFilter.toLowerCase()))
          .map(tag => {
            const style = activeTags.includes(tag.name) ? {
              color: "#FFF",
              backgroundColor: "#1982C4",
            } : {
              color: "#1982C4",
              backgroundColor: "#e8f3f9",
            };

            return(
              <div
                key={tag.name}
                className={commonTagClasses}
                style={style}
                onClick={() => {
                  if(activeTags.includes(tag.name)){
                    const newActiveTags = activeTags.filter(activeTag => activeTag !== tag.name);

                    setActiveTags(newActiveTags);
                    onChange(newActiveTags);
                  } else {
                    const newActiveTags = [...activeTags, tag.name];

                    setActiveTags(newActiveTags);
                    onChange(newActiveTags);
                  }
                }}
              >
                {tag.name}
              </div>
            );
          })
        }
      </div>
    </div>
  )
}

TagFilter.propTypes = {
};

export default TagFilter;
