import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import RichEditor from '../../../RichEditor/components/RichEditor';
import PageDetailTemplates from '../../../PageDetailTemplates/components/PageDetailTemplates';

var debouncedAutoSavePageDetail;

const TextPageDetail = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  pageDetail,
  pageDetailChanged,
  autoSavePageDetail,
  artistBios,
  artistBiosChanged,
  pageDetailTemplates,
  deletePageDetailTemplate,
  pageDetailTemplate,
  pageDetailTemplateChanged,
  createPageDetailTemplate
}) => {
  useEffect(() => {
    debouncedAutoSavePageDetail = _.debounce((csrfToken, team, pageDetail) => {
      autoSavePageDetail(csrfToken, team, pageDetail);
    }, 600);
  }, []);

  return (
    <RichEditor
      editorState={pageDetail.body}
      toolbarCustomButtons={[
        <PageDetailTemplates csrfToken={csrfToken}
                             team={team}
                             currentUser={currentUser}
                             artistBios={artistBios}
                             artistBiosChanged={artistBiosChanged}
                             pageDetailTemplates={pageDetailTemplates}
                             deletePageDetailTemplate={deletePageDetailTemplate}
                             pageDetailTemplate={pageDetailTemplate}
                             pageDetailTemplateChanged={pageDetailTemplateChanged}
                             createPageDetailTemplate={createPageDetailTemplate}
                             confirm={confirm} />
      ]}
      onEditorStateChange={
        (editorState) => {
          var updated = Object.assign({}, pageDetail, {
            body: editorState
          });

          pageDetailChanged(updated);
          debouncedAutoSavePageDetail(csrfToken, team, updated);
        }
      } />
  );
};

TextPageDetail.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  pageDetail: PropTypes.object.isRequired,
  pageDetailChanged: PropTypes.func.isRequired,
  autoSavePageDetail: PropTypes.func.isRequired,
  artistBios: PropTypes.object,
  artistBiosChanged: PropTypes.func.isRequired,
  pageDetailTemplates: PropTypes.array,
  deletePageDetailTemplate: PropTypes.func.isRequired,
  pageDetailTemplate: PropTypes.object,
  pageDetailTemplateChanged: PropTypes.func.isRequired,
  createPageDetailTemplate: PropTypes.func.isRequired
};

export default TextPageDetail;
