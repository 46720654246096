import { combineReducers } from 'redux';

import {
  ARTIST_CHANGED,
  TAG_MANAGER_OPEN_CHANGED,
  TAG_MANAGER_FILTER_CHANGED,
  AVAILABLE_TAGS_CHANGED
} from '../constants/artistDetailsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const artist = (state = {}, action) => {
  switch (action.type) {
    case ARTIST_CHANGED:
      return action.artist;
    default:
      return state;
  }
};

const tagManagerOpen = (state = false, action) => {
  switch (action.type) {
    case TAG_MANAGER_OPEN_CHANGED:
      return action.tagManagerOpen;
    default:
      return state;
  }
};

const tagManagerFilter = (state = "", action) => {
  switch (action.type) {
    case TAG_MANAGER_FILTER_CHANGED:
      return action.tagManagerFilter;
    default:
      return state;
  }
};

const availableTags = (state = [], action) => {
  switch (action.type) {
    case AVAILABLE_TAGS_CHANGED:
      return action.availableTags;
    default:
      return state;
  }
};

const artistDetailsReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  artist,
  tagManagerOpen,
  tagManagerFilter,
  availableTags,
});

export default artistDetailsReducer;
