/* eslint-disable import/prefer-default-export */

import {
  SELECTED_ARTIST_CHANGED,
  POTENTIAL_ARTIST_FAN_DATA_CHANGED,
  IS_LOADING_POTENTIAL_ARTIST_FAN_DATA_CHANGED,
  IS_LOADING_GEO_COORDINATES_CHANGED,
  GEO_COORDINATES_CHANGED,
  IS_LOADING_TOP_GENRES_CHANGED,
  TOP_GENRES_CHANGED
} from '../constants/fanDashboardConstants';

const axios = require('axios').default;

export const selectedArtistChanged = (selectedArtist) => ({
  type: SELECTED_ARTIST_CHANGED,
  selectedArtist
});

export const potentialArtistFanDataChanged = (potentialArtistFanData) => ({
  type: POTENTIAL_ARTIST_FAN_DATA_CHANGED,
  potentialArtistFanData
});

export const fetchPotentialArtistFanData = (dispatch, team, artistId) => {
  return dispatch => {
    dispatch(isLoadingPotentialArtistFanDataChanged(true));

    return axios.get("/teams/" + team.id + "/fans/potential_artist_fans.json", {
        params: {
          artist_id: artistId
        }
      })
      .then(({ data }) => {
        dispatch(potentialArtistFanDataChanged(data));
      })
      .finally(() => {
        dispatch(isLoadingPotentialArtistFanDataChanged(false));
      });
  };
};

export const isLoadingPotentialArtistFanDataChanged = (isLoadingPotentialArtistFanData) => ({
  type: IS_LOADING_POTENTIAL_ARTIST_FAN_DATA_CHANGED,
  isLoadingPotentialArtistFanData
});

export const isLoadingGeoCoordinatesChanged = (isLoadingGeoCoordinates) => ({
  type: IS_LOADING_GEO_COORDINATES_CHANGED,
  isLoadingGeoCoordinates
});

export const fetchGeoCoordinates = (dispatch, team) => {
  return dispatch => {
    dispatch(isLoadingGeoCoordinatesChanged(true));

    return axios.get("/teams/" + team.id + "/fans/geo_coordinates", {})
      .then(({ data }) => {
        dispatch(geoCoordinatesChanged(data));
      })
      .finally(() => {
        dispatch(isLoadingGeoCoordinatesChanged(false));
      });
  };
};

export const geoCoordinatesChanged = (geoCoordinates) => ({
  type: GEO_COORDINATES_CHANGED,
  geoCoordinates
});

export const isLoadingTopGenresChanged = (isLoadingTopGenres) => ({
  type: IS_LOADING_TOP_GENRES_CHANGED,
  isLoadingTopGenres
});

export const fetchTopGenres = (dispatch, team) => {
  return dispatch => {
    dispatch(isLoadingTopGenresChanged(true));

    return axios.get("/teams/" + team.id + "/fans/top_genres", {})
      .then(({ data }) => {
        dispatch(topGenresChanged(data));
      })
      .finally(() => {
        dispatch(isLoadingTopGenresChanged(false));
      });
  };
};

export const topGenresChanged = (topGenres) => ({
  type: TOP_GENRES_CHANGED,
  topGenres
});
