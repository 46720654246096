import PropTypes from 'prop-types';
import React from 'react';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

export class MapWithMarker extends React.Component {
  static propTypes = {
    lat: PropTypes.string.isRequired,
    lon: PropTypes.string.isRequired,
    height: PropTypes.string
  };

  static defaultProps = {
    height: "200px"
  };

  constructor(props) {
    super(props);

    this.state = {
      lat: this.props.lat,
      lon: this.props.lon,
      height: this.props.height
    };
  }

  render() {
    const style = {
      position: 'relative',
      width: '100%',
      height: '100%'
    }

    return (
      <div style={{height: this.state.height}}>
        <Map google={this.props.google}
             style={style}
             zoomControl={false}
             mapTypeControl={false}
             scaleControl={false}
             streetViewControl={false}
             panControl={false}
             rotateControl={false}
             fullscreenControl={false}
             initialCenter={{
               lat: this.state.lat,
               lng: this.state.lon
             }}
             zoom={15} >
          <Marker
            position={{lat: this.state.lat, lng: this.state.lon}} />
          <Marker />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: (gon.google_api_key)
})(MapWithMarker)
