import PropTypes from 'prop-types';
import React from 'react';

import AddOnSeatingChart from './../../AddOnSeatingChart/components/AddOnSeatingChart';

const ChooseSeatedAddOns = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  addOns,
  buildTicketReservation,
  visibleAddOns,
  promoCode,
  addOnsChanged,
  ticketReservation,
  userContext,
  postToParent,
  formatItemsForGoogleTagManager,
  seatsIOPublicKey,
  selectedObjectLabel,
  selectedObjectType,
  calculateDiscountedAddOnPrice,
  discountedAddOnPrice
}) => {
  const hasAddOnWithSharedInventory = (addOns) => {
    return addOns.some((ao) => ao.share_inventory_with_id);
  };

  return (
    hasAddOnWithSharedInventory(addOns) ? (
      addOns.filter((ao) => ao.seating_chart_category_key).sort((a, b) => a.name.localeCompare(b.name)).map((addOn, index) =>
        <AddOnSeatingChart team={team}
                          key={index}
                          csrfToken={csrfToken}
                          confirm={confirm}
                          ticketTypes={ticketTypes}
                          addOns={addOns}
                          visibleAddOns={visibleAddOns}
                          promoCode={promoCode}
                          addOnsChanged={addOnsChanged}
                          ticketReservation={ticketReservation}
                          postToParent={postToParent}
                          formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                          seatsIOPublicKey={seatsIOPublicKey}
                          selectedObjectLabel={selectedObjectLabel}
                          selectedObjectType={selectedObjectType}
                          buildTicketReservation={
                            (updated) => {
                              buildTicketReservation(csrfToken, confirm, ticketTypes, promoCode, updated, ticketReservation, userContext)
                            }
                          }
                          userContext={userContext}
                          addOnsToSell={[addOn]}
                          sharedInventoryMode={true}
                          discountedAddOnPrice={discountedAddOnPrice}
                          calculateDiscountedAddOnPrice={calculateDiscountedAddOnPrice} />
      )
    ) : (
      <AddOnSeatingChart team={team}
                          csrfToken={csrfToken}
                          confirm={confirm}
                          ticketTypes={ticketTypes}
                          addOns={addOns}
                          visibleAddOns={visibleAddOns}
                          promoCode={promoCode}
                          addOnsChanged={addOnsChanged}
                          ticketReservation={ticketReservation}
                          postToParent={postToParent}
                          formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                          seatsIOPublicKey={seatsIOPublicKey}
                          selectedObjectLabel={selectedObjectLabel}
                          selectedObjectType={selectedObjectType}
                          buildTicketReservation={
                            (updated) => {
                              buildTicketReservation(csrfToken, confirm, ticketTypes, promoCode, updated, ticketReservation, userContext)
                            }
                          }
                          userContext={userContext}
                          addOnsToSell={addOns}
                          sharedInventoryMode={false}
                          discountedAddOnPrice={discountedAddOnPrice}
                          calculateDiscountedAddOnPrice={calculateDiscountedAddOnPrice} />
    )
  );
}

ChooseSeatedAddOns.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  addOns: PropTypes.array.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  visibleAddOns: PropTypes.array,
  promoCode: PropTypes.object,
  addOnsChanged: PropTypes.func.isRequired,
  ticketReservation: PropTypes.object,
  userContext: PropTypes.object,
  postToParent: PropTypes.func.isRequired,
  formatItemsForGoogleTagManager: PropTypes.func.isRequired,
  seatsIOPublicKey: PropTypes.string.isRequired,
  selectedObjectLabel: PropTypes.func.isRequired,
  selectedObjectType: PropTypes.func.isRequired,
  calculateDiscountedAddOnPrice: PropTypes.func.isRequired,
  discountedAddOnPrice: PropTypes.func.isRequired
};

export default ChooseSeatedAddOns;
