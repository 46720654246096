/* eslint-disable import/prefer-default-export */

import {
  VENUE_OWNERSHIP_REMOVED,
  VENUE_OWNERSHIP_ADDED,
  MANUAL_VENUE_ENTRY_CLICKED,
  VENUE_SUGGESTION_NAME_CHANGED,
  VENUE_SUGGESTION_ADDRESS_ONE_CHANGED,
  VENUE_SUGGESTION_ADDRESS_TWO_CHANGED,
  VENUE_SUGGESTION_CITY_CHANGED,
  VENUE_SUGGESTION_STATE_CHANGED,
  VENUE_SUGGESTION_ZIP_CHANGED,
  VENUE_SUGGESTION_ERRORS_CHANGED,
  VENUE_SUGGESTION_CREATION_SUCCESS,
  OPEN_MODAL,
  CLOSE_MODAL,
  CANCEL_EDIT_VENUE_OWNERSHIP,
  VENUE_OWNERSHIP_BIO_CHANGED,
  VENUE_OWNERSHIP_LOGO_EL_CHANGED,
  VENUE_OWNERSHIP_LOGO_CHANGED,
  VENUE_OWNERSHIP_TICKET_LOGO_EL_CHANGED,
  VENUE_OWNERSHIP_TICKET_LOGO_CHANGED,
  VENUE_OWNERSHIP_CHANGED,
  VENUE_OWNERSHIP_LOGO_REMOVED,
  VENUE_OWNERSHIP_TICKET_LOGO_REMOVED,
  VENUE_OWNERSHIP_ERRORS_CHANGED
} from '../constants/venueManagerConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const deleteVenueOwnership = (dispatch, csrfToken, team, venueOwnership) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/venue_ownerships/" + venueOwnership.id)
      .then(({ data }) => {
        dispatch(venueOwnershipRemoved(data));

        toast.success("The venue was removed from your account", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });

  };
};

export const venueOwnershipRemoved = (venueOwnership) => ({
  type: VENUE_OWNERSHIP_REMOVED,
  venueOwnership
});

export const createVenueOwnership = (dispatch, csrfToken, team, venueableId, venueableType) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    toast.success("Adding venue to your account. One moment...", {
      position: toast.POSITION.TOP_CENTER,
      draggable: false,
      closeOnClick: false,
      autoClose: 5000,
      hideProgressBar: true
    });

    return axios.post("/teams/" + team.id + "/venue_ownerships", {
        venue_ownership: {
          venueable_id: venueableId,
          venueable_type: venueableType
        }
      })
      .then(({ data }) => {
        dispatch(venueOwnershipAdded(data));
      });
  };
};

export const venueOwnershipAdded = (venueOwnership) => ({
  type: VENUE_OWNERSHIP_ADDED,
  venueOwnership
});

export const manualVenueEntryClicked = () => ({
  type: MANUAL_VENUE_ENTRY_CLICKED
});

export const venueSuggestionNameChanged = (name) => ({
  type: VENUE_SUGGESTION_NAME_CHANGED,
  name
});

export const venueSuggestionAddressOneChanged = (address1) => ({
  type: VENUE_SUGGESTION_ADDRESS_ONE_CHANGED,
  address1
});

export const venueSuggestionAddressTwoChanged = (address2) => ({
  type: VENUE_SUGGESTION_ADDRESS_TWO_CHANGED,
  address2
});

export const venueSuggestionCityChanged = (city) => ({
  type: VENUE_SUGGESTION_CITY_CHANGED,
  city
});

export const venueSuggestionStateChanged = (state) => ({
  type: VENUE_SUGGESTION_STATE_CHANGED,
  state
});

export const venueSuggestionZipChanged = (zip) => ({
  type: VENUE_SUGGESTION_ZIP_CHANGED,
  zip
});

export const venueSuggestionErrorsChanged = (errors) => ({
  type: VENUE_SUGGESTION_ERRORS_CHANGED,
  errors
});

export const venueSuggestionCreationSuccess = () => ({
  type: VENUE_SUGGESTION_CREATION_SUCCESS
});

export const createVenueSuggestion = (dispatch, csrfToken, team, venueSuggestion) => {
  dispatch(venueSuggestionErrorsChanged([]))

  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/venues", {
        venue: {
          name: venueSuggestion.name,
          address1: venueSuggestion.address1,
          address2: venueSuggestion.address2,
          city: venueSuggestion.city,
          state_code: venueSuggestion.state,
          zip: venueSuggestion.zip
        }
      })
      .then(({ data }) => {
        dispatch(venueSuggestionCreationSuccess());
        dispatch(createVenueOwnership(dispatch, csrfToken, team, data.id, "Venue"))
      })
      .catch((error) => {
        dispatch(venueSuggestionErrorsChanged(error.response.data));
      });
  };
};

export const openModal = () => ({
  type: OPEN_MODAL
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});

export const cancelEditVenueOwnership = () => ({
  type: CANCEL_EDIT_VENUE_OWNERSHIP
});

export const venueOwnershipBioChanged = (index, bio) => ({
  type: VENUE_OWNERSHIP_BIO_CHANGED,
  index,
  bio
});

export const venueOwnershipLogoElChanged = (index, logoEl) => ({
  type: VENUE_OWNERSHIP_LOGO_EL_CHANGED,
  index,
  logoEl
});

export const venueOwnershipLogoChanged = (index, logo) => ({
  type: VENUE_OWNERSHIP_LOGO_CHANGED,
  index,
  logo
});

export const venueOwnershipTicketLogoElChanged = (index, ticketLogoEl) => ({
  type: VENUE_OWNERSHIP_TICKET_LOGO_EL_CHANGED,
  index,
  ticketLogoEl
});

export const venueOwnershipTicketLogoChanged = (index, ticketLogo) => ({
  type: VENUE_OWNERSHIP_TICKET_LOGO_CHANGED,
  index,
  ticketLogo
});

export const fetchVenueOwnership = (dispatch, csrfToken, team, venueOwnershipId) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get("/teams/" + team.id + "/venue_ownerships/" + venueOwnershipId)
      .then(({ data }) => {
        var venueOwnership = Object.assign({}, data, {isEditing: true});
        dispatch(venueOwnershipChanged(venueOwnership));
      });
  };
};

export const venueOwnershipChanged = (venueOwnership) => ({
  type: VENUE_OWNERSHIP_CHANGED,
  venueOwnership
});

export const venueOwnershipLogoRemoved = (index) => ({
  type: VENUE_OWNERSHIP_LOGO_REMOVED,
  index
});

export const venueOwnershipTicketLogoRemoved = (index) => ({
  type: VENUE_OWNERSHIP_TICKET_LOGO_REMOVED,
  index
});

export const venueOwnershipErrorsChanged = (venueOwnership, errors) => ({
  type: VENUE_OWNERSHIP_ERRORS_CHANGED,
  venueOwnership,
  errors
});

export const updateVenueOwnership = (dispatch, csrfToken, team, venueOwnership) => {
  dispatch(venueOwnershipErrorsChanged(venueOwnership, []));

  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var params = new FormData();
    params.append("venue_ownership[time_zone]", venueOwnership.time_zone);

    if(venueOwnership.bio && venueOwnership.bio.length > 0){
      params.set('venue_ownership[bio]', venueOwnership.bio);
    } else {
      params.set('venue_ownership[bio]', "");
    }

    if(venueOwnership.phone && venueOwnership.phone.length > 0){
      var nonDigit = /\D/ig;
      params.set('venue_ownership[phone]', venueOwnership.phone.replaceAll(nonDigit, ""));
    } else {
      params.set('venue_ownership[phone]', "");
    }

    if(venueOwnership.email && venueOwnership.email.length > 0){
      params.set('venue_ownership[email]', venueOwnership.email);
    } else {
      params.set('venue_ownership[email]', "");
    }

    if(venueOwnership.logo){
      params.append("venue_ownership[logo]", venueOwnership.logo);
    }

    if(venueOwnership.ticketLogo){
      params.append("venue_ownership[ticket_logo]", venueOwnership.ticketLogo);
    }

    if(venueOwnership.destroy_logo){
      params.set('venue_ownership[destroy_logo]', venueOwnership.destroy_logo);
    }

    if(venueOwnership.destroy_ticket_logo){
      params.set('venue_ownership[destroy_ticket_logo]', venueOwnership.destroy_ticket_logo);
    }

    return axios.patch("/teams/" + team.id + "/venue_ownerships/" + venueOwnership.id, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        dispatch(venueOwnershipChanged(data));
      })
      .catch((error) => {
        dispatch(venueOwnershipErrorsChanged(venueOwnership, error.response.data));
      });
  };
};
