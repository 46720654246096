import React, { useRef } from "react";

const htmlViewerContainerStyle = {
  height: "60vh",
  maxHeight: "75%",
  padding: "15px",
  margin: "15px",
  border: "1px solid #EEEEEE",
};

const errorsContainer = {
  backgroundColor: "#FF5A5F",
  borderRadius: "5px",
  color: "#FFF",
  marginBottom: "10px",
  padding: "10px",
};

const htmlContentStyle = {
  border: "none",
  height: "100%",
  overflow: "scroll",
  width: "100%",
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 6fr",
  gap: "5px",
  paddingVertical: "10px",
};

const labelStyle = {
  textAlign: "right",
  fontWeight: "bold",
}

const AhoyMessageShow = ({
  ahoyMessage
}) => {
  const iframeRef = useRef(null);

  const updateLinks = () => {
    const iframe = iframeRef.current;

    if (iframe && iframe.contentDocument) {
      const iframeLinks = iframe.contentDocument.querySelectorAll("a");

      iframeLinks.forEach(link => {
        link.setAttribute("target", "_blank");
      });
    }
  };

  return (
    <div className="card border-0">
      <div className="card-body">
        {ahoyMessage.failure_reasons.length > 0 ? (
          <div style={errorsContainer}>
            This delivery failed to send because of the following errors:
            {ahoyMessage.failure_reasons.map((reason, index) => (
              <li key={index}>{reason}</li>
            ))}
          </div>
        ) : null}
        <div style={gridStyle}>
          <>
            <div style={labelStyle}>From:</div>
            <div>{ahoyMessage.email?.from}</div>
          </>
          <>
            <div style={labelStyle}>To:</div>
            <div>{`${ahoyMessage.user?.name} <${ahoyMessage.email?.to}>`}</div>
          </>
          <>
            <div style={labelStyle}>Subject:</div>
            <div>{ahoyMessage.email?.subject}</div>
          </>
        </div>
        <div style={gridStyle}>
          {ahoyMessage.human_timestamps ? (
            <>
              {ahoyMessage.human_timestamps.sent_at ? (
                <>
                  <div style={labelStyle}>Sent at</div>
                  <div>{ahoyMessage.human_timestamps.sent_at}</div>
                </>
              ) : null}
              {ahoyMessage.human_timestamps.opened_at ? (
                <>
                  <div style={labelStyle}>Opened at</div>
                  <div>{ahoyMessage.human_timestamps.opened_at}</div>
                </>
              ) : null}
              {ahoyMessage.human_timestamps.clicked_at ? (
                <>
                  <div style={labelStyle}>Clicked at</div>
                  <div>{ahoyMessage.human_timestamps.clicked_at}</div>
                </>
              ) : null}
              {ahoyMessage.human_timestamps.failed_at ? (
                <>
                  <div style={labelStyle}>Failed at</div>
                  <div>{ahoyMessage.human_timestamps.failed_at}</div>
                </>
              ) : null}
            </>
          ) : null}
        </div>
        <div>
          <div>
            <div style={htmlViewerContainerStyle}>
              <iframe
                ref={iframeRef}
                srcDoc={ahoyMessage.email?.html_body}
                style={htmlContentStyle}
                onLoad={updateLinks}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AhoyMessageShow;
