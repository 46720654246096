import React from "react";

import CardFooter from "./CardFooter";

const cardStyle = {
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  cursor: "pointer",
  border: "2px solid #CCCCCC",
};

const cardTopStyle = {
  alignItems: "baseline",
  flex: 1,
  display: "flex",
  backgroundColor: "white",
  margin: "10x",
  padding: "15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const headerStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  fontWeight: "700",
  height: "25px",
  justifyContent: "space-between",
  width: "100%",
};

const headerTitleStyle = {
  justifyContent: "flex-start",
  textWrap: "nowrap",
};

const headerIconStyle = {
  color: "#333333",
  marginRight: "5px",
};

const descriptionStyle = {
  color: "#5C5C5C",
  fontSize: "10px",
  fontWeight: "lighter",
};

const AvailableCampaignCard = ({
  campaignTemplate,
  handleClick,
  styleOverrides = {},
}) => {
  return (
    <div
      style={{
        ...cardStyle,
        ...styleOverrides,
      }}
      onClick={handleClick}>
      <div style={cardTopStyle}>
        <div style={headerStyle}>
          <div style={headerTitleStyle}>
            <i className={`fa ${campaignTemplate.fa_icon}`} style={headerIconStyle} />
            {campaignTemplate.name}
          </div>
        </div>
        <div style={descriptionStyle}>
          {campaignTemplate.description}
        </div>
      </div>
      <CardFooter
        estimatedRevenue={campaignTemplate.estimated_revenue}
        tooltipCopy={campaignTemplate.tooltip_copy}
      />
    </div>
  );
};

export default AvailableCampaignCard;
