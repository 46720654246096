// Simple example of a React "smart" component

import { connect } from 'react-redux';
import SocialManager from '../components/SocialManager';
import * as actions from '../actions/socialManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  addingAccount: state.addingAccount,
  twitterProviderId: state.twitterProviderId,
  facebookProviderId: state.facebookProviderId,
  onepostPublicKey: state.onepostPublicKey,
  authorizedPages: state.authorizedPages
});

const mapDispatchToProps = (dispatch) => {
  return {
    addingAccountChanged: (addingAccount) => dispatch(actions.addingAccountChanged(addingAccount)),
    deleteAuthorizedPage: (team, csrfToken, authorizedPage) => dispatch(actions.deleteAuthorizedPage(dispatch, team, csrfToken, authorizedPage))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(SocialManager);
