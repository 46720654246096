import { combineReducers } from 'redux';
import { DateUtils } from 'react-day-picker';

import {
  BUYERS_CHANGED,
  PROMOTERS_CHANGED,
  CONFIRM_CHANGED,
  CONFIRM_SAVED,
  EVENT_DETAILS_CHANGED,
  HOLD_GROUP_HOLD_POSITION_UPDATED,
  HOLD_GROUP_HOLD_REMOVED,
  HOLD_GROUP_CALENDAR_DATE_CLICKED,
  HOLD_GROUP_HOLD_AVAILABILITY_UPDATED
} from '../constants/eventDetailsConstants';

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CHANGED:
      return action.confirm;
    default:
      return state;
  }
};

const confirmSaved = (state = (() => {}), action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const buyers = (state = [], action) => {
  switch(action.type) {
    case BUYERS_CHANGED:
      return action.buyers;
    default:
      return state;
  }
};

const promoters = (state = [], action) => {
  switch(action.type) {
    case PROMOTERS_CHANGED:
      return action.promoters;
    default:
      return state;
  }
};

const eventDetails = (state = {}, action) => {
  if(typeof action.date !== 'undefined'){
    var actionDate = new Date(offsetDateForBrowser(action.date));
  }

  switch (action.type) {
    case EVENT_DETAILS_CHANGED:
      return action.eventDetails;
    case HOLD_GROUP_CALENDAR_DATE_CLICKED:
      var holdGroupEntries = (state.holdGroup.hold_group_entries ? state.holdGroup.hold_group_entries : []);
      var holdGroupEntry   = holdGroupEntries.find((entry) => {
        var startDate = new Date(offsetDateForBrowser(entry.hold.start_time));

        return DateUtils.isSameDay(startDate, actionDate);
      });

      var newHoldGroupEntries;
      if (holdGroupEntry){
        newHoldGroupEntries = [...holdGroupEntries].map((entry) => {
          var startDate = new Date(offsetDateForBrowser(entry.hold.start_time));

          if(DateUtils.isSameDay(startDate, actionDate)){
            return Object.assign({}, entry, {_destroy: !entry._destroy});
          } else {
            return entry;
          }
        });
      } else {
        var newHoldGroupEntry   = {hold: {start_time: actionDate, position: 1}};
        newHoldGroupEntries = [...holdGroupEntries, newHoldGroupEntry];
      }

      var newHoldGroup = Object.assign({}, state.holdGroup, {hold_group_entries: newHoldGroupEntries});
      return Object.assign({}, state, {holdGroup: newHoldGroup});
    case HOLD_GROUP_HOLD_POSITION_UPDATED:
      var holdGroupEntries = [...state.holdGroup.hold_group_entries].map((holdGroupEntry) => {
        var startDate = new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));

        if(DateUtils.isSameDay(startDate, actionDate)){
          var hold = Object.assign({}, holdGroupEntry.hold, {position: action.position});
          return Object.assign({}, holdGroupEntry, {hold: hold});
        } else {
          return holdGroupEntry;
        }
      });

      var newHoldGroup = Object.assign({}, state.holdGroup, {hold_group_entries: holdGroupEntries});
      return Object.assign({}, state, {holdGroup: newHoldGroup});
    case HOLD_GROUP_HOLD_REMOVED:
      var holdGroupEntries = [...state.holdGroup.hold_group_entries].map((holdGroupEntry) => {
        var startDate = new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));

        if(DateUtils.isSameDay(startDate, actionDate)){
          return Object.assign({}, holdGroupEntry, {_destroy: true});
        } else {
          return holdGroupEntry;
        }
      });

      var newHoldGroup = Object.assign({}, state.holdGroup, {hold_group_entries: holdGroupEntries});
      return Object.assign({}, state, {holdGroup: newHoldGroup});
    case HOLD_GROUP_HOLD_AVAILABILITY_UPDATED:
      var holdGroupEntries = [...state.holdGroup.hold_group_entries].map((holdGroupEntry) => {
        var startDate = new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));

        if(DateUtils.isSameDay(startDate, actionDate)){
          var holdAvailability = action.holdAvailability;

          var defaultTopHold = action.team.default_top_hold;
          var firstAvailable = holdAvailability.slice(defaultTopHold-1, -1).find((availity) => {
            return availity.hold_title === null;
          });

          if(holdGroupEntry._destroy) {
            var hold = Object.assign({}, holdGroupEntry.hold)
          } else {
            var hold = Object.assign({}, holdGroupEntry.hold, {
              position: firstAvailable.position,
              hold_availability: holdAvailability
            });
          }

          return Object.assign({}, holdGroupEntry, {hold: hold});
        } else {
          return holdGroupEntry;
        }
      });

      var newHoldGroup = Object.assign({}, state.holdGroup, {hold_group_entries: holdGroupEntries});
      return Object.assign({}, state, {holdGroup: newHoldGroup});
    default:
      return state;
  }
}

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const financeItemsChanged = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const holdGroupDates = (state = [], action) => {
  switch (action.type) {
    case HOLD_GROUP_HOLD_REMOVED:
      return state.filter(date =>
        !DateUtils.isSameDay(date, action.date)
      );
    case HOLD_GROUP_CALENDAR_DATE_CLICKED:
      if (action.selected){
        return state.filter(date =>
          !DateUtils.isSameDay(date, action.date)
        );
      } else {
        return [...state, action.date];
      }
    case EVENT_DETAILS_CHANGED:
      if(action.eventDetails && action.eventDetails.holdGroup){
        return action.eventDetails.holdGroup.hold_group_entries.map((holdGroupEntry) => {
          return new Date(offsetDateForBrowser(holdGroupEntry.hold.start_time));
        });
      } else {
        return [];
      }
    default:
      return state;
  }
};

const eventDetailsReducer = combineReducers({
  csrfToken,
  team,
  confirm,
  confirmSaved,
  buyers,
  promoters,
  eventDetails,
  currentUser,
  financeItemsChanged,
  holdGroupDates
});

export default eventDetailsReducer;
