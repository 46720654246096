/* eslint-disable import/prefer-default-export */

import {
  OPEN_MODAL,
  CLOSE_MODAL,
  DATA_TABLE_EL_CHANGED,
  OFFER_CHANGED,
  PIPELINE_CHANGED,
  OFFER_TO_LINK_CHANGED
} from '../constants/offerManagerConstants';

import {
  serverJSONToLocal
} from '../../OfferModal/actions/offerModalActionCreators';

const axios = require('axios').default;

import $ from 'jquery';
require('datatables.net-bs4');

export const openModal = () => ({
  type: OPEN_MODAL
});

export const closeModal = (dispatch, defaultOffer, dataTableEl) => {
  return dispatch => {
    $(dataTableEl).DataTable().ajax.reload();

    var deepCopy = JSON.parse(JSON.stringify(defaultOffer));
    dispatch(updateOfferData(deepCopy));

    dispatch(broadcastCloseModal());
  };
};

export const broadcastCloseModal = () => ({
  type: CLOSE_MODAL
});

export const onInit = (dispatch, defaultOffer) => {
  return dispatch => {
    var deepCopy = JSON.parse(JSON.stringify(defaultOffer));
    dispatch(updateOfferData(deepCopy));
  }
};

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const fetchOfferData = (dispatch, team, offerId, frontEndState) => {
  return dispatch => {
    return axios.get(`/teams/${team.id}/offers/${offerId}.json`)
      .then(({ data }) => {
        var offer = serverJSONToLocal(data);
        offer = Object.assign({}, offer, {frontEndState: frontEndState});

        dispatch(updateOfferData(offer));
        dispatch(openModal());
      })
  };
};

export const deleteOffer = (dispatch, team, offerId, csrfToken, rowTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/offers/" + offerId)
      .then(({ data }) => {
        $(rowTableEl).DataTable().ajax.reload();
      })
  };
};

export const acceptOffer = (dispatch, team, offerId, csrfToken, rowTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offers/" + offerId + "/accept")
      .then(({ data }) => {
        $(rowTableEl).DataTable().ajax.reload();
      })
  };
};

export const declineOffer = (dispatch, team, offerId, csrfToken, rowTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offers/" + offerId + "/decline")
      .then(({ data }) => {
        $(rowTableEl).DataTable().ajax.reload();
      })
  };
};

export const updateOfferData = (offer) => ({
  type: OFFER_CHANGED,
  offer
});

export const fetchPipeline = (dispatch, team, pipelineId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/pipelines/" + pipelineId)
      .then(({ data }) => {
        dispatch(pipelineChanged(data));
      })
  };
};

export const pipelineChanged = (pipeline) => ({
  type: PIPELINE_CHANGED,
  pipeline
});

export const offerToLinkChanged = (offer) => ({
  type: OFFER_TO_LINK_CHANGED,
  offer
});
