import PropTypes from 'prop-types';
import React from 'react';

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

const ForecastColumn = ({
  show,
  values,
  showCurrencyConversion,
  globalCurrency,
  colgroup,
  header,
  top,
  bottom,
  height,
  item,
  calculatedBudget,
  calculatedRow,
  csrfToken,
  budget,
  team,
  lastRow,
  updatedBudgetWithBudgetLineItem,
  updateBudget,
  debouncedAutoSaveBudgetLineItem,
  formatCalculatedNumber,
  children
}) => {
  if(typeof(show) === "undefined") {
    show = false;
  }

  if(typeof(values) === "undefined") {
    values = null;
  }

  if(typeof(showCurrencyConversion) === "undefined") {
    showCurrencyConversion = false;
  }

  if(typeof(globalCurrency) === "undefined") {
    globalCurrency = null;
  }

  if(typeof(item) !== "undefined" && typeof(colgroup) === "undefined") {
    throw new Error("colgroup is required when item prop is passed");
  }

  if(item && typeof(calculatedBudget) === "undefined") {
    throw new Error("calculatedBudget is required when item prop is passed");
  }

  if(typeof(header) === "undefined") {
    header = false;
  }

  if(typeof(top) === "undefined") {
    top = false;
  }

  if(typeof(bottom) === "undefined") {
    bottom = false;
  }

  if(typeof(height) === "undefined") {
    height = "35px";
  }

  const cellClasses = "p-0 borderless";
  const cellStyles = {
    height: height
  };

  let width = !show ? "50px" : showCurrencyConversion ? "485px" : "355px";

  const innerDiv = (
    <div
      className={`p-0 h-100 ${top ? "pt-2 rounded-top text-center" : bottom ? "rounded-bottom" : ""}`}
      style={{
        width: width,
        marginLeft: "10px",
        backgroundColor: "#f4f9fc"
      }}
    >
      {!show ? (
        top ? children : <>&nbsp;</>
      ) : children ? (
        children
      ) : (
        <table
          className="border-0"
          style={{
            margin: "0 10px",
            width: showCurrencyConversion ? "455px" : "335px"
          }}
        >
          {colgroup}
          <tbody>
            {values && !item ? (
              <tr>
                <td></td>
                <td></td>
                {showCurrencyConversion ? (
                  <td className="text-right py-0 pl-0">
                    {values.convertedTotal}
                  </td>
                ) : null}
                <td className="text-right py-0 pl-0">
                  {values.total}
                </td>
              </tr>
            ) : !values && item ? (
              <tr>
                <td
                  className={`text-right position-relative p-0 ${lastRow ? "" : "borderless-bottom"}`}
                  style={{
                    padding: "0px",
                    backgroundColor: "#fff"
                  }}>
                  <CurrencyInput
                    key={`${item.calc_type}-left-hand-${item.uuid}`}
                    className="form-control form-control-sm border-0 text-right"
                    style={["fixed", "per_tickets_sold"].includes(item.calc_type) ? (
                      { fontSize: "14px", height: "35px" }
                    ) : (
                      { fontSize: "14px", height: "35px", paddingRight: "26px" }
                    )}
                    maskOptions={["fixed", "per_tickets_sold"].includes(item.calc_type) ? (
                      { prefix: "", integerLimit: null }
                    ) : (
                      { prefix: "", integerLimit: null, allowDecimal: true, decimalLimit: 5 }
                    )}
                    placeholder="0.0"
                    value={item.left_hand_forecast}
                    onChange={e => {
                      var leftHandForecast = e.target.value.length > 0
                        ? e.target.value.replace(/,/g, '') : '';

                      var updatedBudgetLineItem = Object.assign({}, item, {left_hand_forecast: leftHandForecast});
                      var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                      updateBudget(updatedBudget);
                      debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
                    }}
                  />
                  {["fixed", "per_tickets_sold"].includes(item.calc_type) ? (
                    <div className="position-absolute"
                         style={{
                           "left": "10px",
                           "top": "7px"
                         }}>
                      <i className="fas fa-dollar-sign"></i>
                    </div>
                  ) : (
                    <div className="position-absolute"
                         style={{
                           "right": "10px",
                           "top": "7px"
                         }}>
                      <i className="fas fa-percent"></i>
                    </div>
                  )}
                </td>
                <td
                  className={`text-right p-0 ${lastRow ? "" : "borderless-bottom"}`}
                  style={{
                    padding: "0px",
                    backgroundColor: "#fff"
                  }}>
                  <CurrencyInput
                    disabled={item.calc_type !== "fixed"}
                    key={`${item.calc_type}-left-hand-${item.uuid}`}
                    className="form-control form-control-sm border-0 text-right"
                    style={{
                      height: "35px",
                      fontSize: "14px",
                      paddingLeft: "12px",
                      paddingRight: "12px"
                    }}
                    maskOptions={["fixed", "per_tickets_sold"].includes(item.calc_type) ? (
                      { prefix: "", integerLimit: null, allowDecimal: false }
                    ) : (
                      { prefix: "", integerLimit: null, allowDecimal: true, decimalLimit: 2 }
                    )}
                    placeholder={["fixed", "per_tickets_sold"].includes(item.calc_type) ? "0" : "0.00"}
                    value={item.calc_type === "fixed" ? (
                      item.right_hand_forecast === '' ? item.right_hand_forecast : Number(item.right_hand_forecast)
                    ) : Object.keys(calculatedBudget).length == 0 ? (
                      0
                    ) : item.calc_type === "per_tickets_sold" ? (
                      calculatedBudget.numberOfPaidTicketsForecast()
                    ) : item.calc_type === "percentage_of_gross" ? (
                      (calculatedBudget.ticketsSection.forecastTotal + calculatedBudget.revenueSection.forecastTotal).toFixed(2)
                    ) : item.calc_type === "percentage_of_net_gross" ? (
                      calculatedBudget.showSummary.netBoxOfficeReceiptsForecast.toFixed(2)
                    ) : item.calc_type === "percentage_of_net" ? (
                      calculatedBudget.showSummary.showNetAfterExpensesForecast.toFixed(2)
                    ) : item.calc_type === "percentage_of_expenses" ? (
                      calculatedBudget.expensesSection.forecastTotal.toFixed(2)
                    ) : 0}
                    onChange={e => {
                      if (item.calc_type !== "fixed") return;

                      var rightHandForecast = e.target.value.length > 0
                        ? e.target.value.replace(/,/g, '') : '';

                      var updatedBudgetLineItem = Object.assign({}, item, {right_hand_forecast: rightHandForecast});
                      var updatedBudget = updatedBudgetWithBudgetLineItem(budget, updatedBudgetLineItem);

                      updateBudget(updatedBudget);
                      debouncedAutoSaveBudgetLineItem(csrfToken, team, updatedBudgetLineItem);
                    }}
                  />
                </td>
                {showCurrencyConversion ? (
                  <td
                    className={`text-right strong total-td ${lastRow ? "" : "borderless-bottom"}`}>
                    {globalCurrency.formatted(calculatedRow ? calculatedRow.calculatedForecastTotal : 0.00)}
                  </td>
                ) : null}
                <td
                  className={`text-right position-relative strong total-td ${lastRow ? "" : "borderless-bottom"}`}>
                  {formatCalculatedNumber(calculatedRow ? calculatedRow.calculatedForecastTotal : 0.00)}
                  <div className="position-absolute"
                       style={{
                         "left": "10px",
                         "top": "7px"
                       }}>
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      )}
    </div>
  );

  return(
    header ? (
      <th className={cellClasses} style={cellStyles}>
        {innerDiv}
      </th>
    ) : (
      <td className={cellClasses} style={cellStyles}>
        {innerDiv}
      </td>
    )
  );
}

ForecastColumn.propTypes = {
  show: PropTypes.bool.isRequired,
  values: PropTypes.object,
  showCurrencyConversion: PropTypes.bool.isRequired,
  globalCurrency: PropTypes.object,
  colgroup: PropTypes.object,
  header: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  height: PropTypes.string,
  item: PropTypes.object,
  calculatedBudget: PropTypes.object,
  calculatedRow: PropTypes.object,
  csrfToken: PropTypes.string,
  budget: PropTypes.object,
  team: PropTypes.object,
  lastRow: PropTypes.bool,
  updatedBudgetWithBudgetLineItem: PropTypes.func,
  updateBudget: PropTypes.func,
  debouncedAutoSaveBudgetLineItem: PropTypes.func,
  formatCalculatedNumber: PropTypes.func,
  children: PropTypes.node
};

export default ForecastColumn;
