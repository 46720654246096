/* eslint-disable import/prefer-default-export */

export const ADD_TEAM_MEMBER_CLICKED = 'ADD_TEAM_MEMBER_CLICKED';
export const CANCEL_NEW_INVITATION = 'CANCEL_NEW_INVITATION';

export const NEW_INVITATION_FIRST_NAME_CHANGED = 'NEW_INVITATION_FIRST_NAME_CHANGED';
export const NEW_INVITATION_LAST_NAME_CHANGED = 'NEW_INVITATION_LAST_NAME_CHANGED';
export const NEW_INVITATION_EMAIL_CHANGED = 'NEW_INVITATION_EMAIL_CHANGED';
export const NEW_INVITATION_ROLE_ID_CHANGED = 'NEW_INVITATION_ROLE_ID_CHANGED';
export const NEW_INVITATION_COMPANY_CHANGED = 'NEW_INVITATION_COMPANY_CHANGED';
export const NEW_INVITATION_VENUE_OWNERSHIP_CLICKED = 'NEW_INVITATION_VENUE_OWNERSHIP_CLICKED';
export const NEW_INVITATION_CREATION_SUCCESS = 'NEW_INVITATION_CREATION_SUCCESS';
export const NEW_INVITATION_ERRORS_CHANGED = 'NEW_INVITATION_ERRORS_CHANGED';
export const NEW_INVITATION_ENABLE_ALL_VENUES_CLICK = 'NEW_INVITATION_ENABLE_ALL_VENUES_CLICK';
export const NEW_INVITATION_DISABLE_ALL_VENUES_CLICK = 'NEW_INVITATION_DISABLE_ALL_VENUES_CLICK';

export const EDIT_TEAM_MANAGEABLE_CLICKED = 'EDIT_TEAM_MANAGEABLE_CLICKED';
export const EDIT_TEAM_MANAGEABLE_CANCELED = 'EDIT_TEAM_MANAGEABLE_CANCELED';
export const TEAM_MANAGEABLE_DELETED = 'TEAM_MANAGEABLE_DELETED';

export const TEAM_MANAGEABLE_TO_EDIT_FIRST_NAME_CHANGED = 'TEAM_MANAGEABLE_TO_EDIT_FIRST_NAME_CHANGED';
export const TEAM_MANAGEABLE_TO_EDIT_LAST_NAME_CHANGED = 'TEAM_MANAGEABLE_TO_EDIT_LAST_NAME_CHANGED';
export const TEAM_MANAGEABLE_TO_EDIT_EMAIL_CHANGED = 'TEAM_MANAGEABLE_TO_EDIT_EMAIL_CHANGED';
export const TEAM_MANAGEABLE_TO_EDIT_ROLE_ID_CHANGED = 'TEAM_MANAGEABLE_TO_EDIT_ROLE_ID_CHANGED';
export const TEAM_MANAGEABLE_TO_EDIT_COMPANY_CHANGED = 'TEAM_MANAGEABLE_TO_EDIT_COMPANY_CHANGED';
export const TEAM_MANAGEABLE_TO_EDIT_VENUE_OWNERSHIP_CLICKED = 'TEAM_MANAGEABLE_TO_EDIT_VENUE_OWNERSHIP_CLICKED';
export const TEAM_MANAGEABLE_TO_EDIT_ENABLE_ALL_VENUES_CLICK = 'TEAM_MANAGEABLE_TO_EDIT_ENABLE_ALL_VENUES_CLICK';
export const TEAM_MANAGEABLE_TO_EDIT_DISABLE_ALL_VENUES_CLICK = 'TEAM_MANAGEABLE_TO_EDIT_DISABLE_ALL_VENUES_CLICK';
export const TEAM_MANAGEABLE_TO_EDIT_UPDATED = 'TEAM_MANAGEABLE_TO_EDIT_UPDATED';
export const TEAM_MANAGEABLE_TO_EDIT_ERRORS_CHANGED = 'TEAM_MANAGEABLE_TO_EDIT_ERRORS_CHANGED';
