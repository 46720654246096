import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import TotalsTable from './TotalsTable';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const SecondColumn = ({
  confirm,
  ticketTypes,
  ticketReservation,
  addOns
}) => (
  <div>
    {confirm.compressed_main_event_image_url ? (
      <img src={confirm.compressed_main_event_image_url}
           className="img-fluid rounded mb-3 mb-lg-0" />
    ) : null}
    <div className="d-none d-lg-block">
      <TotalsTable ticketTypes={ticketTypes}
                   ticketReservation={ticketReservation}
                   addOns={addOns} />
    </div>
  </div>
);

SecondColumn.propTypes = {
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  ticketReservation: PropTypes.object,
  addOns: PropTypes.array
};

export default SecondColumn;
