import PropTypes from 'prop-types';
import React from 'react';

import Iframe from 'react-iframe'
import ShowHideContent from '../../ShowHideContent/components/ShowHideContent';

const pipelineEventIcon = function(pipelineEvent){
  var icon;

  switch(pipelineEvent.type) {
    case "PipelineEvents::OccurConfirm":
      icon = <i className="fas fa-microphone"></i>;
      break;
    case "PipelineEvents::AcceptOffer":
      icon = <i className="fas fa-check"></i>;
      break;
    case "PipelineEvents::ClickOutreach":
      icon = <i className="fas fa-mouse-pointer"></i>;
      break;
    case "PipelineEvents::CreateConfirm":
      icon = <i className="fas fa-star"></i>;
      break;
    case "PipelineEvents::CreateEmail":
      icon = <i className="fas fa-reply"></i>;
      break;
    case "PipelineEvents::CreateFollowUp":
      icon = <i className="fas fa-bell"></i>;
      break;
    case "PipelineEvents::CreateHoldGroup":
      icon = <i className="fas fa-calendar-alt"></i>;
      break;
    case "PipelineEvents::CreateOffer":
      icon = <i className="fal fa-file-alt"></i>;
      break;
    case "PipelineEvents::CreateOutreach":
      icon = <i className="fas fa-plus"></i>;
      break;
    case "PipelineEvents::DeclineOffer":
      icon = <i className="fas fa-times"></i>;
      break;
    case "PipelineEvents::ViewOutreach":
      icon = <i className="fas fa-eye"></i>;
      break;
    default:
      icon = <i className="fas fa-check"></i>;
  }

  return icon;
}

const PipelineEvent = ({
  team,
  pipelineEvents,
  pipelineEvent,
  index
}) => (
  <div style={
         (index + 1 === pipelineEvents.length) ? (
           {"borderLeft": "2px solid white"}
         ) : (
           {"borderLeft": "2px solid #cdcdcd"}
         )
       }
       className="pb-4 pl-3 position-relative">
    <div className="pipeline-event-icon d-flex justify-content-center">
      <div className="align-self-center" style={{"fontSize": "10px", "marginLeft": "1px"}}>
        {pipelineEventIcon(pipelineEvent)}
      </div>
    </div>
    <div className="pb-2"
         dangerouslySetInnerHTML={{ __html: pipelineEvent.rendered_message}}></div>
    <p className="text-muted small mb-0">
      {pipelineEvent.human_created_at}
    </p>
    {pipelineEvent.type === "PipelineEvents::CreateFollowUp" ? (
      <ShowHideContent showLabel="View Note"
                       hideLabel="Hide Note"
                       className="pt-2 small">
        <div className="mt-2 p-2 rounded"
             style={{"border": "2px solid #f5f5f5"}}>
          <div style={{"minHeight": "70px", "maxHeight": "200px", "overflowY": "scroll"}}
               dangerouslySetInnerHTML={{__html: pipelineEvent.extra.simple_format_note}}>
          </div>
        </div>
      </ShowHideContent>
    ) : null}
    {pipelineEvent.type === "PipelineEvents::CreateOutreach" && pipelineEvent.extra.email_id ? (
      <ShowHideContent showLabel="View Message"
                       hideLabel="Hide Message"
                       className="pt-2 small">
        <div className="mt-2 p-2 rounded"
             style={{"border": "2px solid #f5f5f5"}}>
          <div className="embed-responsive" style={{"height": "200px"}}>
            <Iframe url={("/teams/" + team.id + "/emails/" + pipelineEvent.extra.email_id)}
                  className="embed-responsive-item" />
          </div>
        </div>
      </ShowHideContent>
    ) : null}
    {pipelineEvent.type === "PipelineEvents::CreateEmail" ? (
      <ShowHideContent showLabel="View Message"
                       hideLabel="Hide Message"
                       className="pt-2 small">
        <div className="mt-2 p-2 rounded"
             style={{"border": "2px solid #f5f5f5"}}>
          <div className="embed-responsive" style={{"height": "200px"}}>
            <Iframe url={("/teams/" + team.id + "/emails/" + pipelineEvent.eventable_id)}
                  className="embed-responsive-item" />
          </div>
        </div>
      </ShowHideContent>
    ) : null}
  </div>
);

PipelineEvent.propTypes = {
  team: PropTypes.object.isRequired,
  pipelineEvents: PropTypes.array,
  pipelineEvent: PropTypes.object,
  index: PropTypes.number
};

export default PipelineEvent;
