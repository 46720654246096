import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const axios = require('axios').default;
import AsyncSelect from 'react-select/async';
import Flatpickr from "react-flatpickr";

import {
  setGonTimeZoneFromVenue
} from '../../../shared/timeZoneLogic';

const canSubmitForm = (offer) => {
  if(!offer.create_calendar_event){
    return (
      offer.calendar_event_id
    );
  } else {
    return (
      offer.venue_id
        && offer.event_name
        && offer.event_name.length > 0
    );
  }
};

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name, permalink: data.permalink };
        });

        callback(newOptions);
      })
  });
}, 250);

const debouncedConfirmFetch = _.debounce((inputValue, callback, team) => {
  new Promise(resolve => {
    axios.get('/teams/' + team.id + '/calendar_events/autocomplete', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var options = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(options);
      })
  });
}, 250);

const debouncedFetchEventTemplates = _.debounce((team, inputValue, callback) => {
  new Promise(resolve => {
    axios.get(`/teams/${team.id}/event_templates`).then(function (response) {
      var newOptions = response.data.map(function(data){
        return { value: data.id, label: data.name };
      });

      callback(newOptions);
    })
  });
}, 250);

const performerIsDisabled = (offer, performer) => {
  return (
    performer.offer
      && performer.offer.id
      && performer.offer.id !== offer.id
  );
};

const performerIsSelected = (offer, performer) => {
  return (offer.performer_id === performer.id);
};

const OfferV2Modal = ({
  csrfToken,
  team,
  currentUser,
  venuesOptions,
  onClose,
  offer,
  closeModal,
  createCalendarEventChanged,
  eventNameChanged,
  eventTemplateChanged,
  venueIdChanged,
  venueSelected,
  startTimeChanged,
  primaryArtistChanged,
  calendarEventChanged,
  fetchCalendarEventPerformers,
  calendarEvent,
  performers,
  performerIdChanged,
  fetchArtistData,
  selectedArtist,
  clearSelectedArtist,
  createOffer,
  performersChanged,
  pipeline,
  venueOwnerships
}) => {
  useEffect(() => {
    if(Object.keys(offer).length === 0){
      createCalendarEventChanged(false);
    }

    if(venueSelected && venueSelected.id){
      venueIdChanged(venueSelected.id);
      setGonTimeZoneFromVenue(venueOwnerships, venueSelected.id);
    }

    if(calendarEvent && calendarEvent.id){
      calendarEventChanged(calendarEvent);
    }

    // calendarEventChanged resets performers
    if(performers && performers.length > 0){
      performersChanged(performers);
    }

    if(selectedArtist && selectedArtist.id){
      primaryArtistChanged({
        label: selectedArtist.name,
        value: selectedArtist.id
      });

      fetchArtistData(selectedArtist.permalink);
    }
  }, []);

  return (
    <div className="offer-modal-container"
         style={{"padding": "30px 40px"}}>
      <div className="row">
        <div className="col">
          <h3 style={{"fontSize": "20px"}}>
            <strong>Create Offer</strong>
          </h3>
        </div>
        <div className="col-xs-auto"
             style={{"paddingRight": "15px"}}>
          <a href="#"
             onClick={
               (e) => {
                 e.preventDefault();
                 closeModal(onClose);
               }
             }
             style={{"fontSize": "26px"}}
             className="text-dark">
            <i className="fal fa-times"></i>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="text-muted"
             style={{"fontSize": "16px"}}>
            Build your offer for an existing event or create a brand new event.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(!canSubmitForm(offer)){
                      return false;
                    }

                    createOffer(csrfToken, team, offer, pipeline);
                  }
                }>
            <div className="form-row my-3">
              <div className="col-12">
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio"
                         id="offer-create-calendar-event-false"
                         checked={!offer.create_calendar_event || false}
                         onChange={
                           (e) => {
                             createCalendarEventChanged(false);
                           }
                         }
                         className="custom-control-input" />
                  <label className="custom-control-label"
                         style={{"fontSize": "16px"}}
                         htmlFor="offer-create-calendar-event-false">
                    Existing event
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio"
                         id="offer-create-calendar-event-true"
                         checked={offer.create_calendar_event || false}
                         onChange={
                           (e) => {
                             createCalendarEventChanged(true);
                             venueIdChanged(venueSelected.id);
                             setGonTimeZoneFromVenue(venueOwnerships, venueSelected.id);
                           }
                         }
                         className="custom-control-input" />
                  <label className="custom-control-label"
                         style={{"fontSize": "16px"}}
                         htmlFor="offer-create-calendar-event-true">
                    New event
                  </label>
                </div>
              </div>
            </div>
            {offer.create_calendar_event ? (
              <div className="row">
                <div className="col-12">
                  <div className="form-row">
                    <div className="col-6 col-md-4">
                      <div className="form-group">
                        <label htmlFor="offer-venue"
                               className="text-muted">
                          Venue
                        </label>
                        <select className="form-control"
                                value={offer.venue_id || ""}
                                onChange={
                                  (e) => {
                                    venueIdChanged(e.target.value);

                                    var venueId = parseInt(e.target.value);
                                    setGonTimeZoneFromVenue(venueOwnerships, venueId);
                                  }
                                }
                                id="offer-venue">
                          {venuesOptions.map((venueOption, index) =>
                            <option key={index}
                                    value={venueOption.id}>
                              {venueOption.name}
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-6 col-md-4">
                      <div className="form-group">
                        <label htmlFor="offer-date-time"
                               className="text-muted">
                          Date/Time (leave blank for TBD dates)
                        </label>
                        <Flatpickr
                          data-enable-time
                          options={{
                            dateFormat: "F j, Y - h:i K"
                          }}
                          placeholder={"Select date and time"}
                          className={"form-control form-control-flatpickr"}
                          value={offer.start_time || ""}
                          onChange={date => {
                            startTimeChanged(date);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    {Object.keys(selectedArtist).length === 0 ? (
                      <div>
                        <label htmlFor="offer-primary-artist" className="text-muted">
                          Artist (optional)
                        </label>
                        <AsyncSelect cacheOptions
                                     styles={selectStyles}
                                     menuPortalTarget={document.querySelector('body')}
                                     defaultOptions
                                     id="offer-primary-artist"
                                     onChange={
                                       (option) => {
                                         primaryArtistChanged(option);

                                         if(option.value){
                                           fetchArtistData(option.permalink);
                                         }
                                       }
                                     }
                                     value={
                                       offer.primary_artist || ""
                                     }
                                     loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback)} />
                      </div>
                    ) : (
                      <div className={"col-12 p-2 mt-2 selectable-card selected"}>
                        <div className="row">
                          <div className="col-xs-auto d-flex" style={{'paddingLeft': '20px'}}>
                            <div className="custom-control custom-radio align-self-center">
                              <input type="radio"
                                     checked={true}
                                     onChange={() => {}}
                                     id={"offer-selected-artist"}
                                     className="custom-control-input" />
                              <label className="custom-control-label"
                                     htmlFor={"offer-selected-artist"}>
                                &nbsp;
                              </label>
                            </div>
                          </div>
                          <div className="col-xs-auto" style={{'paddingLeft': '15px'}}>
                            <img src={selectedArtist.image_thumb_url_for_json}
                                 className="img-fluid rounded-circle mr-2 less-opacity-when-disabled"
                                 style={{"height": "60px", "width": "60px"}} />
                          </div>
                          <div className="col less-opacity-when-disabled">
                            <h5 className="mb-0">
                              <strong>
                                {selectedArtist.name}
                              </strong>
                            </h5>
                            {selectedArtist.primary_agent ? (
                              <span>
                                <p className='mb-0 small'>{selectedArtist.primary_agent.name}</p>
                                <p className='mb-0 text-muted small'>{selectedArtist.primary_agent.email}</p>
                              </span>
                            ) : (
                              <span>
                                <p className='mb-0 text-muted small'>Agent info needed.</p>
                              </span>
                            )}
                          </div>
                          <div className="col-xs-auto" style={{'paddingRight': '15px'}}>
                            <a href='#'
                               className='mt-3 d-inline-block'
                               onClick={
                                 (e) => {
                                   e.preventDefault();
                                   clearSelectedArtist();
                                 }
                               }
                               style={{'color': '#1982C4'}}
                               >
                              <i className="fa fa-times-circle"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="offer-event-name"
                           className="text-muted">
                      Event Name
                    </label>
                    <input type="text"
                           className="form-control"
                           id="offer-event-name"
                           onChange={
                             (e) => {
                               eventNameChanged(e.target.value);
                             }
                           }
                           value={offer.event_name || ""}
                           placeholder="Enter event name" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="new-event-template" className="text-muted">
                      Event Template
                      <span className="text-optional pl-2">
                        (optional)
                      </span>
                    </label>
                    <AsyncSelect
                      cacheOptions
                      styles={selectStyles}
                      menuPortalTarget={document.querySelector('body')}
                      defaultOptions
                      id="new-event-template"
                      value={offer.event_template ? offer.event_template : null}
                      loadOptions={(inputValue, callback) => debouncedFetchEventTemplates(team, inputValue, callback)}
                      onChange={(option) => {
                        eventTemplateChanged(option);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <AsyncSelect cacheOptions
                               placeholder={"Select event"}
                               isClearable={true}
                               styles={selectStyles}
                               menuPortalTarget={document.querySelector('body')}
                               defaultOptions
                               id="offer-primary-artist"
                               onChange={
                                 (option) => {
                                   calendarEventChanged(option);

                                   if(option && option.value){
                                     fetchCalendarEventPerformers(team, option.value);
                                   }
                                 }
                               }
                               value={Object.keys(calendarEvent).length > 0 ? calendarEvent : ""}
                               loadOptions={(inputValue, callback) => debouncedConfirmFetch(inputValue, callback, team)} />
                </div>
                <div className="col-12 mt-3">
                  {performers.length > 0 ? (
                    <label className="text-muted mb-0">
                      CHOOSE AN ARTIST
                    </label>
                  ) : null}
                  {performers.map((performer, index) => (
                    <div key={index}
                         onClick={
                           (e) => {
                             e.preventDefault();

                             if(!performerIsDisabled(offer, performer) && !performerIsSelected(offer, performer)){
                               performerIdChanged(performer.id);
                             }
                           }
                         }
                         className={"col-12 p-2 mt-2 selectable-card " + (performerIsSelected(offer, performer) ? "selected " : "") + (performerIsDisabled(offer, performer) ? "disabled " : "")}>
                      <div className="row">
                        <div className="col-xs-auto d-flex" style={{'paddingLeft': '20px'}}>
                          <div className="custom-control custom-radio align-self-center">
                            <input type="radio"
                                   checked={performerIsSelected(offer, performer)}
                                   onChange={() => {}}
                                   disabled={performerIsDisabled(offer, performer)}
                                   id={"partnership-" + index}
                                   name={"offer-partnership"}
                                   className="custom-control-input" />
                            <label className="custom-control-label"
                                   htmlFor={"partnership-" + index}>
                              &nbsp;
                            </label>
                          </div>
                        </div>
                        <div className="col-xs-auto" style={{'paddingLeft': '15px'}}>
                          <img src={performer.artistable.image_thumb_url_for_json}
                               className="img-fluid rounded-circle mr-2 less-opacity-when-disabled"
                               style={{"height": "60px", "width": "60px"}} />
                        </div>
                        <div className="col less-opacity-when-disabled">
                          <h5 className="mb-0">
                            <strong>
                              {performer.artistable.name}
                            </strong>
                          </h5>
                          {performer.artistable && performer.artistable.primary_agent ? (
                            <span>
                              <p className='mb-0 small'>{performer.artistable.primary_agent.name}</p>
                              <p className='mb-0 text-muted small'>{performer.artistable.primary_agent.email}</p>
                            </span>
                          ) : (
                            <span>
                              <p className='mb-0 text-muted small'>Agent info needed.</p>
                            </span>
                          )}
                        </div>
                        {performerIsDisabled(offer, performer) ? (
                          <div className="col-xs-auto d-flex" style={{'paddingRight': '15px'}}>
                            <div className="align-self-center">
                              <span className="badge badge-secondary p-2" style={{"fontSize": "12px"}}>
                                <strong>Offer Exists</strong>
                              </span>
                            </div>
                          </div>
                        ) : null}
                        {performerIsSelected(offer, performer) ? (
                          <div className="col-xs-auto" style={{'paddingRight': '15px'}}>
                            <a href='#'
                               className='mt-3 d-inline-block'
                               onClick={
                                 (e) => {
                                   e.preventDefault();
                                   performerIdChanged(null);
                                 }
                               }
                               style={{'color': '#1982C4'}}
                               >
                              <i className="fa fa-times-circle"></i>
                            </a>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="row small" style={{"marginTop": "30px"}}>
              <div className="col-12 text-right">
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       closeModal(onClose);
                     }
                   }>
                  <strong>Cancel</strong>
                </a>
                <input type="submit"
                       style={{"borderRadius": "8px"}}
                       className="btn btn-primary btn-sm ml-3 strong"
                       disabled={!canSubmitForm(offer)}
                       value="Create" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

OfferV2Modal.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  venuesOptions: PropTypes.array,
  onClose: PropTypes.func,
  offer: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  createCalendarEventChanged: PropTypes.func.isRequired,
  eventNameChanged: PropTypes.func.isRequired,
  eventTemplateChanged: PropTypes.func.isRequired,
  venueIdChanged: PropTypes.func.isRequired,
  venueSelected: PropTypes.object,
  startTimeChanged: PropTypes.func.isRequired,
  primaryArtistChanged: PropTypes.func.isRequired,
  calendarEventChanged: PropTypes.func.isRequired,
  fetchCalendarEventPerformers: PropTypes.func.isRequired,
  calendarEvent: PropTypes.object,
  performers: PropTypes.array,
  performerIdChanged: PropTypes.func.isRequired,
  fetchArtistData: PropTypes.func.isRequired,
  selectedArtist: PropTypes.object,
  clearSelectedArtist: PropTypes.func.isRequired,
  createOffer: PropTypes.func.isRequired,
  performersChanged: PropTypes.func.isRequired,
  pipeline: PropTypes.object,
  venueOwnerships: PropTypes.array.isRequired
};

export default OfferV2Modal;
