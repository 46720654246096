/* eslint-disable import/prefer-default-export */

import {
  ACTIVE_PAGE_CHANGED
} from '../constants/fanUpcomingEventsConstants';

const axios = require('axios').default;

export const activePageChanged = (activePage) => ({
  type: ACTIVE_PAGE_CHANGED,
  activePage
});
