// Simple example of a React "smart" component

import { connect } from 'react-redux';
import TagManager from '../components/TagManager';
import * as actions from '../actions/tagManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  scope: state.scope,
  title: state.title,
  taggable: state.taggable,
  activeTags: state.activeTags,
  onChange: state.onChange,
  onTagCreate: state.onTagCreate,
  popupClasses: state.popupClasses,
  popupStyles: state.popupStyles,
  tagsButtonPosition: state.tagsButtonPosition,
  activeTagsAlignment: state.activeTagsAlignment,
});

const mapDispatchToProps = (dispatch) => {
  return {
    assignTag: (csrfToken, team, scope, taggable, tag, onChange) => dispatch(actions.assignTag(dispatch, csrfToken, team, scope, taggable, tag, onChange)),
    unassignTag: (csrfToken, team, scope, tag, onChange) => dispatch(actions.unassignTag(dispatch, csrfToken, team, scope, tag, onChange)),
    createTag: (csrfToken, team, scope, taggable, tag, onChange) => dispatch(actions.createTag(dispatch, csrfToken, team, scope, taggable, tag, onChange)),
    getActiveTags: (csrfToken, team, scope, taggable, onChange) => dispatch(actions.getActiveTags(dispatch, csrfToken, team, scope, taggable, onChange)),
    getAvailableTags: (csrfToken, team, scope, onChange) => dispatch(actions.getAvailableTags(dispatch, csrfToken, team, scope, onChange)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(TagManager);
