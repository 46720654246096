import React, { useEffect, useState } from "react";
import MaskedInput from "react-text-mask";

const axios = require("axios").default;
const qs = require("qs");

const formContainerStyle = {
  alignItems: "center",
  backgroundColor: "#F9F9F9",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  width: "100%",
};

const formStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
  justifyContent: "center",
  marginBottom: "10px",
  maxWidth: "500px",
  width: "100%",
};

const headerStyle = {
  fontSize: "18px",
  fontWeight: "bold",
  marginBottom: "5px",
};

const subHeaderStyle = {
  fontSize: "14px",
  marginBottom: "20px",
};

const inputStyle = {
  borderRadius: "5px",
  border: "1px solid #E5E5E5",
  padding: "10px",
  width: "240px",
};

const buttonStyle = {
  color: "white",
  borderRadius: "5px",
  backgroundColor: "#FF595E",
  border: "none",
  fontWeight: "bold",
  fontSize: "12px",
  padding: "15px",
  width: "240px",
};

const CalendarEventInterestForm = ({
  confirm,
  csrfToken,
  interestType
}) => {
  const findExistingInterest = async () => {
    let queryString = qs.stringify({
      q: {
        calendar_event_id_eq: confirm.id,
        interest_type_eq: interestType,
      }
    });

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    return axios.get("/calendar_event_interests?" + queryString);
  };

  const createCalendarEventInterest = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post("/calendar_event_interests/", {
      calendar_event_id: confirm.id,
      interest_type: interestType,
      email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone_number: `1${formData.phoneNumber.replace(/\D/g, "")}`,
    }).catch(error => {
      console.error("Error creating calendar event interest", error);
    });
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    let isMounted = true;

    findExistingInterest()
      .then(response => {
        if (isMounted) {
          response.data.length > 0 && setFormSubmitted(true);
          setIsLoading(false);
        }
      }).catch(error => {
        if (isMounted) {
          setIsLoading(false);
          console.error("Error finding existing interest", error);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (formErrors[e.target.name]) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: "",
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) {
      errors.firstName = "First name is required.";
    }
    if (!formData.lastName) {
      errors.lastName = "Last name is required.";
    }
    if (!formData.phoneNumber || formData.phoneNumber.replace(/\D/g, "").length < 10) {
      errors.phoneNumber = "A valid phone number is required.";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "A valid email address is required.";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      createCalendarEventInterest();
      setFormSubmitted(true);
    } else {
      setFormErrors(errors);
    }
  };

  return formSubmitted ? (
    <div style={formContainerStyle}>
      <div style={headerStyle}>
        Thanks!
      </div>
      <div style={subHeaderStyle}>
        {interestType === "Announce" ? (
          "We'll notify you when tickets go on sale."
        ) : (
          "We'll notify you when tickets become available."
        )}
      </div>
    </div>
  ) : isLoading ? (
    <div style={formContainerStyle}>
      <div style={headerStyle}>
        Loading...
      </div>
    </div>
  ) : (
    <div style={formContainerStyle}>
      <div style={headerStyle}>
        {interestType === "Announce" ? (
          "Be the first to get tickets!"
        ) : (
          "Get on the waitlist!"
        )}
      </div>
      <div style={subHeaderStyle}>
        {interestType === "Announce" ? (
          "Sign up to get notified when tickets go on sale!"
        ) : (
          "Sign up to be notified if any tickets become available!"
        )}
      </div>
      <div style={formStyle}>
        <>
          <input
            id="firstName"
            name="firstName"
            placeholder="First name"
            onChange={handleChange}
            style={{
              ...inputStyle,
              borderColor: formErrors.firstName ? "red" : "#E5E5E5",
            }}
            type="text"
            value={formData.firstName}
          />
        </>
        <>
          <input
            id="lastName"
            name="lastName"
            onChange={handleChange}
            placeholder="Last name"
            style={{
              ...inputStyle,
              borderColor: formErrors.lastName ? "red" : "#E5E5E5",
            }}
            type="text"
            value={formData.lastName}
          />
        </>
        <>
          <MaskedInput
            id="phoneNumber"
            name="phoneNumber"          
            onChange={handleChange}
            placeholder="Mobile phone"
            style={{
              ...inputStyle,
              borderColor: formErrors.phoneNumber ? "red" : "#E5E5E5",
            }}
            value={formData.phoneNumber || ""}
            type="tel"
            mask={['(',/[1-9]/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/]} />
        </>
        <>
          <input
            id="email"
            name="email"
            onChange={handleChange}
            placeholder="Email address"
            style={{
              ...inputStyle,
              borderColor: formErrors.email ? "red" : "#E5E5E5",
            }}
            type="email"
            value={formData.email}
          />
        </>
      </div>
      <button onClick={handleSubmit} style={buttonStyle}>
        Get Notifications
      </button>
    </div>
  );
};

export default CalendarEventInterestForm;
