/* eslint-disable import/prefer-default-export */

import {
  ARTIST_CHANGED,
  TAG_MANAGER_OPEN_CHANGED,
  TAG_MANAGER_FILTER_CHANGED,
  AVAILABLE_TAGS_CHANGED
} from '../constants/artistDetailsConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const artistChanged = (artist) => ({
  type: ARTIST_CHANGED,
  artist
});

export const tagManagerOpenChanged = (tagManagerOpen) => ({
  type: TAG_MANAGER_OPEN_CHANGED,
  tagManagerOpen
});

export const tagManagerFilterChanged = (tagManagerFilter) => ({
  type: TAG_MANAGER_FILTER_CHANGED,
  tagManagerFilter
});

export const availableTagsChanged = (availableTags) => ({
  type: AVAILABLE_TAGS_CHANGED,
  availableTags
});
