import React, { createContext, useContext } from "react";
import { ToastContainer } from "react-toastify";

import BroadcastShow from "./BroadcastShow";

const BroadcastShowContext = createContext();

export function useBroadcastShowContext() {
  return useContext(BroadcastShowContext);
}

const BroadcastShowProvider = (props) => {
  const { csrfToken, user, team, venueOwnership, broadcast } = props;

  const value = {
    csrfToken,
    user,
    team,
    venueOwnership,
    broadcast,
  };

  return (
    <BroadcastShowContext.Provider value={value}>
      {props.children}
    </BroadcastShowContext.Provider>
  );
};

const BroadcastShowContainer = (props) => (
  <BroadcastShowProvider {...props}>
    <ToastContainer />
    <BroadcastShow />
  </BroadcastShowProvider>
);

export default BroadcastShowContainer;
