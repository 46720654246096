import React from "react";

import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";

const containerStyle = {
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: 5,
};

const labelStyle = {
  color: "#333333",
  fontWeight: 700,
};

const inputContainerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
};

const inputStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  color: "#3333333",
  fontSize: 14,
  height: 40,
  padding: 8,
  width: 125,
  textAlign: "right",
};

const EmailFromAddressInput = ({
  sendDomain,
}) => {
  const {
    sendDomainModified,
  } = useMarketingSettingsContext();

  const handleChange = (e) => {
    e.preventDefault();

    sendDomainModified({ ...sendDomain, from_address: e.target.value });
  };

  return (
    <div style={containerStyle}>
      <div style={labelStyle}>Email From Address:</div>
      <div style={inputContainerStyle}>
        <input
          placeholder="hello"
          type="text"
          onChange={handleChange}
          value={sendDomain.from_address || ""}
          style={inputStyle}
        />
        <div>
          @{sendDomain.domain || "yourvenue.com"}
        </div>
      </div>
    </div>
  );
};

export default EmailFromAddressInput;
