import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { combineReducers } from 'redux';

import pipelineSlidingPaneReducer from '../reducers/pipelineSlidingPaneReducer';
import eventFormReducer from '../../EventForm/reducers/eventFormReducer';

const rootReducer = combineReducers({
  pipelineSlidingPane: pipelineSlidingPaneReducer,
  eventForm: eventFormReducer
});

const configureStore = (railsProps) => (
  createStore(rootReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
