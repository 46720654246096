// Simple example of a React "smart" component

import { connect } from 'react-redux';
import RefundSettings from '../components/RefundSettings';
import * as actions from '../actions/refundSettingsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  stripePublishableKey: state.stripePublishableKey,
  financialConnectionAccounts: state.financialConnectionAccounts
});

const mapDispatchToProps = (dispatch) => {
  return {
    createStripeFinancialConnectionSession: (csrfToken, team, stripe) => dispatch(actions.createStripeFinancialConnectionSession(dispatch, csrfToken, team, stripe)),
    deleteFinancialConnectionAccount: (csrfToken, team, financialConnectionAccount) => dispatch(actions.deleteFinancialConnectionAccount(dispatch, csrfToken, team, financialConnectionAccount))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(RefundSettings);
