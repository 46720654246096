// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ActivityFeed from '../components';
import * as actions from '../actions';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken:state.csrfToken,
  currentUser:state.currentUser,
  pipelineEvents:state.pipelineEvents,
  team: state.team,
  lastPipelineEvent: state.lastPipelineEvent,
  artist: state.artist,
  fetchPipelineEventsForArtistOnInit: state.fetchPipelineEventsForArtistOnInit,
  unseenPipelineEvents: state.unseenPipelineEvents
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPipelineEventsForArtist: (team, artist) => dispatch(actions.fetchPipelineEventsForArtist(dispatch, team, artist)),
    pipelineEventsChanged: (pipelineEvents) => dispatch(actions.pipelineEventsChanged(pipelineEvents)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFeed);
