/* eslint-disable import/prefer-default-export */

import { SELECTED_VENUE_CHANGED, UPDATE_RECOMMENDATIONS, UPDATE_ACTIVE_PAGE, LOADING_STARTED, LOADING_STOPPED } from '../constants/recommendationsTableConstants';

const axios = require('axios').default;

export const updatedSelectedVenue = (venue) => ({
  type: SELECTED_VENUE_CHANGED,
  venue
});

export const fetchRecommendations = (dispatch, team, venue, itemsCountPerPage, csrfToken) => {
  dispatch(startLoading());

  return dispatch => {
    return axios.get("/teams/" + team.id + "/recommendations", {
        params: {
          venue_id: venue.permalink
        }
      })
      .then(({ data }) => {
        dispatch(stopLoading());

        dispatch(updateRecommendations(data, itemsCountPerPage));
        dispatch(logRecommendationsView(dispatch, team, venue, 1, csrfToken));
      })
      .catch(() => {
        dispatch(stopLoading());
      });
  };
};

export const onInit = (dispatch, team, venue, itemsCountPerPage, csrfToken) => {
  return dispatch => {
    dispatch(fetchRecommendations(dispatch, team, venue, itemsCountPerPage, csrfToken));
  }
};

export const updateRecommendations = (recommendations, itemsCountPerPage) => ({
  type: UPDATE_RECOMMENDATIONS,
  recommendations,
  itemsCountPerPage
});

export const updateActivePage = (page, recommendations, itemsCountPerPage) => ({
  type: UPDATE_ACTIVE_PAGE,
  page,
  recommendations,
  itemsCountPerPage
});

export const startLoading = () => ({
  type: LOADING_STARTED
});

export const stopLoading = () => ({
  type: LOADING_STOPPED
});

export const clickOnRecommendation = (dispatch, team, recommendation, venueSelected, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/recommendations/click", {
        recommendation: {
          venue_name: venueSelected.name,
          artist_id: recommendation.artist.permalink,
          artist_name: recommendation.artist.name,
          date: recommendation.date
        }
      })
      .then(({ data }) => {
        window.location.href = ("/artists/" + recommendation.artist.permalink);
      });
  };
};

export const logRecommendationsView = (dispatch, team, venueSelected, pageNumber, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/recommendations/view", {
        venue_name: venueSelected.name,
        page_number: pageNumber
      });
  };
};
