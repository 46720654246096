import {
    PIPELINE_EVENTS_CHANGED,
    UNSEEN_PIPELINE_EVENTS_CHANGED
  } from '../constants';

const axios = require('axios').default;

export const fetchPipelineEventsForArtist = (dispatch, team, artist) => {
    return dispatch => {
      return axios.get("/teams/" + team.id + "/pipeline_events", {
          params: {
            artist_id: artist.permalink
          }
        })
        .then(({ data }) => {
          dispatch(pipelineEventsChanged(data));
          dispatch(unseenPipelineEventsChanged([]));
        })
    };
  };

export const pipelineEventsChanged = (pipelineEvents) => ({
    type: PIPELINE_EVENTS_CHANGED,
    pipelineEvents
});

export const unseenPipelineEventsChanged = (pipelineEvents) => ({
    type: UNSEEN_PIPELINE_EVENTS_CHANGED,
    pipelineEvents
});