import React from "react";

import { useActionContext } from "./ActionContainer";

const headerRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const leftHeaderStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  maxWidth: "75%",
};

const nameStyle = {
  color: "#333333",
  fontSize: "16px",
  fontWeight: "600",
};

const descriptionTitleStyle = {
  color: "#333333",
  fontSize: "10px",
  opacity: "0.5",
};

const descriptionStyle = {
  color: "#333333",
  fontSize: "12px",
};

const rightHeaderStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const backButtonStyle = {
  padding: "25px 12px",
  color: "#1982C4",
  fontWeight: "bold",
  fontSize: "12px",
};

const saveButtonStyle = {
  backgroundColor: "#1982C4",
  borderRadius: "5px",
  color: "white",
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: "bold",
  height: "fit-content",
  padding: "10px 12px",
};

const ActionShowHeader = () => {
  const {
    team,
    venueOwnership,
    campaign,
    action,
    template,
    body,
    isEmailTemplate,
    updateAction
  } = useActionContext();

  const saveAction = () => {
    const requestBody = isEmailTemplate ? {
      campaign_action: {
        beefree_template_attributes: {
          id: action.beefree_template.id,
          template: template,
        },
      }
    } : {
      campaign_action: {
        body: body,
      }
    };

    updateAction(requestBody);
  };

  return (
    <div style={headerRowStyle}>
      <div style={leftHeaderStyle}>
        <div style={nameStyle}>
          Campaigns / {venueOwnership.venueable.name} / {campaign.campaign_template.name}
        </div>
        <div>
          <div style={descriptionTitleStyle}>
            DESCRIPTION
          </div>
          <div style={descriptionStyle}>
            {campaign.campaign_template.description}
          </div>
        </div>
      </div>
      <div style={rightHeaderStyle}>
        <a href={`/teams/${team.id}/campaigns/${campaign.id}`} style={backButtonStyle}>
          Back
        </a>
        <div style={saveButtonStyle} onClick={saveAction}>
          Save
        </div>
      </div>
    </div>
  );
};

export default ActionShowHeader;
