import { combineReducers } from 'redux';

import {
  DWOLLA_CUSTOMER_CHANGED,
  PLAID_ACCOUNT_LINKS_CHANGED,
  VENUE_OWNERSHIPS_CHANGED
} from '../constants/financeSettingsManagerConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const plaidToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dwollaCustomer = (state = {}, action) => {
  switch (action.type) {
    case DWOLLA_CUSTOMER_CHANGED:
      return action.dwollaCustomer;
    default:
      return state;
  }
};

const venueBankLinks = (state = [], action) => {
  switch (action.type) {
    case PLAID_ACCOUNT_LINKS_CHANGED:
      return action.venueBankLinks;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    case VENUE_OWNERSHIPS_CHANGED:
      return action.venueOwnerships;
    default:
      return state;
  }
};

const financeSettingsManagerReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  plaidToken,
  dwollaCustomer,
  venueBankLinks,
  venueOwnerships
});

export default financeSettingsManagerReducer;
