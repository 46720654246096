import React, { useEffect, useState } from "react";
import FanFilterForm from "./FanFilterForm";
import axios from "axios";

import { toastError } from "../../../../shared/toastHelper";

export const formInputContainerStyle = {
  marginRight: 8
};

export const formInputStyle = {
  width: 200,
  height: 40,
  borderRadius: 8,
  padding: "0 10px",
  border: "1px solid #ccc",
  color: "#333333",
};

export const deleteButtonStyle = {
  height: 40,
  width: 40,
  backgroundColor: "white",
  border: "1px solid #1982C4",
  borderRadius: 8,
  fontSize: 24,
  color: "#1982C4",
  fontWeight: 900,
  flexShrink: 0,
};

const filterGroupHeaderStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 8,
  color: "#333333",
};

const matchConditionStyle = {
  fontSize: 16,
  fontWeight: "bold",
  textAlign: "center",
  width: 0,
  position: "relative",
  left: -50,
};

const matchConditionSelectStyle = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "8px 0px",
};

const filterGroupFooterStyle = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginBottom: 8,
};

export const renderMatchCondition = (index, depth, matchCondition) => {
  return (
    depth === 0 ? null : (
      <div style={matchConditionStyle}>
        {index === 0 ? null : matchCondition === "All" ? (
          "AND"
        ) : matchCondition === "At Least One" ? (
          "OR"
        ) : null}
      </div>
    )
  )
};

const FanFilterGroupForm = (props) => {
  const {
    csrfToken,
    team,
    refreshParentGroupState,
    onValidChangeCallback,
    matchCondition=props.fanFilterGroup.match_condition,
    depth=0,
    index=0,
  } = props;

  const [fanFilterGroup, setFanFilterGroup] = useState(props.fanFilterGroup);

  useEffect(() => {
    setFanFilterGroup(props.fanFilterGroup);
  }, [props.fanFilterGroup]);

  const insertFanFilter = (payload) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/fan_filter_groups/${fanFilterGroup.id}/fan_filters`, {
      fan_filter: {
        ...payload,
        fan_filter_group_id: fanFilterGroup.id,
      }
    })
      .then((data) => {
        setFanFilterGroup({
          ...fanFilterGroup,
          fan_filters: [...fanFilterGroup.fan_filters, data["data"]]
        });
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  const insertFanFilterGroup = (match_condition) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/fan_filter_groups`, {
      fan_filter_group: {
        parent_id: fanFilterGroup.id,
        match_condition: match_condition
      }
    })
      .then((data) => {
        setFanFilterGroup({
          ...fanFilterGroup,
          children: [...fanFilterGroup.children, data["data"]]
        });
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  const updateFanFilterGroup = (payload) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put(`/teams/${team.id}/fan_filter_groups/${fanFilterGroup.id}`, { fan_filter_group: payload })
      .then((data) => {
        setFanFilterGroup(data["data"]);
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  const refreshState = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.get(`/teams/${team.id}/fan_filter_groups/${fanFilterGroup.id}`)
      .then((data) => {
        setFanFilterGroup({
          ...fanFilterGroup,
          children: [],
          fan_filters: [],
        });
        setFanFilterGroup(data["data"]);
        onValidChangeCallback();
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  const deleteFanFilterGroup = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.delete(`/teams/${team.id}/fan_filter_groups/${fanFilterGroup.id}`)
      .then(() => {
        refreshParentGroupState();
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  return (
    <div style={{
      paddingLeft: depth && 50,
    }}>
      <div style={filterGroupHeaderStyle}>
        <div style={matchConditionSelectStyle}>
          {renderMatchCondition(index, depth, matchCondition)}
          <select
            value={fanFilterGroup.match_condition}
            onChange={(e) => updateFanFilterGroup({match_condition: e.target.value}) }
            style={formInputStyle}
          >
            <option value="All">All</option>
            <option value="At Least One">At Least One</option>
          </select>
          <div style={{ paddingLeft: 5 }}>
            of the following conditions match
          </div>
        </div>
        {refreshParentGroupState ? (
          <button onClick={deleteFanFilterGroup} style={deleteButtonStyle}>
            <i className="fa fa-times"></i>
          </button>
        ) : null}
      </div>
      {fanFilterGroup.fan_filters.map((fanFilter, i) => (
        <FanFilterForm key={i}
          csrfToken={csrfToken}
          team={team}
          fanFilter={fanFilter}
          fanFilterGroup={fanFilterGroup}
          refreshParentGroupState={refreshState}
          onValidChangeCallback={onValidChangeCallback}
          index={i}
          depth={depth + 1}
          matchCondition={fanFilterGroup.match_condition}
        />
      ))}
      {fanFilterGroup.children.map((group, i) => (
        <FanFilterGroupForm key={i}
          csrfToken={csrfToken}
          team={team}
          fanFilterGroup={group}
          refreshParentGroupState={refreshState}
          depth={depth + 1}
          index={fanFilterGroup.fan_filters.length + i}
          matchCondition={fanFilterGroup.match_condition}
        />
      ))}
      <div style={filterGroupFooterStyle}>
        {renderMatchCondition(index, depth)}
        <select value="Add condition or group" style={formInputStyle} onChange={(e) => {
          e.target.value === "Fan Attribute" ? (
            insertFanFilter({
              type: "FanFilters::UserData",
              filter_params: {
                user_data_type: e.target.value,
              }
            })
          ) : e.target.value === "Email" ? (
            insertFanFilter({
              type: "FanFilters::MessageData",
              filter_params: {
                message_data_type: e.target.value,
              }
            })
          ) : e.target.value === "All" ? (
            insertFanFilterGroup("All")
          ) : e.target.value === "At Least One" ? (
            insertFanFilterGroup("At Least One")
          ) : null;
        }}>
          <option value="">Add condition or group</option>
          <option disabled>USER DATA</option>
          <option>Fan Attribute</option>
          <option disabled>MESSAGE DATA</option>
          <option>Email</option>
          <option disabled>GROUP</option>
          <option value="All">All (AND)</option>
          <option value="At Least One">At Least One (OR)</option>
        </select>
      </div>
      {depth !== 0 ? <hr style={{"borderTop": "1px solid #ececec"}} /> : null}
    </div>
  );
};

export default FanFilterGroupForm;
