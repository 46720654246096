import React, { createContext, useContext, useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import BeefreeAddonIndex from "./BeefreeAddonIndex";

const BeefreeAddonIndexContext = createContext();

export function useBeefreeAddonIndexContext() {
  return useContext(BeefreeAddonIndexContext);
}

const BeefreeAddonIndexProvider = (props) => {
  const {
    beeUid,
    placeholderImageUrl,
    lastTouchedVenueOwnership,
    venueOwnerships,
  } = props;

  const [venueOwnership, setVenueOwnership] = useState(lastTouchedVenueOwnership);
  const [formState, setFormState] = useState({
    eventType: "upcoming",
    align: "left",
  });

  const insertContent = () => {
    const eventPrefix = `${formState.eventType}-${venueOwnership.id}-1`;

    window.parent.postMessage({
      action: "onSave",
      data: {
        type: "mixed",
        value: [
          {
            type: "image",
            value: {
              alt: "Event Image",
              href: `{{${eventPrefix}.url}}`,
              src: placeholderImageUrl,
              dynamicSrc: `{{${eventPrefix}.image}}`,
              target: "_blank",
              width: "560px",
            },
          },
          {
            type: "title",
            value: {
              text: `{{${eventPrefix}.presenter}}`,
              bold: false,
              size: 14,
              align: formState.align,
            },
          },
          {
            type: "title",
            value: {
              text: `{{${eventPrefix}.name}}`,
              size: 16,
              align: formState.align,
            },
          },
          {
            type: "title",
            value: {
              text: `{{${eventPrefix}.start_date}}`,
              size: 14,
              align: formState.align,
            },
          },
          {
            type: "title",
            value: {
              text: `Doors: {{${eventPrefix}.door_time}}`,
              align: formState.align,
              size: 14,
              bold: false,
            },
          },
          {
            type: "title",
            value: {
              text: `Show: {{${eventPrefix}.start_time}}`,
              align: formState.align,
              size: 14,
              bold: false,
            },
          },
          {
            type: "title",
            value: {
              text: `{{${eventPrefix}.venue.name}}`,
              align: formState.align,
              size: 14,
              bold: false,
            },
          },
        ],
      },
    }, "*");
  };

  const closeModal = () => {
    window.parent.postMessage({
      action: "onCancel",
    }, "*");
  };

  useEffect(() => {
    window.parent.postMessage({
      action: "loaded",
      data: {
        isRounded: true,
        hasTitleBar: false,
        showTitle: false,
        width: "435px",
        height: "450px",
      },
    }, "*");
  }, []);

  const value = {
    beeUid,
    placeholderImageUrl,
    venueOwnerships,
    venueOwnership,
    setVenueOwnership,
    formState,
    setFormState,
    insertContent,
    closeModal,
  };

  return (
    <BeefreeAddonIndexContext.Provider value={value}>
      {props.children}
    </BeefreeAddonIndexContext.Provider>
  );
};

const BeefreeAddonIndexContainer = (props) => (
  <BeefreeAddonIndexProvider {...props}>
    <ToastContainer />
    <BeefreeAddonIndex />
  </BeefreeAddonIndexProvider>
);

export default BeefreeAddonIndexContainer;
