import React from "react";

import { useBeefreeAddonIndexContext } from "./BeefreeAddonIndexContainer";

const containerStyle = {
  padding: 25,
  display: "flex",
  flexDirection: "column",
  color: "#333333",
  fontSize: 14,
};

const titleStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: 21,
  fontWeight: 700,
};

const subHeaderStyle = {
  fontSize: 12,
  marginBottom: 10,
};

const venueLabelStyle = {
  fontSize: 12,
  opacity: 0.5,
};

const selectStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  height: 40,
  padding: 8,
  marginBottom: 30,
  width: "100%",
};

const closeButtonStyle = {
  border: "none",
  background: "none",
};

const eventTypeContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const eventTypeButtonStyle = {
  width: "48%",
  fontWeight: 700,
  borderRadius: 4,
  padding: 10,
};

const eventTypeSubHeaderStyle = {
  fontSize: 10,
  marginBottom: 15,
};

const labelStyle = {
  marginBottom: 10,
};

const layoutContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontWeight: 700,
  marginBottom: 25,
};

const layoutButtonStyle = {
  width: "30%",
  fontWeight: 700,
  borderRadius: 4,
  padding: 10,
};

const footerContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
};

const enterButtonStyle = {
  background: "#1A82C4",
  border: "none",
  borderRadius: 4,
  color: "white",
  fontSize: 12,
  fontWeight: 700,
  borderRadius: 4,
  padding: "10px 20px",
};

const BroadcastIndex = () => {
  const {
    venueOwnerships,
    venueOwnership,
    setVenueOwnership,
    formState,
    setFormState,
    insertContent,
    closeModal,
  } = useBeefreeAddonIndexContext();

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>
        <div>Add Event</div>
        <button onClick={closeModal} style={closeButtonStyle}>
          <i className="fa fa-times" />
        </button>
      </div>
      <div style={subHeaderStyle}>Automatically add events from your Opendate calendar.</div>
      <div style={venueLabelStyle}>Venue</div>
      <select value={venueOwnerships.findIndex(o => o.id === venueOwnership.id)} onChange={(e) => setVenueOwnership(venueOwnerships[e.target.value])} style={selectStyle}>
        {venueOwnerships.map((option, index) => (
          <option key={`${option.venueable.name}-${index}`} value={index}>
            {option.venueable.name}
          </option>
        ))}
      </select>
      <div style={labelStyle}>Select the kind of event you want to include.</div>
      <div style={eventTypeContainerStyle}>
        <button style={{
          ...eventTypeButtonStyle,
          background: formState.eventType === "upcoming" ? "#EEF7FD" : "none",
          border: formState.eventType === "upcoming" ? "2px solid #1A82C4" : "2px solid #CCCCCC",
        }}
          onClick={() => setFormState({ ...formState, eventType: "upcoming" })}
        >
          Upcoming Event
        </button>
        <button style={{
          ...eventTypeButtonStyle,
          background: formState.eventType === "announced" ? "#EEF7FD" : "none",
          border: formState.eventType === "announced" ? "2px solid #1A82C4" : "2px solid #CCCCCC",
        }}
          onClick={() => setFormState({ ...formState, eventType: "announced" })}
        >
          Just Announced
        </button>
      </div>
      <div style={eventTypeSubHeaderStyle}>
        { formState.eventType === "Upcoming" ? (
          "Add the next upcoming events from your calendar."
        ) : (
          "Add just announced events from your calendar."
        )}
      </div>
      <div style={labelStyle}>Choose a layout.</div>
      <div style={layoutContainerStyle}>
        <button style={{
          ...layoutButtonStyle,
          background: formState.align === "left" ? "#EEF7FD" : "none",
          border: formState.align === "left" ? "2px solid #1A82C4" : "2px solid #CCCCCC",          
        }}
          onClick={() => setFormState({ ...formState, align: "left" })}
        >
          <i className="fa fa-align-left" />
        </button>
        <button style={{
          ...layoutButtonStyle,
          background: formState.align === "center" ? "#EEF7FD" : "none",
          border: formState.align === "center" ? "2px solid #1A82C4" : "2px solid #CCCCCC",          
        }}
          onClick={() => setFormState({ ...formState, align: "center" })}
        >
          <i className="fa fa-align-center" />
        </button>
        <button style={{
          ...layoutButtonStyle,
          background: formState.align === "right" ? "#EEF7FD" : "none",
          border: formState.align === "right" ? "2px solid #1A82C4" : "2px solid #CCCCCC",          
        }}
          onClick={() => setFormState({ ...formState, align: "right" })}
        >
          <i className="fa fa-align-right" />
        </button>
      </div>
      <div style={footerContainerStyle}>
        <button onClick={insertContent} style={enterButtonStyle}>
          Enter
        </button>
      </div>
    </div>
  );
};

export default BroadcastIndex;
