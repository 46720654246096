// Simple example of a React "smart" component

import { connect } from 'react-redux';
import PayoutManager from '../components/PayoutManager';
import * as actions from '../actions/payoutManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  dataTableTitle: state.dataTableTitle,
  dataTableDataSource: state.dataTableDataSource,
  dataTableOrder: state.dataTableOrder,
  dataTableColumns: state.dataTableColumns,
  dataTableEl: state.dataTableEl,
  filters: state.filters
});

const mapDispatchToProps = (dispatch) => {
  return {
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    filtersChanged: (filters) => dispatch(actions.filtersChanged(filters))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(PayoutManager);
