import PropTypes from 'prop-types';
import React from 'react';

import ExportCSV from './ExportCSV';

const TableSearch = ({
  typedSearchTerm,
  startSearchTermUpdate,
  dataTableEl,
  dataTableElChanged,
  csvDataSource,
  team,
  confirm,
  currentUser
}) => (
  <div>
    <div className="row dataTable-search-row">
      <div className="col"></div>
      <div className="col-xs-auto"
           style={{"paddingRight": "15px"}}>
        <form>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text"
                    id="table-search-addon">
                <i className="fa fa-search"></i>
              </span>
            </div>
            <input type="search"
                   className="form-control recent-order-search"
                   style={{"fontSize": "14px"}}
                   placeholder={"Search orders..."}
                   value={typedSearchTerm}
                   onChange={(e) => {startSearchTermUpdate(e.target.value)}}
                   onKeyPress={e => {
                     if (e.key === 'Enter') e.preventDefault();
                   }}
                   aria-label="Search"
                   aria-describedby="table-search-addon" />
          </div>
        </form>
      </div>
      <div className="col-xs-auto"
           style={{"paddingRight": "15px"}}>
        <div className="row">
          <div className="col">
            <ExportCSV dataTableEl={dataTableEl}
                       team={team}
                       confirm={confirm}
                       currentUser={currentUser}
                       csvDataSource={csvDataSource}
                       dataTableElChanged={dataTableElChanged} />
          </div>
          <div className="col-xs-auto">
            <a className="btn btn-external"
               style={{"fontSize": "14px"}}
               href={"/teams/" + team.id + "/confirms/" + confirm.id + "/attendees"}>
              <i className="fal fa-plus mr-2"></i>
              <strong>
                Add Attendees
              </strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

TableSearch.propTypes = {
  typedSearchTerm: PropTypes.string,
  startSearchTermUpdate: PropTypes.func.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  csvDataSource: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default TableSearch;
