// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ArtistDetails from '../components/ArtistDetails';
import * as actions from '../actions/artistDetailsActionCreators';
import * as tagManagerActions from '../../TagManager/actions/tagManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  artist: state.artist,
  tagManagerOpen: state.tagManagerOpen,
  tagManagerFilter: state.tagManagerFilter,
  availableTags: state.availableTags,
});

const mapDispatchToProps = (dispatch) => {
  return {
    artistChanged: (artist) => dispatch(actions.artistChanged(artist)),
    tagManagerOpenChanged: (tagManagerOpen) => dispatch(actions.tagManagerOpenChanged(tagManagerOpen)),
    tagManagerFilterChanged: (tagManagerFilter) => dispatch(actions.tagManagerFilterChanged(tagManagerFilter)),
    getActiveTags: (csrfToken, team, scope, taggable, onChange) => dispatch(tagManagerActions.getActiveTags(dispatch, csrfToken, team, scope, taggable, onChange)),
    getAvailableTags: (csrfToken, team, scope, onChange) => dispatch(tagManagerActions.getAvailableTags(dispatch, csrfToken, team, scope, onChange)),
    availableTagsChanged: (availableTags) => dispatch(actions.availableTagsChanged(availableTags)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ArtistDetails);
