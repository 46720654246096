import { combineReducers } from 'redux';

import {EditorState} from 'draft-js';
import { DateUtils } from 'react-day-picker';

import {
  DATA_TABLE_EL_CHANGED,
  OUTREACH_EXISTS_CHANGED,
  OFFER_MODAL_OPENED,
  OFFER_MODAL_CLOSED,
  CALENDAR_EVENT_ARTISTS_UPDATED,
  CALENDAR_EVENT_FORM_CLOSED,
  HOLD_GROUP_CALENDAR_DATE_CLICKED,
  HOLD_GROUP_HOLD_REMOVED,
  HOLD_GROUP_HOLD_POSITION_UPDATED,
  HOLD_GROUP_HOLD_AVAILABILITY_UPDATED,
  HOLD_GROUP_UPDATED,
  CONFIRM_START_DATE_UPDATED,
  CONFIRM_START_TIME_UPDATED,
  CONFIRM_UPDATED,
  CONFIRM_HOLD_UPDATED,
  CALENDAR_EVENT_TITLE_UPDATED,
  CALENDAR_EVENT_TYPE_UPDATED,
  CALENDAR_EVENT_NOTE_EDITOR_STATE_UPDATED,
  CALENDAR_EVENT_ERRORS_UPDATED,
  FOLLOW_UP_CHANGED,
  NEW_OUTREACH_CHANGED,
  CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED,
  CLOSE_CALENDAR_EVENT_MODAL,
  OFFER_CHANGED,
  PIPELINE_CHANGED,
  SUBMITTING_FORM_CHANGED
} from '../constants/outreachManagerConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case OUTREACH_EXISTS_CHANGED:
      return (action.outreachesExists ? state : {});
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const outreachesExists = (state = true, action) => {
  switch (action.type) {
    case OUTREACH_EXISTS_CHANGED:
      return action.outreachesExists;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const modalIsOpen = (state = false, action) => {
  switch (action.type) {
    case OFFER_MODAL_OPENED:
      return true;
    case OFFER_MODAL_CLOSED:
      return false;
    default:
      return state;
  }
};

const defaultOffer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueSelected = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const followUp = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_CHANGED:
      return action.followUp;
    default:
      return state;
  }
};

const outreachTemplates = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const newOutreach = (state = {}, action) => {
  switch (action.type) {
    case NEW_OUTREACH_CHANGED:
      return action.outreach;
    default:
      return state;
  }
};

const calendarEventArtists = (state = [], action) => {
  switch (action.type) {
    case CLOSE_CALENDAR_EVENT_MODAL:
      return [];
    case CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED:
      return action.artists;
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CLOSE_CALENDAR_EVENT_MODAL:
      return {};
    case CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED:
      return Object.assign({}, state, {
        outreach: action.outreach,
        pipeline: action.outreach.pipeline,
        rowTableEl: action.rowTableEl
      });
    default:
      return state;
  }
};

const holdGroup = (state = {}, action) => {
  switch (action.type) {
    case CLOSE_CALENDAR_EVENT_MODAL:
      return {};
    case CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED:
      return Object.assign({}, state, {
        outreach: action.outreach,
        pipeline: action.outreach.pipeline,
        rowTableEl: action.rowTableEl
      });
    default:
      return state;
  }
};

const offer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_CHANGED:
      return action.offer;
    default:
      return state;
  }
};

const offerTemplates = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pipeline = (state = {}, action) => {
  switch (action.type) {
    case PIPELINE_CHANGED:
      return action.pipeline;
    default:
      return state;
  }
};

const submittingForm = (state = false, action) => {
  switch (action.type) {
    case SUBMITTING_FORM_CHANGED:
      return action.submittingForm;
    default:
      return state;
  }
};

const outreachManagerReducer = combineReducers({ csrfToken, team, currentUser, dataTableTitle, dataTableDataSource, dataTableOrder, dataTableColumns, dataTableEl, outreachesExists, venueOwnerships, modalIsOpen, defaultOffer, venuesOptions, venueSelected, followUp, outreachTemplates, newOutreach, calendarEventArtists, confirm, holdGroup, offer, offerTemplates, pipeline, submittingForm });

export default outreachManagerReducer;
