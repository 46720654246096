import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const TotalsTable = ({
  ticketTypes,
  promoCode,
  ticketReservation,
  discountedTicketTypePrice,
  addOns,
  seatsIOSelectedObjects,
  discountedAddOnPrice,
  confirm,
  ticketInsurance
}) => (
  <div>
    {Object.keys(ticketReservation).length > 0 ? (
      <div className="row">
        <div className="col-12">
          <p style={{"fontSize": "18px", "marginTop": "20px", "marginBottom": "15px"}}>
            <strong>Order Summary</strong>
          </p>
          {ticketTypes.filter((ti) => ti.quantity && ti.quantity > 0).map((ticketType, index) =>
            ticketType.seatsIOObjects && ticketType.seatsIOObjects.length > 0 ? (
              ticketType.seatsIOObjects.map((object, objectIndex) =>
                <div className="row"
                     style={{"marginBottom": "12px"}}
                     key={objectIndex}>
                  <div className="col">
                    <p className="mb-0"
                       style={{"fontSize": "16px"}}>
                      {`${ticketType.name}: ${object.seat_assignment}`}
                    </p>
                  </div>
                  <div className="col-xs-auto text-right"
                       style={{"paddingRight": "15px"}}>
                    <p className="mb-0" style={{"fontSize": "16px"}}>
                      {ticketType.free ? (
                        <span>FREE</span>
                      ) : (
                        Object.keys(promoCode).length > 0 ? (
                          <span>{discountedTicketTypePrice(ticketType, promoCode)}</span>
                        ) : (
                          <span>{ticketType.price_to_currency}</span>
                        )
                      )}
                    </p>
                  </div>
                </div>
              )
            ) : (
              <div className="row"
                   style={{"marginBottom": "12px"}}
                   key={index}>
                <div className="col">
                  <p className="mb-0"
                     style={{"fontSize": "16px"}}>
                    {ticketType.quantity} x {ticketType.name}
                  </p>
                </div>
                <div className="col-xs-auto text-right"
                     style={{"paddingRight": "15px"}}>
                  <p className="mb-0" style={{"fontSize": "16px"}}>
                    {ticketType.free ? (
                      <span>FREE</span>
                    ) : (
                      Object.keys(promoCode).length > 0 ? (
                        <span>{discountedTicketTypePrice(ticketType, promoCode)}</span>
                      ) : (
                        <span>{ticketType.price_to_currency}</span>
                      )
                    )}
                  </p>
                </div>
              </div>
            )
          )}
          {addOns.filter((ao) => ao.quantity && ao.quantity > 0).map((addOn, index) =>
            addOn.seatsIOObjects && addOn.seatsIOObjects.length > 0 ? (
              addOn.seatsIOObjects.map((object, objectIndex) =>
                <div className="row"
                     style={{"marginBottom": "12px"}}
                     key={objectIndex}>
                  <div className="col">
                    <p className="mb-0"
                       style={{"fontSize": "16px"}}>
                      {`${addOn.name}: ${object.seat_assignment}`}
                    </p>
                  </div>
                  <div className="col-xs-auto text-right"
                       style={{"paddingRight": "15px"}}>
                    <p className="mb-0" style={{"fontSize": "16px"}}>
                      {Object.keys(promoCode).length > 0 ? (
                        <span>{discountedAddOnPrice(addOn, promoCode)}</span>
                      ) : (
                        <span>{addOn.price_to_currency}</span>
                      )}
                    </p>
                  </div>
                </div>
              )
            ) : (
              <div className="row"
                   style={{"marginBottom": "12px"}}
                   key={index}>
                <div className="col">
                  <p className="mb-0"
                     style={{"fontSize": "16px"}}>
                    {addOn.quantity} x {addOn.name}
                  </p>
                </div>
                <div className="col-xs-auto text-right"
                     style={{"paddingRight": "15px"}}>
                  <p className="mb-0" style={{"fontSize": "16px"}}>
                    {Object.keys(promoCode).length > 0 ? (
                      <span>{discountedAddOnPrice(addOn, promoCode)}</span>
                    ) : (
                      <span>{addOn.price_to_currency}</span>
                    )}
                  </p>
                </div>
              </div>
            )
          )}
          <hr style={{"borderTop": "1px solid #ececec"}} />
          <div className="row"
               style={{"marginBottom": "12px"}}>
            <div className="col">
              <p className="mb-0"
                 style={{"fontSize": "16px"}}>
                Subtotal
              </p>
            </div>
            <div className="col-xs-auto text-right"
                 style={{"paddingRight": "15px"}}>
              <p className="mb-0" style={{"fontSize": "16px"}}>
                {formatter.format(ticketReservation.subtotal_with_discount || 0.00)}
              </p>
            </div>
          </div>
          {(ticketReservation.abstract_order_items || []).map((abstractOrderItem, index) =>
            <div className="row"
                 style={{"marginBottom": "12px"}}
                 key={index}>
              {abstractOrderItem.custom_charge_type_type === "CustomChargeTypes::Donation" ? (
                <>
                  <div className="col">
                    <p className="mb-0"
                      style={{"fontSize": "16px"}}>
                      {abstractOrderItem.name}
                    </p>
                  </div>
                  <div className="col-xs-auto text-right"
                      style={{"paddingRight": "15px"}}>
                    <p className="mb-0" style={{"fontSize": "16px"}}>
                      <span>{abstractOrderItem.summed_price_to_currency}</span>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="col">
                    <p className="mb-0"
                      style={{"fontSize": "16px"}}>
                      {abstractOrderItem.quantity} x {abstractOrderItem.price_to_currency} {abstractOrderItem.name}
                    </p>
                  </div>
                  <div className="col-xs-auto text-right"
                      style={{"paddingRight": "15px"}}>
                    <p className="mb-0" style={{"fontSize": "16px"}}>
                      <span>{abstractOrderItem.summed_price_to_currency}</span>
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
          {(ticketReservation.in_memory_fees || []).map((fee, index) =>
            <div className="row"
                 key={index}
                 style={{"marginBottom": "12px"}}>
              <div className="col">
                <p className="mb-0"
                   style={{"fontSize": "16px"}}>
                  {fee.name}
                </p>
              </div>
              <div className="col-xs-auto text-right"
                   style={{"paddingRight": "15px"}}>
                <p className="mb-0" style={{"fontSize": "16px"}}>
                  {formatter.format(fee.value)}
                </p>
              </div>
            </div>
          )}
          {parseFloat(ticketReservation.taxes) > 0.00 ? (
            <div className="row"
                 style={{"marginBottom": "12px"}}>
              <div className="col">
                <p className="mb-0"
                   style={{"fontSize": "16px"}}>
                  Taxes
                </p>
              </div>
              <div className="col-xs-auto text-right"
                   style={{"paddingRight": "15px"}}>
                <p className="mb-0" style={{"fontSize": "16px"}}>
                  {formatter.format(ticketReservation.taxes)}
                </p>
              </div>
            </div>
          ) : null}
          <hr style={{"borderTop": "1px solid #ececec"}} />
          <div className="row"
               style={{"marginBottom": "12px"}}>
            <div className="col">
              <p className="mb-0"
                 style={{"fontSize": "16px"}}>
                <strong>Total</strong>
              </p>
            </div>
            <div className="col-xs-auto text-right"
                 style={{"paddingRight": "15px"}}>
              <p className="mb-0" style={{"fontSize": "16px"}}>
                <strong>{formatter.format(ticketReservation.total || 0.00)}</strong>
              </p>
            </div>
          </div>
          {ticketInsurance && ticketInsurance.quote_id ? (
            <div className="row"
                 style={{"marginBottom": "12px"}}>
              <div className="col">
                <p className="mb-0"
                   style={{"fontSize": "16px"}}>
                  <strong>Insurance</strong>
                </p>
              </div>
              <div className="col-xs-auto text-right"
                   style={{"paddingRight": "15px"}}>
                <p className="mb-0" style={{"fontSize": "16px"}}>
                  <strong>{formatter.format(ticketInsurance.premium_amount)}</strong>
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {confirm.checkout_widget_memo && confirm.checkout_widget_memo.length > 0 ? (
          <div className="col-12">
            <p className="small text-muted"
              style={{
                "marginTop": "5px",
                "marginBottom": "12px"
              }}>
              {confirm.checkout_widget_memo}
            </p>
          </div>
        ) : null}
      </div>
    ) : null}
  </div>
);

TotalsTable.propTypes = {
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  ticketReservation: PropTypes.object,
  discountedTicketTypePrice: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  seatsIOSelectedObjects: PropTypes.array,
  discountedAddOnPrice: PropTypes.func.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketInsurance: PropTypes.object
};

export default TotalsTable;
