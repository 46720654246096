import React, { createContext, useState, useContext } from "react";
import { ToastContainer } from "react-toastify";

import SegmentNew from "./SegmentNew";

const SegmentNewContext = createContext();

export function useSegmentNewContext() {
  return useContext(SegmentNewContext);
}

const SegmentNewProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
  } = props;

  const [segment, setSegment] = useState({
    type: "Segments::Manual",
    name: "",
  });

  const value = {
    csrfToken,
    user,
    team,
    segment,
    setSegment,
  };

  return (
    <SegmentNewContext.Provider value={value}>
      {props.children}
    </SegmentNewContext.Provider>
  );
};

const SegmentNewContainer = (props) => (
  <SegmentNewProvider {...props}>
    <ToastContainer />
    <SegmentNew />
  </SegmentNewProvider>
);

export default SegmentNewContainer;
