// Simple example of a React "smart" component

import { connect } from 'react-redux';
import Settlement from '../components/Settlement';
import * as actions from '../actions/settlementActionCreators';
import * as tagManagerActions from '../../TagManager/actions/tagManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  budget: state.budget,
  offer: state.offer,
  confirm: state.confirm,
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  offerId: state.offerId,
  itemToEdit: state.itemToEdit,
  requestFormat: state.requestFormat,
  isSettling: state.isSettling,
  editedDropCount: state.editedDropCount,
  editedCompCount: state.editedCompCount,
  editedKillCount: state.editedKillCount,
  isSaving: state.isSaving,
  showPageFrame: state.showPageFrame
});

const mapDispatchToProps = (dispatch) => {
  return {
    createSettlementLineItem: (csrfToken, team, params) => dispatch(actions.createSettlementLineItem(dispatch, csrfToken, team, params)),
    deleteSettlementLineItem: (csrfToken, team, settlementLineItem) => dispatch(actions.deleteSettlementLineItem(dispatch, csrfToken, team, settlementLineItem)),
    updateSettlementLineItem: (csrfToken, team, settlementLineItem) => dispatch(actions.updateSettlementLineItem(dispatch, csrfToken, team, settlementLineItem)),
    itemToEditChanged: (itemToEdit) => dispatch(actions.itemToEditChanged(itemToEdit)),
    submitSettlementOverrideForm: (csrfToken, team, offerId, itemToEdit) => dispatch(actions.submitSettlementOverrideForm(dispatch, csrfToken, team, offerId, itemToEdit)),
    deleteSettlementOverride: (csrfToken, team, settlementOverride) => dispatch(actions.deleteSettlementOverride(dispatch, csrfToken, team, settlementOverride)),
    editedDropCountChanged: (editedDropCount) => dispatch(actions.editedDropCountChanged(editedDropCount)),
    editedCompCountChanged: (editedCompCount) => dispatch(actions.editedCompCountChanged(editedCompCount)),
    editedKillCountChanged: (editedKillCount) => dispatch(actions.editedKillCountChanged(editedKillCount)),
    saveOverrides: (csrfToken, team, offerId, budget, overrides) => dispatch(actions.saveOverrides(dispatch, csrfToken, team, offerId, budget, overrides)),
    offerChanged: (offer) => dispatch(actions.offerChanged(offer)),
    isSavingChanged: (isSaving) => dispatch(actions.isSavingChanged(isSaving)),
    getActiveTags: (csrfToken, team, scope, taggable, onChange) => dispatch(tagManagerActions.getActiveTags(dispatch, csrfToken, team, scope, taggable, onChange)),
    getAvailableTags: (csrfToken, team, scope, onChange) => dispatch(tagManagerActions.getAvailableTags(dispatch, csrfToken, team, scope, onChange)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(Settlement);
