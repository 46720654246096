import { createStore, applyMiddleware } from 'redux';
import SimilarArtistsReducer from '../reducers/SimilarArtistsReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(SimilarArtistsReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
