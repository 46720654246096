import { combineReducers } from 'redux';

import {
  CALENDAR_EVENT_CHANGED,
  PERFORMERS_CHANGED,
  SELECTED_PERFORMER_CHANGED
} from '../constants/linkOfferModalConstants';

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const performers = (state = [], action) => {
  switch (action.type) {
    case PERFORMERS_CHANGED:
      return action.performers;
    case CALENDAR_EVENT_CHANGED:
      return [];
    default:
      return state;
  }
};

const calendarEvent = (state = {}, action) => {
  switch (action.type) {
    case CALENDAR_EVENT_CHANGED:
      return action.calendarEvent;
    default:
      return state;
  }
};

const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedPerformer = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_PERFORMER_CHANGED:
      return action.performer;
    case CALENDAR_EVENT_CHANGED:
      return  {};
    default:
      return state;
  }
};

const onSuccess = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const linkOfferModalReducer = combineReducers({
  team,
  csrfToken,
  currentUser,
  offer,
  performers,
  calendarEvent,
  onClose,
  selectedPerformer,
  onSuccess
});

export default linkOfferModalReducer;
