import React, { useState } from "react";
import ReactDOM from "react-dom";
import Popup from "reactjs-popup";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import { toastError, toastSuccess } from "../../../shared/toastHelper";
import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";

const headerStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
};

const titleStyle = {
  color: "#333333",
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 20,
};

const tabContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

const tabStyle = {
  background: "transparent",
  color: "#666666",
  border: "none",
  borderRadius: 5,
  fontSize: 12,
  fontWeight: 700,
  padding: "5px 10px",
};

const selectedTabStyles = {
  background: "#DFF1FB",
  color: "#1982C4",
};

const containerStyle = {
  backgroundColor: "white",
  borderRadius: 10,
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  margin: "20px 0",
  padding: 20,
};

const popupContentStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "0px",
  width: "fit-content",
};

const menuLineStyle = {
  display: "flex",
  flexDirection: "row",
  fontSize: "12px",
  fontWeight: "normal",
  margin: "5px",
  paddingRight: "10px",
};

const menuOptionStyle = {
  width: "28px"
};

const SegmentsDashboard = ({
  calendar_event_segment_data_table_props,
  custom_segment_data_table_props,
  team,
  csrfToken,
}) => {
  const [selectedTab, setSelectedTab] = useState("Upcoming Events");
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const handleTab = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);
  };

  const { dataSource, ...dataTableProps } = calendar_event_segment_data_table_props;
  const tabDataSource = `${dataSource}?tab=${selectedTab}`;

  const defaultOrder = selectedTab === "Upcoming Events" ?
    [[0, "asc"]] :
    [[0, "desc"]];
  
  const deleteSegment = (segmentId) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.delete(`/teams/${team.id}/segments/${segmentId}`)
      .then(() => {
        toastSuccess("Segment deleted successfully");
        setLastUpdated(new Date());
      })
      .catch(() => toastError("Could not delete segment, please try again"));
  };

  return (
    <div>
      <ToastContainer />
      <div style={headerStyle}>
        <div>
          <div style={titleStyle}>Segments</div>
          <div style={tabContainerStyle}>
            <button
              onClick={(e) => handleTab(e, "Upcoming Events")}
              style={{
                ...tabStyle,
                ...(selectedTab === "Upcoming Events" && selectedTabStyles),
              }}
            >
              Upcoming Events
            </button>
            <button
              onClick={(e) => handleTab(e, "Past Events")}
              style={{
                ...tabStyle,
                ...(selectedTab === "Past Events" && selectedTabStyles),
              }}
            >
              Past Events
            </button>
            <button
              onClick={(e) => handleTab(e, "Custom Segments")}
              style={{
                ...tabStyle,
                ...(selectedTab === "Custom Segments" && selectedTabStyles),
              }}
            >
              Custom Segments
            </button>
          </div>
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => window.location.href = `/teams/${team.id}/segments/new`}
          >
            New Segment
          </button>
        </div>
      </div>
      <div style={containerStyle}>
        {selectedTab === "Custom Segments" ? (
          <ContainerlessTableViewApp
            key={lastUpdated}
            {...custom_segment_data_table_props}
            rowCallback={(row, data) => {
              const toolsCell = row.getElementsByClassName("tools-cell")[0];
              if (!toolsCell) return;

              ReactDOM.render((
                <Popup arrow={false}
                  offsetY={5}
                  position="bottom right"
                  contentStyle={popupContentStyle}
                  onOpen={(e) => { e.stopPropagation(); e.preventDefault(); }}
                  trigger={_open => (
                    <a href="#"
                      onClick={(e) => { e.preventDefault(); }}
                      className="text-muted"
                    >
                      <i className="fas fa-ellipsis-v"></i>
                    </a>
                  )} >
                  {_close => (
                    <div className='row text-left'>
                      <div className="col-12">
                        <ul className="list-group offer-inline-menu highlight-primary">
                          <li className="list-group-item">
                            <a className="text-muted"
                              style={menuLineStyle}
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                if (data.type === "Segments::Manual") {
                                  window.location.href = `/teams/${team.id}/segments/${data.id}`;
                                } else {
                                  window.location.href = `/teams/${team.id}/segments/${data.id}/edit`;
                                }
                              }}
                            >
                              <span className="d-inline-block text-center" style={menuOptionStyle}>
                                <i className="fas fa-pencil"></i>
                              </span>
                              Edit
                            </a>
                          </li>
                          <li className="list-group-item">
                            <a className="text-muted"
                              style={menuLineStyle}
                              href="#"
                              onClick={(e) => { e.preventDefault(); deleteSegment(data.id); }}
                            >
                              <span className="d-inline-block text-center" style={menuOptionStyle}>
                                <i className="fas fa-trash"></i>
                              </span>
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </Popup>
              ), toolsCell);
            }}
          />
        ) : (
          <ContainerlessTableViewApp
            key={selectedTab}
            dataSource={tabDataSource}
            order={defaultOrder}
            {...dataTableProps}
          />
        )}
      </div>
    </div>
  );
};

export default SegmentsDashboard;
