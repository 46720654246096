import { combineReducers } from 'redux';

import {
  DATA_TABLE_EL_CHANGED,
  FOLLOW_UP_EXISTS_CHANGED,
  FOLLOW_UP_MANAGER_FOLLOW_UP_CHANGED,
  PIPELINE_CHANGED,
  NEW_OUTREACH_CHANGED
} from '../constants/followUpManagerConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    case FOLLOW_UP_EXISTS_CHANGED:
      return (action.followUpsExists ? state : {});
    default:
      return state;
  }
};

const exampleArtists = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const followUpsExists = (state = true, action) => {
  switch (action.type) {
    case FOLLOW_UP_EXISTS_CHANGED:
      return action.followUpsExists;
    default:
      return state;
  }
};

const followUp = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_MANAGER_FOLLOW_UP_CHANGED:
      return action.followUp;
    default:
      return state;
  }
};

const pipeline = (state = {}, action) => {
  switch (action.type) {
    case PIPELINE_CHANGED:
      return action.pipeline;
    default:
      return state;
  }
};

const newOutreach = (state = {}, action) => {
  switch (action.type) {
    case NEW_OUTREACH_CHANGED:
      return action.outreach;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const outreachTemplates = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const followUpManagerReducer = combineReducers({ csrfToken, team, currentUser, dataTableTitle, dataTableDataSource, dataTableOrder, dataTableColumns, dataTableEl, exampleArtists, followUpsExists, followUp, pipeline, newOutreach, venueOwnerships, outreachTemplates });

export default followUpManagerReducer;
