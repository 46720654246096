/* eslint-disable import/prefer-default-export */

import {
  DATA_TABLE_EL_CHANGED,
  FOLLOW_UP_EXISTS_CHANGED,
  FOLLOW_UP_MANAGER_FOLLOW_UP_CHANGED,
  PIPELINE_CHANGED,
  NEW_OUTREACH_CHANGED
} from '../constants/followUpManagerConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {EditorState} from 'draft-js';

import $ from 'jquery';
require('datatables.net-bs4');

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const fetchNewFollowUp = (dispatch, team) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/follow_ups/new")
      .then(({ data }) => {
        dispatch(followUpChanged(data));
      })
  };
};

export const deleteFollowUp = (dispatch, team, followUp, csrfToken, rowTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/follow_ups/" + followUp.id)
      .then(({ data }) => {
        $(rowTableEl).DataTable().ajax.reload((json) => {
          dispatch(followUpExistsChanged(json.recordsTotal > 0))
        });
      });
  };
};

export const completeFollowUp = (dispatch, team, followUp, csrfToken, rowTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/follow_ups/" + followUp.id, {
        follow_up: {
          completed: true
        }
      })
      .then(({ data }) => {
        $(rowTableEl).DataTable().ajax.reload();
      });
  };
};

export const fetchEditFollowUp = (dispatch, team, followUp) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/follow_ups/" + followUp.id + "/edit")
      .then(({ data }) => {
        var remindAt = new Date(data.remind_at_midday);
        var followUp = Object.assign({}, data, {remind_at: remindAt});

        dispatch(followUpChanged(followUp));
      })
  };
};

export const followUpExistsChanged = (followUpsExists) => ({
  type: FOLLOW_UP_EXISTS_CHANGED,
  followUpsExists
});

export const followUpChanged = (followUp) => ({
  type: FOLLOW_UP_MANAGER_FOLLOW_UP_CHANGED,
  followUp
});

export const fetchPipeline = (dispatch, team, pipelineId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/pipelines/" + pipelineId)
      .then(({ data }) => {
        dispatch(pipelineChanged(data));
      })
  };
};

export const pipelineChanged = (pipeline) => ({
  type: PIPELINE_CHANGED,
  pipeline
});

export const newOutreachChanged = (outreach) => ({
  type: NEW_OUTREACH_CHANGED,
  outreach
});

export const fetchNewOutreach = (dispatch, team, followUp) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/outreaches/new", {
        params: {
          artist_id: followUp.artist.permalink
        }
      })
      .then(({ data }) => {
        var outreach = Object.assign({}, data, {
          body: EditorState.createEmpty(),
          pipeline: followUp.pipeline
        });

        dispatch(newOutreachChanged(outreach));
      })
  };
};
