import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { ToastContainer, toast } from 'react-toastify';
import OutreachModalApp from '../../OutreachModal/startup/OutreachModalApp';
import OfferV2ModalApp from '../../OfferV2Modal/startup/OfferV2ModalApp';

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "20px"
}

const AddOutreach = ({
  csrfToken,
  currentUser,
  team,
  outreach,
  fetchNewOutreach,
  venuesOptions,
  venueSelected,
  artist,
  venueOwnerships,
  outreachTemplates,
  outreachChanged,
  lastPipelineEventChanged,
  fetchUnseenPipelineEvents,
}) => {

  const [isOfferModalOpen, toggleOfferModal] = useState()

  useEffect(() => {
    fetchUnseenPipelineEvents(team, artist);
  }, [])

  return (
    <div>
      <ToastContainer />
      {currentUser.can_manage_outreaches ? (
        <div>
          <div>
            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Actions
            </button>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-aw" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="#"
                onClick={
                (e) => {
                  e.preventDefault();
                  fetchNewOutreach(team, artist);
                }
                }>Add to Outreach</a>
                <a className="dropdown-item" href="#" onClick={()=>toggleOfferModal(true) }>Create Offer</a>
            </div>
          </div>
        </div>
      ) : null}

      <Popup
        open={isOfferModalOpen}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={false}
        className={"offer-modal"}
        onClose={() =>
          toggleOfferModal(false)
        }
      >
        <OfferV2ModalApp
          currentUser={currentUser}
          csrfToken={csrfToken}
          onClose={
            () => {
              toggleOfferModal(false)
            }
          }
          venuesOptions={venuesOptions}
          venueSelected={venueSelected}
          team={team}
          selectedArtist={artist}
          offer={{create_calendar_event:true}}
        />
      </Popup>

      <Popup
        open={Object.keys(outreach).length > 0}
        contentStyle={modalContentStyle}
        className={"popup-modal"}
        closeOnDocumentClick={false}
        onClose={() => outreachChanged({})}
      >
        <OutreachModalApp
          csrfToken={csrfToken}
          team={team}
          outreach={outreach}
          artist={artist}
          venueOwnerships={venueOwnerships}
          onOutreachCreate={
            (outreach) => {
              lastPipelineEventChanged(outreach.latest_pipeline_event);
              outreachChanged({});
              fetchUnseenPipelineEvents(team, artist);

              toast.success("Added outreach for " + outreach.artist.name, {
                position: toast.POSITION.TOP_CENTER,
                draggable: false,
                closeOnClick: false,
                autoClose: 5000,
                hideProgressBar: true
              });
            }
          }
          onClose={
            () => {
              outreachChanged({});
            }
          }
          outreachTemplates={outreachTemplates}
        />
      </Popup>
    </div>
  )
};

AddOutreach.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  outreach: PropTypes.object,
  fetchNewOutreach: PropTypes.func.isRequired,
  artist: PropTypes.object.isRequired,
  venueOwnerships: PropTypes.array,
  lastPipelineEvent: PropTypes.object,
  outreachTemplates: PropTypes.array,
  outreachChanged: PropTypes.func.isRequired,
  lastPipelineEventChanged: PropTypes.func.isRequired,
  pipelineEvents: PropTypes.array,
  venuesOptions:PropTypes.array,
  venueSelected: PropTypes.object,
  pipelineEventsChanged: PropTypes.func.isRequired,
  fetchUnseenPipelineEvents: PropTypes.func.isRequired,
  unseenPipelineEventsChanged: PropTypes.func.isRequired
};

export default AddOutreach;
