import PropTypes from 'prop-types';
import React from 'react';
var ProgressBar = require('progressbar.js')

class SemiCircle extends React.Component {
  static propTypes = {
    percentage: PropTypes.number.isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string
  };

  static defaultProps = {
    className: "",
    wrapperClassName: ""
  }

  constructor(props) {
    super(props);

    this.state = {
      percentage: this.props.percentage,
      className: this.props.className,
      wrapperClassName: this.props.wrapperClassName
    };
  }

  componentDidMount() {
    this.$el = $(this.el);

    var barColor = null;
    var trailColor = null;

    if(this.state.percentage <= 33){
      barColor   = "rgba(255,89,94,1.0)";
      trailColor = "rgba(255,89,94,0.25)";
    } else if (this.state.percentage <= 66) {
      barColor   = "rgba(255,202,58,1.0)";
      trailColor = "rgba(255,202,58,0.25)";
    } else {
      barColor   = "rgba(138,201,38,1.0)";
      trailColor = "rgba(138,201,38,0.25)";
    }

    this.bar = new ProgressBar.SemiCircle(this.$el[0], {
      strokeWidth: 6,
      trailColor: trailColor,
      trailWidth: 6,
      easing: 'easeInOut',
      duration: 1400,
      text: {
        value: '',
        alignToBottom: true
      },
      from: {color: barColor},
      to: {color: barColor},
      step: (state, bar) => {
        var value = Math.round(bar.value() * 100);

        if (value === 0) {
          bar.setText('0%');
        } else {
          bar.setText(value + "%");
        }

        bar.path.setAttribute('stroke', state.color);
      }
    });

    if(this.state.percentage > 0){
      this.updateBar();
    } else {
      this.bar.setText('0%');
    }
  }

  componentWillUnmount() {
    this.bar.destroy();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.className !== this.props.className) {
      this.setState({className: this.props.className});
    }

    if (prevProps.wrapperClassName !== this.props.wrapperClassName) {
      this.setState({wrapperClassName: this.props.wrapperClassName});
    }

    if (prevProps.percentage !== this.props.percentage) {
      this.setState({percentage: this.props.percentage});

      setTimeout(() => {
        this.updateBar();
      }, 1);
    }
  }

  updateBar() {
    var animatePercentage = 0;
    if(this.state.percentage > 100){
      animatePercentage = 100;
    } else {
      animatePercentage = this.state.percentage;
    }

    this.bar.animate((animatePercentage / 100));
  }

  render() {
    return (
      <div className={this.state.wrapperClassName}>
        <div className={"SemiCircle-progressbar " + this.state.className}
             ref={el => this.el = el}>
        </div>
      </div>
    );
  }
}

export default SemiCircle;
