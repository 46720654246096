// Simple example of a React "smart" component

import { connect } from 'react-redux';
import InboundSubmissions from '../components/InboundSubmissions';
import * as actions from '../actions/inboundSubmissionsActionCreators';
import * as tagManagerActions from '../../TagManager/actions/tagManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  dataTableTitle: state.dataTableTitle,
  dataTableDataSource: state.dataTableDataSource,
  dataTableOrder: state.dataTableOrder,
  dataTableColumns: state.dataTableColumns,
  dataTableEl: state.dataTableEl,
  inboundSubmissionExists: state.inboundSubmissionExists,
  openInboundSubmission: state.openInboundSubmission,
  venueOwnerships: state.venueOwnerships,
  gettingFormLinks: state.gettingFormLinks,
  filters: state.filters,
  canViewArtist: state.canViewArtist,
  tagManagerOpen: state.tagManagerOpen,
  tagManagerFilter: state.tagManagerFilter,
  availableTags: state.availableTags,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    openInboundSubmissionChanged: (openInboundSubmission) => dispatch(actions.openInboundSubmissionChanged(openInboundSubmission)),
    gettingFormLinksChanged: (gettingFormLinks) => dispatch(actions.gettingFormLinksChanged(gettingFormLinks)),
    updateFilter: (filter, value) => dispatch(actions.updateFilter(filter, value)),
    clearFilterValues: (filter, value) => dispatch(actions.clearFilterValues(filter, value)),
    debouncedUpdateFilter: (filter, value) => dispatch(actions.debouncedUpdateFilter(dispatch, filter, value)),
    deleteInboundSubmission: (csrfToken, team, inboundSubmission, dataTableEl) => dispatch(actions.deleteInboundSubmission(dispatch, csrfToken, team, inboundSubmission, dataTableEl)),
    tagManagerOpenChanged: (tagManagerOpen) => dispatch(actions.tagManagerOpenChanged(tagManagerOpen)),
    tagManagerFilterChanged: (tagManagerFilter) => dispatch(actions.tagManagerFilterChanged(tagManagerFilter)),
    getActiveTags: (csrfToken, team, scope, taggable, onChange) => dispatch(tagManagerActions.getActiveTags(dispatch, csrfToken, team, scope, taggable, onChange)),
    getAvailableTags: (csrfToken, team, scope, onChange) => dispatch(tagManagerActions.getAvailableTags(dispatch, csrfToken, team, scope, onChange)),
    availableTagsChanged: (availableTags) => dispatch(actions.availableTagsChanged(availableTags)),
    venueOwnershipsChanged: (venueOwnerships) => dispatch(actions.venueOwnershipsChanged(venueOwnerships)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(InboundSubmissions);
