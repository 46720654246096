import PropTypes from 'prop-types';
import React from 'react';

const PageDetailFrame = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  pageDetail,
  children,
  provided,
  deletePageDetail
}) => (
  <div className="form-row pb-3">
    <div className="col">
      {children}
    </div>
    <div className="col-xs-auto"
         style={{"paddingRight": "15px"}}>
      <ul className={"no-bullets p-0 " + (pageDetail.type === "PageDetails::Video" ?  "mt-4" : "")}>
        <li>
          <a href="#"
             onClick={
               (e) => {
                 e.preventDefault();
                 deletePageDetail(csrfToken, team, pageDetail);
               }
             }
             className="btn btn-muted btn-muted-danger btn-sm small text-dark mb-2">
            <i className="far fa-trash fa-fw"></i>
          </a>
        </li>
        <li>
          <a {...provided.dragHandleProps}
             onClick={
               (e) => {
                 e.preventDefault();
               }
             }
             href="#"
             className="btn btn-link btn-sm small text-dark">
            <i className="fas fa-grip-vertical fa-fw"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
);

PageDetailFrame.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  pageDetail: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  provided: PropTypes.object.isRequired,
  deletePageDetail: PropTypes.func.isRequired
};

export default PageDetailFrame;
