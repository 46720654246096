import PropTypes from 'prop-types';
import React from 'react';

var _ = require('lodash');
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
const axios = require('axios').default;
import DatePicker from "react-datepicker";
import RichEditor from '../../RichEditor/components/RichEditor';

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const canSubmitForm = (offer) => {
  var hasArtist = (
    offer.primaryPerformer
      && (offer.primaryPerformer.artist || offer.primaryPerformer.artistSuggestion)
  );

  if(offer.calendarEvent && offer.calendarEvent.value !== 0){
    return (
      offer.calendarEvent
    );
  } else if (offer.createCalendarEvent) {
    return (
      offer.venueSelected
        && offer.startDate
        && offer.startTime
        && offer.eventName
    );
  } else {
    return (
      offer.venueSelected
        && offer.eventName
        && offer.eventName.length > 0
    );
  }
};

const canCreateOfferTemplate = (offerTemplateName) => {
  return (
    offerTemplateName
      && offerTemplateName.length > 0
  );
};

const performerIsDisabled = (offer, performer) => {
  return (
    performer.offer
      && performer.offer.id
      && performer.offer.id !== offer.id
  );
};

const performerIsSelected = (offer, performer) => {
  return (offer.performer && offer.performer.id === performer.id ? true : false);
};

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name, permalink: data.permalink };
        });

        callback(newOptions);
      })
  });
}, 250);

const debouncedConfirmFetch = _.debounce((inputValue, callback, team) => {
  new Promise(resolve => {
    axios.get('/teams/' + team.id + '/calendar_events/autocomplete', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        var createNewOption = {
          value: 0,
          label: "Create a New Event (this will add a confirmed event to your calendar)"
        }

        callback([createNewOption, ...newOptions]);
      })
  });
}, 250);

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const OfferForm = ({
  offer,
  venuesOptions,
  closeModal,
  updateOfferVenue,
  updateOfferPrimaryArtist,
  updateOfferStartDate,
  addOfferDealTermLineItem,
  removeOfferDealTermLineItem,
  updateOfferDealTermLineItemLabel,
  updateOfferDealTermLineItemDescription,
  updateOfferAdditionalTerms,
  updateOfferContactDetails,
  csrfToken,
  team,
  submitOfferForm,
  fetchArtistData,
  updateOfferRemovePerformer,
  updateOfferAgentName,
  updateOfferAgentEmail,
  defaultOffer,
  dataTableEl,
  offerTemplateName,
  updateOfferTemplateName,
  createOfferTemplate,
  offerTemplates,
  fetchOfferTemplate,
  selectedOfferTemplate,
  selectOfferTemplate,
  addArtistSuggestion,
  currentUser,
  offerCalendarEventChanged,
  fetchCalendarEventPerformers,
  offerPerformerSelected,
  offerStartTimeChanged,
  updateOfferEventName,
  offerPerformerUnselected,
  updateOfferDollarOrPercentageOperator,
  updateOfferDollarAmount,
  updateOfferUpsidePercentage,
  hiddenFileUploadInputEl,
  hiddenFileUploadInputElChanged,
  uploadAttachment,
  attachmentRemoved
}) => (
  <form style={{"margin": "30px 20px"}}
        onSubmit={
          (e) => {
            e.preventDefault();

            if(canSubmitForm(offer)){
              submitOfferForm(csrfToken, team, offer);
            }
          }
        }>
    <input
      ref={el => {
        if(el){
          hiddenFileUploadInputElChanged(el);
        }
      }}
      onChange={
        (e) => {
          var files = e.target.files;
          for (var i = 0, file; file = files[i]; i++) {
            uploadAttachment(csrfToken, team, file);
          }

          e.target.value = null;
        }
      }
      type="file"
      multiple={true}
      id="hidden-file-input"
      style={{ display: "none" }} />
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8 pb-3 pb-md-0">
            <h4 className="d-inline">
              <strong>New Offer</strong>
              <span className="badge badge-secondary ml-2" style={{"fontSize": "12px"}}>
                Draft
              </span>
            </h4>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <select className="custom-select"
                    style={{"border": "2px solid #f2f2f2", "color": "#d4d4d4"}}
                    value={
                      (Object.keys(selectedOfferTemplate).length ? selectedOfferTemplate.id : "")
                    }
                    onChange={
                      (e) => {
                        var selected = offerTemplates.find((offerTemplate) => {
                          return offerTemplate.id === parseInt(e.target.value);
                        });

                        selectOfferTemplate(selected);
                        fetchOfferTemplate(team, e.target.value);
                      }
                    }>
              <option value="" disabled="disabled">
                Choose offer template
              </option>
              {offerTemplates.map((offerTemplate, index) => (
                <option key={index} value={offerTemplate.id}>
                  {offerTemplate.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="col-12 pt-2">
        <fieldset className="form-group">
          <div className="form-row">
            <legend className="col-form-label">
              <strong>Event Details</strong>
            </legend>
          </div>
        </fieldset>
        <fieldset className="form-group">
          <div className="form-row">
            <div className="col-12">
              <label htmlFor="offer-primary-artist" className="text-muted">
                Event (Leave blank to create an offer unlinked to an event. You can change this later.)
              </label>
              <AsyncSelect cacheOptions
                           placeholder={"Select event"}
                           isClearable={true}
                           styles={selectStyles}
                           menuPortalTarget={document.querySelector('body')}
                           defaultOptions
                           id="offer-primary-artist"
                           onChange={
                             (option) => {
                               offerCalendarEventChanged(option)

                               if(option && option.value !== 0){
                                 fetchCalendarEventPerformers(team, option.value, offer);
                               }
                             }
                           }
                           value={offer.calendarEvent || ""}
                           loadOptions={(inputValue, callback) => debouncedConfirmFetch(inputValue, callback, team)} />
            </div>
          </div>
        </fieldset>
        {offer.calendarEvent && offer.calendarEvent.value ? (
          <fieldset className="form-group">
            <div className="form-row">
              <div className='col-12'>
                {offer.calendarEventPerformers && offer.calendarEventPerformers.length > 0 ? (
                  <label className="text-muted mb-0">
                    Choose an artist
                  </label>
                ) : null}
                {offer.calendarEventPerformers && offer.calendarEventPerformers.map((performer, index) => (
                  <div key={index}
                       onClick={
                         (e) => {
                           e.preventDefault();

                           if(!performerIsDisabled(offer, performer) && !performerIsSelected(offer, performer)){
                             offerPerformerSelected(performer);
                           }
                         }
                       }
                       className={"col-12 p-2 mt-2 selectable-card " + (performerIsSelected(offer, performer) ? "selected " : "") + (performerIsDisabled(offer, performer) ? "disabled " : "")}>
                    <div className="row">
                      <div className="col-xs-auto d-flex" style={{'paddingLeft': '20px'}}>
                        <div className="custom-control custom-radio align-self-center">
                          <input type="radio"
                                 checked={performerIsSelected(offer, performer)}
                                 onChange={() => {}}
                                 disabled={performerIsDisabled(offer, performer)}
                                 id={"partnership-" + index}
                                 name={"offer-partnership"}
                                 className="custom-control-input" />
                          <label className="custom-control-label"
                                 htmlFor={"partnership-" + index}>
                            &nbsp;
                          </label>
                        </div>
                      </div>
                      <div className="col-xs-auto" style={{'paddingLeft': '15px'}}>
                        <img src={performer.artistable.image_thumb_url_for_json}
                             className="img-fluid rounded-circle mr-2 less-opacity-when-disabled"
                             style={{"height": "60px", "width": "60px"}} />
                      </div>
                      <div className="col less-opacity-when-disabled">
                        <h5 className="mb-0">
                          <strong>
                            {performer.artistable.name}
                          </strong>
                        </h5>
                        {performer.artistable && performer.artistable.primary_agent ? (
                          <span>
                            <p className='mb-0 small'>{performer.artistable.primary_agent.name}</p>
                            <p className='mb-0 text-muted small'>{performer.artistable.primary_agent.email}</p>
                          </span>
                        ) : (
                          <span>
                            <p className='mb-0 text-muted small'>Agent info needed.</p>
                          </span>
                        )}
                      </div>
                      {performerIsDisabled(offer, performer) ? (
                        <div className="col-xs-auto d-flex" style={{'paddingRight': '15px'}}>
                          <div className="align-self-center">
                            <span className="badge badge-secondary p-2" style={{"fontSize": "12px"}}>
                              <strong>Offer Exists</strong>
                            </span>
                          </div>
                        </div>
                      ) : null}
                      {performerIsSelected(offer, performer) ? (
                        <div className="col-xs-auto" style={{'paddingRight': '15px'}}>
                          <a href='#'
                             className='mt-3 d-inline-block'
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 offerPerformerUnselected();
                               }
                             }
                             style={{'color': '#1982C4'}}
                             >
                            <i className="fa fa-times-circle"></i>
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </fieldset>
        ) : (
          <div>
            <fieldset className="form-group">
              <div className="form-row">
                <div className="col-12 col-md-6 col-lg-4">
                  <label htmlFor="offer-venue" className="text-muted">
                    Venue
                  </label>
                  <select className="custom-select"
                          defaultValue={(offer.venueSelected ? offer.venueSelected.id : null)}
                          onChange={
                            (e) => {
                              var venue = venuesOptions.find((venuesOption) => {
                                return venuesOption.id === parseInt(e.target.value);
                              });

                              updateOfferVenue(venue);
                            }
                          }
                          id="offer-venue">
                    {venuesOptions.map((venue, index) => (
                      <option key={index} value={venue.id}>
                        {venue.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <label htmlFor="offer-date" className="text-muted">
                    {offer.createCalendarEvent ? (
                      <span>Date</span>
                    ) : (
                      <span>Date (leave blank for TBD dates)</span>
                    )}
                  </label>
                  <div className="inline-date-picker">
                    <DatePicker
                      selected={offer.startDate}
                      onChange={date => updateOfferStartDate(date)}
                      className="form-control"
                      selectsStart
                      placeholderText={"Enter date"}
                      showPopperArrow={false}
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "0px, 10px"
                        }
                      }}
                    />
                  </div>
                </div>
                {offer.createCalendarEvent ? (
                  <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="offer-time" className="text-muted">
                      Time
                    </label>
                    <div className="inline-date-picker">
                      <DatePicker
                        selected={offer.startTime}
                        onChange={date => offerStartTimeChanged(date)}
                        className="form-control"
                        showPopperArrow={false}
                        placeholderText={"Select time"}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: "0px, 10px"
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="form-row">
                {offer.primaryPerformer && (offer.artistPreview || offer.primaryPerformer.artistSuggestion) ? (
                  <div className="col-12 p-2" style={{'background': '#f4f9fc', 'borderRadius': '8px'}}>
                    <div className="row">
                      {offer.artistPreview ? (
                        <div className="col-xs-auto" style={{'paddingLeft': '15px'}}>
                          <img src={offer.artistPreview.image_thumb_url_for_json}
                               className="img-fluid rounded-circle mr-2"
                               style={{"height": "60px", "width": "60px"}} />
                        </div>
                      ) : (
                        <div className="col-xs-auto" style={{'paddingLeft': '15px'}}>
                          {offer.primaryPerformer.artistSuggestion && offer.primaryPerformer.artistSuggestion.image_thumb_url_for_json ? (
                            <img src={offer.primaryPerformer.artistSuggestion.image_thumb_url_for_json}
                                 className="img-fluid rounded-circle mr-2"
                                 style={{"height": "60px", "width": "60px"}} />
                          ) : (
                            <img src="/avatar-01.png"
                                 className="img-fluid rounded-circle mr-2"
                                 style={{"height": "60px", "width": "60px"}} />
                          )}
                        </div>
                      )}
                      <div className="col">
                        <h5 className="mb-0">
                          <strong>
                            {offer.artistPreview ? (
                              offer.artistPreview.name
                            ) : null}
                            {offer.primaryPerformer.artistSuggestion ? (
                              offer.primaryPerformer.artistSuggestion.name
                            ) : null}
                          </strong>
                        </h5>
                        {offer.artistPreview && offer.artistPreview.primary_agent ? (
                          <span>
                            <p className='mb-0 small'>{offer.artistPreview.primary_agent.name}</p>
                            <p className='mb-0 text-muted small'>{offer.artistPreview.primary_agent.email}</p>
                          </span>
                        ) : (
                          <span>
                            <p className='mb-0 text-muted small'>Agent info needed.</p>
                          </span>
                        )}
                      </div>
                      <div className="col-xs-auto" style={{'paddingRight': '15px'}}>
                        <a href='#'
                           className='mt-3 d-inline-block'
                           onClick={
                             (e) => {
                               e.preventDefault();
                               updateOfferRemovePerformer();
                             }
                           }
                           style={{'color': '#1982C4'}}
                           >
                          <i className="fa fa-times-circle"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    <label htmlFor="offer-primary-artist" className="text-muted">
                      Artist (optional)
                    </label>
                    <AsyncCreatableSelect cacheOptions
                                 placeholder={"Search artists..."}
                                 noOptionsMessage={() => {
                                   return "Enter an artist name.";
                                 }}
                                 styles={selectStyles}
                                 menuPortalTarget={document.querySelector('body')}
                                 defaultOptions
                                 id="offer-primary-artist"
                                 formatCreateLabel={
                                   (label) => ("Can’t find your artist? Add \"" + label + "\"")
                                 }
                                 onCreateOption={
                                   (label) => {
                                     addArtistSuggestion(label, team, currentUser);
                                   }
                                 }
                                 onChange={
                                   (option) => {
                                     fetchArtistData(option.permalink, offer);
                                     updateOfferPrimaryArtist(option);
                                   }
                                 }
                                 value={ (offer.primaryPerformer ? offer.primaryPerformer.artist : null) }
                                 loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback)} />
                  </div>
                )}
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="form-row">
                <div className="col-12 col-md-6 col-lg-8">
                  <label htmlFor="offer-event-name" className="text-muted">
                    Event Name
                  </label>
                  <input type="text"
                         id={"offer-event-name"}
                         value={(offer.eventName || "")}
                         placeholder={"Enter event name"}
                         onChange={(e) => updateOfferEventName(e.target.value)}
                         className="form-control" />
                </div>
              </div>
            </fieldset>
          </div>
        )}
        <hr className="darker" style={{'margin': "25px 0"}} />
        <fieldset className="form-group">
          <div className="form-row">
            <legend className="col-form-label pt-0">
              <strong>Deal Terms</strong>
            </legend>
            <div className="col-12">
              <div className="card no-border my-1" style={{'background': '#f4f9fc'}}>
                <div className="card-body small p-3">
                  <strong>Offer</strong>
                  <p>Please specify the offer details. In addition to ensuring the accuracy of the offer, these details are used in the event settlement.</p>
                  <div className="form-row">
                    <div className="col-12 col-md-8">
                      <div className="form-row">
                        <div className="col">
                          <div className="form-group mb-0">
                            <label className="text-muted"
                                   htmlFor="offer-dollar-amount">
                              Offer Amount
                            </label>
                            <div className="input-group inline-white">
                              <div className="input-group-prepend">
                                <span className="input-group-text px-2"
                                      id="offer-dollar-amount-dollar-sign">
                                  <i className="far fa-dollar-sign text-muted"></i>
                                </span>
                              </div>
                              <CurrencyInput className="form-control"
                                             maskOptions={{
                                               prefix: "",
                                               integerLimit: null
                                             }}
                                             placeholder={"Enter amount"}
                                             value={offer.dollar_amount || ""}
                                             onChange={
                                               (e) => {
                                                 updateOfferDollarAmount(e.target.value);
                                               }
                                             }
                                             id="offer-dollar-amount"
                                             aria-describedby="offer-dollar-amount-dollar-sign" />
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-auto">
                          <div className="btn-group btn-group-sm"
                               style={{"marginTop": "29px"}}
                               role="group">
                            <button type="button"
                                    style={{"borderTopLeftRadius": "8px", "borderBottomLeftRadius": "8px"}}
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        updateOfferDollarOrPercentageOperator("AND");
                                      }
                                    }
                                    className={"btn strong " + (offer.dollar_or_percentage_operator === 'AND' ? "btn-primary" : "btn-external-inverse")}>
                              <i className="fa-solid fa-plus"></i>
                            </button>
                            <button type="button"
                                    style={{"borderTopRightRadius": "8px", "borderBottomRightRadius": "8px"}}
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        updateOfferDollarOrPercentageOperator("OR");
                                      }
                                    }
                                    className={"btn strong " + (offer.dollar_or_percentage_operator === 'OR' ? "btn-primary" : "btn-external-inverse")}>
                              VS
                            </button>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group mb-0">
                            <label className="text-muted"
                                   htmlFor="offer-upside-percentage">
                              Percentage of ticket sales
                            </label>
                            <div className="input-group inline-white">
                              <MaskedInput className="form-control"
                                           mask={createNumberMask({
                                             prefix: "",
                                             decimalLimit: 0
                                           })}
                                           placeholder={"0"}
                                           id="offer-upside-percentage"
                                           onChange={
                                             (e) => {
                                               updateOfferUpsidePercentage(e.target.value);
                                             }
                                           }
                                           value={offer.upside_percentage || ""}
                                           aria-describedby="offer-upside-percentage-percentage" />
                              <div className="input-group-append">
                                <span className="input-group-text px-2"
                                      id="offer-upside-percentage-percentage">
                                  <i className="far fa-percentage text-muted"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 text-muted">
                      <div className="form-group mb-0">
                        <div className="form-row"
                             style={{"marginTop": "26px"}}>
                          <div className="col-xs-auto">
                            <i className="far fa-info-circle"></i>
                          </div>
                          <div className="col">
                            <p>With OR offers, the higher of the two amounts will be paid to the artist.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              {offer.dealTermLineItems.map((dealTermLineItem, index) => (
                <div className={"form-row pt-2 " + (dealTermLineItem._destroy ? "hide" : "")}
                     key={index}>
                  <div className="col-4">
                    <label htmlFor={"offer-deal-term-line-item-label-" + index} className="text-muted">
                      Label
                    </label>
                    <input type="text"
                           id={"offer-deal-term-line-item-label-" + index}
                           value={(dealTermLineItem.label || "")}
                           placeholder={"..."}
                           onChange={(e) => updateOfferDealTermLineItemLabel(index, e.target.value)}
                           className="form-control" />
                  </div>
                  <div className="col-7">
                    <label htmlFor={"offer-deal-term-line-item-description-" + index} className="text-muted">
                      Description
                    </label>
                    <input type="text"
                           id={"offer-deal-term-line-item-description-" + index}
                           value={(dealTermLineItem.description || "")}
                           placeholder={"..."}
                           onChange={(e) => updateOfferDealTermLineItemDescription(index, e.target.value)}
                           className="form-control" />
                  </div>
                  <div className="col-1">
                    <label>&nbsp;</label>
                    <div className="pt-2">
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             removeOfferDealTermLineItem(index);
                           }
                         }
                         className="text-muted">
                        <i className="fa fa-times-circle"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 pt-3">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     addOfferDealTermLineItem();
                   }
                 }>
                <i className="fal fa-plus"></i>
                &nbsp;
                &nbsp;
                Add Line Item
              </a>
            </div>
          </div>
        </fieldset>
        <fieldset className="form-group">
          <div className="form-row">
            <legend className="col-form-label">
              <strong>Additional Terms</strong>
            </legend>
            <div className="col-12 pt-2">
              <RichEditor
                editorState={offer.additionalTerms}
                onEditorStateChange={
                  (editorState) => {
                    updateOfferAdditionalTerms(editorState);
                  }
                } />
            </div>
          </div>
        </fieldset>
        <fieldset className="form-group">
          <div className="form-row">
            <legend className="col-form-label">
              <strong>Contact Details</strong>
            </legend>
            <div className="col-12 pt-2">
              <RichEditor
                editorState={offer.contactDetails}
                onEditorStateChange={
                  (editorState) => {
                    updateOfferContactDetails(editorState);
                  }
                } />
            </div>
          </div>
        </fieldset>
        <hr style={{
          borderTop: "2px solid #f5f5f5",
          margin: "25px 0"
        }} />
        <fieldset className="form-group mb-1">
          <div className="form-row">
            <div className="col-xs-auto">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     hiddenFileUploadInputEl.click();
                   }
                 }
                 style={{
                   "borderRadius": "8px"
                 }}
                 className="btn btn-external btn-sm strong mr-1">
                <i className="fas fa-paperclip mr-2"></i>
                Attach a File
              </a>
            </div>
            {offer && offer.attachments && offer.attachments.length > 0 ? (
              <div className="col" style={{"marginTop": "5px"}}>
                <div className="row">
                  {offer.attachments.map((attachment, index) =>
                    <div key={index}
                         className="col-12">
                      <a className="text-dark"
                         href={"/teams/" + team.id + "/attachments/" + attachment.token}>
                        {attachment.file_file_name}
                      </a>
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             attachmentRemoved(attachment);
                           }
                         }
                         className="text-muted">
                        <i className="fas fa-times-circle extra-small ml-1"></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </fieldset>
        {offer.errors && offer.errors.length > 0 ? (
          <div className="form-group">
            <div className="p-3 mb-2 bg-danger text-white rounded">
              <ul className="mb-0">
                {offer.errors.map((error, key) =>
                  <li key={key}>{error}</li>
                )}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
      <div className="col-12 pt-4">
        <div className='row'>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="input-group mb-3 mb-md-0">
              <input type="text"
                     className="form-control text-muted-placeholder"
                     value={offerTemplateName || ""}
                     onChange={
                       (e) => {
                         updateOfferTemplateName(e.target.value);
                       }
                     }
                     style={
                       {
                         "border": "2px solid #f2f2f2",
                         "borderRight": "none"
                       }
                     }
                     placeholder="Save as a template" />
              <div className="input-group-append">
                <button className="btn"
                        type="button"
                        disabled={!canCreateOfferTemplate(offerTemplateName)}
                        onClick={
                          (e) => {
                            e.preventDefault();

                            if(canCreateOfferTemplate(offerTemplateName)){
                              createOfferTemplate(csrfToken, team, offer, offerTemplateName);
                            }
                          }
                        }
                        style={
                          {
                            "border": "2px solid #f2f2f2",
                            "borderLeft": "none"
                          }
                        }>
                  <span style={{'color': "#1982C4"}}>Save</span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-8 text-right">
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();
                   closeModal(defaultOffer, dataTableEl);
                 }
               }>
              Cancel
            </a>
            <input type="submit"
                   className="btn btn-primary ml-3 strong"
                   disabled={!canSubmitForm(offer)}
                   value="Preview & Save" />
          </div>
        </div>
      </div>
    </div>
  </form>
);

OfferForm.propTypes = {
  offer: PropTypes.object.isRequired,
  venuesOptions: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
  updateOfferVenue: PropTypes.func.isRequired,
  updateOfferPrimaryArtist: PropTypes.func.isRequired,
  updateOfferStartDate: PropTypes.func.isRequired,
  addOfferDealTermLineItem: PropTypes.func.isRequired,
  removeOfferDealTermLineItem: PropTypes.func.isRequired,
  updateOfferDealTermLineItemLabel: PropTypes.func.isRequired,
  updateOfferDealTermLineItemDescription: PropTypes.func.isRequired,
  updateOfferAdditionalTerms: PropTypes.func.isRequired,
  updateOfferContactDetails: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  submitOfferForm: PropTypes.func.isRequired,
  fetchArtistData: PropTypes.func.isRequired,
  updateOfferRemovePerformer: PropTypes.func.isRequired,
  updateOfferAgentName: PropTypes.func.isRequired,
  updateOfferAgentEmail: PropTypes.func.isRequired,
  defaultOffer: PropTypes.object.isRequired,
  dataTableEl: PropTypes.object,
  offerTemplateName: PropTypes.string,
  updateOfferTemplateName: PropTypes.func.isRequired,
  createOfferTemplate: PropTypes.func.isRequired,
  offerTemplates: PropTypes.array,
  fetchOfferTemplate: PropTypes.func.isRequired,
  selectedOfferTemplate: PropTypes.object,
  selectOfferTemplate: PropTypes.func.isRequired,
  addArtistSuggestion: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  offerCalendarEventChanged: PropTypes.func.isRequired,
  fetchCalendarEventPerformers: PropTypes.func.isRequired,
  offerPerformerSelected: PropTypes.func.isRequired,
  offerStartTimeChanged: PropTypes.func.isRequired,
  updateOfferEventName: PropTypes.func.isRequired,
  offerPerformerUnselected: PropTypes.func.isRequired,
  updateOfferDollarOrPercentageOperator: PropTypes.func.isRequired,
  updateOfferDollarAmount: PropTypes.func.isRequired,
  updateOfferUpsidePercentage: PropTypes.func.isRequired,
  hiddenFileUploadInputEl: PropTypes.object,
  hiddenFileUploadInputElChanged: PropTypes.func.isRequired,
  uploadAttachment: PropTypes.func.isRequired,
  attachmentRemoved: PropTypes.func.isRequired
};

export default OfferForm;
