// Simple example of a React "smart" component

import { connect } from 'react-redux';
import TeamManager from '../components/TeamManager';
import * as actions from '../actions/teamManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  venueOwnerships: state.venueOwnerships,
  teamMemberships: state.teamMemberships,
  pendingInvitations: state.pendingInvitations,
  addingNewInvitation: state.addingNewInvitation,
  newInvitation: state.newInvitation,
  roles: state.roles,
  csrfToken: state.csrfToken,
  team: state.team,
  newInvitationEnableAllVenues: state.newInvitationEnableAllVenues,
  teamManageableToEdit: state.teamManageableToEdit,
  teamManageableToEditEnableAllVenues: state.teamManageableToEditEnableAllVenues
});

const mapDispatchToProps = (dispatch) => {
  return {
    addTeamMemberClicked: () => dispatch(actions.addTeamMemberClicked()),
    cancelNewInvitation: () => dispatch(actions.cancelNewInvitation()),
    newInvitationFirstNameChanged: (firstName) => dispatch(actions.newInvitationFirstNameChanged(firstName)),
    newInvitationLastNameChanged: (lastName) => dispatch(actions.newInvitationLastNameChanged(lastName)),
    newInvitationEmailChanged: (email) => dispatch(actions.newInvitationEmailChanged(email)),
    newInvitationRoleIdChanged: (roleId) => dispatch(actions.newInvitationRoleIdChanged(roleId)),
    newInvitationCompanyChanged: (company) => dispatch(actions.newInvitationCompanyChanged(company)),
    newInvitationVenueOwnershipClicked: (venueOwnershipId) => dispatch(actions.newInvitationVenueOwnershipClicked(venueOwnershipId)),
    createInvitation: (csrfToken, venueOwnerships, invitation, team) => dispatch(actions.createInvitation(dispatch, csrfToken, venueOwnerships, invitation, team)),
    newInvitationEnableAllVenuesClicked: (venueOwnerships) => dispatch(actions.newInvitationEnableAllVenuesClicked(venueOwnerships)),
    newInvitationDisableAllVenuesClicked: () => dispatch(actions.newInvitationDisableAllVenuesClicked()),
    editTeamManageable: (teamManageable) => dispatch(actions.editTeamManageable(teamManageable)),
    cancelEditTeamManageable: (teamManageable) => dispatch(actions.cancelEditTeamManageable(teamManageable)),
    deleteTeamManageable: (csrfToken, team, teamManageable, closeModal) => dispatch(actions.deleteTeamManageable(dispatch, csrfToken, team, teamManageable, closeModal)),
    teamManageableToEditUpdateFirstName: (firstName) => dispatch(actions.teamManageableToEditUpdateFirstName(firstName)),
    teamManageableToEditUpdateLastName: (lastName) => dispatch(actions.teamManageableToEditUpdateLastName(lastName)),
    teamManageableToEditUpdateEmail: (email) => dispatch(actions.teamManageableToEditUpdateEmail(email)),
    teamManageableToEditUpdateRoleId: (roleId) => dispatch(actions.teamManageableToEditUpdateRoleId(roleId)),
    teamManageableToEditVenueOwnershipClicked: (venueOwnershipId) => dispatch(actions.teamManageableToEditVenueOwnershipClicked(venueOwnershipId)),
    teamManageableToEditEnableAllVenuesClicked: (venueOwnerships) => dispatch(actions.teamManageableToEditEnableAllVenuesClicked(venueOwnerships)),
    teamManageableToEditDisableAllVenuesClicked: () => dispatch(actions.teamManageableToEditDisableAllVenuesClicked()),
    submitUpdateTeamManageable: (csrfToken, team, teamManageable) => dispatch(actions.submitUpdateTeamManageable(dispatch, csrfToken, team, teamManageable)),
    teamManageableToEditErrorsChanged: (errors) => dispatch(actions.teamManageableToEditErrorsChanged(errors)),
    resetPasswordForEmail: (csrfToken, teamMembership, team) => dispatch(actions.resetPasswordForEmail(dispatch, csrfToken, teamMembership, team)),
    teamManageableToEditCompanyChanged: (company) => dispatch(actions.teamManageableToEditCompanyChanged(company))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(TeamManager);
