import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import Popup from "reactjs-popup";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "195px",
  "padding": "0px"
}

const StripeAccount = ({
  csrfToken,
  team,
  currentUser,
  createStripeFinancialConnectionSession,
  financialConnectionAccounts,
  deleteFinancialConnectionAccount
}) => {
  var stripe = useStripe();

  return (
    <div className="row">
      <div className="col-12">
        <p className="mb-0 text-dark"
           style={{"fontSize": "16px"}}>
          <strong>Refunds</strong>
        </p>
        <p className="mb-0 text-muted small">
          Connect your bank account(s) to issue refunds.
        </p>
        <div className="row mt-3">
          {financialConnectionAccounts.map((financialConnectionAccount, index) =>
            <div className="col-12 col-md-6 col-lg-4"
                 key={index}>
              <div className="card w-100"
                   style={{"border": "1px solid #e7e7e7"}}>
                <div className="card-body"
                     style={{"padding": "11px 17px"}}>
                  <div className="row">
                    <div className="col-xs-auto d-flex align-items-center"
                         style={{"paddingLeft": "15px"}}>
                      <i className="fas fa-university"
                         style={{
                           "color": "#B3B3B3",
                           "fontSize": "26px"
                         }}>
                      </i>
                    </div>
                    <div className="col">
                      <table className="table table-sm mb-0 table-borderless">
                        <tbody>
                          <tr>
                            <td className="p-0 overflow-ellipsis"
                                title={financialConnectionAccount.institution_name}>
                              <p className="small mb-0 text-overflow-ellipsis">
                                <strong>{financialConnectionAccount.institution_name}</strong>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0">
                              <p className="small mb-0 text-muted">
                                ***** {financialConnectionAccount.last4} (USD)
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-xs-auto"
                         style={{"paddingRight": "15px"}}>
                      <Popup arrow={false}
                             offsetY={5}
                             position="bottom right"
                             contentStyle={popupContentStyle}
                             onOpen={
                               (e) => {
                                 e.stopPropagation();
                                 e.preventDefault();
                               }
                             }
                             trigger={open => (
                               <a href="#"
                                  style={
                                    {
                                      "top": "10px",
                                      "right": "12px",
                                      "position": "absolute"
                                    }
                                  }
                                  className="text-muted">
                                 <i className="far fa-ellipsis-v"></i>
                               </a>
                             )} >
                        {close => (
                          <div className='row text-left'>
                            <div className="col-12">
                              <ul className="list-group offer-inline-menu">
                                <li className="list-group-item delete-offer-li">
                                  <a href="#"
                                     onClick={
                                       (e) => {
                                         e.preventDefault();

                                         if(confirm("Are you sure?")){
                                           close();
                                           deleteFinancialConnectionAccount(csrfToken, team, financialConnectionAccount);
                                         }
                                       }
                                     }
                                     className="text-danger">
                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                      <i className="fas fa-times-circle"></i>
                                    </span>
                                    Remove
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">
            <a href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    createStripeFinancialConnectionSession(csrfToken, team, stripe);
                  }
                }
                style={{
                  "borderRadius": "4px",
                  "borderWidth": "1px"
                }}
                className="btn btn-primary small">
              <strong>
                Add Account
              </strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

StripeAccount.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  createStripeFinancialConnectionSession: PropTypes.func.isRequired,
  financialConnectionAccounts: PropTypes.array,
  deleteFinancialConnectionAccount: PropTypes.func.isRequired
};

export default StripeAccount;
