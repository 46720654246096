// Simple example of a React "smart" component

import { connect } from 'react-redux';
import TextMessages from '../components/TextMessages';
import * as actions from '../actions/textMessagesActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  dataTableTitle: state.dataTableTitle,
  dataTableDataSource: state.dataTableDataSource,
  dataTableOrder: state.dataTableOrder,
  dataTableColumns: state.dataTableColumns,
  dataTableEl: state.dataTableEl,
  searchedSearchTerm: state.searchedSearchTerm,
  textMessage: state.textMessage,
  venueOwnerships: state.venueOwnerships,
  isSubmitting: state.isSubmitting,
  estimatedNumberOfSends: state.estimatedNumberOfSends,
  hasTextMessages: state.hasTextMessages,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    fetchNewTextMessage: (csrfToken, team) => dispatch(actions.fetchNewTextMessage(dispatch, csrfToken, team)),
    textMessageChanged: (textMessage) => dispatch(actions.textMessageChanged(textMessage)),
    createTextMessage: (csrfToken, team, textMessage, dataTableEl) => dispatch(actions.createTextMessage(dispatch, csrfToken, team, textMessage, dataTableEl)),
    fetchEstimatedNumberOfSends: (team, textMessage) => dispatch(actions.fetchEstimatedNumberOfSends(dispatch, team, textMessage)),
    estimatedNumberOfSendsChanged: (estimatedNumberOfSends) => dispatch(actions.estimatedNumberOfSendsChanged(estimatedNumberOfSends))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(TextMessages);
