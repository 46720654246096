import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const Provider = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  insertHtmlAtCursor,
  bioData,
  toggleOpen,
  onChange,
  editorState,
  title,
  logoImgSrc,
  closePopup
}) => {
  useEffect(() => {
    // ...
  }, []);

  return (
    bioData.count > 0 ? (
      <React.Fragment>
        <div className="row clickable"
             onClick={toggleOpen}
             style={{"margin": "11px 0px"}}>
          <div className="col-xs-auto d-flex align-items-center">
            <img className="img-fluid"
                 src={logoImgSrc}
                 style={{maxWidth: "16px"}} />
          </div>
          <div className="col d-flex align-items-center">
            <p className="text-dark small mb-0">
              {title}
            </p>
          </div>
          <div className="col-xs-auto text-right d-flex align-items-center">
            {bioData.open ? (
              <i className="far fa-angle-down"></i>
            ) : (
              <i className="far fa-angle-right"></i>
            )}
          </div>
        </div>
        {bioData.open ? (
          <div className="row mb-2">
            {bioData.data.map((bioData, index) =>
              <div className="col-12"
                   style={{"marginTop": "-5px"}}
                   key={index}>
                <p className="mb-0 text-overflow-ellipsis"
                   title={bioData.name}
                   style={{"paddingLeft": "32px"}}>
                  <a href="#"
                     className="text-muted mb-0"
                     style={{"fontSize": "10px"}}
                     onClick={
                       (e) => {
                         e.preventDefault();
                         insertHtmlAtCursor(editorState, onChange, bioData.sanitized);
                         closePopup();
                       }
                     }
                     style={{"fontSize": "10px"}}>
                    {bioData.name}
                  </a>
                </p>
              </div>
            )}
          </div>
        ) : null}
      </React.Fragment>
    ) : null
  );
};

Provider.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  insertHtmlAtCursor: PropTypes.func.isRequired,
  bioData: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  editorState: PropTypes.object,
  title: PropTypes.string,
  logoImgSrc: PropTypes.string,
  closePopup: PropTypes.func.isRequired
};

export default Provider;
