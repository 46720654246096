import React, { useState } from "react";

import { useMailkickSubscriptionIndexContext } from "./MailkickSubscriptionsIndexContainer";

const MailkickSubscriptionRow = ({
  venueOwnership,
}) => {
  const {
    mailkickSubscriptionsByVenueOwnership,
    toggleSubscription,
  } = useMailkickSubscriptionIndexContext();

  const mailkickSubscriptions = mailkickSubscriptionsByVenueOwnership[venueOwnership.id]

  const nameStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const checkboxStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  const [emailChecked, setEmailChecked] = useState(!!mailkickSubscriptions.MARKETING_EMAIL);
  const [smsChecked, setSmsChecked] = useState(!!mailkickSubscriptions.MARKETING_SMS);

  const toggleEmail = () => {
    setEmailChecked(!emailChecked);
    toggleSubscription(
      `MARKETING_EMAIL-${venueOwnership.id}`,
      emailChecked,
    );
  };

  const toggleSms = () => {
    setSmsChecked(!smsChecked);
    toggleSubscription(
      `MARKETING_SMS-${venueOwnership.id}`,
      smsChecked,
    );
  };

  return (
    <React.Fragment>
      <div style={nameStyle}>
        {venueOwnership.venueable.name}
      </div>
      <div style={checkboxStyle}>
        <input
          type="checkbox"
          checked={emailChecked}
          onChange={toggleEmail}
        />
      </div>
      <div style={checkboxStyle}>
        <input
          type="checkbox"
          checked={smsChecked}
          onChange={toggleSms}
        />
      </div>
    </React.Fragment>
  );
};

export default MailkickSubscriptionRow;
