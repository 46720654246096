import { createStore, applyMiddleware } from 'redux';
import fanDashboardReducer from '../reducers/fanDashboardReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(fanDashboardReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
