import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import CheckoutForm from './CheckoutForm';
import SecondColumn from './SecondColumn';

import Countdown from 'react-countdown';

const renderer = ({ minutes, seconds }) => {
  var formattedSeconds = seconds.toString();

  if(formattedSeconds.length === 1){
    formattedSeconds = ("0" + formattedSeconds);
  }

  return <span>{minutes}:{formattedSeconds}</span>;
};

// For 8 minute timer, 37.5% is 3 minutes left
const gettingCloseToExpiration = (checkoutTimePercentageRemaining) => {
  return checkoutTimePercentageRemaining < 37.5;
}

const Checkout = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  ticketReservation,
  ticketReservationChanged,
  updateTicketReservation,
  rootUrl,
  checkoutTimePercentageRemaining,
  checkoutTimePercentageRemainingChanged,
  timeLimitReachedChanged,
  isPlacingOrder,
  isPlacingOrderChanged,
  addOns,
  deleteTicketReservation,
  customFieldValues,
  customFieldValueChanged
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <p style={{"fontSize": "20px", "marginBottom": "3px"}}>
            <strong>Registration Information</strong>
          </p>
          <hr style={{
                "borderTop": "2px solid #f1f1f1",
                "margin": "18px 0px 15px 0px"
              }} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="mb-3 mt-1 text-dark"
             style={{"fontSize": "24px"}}>
            &nbsp;
          </p>
        </div>
        <div className="col-xs-auto"
             style={{"paddingRight": "15px"}}>
          <div className="row mt-2">
            <div className="col small">
              <p className='mb-1'>
                <strong>
                  Time left {
                    <Countdown date={new Date(ticketReservation.expires_at)}
                               onTick={
                                 () => {
                                   var expiresAt = new Date(ticketReservation.expires_at);
                                   var startsAt = new Date(ticketReservation.checkout_starts_at);
                                   var currentTime = new Date();

                                   var totalTime = (expiresAt - startsAt);
                                   var remainingTime = (expiresAt - currentTime);

                                   var percentage = (remainingTime / totalTime) * 100;
                                   checkoutTimePercentageRemainingChanged(percentage);
                                 }
                               }
                               onComplete={
                                 () => {
                                   checkoutTimePercentageRemainingChanged(0);
                                   deleteTicketReservation(csrfToken, team, ticketReservation);
                                   timeLimitReachedChanged(true);
                                 }
                               }
                               renderer={renderer} />
                  }
                </strong>
              </p>
              <div className="progress"
                   style={{
                     "background": (gettingCloseToExpiration(checkoutTimePercentageRemaining) ? "#f9d8ce" : "#d9edb0"),
                     "height": "8px"
                   }}>
                <div className="progress-bar animate-change-to-width"
                     style={{
                       "background": (gettingCloseToExpiration(checkoutTimePercentageRemaining) ? "#ec4926" : "#98c648"),
                       "width": (checkoutTimePercentageRemaining + "%"),
                       "borderTopRightRadius": "8px",
                       "borderBottomRightRadius": "8px"
                     }}
                     role="progressbar"
                     aria-valuenow="0"
                     aria-valuemin="0"
                     aria-valuemax="100">
                </div>
              </div>
            </div>
            <div className="col-xs-auto"
                 style={{"paddingRight": "15px"}}>
              <i className="fas fa-clock mt-1"
                 style={{
                   "fontSize": "24px",
                   "color": (gettingCloseToExpiration(checkoutTimePercentageRemaining) ? "#ec4926" : "#98c648")
                 }}></i>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 mb-3">
          <CheckoutForm team={team}
                        csrfToken={csrfToken}
                        confirm={confirm}
                        ticketReservation={ticketReservation}
                        deleteTicketReservation={deleteTicketReservation}
                        ticketReservationChanged={ticketReservationChanged}
                        updateTicketReservation={updateTicketReservation}
                        rootUrl={rootUrl}
                        ticketTypes={ticketTypes}
                        isPlacingOrder={isPlacingOrder}
                        isPlacingOrderChanged={isPlacingOrderChanged}
                        customFieldValues={customFieldValues}
                        customFieldValueChanged={customFieldValueChanged}
                        addOns={addOns} />
        </div>
        <div className="col-12 col-lg-6 order-first order-lg-last">
          <SecondColumn confirm={confirm}
                        ticketTypes={ticketTypes}
                        addOns={addOns}
                        ticketReservation={ticketReservation} />
        </div>
      </div>
    </div>
  );
};

Checkout.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  ticketReservation: PropTypes.object,
  ticketReservationChanged: PropTypes.func.isRequired,
  updateTicketReservation: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  checkoutTimePercentageRemaining: PropTypes.number,
  checkoutTimePercentageRemainingChanged: PropTypes.func.isRequired,
  timeLimitReachedChanged: PropTypes.func.isRequired,
  isPlacingOrder: PropTypes.bool,
  isPlacingOrderChanged: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  deleteTicketReservation: PropTypes.func.isRequired,
  customFieldValues: PropTypes.array,
  customFieldValueChanged: PropTypes.func.isRequired
};

export default Checkout;
