/* eslint-disable import/prefer-default-export */

export const CLOSE_CALENDAR_EVENT_ATTACHMENT_MODAL = 'CLOSE_CALENDAR_EVENT_ATTACHMENT_MODAL';
export const SET_OPEN_CALENDAR_EVENT_ATTACHMENT = 'SET_OPEN_CALENDAR_EVENT_ATTACHMENT';
export const OPEN_CALENDAR_EVENT_ATTACHMENT_PDF_NUMBER_OF_PAGES_CHANGED = 'OPEN_CALENDAR_EVENT_ATTACHMENT_PDF_NUMBER_OF_PAGES_CHANGED';
export const EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME = 'EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME';
export const SET_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME_EL = 'SET_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME_EL';
export const OPEN_CALENDAR_EVENT_ATTACHMENT_UPDATE_SUCCESS = 'OPEN_CALENDAR_EVENT_ATTACHMENT_UPDATE_SUCCESS';
export const END_EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME = 'END_EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME';

export const TOGGLE_FOLDERER_ITEM_SELECTED = 'TOGGLE_FOLDERER_ITEM_SELECTED';

export const STAGE_FOLDERED_ITEMS_FOR_DESTROY = 'STAGE_FOLDERED_ITEMS_FOR_DESTROY';
export const STAGE_FOLDERED_ITEMS_FOR_MOVE = 'STAGE_FOLDERED_ITEMS_FOR_MOVE';

export const REMOVE_FOLDERED_ITEMS_FROM_SELECTED = 'REMOVE_FOLDERED_ITEMS_FROM_SELECTED';

export const COPIED_FOLDERED_ITEM_CHANGED = 'COPIED_FOLDERED_ITEM_CHANGED';
export const COPIED_FOLDERED_ITEM_NAME_CHANGED = 'COPIED_FOLDERED_ITEM_NAME_CHANGED';

export const PERMISSION_SET_CHANGED = 'PERMISSION_SET_CHANGED';
export const PERMISSION_RULE_ENABLED_CLICKED = 'PERMISSION_RULE_ENABLED_CLICKED';
export const SELECT_ALL_PERMISSION_RULES_CLICKED = 'SELECT_ALL_PERMISSION_RULES_CLICKED';
export const ON_PERMISSION_SET_SAVED_CHANGED = 'ON_PERMISSION_SET_SAVED_CHANGED';
export const CONFIRM_PAGE_SECTION_CHANGED = 'CONFIRM_PAGE_SECTION_CHANGED';

export const TARGET_FOLDER_CHANGED = 'TARGET_FOLDER_CHANGED';
export const TARGET_FOLDER_SEARCH_TERM_CHANGED = 'TARGET_FOLDER_SEARCH_TERM_CHANGED';

export const FOLDER_CHANGED = 'FOLDER_CHANGED';
export const FOLDER_COPY_CHANGED = 'FOLDER_COPY_CHANGED';
export const FOLDER_COPY_PAGINATED_DATA_FETCHED = 'FOLDER_COPY_PAGINATED_DATA_FETCHED';
export const FOLDER_COPY_LOADING_PAGINATED_DATA_CHANGED = 'FOLDER_COPY_LOADING_PAGINATED_DATA_CHANGED';
export const TOGGLE_FOLDER_COPY = 'TOGGLE_FOLDER_COPY';

export const IS_ADDING_FOLDER_CHANGED = 'IS_ADDING_FOLDER_CHANGED';
export const NEW_FOLDER_NAME_CHANGED = 'NEW_FOLDER_NAME_CHANGED';

export const IS_UPLOADING_FILES_CHANGED = 'IS_UPLOADING_FILES_CHANGED';
export const SELECTED_FILES_TO_UPLOAD_CHANGED = 'SELECTED_FILES_TO_UPLOAD_CHANGED';
export const IS_LOADING_UPLOADED_FILES_CHANGED = 'IS_LOADING_UPLOADED_FILES_CHANGED';

export const GUEST_TEAM_MEMBERSHIPS_CHANGED = 'GUEST_TEAM_MEMBERSHIPS_CHANGED';
export const PROMOTERS_WITH_PERMISSION_CHANGED = 'PROMOTERS_WITH_PERMISSION_CHANGED';

export const CALENDAR_EVENT_ATTACHMENT_TO_COPY_CHANGED = 'CALENDAR_EVENT_ATTACHMENT_TO_COPY_CHANGED';
export const IS_COPYING_CALENDAR_EVENT_ATTACHMENT_CHANGED = 'IS_COPYING_CALENDAR_EVENT_ATTACHMENT_CHANGED';

export const MOVE_TO_FOLDER_CONTEXT = 'MOVE_TO_FOLDER_CONTEXT';
export const NEW_FOLDER_CONTEXT = 'NEW_FOLDER_CONTEXT';
export const UPLOAD_FILES_CONTEXT = 'UPLOAD_FILES_CONTEXT';
export const COPY_FILE_CONTEXT = 'COPY_FILE_CONTEXT';

export const DRAGGED_FILES_TO_UPLOAD_CHANGED = 'DRAGGED_FILES_TO_UPLOAD_CHANGED';
