// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ShowActivitySettings from '../components/ShowActivitySettings';
import * as actions from '../actions/showActivitySettingsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  defaultUnits: state.defaultUnits,
  defaultMinutes: state.defaultMinutes,
  defaultHours: state.defaultHours,
  defaultTopHold: state.defaultTopHold,
  teamId: state.teamId,
  showActivityDefaults: state.showActivityDefaults,
  showActivitySettings: state.showActivitySettings,
  eventTemplates: state.eventTemplates,
  eventTemplateForForm: state.eventTemplateForForm,
  financesVisible: state.financesVisible,
  financeCategories: state.financeCategories,
  notesVisible: state.notesVisible,
  noteTemplates: state.noteTemplates,
  team: state.team,
  exchangeRates: state.exchangeRates
});

const mapDispatchToProps = (dispatch) => {
  return {
    showActivityDefaultsChanged: (showActivityDefaults) => dispatch(actions.showActivityDefaultsChanged(showActivityDefaults)),
    defaultTopHoldChanged: (defaultTopHold) => dispatch(actions.defaultTopHoldChanged(defaultTopHold)),
    eventTemplatesChanged: (eventTemplates) => dispatch(actions.eventTemplatesChanged(eventTemplates)),
    eventTemplateForFormChanged: (eventTemplateForForm) => dispatch(actions.eventTemplateForFormChanged(eventTemplateForForm)),
    createEventTemplate: (csrfToken, teamId, eventTemplate, eventTemplates) => dispatch(actions.createEventTemplate(dispatch, csrfToken, teamId, eventTemplate, eventTemplates)),
    updateEventTemplate: (csrfToken, teamId, eventTemplate, eventTemplates) => dispatch(actions.updateEventTemplate(dispatch, csrfToken, teamId, eventTemplate, eventTemplates)),
    deleteEventTemplate: (csrfToken, teamId, eventTemplate, eventTemplates) => dispatch(actions.deleteEventTemplate(dispatch, csrfToken, teamId, eventTemplate, eventTemplates)),
    financesVisibleChanged: (financesVisible) => dispatch(actions.financesVisibleChanged(financesVisible)),
    financeCategoriesChanged: (financeCategories) => dispatch(actions.financeCategoriesChanged(financeCategories)),
    notesVisibleChanged: (notesVisible) => dispatch(actions.notesVisibleChanged(notesVisible)),
    noteTemplatesChanged: (noteTemplates) => dispatch(actions.noteTemplatesChanged(noteTemplates)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ShowActivitySettings);
