import PropTypes from 'prop-types';
import React from 'react';

import { Editor } from 'react-draft-wysiwyg';

import bold from "./icons/bold.svg";
import italic from "./icons/italic.svg";
import link from "./icons/link.svg";
import unlink from "./icons/unlink.svg";
import left from "./icons/align-left.svg";
import right from "./icons/align-right.svg";
import center from "./icons/align-center.svg";
import justify from "./icons/align-justify.svg";
import unordered from "./icons/unordered.svg";
import ordered from "./icons/ordered.svg";
import indent from "./icons/indent.svg";
import outdent from "./icons/outdent.svg";

const toolbarOptions = {
  options: ['inline', 'link', 'textAlign', 'list'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic'],
    bold: { icon: bold, className: undefined },
    italic: { icon: italic, className: undefined },
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
    link: { icon: link, className: undefined },
    unlink: { icon: unlink, className: undefined },
    linkCallback: undefined
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    left: { icon: left, className: undefined },
    center: { icon: center, className: undefined },
    right: { icon: right, className: undefined },
    justify: { icon: justify, className: undefined },
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: { icon: unordered, className: undefined },
    ordered: { icon: ordered, className: undefined },
    indent: { icon: indent, className: undefined },
    outdent: { icon: outdent, className: undefined },
  }
}

class RichEditor extends React.Component {
  static propTypes = {
    editorState: PropTypes.object,
    onEditorStateChange: PropTypes.func,
    editorStyle: PropTypes.object,
    wrapperClassName: PropTypes.string,
    toolbarCustomButtons: PropTypes.array
  };

  static defaultProps = {
    onEditorStateChange: (editorState) => {},
    editorStyle: {},
    wrapperClassName: "",
    toolbarCustomButtons: []
  };

  constructor(props) {
    super(props);

    this.state = {
      editorState: this.props.editorState,
      onEditorStateChange: this.props.onEditorStateChange,
      editorStyle: this.props.editorStyle,
      wrapperClassName: this.props.wrapperClassName,
      toolbarCustomButtons: this.props.toolbarCustomButtons
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editorState !== this.props.editorState) {
      this.setState({editorState: this.props.editorState});
    }

    if (prevProps.onEditorStateChange !== this.props.onEditorStateChange) {
      this.setState({onEditorStateChange: this.props.onEditorStateChange});
    }

    if (prevProps.editorStyle !== this.props.editorStyle) {
      this.setState({editorStyle: this.props.editorStyle});
    }

    if (prevProps.wrapperClassName !== this.props.wrapperClassName) {
      this.setState({wrapperClassName: this.props.wrapperClassName});
    }

    if (prevProps.toolbarCustomButtons !== this.props.toolbarCustomButtons) {
      this.setState({toolbarCustomButtons: this.props.toolbarCustomButtons});
    }
  }

  render() {
    return (
      <Editor
        editorState={this.state.editorState}
        editorStyle={this.state.editorStyle}
        toolbarClassName="toolbar-container"
        wrapperClassName={"wysiwyg-editor-container " + this.state.wrapperClassName}
        editorClassName="document-container"
        toolbar={toolbarOptions}
        stripPastedStyles={true}
        onEditorStateChange={this.state.onEditorStateChange}
        toolbarCustomButtons={this.state.toolbarCustomButtons}
        />
    );
  }
}

export default RichEditor;
