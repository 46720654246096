import React, { useEffect, useState } from 'react';
import ArtistsSearch from '../../ArtistsSearch/components/ArtistsSearch';
import styled from "styled-components";

const GlobalArtistsSearchWrapper = styled.div`
    width: 300px;
    padding: 0.375rem 0.75rem;
    display: flex;

    .magnifier {
        display: flex;
        margin-left: auto;
        cursor: pointer;
    }
    .artists-search {
        opacity: 0;
        width: 0;
        transition: .3s ease-in-out;
        margin-left: auto;

        .rbt-highlight-text {
            background-color: #F4F9FC;
        }
    }

    &.active {
        .magnifier {
            display: none;
        }
        .artists-search {
            opacity: 1;
            width: 270px;
        }
    }
`
const MagnifierWrapper = styled.div`
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: rgba(51, 51, 51, 0.5);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
`
const GlobalArtistsSearch = ({}) => {
    const onClickMagnifier = () => {
        $('.global-artists-search').addClass('active');
        $('.artists-search input:first').focus();
    }

    return (
        <GlobalArtistsSearchWrapper className='global-artists-search'>
            <ArtistsSearch className="artists-search" />
            <MagnifierWrapper className="magnifier" onClick={onClickMagnifier}>
                <i className="fa fa-search"></i>
            </MagnifierWrapper>
        </GlobalArtistsSearchWrapper>
    );
}

export default GlobalArtistsSearch;
