/* eslint-disable import/prefer-default-export */

export const CONFIRM_CHANGED = 'CONFIRM_CHANGED';
export const CALENDAR_EVENT_TITLE_UPDATED = 'CALENDAR_EVENT_TITLE_UPDATED';
export const CALENDAR_EVENT_ARTISTS_UPDATED = 'CALENDAR_EVENT_ARTISTS_UPDATED';
export const CALENDAR_EVENT_TYPE_UPDATED = 'CALENDAR_EVENT_TYPE_UPDATED';
export const CALENDAR_EVENT_TICKET_FORECAST_CHANGED = 'CALENDAR_EVENT_TICKET_FORECAST_CHANGED';
export const CALENDAR_EVENT_NOTE_EDITOR_STATE_UPDATED = 'CALENDAR_EVENT_NOTE_EDITOR_STATE_UPDATED';
export const CALENDAR_EVENT_ERRORS_UPDATED = 'CALENDAR_EVENT_ERRORS_UPDATED';
export const CALENDAR_EVENT_FORM_CLOSED = 'CALENDAR_EVENT_FORM_CLOSED';
export const HOLD_GROUP_CHANGED = 'HOLD_GROUP_CHANGED';
export const HOLD_GROUP_CALENDAR_DATE_CLICKED = 'HOLD_GROUP_CALENDAR_DATE_CLICKED';
export const HOLD_GROUP_HOLD_POSITION_UPDATED = 'HOLD_GROUP_HOLD_POSITION_UPDATED';
export const HOLD_GROUP_HOLD_REMOVED = 'HOLD_GROUP_HOLD_REMOVED';
export const CONFIRM_START_DATE_UPDATED = 'CONFIRM_START_DATE_UPDATED';
export const CONFIRM_START_TIME_UPDATED = 'CONFIRM_START_TIME_UPDATED';
export const HOLD_GROUP_HOLD_AVAILABILITY_UPDATED = 'HOLD_GROUP_HOLD_AVAILABILITY_UPDATED';
export const HOLD_GROUP_UPDATED = 'HOLD_GROUP_UPDATED';
export const CONFIRM_UPDATED = 'CONFIRM_UPDATED';
export const CONFIRM_HOLD_UPDATED = 'CONFIRM_HOLD_UPDATED';
export const EVENT_TO_EDIT_UPDATE_SUCCESS = 'EVENT_TO_EDIT_UPDATE_SUCCESS';
export const EVENT_TO_EDIT_DELETED = 'EVENT_TO_EDIT_DELETED';
export const VENUE_SELECTED_CHANGED = 'VENUE_SELECTED_CHANGED';
export const BUYERS_CHANGED = 'BUYERS_CHANGED';
export const PROMOTERS_CHANGED = 'PROMOTERS_CHANGED';
export const SELECTED_PROMOTER_CHANGED = 'SELECTED_PROMOTER_CHANGED';
export const SELECTED_BUYER_CHANGED = 'SELECTED_BUYER_CHANGED';
export const USER_CHANGED_DOORS_TIME_CHANGED = 'USER_CHANGED_DOORS_TIME_CHANGED';
export const USER_CHANGED_EVENT_END_TIME_CHANGED = 'USER_CHANGED_EVENT_END_TIME_CHANGED';

export const HOLD_GROUP_DELETION_CHANGED = 'HOLD_GROUP_DELETION_CHANGED';
export const HOLD_GROUP_DELETION_REASON_CHANGED = 'HOLD_GROUP_DELETION_REASON_CHANGED';
export const HOLD_GROUP_DELETION_EXPLANATION_CHANGED = 'HOLD_GROUP_DELETION_EXPLANATION_CHANGED';
export const HOLD_GROUP_DELETION_FOLLOW_UP_CHANGED = 'HOLD_GROUP_DELETION_FOLLOW_UP_CHANGED';
export const HOLD_GROUP_DELETION_FOLLOW_UP_AT_CHANGED = 'HOLD_GROUP_DELETION_FOLLOW_UP_AT_CHANGED';

export const SUBMITTING_FORM_CHANGED = 'SUBMITTING_FORM_CHANGED';
