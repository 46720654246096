import PropTypes from 'prop-types';
import React from 'react';
import Popup from "reactjs-popup";

import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const contentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "350px",
  "padding": "20px"
}

class RangeFilter extends React.Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    updateFilter: PropTypes.func.isRequired,
    debouncedUpdateFilter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: this.props.filter,
      updateFilter: this.props.updateFilter,
      debouncedUpdateFilter: this.props.debouncedUpdateFilter,
      minimumValue: this.props.filter.minimum,
      maximumValue: this.props.filter.maximum
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        filter: this.props.filter,
        minimumValue: this.props.filter.minimum,
        maximumValue: this.props.filter.maximum
      });
    }
  }

  rangeUpdated(range) {
    this.setState({
      minimumValue: range[0],
      maximumValue: range[1]
    });

    this.state.debouncedUpdateFilter(this.state.filter, range);
  }

  popupJustOpened() {
    this.state.updateFilter(this.state.filter, [this.state.minimumValue, this.state.maximumValue]);
  }

  render() {
    return (
      <div className="filter-pill-container mr-2 my-3">
        <Popup arrow={false}
               offsetY={5}
               position="bottom left"
               onOpen={() => {this.popupJustOpened()}}
               contentStyle={contentStyle}
               trigger={open => (
          <span className={"filter-pill clickable " + (open ? " opened " : "") + (this.state.filter.value.length > 0 ? " filtering " : "")}>
            <span className="px-1">{this.state.filter.label}</span>
            {this.state.filter.value.length === 0 &&
              <strong className="px-2">All</strong>
            }
            {this.state.filter.value.length > 0 &&
              <strong className="px-2">{this.state.filter.value.join(" → ")}</strong>
            }
            <i className="fa fa-chevron-down px-1"></i>
          </span>
        )} >
          {close => (
            <div>
              <p><strong>{this.state.filter.rangeLabel}</strong></p>
              <br />
              <Range min={this.state.filter.minimum}
                     max={this.state.filter.maximum}
                     step={this.state.filter.step}
                     onChange={(range) => this.rangeUpdated(range)}
                     defaultValue={[this.state.minimumValue, this.state.maximumValue]}
                     tipFormatter={value => value}
                     tipProps={{
                       placement: "top",
                       visible: true
                     }} />
            </div>
          )}
        </Popup>
      </div>
    );
  }
}

export default RangeFilter;
