import React from "react";
import Popup from "reactjs-popup";
import axios from "axios";

import { toastSuccess, toastError } from "../../../../shared/toastHelper";
import { useActionContext } from "./ActionContainer";

const popupContentStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "0px",
  width: "fit-content",
};

const menuOptionStyle = {
  width: "28px"
};

const menuLineStyle = {
  display: "flex",
  flexDirection: "row",
  fontSize: "12px",
  fontWeight: "normal",
  margin: "5px",
  paddingRight: "10px",
};

const actionsButtonStyle = {
  alignItems: "center",
  background: "white",
  border: "1px solid #B2DBF5",
  borderRadius: "5px",
  color: "#1982C4",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  fontSize: "12px",
  fontWeight: "bold",
  gap: "10px",
  height: "40px",
  padding: "15px 11px",
};

const ActionDropdown = () => {
  const {
    csrfToken,
    team,
    campaign,
    campaignState,
    setCampaignState,
    setTestModalOpen,
    isEmailTemplate,
  } = useActionContext();

  const updateCampaign = (campaignId, data) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put("/teams/" + team.id + "/campaigns/" + campaignId, data)
      .then(({data}) => {
        setCampaignState(data.state);
        toastSuccess("Campaign has been updated");
      })
      .catch(_error => toastError("Could not update campaign, please try again"));
  };

  return (
    !isEmailTemplate && campaignState !== "paused" && campaignState !== "active" ? null : (  
      <Popup arrow={false}
        offsetY={5}
        position="bottom right"
        contentStyle={popupContentStyle}
        onOpen={
          (e) => {
            e.stopPropagation();
            e.preventDefault();
          }
        }
        trigger={_open => (
          <div style={actionsButtonStyle}>
            <div>Actions</div>
            <i className="fa-solid fa-chevron-down" />
          </div>
        )} >
        {close => (
          <div className='row text-left'>
            <div className="col-12">
              <ul className="list-group offer-inline-menu highlight-primary">
                {campaignState === "active" ? (
                  <li className="list-group-item">
                    <a className="text-muted"
                      style={menuLineStyle}
                      href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          close();
                          updateCampaign(campaign.id, { state: "paused" });
                        }
                      }
                    >
                      <span className="d-inline-block text-center" style={menuOptionStyle}>
                        <i className="fas fa-pause"></i>
                      </span>
                      Pause Campaign
                    </a>
                  </li>
                ) : null}
                {campaignState === "paused" ? (
                  <li className="list-group-item">
                    <a className="text-muted"
                      style={menuLineStyle}
                      href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          close();
                          updateCampaign(campaign.id, { state: "active" });
                        }
                      }
                    >
                      <span className="d-inline-block text-center" style={menuOptionStyle}>
                        <i className="fas fa-play"></i>
                      </span>
                      Resume Campaign
                    </a>
                  </li>
                ) : null}
                {isEmailTemplate ? (
                  <li className="list-group-item">
                    <a className="text-muted"
                      style={menuLineStyle}
                      href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          setTestModalOpen(true);
                          close();
                        }
                      }
                    >
                      <span className="d-inline-block text-center" style={menuOptionStyle}>
                        <i className="far fa-envelope"></i>
                      </span>
                      Send Test
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        )}
      </Popup>
    )
  );
};

export default ActionDropdown;
