/* eslint-disable import/prefer-default-export */

import {
  CONFIRM_CHANGED,
  PROMO_CODE_NAME_CHANGED,
  PROMO_CODE_NAME_VALID_CHANGED,
  PROMO_CODE_CHANGED,
  TICKET_RESERVATION_CHANGED,
  TICKET_TYPES_CHANGED,
  CANCEL_CHECKOUT,
  STRIPE_PROMISE_CHANGED,
  STRIPE_ERROR_CHANGED,
  CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED,
  TIME_LIMIT_REACHED_CHANGED,
  IS_PLACING_ORDER_CHANGED,
  ADD_ONS_CHANGED,
  GTAG_CHANGED,
  CUSTOM_FIELD_VALUE_CHANGED,
  SEATS_IO_CHART_CHANGED,
  SEATS_IO_SELECTED_OBJECTS_CHANGED,
  CUSTOMERIO_ID_CHANGED,
  IS_CREATING_TICKET_RESERVATION_CHANGED,
  INSURANCE_OFFER_STATE_CHANGED,
  TICKET_INSURANCE_CHANGED,
  HAS_INSURANCE_QUOTE_CHANGED,
  DONATION_CUSTOM_CHARGE_TYPE_CHANGED
} from '../constants/webOrderFormConstants';

import {
  customFieldValueToParams
} from './../../CustomFieldValues/actions/customFieldValuesActionCreators';

import { RealtimeDataMutator } from '../../RealtimeDataMutator/RealtimeDataMutator';

const axios = require('axios').default;
import { toast } from 'react-toastify';

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateZip(zip) {
  const re = /^\d{5}(-\d{4})?$/;
  return re.test(String(zip));
}

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

export const confirmChanged = (confirm) => ({
  type: CONFIRM_CHANGED,
  confirm
});

export const channelMsgReceived = (dispatch, data) => {
  return (dispatch, getState) => {
    const state = getState();

    if(state.ticketReservation.id || state.isCreatingTicketReservation) {
      return;
    }

    let mutatedStates = new RealtimeDataMutator(
      data,
      {...state.confirm},
      [...state.ticketTypes],
      {...state.ticketReservation},
      [...state.addOns],
      state.promoCode
    ).process();

    if(mutatedStates.confirm && mutatedStates.confirm.sold_out) {
      dispatch(confirmChanged(mutatedStates.confirm));
      dispatch(ticketReservationChanged(mutatedStates.ticketReservation || {}));

      if(mutatedStates.cartChanged) {
        dispatch(ticketTypesChanged(mutatedStates.ticketTypes));

        if(mutatedStates.addOns) {
          dispatch(addOnsChanged(mutatedStates.addOns));
        }

        toastError("We're sorry, this event has JUST sold out. Your cart has been updated.");
      } else {
        toastError("We're sorry, this event has JUST sold out.");
      }

      return;
    }

    if(mutatedStates.confirm && !mutatedStates.confirm.sold_out) {
      dispatch(confirmChanged(mutatedStates.confirm));
    }

    if(!mutatedStates.cartChanged) {
      // Ticket quantity options have changed
      if(mutatedStates.ticketTypes) {
        dispatch(ticketTypesChanged(mutatedStates.ticketTypes));
      }

      // AddOn quantity options have changed
      if(mutatedStates.addOns) {
        dispatch(addOnsChanged(mutatedStates.addOns));
      }
    } else {
      // Ticket quantity has changed in cart
      if(mutatedStates.ticketTypes) {
        dispatch(ticketTypesChanged(mutatedStates.ticketTypes));
      }

      // AddOn quantity has changed in cart
      if(mutatedStates.addOns) {
        dispatch(addOnsChanged(mutatedStates.addOns));
      }

      // Promo code has been removed
      if(mutatedStates.promoCodeRemoved) {
        dispatch(promoCodeChanged({}));
        toastError("We're sorry, but your selected promo code JUST sold out. Your cart has been updated.");
      }

      // Rebuild ticket reservation
      dispatch(
        buildTicketReservation(
          dispatch,
          state.csrfToken,
          state.confirm,
          mutatedStates.ticketTypes ? mutatedStates.ticketTypes : state.ticketTypes,
          mutatedStates.promoCodeRemoved ? {} : state.promoCode,
          mutatedStates.addOns ? mutatedStates.addOns : state.addOns,
          state.ticketReservation,
          state.userContext
        )
      );

      if(mutatedStates.ticketTypesSoldOut) {
        toastError("We're sorry, one or more of your selected tickets has JUST sold out. Your cart has been updated.");
      } else if(mutatedStates.ticketTypes || mutatedStates.addOns) {
        toastError("We're sorry, the availability of one or more of your selected tickets has JUST changed. Your cart has been updated.");
      }
    }
  }
}

const toastError = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    draggable: false,
    closeOnClick: false,
    autoClose: 5000,
    hideProgressBar: true
  });
}

export const lookupPromoCode = (dispatch, confirm, name, ticketTypes, csrfToken, addOns, ticketReservation, userContext) => {
  return dispatch => {
    return axios.get("/confirms/" + confirm.id + "/web_orders/lookup_promo_code", {
        params: {
          name: name
        }
      })
      .then(({ data }) => {
        if(data == null){
          dispatch(promoCodeNameValidChanged(false));
        } else {
          dispatch(promoCodeChanged(data));
          dispatch(buildTicketReservation(dispatch, csrfToken, confirm, ticketTypes, data, addOns, ticketReservation, userContext));
        }
      });
  };
};

export const promoCodeNameChanged = (name) => ({
  type: PROMO_CODE_NAME_CHANGED,
  name
});

export const promoCodeNameValidChanged = (valid) => ({
  type: PROMO_CODE_NAME_VALID_CHANGED,
  valid
});

export const promoCodeChanged = (promoCode) => ({
  type: PROMO_CODE_CHANGED,
  promoCode
});

export const buildAbstractOrderItems = (donationCustomChargeType) => {
  var abstractOrderItems = [];

  var submitDonation = (
    donationCustomChargeType
      && donationCustomChargeType.enteredPrice
      && donationCustomChargeType.enteredPrice.length > 0
      && parseFloatFromString(donationCustomChargeType.enteredPrice) > 0.50
  )

  if(submitDonation){
    abstractOrderItems.push({
        custom_charge_type_id: donationCustomChargeType.id,
        quantity: 1,
        price: donationCustomChargeType.price,
        face_value: donationCustomChargeType.price
    });
  }

  return abstractOrderItems;
}

export const buildTicketReservation = (dispatch, csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, userContext) => {
  return (dispatch, getState) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var orderItems = ticketTypes.filter((tt) =>
      tt.quantity && tt.quantity > 0
    );

    var addOnOrderItems = addOns.filter((ao) =>
      ao.quantity && ao.quantity > 0
    );

    var donationCustomChargeType = getState().donationCustomChargeType;
    var abstractOrderItems = buildAbstractOrderItems(donationCustomChargeType);

    if(orderItems.length === 0 && addOnOrderItems.length === 0 && abstractOrderItems.length === 0){
      var updated = {};

      if (ticketReservation._screen){
        updated = Object.assign({}, updated, {
          _screen: ticketReservation._screen
        });
      }

      return dispatch(ticketReservationChanged(updated));
    }

    return axios.post("/confirms/" + confirm.id + "/ticket_reservations/build", {
        ticket_reservation: {
          promo_code_name: (promoCode && promoCode.name ? promoCode.name : null),
          user_context_access_token: (userContext && userContext.access_token ? userContext.access_token : null),
          order_items_attributes: orderItems.map((item) => {
            return {
              ticket_type_id: item.id,
              quantity: item.quantity,
              seatsio_objects: item.seatsIOObjects
            };
          }),
          add_on_order_items_attributes: addOnOrderItems.map((item) => {
            return {
              add_on_id: item.id,
              quantity: item.quantity,
              seatsio_objects: item.seatsIOObjects
            };
          }),
          abstract_order_items_attributes: abstractOrderItems.map((item) => {
            return {
              custom_charge_type_id: item.custom_charge_type_id,
              quantity: item.quantity,
              price: item.price,
              face_value: item.face_value
            };
          })
        }
      })
      .then(({ data }) => {
        if(ticketReservation._screen){
          data = Object.assign({}, data, {_screen: ticketReservation._screen});
        }

        dispatch(ticketReservationChanged(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const ticketReservationChanged = (ticketReservation) => ({
  type: TICKET_RESERVATION_CHANGED,
  ticketReservation
});

export const ticketTypesChanged = (ticketTypes) => ({
  type: TICKET_TYPES_CHANGED,
  ticketTypes
});

export const createTicketReservation = (dispatch, csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, seatsIOChart, customerioId, userContext) => {
  return (dispatch, getState) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    dispatch(isCreatingTicketReservationChanged(true));

    var orderItems = ticketTypes.filter((tt) =>
      tt.quantity && tt.quantity > 0
    );

    var addOnOrderItems = addOns.filter((ao) =>
      ao.quantity && ao.quantity > 0
    );

    var seatsioHoldTokens = [];

    if(orderItems.some((i) => i.seatsIOObjects && i.seatsIOObjects.length > 0)){
      seatsioHoldTokens = [...seatsioHoldTokens, seatsIOChart.holdToken];
    }

    var addOnHoldTokens = [
      ...new Set(
        addOnOrderItems
          .filter((item) => item.seatsIOObjects)
          .map((item) => item.seatsIOObjects)
          .flat()
          .map((object) => object.hold_token)
      )
    ];

    seatsioHoldTokens = [
      ...seatsioHoldTokens,
      ...addOnHoldTokens
    ];

    var donationCustomChargeType = getState().donationCustomChargeType;
    var abstractOrderItems = buildAbstractOrderItems(donationCustomChargeType);

    return axios.post("/confirms/" + confirm.id + "/ticket_reservations", {
        ticket_reservation: {
          seatsio_hold_tokens: seatsioHoldTokens,
          promo_code_name: (promoCode && promoCode.name ? promoCode.name : null),
          user_context_access_token: (userContext && userContext.access_token ? userContext.access_token : null),
          customerio_id: (customerioId && customerioId.length > 0 ? customerioId : null),
          order_items_attributes: orderItems.map((item) => {
            return {
              ticket_type_id: item.id,
              quantity: item.quantity,
              seatsio_objects: item.seatsIOObjects
            };
          }),
          add_on_order_items_attributes: addOnOrderItems.map((item) => {
            return {
              add_on_id: item.id,
              quantity: item.quantity,
              seatsio_objects: item.seatsIOObjects
            };
          }),
          abstract_order_items_attributes: abstractOrderItems.map((item) => {
            return {
              custom_charge_type_id: item.custom_charge_type_id,
              quantity: item.quantity,
              price: item.price,
              face_value: item.face_value
            };
          })
        }
      })
      .then(({ data }) => {
        if(data.user_context_access_token){
          data = Object.assign({}, data, {
            confirm_email: data.email
          });
        }

        dispatch(ticketReservationChanged(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        dispatch(isCreatingTicketReservationChanged(false));
      });
  };
};

export const deleteTicketReservation = (dispatch, csrfToken, confirm, ticketReservation) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/confirms/" + confirm.id + "/ticket_reservations/" + ticketReservation.token)
      .then(({ data }) => {
        dispatch(cancelCheckout());
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const cancelCheckout = () => ({
  type: CANCEL_CHECKOUT
});

export const stripePromiseChanged = (stripePromise) => ({
  type: STRIPE_PROMISE_CHANGED,
  stripePromise
});

export const stripeErrorChanged = (error) => ({
  type: STRIPE_ERROR_CHANGED,
  error
});

export const updateTicketReservation = (dispatch, csrfToken, confirm, ticketReservation, onSuccess, customFieldValues) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/confirms/" + confirm.id + "/ticket_reservations/" + ticketReservation.token, {
        ticket_reservation: {
          first_name: ticketReservation.first_name,
          last_name: ticketReservation.last_name,
          email: ticketReservation.email,
          subscribe_to_venue: ticketReservation.subscribe_to_venue,
          custom_field_values_attributes: customFieldValues.map((customFieldValue) => customFieldValueToParams(customFieldValue)),
          recaptcha_token: ticketReservation.recaptcha_token
        }
      })
      .then(({ data }) => {
        var updated = Object.assign({}, ticketReservation, data);

        customFieldValues.map((cfv) => {
          var persisted = data.custom_field_values.find((value) =>
            value.custom_field_id === cfv.custom_field.id
          );

          if(persisted){
            var updatedCustomFieldValue = Object.assign({}, cfv, {
              id: persisted.id
            });

            dispatch(customFieldValueChanged(updatedCustomFieldValue));
          }
        });

        if(updated.in_memory_fees === null){
          updated = Object.assign({}, updated, {
            in_memory_fees: ticketReservation.in_memory_fees
          });
        }

        dispatch(ticketReservationChanged(updated));
        onSuccess(updated);
      })
      .catch((error) => {
        dispatch(isPlacingOrderChanged(false));

        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const autoSaveTicketReservation = (dispatch, csrfToken, confirm, ticketReservation) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var params = {
      first_name: ticketReservation.first_name,
      last_name: ticketReservation.last_name,
      email: null,
      contact_zip: null
    }

    if(validateEmail(ticketReservation.email)){
      params = Object.assign({}, params, {
        email: ticketReservation.email
      });
    }

    if(validateZip(ticketReservation.contact_zip)){
      params = Object.assign({}, params, {
        contact_zip: ticketReservation.contact_zip
      });
    }

    return axios.patch("/confirms/" + confirm.id + "/ticket_reservations/" + ticketReservation.token, {
        ticket_reservation: params
      })
      .then(({ data }) => {
        // ...
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const saveGeoCodedStateCode = (dispatch, csrfToken, confirm, ticketReservation, stateCode) => {
  return (dispatch, getState) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var params = {
      contact_state_code: stateCode
    }

    return axios.patch("/confirms/" + confirm.id + "/ticket_reservations/" + ticketReservation.token, {
        ticket_reservation: params
      })
      .then(({ data }) => {
        var currentTicketReservation = getState().ticketReservation;
        var updated = Object.assign({}, currentTicketReservation, {
          contact_state_code: data.contact_state_code
        });

        dispatch(ticketReservationChanged(updated));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const checkoutTimePercentageRemainingChanged = (percentage) => ({
  type: CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED,
  percentage
});

export const timeLimitReachedChanged = (reached) => ({
  type: TIME_LIMIT_REACHED_CHANGED,
  reached
});

export const isPlacingOrderChanged = (isPlacingOrder) => ({
  type: IS_PLACING_ORDER_CHANGED,
  isPlacingOrder
});

export const addOnsChanged = (addOns) => ({
  type: ADD_ONS_CHANGED,
  addOns
});

export const gtagChanged = (gtag) => ({
  type: GTAG_CHANGED,
  gtag
});

export const customFieldValueChanged = (customFieldValue) => ({
  type: CUSTOM_FIELD_VALUE_CHANGED,
  customFieldValue
});

export const seatsIOChartChanged = (seatsIOChart) => ({
  type: SEATS_IO_CHART_CHANGED,
  seatsIOChart
});

export const seatsIOSelectedObjectsChanged = (seatsIOSelectedObjects) => ({
  type: SEATS_IO_SELECTED_OBJECTS_CHANGED,
  seatsIOSelectedObjects
});

export const customerioIdChanged = (customerioId) => ({
  type: CUSTOMERIO_ID_CHANGED,
  customerioId
});

export const isCreatingTicketReservationChanged = (isCreatingTicketReservation) => ({
  type: IS_CREATING_TICKET_RESERVATION_CHANGED,
  isCreatingTicketReservation
});

export const registerTicketInsurance = (dispatch, csrfToken, confirm, ticketReservation, quote) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/confirms/" + confirm.id + "/ticket_insurances/" + quote.quote_id + "/register", {
        ticket_insurance: {
          ticket_reservation_id: ticketReservation.token,
          premium_amount_in_cents: quote.premium_amount
        }
      })
      .then(({ data }) => {
        dispatch(ticketInsuranceChanged(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const insuranceOfferStateChanged = (insuranceOfferState) => ({
  type: INSURANCE_OFFER_STATE_CHANGED,
  insuranceOfferState
});

export const ticketInsuranceChanged = (ticketInsurance) => ({
  type: TICKET_INSURANCE_CHANGED,
  ticketInsurance
});

export const deleteTicketInsurance = (dispatch, csrfToken, confirm, ticketInsurance) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    if(!ticketInsurance.quote_id){
      return;
    }

    return axios.delete("/confirms/" + confirm.id + "/ticket_insurances/" + ticketInsurance.quote_id)
      .then(({ data }) => {
        dispatch(ticketInsuranceChanged({}));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const hasInsuranceQuoteChanged = (hasInsuranceQuote) => ({
  type: HAS_INSURANCE_QUOTE_CHANGED,
  hasInsuranceQuote
});

export const donationCustomChargeTypeChanged = (donationCustomChargeType) => ({
  type: DONATION_CUSTOM_CHARGE_TYPE_CHANGED,
  donationCustomChargeType
});
