// Simple example of a React "smart" component

import { connect } from 'react-redux';
import EventForm from '../components/EventForm';
import * as actions from '../../EventForm/actions/eventFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  forDuplication: state.forDuplication,
  holdGroup: state.holdGroup,
  confirm: state.confirm,
  formTitle: state.formTitle,
  formButtonLabel: state.formButtonLabel,
  csrfToken: state.csrfToken,
  team: state.team,
  venuesOptions: state.venuesOptions,
  venueSelected: state.venueSelected,
  calendarStartDate: state.calendarStartDate,
  calendarEventTitle: state.calendarEventTitle,
  calendarEventArtists: state.calendarEventArtists,
  calendarEventType: state.calendarEventType,
  calendarEventTicketForecast: state.calendarEventTicketForecast,
  calendarEventNoteEditorState: state.calendarEventNoteEditorState,
  calendarEventErrors: state.calendarEventErrors,
  userChangedCalendarEventTitle: state.userChangedCalendarEventTitle,
  holdGroupDates: state.holdGroupDates,
  submitEventForm: state.submitEventForm,
  onClose: state.onClose,
  calendarRef: state.calendarRef,
  onSuccess: state.onSuccess,
  selectedPromoter: state.selectedPromoter,
  buyers: state.buyers,
  promoters: state.promoters,
  selectedBuyer: state.selectedBuyer,
  currentUser: state.currentUser,
  holdGroupDeletion: state.holdGroupDeletion,
  submittingForm: state.submittingForm,
  doorsTimeDefault: state.doorsTimeDefault,
  eventEndTimeDefault: state.eventEndTimeDefault,
  userChangedDoorsTime: state.userChangedDoorsTime,
  userChangedEventEndTime: state.userChangedEventEndTime,
  onVenueChange: state.onVenueChange
});

const mapDispatchToProps = (dispatch) => {
  return {
    submitEditEventForm: (csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => dispatch(actions.submitEditEventForm(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer)),
    deleteEvent: (csrfToken, team, closeFunction, holdGroup, confirm) => dispatch(actions.deleteEvent(dispatch, csrfToken, team, closeFunction, holdGroup, confirm)),
    fetchHoldAvailability: (team, venueId, date) => dispatch(actions.fetchHoldAvailability(dispatch, team, venueId, date)),
    updateCalendarEventTitle: (title) => dispatch(actions.updateCalendarEventTitle(title)),
    updateCalendarEventArtists: (artists, userChangedCalendarEventTitle) => dispatch(actions.updateCalendarEventArtists(artists, userChangedCalendarEventTitle)),
    updateCalendarEventType: (calendarEventType) => dispatch(actions.updateCalendarEventType(calendarEventType)),
    updateCalendarEventNoteEditorState: (noteEditorState) => dispatch(actions.updateCalendarEventNoteEditorState(noteEditorState)),
    calendarEventTicketForecastChanged: (ticketForecast) => dispatch(actions.calendarEventTicketForecastChanged(ticketForecast)),
    calendarEventFormClosed: (onClose) => dispatch(actions.calendarEventFormClosed(dispatch, onClose)),
    holdGroupCalendarDateClicked: (date, selected) => dispatch(actions.holdGroupCalendarDateClicked(date, selected)),
    holdGroupHoldPositionUpdated: (date, position) => dispatch(actions.holdGroupHoldPositionUpdated(date, position)),
    holdGroupHoldRemoved: (date) => dispatch(actions.holdGroupHoldRemoved(date)),
    confirmChanged: (confirm) => dispatch(actions.confirmChanged(confirm)),
    confirmStartDateUpdated: (date) => dispatch(actions.confirmStartDateUpdated(date)),
    confirmStartTimeUpdated: (time) => dispatch(actions.confirmStartTimeUpdated(time)),
    confirmHoldUpdated: (hold) => dispatch(actions.confirmHoldUpdated(hold)),
    updateSelectedVenue: (venues, selected) => dispatch(actions.updateSelectedVenue(venues, selected)),
    selectedPromoterChanged: (promoter) => dispatch(actions.selectedPromoterChanged(promoter)),
    selectedBuyerChanged: (buyer) => dispatch(actions.selectedBuyerChanged(buyer)),
    userChangedDoorsTimeChanged: (userChangedDoorsTime) => dispatch(actions.userChangedDoorsTimeChanged(userChangedDoorsTime)),
    userChangedEventEndTimeChanged: (userChangedDoorsTime) => dispatch(actions.userChangedEventEndTimeChanged(userChangedEventEndTime)),
    buyersChanged: (buyers) => dispatch(actions.buyersChanged(buyers)),
    promotersChanged: (promoters) => dispatch(actions.promotersChanged(promoters)),
    selectedBuyerChanged: (buyer) => dispatch(actions.selectedBuyerChanged(buyer)),
    holdGroupDeletionChanged: (holdGroupDeletion) => dispatch(actions.holdGroupDeletionChanged(holdGroupDeletion)),
    holdGroupDeletionReasonChanged: (reason) => dispatch(actions.holdGroupDeletionReasonChanged(reason)),
    holdGroupDeletionExplanationChanged: (explanation) => dispatch(actions.holdGroupDeletionExplanationChanged(explanation)),
    holdGroupDeletionFollowUpChanged: (followUp) => dispatch(actions.holdGroupDeletionFollowUpChanged(followUp)),
    holdGroupDeletionFollowUpAtChanged: (followUpAt) => dispatch(actions.holdGroupDeletionFollowUpAtChanged(followUpAt)),
    deleteHoldGroup: (csrfToken, team, closeFunction, holdGroup, holdGroupDeletion) => dispatch(actions.deleteHoldGroup(dispatch, csrfToken, team, closeFunction, holdGroup, holdGroupDeletion)),
    applyTimeDefaults: (confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime, callback) => dispatch(actions.applyTimeDefaults(dispatch, confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime, callback)),
    holdGroupChanged: (holdGroup) => dispatch(actions.holdGroupChanged(holdGroup)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
