import React, { useState } from 'react';

const SimilarArtists = ({similarArtists}) => {
  const [showMore, setShowMore] = useState(similarArtists.length > 5);

  const showMoreOrLess = () => {
    setShowMore(!showMore);
  }

  const similarArtistItem = (similarArtist, index) => {
    return (
      <span
        key={index}
        className="pt-1"
        style={{
          "display": "inline-block",
          "paddingRight": "0.5rem",
          "paddingBottom": "0.5rem"
        }}
      >
        <img
          className="img-fluid rounded-circle tiny-avatar mr-1"
          src={similarArtist.image_url}
        />
        <strong>
          <a
            className="text-dark normal-text"
            href={ `/artists/${similarArtist.permalink}` }
          >
            {similarArtist.name}
          </a>
        </strong>
      </span>
    )
  }

  return (
    <div className='col-12'>
      <div className="pb-2">
        <strong className="normal-number mb-3">
          Similar Artists
        </strong>
      </div>
      {similarArtists.slice(0, 5).map((similarArtist, i) => similarArtistItem(similarArtist, i)) }
      { !showMore && similarArtists.slice(5).map((similarArtist, i) => similarArtistItem(similarArtist, i)) }
      { similarArtists.length > 5 && (
        <div className="row col-12">
          <button className="btn btn-link btn-sm normal-text pl-0" onClick={showMoreOrLess}>
            Show {showMore ? 'More' : 'Less'}
          </button>
        </div>
      )}
    </div>
  );
}

export default SimilarArtists;
