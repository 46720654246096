import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import OutreachModalApp from '../../OutreachModal/startup/OutreachModalApp';
import OfferV2ModalApp from '../../OfferV2Modal/startup/OfferV2ModalApp';
import EventFormApp from '../../EventForm/startup/EventFormApp';
import FollowUpModalApp from '../../FollowUpModal/startup/FollowUpModalApp';
import PipelineSlidingPaneApp from '../../PipelineSlidingPane/startup/PipelineSlidingPaneApp';

var ReactDOM = require('react-dom');
const axios = require('axios').default;
import AsyncSelect from 'react-select/async';
import Popup from "reactjs-popup";
import DataTable from '../../DataTable/components/DataTable';
import { ToastContainer } from 'react-toastify';
import DatePicker from "react-datepicker";

import $ from 'jquery';
require('datatables.net-bs4');

import {
  setGonTimeZoneFromVenue
} from '../../../shared/timeZoneLogic';

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "190px",
  "padding": "0px"
}

const eventFormModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "594px",
  "padding": "0px"
}

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "493px",
  "padding": "20px 15px"
}

const newOutreachModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "20px 15px"
}

const offerModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "0"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const OutreachManager = ({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  dataTableElChanged,
  deleteOutreach,
  followUp,
  fetchNewFollowUp,
  outreachesExists,
  fetchNewOutreach,
  newOutreach,
  venueOwnerships,
  outreachTemplates,
  outreachExistsChanged,
  modalIsOpen,
  defaultOffer,
  offerModalOpened,
  offerModalClosed,
  offer,
  offerTemplates,
  venuesOptions,
  onInit,
  fetchNewOffer,
  calendarEventArtists,
  venueSelected,
  createCalendarEventFromOutreachClicked,
  fetchPipeline,
  followUpChanged,
  newOutreachChanged,
  confirm,
  holdGroup,
  submitNewEventForm,
  closeCalendarEventModal,
  pipeline,
  pipelineChanged,
  buyers,
  promoters,
  selectedBuyer,
  selectedPromoter,
  submittingForm,
  doorsTimeDefault,
  eventEndTimeDefault,
  userChangedDoorsTime,
  userChangedEventEndTime,
}) => {

  useEffect(() => {
    onInit(defaultOffer);
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
  }, [])

  return (
    <div>
      <ToastContainer />
      {outreachesExists ? (
        <div>
          {currentUser.can_manage_outreaches ? (
            <div className="datatable-right-header-section">
              <a href="#"
                 onClick={(e) => {
                   e.preventDefault();
                   fetchNewOutreach(team);
                 }}
                 className="btn btn-primary">
                <i className="fal fa-plus"></i>
                &nbsp;
                <strong>New Outreach</strong>
              </a>
            </div>
          ) : null}
          <DataTable title={dataTableTitle}
                     dataSource={dataTableDataSource}
                     columns={dataTableColumns}
                     colGroup={
                       <colgroup>
                         <col span={1} style={{"width": "35%"}} />
                         <col span={1} style={{"width": "15%"}} />
                         <col span={1} style={{"width": "30%"}} />
                         <col span={1} style={{"width": "15%"}} />
                         <col span={1} style={{"width": "5%"}} />
                       </colgroup>
                     }
                     onTableElementSet={
                       (el) => {
                         dataTableElChanged(el);
                       }
                     }
                     rowCallback={
                        (row, data) => {
                          var mountReact = row.getElementsByClassName("outreach-popup-mount-react")[0];
                          var rowOffer   = data;
                          var outreach   = JSON.parse(decodeHtml(rowOffer.json));

                          var mountArtistReact = row.getElementsByClassName("outreach-artist-mount-react")[0];
                          ReactDOM.render((
                            <span>
                              <img className="img-fluid rounded inline-img"
                                   src={outreach.artist.image_thumb_url_for_json} />
                              <strong className="px-2">
                                <a href="#"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();
                                       fetchPipeline(team, outreach.pipeline.id);
                                     }
                                   }>
                                  {outreach.artist.name}
                                </a>
                              </strong>
                            </span>
                          ), mountArtistReact);

                          ReactDOM.render((
                            <div>
                              {currentUser.can_manage_outreaches ? (
                                <Popup arrow={false}
                                       offsetY={5}
                                       position="bottom right"
                                       contentStyle={popupContentStyle}
                                       onOpen={
                                         (e) => {
                                           e.preventDefault();
                                         }
                                       }
                                       trigger={open => (
                                         <a href="#">
                                           <i className="fas fa-ellipsis-v"></i>
                                         </a>
                                       )} >
                                  {close => (
                                    <div className='row text-left'>
                                      <div className="col-12">
                                        <ul className="list-group offer-inline-menu highlight-primary">
                                          <li className="list-group-item">
                                            <a onClick={
                                                 (e) => {
                                                   e.preventDefault();

                                                   var rowTableEl = row.parentElement.parentElement;
                                                   createCalendarEventFromOutreachClicked(outreach, rowTableEl, [outreach.artist]);
                                                 }
                                               }
                                               className="text-muted"
                                               href="#">
                                              <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                <i className="fal fa-plus"></i>
                                              </span>
                                              Create Hold/Confirm
                                            </a>
                                          </li>
                                          <li className="list-group-item">
                                            <a onClick={
                                                 (e) => {
                                                   e.preventDefault();

                                                   var rowTableEl = row.parentElement.parentElement;
                                                   fetchNewOffer(team, outreach, defaultOffer, rowTableEl);
                                                 }
                                               }
                                               className="text-muted"
                                               href="#">
                                              <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                <i className="fas fa-clone fa-flip-both"></i>
                                              </span>
                                              Create Offer
                                            </a>
                                          </li>
                                          <li className="list-group-item">
                                            <a onClick={
                                                 (e) => {
                                                   e.preventDefault();

                                                   var rowTableEl = row.parentElement.parentElement;
                                                   fetchNewFollowUp(team, outreach, rowTableEl);
                                                 }
                                               }
                                               className="text-muted"
                                               href="#">
                                              <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                <i className="far fa-calendar-week"></i>
                                              </span>
                                              Follow-up Later
                                            </a>
                                          </li>
                                          <li className="list-group-item delete-offer-li">
                                            <Popup
                                                trigger={
                                                  <a href="#" className="text-danger">
                                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                      <i className="fas fa-trash"></i>
                                                    </span>
                                                    Remove
                                                  </a>
                                                }
                                                modal
                                                onOpen={
                                                  (e) => {
                                                    e.preventDefault();
                                                  }
                                                }
                                                contentStyle={deleteModalContentStyle}
                                                closeOnDocumentClick
                                              >
                                              {closeDeletePopup => (
                                                <div>
                                                  <div className="row">
                                                    <div className="col">
                                                      <div className="row">
                                                        <div className="col">
                                                          <h5 style={{"marginBottom": "16px"}}>
                                                            <div className="strong">Remove outreach?</div>
                                                          </h5>
                                                        </div>
                                                        <div className="col-xs-auto">
                                                          <a href="#"
                                                             className="text-dark"
                                                             style={{"paddingRight": "15px"}}
                                                             onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                            <i className="fa fa-times"></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <p>You're about to remove this outreach.</p>
                                                      <p>This cannot be undone.</p>
                                                      <p>Are you sure?</p>
                                                      <div className="text-right">
                                                        <a href="#"
                                                           onClick={
                                                             (e) => {
                                                               e.preventDefault();
                                                               closeDeletePopup();
                                                             }
                                                           }>
                                                          Cancel
                                                        </a>
                                                        <a href="#"
                                                           className="btn btn-danger ml-3"
                                                           onClick={
                                                             (e) => {
                                                               e.preventDefault();
                                                               var rowTableEl = row.parentElement.parentElement;

                                                               closeDeletePopup();
                                                               close();
                                                               deleteOutreach(team, outreach, csrfToken, rowTableEl);
                                                             }
                                                           }>
                                                          Remove
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </Popup>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              ) : null}
                            </div>
                          ), mountReact);
                        }
                     }
                     order={dataTableOrder} />
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card no-border shadow-2">
              <div className="card-body">
                <div className='row pt-4'>
                  <div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
                    <div className="card no-border" style={{"background": "#f4f9fc"}}>
                      <div className="card-body text-center p-5">
                        <div className="row">
                          <div className="col-12">
                            <img src="/outreach-empty.png"
                                 className="img-fluid pb-5" />
                            <h5 className='py-2'>
                              <strong className="text-dark">
                                Connect with agents. Book more shows.
                              </strong>
                            </h5>
                            <p className='pb-2'>
                              Reach out to agents directly through Opendate. We'll track responses and help you move the opportunity along.
                            </p>
                            {currentUser.can_manage_outreaches ? (
                              <div>
                                <a href="#"
                                   onClick={(e) => {
                                     e.preventDefault();
                                     fetchNewOutreach(team);
                                   }}
                                   style={{"borderRadius": "8px"}}
                                   className="btn btn-primary btn-sm">
                                  <i className="fal fa-plus"></i>
                                  &nbsp;
                                  <strong>New Outreach</strong>
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
     <Popup
       open={Object.keys(followUp).length > 0}
       contentStyle={modalContentStyle}
       onClose={() => followUpChanged({})}
     >
       <FollowUpModalApp followUp={followUp}
                         team={team}
                         dataTableEl={dataTableEl}
                         csrfToken={csrfToken}
                         onClose={
                           () => {
                             followUpChanged({});
                           }
                         }
                         canChangeArtist={false} />
     </Popup>
     <Popup
       open={Object.keys(newOutreach).length > 0}
       contentStyle={newOutreachModalContentStyle}
       className={"popup-modal"}
       closeOnDocumentClick={false}
       onClose={() => newOutreachChanged({})}
     >
       <OutreachModalApp csrfToken={csrfToken}
                         team={team}
                         outreach={newOutreach}
                         venueOwnerships={venueOwnerships}
                         onOutreachCreate={() => {
                           outreachExistsChanged(true);
                           newOutreachChanged({});

                           if(Object.keys(dataTableEl).length > 0){
                             $(dataTableEl).DataTable().ajax.reload();
                           }
                         }}
                         onClose={
                           () => {
                             newOutreachChanged({});
                           }
                         }
                         outreachTemplates={outreachTemplates}
                         canChangeArtist={true} />
     </Popup>
     <Popup
       open={modalIsOpen}
       contentStyle={offerModalContentStyle}
       closeOnDocumentClick={false}
       className={"offer-modal"}
       onClose={() => offerModalClosed(defaultOffer)}
     >
       <OfferV2ModalApp currentUser={currentUser}
                        csrfToken={csrfToken}
                        onClose={
                          () => {
                            offerModalClosed(defaultOffer);
                          }
                        }
                        venuesOptions={venuesOptions}
                        venueSelected={venueSelected}
                        venueOwnerships={venueOwnerships}
                        selectedArtist={offer.artistPreview}
                        offer={{create_calendar_event: true}}
                        pipeline={offer.pipeline}
                        team={team} />
     </Popup>
     <Popup
       open={calendarEventArtists.length > 0}
       contentStyle={eventFormModalContentStyle}
       className={"popup-modal"}
       closeOnDocumentClick={false}
       onOpen={
         () => {
           setGonTimeZoneFromVenue(venueOwnerships, venueSelected.value);
         }
       }
       onClose={() => closeCalendarEventModal()}
     >
       <div className="popup-modal-container">
         <EventFormApp formTitle={"New Event"}
                       currentUser={currentUser}
                       confirm={confirm}
                       holdGroup={holdGroup}
                       formButtonLabel={"Create Event"}
                       calendarEventTitle={
                         calendarEventArtists.map((artist) => artist.name).join(" & ")
                       }
                       onClose={
                         () => {
                           closeCalendarEventModal();
                         }
                       }
                       csrfToken={csrfToken}
                       team={team}
                       venuesOptions={venuesOptions}
                       venueSelected={venueSelected}
                       onVenueChange={
                         (venue) => {
                           setGonTimeZoneFromVenue(venueOwnerships, venue.value);
                         }
                       }
                       calendarEventArtists={calendarEventArtists}
                       buyers={buyers}
                       promoters={promoters}
                       selectedBuyer={selectedBuyer}
                       selectedPromoter={selectedPromoter}
                       submittingForm={submittingForm}
                       submitEventForm={submitNewEventForm}
                       onSuccess={(url) => {window.location.href = url;}}
                       doorsTimeDefault={doorsTimeDefault}
                       eventEndTimeDefault={eventEndTimeDefault}
                       userChangedDoorsTime={userChangedDoorsTime}
                       userChangedEventEndTime={userChangedEventEndTime} />
       </div>
     </Popup>
     <PipelineSlidingPaneApp pipelineSlidingPane={{
                               pipeline: pipeline,
                               csrfToken: csrfToken,
                               team: team,
                               dataTableEl: dataTableEl,
                               venuesOptions: venuesOptions,
                               venueSelected: venueSelected,
                               venueOwnerships: venueOwnerships,
                               defaultOffer: defaultOffer,
                               offerTemplates: offerTemplates,
                               currentUser: currentUser,
                               onClose: () => {
                                 pipelineChanged({});
                               }
                             }}
                             eventForm={{
                               buyers: buyers,
                               promoters: promoters,
                               selectedBuyer: selectedBuyer,
                               selectedPromoter: selectedPromoter,
                               doorsTimeDefault: doorsTimeDefault,
                               eventEndTimeDefault: eventEndTimeDefault,
                               userChangedDoorsTime: userChangedDoorsTime,
                               userChangedEventEndTime: userChangedEventEndTime,
                             }} />
    </div>
  )
};

OutreachManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  deleteOutreach: PropTypes.func.isRequired,
  followUp: PropTypes.object,
  fetchNewFollowUp: PropTypes.func.isRequired,
  outreachesExists: PropTypes.bool,
  fetchNewOutreach: PropTypes.func.isRequired,
  newOutreach: PropTypes.object,
  venueOwnerships: PropTypes.array,
  outreachTemplates: PropTypes.array,
  outreachExistsChanged: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
  defaultOffer: PropTypes.object.isRequired,
  offerModalOpened: PropTypes.func.isRequired,
  offerModalClosed: PropTypes.func.isRequired,
  offer: PropTypes.object,
  offerTemplates: PropTypes.array,
  venuesOptions: PropTypes.array,
  onInit: PropTypes.func.isRequired,
  fetchNewOffer: PropTypes.func.isRequired,
  calendarEventArtists: PropTypes.array,
  venueSelected: PropTypes.object,
  createCalendarEventFromOutreachClicked: PropTypes.func.isRequired,
  fetchPipeline: PropTypes.func.isRequired,
  followUpChanged: PropTypes.func.isRequired,
  newOutreachChanged: PropTypes.func.isRequired,
  confirm: PropTypes.object,
  holdGroup: PropTypes.object,
  submitNewEventForm: PropTypes.func.isRequired,
  closeCalendarEventModal: PropTypes.func.isRequired,
  pipeline: PropTypes.object,
  pipelineChanged: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  promoters: PropTypes.array,
  selectedBuyer: PropTypes.object,
  selectedPromoter: PropTypes.object,
  submittingForm: PropTypes.bool,
  doorsTimeDefault: PropTypes.object.isRequired,
  eventEndTimeDefault: PropTypes.object.isRequired,
  userChangedDoorsTime: PropTypes.bool,
  userChangedEventEndTime: PropTypes.bool,
};

export default OutreachManager;
