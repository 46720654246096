import PropTypes from 'prop-types';
import React from 'react';

class ShowHideContent extends React.Component {
  static propTypes = {
    isShowing: PropTypes.bool,
    showLabel: PropTypes.string,
    hideLabel: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    isShowing: false,
    showLabel: "View",
    hideLabel: "Hide",
    className: ""
  }

  constructor(props) {
    super(props);

    this.state = {
      isShowing: this.props.isShowing,
      showLabel: this.props.showLabel,
      hideLabel: this.props.hideLabel,
      className: this.props.className
    };
  }

  render() {
    return (
      <div className={this.state.className}>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               this.setState({isShowing: !this.state.isShowing});
             }
           }>
          {this.state.isShowing ? this.state.hideLabel : this.state.showLabel}
        </a>
        {this.state.isShowing ? (
          this.props.children
        ) : null}
      </div>
    );
  }
}

export default ShowHideContent;
