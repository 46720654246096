import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "190px",
  "padding": "0px"
}

const followUpPopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const InlineMenu = ({
  pipeline,
  currentUser,
  team,
  fetchNewFollowUp,
  createCalendarEventFromPipeline,
  fetchNewOffer,
  defaultOffer,
  fetchEditFollowUp
}) => (
  pipeline.state === "outreach" && currentUser.can_manage_outreaches ? (
    <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
      <Popup arrow={false}
             offsetY={7}
             position="bottom right"
             contentStyle={popupContentStyle}
             onOpen={
               (e) => {
                 e.preventDefault();
               }
             }
             trigger={open => (
               <a href="#"
                  className="btn btn-external btn-sm"
                  style={{"borderRadius": "8px"}}>
                 <i className="fas fa-ellipsis-v small"></i>
               </a>
             )} >
        {close => (
          <div className='row text-left'>
            <div className="col-12">
              <ul className="list-group offer-inline-menu highlight-primary">
                <li className="list-group-item">
                  <a onClick={
                       (e) => {
                         e.preventDefault();
                         createCalendarEventFromPipeline(pipeline);
                       }
                     }
                     className="text-muted"
                     href="#">
                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                      <i className="fal fa-plus"></i>
                    </span>
                    Create Hold/Confirm
                  </a>
                </li>
                <li className="list-group-item">
                  <a onClick={
                       (e) => {
                         e.preventDefault();
                         fetchNewOffer(team, pipeline, defaultOffer);
                       }
                     }
                     className="text-muted"
                     href="#">
                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                      <i className="fas fa-clone fa-flip-both"></i>
                    </span>
                    Create Offer
                  </a>
                </li>
                <li className="list-group-item">
                  <a onClick={
                       (e) => {
                         e.preventDefault();
                         fetchNewFollowUp(team, pipeline);
                       }
                     }
                     className="text-muted"
                     href="#">
                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                      <i className="far fa-calendar-week"></i>
                    </span>
                    Follow-up Later
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Popup>
    </div>
  ) : pipeline.state === "follow_up" && currentUser.can_manage_follow_ups ? (
    <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
      <Popup arrow={false}
             offsetY={7}
             position="bottom right"
             contentStyle={followUpPopupContentStyle}
             onOpen={
               (e) => {
                 e.preventDefault();
               }
             }
             trigger={open => (
               <a href="#"
                  className="btn btn-external btn-sm"
                  style={{"borderRadius": "8px"}}>
                 <i className="fas fa-ellipsis-v small"></i>
               </a>
             )} >
        {close => (
          <div className='row text-left'>
            <div className="col-12">
              <ul className="list-group offer-inline-menu highlight-primary">
                {!pipeline.last_touched_record.pipeline_extra.completed ? (
                  <li className="list-group-item">
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           fetchEditFollowUp(team, pipeline.last_touched_record);
                         }
                       }
                       className="text-muted">
                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                        <i className="far fa-calendar-week"></i>
                      </span>
                      Edit Follow-up Date
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        )}
      </Popup>
    </div>
  ) : null
);

InlineMenu.propTypes = {
  pipeline: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  fetchNewFollowUp: PropTypes.func.isRequired,
  createCalendarEventFromPipeline: PropTypes.func.isRequired,
  fetchNewOffer: PropTypes.func.isRequired,
  defaultOffer: PropTypes.object,
  fetchEditFollowUp: PropTypes.func.isRequired
};

export default InlineMenu;
