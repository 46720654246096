// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ConfirmDashboard from '../components/ConfirmDashboard';
import * as actions from '../actions/confirmDashboardActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  confirm: state.confirm,
  ticketTypes: state.ticketTypes,
  typedSearchTerm: state.typedSearchTerm,
  searchedSearchTerm: state.searchedSearchTerm,
  csvDataSource: state.csvDataSource,
  dataTableEl: state.dataTableEl,
  order: state.order,
  widgetTab: state.widgetTab,
  addOnsByPrice: state.addOnsByPrice,
  refund: state.refund,
  refundReasons: state.refundReasons,
  submittingRefund: state.submittingRefund,
  slidingPaneState: state.slidingPaneState,
  resendEmail: state.resendEmail,
  sendingConfirmationEmail: state.sendingConfirmationEmail,
  cumulativeSalesData: state.cumulativeSalesData,
  fakeSalesData: state.fakeSalesData,
  refundOrder: state.refundOrder,
  ticketBuyersData: state.ticketBuyersData,
  buyers: state.buyers,
  promoters: state.promoters,
  ticketTypesByPrice: state.ticketTypesByPrice,
  canBypassRefundRestrictions: state.canBypassRefundRestrictions,
  payoutInfo: state.payoutInfo,
  salesData: state.salesData
});

const mapDispatchToProps = (dispatch) => {
  return {
    confirmChanged: (confirm) => dispatch(actions.confirmChanged(confirm)),
    startSearchTermUpdate: (state) => dispatch(actions.startSearchTermUpdate(dispatch, state)),
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    orderChanged: (order) => dispatch(actions.orderChanged(order)),
    sendOrderReceipt: (csrfToken, team, order, resendEmail) => dispatch(actions.sendOrderReceipt(dispatch, csrfToken, team, order, resendEmail)),
    widgetTabChanged: (widgetTab) => dispatch(actions.widgetTabChanged(widgetTab)),
    fetchNewRefund: (csrfToken, team, order, ticket) => dispatch(actions.fetchNewRefund(dispatch, csrfToken, team, order, ticket)),
    refundChanged: (refund) => dispatch(actions.refundChanged(refund)),
    createRefund: (csrfToken, team, order, confirm, refund) => dispatch(actions.createRefund(dispatch, csrfToken, team, order, confirm, refund)),
    slidingPaneStateChanged: (slidingPaneState) => dispatch(actions.slidingPaneStateChanged(slidingPaneState)),
    resendEmailChanged: (resendEmail) => dispatch(actions.resendEmailChanged(resendEmail)),
    fakeSalesDataChanged: (fakeSalesData) => dispatch(actions.fakeSalesDataChanged(fakeSalesData)),
    confirmRefund: (csrfToken, team, order, refund) => dispatch(actions.confirmRefund(dispatch, csrfToken, team, order, refund)),
    fetchTicketBuyersData: (csrfToken, team, confirm) => dispatch(actions.fetchTicketBuyersData(dispatch, csrfToken, team, confirm)),
    ticketBuyersDataChanged: (ticketBuyersData) => dispatch(actions.ticketBuyersDataChanged(ticketBuyersData)),
    sendOrderCheckIn: (csrfToken, team, order) => dispatch(actions.sendOrderCheckIn(dispatch, csrfToken, team, order)),
    sendOrderCheckOut: (csrfToken, team, order) => dispatch(actions.sendOrderCheckOut(dispatch, csrfToken, team, order)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDashboard);
