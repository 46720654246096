import React, { createContext, useState, useContext } from "react";
import { ToastContainer } from "react-toastify";

import SegmentEdit from "./SegmentEdit";

const SegmentEditContext = createContext();

export function useSegmentEditContext() {
  return useContext(SegmentEditContext);
}

const SegmentEditProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
  } = props;

  const [segment, setSegment] = useState(props.segment);

  const value = {
    csrfToken,
    user,
    team,
    segment,
    setSegment,
  };

  return (
    <SegmentEditContext.Provider value={value}>
      {props.children}
    </SegmentEditContext.Provider>
  );
};

const SegmentEditContainer = (props) => (
  <SegmentEditProvider {...props}>
    <ToastContainer />
    <SegmentEdit />
  </SegmentEditProvider>
);

export default SegmentEditContainer;
