import React from "react";

import { useMailkickSubscriptionIndexContext } from "./MailkickSubscriptionsIndexContainer";
import MailkickSubscriptionRow from "./MailkickSubscriptionRow";

const MailkickSubscriptionIndex = () => {
  const {
    csrfToken,
    user,
    team,
    mailkickSubscriptionsByVenueOwnership,
    venueOwnerships,
  } = useMailkickSubscriptionIndexContext();

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "65% 1fr 1fr",
    gap: "10px"
  };

  const headerStyle = {
    fontWeight: "bold",
    textAlign: "center",
  };

  return (
    <div>
      <div style={{
        fontSize: 16,
        fontWeight: 700,
      }}>
        Subscriptions
      </div>
      <div style={gridContainerStyle}>
        <div style={headerStyle}></div>
        <div style={headerStyle}>Email</div>
        <div style={headerStyle}>SMS</div>
        {venueOwnerships.map((venueOwnership, index) => (
          <MailkickSubscriptionRow
            key={index}
            venueOwnership={venueOwnership}
          />
        ))}
      </div>
    </div>
  );
};

export default MailkickSubscriptionIndex;
