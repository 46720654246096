import PropTypes from 'prop-types';
import React from 'react';

import Iframe from 'react-iframe'

import {
  PREVIEW,
  SEND
} from '../constants/offerV2PreviewConstants';

const canSubmitForm = (offer) => {
  return (
    offer
      && offer.last_sent_to
      && offer.last_sent_to.length > 0
  );
};

const OfferV2Preview = ({
  csrfToken,
  team,
  currentUser,
  offer,
  onClose,
  uiState,
  updateUIState,
  updateOfferTo,
  updateOfferBody,
  sendOffer,
  onSuccess
}) => (
  <div className="offer-modal-container">
    {uiState === PREVIEW ? (
      <div>
        <div className="row py-2 mb-3"
             style={{
               "borderBottom": "2px solid #fafafa"
             }}>
          <div className='col d-flex align-self-center'
               style={{"paddingLeft": "30px"}}>
            <a href="#"
               className="btn btn-external mr-2"
               onClick={
                 (e) => {
                   e.preventDefault();
                   updateUIState(SEND);
                 }
               }>
              <i className="far fa-paper-plane"></i>
            </a>
            <a href={"/teams/" + team.id + "/offers/" + offer.id + "/preview.pdf"}
               target="_blank"
               className="btn btn-external">
              <i className="fal fa-arrow-to-bottom"></i>
            </a>
          </div>
          <div className='col-xs-auto d-flex align-self-center'
               style={{
                 "paddingRight": "30px",
                 "fontSize": "24px"
               }}>
            <a href="#"
               className="text-dark mr-2"
               onClick={
                 (e) => {
                   e.preventDefault();
                   onClose();
                 }
               }>
              <i className="fal fa-times"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12"
               style={{'padding': "0 30px"}}>
            <div className="embed-responsive" style={{"minHeight": "500px"}}>
              <Iframe url={"/teams/" + team.id + "/offers/" + offer.id + "/preview"}
                      className="embed-responsive-item" />
            </div>
          </div>
        </div>
      </div>
    ) : null}
    {uiState === SEND ? (
      <div>
        <div className="row py-2 mb-3"
             style={{
               "borderBottom": "2px solid #fafafa"
             }}>
          <div className='col d-flex align-self-center'
               style={{"paddingLeft": "30px"}}>
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();
                   updateUIState(PREVIEW);
                 }
               }
               className="text-muted">
              <i className="far fa-arrow-left mr-2"></i>
              Back to preview
            </a>
          </div>
          <div className='col-xs-auto d-flex align-self-center'
               style={{
                 "paddingRight": "30px",
                 "fontSize": "24px"
               }}>
            <a href="#"
               className="text-dark mr-2"
               onClick={
                 (e) => {
                   e.preventDefault();
                   onClose();
                 }
               }>
              <i className="fal fa-times"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12"
               style={{'padding': "0 30px"}}>
            <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(!canSubmitForm(offer)){
                        return false;
                      }

                      sendOffer(csrfToken, team, offer, onSuccess);
                    }
                  }>
              <div className="form-group">
                <label htmlFor="offer-agent-email" className="text-muted">
                  To
                </label>
                <input type="email"
                       id="offer-agent-email"
                       value={offer.last_sent_to || ""}
                       multiple={true}
                       className={"form-control"}
                       style={{'border': '2px solid #f5f5f5'}}
                       onChange={
                         (e) => {
                           updateOfferTo(e.target.value)
                         }
                       } />
              </div>
              <p className='mb-2'
                 style={{"fontSize": "16px"}}>
                <strong>Customize your message</strong>
              </p>
              <div className="form-group">
                <textarea className="form-control"
                          value={offer.email_body || ""}
                          onChange={
                            (e) => {
                              updateOfferBody(e.target.value);
                            }
                          }
                          style={{'border': '2px solid #f5f5f5'}}
                          rows="7">
                </textarea>
              </div>
              <div className="form-group text-right">
                <a href="#"
                   className="mr-3"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       onClose();
                     }
                   }>
                  Cancel
                </a>
                <button type="submit"
                        disabled={!canSubmitForm(offer)}
                        className="btn btn-primary">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : null}
  </div>
);

OfferV2Preview.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  uiState: PropTypes.string,
  updateUIState: PropTypes.func.isRequired,
  updateOfferTo: PropTypes.func.isRequired,
  updateOfferBody: PropTypes.func.isRequired,
  sendOffer: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
};

export default OfferV2Preview;
