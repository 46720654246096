import React from "react";

import ReactDOM from "react-dom";
import { useBroadcastIndexContext } from "./BroadcastIndexContainer";
import * as axios from 'axios';
import { toastError } from "../../../shared/toastHelper";
import Tippy from '@tippyjs/react';
import Popup from "reactjs-popup";

import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";

const titleStyle = {
  color: "#333333",
  fontSize: 16,
  fontWeight: 600,
};

const selectStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  color: "#333333",
  fontSize: 14,
  height: 40,
  margin: "10px 0",
  padding: 8,
  width: 250,
};

const containerStyle = {
  backgroundColor: "white",
  borderRadius: 10,
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  margin: "10px 0",
  padding: 20,
};

const broadcastModalContentStyle = {
  textAlign: "left",
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  width: "500px",
  padding: "15px"
}

const handleCreateBroadcast = (csrfToken, team, venueOwnership) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  axios.post(`/teams/${team.id}/broadcasts/`, {
    team_id: team.id,
    broadcast: {
      venue_ownership_id: venueOwnership.id
    },
  }).then((response) => {
      window.location.href = `/teams/${team.id}/broadcasts/${response.data.id}/edit`;
  }).catch((error) => {
    toastError("Failed to create a new broadcast");
  });
}

const BroadcastIndex = () => {
  const {
    team,
    csrfToken,
    dataTableProps,
    venueOwnerships,
    venueOwnership,
    setVenueOwnership,
  } = useBroadcastIndexContext();

  const { dataSource, ...defaultTableProps } = dataTableProps;
  const dataSourceForVenueOwnership = `${dataSource}?venue_ownership_id=${venueOwnership.id}`;

  const handleVenueOwnershipChange = (e) => {
    e.preventDefault();

    setVenueOwnership(venueOwnerships[e.target.value])
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <div style={titleStyle}>
            Broadcasts
          </div>
          <select value={venueOwnerships.findIndex(o => o.id === venueOwnership.id)} onChange={handleVenueOwnershipChange} style={selectStyle}>
            {venueOwnerships.map((option, index) => (
              <option key={`${option.venueable.name}-${index}`} value={index}>
                {option.venueable.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => window.location.href = `/teams/${team.id}/broadcasts/new?venue_ownership_id=${venueOwnership.id}`}
          >
            New Broadcast
          </button>
        </div>
      </div>
      <div style={containerStyle}>
        <ContainerlessTableViewApp
          key={venueOwnership.id}
          dataSource={dataSourceForVenueOwnership}
          rowCallback={(row, data) => {
            const mountBroadcastTools = row.getElementsByClassName("broadcast-tools")[0];
            const broadcastId = data['id'];
            const teamId = data['team_id'];

            ReactDOM.render((
              <>
                <Popup
                  trigger={
                    <a href="#">
                      <Tippy content="Duplicate Broadcast">
                        <i className="fas fa-copy"></i>
                      </Tippy>
                    </a>
                  }
                  modal
                  onOpen={e => e.preventDefault()}
                  contentStyle={broadcastModalContentStyle}
                  closeOnDocumentClick
                >
                  {closeDuplicatePopup => (
                    <div>
                      <div className="row">
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <h5 style={{"marginBottom": "16px"}}>
                                <div className="strong">Duplicate Broadcast?</div>
                              </h5>
                            </div>
                            <div className="col-xs-auto">
                              <a href="#"
                                 className="text-dark"
                                 style={{"paddingRight": "15px"}}
                                 onClick={e => {e.preventDefault(); closeDuplicatePopup();}} >
                                <i className="fa fa-times"></i>
                              </a>
                            </div>
                          </div>
                          <p>You're about to duplicate this broadcast.</p>
                          <p>Are you sure?</p>
                          <div className="d-flex align-items-center justify-content-end">
                            <a href="#"
                               onClick={e => {
                                 e.preventDefault();
                                 closeDuplicatePopup();
                               }}>
                              Cancel
                            </a>
                            <form method="post" action={`/teams/${teamId}/broadcasts/${broadcastId}/duplicate`}>
                              <input type="hidden" name="authenticity_token" value={csrfToken} />
                              <button className="btn btn-primary ml-3">
                                Duplicate
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
                <Popup
                  trigger={
                    <a className="ml-3" >
                      <Tippy content="Delete Broadcast">
                        <i className="fas fa-trash text-danger"></i>
                      </Tippy>
                    </a>
                  }
                  modal
                  onOpen={e => e.preventDefault()}
                  contentStyle={broadcastModalContentStyle}
                  closeOnDocumentClick
                >
                  {closeDeletePopup => (
                    <div>
                      <div className="row">
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <h5 style={{"marginBottom": "16px"}}>
                                <div className="strong">Delete Broadcast?</div>
                              </h5>
                            </div>
                            <div className="col-xs-auto">
                              <a href="#"
                                 className="text-dark"
                                 style={{"paddingRight": "15px"}}
                                 onClick={e => {e.preventDefault(); closeDeletePopup();}} >
                                <i className="fa fa-times"></i>
                              </a>
                            </div>
                          </div>
                          <p>You're about to delete this broadcast.</p>
                          <p>This cannot be undone.</p>
                          <p>Are you sure?</p>
                          <div className="d-flex align-items-center justify-content-end">
                            <a href="#"
                               onClick={e => {
                                 e.preventDefault();
                                 closeDeletePopup();
                               }}>
                              Cancel
                            </a>
                            <form method="post" action={`/teams/${teamId}/broadcasts/${broadcastId}`}>
                              <input type="hidden" name="authenticity_token" value={csrfToken} />
                              <input type="hidden" name="_method" value="delete" />
                              <button className="btn btn-danger ml-3">
                                Delete
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              </>
            ), mountBroadcastTools);

          }}
          {...defaultTableProps}
        />
      </div>
    </div>
  );
};

export default BroadcastIndex;
