// Simple example of a React "smart" component

import { connect } from 'react-redux';
import FanDashboard from '../components/FanDashboard';
import * as actions from '../actions/fanDashboardActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  topFans: state.topFans,
  lastMinuteBuyers: state.lastMinuteBuyers,
  topGenres: state.topGenres,
  geoCoordinates: state.geoCoordinates,
  selectedArtist: state.selectedArtist,
  potentialArtistFanData: state.potentialArtistFanData,
  isLoadingPotentialArtistFanData: state.isLoadingPotentialArtistFanData,
  isLoadingGeoCoordinates: state.isLoadingGeoCoordinates,
  isLoadingTopGenres: state.isLoadingTopGenres
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectedArtistChanged: (selectedArtist) => dispatch(actions.selectedArtistChanged(selectedArtist)),
    fetchPotentialArtistFanData: (team, artistId) => dispatch(actions.fetchPotentialArtistFanData(dispatch, team, artistId)),
    fetchGeoCoordinates: (team) => dispatch(actions.fetchGeoCoordinates(dispatch, team)),
    fetchTopGenres: (team) => dispatch(actions.fetchTopGenres(dispatch, team))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(FanDashboard);
