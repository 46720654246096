import { createStore, applyMiddleware } from 'redux';
import agentsTableReducer from '../reducers/agentsTableReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(agentsTableReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
