import React from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/eventDetailsStore';
import EventDetailsContainer from '../containers/EventDetailsContainer';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const EventDetailsApp = (props) => (
  <Provider store={configureStore(props)}>
    <EventDetailsContainer confirmSaved={props.confirmSaved} />
  </Provider>
);

export default EventDetailsApp;
