import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';

const axios = require('axios').default;

const debouncedConfirmFetch = _.debounce((inputValue, callback, team) => {
  new Promise(resolve => {
    axios.get('/teams/' + team.id + '/calendar_events/autocomplete', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var options = response.data.map(function(data){
          return { value: data.id, label: data.name };
        });

        callback(options);
      })
  });
}, 250);

const performerIsDisabled = (offer, performer) => {
  return (
    performer.offer
      && performer.offer.id
      && performer.offer.id !== offer.id
  );
};

const performerIsSelected = (selectedPerformer, performer) => {
  return (selectedPerformer.id === performer.id);
};

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const LinkOfferModal = ({
  team,
  csrfToken,
  currentUser,
  offer,
  performers,
  calendarEvent,
  onClose,
  calendarEventChanged,
  fetchCalendarEventPerformers,
  selectedPerformer,
  selectedPerformerChanged,
  onSuccess,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const canSubmitForm = () => {
    return (
      calendarEvent
        && calendarEvent.value
        && !isSaving
    );
  };

  const submitForm = () => {
    setIsSaving(true);

    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    const performer = offer.primary_performer;
    const artistableIds = performers.map((performer) => performer.artistable.id);

    if (!selectedPerformer.id && performer && !artistableIds.includes(performer.artistable.id)) {
      axios.post("/teams/" + team.id + "/performers", {
        performer: {
          artistable_type: performer.artistable_type,
          artistable_id: performer.artistable.id,
          performable_type: "CalendarEvent",
          performable_id: calendarEvent.value
        }
      })
      .then(({ data }) => {
        axios.patch("/teams/" + team.id + "/offers/" + offer.id, {
          offer: {
            calendar_event_id: calendarEvent.value,
            performer_id: data.id,
          }
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch((error) => {
          onError(error);
          setIsSaving(false);
        });
      })
      .catch((error) => {
        onError(error);
        setIsSaving(false);
      });
    } else {
      axios.patch("/teams/" + team.id + "/offers/" + offer.id, {
        offer: {
          calendar_event_id: calendarEvent.value,
          performer_id: selectedPerformer.id
        }
      })
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
        setIsSaving(false);
      })
    }
  };

  const onError = (error) => {
    toast.error(error.response.data.join(", "), {
      position: toast.POSITION.TOP_CENTER,
      draggable: false,
      closeOnClick: false,
      autoClose: 5000,
      hideProgressBar: true
    });
  };

  return(<div className="offer-modal-container"
       style={{"padding": "30px 40px"}}>
    <div className="row">
      <div className="col">
        <h3 style={{"fontSize": "20px"}}>
          <strong>Link Offer</strong>
        </h3>
      </div>
      <div className="col-xs-auto"
           style={{"paddingRight": "15px"}}>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               onClose();
             }
           }
           style={{"fontSize": "26px"}}
           className="text-dark">
          <i className="fal fa-times"></i>
        </a>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <p className="text-muted"
           style={{"fontSize": "16px"}}>
           Link this offer with an existing event on your calendar.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(!canSubmitForm(calendarEvent)){
                    return false;
                  }

                  submitForm();
                }
              }>
          <div className="row">
            <div className="col-12">
              <AsyncSelect cacheOptions
                           placeholder={"Select event"}
                           isClearable={true}
                           styles={selectStyles}
                           menuPortalTarget={document.querySelector('body')}
                           defaultOptions
                           id="offer-primary-artist"
                           onChange={
                             (option) => {
                               calendarEventChanged(option || {});

                               if(option && option.value){
                                 fetchCalendarEventPerformers(team, option.value);
                               }
                             }
                           }
                           value={Object.keys(calendarEvent).length > 0 ? calendarEvent : ""}
                           loadOptions={(inputValue, callback) => debouncedConfirmFetch(inputValue, callback, team)} />
            </div>
            <div className="col-12 mt-3">
              {performers.length > 0 ? (
                <label className="text-muted mb-0">
                  CHOOSE AN ARTIST
                </label>
              ) : null}
              {performers.map((performer, index) => (
                <div key={index}
                     onClick={
                       (e) => {
                         e.preventDefault();

                         if(!performerIsDisabled(offer, performer) && !performerIsSelected(selectedPerformer, performer)){
                           selectedPerformerChanged(performer);
                         }
                       }
                     }
                     className={"col-12 p-2 mt-2 selectable-card " + (performerIsSelected(selectedPerformer, performer) ? "selected " : "") + (performerIsDisabled(offer, performer) ? "disabled " : "")}>
                  <div className="row">
                    <div className="col-xs-auto d-flex" style={{'paddingLeft': '20px'}}>
                      <div className="custom-control custom-radio align-self-center">
                        <input type="radio"
                               checked={performerIsSelected(selectedPerformer, performer)}
                               onChange={() => {}}
                               disabled={performerIsDisabled(offer, performer)}
                               id={"partnership-" + index}
                               name={"offer-partnership"}
                               className="custom-control-input" />
                        <label className="custom-control-label"
                               htmlFor={"partnership-" + index}>
                          &nbsp;
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-auto" style={{'paddingLeft': '15px'}}>
                      <img src={performer.artistable.image_thumb_url_for_json}
                           className="img-fluid rounded-circle mr-2 less-opacity-when-disabled"
                           style={{"height": "60px", "width": "60px"}} />
                    </div>
                    <div className="col less-opacity-when-disabled">
                      <h5 className="mb-0">
                        <strong>
                          {performer.artistable.name}
                        </strong>
                      </h5>
                      {performer.artistable && performer.artistable.primary_agent ? (
                        <span>
                          <p className='mb-0 small'>{performer.artistable.primary_agent.name}</p>
                          <p className='mb-0 text-muted small'>{performer.artistable.primary_agent.email}</p>
                        </span>
                      ) : (
                        <span>
                          <p className='mb-0 text-muted small'>Agent info needed.</p>
                        </span>
                      )}
                    </div>
                    {performerIsDisabled(offer, performer) ? (
                      <div className="col-xs-auto d-flex" style={{'paddingRight': '15px'}}>
                        <div className="align-self-center">
                          <span className="badge badge-secondary p-2" style={{"fontSize": "12px"}}>
                            <strong>Offer Exists</strong>
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {performerIsSelected(selectedPerformer, performer) ? (
                      <div className="col-xs-auto" style={{'paddingRight': '15px'}}>
                        <a href='#'
                           className='mt-3 d-inline-block'
                           onClick={
                             (e) => {
                               e.preventDefault();
                               selectedPerformerChanged({});
                             }
                           }
                           style={{'color': '#1982C4'}}
                           >
                          <i className="fa fa-times-circle"></i>
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row small" style={{"marginTop": "30px"}}>
            <div className="col-12 text-right">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     onClose();
                   }
                 }>
                <strong>Cancel</strong>
              </a>
              <input type="submit"
                     style={{"borderRadius": "8px"}}
                     className="btn btn-primary btn-sm ml-3 strong"
                     disabled={!canSubmitForm(calendarEvent)}
                     value="Save" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>);
};

LinkOfferModal.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  offer: PropTypes.object,
  performers: PropTypes.array,
  calendarEvent: PropTypes.object,
  onClose: PropTypes.func,
  calendarEventChanged: PropTypes.func.isRequired,
  fetchCalendarEventPerformers: PropTypes.func.isRequired,
  selectedPerformer: PropTypes.object,
  selectedPerformerChanged: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default LinkOfferModal;
