import PropTypes from 'prop-types';
import React from 'react';

import Iframe from 'react-iframe'
import SlidingPane from "react-sliding-pane";

import ShowHideContent from '../../ShowHideContent/components/ShowHideContent';
import PipelineEvent from '../../PipelineEvent/components/PipelineEvent';

const PipelineEventsSlidingPane = ({
  currentUser,
  team,
  csrfToken,
  artist,
  pipelineEvents,
  onClose
}) => (
  <div>
    <SlidingPane
      isOpen={Object.keys(pipelineEvents).length > 0}
      className="sliding-pane-container"
      width={"480px"}
      hideHeader={true}
      onRequestClose={() => {
        onClose();
      }}
    >
      <div className="row">
        <div className="col-12">
          <div className="row mx-0 py-3"
               style={{"borderBottom": "2px solid #f5f5f5"}}>
            <div className="col">
              <strong style={{"fontSize": "24px"}}>
                Activity
              </strong>
            </div>
            <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
              <a href="#"
                 style={{"fontSize": "24px"}}
                 className="text-dark"
                 onClick={(e) => {
                   e.preventDefault();
                   onClose();
                 }} >
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row py-4" style={{"margin": "0px 0px 0px 7px"}}>
            <div className="col-12">
              {(pipelineEvents).map((pipelineEvent, index) => (
                <PipelineEvent key={index}
                               index={index}
                               pipelineEvents={pipelineEvents}
                               pipelineEvent={pipelineEvent}
                               team={team} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </SlidingPane>
  </div>
);

PipelineEventsSlidingPane.propTypes = {
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  artist: PropTypes.object.isRequired,
  pipelineEvents: PropTypes.array,
  onClose: PropTypes.func
};

export default PipelineEventsSlidingPane;
