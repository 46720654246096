import { combineReducers } from 'redux';
import {
  USER_CHANGED,
  FILE_EL_CHANGED,
  ORIGINAL_USER_CHANGED
} from '../constants/userSettingsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case USER_CHANGED:
      return action.user;
    default:
      return state;
  }
};

const fileEl = (state = {}, action) => {
  switch (action.type) {
    case FILE_EL_CHANGED:
      return action.fileEl;
    default:
      return state;
  }
};

const originalUser = (state = {}, action) => {
  switch (action.type) {
    case ORIGINAL_USER_CHANGED:
      return action.originalUser;
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const myMobileApps = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isVenueManager = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const availableTimezones = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const userSettingsReducer = combineReducers({
  csrfToken,
  user,
  fileEl,
  originalUser,
  team,
  myMobileApps,
  isVenueManager,
  availableTimezones,
});

export default userSettingsReducer;
