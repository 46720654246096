import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";

import PageSectionMenu from './PageSectionMenu';
import OfferV2SettlementApp from '../../OfferV2Settlement/startup/OfferV2SettlementApp';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "0px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "250px",
  "padding": "0px"
}

const ManageSettlements = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  offers,
  offerForSettlement,
  offerForSettlementChanged,
  confirmPageSection,
  fetchPermissionSet,
  updateConfirmPageSection,
  offerChanged,
  fetchOfferTotalDue
}) => {
  useEffect(() => {
    // ...
  }, [])

  return (
    <div>
      <div className="card-body">
        <div className="row pb-3">
          <div className="col">
            <h5 className="strong">
              Settlements
              {confirmPageSection.permission_set_id ? (
                <i className="ml-2 far fa-lock small text-danger"></i>
              ) : null}
            </h5>
          </div>
          <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
            <PageSectionMenu csrfToken={csrfToken}
                             team={team}
                             confirm={confirm}
                             currentUser={currentUser}
                             confirmPageSection={confirmPageSection}
                             fetchPermissionSet={fetchPermissionSet}
                             updateConfirmPageSection={updateConfirmPageSection} />
          </div>
        </div>
        <div className="form-row">
          {offers.sort((a, b) => {return new Date(a.created_at) - new Date(b.created_at)}).map((offer, index) => (
            <div key={index} className="col-12 col-lg-6">
              <div className="card no-border mb-0 pb-2">
                <div className="card-body rounded" style={{'background': "#f5f5f5"}}>
                  <div className="row">
                    <div className="col">
                      <div className="text-muted small">SETTLEMENT FOR</div>
                      <div className="strong text-overflow-ellipsis"
                            title={offer.artist_or_event_name}
                            style={{"maxWidth": "70%"}}>
                        {offer.artist_or_event_name}
                      </div>
                    </div>
                    <div className="col-xs-auto" style={
                      {
                        "position": "absolute",
                        "right": "15px"
                      }
                    }>
                      {currentUser.can_manage_offers ? (
                        <Popup arrow={false}
                                offsetY={5}
                                position="bottom right"
                                contentStyle={popupContentStyle}
                                onOpen={
                                  (e) => {
                                    e.preventDefault();
                                  }
                                }
                                trigger={open => (
                                  <a href="#" className="pl-3">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </a>
                                )} >
                          {close => (
                            <div className='row text-left'>
                              <div className="col-12">
                                <ul className="list-group offer-inline-menu">
                                  <li className="list-group-item">
                                    <a href={"/teams/" + team.id + "/offers/" + offer.id + "/settlement.pdf"}
                                        target="_blank"
                                        className="text-muted">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-download"></i>
                                      </span>
                                      <span className="text-dark">
                                        Download PDF
                                      </span>
                                    </a>
                                  </li>
                                  <li className="list-group-item">
                                    <a href={`/teams/${team.id}/offers/${offer.id}/settlement`}
                                        className="text-muted">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-pencil"></i>
                                      </span>
                                      <span className="text-dark">
                                        Edit
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </Popup>
                      ) : null}
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-12">
                      <div className="text-muted small">TOTAL DUE</div>
                      <div>
                        {offer.total_due ? (
                          formatter.format(offer.total_due)
                        ) : (
                          <span className="text-muted">--</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Popup
        open={Object.keys(offerForSettlement).length > 0}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={false}
        className={"offer-modal"}
        onClose={() => {
          offerForSettlementChanged({});
        }}
      >
        <OfferV2SettlementApp offer={offerForSettlement}
                              currentUser={currentUser}
                              csrfToken={csrfToken}
                              onClose={
                                () => {
                                  var updated = Object.assign({}, offerForSettlement, {
                                    total_due: undefined
                                  });

                                  offerChanged(updated);
                                  fetchOfferTotalDue(team, updated);
                                  offerForSettlementChanged({});
                                }
                              }
                              team={team} />
      </Popup>
    </div>
  );
};

ManageSettlements.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  offers: PropTypes.array,
  offerForSettlement: PropTypes.object,
  offerForSettlementChanged: PropTypes.func.isRequired,
  confirmPageSection: PropTypes.object,
  fetchPermissionSet: PropTypes.func.isRequired,
  updateConfirmPageSection: PropTypes.func.isRequired,
  offerChanged: PropTypes.func.isRequired,
  fetchOfferTotalDue: PropTypes.func.isRequired
};

export default ManageSettlements;
