// Simple example of a React "smart" component

import { connect } from 'react-redux';
import DataTableWithSidebarView from '../components/DataTableWithSidebarView';
import * as actions from '../actions/dataTableWithSidebarViewActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  browser: state.browser,
  title: state.title,
  dataSource: state.dataSource,
  order: state.order,
  columns: state.columns,
  eventPermalink: state.eventPermalink,
  event: state.event,
  displayShowMore: state.displayShowMore,
  isEventViewSliding: state.isEventViewSliding,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEventData: (state) => dispatch(actions.fetchEventData(dispatch, state)),
    closeEventModal: () => dispatch(actions.updateEvent({})),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(DataTableWithSidebarView);
