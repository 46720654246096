import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Popup from "reactjs-popup";

class ToolTip extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      children: this.props.children,
      content: this.props.content,
      contentStyle: this.props.contentStyle,
      arrowStyle: this.props.arrowStyle,
      position: this.props.position
    };
  }

  render() {
    return (
      <Popup
          trigger={
            <div className="d-inline"
                 dangerouslySetInnerHTML={{__html: this.state.children}}>
            </div>
          }
          contentStyle={this.state.contentStyle}
          arrowStyle={this.state.arrowStyle}
          position={this.state.position}
          on="hover"
        >
        <div className="d-inline"
             dangerouslySetInnerHTML={{__html: this.state.content}}>
        </div>
      </Popup>
    );
  }
}

ToolTip.defaultProps = {
  contentStyle: {
    "border": "none",
    "borderRadius": "8px",
    "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
    "marginTop": "5px"
  },
  arrowStyle: {},
  position: "bottom center"
};

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
  contentStyle: PropTypes.object,
  arrowStyle: PropTypes.object
};

export default ToolTip;
