import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

import $ from 'jquery';
require('datatables.net-bs4');

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const ExportCSV = ({ csvDataSource, dataTableEl, dataTableFilters }) => (
  <Popup arrow={false}
        offsetY={7}
        position="bottom right"
        contentStyle={popupContentStyle}
        onOpen={
          (e) => {
            e.preventDefault();
          }
        }
        trigger={open => (
          <a href="#"
             className="btn btn-external btn-sm mr-3"
             style={{"borderRadius": "8px", "paddingLeft": "11px", "paddingRight": "11px"}}>
            <i className="fas fa-ellipsis-v small"></i>
          </a>
        )} >
   {close => (
     <div className='row text-left'>
       <div className="col-12">
         <ul className="list-group offer-inline-menu highlight-primary">
           <li className="list-group-item">
             <a onClick={
                  (e) => {
                    e.preventDefault();

                    var dataTable = $(dataTableEl).DataTable();
                    var params = Object.assign({},
                      dataTable.ajax.params(),
                      dataTableFilters
                    );

                    var urlWithParams = (csvDataSource + '?' + $.param(params));
                    window.location.href = urlWithParams;
                  }
                }
                className="text-muted"
                href="#">
               <span className="d-inline-block text-center" style={{width: "28px"}}>
                 <i className="fas fa-download"></i>
               </span>
               Export CSV
             </a>
           </li>
         </ul>
       </div>
     </div>
   )}
  </Popup>
);

ExportCSV.propTypes = {
  csvDataSource: PropTypes.string.isRequired,
  dataTableEl: PropTypes.object,
  dataTableFilters: PropTypes.object
};

export default ExportCSV;
