import React, { useEffect, useState } from "react";
import { useBroadcastEditContext } from "./BroadcastEditContainer";
import BroadcastSidebar from "./BroadcastSidebar";

import Tippy from "@tippyjs/react";
import Popup from "reactjs-popup";

const modalContentStyle = {
  textAlign: "left",
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  width: "840px",
  height: "600px",
  padding: "0px"
}

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const defaultTabStyle = {
  color: "#000",
  borderBottom: "2px solid #ccc"
};

const activeTabStyle = {
  color: "#1982C4",
  borderBottom: "2px solid #1982C4"
};

const overlayContainerStyle = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  zIndex: "1",
}

const overlayStyle = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  width: "100%",
  height: "100%",
  background: "#fff",
  opacity: "0.8",
  zIndex: 1,
}

const Tab = ({
  text,
  templateTab,
  setTemplateTab,
}) => {
  return(
    <a
      href="#"
      className="px-4 py-3 text-decoration-none"
      style={templateTab === text ? activeTabStyle : defaultTabStyle}
      onClick={e => {
        e.preventDefault();
        setTemplateTab(text);
      }}
    >
      <strong>
        {text}
      </strong>
    </a>
  );
};

const ContentTemplate = ({
  current = false,
  name,
  img,
  previewable = true,
  comingSoon = false,
  template = null,
}) => {
  const {
    team,
    broadcast,
    updateBroadcast,
    setPreviewTemplate,
  } = useBroadcastEditContext();

  const [hovering, setHovering] = useState(false);

  return(
    <div className="mt-4 mr-3"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div
        className="rounded shadow"
        style={{
          position: "relative",
          border: hovering ? "2px solid #1a82c4" : "2px solid #e6e6e6",
        }}
      >
        <div
          className="flex-column justify-content-center align-items-center"
          style={{
            ...overlayContainerStyle,
            display: hovering ? "flex" : "none",
          }}
        >
          <div className="rounded" style={overlayStyle}></div>
          {comingSoon ? (
            <div
              style={{
                fontSize: "14px",
                zIndex: 2,
              }}
            >
              <strong>
                Coming soon!
              </strong>
            </div>
          ) : (
            <>
              {current ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{zIndex: 2}}
                  onClick={() => {
                    window.location.href = `/teams/${team.id}/broadcasts/${broadcast.id}/edit?tab=content`
                  }}
                >
                  Edit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{zIndex: 2}}
                  onClick={() => {
                    const params = {
                      broadcast: {
                        change_template: template ? template.templatable.id : null
                      }
                    }

                    updateBroadcast(params, false, () => {
                      window.location.href = `/teams/${team.id}/broadcasts/${broadcast.id}/edit?tab=content`
                    })
                  }}
                >
                  Select
                </button>
              )}
              {previewable ? (
                <a
                  href="#"
                  className="text-decoration-none"
                  style={{...backButtonStyle, zIndex: 2}}
                  onClick={e => {
                    e.preventDefault();
                    setPreviewTemplate(template);
                  }}
                >
                  Preview
                </a>
              ) : null}
            </>
          )}
        </div>
        <div style={{ position: "relative", zIndex: 0 }}>
          {img ? (
            <img
              src={img}
              alt={name}
              className="m-3"
              style={{
                width: "174px",
                height: "167px",
                border: "1px solid #e6e6e6",
                position: "relative",
              }}
            />
          ) : (
            <div
              className="m-3"
              style={{
                width: "174px",
                height: "167px",
                border: "1px solid #e6e6e6",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <iframe
                srcDoc={template.template_html}
                frameBorder="0"
                style={{
                  width: "560px",
                  height: "560px",
                  overflow: "hidden",
                  border: "none",
                  transform: "scale(0.315)",
                  transformOrigin: "0 0",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              ></iframe>
            </div>
          )}
          <hr className="m-0" style={{ borderColor: "#e6e6e6" }} />
          <p className="m-0 p-3">
            {current ? "Current" : name}
          </p>
        </div>
      </div>
    </div>
  );
};

const ContentSelectTab = () => {
  const {
    tab,
    setTab,
    broadcast,
    beefreeToken,
    beefreeUid,
    beefreeTemplate,
    template,
    setTemplate,
    subject,
    updateBroadcast,
    setSubject,
    sendDomain,
    selectedSendDomain,
    setSelectedSendDomain,
    previousBroadcastTemplates,
    fetchPreviousBroadcastTemplates,
    previewTemplate,
    setPreviewTemplate,
  } = useBroadcastEditContext();

  const [templateTab, setTemplateTab] = useState("Opendate Template");

  useEffect(() => fetchPreviousBroadcastTemplates(), []);

  return (
    <BroadcastSidebar>
      <div className="mt-3 ml-5">
        <h3 style={{ fontSize: "20px" }}>
          <strong>
            Content
          </strong>
        </h3>
        <p className="">Design the content of your email.</p>
        {broadcast.beefree_template.template ? (
          <div className="mb-3 d-flex flex-wrap">
            <ContentTemplate
              current={true}
              previewable={false}
              key={broadcast.beefree_template.id}
              name={broadcast.name}
              template={broadcast.beefree_template}
            />
          </div>
        ) : null}
        <div className="d-flex">
          <Tab text="Opendate Template" templateTab={templateTab} setTemplateTab={setTemplateTab} />
          <Tab text="Previous Broadcasts" templateTab={templateTab} setTemplateTab={setTemplateTab} />
        </div>
        <div className="d-flex flex-wrap">
          {templateTab === "Opendate Template" ? (
            <>
              <ContentTemplate name="Start from Scratch" img="/scratch.jpg" previewable={false} />
              <ContentTemplate name="Minimal" img="/minimal.jpg" comingSoon={true} />
              <ContentTemplate name="Natural" img="/natural.jpg" comingSoon={true} />
              <ContentTemplate name="Simple" img="/simple.jpg" comingSoon={true} />
            </>
          ) : templateTab === "Previous Broadcasts" ? (
            previousBroadcastTemplates
              .filter(template => template.templatable.id !== broadcast.id)
              .map(template => (
                <ContentTemplate
                  key={template.id}
                  name={template.templatable.name}
                  template={template}
                />
              ))
          ) : null}
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "250px" }}
        >
          <a
            href="#"
            className="text-decoration-none"
            style={backButtonStyle}
            onClick={e => {
              e.preventDefault();
              setTab("from")
            }}
          >
            Back
          </a>
          <button
            type="button"
            className="ml-2 btn btn-primary"
            onClick={e => {
              e.preventDefault();
              setTab("review-and-schedule");
            }}
          >
            Next
          </button>
        </div>
      </div>
      <Popup
        open={previewTemplate !== null}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={true}
        className={"popup-modal"}
        onClose={() => setPreviewTemplate(null)}
      >
        {close => (
          previewTemplate ? (
            <div className="w-100 h-100 p-4 d-flex flex-column">
              <div className="d-flex justify-content-between align-items-start">
                <h4>
                  <strong>
                    {previewTemplate.templatable.name}
                  </strong>
                </h4>
                <button
                  type="button"
                  className="border-0 bg-transparent text-muted"
                  onClick={() => setPreviewTemplate(null)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <iframe
                className="mt-2 flex-grow-1"
                srcDoc={previewTemplate.template_html}
                frameBorder="0"
                style={{
                  width: "100%",
                  height: "max-content",
                  overflow: "hidden",
                  border: "none",
                }}
              ></iframe>
            </div>
          ) : null
        )}
      </Popup>
    </BroadcastSidebar>
  );
};

export default ContentSelectTab;
