import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import ManageMarketingBudgetItems from './ManageMarketingBudgetItems';
import PageSectionMenu from './PageSectionMenu';
import SocialPostStatusBars from '../../../bundles/SocialPostStatusBars/components/SocialPostStatusBars';
import OnepostComposer from '../../../bundles/OnepostComposer/components/OnepostComposer';
import SocialAlert from './SocialAlert';

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "190px",
  "padding": "0px"
}

const imageThumbContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "105px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "430px",
  "padding": "15px"
}

const addSocialModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "470px",
  "padding": "15px"
}

const composePostModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "880px",
  "padding": "0px",
  "overflow": "hidden"
}

const canSubmitForm = (customerIOEmailPurpose, customerIOEmailTemplate) => {
  return (
    customerIOEmailPurpose
      && customerIOEmailPurpose.length > 0
      && customerIOEmailTemplate
      && customerIOEmailTemplate.length > 0
  );
}

const canSeeEmailSection = (team, confirm) => {
  return (
    team
      && team.id === 2
      && confirm.eventbrite_event_id
  );
}

const hasScheduledPosts = (posts) => {
  return posts.some((post) => !post.human_published_at);
}

const hasPublishedPosts = (posts) => {
  return posts.some((post) => post.human_published_at);
}

const disablePostEditing = (post) => {
  return (
    post.has_retrying_facebook_social_posts
      || post.has_retrying_twitter_social_posts
      || post.has_retrying_instagram_social_posts
  );
}

const MarketingSection = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  sendingCustomerIOEmail,
  sendingCustomerIOEmailChanged,
  customerIOEmailPurpose,
  customerIOEmailPurposeChanged,
  customerIOEmailTemplate,
  customerIOEmailTemplateChanged,
  createCustomerIOEmail,
  marketingSectionActiveTab,
  marketingSectionActiveTabChanged,
  authorizedPages,
  addingNewSocialAccount,
  addingNewSocialAccountChanged,
  onepostPublicKey,
  twitterProviderId,
  facebookProviderId,
  composingPost,
  composingPostChanged,
  createPost,
  posts,
  deletePost,
  postToEdit,
  postToEditChanged,
  updatePost,
  confirmPageSection,
  fetchPermissionSet,
  updateConfirmPageSection,
  socialMarketingBudgetItems,
  traditionalMarketingBudgetItems,
  addSocialMarketingBudgetItem,
  addTraditionalMarketingBudgetItem,
  createMarketingBudgetItem,
  destroyMarketingBudgetItem,
  editMarketingBudgetItem,
  updateMarketingBudgetItem,
  marketingBudgetItemToEdit,
  marketingBudgetItemToEditChanged
}) => (
  <div>
    <div className="card-body">
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <h5 className="strong">
                Marketing
                {confirmPageSection.permission_set_id ? (
                  <i className="ml-2 far fa-lock small text-danger"></i>
                ) : null}
              </h5>
            </div>
            {authorizedPages.length > 0 && marketingSectionActiveTab === "social" && currentUser.can_manage_posts ? (
              <div className="col-xs-auto position-absolute"
                   style={{
                     "paddingRight": "15px",
                     "right": "0px",
                     "top": "0px"
                   }}>
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       composingPostChanged(true);
                     }
                   }
                   style={{borderRadius: '8px'}}
                   className="btn btn-external btn-sm">
                  <strong>New Post</strong>
                </a>
                <PageSectionMenu csrfToken={csrfToken}
                                 team={team}
                                 confirm={confirm}
                                 currentUser={currentUser}
                                 confirmPageSection={confirmPageSection}
                                 fetchPermissionSet={fetchPermissionSet}
                                 updateConfirmPageSection={updateConfirmPageSection} />
              </div>
            ) : null }
            {marketingSectionActiveTab === "email" && currentUser.can_manage_customer_io_emails ? (
              <div className="col-xs-auto position-absolute"
                   style={{
                     "paddingRight": "15px",
                     "right": "0px",
                     "top": "0px"
                   }}>
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       sendingCustomerIOEmailChanged(true);
                     }
                   }
                   style={{borderRadius: '8px'}}
                   className="btn btn-external btn-sm">
                  <strong>New Email</strong>
                </a>
                <PageSectionMenu csrfToken={csrfToken}
                                 team={team}
                                 confirm={confirm}
                                 currentUser={currentUser}
                                 confirmPageSection={confirmPageSection}
                                 fetchPermissionSet={fetchPermissionSet}
                                 updateConfirmPageSection={updateConfirmPageSection} />
              </div>
            ) : null }
          </div>
          <ul className="nav nav-tabs small"
              style={{"borderBottom": "2px solid #f5f5f5"}}>
            <li className="nav-item">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     marketingSectionActiveTabChanged("social");
                   }
                 }
                 className={"nav-link px-4 " + (marketingSectionActiveTab === "social" ? "active" : "")}>
                <strong>Social - Organic</strong>
              </a>
            </li>
            {canSeeEmailSection(team, confirm) ? (
              <li className="nav-item">
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       marketingSectionActiveTabChanged("email");
                     }
                   }
                   className={"nav-link px-4 " + (marketingSectionActiveTab === "email" ? "active" : "")}>
                  <strong>Email</strong>
                </a>
              </li>
            ) : null}
            <li className="nav-item">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     marketingSectionActiveTabChanged("social_ads");
                   }
                 }
                 className={"nav-link px-4 " + (marketingSectionActiveTab === "social_ads" ? "active" : "")}>
                <strong>Social - Ads</strong>
              </a>
            </li>
            <li className="nav-item">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     marketingSectionActiveTabChanged("tv_radio_print");
                   }
                 }
                 className={"nav-link px-4 " + (marketingSectionActiveTab === "tv_radio_print" ? "active" : "")}>
                <strong>TV / Radio / Print</strong>
              </a>
            </li>
          </ul>
          {marketingSectionActiveTab === "social" ? (
            <div>
              {authorizedPages.length === 0 ? (
                <div className="card no-border mt-4 mb-0" style={{'background': '#f4f9fc'}}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-4 order-md-2 justify-content-start justify-content-md-center d-flex">
                        <i className="far fa-share-alt align-self-center"
                           style={{"fontSize": "120px", "color": "rgba(25, 130, 196, .5)"}}></i>
                      </div>
                      <div className="col-12 col-md-6 col-lg-8 order-md-1">
                        <h5>Create and schedule social posts right from Opendate</h5>
                        <p className="text-muted">
                          Compose once, schedule, and post to Facebook, Twitter, and Instagram with just a few clicks.
                        </p>
                        {currentUser.can_manage_authorized_pages ? (
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 addingNewSocialAccountChanged(true);
                               }
                             }
                             className="btn btn-primary">
                            <strong>Get Started</strong>
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                posts.length === 0 ? (
                  <div className="row mt-5">
                    <div className="col-12 text-center">
                      <h5>
                        No posts for this event yet
                      </h5>
                      <p className="text-muted">
                        Create a post and promote this event
                      </p>
                      {currentUser.can_manage_posts ? (
                        <a href="#"
                           onClick={
                             (e) => {
                               e.preventDefault();
                               composingPostChanged(true);
                             }
                           }
                           style={{"borderRadius": "8px"}}
                           className="btn btn-primary btn-sm">
                          New Post
                        </a>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {hasScheduledPosts(posts) ? (
                      <div className="col-12 mt-4">
                        <p className="mb-2 strong"
                           style={{"color": "#a1a1a1"}}>
                          SCHEDULED
                        </p>
                        {posts.filter((p) => !p.human_published_at).map((post, index) =>
                          <div key={index} className="card border-0 shadow-1">
                            <div className="card-body p-3">
                              <div className="row">
                                <div className="col-xs-auto"
                                     style={{"paddingLeft": "15px"}}>
                                  <ul className="no-bullets p-0">
                                    {post.has_facebook_social_posts ? (
                                      <li className="mt-1 position-relative">
                                        {post.has_retrying_facebook_social_posts ? (
                                          <SocialAlert socialNetwork="Facebook" />
                                        ) : null}
                                        <span className="social-account-icon facebook d-flex justify-content-center">
                                          <i className="fab fa-facebook-f extra-small align-self-center" style={{"marginTop": "2px"}}></i>
                                        </span>
                                      </li>
                                    ) : null}
                                    {post.has_twitter_social_posts ? (
                                      <li className="mt-1 position-relative">
                                        {post.has_retrying_twitter_social_posts ? (
                                          <SocialAlert socialNetwork="Twitter" />
                                        ) : null}
                                        <span className="social-account-icon twitter d-flex justify-content-center">
                                          <i className="fab fa-twitter extra-small align-self-center" style={{"marginTop": "2px"}}></i>
                                        </span>
                                      </li>
                                    ) : null}
                                    {post.has_instagram_social_posts ? (
                                      <li className="mt-1 position-relative">
                                        {post.has_retrying_instagram_social_posts ? (
                                          <SocialAlert socialNetwork="Instagram" />
                                        ) : null}
                                        <span className="social-account-icon instagram d-flex justify-content-center">
                                          <i className="fab fa-instagram extra-small align-self-center" style={{"marginTop": "2px"}}></i>
                                        </span>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                                <div className="col">
                                  <div dangerouslySetInnerHTML={{__html: post.simple_formatted_body}}></div>
                                  <p className="mb-0">
                                    <span className="strong"
                                          style={{"color": "#a1a1a1"}}>
                                      SCHEDULED
                                    </span>
                                    &nbsp;
                                    {post.human_scheduled_at}
                                  </p>
                                </div>
                                {post.image_thumb_url ? (
                                  <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
                                    <div className="cover-img"
                                         style={{
                                           "width": "95px",
                                           "height": "55px",
                                           "backgroundImage": "url(" + post.image_thumb_url + ")"
                                         }}>
                                    </div>
                                  </div>
                                ) : null}
                                {currentUser.can_manage_posts ? (
                                  <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
                                    <Popup arrow={false}
                                           offsetY={5}
                                           position="bottom right"
                                           contentStyle={popupContentStyle}
                                           trigger={open => (
                                             <button className="btn btn-external btn-sm"
                                                     style={{"borderRadius": "8px"}}>
                                               <i className="fas fa-ellipsis-v"></i>
                                             </button>
                                           )} >
                                      {close => (
                                        <div className='row text-left'>
                                          <div className="col-12">
                                            <ul className="list-group offer-inline-menu highlight-primary">
                                              {!disablePostEditing(post) ? (
                                                <li className="list-group-item">
                                                  <a href="#"
                                                     onClick={
                                                       (e) => {
                                                         e.preventDefault();
                                                         postToEditChanged(post);
                                                       }
                                                     }
                                                     className="text-muted">
                                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                      <i className="fas fa-pencil"></i>
                                                    </span>
                                                    Edit post details
                                                  </a>
                                                </li>
                                              ) : null}
                                              <li className="list-group-item delete-offer-li">
                                                <Popup
                                                    trigger={
                                                      <a href="#" className="text-danger">
                                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                          <i className="fas fa-trash"></i>
                                                        </span>
                                                        Remove
                                                      </a>
                                                    }
                                                    modal
                                                    onOpen={
                                                      (e) => {
                                                        e.preventDefault();
                                                      }
                                                    }
                                                    contentStyle={deleteModalContentStyle}
                                                    closeOnDocumentClick
                                                  >
                                                  {closeDeletePopup => (
                                                    <div>
                                                      <div className="row">
                                                        <div className="col">
                                                          <div className="row">
                                                            <div className="col">
                                                              <h5 style={{"marginBottom": "16px"}}>
                                                                <div className="strong">Remove post?</div>
                                                              </h5>
                                                            </div>
                                                            <div className="col-xs-auto">
                                                              <a href="#"
                                                                 className="text-dark"
                                                                 style={{"paddingRight": "15px"}}
                                                                 onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                                <i className="fa fa-times"></i>
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <p>You're about to remove this post.</p>
                                                          <p>This cannot be undone.</p>
                                                          <p>Are you sure?</p>
                                                          <div className="text-right">
                                                            <a href="#"
                                                               onClick={
                                                                 (e) => {
                                                                   e.preventDefault();
                                                                   closeDeletePopup();
                                                                 }
                                                               }>
                                                              Cancel
                                                            </a>
                                                            <a href="#"
                                                               className="btn btn-danger ml-3"
                                                               onClick={
                                                                 (e) => {
                                                                   e.preventDefault();
                                                                   closeDeletePopup();
                                                                   close();
                                                                   deletePost(csrfToken, team, post);
                                                                 }
                                                               }>
                                                              Remove
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Popup>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>

                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {hasPublishedPosts(posts) ? (
                      <div className="col-12 mt-4">
                        <p className="mb-2 strong"
                           style={{"color": "#a1a1a1"}}>
                          POSTED
                        </p>
                        <table className="table table-sm table-borderless vertical-middle mb-0"
                               style={{tableLayout: "fixed"}}>
                          <colgroup>
                            <col span={1} style={{"width": "30%"}} />
                            <col span={1} style={{"width": "57%"}} />
                            <col span={1} style={{"width": "10%"}} />
                            <col span={1} style={{"width": "3%"}} />
                          </colgroup>
                          <tbody>
                            {posts.filter((p) => p.human_published_at).map((post, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    {post.human_published_at}
                                  </td>
                                  <td className="overflow-ellipsis">
                                    <span title={post.body}>
                                      {post.body}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    {post.image_thumb_url ? (
                                      <Popup arrow={false}
                                             offsetX={5}
                                             position="left top"
                                             contentStyle={imageThumbContentStyle}
                                             on={"hover"}
                                             trigger={open => (
                                               <i className="far fa-image text-primary"></i>
                                             )} >
                                        <div className="cover-img"
                                             style={{
                                               "width": "105px",
                                               "height": "105px",
                                               "borderRadius": "8px",
                                               "backgroundImage": "url(" + post.image_thumb_url + ")"
                                             }}>
                                        </div>
                                      </Popup>
                                    ) : null}
                                  </td>
                                  <td className="text-right">
                                    {currentUser.can_manage_posts ? (
                                      <Popup arrow={false}
                                             offsetY={5}
                                             position="bottom right"
                                             contentStyle={popupContentStyle}
                                             onOpen={
                                               (e) => {
                                                 e.preventDefault();
                                               }
                                             }
                                             trigger={open => (
                                               <a href="#"
                                                  className="text-muted">
                                                 <i className="fas fa-ellipsis-v"></i>
                                               </a>
                                             )} >
                                        {close => (
                                          <div className='row text-left'>
                                            <div className="col-12">
                                              <ul className="list-group offer-inline-menu highlight-primary">
                                                <li className="list-group-item delete-offer-li">
                                                  <Popup
                                                      trigger={
                                                        <a href="#" className="text-danger">
                                                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                            <i className="fas fa-trash"></i>
                                                          </span>
                                                          Remove
                                                        </a>
                                                      }
                                                      modal
                                                      onOpen={
                                                        (e) => {
                                                          e.preventDefault();
                                                        }
                                                      }
                                                      contentStyle={deleteModalContentStyle}
                                                      closeOnDocumentClick
                                                    >
                                                    {closeDeletePopup => (
                                                      <div>
                                                        <div className="row">
                                                          <div className="col">
                                                            <div className="row">
                                                              <div className="col">
                                                                <h5 style={{"marginBottom": "16px"}}>
                                                                  <div className="strong">Remove post?</div>
                                                                </h5>
                                                              </div>
                                                              <div className="col-xs-auto">
                                                                <a href="#"
                                                                   className="text-dark"
                                                                   style={{"paddingRight": "15px"}}
                                                                   onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                                  <i className="fa fa-times"></i>
                                                                </a>
                                                              </div>
                                                            </div>
                                                            <p>You're about to remove this post.</p>
                                                            <p>This cannot be undone.</p>
                                                            <p>Are you sure?</p>
                                                            <div className="text-right">
                                                              <a href="#"
                                                                 onClick={
                                                                   (e) => {
                                                                     e.preventDefault();
                                                                     closeDeletePopup();
                                                                   }
                                                                 }>
                                                                Cancel
                                                              </a>
                                                              <a href="#"
                                                                 className="btn btn-danger ml-3"
                                                                 onClick={
                                                                   (e) => {
                                                                     e.preventDefault();
                                                                     closeDeletePopup();
                                                                     close();
                                                                     deletePost(csrfToken, team, post);
                                                                   }
                                                                 }>
                                                                Remove
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Popup>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                    ) : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="social-post-metrics small" colSpan="4">
                                    <SocialPostStatusBars post={post} />
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                )
              )}
            </div>
          ) : null}

          {marketingSectionActiveTab === "email" ? (
            <div>
              {confirm.customer_io_emails.length === 0 ? (
                <div className="row mt-5">
                  <div className="col-12 text-center">
                    <h5>
                      No emails for this event yet
                    </h5>
                    <p className="text-muted">
                      Create an email and promote this event
                    </p>
                    {currentUser.can_manage_customer_io_emails ? (
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             sendingCustomerIOEmailChanged(true);
                           }
                         }
                         style={{"borderRadius": "8px"}}
                         className="btn btn-primary btn-sm">
                        New Email
                      </a>
                    ) : null}
                  </div>
                </div>
              ) : (
                <table style={{'marginTop': "16px", "tableLayout": "fixed"}}
                       className="table table-borderless table-sm microtable mb-0">
                  <colgroup>
                    <col span={1} style={{"width": "50%"}} />
                    <col span={1} style={{"width": "12.5%"}} /    >
                    <col span={1} style={{"width": "12.5%"}} />
                    <col span={1} style={{"width": "12.5%"}} />
                    <col span={1} style={{"width": "12.5%"}} />
                  </colgroup>
                  <tbody>
                    {confirm.customer_io_emails.map((customerIOEmail, index) =>
                      <tr key={index}>
                        <td>
                          <strong>{customerIOEmail.name}</strong><br />
                          <span className="text-muted">
                            {customerIOEmail.human_sent_at ? (
                              customerIOEmail.human_sent_at
                            ) : (
                              "Draft"
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="text-muted">Sent</span><br />
                          <span>{customerIOEmail.sent}</span>
                        </td>
                        <td>
                          <span className="text-muted">Opened</span><br />
                          <span>{customerIOEmail.percentage_opened}%</span>
                        </td>
                        <td>
                          <span className="text-muted">Clicked</span><br />
                          <span>{customerIOEmail.percentage_clicked}%</span>
                        </td>
                        <td>
                          <span className="text-muted">Converted</span><br />
                          <span>{customerIOEmail.percentage_converted}%</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          ) : null}

          {marketingSectionActiveTab === "social_ads" ? (
            <ManageMarketingBudgetItems csrfToken={csrfToken}
                                        team={team}
                                        confirm={confirm}
                                        currentUser={currentUser}
                                        title={"No Social Ad activities yet."}
                                        subtitle={"List your social ad strategy for this event."}
                                        newCopy={"New Activity"}
                                        namePlaceholder={"Enter activity"}
                                        addNewMarketingBudgetItem={
                                          () => {
                                            addSocialMarketingBudgetItem({
                                              category: "Social",
                                              uuid: uuidv4()
                                            });
                                          }
                                        }
                                        createMarketingBudgetItem={createMarketingBudgetItem}
                                        destroyMarketingBudgetItem={destroyMarketingBudgetItem}
                                        editMarketingBudgetItem={editMarketingBudgetItem}
                                        updateMarketingBudgetItem={updateMarketingBudgetItem}
                                        marketingBudgetItemToEdit={marketingBudgetItemToEdit}
                                        marketingBudgetItemToEditChanged={marketingBudgetItemToEditChanged}
                                        marketingBudgetItems={socialMarketingBudgetItems} />
          ) : null}

          {marketingSectionActiveTab === "tv_radio_print" ? (
            <ManageMarketingBudgetItems csrfToken={csrfToken}
                                        team={team}
                                        confirm={confirm}
                                        currentUser={currentUser}
                                        title={"Add Marketing Budget Items for this event"}
                                        subtitle={"Create a list of marketing items."}
                                        newCopy={"New Item"}
                                        namePlaceholder={"Enter item"}
                                        addNewMarketingBudgetItem={
                                          () => {
                                            addTraditionalMarketingBudgetItem({
                                              category: "TV / Radio / Print",
                                              uuid: uuidv4()
                                            })
                                          }
                                        }
                                        createMarketingBudgetItem={createMarketingBudgetItem}
                                        destroyMarketingBudgetItem={destroyMarketingBudgetItem}
                                        editMarketingBudgetItem={editMarketingBudgetItem}
                                        updateMarketingBudgetItem={updateMarketingBudgetItem}
                                        marketingBudgetItemToEdit={marketingBudgetItemToEdit}
                                        marketingBudgetItemToEditChanged={marketingBudgetItemToEditChanged}
                                        marketingBudgetItems={traditionalMarketingBudgetItems} />
          ) : null}

        </div>
      </div>
    </div>
    <Popup
      open={sendingCustomerIOEmail}
      contentStyle={modalContentStyle}
      closeOnDocumentClick={true}
      onClose={() => sendingCustomerIOEmailChanged(false)}
    >
      <div>
        <div className="row mb-5">
          <div className="col d-flex">
            <span className="d-flex justify-content-center align-self-center mr-3"
                  style={{
                    "display": "inline-block",
                    "width": "30px",
                    "height": "30px",
                    "borderRadius": "50%",
                    "color": "white",
                    "background": "#3c82bf"
                  }}>
              <i className="fas fa-envelope align-self-center"
                 style={{
                   "fontSize": "14px",
                   "marginTop": "2px"
                 }}>
              </i>
            </span>
            <h5 className="mb-0 align-self-center">
              <strong>Generate New Email</strong>
            </h5>
          </div>
          <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
            <a href="#"
               style={{"fontSize": "24px"}}
               className="text-dark"
               onClick={(e) => {
                 e.preventDefault();
                 sendingCustomerIOEmailChanged(false);
               }} >
              <i className="fal fa-times"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form onSubmit={
              (e) => {
                e.preventDefault();

                if(canSubmitForm(customerIOEmailPurpose, customerIOEmailTemplate)){
                  createCustomerIOEmail(confirm.eventbrite_event.eventbrite_id, customerIOEmailPurpose, customerIOEmailTemplate);
                }
              }
            }>
              <div className="form-group">
                <select className="form-control"
                        onChange={
                          (e) => {
                            customerIOEmailPurposeChanged(e.target.value);
                          }
                        }
                        value={customerIOEmailPurpose || ""}>
                  <option value="" disabled>Email purpose</option>
                  <option value="1">Show Announce</option>
                  <option value="2">Pre-Sale</option>
                  <option value="3">On-Sale</option>
                  <option value="4">Don’t Miss</option>
                </select>
              </div>
              <div className="form-group">
                <select className="form-control"
                        onChange={
                          (e) => {
                            customerIOEmailTemplateChanged(e.target.value);
                          }
                        }
                        value={customerIOEmailTemplate || ""}>
                  <option value="" disabled>Email template</option>
                  <option value="1">Design #1</option>
                  <option value="2">Design #2</option>
                </select>
              </div>
              <div className="form-row pt-3">
                <div className="col-12 text-right">
                  <a href="#" onClick={
                    (e) => {
                      e.preventDefault();
                      sendingCustomerIOEmailChanged(false);
                    }
                  }>
                    <strong>Cancel</strong>
                  </a>
                  <button type="submit"
                          disabled={!canSubmitForm(customerIOEmailPurpose, customerIOEmailTemplate)}
                          className="btn btn-primary ml-3">
                    <strong>Create</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>
    <Popup
      open={addingNewSocialAccount}
      contentStyle={addSocialModalContentStyle}
      closeOnDocumentClick={true}
      onClose={() => addingNewSocialAccountChanged(false)}
    >
      <div>
        <div className="row mb-5">
          <div className="col d-flex">
            <h5 className="mb-0 align-self-center">
              <strong>Add an account to Opendate</strong>
            </h5>
          </div>
          <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
            <a href="#"
               style={{"fontSize": "24px"}}
               className="text-dark"
               onClick={(e) => {
                 e.preventDefault();
                 addingNewSocialAccountChanged(false);
               }} >
              <i className="fal fa-times"></i>
            </a>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col d-none">
            <a className="text-dark" href={"https://api.getonepost.com/users/auth/twitter?provider_id=" + twitterProviderId + "&public_key=" + onepostPublicKey}>
              <div className="card mb-0 h-100">
                <div className="card-body text-center py-4 px-0">
                  <div style={{"height": "87px"}}>
                    <img className="img-fluid pb-2" src="/twitter@2x.png" style={{maxWidth: "64px"}} />
                  </div>
                  <strong className="d-block">Twitter</strong>
                </div>
              </div>
            </a>
          </div>
          <div className="col">
            <a className="text-dark" href={"https://api.getonepost.com/users/auth/facebook?provider_id=" + facebookProviderId + "&public_key=" + onepostPublicKey}>
              <div className="card mb-0 h-100">
                <div className="card-body text-center py-4 px-0">
                  <img className="img-fluid pb-2" src="/fb-insta@2x.png" style={{maxWidth: "113px"}} />
                  <strong className="d-block">Facebook & Instagram</strong>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Popup>
    <Popup
      open={composingPost}
      contentStyle={composePostModalContentStyle}
      closeOnDocumentClick={false}
      onClose={() => composingPostChanged(false)}
    >
      <div className="row">
        <div className="col-12">
          <div className="col-xs-auto position-absolute"
               style={{
                 "right": "37px",
                 "top": "26px"
               }}>
            <a href="#"
               style={{"fontSize": "24px"}}
               className="text-dark"
               onClick={(e) => {
                 e.preventDefault();
                 composingPostChanged(false);
               }} >
              <i className="fal fa-times"></i>
            </a>
          </div>
          <OnepostComposer publicKey={onepostPublicKey}
                           onSuccess={
                             (data) => {
                               createPost(csrfToken, team, confirm, data.token);
                             }
                           }
                           onFailure={
                             (error) => {
                               var messages = Object.keys(error).map((attribute) => (
                                 error[attribute].map((message) => (
                                   (attribute + " " + message)
                                 ))
                               )).flat().join(", ");

                               toast.error("An error occurred while processing your request (" + messages + ")", {
                                 position: toast.POSITION.TOP_CENTER,
                                 draggable: false,
                                 closeOnClick: false,
                                 autoClose: 5000,
                                 hideProgressBar: true
                               });
                             }
                           }
                           imageIds={[]}
                           authorizedPageIds={authorizedPages.map((ap) => ap.onepost_id)} />
        </div>
      </div>
    </Popup>
    <Popup
      open={Object.keys(postToEdit).length > 0}
      contentStyle={composePostModalContentStyle}
      closeOnDocumentClick={false}
      onClose={() => postToEditChanged({})}
    >
      <div className="row">
        <div className="col-12">
          <div className="col-xs-auto position-absolute"
               style={{
                 "right": "37px",
                 "top": "26px"
               }}>
            <a href="#"
               style={{"fontSize": "24px"}}
               className="text-dark"
               onClick={(e) => {
                 e.preventDefault();
                 postToEditChanged({});
               }} >
              <i className="fal fa-times"></i>
            </a>
          </div>
          <OnepostComposer publicKey={onepostPublicKey}
                           onSuccess={
                             (data) => {
                               updatePost(csrfToken, team, postToEdit, data.token);
                             }
                           }
                           onFailure={
                             (error) => {
                               toast.error("An error occurred while processing your request", {
                                 position: toast.POSITION.TOP_CENTER,
                                 draggable: false,
                                 closeOnClick: false,
                                 autoClose: 5000,
                                 hideProgressBar: true
                               });
                             }
                           }
                           token={postToEdit.onepost_token}
                           imageIds={[]}
                           authorizedPageIds={authorizedPages.map((ap) => ap.onepost_id)} />
        </div>
      </div>
    </Popup>
  </div>
);

MarketingSection.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  sendingCustomerIOEmail: PropTypes.bool.isRequired,
  sendingCustomerIOEmailChanged: PropTypes.func.isRequired,
  customerIOEmailPurpose: PropTypes.string,
  customerIOEmailPurposeChanged: PropTypes.func.isRequired,
  customerIOEmailTemplate: PropTypes.string,
  customerIOEmailTemplateChanged: PropTypes.func.isRequired,
  createCustomerIOEmail: PropTypes.func.isRequired,
  marketingSectionActiveTab: PropTypes.string,
  marketingSectionActiveTabChanged: PropTypes.func.isRequired,
  authorizedPages: PropTypes.array,
  addingNewSocialAccount: PropTypes.bool,
  addingNewSocialAccountChanged: PropTypes.func.isRequired,
  onepostPublicKey: PropTypes.string,
  twitterProviderId: PropTypes.number,
  facebookProviderId: PropTypes.number,
  composingPost: PropTypes.bool,
  composingPostChanged: PropTypes.func.isRequired,
  createPost: PropTypes.func.isRequired,
  posts: PropTypes.array,
  deletePost: PropTypes.func.isRequired,
  postToEdit: PropTypes.object,
  postToEditChanged: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  confirmPageSection: PropTypes.object,
  fetchPermissionSet: PropTypes.func.isRequired,
  updateConfirmPageSection: PropTypes.func.isRequired,
  socialMarketingBudgetItems: PropTypes.array,
  traditionalMarketingBudgetItems: PropTypes.array,
  addSocialMarketingBudgetItem: PropTypes.func.isRequired,
  addTraditionalMarketingBudgetItem: PropTypes.func.isRequired,
  createMarketingBudgetItem: PropTypes.func.isRequired,
  destroyMarketingBudgetItem: PropTypes.func.isRequired,
  editMarketingBudgetItem: PropTypes.func.isRequired,
  updateMarketingBudgetItem: PropTypes.func.isRequired,
  marketingBudgetItemToEdit: PropTypes.object,
  marketingBudgetItemToEditChanged: PropTypes.func.isRequired
};

export default MarketingSection;
