/* eslint-disable import/prefer-default-export */

export const FORM_STATE = 'FORM_STATE';
export const PREVIEW_STATE = 'PREVIEW_STATE';
export const CUSTOMIZE_STATE = 'CUSTOMIZE_STATE';

export const OFFER_VENUE_CHANGED = 'OFFER_VENUE_CHANGED';
export const OFFER_PRIMARY_ARTIST_CHANGED = 'OFFER_PRIMARY_ARTIST_CHANGED';
export const OFFER_START_DATE_CHANGED = 'OFFER_START_DATE_CHANGED';
export const OFFER_DEAL_TERM_LINE_ITEM_ADDED = 'OFFER_DEAL_TERM_LINE_ITEM_ADDED';
export const OFFER_DEAL_TERM_LINE_ITEM_REMOVED = 'OFFER_DEAL_TERM_LINE_ITEM_REMOVED';
export const OFFER_DEAL_TERM_LINE_ITEM_LABEL_CHANGED = 'OFFER_DEAL_TERM_LINE_ITEM_LABEL_CHANGED';
export const OFFER_DOLLAR_OR_PERCENTAGE_OPERATOR_CHANGED = 'OFFER_DOLLAR_OR_PERCENTAGE_OPERATOR_CHANGED';
export const OFFER_DOLLAR_AMOUNT_CHANGED = 'OFFER_DOLLAR_AMOUNT_CHANGED';
export const OFFER_UPSIDE_PERCENTAGE_CHANGED = 'OFFER_UPSIDE_PERCENTAGE_CHANGED';
export const OFFER_DEAL_TERM_LINE_ITEM_DESCRIPTION_CHANGED = 'OFFER_DEAL_TERM_LINE_ITEM_DESCRIPTION_CHANGED';
export const OFFER_ADDITIONAL_TERMS_CHANGED = 'OFFER_ADDITIONAL_TERMS_CHANGED';
export const OFFER_CONTACT_DETAILS_CHANGED = 'OFFER_CONTACT_DETAILS_CHANGED';
export const OFFER_PERFORMER_REMOVED = 'OFFER_PERFORMER_REMOVED';
export const OFFER_EVENT_NAME_CHANGED = 'OFFER_EVENT_NAME_CHANGED';
export const OFFER_AGENT_NAME_CHANGED = 'OFFER_AGENT_NAME_CHANGED';
export const OFFER_AGENT_EMAIL_CHANGED = 'OFFER_AGENT_EMAIL_CHANGED';
export const OFFER_ARTIST_SUGGESTION_ADDED = 'OFFER_ARTIST_SUGGESTION_ADDED';
export const OFFER_CALENDAR_EVENT_CHANGED = 'OFFER_CALENDAR_EVENT_CHANGED';
export const OFFER_PERFORMER_SELECTED = 'OFFER_PERFORMER_SELECTED';
export const OFFER_PERFORMER_UNSELECTED = 'OFFER_PERFORMER_UNSELECTED';
export const OFFER_START_TIME_CHANGED = 'OFFER_START_TIME_CHANGED';
export const OFFER_FRONT_END_STATE_CHANGED = 'OFFER_FRONT_END_STATE_CHANGED';
export const OFFER_EMAIL_BODY_CHANGED = 'OFFER_EMAIL_BODY_CHANGED';
export const OFFER_LAST_SENT_TO_CHANGED = 'OFFER_LAST_SENT_TO_CHANGED';
export const OFFER_CALENDAR_EVENT_PERFORMERS_CHANGED = 'OFFER_CALENDAR_EVENT_PERFORMERS_CHANGED';
export const OFFER_ARTIST_PREVIEW_CHANGED = 'OFFER_ARTIST_PREVIEW_CHANGED';
export const OFFER_CHANGED = 'OFFER_CHANGED';
export const OFFER_ERRORS_CHANGED = 'OFFER_ERRORS_CHANGED';

export const OFFER_TEMPLATE_NAME_CHANGED = 'OFFER_TEMPLATE_NAME_CHANGED';
export const SELECTED_OFFER_TEMPLATE_CHANGED = 'SELECTED_OFFER_TEMPLATE_CHANGED';
export const APPLY_OFFER_TEMPLATE = 'APPLY_OFFER_TEMPLATE';
export const NEW_OFFER_TEMPLATE_CREATED = 'NEW_OFFER_TEMPLATE_CREATED';

export const HIDDEN_FILE_UPLOAD_INPUT_EL_CHANGED = 'HIDDEN_FILE_UPLOAD_INPUT_EL_CHANGED';
export const ATTACHMENT_ADDED = 'ATTACHMENT_ADDED';
export const ATTACHMENT_REMOVED = 'ATTACHMENT_REMOVED';
