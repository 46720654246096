import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { MultiSelect } from "react-multi-select-component";
import Popup from "reactjs-popup";
import { usePlaidLink } from 'react-plaid-link';
const axios = require('axios').default;
import { ToastContainer, toast } from 'react-toastify';
import InputMask from "react-input-mask";
import Tippy from "@tippyjs/react";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "195px",
  "padding": "0px"
}

const microDepositLinkStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "18px 0px"
}

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const FinanceSettingsManager = ({
  csrfToken,
  team,
  currentUser,
  plaidToken,
  dwollaCustomer,
  dwollaCustomerChanged,
  venueBankLinks,
  venueBankLinksChanged,
  venueOwnerships,
  venueOwnershipsChanged
}) => {
  const [creatingDwollaCustomer, setCreatingDwollaCustomer] = useState(false);
  const [originalDwollaCustomer, setOriginalDwollaCustomer] = useState({...dwollaCustomer});

  const [creatingMicroDepositLink, setCreatingMicroDepositLink] = useState(false);
  const [microDepositLink, setMicroDepositLink] = useState({});
  const [microDepositLinkVerification, setMicroDepositLinkVerification] = useState({});
  const [verifyingMicroDepositLink, setVerifyingMicroDepositLink] = useState(false);

  const createDwollaCustomer = (dwollaCustomer) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    setCreatingDwollaCustomer(true);

    axios.post(`/teams/${team.id}/dwolla_customers`, {
        dwolla_customer: {
          first_name: dwollaCustomer.first_name,
          last_name: dwollaCustomer.last_name,
          email: dwollaCustomer.email,
          business_name: dwollaCustomer.business_name,
          terms_and_privacy_policy: dwollaCustomer.terms_and_privacy_policy
        }
      })
      .then(({data}) => {
        setOriginalDwollaCustomer(data);
        dwollaCustomerChanged(data);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        setCreatingDwollaCustomer(false);
      });
  };

  const updateDwollaCustomer = (dwollaCustomer) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    setCreatingDwollaCustomer(true);

    axios.patch(`/teams/${team.id}/dwolla_customers/${dwollaCustomer.id}`, {
        dwolla_customer: {
          first_name: dwollaCustomer.first_name,
          last_name: dwollaCustomer.last_name,
          email: dwollaCustomer.email,
          business_name: dwollaCustomer.business_name
        }
      })
      .then(({data}) => {
        setOriginalDwollaCustomer(data);
        dwollaCustomerChanged(data);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        setCreatingDwollaCustomer(false);
      });
  };

  const updatePlaidAccountLink = (venueBankLink, venueOwnershipIds) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.patch(`/teams/${team.id}/venue_bank_links/${venueBankLink.id}`, {
        venue_bank_link: {
          venue_ownership_ids: venueOwnershipIds
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };

  const deletePlaidAccountLink = (venueBankLink) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.delete(`/teams/${team.id}/venue_bank_links/${venueBankLink.id}`)
      .then(({data}) => {
        venueBankLinksChanged(
          venueBankLinks.filter((pal) => pal.id !== data.id)
        );

        venueOwnershipsChanged(
          [...venueOwnerships].map((vo) => {
            if(vo.venue_bank_link_id === data.id){
              return {
                ...vo,
                venue_bank_link_id: null
              };
            } else {
              return vo;
            }
          })
        )
      });
  };

  const canSaveDwollaCustomer = (dwollaCustomer, creatingDwollaCustomer, originalDwollaCustomer) => {
    var validTerms = (
      dwollaCustomer.terms_and_privacy_policy === "1" ||
        dwollaCustomer.id
    );

    var dwollaCustomerChanged = (
      dwollaCustomer.first_name !== originalDwollaCustomer.first_name ||
        dwollaCustomer.last_name !== originalDwollaCustomer.last_name ||
        dwollaCustomer.email !== originalDwollaCustomer.email ||
        dwollaCustomer.business_name !== originalDwollaCustomer.business_name ||
        dwollaCustomer.terms_and_privacy_policy !== originalDwollaCustomer.terms_and_privacy_policy
    );

    return (
      dwollaCustomer.first_name &&
        dwollaCustomer.first_name.length > 0 &&
        dwollaCustomer.last_name &&
        dwollaCustomer.last_name.length > 0 &&
        dwollaCustomer.email &&
        dwollaCustomer.email.length > 0 &&
        validateEmail(dwollaCustomer.email) &&
        dwollaCustomer.business_name &&
        dwollaCustomer.business_name.length > 0 &&
        validTerms &&
        dwollaCustomerChanged &&
        !creatingDwollaCustomer
    );
  }

  const { open, ready } = usePlaidLink({
    token: plaidToken,
    onSuccess: (publicToken, metadata) => {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

      return axios.post("/teams/" + team.id + "/plaid_account_links", {
          public_token: publicToken,
          account_id: metadata.account_id
        })
        .then(({ data }) => {
          var updated = [...venueBankLinks, data];
          venueBankLinksChanged(updated);
        });
    },
  });

  const canSubmitMicroDepositLink = (
    Object.keys(microDepositLink).length > 0
      && microDepositLink.account_type
      && microDepositLink.account_type.length > 0
      && microDepositLink.routing_number
      && microDepositLink.routing_number.length === 9
      && microDepositLink.account_number
      && microDepositLink.account_number.length >= 5
      && microDepositLink.account_number.length <= 17
      && microDepositLink.account_name
      && microDepositLink.account_name.length > 0
      && !creatingMicroDepositLink
  );

  const canSubmitMicroDepositLinkVerification = (
    Object.keys(microDepositLinkVerification).length > 0
      && microDepositLinkVerification.amount1
      && microDepositLinkVerification.amount1.length > 0
      && microDepositLinkVerification.amount1.length <= 2
      && microDepositLinkVerification.amount2
      && microDepositLinkVerification.amount2.length > 0
      && microDepositLinkVerification.amount2.length <= 2
      && !verifyingMicroDepositLink
  );

  const createMicroDepositLink = (microDepositLink) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    setCreatingMicroDepositLink(true);

    axios.post(`/teams/${team.id}/micro_deposit_links`, {
        micro_deposit_link: {
          routing_number: microDepositLink.routing_number,
          account_number: microDepositLink.account_number,
          account_type: microDepositLink.account_type,
          account_name: microDepositLink.account_name
        }
      })
      .then(({data}) => {
        setMicroDepositLink({});

        venueBankLinksChanged(
          [...venueBankLinks, data]
        );
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        setCreatingMicroDepositLink(false);
      });
  };

  const verifyMicroDepositLink = (microDepositLinkVerification) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    setVerifyingMicroDepositLink(true);

    axios.post(`/teams/${team.id}/micro_deposit_links/${microDepositLinkVerification.id}/verify`, {
        amount1: microDepositLinkVerification.amount1,
        amount2: microDepositLinkVerification.amount2
      })
      .then(({data}) => {
        setMicroDepositLinkVerification({});

        venueBankLinksChanged(
          [...venueBankLinks].map((vbl) => {
            if(vbl.id === data.id){
              return data;
            } else {
              return vbl;
            }
          })
        );
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        setVerifyingMicroDepositLink(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className='row'>
        <div className='col-12' style={{"marginBottom": "40px"}}>
          <p className="mb-0 text-dark"
              style={{"fontSize": "16px"}}>
            <strong>Connect your bank account to receive payouts</strong>
          </p>
          <p className="mb-3 text-muted small">
            Opendate has partnered with a service called Dwolla to connect your bank account. Complete the following steps to get you account connected.
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <p className="mb-0 text-muted"
              style={{"fontSize": "10px"}}>
            <strong>STEP 1</strong>
          </p>
          <p className="mb-3 text-dark">
            <strong>
              Create your profile
            </strong>
          </p>
          <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(canSaveDwollaCustomer(dwollaCustomer, creatingDwollaCustomer, originalDwollaCustomer)){
                      if(dwollaCustomer.id){
                        updateDwollaCustomer(dwollaCustomer);
                      } else {
                        createDwollaCustomer(dwollaCustomer);
                      }
                    }
                  }
                }>
            <div className='form-row'>
              <div className='col-6'>
                <div className="form-group">
                  <label className='text-muted mb-0'
                          htmlFor="dwolla-customer-first-name">
                    First Name
                  </label>
                  <input type="text"
                        className="form-control"
                        style={{"borderWidth": "1px"}}
                        value={dwollaCustomer.first_name || ""}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              first_name: e.target.value
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-first-name" />
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <label className='text-muted mb-0'
                          htmlFor="dwolla-customer-last-name">
                    Last Name
                  </label>
                  <input type="text"
                        className="form-control"
                        style={{"borderWidth": "1px"}}
                        value={dwollaCustomer.last_name || ""}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              last_name: e.target.value
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-last-name" />
                </div>
              </div>
            </div>
            <div className='form-row'>
              <div className='col-6'>
                <div className="form-group">
                  <label className='text-muted mb-0'
                          htmlFor="dwolla-customer-email">
                    Email
                  </label>
                  <input type="email"
                        className="form-control"
                        style={{"borderWidth": "1px"}}
                        value={dwollaCustomer.email || ""}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              email: e.target.value
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-email" />
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <label className='text-muted mb-0'
                          htmlFor="dwolla-customer-business-name">
                    Business Name
                  </label>
                  <input type="text"
                        className="form-control"
                        style={{"borderWidth": "1px"}}
                        value={dwollaCustomer.business_name || ""}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              business_name: e.target.value
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-business-name" />
                </div>
              </div>
            </div>
            {!dwollaCustomer.id ? (
              <div className="form-check mb-2">
                <input type="checkbox"
                        className="form-check-input"
                        checked={dwollaCustomer.terms_and_privacy_policy === "1" || false}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              terms_and_privacy_policy: (e.target.checked ? "1" : "0")
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-agree-to-terms" />
                <label className="form-check-label"
                        htmlFor="dwolla-customer-agree-to-terms">
                  By checking this box you agree to our payments partner Dwolla's <a href="https://www.dwolla.com/legal/dwolla-account-terms-of-service" target="_blank">Terms of Service</a> and <a href="https://www.dwolla.com/legal/privacy" target="_blank">Privacy Policy</a>
                </label>
              </div>
            ) : null}
            <button type="submit"
                    disabled={!canSaveDwollaCustomer(dwollaCustomer, creatingDwollaCustomer, originalDwollaCustomer)}
                    style={{"borderRadius": "4px"}}
                    className="btn btn-primary small mt-2">
              {creatingDwollaCustomer ? (
                <React.Fragment>
                  <img src="/uploading-loading.gif"
                      className="mr-1"
                      style={{width: "16px"}} />
                  <strong>Saving...</strong>
                </React.Fragment>
              ) : (
                <strong>Save</strong>
              )}
            </button>
          </form>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'
              style={{
                "marginTop": "60px",
                "marginBottom": "60px"
              }}>
          <p className="mb-0 text-muted"
              style={{"fontSize": "10px"}}>
            <strong>STEP 2</strong>
          </p>
          <p className="mb-3 text-dark">
            <strong>
              Add your account(s)
            </strong>
          </p>
          <div className="row mt-3">
            {venueBankLinks.map((venueBankLink, index) =>
              <div className="col-12"
                  key={index}>
                <div className='form-row'>
                  <div className='col-6 col-xl-4'>
                    <div className="card w-100"
                        style={{"border": "2px solid #e7e7e7"}}>
                      <div className="card-body"
                          style={{"padding": "11px 17px"}}>
                        <div className="row">
                          <div className="col-xs-auto d-flex align-items-center"
                              style={{"paddingLeft": "15px"}}>
                            <i className="fas fa-university"
                              style={{
                                "color": "#B3B3B3",
                                "fontSize": "26px"
                              }}>
                            </i>
                          </div>
                          <div className="col">
                            <table className="table table-sm mb-0 table-borderless">
                              <tbody>
                                <tr>
                                  <td className="p-0 overflow-ellipsis"
                                      title={venueBankLink.official_name}>
                                    <p className="small mb-0 text-overflow-ellipsis">
                                      <strong>{venueBankLink.name}</strong>
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-0">
                                    <p className="small mb-0 text-muted">
                                      ***** {venueBankLink.mask} (USD)
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-xs-auto"
                              style={{"paddingRight": "15px"}}>
                            {venueBankLink.verified_at ? (
                              <span className="badge badge-success px-2 py-1 mr-2"
                                    style={{"fontSize": "10px"}}>
                                Verified
                              </span>
                            ) : (
                              venueBankLink.deposited_at ? (
                                <a href="#"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        setMicroDepositLinkVerification({...venueBankLink});
                                      }
                                    }
                                    className='small mr-2'>
                                  Verify Amounts
                                </a>
                              ) : (
                                <Tippy content="Over the next  2-3 business days you'll receive two small deposits to your bank account. Once these deposits are made, you'll get an email with instructions on how to complete your account verification.">
                                  <span className='mr-2' style={{"fontSize": "12px"}}>
                                    <i className="fas fa-info-circle mr-1"></i>
                                    Pending Verification
                                  </span>
                                </Tippy>
                              )
                            )}
                            <Popup arrow={false}
                                  offsetY={5}
                                  position="bottom right"
                                  contentStyle={popupContentStyle}
                                  onOpen={
                                    (e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }
                                  trigger={open => (
                                    <a href="#"
                                        style={
                                          {
                                            "top": "10px",
                                            "right": "12px",
                                            "position": "absolute"
                                          }
                                        }
                                        className="text-muted">
                                      <i className="far fa-ellipsis-v"></i>
                                    </a>
                                  )} >
                              {close => (
                                <div className='row text-left'>
                                  <div className="col-12">
                                    <ul className="list-group offer-inline-menu">
                                      <li className="list-group-item delete-offer-li">
                                        <a href="#"
                                          onClick={
                                            (e) => {
                                              e.preventDefault();

                                              if(confirm("Are you sure?")){
                                                close();
                                                deletePlaidAccountLink(venueBankLink);
                                              }
                                            }
                                          }
                                          className="text-danger">
                                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                                            <i className="fas fa-times-circle"></i>
                                          </span>
                                          Remove
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6 col-xl-8'>
                    <div className="card w-100"
                        style={{"border": "1px solid #e7e7e7"}}>
                      <div className="card-body"
                          style={{"padding": "11px 17px"}}>
                        <MultiSelect
                          className="dropdown-container-inline"
                          options={
                            venueOwnerships.map((venueOwnership) => (
                              {value: venueOwnership.id, label: venueOwnership.name}
                            ))
                          }
                          hasSelectAll={true}
                          value={
                            venueOwnerships
                              .filter((vo) => vo.venue_bank_link_id === venueBankLink.id)
                              .map((vo) => ({value: vo.id, label: vo.name}))
                          }
                          onChange={
                            (nowSelected) => {
                              var nowSelectedIds = nowSelected.map((ns) => ns.value);
                              var previouslySelectedIds = venueOwnerships
                                .filter((vo) => vo.venue_bank_link_id === venueBankLink.id);
                              var removedIds = previouslySelectedIds
                                .filter((ps) => !nowSelectedIds.includes(ps.id))
                                .map((ps) => ps.id);
                              var addedIds = nowSelectedIds
                                .filter((ns) => !previouslySelectedIds.map((ps) => ps.id).includes(ns))
                                .map((ns) => ns);

                              venueOwnershipsChanged(
                                venueOwnerships.map((vo) => {
                                  if(removedIds.includes(vo.id)){
                                    return {
                                      ...vo,
                                      venue_bank_link_id: null
                                    };
                                  } else if(addedIds.includes(vo.id)){
                                    return {
                                      ...vo,
                                      venue_bank_link_id: venueBankLink.id
                                    };
                                  } else {
                                    return vo;
                                  }
                                }
                              ));

                              updatePlaidAccountLink(venueBankLink, nowSelectedIds);
                            }
                          }
                          overrideStrings={{
                            "selectSomeItems": "Assign to venue",
                            "allItemsAreSelected": "Assigned to all venues"
                          }}
                          labelledBy="Select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <button onClick={() => open()}
                  className='btn btn-primary small'
                  style={{
                    "borderRadius": "4px",
                    "borderWidth": "1px"
                  }}
                  disabled={!ready || !dwollaCustomer.id}>
            <strong>
              Add Account
            </strong>
          </button>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();

                  if(!dwollaCustomer.id){
                    return false;
                  }

                  setMicroDepositLink({
                    "account_type": "checking"
                  });
                }
              }
              className={'ml-2 text-muted ' + (!dwollaCustomer.id ? "disabled" : "")}>
            Enter Bank Account Manually
          </a>
        </div>
      </div>
      <Popup
        open={Object.keys(microDepositLink).length > 0}
        contentStyle={microDepositLinkStyle}
        className={"popup-modal"}
        closeOnDocumentClick={true}
        onClose={() => { setMicroDepositLink({}); }}
      >
        <div className="popup-modal-container">
          <div className="row m-0">
            <div className="col-12">
              <div className='row'>
                <div className='col d-flex flex-column justify-content-start'>
                  <p className="mb-0" style={{"fontSize": "20px"}}>
                    <strong>Enter your bank information</strong>
                  </p>
                  <p className='mb-0 text-muted' style={{"maxWidth": "580px"}}>
                    Provide the routing and account number for your bank account. You must be an owner of the account you are adding. It can take up to 2-3 business days to verify your account.
                  </p>
                </div>
                <div className='col-xs-auto'>
                  <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          setMicroDepositLink({});
                        }
                      }
                      className="text-dark"
                      style={{"fontSize": "20px", "paddingRight": "15px"}}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 mt-4'>
                  <img src="/check_400px.png" className="img-fluid" style={{width: "400px"}} />
                </div>
                <div className='col-12 mt-4'>
                  <form onSubmit={
                          (e) => {
                            e.preventDefault();

                            if(canSubmitMicroDepositLink){
                              createMicroDepositLink(microDepositLink);
                            }
                          }
                        }>
                    <div className='form-row'>
                      <div className='col-6'>
                        <div className="form-group">
                          <label htmlFor="bank-account-name"
                                  className="mb-1 text-muted">
                            Account Nickname
                          </label>
                          <input type="text"
                                className="form-control"
                                value={microDepositLink.account_name || ""}
                                onChange={
                                  (e) => {
                                    setMicroDepositLink({
                                      ...microDepositLink,
                                      account_name: e.target.value
                                    });
                                  }
                                }
                                id="bank-account-name"
                                style={{"borderWidth": "1px"}}
                                autoFocus={true} />
                        </div>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col-6'>
                        <div className="form-group">
                          <label htmlFor="bank-routing-number"
                                  className="mb-1 text-muted">
                            Bank Routing Number
                          </label>
                          <InputMask mask={"999999999"}
                                  alwaysShowMask={false}
                                  maskChar={""}
                                  className="form-control"
                                  value={microDepositLink.routing_number || ""}
                                  onChange={
                                    (e) => {
                                      setMicroDepositLink({
                                        ...microDepositLink,
                                        routing_number: e.target.value
                                      });
                                    }
                                  }
                                  id="bank-routing-number"
                                  style={{"borderWidth": "1px"}} />
                        </div>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col-6'>
                        <div className="form-group">
                          <label htmlFor="bank-account-number" className="mb-1 text-muted">
                            Account Number
                          </label>
                          <InputMask mask={"99999999999999999"}
                                  alwaysShowMask={false}
                                  maskChar={""}
                                  className="form-control"
                                  value={microDepositLink.account_number || ""}
                                  onChange={
                                    (e) => {
                                      setMicroDepositLink({
                                        ...microDepositLink,
                                        account_number: e.target.value
                                      });
                                    }
                                  }
                                  id="bank-account-number"
                                  style={{"borderWidth": "1px"}} />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className="form-group">
                          <label htmlFor="bank-account-type"
                                  className="mb-1 text-muted">
                            Account Type
                          </label>
                          <select className='form-control'
                                  id="bank-account-type"
                                  value={microDepositLink.account_type || ""}
                                  onChange={
                                    (e) => {
                                      setMicroDepositLink({
                                        ...microDepositLink,
                                        account_type: e.target.value
                                      });
                                    }
                                  }
                                  style={{"borderWidth": "1px"}}>
                            <option value="checking">Checking</option>
                            <option value="savings">Savings</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 text-right'>
                        <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                setMicroDepositLink({});
                              }
                            }
                            className='mr-2'>
                          Cancel
                        </a>
                        <button type="submit"
                                disabled={!canSubmitMicroDepositLink}
                                className="btn btn-primary">
                          {creatingMicroDepositLink ? (
                            <strong>
                              <img src="/uploading-loading.gif" className="mr-1" style={{width: "16px"}} />
                              Adding Account...
                            </strong>
                          ) : (
                            <strong>
                              Add Account
                            </strong>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={Object.keys(microDepositLinkVerification).length > 0}
        contentStyle={microDepositLinkStyle}
        className={"popup-modal"}
        closeOnDocumentClick={true}
        onClose={() => { setMicroDepositLinkVerification({}); }}
      >
        <div className="popup-modal-container">
          <div className="row m-0">
            <div className="col-12">
              <div className='row'>
                <div className='col d-flex flex-column justify-content-start'>
                  <p className="mb-0" style={{"fontSize": "20px"}}>
                    <strong>Verify your bank account</strong>
                  </p>
                  <p className='mb-0 text-muted' style={{"maxWidth": "580px"}}>
                    We made two microdeposits into your bank account. Now it's time to confirm those amounts have been received. Once you do, enter those amounts so you can receive payouts, provided there are no other outstanding actions.
                  </p>
                </div>
                <div className='col-xs-auto'>
                  <a href="#"
                      className="text-dark"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          setMicroDepositLinkVerification({});
                        }
                      }
                      style={{"fontSize": "20px", "paddingRight": "15px"}}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 mt-4'>
                  <form onSubmit={
                          (e) => {
                            e.preventDefault();

                            if(canSubmitMicroDepositLinkVerification){
                              verifyMicroDepositLink(microDepositLinkVerification);
                            }
                          }
                        }>
                    <div className='form-row'>
                      <div className='col-12 d-flex justify-content-start'>
                        <div className="form-group mr-3">
                          <label htmlFor="verify-amount-1" className="mb-1 text-muted">
                            Amount 1
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text strong" style={{"borderWidth": "1px"}}>
                                <i className="fas fa-dollar-sign"></i> <span className="ml-1" style={{"fontSize": "16px"}}>0.</span>
                              </span>
                            </div>
                            <InputMask mask={"99"}
                                  alwaysShowMask={false}
                                  maskChar={""}
                                  className="form-control"
                                  value={microDepositLinkVerification.amount1 || ""}
                                  placeholder='00'
                                  onChange={
                                    (e) => {
                                      setMicroDepositLinkVerification({
                                        ...microDepositLinkVerification,
                                        amount1: e.target.value
                                      });
                                    }
                                  }
                                  id="verify-amount-1"
                                  style={{"borderWidth": "1px", "maxWidth": "100px"}} />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="verify-amount-2" className="mb-1 text-muted">
                            Amount 2
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text strong" style={{"borderWidth": "1px"}}>
                                <i className="fas fa-dollar-sign"></i> <span className="ml-1" style={{"fontSize": "16px"}}>0.</span>
                              </span>
                            </div>
                            <InputMask mask={"99"}
                                  alwaysShowMask={false}
                                  maskChar={""}
                                  className="form-control"
                                  value={microDepositLinkVerification.amount2 || ""}
                                  placeholder='00'
                                  onChange={
                                    (e) => {
                                      setMicroDepositLinkVerification({
                                        ...microDepositLinkVerification,
                                        amount2: e.target.value
                                      });
                                    }
                                  }
                                  id="verify-amount-2"
                                  style={{"borderWidth": "1px", "maxWidth": "100px"}} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 text-right'>
                        <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                setMicroDepositLinkVerification({});
                              }
                            }
                            className='mr-2'>
                          Cancel
                        </a>
                        <button type="submit"
                                disabled={!canSubmitMicroDepositLinkVerification}
                                className="btn btn-primary">
                          {verifyingMicroDepositLink ? (
                            <React.Fragment>
                              <img src="/uploading-loading.gif"
                                  className="mr-1"
                                  style={{width: "16px"}} />
                              <strong>Verifying Account...</strong>
                            </React.Fragment>
                          ) : (
                            <strong>
                              Verify Account
                            </strong>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
};

FinanceSettingsManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  plaidToken: PropTypes.string.isRequired,
  dwollaCustomer: PropTypes.object,
  dwollaCustomerChanged: PropTypes.func.isRequired,
  venueBankLinks: PropTypes.array,
  venueBankLinksChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array,
  venueOwnershipsChanged: PropTypes.func.isRequired
};

export default FinanceSettingsManager;
