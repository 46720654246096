import React, { useState } from "react";
import axios from "axios";

import { deleteButtonStyle, renderMatchCondition } from "./FanFilterGroupForm";
import UserDataForm from "./UserDataForm";
import MessageDataForm from "./MessageDataForm";
import { toastError } from "../../../../shared/toastHelper";

const filterRowContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: 8,
  paddingLeft: 50,
};

const filterInputStyle = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "8px 0px",
};

const FanFilterForm = (props) => {
  const {
    csrfToken,
    team,
    fanFilterGroup,
    refreshParentGroupState,
    onValidChangeCallback,
    index,
    depth,
    matchCondition,
  } = props;

  const [fanFilter, setFanFilter] = useState(props.fanFilter);

  const updateFanFilter = (payload) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put(`/teams/${team.id}/fan_filter_groups/${fanFilterGroup.id}/fan_filters/${fanFilter.id}`, { fan_filter: payload })
      .then((data) => {
        setFanFilter(data["data"]);

        if (data["data"]["filter_validation_errors"].length === 0) {
          onValidChangeCallback();
        }
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  const deleteFanFilter = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.delete(`/teams/${team.id}/fan_filter_groups/${fanFilterGroup.id}/fan_filters/${fanFilter.id}`)
      .then(() => {
        refreshParentGroupState();
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  return (
    <div style={filterRowContainerStyle}>
      <div style={filterInputStyle}>
        {renderMatchCondition(index, depth, matchCondition)}
        {fanFilter.type === "FanFilters::UserData" ? (
          <UserDataForm 
            fanFilter={fanFilter} 
            updateFanFilter={updateFanFilter}
          />
        ) : fanFilter.type === "FanFilters::MessageData" ? (
          <MessageDataForm
            csrfToken={csrfToken}
            team={team}
            fanFilter={fanFilter}
            updateFanFilter={updateFanFilter}
          />
        ) : null}
      </div>
      <button onClick={deleteFanFilter} style={deleteButtonStyle}>
        <i className="fa fa-times"></i>
      </button>
    </div>
  );
};

export default FanFilterForm;
