import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

import NewRefund from "./Refund/NewRefund";
import ReviewRefund from "./Refund/ReviewRefund";

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "0px"
}

const RefundModal = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  refund,
  fetchNewRefund,
  refundChanged,
  refundReasons,
  createRefund,
  submittingRefund,
  confirmRefund,
  canBypassRefundRestrictions
}) => (
  <div>
    <Popup
      open={(Object.keys(refund).length > 0)}
      contentStyle={modalContentStyle}
      closeOnDocumentClick={false}
      className={"popup-modal"}
      onClose={() => refundChanged({})}
    >
      <div className="popup-modal-container">
        {refund && refund.id ? (
          <ReviewRefund csrfToken={csrfToken}
                        team={team}
                        currentUser={currentUser}
                        confirm={confirm}
                        refund={refund}
                        refundChanged={refundChanged}
                        submittingRefund={submittingRefund}
                        confirmRefund={confirmRefund} />
        ) : (
          <NewRefund csrfToken={csrfToken}
                     team={team}
                     currentUser={currentUser}
                     confirm={confirm}
                     refund={refund}
                     refundChanged={refundChanged}
                     refundReasons={refundReasons}
                     createRefund={createRefund}
                     canBypassRefundRestrictions={canBypassRefundRestrictions}
                     submittingRefund={submittingRefund} />
        )}
      </div>
    </Popup>
  </div>
);

RefundModal.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  refund: PropTypes.object,
  fetchNewRefund: PropTypes.func.isRequired,
  refundChanged: PropTypes.func.isRequired,
  refundReasons: PropTypes.array,
  createRefund: PropTypes.func.isRequired,
  submittingRefund: PropTypes.bool,
  confirmRefund: PropTypes.func.isRequired,
  canBypassRefundRestrictions: PropTypes.bool
};

export default RefundModal;
