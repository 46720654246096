import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import PermissionRule from "./PermissionRule";

const popupStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "560px",
  "padding": "17px 24px 24px 24px"
}

const rolePermissionRules = (permissionSet) => {
  return (permissionSet.permission_rules || [])
    .filter((pr) => pr.rulable_type === "Role")
    .sort((a, b) => a.rulable_data.position - b.rulable_data.position);
}

const teamMembershipPermissionRules = (permissionSet, rolePermissionRule) => {
  return (permissionSet.permission_rules || [])
    .filter((pr) => pr.rulable_type === "TeamMembership")
    .filter((pr) => pr.rulable_data.role_id === rolePermissionRule.rulable_data.id)
    .sort((a, b) => a.rulable_data.name.localeCompare(b.rulable_data.name));
}

const selectAllChecked = (permissionSet) => {
  return (permissionSet.permission_rules || [])
    .every((pr) => pr.enabled);
}

const SetPermissionsPopup = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  permissionSet,
  permissionSetChanged,
  permissionRuleEnabledClicked,
  selectAllPermissionRulesClicked,
  submitPermissionSet,
  onPermissionSetSaved,
  guestTeamMemberships,
  promotersWithPermission
}) => (
  <Popup
    open={(Object.keys(permissionSet).length > 0)}
    contentStyle={popupStyle}
    closeOnDocumentClick={false}
    className={"popup-modal"}
    onClose={() => permissionSetChanged({})}
  >
    <div className="popup-modal-container">
      <div className="row">
        <div className="col d-flex">
          <h3 className="mb-0 align-self-center"
              style={{"fontSize": "20px"}}>
            <strong>
              <i className="fas fa-user-lock text-primary mr-2"></i>
              Set Permissions
            </strong>
          </h3>
        </div>
        <div className="col-xs-auto"
             style={{"paddingRight": "15px"}}>
          <a href="#"
             style={{"fontSize": "28px"}}
             className="text-dark"
             onClick={
               (e) => {
                 e.preventDefault();
                 permissionSetChanged({});
               }
             }>
            <i className="fal fa-times"></i>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>
            Define who has access to this <strong>{(permissionSet._element_type_human_name ? permissionSet._element_type_human_name : permissionSet.element_type)}</strong>.
          </p>
          <form onSubmit={
                  (e) => {
                    e.preventDefault();
                    submitPermissionSet(csrfToken, team, permissionSet, onPermissionSetSaved);
                  }
                }>
            <div className="my-3"
                 style={{
                   "height": "260px",
                   "overflowY": "scroll",
                   "border": "1px solid #ececec"
                 }}>
              <div className="form-row m-0"
                   style={{"borderBottom": "2px solid #fafafa"}}>
                <div className="col-12"
                     style={{
                       "margin": "14px 11px"
                     }}>
                  <div className="custom-control custom-checkbox custom-checkbox-table">
                    <input type="checkbox"
                           className="custom-control-input"
                           checked={selectAllChecked(permissionSet)}
                           onChange={
                             (e) => {
                               selectAllPermissionRulesClicked(e.target.checked);
                             }
                           }
                           id="select-all-permission-rules" />
                    <label className="custom-control-label"
                           style={{"fontSize": "14px"}}
                           htmlFor="select-all-permission-rules">
                      Select All
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row m-0"
                   style={{"paddingTop": "14px"}}>
                {rolePermissionRules(permissionSet).map((rolePermissionRule, index) =>
                  <React.Fragment key={index}>
                    <PermissionRule csrfToken={csrfToken}
                                    team={team}
                                    confirm={confirm}
                                    currentUser={currentUser}
                                    guestTeamMemberships={guestTeamMemberships}
                                    promotersWithPermission={promotersWithPermission}
                                    permissionRuleEnabledClicked={permissionRuleEnabledClicked}
                                    permissionRule={rolePermissionRule} />
                    {teamMembershipPermissionRules(permissionSet, rolePermissionRule).map((permissionRule, index) =>
                      <PermissionRule key={index}
                                      csrfToken={csrfToken}
                                      team={team}
                                      confirm={confirm}
                                      currentUser={currentUser}
                                      guestTeamMemberships={guestTeamMemberships}
                                      promotersWithPermission={promotersWithPermission}
                                      permissionRuleEnabledClicked={permissionRuleEnabledClicked}
                                      rolePermissionRule={rolePermissionRule}
                                      permissionRule={permissionRule} />
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 text-right">
                <a onClick={
                     (e) => {
                       e.preventDefault();
                       permissionSetChanged({});
                     }
                   }
                   href="#">
                  <strong>
                    Cancel
                  </strong>
                </a>
                <button type="submit"
                        style={{"borderRadius": "8px"}}
                        className="btn btn-primary ml-3">
                  <strong>Save</strong>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Popup>
);

SetPermissionsPopup.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  permissionSet: PropTypes.object,
  permissionSetChanged: PropTypes.func.isRequired,
  permissionRuleEnabledClicked: PropTypes.func.isRequired,
  selectAllPermissionRulesClicked: PropTypes.func.isRequired,
  submitPermissionSet: PropTypes.func.isRequired,
  onPermissionSetSaved: PropTypes.func.isRequired,
  guestTeamMemberships: PropTypes.array,
  promotersWithPermission: PropTypes.array
};

export default SetPermissionsPopup;

