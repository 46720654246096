import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import Iframe from 'react-iframe'

import {
  FORM_STATE,
  CUSTOMIZE_STATE
} from '../constants/offerModalConstants';

const offerMenuContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "135px",
  "padding": "0px",
  "zIndex": "1999"
}

const OfferPreview = ({
  team,
  offer,
  updateOfferFrontEndState,
  closeModal,
  defaultOffer,
  dataTableEl,
  acceptOfferFromModal,
  declineOfferFromModal,
  csrfToken
}) => (
  <div>
    <div className="row" style={{'borderBottom': "3px solid #f5f5f5", 'padding': "10px"}}>
      <div className="col-12">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     updateOfferFrontEndState(FORM_STATE);
                   }
                 }
                 className="text-muted mt-2 d-inline-block">
                <span>
                  <i className="fa fa-long-arrow-left mr-2"></i>
                  <strong>Back to edit</strong>
                </span>
              </a>
            </div>
            <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
              {["draft", "sent"].includes(offer.state) ? (
                <Popup arrow={false}
                       offsetY={5}
                       position="bottom right"
                       contentStyle={offerMenuContentStyle}
                       onOpen={
                         (e) => {
                           e.preventDefault();
                         }
                       }
                       trigger={open => (
                         offer.state === "draft" ? (
                           <span className="badge badge-secondary clickable p-2">
                             Draft
                             &nbsp;
                             <i className="fas fa-caret-down"></i>
                           </span>
                         ) : offer.state === "sent" ? (
                           <span className="badge badge-primary clickable p-2">
                             Sent
                             &nbsp;
                             <i className="fas fa-caret-down"></i>
                           </span>
                         ) :  null
                       )} >
                  {close => (
                    <div className='row text-left'>
                      <div className="col-12">
                        <ul className="list-group offer-inline-menu">
                          <li className="list-group-item">
                            <a href="#"
                               onClick={
                                 (e) => {
                                   e.preventDefault();
                                   close();
                                   acceptOfferFromModal(team, offer.id, csrfToken);
                                 }
                               }
                               className="text-muted">
                              <span className="text-dark">
                                Offer Accepted
                              </span>
                            </a>
                          </li>
                          <li className="list-group-item">
                            <a href="#"
                               onClick={
                                 (e) => {
                                   e.preventDefault();
                                   close();
                                   declineOfferFromModal(team, offer.id, csrfToken);
                                 }
                               }
                               className="text-muted">
                              <span className="text-dark">
                                Offer Declined
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </Popup>
              ) : (
                offer.state === "draft" ? (
                  <span className="badge badge-secondary p-2">
                    Draft
                  </span>
                ) : offer.state === "sent" ? (
                  <span className="badge badge-primary p-2">
                    Sent
                  </span>
                ) : offer.state === "accepted" ? (
                  <span className="badge badge-success p-2">
                    Accepted
                  </span>
                ) : offer.state === "declined" ? (
                  <span className="badge badge-danger p-2">
                    Declined
                  </span>
                ) : null
              )}
              <a href={"/teams/" + team.id + "/offers/" + offer.id + "/preview.pdf"}
                 target="_blank"
                 className="btn btn-muted ml-3">
                <i className="fas fa-download"></i>
              </a>
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     updateOfferFrontEndState(CUSTOMIZE_STATE);
                   }
                 }
                 className="btn btn-muted ml-3">
                <i className="fa fa-paper-plane"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container" style={{"padding": "0 50px"}}>
      <div className='row'>
        <div className='col-12'>
          <div className="embed-responsive" style={{"minHeight": "80vh"}}>
            <Iframe url={("/teams/" + team.id + "/offers/" + offer.id + "/preview")}
                  className="embed-responsive-item" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

OfferPreview.propTypes = {
  team: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  updateOfferFrontEndState: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultOffer: PropTypes.object.isRequired,
  dataTableEl: PropTypes.object,
  acceptOfferFromModal: PropTypes.func.isRequired,
  declineOfferFromModal: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired
};

export default OfferPreview;
