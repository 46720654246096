import React, { useRef } from "react";
import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";

const choosePhotoButtonStyle = {
  alignItems: "center",
  backgroundColor: "#1982C4",
  borderRadius: 5,
  color: "white",
  cursor: "pointer",
  display: "flex",
  fontSize: 12,
  fontWeight: 600,
  height: 40,
  justifyContent: "center",
  marginBottom: 0,
  width: 100,
};

const columnStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 5,
};

const labelStyle = {
  color: "#333333",
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 8,
};

const logoStyle = {
  height: 80,
  marginRight: 5,
};

const rowStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

const subtextStyle = {
  color: "#333333",
  fontSize: 12,
  opacity: 0.5,
};

const removeLinkStyle = {
  color: "#FF595E",
  cursor: "pointer",
  fontSize: 12,
  width: "fit-content",
};

const hiddenInputStyle = {
  display: "none",
};

const LogoSelect = () => {
  const {
    logoThumbUrl,
    setLogoThumbUrl,
    setLogoFile,
  } = useMarketingSettingsContext();

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoThumbUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveClick = () => {
    setLogoFile(null);
    setLogoThumbUrl("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div>
      <div style={labelStyle}>Logo</div>
      <div style={rowStyle}>
        {logoThumbUrl ? (
          <img src={logoThumbUrl} style={logoStyle} alt="Logo"/>
        ) : (
          null
        )}
        <div style={columnStyle}>
          <div style={subtextStyle}>Recommended resolution of at least 256px wide.</div>
          <label style={choosePhotoButtonStyle}>
            Choose Photo
            <input type="file" style={hiddenInputStyle} onChange={handleFileChange} ref={fileInputRef} />
          </label>
          {logoThumbUrl ? (
            <a href="#" onClick={(e) => { 
              e.preventDefault(); 
              if (window.confirm("Are you sure you want to remove the logo?")) { 
                handleRemoveClick(); 
              } 
            }} style={removeLinkStyle}>Remove</a>
          ) : (
            null
          )}
        </div>
      </div>
    </div>
  );
};

export default LogoSelect;
