var moment = require('moment-timezone');

export const offsetDateForBrowser = (date) => {
  if(!date){ return date; }

  var browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // The date string should be passed from the server
  // in the correct time_zone for the VenueOwnership.
  // Moment will parse this date string using the timeZone
  // specified and produce a Date object in the local timeZone.
  //
  // Example:
  //   date = 2022-11-27T22:45:00.833-05:00
  //   gon.timeZone = America/New_York
  //   browserTZ = Pacific/Honolulu
  //   original = Sun Nov 27 2022 17:45:00 GMT-1000 (Hawaii-Aleutian Standard Time)
  //
  // The original time in EST was 10:45 PM, so when parsed by Moment
  // it is adjusted to the local time as 5:45 PM Hawaiian time.
  // Even though the user is in Hawaii, we want them to see the original
  // time of 10:45 PM.
  var original = moment.tz(date, gon.timeZone);

  // Now that we have the time in the local time_zone, we need
  // to offset the time to compensate for the time_zone difference.
  // Moment timezone will do this work for us by passing "true"
  // as the second argument while we convert to the local time_zone.
  //
  // Example:
  //   date = 2022-11-27T22:45:00.833-05:00
  //   gon.timeZone = America/New_York
  //   browserTZ = Pacific/Honolulu
  //   original = Sun Nov 27 2022 17:45:00 GMT-1000 (Hawaii-Aleutian Standard Time)
  //   adjusted = Sun Nov 27 2022 22:45:00 GMT-1000 (Hawaii-Aleutian Standard Time)
  //
  // The time is offset by 5 hours to be 10:45 PM Hawaiin time so that we
  // can display the desired value to the user. Under the hood, this new
  // time is actually 3:45 AM (the next day) in EST time. We will have to
  // adjust a second time when we send the value back to the server.
  var adjusted = original.clone().tz(browserTZ, true);

  return adjusted.toDate();
}

// Opposite of offsetDateForBrowser
export const offsetDateForServer = (date) => {
  if(!date){ return date; }

  var browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var original = moment.tz(date, browserTZ);
  var adjusted = original.clone().tz(gon.timeZone, true);

  return adjusted.toDate();
}

// On some views (that involve multiple Venues), we can't set a single
// timeZone for the page since it can change as the Venue changes.
// In an ideal world the user would be picking from VenueOwnerships
// instead of Venues and we wouldn't need this...
export const setGonTimeZoneFromVenue = (venueOwnerships, venueId) => {
  var currentVenueOwnership = venueOwnerships.find((vo) =>
    vo.venueable_type === "Venue"
      && vo.venueable_id === venueId
  );

  var timeZone;
  if(currentVenueOwnership && currentVenueOwnership.tzinfo_identifier){
    timeZone = currentVenueOwnership.tzinfo_identifier;
  } else {
    timeZone = "America/New_York";
  }

  gon.timeZone = timeZone;
  return timeZone;
}

export const offsetDateToTimezone = (date, timeZone) => {
  var original = moment.tz(date, timeZone);
  var adjusted = original.clone().tz(gon.timeZone, true);
  return adjusted.toDate();
}

export const offsetDateFromTimeZone = (date, timeZone) => {
  var original = moment.tz(date, gon.timeZone);
  var adjusted = original.clone().tz(timeZone, true);
  return adjusted.toDate();
}
