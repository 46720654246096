import { combineReducers } from 'redux';
import {
  ADD_TEAM_MEMBER_CLICKED,
  CANCEL_NEW_INVITATION,
  NEW_INVITATION_FIRST_NAME_CHANGED,
  NEW_INVITATION_LAST_NAME_CHANGED,
  NEW_INVITATION_EMAIL_CHANGED,
  NEW_INVITATION_ROLE_ID_CHANGED,
  NEW_INVITATION_COMPANY_CHANGED,
  NEW_INVITATION_VENUE_OWNERSHIP_CLICKED,
  NEW_INVITATION_CREATION_SUCCESS,
  NEW_INVITATION_ERRORS_CHANGED,
  NEW_INVITATION_ENABLE_ALL_VENUES_CLICK,
  NEW_INVITATION_DISABLE_ALL_VENUES_CLICK,
  EDIT_TEAM_MANAGEABLE_CLICKED,
  EDIT_TEAM_MANAGEABLE_CANCELED,
  TEAM_MANAGEABLE_DELETED,
  TEAM_MANAGEABLE_TO_EDIT_FIRST_NAME_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_LAST_NAME_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_EMAIL_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_ROLE_ID_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_VENUE_OWNERSHIP_CLICKED,
  TEAM_MANAGEABLE_TO_EDIT_ENABLE_ALL_VENUES_CLICK,
  TEAM_MANAGEABLE_TO_EDIT_DISABLE_ALL_VENUES_CLICK,
  TEAM_MANAGEABLE_TO_EDIT_UPDATED,
  TEAM_MANAGEABLE_TO_EDIT_ERRORS_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_COMPANY_CHANGED
} from '../constants/teamManagerConstants';

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const teamMemberships = (state = [], action) => {
  switch (action.type) {
    case TEAM_MANAGEABLE_TO_EDIT_UPDATED:
      return [...state].map((teamMembership) => {
        if(teamMembership.id === action.teamManageable.id && teamMembership.type_for_ui === action.teamManageable.type_for_ui){
          return Object.assign({}, action.teamManageable, {currentlyEditing: false});
        } else {
          return Object.assign({}, teamMembership, {currentlyEditing: false});
        }
      });
    case TEAM_MANAGEABLE_DELETED:
      return [...state].filter((teamMembership) => (
        !(teamMembership.type_for_ui === action.teamManageable.type_for_ui
          && teamMembership.id === action.teamManageable.id)
      ));
    case EDIT_TEAM_MANAGEABLE_CANCELED:
      return [...state].map((teamMembership) => (
        Object.assign({}, teamMembership, {currentlyEditing: false})
      ));
    case EDIT_TEAM_MANAGEABLE_CLICKED:
      return [...state].map((teamMembership) => {
        var currentlyEditing = (
          teamMembership.type_for_ui === action.teamManageable.type_for_ui
            && teamMembership.id === action.teamManageable.id
        );

        return Object.assign({}, teamMembership, {
          currentlyEditing: currentlyEditing
        });
      });
    default:
      return state;
  }
};

const pendingInvitations = (state = [], action) => {
  switch (action.type) {
    case TEAM_MANAGEABLE_TO_EDIT_UPDATED:
      return [...state].map((teamMembership) => {
        if(teamMembership.id === action.teamManageable.id && teamMembership.type_for_ui === action.teamManageable.type_for_ui){
          return Object.assign({}, action.teamManageable, {currentlyEditing: false});
        } else {
          return Object.assign({}, teamMembership, {currentlyEditing: false});
        }
      });
    case TEAM_MANAGEABLE_DELETED:
      return [...state].filter((teamMembership) => (
        !(teamMembership.type_for_ui === action.teamManageable.type_for_ui
          && teamMembership.id === action.teamManageable.id)
      ));
    case EDIT_TEAM_MANAGEABLE_CANCELED:
      return [...state].map((teamMembership) => (
        Object.assign({}, teamMembership, {currentlyEditing: false})
      ));
    case NEW_INVITATION_CREATION_SUCCESS:
      return [...state, action.invitation];
    case EDIT_TEAM_MANAGEABLE_CLICKED:
      return [...state].map((teamMembership) => {
        var currentlyEditing = (
          teamMembership.type_for_ui === action.teamManageable.type_for_ui
            && teamMembership.id === action.teamManageable.id
        );

        return Object.assign({}, teamMembership, {
          currentlyEditing: currentlyEditing
        });
      });
    default:
      return state;
  }
};

const addingNewInvitation = (state = false, action) => {
  switch (action.type) {
    case NEW_INVITATION_CREATION_SUCCESS:
      return false;
    case ADD_TEAM_MEMBER_CLICKED:
      return true;
    case CANCEL_NEW_INVITATION:
      return false;
    default:
      return state;
  }
};

const newInvitation = (state = {}, action) => {
  switch (action.type) {
    case NEW_INVITATION_CREATION_SUCCESS:
      return {};
    case NEW_INVITATION_FIRST_NAME_CHANGED:
      return Object.assign({}, state, {firstName: action.firstName});
    case NEW_INVITATION_LAST_NAME_CHANGED:
      return Object.assign({}, state, {lastName: action.lastName});
    case NEW_INVITATION_EMAIL_CHANGED:
      return Object.assign({}, state, {email: action.email});
    case NEW_INVITATION_COMPANY_CHANGED:
      return Object.assign({}, state, {company: action.company});
    case NEW_INVITATION_ROLE_ID_CHANGED:
      return Object.assign({}, state, {roleId: action.roleId});
    case NEW_INVITATION_ERRORS_CHANGED:
      return Object.assign({}, state, {errors: action.errors});
    case NEW_INVITATION_VENUE_OWNERSHIP_CLICKED:
      var venueOwnershipIds = (state.venueOwnershipIds ? [...state.venueOwnershipIds] : []);

      if (venueOwnershipIds.includes(action.venueOwnershipId)){
        venueOwnershipIds = venueOwnershipIds.filter((id) => {
          return id !== action.venueOwnershipId
        });
      } else {
        venueOwnershipIds = venueOwnershipIds.concat(action.venueOwnershipId);
      }

      return Object.assign({}, state, {venueOwnershipIds: venueOwnershipIds});
    case NEW_INVITATION_ENABLE_ALL_VENUES_CLICK:
      var venueOwnershipIds = action.venueOwnerships.map((venueOwnership) => {
        return venueOwnership.id;
      });

      return Object.assign({}, state, {venueOwnershipIds: venueOwnershipIds});
    case NEW_INVITATION_DISABLE_ALL_VENUES_CLICK:
      return Object.assign({}, state, {venueOwnershipIds: []});
    default:
      return state;
  }
};

const roles = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const newInvitationEnableAllVenues = (state = true, action) => {
  switch (action.type) {
    case NEW_INVITATION_CREATION_SUCCESS:
      return true;
    case NEW_INVITATION_ENABLE_ALL_VENUES_CLICK:
      return false;
    case NEW_INVITATION_DISABLE_ALL_VENUES_CLICK:
      return true;
    default:
      return state;
  }
};

const teamManageableToEdit = (state = {}, action) => {
  switch (action.type) {
    case EDIT_TEAM_MANAGEABLE_CLICKED:
      return action.teamManageable;
    case TEAM_MANAGEABLE_TO_EDIT_FIRST_NAME_CHANGED:
      return Object.assign({}, state, {first_name: action.firstName});
    case TEAM_MANAGEABLE_TO_EDIT_LAST_NAME_CHANGED:
      return Object.assign({}, state, {last_name: action.lastName});
    case TEAM_MANAGEABLE_TO_EDIT_EMAIL_CHANGED:
      return Object.assign({}, state, {email: action.email});
    case TEAM_MANAGEABLE_TO_EDIT_COMPANY_CHANGED:
      return Object.assign({}, state, {company: action.company});
    case TEAM_MANAGEABLE_TO_EDIT_ROLE_ID_CHANGED:
      return Object.assign({}, state, {role_id: action.roleId});
    case TEAM_MANAGEABLE_TO_EDIT_ERRORS_CHANGED:
      return Object.assign({}, state, {errors: action.errors});
    case TEAM_MANAGEABLE_TO_EDIT_VENUE_OWNERSHIP_CLICKED:
      var venueOwnershipIds = (state.venue_ownership_ids ? [...state.venue_ownership_ids] : []);

      if (venueOwnershipIds.includes(action.venueOwnershipId)){
        venueOwnershipIds = venueOwnershipIds.filter((id) => {
          return id !== action.venueOwnershipId
        });
      } else {
        venueOwnershipIds = venueOwnershipIds.concat(action.venueOwnershipId);
      }

      return Object.assign({}, state, {venue_ownership_ids: venueOwnershipIds});
    case TEAM_MANAGEABLE_TO_EDIT_ENABLE_ALL_VENUES_CLICK:
      var venueOwnershipIds = action.venueOwnerships.map((venueOwnership) => {
        return venueOwnership.id;
      });

      return Object.assign({}, state, {venue_ownership_ids: venueOwnershipIds});
    case TEAM_MANAGEABLE_TO_EDIT_DISABLE_ALL_VENUES_CLICK:
      return Object.assign({}, state, {venue_ownership_ids: []});
    default:
      return state;
  }
};

const teamManageableToEditEnableAllVenues = (state = true, action) => {
  switch (action.type) {
    case EDIT_TEAM_MANAGEABLE_CLICKED:
      return true;
    case TEAM_MANAGEABLE_TO_EDIT_ENABLE_ALL_VENUES_CLICK:
      return false;
    case TEAM_MANAGEABLE_TO_EDIT_DISABLE_ALL_VENUES_CLICK:
      return true;
    default:
      return state;
  }
};

const teamManagerReducer = combineReducers({ venueOwnerships, teamMemberships, pendingInvitations, addingNewInvitation, newInvitation, roles, csrfToken, team, newInvitationEnableAllVenues, teamManageableToEdit, teamManageableToEditEnableAllVenues });

export default teamManagerReducer;
