// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OfferManager from '../components/OfferManager';
import * as actions from '../actions/offerManagerActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => {
  return {
    modalIsOpen: state.modalIsOpen,
    venuesOptions: state.venuesOptions,
    csrfToken: state.csrfToken,
    team: state.team,
    defaultOffer: state.defaultOffer,
    dataTableTitle: state.dataTableTitle,
    dataTableDataSource: state.dataTableDataSource,
    dataTableOrder: state.dataTableOrder,
    dataTableColumns: state.dataTableColumns,
    dataTableClassNames: state.dataTableClassNames,
    dataTableEl: state.dataTableEl,
    currentUser: state.currentUser,
    offer: state.offer,
    offerTemplates: state.offerTemplates,
    pipeline: state.pipeline,
    venueSelected: state.venueSelected,
    offerToLink: state.offerToLink,
    venueOwnerships: state.venueOwnerships,
    offerExists: state.offerExists
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: () => dispatch(actions.openModal()),
    closeModal: (defaultOffer, dataTableEl) => dispatch(actions.closeModal(dispatch, defaultOffer, dataTableEl)),
    declineOffer: (team, offerId, csrfToken, rowTableEl) => dispatch(actions.declineOffer(dispatch, team, offerId, csrfToken, rowTableEl)),
    deleteOffer: (team, offerId, csrfToken, rowTableEl) => dispatch(actions.deleteOffer(dispatch, team, offerId, csrfToken, rowTableEl)),
    acceptOffer: (team, offerId, csrfToken, rowTableEl) => dispatch(actions.acceptOffer(dispatch, team, offerId, csrfToken, rowTableEl)),
    onInit: (defaultOffer) => dispatch(actions.onInit(dispatch, defaultOffer)),
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    fetchOfferData: (team, offerId, frontEndState) => dispatch(actions.fetchOfferData(dispatch, team, offerId, frontEndState)),
    fetchPipeline: (team, pipelineId) => dispatch(actions.fetchPipeline(dispatch, team, pipelineId)),
    pipelineChanged: (pipeline) => dispatch(actions.pipelineChanged(pipeline)),
    offerToLinkChanged: (offer) => dispatch(actions.offerToLinkChanged(offer))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OfferManager);
