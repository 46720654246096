/* eslint-disable import/prefer-default-export */

export const DATA_TABLE_EL_CHANGED = 'DATA_TABLE_EL_CHANGED';

export const NEW_OUTREACH_CHANGED = 'NEW_OUTREACH_CHANGED';
export const OUTREACH_EXISTS_CHANGED = 'OUTREACH_EXISTS_CHANGED';

export const OFFER_CHANGED = 'OFFER_CHANGED';
export const OFFER_MODAL_OPENED = 'OFFER_MODAL_OPENED';
export const OFFER_MODAL_CLOSED = 'OFFER_MODAL_CLOSED';

export const FOLLOW_UP_CHANGED = 'FOLLOW_UP_CHANGED';

export const CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED = 'CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED';
export const CLOSE_CALENDAR_EVENT_MODAL = 'CLOSE_CALENDAR_EVENT_MODAL';

export const PIPELINE_CHANGED = 'PIPELINE_CHANGED';
export const SUBMITTING_FORM_CHANGED = 'SUBMITTING_FORM_CHANGED';
