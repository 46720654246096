import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const canSubmitForm = (refund, submittingRefund) => {
  return (
    refund
      && !submittingRefund
  );
}

const ReviewRefund = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  refund,
  refundChanged,
  submittingRefund,
  confirmRefund
}) => {
  return (
    <React.Fragment>
      <div className="row m-0">
        <div className="col d-flex align-items-center"
             style={{
               "padding": "25px 25px 22px 25px"
             }}>
          <p className="mb-0"
             style={{"fontSize": "20px"}}>
            <strong>Issue Refund</strong>
          </p>
        </div>
        <div className="col-xs-auto d-flex align-items-center"
             style={{"paddingRight": "25px"}}>
          <a href="#"
             onClick={
               (e) => {
                 e.preventDefault();
                 refundChanged({});
               }
             }
             style={{"fontSize": "24px"}}
             className="text-muted">
            <i className="fal fa-times"></i>
          </a>
        </div>
      </div>
      <div className="row m-0"
           style={{"borderTop": "2px solid #f5f5f5"}}>
        <div className="col-12"
             style={{
               "padding": (refund.state === "reviewing" ? "33px 25px 0px 25px" : "33px 25px")
             }}>
          <p className="text-dark"
             style={{"fontSize": "16px", "marginBottom": "5px"}}>
            <strong>Order details</strong>
          </p>
          <table className="table table-sm table-borderless vertical-middle mt-2 small mb-0"
                 style={{tableLayout: "fixed"}}>
            <colgroup>
              <col span={1} style={{"width": "50%"}} />
              <col span={1} style={{"width": "50%"}} />
            </colgroup>
            <tbody>
              {refund && refund.order ? (
                <React.Fragment>
                  <tr>
                    <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                      Order #:
                    </td>
                    <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                      {refund.order.id}
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                      Purchased by:
                    </td>
                    <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                      {refund.order.purchased_by}
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                      Purchase date:
                    </td>
                    <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                      {refund.order.human_purchased_at}
                    </td>
                  </tr>
                </React.Fragment>
              ) : null}
              <tr>
                <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                  Original payment:
                </td>
                <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                  {formatter.format(parseFloat(refund.max_amount))}
                </td>
              </tr>
              <tr>
                <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                  Refund status:
                </td>
                <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                  {refund.state === "reviewing" ? "Pending" : "In Progress"}
                </td>
              </tr>
              <tr>
                <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                  Refund amount:
                </td>
                <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                  {formatter.format(parseFloat(refund.amount))}
                </td>
              </tr>
              <tr>
                <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                  Processing fee:
                </td>
                <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                  {formatter.format(parseFloat(refund.ach_fee))}
                </td>
              </tr>
              <tr>
                <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                  <strong>Total:</strong>
                </td>
                <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                  <strong>{formatter.format(parseFloat(refund.ach_organizer_total))}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {refund.state === "reviewing" ? (
          <div className="col-12">
            <hr style={{
                  "marginBottom": "28px",
                  "borderTop": "1px solid #e6e6e6"
                }} />
            <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(!canSubmitForm(refund, submittingRefund)){
                        return false;
                      }

                      confirmRefund(csrfToken, team, refund.order, refund);
                    }
                  }>
              <div className="form-row"
                   style={{"marginTop": "9px", "marginBottom": "18px"}}>
                <div className="col-12 text-right">
                  {!submittingRefund ? (
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           refundChanged({});
                         }
                       }
                       className="mr-3">
                      <strong>Cancel</strong>
                    </a>
                  ) : null}
                  <button type="submit"
                          disabled={!canSubmitForm(refund, submittingRefund)}
                          className="btn btn-primary btn-sm rounded">
                    {submittingRefund ? (
                      <React.Fragment>
                        <img src="/uploading-loading.gif"
                             className="mr-1"
                             style={{width: "16px"}} />
                        <strong>Issue Refund</strong>
                      </React.Fragment>
                    ) : (
                      <strong>Issue Refund</strong>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

ReviewRefund.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  refund: PropTypes.object,
  refundChanged: PropTypes.func.isRequired,
  submittingRefund: PropTypes.bool,
  confirmRefund: PropTypes.func.isRequired
};

export default ReviewRefund;
