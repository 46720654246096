import React, { useEffect, useState } from 'react';
import ArtistProfile from './ArtistProfile'
import AgentSuggestion from './AgentSuggestion'
import Popup from "reactjs-popup";
import styled from "styled-components";
import * as axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const AgentOptions = styled.div`
  .list-group-item {
    cursor: pointer;

    &.agent-edit {
      color: #030F1C;

      i {
        color: #030F1C;
      }
    }

    &.agent-remove {
      color: #FF595E;

      i {
        color: #FF595E;
      }
    }
  }
`
const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px",
  "marginTop": "10px",
  "zIndex": "3000"
};

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "400px",
  "padding": "20px"
};

const ArtistRepresentation = ({artistId, agencies, csrfToken}) =>{
  const [agenciesViewStatus, setAgenciesViewStatus] = useState({});
  const [editAgentId, setEditAgentId] = useState(null);
  const [removeAgentId, setRemoveAgentId] = useState(null);

  useEffect(() => {
    const status = {}
    agencies.map ((agency) => status[agency.id] = false)
    setAgenciesViewStatus(status);
  }, [agencies])

  const onClickView = (agencyId) => {
    const status = {...agenciesViewStatus};
    status[agencyId] = !status[agencyId];
    setAgenciesViewStatus(status);
  };

  const handleEditAgent = (agentId) => {
    setEditAgentId(agentId);
  };

  const handleRemoveAgent = (agentId) => {
    setRemoveAgentId(agentId);
  }

  const handleOnEditSuggestionClose = () => {
    setEditAgentId(null);
  }

  const handleOnRemoveAgentClose = () => {
    setRemoveAgentId(null);
  }

  const handleOnRemoveAgentSubmit = async () => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    try {
      await axios.post(`/agent_suggestions`,{
        artist_id: artistId,
        agent_id: removeAgentId,
        user_intent: 'user_intent_delete',
      });
      setRemoveAgentId(null);

      toast.success("Your agent delete suggestion is submitted", {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: true,
        autoClose: 5000,
        hideProgressBar: true
      });
    } catch(err) {
      toast.error(err.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: true,
        autoClose: 5000,
        hideProgressBar: true
      });
    }
  }

  return(
    <div className='row d-flex flex-column artist-representations'>
      <ToastContainer />
      <div className="col-12">
        <h1 className='normal-number font-700 pb-4'>Artist Representation</h1>
      </div>
      <div className="col-12">
        {agencies.length === 0 && (
          <AgentSuggestion
            artistId={artistId}
            agentInfo={{}}
            noAgent={true}
            csrfToken={csrfToken}
            intent={'user_intent_create'}
          />
        )}
        {removeAgentId && (
          <Popup
            open={removeAgentId}
            contentStyle={modalContentStyle}
            closeOnDocumentClick={false}
          >
            {closeDeletePopup => (
              <div>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <h6 style={{"marginBottom": "16px"}}>
                          <strong>Remove Agent?</strong>
                        </h6>
                      </div>
                    </div>
                    <p>
                      Is this agent no longer associated with this artist? Agent updates are reviewed by the Opendate team. You’ll receive a notification when this agent is updated in the app.
                    </p>
                    <div className="text-right">
                      <button
                        className="btn btn-external-inverse"
                        onClick={handleOnRemoveAgentClose}>
                        Cancel
                      </button>
                      <button
                        className="btn btn-danger ml-3"
                        onClick={handleOnRemoveAgentSubmit}>
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Popup>
        )}
        {agencies.map(agency => {
          return(
            <div className='card p-4' key={agency.id}>
              <div className='row'>
                { editAgentId === agency.id ? (
                  <div className="col-12">
                    <AgentSuggestion
                      artistId={artistId}
                      agentInfo={agency}
                      noAgent={false}
                      csrfToken={csrfToken}
                      onClose={handleOnEditSuggestionClose}
                      intent={'user_intent_update'}
                    />
                  </div>
                ) : (
                  <div className='pb-2 d-flex col-12'>
                    {agency.agency.logo_thumb_url && <img className='agent-avatar' src={agency.agency.logo_thumb_url}></img>}
                    <div className='d-flex flex-column'>
                      <h2 className='normal-medium-text font-700'>{agency.name}</h2>
                      <span className='normal-medium-text'>{agency.agency.name}</span>
                      <span className='normal-medium-text'>{agency.email}</span>
                    </div>
                    <div className="ml-auto">
                      <Popup
                        arrow={false}
                        offsetY={5}
                        position="bottom right"
                        contentStyle={settingsContentStyle}
                        onOpen={
                          (e) => {
                            e.preventDefault();
                          }
                        }
                        trigger={open => (
                          <a
                            href="#"
                            onClick={(e) => {e.preventDefault();}}
                            className='btn btn-overflow'
                          >
                            <i className="fa fa-ellipsis-v"></i>
                          </a>
                        )}
                      >
                        {close => (
                          <AgentOptions className='row text-left'>
                            <div className="col-12">
                              <ul className="list-group offer-inline-menu highlight-primary">
                                <li className="list-group-item p-3 agent-edit" onClick={() => handleEditAgent(agency.id)}>
                                  <i className="fas fa-pen mr-3"></i>
                                  Edit Agent
                                </li>
                                <li className="list-group-item p-3 agent-remove" onClick={() => {handleRemoveAgent(agency.id); close();}}>
                                  <i className="fas fa-trash mr-3"></i>
                                  Remove Agent
                                </li>
                              </ul>
                            </div>
                          </AgentOptions>
                        )}
                      </Popup>
                    </div>
                  </div>
                )}
                <div className='mt-2 col-12'>
                  <div className="row">
                    <div className="col-12">
                      <span className='extra-small text-muted text-uppercase'>Artist Roster</span>
                    </div>
                  </div>
                  <div className="row">
                    {agency.artists.slice(0, 9).sort((a,b)=> a.is_star === b.is_star ? 0 : a.is_star? -1 : 1).sort((a,b)=>a.name - b.name).map((item, index) => {
                      return <ArtistProfile key={index} {...item} />
                    })}
                    {agency.artists.length > 9 && (
                      <div className="col-12" id={agenciesViewStatus[agency.id] ? "true" : "false"}>
                        {agenciesViewStatus[agency.id] && (
                          <div className="row">
                            {agency.artists.slice(9).sort((a,b)=> a.is_star === b.is_star ? 0 : a.is_star? -1 : 1).sort((a,b)=>a.name - b.name).map((item, index) => {
                              return <ArtistProfile key={index} {...item} />
                            })}
                          </div>
                        )}
                        <div className="row col-12">
                          <button className="btn btn-link btn-sm normal-text pl-0" onClick={() => onClickView(agency.id)}>
                            View {agenciesViewStatus[agency.id] ? 'Less' : 'More'}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className='col-12 d-flex mt-2 align-items-center justify-content-end mt-2'>
                      <i className="fas fa-star"></i><p className='mb-0 ml-2 footer-note'>Denotes artists that have played at your venue</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ArtistRepresentation
