/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  UPDATE_UI_STATE,
  OFFER_TO_CHANGED,
  OFFER_BODY_CHANGED
} from '../constants/offerV2PreviewConstants';

export const updateUIState = (uiState) => ({
  type: UPDATE_UI_STATE,
  uiState
});

export const updateOfferTo = (to) => ({
  type: OFFER_TO_CHANGED,
  to
});

export const updateOfferBody = (body) => ({
  type: OFFER_BODY_CHANGED,
  body
});

export const sendOffer = (dispatch, csrfToken, team, offer, onSuccess) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offers/" + offer.id + "/send_offer", {
        offer: {
          email_body: offer.email_body,
          last_sent_to: offer.last_sent_to
        }
      })
      .then(({ data }) => {
        onSuccess(data);
      })
  };
};
