import { combineReducers } from 'redux';
import { SELECTED_VENUE_CHANGED, UPDATE_RECOMMENDATIONS, UPDATE_ACTIVE_PAGE, LOADING_STARTED, LOADING_STOPPED } from '../constants/recommendationsTableConstants';

const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueSelected = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_VENUE_CHANGED:
      return action.venue;
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const recommendations = (state = [], action) => {
  switch (action.type) {
    case UPDATE_RECOMMENDATIONS:
      return action.recommendations;
    default:
      return state;
  }
};

const pagedRecommendations = (state = [], action) => {
  switch (action.type) {
    case UPDATE_RECOMMENDATIONS:
      return action.recommendations.slice(0, action.itemsCountPerPage);
    case UPDATE_ACTIVE_PAGE:
      var startIndex = ((action.page - 1) * action.itemsCountPerPage);
      var endIndex   = (startIndex + action.itemsCountPerPage);
      return [...action.recommendations].slice(startIndex, endIndex);
    default:
      return state;
  }
};

const activePage = (state = 1, action) => {
  switch (action.type) {
    case UPDATE_RECOMMENDATIONS:
      return 1;
    case UPDATE_ACTIVE_PAGE:
      return action.page;
    default:
      return state;
  }
};

const itemsCountPerPage = (state = 10, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case LOADING_STARTED:
      return true;
    case LOADING_STOPPED:
      return false;
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const recommendationsTableReducer = combineReducers({ venuesOptions, venueSelected, team, recommendations, activePage, pagedRecommendations, itemsCountPerPage, isLoading, csrfToken });

export default recommendationsTableReducer;
