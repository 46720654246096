import { combineReducers } from 'redux';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const confirm = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const showPageFrame = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const incomeItems = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const incomeTotal = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const incomeEstimateTotal = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const incomeForecastTotal = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const expenseItems = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const expenseTotal = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const expenseEstimateTotal = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const expenseForecastTotal = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const profitLoss = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const profitLossEstimate = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const profitLossForecast = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ticketsSold = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ticketsSoldForecast = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const profitAndLossReducer = combineReducers({
  csrfToken,
  team,
  confirm,
  currentUser,
  showPageFrame,
  incomeItems,
  incomeTotal,
  incomeEstimateTotal,
  incomeForecastTotal,
  expenseItems,
  expenseTotal,
  expenseEstimateTotal,
  expenseForecastTotal,
  profitLoss,
  profitLossEstimate,
  profitLossForecast,
  ticketsSold,
  ticketsSoldForecast,
});

export default profitAndLossReducer;
