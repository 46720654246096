import PropTypes from 'prop-types';
import React from 'react';

import { BarChart, Bar, XAxis, YAxis, LabelList, Cell, ResponsiveContainer } from 'recharts';

class CapacityBarChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data
    };
  }

  render() {
    return (
      <div>
        <ResponsiveContainer width="100%" height={150}>
          <BarChart data={this.state.data} layout="vertical">
            <XAxis dataKey="capacity"
                   type="number"
                   hide={true}
                   padding={{ left: 0, right: 60 }} />
            <YAxis type="category" hide={true} />
            <Bar dataKey="capacity"
                 barSize={30}
                 radius={[2, 2, 2, 2]}>
              {this.state.data.map((entry, index) => (
                <Cell key={index} fill={ entry.background }/>
              ))}
              <LabelList dataKey="capacity"
                         position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default CapacityBarChart;
