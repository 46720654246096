/* eslint-disable import/prefer-default-export */

import {
  CALENDAR_EVENT_CHANGED,
  PERFORMERS_CHANGED,
  SELECTED_PERFORMER_CHANGED
} from '../constants/linkOfferModalConstants';

const axios = require('axios').default;

export const calendarEventChanged = (calendarEvent) => ({
  type: CALENDAR_EVENT_CHANGED,
  calendarEvent
});

export const fetchCalendarEventPerformers = (dispatch, team, calendarEventId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/performers", {
        params: {
          performable_type: "CalendarEvent",
          performable_id: calendarEventId
        }
      })
      .then(({ data }) => {
        dispatch(performersChanged(data));
      })
  };
};

export const performersChanged = (performers) => ({
  type: PERFORMERS_CHANGED,
  performers
});

export const selectedPerformerChanged = (performer) => ({
  type: SELECTED_PERFORMER_CHANGED,
  performer
});
