import PropTypes from 'prop-types';
import React from 'react';

import Tippy from '@tippyjs/react';
import MapWithMarkerClusters from '../../MapWithMarkerClusters/components/MapWithMarkerClusters';

const FansByLocation = ({
  csrfToken,
  team,
  currentUser,
  geoCoordinates,
  isLoadingGeoCoordinates
}) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col d-flex align-items-center">
          <p style={{
              "fontSize": "20px",
              "marginBottom": "6px"
            }}>
            <strong>
              Fans By Location
            </strong>
            <Tippy content="Where are your fans coming from? Explore by location.">
              <i className="fas fa-info-circle text-muted ml-2"
                 style={{"fontSize": "16px"}}></i>
            </Tippy>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {isLoadingGeoCoordinates ? (
            <div className="row">
              <div className="col-12 text-center my-4">
                <img src="/uploading-loading.gif"
                      className="mr-1"
                      style={{width: "16px"}} />
              </div>
            </div>
          ) : (
            geoCoordinates.length === 0 ? (
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-center flex-column text-center"
                    style={{"height": "350px"}}>
                  <div className="dot d-flex justify-content-center align-items-center"
                      style={{
                        "width": "44px",
                        "height": "44px",
                        "background": "#F3F9FC",
                        "borderRadius": "50%",
                        "border": "4px solid #BBE0F6"
                      }}>
                    <i className="fa-regular fa-location-dot text-primary" style={{"fontSize": "21px"}}></i>
                  </div>
                  <p className="mb-0 mt-2 text-muted"
                    style={{
                      "fontSize": "14px"
                    }}>
                    <strong>Where are your fans coming from? Explore by location.</strong>
                  </p>
                  <p className="small mb-0 text-muted">
                    No Data Yet
                  </p>
                </div>
              </div>
            ) : (
              <div className="embed-responsive">
                <MapWithMarkerClusters geoCoordinates={geoCoordinates}
                                  height={"360px"}
                                  lat={"39.5"}
                                  lon={"-98.35"} />
              </div>
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

FansByLocation.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  geoCoordinates: PropTypes.array,
  isLoadingGeoCoordinates: PropTypes.bool
};

export default FansByLocation;
