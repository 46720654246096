import PropTypes from 'prop-types';
import React from 'react';

import ContainerlessDataTable from '../../DataTable/components/ContainerlessDataTable';

const reduceFilters = (filters) => {
  return filters.filter((filter) => {
    return filter.value.length > 0;
  }).reduce((params, filter) => {
    var newParam = {};
    newParam[filter.paramName] = filter.value;

    return Object.assign(params, newParam);
  }, {});
}

const ContainerlessTableView = ({
  title,
  searchedSearchTerm,
  dataSource,
  columns,
  order,
  filters,
  tableClassNames,
  dataTableEl,
  dataTableElChanged,
  rowCallback,
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div key={filters.map(filter => filter.value)}>
            <ContainerlessDataTable
              title={title}
              searchedSearchTerm={searchedSearchTerm}
              dataSource={dataSource}
              columns={columns}
              reducedFilters={reduceFilters(filters)}
              tableClassNames={tableClassNames}
              onInitComplete={(settings, json) => {
                dataTableEl.current = settings.oInstance[0]
                dataTableElChanged(dataTableEl.current);
              }}
              rowCallback={rowCallback}
              order={order}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ContainerlessTableView.propTypes = {
  title: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  typedSearchTerm: PropTypes.string,
  searchedSearchTerm: PropTypes.string,
  startSearchTermUpdate: PropTypes.func.isRequired,
  dataSource: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  order: PropTypes.array,
  filters: PropTypes.array,
  updateFilter: PropTypes.func.isRequired,
  debouncedUpdateFilter: PropTypes.func.isRequired,
  clearFilterValues: PropTypes.func.isRequired,
  tableClassNames: PropTypes.string,
  csvDataSource: PropTypes.string,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  rowCallback: PropTypes.func,
  layout: PropTypes.string
};

export default ContainerlessTableView;
