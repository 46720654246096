/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import {
  OUTREACH_MODAL_OUTREACH_BODY_CHANGED,
  OUTREACH_MODAL_OUTREACH_TO_CHANGED,
  OUTREACH_MODAL_OUTREACH_CC_CHANGED,
  OUTREACH_MODAL_OUTREACH_BCC_CHANGED,
  OUTREACH_MODAL_OUTREACH_SUBJECT_CHANGED,
  OUTREACH_MODAL_SELECTED_OUTREACH_TEMPLATE_CHANGED,
  OUTREACH_MODAL_OUTREACH_TEMPLATE_NAME_CHANGED,
  OUTREACH_MODAL_OUTREACH_BODY_CHANGED_FROM_TEMPLATE,
  OUTREACH_MODAL_OUTREACH_TEMPLATE_ADDED,
  OUTREACH_MODAL_OUTREACH_ERRORS_CHANGED,
  OUTREACH_MODAL_OUTREACH_VENUE_OWNERSHIP_ID_CHANGED,
  OUTREACH_MODAL_ADD_AGENT_EMAIL_TO_TOS,
  OUTREACH_MODAL_REMOVE_AGENT_EMAIL_FROM_TOS,
  OUTREACH_MODAL_OUTREACH_ARTIST_CHANGED,
  OUTREACH_MODAL_OUTREACH_CHANGED,
  OUTREACH_MODAL_OUTREACH_STATE_CHANGED,
  OUTREACH_MODAL_OUTREACH_MODAL_CLOSED,
  OUTREACH_MODAL_OUTREACH_CREATED,
  OUTREACH_MODAL_AGENT_PREVIEWS_CHANGED
} from '../constants/outreachModalConstants';

export const outreachBodyChanged = (editorState) => ({
  type: OUTREACH_MODAL_OUTREACH_BODY_CHANGED,
  editorState
});

export const outreachToChanged = (to) => ({
  type: OUTREACH_MODAL_OUTREACH_TO_CHANGED,
  to
});

export const outreachCcChanged = (cc) => ({
  type: OUTREACH_MODAL_OUTREACH_CC_CHANGED,
  cc
});

export const outreachBccChanged = (bcc) => ({
  type: OUTREACH_MODAL_OUTREACH_BCC_CHANGED,
  bcc
});

export const outreachSubjectChanged = (subject) => ({
  type: OUTREACH_MODAL_OUTREACH_SUBJECT_CHANGED,
  subject
});

export const selectedOutreachTemplateChanged = (outreachTemplate) => ({
  type: OUTREACH_MODAL_SELECTED_OUTREACH_TEMPLATE_CHANGED,
  outreachTemplate
});

export const outreachTemplateNameChanged = (name) => ({
  type: OUTREACH_MODAL_OUTREACH_TEMPLATE_NAME_CHANGED,
  name
});

export const outreachBodyChangedFromTemplate = (editorState) => ({
  type: OUTREACH_MODAL_OUTREACH_BODY_CHANGED_FROM_TEMPLATE,
  editorState
});

export const createOutreachTemplate = (dispatch, team, outreach, outreachTemplateName, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var outreachBodyHTML;
    if(outreach.body){
      outreachBodyHTML = stateToHTML(outreach.body.getCurrentContent());
    }

    return axios.post("/teams/" + team.id + "/outreach_templates", {
        outreach_template: {
          name: outreachTemplateName,
          body: outreachBodyHTML
        }
      })
      .then(({ data }) => {
        toast.success("Outreach template saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        dispatch(outreachTemplateAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const outreachTemplateAdded = (outreachTemplate) => ({
  type: OUTREACH_MODAL_OUTREACH_TEMPLATE_ADDED,
  outreachTemplate
})

const outreachParams = (team, outreach) => {
  var params = {};

  if(outreach.state === "added_manually"){
    params = {
      outreach: {
        artist_id: outreach.artist_id,
        venue_ownership_id: outreach.venue_ownership_id,
        state: outreach.state
      }
    };
  } else {
    var outreachBodyHTML;
    if(outreach.body){
      outreachBodyHTML = stateToHTML(outreach.body.getCurrentContent());
    }

    params = {
      outreach: {
        artist_id: outreach.artist_id,
        venue_ownership_id: outreach.venue_ownership_id,
        subject: outreach.subject,
        body: outreachBodyHTML,
        to: outreach.to.split(","),
        cc: outreach.cc.split(","),
        bcc: outreach.bcc.split(",")
      }
    };
  }

  if(outreach.pipeline){
    params.outreach.update_pipeline_attributes = {
      id: outreach.pipeline.id,
      state: "outreach"
    };
  } else {
    params.outreach.pipeline_attributes = {
      team_id: team.id,
      state: "outreach"
    }
  }

  return params;
};

export const submitOutreachForm = (dispatch, csrfToken, team, outreach, onCreate) => {
  dispatch(outreachErrorsChanged([]));

  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/outreaches", outreachParams(team, outreach))
      .then(({ data }) => {
        onCreate(data);

        dispatch(outreachChanged({}));
        dispatch(outreachModalClosed());
        dispatch(outreachCreated(data));
      })
      .catch((error) => {
        dispatch(outreachErrorsChanged(error.response.data));
      });
  };
};

export const outreachErrorsChanged = (errors) => ({
  type: OUTREACH_MODAL_OUTREACH_ERRORS_CHANGED,
  errors
})

export const outreachVenueOwnershipIdChanged = (venueOwnership, artist) => ({
  type: OUTREACH_MODAL_OUTREACH_VENUE_OWNERSHIP_ID_CHANGED,
  venueOwnership,
  artist
})

export const addAgentEmailToTos = (agent) => ({
  type: OUTREACH_MODAL_ADD_AGENT_EMAIL_TO_TOS,
  agent
})

export const removeAgentEmailFromTos = (agent) => ({
  type: OUTREACH_MODAL_REMOVE_AGENT_EMAIL_FROM_TOS,
  agent
})

export const outreachArtistChanged = (artist, venueOwnership) => ({
  type: OUTREACH_MODAL_OUTREACH_ARTIST_CHANGED,
  artist,
  venueOwnership
})

export const outreachChanged = (outreach) => ({
  type: OUTREACH_MODAL_OUTREACH_CHANGED,
  outreach
});

export const outreachStateChanged = (state) => ({
  type: OUTREACH_MODAL_OUTREACH_STATE_CHANGED,
  state
});

export const fetchOutreachTemplate = (dispatch, team, outreachTemplate) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/outreach_templates/" + outreachTemplate.id)
      .then(({ data }) => {
        var state = stateFromHTML(data.body);
        var editorState = EditorState.createWithContent(state);

        dispatch(outreachBodyChangedFromTemplate(editorState));
      });
  };
};

export const outreachModalClosed = () => ({
  type: OUTREACH_MODAL_OUTREACH_MODAL_CLOSED
});

export const outreachCreated = (outreach) => ({
  type: OUTREACH_MODAL_OUTREACH_CREATED,
  outreach
});

export const fetchAgentPreviews = (dispatch, team, to) => {
  return dispatch => {
    var emails =  to.split(",").map((e) => e.trim());

    return axios.get("/teams/" + team.id + "/agents/outreach_info", {
        params: {
          emails: emails
        }
      })
      .then(({ data }) => {
        dispatch(agentPreviewsChanged(data));
      });
  };
};

export const agentPreviewsChanged = (agentPreviews) => ({
  type: OUTREACH_MODAL_AGENT_PREVIEWS_CHANGED,
  agentPreviews
});
