/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  PIPELINE_SLIDING_PANE_PIPELINE_CHANGED,
  FOLLOW_UP_CHANGED,
  CREATE_CALENDAR_EVENT_FROM_PIPELINE,
  CLOSE_CALENDAR_EVENT_MODAL,
  OFFER_CHANGED
} from '../constants/pipelineSlidingPaneConstants';

import {
  serverJSONToLocal
} from '../../OfferModal/actions/offerModalActionCreators';

export const pipelineChanged = (pipeline) => ({
  type: PIPELINE_SLIDING_PANE_PIPELINE_CHANGED,
  pipeline
});

export const followUpChanged = (followUp) => ({
  type: FOLLOW_UP_CHANGED,
  followUp
});

export const fetchNewFollowUp = (dispatch, team, pipeline) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/follow_ups/new")
      .then(({ data }) => {
        var followUp = Object.assign({}, data, {
          last_contact_id: pipeline.last_touched_record.id,
          last_contact_type: pipeline.last_touched_record.class_name,
          artist: pipeline.artist,
          pipeline: pipeline
        });

        dispatch(followUpChanged(followUp));
      });
  };
};

export const createCalendarEventFromPipeline = (pipeline) => ({
  type: CREATE_CALENDAR_EVENT_FROM_PIPELINE,
  pipeline
});

export const closeCalendarEventModal = () => ({
  type: CLOSE_CALENDAR_EVENT_MODAL
});

export const fetchNewOffer = (dispatch, team, pipeline, defaultOffer) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/offers/new", {
        params: {
          "offer[primary_performer_attributes][artistable_id]": pipeline.artist.id,
          "offer[primary_performer_attributes][artistable_type]": "Artist"
        }
      })
      .then(({ data }) => {
        var serverOffer = serverJSONToLocal(data);
        var offer = Object.assign({}, serverOffer, defaultOffer, {
          eventName: pipeline.artist.name,
          pipeline: pipeline
        });

        dispatch(offerChanged(offer));
      });
  };
};

export const offerChanged = (offer) => ({
  type: OFFER_CHANGED,
  offer
});

export const fetchEditFollowUp = (dispatch, team, followUp) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/follow_ups/" + followUp.id + "/edit")
      .then(({ data }) => {
        var remindAt = new Date(data.remind_at_midday);
        var followUp = Object.assign({}, data, {remind_at: remindAt});

        dispatch(followUpChanged(followUp));
      })
  };
};
