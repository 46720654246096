import React from 'react'

const ArtistProfile = ({ image_thumb_url_for_json, name, is_star, permalink })=>{
  return(
    <div className='d-flex col-4 mt-3'>
      <div className='artist-avatar border-2 position-relative' style={{backgroundImage:`url('${image_thumb_url_for_json}')`}}>
        {is_star && <i className="fas fa-star position-absolute"></i>}
      </div>
      <a  href={'/artists/' + permalink} className='text-capitalize ml-3 small font-700 text-dark'>{name}</a>
    </div>
  )
}

export default ArtistProfile
