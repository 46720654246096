/* eslint-disable import/prefer-default-export */

export const STATE_OPTIONS = [
  {name: "Alaska", code: "AK"},
  {name: "Alabama", code: "AL"},
  {name: "Arkansas", code: "AR"},
  {name: "American Samoa", code: "AS"},
  {name: "Arizona", code: "AZ"},
  {name: "California", code: "CA"},
  {name: "Colorado", code: "CO"},
  {name: "Connecticut", code: "CT"},
  {name: "District of Columbia", code: "DC"},
  {name: "Delaware", code: "DE"},
  {name: "Florida", code: "FL"},
  {name: "Georgia", code: "GA"},
  {name: "Guam", code: "GU"},
  {name: "Hawaii", code: "HI"},
  {name: "Iowa", code: "IA"},
  {name: "Idaho", code: "ID"},
  {name: "Illinois", code: "IL"},
  {name: "Indiana", code: "IN"},
  {name: "Kansas", code: "KS"},
  {name: "Kentucky", code: "KY"},
  {name: "Louisiana", code: "LA"},
  {name: "Massachusetts", code: "MA"},
  {name: "Maryland", code: "MD"},
  {name: "Maine", code: "ME"},
  {name: "Michigan", code: "MI"},
  {name: "Minnesota", code: "MN"},
  {name: "Missouri", code: "MO"},
  {name: "Northern Mariana Islands", code: "MP"},
  {name: "Mississippi", code: "MS"},
  {name: "Montana", code: "MT"},
  {name: "North Carolina", code: "NC"},
  {name: "North Dakota", code: "ND"},
  {name: "Nebraska", code: "NE"},
  {name: "New Hampshire", code: "NH"},
  {name: "New Jersey", code: "NJ"},
  {name: "New Mexico", code: "NM"},
  {name: "Nevada", code: "NV"},
  {name: "New York", code: "NY"},
  {name: "Ohio", code: "OH"},
  {name: "Oklahoma", code: "OK"},
  {name: "Oregon", code: "OR"},
  {name: "Pennsylvania", code: "PA"},
  {name: "Puerto Rico", code: "PR"},
  {name: "Rhode Island", code: "RI"},
  {name: "South Carolina", code: "SC"},
  {name: "South Dakota", code: "SD"},
  {name: "Tennessee", code: "TN"},
  {name: "Texas", code: "TX"},
  {name: "United States Minor Outlying Islands", code: "UM"},
  {name: "Utah", code: "UT"},
  {name: "Virginia", code: "VA"},
  {name: "Virgin Islands", code: "VI"},
  {name: "Vermont", code: "VT"},
  {name: "Washington", code: "WA"},
  {name: "Wisconsin", code: "WI"},
  {name: "West Virginia", code: "WV"},
  {name: "Wyoming", code: "WY"},
  {name: "Armed Forces Americas (except Canada)", code: "AA"},
  {name: "Armed Forces Africa, Canada, Europe, Middle East", code: "AE"},
  {name: "Armed Forces Pacific", code: "AP"}
];
