import { createStore, applyMiddleware } from 'redux';
import fanUpcomingEventsReducer from '../reducers/fanUpcomingEventsReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(fanUpcomingEventsReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
