import { combineReducers } from 'redux';

import {
  FOLLOW_UP_MODAL_CLOSED,
  FOLLOW_UP_CHANGED,
  FOLLOW_UP_ARTIST_CHANGED,
  FOLLOW_UP_REMIND_AT_CHANGED,
  FOLLOW_UP_NOTE_CHANGED
} from '../constants/followUpModalConstants';

const followUp = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_MODAL_CLOSED:
      return {};
    case FOLLOW_UP_CHANGED:
      return action.followUp;
    case FOLLOW_UP_ARTIST_CHANGED:
      return Object.assign({}, state, {artist: action.artist});
    case FOLLOW_UP_REMIND_AT_CHANGED:
      return Object.assign({}, state, {remind_at: action.date});
    case FOLLOW_UP_NOTE_CHANGED:
      return Object.assign({}, state, {note: action.note});
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const canChangeArtist = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onCreate = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const followUpModalReducer = combineReducers({ followUp, team, dataTableEl, csrfToken, canChangeArtist, onClose, onCreate });

export default followUpModalReducer;
