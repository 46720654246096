// Simple example of a React "smart" component

import { connect } from 'react-redux';
import PostedPosts from '../components/PostedPosts';
import * as actions from '../actions/postedPostsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  dataTableTitle: state.dataTableTitle,
  dataTableDataSource: state.dataTableDataSource,
  dataTableOrder: state.dataTableOrder,
  dataTableColumns: state.dataTableColumns,
  dataTableEl: state.dataTableEl,
  searchedSearchTerm: state.searchedSearchTerm,
  authorizedPages: state.authorizedPages,
  hasPosts: state.hasPosts
});

const mapDispatchToProps = (dispatch) => {
  return {
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    deletePost: (csrfToken, team, post, dataTable) => dispatch(actions.deletePost(dispatch, csrfToken, team, post, dataTable))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(PostedPosts);
