import PropTypes from 'prop-types';
import React from 'react';

const AgentsTable = ({
  agents,
  index,
  indexChanged
}) => (
  (agents.length > 0 ? (
    <div>
      <div className="row">
        <div className="col small">
          <div className="text-muted pb-2">
            <strong>Representation</strong>
          </div>
        </div>
        {agents.length > 1 ? (
          <div className="col-xs-auto small" style={{"paddingRight": "15px"}}>
            <div className="text-muted pb-2">
              <strong>
                <a href=""
                   className="text-muted pr-2"
                   onClick={
                     (e) => {
                       e.preventDefault();

                       if((index - 1) >= 0){
                         indexChanged(index - 1);
                       }
                     }
                   }>
                  <i className="fas fa-caret-left"></i>
                </a>
                {index + 1} of {agents.length}
                <a href=""
                   className="text-muted pl-2"
                   onClick={
                     (e) => {
                       e.preventDefault();

                       if((index + 1) < agents.length){
                         indexChanged(index + 1);
                       }
                     }
                   }>
                  <i className="fas fa-caret-right"></i>
                </a>
              </strong>
            </div>
          </div>
        ) : null}
      </div>
      <div className="table-responsive">
        <table className="table key-value-table microtable">
          <tbody>
            <tr>
              <td>
                <strong>Agency</strong>
              </td>
              <td className="text-right">
                {agents[index].agency.name}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Agent</strong>
              </td>
              <td className="text-right">
                {agents[index].name}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Email</strong>
              </td>
              <td className="text-right">
                {agents[index].email}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : null)
);

AgentsTable.propTypes = {
  agents: PropTypes.array,
  index: PropTypes.number,
  indexChanged: PropTypes.func.isRequired
};

export default AgentsTable;
