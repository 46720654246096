// Simple example of a React "smart" component

import { connect } from 'react-redux';
import EmailNotificationSettings from '../components/EmailNotificationSettings';
import * as actions from '../actions/emailNotificationSettingsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentUser: state.currentUser,
  team: state.team,
  teamMembership: state.teamMembership,
  originalTeamMembership: state.originalTeamMembership
});

const mapDispatchToProps = (dispatch) => {
  return {
    teamMembershipChanged: (teamMembership) => dispatch(actions.teamMembershipChanged(teamMembership)),
    updateTeamMembership: (csrfToken, team, teamMembership) => dispatch(actions.updateTeamMembership(dispatch, csrfToken, team, teamMembership))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(EmailNotificationSettings);
