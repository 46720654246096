/* eslint-disable import/prefer-default-export */

export const OUTREACH_MODAL_OUTREACH_CHANGED = 'OUTREACH_MODAL_OUTREACH_CHANGED';
export const OUTREACH_MODAL_OUTREACH_MODAL_CLOSED = 'OUTREACH_MODAL_OUTREACH_MODAL_CLOSED';
export const OUTREACH_MODAL_OUTREACH_BODY_CHANGED = 'OUTREACH_MODAL_OUTREACH_BODY_CHANGED';
export const OUTREACH_MODAL_OUTREACH_TO_CHANGED = 'OUTREACH_MODAL_OUTREACH_TO_CHANGED';
export const OUTREACH_MODAL_OUTREACH_CC_CHANGED = 'OUTREACH_MODAL_OUTREACH_CC_CHANGED';
export const OUTREACH_MODAL_OUTREACH_BCC_CHANGED = 'OUTREACH_MODAL_OUTREACH_BCC_CHANGED';
export const OUTREACH_MODAL_OUTREACH_SUBJECT_CHANGED = 'OUTREACH_MODAL_OUTREACH_SUBJECT_CHANGED';
export const OUTREACH_MODAL_OUTREACH_ERRORS_CHANGED = 'OUTREACH_MODAL_OUTREACH_ERRORS_CHANGED';
export const OUTREACH_MODAL_OUTREACH_VENUE_OWNERSHIP_ID_CHANGED = 'OUTREACH_MODAL_OUTREACH_VENUE_OWNERSHIP_ID_CHANGED';
export const OUTREACH_MODAL_OUTREACH_STATE_CHANGED = 'OUTREACH_MODAL_OUTREACH_STATE_CHANGED';
export const OUTREACH_MODAL_OUTREACH_BODY_CHANGED_FROM_TEMPLATE = 'OUTREACH_MODAL_OUTREACH_BODY_CHANGED_FROM_TEMPLATE';
export const OUTREACH_MODAL_OUTREACH_CREATED = 'OUTREACH_MODAL_OUTREACH_CREATED';

export const OUTREACH_MODAL_ADD_AGENT_EMAIL_TO_TOS = 'OUTREACH_MODAL_ADD_AGENT_EMAIL_TO_TOS';
export const OUTREACH_MODAL_REMOVE_AGENT_EMAIL_FROM_TOS = 'OUTREACH_MODAL_REMOVE_AGENT_EMAIL_FROM_TOS';

export const OUTREACH_MODAL_OUTREACH_ARTIST_CHANGED = 'OUTREACH_MODAL_OUTREACH_ARTIST_CHANGED';

export const OUTREACH_MODAL_OUTREACH_TEMPLATE_ADDED = 'OUTREACH_MODAL_OUTREACH_TEMPLATE_ADDED';
export const OUTREACH_MODAL_OUTREACH_TEMPLATE_NAME_CHANGED = 'OUTREACH_MODAL_OUTREACH_TEMPLATE_NAME_CHANGED';
export const OUTREACH_MODAL_SELECTED_OUTREACH_TEMPLATE_CHANGED = 'OUTREACH_MODAL_SELECTED_OUTREACH_TEMPLATE_CHANGED';

export const OUTREACH_MODAL_AGENT_PREVIEWS_CHANGED = 'OUTREACH_MODAL_AGENT_PREVIEWS_CHANGED';
