/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {
  offsetDateForServer
} from '../../../shared/timeZoneLogic';

import {
  CLOSE_MODAL,
  CREATE_CALENDAR_EVENT_CHANGED,
  EVENT_NAME_CHANGED,
  VENUE_ID_CHANGED,
  START_TIME_CHANGED,
  PRIMARY_ARTIST_CHANGED,
  CALENDAR_EVENT_CHANGED,
  PERFORMERS_CHANGED,
  PERFORMER_ID_CHANGED,
  SELECTED_ARTIST_CHANGED,
  CLEAR_SELECTED_ARTIST,
  EVENT_TEMPLATE_CHANGED,
} from '../constants/offerV2ModalConstants';

export const closeModal = (dispatch, onClose) => {
  return dispatch => {
    onClose();
    dispatch(modalWasClosed());
  };
};

export const modalWasClosed = () => ({
  type: CLOSE_MODAL
});

export const createCalendarEventChanged = (createCalendarEvent) => ({
  type: CREATE_CALENDAR_EVENT_CHANGED,
  createCalendarEvent
});

export const eventNameChanged = (name) => ({
  type: EVENT_NAME_CHANGED,
  name
});

export const eventTemplateChanged = (eventTemplate) => ({
  type: EVENT_TEMPLATE_CHANGED,
  eventTemplate
});

export const venueIdChanged = (venueId) => ({
  type: VENUE_ID_CHANGED,
  venueId
});

export const startTimeChanged = (date) => ({
  type: START_TIME_CHANGED,
  date
});

export const primaryArtistChanged = (artist) => ({
  type: PRIMARY_ARTIST_CHANGED,
  artist
});

export const calendarEventChanged = (calendarEvent) => ({
  type: CALENDAR_EVENT_CHANGED,
  calendarEvent
});

export const fetchCalendarEventPerformers = (dispatch, team, calendarEventId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/performers", {
        params: {
          performable_type: "CalendarEvent",
          performable_id: calendarEventId
        }
      })
      .then(({ data }) => {
        dispatch(performersChanged(data));
      })
  };
};

export const performersChanged = (performers) => ({
  type: PERFORMERS_CHANGED,
  performers
});

export const performerIdChanged = (performerId) => ({
  type: PERFORMER_ID_CHANGED,
  performerId
});

export const fetchArtistData = (dispatch, artistId) => {
  return dispatch => {
    return axios.get("/artists/" + artistId + ".json")
      .then(({ data }) => {
        dispatch(selectedArtistChanged(data));
      })
  };
};

export const selectedArtistChanged = (artist) => ({
  type: SELECTED_ARTIST_CHANGED,
  artist
});

export const clearSelectedArtist = () => ({
  type: CLEAR_SELECTED_ARTIST
});

export const createOffer = (dispatch, csrfToken, team, offer, pipeline) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var offerParams = {
      ui_version: 2,
      create_calendar_event: offer.create_calendar_event
    };

    if(pipeline && pipeline.id){
      offerParams.update_pipeline_attributes = {
        id: pipeline.id,
        state: "offer"
      };
    } else {
      offerParams.pipeline_attributes = {
        team_id: team.id,
        state: "offer"
      };
    }

    if(offer.create_calendar_event){
      if(offer.primary_artist){
        offerParams.primary_performer_attributes = {
          artistable_type: "Artist",
          artistable_id: offer.primary_artist.value
        }
      }

      if(offer.start_time && offer.start_time.length > 0){
        offerParams.start_date = offsetDateForServer(offer.start_time[0]);
        offerParams.start_time = offsetDateForServer(offer.start_time[0]);
      }

      offerParams = Object.assign({}, offerParams, {
        venue_id: offer.venue_id,
        event_name: offer.event_name,
        event_template_id: offer.event_template?.value
      });
    } else {
      offerParams = Object.assign({}, offerParams, {
        calendar_event_id: offer.calendar_event_id,
        performer_id: offer.performer_id
      });
    }

    return axios.post("/teams/" + team.id + "/offers", {
        offer: offerParams
      })
      .then(({ data }) => {
        window.location.href = ("/teams/" + team.id + "/offers/" + data.id + "/edit");
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};
