import PropTypes from 'prop-types';
import React from 'react';

import OfferForm from './OfferForm';
import OfferPreview from './OfferPreview';
import OfferCustomize from './OfferCustomize';

import {
  FORM_STATE,
  PREVIEW_STATE,
  CUSTOMIZE_STATE
} from '../constants/offerModalConstants';

const OfferModal = ({
  offer,
  venuesOptions,
  closeModal,
  updateOfferVenue,
  updateOfferPrimaryArtist,
  updateOfferStartDate,
  addOfferDealTermLineItem,
  removeOfferDealTermLineItem,
  updateOfferDealTermLineItemLabel,
  updateOfferDealTermLineItemDescription,
  updateOfferAdditionalTerms,
  updateOfferContactDetails,
  csrfToken,
  team,
  submitOfferForm,
  fetchArtistData,
  updateOfferRemovePerformer,
  updateOfferAgentName,
  updateOfferAgentEmail,
  defaultOffer,
  dataTableEl,
  offerTemplateName,
  updateOfferTemplateName,
  createOfferTemplate,
  offerTemplates,
  fetchOfferTemplate,
  selectedOfferTemplate,
  selectOfferTemplate,
  addArtistSuggestion,
  currentUser,
  offerCalendarEventChanged,
  fetchCalendarEventPerformers,
  offerPerformerSelected,
  offerStartTimeChanged,
  updateOfferFrontEndState,
  updateOfferEmailBody,
  updateOfferLastSentTo,
  sendOffer,
  acceptOfferFromModal,
  declineOfferFromModal,
  updateOfferEventName,
  offerPerformerUnselected,
  updateOfferDollarOrPercentageOperator,
  updateOfferDollarAmount,
  updateOfferUpsidePercentage,
  hiddenFileUploadInputEl,
  hiddenFileUploadInputElChanged,
  uploadAttachment,
  attachmentRemoved
}) => (
  <div className="offer-modal-container">
    <div className="row">
      <div className='col-12'>
        {offer.frontEndState === FORM_STATE ? (
          <OfferForm offer={offer}
                     currentUser={currentUser}
                     defaultOffer={defaultOffer}
                     closeModal={closeModal}
                     updateOfferVenue={updateOfferVenue}
                     updateOfferPrimaryArtist={updateOfferPrimaryArtist}
                     updateOfferStartDate={updateOfferStartDate}
                     addOfferDealTermLineItem={addOfferDealTermLineItem}
                     removeOfferDealTermLineItem={removeOfferDealTermLineItem}
                     updateOfferDealTermLineItemLabel={updateOfferDealTermLineItemLabel}
                     updateOfferDealTermLineItemDescription={updateOfferDealTermLineItemDescription}
                     updateOfferAdditionalTerms={updateOfferAdditionalTerms}
                     updateOfferContactDetails={updateOfferContactDetails}
                     csrfToken={csrfToken}
                     team={team}
                     submitOfferForm={submitOfferForm}
                     fetchArtistData={fetchArtistData}
                     updateOfferRemovePerformer={updateOfferRemovePerformer}
                     updateOfferAgentName={updateOfferAgentName}
                     updateOfferAgentEmail={updateOfferAgentEmail}
                     offerTemplateName={offerTemplateName}
                     updateOfferTemplateName={updateOfferTemplateName}
                     createOfferTemplate={createOfferTemplate}
                     offerTemplates={offerTemplates}
                     fetchOfferTemplate={fetchOfferTemplate}
                     selectedOfferTemplate={selectedOfferTemplate}
                     selectOfferTemplate={selectOfferTemplate}
                     addArtistSuggestion={addArtistSuggestion}
                     offerCalendarEventChanged={offerCalendarEventChanged}
                     fetchCalendarEventPerformers={fetchCalendarEventPerformers}
                     offerPerformerSelected={offerPerformerSelected}
                     offerStartTimeChanged={offerStartTimeChanged}
                     updateOfferEventName={updateOfferEventName}
                     offerPerformerUnselected={offerPerformerUnselected}
                     updateOfferDollarOrPercentageOperator={updateOfferDollarOrPercentageOperator}
                     updateOfferDollarAmount={updateOfferDollarAmount}
                     updateOfferUpsidePercentage={updateOfferUpsidePercentage}
                     hiddenFileUploadInputEl={hiddenFileUploadInputEl}
                     hiddenFileUploadInputElChanged={hiddenFileUploadInputElChanged}
                     uploadAttachment={uploadAttachment}
                     attachmentRemoved={attachmentRemoved}
                     venuesOptions={venuesOptions} />
        ) : null}
        {offer.frontEndState === PREVIEW_STATE ? (
          <OfferPreview team={team}
                        updateOfferFrontEndState={updateOfferFrontEndState}
                        closeModal={closeModal}
                        defaultOffer={defaultOffer}
                        dataTableEl={dataTableEl}
                        acceptOfferFromModal={acceptOfferFromModal}
                        declineOfferFromModal={declineOfferFromModal}
                        csrfToken={csrfToken}
                        offer={offer} />
        ) : null}
        {offer.frontEndState === CUSTOMIZE_STATE ? (
          <OfferCustomize team={team}
                          updateOfferFrontEndState={updateOfferFrontEndState}
                          updateOfferEmailBody={updateOfferEmailBody}
                          updateOfferLastSentTo={updateOfferLastSentTo}
                          sendOffer={sendOffer}
                          csrfToken={csrfToken}
                          closeModal={closeModal}
                          defaultOffer={defaultOffer}
                          dataTableEl={dataTableEl}
                          offer={offer} />
        ) : null}
      </div>
    </div>
  </div>
);

OfferModal.propTypes = {
  offer: PropTypes.object.isRequired,
  venuesOptions: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
  updateOfferVenue: PropTypes.func.isRequired,
  updateOfferPrimaryArtist: PropTypes.func.isRequired,
  updateOfferStartDate: PropTypes.func.isRequired,
  addOfferDealTermLineItem: PropTypes.func.isRequired,
  removeOfferDealTermLineItem: PropTypes.func.isRequired,
  updateOfferDealTermLineItemLabel: PropTypes.func.isRequired,
  updateOfferDealTermLineItemDescription: PropTypes.func.isRequired,
  updateOfferAdditionalTerms: PropTypes.func.isRequired,
  updateOfferContactDetails: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  submitOfferForm: PropTypes.func.isRequired,
  fetchArtistData: PropTypes.func.isRequired,
  updateOfferRemovePerformer: PropTypes.func.isRequired,
  updateOfferAgentName: PropTypes.func.isRequired,
  updateOfferAgentEmail: PropTypes.func.isRequired,
  defaultOffer: PropTypes.object.isRequired,
  dataTableEl: PropTypes.object,
  offerTemplateName: PropTypes.string,
  updateOfferTemplateName: PropTypes.func.isRequired,
  createOfferTemplate: PropTypes.func.isRequired,
  offerTemplates: PropTypes.array,
  fetchOfferTemplate: PropTypes.func.isRequired,
  selectedOfferTemplate: PropTypes.object,
  selectOfferTemplate: PropTypes.func.isRequired,
  addArtistSuggestion: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  offerCalendarEventChanged: PropTypes.func.isRequired,
  fetchCalendarEventPerformers: PropTypes.func.isRequired,
  offerPerformerSelected: PropTypes.func.isRequired,
  offerStartTimeChanged: PropTypes.func.isRequired,
  updateOfferFrontEndState: PropTypes.func.isRequired,
  updateOfferEmailBody: PropTypes.func.isRequired,
  updateOfferLastSentTo: PropTypes.func.isRequired,
  sendOffer: PropTypes.func.isRequired,
  acceptOfferFromModal: PropTypes.func.isRequired,
  declineOfferFromModal: PropTypes.func.isRequired,
  updateOfferEventName: PropTypes.func.isRequired,
  offerPerformerUnselected: PropTypes.func.isRequired,
  updateOfferDollarOrPercentageOperator: PropTypes.func.isRequired,
  updateOfferDollarAmount: PropTypes.func.isRequired,
  updateOfferUpsidePercentage: PropTypes.func.isRequired,
  hiddenFileUploadInputEl: PropTypes.object,
  hiddenFileUploadInputElChanged: PropTypes.func.isRequired,
  uploadAttachment: PropTypes.func.isRequired,
  attachmentRemoved: PropTypes.func.isRequired
};

export default OfferModal;
