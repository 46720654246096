// Simple example of a React "smart" component

import { connect } from 'react-redux';
import BulkRefund from '../components/BulkRefund';
import * as actions from '../actions/bulkRefundActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  confirm: state.confirm,
  dataTableEl: state.dataTableEl,
  refund: state.refund,
  allOrderIds: state.allOrderIds,
  submittingRefund: state.submittingRefund,
  creatingRefund: state.creatingRefund,
  buildingRefund: state.buildingRefund,
  buyers: state.buyers,
  promoters: state.promoters,
  canBypassRefundRestrictions: state.canBypassRefundRestrictions
});

const mapDispatchToProps = (dispatch) => {
  return {
    confirmChanged: (confirm) => dispatch(actions.confirmChanged(confirm)),
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    refundChanged: (refund) => dispatch(actions.refundChanged(refund)),
    renderDataTableRows: (dataTableEl, refund, csrfToken, team, confirm, buildBulkRefund, refundChanged) => dispatch(actions.renderDataTableRows(dispatch, dataTableEl, refund, csrfToken, team, confirm, buildBulkRefund, refundChanged)),
    renderDataTableHeader: (dataTableEl, allOrderIds, refund, refundChanged, csrfToken, team, confirm, buildBulkRefund) => dispatch(actions.renderDataTableHeader(dispatch, dataTableEl, allOrderIds, refund, refundChanged, csrfToken, team, confirm, buildBulkRefund)),
    fetchAllOrderIds: (team, confirm) => dispatch(actions.fetchAllOrderIds(dispatch, team, confirm)),
    buildBulkRefund: (csrfToken, team, confirm, refund) => dispatch(actions.buildBulkRefund(dispatch, csrfToken, team, confirm, refund)),
    confirmRefund: (csrfToken, team, confirm, refund) => dispatch(actions.confirmRefund(dispatch, csrfToken, team, confirm, refund)),
    createBulkRefund: (csrfToken, team, confirm, refund) => dispatch(actions.createBulkRefund(dispatch, csrfToken, team, confirm, refund))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(BulkRefund);
