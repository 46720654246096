import React, { useState } from "react";

import { useBroadcastEditContext } from "./BroadcastEditContainer";
import TemplateEditor from "../BeefreeTemplates/TemplateEditor";

const containerStyle = {
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
};

const editorHeaderRowStyle = {
  alignItems: "center",
  backgroundColor: "white",
  borderBottom: "1px solid #EEEEEE",
  display: "flex",
  flexDirection: "row",
  gap: 15,
  padding: 15,
};

const inputStyle = {
  border: "none",
  background: "transparent",
  fontSize: "inherit",
  fontWeight: "inherit",
  width: 400,
};

const inlineFormStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

const submitButtonStyle = {
  border: "none",
  backgroundColor: "transparent",
};

const iconStyle = {
  cursor: "pointer",
  color: "#333333",
  opacity: 0.8,
};

const EmailContentTab = () => {
  const {
    broadcast,
    subject,
    setSubject,
    preheader,
    setPreheader,
    updateBroadcast,
    sendDomain,
  } = useBroadcastEditContext();

  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const [isEditingPreheader, setIsEditingPreheader] = useState(false);

  const updateSubject = () => {
    setIsEditingSubject(false);
    updateBroadcast({
      broadcast: {
        subject: subject
      }
    })
  };

  const updatePreheader = () => {
    setIsEditingPreheader(false);
    updateBroadcast({
      broadcast: {
        preheader: preheader
      }
    })
  };

  return (
    <div style={containerStyle}>
      <div style={editorHeaderRowStyle}>
        <div><b>From: </b>{sendDomain?.name_with_address}</div>
        <form style={inlineFormStyle} onSubmit={(e) => {
          e.preventDefault();
          updateSubject();
        }}>
          <b>Subject: </b>
          {isEditingSubject ? (
            <>
              <input
                autoFocus
                style={inputStyle}
                value={subject}
                disabled={!isEditingSubject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <>
                <button type="submit" style={submitButtonStyle}>
                  <i className="far fa-check" style={iconStyle} />
                </button>
                <i className="far fa-times" style={iconStyle} onClick={() => { setSubject(broadcast.subject); setIsEditingSubject(false); }}></i>
              </>
            </>
          ) : (
            <>
              {subject}
              <i className="far fa-pencil" style={iconStyle} onClick={() => setIsEditingSubject(true)} />
            </>
          )}
        </form>
        <form style={inlineFormStyle} onSubmit={(e) => {
          e.preventDefault();
          updatePreheader();
        }}>
          <b>Preheader: </b>
            {isEditingPreheader ? (
            <>
              <input
                autoFocus
                style={inputStyle}
                value={preheader}
                disabled={!isEditingPreheader}
                onChange={(e) => setPreheader(e.target.value)}
              />
              <>
                <button type="submit" style={submitButtonStyle}>
                  <i className="far fa-check" style={iconStyle} />
                </button>
                <i className="far fa-times" style={iconStyle} onClick={() => { setPreheader(broadcast.preheader); setIsEditingPreheader(false); }}></i>
              </>
            </>
          ) : (
            <>
              {preheader}
              <i className="far fa-pencil" style={iconStyle} onClick={() => setIsEditingPreheader(true)} />
            </>
          )}
        </form>
      </div>
      <TemplateEditor
        templatableContext={useBroadcastEditContext}
        mergeTags={[
          {
            name: "Fan First Name",
            value: "{{fan.first_name}}"
          },
          {
            name: "Fan Last Name",
            value: "{{fan.last_name}}"
          },
          {
            name: "Fan Full Name",
            value: "{{fan.full_name}}"
          },
          {
            name: "Fan Phone Number",
            value: "{{fan.phone_number}}"
          },
          {
            name: "Fan Email",
            value: "{{fan.email}}"
          },
        ]}
      />
    </div>
  );
};

export default EmailContentTab;
