import PropTypes from 'prop-types';
import React from 'react';

import BudgetLineItemsTableRow from './BudgetLineItemsTableRow';

const BudgetLineItemsTable = ({
  items,
  title,
  formatCurrency,
  numberWithCommas,
  totalRowTotal,
  children,
  totalRowRightHand,
  totalRowLeftHand,
  onClickAddSettlementLineItem,
  deleteSettlementLineItem,
  csrfToken,
  team,
  itemToEdit,
  itemToEditChanged,
  leftHandTitle,
  rightHandTitle,
  totalTitle,
  deleteSettlementOverride,
  requestFormat,
  isSettling,
  tableColgroup,
  offerId,
  setBudgetState,
  editedDropCountChanged,
  showCurrencyConversion,
  globalCurrency
}) => (
  isSettling || items.length > 0 ? (
    <div>
      <div style={{"marginLeft": "15px"}}>
        <strong style={{"fontSize": "16px"}}>
          {title}
        </strong>
      </div>
      <table style={{"width": "100%", "marginTop": "-8px", "borderSpacing": "0px 2px", "borderCollapse": "separate"}}>
        {tableColgroup}
        <thead style={{"textAlign": "left"}}>
          <tr style={{"color": "#B3B3B3", "fontSize": "10px"}}>
            <th style={{"fontWeight": "normal", "paddingLeft": "15px"}}>&nbsp;</th>
            {!isSettling && title === "Tickets" ? (
              <>
                <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>
                  <span>AVAIL</span>
                </th>
                <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>
                  <span>COMPS</span>
                </th>
                <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>
                  <span>KILLS</span>
                </th>
              </>
            ) : null}
            <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>
              {rightHandTitle && rightHandTitle.length > 0 ? (
                rightHandTitle
              ) : (
                <span>&nbsp;</span>
              )}
            </th>
            <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>
              {leftHandTitle && leftHandTitle.length > 0 ? (
                leftHandTitle
              ) : (
                <span>&nbsp;</span>
              )}
            </th>
            {showCurrencyConversion ? (
              <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>
                {team.secondary_currency_symbol.toUpperCase()}
              </th>
            ) : null}
            <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "15px"}}>
              {totalTitle && totalTitle.length > 0 ? (
                totalTitle
              ) : (
                <span>&nbsp;</span>
              )}
            </th>
            <th>
              <span>&nbsp;</span>
            </th>
          </tr>
        </thead>
        <tbody style={{"fontSize": "12px"}}>
          {items.map((item, index) =>
            <BudgetLineItemsTableRow key={index}
                                      title={title}
                                      formatCurrency={formatCurrency}
                                      numberWithCommas={numberWithCommas}
                                      csrfToken={csrfToken}
                                      team={team}
                                      isSettling={isSettling}
                                      requestFormat={requestFormat}
                                      itemToEdit={itemToEdit}
                                      itemToEditChanged={itemToEditChanged}
                                      editedDropCountChanged={editedDropCountChanged}
                                      deleteSettlementLineItem={deleteSettlementLineItem}
                                      deleteSettlementOverride={deleteSettlementOverride}
                                      offerId={offerId}
                                      setBudgetState={setBudgetState}
                                      showCurrencyConversion={showCurrencyConversion}
                                      globalCurrency={globalCurrency}
                                      item={item} />
          )}
          <tr>
            <td style={{"paddingLeft": "15px"}}>
              {isSettling && requestFormat === "html" ? (
                <a href="#"
                    style={{
                      "textDecoration": "none",
                      "color": "#1982C4",
                      "fontSize": "12px"
                    }}
                    onClick={
                      (e) => {
                        onClickAddSettlementLineItem(e);
                        editedDropCountChanged(null);
                      }
                    }>
                  <strong>
                    <i className="fas fa-plus-circle"
                        aria-hidden
                        style={{
                          "marginRight": "10px"
                        }}></i>
                    Add Item
                  </strong>
                </a>
              ) : (
                <span>&nbsp;</span>
              )}
            </td>
            {!isSettling && title === "Tickets" ? (
              <>
                <td
                  style={{
                    "textAlign": "right",
                    "fontSize": "14px",
                    "paddingRight": "9px"
                  }}>
                  <strong>
                    {items.reduce((sum, i) => { return sum + i.available }, 0).toLocaleString()}
                  </strong>
                </td>
                <td
                  style={{
                    "textAlign": "right",
                    "fontSize": "14px",
                    "paddingRight": "9px"
                  }}>
                  <strong>
                    {items.reduce((sum, i) => { return sum + i.comps }, 0).toLocaleString()}
                  </strong>
                </td>
                <td
                  style={{
                    "textAlign": "right",
                    "fontSize": "14px",
                    "paddingRight": "9px"
                  }}>
                  <strong>
                    {items.reduce((sum, i) => { return sum + i.kills }, 0).toLocaleString()}
                  </strong>
                </td>
              </>
            ) : null}
            <td style={{
                  "textAlign": "right",
                  "fontWeight": "bold",
                  "padding": "3px 9px 3px 0px",
                  "fontSize": "14px"
                }}>
              {totalRowRightHand && totalRowRightHand.length > 0 ? (
                totalRowRightHand
              ) : (
                <span>&nbsp;</span>
              )}
            </td>
            <td style={{"textAlign": "right", "paddingRight": "9px"}}>
              {totalRowLeftHand && totalRowLeftHand.length > 0 ? (
                <strong style={{"fontSize": "14px"}}>{totalRowLeftHand}</strong>
              ) : (
                <span>&nbsp;</span>
              )}
            </td>
            {showCurrencyConversion ? (
              <td style={{
                    "textAlign": "right",
                    "fontWeight": "bold",
                    "padding": "3px 9px 3px 0px",
                    "fontSize": "14px"
                  }}>
                {totalRowTotal ? (
                  globalCurrency.formatted(totalRowTotal)
                ) : (
                  <span>&nbsp;</span>
                )}
              </td>
            ) : null}
            <td style={{
                  "textAlign": "right",
                  "fontWeight": "bold",
                  "padding": "3px 15px 3px 0px",
                  "fontSize": "14px"
                }}>
              {totalRowTotal ? (
                formatCurrency(totalRowTotal)
              ) : (
                <span>&nbsp;</span>
              )}
            </td>
          </tr>
          {children ? (
            children
          ) : null}
        </tbody>
      </table>
    </div>
  ) : null
);

BudgetLineItemsTable.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  numberWithCommas: PropTypes.func.isRequired,
  totalRowTotal: PropTypes.number,
  children: PropTypes.element,
  totalRowRightHand: PropTypes.string,
  totalRowLeftHand: PropTypes.string,
  onClickAddSettlementLineItem: PropTypes.func.isRequired,
  deleteSettlementLineItem: PropTypes.func.isRequired,
  csrfToken: PropTypes.string,
  team: PropTypes.object.isRequired,
  itemToEdit: PropTypes.object,
  itemToEditChanged: PropTypes.func.isRequired,
  leftHandTitle: PropTypes.string,
  rightHandTitle: PropTypes.string,
  totalTitle: PropTypes.string,
  deleteSettlementOverride: PropTypes.func.isRequired,
  isSettling: PropTypes.bool.isRequired,
  requestFormat: PropTypes.string,
  tableColgroup: PropTypes.object.isRequired,
  offerId: PropTypes.string.isRequired,
  setBudgetState: PropTypes.func.isRequired,
  editedDropCountChanged: PropTypes.func.isRequired,
  showCurrencyConversion: PropTypes.bool,
  globalCurrency: PropTypes.object
};

export default BudgetLineItemsTable;
