import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import * as axios from 'axios';
import { toast } from 'react-toastify';

const AgentSuggestionWraper = styled.div`
  &.border {
    border-radius: 8px;
    border: 2px solid #F9F9F9;
  }

  .btn-add-agent {
    font-size: 12px;
    font-weight: 700;
  }

  .form-group {
    margin-bottom: 8px;

    .form-control {
      border-radius: 4px;
      font-size: 12px;
      color: #808080;
    }
  }

  .btn-submit {
    font-size: 14px;
    font-weight: 700;
  }

  .btn-cancel {
    font-size: 14px;
    font-weight: 700;

    &:hover {
      background: white;
      color: #3b84bf;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .review-description {
    background: #F9F9F9;
    border-radius: 4px;
    height: 100%;
    padding: 15px;

    i {
      color: #1982C4;
      font-weight: 500;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      color: #808080;
    }
  }
`;

const AgentSuggestion = ({artistId, agentInfo = {}, noAgent = false, csrfToken, intent, onClose}) => {
  const [name, setName] = useState(agentInfo.name || '');
  const [agencyName, setAgencyName] = useState(agentInfo.agency?.name || '');
  const [email, setEmail] = useState(agentInfo.email || '');
  const [showNoAgent, setShowNoAgent] = useState(noAgent);
  const [isValidForm, setIsValidForm] = useState(agentInfo.id ? true : false);
  const [userIntent, setUserIntent] = useState(intent);

  useEffect(() => {
    if (name && agencyName && email) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  }, [name, agencyName, email])

  const handleOnClickSubmit = async () => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    try {
      await axios.post(`/agent_suggestions`,{
        artist_id: artistId,
        agent_id: agentInfo.id,
        name,
        agency_name: agencyName,
        email,
        user_intent: userIntent,
      });

      toast.success("Your agent suggestion is submitted", {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: true,
        autoClose: 5000,
        hideProgressBar: true
      });

      setName('');
      setAgencyName('');
      setEmail('');
    } catch (err) {
      toast.error(err.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: true,
        autoClose: 5000,
        hideProgressBar: true
      });
    }
  }

  const handleOnClickCancel = () => {
    if (noAgent) {
      setShowNoAgent(true);
    }

    if (onClose) {
      onClose();
    }
  }

  const handleAddAgent = () => {
    setShowNoAgent(false);
    setUserIntent('user_intent_create');
  }

  return (
    <AgentSuggestionWraper className={`${ agentInfo.id ? '' : 'card p-4'}`}>
      <div className="row">
        { showNoAgent ? (
          <div className="col-12 text-center">
            <strong>No Agent Found</strong>
            <p>Have agent details that you can share? Add them to Opendate</p>
            <button
              className="btn btn-primary btn-add-agent"
              type="button"
              onClick={handleAddAgent}
            >
              Add Agent
            </button>
          </div>
        ) : (
          <>
            <div className="col-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Agent Name"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={agencyName}
                  onChange={(e) => setAgencyName(e.target.value)}
                  placeholder="Agency"
                />
              </div>
              <div className="form-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Agent Email"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex align-items-center review-description">
                <div className="d-flex">
                  <i className="fa fa-exclamation-circle fa-lg mt-1 mr-2"></i>
                  <p>Agent submissions will be reviewed by the Opendate team. You’ll receive a notification when this agent is available in the app.</p>
                </div>
              </div>
            </div>
            <div className="col-6 mt-3">
              <button
                className="btn btn-primary mr-3 btn-submit"
                type="button"
                disabled={ !isValidForm }
                onClick={handleOnClickSubmit}
              >
                Submit
              </button>

              <button
                className="btn btn-external-inverse btn-cancel"
                type="button"
                onClick={handleOnClickCancel}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </AgentSuggestionWraper>
  )
};

export default AgentSuggestion
