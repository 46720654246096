import { combineReducers } from 'redux';

import {
} from '../constants/checkoutAccordionConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirms = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const checkoutAccordionReducer = combineReducers({
  csrfToken,
  team,
  confirms
});

export default checkoutAccordionReducer;
