import PropTypes from 'prop-types';
import React from 'react';

import SemiCircle from '../../SemiCircle/components/SemiCircle';

const filteredEventbriteTicketClasses = (confirm, currentUser) => {
  var ticketClasses = confirm.eventbrite_event.eventbrite_ticket_classes;

  if(currentUser.can_see_unmanifested_tickets){
    return ticketClasses;
  } else {
    return ticketClasses.filter((ticket) => ticket.manifested);
  }
}

const calculatePercentageSold = (totalTicketsSold, totalCapacity) => {
  if(totalTicketsSold === 0 || totalCapacity === 0){
    return 0;
  } else {
    return Math.ceil((totalTicketsSold / totalCapacity) * 100);
  }
}

const ManageTickets = ({
  csrfToken,
  team,
  confirm,
  currentUser
}) => {
  var eventbriteTicketClasses;
  var totalTicketsSold = 0;
  var totalUnmanifestedTickets = 0;
  var totalTicketsIssued = 0;
  var percentageSold = 0;

  if(confirm.eb_connected && confirm.eventbrite_event){
    eventbriteTicketClasses = filteredEventbriteTicketClasses(
      confirm,
      currentUser
    );

    totalTicketsSold = eventbriteTicketClasses
      .filter((ticket) => ticket.manifested)
      .reduce((sum, ticket) => {
        return sum + ticket.quantity_sold;
      }, 0);

    totalUnmanifestedTickets = eventbriteTicketClasses
      .filter((ticket) => !ticket.manifested)
      .reduce((sum, ticket) => {
        return sum + ticket.quantity_sold;
      }, 0);

    totalTicketsIssued = (
      totalTicketsSold + totalUnmanifestedTickets
    );

    percentageSold = calculatePercentageSold(
      totalTicketsSold,
      confirm.venue.capacity
    );
  }

  return (
    <div className="card no-border shadow-2">
      <div className="card-body px-md-3">
        <h5 className="strong">Tickets</h5>
        {(confirm.eb_connected && confirm.eventbrite_event) ? (
          <div>
            <div className="row pt-5 px-2">
              <div className="col-12 col-lg-4 d-flex justify-content-between flex-row flex-lg-column">
                <dl>
                  <dt className="text-muted">
                    <strong className="text-no-wrap">Sold</strong>
                  </dt>
                  <dd style={{"marginTop": "-5px"}}>
                    <strong className="large-number text-no-wrap">
                      {totalTicketsSold}
                    </strong>
                  </dd>
                </dl>
                <dl>
                  <dt className="text-muted">
                    <strong className="text-no-wrap">Capacity</strong>
                  </dt>
                  <dd style={{"marginTop": "-5px"}}>
                    <strong className="large-number text-no-wrap">
                      {confirm.venue.capacity}
                    </strong>
                  </dd>
                </dl>
              </div>
              <div className="col-12 col-lg-8 d-flex justify-content-center"
                   style={{"paddingBottom": "35px"}}>
                <SemiCircle wrapperClassName="align-self-end"
                            percentage={percentageSold} />
              </div>
            </div>
            {currentUser.can_see_unmanifested_tickets ? (
              <div className="row">
                <div className="col-12">
                  <div className="card border-0 rounded-0"
                       style={{
                         "background": "#f9f9f9",
                         "marginBottom": "11px"
                       }}>
                    <div className="card-body text-center m-0 py-3">
                      <span style={{"fontSize": "16px"}}><strong>{totalTicketsIssued} </strong></span>
                      <span className="text-muted small">
                        <strong>
                          Tickets Issued
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 mb-3">
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <table className="table table-sm table-borderless vertical-middle"
                       style={{tableLayout: "fixed"}}>
                  <colgroup>
                    <col span={1} style={{"width": "60%"}} />
                    <col span={1} style={{"width": "20%"}} />
                    <col span={1} style={{"width": "20%"}} />
                  </colgroup>
                  <tbody>
                    {eventbriteTicketClasses.sort((a, b) => {return new Date(a.sales_start) - new Date(b.sales_start)}).map((ticketClass, index) => (
                      <tr key={index}
                          style={{"borderBottom": "2px solid #efefef"}}
                          className={(ticketClass.before_sales_start ? "text-muted" : "")}>
                        <td className="py-2 overflow-ellipsis">
                          <strong title={ticketClass.name}>
                            {ticketClass.name}
                          </strong>
                          {ticketClass.before_sales_start ? (
                            <p className='mb-0 small text-overflow-ellipsis'
                               title={ticketClass.sales_timeline ? (ticketClass.sales_timeline) : ""}>
                              <span>Scheduled </span>
                              {ticketClass.sales_timeline ? (
                                ticketClass.sales_timeline
                              ) : null}
                            </p>
                          ) : null}
                        </td>
                        <td className="py-2 text-center">
                          {ticketClass.quantity_sold}/{ticketClass.quantity_total}
                        </td>
                        <td className="py-2 text-right">
                          {ticketClass.display_cost ? (
                            ticketClass.display_cost
                          ) : (
                            "FREE"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="mb-0 text-muted small text-left">
                  Event ID: <a href={confirm.eventbrite_event.eventbrite_url} target="_blank">{confirm.eventbrite_event.eventbrite_id}</a>
                </p>
              </div>
              <div className="col">
                <p className="mb-0 text-muted small text-right">
                  Powered by
                  <img src="/Eventbrite_Logo_full2x.png"
                       style={
                         {
                           "height": "12px",
                           "marginTop": "-3px",
                           "marginLeft": "2px"
                         }
                       }
                       className="img-fluid" />
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="row pt-3">
            <div className="col-12">
              <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 text-center">
                      <i className="fal fa-ticket-alt"
                         style={{"fontSize": "60px", "color": "rgba(25, 130, 196, .5)"}}></i>
                      <h5 className="pt-2">No Ticket Data Yet</h5>
                      <p className="text-muted">
                        Once your event is set up in your ticketing system ticket sales data will populate here.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
};

ManageTickets.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default ManageTickets;
