// Simple example of a React "smart" component

import { connect } from 'react-redux';
import UserMenu from '../components/UserMenu';
import * as actions from '../actions/userMenuActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  teamMemberships: state.teamMemberships,
  teamMenuOpen: state.teamMenuOpen
});

const mapDispatchToProps = (dispatch) => {
  return {
    destroyUserSession: (csrfToken) => dispatch(actions.destroyUserSession(dispatch, csrfToken)),
    teamMenuOpenChanged: (teamMenuOpen) => dispatch(actions.teamMenuOpenChanged(teamMenuOpen)),
    switchCurrentTeam: (csrfToken, teamId) => dispatch(actions.switchCurrentTeam(dispatch, csrfToken, teamId))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
