import PropTypes from 'prop-types';
import React from 'react';

class OnepostComposer extends React.Component {
  static propTypes = {
    publicKey: PropTypes.string.isRequired,
    authorizedPageIds: PropTypes.array.isRequired,
    imageIds: PropTypes.array,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
    token: PropTypes.string
  };

  static defaultProps = {
    onSuccess: (data) => {},
    onFailure: (error) => {},
    imageIds: []
  }

  constructor(props) {
    super(props);

    this.state = {
      publicKey: this.props.publicKey,
      authorizedPageIds: this.props.authorizedPageIds,
      imageIds: this.props.imageIds,
      onSuccess: this.props.onSuccess,
      onFailure: this.props.onFailure,
      token: this.props.token
    };
  }

  componentDidMount() {
    var onepost = new OnepostUI(this.el, this.state.publicKey, this.state.authorizedPageIds, {
      onSuccess: this.state.onSuccess,
      onFailure: this.state.onFailure,
      imageIds: this.state.imageIds,
      token: this.state.token
    });

    onepost.attach();
    this.setState({onepost: onepost});
  }

  componentWillUnmount() {
    this.state.onepost.detach();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.publicKey !== this.props.publicKey) {
      this.setState({publicKey: this.props.publicKey});
    }

    if (prevProps.authorizedPageIds !== this.props.authorizedPageIds) {
      this.setState({authorizedPageIds: this.props.authorizedPageIds});
    }

    if (prevProps.imageIds !== this.props.imageIds) {
      this.setState({imageIds: this.props.imageIds});
    }

    if (prevProps.token !== this.props.token) {
      this.setState({token: this.props.token});
    }

    if (prevProps.onSuccess !== this.props.onSuccess) {
      this.setState({onSuccess: this.props.onSuccess});
    }

    if (prevProps.onFailure !== this.props.onFailure) {
      this.setState({onFailure: this.props.onFailure});
    }
  }

  render() {
    return (
      <div className="onepost-composer pb-4"
           ref={el => this.el = el}>
      </div>
    );
  }
}

export default OnepostComposer;
