/* eslint-disable import/prefer-default-export */

export const VENUE_OWNERSHIP_REMOVED = 'VENUE_OWNERSHIP_REMOVED';
export const VENUE_OWNERSHIP_ADDED = 'VENUE_OWNERSHIP_ADDED';
export const MANUAL_VENUE_ENTRY_CLICKED = 'MANUAL_VENUE_ENTRY_CLICKED';

export const CANCEL_EDIT_VENUE_OWNERSHIP = 'CANCEL_EDIT_VENUE_OWNERSHIP';
export const VENUE_OWNERSHIP_BIO_CHANGED = 'VENUE_OWNERSHIP_BIO_CHANGED';
export const VENUE_OWNERSHIP_LOGO_EL_CHANGED = 'VENUE_OWNERSHIP_LOGO_EL_CHANGED';
export const VENUE_OWNERSHIP_LOGO_CHANGED = 'VENUE_OWNERSHIP_LOGO_CHANGED';
export const VENUE_OWNERSHIP_TICKET_LOGO_EL_CHANGED = 'VENUE_OWNERSHIP_TICKET_LOGO_EL_CHANGED';
export const VENUE_OWNERSHIP_TICKET_LOGO_CHANGED = 'VENUE_OWNERSHIP_TICKET_LOGO_CHANGED';
export const VENUE_OWNERSHIP_CHANGED = 'VENUE_OWNERSHIP_CHANGED';
export const VENUE_OWNERSHIP_LOGO_REMOVED = 'VENUE_OWNERSHIP_LOGO_REMOVED';
export const VENUE_OWNERSHIP_TICKET_LOGO_REMOVED = 'VENUE_OWNERSHIP_TICKET_LOGO_REMOVED';
export const VENUE_OWNERSHIP_ERRORS_CHANGED = 'VENUE_OWNERSHIP_ERRORS_CHANGED';

export const VENUE_SUGGESTION_NAME_CHANGED = 'VENUE_SUGGESTION_NAME_CHANGED';
export const VENUE_SUGGESTION_ADDRESS_ONE_CHANGED = 'VENUE_SUGGESTION_ADDRESS_ONE_CHANGED';
export const VENUE_SUGGESTION_ADDRESS_TWO_CHANGED = 'VENUE_SUGGESTION_ADDRESS_TWO_CHANGED';
export const VENUE_SUGGESTION_CITY_CHANGED = 'VENUE_SUGGESTION_CITY_CHANGED';
export const VENUE_SUGGESTION_STATE_CHANGED = 'VENUE_SUGGESTION_STATE_CHANGED';
export const VENUE_SUGGESTION_ZIP_CHANGED = 'VENUE_SUGGESTION_ZIP_CHANGED';
export const VENUE_SUGGESTION_ERRORS_CHANGED = 'VENUE_SUGGESTION_ERRORS_CHANGED';
export const VENUE_SUGGESTION_CREATION_SUCCESS = 'VENUE_SUGGESTION_CREATION_SUCCESS';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
