import PropTypes from 'prop-types';
import React from 'react';

import Tippy from '@tippyjs/react';

const SocialAlert = ({
  socialNetwork
}) => (
  <Tippy content={
           <div style={{
                  "fontSize": "10px"
                }}>
             Something happened and your post hasn't made its way to {socialNetwork} yet. We'll let you know when this post has published.
           </div>
         }
         placement={'top-start'}
         maxWidth={160}
         animation={'shift-away'}
         theme='material'>
    <div className="btn-alert-count small d-flex justify-content-center single"
        style={{
          width: "12px",
          height: "12px",
          fontSize: "8px"
        }}>
      <div className="align-self-center">
        <i className="fas fa-exclamation"></i>
      </div>
    </div>
  </Tippy>
);

SocialAlert.propTypes = {
  socialNetwork: PropTypes.string.isRequired
};

export default SocialAlert;
