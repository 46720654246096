/* eslint-disable import/prefer-default-export */

import {
  CONFIRM_CHANGED,
  ACTIVE_MENU_ITEM_CHANGED,
  UPDATE_CONFIRM,
  PERFORMER_REMOVED,
  PERFORMER_ADDED,
  PERFORMER_UPDATED,
  PERFORMER_DRAGGED,
  PAGE_DETAIL_CREATED,
  PAGE_DETAIL_REMOVED,
  PAGE_DETAIL_CHANGED,
  PAGE_DETAIL_DRAGGED,
  ACTIVE_TICKETING_MENU_ITEM_CHANGED,
  TICKET_TYPE_FOR_FORM_CHANGED,
  TICKET_TYPE_DELETED,
  TICKET_TYPE_ADDED,
  TICKET_TYPE_UPDATED,
  TICKET_TYPE_DRAGGED,
  TICKET_TYPES_CHANGED,
  PROMO_CODE_FOR_FORM_CHANGED,
  PROMO_CODE_DELETED,
  PROMO_CODE_ADDED,
  PROMO_CODE_UPDATED,
  MAIN_EVENT_IMAGE_LOADING_CHANGED,
  ADD_ONS_CHANGED,
  ADD_ON_FOR_FORM_CHANGED,
  ADD_ON_ADDED,
  ADD_ON_DELETED,
  ADD_ON_UPDATED,
  ADD_ON_DRAGGED,
  STATEMENT_DESCRIPTOR_SUFFIX_CHANGED,
  ARTIST_BIOS_CHANGED,
  PAGE_DETAIL_TEMPLATES_CHANGED,
  PAGE_DETAIL_TEMPLATE_DELETED,
  PAGE_DETAIL_TEMPLATE_CHANGED,
  PAGE_DETAIL_TEMPLATE_ADDED,
  EXAMPLE_TICKET_CHANGED,
  IS_LOADING_DEFAULT_TICKET_TYPES_CHANGED,
  IS_EDITING_ADD_ONS_INTRO_CHANGED,
  ADD_ONS_INTRO_EDITOR_STATE_CHANGED,
  IS_LOADING_DEFAULT_ADD_ONS_CHANGED,
  IS_EDITING_ADD_ONS_ALERT_MESSAGE_CHANGED,
  ADD_ONS_ALERT_MESSAGE_EDITOR_STATE_CHANGED,
  REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_ADDED,
  REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_CHANGED,
  REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_DELETED,
  WARN_BEFORE_LEAVING_CHANGED,
  WARN_BEFORE_LEAVING_OPEN_CHANGED,
  WARN_BEFORE_LEAVING_CALLBACK_CHANGED,
  CHECKOUT_CATEGORIES_CHANGED
} from '../constants/confirmTicketingConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {stateToHTML} from 'draft-js-export-html';

import {
  offsetDateForBrowser,
  offsetDateForServer
} from '../../../shared/timeZoneLogic';

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

export const activeMenuItemChanged = (activeMenuItem) => ({
  type: ACTIVE_MENU_ITEM_CHANGED,
  activeMenuItem
});

export const confirmChanged = (confirm) => ({
  type: CONFIRM_CHANGED,
  confirm
});

export const updateConfirm = (confirm) => ({
  type: UPDATE_CONFIRM,
  confirm
});

export const autoSaveConfirm = (dispatch, csrfToken, team, confirm, throwError) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/confirms/" + confirm.id, {
        confirm: {
          title: confirm.title,
          presenter: confirm.presenter,
          venue_capacity: parseFloatFromString(confirm.venue_capacity || ""),
          venue_id: (confirm.venue ? confirm.venue.id : ""),
          venue_ownership_id: confirm.venue_ownership_id,
          age_restriction: confirm.age_restriction,
          start_time: confirm.start_time,
          door_time: confirm.door_time,
          end_time: confirm.end_time,
          ticketing_arrangement: confirm.ticketing_arrangement,
          seating_chart_id: confirm.seating_chart_id,
          add_on_ticketing_arrangement: confirm.add_on_ticketing_arrangement,
          add_on_seating_chart_id: confirm.add_on_seating_chart_id,
          has_add_ons_intro: confirm.has_add_ons_intro,
          add_ons_intro: confirm.add_ons_intro,
          has_add_ons_alert_message: confirm.has_add_ons_alert_message,
          add_ons_alert_message: confirm.add_ons_alert_message,
          max_tickets_per_email:  confirm.max_tickets_per_email,
          promote_on_bandsintown: confirm.promote_on_bandsintown,
          enable_recaptcha: confirm.enable_recaptcha
        }
      })
      .then(({ data }) => {
        // dispatch(attachmentAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        if(throwError) {
          throw error;
        }
      });
  };
};

export const deletePerformer = (dispatch, csrfToken, team, performer) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/performers/" + performer.id)
      .then(({ data }) => {
        dispatch(performerRemoved(data));
      })
      .catch((error) => {
        // dispatch(updateEventToEditErrors(error.response.data));
      });
  };
};

export const performerRemoved = (performer) => ({
  type: PERFORMER_REMOVED,
  performer
});

export const createPerformer = (dispatch, csrfToken, team, confirm, artist) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/performers", {
        performer: {
          artistable_type: "Artist",
          artistable_id: artist.value,
          performable_id: confirm.id,
          performable_type: "CalendarEvent"
        }
      })
      .then(({ data }) => {
        dispatch(performerAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const performerAdded = (performer) => ({
  type: PERFORMER_ADDED,
  performer
});

export const updatePerformer = (dispatch, csrfToken, team, performer) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/performers/" + performer.id, {
        performer: {
          act_type: performer.act_type,
          position: performer.position
        }
      })
      .then(({ data }) => {
        dispatch(performerUpdated(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const performerUpdated = (performer) => ({
  type: PERFORMER_UPDATED,
  performer
});

export const createPageDetail = (dispatch, csrfToken, team, confirm, pageDetail) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/page_details", {
        page_detail: {
          type: pageDetail.type,
          calendar_event_id: pageDetail.calendar_event_id,
          position: pageDetail.position
        }
      })
      .then(({ data }) => {
        dispatch(pageDetailCreated(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const pageDetailCreated = (pageDetail) => ({
  type: PAGE_DETAIL_CREATED,
  pageDetail
});

export const deletePageDetail = (dispatch, csrfToken, team, pageDetail) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/page_details/" + pageDetail.id)
      .then(({ data }) => {
        dispatch(pageDetailRemoved(data));
      })
      .catch((error) => {
        // dispatch(updateEventToEditErrors(error.response.data));
      });
  };
};

export const pageDetailRemoved = (pageDetail) => ({
  type: PAGE_DETAIL_REMOVED,
  pageDetail
});

export const pageDetailChanged = (pageDetail) => ({
  type: PAGE_DETAIL_CHANGED,
  pageDetail
});

export const autoSavePageDetail = (dispatch, csrfToken, team, pageDetail) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var pageDetailParams;

    switch(pageDetail.type) {
      case "PageDetails::Text":
        pageDetailParams = {
          position: pageDetail.position,
          body: (pageDetail.body ? stateToHTML(pageDetail.body.getCurrentContent()) : "")
        }
        break;
      case "PageDetails::Image":
        pageDetailParams = {
          position: pageDetail.position
        }
        break;
      case "PageDetails::Video":
        pageDetailParams = {
          position: pageDetail.position,
          video_url: pageDetail.video_url
        }
        break;
    }

    return axios.patch("/teams/" + team.id + "/page_details/" + pageDetail.id, {
        page_detail: pageDetailParams
      })
      .then(({ data }) => {
        if(pageDetail.type !== "PageDetails::Text"){
          dispatch(pageDetailChanged(data));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const pageDetailDragged = (result) => ({
  type: PAGE_DETAIL_DRAGGED,
  result
});

export const uploadPageDetailImage = (dispatch, csrfToken, team, pageDetail, file) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var updated = Object.assign({}, pageDetail, {_isLoading: true});
    dispatch(pageDetailChanged(updated));

    var formData = new FormData();
    formData.append("page_detail[image]", file);

    return axios.patch("/teams/" + team.id + "/page_details/" + pageDetail.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        pageDetail = Object.assign({}, data);
        dispatch(pageDetailChanged(pageDetail));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        var updated = Object.assign({}, pageDetail, {_isLoading: false});
        dispatch(pageDetailChanged(updated));
      });
  };
};

export const uploadPageDetailImageUrl = (dispatch, csrfToken, team, pageDetail, url) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var updated = Object.assign({}, pageDetail, {_isLoading: true});
    dispatch(pageDetailChanged(updated));

    return axios.patch("/teams/" + team.id + "/page_details/" + pageDetail.id, {
        page_detail: {
          image_url: url
        }
      })
      .then(({ data }) => {
        pageDetail = Object.assign({}, data);
        dispatch(pageDetailChanged(pageDetail));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        var updated = Object.assign({}, pageDetail, {_isLoading: false});
        dispatch(pageDetailChanged(updated));
      });
  };
};

export const uploadConfirmMainImageFile = (dispatch, csrfToken, team, confirm, file) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(mainEventImageLoadingChanged(true));

    var formData = new FormData();
    formData.append("confirm[main_event_image]", file);

    return axios.patch("/teams/" + team.id + "/confirms/" + confirm.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        dispatch(updateConfirm(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        dispatch(mainEventImageLoadingChanged(false));
      });
  };
};

export const uploadConfirmMainImageUrl = (dispatch, csrfToken, team, confirm, url) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(mainEventImageLoadingChanged(true));

    return axios.patch("/teams/" + team.id + "/confirms/" + confirm.id, {
        confirm: {
          main_event_image_url: url
        }
      })
      .then(({ data }) => {
        dispatch(updateConfirm(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        dispatch(mainEventImageLoadingChanged(false));
      });
  };
};

export const activeTicketingMenuItemChanged = (menuItem) => ({
  type: ACTIVE_TICKETING_MENU_ITEM_CHANGED,
  menuItem
});

export const ticketTypeForFormChanged = (ticketType) => ({
  type: TICKET_TYPE_FOR_FORM_CHANGED,
  ticketType
});

export const fetchNewTicketType = (dispatch, csrfToken, team, confirm, ticketTypeToCopy) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var url = "/teams/" + team.id + "/ticket_types/new?confirm_id=" + confirm.id;
    if(ticketTypeToCopy){
      url = (url + "&copy_from_id=" + ticketTypeToCopy.id);
    }

    return axios.get(url)
      .then(({ data }) => {
        var ticketType = Object.assign({}, data, {
          start_time: offsetDateForBrowser(data.start_time),
          end_time: offsetDateForBrowser(data.end_time)
        });

        dispatch(ticketTypeForFormChanged(ticketType));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const deleteTicketType = (dispatch, csrfToken, team, ticketType) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/ticket_types/" + ticketType.id)
      .then(({ data }) => {
        dispatch(ticketTypeDeleted(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const ticketTypeDeleted = (ticketType) => ({
  type: TICKET_TYPE_DELETED,
  ticketType
});

export const fetchTicketTypeForEdit = (dispatch, csrfToken, team, ticketType) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get("/teams/" + team.id + "/ticket_types/" + ticketType.id  + "/edit")
      .then(({ data }) => {
        var ticketType = Object.assign({}, data, {
          start_time: offsetDateForBrowser(data.start_time),
          end_time: offsetDateForBrowser(data.end_time)
        });

        dispatch(ticketTypeForFormChanged(ticketType));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

const ticketTypeParams = (ticketType, confirm) => {
  var params = {
    name: ticketType.name,
    description: ticketType.description,
    available: (ticketType.available ? parseFloatFromString(ticketType.available) : null),
    price: (ticketType.price_two_decimals ? parseFloatFromString(ticketType.price_two_decimals) : null),
    settlement_amount: (ticketType.settlement_amount_two_decimals ? parseFloatFromString(ticketType.settlement_amount_two_decimals) : null),
    availability: ticketType.availability,
    minimum_tickets_per_order: ticketType.minimum_tickets_per_order,
    maximum_tickets_per_order: ticketType.maximum_tickets_per_order,
    free: ticketType.free,
    visibility: ticketType.visibility,
    sales_channel: ticketType.sales_channel,
    has_fixed_fees: ticketType.has_fixed_fees,
    fixed_fee_amount: (ticketType.fixed_fee_amount_two_decimals ? parseFloatFromString(ticketType.fixed_fee_amount_two_decimals) : "0.00"),
    position: ticketType.position,
    absorb_fees: ticketType.absorb_fees,
    should_be_default: ticketType.should_be_default,
    manifested: ticketType.manifested,
    start_time: offsetDateForServer(ticketType.start_time),
    end_time: offsetDateForServer(ticketType.end_time),
    start_show_activity_id: ticketType.start_show_activity_id,
    end_show_activity_id: ticketType.end_show_activity_id,
    wait_for_ticket_type_id: ticketType.wait_for_ticket_type_id,
    taxable_items_attributes: ticketType.taxable_items,
    has_bundled_ticket_type: ticketType.has_bundled_ticket_type,
    bundled_ticket_type_id: ticketType.bundled_ticket_type_id,
  };

  if(confirm && confirm.id){
    params = Object.assign({}, params, {
      calendar_event_id: confirm.id
    });
  }

  return params;
}

export const createTicketType = (dispatch, csrfToken, team, confirm, ticketType) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/ticket_types", {
        ticket_type: ticketTypeParams(ticketType, confirm)
      })
      .then(({ data }) => {
        dispatch(ticketTypeAdded(data));
        dispatch(ticketTypeForFormChanged({}));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const ticketTypeAdded = (ticketType) => ({
  type: TICKET_TYPE_ADDED,
  ticketType
});

export const updateTicketType = (dispatch, csrfToken, team, ticketType) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/ticket_types/" + ticketType.id, {
        ticket_type: ticketTypeParams(ticketType)
      })
      .then(({ data }) => {
        dispatch(ticketTypeUpdated(data));
        dispatch(ticketTypeForFormChanged({}));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const ticketTypeUpdated = (ticketType) => ({
  type: TICKET_TYPE_UPDATED,
  ticketType
});

export const ticketTypeDragged = (result) => ({
  type: TICKET_TYPE_DRAGGED,
  result
});

export const mainEventImageLoadingChanged = (mainEventImageLoading) => ({
  type: MAIN_EVENT_IMAGE_LOADING_CHANGED,
  mainEventImageLoading
});

export const publishConfirm = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/confirms/" + confirm.id + "/publish", {
        confirm: {
          published_at: (confirm.is_public ? offsetDateForServer(confirm.published_at) : new Date()),
          is_public: confirm.is_public,
        }
      })
      .then(({ data }) => {
        window.location.replace("/teams/" + team.id + "/confirms/" + confirm.id);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const fetchNewAddOn = (dispatch, csrfToken, team, confirm, addOnToCopy) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var url = "/teams/" + team.id + "/add_ons/new?confirm_id=" + confirm.id;
    if(addOnToCopy){
      url = (url + "&copy_from_id=" + addOnToCopy.id);
    }

    return axios.get(url)
      .then(({ data }) => {
        var addOn = Object.assign({}, data, {
          start_time: offsetDateForBrowser(data.start_time),
          end_time: offsetDateForBrowser(data.end_time)
        });

        dispatch(addOnForFormChanged(addOn));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const addOnForFormChanged = (addOn) => ({
  type: ADD_ON_FOR_FORM_CHANGED,
  addOn
});

const addOnParams = (addOn, confirm) => {
  var params = {
    add_on: {
      calendar_event_id: confirm.id,
      name: addOn.name,
      description: addOn.description,
      absorb_fees: addOn.absorb_fees,
      confirmation_details: addOn.confirmation_details,
      available: (addOn.available ? parseFloatFromString(addOn.available) : null),
      availability: addOn.availability,
      price: (addOn.price_two_decimals ? parseFloatFromString(addOn.price_two_decimals) : null),
      minimum_per_order: (addOn.minimum_per_order ? parseFloatFromString(addOn.minimum_per_order) : null),
      maximum_per_order: (addOn.maximum_per_order ? parseFloatFromString(addOn.maximum_per_order) : null),
      require_ticket_type: addOn.require_ticket_type,
      ticket_type_ids: (addOn.ticket_type_ids ? addOn.ticket_type_ids : []),
      position: addOn.position,
      start_time: offsetDateForServer(addOn.start_time),
      end_time: offsetDateForServer(addOn.end_time),
      start_show_activity_id: addOn.start_show_activity_id,
      end_show_activity_id: addOn.end_show_activity_id,
      taxable_items_attributes: addOn.taxable_items
    }
  };

  return params
}

export const createAddOn = (dispatch, csrfToken, team, confirm, addOn) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/add_ons", addOnParams(addOn, confirm))
      .then(({ data }) => {
        dispatch(addOnAdded(data));
        dispatch(addOnForFormChanged({}));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const addOnAdded = (addOn) => ({
  type: ADD_ON_ADDED,
  addOn
});

export const fetchAddOnToEdit = (dispatch, csrfToken, team, addOn) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get("/teams/" + team.id + "/add_ons/" + addOn.id + "/edit")
      .then(({ data }) => {
        var addOn = Object.assign({}, data, {
          start_time: offsetDateForBrowser(data.start_time),
          end_time: offsetDateForBrowser(data.end_time)
        });

        dispatch(addOnForFormChanged(addOn));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const deleteAddOn = (dispatch, csrfToken, team, addOn) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/add_ons/" + addOn.id)
      .then(({ data }) => {
        dispatch(addOnDeleted(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const addOnDeleted = (addOn) => ({
  type: ADD_ON_DELETED,
  addOn
});

export const updateAddOn = (dispatch, csrfToken, team, addOn) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/add_ons/" + addOn.id, addOnParams(addOn, confirm))
      .then(({ data }) => {
        dispatch(addOnUpdated(data));
        dispatch(addOnForFormChanged({}));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const addOnUpdated = (addOn) => ({
  type: ADD_ON_UPDATED,
  addOn
});

export const addOnDragged = (result) => ({
  type: ADD_ON_DRAGGED,
  result
});

export const statementDescriptorSuffixChanged = (statementDescriptorSuffix) => ({
  type: STATEMENT_DESCRIPTOR_SUFFIX_CHANGED,
  statementDescriptorSuffix
});

export const updateStatementDescriptorSuffix = (dispatch, csrfToken, team, confirm, statementDescriptorSuffix) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/confirms/" + confirm.id, {
        confirm: {
          statement_descriptor_suffix: statementDescriptorSuffix
        }
      })
      .then(({ data }) => {
        dispatch(updateConfirm(data));

        toast.success("Credit Card Statement Descriptor successfully updated", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const artistBiosChanged = (artistBios) => ({
  type: ARTIST_BIOS_CHANGED,
  artistBios
});

export const pageDetailTemplatesChanged = (pageDetailTemplates) => ({
  type: PAGE_DETAIL_TEMPLATES_CHANGED,
  pageDetailTemplates
});

export const deletePageDetailTemplate = (dispatch, csrfToken, team, pageDetailTemplate) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/page_detail_templates/" + pageDetailTemplate.id)
      .then(({ data }) => {
        dispatch(pageDetailTemplateDeleted(pageDetailTemplate));
      })
      .catch((error) => {
        // dispatch(updateEventToEditErrors(error.response.data));
      });
  };
};

export const pageDetailTemplateDeleted = (pageDetailTemplate) => ({
  type: PAGE_DETAIL_TEMPLATE_DELETED,
  pageDetailTemplate
});

export const pageDetailTemplateChanged = (pageDetailTemplate) => ({
  type: PAGE_DETAIL_TEMPLATE_CHANGED,
  pageDetailTemplate
});

export const createPageDetailTemplate = (dispatch, csrfToken, team, pageDetailTemplate, body, onSuccess) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/page_detail_templates", {
        page_detail_template: {
          name: pageDetailTemplate.name,
          body: body
        }
      })
      .then(({ data }) => {
        dispatch(pageDetailTemplateChanged({}));
        dispatch(pageDetailTemplateAdded(data));
        onSuccess(data);

        toast.success("Template has been saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const pageDetailTemplateAdded = (pageDetailTemplate) => ({
  type: PAGE_DETAIL_TEMPLATE_ADDED,
  pageDetailTemplate
});

export const createPerformerAndArtist = (dispatch, csrfToken, team, confirm, artistName) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/performers", {
        performer: {
          performable_id: confirm.id,
          performable_type: "CalendarEvent",
          artist_attributes: {
            name: artistName
          }
        }
      })
      .then(({ data }) => {
        dispatch(performerAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const performerDragged = (result) => ({
  type: PERFORMER_DRAGGED,
  result
});

export const buildExampleTicket = (dispatch, csrfToken, team, confirm, ticketType) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var normalParams = ticketTypeParams(ticketType, confirm);
    var params = {
      price: normalParams.price,
      free: normalParams.free,
      has_fixed_fees: normalParams.has_fixed_fees,
      fixed_fee_amount: normalParams.fixed_fee_amount,
      absorb_fees: normalParams.absorb_fees,
      calendar_event_id: normalParams.calendar_event_id,
      taxable_items_attributes: normalParams.taxable_items_attributes.map((taxableItem) => {
        return {...taxableItem, id: ""};
      })
    };

    return axios.post("/teams/" + team.id + "/ticket_types/build_ticket", {
        ticket_type: params
      })
      .then(({ data }) => {
        dispatch(exampleTicketChanged(data));
      });
  };
};

export const exampleTicketChanged = (exampleTicket) => ({
  type: EXAMPLE_TICKET_CHANGED,
  exampleTicket
});

export const createDefaultTicketTypes = (dispatch, csrfToken, team, confirm, seatingChart, destroyAll) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isLoadingDefaultTicketTypesChanged(true));

    return axios.post("/teams/" + team.id + "/ticket_types/create_defaults", {
        confirm_id: confirm.id,
        seating_chart_id: (seatingChart ? seatingChart.id : ""),
        destroy_all: destroyAll
      })
      .then(({ data }) => {
        dispatch(ticketTypesChanged(data));
      })
      .catch((error) => {
        toast.error("Something went wrong. Try again.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        dispatch(isLoadingDefaultTicketTypesChanged(false));
      });
  };
};

export const ticketTypesChanged = (ticketTypes) => ({
  type: TICKET_TYPES_CHANGED,
  ticketTypes
});

export const isLoadingDefaultTicketTypesChanged = (isLoadingDefaultTicketTypes) => ({
  type: IS_LOADING_DEFAULT_TICKET_TYPES_CHANGED,
  isLoadingDefaultTicketTypes
});

export const isEditingAddOnsIntroChanged = (isEditingAddOnsIntro) => ({
  type: IS_EDITING_ADD_ONS_INTRO_CHANGED,
  isEditingAddOnsIntro
});

export const addOnsIntroEditorStateChanged = (addOnsIntroEditorState) => ({
  type: ADD_ONS_INTRO_EDITOR_STATE_CHANGED,
  addOnsIntroEditorState
});

export const createDefaultAddOns = (dispatch, csrfToken, team, confirm, seatingChart, destroyAll) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isLoadingDefaultAddOnsChanged(true));

    return axios.post("/teams/" + team.id + "/add_ons/create_defaults", {
        confirm_id: confirm.id,
        seating_chart_id: (seatingChart ? seatingChart.id : ""),
        destroy_all: destroyAll
      })
      .then(({ data }) => {
        dispatch(addOnsChanged(data));
      })
      .catch((error) => {
        toast.error("Something went wrong. Try again.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        dispatch(isLoadingDefaultAddOnsChanged(false));
      });
  };
};

export const addOnsChanged = (addOns) => ({
  type: ADD_ONS_CHANGED,
  addOns
});

export const isLoadingDefaultAddOnsChanged = (isLoadingDefaultAddOns) => ({
  type: IS_LOADING_DEFAULT_ADD_ONS_CHANGED,
  isLoadingDefaultAddOns
});

export const isEditingAddOnsAlertMessageChanged = (isEditingAddOnsAlertMessage) => ({
  type: IS_EDITING_ADD_ONS_ALERT_MESSAGE_CHANGED,
  isEditingAddOnsAlertMessage
});

export const addOnsAlertMessageEditorStateChanged = (addOnsAlertMessageEditorState) => ({
  type: ADD_ONS_ALERT_MESSAGE_EDITOR_STATE_CHANGED,
  addOnsAlertMessageEditorState
});

export const createRequirePerTicketCustomChargeType = (dispatch, csrfToken, team, confirm) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/require_per_ticket_custom_charge_types", {
        custom_charge_type: {
          calendar_event_id: confirm.id
        }
      })
      .then(({ data }) => {
        dispatch(requirePerTicketCustomChargeTypeAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const requirePerTicketCustomChargeTypeAdded = (requirePerTicketCustomChargeType) => ({
  type: REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_ADDED,
  requirePerTicketCustomChargeType
});

export const requirePerTicketCustomChargeTypeChanged = (requirePerTicketCustomChargeType) => ({
  type: REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_CHANGED,
  requirePerTicketCustomChargeType
});

export const deleteRequirePerTicketCustomChargeType = (dispatch, csrfToken, team, requirePerTicketCustomChargeType) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/require_per_ticket_custom_charge_types/" + requirePerTicketCustomChargeType.id)
      .then(({ data }) => {
        dispatch(requirePerTicketCustomChargeTypeDeleted(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const requirePerTicketCustomChargeTypeDeleted = (requirePerTicketCustomChargeType) => ({
  type: REQUIRE_PER_TICKET_CUSTOM_CHARGE_TYPE_DELETED,
  requirePerTicketCustomChargeType
});

export const autoSaveRequirePerTicketCustomChargeType = (dispatch, csrfToken, team, requirePerTicketCustomChargeType) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/require_per_ticket_custom_charge_types/" + requirePerTicketCustomChargeType.id, {
        custom_charge_type: {
          name: requirePerTicketCustomChargeType.name,
          default_amount: (requirePerTicketCustomChargeType.default_amount ? parseFloatFromString(requirePerTicketCustomChargeType.default_amount) : ""),
          enabled: requirePerTicketCustomChargeType.enabled
        }
      })
      .then(({ data }) => {
        // ...
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const warnBeforeLeavingChanged = (warnBeforeLeaving) => ({
  type: WARN_BEFORE_LEAVING_CHANGED,
  warnBeforeLeaving
});

export const warnBeforeLeavingOpenChanged = (warnBeforeLeavingOpen) => ({
  type: WARN_BEFORE_LEAVING_OPEN_CHANGED,
  warnBeforeLeavingOpen
});

export const warnBeforeLeavingCallbackChanged = (warnBeforeLeavingCallback) => ({
  type: WARN_BEFORE_LEAVING_CALLBACK_CHANGED,
  warnBeforeLeavingCallback
});

export const checkoutCategoriesChanged = (checkoutCategories) => ({
  type: CHECKOUT_CATEGORIES_CHANGED,
  checkoutCategories
});
