// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ScheduledPosts from '../components/ScheduledPosts';
import * as actions from '../actions/scheduledPostsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  currentUser: state.currentUser,
  dataTableTitle: state.dataTableTitle,
  dataTableDataSource: state.dataTableDataSource,
  dataTableOrder: state.dataTableOrder,
  dataTableColumns: state.dataTableColumns,
  dataTableEl: state.dataTableEl,
  searchedSearchTerm: state.searchedSearchTerm,
  postToEdit: state.postToEdit,
  onepostPublicKey: state.onepostPublicKey,
  authorizedPages: state.authorizedPages,
  newPost: state.newPost,
  hasPosts: state.hasPosts,
  openNewPost: state.openNewPost
});

const mapDispatchToProps = (dispatch) => {
  return {
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    deletePost: (csrfToken, team, post, dataTable) => dispatch(actions.deletePost(dispatch, csrfToken, team, post, dataTable)),
    postToEditChanged: (post) => dispatch(actions.postToEditChanged(post)),
    updatePost: (csrfToken, team, post, postIntentToken) => dispatch(actions.updatePost(dispatch, csrfToken, team, post, postIntentToken)),
    newPostChanged: (post) => dispatch(actions.newPostChanged(post)),
    createPost: (csrfToken, team, postIntentToken, newPost) => dispatch(actions.createPost(dispatch, csrfToken, team, postIntentToken, newPost)),
    hasPostsChanged: (hasPosts) => dispatch(actions.hasPostsChanged(hasPosts))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ScheduledPosts);
