import PropTypes from 'prop-types';
import React from 'react';

import Dropzone from 'react-dropzone'

const ImagePageDetail = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  pageDetail,
  imageCalendarEventAttachments,
  uploadPageDetailImage,
  uploadPageDetailImageUrl,
  rootUrl
}) => (
  <div>
    {pageDetail._isLoading ? (
      <div className="mb-4 position-relative">
        <div className="">
          <div style={{
                 "width": "100%",
                 "height": "195px",
                 "border": "2px dashed #cccccc"
               }}>
          </div>
        </div>
        <img className="img-fluid position-absolute absolute-position-center"
             src="/uploading-loading.gif"
             style={{width: "25px"}} />
      </div>
    ) : pageDetail.renderable ? (
      <center>
        <img src={rootUrl + "image_page_details/" + pageDetail.token + "/download"}
             className="img-fluid" />
      </center>
    ) : (
      <React.Fragment>
        <Dropzone maxFiles={1}
                  accept="image/*"
                  onDrop={
                    (files) => {
                      uploadPageDetailImage(csrfToken, team, pageDetail, files[0]);
                    }
                  }>
          {({getRootProps, getInputProps}) => (
            <section className="d-flex justify-content-center align-items-center text-center"
                     style={{
                       "height": "195px",
                       "border": "2px dashed #cccccc"
                     }}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <i className="fas fa-cloud-upload-alt mb-2"
                   style={{
                     "color": "#cccccc",
                     "fontSize": "36px"
                   }}></i>
                <p className="mb-0">
                  Drag-and-drop a file, or <a className="text-danger" onClick={(e) => e.preventDefault()} href="#">browse to upload</a>
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        {imageCalendarEventAttachments.length > 0 ? (
          <div className="row mt-4">
            <div className="col-12">
              <div className="card border-0 rounded-0"
                   style={{"background": "#f4f9fc"}}>
                <div className="card-body p-3">
                  <p className="mb-0">
                    <strong>
                      Choose from existing event assets
                    </strong>
                  </p>
                  {imageCalendarEventAttachments.map((calendarEventAttachment, index) =>
                    <a key={index}
                       onClick={
                         (e) => {
                           e.preventDefault();
                           uploadPageDetailImageUrl(csrfToken, team, pageDetail, calendarEventAttachment.resolved_file_url);
                         }
                       }
                       href="#">
                      <div title={calendarEventAttachment.human_file_name}
                           className="d-inline-block mr-3 mt-3"
                           style={{
                             "width": "55px",
                             "height": "55px",
                             "borderRadius": "2px",
                             "backgroundSize": "cover",
                             "backgroundImage": "url(" + calendarEventAttachment.compressed_file_url + ")"
                           }}>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    )}
  </div>
);

ImagePageDetail.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  pageDetail: PropTypes.object.isRequired,
  imageCalendarEventAttachments: PropTypes.array,
  uploadPageDetailImage: PropTypes.func.isRequired,
  uploadPageDetailImageUrl: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired
};

export default ImagePageDetail;
