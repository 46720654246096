import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ConfirmRow = ({
  confirm,
  confirms,
  index
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(isOpen) {
      ODEmbed(`od-confirm-${confirm.id}-iframe`);
    }
  }, [isOpen])

  return (
    <div className='col-12'
          style={
            confirms.length === (index + 1) ? {
              "marginBottom": "20px"
            } : {
              "paddingBottom": "20px",
              "marginBottom": "20px",
              "borderBottom": "2px solid #e4e4e4"
            }
          }>
      <div className='row'>
        <div className='col-12 d-flex align-items-center justify-content-between'>
          <div>
            <p className="mb-1 mt-1 text-dark"
              style={{"fontSize": "24px"}}>
              <strong>{confirm.title}</strong>
            </p>
            {confirm.support_artist_names && confirm.support_artist_names.length > 0 ? (
              <p className="mb-1"
                style={{"fontSize": "16px", "color": "#686868"}}>
                with {confirm.support_artist_names}
              </p>
            ) : null}
            <p className="mb-1"
              style={{"color": "#686868"}}>
              {confirm.human_date_and_time_with_day_of_week}
            </p>
            {confirm.venue.has_non_country_address_info ? (
              <p className="mb-1"
                style={{"color": "#686868"}}>
                {confirm.venue.address_from_components}
              </p>
            ) : null}
          </div>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();
                  setIsOpen(!isOpen);
                }
              }
              style={{"borderRadius": "4px"}}
              className='btn btn-primary text-nowrap'>
            {isOpen ? (
              <strong>Hide Tickets</strong>
            ) : (
              <strong>Get Tickets</strong>
            )}
          </a>
        </div>
        {isOpen ? (
          <div className='col-12'>
            <iframe src={`${confirm.widget_embed_url}?show_header=false`}
                    id={`od-confirm-${confirm.id}-iframe`}
                    title={confirm.title}
                    allowpaymentrequest="true"
                    style={{"border": "none", "width": "1px", "minWidth": "100%"}}
                    height="600px">
            </iframe>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const CheckoutAccordion = ({
  csrfToken,
  team,
  confirms
}) => {
  return (
    <div className="form-row" style={{"marginTop": "12px"}}>
      {confirms.map((confirm, index) =>
        <ConfirmRow key={index}
                    index={index}
                    confirms={confirms}
                    confirm={confirm} />
      )}
    </div>
  )
};

CheckoutAccordion.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirms: PropTypes.array
};

export default CheckoutAccordion;
