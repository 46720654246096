// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OutreachManager from '../components/OutreachManager';
import * as actions from '../actions/outreachManagerActionCreators';
import * as eventFormActions from '../../EventForm/actions/eventFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.outreachManager.csrfToken,
  team: state.outreachManager.team,
  currentUser: state.outreachManager.currentUser,
  dataTableTitle: state.outreachManager.dataTableTitle,
  dataTableDataSource: state.outreachManager.dataTableDataSource,
  dataTableOrder: state.outreachManager.dataTableOrder,
  dataTableColumns: state.outreachManager.dataTableColumns,
  dataTableEl: state.outreachManager.dataTableEl,
  followUp: state.outreachManager.followUp,
  outreachesExists: state.outreachManager.outreachesExists,
  venueOwnerships: state.outreachManager.venueOwnerships,
  outreachTemplates: state.outreachManager.outreachTemplates,
  newOutreach: state.outreachManager.newOutreach,
  modalIsOpen: state.outreachManager.modalIsOpen,
  defaultOffer: state.outreachManager.defaultOffer,
  venuesOptions: state.outreachManager.venuesOptions,
  venueSelected: state.outreachManager.venueSelected,
  calendarEventArtists: state.outreachManager.calendarEventArtists,
  confirm: state.outreachManager.confirm,
  holdGroup: state.outreachManager.holdGroup,
  offer: state.outreachManager.offer,
  offerTemplates: state.outreachManager.offerTemplates,
  pipeline: state.outreachManager.pipeline,
  buyers: state.eventForm.buyers,
  promoters: state.eventForm.promoters,
  selectedBuyer: state.eventForm.selectedBuyer,
  selectedPromoter: state.eventForm.selectedPromoter,
  submittingForm: state.outreachManager.submittingForm,
  doorsTimeDefault: state.eventForm.doorsTimeDefault,
  eventEndTimeDefault: state.eventForm.eventEndTimeDefault,
  userChangedDoorsTime: state.eventForm.userChangedDoorsTime,
  userChangedEventEndTime: state.eventForm.userChangedEventEndTime,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    followUpChanged: (followUp) => dispatch(actions.followUpChanged(followUp)),
    deleteOutreach: (team, outreach, csrfToken, rowTableEl) => dispatch(actions.deleteOutreach(dispatch, team, outreach, csrfToken, rowTableEl)),
    fetchNewFollowUp: (team, outreach, rowTableEl) => dispatch(actions.fetchNewFollowUp(dispatch, team, outreach, rowTableEl)),
    outreachExistsChanged: (outreachesExists) => dispatch(actions.outreachExistsChanged(outreachesExists)),
    offerModalOpened: () => dispatch(actions.offerModalOpened()),
    offerModalClosed: (defaultOffer) => dispatch(actions.offerModalClosed(dispatch, defaultOffer)),
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    onInit: (defaultOffer) => dispatch(actions.onInit(dispatch, defaultOffer)),
    createCalendarEventFromOutreachClicked: (outreach, rowTableEl, artists) => dispatch(actions.createCalendarEventFromOutreachClicked(outreach, rowTableEl, artists)),
    closeCalendarEventModal: () => dispatch(actions.closeCalendarEventModal()),
    newOutreachChanged: (outreach) => dispatch(actions.newOutreachChanged(outreach)),
    fetchNewOffer: (team, outreach, defaultOffer, rowTableEl) => dispatch(actions.fetchNewOffer(dispatch, team, outreach, defaultOffer, rowTableEl)),
    fetchNewOutreach: (team) => dispatch(actions.fetchNewOutreach(dispatch, team)),
    submitNewEventForm: (csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => dispatch(eventFormActions.submitNewEventForm(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer)),
    fetchPipeline: (team, pipelineId) => dispatch(actions.fetchPipeline(dispatch, team, pipelineId)),
    pipelineChanged: (pipeline) => dispatch(actions.pipelineChanged(pipeline)),
    applyTimeDefaults: (confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime) => dispatch(eventFormActions.applyTimeDefaults(dispatch, confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime)),
    userChangedDoorsTimeChanged: (userChangedDoorsTime) => dispatch(eventFormActions.userChangedDoorsTimeChanged(userChangedDoorsTime)),
    userChangedEventEndTimeChanged: (userChangedEventEndTime) => dispatch(eventFormActions.userChangedEventEndTimeChanged(userChangedEventEndTime)),
    applyTimeDefaults: (confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime) => dispatch(eventFormActions.applyTimeDefaults(dispatch, confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OutreachManager);
