import PropTypes from 'prop-types';
import React from 'react';

import RenderVideo from './PageDetails/RenderVideo';

const renderablePageDetails  = (pageDetails) => {
  return pageDetails.filter((pd) => pd.renderable);
}

const ConfirmDescription = ({
  pageDetails,
  rootUrl,
  addVideoStyle
}) => (
  <div className="confirm-description">
    {renderablePageDetails(pageDetails).map((pageDetail, index) =>
      <div key={index}
           style={{"marginBottom": "24px"}}>
        {pageDetail.type === "PageDetails::Text" ? (
          <div dangerouslySetInnerHTML={{__html: pageDetail.sanitized_body}}></div>
        ) : pageDetail.type === "PageDetails::Image" ? (
          <center>
            <img src={rootUrl + "image_page_details/" + pageDetail.token + "/download.jpg"}
                 style={{
                   "maxWidth": "100%",
                   "height": "auto"
                 }} />
          </center>
        ) : pageDetail.type === "PageDetails::Video" ? (
          <RenderVideo pageDetail={pageDetail}
                        addVideoStyle={addVideoStyle} />
        ) : null}
      </div>
    )}
  </div>
);

ConfirmDescription.defaultProps = {
  addVideoStyle: true
}

ConfirmDescription.propTypes = {
  pageDetails: PropTypes.array,
  rootUrl: PropTypes.string.isRequired,
  addVideoStyle: PropTypes.bool
};

export default ConfirmDescription;
