import React from "react";
import Tippy from '@tippyjs/react';
import AsyncSelect from "react-select/async";
import axios from "axios";

import { formInputContainerStyle, formInputStyle } from "./FanFilterGroupForm";

const MessageDataForm = (props) => {
  const {
    csrfToken,
    team,
    fanFilter,
    updateFanFilter,
  } = props;

  const supportedConditions = fanFilter.filter_param_options.condition || [];
  const supportedBehaviors = fanFilter.filter_param_options.behavior || [];

  const messageDataType = fanFilter.filter_params.message_data_type || "";
  const trackables = fanFilter.filter_params.trackables || [];
  const condition = fanFilter.filter_params.condition || "";
  const behavior = fanFilter.filter_params.behavior || "";

  const debounceFetchMarketingTrackables = _.debounce((inputValue, callback) => {
    new Promise(resolve => {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.get(`/teams/${team.id}/ahoy_messages/grouped_trackables_index.json?q=${inputValue}`)
        .then(function (response) {
          var options = response.data.map(function(data){
            return data;
          });

          callback(options);
        })
    });
  }, 250);

  return (
    <>
      <div style={formInputContainerStyle}>
        <select
          value={messageDataType}
          onChange={(e) => updateFanFilter({
            filter_params: {
              message_data_type: e.target.value,
            },
          })}
          style={formInputStyle}
        >
          {fanFilter.filter_param_options.message_data_type.map((filterType, i) => (
            <option key={i} value={filterType}>{filterType}</option>
          ))}
        </select>
      </div>
      <div style={formInputContainerStyle}>
        <AsyncSelect
          isMulti
          placeholder="select messages"
          defaultOptions
          value={trackables.map(trackable => ({
            value: {
              trackable_id: trackable.trackable_id,
              trackable_type: trackable.trackable_type,
              label: trackable.label,
            },
            label: trackable.label,
          }))}
          loadOptions={(inputValue, callback) => {
            return debounceFetchMarketingTrackables(inputValue, callback);
          }}
          formatGroupLabel={data => (
            <div className="text-dark">
              <strong>{data.label}</strong>
            </div>
          )}
          getOptionLabel={option => option.label}
          onChange={options => {
            updateFanFilter({
              filter_params: {
                ...fanFilter.filter_params,
                trackables: options?.map(option => option.value) || []
              },
            })
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              minWidth: "260px",
              borderRadius: "8px",
              border: trackables.length ? "1px solid #ccc" : "1px solid #FF595E",
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              width: "max-content",
              maxWidth: "500px"
            }),
            multiValue: (styles, { isMulti, data, index }) => {
              return index === 0 ? styles : {
                ...styles,
                ':before': {
                  backgroundColor: '#fff',
                  content: '" or "',
                  color: 'black',
                  paddingLeft: '4px',
                  paddingRight: '8px',
                }
              };
            }
          }}
        />
      </div>
      <div style={formInputContainerStyle}>
        <select
          value={condition}
          onChange={(e) => updateFanFilter({
            filter_params: {
              ...fanFilter.filter_params,
              condition: e.target.value,
              behavior: "",
            },
          })}
          style={{
            ...formInputStyle,
            border: condition ? "1px solid #ccc" : "1px solid #FF595E",
            color: condition ? "#333" : "#757575",
          }}
        >
          <option value="" disabled>
            Select a condition
          </option>
          {supportedConditions.map((operator, i) => (
            <option key={i} value={operator}>{operator}</option>
          ))}
        </select>
      </div>
      <div style={formInputContainerStyle}>
        <select
          value={behavior}
          onChange={(e) => updateFanFilter({
            filter_params: {
              ...fanFilter.filter_params,
              behavior: e.target.value,
            },
          })}
          style={{
            ...formInputStyle,
            border: behavior ? "1px solid #ccc" : "1px solid #FF595E",
            color: behavior ? "#333" : "#757575",
          }}
        >
          <option value="" disabled>
            Select a condition
          </option>
          {supportedBehaviors.map((operator, i) => (
            <option key={i} value={operator}>{operator}</option>
          ))}
        </select>
      </div>
      {fanFilter.filter_validation_errors.length ? (
        <Tippy content={fanFilter.filter_validation_errors.join(" ")}>
          <i className="far fa-circle-exclamation" style={{color: "#FF595E" }}/>
        </Tippy>
      ) : null}
    </>
  );
};

export default MessageDataForm;
