import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import FilterPanel from '../../TableView/components/FilterPanel';
import DataTable from '../../DataTable/components/DataTable';
import { ToastContainer } from 'react-toastify';
import SlidingPane from "react-sliding-pane";
import Dropzone from 'react-dropzone'

var _ = require('lodash');

import { v4 as uuidv4 } from 'uuid';
const axios = require('axios').default;
import $ from 'jquery';
require('datatables.net-bs4');

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const PaymentDisputeManager = ({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  dataTableElChanged,
  filters,
  filtersChanged,
  paymentDispute,
  paymentDisputeChanged,
  paymentDisputeUploadCreated
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);

  const reduceFilters = (filters) => {
    return filters.filter((filter) => {
      return filter.value.length > 0;
    }).reduce((params, filter) => {
      var newParam = {};
      newParam[filter.paramName] = filter.value;
  
      return Object.assign(params, newParam);
    }, {});
  }

  const reducedFilters = useMemo(() => {
    return (
      reduceFilters(filters)
    );
  }, [
    filters
  ]);

  const updateFilter = (filter, value) => {
    var updated = filters.map((item) => {
      if (item.paramName === filter.paramName) {
        var arrayValue = Array(value).flat();
        var newItem = Object.assign({}, item, {value: arrayValue});

        return newItem;
      }

      return item;
    })

    filtersChanged(updated);
  }

  const debouncedUpdateFilter = _.debounce((filter, value) => {
    updateFilter(filter, value);
  }, 200)

  const canSubmitForm = (
    paymentDispute
      && paymentDispute.id
      && paymentDispute.uncategorized_text
      && paymentDispute.uncategorized_text.length > 0
      && paymentDispute.one_time_submission_agreement
      && paymentDispute.payment_dispute_uploads.every((pdu) => pdu.category && pdu.category.length > 0)
      && !isSubmitting
  );

  const shouldDisableCategory = (category) => {
    return paymentDispute
      .payment_dispute_uploads
      .some((pdu) => pdu.category === category);
  }

  const createPaymentDisputeUpload = (file) => {
    new Promise(resolve => {
      var uploadingFile = {
        uuid: uuidv4(),
        file: file
      }

      setUploadingFiles((files) => {
        return [...files, uploadingFile];
      });

      var formData = new FormData();
      formData.append("payment_dispute_upload[file]", file);
      formData.append("payment_dispute_upload[payment_dispute_id]", paymentDispute.id);

      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
      axios.post(
        `/teams/${team.id}/payment_dispute_uploads`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(({data}) => {
        paymentDisputeUploadCreated(data);

        setUploadingFiles((files) => {
          return files.filter((f) => f.uuid !== uploadingFile.uuid);
        });
      })
    });
  };

  const updatePaymentDisputeUpload = (paymentDisputeUpload) => {
    new Promise(resolve => {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
      axios.patch(`/teams/${team.id}/payment_dispute_uploads/${paymentDisputeUpload.id}`, {
          payment_dispute_upload: {
            category: paymentDisputeUpload.category
          }
        })
    });
  };

  const destroyPaymentDisputeUpload = (paymentDisputeUpload) => {
    new Promise(resolve => {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
      axios.delete(`/teams/${team.id}/payment_dispute_uploads/${paymentDisputeUpload.id}`)
        .then(({data}) => {
          var updated = {
            ...paymentDispute,
            payment_dispute_uploads: paymentDispute.payment_dispute_uploads.filter((pdu) =>
              pdu.id !== data.id
            )
          }

          paymentDisputeChanged(updated);
        })
    });
  };

  const submitPaymentDispute = () => {
    new Promise(resolve => {
      setIsSubmitting(true);

      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
      axios.post(`/teams/${team.id}/payment_disputes/${paymentDispute.id}/submit`, {
          payment_dispute: {
            one_time_submission_agreement: paymentDispute.one_time_submission_agreement,
            uncategorized_text: paymentDispute.uncategorized_text
          }
        })
        .then(({data}) => {
          paymentDisputeChanged(data);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    });
  };

  return (
    <div>
      <ToastContainer />
      <FilterPanel filters={filters}
                    clearFilterValues={
                      () => {
                        var updated = filters.map((item) => {
                          return Object.assign({}, item, {value: []})
                        })

                        filtersChanged(updated);
                      }
                    }
                    debouncedUpdateFilter={debouncedUpdateFilter}
                    updateFilter={updateFilter} />
      <div className='row'>
        <div className='col text-right'
              style={{"paddingBottom": "16px"}}>
          <a href="https://docs.stripe.com/disputes/responding" target="_blank">
            <strong>
              Need help?
            </strong>
          </a>
        </div>
      </div>
      <DataTable title={dataTableTitle}
                  dataSource={dataTableDataSource}
                  columns={dataTableColumns}
                  colGroup={
                    <colgroup>
                      <col span={1} style={{"width": "5%"}} />
                      <col span={1} style={{"width": "20%"}} />
                      <col span={1} style={{"width": "15%"}} />
                      <col span={1} style={{"width": "20%"}} />
                      <col span={1} style={{"width": "13.33%"}} />
                      <col span={1} style={{"width": "13.33%"}} />
                      <col span={1} style={{"width": "13.33%"}} />
                    </colgroup>
                  }
                  onTableElementSet={
                    (el) => {
                      dataTableElChanged(el);
                    }
                  }
                  rowCallback={
                    function(el, data){
                      let paymentDispute = JSON.parse(decodeHtml(data.json));

                      el.addEventListener("click", () => {
                        paymentDisputeChanged(paymentDispute);
                      });
                    }
                  }
                  reducedFilters={reducedFilters}
                  order={dataTableOrder} />
      <SlidingPane
          isOpen={Object.keys(paymentDispute).length > 0}
          className="sliding-pane-container-full-height"
          width={"420px"}
          hideHeader={true}
          onRequestClose={() => {
            paymentDisputeChanged({});
            $(dataTableEl).DataTable().ajax.reload();
          }}
        >
          <>
            <div className="row m-0">
              <div className="col-12"
                    style={{
                      "background": "#1982C4",
                      "padding": "20px 30px"
                    }}>
                <div className='row text-white' style={{"fontSize": "16px"}}>
                  <div className='col'>
                    <p className='mb-0'>
                      <strong>Counter Dispute</strong>
                    </p>
                  </div>
                  <div className='col-xs-auto'
                        style={{"paddingRight": "15px"}}>
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            paymentDisputeChanged({});
                          }
                        }>
                      <i className="fas fa-times text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className='col-12'
                    style={{"marginTop": "20px"}}>
                <div className='card border-0'
                      style={{"background": "#F0F0F0", "borderRadius": "4px"}}>
                  <div className='card-body'
                        style={{"padding": "10px 22px 0px 22px"}}>
                    <table className='table table-sm table-borderless'>
                      <tbody>
                        <tr style={{"fontSize": "14px"}}>
                          <td>
                            <strong>Dispute details</strong>
                          </td>
                          <td className='text-right'>
                            {paymentDispute.status === "lost" ? (
                              <span style={{
                                "borderRadius": "4px",
                                "color": "#BD0006",
                                "background": "#FFC2C4",
                                "padding": "2px 5px",
                                "fontWeight": "600",
                                "fontSize": "12px"
                              }}>
                                Lost
                              </span>
                            ) : paymentDispute.status === "won" ? (
                              <span style={{
                                "borderRadius": "4px",
                                "color": "#6D9F1E",
                                "background": "#D4EFA9",
                                "padding": "2px 5px",
                                "fontWeight": "600",
                                "fontSize": "12px"
                              }}>
                                Won
                              </span>
                            ) : paymentDispute.status === "under_review" ? (
                              <span style={{
                                "borderRadius": "4px",
                                "color": "#1982C4",
                                "background": "#DFF0FB",
                                "padding": "2px 5px",
                                "fontWeight": "600",
                                "fontSize": "12px"
                              }}>
                                {`${paymentDispute.time_in_words_until_respond_by} till results`}
                              </span>
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </td>
                        </tr>
                        {paymentDispute.calendar_event && paymentDispute.calendar_event.id ? (
                          <tr style={{"fontSize": '12px'}}>
                            <td>Event</td>
                            <td className='text-right overflow-ellipsis'>
                              <a href={`/teams/${paymentDispute.team_id}/confirms/${paymentDispute.calendar_event.id}/dashboard`} target="_blank">
                                {paymentDispute.calendar_event.title}
                              </a>
                            </td>
                          </tr>
                        ) : null}
                        <tr style={{"fontSize": '12px'}}>
                          <td>Order ID</td>
                          <td className='text-right'>{paymentDispute.order_id}</td>
                        </tr>
                        <tr style={{"fontSize": '12px'}}>
                          <td>Amount</td>
                          <td className='text-right'>{formatter.format(paymentDispute.amount)}</td>
                        </tr>
                        <tr style={{"fontSize": '12px'}}>
                          <td>Reason</td>
                          <td className='text-right'>{paymentDispute.titleized_reason}</td>
                        </tr>
                        <tr style={{"fontSize": '12px'}}>
                          <td>Customer</td>
                          <td className='text-right'>{paymentDispute.email}</td>
                        </tr>
                        <tr style={{"fontSize": '12px'}}>
                          <td>Payment method</td>
                          <td className='text-right'>···· {paymentDispute.card_last4}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0" style={{"marginTop": "15px"}}>
              {paymentDispute.status === "lost" ? (
                <div className='col-12'>
                  <p className='mb-0' style={{"fontSize": "16px"}}>
                    <strong>This dispute was lost</strong>
                  </p>
                  <p className='mb-0' style={{"fontSize": "12px", "marginTop": "2px"}}>
                    {`The issuing bank decided in favor of the cardholder. The disputed amount of ${formatter.format(paymentDispute.amount)} USD and the dispute fee will not be returned to you.`}
                  </p>
                </div>
              ) : paymentDispute.status === "won" ? (
                <div className='col-12'>
                  <p className='mb-0' style={{"fontSize": "16px"}}>
                    <strong>You won the dispute!</strong>
                  </p>
                  <p className='mb-0' style={{"fontSize": "12px", "marginTop": "2px"}}>
                    {`The issuing bank decided in your favor${paymentDispute.human_closed_at ? ` on ${paymentDispute.human_closed_at}` : ""}. The disputed amount of ${formatter.format(paymentDispute.amount)} USD, less any applicable Stripe fees will be returned to you.`}
                  </p>
                </div>
              ) : paymentDispute.status === "under_review" ? (
                <div className='col-12'>
                  <p className='mb-0' style={{"fontSize": "16px"}}>
                    <strong>Your dispute evidence has been submitted</strong>
                  </p>
                  <p className='mb-0' style={{"fontSize": "12px", "marginTop": "2px"}}>
                    {`Your response${paymentDispute.human_submitted_at ? ` submitted on ${paymentDispute.human_submitted_at}` : ""} is being reviewed by the cardholder's issuing bank. We will notify you when we receive the issuing bank's decision, which can take up to three months.`}
                  </p>
                </div>
              ) : paymentDispute.status === "needs_response" ? (
                <div className='col-12'>
                  <form onSubmit={
                          (e) => {
                            e.preventDefault();

                            if(!canSubmitForm){
                              return false;
                            }

                            submitPaymentDispute();
                          }
                        }>
                    <p className="mb-0 small text-primary">
                      STEP 1 OF 2
                    </p>
                    <p className='mb-1' style={{"fontSize": "16px"}}>
                      <strong>Tell us about the dispute</strong>
                    </p>
                    <p className='mb-3 small'>
                      Your responses will help us collect the most relevant evidence to counter the cardholder's claim. 
                    </p>
                    <div className="form-group">
                      <div className='row'>
                        <div className='col'>
                          <label htmlFor="payment-dispute-uncategorized-text"
                                  className='text-dark'
                                  style={{"fontSize": "14px"}}>
                            <strong>Why should you win this dispute?</strong>
                          </label>
                        </div>
                        <div className='col-xs-auto'
                              style={{"paddingRight": "15px"}}>
                          <a href="https://docs.stripe.com/disputes/responding" target="_blank">
                            <strong>
                              Need help?
                            </strong>
                          </a>
                        </div>
                      </div>
                      <textarea className="form-control small"
                                style={{"border": "1px solid #E6E6E6"}}
                                id="payment-dispute-uncategorized-text"
                                placeholder='Provide a response'
                                value={paymentDispute.uncategorized_text || ""}
                                onChange={
                                  (e) => {
                                    var updated = {
                                      ...paymentDispute,
                                      uncategorized_text: e.target.value
                                    }

                                    paymentDisputeChanged(updated);
                                  }
                                }
                                rows="3">
                      </textarea>
                    </div>
                    <p className="mb-0 small text-primary"
                        style={{"marginTop": "25px"}}>
                      STEP 2 OF 2
                    </p>
                    <p className='mb-1' style={{"fontSize": "16px"}}>
                      <strong>Collect evidence</strong>
                    </p>
                    <p className='small' style={{"marginBottom": "10px"}}>
                      Your responses will help us collect the most relevant evidence to counter the cardholder's claim.
                    </p>
                    <p className='mb-1' style={{"fontSize": "14px"}}>
                      <strong>Upload evidence</strong>
                    </p>
                    <p className='mb-0 small'>
                      The following evidence is most relevant to this dispute. If you would like to share other types of evidence not listed below, upload it and label it as 'other'.
                    </p>
                    <ul className='small mt-3'>
                      <li>Customer communication</li>
                      <li>Customer signature</li>
                      <li>Receipt</li>
                      <li>Other evidence</li>
                    </ul>
                    <p className='small'
                        style={{"marginBottom": "16px"}}>
                      Upload one file per recommended document (5MB max size total)
                    </p>
                    {paymentDispute.payment_dispute_uploads.length > 0 || uploadingFiles.length > 0 ? (
                      <div style={{
                            "borderTop": "1px solid #CCCCCC",
                            "borderRight": "1px solid #CCCCCC",
                            "borderLeft": "1px solid #CCCCCC",
                            "padding": "20px 15px",
                            "borderTopLeftRadius": "8px",
                            "borderTopRightRadius": "8px"
                          }}>
                        <p className='small'
                            style={{"fontWeight": "600", "marginBottom": "10px"}}>
                          UPLOADED DOCUMENTS
                        </p>
                        {paymentDispute.payment_dispute_uploads.map((paymentDisputeUpload, index) => 
                          <div className='card border-0 mb-0' key={index}>
                            <div className='card-body' style={{"padding": "10px 0px"}}>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div class="form-group mb-0">
                                  <label htmlFor={`payment-dispute-upload-${index}-category`}
                                        style={{"fontSize": "12px"}}>
                                    {paymentDisputeUpload.file_file_name}
                                  </label>
                                  <select className="form-control form-control-sm"
                                          value={paymentDisputeUpload.category || ""}
                                          onChange={
                                            (e) => {
                                              var updatedPaymentDisputeUpload = {
                                                ...paymentDisputeUpload,
                                                category: e.target.value
                                              }

                                              var updated = {
                                                ...paymentDispute,
                                                payment_dispute_uploads: paymentDispute.payment_dispute_uploads.map((pd) => {
                                                  if(pd.id === updatedPaymentDisputeUpload.id){
                                                    return updatedPaymentDisputeUpload;
                                                  } else {
                                                    return pd;
                                                  }
                                                })
                                              }

                                              updatePaymentDisputeUpload(updatedPaymentDisputeUpload);
                                              paymentDisputeChanged(updated);
                                            }
                                          }
                                          style={{
                                            "border": "1px solid #CCCCCC",
                                            "marginTop": "-3px",
                                            "borderRadius": "8px"
                                          }}
                                          id={`payment-dispute-upload-${index}-category`}>
                                    <option value="">
                                      Choose category
                                    </option>
                                    <option value="cancellation_policy"
                                            disabled={shouldDisableCategory("cancellation_policy")}>
                                      Cancellation policy
                                    </option>
                                    <option value="customer_communication"
                                            disabled={shouldDisableCategory("customer_communication")}>
                                      Customer communication
                                    </option>
                                    <option value="customer_signature"
                                            disabled={shouldDisableCategory("customer_signature")}>
                                      Customer signature
                                    </option>                                    
                                    <option value="duplicate_charge_documentation"
                                            disabled={shouldDisableCategory("duplicate_charge_documentation")}>
                                      Duplicate charge documentation
                                    </option>
                                    <option value="receipt"
                                            disabled={shouldDisableCategory("receipt")}>
                                      Receipt
                                    </option>
                                    <option value="refund_policy"
                                            disabled={shouldDisableCategory("refund_policy")}>
                                      Refund policy
                                    </option>
                                    <option value="service_documentation"
                                            disabled={shouldDisableCategory("service_documentation")}>
                                      Service documentation
                                    </option>
                                    <option value="shipping_documentation"
                                            disabled={shouldDisableCategory("shipping_documentation")}>
                                      Shipping documentation
                                    </option>
                                    <option value="uncategorized_file"
                                            disabled={shouldDisableCategory("uncategorized_file")}>
                                      Other
                                    </option>
                                  </select>
                                </div>
                                <a href="#"
                                    style={{"fontSize": "18px"}}
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        destroyPaymentDisputeUpload(paymentDisputeUpload);
                                      }
                                    }
                                    className='text-muted ml-4'>
                                  <i className="far fa-times"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                        {uploadingFiles.map((uploadingFile, index) =>
                          <div className='card border-0 mb-0' key={index}>
                            <div className='card-body' style={{"padding": "10px 0px"}}>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div class="form-group mb-0">
                                  <label htmlFor={`payment-dispute-upload-${index}-category`}
                                        style={{"fontSize": "12px"}}>
                                    {uploadingFile.file.name}
                                  </label>
                                  <select className="form-control form-control-sm"
                                          disabled={true}
                                          style={{
                                            "border": "1px solid #CCCCCC",
                                            "marginTop": "-3px",
                                            "borderRadius": "8px",
                                            "width": "251.5px"
                                          }}
                                          id={`payment-dispute-upload-${index}-category`}>
                                    <option value="">
                                      Choose category
                                    </option>
                                  </select>
                                </div>
                                <img src="/uploading-loading.gif"
                                      className="mr-1"
                                      style={{width: "18px"}} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    <Dropzone accept={'application/pdf, image/jpeg, image/png'}
                              onDrop={
                                (files) => {
                                  files.map((f) => createPaymentDisputeUpload(f))
                                }
                              }>
                      {({getRootProps, getInputProps}) => (
                        <section style={{
                                  "border": "1px solid #CCCCCC",
                                  "padding": "20px 15px",
                                  "borderTopLeftRadius": (paymentDispute.payment_dispute_uploads.length === 0 ? "8px" : "0px"),
                                  "borderTopRightRadius": (paymentDispute.payment_dispute_uploads.length === 0 ? "8px" : "0px"),
                                  "borderBottomLeftRadius": "8px",
                                  "borderBottomRightRadius": "8px"
                                }}>
                          <input {...getInputProps()} />
                          <div {...getRootProps()}
                                className="d-flex flex-row justify-content-between align-items-center">
                            <p className="mb-0">
                              <a className="btn small btn-outline-primary"
                                  onClick={(e) => e.preventDefault()} href="#">
                                <i className="fas fa-arrow-up mr-2"></i>
                                Upload from computer
                              </a>
                            </p>
                            <p className='mb-0 small'>
                              Drag and drop files.
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <div className="custom-control custom-checkbox custom-checkbox-table"
                          style={{"marginTop": "20px"}}>
                      <input type="checkbox"
                              className="custom-control-input"
                              checked={paymentDispute.one_time_submission_agreement}
                              onChange={
                                (e) => {
                                  var updated = {
                                    ...paymentDispute,
                                    one_time_submission_agreement: e.target.checked
                                  }

                                  paymentDisputeChanged(updated);
                                }
                              }
                              id="payment-dispute-one-time-submission-agreement" />
                      <label class="custom-control-label"
                              htmlFor="payment-dispute-one-time-submission-agreement">
                        I understand that I can only issue this evidence to the cardholders card issuer once. 
                      </label>
                    </div>
                    <button type="submit"
                            disabled={!canSubmitForm}
                            style={{"margin": "13px 0px 20px 0px"}}
                            className='btn btn-primary btn-block'>
                      {isSubmitting ? (
                        <React.Fragment>
                          <img src="/uploading-loading.gif"
                              className="mr-1"
                              style={{width: "16px"}} />
                          <strong>Submitting Evidence...</strong>
                        </React.Fragment>
                      ) : (
                        <strong>Submit Evidence</strong>
                      )}
                    </button>
                  </form>
                </div>
              ) : null}
            </div>
          </>
      </SlidingPane>
    </div>
  )
};

PaymentDisputeManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  filters: PropTypes.array,
  filtersChanged: PropTypes.func.isRequired,
  paymentDispute: PropTypes.object,
  paymentDisputeChanged: PropTypes.func.isRequired,
  paymentDisputeUploadCreated: PropTypes.func.isRequired
};

export default PaymentDisputeManager;
