import { combineReducers } from 'redux';
import {
  ACTIVE_PAGE_CHANGED
} from '../constants/fanUpcomingEventsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirms = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const itemsCountPerPage = (state = 3, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const activePage = (state = 1, action) => {
  switch (action.type) {
    case ACTIVE_PAGE_CHANGED:
      return action.activePage;
    default:
      return state;
  }
};

const fanUpcomingEventsReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  confirms,
  itemsCountPerPage,
  activePage
});

export default fanUpcomingEventsReducer;
