import React from "react";

const domainInfoStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 7,
  alignItems: "center",
};

const checkIconStyle = {
  color: "#8AC926",
  fontSize: 24,
};

const xmarkIconStyle = {
  color: "#FF4B4B",
  fontSize: 24,
};

const domainTextStyle = {
  fontSize: 12,
  display: "flex",
  flexDirection: "column",
};

const SendDomainStatus = ({
  sendDomain
}) => {
  const hasEmailFromAddress = !!sendDomain?.from_address;

  return (
    <div style={domainInfoStyle}>
      {sendDomain?.verified && hasEmailFromAddress ? (
        <i className="far fa-circle-check" style={checkIconStyle} />
      ) : (
        <i className="far fa-circle-xmark" style={xmarkIconStyle} />
      )}
      <div style={domainTextStyle}>
        <b>{sendDomain?.domain}</b>
        {sendDomain?.verified ? (
          `Verified Domain${hasEmailFromAddress ? "" : ", missing \"Email From Address\""}`
        ) : (
          "Unverified Domain"
        )}
      </div>
    </div>
  );
};

export default SendDomainStatus;
