import React from "react";

import UnverifiedInstructions from "./UnverifiedInstructions";

const verificationRequiredStyle = {
  padding: 15,
  borderRadius: 5,
  backgroundColor: "#F2F2F2",
};

const verificationTextStyle = {
  display: "flex",
  color: "#333333",
  fontSize: 14,
  fontWeight: 700,
  alignItems: "center",
  gap: 5,
};

const verificationIconStyle = {
  color: "#333333",
  fontSize: 14,
};

const SetupInstructions = ({
  sendDomain,
}) => {
  return (
    <div style={verificationRequiredStyle}>
      <div style={verificationTextStyle}>
        <i className="far fa-triangle-exclamation" style={verificationIconStyle} />
        Verification Required
      </div>
      <UnverifiedInstructions sendDomain={sendDomain} />
    </div>
  );
};

export default SetupInstructions;
