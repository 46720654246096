import React, { useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import * as axios from 'axios';
import styled from "styled-components";

const ArtistsSearchWrapper = styled.div`
    position: relative;

    .form-control:not(.search-icon) {
        padding-left: 2em;
        border: none;
    }
    #artists-search {
        left: 0;
        width: 100%;
    }
`;

const SearchIcon = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1rem;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 2rem;
    background: white;
    color: rgba(51, 51, 51, 0.5);
    border: none;
    padding-left: 0.5rem;
`;

const ArtistsSearch = ({className}) => {
    const [artists, setArtists] = useState([]);
    const [singleSelections, setSingleSelections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [controller, setController] = useState(null);

    const handleOnSearch = async (query) => {
        setIsLoading(true);

        controller && controller.abort()
        const newController = new AbortController();
        setController(newController)

        const searchedArtists = await axios.get(`/artists/search_by_name?name=${query}`,{
            signal: newController.signal
         });

        setIsLoading(false);
        setArtists(searchedArtists.data);
    };

    useEffect(() => {
        if (singleSelections.length > 0) {
            window.location.href = `/artists/${singleSelections[0].permalink}`;
        }
    }, [singleSelections])

    return (
        <ArtistsSearchWrapper className={className}>
            <SearchIcon className="form-control search-icon">
                <i className="fa fa-search"></i>
            </SearchIcon>
            <AsyncTypeahead
                id="artists-search"
                className="artists-search"
                labelKey="name"
                filterBy={() => true}
                isLoading={false}
                options={artists}
                onSearch={handleOnSearch}
                placeholder="Search artists"
                useCache={false}
                selected={singleSelections}
                onChange={setSingleSelections}
            />
        </ArtistsSearchWrapper>
    );
}

export default ArtistsSearch;
