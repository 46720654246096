import React, { useRef } from "react";

import { useBroadcastShowContext } from "./BroadcastShowContainer";

const headerRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingBottom: 15,
};

const leftHeaderStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 15,
  maxWidth: "75%",
};

const nameStyle = {
  color: "#333333",
  fontSize: 16,
  fontWeight: 600,
};

const rightHeaderStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: 10,
};

const editLinkStyle = {
  backgroundColor: "white",
  border: "#1982C4 1px solid",
  borderRadius: 5,
  color: "#1982C4",
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
  height: "fit-content",
  padding: "10px 12px",
};

const boxStyle = {
  border: "1px solid #E6E6E6",
  borderRadius: "5px",
  height: "75vh",
  width: "100%",
  padding: "15px",
  overflow: "scroll",
};

const htmlContentStyle = {
  border: "none",
  height: "100%",
  overflow: "scroll",
  width: "100%",
};

const BroadcastShow = () => {
  const {
    team,
    venueOwnership,
    broadcast,
  } = useBroadcastShowContext();

  const emailHtml = broadcast.layout.includes("{{ content }}") ? (
    broadcast.layout.replace("{{ content }}", broadcast.beefree_template.template_html)
  ) : (
    broadcast.beefree_template.template_html
  );

  const iframeRef = useRef(null);

  const updateLinks = () => {
    const iframe = iframeRef.current;

    if (iframe && iframe.contentDocument) {
      const iframeLinks = iframe.contentDocument.querySelectorAll("a");

      iframeLinks.forEach(link => {
        link.setAttribute("disabled", true);
        link.setAttribute("aria-disabled", true);
        link.style.pointerEvents = "none";
      });
    }
  };

  return (
    <div>
      <div style={headerRowStyle}>
        <div style={leftHeaderStyle}>
          <div style={nameStyle}>
            Broadcasts / {venueOwnership.venueable.name} / {broadcast.name}
          </div>
        </div>
        <div style={rightHeaderStyle}>
          <a style={editLinkStyle} href={`/teams/${team.id}/broadcasts/${broadcast.id}/edit`}>
            Edit
          </a>
        </div>
      </div>
      <div style={boxStyle}>
        <iframe
          ref={iframeRef}
          srcDoc={emailHtml}
          style={htmlContentStyle}
          onLoad={updateLinks}
        />
      </div>
    </div>
  );
};

export default BroadcastShow;
