// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ProfitAndLoss from '../components/ProfitAndLoss';
import * as actions from '../actions/profitAndLossActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  confirm: state.confirm,
  currentUser: state.currentUser,
  showPageFrame: state.showPageFrame,
  incomeItems: state.incomeItems,
  incomeTotal: state.incomeTotal,
  incomeEstimateTotal: state.incomeEstimateTotal,
  incomeForecastTotal: state.incomeForecastTotal,
  expenseItems: state.expenseItems,
  expenseTotal: state.expenseTotal,
  expenseEstimateTotal: state.expenseEstimateTotal,
  expenseForecastTotal: state.expenseForecastTotal,
  profitLoss: state.profitLoss,
  profitLossEstimate: state.profitLossEstimate,
  profitLossForecast: state.profitLossForecast,
  ticketsSold: state.ticketsSold,
  ticketsSoldForecast: state.ticketsSoldForecast,
});

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(ProfitAndLoss);
