import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const BudgetBonusRow = ({
  csrfToken,
  team,
  currentUser,
  budget,
  budgetBonus,
  deleteBudgetBonus,
  debouncedAutoSaveBudgetBonus,
  updateBudget
}) => (
  <div className="row">
    <div className="col-xs-auto"
         style={{"paddingLeft": "15px"}}>
      <div className="form-group" style={{"marginBottom": "1px"}}>
        <label htmlFor={"budget-bonus-" + budgetBonus.id + "-ticket-count-requirement"}
               className="text-muted mb-1"
               style={{"fontSize": "10px"}}>
          After ticket sales meet or exceed
        </label>
        <MaskedInput className="form-control form-control-sm"
                     style={{
                       "width": "170px",
                       "fontSize": "14px",
                       "border": "1px solid #e7e7e7",
                       "borderRadius": "4px",
                       "marginRight": "15px"
                     }}
                     id={"budget-bonus-" + budgetBonus.id + "-ticket-count-requirement"}
                     mask={createNumberMask({
                       prefix: "",
                       decimalLimit: 0
                     })}
                     onChange={
                       (e) => {
                         var updatedBudgetBonus = Object.assign({}, budgetBonus, {
                           ticket_count_requirement: e.target.value
                         });
                         var budgetBonuses = [...budget.budget_bonuses].map((bb) => {
                           if(bb.id === budgetBonus.id){
                             return updatedBudgetBonus;
                           } else {
                             return bb;
                           }
                         });
                         var updatedBudget = Object.assign({}, budget, {
                           budget_bonuses: budgetBonuses
                         });

                         updateBudget(updatedBudget);
                         debouncedAutoSaveBudgetBonus(csrfToken, team, updatedBudgetBonus);
                       }
                     }
                     value={budgetBonus.ticket_count_requirement} />
      </div>
    </div>
    <div className="col-xs-auto">
      <div className="form-group" style={{"marginBottom": "1px"}}>
        <label htmlFor={"budget-bonus-" + budgetBonus.id + "-ticket-bonus-amount"}
               className="text-muted mb-1"
               style={{
                 "fontSize": "10px",
                 "marginLeft": "66px"
               }}>
          {budgetBonus.dollar_or_percentage_operator === "CASH" ? (
            <span>Bonus amount</span>
          ) : (
            <span>Percentage of ticket sales</span>
          )}
        </label>
        <div className="input-group input-group-sm"
             style={{"fontSize": "14px"}}>
          <div className="input-group-prepend">
            <button className="btn btn-light text-primary"
                    style={{
                      "border": "1px solid #bcdbf3",
                      "width": "33px",
                      "background": (budgetBonus.dollar_or_percentage_operator === "CASH" ? "#bcdbf3" : "white")
                    }}
                    onClick={
                      (e) => {
                        if(budgetBonus.dollar_or_percentage_operator === "CASH"){
                          return false;
                        }

                        var newUpsidePercentage = "";
                        var newDollarAmount = budgetBonus.upside_percentage;

                        var updatedBudgetBonus = Object.assign({}, budgetBonus, {
                          dollar_or_percentage_operator: "CASH",
                          upside_percentage: newUpsidePercentage,
                          dollar_amount: newDollarAmount
                        });
                        var budgetBonuses = [...budget.budget_bonuses].map((bb) => {
                          if(bb.id === budgetBonus.id){
                            return updatedBudgetBonus;
                          } else {
                            return bb;
                          }
                        });
                        var updatedBudget = Object.assign({}, budget, {
                          budget_bonuses: budgetBonuses
                        });

                        updateBudget(updatedBudget);
                        debouncedAutoSaveBudgetBonus(csrfToken, team, updatedBudgetBonus);
                      }
                    }
                    type="button">
              <strong>$</strong>
            </button>
            <button className="btn btn-light text-primary"
                    style={{
                      "border": "1px solid #bcdbf3",
                      "width": "33px",
                      "background": (budgetBonus.dollar_or_percentage_operator === "PERCENTAGE" ? "#bcdbf3" : "white")
                    }}
                    onClick={
                      (e) => {
                        if(budgetBonus.dollar_or_percentage_operator === "PERCENTAGE"){
                          return false;
                        }

                        var newUpsidePercentage = budgetBonus.dollar_amount;
                        var newDollarAmount = "";

                        var updatedBudgetBonus = Object.assign({}, budgetBonus, {
                          dollar_or_percentage_operator: "PERCENTAGE",
                          upside_percentage: newUpsidePercentage,
                          dollar_amount: newDollarAmount
                        });
                        var budgetBonuses = [...budget.budget_bonuses].map((bb) => {
                          if(bb.id === budgetBonus.id){
                            return updatedBudgetBonus;
                          } else {
                            return bb;
                          }
                        });
                        var updatedBudget = Object.assign({}, budget, {
                          budget_bonuses: budgetBonuses
                        });

                        updateBudget(updatedBudget);
                        debouncedAutoSaveBudgetBonus(csrfToken, team, updatedBudgetBonus);
                      }
                    }
                    type="button">
              <strong>%</strong>
            </button>
          </div>
          <CurrencyInput className="form-control"
                         id={"budget-bonus-" + budgetBonus.id + "-ticket-bonus-amount"}
                         style={{
                           "borderTop": "1px solid #e7e7e7",
                           "borderRight": "1px solid #e7e7e7",
                           "borderBottom": "1px solid #e7e7e7",
                           "width": "124px"
                         }}
                         value={
                           (
                             budgetBonus.dollar_or_percentage_operator === "CASH" ?
                               budgetBonus.dollar_amount : budgetBonus.upside_percentage
                           )
                         }
                         onChange={
                           (e) => {
                             var attrs;

                             if(budgetBonus.dollar_or_percentage_operator === "CASH"){
                               attrs = {dollar_amount: e.target.value};
                             } else {
                               attrs = {upside_percentage: e.target.value};
                             }

                             var updatedBudgetBonus = Object.assign({}, budgetBonus, attrs);
                             var budgetBonuses = [...budget.budget_bonuses].map((bb) => {
                               if(bb.id === budgetBonus.id){
                                 return updatedBudgetBonus;
                               } else {
                                 return bb;
                               }
                             });
                             var updatedBudget = Object.assign({}, budget, {
                               budget_bonuses: budgetBonuses
                             });

                             updateBudget(updatedBudget);
                             debouncedAutoSaveBudgetBonus(csrfToken, team, updatedBudgetBonus);
                           }
                         }
                         maskOptions={{
                           prefix: "",
                           integerLimit: null
                         }} />
        </div>
      </div>
    </div>
    <div className="col-xs-auto position-relative">
      <a href="#"
         onClick={
           (e) => {
             e.preventDefault();

             var budgetBonuses = budget.budget_bonuses.filter((bb) =>
               bb.id !== budgetBonus.id
             );
             var updated = Object.assign({}, budget, {
               budget_bonuses: budgetBonuses
             });

             updateBudget(updated);
             deleteBudgetBonus(csrfToken, team, budgetBonus);
           }
         }
         style={{
           "bottom": "7px",
           "left": "10px",
           "color": "#cacaca"
         }}
         className="position-absolute">
        <i className="fas fa-trash-alt"></i>
      </a>
    </div>
  </div>
);

BudgetBonusRow.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  budget: PropTypes.object.isRequired,
  budgetBonus: PropTypes.object.isRequired,
  deleteBudgetBonus: PropTypes.func.isRequired,
  debouncedAutoSaveBudgetBonus: PropTypes.func,
  updateBudget: PropTypes.func.isRequired
};

export default BudgetBonusRow;
