import React from "react";

const buttonStyle = {
  fontSize: 12,
  fontWeight: 600,
  color: "#1982C4",
  padding: "10px 15px",
  borderRadius: 5,
  border: "1px solid #1982C4",
  cursor: "pointer",
};

const GoToEditorButton = ({
  action,
}) => {
  return (
    <a
      style={buttonStyle}
      href={`/teams/${action.team_id}/campaigns/${action.campaign_id}/campaign_actions/${action.id}`}
    >
      {"Go to editor >"}
    </a>
  );
};

export default GoToEditorButton;
