import { combineReducers } from 'redux';

import {
  DATA_TABLE_EL_CHANGED,
  OPEN_INBOUND_SUBMISSION_CHANGED,
  GETTING_FORM_LINKS_CHANGED,
  CLEAR_FILTER_VALUES,
  FILTER_UPDATED,
  TAG_MANAGER_OPEN_CHANGED,
  TAG_MANAGER_FILTER_CHANGED,
  AVAILABLE_TAGS_CHANGED,
  VENUE_OWNERSHIPS_CHANGED,
} from '../constants/inboundSubmissionsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableTitle = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableOrder = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableColumns = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const inboundSubmissionExists = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const openInboundSubmission = (state = {}, action) => {
  switch (action.type) {
    case OPEN_INBOUND_SUBMISSION_CHANGED:
      return action.openInboundSubmission;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    case VENUE_OWNERSHIPS_CHANGED:
      return action.venueOwnerships;
    default:
      return state;
  }
};

const gettingFormLinks = (state = false, action) => {
  switch (action.type) {
    case GETTING_FORM_LINKS_CHANGED:
      return action.gettingFormLinks;
    default:
      return state;
  }
};

const filters = (state = [], action) => {
  switch (action.type) {
    case CLEAR_FILTER_VALUES:
      return state.map((item, index) => {
        return Object.assign({}, item, {value: []})
      })
    case FILTER_UPDATED:
      return state.map((item, index) => {
        if (item === action.filter) {
          var arrayValue = Array(action.value).flat();
          var newItem = Object.assign({}, item, {value: arrayValue});

          return newItem;
        }

        return item;
      })
    default:
      return state;
  }
};

const canViewArtist = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tagManagerOpen = (state = false, action) => {
  switch (action.type) {
    case TAG_MANAGER_OPEN_CHANGED:
      return action.tagManagerOpen;
    default:
      return state;
  }
};

const tagManagerFilter = (state = "", action) => {
  switch (action.type) {
    case TAG_MANAGER_FILTER_CHANGED:
      return action.tagManagerFilter;
    default:
      return state;
  }
};

const availableTags = (state = [], action) => {
  switch (action.type) {
    case AVAILABLE_TAGS_CHANGED:
      return action.availableTags;
    default:
      return state;
  }
};

const inboundSubmissionsReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  inboundSubmissionExists,
  openInboundSubmission,
  venueOwnerships,
  gettingFormLinks,
  filters,
  canViewArtist,
  tagManagerOpen,
  tagManagerFilter,
  availableTags,
});

export default inboundSubmissionsReducer;
