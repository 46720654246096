/* eslint-disable import/prefer-default-export */

import {
  DATA_TABLE_EL_CHANGED,
  POST_TO_EDIT_CHANGED,
  NEW_POST_CHANGED,
  HAS_POSTS_CHANGED
} from '../constants/scheduledPostsConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import $ from 'jquery';
require('datatables.net-bs4');

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const deletePost = (dispatch, csrfToken, team, post, dataTable) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/posts/" + post.id)
      .then(({ data }) => {
        $(dataTable[0]).DataTable().ajax.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const postToEditChanged = (post) => ({
  type: POST_TO_EDIT_CHANGED,
  post
});

export const updatePost = (dispatch, csrfToken, team, post, postIntentToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.put("/teams/" + team.id + "/posts/" + post.id, {
        post: {
          post_intent_token: postIntentToken
        }
      })
      .then(({ data }) => {
        dispatch(postToEditChanged({}));
        $(post.dataTableEl).DataTable().ajax.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const newPostChanged = (post) => ({
  type: NEW_POST_CHANGED,
  post
});

export const createPost = (dispatch, csrfToken, team, postIntentToken, newPost) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/posts", {
        post: {
          post_intent_token: postIntentToken
        }
      })
      .then(({ data }) => {
        dispatch(newPostChanged({}));

        if(newPost.dataTableEl && Object.keys(newPost.dataTableEl).length > 0){
          $(newPost.dataTableEl).DataTable().ajax.reload();
        } else {
          window.location.replace(`/teams/${team.id}/posts/scheduled`);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const hasPostsChanged = (hasPosts) => ({
  type: HAS_POSTS_CHANGED,
  hasPosts
});
