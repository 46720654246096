/* eslint-disable import/prefer-default-export */

import {
  DATA_TABLE_EL_CHANGED,
  FILTERS_CHANGED,
  PAYMENT_DISPUTE_CHANGED,
  PAYMENT_DISPUTE_UPLOAD_CREATED
} from '../constants/paymentDisputeManagerConstants';

const axios = require('axios').default;

import $ from 'jquery';
require('datatables.net-bs4');

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const filtersChanged = (filters) => ({
  type: FILTERS_CHANGED,
  filters
});

export const paymentDisputeChanged = (paymentDispute) => ({
  type: PAYMENT_DISPUTE_CHANGED,
  paymentDispute
});

export const paymentDisputeUploadCreated = (paymentDisputeUpload) => ({
  type: PAYMENT_DISPUTE_UPLOAD_CREATED,
  paymentDisputeUpload
});
