/* eslint-disable import/prefer-default-export */

import {
  ADDING_ACCOUNT_CHANGED,
  AUTHORIZED_PAGE_DELETED
} from '../constants/socialManagerConstants';

const axios = require('axios').default;

export const addingAccountChanged = (addingAccount) => ({
  type: ADDING_ACCOUNT_CHANGED,
  addingAccount
});

export const deleteAuthorizedPage = (dispatch, team, csrfToken, authorizedPage) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/authorized_pages/" + authorizedPage.id)
      .then(({ data }) => {
        dispatch(authorizedPageDeleted(data));
      })
  };
};

export const authorizedPageDeleted = (authorizedPage) => ({
  type: AUTHORIZED_PAGE_DELETED,
  authorizedPage
});
