import React from "react";

import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";

const labelStyle = {
  fontSize: 14,
  color: "#333333",
  fontWeight: 700,
  marginBottom: 8,
};

const inlineSocialRow = {
  display: "flex",
  flexDirection: "row",
  gap: 8,
  alignItems: "center",
  marginBottom: 8,
};

const iconStyle = {
  fontSize: 18,
  color: "#999999",
  width: 25,
};

const inputStyle = {
  border: "1px solid #B3B3B3",
  borderRadius: 4,
  color: "#3333333",
  fontSize: 14,
  height: 40,
  marginRight: 15,
  padding: 8,
  width: 320,
};

const SocialMediaInputs = () => {
  const {
    formState,
    setFormState,
  } = useMarketingSettingsContext();

  return (
    <div>
      <div style={labelStyle}>Social Media</div>
      <div style={inlineSocialRow}>
        <i style={iconStyle} className="fa-brands fa-facebook"></i>
        <input style={inputStyle} type="text" value={formState.facebook_url || ""} onChange={
          (e) => {
            setFormState((prev) => ({
              ...prev,
              facebook_url: e.target.value,
            }));
          }
        } placeholder="https://www.facebook.com/your-venue" />
      </div>
      <div style={inlineSocialRow}>
        <i style={iconStyle} className="fa-brands fa-twitter"></i>
        <input style={inputStyle} type="text" value={formState.twitter_url || ""} onChange={
          (e) => {
            setFormState((prev) => ({
              ...prev,
              twitter_url: e.target.value,
            }));
          }
        } placeholder="https://www.twitter.com/your-venue" />
      </div>
      <div style={inlineSocialRow}>
        <i style={iconStyle} className="fa-brands fa-instagram"></i>
        <input style={inputStyle} type="text" value={formState.instagram_url || ""} onChange={
          (e) => {
            setFormState((prev) => ({
              ...prev,
              instagram_url: e.target.value,
            }));
          }
        } placeholder="https://www.instagram.com/your-venue" />
      </div>
      <div style={inlineSocialRow}>
        <i style={iconStyle} className="fa-brands fa-linkedin"></i>
        <input style={inputStyle} type="text" value={formState.linkedin_url || ""} onChange={
          (e) => {
            setFormState((prev) => ({
              ...prev,
              linkedin_url: e.target.value,
            }));
          }
        } placeholder="https://www.linkedin.com/your-venue" />
      </div>
    </div>
  );
};

export default SocialMediaInputs;
