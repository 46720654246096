import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import OutreachModalApp from '../../OutreachModal/startup/OutreachModalApp';
import FollowUpModalApp from '../../FollowUpModal/startup/FollowUpModalApp';
import PipelineSlidingPaneApp from '../../PipelineSlidingPane/startup/PipelineSlidingPaneApp';

var ReactDOM = require('react-dom');
const axios = require('axios').default;
import AsyncSelect from 'react-select/async';
import Popup from "reactjs-popup";
import DataTable from '../../DataTable/components/DataTable';
import { ToastContainer } from 'react-toastify';
import DatePicker from "react-datepicker";
import Tippy from '@tippyjs/react';

import $ from 'jquery';
require('datatables.net-bs4');

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const newOutreachModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "20px 15px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "250px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const FollowUpManager = ({
  csrfToken,
  team,
  currentUser,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableEl,
  dataTableElChanged,
  followUp,
  fetchNewFollowUp,
  createNewFollowUp,
  deleteFollowUp,
  completeFollowUp,
  fetchEditFollowUp,
  updateFollowUp,
  exampleArtists,
  followUpsExists,
  pipeline,
  fetchPipeline,
  followUpChanged,
  followUpExistsChanged,
  pipelineChanged,
  newOutreachChanged,
  newOutreach,
  venueOwnerships,
  outreachTemplates,
  fetchNewOutreach
}) => {
  return (
    <div>
      <ToastContainer />
      {followUpsExists ? (
        <div>
          {currentUser.can_manage_follow_ups ? (
            <div className="datatable-right-header-section">
              <a href="#"
                 onClick={(e) => {
                   e.preventDefault();
                   fetchNewFollowUp(team);
                 }}
                 className="btn btn-primary">
                <i className="fal fa-plus"></i>
                &nbsp;
                <strong>New Follow-up</strong>
              </a>
            </div>
          ) : null}
          <DataTable title={dataTableTitle}
                     dataSource={dataTableDataSource}
                     columns={dataTableColumns}
                     colGroup={
                       <colgroup>
                         <col span={1} style={{"width": "30%"}} />
                         <col span={1} style={{"width": "15%"}} />
                         <col span={1} style={{"width": "15%"}} />
                         <col span={1} style={{"width": "15%"}} />
                         <col span={1} style={{"width": "20%"}} />
                         <col span={1} style={{"width": "5%"}} />
                       </colgroup>
                     }
                     onTableElementSet={
                       (el) => {
                         dataTableElChanged(el);
                       }
                     }
                     rowCallback={
                        (row, data) => {
                          var mountReact = row.getElementsByClassName("follow-up-popup-mount-react")[0];
                          var rowOffer   = data;
                          var followUp   = JSON.parse(decodeHtml(rowOffer.json));

                          var mountArtistReact = row.getElementsByClassName("follow-up-artist-mount-react")[0];
                          ReactDOM.render((
                            <span>
                              <img className="img-fluid rounded inline-img"
                                   src={followUp.artist.image_thumb_url_for_json} />
                              <strong className="px-2">
                                <a href="#"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();
                                       fetchPipeline(team, followUp.pipeline.id);
                                     }
                                   }>
                                  {followUp.artist.name}
                                </a>
                              </strong>
                            </span>
                          ), mountArtistReact);

                          var mountNoteReact = row.getElementsByClassName("follow-up-note-mount-react")[0];
                          ReactDOM.render((
                            (followUp.note && followUp.note.length > 0) ? (
                              <Tippy content={
                                       <div style={{
                                              "paddingTop": "16px",
                                              "paddingLeft": "15px",
                                              "paddingRight": "15px"
                                            }}>
                                         <div dangerouslySetInnerHTML={{__html: followUp.simple_format_note}} />
                                       </div>
                                     }
                                     placement={'top-start'}
                                     animation={'shift-away'}
                                     theme='light'>
                                <div>{followUp.note_preview}</div>
                              </Tippy>
                            ) : (
                              <span style={{opacity: '0.5'}}>--</span>
                            )
                          ), mountNoteReact);

                          ReactDOM.render((
                            <div>
                              {currentUser.can_manage_follow_ups ? (
                                <Popup arrow={false}
                                       offsetY={5}
                                       position="bottom right"
                                       contentStyle={popupContentStyle}
                                       onOpen={
                                         (e) => {
                                           e.preventDefault();
                                         }
                                       }
                                       trigger={open => (
                                         <a href="#">
                                           <i className="fas fa-ellipsis-v"></i>
                                         </a>
                                       )} >
                                  {close => (
                                    <div className='row text-left'>
                                      <div className="col-12">
                                        <ul className="list-group offer-inline-menu highlight-primary">
                                          <li className="list-group-item">
                                            <a href="#"
                                               onClick={
                                                 (e) => {
                                                   e.preventDefault();
                                                   fetchNewOutreach(team, followUp);
                                                 }
                                               }
                                               className="text-muted">
                                              <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                <i className="fas fa-plus"></i>
                                              </span>
                                              Create New Outreach
                                            </a>
                                          </li>
                                          {!followUp.completed ? (
                                            <li className="list-group-item">
                                              <a href="#"
                                                 onClick={
                                                   (e) => {
                                                     e.preventDefault();
                                                     fetchEditFollowUp(team, followUp);
                                                   }
                                                 }
                                                 className="text-muted">
                                                <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                  <i className="far fa-calendar-week"></i>
                                                </span>
                                                Edit Follow-up Date
                                              </a>
                                            </li>
                                          ) : null}
                                          {!followUp.completed ? (
                                            <li className="list-group-item">
                                              <a href="#"
                                                 onClick={
                                                   (e) => {
                                                     var rowTableEl = row.parentElement.parentElement;
                                                     e.preventDefault();

                                                     completeFollowUp(team, followUp, csrfToken, rowTableEl);
                                                   }
                                                 }
                                                 className="text-muted">
                                                <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                  <i className="fas fa-check-square"></i>
                                                </span>
                                                Mark Completed and Remove
                                              </a>
                                            </li>
                                          ) : null}
                                          <li className="list-group-item delete-offer-li">
                                            <Popup
                                                trigger={
                                                  <a href="#" className="text-danger">
                                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                      <i className="fas fa-trash"></i>
                                                    </span>
                                                    Remove
                                                  </a>
                                                }
                                                modal
                                                onOpen={
                                                  (e) => {
                                                    e.preventDefault();
                                                  }
                                                }
                                                contentStyle={deleteModalContentStyle}
                                                closeOnDocumentClick
                                              >
                                              {closeDeletePopup => (
                                                <div>
                                                  <div className="row">
                                                    <div className="col">
                                                      <div className="row">
                                                        <div className="col">
                                                          <h5 style={{"marginBottom": "16px"}}>
                                                            <div className="strong">Remove follow-up?</div>
                                                          </h5>
                                                        </div>
                                                        <div className="col-xs-auto">
                                                          <a href="#"
                                                             className="text-dark"
                                                             style={{"paddingRight": "15px"}}
                                                             onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                            <i className="fa fa-times"></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <p>You're about to remove this follow-up.</p>
                                                      <p>This cannot be undone.</p>
                                                      <p>Are you sure?</p>
                                                      <div className="text-right">
                                                        <a href="#"
                                                           onClick={
                                                             (e) => {
                                                               e.preventDefault();
                                                               closeDeletePopup();
                                                             }
                                                           }>
                                                          Cancel
                                                        </a>
                                                        <a href="#"
                                                           className="btn btn-danger ml-3"
                                                           onClick={
                                                             (e) => {
                                                               e.preventDefault();
                                                               var rowTableEl = row.parentElement.parentElement;

                                                               closeDeletePopup();
                                                               close();
                                                               deleteFollowUp(team, followUp, csrfToken, rowTableEl);
                                                             }
                                                           }>
                                                          Remove
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </Popup>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              ) : null}
                            </div>
                          ), mountReact);
                        }
                     }
                     order={dataTableOrder} />
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card no-border shadow-2">
              <div className="card-body">
                <div className='row pt-4'>
                  <div className='col-12 col-md-6 offset-md-3'>
                    <div className="card no-border" style={{"background": "#f4f9fc"}}>
                      <div className="card-body text-center p-5">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 col-sm-6 offset-sm-3 col-md-12 offset-md-0 col-lg-8 offset-lg-2">
                                {exampleArtists.map((artist, index) => (
                                  <div key={index} className="card no-border mb-3" style={{"background": "white"}}>
                                    <div className="card-body p-2">
                                      <div className="row">
                                        <div className="col-xs-auto" style={{"paddingLeft": "30px"}}>
                                          <img src={artist.image_thumb_url_for_json}
                                               style={{"height": "40px", "width": "40px"}}
                                               className="img-fluid rounded-circle" />
                                        </div>
                                        <div className="col">
                                          <div className="mb-1" style={
                                            {
                                              "background": "#cccccc",
                                              "width": "auto",
                                              "maxWidth": '75px',
                                              "height": "15px",
                                              "borderRadius": "8px"
                                            }
                                          }>
                                          </div>
                                          <div style={
                                            {
                                              "background": "#f5f5f5",
                                              "width": "auto",
                                              "maxWidth": "115px",
                                              "height": "15px",
                                              "borderRadius": "8px"
                                            }
                                          }>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <h5 className='py-2'>
                              <strong className="text-dark">
                                Follow-up with an artist or agent
                              </strong>
                            </h5>
                            <p className='pb-2'>
                              Sometimes the timing just isn't right. Let Opendate remind you to follow-up with an artist when the answer is not right now.
                            </p>
                            {currentUser.can_manage_follow_ups ? (
                              <a href="#"
                                 onClick={(e) => {
                                   e.preventDefault();
                                   fetchNewFollowUp(team);
                                 }}
                                 style={{"borderRadius": "8px"}}
                                 className="btn btn-primary btn-sm">
                                <i className="fal fa-plus"></i>
                                &nbsp;
                                <strong className="text-white">New Follow-up</strong>
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Popup
        open={Object.keys(followUp).length > 0}
        contentStyle={modalContentStyle}
        onClose={() => followUpChanged({})}
      >
        <FollowUpModalApp followUp={followUp}
                          team={team}
                          dataTableEl={dataTableEl}
                          csrfToken={csrfToken}
                          onClose={
                            () => {
                              followUpChanged({});
                            }
                          }
                          onCreate={
                            (followUp) => {
                              followUpExistsChanged(true);
                            }
                          }
                          canChangeArtist={true} />
      </Popup>
      <Popup
        open={Object.keys(newOutreach).length > 0}
        contentStyle={newOutreachModalContentStyle}
        className={"popup-modal"}
        closeOnDocumentClick={false}
        onClose={() => newOutreachChanged({})}
      >
        <OutreachModalApp csrfToken={csrfToken}
                          team={team}
                          outreach={newOutreach}
                          artist={newOutreach.artist}
                          venueOwnerships={venueOwnerships}
                          onOutreachCreate={() => {
                            newOutreachChanged({});
                            $(dataTableEl).DataTable().ajax.reload();
                          }}
                          onClose={
                            () => {
                              newOutreachChanged({});
                            }
                          }
                          outreachTemplates={outreachTemplates} />
      </Popup>
      <PipelineSlidingPaneApp pipelineSlidingPane={{
                                pipeline: pipeline,
                                currentUser: currentUser,
                                csrfToken: csrfToken,
                                team: team,
                                dataTableEl: dataTableEl,
                                onClose: () => {
                                  pipelineChanged({});
                                }
                              }} />
    </div>
  )
};

FollowUpManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  followUp: PropTypes.object,
  fetchNewFollowUp: PropTypes.func.isRequired,
  deleteFollowUp: PropTypes.func.isRequired,
  completeFollowUp: PropTypes.func.isRequired,
  fetchEditFollowUp: PropTypes.func.isRequired,
  exampleArtists: PropTypes.array.isRequired,
  followUpsExists: PropTypes.bool,
  pipeline: PropTypes.object,
  fetchPipeline: PropTypes.func.isRequired,
  followUpChanged: PropTypes.func.isRequired,
  followUpExistsChanged: PropTypes.func.isRequired,
  pipelineChanged: PropTypes.func.isRequired,
  newOutreachChanged: PropTypes.func.isRequired,
  newOutreach: PropTypes.object,
  venueOwnerships: PropTypes.array,
  outreachTemplates: PropTypes.array,
  fetchNewOutreach: PropTypes.func.isRequired
};

export default FollowUpManager;
