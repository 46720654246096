import { createStore, applyMiddleware } from 'redux';
import tableViewReducer from '../reducers/tableViewReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(tableViewReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
