import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

const sectionMenuPopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const PageSectionMenu = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  confirmPageSection,
  fetchPermissionSet,
  updateConfirmPageSection
}) => (
  (currentUser && currentUser.role.name === "Administrator" ? (
    <Popup arrow={false}
           offsetY={5}
           position="bottom right"
           contentStyle={sectionMenuPopupContentStyle}
           onOpen={
             (e) => {
               e.preventDefault();
             }
           }
           trigger={open => (
             <a className="btn btn-external btn-sm ml-2"
                style={{"borderRadius": "8px"}}
                onClick={
                  (e) => {
                    e.preventDefault();
                  }
                }
                href="#">
               <i className="far fa-ellipsis-v"></i>
             </a>
           )} >
      {close => (
        <div className='row text-left'>
          <div className="col-12">
            <ul className="list-group offer-inline-menu"
                style={{"fontSize": "14px"}}>
              <li className="list-group-item border-0 py-2 pl-3">
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       close();

                       var permissionSetToFetch;
                       if(confirmPageSection.permission_set_id){
                         permissionSetToFetch = {
                           id: confirmPageSection.permission_set_id
                         };
                       } else {
                         permissionSetToFetch = {
                           element_id: confirmPageSection.id,
                           element_type: "ConfirmPageSection"
                         };
                       }

                       permissionSetToFetch = Object.assign({}, permissionSetToFetch, {
                         _element_type_human_name: "Page Section"
                       });

                       fetchPermissionSet(team, permissionSetToFetch, (permissionSet) => {
                         var updated = Object.assign({}, confirmPageSection, {permission_set_id: permissionSet.id});
                         updateConfirmPageSection(updated);
                       });
                     }
                   }
                   className="text-muted">
                  <span className="d-inline-block text-center" style={{width: "28px"}}>
                    <i className="far fa-user-lock"></i>
                  </span>
                  <span className="text-dark">
                    Set Permissions
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Popup>
  ) : null)
);

PageSectionMenu.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirmPageSection: PropTypes.object,
  fetchPermissionSet: PropTypes.func.isRequired,
  updateConfirmPageSection: PropTypes.func.isRequired
};

export default PageSectionMenu;
