/* eslint-disable import/prefer-default-export */

export const SETTLEMENT_LINE_ITEM_ADDED = 'SETTLEMENT_LINE_ITEM_ADDED';
export const SETTLEMENT_LINE_ITEM_REMOVED = 'SETTLEMENT_LINE_ITEM_REMOVED';
export const SETTLEMENT_LINE_ITEM_CHANGED = 'SETTLEMENT_LINE_ITEM_CHANGED';

export const ITEM_TO_EDIT_CHANGED = 'ITEM_TO_EDIT_CHANGED';
export const EDITED_DROP_COUNT_CHANGED_CHANGED = 'EDITED_DROP_COUNT_CHANGED_CHANGED';
export const EDITED_COMP_COUNT_CHANGED_CHANGED = 'EDITED_COMP_COUNT_CHANGED_CHANGED';
export const EDITED_KILL_COUNT_CHANGED_CHANGED = 'EDITED_KILL_COUNT_CHANGED_CHANGED';
export const BUDGET_CHANGED = 'BUDGET_CHANGED';
export const OFFER_CHANGED = 'OFFER_CHANGED';
export const IS_SAVING_CHANGED = 'IS_SAVING_CHANGED';

export const SETTLEMENT_OVERRIDE_ADDED = 'SETTLEMENT_OVERRIDE_ADDED';
export const SETTLEMENT_OVERRIDE_REMOVED = 'SETTLEMENT_OVERRIDE_REMOVED';
export const SETTLEMENT_OVERRIDE_CHANGED = 'SETTLEMENT_OVERRIDE_CHANGED';
