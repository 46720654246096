import { combineReducers } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';

var _ = require('lodash');

import {
  OPEN_CALENDAR_EVENT_ATTACHMENT_UPDATE_SUCCESS,
  TOGGLE_FOLDERER_ITEM_SELECTED,
  STAGE_FOLDERED_ITEMS_FOR_DESTROY,
  COPIED_FOLDERED_ITEM_CHANGED,
  REMOVE_FOLDERED_ITEMS_FROM_SELECTED,
  COPIED_FOLDERED_ITEM_NAME_CHANGED,
  STAGE_FOLDERED_ITEMS_FOR_MOVE,
  PERMISSION_SET_CHANGED,
  PERMISSION_RULE_ENABLED_CLICKED,
  SELECT_ALL_PERMISSION_RULES_CLICKED,
  ON_PERMISSION_SET_SAVED_CHANGED,
  TARGET_FOLDER_CHANGED,
  FOLDER_COPY_CHANGED,
  FOLDER_COPY_PAGINATED_DATA_FETCHED,
  TOGGLE_FOLDER_COPY,
  FOLDER_COPY_LOADING_PAGINATED_DATA_CHANGED,
  CLOSE_CALENDAR_EVENT_ATTACHMENT_MODAL,
  SET_OPEN_CALENDAR_EVENT_ATTACHMENT,
  EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME,
  SET_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME_EL,
  END_EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME,
  OPEN_CALENDAR_EVENT_ATTACHMENT_PDF_NUMBER_OF_PAGES_CHANGED,
  IS_ADDING_FOLDER_CHANGED,
  NEW_FOLDER_NAME_CHANGED,
  IS_UPLOADING_FILES_CHANGED,
  SELECTED_FILES_TO_UPLOAD_CHANGED,
  IS_LOADING_UPLOADED_FILES_CHANGED,
  GUEST_TEAM_MEMBERSHIPS_CHANGED,
  PROMOTERS_WITH_PERMISSION_CHANGED,
  TARGET_FOLDER_SEARCH_TERM_CHANGED,
  CALENDAR_EVENT_ATTACHMENT_TO_COPY_CHANGED,
  IS_COPYING_CALENDAR_EVENT_ATTACHMENT_CHANGED,
  DRAGGED_FILES_TO_UPLOAD_CHANGED,
  FOLDER_CHANGED
} from '../constants/folderViewConstants';

const traverseFolderAndFolderedItems = (item, transform) => {
  var updates = {};
  var transformed = transform(item);

  if(transformed.foldered_items){
    updates.foldered_items = [...transformed.foldered_items].map((folderedItem) => {
      var updatedItem = traverseFolderAndFolderedItems(folderedItem.item, transform);
      return Object.assign({}, folderedItem, {item: updatedItem});
    });
  }

  return Object.assign({}, transformed, updates);
}

const confirm = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const folder = (state = [], action) => {
  switch (action.type) {
    case FOLDER_CHANGED:
      return action.folder;
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const getChildFolderedItemIds = (folderedItem) => {
  var childFolderedItemIds = [];

  if(folderedItem.item.foldered_items){
    folderedItem.item.foldered_items.forEach((childFolderedItem) => {
      childFolderedItemIds.push(childFolderedItem.id);
      childFolderedItemIds = childFolderedItemIds.concat(getChildFolderedItemIds(childFolderedItem));
    });
  }

  return childFolderedItemIds;
};

const selectedFolderedItems = (state = [], action) => {
  switch (action.type) {
    case REMOVE_FOLDERED_ITEMS_FROM_SELECTED:
      var folderedItemIds = action.folderedItems.map((fi) => fi.id);
      return state.filter((fi) => !folderedItemIds.includes(fi.id));
    case TOGGLE_FOLDERER_ITEM_SELECTED:
      var childFolderedItemIds = getChildFolderedItemIds(action.folderedItem);

      if(state.map((fi) => fi.id).includes(action.folderedItem.id)){
        return state.filter((fi) => fi.id !== action.folderedItem.id);
      } else {
        return [...state, action.folderedItem]
          .filter((fi) => !childFolderedItemIds.includes(fi.id));
      }
    default:
      return state;
  }
};

const folderedItemsToDestroy = (state = [], action) => {
  switch (action.type) {
    case STAGE_FOLDERED_ITEMS_FOR_DESTROY:
      return action.folderedItems;
    default:
      return state;
  }
};

const copiedFolderedItem = (state = {}, action) => {
  switch (action.type) {
    case COPIED_FOLDERED_ITEM_CHANGED:
      return action.folderedItem;
    case COPIED_FOLDERED_ITEM_NAME_CHANGED:
      var item = Object.assign({}, state.item, {human_file_name: action.name});
      return Object.assign({}, state, {item: item});
    default:
      return state;
  }
};

const folderedItemsToMove = (state = [], action) => {
  switch (action.type) {
    case STAGE_FOLDERED_ITEMS_FOR_MOVE:
      return action.folderedItems;
    default:
      return state;
  }
};

const permissionSet = (state = {}, action) => {
  switch (action.type) {
    case PERMISSION_SET_CHANGED:
      return action.permissionSet;
    case SELECT_ALL_PERMISSION_RULES_CLICKED:
      var permissionRules = [...state.permission_rules].map((permissionRule) => {
        return Object.assign({}, permissionRule, {enabled: action.checked});
      });

      return Object.assign({}, state, {permission_rules: permissionRules});
    case PERMISSION_RULE_ENABLED_CLICKED:
      var isEnabled = !action.permissionRule.enabled;

      var permissionRules = [...state.permission_rules].map((permissionRule) => {
        var isMatch = (
          (
            action.permissionRule.rulable_type === permissionRule.rulable_type
              && action.permissionRule.rulable_id === permissionRule.rulable_id
          ) || (
            action.permissionRule.rulable_type === "Role"
              && permissionRule.rulable_type === "TeamMembership"
              && permissionRule.rulable_data.role_id === action.permissionRule.rulable_id
          )
        );

        if(isMatch){
          return  Object.assign({}, permissionRule, {enabled: isEnabled});
        } else {
          return permissionRule;
        }
      });

      return Object.assign({}, state, {permission_rules: permissionRules});
    default:
      return state;
  }
};

const onPermissionSetSaved = (state = () => {}, action) => {
  switch (action.type) {
    case ON_PERMISSION_SET_SAVED_CHANGED:
      return action.onPermissionSetSaved;
    default:
      return state;
  }
};

const targetFolder = (state = {}, action) => {
  switch(action.type) {
    case TARGET_FOLDER_SEARCH_TERM_CHANGED:
      return {};
    case TARGET_FOLDER_CHANGED:
      return action.targetFolder;
    default:
      return state;
  }
};

const folderCopy = (state = {}, action) => {
  switch(action.type) {
    case TARGET_FOLDER_SEARCH_TERM_CHANGED:
      return Object.assign({}, state, {
        foldered_items: [],
        lastRequestData: undefined,
        isLoadingPaginatedData: undefined
      });
    case FOLDER_COPY_CHANGED:
      return action.folderCopy;
    case TOGGLE_FOLDER_COPY:
      return traverseFolderAndFolderedItems(state, (item) => {
        if(item.is_folder && item.id === action.folder.id){
          return Object.assign({}, item, {
            extended: !item.extended
          });
        } else {
          return item;
        }
      });
    case FOLDER_COPY_LOADING_PAGINATED_DATA_CHANGED:
      return traverseFolderAndFolderedItems(state, (item) => {
        if(item.is_folder && item.id === action.folder.id){
          return Object.assign({}, item, {
            isLoadingPaginatedData: action.isLoadingPaginatedData
          });
        } else {
          return item;
        }
      });
    case FOLDER_COPY_PAGINATED_DATA_FETCHED:
      return traverseFolderAndFolderedItems(state, (item) => {
        if(item.id === action.folder.id){
          var folderedItems = _.uniqBy([...item.foldered_items, ...action.data.collection], "id");

          return Object.assign({}, item, {
            foldered_items: folderedItems,
            lastRequestData: action.data
          });
        } else {
          return item;
        }
      });
    default:
      return state;
  }
};

const openCalendarEventAttachment = (state = {}, action) => {
  switch (action.type) {
    case CLOSE_CALENDAR_EVENT_ATTACHMENT_MODAL:
      return {};
    case SET_OPEN_CALENDAR_EVENT_ATTACHMENT:
      return action.calendarEventAttachment;
    case EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME:
      return Object.assign({}, state, {editName: true});
    case SET_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME_EL:
      return Object.assign({}, state, {nameSpanEl: action.el});
    case OPEN_CALENDAR_EVENT_ATTACHMENT_UPDATE_SUCCESS:
      return action.calendarEventAttachment;
    case END_EDIT_OPEN_CALENDAR_EVENT_ATTACHMENT_NAME:
      return Object.assign({}, state, {editName: false, nameSpanEl: undefined});
    default:
      return state;
  }
};

const onFolderChanged = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const guestTeamMemberships = (state = [], action) => {
  switch (action.type) {
    case GUEST_TEAM_MEMBERSHIPS_CHANGED:
      return action.guestTeamMemberships;
    default:
      return state;
  }
};

const promotersWithPermission = (state = [], action) => {
  switch (action.type) {
    case PROMOTERS_WITH_PERMISSION_CHANGED:
      return action.promotersWithPermission;
    default:
      return state;
  }
};

const openCalendarEventAttachmentPdfNumberOfPages = (state = 0, action) => {
  switch (action.type) {
    case OPEN_CALENDAR_EVENT_ATTACHMENT_PDF_NUMBER_OF_PAGES_CHANGED:
      return action.numPages;
    default:
      return state;
  }
};

const defaultHeader = () => (
  <h5 className="mb-0 strong">Assets</h5>
)

const headerComponent = (state = defaultHeader, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isAddingFolder = (state = false, action) => {
  switch (action.type) {
    case IS_ADDING_FOLDER_CHANGED:
      return action.isAddingFolder;
    default:
      return state;
  }
};

const newFolderName = (state = "", action) => {
  switch (action.type) {
    case NEW_FOLDER_NAME_CHANGED:
      return action.newFolderName;
    default:
      return state;
  }
};

const isUploadingFiles = (state = false, action) => {
  switch (action.type) {
    case IS_UPLOADING_FILES_CHANGED:
      return action.isUploadingFiles;
    default:
      return state;
  }
};

const selectedFilesToUpload = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_FILES_TO_UPLOAD_CHANGED:
      return action.selectedFilesToUpload;
    default:
      return state;
  }
};

const isLoadingUploadedFiles = (state = false, action) => {
  switch (action.type) {
    case IS_LOADING_UPLOADED_FILES_CHANGED:
      return action.isLoadingUploadedFiles;
    default:
      return state;
  }
};

const canSelectRootFolderAsTarget = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const searchTerm = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const targetFolderSearchTerm = (state = "", action) => {
  switch (action.type) {
    case TARGET_FOLDER_SEARCH_TERM_CHANGED:
      return action.targetFolderSearchTerm;
    default:
      return state;
  }
};

const calendarEventAttachmentToCopy = (state = {}, action) => {
  switch (action.type) {
    case CALENDAR_EVENT_ATTACHMENT_TO_COPY_CHANGED:
      return action.calendarEventAttachmentToCopy;
    default:
      return state;
  }
};

const isCopyingCalendarEventAttachment = (state = false, action) => {
  switch (action.type) {
    case IS_COPYING_CALENDAR_EVENT_ATTACHMENT_CHANGED:
      return action.isCopyingCalendarEventAttachment;
    default:
      return state;
  }
};

const teamRootFolder = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const draggedFilesToUpload = (state = [], action) => {
  switch (action.type) {
    case DRAGGED_FILES_TO_UPLOAD_CHANGED:
      return action.draggedFilesToUpload;
    default:
      return state;
  }
};

const showConfirmIdColumn = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const folderViewReducer = combineReducers({
  confirm,
  csrfToken,
  team,
  currentUser,
  folder,
  selectedFolderedItems,
  folderedItemsToDestroy,
  copiedFolderedItem,
  folderedItemsToMove,
  permissionSet,
  onPermissionSetSaved,
  targetFolder,
  folderCopy,
  openCalendarEventAttachment,
  onFolderChanged,
  guestTeamMemberships,
  promotersWithPermission,
  openCalendarEventAttachmentPdfNumberOfPages,
  headerComponent,
  isAddingFolder,
  newFolderName,
  isUploadingFiles,
  selectedFilesToUpload,
  isLoadingUploadedFiles,
  canSelectRootFolderAsTarget,
  searchTerm,
  targetFolderSearchTerm,
  calendarEventAttachmentToCopy,
  isCopyingCalendarEventAttachment,
  teamRootFolder,
  draggedFilesToUpload,
  showConfirmIdColumn
});

export default folderViewReducer;
