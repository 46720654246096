import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import DatePicker from "react-datepicker";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "180px",
  "padding": "0px"
}

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

// https://stackoverflow.com/questions/43855166/how-to-tell-if-two-dates-are-in-the-same-day
function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

const canSubmitForm = (marketingBudgetItemToEdit) => {
  var startDateMidday = parseDateTime(marketingBudgetItemToEdit.start_date_midday);
  var endDateMidday = parseDateTime(marketingBudgetItemToEdit.end_date_midday);

  return (
    marketingBudgetItemToEdit
      && marketingBudgetItemToEdit.name
      && marketingBudgetItemToEdit.name.length > 0
      && (
        !startDateMidday
          || !endDateMidday
          || startDateMidday < endDateMidday
          || sameDay(startDateMidday, endDateMidday)
      )
  );
}

const canAddNewItem = (marketingBudgetItems) => {
  return !marketingBudgetItems.some((item) => !item.id);
}

const ManageMarketingBudgetItems = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  marketingBudgetItems,
  title,
  subtitle,
  newCopy,
  addNewMarketingBudgetItem,
  marketingBudgetItemToEditChanged,
  createMarketingBudgetItem,
  destroyMarketingBudgetItem,
  editMarketingBudgetItem,
  updateMarketingBudgetItem,
  marketingBudgetItemToEdit,
  namePlaceholder
}) => (
  <div>
    {marketingBudgetItems.length === 0 ? (
      <div className="row mt-5">
        <div className="col-12 text-center">
          <h5>
            {title}
          </h5>
          <p className="text-muted">
            {subtitle}
          </p>
          {currentUser.can_manage_marketing_budget_items ? (
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();
                   addNewMarketingBudgetItem();
                 }
               }
               style={{"borderRadius": "8px"}}
               className="btn btn-primary btn-sm">
              {newCopy}
            </a>
          ) : null}
        </div>
      </div>
    ) : (
      <div>
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(Object.keys(marketingBudgetItemToEdit).length === 0){
                    return false;
                  }

                  if(marketingBudgetItemToEdit.id){
                    updateMarketingBudgetItem(csrfToken, team, marketingBudgetItemToEdit);
                  } else {
                    createMarketingBudgetItem(csrfToken, team, confirm, marketingBudgetItemToEdit);
                  }
                }
              }>
          <table className="table table-sm table-borderless vertical-middle mt-3"
                 style={{tableLayout: "fixed"}}>
            <colgroup>
              <col span={1} style={{"width": "25%"}} />
              <col span={1} style={{"width": "15%"}} />
              <col span={1} style={{"width": "15%"}} />
              <col span={1} style={{"width": "15%"}} />
              <col span={1} style={{"width": "15%"}} />
              <col span={1} style={{"width": "15%"}} />
            </colgroup>
            <thead>
              <tr className="text-muted">
                <th style={{"paddingLeft": "25px"}}>Activity</th>
                <th>Budget</th>
                <th>Actual</th>
                <th>Start</th>
                <th>End</th>
                <th style={{"paddingRight": "25px"}}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {marketingBudgetItems.sort((a, b) => {return new Date(a.start_date) - new Date(b.start_date)}).map((marketingBudgetItem, index) =>
                marketingBudgetItem.uuid === marketingBudgetItemToEdit.uuid ? (
                  <tr key={index}>
                    <td className="overflow-ellipsis" style={{"paddingLeft": "25px"}}>
                      <input type="text"
                             autoFocus={true}
                             className="form-control form-control-sm w-100 small"
                             style={{"borderRadius": "8px"}}
                             value={marketingBudgetItemToEdit.name || ""}
                             onChange={
                               (e) => {
                                 var updated = Object.assign({}, marketingBudgetItemToEdit, {name: e.target.value});
                                 marketingBudgetItemToEditChanged(updated);
                               }
                             }
                             placeholder={namePlaceholder} />
                    </td>
                    <td>
                      <CurrencyInput className="form-control form-control-sm w-100 small"
                                     style={{"borderRadius": "8px"}}
                                     maskOptions={{
                                       prefix: "",
                                       integerLimit: null
                                     }}
                                     placeholder={"0.00"}
                                     value={marketingBudgetItemToEdit.budget_with_two_decimals || ""}
                                     onChange={
                                       (e) => {
                                         var updated = Object.assign({}, marketingBudgetItemToEdit, {budget_with_two_decimals: e.target.value});
                                         marketingBudgetItemToEditChanged(updated);
                                       }
                                     } />
                    </td>
                    <td>
                      <CurrencyInput className="form-control form-control-sm w-100 small"
                                     style={{"borderRadius": "8px"}}
                                     maskOptions={{
                                       prefix: "",
                                       integerLimit: null
                                     }}
                                     placeholder={"0.00"}
                                     value={marketingBudgetItemToEdit.actual_with_two_decimals || ""}
                                     onChange={
                                       (e) => {
                                         var updated = Object.assign({}, marketingBudgetItemToEdit, {actual_with_two_decimals: e.target.value});
                                         marketingBudgetItemToEditChanged(updated);
                                       }
                                     } />
                    </td>
                    <td>
                      <DatePicker
                        selected={parseDateTime(marketingBudgetItemToEdit.start_date_midday)}
                        onChange={date => {
                          var updated = Object.assign({}, marketingBudgetItemToEdit, {start_date_midday: date});
                          marketingBudgetItemToEditChanged(updated);
                        }}
                        className="form-control form-control-sm rounded-div small w-100"
                        placeholderText={"Select Date"}
                        showPopperArrow={false}
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={parseDateTime(marketingBudgetItemToEdit.end_date_midday)}
                        onChange={date => {
                          var updated = Object.assign({}, marketingBudgetItemToEdit, {end_date_midday: date});
                          marketingBudgetItemToEditChanged(updated);
                        }}
                        className="form-control form-control-sm rounded-div small w-100"
                        placeholderText={"Select Date"}
                        showPopperArrow={false}
                      />
                    </td>
                    <td className="text-right" style={{"paddingRight": "25px"}}>
                      <button type="submit"
                              style={{"borderRadius": "8px"}}
                              disabled={!canSubmitForm(marketingBudgetItemToEdit)}
                              className="btn btn-primary btn-block btn-sm ml-2">
                        Save
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td className="overflow-ellipsis" style={{"paddingLeft": "25px"}}>
                      <span title={marketingBudgetItem.name}>
                        {marketingBudgetItem.name}
                      </span>
                    </td>
                    <td>
                      {marketingBudgetItem.formatted_budget ? (
                        <span>{marketingBudgetItem.formatted_budget}</span>
                      ) : (
                        <span style={{"opacity": "0.5"}}>--</span>
                      )}
                    </td>
                    <td>
                      {marketingBudgetItem.formatted_actual ? (
                        <span>{marketingBudgetItem.formatted_actual}</span>
                      ) : (
                        <span style={{"opacity": "0.5"}}>--</span>
                      )}
                    </td>
                    <td>
                      {marketingBudgetItem.human_start_date ? (
                        <span>{marketingBudgetItem.human_start_date}</span>
                      ) : (
                        <span style={{"opacity": "0.5"}}>--</span>
                      )}
                    </td>
                    <td>
                      {marketingBudgetItem.human_end_date ? (
                        <span>{marketingBudgetItem.human_end_date}</span>
                      ) : (
                        <span style={{"opacity": "0.5"}}>--</span>
                      )}
                    </td>
                    <td className="text-right" style={{"paddingRight": "25px"}}>
                      {currentUser.can_manage_marketing_budget_items ? (
                        <Popup arrow={false}
                               offsetY={5}
                               position="bottom right"
                               contentStyle={popupContentStyle}
                               onOpen={
                                 (e) => {
                                   e.stopPropagation();
                                   e.preventDefault();
                                 }
                               }
                               trigger={open => (
                                 <a className="btn btn-external btn-sm small"
                                    style={{"borderRadius": "8px"}}
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                      }
                                    }
                                    href="#">
                                   <i className="far fa-ellipsis-v"></i>
                                 </a>
                               )} >
                          {close => (
                            <div className='row text-left'>
                              <div className="col-12">
                                <ul className="list-group offer-inline-menu">
                                  <li className="list-group-item">
                                    <a href="#"
                                       onClick={
                                         (e) => {
                                           e.preventDefault();
                                           close();
                                           editMarketingBudgetItem(marketingBudgetItem);
                                         }
                                       }
                                       className="text-muted">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-pencil"></i>
                                      </span>
                                      <span className="text-dark">
                                        Edit
                                      </span>
                                    </a>
                                  </li>
                                  <li className="list-group-item delete-offer-li">
                                    <a href="#"
                                       onClick={
                                         (e) => {
                                           e.preventDefault();
                                           close();
                                           destroyMarketingBudgetItem(csrfToken, team, marketingBudgetItem);
                                         }
                                       }
                                       className="text-danger">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-trash"></i>
                                      </span>
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </Popup>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </form>
        {currentUser.can_manage_marketing_budget_items ? (
          <div className="card-body pt-3">
            <a href="#"
               className={(canAddNewItem(marketingBudgetItems) ? "" : "disabled")}
               onClick={
                 (e) => {
                   e.preventDefault();

                   if(canAddNewItem(marketingBudgetItems)){
                     addNewMarketingBudgetItem();
                   }
                 }
               }>
              <i className="fas fa-plus-circle"></i>
              &nbsp;
              <strong>{newCopy}</strong>
            </a>
          </div>
        ) : null}
      </div>
    )}
  </div>
);

ManageMarketingBudgetItems.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  marketingBudgetItems: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  newCopy: PropTypes.string,
  addNewMarketingBudgetItem: PropTypes.func.isRequired,
  marketingBudgetItemToEditChanged: PropTypes.func.isRequired,
  createMarketingBudgetItem: PropTypes.func.isRequired,
  destroyMarketingBudgetItem: PropTypes.func.isRequired,
  editMarketingBudgetItem: PropTypes.func.isRequired,
  updateMarketingBudgetItem: PropTypes.func.isRequired,
  marketingBudgetItemToEdit: PropTypes.object,
  namePlaceholder: PropTypes.string
};

export default ManageMarketingBudgetItems;
