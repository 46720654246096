import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const OrderTicketTableRow = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  order,
  fetchNewRefund,
  ticket,
  setObjToPrint,
}) => (
  <tr>
    <td className="text-center" style={{"borderBottom": "1px solid #D0DDEA"}}>
      1
    </td>
    <td className="overflow-ellipsis" style={{"borderBottom": "1px solid #D0DDEA", "whiteSpace": "normal"}}>
      <p className="mb-0">
        {ticket.seat_assignment && ticket.seat_assignment.length > 0 ? (
          <span>{ticket.product_name}: {ticket.seat_assignment}</span>
        ) : (
          <span>{ticket.product_name}</span>
        )}
      </p>
      {ticket.checked_in_at ? (
        <p className="mb-0"
            style={{
              "color": "#8AC926",
              "marginTop": "-5px"
            }}>
          Checked in
        </p>
      ) : null}
    </td>
    <td style={{"borderBottom": "1px solid #D0DDEA"}}>
      {formatter.format(parseFloat(ticket.final_cost))}
    </td>
    <td style={{"borderBottom": "1px solid #D0DDEA"}}>
      <Popup arrow={false}
            offsetY={5}
            position="bottom right"
            contentStyle={popupContentStyle}
            onOpen={
              (e) => {
                e.preventDefault();
              }
            }
            trigger={open => (
              <a href="#"
                  style={{"marginRight": "10px"}}
                  className="text-muted">
                <i className="fas fa-ellipsis-v"></i>
              </a>
            )} >
        {close => (
          <div className='row text-left'>
            <div className="col-12">
              <ul className="list-group offer-inline-menu highlight-primary"
                  style={{"fontSize": "14px"}}>
                {team.package.printing && confirm.venue_ownership.can_print ? (
                  <li className="list-group-item pl-2">
                    <a
                      href="#"
                      className="text-dark"
                      onClick={e => {
                        e.preventDefault();
                        setObjToPrint({ type: "ticket", obj: ticket });
                        close();
                      }}>
                      Print Ticket
                    </a>
                  </li>
                ) : null}
                <li className="list-group-item pl-2">
                  <a href="#"
                    onClick={
                      (e) => {
                        e.preventDefault();
                        fetchNewRefund(csrfToken, team, order, ticket);
                      }
                    }
                    className="text-dark">
                    Refund Ticket
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Popup>
    </td>
  </tr>
);

OrderTicketTableRow.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  order: PropTypes.object,
  fetchNewRefund: PropTypes.func.isRequired,
  ticket: PropTypes.object,
  setObjToPrint: PropTypes.func.isRequired,
};

export default OrderTicketTableRow;
