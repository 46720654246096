import React from "react";

const PillContainer = ({
  label,
  background,
  fontSize,
  padding,
}) => {
  const containerStyle = {
    background: background,
    borderRadius: "5px",
    color: "white",
    fontSize: fontSize || 12,
    fontWeight: "600",
    padding: padding || 5,
  };

  return (
    <div style={containerStyle}>
      {label}
    </div>
  );
};

export default PillContainer;
