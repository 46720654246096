import { combineReducers } from 'redux';
import {
  DEFAULT_TOP_HOLD_CHANGED,
  SHOW_ACTIVITY_DEFAULTS_CHANGED,
  EVENT_TEMPLATES_CHANGED,
  EVENT_TEMPLATE_FOR_FORM_CHANGED,
  FINANCES_VISIBLE_CHANGED,
  FINANCE_CATEGORIES_CHANGED,
  NOTES_VISIBLE_CHANGED,
  NOTE_TEMPLATES_CHANGED,
} from '../constants/ShowActivitySettingsConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultUnits = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const defaultMinutes = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const defaultHours = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const defaultTopHold = (state = 1, action) => {
  switch (action.type) {
    case DEFAULT_TOP_HOLD_CHANGED:
      return action.defaultTopHold;
    default:
      return state;
  }
}

const showActivityDefaults = (state = [], action) => {
  switch (action.type) {
    case SHOW_ACTIVITY_DEFAULTS_CHANGED:
      return action.showActivityDefaults;
    default:
      return state;
  }
}

const showActivitySettings = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const teamId = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const eventTemplates = (state = [], action) => {
  switch (action.type) {
    case EVENT_TEMPLATES_CHANGED:
      return action.eventTemplates;
    default:
      return state;
  }
}

const eventTemplateForForm = (state = {}, action) => {
  switch (action.type) {
    case EVENT_TEMPLATE_FOR_FORM_CHANGED:
      return action.eventTemplateForForm;
    default:
      return state;
  }
}

const financesVisible = (state = true, action) => {
  switch (action.type) {
    case FINANCES_VISIBLE_CHANGED:
      return action.financesVisible;
    default:
      return state;
  }
}

const financeCategories = (state = [], action) => {
  switch (action.type) {
    case FINANCE_CATEGORIES_CHANGED:
      return action.financeCategories;
    default:
      return state;
  }
}

const notesVisible = (state = true, action) => {
  switch (action.type) {
    case NOTES_VISIBLE_CHANGED:
      return action.notesVisible;
    default:
      return state;
  }
}

const noteTemplates = (state = [], action) => {
  switch (action.type) {
    case NOTE_TEMPLATES_CHANGED:
      return action.noteTemplates;
    default:
      return state;
  }
}

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const exchangeRates = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

const ShowActivitySettingsReducer = combineReducers({
  csrfToken,
  defaultUnits,
  defaultMinutes,
  defaultHours,
  defaultTopHold,
  showActivityDefaults,
  showActivitySettings,
  teamId,
  eventTemplates,
  eventTemplateForForm,
  financesVisible,
  financeCategories,
  notesVisible,
  noteTemplates,
  team,
  exchangeRates
});

export default ShowActivitySettingsReducer;
