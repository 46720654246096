/* eslint-disable import/prefer-default-export */

export const MIN_TICKETS = 1;
export const MAX_TICKETS = 1000;

export const ADD_ATTENDEES_MENU_ITEM = 'ADD_ATTENDEES_MENU_ITEM';
export const ACTIVE_MENU_ITEM_CHANGED = 'ACTIVE_MENU_ITEM_CHANGED';

export const CANCEL_CHECKOUT = 'CANCEL_CHECKOUT';

export const CONFIRM_CHANGED = 'CONFIRM_CHANGED';
export const ORDER_TYPE_CHANGED = 'ORDER_TYPE_CHANGED';
export const TICKET_RESERVATION_CHANGED = 'TICKET_RESERVATION_CHANGED';
export const TICKET_TYPES_CHANGED = 'TICKET_TYPES_CHANGED';
export const ADD_ONS_CHANGED = 'ADD_ONS_CHANGED';
export const IS_PLACING_ORDER_CHANGED = 'IS_PLACING_ORDER_CHANGED';
export const CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED = 'CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED';
export const TIME_LIMIT_REACHED_CHANGED = 'TIME_LIMIT_REACHED_CHANGED';
export const CUSTOM_FIELD_VALUE_CHANGED = 'CUSTOM_FIELD_VALUE_CHANGED';

export const SEATS_IO_CHART_CHANGED = 'SEATS_IO_CHART_CHANGED';
export const SEATS_IO_SELECTED_OBJECTS_CHANGED = 'SEATS_IO_SELECTED_OBJECTS_CHANGED';
