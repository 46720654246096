import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from "react-toastify";

import TopFans from './TopFans';
import LastMinuteBuyers from './LastMinuteBuyers';
import TopGenresByFans from './TopGenresByFans';
import PotentialArtistFans from './PotentialArtistFans';
import FansByLocation from './FansByLocation';
import AddFanSlidingPane from './AddFanSlidingPane';
import CSVImporter from '../../CSVImporter/CSVImporter';

const headerRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: 15,
  marginTop: 15,
};

const titleStyle = {
  fontSize: 16,
  fontWeight: 600,
  color: "#333333",
};

const buttonCollectionStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 10,
};

const FanDashboard = ({
  csrfToken,
  team,
  currentUser,
  topFans,
  lastMinuteBuyers,
  topGenres,
  geoCoordinates,
  selectedArtist,
  selectedArtistChanged,
  potentialArtistFanData,
  fetchPotentialArtistFanData,
  isLoadingPotentialArtistFanData,
  isLoadingGeoCoordinates,
  fetchGeoCoordinates,
  isLoadingTopGenres,
  fetchTopGenres
}) => {
  const [addAFanIsOpen, setAddAFanIsOpen] = useState(false);

  useEffect(() => {
    fetchPotentialArtistFanData(team, selectedArtist.value);
    fetchGeoCoordinates(team);
    fetchTopGenres(team);
  }, []);

  return (
    <div>
      <ToastContainer />
      <div style={headerRowStyle}>
        <div style={titleStyle}>
          Fans
        </div>
        <div style={buttonCollectionStyle}>
          <a href="#"
            className='btn btn-external-inverse small font-weight-bold'
            onClick={(e) => {
              e.preventDefault();
              setAddAFanIsOpen(true);
            }}
          >
            Add a Fan
          </a>
          <CSVImporter
            csrfToken={csrfToken}
            team={team}
            importerKey={"9fc18a88-a6c7-491d-ab1f-eb85e5a8b68c"}
            type={"CSVImportRows::Fan"}
            renderAnchor={(openModal) => (
              <a href="#"
                className='btn btn-external-inverse small font-weight-bold'
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Import Fans
              </a>
            )}
          />
        </div>
      </div>
      <div className="card no-border shadow-2">
        <div className="card-body" style={{
          "padding": "20px 30px"
        }}>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4"
                style={{"marginBottom": "38px"}}>
              <TopFans csrfToken={csrfToken}
                      team={team}
                      currentUser={currentUser}
                      topFans={topFans} />
            </div>
            <div className="col-12 col-md-6 col-lg-4"
                style={{"marginBottom": "38px"}}>
              <TopGenresByFans csrfToken={csrfToken}
                              team={team}
                              currentUser={currentUser}
                              isLoadingTopGenres={isLoadingTopGenres}
                              topGenres={topGenres} />
            </div>
            <div className="col-12 col-md-6 col-lg-4"
                style={{"marginBottom": "38px"}}>
              <LastMinuteBuyers csrfToken={csrfToken}
                                team={team}
                                currentUser={currentUser}
                                lastMinuteBuyers={lastMinuteBuyers} />
            </div>
          </div>
          <div className="row"
              style={{"marginTop": "10px"}}>
            <div className="col-12 col-md-6"
                style={{"marginBottom": "60px"}}>
              <PotentialArtistFans csrfToken={csrfToken}
                                  team={team}
                                  selectedArtist={selectedArtist}
                                  selectedArtistChanged={selectedArtistChanged}
                                  potentialArtistFanData={potentialArtistFanData}
                                  fetchPotentialArtistFanData={fetchPotentialArtistFanData}
                                  isLoadingPotentialArtistFanData={isLoadingPotentialArtistFanData}
                                  currentUser={currentUser} />
            </div>
            <div className="col-12 col-md-6"
                style={{"marginBottom": "60px"}}>
              <FansByLocation csrfToken={csrfToken}
                              team={team}
                              currentUser={currentUser}
                              isLoadingGeoCoordinates={isLoadingGeoCoordinates}
                              geoCoordinates={geoCoordinates} />
            </div>
          </div>
        </div>
      </div>
      <AddFanSlidingPane
        csrfToken={csrfToken}
        team={team}
        addAFanIsOpen={addAFanIsOpen}
        setAddAFanIsOpen={setAddAFanIsOpen}
      />
    </div>
  );
};

FanDashboard.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  topFans: PropTypes.array,
  lastMinuteBuyers: PropTypes.array,
  topGenres: PropTypes.array,
  geoCoordinates: PropTypes.array,
  selectedArtist: PropTypes.object.isRequired,
  selectedArtistChanged: PropTypes.func.isRequired,
  potentialArtistFanData: PropTypes.object,
  fetchPotentialArtistFanData: PropTypes.func.isRequired,
  isLoadingPotentialArtistFanData: PropTypes.bool,
  isLoadingGeoCoordinates: PropTypes.bool,
  fetchGeoCoordinates: PropTypes.func.isRequired,
  isLoadingTopGenres: PropTypes.bool,
  fetchTopGenres: PropTypes.func.isRequired
};

export default FanDashboard;
