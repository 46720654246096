import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import Tags from "@yaireo/tagify/dist/react.tagify";

const sharePopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "560px",
  "padding": "22px 25px 30px 25px"
}

const canSubmitForm = (emailsToInvite) => {
  return (
    emailsToInvite
      && emailsToInvite.length > 0
  );
}

const ShareSettingsPopup = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  confirmInvitation,
  confirmInvitationChanged,
  confirmInvitationMemoChanged,
  emailsToInvite,
  emailsToInviteChanged,
  calendarEventContacts,
  guestRole,
  createConfirmInvitations,
  confirmInvitationsAndTeamMemberships,
  isViewingGuests,
  isViewingGuestsChanged,
  deleteConfirmInvitation,
  removeConfirmAccess,
  shareSettingsPopupClosed
}) => (
  <div>
    <Popup
      open={Object.keys(confirmInvitation).length > 0}
      contentStyle={sharePopupContentStyle}
      closeOnDocumentClick={false}
      className={""}
      onClose={() => {
          shareSettingsPopupClosed();
          confirmInvitationChanged({});
        }
      }
    >
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col d-flex">
              {isViewingGuests ? (
                <a href="#"
                   className="align-self-center small"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       isViewingGuestsChanged(false);
                     }
                   }>
                  <strong>
                    <i className="fal fa-arrow-left mr-2"></i>
                    Back
                  </strong>
                </a>
              ) : (
                <h3 className="mb-0 align-self-center"
                    style={{"fontSize": "20px"}}>
                  <strong>
                    <i className="fas fa-share text-primary mr-2"></i>
                    Share this page
                  </strong>
                </h3>
              )}
            </div>
            <div className="col-xs-auto"
                 style={{"paddingRight": "15px"}}>
              <a href="#"
                 style={{"fontSize": "28px"}}
                 className="text-dark"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     confirmInvitationChanged({});
                   }
                 }>
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row">
            {isViewingGuests ? (
              <div className="col-12">
                <strong className="mt-1 d-block"
                        style={{"fontSize": "16px"}}>
                  People with access to this event page...
                </strong>
                <table className="table table-sm table-borderless mb-0 mt-3">
                  <colgroup>
                    <col span={1} style={{"width": "80%"}} />
                    <col span={1} style={{"width": "20%"}} />
                  </colgroup>
                  <tbody>
                    {confirmInvitationsAndTeamMemberships.map((entry, index) => (
                      <tr key={index}>
                        <td className="px-0 overflow-ellipsis"
                            title={entry.name_with_email}>
                          {entry.name_with_email}
                        </td>
                        <td className="px-0 text-right">
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();

                                 if(!window.confirm("Are you sure?")){
                                   return false;
                                 }

                                 if(entry.type_for_ui === "Invitations::Confirm"){
                                   deleteConfirmInvitation(csrfToken, team, entry, confirmInvitationsAndTeamMemberships);
                                 } else {
                                   removeConfirmAccess(csrfToken, team, entry, confirm, confirmInvitationsAndTeamMemberships);
                                 }
                               }
                             }
                             className="text-danger small">
                            <strong>
                              Remove
                            </strong>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col-12">
                <p>
                  Share a read-only version of this page with agents and others. Share members will be able to upload assets.
                </p>
                <form onSubmit={
                        (e) => {
                          e.preventDefault();

                          if(!canSubmitForm(emailsToInvite)){
                            return false;
                          }

                          createConfirmInvitations(csrfToken, team, confirm, confirmInvitation, emailsToInvite, guestRole);
                        }
                      }>
                  <div className="form-group">
                    <Tags placeholder={"Add an email or contact"}
                          settings={{
                            pattern: /[^@\s]+@[^@\s]+/,
                            tagTextProp: "name",
                            dropdown: {
                              searchKeys: ["name", "value"]
                            },
                            blacklist: confirmInvitationsAndTeamMemberships.map((d) => d.email.toLowerCase()),
                            whitelist: calendarEventContacts
                                         .filter((cec) => cec.id)
                                         .filter((cec) => cec.contact.email && cec.contact.email.length > 0)
                                         .filter((cec) => !confirmInvitationsAndTeamMemberships.map((d) => d.email.toLowerCase()).includes(cec.contact.email.toLowerCase()))
                                         .map((cec) => {
                                           return Object.assign({}, cec.contact, {
                                             value: cec.contact.email
                                           });
                                         }),
                            templates: {
                              dropdownItem: function(item){
                                return `<div ${this.getAttributes(item)}
                                  class='${this.settings.classNames.dropdownItem} ${item.class ? item.class : ""}'
                                  tabindex="0"
                                  role="option">${item.name} - ${item.value}</div>`
                              }
                            }
                          }}
                          defaultValue={emailsToInvite}
                          onChange={
                            (e) => {
                              emailsToInviteChanged(e.detail.tagify.value);
                            }
                          } />
                  </div>
                  {emailsToInvite.length > 0 ? (
                    <div className="form-group">
                      <textarea className="form-control form-control-sm"
                                placeholder={"Add a note"}
                                value={confirmInvitation.memo || ""}
                                onChange={
                                  (e) => {
                                    confirmInvitationMemoChanged(e.target.value);
                                  }
                                }
                                style={{
                                  "border": "1px solid #e6e6e6",
                                  "borderRadius": "4px",
                                  "fontSize": "14px"
                                }}
                                rows="3">
                      </textarea>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col d-flex">
                      {confirmInvitationsAndTeamMemberships.length === 0 ? (
                        <p className="mb-0 text-muted align-self-end">
                          0 people have access
                        </p>
                      ) : (
                        <a href="#"
                           onClick={
                             (e) => {
                               e.preventDefault();
                               isViewingGuestsChanged(true);
                             }
                           }
                           className={"align-self-end text-primary"}>
                          {confirmInvitationsAndTeamMemberships.length === 1 ? (
                            <span>1 person has access</span>
                          ) : (
                            <span>{confirmInvitationsAndTeamMemberships.length} people have access</span>
                          )}
                        </a>
                      )}
                    </div>
                    <div className="col-xs-auto"
                         style={{"paddingRight": "15px"}}>
                      <button type="submit"
                              style={{"borderRadius": "8px"}}
                              disabled={!canSubmitForm(emailsToInvite)}
                              className="btn btn-primary">
                        <strong>Share</strong>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </Popup>
  </div>
);

ShareSettingsPopup.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirmInvitation: PropTypes.object,
  confirmInvitationChanged: PropTypes.func.isRequired,
  confirmInvitationMemoChanged: PropTypes.func.isRequired,
  emailsToInvite: PropTypes.array,
  emailsToInviteChanged: PropTypes.func.isRequired,
  calendarEventContacts: PropTypes.array,
  guestRole: PropTypes.object.isRequired,
  createConfirmInvitations: PropTypes.func.isRequired,
  confirmInvitationsAndTeamMemberships: PropTypes.array,
  isViewingGuests: PropTypes.bool,
  isViewingGuestsChanged: PropTypes.func.isRequired,
  deleteConfirmInvitation: PropTypes.func.isRequired,
  removeConfirmAccess: PropTypes.func.isRequired,
  shareSettingsPopupClosed: PropTypes.func.isRequired
};

export default ShareSettingsPopup;
