/* eslint-disable import/prefer-default-export */

import {
  DATA_TABLE_EL_CHANGED,
  TEXT_MESSAGE_CHANGED,
  IS_SUBMITTING_CHANGED,
  ESTIMATED_NUMBER_OF_SENDS_CHANGED
} from '../constants/textMessagesConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import $ from 'jquery';
import TextMessages from '../components/TextMessages';
require('datatables.net-bs4');

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const fetchNewTextMessage = (dispatch, csrfToken, team) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.get("/teams/" + team.id + "/text_messages/new")
      .then(({ data }) => {
        dispatch(textMessageChanged(data));
      });
  };
};

export const textMessageChanged = (textMessage) => ({
  type: TEXT_MESSAGE_CHANGED,
  textMessage
});

export const createTextMessage = (dispatch, csrfToken, team, textMessage, dataTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    dispatch(isSubmittingChanged(true));

    return axios.post("/teams/" + team.id + "/text_messages", {
        text_message: {
          venue_ownership_id: textMessage.venue_ownership_id,
          calendar_event_ids: textMessage.calendar_events.map((ce) => ce.value),
          message: textMessage.message
        }
      })
      .then(({ data }) => {
        dispatch(textMessageChanged({}));

        if(dataTableEl && Object.keys(dataTableEl).length > 0){
          $(dataTableEl).DataTable().ajax.reload();
        } else {
          window.location.replace(`/teams/${team.id}/text_messages`);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .then(() => {
        dispatch(isSubmittingChanged(false));
      });
  }
};

export const isSubmittingChanged = (isSubmitting) => ({
  type: IS_SUBMITTING_CHANGED,
  isSubmitting
});

export const fetchEstimatedNumberOfSends = (dispatch, team, textMessage) => {
  return dispatch => {
    var params = {
      "text_message[venue_ownership_id]": textMessage.venue_ownership_id,
      "text_message[calendar_event_ids]": (textMessage.calendar_events || []).map((ce) => ce.value)
    };

    return axios.get("/teams/" + team.id + "/text_messages/estimated_number_of_sends", {
        params: params
      })
      .then(({ data }) => {
        dispatch(estimatedNumberOfSendsChanged(data.count));
      });
  };
};

export const estimatedNumberOfSendsChanged = (estimatedNumberOfSends) => ({
  type: ESTIMATED_NUMBER_OF_SENDS_CHANGED,
  estimatedNumberOfSends
});
