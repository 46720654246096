/* eslint-disable import/prefer-default-export */

import {
  SELECTED_VENUE_OWNERSHIP_CHANGED,
  FOLDER_CHANGED,
  SEARCH_TERM_CHANGED
} from '../constants/eventsAssetCenterConstants';

export const selectedVenueOwnershipChanged = (selectedVenueOwnership) => ({
  type: SELECTED_VENUE_OWNERSHIP_CHANGED,
  selectedVenueOwnership
});

export const folderChanged = (folder) => ({
  type: FOLDER_CHANGED,
  folder
});

export const searchTermChanged = (searchTerm) => ({
  type: SEARCH_TERM_CHANGED,
  searchTerm
});
