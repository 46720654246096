import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const axios = require('axios').default;

import { toast } from 'react-toastify';
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
import Flatpickr from "react-flatpickr";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const debouncedFetchFinanceCategories = _.debounce((csrfToken, teamId, inputValue, callback) => {
  new Promise(resolve => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.get(`/teams/${teamId}/finance_categories/autocomplete`, {
      params: {
        name: inputValue
      }
    })
    .then(function (response) {
      var newOptions = response.data;

      if(!newOptions.map(option => option.label).includes(inputValue)) {
        newOptions.unshift({
          value: inputValue,
          label: `Add "${inputValue}"`,
          name: inputValue
        })
      }

      callback(newOptions);
    })
  });
}, 250);

const createFinanceCategory = (csrfToken, teamId, category, successCallback, errorCallback) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  axios.post(`/teams/${teamId}/finance_categories`, {
    finance_category: {
      name: category
    }
  })
  .then(response => successCallback(response.data))
  .catch(errorCallback)
};

const FinanceItemTemplate = ({
  csrfToken,
  teamId,
  financeItem,
  eventTemplateForForm,
  eventTemplateForFormChanged,
  financeCategories,
  financeCategoriesChanged,
}) => {
  let addItemButton = useRef(null);
  let focusedRow = useRef(null);
  let focusedRowElement = useRef(null);
  let [focusedRowIndex, setFocusedRowIndex] = useState(null);

  useEffect(() => {
    if(focusedRowIndex !== null
      && focusedRow !== null
      && focusedRowElement !== null
      && focusedRow.current !== document.activeElement.closest('.line-item-row')
    ) {
      focusedRowElement.current.focus();
    }
  }, [focusedRowIndex]);

  return (
    <div>
      {financeItem.finance_line_items.filter(lineItem => !lineItem._destroy).length > 0 ? (
        <>
          <div className="d-flex justify-content-between small text-muted text-uppercase">
            <div className="d-flex flex-grow-1" style={{"margin":"0 26px 0 0","borderBottom":"1px solid #e6e6e6"}}>
              <div style={{"width":"18.66%", "paddingLeft":"10px"}} className="flex-grow-1">Type</div>
              <div style={{"width":"24.00%", "paddingLeft":"10px"}} className="flex-grow-1">Category*</div>
              <div style={{"width":"24.00%", "paddingLeft":"10px"}} className="flex-grow-1">Description*</div>
              <div style={{"width":"11.11%", "paddingLeft":"10px"}} className="flex-grow-1">Amount</div>
              <div style={{"width":"11.11%", "paddingLeft":"10px"}} className="flex-grow-1">Forecast</div>
              <div style={{"width":"11.11%", "paddingLeft":"10px"}} className="flex-grow-1">Actual</div>
            </div>
          </div>
          {financeItem.finance_line_items.filter(lineItem => !lineItem._destroy).map((lineItem, i) => (
            <div
              ref={focusedRowIndex === i ? focusedRow : null}
              key={lineItem.id || `new-${i}`}
              className="line-item-row d-flex align-items-center justify-content-between"
              onFocus={() => setFocusedRowIndex(i)}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  e.preventDefault();

                  setFocusedRowIndex(i + 1);
                  if(financeItem.finance_line_items.filter(lineItem => !lineItem._destroy).length === i + 1) {
                    addItemButton.current.click();
                  }
                }
              }}
            >
              <div className="text-center" style={{"width":"0px"}}>
                <i className="far fa-grip-dots-vertical text-muted" style={{"fontSize":"18px"}}></i>
              </div>
              <div
                className="d-flex flex-grow-1"
                style={{"height":"38px", "backgroundColor":"#fff", "border":"1px solid #e6e6e6", "borderTop":"none"}}
              >
                <div className="h-100 flex-grow-1" style={{"width":"18.66%", "borderRight":"1px solid #e6e6e6"}}>
                  <Select
                    ref={focusedRowIndex === i ? focusedRowElement : null}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        boxShadow: "none",
                        minHeight: "37px",
                        maxHeight: "37px",
                        border: `2px solid ${state.isFocused ? "#015fcc" : "white"}`,
                        borderRadius: "3px"
                      })
                    }}
                    value={lineItem.calc_type ? {value: lineItem.calc_type, label: lineItem.calc_type} : null}
                    options={financeItem.is_income ? [
                      { label: "Fixed", value: "Fixed" },
                      { label: "Per Ticket Sold", value: "Per Ticket Sold" },
                    ] : [
                      { label: "Fixed", value: "Fixed" },
                      { label: "Per Ticket Sold", value: "Per Ticket Sold" },
                      { label: "Percentage of Gross", value: "Percentage of Gross" }
                    ]}
                    onChange={e => {
                      var updated = {
                        ...eventTemplateForForm,
                      }

                      const template = financeItem.is_income ? updated.income_template : updated.expense_template;
                      const templateStr = financeItem.is_income ? "income_template" : "expense_template";

                      updated[templateStr] = {
                        ...template,
                        finance_line_items: template.finance_line_items.map(item => {
                          if (item.id === lineItem.id) {
                            return {...item, calc_type: e.value};
                          } else {
                            return item;
                          }
                        })
                      }

                      eventTemplateForFormChanged(updated);
                    }}
                  />
                </div>
                <div className="h-100 flex-grow-1" style={{"width":"24%", "borderRight":"1px solid #e6e6e6"}}>
                  <AsyncSelect
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        boxShadow: "none",
                        minHeight: "37px",
                        maxHeight: "37px",
                        borderRadius: "3px",
                        border: `2px solid ${
                          state.isFocused
                            ? "#015fcc"
                            : eventTemplateForForm.errors?.includes(`${financeItem.is_income ? "income" : "expense"}LineItemCategory${i}`)
                            ? "#FF595E"
                            : "white"
                        }`
                      })
                    }}
                    placeholder="Select or enter category"
                    value={lineItem.category_id ? {value: lineItem.category_id, label: lineItem.category.name} : null}
                    defaultOptions={financeCategories.map((category) => { return { label: category.name, value: category.id }})}
                    loadOptions={(inputValue, callback) => debouncedFetchFinanceCategories(csrfToken, teamId, inputValue, callback)}
                    onChange={e => {
                      var updated = {
                        ...eventTemplateForForm,
                        errors: eventTemplateForForm.errors?.filter(error => error !== `${financeItem.is_income ? "income" : "expense"}LineItemCategory${i}`),
                      }

                      const template = financeItem.is_income ? updated.income_template : updated.expense_template;
                      const templateStr = financeItem.is_income ? "income_template" : "expense_template";

                      if(e === null) {
                        updated[templateStr] = {
                          ...template,
                          finance_line_items: template.finance_line_items.map(item => {
                            if (item.id === lineItem.id) {
                              return {...item, category_id: null};
                            } else {
                              return item;
                            }
                          })
                        }

                        eventTemplateForFormChanged(updated);
                      } else if(financeCategories.map(category => category.name).includes(e.label)) {
                        updated[templateStr] = {
                          ...template,
                          finance_line_items: template.finance_line_items.map(item => {
                            if (item.id === lineItem.id) {
                              return {...item, category_id: e.value, category: { id: e.value, name: e.label }};
                            } else {
                              return item;
                            }
                          })
                        }

                        eventTemplateForFormChanged(updated);
                      } else {
                        createFinanceCategory(csrfToken, teamId, e.name,
                          (category) => { // Success callback
                            financeCategoriesChanged([...financeCategories, category]);

                            updated[templateStr] = {
                              ...template,
                              finance_line_items: template.finance_line_items.map(item => {
                                if (item.id === lineItem.id) {
                                  return {...item, category_id: category.id, category: category};
                                } else {
                                  return item;
                                }
                              })
                            }

                            eventTemplateForFormChanged(updated);

                            toast.success(`Category "${category.name}" was created`, {
                              position: toast.POSITION.TOP_CENTER,
                              draggable: false,
                              closeOnClick: false,
                              autoClose: 5000,
                              hideProgressBar: true
                            });
                          },
                          () => { // Error callback

                            updated[templateStr] = {
                              ...template,
                              finance_line_items: template.finance_line_items.map(item => {
                                if (item.id === lineItem.id) {
                                  return {...item, category_id: null, category: null};
                                } else {
                                  return item;
                                }
                              })
                            }

                            eventTemplateForFormChanged(updated);

                            toast.error(`Could not create category "${e.name}"`, {
                              position: toast.POSITION.TOP_CENTER,
                              draggable: false,
                              closeOnClick: false,
                              autoClose: 5000,
                              hideProgressBar: true
                            });
                          }
                        );
                      }
                    }}
                  />
                </div>
                <div className="h-100 flex-grow-1" style={{"width":"24%", "borderRight":"1px solid #e6e6e6"}}>
                  <input
                    type="text"
                    className="px-2 d-block w-100 h-100"
                    style={{
                      borderRadius: "3px",
                      border: `2px solid ${eventTemplateForForm.errors?.includes(`${financeItem.is_income ? "income" : "expense"}LineItemDescription${i}`) ? "#FF595E" : "white"}`,
                    }}
                    value={lineItem.description || ""}
                    placeholder="Enter description"
                    onChange={e => {
                      var updated = {
                        ...eventTemplateForForm,
                        errors: eventTemplateForForm.errors?.filter(error => error !== `${financeItem.is_income ? "income" : "expense"}LineItemDescription${i}`),
                      }

                      const template = financeItem.is_income ? updated.income_template : updated.expense_template;
                      const templateStr = financeItem.is_income ? "income_template" : "expense_template";

                      updated[templateStr] = {
                        ...template,
                        finance_line_items: template.finance_line_items.map(item => {
                          if (item.id === lineItem.id) {
                            return {...item, description: e.target.value};
                          } else {
                            return item;
                          }
                        })
                      }

                      eventTemplateForFormChanged(updated);
                    }}
                  />
                </div>
                <div
                  className="h-100 flex-grow-1 d-flex align-items-center"
                  style={{
                    "width":"11.11%",
                    "borderRight":"1px solid #e6e6e6",
                    "backgroundColor": lineItem.calc_type !== "Fixed" ? "initial" : "#F0F0F0",
                  }}
                >
                  {lineItem.calc_type === "Percentage of Gross" ? (
                    <i className="mx-2 fas fa-percent text-muted" style={{"fontSize":"18px"}}></i>
                  ) : (
                    <i className="mx-2 fas fa-dollar text-muted" style={{"fontSize":"18px"}}></i>
                  )}
                  <CurrencyInput
                    key={`${lineItem.id}-amount-${lineItem.calc_type}`}
                    className={`pr-2 d-block w-100 h-100 text-right ${lineItem.calc_type !== "Fixed" ? "" : "text-muted"}`}
                    style={{ "border":"none" }}
                    placeholder={"0.00"}
                    maskOptions={{
                      prefix: "",
                      integerLimit: null,
                      allowDecimal: true,
                      decimalLimit: lineItem.calc_type === "Percentage of Gross" ? 5 : 2
                    }}
                    value={lineItem.amount}
                    disabled={lineItem.calc_type === "Fixed"}
                    onChange={e => {
                      var updated = {
                        ...eventTemplateForForm,
                      }

                      const template = financeItem.is_income ? updated.income_template : updated.expense_template;
                      const templateStr = financeItem.is_income ? "income_template" : "expense_template";

                      updated[templateStr] = {
                        ...template,
                        finance_line_items: template.finance_line_items.map(item => {
                          if (item.id === lineItem.id) {
                            var newValue = e.target.value === '' ? "0.00" : e.target.value;
                            return {...item, amount: newValue.replace(',', ''), amount_two_decimals: e.target.value};
                          } else {
                            return item;
                          }
                        })
                      }

                      eventTemplateForFormChanged(updated);
                    }}
                    onFocus={(e) => {
                      (e.target.value === "0.00" || e.target.value === "0") && (e.target.value = "");
                    }}
                  />
                </div>
                <div
                  className="h-100 flex-grow-1 d-flex align-items-center"
                  style={{
                    "width":"11.11%",
                    "borderRight":"1px solid #e6e6e6",
                    "backgroundColor": lineItem.calc_type === "Fixed" ? "initial" : "#F0F0F0",
                  }}
                >
                  <i className="mx-2 fas fa-dollar text-muted" style={{"fontSize":"18px"}}></i>
                  <CurrencyInput
                    className={`pr-2 d-block w-100 h-100 text-right ${lineItem.calc_type === "Fixed" ? "" : "text-muted"}`}
                    style={{"border":"none"}}
                    placeholder="0.00"
                    maskOptions={{prefix: "", integerLimit: null}}
                    value={lineItem.calc_type === "Fixed" ? (
                      lineItem.estimate_two_decimals
                    ) : (
                      "0.00" // We cannot calculate the estimate for non-fixed items at this stage
                    )}
                    disabled={lineItem.calc_type !== "Fixed"}
                    onChange={e => {
                      var updated = {
                        ...eventTemplateForForm,
                      }

                      const template = financeItem.is_income ? updated.income_template : updated.expense_template;
                      const templateStr = financeItem.is_income ? "income_template" : "expense_template";

                      updated[templateStr] = {
                        ...template,
                        finance_line_items: template.finance_line_items.map(item => {
                          if (item.id === lineItem.id) {
                            var newValue = e.target.value === '' ? "0.00" : e.target.value;
                            return {...item, estimate: newValue.replace(',', ''), estimate_two_decimals: e.target.value};
                          } else {
                            return item;
                          }
                        })
                      }

                      eventTemplateForFormChanged(updated);
                    }}
                    onFocus={(e) => {
                      e.target.value === "0.00" && (e.target.value = "");
                    }}
                  />
                </div>
                <div
                  className="h-100 flex-grow-1 d-flex align-items-center"
                  style={{
                    "width":"11.11%",
                    "backgroundColor": "#F0F0F0",
                  }}
                >
                  <i className="mx-2 fas fa-dollar text-muted" style={{"fontSize":"18px"}}></i>
                  <CurrencyInput
                    className="pr-2 d-block w-100 h-100 text-right text-muted"
                    style={{ "border":"none" }}
                    placeholder="0.00"
                    maskOptions={{prefix: "", integerLimit: null}}
                    value={"0.00"}
                    disabled={true}
                    onChange={e => {
                    }}
                  />
                </div>
              </div>
              <div className="text-right"  style={{"width":"26px"}}>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();

                    var updated = {
                      ...eventTemplateForForm,
                    }

                    const template = financeItem.is_income ? updated.income_template : updated.expense_template;
                    const templateStr = financeItem.is_income ? "income_template" : "expense_template";

                    updated[templateStr] = {
                      ...template,
                      finance_line_items: template.finance_line_items.map(item => {
                        if (item.id === lineItem.id) {
                          return {...item, _destroy: true};
                        } else {
                          return item;
                        }
                      })
                    }

                    eventTemplateForFormChanged(updated);
                  }}
                >
                  <i className="far fa-trash text-muted" style={{"fontSize":"18px"}}></i>
                </a>
              </div>
            </div>
          ))}
        </>
      ) : null}
      <div
        className="mt-3 d-flex justify-content-between"
        style={{"margin":"0 26px 0 0px"}}
      >
        <div className="d-flex flex-grow-1">
          <div style={{"width":"40%"}} className="flex-grow-1 d-flex align-items-center text-primary strong">
            <a
              href="#"
              ref={addItemButton}
              onClick={e => {
                e.preventDefault();

                const updated = {
                  ...eventTemplateForForm,
                }

                const template = financeItem.is_income ? updated.income_template : updated.expense_template;
                const templateStr = financeItem.is_income ? "income_template" : "expense_template";

                updated[templateStr] = {
                  ...(template),
                  finance_line_items: [
                    ...template.finance_line_items,
                    {
                      id: `new-${template.finance_line_items.length}`,
                      category_id: "",
                      description: "",
                      calc_type: "Fixed",
                      amount: "0.00",
                      estimate: "0.00",
                      actual: "0.00"
                    }
                  ]
                }

                if(focusedRowIndex === null) {
                  setFocusedRowIndex(0);
                }
                eventTemplateForFormChanged(updated);
              }}
            >
              <i className="fas fa-plus-circle mr-2" style={{"fontSize":"18px"}}></i>
              <span>Add Item</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

FinanceItemTemplate.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  teamId: PropTypes.number.isRequired,
  financeItem: PropTypes.object.isRequired,
  eventTemplateForForm: PropTypes.object.isRequired,
  eventTemplateForFormChanged: PropTypes.func.isRequired,
  financeCategories: PropTypes.array.isRequired,
  financeCategoriesChanged: PropTypes.func.isRequired,
}

export default FinanceItemTemplate;
