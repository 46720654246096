import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "190px",
  "padding": "0px"
}

const actTypePopupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "100px",
  "padding": "0px"
}

const draggablePropsWithStyle = (provided, snapshot) => {
  var props =  {...provided.draggableProps};
  var style = Object.assign({}, props.style, {
    "display": (snapshot.isDragging ? "table" : "")
  });

  return Object.assign({}, props, {style: style});
}

const PerformerCard = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  performer,
  deletePerformer,
  updatePerformer,
  provided,
  snapshot
}) => (
  <div className="row" ref={provided.innerRef} {...draggablePropsWithStyle(provided, snapshot)}>
    <div className="col-12">
      <div className="card border-0 mb-2"
           style={{"background": "#f4f9fc"}}>
        <div className="card-body p-2">
          <div className="row">
            <div className="col-xs-auto d-flex align-items-center"
                 style={{"paddingLeft": "15px"}}>
              <a {...provided.dragHandleProps}
                 href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                   }
                 }
                 style={{"marginLeft": "8px"}}>
                <i className="fas fa-grip-vertical"></i>
              </a>
            </div>
            <div className="col d-flex align-items-center"
                 style={{"paddingLeft": "12px"}}>
              <img src={performer.artistable.image_thumb_url_for_json}
                   className="img-fluid rounded-circle mr-3"
                   style={{"height": "60px", "width": "60px"}} />
              <strong title={performer.artistable.name}>
                <a className="text-dark"
                   target="_blank"
                   style={{"fontSize": "20px"}}
                   href={"/artists/" + performer.artistable.permalink}>
                  {performer.artistable.name}
                </a>
              </strong>
            </div>
            <div className="col-xs-auto d-flex align-items-center"
                 style={{"paddingRight": "30px"}}>
              <Popup arrow={false}
                     offsetY={5}
                     position="bottom right"
                     contentStyle={actTypePopupContentStyle}
                     onOpen={
                        (e) => {
                          e.preventDefault();
                        }
                     }
                     trigger={open => (
                       <a href="#"
                          className="mr-5"
                          onClick={
                            (e) => {
                              e.preventDefault();
                            }
                          }>
                         {performer.humanized_act_type}
                         <i className="fas fa-caret-down ml-1"></i>
                       </a>
                     )} >
                 {close => (
                   <div className='row text-left'>
                     <div className="col-12">
                       <ul className="list-group offer-inline-menu highlight-primary">
                         <li className="list-group-item py-2 px-3">
                           <a href="#"
                              className={performer.act_type === "headline" ? "disabled" : ""}
                              onClick={
                                (e) => {
                                  e.preventDefault();
                                  close();

                                  var updated = Object.assign({}, performer, {act_type: "headline"});
                                  updatePerformer(csrfToken, team, updated);
                                }
                              }>
                             Headliner
                           </a>
                         </li>
                         <li className="list-group-item py-2 px-3">
                           <a href="#"
                              className={performer.act_type === "support" ? "disabled" : ""}
                              onClick={
                                (e) => {
                                  e.preventDefault();
                                  close();

                                  var updated = Object.assign({}, performer, {act_type: "support"});
                                  updatePerformer(csrfToken, team, updated);
                                }
                              }>
                             Support
                           </a>
                         </li>
                       </ul>
                     </div>
                   </div>
                 )}
              </Popup>
              <Popup arrow={false}
                     offsetY={5}
                     position="bottom right"
                     contentStyle={popupContentStyle}
                     onOpen={
                       (e) => {
                         e.preventDefault();
                       }
                     }
                     trigger={open => (
                       <a href="#">
                         <i className="fas fa-ellipsis-v"></i>
                       </a>
                     )} >
                {close => (
                  <div className='row text-left'>
                    <div className="col-12">
                      <ul className="list-group offer-inline-menu highlight-primary">
                        <li className="list-group-item delete-offer-li">
                          <a href="#"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 close();
                                 deletePerformer(csrfToken, team, performer);
                               }
                             }
                             className="text-danger">
                            <span className="d-inline-block text-center" style={{width: "28px"}}>
                              <i className="fas fa-trash"></i>
                            </span>
                            Remove
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

PerformerCard.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  performer: PropTypes.object.isRequired,
  deletePerformer: PropTypes.func.isRequired,
  updatePerformer: PropTypes.func.isRequired,
  provided: PropTypes.object.isRequired,
  snapshot: PropTypes.object.isRequired
};

export default PerformerCard;
