import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as axios from 'axios';
import styled from "styled-components";
import Popup from "reactjs-popup";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import EventTemplatesManager from './EventTemplatesManager';

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const ShowActivitySettingsWrapper = styled.div`
  .btn-add {
    i {
      margin-right: 10px;
    }
  }
  .btn-delete {
    color: #B3B3B3;

    &:hover {
      color: #FF2D00;
    }
  }
  .table {
    margin-top: 20px;

    th {
      font-weight: normal;
      padding-bottom: 10px;

      &.field-name {
        width: 200px;
      }

      &.default {
        width: 80px;
        text-align: center;
      }
    }

    td ul {
      padding-left: 0;
      list-style-position: inside;
    }
  }
`;

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const ShowActivitySettings = ({
  csrfToken,
  defaultUnits,
  defaultMinutes,
  defaultHours,
  defaultTopHold,
  defaultTopHoldChanged,
  teamId,
  showActivityDefaults,
  showActivityDefaultsChanged,
  showActivitySettings,
  eventTemplates,
  eventTemplatesChanged,
  eventTemplateForForm,
  eventTemplateForFormChanged,
  financesVisible,
  financesVisibleChanged,
  financeCategories,
  financeCategoriesChanged,
  notesVisible,
  notesVisibleChanged,
  noteTemplates,
  noteTemplatesChanged,
  createEventTemplate,
  updateEventTemplate,
  deleteEventTemplate,
  team,
  exchangeRates
}) => {
  const [holdPromotionLevel, setHoldPromotionLevel] = useState(team.hold_promotion_level);
  const [settings, setSettings] = useState(showActivitySettings);
  const [currencySettings, setCurrencySettings] = useState({
    convertArtistEarningsOnly: team.convert_artist_earnings_only,
    baseCurrencySymbol: team.base_currency_symbol,
    secondaryCurrencySymbol: team.secondary_currency_symbol,
    secondaryCurrencyRate: team.secondary_currency_rate,
    useLatestExchangeRates: team.use_latest_exchange_rates
  });

  const settingNameChanged = (index, value) => {
    setSettings(settings.map((setting, i) => i === index ? {...setting, name: value} : setting));
  }

  const settingDefaultChanged = (index, value) => {
    setSettings(settings.map((setting, i) => i === index ? {...setting, default: value} : setting));
  }

  const addSetting = () => {
    setSettings(settings.concat({id: null, name: "", default: false}));
  }

  const deleteSetting = (index) => {
    setSettings(settings.map((setting, i) => i === index ? {...setting, _destroy: true} : setting));
  }

  const refreshSettings = (items) => {
    setSettings(settings
      .filter((setting) => (setting.name.trim() !== "" && setting._destroy === undefined))
      .map((setting, i) => {
        let match = items.find(item => (item.name === setting.name));
        return match !== undefined ? {...setting, ...match} : setting;
      })
    );
  }

  const addErrorsToSettings = (errors) => {
    setSettings(settings.map((setting, i) => {
      let match = errors.find(error => (error.id === setting.id && error.name === setting.name));
      return match !== undefined ? {...setting, errors: match.errors} : setting;
    }));
  }

  const handleOnSaveChanges = async () => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var currencyRate = currencySettings.secondaryCurrencyRate ? (
      parseFloatFromString(currencySettings.secondaryCurrencyRate)
    ) : "";

    const response = await axios.patch(
      `/teams/${teamId}`,
      {
        team: {
          hold_promotion_level: holdPromotionLevel,
          default_top_hold: defaultTopHold,
          show_activity_defaults_attributes: showActivityDefaults,
          show_activity_settings_attributes: settings.filter(setting => setting.name.trim() != ""),
          base_currency_symbol: currencySettings.baseCurrencySymbol,
          secondary_currency_symbol: currencySettings.secondaryCurrencySymbol,
          secondary_currency_rate: currencyRate,
          use_latest_exchange_rates: currencySettings.useLatestExchangeRates,
          convert_artist_earnings_only: currencySettings.convertArtistEarningsOnly
        }
      }
    )
    .then(function (response) {
      toast.success("Show Detail Settings saved", {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });

      refreshSettings(response.data);
    })
    .catch(function (error) {
      toast.error("An error occurred", {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });

      addErrorsToSettings(error.response.data);
    });
  }

  const minuteOptions = defaultMinutes.map(minute => {
    return { value: minute, label: minute };
  });

  const hourOptions = defaultHours.map(hour => {
    return { value: hour, label: hour };
  });

  const unitOptions = defaultUnits.map(unit => {
    return {
      value: unit,
      label: unit.charAt(0).toUpperCase() + unit.substr(1).toLowerCase()
    };
  });

  const unitValues = (unit) => {
    if(unit === "minutes") {
      return minuteOptions;
    } else if(unit === "hours") {
      return hourOptions;
    }
  }

  return (
    <ShowActivitySettingsWrapper>
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between">
        <button
          type="submit"
          className="btn btn-primary mb-3 mb-0 order-2 flex-shrink-0"
          onClick={handleOnSaveChanges}
        >
          <strong>Save Changes</strong>
        </button>

        <div>
          <p className="mb-1 text-dark"
          style={{"fontSize": "16px"}}>
            <strong>Event Templates</strong>
          </p>
          <p className="m-0 small text-muted">
            Create event templates to streamline the creation process.
          </p>
        </div>
      </div>
      <div>
        <EventTemplatesManager
          csrfToken={csrfToken}
          teamId={teamId}
          team={team}
          eventTemplates={eventTemplates}
          eventTemplatesChanged={eventTemplatesChanged}
          eventTemplateForForm={eventTemplateForForm}
          eventTemplateForFormChanged={eventTemplateForFormChanged}
          financesVisible={financesVisible}
          financesVisibleChanged={financesVisibleChanged}
          financeCategories={financeCategories}
          financeCategoriesChanged={financeCategoriesChanged}
          notesVisible={notesVisible}
          notesVisibleChanged={notesVisibleChanged}
          noteTemplates={noteTemplates}
          noteTemplatesChanged={noteTemplatesChanged}
          createEventTemplate={createEventTemplate}
          updateEventTemplate={updateEventTemplate}
          deleteEventTemplate={deleteEventTemplate}
        />
      </div>
      <hr className="my-4" style={{"borderWidth": "2px", "borderColor": "#ededed"}} />
      <div>
        <p className="mb-1 text-dark"
        style={{"fontSize": "16px"}}>
          <strong>Default Top Hold</strong>
        </p>
        <p className="m-0 small text-muted">
          Select the default hold level when creating a new hold.
        </p>
      </div>
      <div className="mt-3" style={{"width": "150px"}}>
        <Select
          menuPortalTarget={document.querySelector('body')}
          value={{ value: defaultTopHold, label: `H${defaultTopHold}` }}
          onChange={e => defaultTopHoldChanged(e.value)}
          options={[
            { value: 1, label: 'H1' },
            { value: 2, label: 'H2' },
            { value: 3, label: 'H3' },
            { value: 4, label: 'H4' },
            { value: 5, label: 'H5' },
            { value: 6, label: 'H6' },
            { value: 7, label: 'H7' },
            { value: 8, label: 'H8' },
            { value: 9, label: 'H9' },
            { value: 10, label: 'H10' },
            { value: 11, label: 'H11' },
            { value: 12, label: 'H12' },
            { value: 13, label: 'H13' },
            { value: 14, label: 'H14' },
            { value: 15, label: 'H15' },
            { value: 16, label: 'H16' },
            { value: 17, label: 'H17' },
            { value: 18, label: 'H18' },
            { value: 19, label: 'H19' },
            { value: 20, label: 'H20' }
          ]}
        />
      </div>
      <hr className="my-4" style={{"borderWidth": "2px", "borderColor": "#ededed"}} />
      <div>
        <p className="mb-1 text-dark"
        style={{"fontSize": "16px"}}>
          <strong>Hold Promotion</strong>
        </p>
        <p className="m-0 small text-muted">
          Choose how calendar holds behave when deleted.
        </p>
      </div>
      <div className="mt-3">
        <div className="custom-control custom-radio">
          <input type="radio"
                 id="hold-promote-auto-promote"
                 name="hold-promotion-level"
                 checked={holdPromotionLevel === "HOLD_PROMOTE_AUTO_PROMOTE"}
                 onChange={e => {
                  setHoldPromotionLevel("HOLD_PROMOTE_AUTO_PROMOTE");
                 }}
                 className="custom-control-input" />
          <label className="custom-control-label"
                 style={{"fontSize": "14px"}}
                 htmlFor="hold-promote-auto-promote">
            Auto-Promote
            <p className="m-0 small text-muted">
              When clearing a hold on your calendar, all other lower holds on that date will move up in position.
            </p>
          </label>
        </div>
        <div className="mt-2 custom-control custom-radio">
          <input type="radio"
                 id="hold-promote-always-sequential"
                 name="hold-promotion-level"
                 checked={holdPromotionLevel === "HOLD_PROMOTE_ALWAYS_SEQUENTIAL"}
                 onChange={e => {
                  setHoldPromotionLevel("HOLD_PROMOTE_ALWAYS_SEQUENTIAL");
                 }}
                 className="custom-control-input" />
          <label className="custom-control-label"
                 style={{"fontSize": "14px"}}
                 htmlFor="hold-promote-always-sequential">
            Auto Promote: Always Sequential
            <p className="m-0 small text-muted">
              When a hold is deleted all remaining holds will be promoted and reordered to always be sequential.
            </p>
          </label>
        </div>
        <div className="mt-2 custom-control custom-radio">
          <input type="radio"
                 id="hold-promote-none"
                 name="hold-promotion-level"
                 checked={holdPromotionLevel === "HOLD_PROMOTE_NONE"}
                 onChange={e => {
                   setHoldPromotionLevel("HOLD_PROMOTE_NONE");
                 }}
                 className="custom-control-input" />
          <label className="custom-control-label"
                 style={{"fontSize": "14px"}}
                 htmlFor="hold-promote-none">
            Don't promote
            <p className="m-0 small text-muted">
              When clearing a hold on your calendar, leave all other holds on that date at their original position.
            </p>
          </label>
        </div>
      </div>
      <hr className="my-4" style={{"borderWidth": "2px", "borderColor": "#ededed"}} />
      <div className="d-flex align-items-center">
        <div>
          <p className="mb-1 text-dark"
          style={{"fontSize": "16px"}}>
            <strong>Event Time Preferences</strong>
          </p>
          <p className="m-0 small text-muted">
            Specify how default time settings are calculated during event confirmation.
          </p>
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row align-items-lg-center mt-3">
        <div className="mb-2 mb-lg-0 pr-3 font-weight-bold" style={{"width": "146px"}} >
          <i className="far fa-calendar pr-2 text-muted"
             style={{"fontSize": "14px"}}>
          </i>
          Doors Time
        </div>
        <div className="d-flex flex-lg-columns align-items-center">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-start mr-3 p-2 rounded" style={{"backgroundColor": "#f2f2f2"}}>
            <div className="pr-2">@</div>
            <div className="pr-2" style={{"width": "80px"}}>
              <Select
                options={unitValues(showActivityDefaults[0].interval_unit)}
                menuPortalTarget={document.querySelector('body')}
                value={
                  unitValues(showActivityDefaults[0].interval_unit).find(unitValue => {
                    return unitValue.value === showActivityDefaults[0].interval_value
                  })
                }
                onChange={(option) => {
                  showActivityDefaultsChanged([
                    {...showActivityDefaults[0], interval_value: option.value },
                    {...showActivityDefaults[1]}
                  ]);
                }}
                components={{
                  IndicatorSeparator: () => null
                }} />
            </div>
            <div className="pr-2" style={{"width": "110px"}}>
              <Select
                options={unitOptions}
                menuPortalTarget={document.querySelector('body')}
                value={
                  (unitOptions.find(unit => unit.value === showActivityDefaults[0].interval_unit))
                }
                onChange={(option) => {
                  var newValue = unitValues(option.value).find(value =>
                    value == showActivityDefaults[0].interval_value
                  )

                  var changedShowActivityDefault = {
                    ...showActivityDefaults[0],
                    interval_unit: option.value
                  }

                  if(typeof newValue === 'undefined') {
                    changedShowActivityDefault.interval_value = unitValues(option.value)[0].value;
                  }

                  showActivityDefaultsChanged([
                    changedShowActivityDefault,
                    {...showActivityDefaults[1]}
                  ])
                }}
                components={{
                  IndicatorSeparator: () => null
                }} />
            </div>
            <div style={{"width": "50px"}}>
              Before
            </div>
          </div>
          <div className="font-weight-bold">
            <i className="far fa-calendar pr-2 text-muted"
               style={{"fontSize": "14px"}}>
            </i>
            Event Start Time
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row align-items-lg-center mt-3">
        <div className="mb-2 mb-lg-0 pr-3 font-weight-bold" style={{"width": "146px"}} >
          <i className="far fa-calendar pr-2 text-muted"
             style={{"fontSize": "14px"}}>
          </i>
          Event End Time
        </div>
        <div className="d-flex flex-lg-columns align-items-center">
          <div className="d-flex align-items-center mr-3 p-2 rounded" style={{"backgroundColor": "#f2f2f2"}}>
            <div className="pr-2">@</div>
            <div className="pr-2" style={{"width": "80px"}}>
              <Select
                options={unitValues(showActivityDefaults[1].interval_unit)}
                menuPortalTarget={document.querySelector('body')}
                value={
                  unitValues(showActivityDefaults[1].interval_unit).find(unitValue => {
                    return unitValue.value === showActivityDefaults[1].interval_value
                  })
                }
                onChange={(option) => {
                  showActivityDefaultsChanged([
                    {...showActivityDefaults[0]},
                    {...showActivityDefaults[1], interval_value: option.value }
                  ]);
                }}
                components={{
                  IndicatorSeparator: () => null
                }} />
            </div>
            <div className="pr-2" style={{"width": "110px"}}>
              <Select
                options={unitOptions}
                menuPortalTarget={document.querySelector('body')}
                value={
                  (unitOptions.find(unit => unit.value === showActivityDefaults[1].interval_unit))
                }
                onChange={(option) => {
                  var newValue = unitValues(option.value).find(value =>
                    value == showActivityDefaults[1].interval_value
                  )

                  var changedShowActivityDefault = {
                    ...showActivityDefaults[1],
                    interval_unit: option.value
                  }

                  if(typeof newValue === 'undefined') {
                    changedShowActivityDefault.interval_value = unitValues(option.value)[0].value;
                  }

                  showActivityDefaultsChanged([
                    {...showActivityDefaults[0]},
                    changedShowActivityDefault
                  ])
                }}
                components={{
                  IndicatorSeparator: () => null
                }} />
            </div>
            <div style={{"width": "50px"}}>
              After
            </div>
          </div>
          <div className="font-weight-bold">
            <i className="far fa-calendar pr-2 text-muted"
               style={{"fontSize": "14px"}}>
            </i>
            Event Start Time
          </div>
        </div>
      </div>
      <hr className="my-4" style={{"borderWidth": "2px", "borderColor": "#ededed"}} />
      <div className="mt-3">
        <div>
          <div>
            <p className="mb-1 text-dark"
            style={{"fontSize": "16px"}}>
              <strong>Show Details</strong>
            </p>
            <p className="m-0 small text-muted">
              Customize which fields show up by default in the Show Details section for each event.
            </p>
          </div>
        </div>
        <div>
          <table className="table table-sm table-borderless vertical-middle">
            <thead>
              <tr>
                <th className="field-name">Field Name</th>
                <th className="default">Default</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {settings.map((setting, index) => {
                if( setting._destroy !== undefined ) { return; }

                let errors;
                let textInputBorderColorClass;
                if( setting.errors && setting.errors.length > 0 ) {
                  textInputBorderColorClass = 'border-danger';
                  let errorMessages = setting.errors.map((error, errorIndex) => {
                    return(<li key={`error-${errorIndex}`} className="text-danger font-weight-bold">{error}</li>);
                  });
                  errors = <tr><td colSpan="3"><ul>{errorMessages}</ul></td></tr>
                }

                return (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td style={{"paddingLeft": "0"}}>
                        <input type="text"
                          className={`form-control form-control-sm w-100 ${textInputBorderColorClass}`}
                          style={{"borderRadius": "8px"}}
                          value={setting.name}
                          onChange={
                            (e) => {
                            settingNameChanged(index, e.target.value);
                            }
                          }
                        />
                      </td>
                      <td style={{"paddingLeft": "0"}}>
                        <div className="custom-control custom-checkbox custom-checkbox-table text-center">
                          <input type="checkbox"
                              className="custom-control-input"
                              checked={setting.default}
                              onChange={
                                (e) => {
                                settingDefaultChanged(index, e.target.checked);
                                }
                              }
                              id={`default-check-${index}`} />
                          <label className="custom-control-label"
                              htmlFor={`default-check-${index}`}>
                            &nbsp;
                          </label>
                        </div>
                      </td>
                      <td className="text-left">
                        <Popup
                          trigger={
                            <a href="#"
                              className="ml-2 btn-delete"
                            >
                              <i className="fas fa-trash"></i>
                            </a>
                          }
                          onOpen={
                          (e) => {
                            e.preventDefault();
                          }
                          }
                          modal
                          contentStyle={deleteModalContentStyle}
                          closeOnDocumentClick
                        >
                        {close => (
                          <div>
                          <div className="row">
                            <div className="col">
                            <div className="row">
                              <div className="col">
                              <h5 style={{"marginBottom": "16px"}}>
                                <strong>Delete field?</strong>
                              </h5>
                              </div>
                              <div className="col-xs-auto">
                              <a href="#"
                                className="text-dark"
                                style={{"paddingRight": "15px"}}
                                onClick={(e) => {e.preventDefault(); close();}} >
                                <i className="fa fa-times"></i>
                              </a>
                              </div>
                            </div>
                            <p>You’re about to delete this field.</p>
                            <p>This cannot be undone.</p>
                            <p>Are you sure?</p>
                            <div className="text-right">
                              <a href="#"
                              onClick={
                                (e) => {
                                e.preventDefault();
                                close();
                                }
                              }>
                              Cancel
                              </a>
                              <a
                                href="#"
                                className="btn btn-danger ml-3"
                                onClick={
                                  (e) => {
                                    e.preventDefault();
                                    close();
                                    deleteSetting(index);
                                  }
                                }
                              >
                              Delete
                              </a>
                            </div>
                            </div>
                          </div>
                          </div>
                        )}
                        </Popup>
                      </td>
                    </tr>
                    {errors}
                  </React.Fragment>
                );
              })}
              <tr>
                <td style={{"paddingLeft": "0"}}>
                  <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      addSetting();
                    }
                  }
                  className="btn btn-primary-inverse btn-add">
                    <i className="fas fa-plus"></i>
                    <strong>Add Field</strong>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr className="my-4" style={{"borderWidth": "2px", "borderColor": "#ededed"}} />
      <div>
        <p className="mb-1 text-dark"
        style={{"fontSize": "16px"}}>
          <strong>Base Currency</strong>
        </p>
        <p className="m-0 small text-muted">
          Select the default currency that will be used in Offers and Settlement.
        </p>
      </div>
      <div className="mt-3">
        <div className='form-row'>
          <div className='col-12 col-md-6 col-lg-4 mb-2'>
            <label htmlFor="team-base-currency-symbol"
                  className='text-muted'
                  style={{"fontSize": "14px", "marginBottom": "3px", "color": "#B2B2B2"}}>
              Currency
            </label>
            <select id="team-base-currency-symbol"
                    style={{"border": "1px solid #B2B2B2", "borderRadius": "4px"}}
                    value={currencySettings.baseCurrencySymbol || ""}
                    onChange={
                      (e) => {
                        var newCurrencySymbol = e.target.value;
                        var newCurrencySettings = {
                          ...currencySettings,
                          baseCurrencySymbol: newCurrencySymbol
                        };

                        setCurrencySettings(newCurrencySettings)
                      }
                    }
                    className="form-control">
              <option value="USD">United States Dollar (USD)</option>
              <option value="CAD">Canadian Dollar (CAD)</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <p className="mt-3 mb-1 text-dark"
        style={{"fontSize": "16px"}}>
          <strong>Secondary Currency</strong>
        </p>
        <p className="m-0 small text-muted">
          Select a secondary currency that will be included in Offers and Settlement.
        </p>
      </div>
      <div className="mt-3">
        <div className='form-row'>
          <div className='col-12 col-md-6 col-lg-4 mb-2'>
            <label htmlFor="team-secondary-currency-symbol"
                  className='text-muted'
                  style={{"fontSize": "14px", "marginBottom": "3px", "color": "#B2B2B2"}}>
              Currency
            </label>
            <select id="team-secondary-currency-symbol"
                    style={{"border": "1px solid #B2B2B2", "borderRadius": "4px"}}
                    value={currencySettings.secondaryCurrencySymbol || ""}
                    onChange={
                      (e) => {
                        var newCurrencySymbol = e.target.value;
                        var newCurrencySettings = {
                          ...currencySettings,
                          secondaryCurrencySymbol: newCurrencySymbol
                        };

                        if(newCurrencySymbol === ""){
                          newCurrencySettings = {
                            ...newCurrencySettings,
                            secondaryCurrencyRate: "",
                            useLatestExchangeRates: true
                          }
                        }

                        setCurrencySettings(newCurrencySettings)
                      }
                    }
                    className="form-control">
              <option value="">Select a secondary currency</option>
              <option value="EUR">Euro (EUR)</option>
              <option value="JPY">Japanese Yen (JPY)</option>
              <option value="GBP">Pound Sterling (GBP)</option>
              <option value="AUD">Australian Dollar (AUD)</option>
              <option value="CAD">Canadian Dollar (CAD)</option>
              <option value="CHF">Swiss Franc (CHF)</option>
              <option value="CNY">Chinese Renminbi (CNY)</option>
              <option value="USD">United States Dollar (USD)</option>
            </select>
          </div>
          {currencySettings.secondaryCurrencySymbol && currencySettings.secondaryCurrencySymbol.length > 0 ? (
            <>
              <div className='col-12 col-md-6 col-lg-4 mb-2'>
                <label htmlFor="team-secondary-currency-rate"
                      className='text-muted'
                      style={{"fontSize": "14px", "marginBottom": "3px", "color": "#B2B2B2"}}>
                  {`Conversion Rate (${currencySettings.baseCurrencySymbol} to ${currencySettings.secondaryCurrencySymbol})`}
                </label>
                <MaskedInput className="form-control"
                                type="text"
                                style={{
                                  "border": "1px solid #B2B2B2",
                                  "borderRadius": "4px"
                                }}
                                disabled={currencySettings.useLatestExchangeRates}
                                placeholder={
                                  currencySettings.useLatestExchangeRates ? exchangeRates[currencySettings.baseCurrencySymbol][currencySettings.secondaryCurrencySymbol] : ""
                                }
                                mask={createNumberMask({
                                  prefix: "",
                                  allowDecimal: true,
                                  integerLimit: 10,
                                  decimalLimit: 10
                                })}
                                value={currencySettings.secondaryCurrencyRate || ""}
                                onChange={
                                  (e) => {
                                    setCurrencySettings({
                                      ...currencySettings, secondaryCurrencyRate: e.target.value
                                    })
                                  }
                                }
                                id="team-secondary-currency-rate" />
              </div>
              <div className='col-12 col-md-6 col-lg-4 mb-2 d-flex align-items-center'>
                <div className="custom-control custom-checkbox custom-checkbox-table"
                      style={{"marginTop": "24px"}}>
                  <input type="checkbox"
                          className="custom-control-input"
                          checked={currencySettings.useLatestExchangeRates}
                          onChange={
                            (e) => {
                              setCurrencySettings({
                                ...currencySettings,
                                useLatestExchangeRates: e.target.checked,
                                secondaryCurrencyRate: ""
                              })
                            }
                          }
                          id="team-use-latest-exchange-rates" />
                  <label className="custom-control-label"
                          style={{"fontSize": "14px"}}
                          htmlFor="team-use-latest-exchange-rates">
                    Use latest conversion rate (updated daily)
                  </label>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {currencySettings.secondaryCurrencySymbol && currencySettings.secondaryCurrencySymbol.length > 0 ? (
        <>
          <div>
            <p className="mt-3 mb-1 text-dark"
            style={{"fontSize": "16px"}}>
              <strong>Conversion Rules</strong>
            </p>
            <p className="m-0 small text-muted">
              Choose how currency conversions are applied in Offers and Settlement.
            </p>
          </div>
          <div className="mt-3">
            <div className='form-row'>
              <div className='col-12'>
                <div className="custom-control custom-radio">
                  <input type="radio"
                        id="team-convert-artist-earnings-only-false"
                        name="team-convert-artist-earnings-only"
                        checked={!currencySettings.convertArtistEarningsOnly}
                        onChange={
                          (e) => {
                            setCurrencySettings({
                              ...currencySettings,
                              convertArtistEarningsOnly: false
                            })
                          }
                        }
                        className="custom-control-input" />
                  <label className="custom-control-label"
                        style={{"fontSize": "14px"}}
                        htmlFor="team-convert-artist-earnings-only-false">
                    Everything
                    <p className="m-0 small text-muted">
                      Convert every line item to secondary currency
                    </p>
                  </label>
                </div>
                <div className="mt-2 custom-control custom-radio">
                  <input type="radio"
                        id="team-convert-artist-earnings-only-true"
                        name="team-convert-artist-earnings-only"
                        checked={currencySettings.convertArtistEarningsOnly}
                        onChange={
                          (e) => {
                            setCurrencySettings({
                              ...currencySettings,
                              convertArtistEarningsOnly: true
                            })
                          }
                        }
                        className="custom-control-input" />
                  <label className="custom-control-label"
                        style={{"fontSize": "14px"}}
                        htmlFor="team-convert-artist-earnings-only-true">
                    Artist Earnings Only
                    <p className="m-0 small text-muted">
                      Convert deal terms and artist earnings only
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </ShowActivitySettingsWrapper>
  );
}

ShowActivitySettings.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  defaultUnits: PropTypes.array.isRequired,
  defaultMinutes: PropTypes.array.isRequired,
  defaultHours: PropTypes.array.isRequired,
  defaultTopHold: PropTypes.number.isRequired,
  defaultTopHoldChanged: PropTypes.func.isRequired,
  showActivitySettings: PropTypes.array.isRequired,
  showActivityDefaults: PropTypes.array.isRequired,
  showActivityDefaultsChanged: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  eventTemplates: PropTypes.array,
  eventTemplatesChanged: PropTypes.func.isRequired,
  eventTemplateForForm: PropTypes.object,
  eventTemplateForFormChanged: PropTypes.func.isRequired,
  financesVisible: PropTypes.bool.isRequired,
  financesVisibleChanged: PropTypes.func.isRequired,
  financeCategories: PropTypes.array.isRequired,
  financeCategoriesChanged: PropTypes.func.isRequired,
  notesVisible: PropTypes.bool.isRequired,
  notesVisibleChanged: PropTypes.func.isRequired,
  noteTemplates: PropTypes.array.isRequired,
  noteTemplatesChanged: PropTypes.func.isRequired,
  createEventTemplate: PropTypes.func.isRequired,
  updateEventTemplate: PropTypes.func.isRequired,
  deleteEventTemplate: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  exchangeRates: PropTypes.object.isRequired
}

export default ShowActivitySettings;
