/* eslint-disable import/prefer-default-export */

import {
  DATA_TABLE_EL_CHANGED,
  OPEN_INBOUND_SUBMISSION_CHANGED,
  GETTING_FORM_LINKS_CHANGED,
  FILTER_UPDATED,
  CLEAR_FILTER_VALUES,
  TAG_MANAGER_OPEN_CHANGED,
  TAG_MANAGER_FILTER_CHANGED,
  AVAILABLE_TAGS_CHANGED,
  VENUE_OWNERSHIPS_CHANGED,
} from '../constants/inboundSubmissionsConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import $ from 'jquery';
require('datatables.net-bs4');

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const openInboundSubmissionChanged = (openInboundSubmission) => ({
  type: OPEN_INBOUND_SUBMISSION_CHANGED,
  openInboundSubmission
});

export const gettingFormLinksChanged = (gettingFormLinks) => ({
  type: GETTING_FORM_LINKS_CHANGED,
  gettingFormLinks
});

export const updateFilter = (filter, value) => ({
  type: FILTER_UPDATED,
  filter,
  value
});

export const clearFilterValues = (filter, value) => ({
  type: CLEAR_FILTER_VALUES
});

export const debouncedUpdateFilter = (dispatch, filter, value) => {
  const thunk = dispatch => {
    dispatch(updateFilter(filter, value));
  };

  thunk.meta = {
    debounce: {
      time: 200,
      key: 'update-filter'
    }
  };

  return thunk;
};

export const deleteInboundSubmission = (dispatch, csrfToken, team, inboundSubmission, dataTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/inbound_submissions/" + inboundSubmission.id)
      .then(({ data }) => {
        $(dataTableEl).DataTable().ajax.reload();
      })
      .catch((error) => {
        toast.error("Something went wrong. Try again.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const tagManagerOpenChanged = (tagManagerOpen) => ({
  type: TAG_MANAGER_OPEN_CHANGED,
  tagManagerOpen
});

export const tagManagerFilterChanged = (tagManagerFilter) => ({
  type: TAG_MANAGER_FILTER_CHANGED,
  tagManagerFilter
});

export const availableTagsChanged = (availableTags) => ({
  type: AVAILABLE_TAGS_CHANGED,
  availableTags
});

export const venueOwnershipsChanged = (venueOwnerships) => ({
  type: VENUE_OWNERSHIPS_CHANGED,
  venueOwnerships
});
