import PropTypes from 'prop-types';
import React, { useEffect } from 'react'
import Tippy from '@tippyjs/react';
import PipelineEventsSlidingPaneApp from '../../PipelineEventsSlidingPane/startup/PipelineEventsSlidingPaneApp';

const ActivityFeed = ({
    csrfToken,
    lastPipelineEvent,
    pipelineEvents,
    currentUser,
    pipelineEventsChanged,
    team,
    artist,
    unseenPipelineEvents,
    fetchPipelineEventsForArtist,
    fetchPipelineEventsForArtistOnInit
})=>{

    useEffect(() => {
      if(fetchPipelineEventsForArtistOnInit){
        fetchPipelineEventsForArtist(team, artist);
      }
    }, [])

    if(!lastPipelineEvent) return null

    return(
      <>
        <Tippy
          content={
                    <div className="text-muted p-1">
                      {"Last Outreach: " + lastPipelineEvent.human_created_at}
                    </div>
                   }
          placement={'top-start'}
          animation={'shift-away'}
          theme='light'
        >
          <div className="d-inline-block" style={{"position": "relative"}}>
            {unseenPipelineEvents.length > 0 ? (
              <div className={"btn-alert-count small d-flex justify-content-center " + (unseenPipelineEvents.length < 10 ? "single" : "multi")}>
                <div className="align-self-center">
                  {unseenPipelineEvents.length.toLocaleString()}
                </div>
              </div>
            ) : null}
            <a
              href="#"
              onClick={
                (e) => {
                  e.preventDefault();
                  fetchPipelineEventsForArtist(team, artist);
                }
              }
              className="btn btn-muted-inverse"
            >
              <i className="far fa-stream"></i>
            </a>
          </div>
        </Tippy>

        <PipelineEventsSlidingPaneApp
          currentUser={currentUser}
          csrfToken={csrfToken}
          artist={artist}
          pipelineEvents={pipelineEvents}
          onClose={
            () => {
              pipelineEventsChanged([]);
            }
          }
          team={team}
        />
      </>
    )
}

ActivityFeed.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  lastPipelineEvent: PropTypes.object,
  pipelineEvents: PropTypes.array,
  currentUser: PropTypes.object.isRequired,
  pipelineEventsChanged: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  artist: PropTypes.object.isRequired,
  unseenPipelineEvents: PropTypes.array,
  fetchPipelineEventsForArtist: PropTypes.func.isRequired,
  fetchPipelineEventsForArtistOnInit: PropTypes.bool,
};

export default ActivityFeed
