import { combineReducers } from 'redux';
import {
  SETTLEMENT_LINE_ITEM_ADDED,
  SETTLEMENT_LINE_ITEM_REMOVED,
  SETTLEMENT_LINE_ITEM_CHANGED,
  ITEM_TO_EDIT_CHANGED,
  SETTLEMENT_OVERRIDE_ADDED,
  SETTLEMENT_OVERRIDE_REMOVED,
  SETTLEMENT_OVERRIDE_CHANGED,
  BUDGET_CHANGED,
  EDITED_DROP_COUNT_CHANGED_CHANGED,
  EDITED_COMP_COUNT_CHANGED_CHANGED,
  EDITED_KILL_COUNT_CHANGED_CHANGED,
  IS_SAVING_CHANGED,
  OFFER_CHANGED
} from '../constants/settlementConstants';

const budget = (state = {}, action) => {
  switch (action.type) {
    case BUDGET_CHANGED:
      return action.budget;
    case SETTLEMENT_LINE_ITEM_CHANGED:
      var updated = [...state.settlement_line_items].map((sli) => {
        if(sli.id === action.settlementLineItem.id){
          return action.settlementLineItem;
        } else {
          return sli;
        }
      });

      return Object.assign({}, state, {
        settlement_line_items: updated
      });
    case SETTLEMENT_LINE_ITEM_REMOVED:
      var updated = state.settlement_line_items.filter((sli) =>
        sli.id !== action.settlementLineItem.id
      );

      return Object.assign({}, state, {
        settlement_line_items: updated
      });
    case SETTLEMENT_LINE_ITEM_ADDED:
      var updated = [...state.settlement_line_items, action.settlementLineItem];

      return Object.assign({}, state, {
        settlement_line_items: updated
      });
    case SETTLEMENT_OVERRIDE_ADDED:
      var updated = [...state.settlement_overrides, action.settlementOverride];

      return Object.assign({}, state, {
        settlement_overrides: updated
      });
    case SETTLEMENT_OVERRIDE_REMOVED:
      var updated = state.settlement_overrides.filter((so) =>
        so.id !== action.settlementOverride.id
      );

      return Object.assign({}, state, {
        settlement_overrides: updated
      });
    case SETTLEMENT_OVERRIDE_CHANGED:
      var updated = [...state.settlement_overrides].map((so) => {
        if(so.id === action.settlementOverride.id){
          return action.settlementOverride;
        } else {
          return so;
        }
      });

      return Object.assign({}, state, {
        settlement_overrides: updated
      });
    default:
      return state;
  }
};

const offer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_CHANGED:
      return action.offer;
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerId = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const itemToEdit = (state = {}, action) => {
  switch (action.type) {
    case ITEM_TO_EDIT_CHANGED:
      return action.itemToEdit;
    default:
      return state;
  }
};

const requestFormat = (state = "html", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isSettling = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const editedDropCount = (state = null, action) => {
  switch (action.type) {
    case EDITED_DROP_COUNT_CHANGED_CHANGED:
      return action.editedDropCount;
    default:
      return state;
  }
};

const editedCompCount = (state = null, action) => {
  switch (action.type) {
    case EDITED_COMP_COUNT_CHANGED_CHANGED:
      return action.editedCompCount;
    default:
      return state;
  }
};

const editedKillCount = (state = null, action) => {
  switch (action.type) {
    case EDITED_KILL_COUNT_CHANGED_CHANGED:
      return action.editedKillCount;
    default:
      return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
    case IS_SAVING_CHANGED:
      return action.isSaving;
    default:
      return state;
  }
};

const showPageFrame = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const settlementReducer = combineReducers({
  budget,
  offer,
  confirm,
  csrfToken,
  team,
  currentUser,
  offerId,
  itemToEdit,
  requestFormat,
  isSettling,
  editedDropCount,
  editedCompCount,
  editedKillCount,
  isSaving,
  showPageFrame
});

export default settlementReducer;
