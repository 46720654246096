import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { combineReducers } from 'redux';

import outreachManagerReducer from '../reducers/outreachManagerReducer';
import eventFormReducer from '../../EventForm/reducers/eventFormReducer';

const rootReducer = combineReducers({
  outreachManager: outreachManagerReducer,
  eventForm: eventFormReducer
});

const configureStore = (railsProps) => (
  createStore(rootReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
