// Simple example of a React "smart" component

import { connect } from 'react-redux';
import UserSettings from '../components/UserSettings';
import * as actions from '../actions/userSettingsActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  user: state.user,
  fileEl: state.fileEl,
  originalUser: state.originalUser,
  team: state.team,
  myMobileApps: state.myMobileApps,
  isVenueManager: state.isVenueManager,
  availableTimezones: state.availableTimezones,
  updateUserTimezone: state.updateUserTimezone,
});

const mapDispatchToProps = (dispatch) => {
  return {
    userChanged: (user) => dispatch(actions.userChanged(user)),
    updateUser: (csrfToken, user) => dispatch(actions.updateUser(dispatch, csrfToken, user)),
    fileElChanged: (fileEl) => dispatch(actions.fileElChanged(fileEl)),
    updateUserHomepage: (csrfToken, user) => dispatch(actions.updateUserHomepage(dispatch, csrfToken, user)),
    updateUserTimezone: (csrfToken, user) => dispatch(actions.updateUserTimezone(dispatch, csrfToken, user)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
