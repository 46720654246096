import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

var currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const FeeRuleRow = ({
  feeRule,
  handleChange,
  handleReset,
  isDisabled,
  isFirst,
  isLast
}) => {
  const effectiveServiceFee = feeRule.calendar_event_fee_rule ? (
    parseFloat(feeRule.calendar_event_fee_rule.organizer_service_fee)
  ) : (
    parseFloat(feeRule.organizer_service_fee)
  );

  const priceRangeStyle = {
    "border": "1px solid #888888",
    "fontWeight": "bold",
    "opacity": "0.5",
    "width": "150px",
    "padding": "4px",
  };

  const textInputStyle = {
    "border": "1px solid #888888",
    "color": "black",
    "fontWeight": "bold",
    "padding": "4px",
  };

  const [serviceFee, setServiceFee] = useState(currencyFormatter.format(effectiveServiceFee));

  useEffect(() => {
    setServiceFee(currencyFormatter.format(effectiveServiceFee));
  }, [feeRule]);

  return (
    <tr>
      <td style={priceRangeStyle}>
        {isFirst ? (
          `${currencyFormatter.format(feeRule.maximum)}`
        ): isLast ? (
          `${currencyFormatter.format(feeRule.minimum)} +`
        ) : (
          `${currencyFormatter.format(feeRule.minimum)} - ${currencyFormatter.format(feeRule.maximum)}`
        )}
      </td>
      <td style={textInputStyle}>
        <CurrencyInput
          className={"text-right"}
          disabled={isDisabled}
          onChange={
            (e) => {
              const floatValue = parseFloat(e.target.value.replace("$", ""));
              setServiceFee(e.target.value);
              handleChange(floatValue);
            }
          }
          style={{
            "border": "0px",
            "fontWeight": "bold",
            "opacity": isDisabled ? "0.5" : "1.0",
            "width": "75px",
          }}
          value={serviceFee}
        />
      </td>
      {!!feeRule.calendar_event_fee_rule ? (
        <td style={{"padding": "5px"}}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleReset(feeRule.calendar_event_fee_rule.id);
            }}
          >
            reset
          </a>
        </td>
      ) : (
        null
      )}
    </tr>
  );
}

FeeRuleRow.propTypes = {
  feeRule: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default FeeRuleRow;
