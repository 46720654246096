import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Tippy from '@tippyjs/react';

import { offsetDateForBrowser } from '../../../shared/timeZoneLogic';

const NewTag = ({ csrfToken, team, scope, tag, taggable, createTag, onChange }) => {
  var style = {
    "color": "#1982C4",
    "backgroundColor": "#F2F2F2",
    "borderColor": "#1982C4"
  }

  let taggableName;
  switch(scope) {
    case "Artist":
      taggableName = taggable.name;
      break;
    case "Offer":
      taggableName = taggable.event_name;
      break;
    default:
      taggableName = taggable.name;
  }

  return(
    <Tippy content={
      <center>
        {`Create "${tag}" and`}
        <br />
        assign to {taggableName}
      </center>
    }>
      <div
        style={style}
        className='small rounded-pill btn mb-2 mr-2 py-0'
        onClick={(e) => { createTag(csrfToken, team, scope, taggable, tag, onChange) }}
      >
        <i className="fa fa-plus mr-2"></i>{tag}
      </div>
    </Tippy>
  );
}

const ActiveTag = ({ csrfToken, team, scope, tag, unassignTag=null, onChange=null }) => {
  var style = {
    "color": "#FFF",
    "backgroundColor": "#1982C4",
    "borderColor": "#1982C4"
  }

  if(unassignTag) {
    style.cursor = "pointer";
  } else {
    style.cursor = "default";
  }

  return(
    <Tippy
      content={
        <center>
          Added by {tag.created_by.name}
        </center>
      }
    >
      <div
        style={style}
        className='small rounded-pill btn mb-2 mr-2 py-0'
        onClick={unassignTag ? (e) => { unassignTag(csrfToken, team, scope, tag, onChange) } : null}
      >
        {tag.tag.name}
      </div>
    </Tippy>
  )
}

const InactiveTag = ({ csrfToken, team, scope, tag, taggable, assignTag, onChange }) => {
  var style = {
    "color": "#1982C4",
    "backgroundColor": "#F2F2F2",
    "borderColor": "#1982C4"
  }

  let taggableName;
  switch(scope) {
    case "Artist":
      taggableName = taggable.name;
      break;
    case "Offer":
      taggableName = taggable.event_name;
      break;
    default:
      taggableName = taggable.name;
  }

  return(
    <Tippy content={
      <center>
        {`Assign "${tag.name}"`}
        <br />
        to {taggableName}
      </center>
    }>
      <div
        style={style}
        className='small rounded-pill btn mb-2 mr-2 py-0'
        onClick={(e) => { assignTag(csrfToken, team, scope, taggable, tag, onChange) }}
      >
        {tag.name}
      </div>
    </Tippy>
  )
}

const Tags = ({
  csrfToken,
  team,
  scope,
  taggable,
  activeTags,
  availableTags,
  tagFilter,
  onChange,
  assignTag,
  unassignTag,
  createTag,
}) => {
  const filteredTags = availableTags.filter(tag => {
    return tag.name.toLowerCase().includes(tagFilter.toLowerCase());
  });

  return(
    <React.Fragment>
      {filteredTags.map(tag => {
        const activeTag = activeTags.find(active => active.tag.id === tag.id);

        return(
          activeTag ? (
            <ActiveTag
              key={activeTag.tag.id}
              csrfToken={csrfToken}
              team={team}
              scope={scope}
              tag={activeTag}
              unassignTag={unassignTag}
              onChange={onChange}
            />
          ) : (
            <InactiveTag
              key={tag.id}
              csrfToken={csrfToken}
              team={team}
              scope={scope}
              tag={tag}
              taggable={taggable}
              assignTag={assignTag}
              onChange={onChange}
            />
          )
        )
      })}
      {tagFilter.length > 0 && !availableTags.find(tag => tag.name === tagFilter) ? (
        <NewTag
          csrfToken={csrfToken}
          team={team}
          scope={scope}
          taggable={taggable}
          tag={tagFilter}
          createTag={createTag}
          onChange={onChange}
        />
      ) : null}
    </React.Fragment>
  )
}

const inactiveTagButtonStyle = {
  color: "#1982C4",
  border: "1px solid #DFF0FB",
  backgroundColor: "#DFF0FB",
  borderRadius: "4px",
  paddingTop: "0.15rem",
  paddingBottom: "0.15rem",
  boxShadow: "none",
}

const activeTagButtonStyle = {
  color: "#1982C4",
  border: "1px solid #1982C4",
  backgroundColor: "#DFF0FB",
  borderRadius: "4px",
  paddingTop: "0.15rem",
  paddingBottom: "0.15rem",
  boxShadow: "none",
}

const disabledTagButtonStyle = {
  ...inactiveTagButtonStyle,
  cursor: "default",
}

const TagManager = ({
  csrfToken,
  team,
  currentUser,
  scope,
  title,
  taggable,
  getActiveTags,
  getAvailableTags,
  assignTag,
  unassignTag,
  createTag,
  onChange,
  popupClasses,
  popupStyles,
  tagsButtonPosition,
  activeTagsAlignment
}) => {
  const [open, openChanged] = useState(false);
  const [tagFilter, tagFilterChanged] = useState("");
  const [activeTags, activeTagsChanged] = useState([]);
  const [availableTags, availableTagsChanged] = useState([]);

  const refreshTagData = (opts={}) => {
    getActiveTags(csrfToken, team, scope, taggable, (data) => {
      activeTagsChanged(data);

      if(onChange) {
        onChange(data);
      }
    });

    if(opts.refreshAvailable) {
      getAvailableTags(csrfToken, team, scope, (data) => {
        availableTagsChanged(data);
      });

      tagFilterChanged('');
    }
  }

  const handleOutsideClick = e => {
    if(!e.target.closest("#tag-manager-popup") && !e.target.closest("#tag-manager-button")) {
      openChanged(false);
    }
  }

  useEffect(() => {
    if(open) {
      document.body.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.body.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.body.removeEventListener("mousedown", handleOutsideClick);
    }
  }, [open]);

  useEffect(() => {
    refreshTagData({ refreshAvailable: true });
  }, []);

  return(
    <React.Fragment>
      <div className="d-flex flex-column position-relative">
        <div className={`flex-grow-1 d-flex align-items-start ${tagsButtonPosition === "right" ? "flex-row-reverse" : "" }`}>
          <a href="#"
            id="tag-manager-button"
            disabled={!currentUser.can_manage_tagged_items}
            className={`flex-shrink-0 mb-2 btn small btn-sm ${tagsButtonPosition === "left" ? "mr-2" : ""}`}
            style={
              currentUser.can_manage_tagged_items
                ? (open ? activeTagButtonStyle : inactiveTagButtonStyle)
                : disabledTagButtonStyle
            }
            onClick={e => {
              e.preventDefault();

              if(currentUser.can_manage_tagged_items) {
                openChanged(!open);
              }
            }}
          >
            <i className="normal far fa-tag mr-1"></i>
            <strong>Tags</strong>
          </a>
          <div
            className={`flex-grow-1 text-${activeTagsAlignment}`}
            style={{"paddingTop": "0.15rem"}}
          >
            {activeTags ? (
             activeTags.map((tag) => (
                <ActiveTag key={tag.id} csrfToken={csrfToken} team={team} scope={scope} tag={tag} />
              ))
            ) : null}
          </div>
        </div>
        {open ? (
          <div
            id="tag-manager-popup"
            className={popupClasses ? popupClasses : "w-100 position-absolute shadow"}
            style={{
              top: "32px",
              border: "1px solid #eee",
              zIndex: 1,
              ...popupStyles,
            }}
          >
            <div className='d-flex flex-column'>
              <div className='py-2' style={{"backgroundColor": "#F2F2F2"}}>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{"color": "#333333", "padding": "0 20px"}}
                >
                  <strong>
                    <a
                      href="#"
                      className="text-dark"
                      onClick={(e) => { e.preventDefault(); openChanged(false) }}
                    >
                      <i className="fa fa-chevron-up"></i>
                    </a>
                  </strong>
                  <strong>{title}</strong>
                  <strong>&nbsp;</strong>
                </div>
              </div>
              <div className='' style={{"backgroundColor": "#F9F9F9"}}>
                <form
                  className="w-100 d-flex align-items-center justify-content-between"
                  onSubmit={e => {
                    e.preventDefault();
                    createTag(csrfToken, team, scope, taggable, tagFilter, refreshTagData);
                  }}
                >
                  <input
                    type="text"
                    name="tag-filter"
                    className="p-4 form-control form-control-sm small"
                    placeholder="Enter tag name to create or filter"
                    style={{
                      "fontSize": "14px",
                      "border": "none",
                      "backgroundColor": "#F9F9F9",
                      "boxShadow": "none"
                    }}
                    value={tagFilter}
                    onChange={e => { tagFilterChanged(e.target.value) }}
                  />
                  {tagFilter.length > 0 ? (
                    <a
                      href="#"
                      className="mr-4 text-muted"
                      onClick={e => { e.preventDefault(); tagFilterChanged('') }}
                    >
                      <i className="far fa-circle-xmark"></i>
                    </a>
                  ) : null}
                </form>
              </div>
              <div
                className="d-flex flex-wrap px-4 py-2"
                style={{"backgroundColor": "#F9F9F9"}}
              >
                <Tags
                  csrfToken={csrfToken}
                  team={team}
                  scope={scope}
                  taggable={taggable}
                  activeTags={activeTags}
                  availableTags={availableTags}
                  tagFilter={tagFilter}
                  onChange={refreshTagData}
                  assignTag={assignTag}
                  unassignTag={unassignTag}
                  createTag={createTag}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

TagManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  taggable: PropTypes.object.isRequired,
  assignTag: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  popupClasses: PropTypes.string,
  popupStyles: PropTypes.object,
  tagsButtonPosition: PropTypes.string,
  activeTagsAlignment: PropTypes.string,
};

export default TagManager;
