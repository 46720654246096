// Simple example of a React "smart" component

import { connect } from 'react-redux';
import OfferModal from '../components/OfferModal';
import * as actions from '../../OfferModal/actions/offerModalActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  team: state.team,
  offer: state.offer,
  defaultOffer: state.defaultOffer,
  currentUser: state.currentUser,
  selectedOfferTemplate: state.selectedOfferTemplate,
  offerTemplateName: state.offerTemplateName,
  offerTemplates: state.offerTemplates,
  closeModal: state.closeModal,
  venuesOptions: state.venuesOptions,
  dataTableEl: state.dataTableEl,
  hiddenFileUploadInputEl: state.hiddenFileUploadInputEl
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateOfferVenue: (venue) => dispatch(actions.updateOfferVenue(venue)),
    updateOfferPrimaryArtist: (artist) => dispatch(actions.updateOfferPrimaryArtist(artist)),
    updateOfferStartDate: (date) => dispatch(actions.updateOfferStartDate(date)),
    addOfferDealTermLineItem: () => dispatch(actions.addOfferDealTermLineItem()),
    removeOfferDealTermLineItem: (index) => dispatch(actions.removeOfferDealTermLineItem(index)),
    updateOfferDealTermLineItemLabel: (index, label) => dispatch(actions.updateOfferDealTermLineItemLabel(index, label)),
    updateOfferDealTermLineItemDescription: (index, description) => dispatch(actions.updateOfferDealTermLineItemDescription(index, description)),
    updateOfferAdditionalTerms: (editorState) => dispatch(actions.updateOfferAdditionalTerms(editorState)),
    updateOfferContactDetails: (editorState) => dispatch(actions.updateOfferContactDetails(editorState)),
    submitOfferForm: (csrfToken, team, offer) => dispatch(actions.submitOfferForm(dispatch, csrfToken, team, offer)),
    updateOfferFrontEndState: (state) => dispatch(actions.updateOfferFrontEndState(state)),
    fetchArtistData: (artistId, offer) => dispatch(actions.fetchArtistData(dispatch, artistId, offer)),
    updateOfferRemovePerformer: () => dispatch(actions.updateOfferRemovePerformer()),
    updateOfferAgentName: (agentName) => dispatch(actions.updateOfferAgentName(agentName)),
    updateOfferAgentEmail: (agentEmail) => dispatch(actions.updateOfferAgentEmail(agentEmail)),
    updateOfferEmailBody: (emailBody) => dispatch(actions.updateOfferEmailBody(emailBody)),
    sendOffer: (csrfToken, team, offer, closeModal) => dispatch(actions.sendOffer(dispatch, csrfToken, team, offer, closeModal)),
    updateOfferLastSentTo: (lastSentTo) => dispatch(actions.updateOfferLastSentTo(lastSentTo)),
    updateOfferTemplateName: (offerTemplateName) => dispatch(actions.updateOfferTemplateName(offerTemplateName)),
    createOfferTemplate: (csrfToken, team, offer, offerTemplateName) => dispatch(actions.createOfferTemplate(dispatch, csrfToken, team, offer, offerTemplateName)),
    fetchOfferTemplate: (team, offerTemplateId) => dispatch(actions.fetchOfferTemplate(dispatch, team, offerTemplateId)),
    selectOfferTemplate: (offerTemplate) => dispatch(actions.selectOfferTemplate(offerTemplate)),
    addArtistSuggestion: (artistSuggestion, team, user) => dispatch(actions.addArtistSuggestion(artistSuggestion, team, user)),
    offerCalendarEventChanged: (calendarEvent) => dispatch(actions.offerCalendarEventChanged(calendarEvent)),
    fetchCalendarEventPerformers: (team, calendarEvent, offer) => dispatch(actions.fetchCalendarEventPerformers(dispatch, team, calendarEvent, offer)),
    offerPerformerSelected: (performer) => dispatch(actions.offerPerformerSelected(performer)),
    offerStartTimeChanged: (startTime) => dispatch(actions.offerStartTimeChanged(startTime)),
    acceptOfferFromModal: (team, offerId, csrfToken) => dispatch(actions.acceptOfferFromModal(dispatch, team, offerId, csrfToken)),
    declineOfferFromModal: (team, offerId, csrfToken) => dispatch(actions.declineOfferFromModal(dispatch, team, offerId, csrfToken)),
    updateOfferEventName: (eventName) => dispatch(actions.updateOfferEventName(eventName)),
    offerPerformerUnselected: () => dispatch(actions.offerPerformerUnselected()),
    updateOfferDollarOrPercentageOperator: (operator) => dispatch(actions.updateOfferDollarOrPercentageOperator(operator)),
    updateOfferDollarAmount: (amount) => dispatch(actions.updateOfferDollarAmount(amount)),
    updateOfferUpsidePercentage: (percentage) => dispatch(actions.updateOfferUpsidePercentage(percentage)),
    hiddenFileUploadInputElChanged: (el) => dispatch(actions.hiddenFileUploadInputElChanged(el)),
    uploadAttachment: (csrfToken, team, file) => dispatch(actions.uploadAttachment(dispatch, csrfToken, team, file)),
    attachmentRemoved: (attachment) => dispatch(actions.attachmentRemoved(attachment))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(OfferModal);
