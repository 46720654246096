/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;

import {
  EXAMPLE
} from '../constants/offerV2SettlementConstants';

export const updateExample = (example) => ({
  type: EXAMPLE,
  example
});
