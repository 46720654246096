import PropTypes from 'prop-types';
import React from 'react';

import {
  SHOW_ORDER_SLIDING_STATE
} from '../constants/confirmDashboardConstants';

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const canSubmitForm = (resendEmail, sendingConfirmationEmail) => {
  return (
    resendEmail
      && resendEmail.length > 0
      && validateEmail(resendEmail)
      && !sendingConfirmationEmail
  );
}

const ResendEmailSlidingState = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  order,
  orderChanged,
  sendOrderReceipt,
  slidingPaneStateChanged,
  resendEmail,
  resendEmailChanged,
  sendingConfirmationEmail
}) => (
  <React.Fragment>
    <div className="row"
         style={{
           "padding": "26px 22px",
           "borderBottom": "2px solid #f5f5f5"
         }}>
      <div className="col">
        <p className="mb-0"
           style={{"fontSize": "20px"}}>
          <a href="#"
             onClick={
               (e) => {
                 e.preventDefault();
                 slidingPaneStateChanged(SHOW_ORDER_SLIDING_STATE);
               }
             }>
            <i className="far fa-arrow-left mr-2"></i>
          </a>
          <strong>Resend Confirmation Email</strong>
        </p>
      </div>
      <div className="col-xs-auto d-flex align-items-center"
           style={{"paddingRight": "15px"}}>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               orderChanged({});
             }
           }
           className="text-muted">
          <i className="fal fa-times"
             style={{"fontSize": "24px"}}></i>
        </a>
      </div>
    </div>
    <div className="row"
         style={{
           "padding": "10px 22px 24px 22px"
         }}>
      <div className="col-12">
        <p className="mb-0 text-dark"
           style={{"fontWeight": "600", "fontSize": "16px"}}>
          Order #{order.id}
        </p>
      </div>
      <div className="col-12">
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(!canSubmitForm(resendEmail, sendingConfirmationEmail)){
                    return false;
                  }

                  sendOrderReceipt(csrfToken, team, order, resendEmail);
                }
              }>
          <div className="form-group"
               style={{"marginTop": "32px"}}>
            <label htmlFor="order-email"
                   className="mb-0"
                   style={{
                     "fontSize": "14px",
                     "color": "#B3B3B3"
                   }}>
              Send the email to:
            </label>
            <input type="email"
                   className="form-control form-control-lg"
                   value={resendEmail || ""}
                   onChange={
                     (e) => {
                       resendEmailChanged(e.target.value);
                     }
                   }
                   style={{
                     "fontSize": "14px",
                     "border": "1px solid #e6e6e6"
                   }}
                   id="order-email" />
            <p className="form-text text-muted small mb-0"
               style={{"marginTop": "14px"}}>
              A confirmation will also be sent to the orignal email registered to the order.
            </p>
          </div>
          <div className="form-row"
               style={{"paddingTop": "8px"}}>
            <div className="col-12 text-right">
              {!sendingConfirmationEmail ? (
                <a href="#"
                   className="btn btn-outline-primary mr-2"
                   style={{"borderWidth": "1px"}}
                   onClick={
                     (e) => {
                       e.preventDefault();
                       slidingPaneStateChanged(SHOW_ORDER_SLIDING_STATE);
                     }
                   }>
                  <strong>Cancel</strong>
                </a>
              ) : null}
              <button type="submit"
                      disabled={!canSubmitForm(resendEmail, sendingConfirmationEmail)}
                      className="btn btn-primary">
                {sendingConfirmationEmail ? (
                  <React.Fragment>
                    <img src="/uploading-loading.gif"
                         className="mr-1"
                         style={{width: "16px"}} />
                    <strong>Sending...</strong>
                  </React.Fragment>
                ) : (
                  <strong>Send</strong>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </React.Fragment>
);

ResendEmailSlidingState.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  order: PropTypes.object,
  orderChanged: PropTypes.func.isRequired,
  sendOrderReceipt: PropTypes.func.isRequired,
  slidingPaneStateChanged: PropTypes.func.isRequired,
  resendEmail: PropTypes.string,
  resendEmailChanged: PropTypes.func.isRequired,
  sendingConfirmationEmail: PropTypes.bool
};

export default ResendEmailSlidingState;
