import PropTypes from 'prop-types';
import React from 'react';
import Popup from "reactjs-popup";
import AsyncSelect from 'react-select/async';
const axios = require('axios').default;

const contentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "265px"
}

const promiseOptions = (inputValue, url, urlPrams) =>
  new Promise(resolve => {
    axios.get(url, {
        params: {
          ...urlPrams,
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return { value: data.name, label: data.name };
        });

        resolve(newOptions);
      })
  });

const MultiSelectFilter = ({ filter, updateFilter }) => (
  <div className="filter-pill-container mr-2 my-3">
    <Popup arrow={false}
           offsetY={5}
           position="bottom left"
           contentStyle={contentStyle}
           trigger={open => (
      <span className={"filter-pill clickable " + (open ? " opened " : "") + (filter.value.length > 0 ? " filtering " : "")}>
        <span className="px-1">{filter.label}</span>
        {filter.value.length === 0 &&
          <strong className="px-2">All</strong>
        }
        {filter.value.length > 0 &&
          <strong className="px-2">{filter.value.join(" | ")}</strong>
        }
        <i className="fa fa-chevron-down px-1"></i>
      </span>
    )} >
      {close => (
        <div>
          <AsyncSelect cacheOptions
                       isMulti
                       defaultOptions
                       onChange={(options) => {updateFilter(filter, (options || []).map((o) => {return o.value}));}}
                       loadOptions={(inputValue) => promiseOptions(inputValue, filter.optionsUrl, filter.optionsUrlParams)} />
        </div>
      )}
    </Popup>
  </div>
);

MultiSelectFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired
};

export default MultiSelectFilter;
