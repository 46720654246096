import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import RichEditor from '../../RichEditor/components/RichEditor';
import { ToastContainer, toast } from 'react-toastify';
import MaskedInput from 'react-text-mask'
import VenueAgreementRow from "./VenueAgreementRow";

const axios = require("axios").default;

const canSubmitForm = (venueOwnership) => {
  return (
    venueOwnership
      && venueOwnership.statement_descriptor_suffix
      && venueOwnership.statement_descriptor_suffix.length >= 3
  );
};

const TicketingSettings = ({
  csrfToken,
  team,
  currentUser,
  venueOwnerships,
  selectedVenueOwnership,
  selectedVenueOwnershipChanged,
  selectedVenueOwnershipEditorStateChanged,
  updateVenueOwnership,
  maxDescriptorLength,
}) => {
  const [agreementsToRender, setAgreementsToRender] = useState([]);
  const [agreementsToCreate, setAgreementsToCreate] = useState({});
  const [agreementsToUpdate, setAgreementsToUpdate] = useState({});

  const reInitializeAgreements = () => {
    setAgreementsToRender([]);
    setAgreementsToCreate({});
    setAgreementsToUpdate({});

    fetchVenueAgreements();
  };

  useEffect(() => {
    reInitializeAgreements();
  }, [selectedVenueOwnership.id]);

  const showToastError = (err) => {
    toast.error(err.response.data.join(", "), {
      position: toast.POSITION.TOP_CENTER,
      draggable: false,
      closeOnClick: false,
      autoClose: 5000,
      hideProgressBar: true
    });
  };

  const fetchVenueAgreements = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.get("/teams/" + team.id + "/venue_ownerships/" +  selectedVenueOwnership.id + "/venue_agreements")
      .then(({ data }) => {
        setAgreementsToRender(data.map((a, index) => {
          return {
            ...a,
            index: index,
          };
        }));
      });
  };

  const handleAgreementChange = (agreement) => {
    if (agreement.id) {
      setAgreementsToUpdate({
        ...agreementsToUpdate,
        [agreement.id]: agreement,
      });
    } else {
      setAgreementsToCreate({
        ...agreementsToCreate,
        [agreement.index]: agreement,
      });
    }
  };

  const handleAgreementDelete = (agreement) => {
    if (!!agreement.id) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.delete("/teams/" + team.id + "/venue_ownerships/" + selectedVenueOwnership.id + "/venue_agreements/" + agreement.id)
        .then(() => {
          reInitializeAgreements();
        })
        .catch((err) => {
          showToastError(err);
          reInitializeAgreements();
        });
    } else {
      reInitializeAgreements();
    }
  };

  const handleAgreementSave = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    var requestPromises = [];

    for(var agreementIndex in agreementsToCreate) {
      requestPromises.push(
        axios.post("/teams/" + team.id + "/venue_ownerships/" + selectedVenueOwnership.id + "/venue_agreements", {
          venue_agreement: {
            ...agreementsToCreate[agreementIndex],
            venue_ownership_id: selectedVenueOwnership.id,
          }
        }).catch((err) => {
          showToastError(err);
        })
      );
    };

    for(var agreementId in agreementsToUpdate) {
      requestPromises.push(
        axios.put("/teams/" + team.id + "/venue_ownerships/" + selectedVenueOwnership.id + "/venue_agreements/" + agreementId, {
          venue_agreement: {
            ...agreementsToUpdate[agreementId],
            venue_ownership_id: selectedVenueOwnership.id,
          }
        }).catch((err) => {
          showToastError(err);
        })
      );
    };

    axios.all(requestPromises).then(() => {
      reInitializeAgreements();
    });
  };

  return(<div>
    <ToastContainer />
    <div className="row">
      <div className="col-12">
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(!canSubmitForm(selectedVenueOwnership)){
                    return false;
                  }

                  handleAgreementSave();
                  updateVenueOwnership(csrfToken, team, selectedVenueOwnership);
                }
              }>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <select className="form-control form-control-sm text-primary"
                      onChange={
                        (e) => {
                          var venueOwnershipId = parseInt(e.target.value);
                          var venueOwnership = venueOwnerships.find((vo) =>
                            vo.id === venueOwnershipId
                          );

                          selectedVenueOwnershipChanged(venueOwnership);
                        }
                      }
                      value={selectedVenueOwnership.id || ""}
                      style={{"border": "1px solid #e6e6e6"}}>
                {venueOwnerships.map((venueOwnership, index) =>
                  <option key={index}
                          value={venueOwnership.id}>
                    {venueOwnership.venueable.name}
                  </option>
                )}
              </select>
            </div>
            <div className="col d-none d-md-block">
            </div>
            <div className="col-xs-auto d-none d-md-block"
                 style={{"paddingRight": "15px"}}>
              <button type="submit"
                      disabled={!canSubmitForm(selectedVenueOwnership)}
                      className="btn btn-primary">
                <strong>Save</strong>
              </button>
            </div>
          </div>
          <div className="row" style={{"marginTop": "27px"}}>
            <div className="col-12">
              <p className="mb-1 text-dark"
                 style={{"fontSize": "16px"}}>
                <strong>Credit Card Statement Descriptor</strong>
              </p>
              <div className="row">
                <div className="col-12 col-md-8">
                  <p className="m-0 small text-muted">
                     Statement descriptors explain charges or payments on bank statements. Determine how your ticketed event transactions appear on your customer’s statements.
                  </p>
                </div>
              </div>
              <div className="form-row" style={{"marginTop": "25px"}}>
                <div className="col col-md-6 col-lg-4">
                  <div className="input-group input-group-sm"
                       style={{
                         "fontSize": "14px"
                       }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0"
                            style={{
                              "color": "#0D4568",
                              "fontFamily": "Courier New",
                              "background": "#e8edf2"
                            }}>
                        <strong>OD*&nbsp;</strong>
                      </span>
                    </div>
                    <MaskedInput
                      mask={
                        () => {
                          return Array.from(Array(maxDescriptorLength).keys())
                            .map((i) => /[^<>\\'"*]+/);
                        }
                      }
                      className="form-control"
                      style={{"border": "1px solid #b3b3b3"}}
                      guide={false}
                      onBlur={() => {}}
                      value={selectedVenueOwnership.statement_descriptor_suffix || ""}
                      onChange={
                        (e) => {
                          var updated = Object.assign({}, selectedVenueOwnership, {statement_descriptor_suffix: e.target.value});
                          selectedVenueOwnershipChanged(updated);
                        }
                      }
                    />
                  </div>
                </div>
                <div className="col-xs-auto d-flex align-items-center"
                     style={{"paddingRight": "15px"}}>
                  <p className="mb-0 text-muted" style={{"fontSize": "10px"}}>
                    {(maxDescriptorLength - selectedVenueOwnership.statement_descriptor_suffix.length) + " characters remaining"}
                  </p>
                </div>
              </div>
              <hr style={{
                    "borderTop": "1px solid #e6e6e6",
                    "marginTop": "56px",
                    "marginBottom": "32px"
                  }} />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <p className="text-dark"
                  style={{
                    "fontSize": "16px",
                    "marginBottom": "4px"
                  }}>
                <strong>Maximum Tickets Allowed per Event</strong>
              </p>
              <div className="row">
                <div className="col-12 col-md-8">
                  <p className="m-0 small text-muted"
                    style={{
                      "paddingBottom": "16px"
                    }}
                  >
                    Define the maximum number of tickets that can be purchased by a fan across multiple orders for a single event. If a fan attempts to purchase more than the maximum number of tickets allowed per event, they will receive an alert notifying them that the event has a maximum and the order will not process.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="venue-ownership-max-tickets-per-email"
                         className="text-muted small"
                         style={{"color": "#B3B3B3"}}>
                    Maximum Tickets Allowed
                  </label>
                  <MaskedInput
                    style={{
                      "textTransform": "uppercase",
                      "fontSize": "14px",
                      "color": "black",
                      "borderWidth": "1px",
                      "width": "96px",
                      "borderRadius": "4px"
                    }}
                    mask={
                      () => {
                        var regex = [];

                        Array.from(Array(3).keys())
                          .map((i) => {
                            regex.push(/[0-9]/);
                          });

                        return regex;
                      }
                    }
                    className="form-control"
                    guide={false}
                    onBlur={() => {}}
                    value={selectedVenueOwnership.max_tickets_per_email || ""}
                    onChange={
                      (e) => {
                        var updated = Object.assign({}, selectedVenueOwnership, {
                          max_tickets_per_email: e.target.value
                        });

                        selectedVenueOwnershipChanged(updated);
                      }
                    }
                    id="venue-ownership-max-tickets-per-email"
                    placeholder="0"
                  />
                </div>
              </div>
              <hr style={{
                    "borderTop": "1px solid #e6e6e6",
                    "marginTop": "25px",
                    "marginBottom": "32px"
                  }} />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <p className="text-dark"
                  style={{
                    "fontSize": "16px",
                    "marginBottom": "4px"
                  }}>
                <strong>Agreements</strong>
              </p>
              <div className="row col-12">
                <p className="m-0 small text-muted"
                  style={{
                    "paddingBottom": "16px"
                  }}
                >
                  Include custom agreements to your checkout page that require customers to accept before completing purchase.
                </p>
              </div>
              {agreementsToRender.map((agreement, index) => (
                <VenueAgreementRow
                  agreement={agreement}
                  onChange={(a) => { handleAgreementChange(a); }}
                  onDelete={(a) => { handleAgreementDelete(a); }}
                  key={index}
                />
              ))}
              <button
                className="btn btn-primary-inverse"
                onClick={(e) => {
                  e.preventDefault();
                  setAgreementsToRender([
                    ...agreementsToRender,
                    {
                      index: agreementsToRender.length,
                      body: ""
                    },
                  ]);
                }}
                style={{ marginTop: "10px" }}
              >
                + Add Agreement
              </button>
              <hr style={{
                    "borderTop": "1px solid #e6e6e6",
                    "marginTop": "25px",
                    "marginBottom": "32px"
                  }} />
            </div>
          </div>
          {selectedVenueOwnership.seating_chart_ids.length > 0 ? (
            <div className="row">
              <div className="col-12">
                <p className="text-dark"
                   style={{
                     "fontSize": "16px",
                     "marginBottom": "12px"
                   }}>
                  <strong>Default Ticket Type</strong>
                </p>
                <div className="row">
                  <div className="col-12">
                    <div className="custom-control custom-radio">
                      <input type="radio"
                             id="default-ticketing-arrangement-seated"
                             name="default-ticketing-arrangement"
                             value="Seated"
                             checked={selectedVenueOwnership.default_ticketing_arrangement === "Seated"}
                             onChange={
                               (e) => {
                                 var updated = Object.assign({}, selectedVenueOwnership, {default_ticketing_arrangement: e.target.value});
                                 selectedVenueOwnershipChanged(updated);
                               }
                             }
                             className="custom-control-input" />
                      <label className="custom-control-label"
                             style={{"fontSize": "14px"}}
                             htmlFor="default-ticketing-arrangement-seated">
                        Seated
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input type="radio"
                             id="default-ticketing-arrangement-general-admission"
                             name="default-ticketing-arrangement"
                             value="General Admission"
                             checked={selectedVenueOwnership.default_ticketing_arrangement === "General Admission"}
                             onChange={
                               (e) => {
                                 var updated = Object.assign({}, selectedVenueOwnership, {default_ticketing_arrangement: e.target.value});
                                 selectedVenueOwnershipChanged(updated);
                               }
                             }
                             className="custom-control-input" />
                      <label className="custom-control-label"
                             style={{"fontSize": "14px"}}
                             htmlFor="default-ticketing-arrangement-general-admission">
                        General Admission
                      </label>
                    </div>
                  </div>
                </div>
                <hr style={{
                      "borderTop": "1px solid #e6e6e6",
                      "marginTop": "25px",
                      "marginBottom": "32px"
                    }} />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-12">
              <p className="mb-1"
                 style={{"fontSize": "16px"}}>
                <strong>Order Form</strong>
              </p>
              <div className="row">
                <div className="col-12 col-md-8">
                  <p className="mb-2 small text-muted">
                    Manage the information you collect during checkout.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <table className="table table-borderless vertical-middle table-sm small mb-0" style={{tableLayout: "fixed"}}>
                    <colgroup>
                      <col span={1} style={{"width": "50%"}} />
                      <col span={1} style={{"width": "25%"}} />
                      <col span={1} style={{"width": "25%"}} />
                    </colgroup>
                    <thead>
                      <tr className="text-muted">
                        <th className="pl-0 pb-0">Field</th>
                        <th className="pb-0 text-center">Enabled</th>
                        <th className="pr-0 pb-0 text-center">Required</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedVenueOwnership.custom_fields.sort((a, b) => a.position - b.position).map((customField, index) =>
                        <tr key={index}>
                          <td className="pl-0 pb-0 overflow-ellipsis" title={customField.name}>
                            <strong>{customField.name}</strong>
                          </td>
                          <td className="pb-0 text-center">
                            <div className="custom-control custom-switch"
                                 style={{"paddingLeft": "46px"}}>
                              <input type="checkbox"
                                     className="custom-control-input"
                                     checked={customField.enabled || false}
                                     onChange={
                                       (e) => {
                                         var customFields = [...selectedVenueOwnership.custom_fields].map((cf) => {
                                           if(cf.id === customField.id){
                                             return Object.assign({}, cf, {enabled: e.target.checked});
                                           } else {
                                             return cf;
                                           }
                                         })

                                         var updated = Object.assign({}, selectedVenueOwnership, {custom_fields: customFields});
                                         selectedVenueOwnershipChanged(updated);
                                       }
                                     }
                                     id={"custom-field-enabled-" + customField.id} />
                              <label className="custom-control-label"
                                     htmlFor={"custom-field-enabled-" + customField.id}>
                                &nbsp;
                              </label>
                            </div>
                          </td>
                          <td className="pr-0 pb-0 text-center">
                            <div className="custom-control custom-switch"
                                 style={{"paddingLeft": "46px"}}>
                              <input type="checkbox"
                                     className="custom-control-input"
                                     checked={customField.required || false}
                                     onChange={
                                       (e) => {
                                         var customFields = [...selectedVenueOwnership.custom_fields].map((cf) => {
                                           if(cf.id === customField.id){
                                             return Object.assign({}, cf, {required: e.target.checked});
                                           } else {
                                             return cf;
                                           }
                                         })

                                         var updated = Object.assign({}, selectedVenueOwnership, {custom_fields: customFields});
                                         selectedVenueOwnershipChanged(updated);
                                       }
                                     }
                                     id={"custom-field-required-" + customField.id} />
                              <label className="custom-control-label"
                                     htmlFor={"custom-field-required-" + customField.id}>
                                &nbsp;
                              </label>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <hr style={{
                    "borderTop": "1px solid #e6e6e6",
                    "marginTop": "32px",
                    "marginBottom": "32px"
                  }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="mb-1"
                 style={{"fontSize": "16px"}}>
                <strong>Confirmation Page and Email</strong>
              </p>
              <div className="row">
                <div className="col-12 col-md-8">
                  <p className="mb-3 small text-muted">
                    After registering for your events customers will receive a confirmation email with their tickets. Add important venue-specific instructions or any other useful information here.
                  </p>
                </div>
              </div>
              <RichEditor
                editorState={selectedVenueOwnership.ticket_confirmation_info}
                onEditorStateChange={
                  (editorState) => {
                    selectedVenueOwnershipEditorStateChanged(editorState);
                  }
                } />
              <hr style={{
                    "borderTop": "1px solid #e6e6e6",
                    "marginTop": "56px",
                    "marginBottom": "32px"
                  }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="mb-1"
                 style={{"fontSize": "16px"}}>
                <strong>Google Analytics</strong>
              </p>
              <div className="row"
                   style={{"marginTop": "10px"}}>
                <div className="col-12">
                  <label htmlFor="venue-ownership-google-tracking-id"
                         className="text-muted mb-0"
                         style={{"fontSize": "14px", "color": "#B3B3B3"}}>
                    Google Universal Analytics Tracking ID
                  </label>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <MaskedInput
                        style={{"textTransform": "uppercase", "fontSize": "14px", "color": "black", "borderWidth": "1px"}}
                        mask={
                         () => {
                           var regex = [];

                           Array.from(Array(100).keys())
                             .map((i) => {
                               regex.push(/[a-zA-Z0-9\-]/);
                             });

                           return regex;
                         }
                        }
                        className="form-control form-control-lg"
                        guide={false}
                        onBlur={() => {}}
                        value={selectedVenueOwnership.google_tracking_id || ""}
                        onChange={
                          (e) => {
                            var newCode = e.target.value.toUpperCase();
                            var updated = Object.assign({}, selectedVenueOwnership, {google_tracking_id: newCode});
                            selectedVenueOwnershipChanged(updated);
                          }
                        }
                        id="venue-ownership-google-tracking-id"
                        placeholder="UA-12345678"
                      />
                    </div>
                  </div>
                  <hr style={{
                        "borderTop": "1px solid #e6e6e6",
                        "marginTop": "56px",
                        "marginBottom": "32px"
                      }} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="mb-1"
                 style={{"fontSize": "16px"}}>
                <strong>Facebook Pixel</strong>
              </p>
              <div className="row"
                   style={{"marginTop": "10px", "marginBottom": "110px"}}>
                <div className="col-12">
                  <label htmlFor="venue-ownership-facebook-pixel-id"
                         className="text-muted mb-0"
                         style={{"fontSize": "14px", "color": "#B3B3B3"}}>
                    Facebook Pixel ID
                  </label>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <MaskedInput
                        style={{"textTransform": "uppercase", "fontSize": "14px", "color": "black", "borderWidth": "1px"}}
                        mask={
                         () => {
                           var regex = [];

                           Array.from(Array(100).keys())
                             .map((i) => {
                               regex.push(/[a-zA-Z0-9\-]/);
                             });

                           return regex;
                         }
                        }
                        className="form-control form-control-lg"
                        guide={false}
                        onBlur={() => {}}
                        value={selectedVenueOwnership.facebook_pixel_id || ""}
                        onChange={
                          (e) => {
                            var newCode = e.target.value.toUpperCase();
                            var updated = Object.assign({}, selectedVenueOwnership, {facebook_pixel_id: newCode});
                            selectedVenueOwnershipChanged(updated);
                          }
                        }
                        id="venue-ownership-facebook-pixel-id"
                        placeholder="12345678"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-block d-md-none">
            <div className="col-12 text-right">
              <button type="submit"
                      disabled={!canSubmitForm(selectedVenueOwnership)}
                      className="btn btn-primary">
                <strong>Save</strong>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
)};

TicketingSettings.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  venueOwnerships: PropTypes.array,
  selectedVenueOwnership: PropTypes.object,
  selectedVenueOwnershipChanged: PropTypes.func.isRequired,
  selectedVenueOwnershipEditorStateChanged: PropTypes.func.isRequired,
  updateVenueOwnership: PropTypes.func.isRequired,
  maxDescriptorLength: PropTypes.number,
};

export default TicketingSettings;
