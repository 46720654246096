import React from "react";

import ActionShowHeader from "./ActionShowHeader";
import SendTestOverlay from "../../BeefreeTemplates/SendTestOverlay";
import ActionTabHeader from "./ActionTabHeader";
import SmsTab from "./SmsTab";
import EmailContentTab from "./EmailContentTab";
import EmailPreviewTab from "./EmailPreviewTab";
import { useActionContext } from "./ActionContainer";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
};

const ActionShow = () => {
  const {
    tab,
    isEmailTemplate,
  } = useActionContext();

  return (
    <div style={containerStyle}>
      <SendTestOverlay templatableContext={useActionContext} />
      <ActionShowHeader />
      <div>
        <ActionTabHeader />
        {isEmailTemplate ? (
          tab === "content" ? (
            <EmailContentTab />
          ) : (
            <EmailPreviewTab />
          )
        ) : (
          <SmsTab />
        )}
      </div>
    </div>
  );
};

export default ActionShow;
