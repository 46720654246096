import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const useToggleState = (initialState) => {
  const [state, setState] = React.useState(initialState);

  const toggleState = React.useCallback(() => {
    setState(!state);
  }, [state, setState]);

  return [state, toggleState];
};

const NooraButton = ({csrfToken}) => {
  useEffect(() => {
    Noora("initChangelog", {
      workspaceId: "65a569c2810ed6c89ed07d71",
      position: "bottom",
      align: "right",
      ssoToken: csrfToken,
      theme: "light"
    });
  }, []);

  const [hover, toggleHover] = useToggleState(false);

  let divStyle = {
    alignItems: "center",
    borderRadius: "20%",
    display: "flex",
    fontSize: "15px",
    height: "40px",
    justifyContent: "center",
    margin: "0px 15px 0px 15px",
    width: "40px",
  };

  hover && (divStyle = {
    ...divStyle,
    backgroundColor: "#f5f5f5",
    cursor: "pointer",
  });

  return (
    <div style={divStyle}>
      <i 
        data-noora-changelog
        className="fa-regular fa-bell"
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      />
    </div>
  );
};

NooraButton.propTypes = {
  csrfToken: PropTypes.string.isRequired,
};

export default NooraButton;
