import { combineReducers } from 'redux';

import {} from '../constants/pipelineEventsSlidingPaneConstants';

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const artist = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pipelineEvents = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pipelineSlidingPaneReducer = combineReducers({ currentUser, team, csrfToken, artist, pipelineEvents, onClose });

export default pipelineSlidingPaneReducer;
