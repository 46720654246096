import { combineReducers } from 'redux';
import {
  CONFIRM_CHANGED,
  ACTIVE_MENU_ITEM_CHANGED,
  ADD_ATTENDEES_MENU_ITEM,
  ORDER_TYPE_CHANGED,
  TICKET_TYPES_CHANGED,
  ADD_ONS_CHANGED,
  TICKET_RESERVATION_CHANGED,
  IS_PLACING_ORDER_CHANGED,
  CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED,
  TIME_LIMIT_REACHED_CHANGED,
  CANCEL_CHECKOUT,
  CUSTOM_FIELD_VALUE_CHANGED,
  SEATS_IO_CHART_CHANGED,
  SEATS_IO_SELECTED_OBJECTS_CHANGED
} from '../constants/confirmAttendeesConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CHANGED:
      return action.confirm;
    default:
      return state;
  }
};

const activeMenuItem = (state = ADD_ATTENDEES_MENU_ITEM, action) => {
  switch (action.type) {
    case ACTIVE_MENU_ITEM_CHANGED:
      return action.activeMenuItem;
    default:
      return state;
  }
};

const ticketReservation = (state = {}, action) => {
  switch (action.type) {
    case TICKET_RESERVATION_CHANGED:
      return action.ticketReservation;
    case CANCEL_CHECKOUT:
      return {};
    default:
      return state;
  }
};

const orderType = (state = "Complimentary", action) => {
  switch (action.type) {
    case ORDER_TYPE_CHANGED:
      return action.orderType;
    case CANCEL_CHECKOUT:
      return "Complimentary";
    default:
      return state;
  }
};

const ticketTypes = (state = [], action) => {
  switch (action.type) {
    case TICKET_TYPES_CHANGED:
      return action.ticketTypes;
    case CANCEL_CHECKOUT:
      return [...state].map((tt) => Object.assign({}, tt, {quantity: 0}));
    default:
      return state;
  }
};

const addOns = (state = [], action) => {
  switch (action.type) {
    case ADD_ONS_CHANGED:
      return action.addOns;
    case CANCEL_CHECKOUT:
      return [...state].map((tt) => Object.assign({}, tt, {quantity: 0}));
    default:
      return state;
  }
};

const rootUrl = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isPlacingOrder = (state = false, action) => {
  switch (action.type) {
    case IS_PLACING_ORDER_CHANGED:
      return action.isPlacingOrder;
    case CANCEL_CHECKOUT:
      return false;
    default:
      return state;
  }
};

const checkoutTimePercentageRemaining = (state = 0, action) => {
  switch (action.type) {
    case CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED:
      return action.percentage;
    case CANCEL_CHECKOUT:
      return 0;
    default:
      return state;
  }
};

const timeLimitReached = (state = false, action) => {
  switch (action.type) {
    case TIME_LIMIT_REACHED_CHANGED:
      return action.reached;
    default:
      return state;
  }
};

const manualTypes = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const customFieldValues = (state = [], action) => {
  switch (action.type) {
    case CUSTOM_FIELD_VALUE_CHANGED:
      return [...state].map((cf) => {
        if(cf.custom_field.id === action.customFieldValue.custom_field.id){
          return action.customFieldValue;
        } else {
          return cf;
        }
      });
    default:
      return state;
  }
};

const buyers = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const promoters = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const seatsIOPublicKey = (state = "", action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const seatsIOChart = (state = {}, action) => {
  switch(action.type) {
    case SEATS_IO_CHART_CHANGED:
      return action.seatsIOChart;
    default:
      return state;
  }
};

const seatsIOSelectedObjects = (state = [], action) => {
  switch(action.type) {
    case SEATS_IO_SELECTED_OBJECTS_CHANGED:
      return action.seatsIOSelectedObjects;
    case CANCEL_CHECKOUT:
      return [];
    default:
      return state;
  }
};

const confirmAttendeesReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  confirm,
  activeMenuItem,
  ticketReservation,
  orderType,
  ticketTypes,
  addOns,
  rootUrl,
  isPlacingOrder,
  checkoutTimePercentageRemaining,
  timeLimitReached,
  manualTypes,
  customFieldValues,
  buyers,
  promoters,
  seatsIOPublicKey,
  seatsIOChart,
  seatsIOSelectedObjects
});

export default confirmAttendeesReducer;
