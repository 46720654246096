import PropTypes from 'prop-types';
import React from 'react';

import { toast } from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Tippy from '@tippyjs/react';

const CalendarSyncLink = ({ header, link }) => {
  return(
    <>
      <h6 className="font-weight-bold">{header}</h6>
      <div className="d-flex justify-content-between mb-4">
        <div
          className="flex-grow-1 px-3 border-right-0 text-muted"
          style={{
            "paddingTop": "0.75rem",
            "paddingBottom": "0.75rem",
            "backgroundColor": "#f9f9f9",
            "border": "1px solid #e6e6e6",
            "borderRadius": "0.25rem 0 0 0.25rem",
            "overflow": "scroll"
          }}
        >
          <span style={{"whiteSpace": "nowrap"}}>{link}</span>
        </div>

        <CopyToClipboard
          text={link}
          onCopy={() => {
            toast.success("Copied to clipboard", {
              position: toast.POSITION.TOP_CENTER,
              draggable: false,
              closeOnClick: false,
              autoClose: 5000,
              hideProgressBar: true
            });
          }}
        >
          <div>
            <Tippy content='Copy to clipboard'>
              <i
                className="flex-shrink-0 fal fa-copy h-100 px-3"
                style={{
                  "paddingTop": "0.75rem",
                  "paddingBottom": "0.75rem",
                  "color": "#1b81c4",
                  "fontSize": "18px",
                  "backgroundColor":"#dfeffb",
                  "border": "1px solid #84c6ef",
                  "cursor": "pointer",
                  "borderRadius": "0 0.25rem 0.25rem 0"
                }}
              >
              </i>
            </Tippy>
          </div>
        </CopyToClipboard>
      </div>
    </>
  );
}

const CalendarSyncPopup = ({
  team,
  venueOwnership,
  calendarSyncIsOpenChanged,
}) => {
  return(
    <div className="popup-modal-container">
      <div className="row px-4">
        <div className="col d-flex align-items-center">
          <p className="mb-0" style={{"fontSize": "20px"}}>
            <strong>Sync to Google Calendar</strong>
          </p>
        </div>
        <div className="col-xs-auto d-flex align-items-center"
             style={{"paddingRight": "15px"}}>
          <a href="#"
             className="text-dark"
             style={{"fontSize": "20px"}}
             onClick={e => {
               e.preventDefault();
               calendarSyncIsOpenChanged(false);
             }}>
            <i className="fa fa-times text-muted"></i>
          </a>
        </div>
      </div>
      <hr className="card-separator" />
      <div className="row px-4">
        <div className="col-12 w-100">
          <p>Sync your Opendate calendar events to your Google calendar using a unique link.</p>
          <CalendarSyncLink
            header="Holds Calendar"
            link={`${venueOwnership.calendar_sync_url}?event_type=hold`}
          />
          <CalendarSyncLink
            header="Confirms Calendar"
            link={`${venueOwnership.calendar_sync_url}?event_type=confirm`}
          />
          <CalendarSyncLink
            header="On Sale Calendar"
            link={`${venueOwnership.calendar_sync_url}?event_type=on_sale`}
          />
          <CalendarSyncLink
            header="Everything Calendar (Holds/Confirms/On Sale)"
            link={`${venueOwnership.calendar_sync_url}`}
          />
        </div>
      </div>
      <div className="row px-4">
        <div className="col-12 py-2 small">
          <div className="p-3 rounded" style={{"backgroundColor": "#f9f9f9"}}>
            <p className="font-weight-bold">Instructions</p>
            <ol className="pl-3">
              <li>On your computer, open Google Calendar.</li>
              <li>On the left, next to &quot;Other calendars&quot;, click Add From URL.</li>
              <li>Paste the address you copied from above.</li>
              <li>Click Add calendar. The calendar appears to the left, under &quot;Other calendars.&quot;</li>
            </ol>
            <p className="m-0">Tip: This is a one-way sync. Items added in Google Calendar will not show up in Opendate. It might take up to 12 hours for a change in Opendate to show in your Google Calendar.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

CalendarSyncPopup.propTypes = {
  team: PropTypes.object.isRequired,
  venueOwnership: PropTypes.object,
  calendarSyncIsOpenChanged: PropTypes.func.isRequired,
};

export default CalendarSyncPopup;
