import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import RichEditor from "../../RichEditor/components/RichEditor";

import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";

const boxStyle = {
  padding: 0,
};

const buttonStyle = {
  alignSelf: "center",
};

const rowStyle = {
  display: "flex",
  flexDirection: "row",
};

const VenueAgreementRow = ({
  agreement,
  onChange,
  onDelete,
}) => {
  const [agreementBody, setAgreementBody] = useState(
    EditorState.createWithContent(stateFromHTML(agreement.body))
  );

  return (
    <>
      <div 
        className={"row col-12"}
        style={{
          fontSize: "10pt",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <strong>Agreement</strong>
      </div>
      <div className={"formRow"} style={rowStyle}>
        <div className={"col col-11"} style={boxStyle}>
          <RichEditor
            editorState={agreementBody}
            onEditorStateChange={
              (editorState) => {
                setAgreementBody(editorState);
                onChange({
                  ...agreement,
                  body: stateToHTML(editorState.getCurrentContent()),
                })
              }
            } />
        </div>
        <div className={"col col-1"} style={buttonStyle}>
          <a href="#"
            className="text-muted ml-2"
            onClick={
              (e) => {
                e.preventDefault();

                if(window.confirm("Are you sure?")){
                  onDelete(agreement);
                }
              }
            }>
            <i className="fa-light fa-trash"></i>
          </a>
        </div>
      </div>
    </>
  );
};

VenueAgreementRow.propTypes = {
  agreement: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default VenueAgreementRow;
