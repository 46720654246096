import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { toast } from 'react-toastify';

import Popup from "reactjs-popup";

import PageSectionMenu from './PageSectionMenu';
import OfferModalApp from '../../OfferModal/startup/OfferModalApp';
import OfferV2ModalApp from '../../OfferV2Modal/startup/OfferV2ModalApp';
import OfferV2PreviewApp from '../../OfferV2Preview/startup/OfferV2PreviewApp';
import AttachArtistToOffer from'../../../shared/AttachArtistToOffer';

import {
  FORM_STATE,
  PREVIEW_STATE,
} from '../../OfferModal/constants/offerModalConstants';

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "0px"
}

const offerMenuContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "135px",
  "padding": "0px",
  "zIndex": "1999"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "250px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const ManageOffers = ({
  csrfToken,
  team,
  confirm,
  refreshConfirm,
  currentUser,
  modalOffer,
  defaultOffer,
  offerModalIsOpen,
  closeOfferModal,
  openOfferModal,
  offerTemplates,
  venuesOptions,
  onManageLineupInit,
  fetchOfferData,
  refreshPerformer,
  offerPerformer,
  setOfferPerformer,
  acceptOffer,
  declineOffer,
  offers,
  offerChanged,
  updateOfferArtist,
  refreshConfirmOffers,
  deleteOffer,
  venueSelected,
  performers,
  confirmPageSection,
  fetchPermissionSet,
  updateConfirmPageSection,
  venueOwnerships,
  possibleMatchingOffers,
  dismissMatchingOffers,
  acceptMatchingOffer
}) => {
  useEffect(() => {
    onManageLineupInit(defaultOffer);
  }, [])

  return (
    <div>
      <div className="card-body">
        <div className="row pb-3">
          <div className="col">
            <h5 className="strong">
              Offers
              {confirmPageSection.permission_set_id ? (
                <i className="ml-2 far fa-lock small text-danger"></i>
              ) : null}
            </h5>
          </div>
          <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
            {currentUser.can_manage_offers && (offers.length > 0 || possibleMatchingOffers.length > 0) ? (
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     openOfferModal();
                   }
                 }
                 className="btn btn-external btn-sm">
                <strong>New Offer</strong>
              </a>
            ) : null }
            <PageSectionMenu csrfToken={csrfToken}
                             team={team}
                             confirm={confirm}
                             currentUser={currentUser}
                             confirmPageSection={confirmPageSection}
                             fetchPermissionSet={fetchPermissionSet}
                             updateConfirmPageSection={updateConfirmPageSection} />
          </div>
        </div>
        {offers.length > 0 || possibleMatchingOffers.length > 0 ? (
          <div className="form-row">
            {offers.sort((a, b) => {return new Date(a.created_at) - new Date(b.created_at)}).map((offer, index) => (
              <div key={index} className="col-12 col-lg-6">
                <div className="card no-border mb-0 pb-2">
                  <div className="card-body rounded" style={{'background': "#f4f9fc"}}>
                    <div className="row">
                      <div className="col">
                        <div className="text-muted small">OFFER FOR</div>
                        <div className="d-flex flex-wrap"
                             style={{"maxWidth": "70%"}}>
                          <div className="mr-2 strong text-overflow-ellipsis"
                               title={offer.artist_or_event_name}>
                            {offer.artist_or_event_name}
                          </div>
                          {!offer.primary_performer ? (
                            <Popup
                              modal
                              contentStyle={deleteModalContentStyle}
                              onOpen={e => { e.preventDefault() }}
                              trigger={open => (
                                <a href="#">
                                  (Attach artist)
                                </a>
                              )}
                            >
                              {close => (
                                <AttachArtistToOffer
                                  csrfToken={csrfToken}
                                  currentUser={currentUser}
                                  team={team}
                                  confirmPerformers={confirm.performers || []}
                                  offer={offer}
                                  offerChanged={offerChanged}
                                  close={close}
                                  handleAttachArtist={updatedOffer => {
                                    updateOfferArtist(csrfToken, team, updatedOffer, (data) => {
                                      refreshConfirm(csrfToken, team);
                                      refreshConfirmOffers(team, confirm);
                                      close();
                                    });
                                  }}
                                />
                              )}
                            </Popup>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xs-auto" style={
                        {
                          "position": "absolute",
                          "right": "15px"
                        }
                      }>
                        {currentUser.can_manage_offers && ["draft", "sent"].includes(offer.state) ? (
                          <Popup arrow={false}
                                 offsetY={5}
                                 position="bottom right"
                                 contentStyle={offerMenuContentStyle}
                                 onOpen={
                                   (e) => {
                                     e.preventDefault();
                                   }
                                 }
                                 trigger={open => (
                                   offer.state === "draft" ? (
                                     <span className="badge badge-secondary clickable px-3 py-2">
                                       Draft
                                       &nbsp;
                                       <i className="fas fa-caret-down"></i>
                                     </span>
                                   ) : offer.state === "sent" ? (
                                     <span className="badge badge-primary clickable px-3 py-2">
                                       Sent
                                       &nbsp;
                                       <i className="fas fa-caret-down"></i>
                                     </span>
                                   ) :  null
                                 )} >
                            {close => (
                              <div className='row text-left'>
                                <div className="col-12">
                                  <ul className="list-group offer-inline-menu">
                                    <li className="list-group-item">
                                      <a href="#"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             close();
                                             acceptOffer(team, offer.id, csrfToken);
                                           }
                                         }
                                         className="text-muted">
                                        <span className="text-dark">
                                          Offer Accepted
                                        </span>
                                      </a>
                                    </li>
                                    <li className="list-group-item">
                                      <a href="#"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             close();
                                             declineOffer(team, offer.id, csrfToken);
                                           }
                                         }
                                         className="text-muted">
                                        <span className="text-dark">
                                          Offer Declined
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </Popup>
                        ) : (
                          offer.state === "draft" ? (
                            <span className="badge badge-secondary px-3 py-2">
                              Draft
                            </span>
                          ) : offer.state === "sent" ? (
                            <span className="badge badge-primary px-3 py-2">
                              Sent
                            </span>
                          ) : offer.state === "accepted" ? (
                            <span className="badge badge-success px-3 py-2">
                              Accepted
                            </span>
                          ) : offer.state === "declined" ? (
                            <span className="badge badge-danger px-3 py-2">
                              Declined
                            </span>
                          ) : null
                        )}
                        {currentUser.can_manage_offers ? (
                          <Popup arrow={false}
                                 offsetY={5}
                                 position="bottom right"
                                 contentStyle={popupContentStyle}
                                 onOpen={
                                   (e) => {
                                     e.preventDefault();
                                   }
                                 }
                                 trigger={open => (
                                   <a href="#" className="pl-3">
                                     <i className="fas fa-ellipsis-v"></i>
                                   </a>
                                 )} >
                            {close => (
                              <div className='row text-left'>
                                <div className="col-12">
                                  <ul className="list-group offer-inline-menu">
                                    {offer.state !== "accepted" && offer.state !== "declined" ? (
                                      <li className="list-group-item">
                                        <a href="#" onClick={
                                          (e) => {
                                            e.preventDefault();
                                            close();
                                            acceptOffer(team, offer.id, csrfToken);
                                          }
                                        }>
                                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                                            <i className="fas fa-check-circle"></i>
                                          </span>
                                          Mark as Accepted
                                        </a>
                                      </li>
                                    ) : null}
                                    <li className="list-group-item">
                                      <a href={"/teams/" + team.id + "/offers/" + offer.id + "/preview.pdf"}
                                         target="_blank"
                                         className="text-muted">
                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                          <i className="fas fa-download"></i>
                                        </span>
                                        <span className="text-dark">
                                          Download PDF
                                        </span>
                                      </a>
                                    </li>
                                    <li className="list-group-item">
                                      <a href="#"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             close();
                                             fetchOfferData(team, offer.id, PREVIEW_STATE);
                                           }
                                         }
                                         className="text-muted">
                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                          <i className="fas fa-search"></i>
                                        </span>
                                        <span className="text-dark">
                                          Preview
                                        </span>
                                      </a>
                                    </li>
                                    <li className="list-group-item">
                                      <a href="#"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             close();

                                             if(offer.ui_version === 1){
                                               fetchOfferData(team, offer.id, FORM_STATE);
                                             } else {
                                               window.location.href = ("/teams/" + team.id + "/offers/" + offer.id + "/edit");
                                             }
                                           }
                                         }
                                         className="text-muted">
                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                          <i className="fas fa-pencil"></i>
                                        </span>
                                        <span className="text-dark">
                                          Edit
                                        </span>
                                      </a>
                                    </li>
                                    <li className="list-group-item delete-offer-li">
                                      <Popup
                                          trigger={
                                            <a href="#" className="text-danger">
                                              <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                <i className="fas fa-trash"></i>
                                              </span>
                                              Delete
                                            </a>
                                          }
                                          modal
                                          contentStyle={deleteModalContentStyle}
                                          onOpen={
                                            (e) => {
                                              e.preventDefault();
                                            }
                                          }
                                          closeOnDocumentClick
                                        >
                                        {closeDeletePopup => (
                                          <div>
                                            <div className="row">
                                              <div className="col">
                                                <div className="row">
                                                  <div className="col">
                                                    <h5 style={{"marginBottom": "16px"}}>
                                                      <div className="strong">Delete offer?</div>
                                                    </h5>
                                                  </div>
                                                  <div className="col-xs-auto">
                                                    <a href="#"
                                                       className="text-dark"
                                                       style={{"paddingRight": "15px"}}
                                                       onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                                      <i className="fa fa-times"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                                <p>You're about to delete this offer.</p>
                                                <p>This cannot be undone.</p>
                                                <p>Are you sure?</p>
                                                <div className="text-right">
                                                  <a href="#"
                                                     onClick={
                                                       (e) => {
                                                         e.preventDefault();
                                                         closeDeletePopup();
                                                       }
                                                     }>
                                                    Cancel
                                                  </a>
                                                  <a href="#"
                                                     className="btn btn-danger ml-3"
                                                     onClick={
                                                       (e) => {
                                                         e.preventDefault();
                                                         closeDeletePopup();
                                                         close();
                                                         deleteOffer(team, offer.id, csrfToken);
                                                       }
                                                     }>
                                                    Yes, delete offer
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </Popup>
                        ) : null}
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-6">
                        <div className="text-muted small">CREATED ON</div>
                        <div>{offer.human_created_at}</div>
                      </div>
                      <div className="col-6">
                        <div className="text-muted small">CREATED BY</div>
                        <div>{offer.team_membership.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {possibleMatchingOffers.length > 0 ? (
              <div className="col-12 col-lg-6">
                <div className="card no-border"
                      style={{"background": "#f5f5f5", "borderRadius": "4px"}}>
                  <div className="card-body" style={{"padding": "9px 17px"}}>
                    <div className="row">
                      <div className="col">
                        <p className='mb-0' style={{"fontSize": "14px", "color": "#666666"}}>
                          <strong>Possible Matching Offer(s)</strong>
                        </p>
                        <p className='mb-0' style={{"fontSize": "10px", "color": "#666666"}}>
                          {`We found these offers that don’t have an associated event but may be applicable to this ${confirm.type === 'Confirm' ? 'confirm' : 'hold'}.  Add it now to unlock even more capabilities.`}
                        </p>
                      </div>
                      <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
                        <a href="#"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                dismissMatchingOffers(csrfToken, team, confirm, possibleMatchingOffers);
                              }
                            }>
                          <i className="fal fa-times"></i>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      {possibleMatchingOffers.map((offer, index) =>
                        <div className='col-12' key={index} style={{"marginTop": "10px"}}>
                          <div className='row'>
                            <div className='col'>
                              <p className='mb-0 small text-muted'>
                                <strong>
                                  {offer.artist_or_event_name}
                                  <a href={`/teams/${team.id}/offers/${offer.id}/edit`}
                                      target="_blank">
                                    <i className="far fa-eye text-primary ml-1"></i>
                                  </a>
                                </strong>
                              </p>
                              <p className='mb-0 small text-muted'>
                                {offer.human_metadata}
                              </p>
                            </div>
                            <div className='col-xs-auto d-flex align-items-center'
                                  style={{"paddingRight": "15px"}}>
                              <a href="#"
                                  onClick={
                                    (e) => {
                                      e.preventDefault();

                                      var offerArtist = offer.primary_performer.artistable;
                                      var performer = performers.find((performer) => {
                                        return (
                                          performer.artistable_type === "Artist"
                                            && performer.artistable
                                            && performer.artistable.id === offerArtist.id
                                        );
                                      });

                                      acceptMatchingOffer(csrfToken, team, confirm, offer, performer);
                                    }
                                  }
                                 className='small'>
                                <strong>
                                  Add Offer
                                </strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {offers.length === 0 && possibleMatchingOffers.length === 0 ? (
          <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 order-md-2 text-left text-md-center">
                  <i className="fal fa-file-contract"
                     style={{"fontSize": "120px", "color": "rgba(25, 130, 196, .5)"}}></i>
                </div>
                <div className="col-12 col-md-6 col-lg-8 order-md-1">
                  <h5>Create an Offer</h5>
                  <p className="text-muted">
                    Now that this event is confirmed, let's get an offer on the books.
                  </p>
                  {currentUser.can_manage_offers ? (
                    <a href="#"
                       onClick={
                         (e) => {
                           e.preventDefault();
                           openOfferModal();
                         }
                       }
                       className="btn btn-primary">
                      <strong>Create Offer</strong>
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Popup
        open={offerModalIsOpen}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={false}
        className={"offer-modal"}
        onClose={() => {
          closeOfferModal(defaultOffer, null);
          refreshConfirmOffers(team, confirm);
        }}
      >
        {Object.keys(modalOffer).length > 0 && modalOffer.id && modalOffer.ui_version === 1 ? (
          <OfferModalApp currentUser={currentUser}
                         defaultOffer={defaultOffer}
                         offer={modalOffer}
                         csrfToken={csrfToken}
                         closeModal={closeOfferModal}
                         offerTemplates={offerTemplates}
                         venuesOptions={venuesOptions}
                         team={team} />
        ) : Object.keys(modalOffer).length > 0 && modalOffer.id && modalOffer.ui_version === 2 ? (
          <OfferV2PreviewApp offer={modalOffer}
                             currentUser={currentUser}
                             csrfToken={csrfToken}
                             onClose={
                               () => {
                                 closeOfferModal(defaultOffer, null);
                                 refreshConfirmOffers(team, confirm);
                               }
                             }
                             onSuccess={
                               (offer) => {
                                 closeOfferModal(defaultOffer, null);
                                 refreshConfirmOffers(team, confirm);

                                 toast.success("Offer has been sent.", {
                                   position: toast.POSITION.TOP_CENTER,
                                   draggable: false,
                                   closeOnClick: false,
                                   autoClose: 5000,
                                   hideProgressBar: true
                                 });
                               }
                             }
                             team={team} />
        ) : (
          <OfferV2ModalApp currentUser={currentUser}
                           csrfToken={csrfToken}
                           onClose={
                             () => {
                               closeOfferModal(defaultOffer, null);
                             }
                           }
                           venuesOptions={venuesOptions}
                           venueSelected={venueSelected}
                           venueOwnerships={venueOwnerships}
                           calendarEvent={confirm}
                           performers={performers}
                           team={team} />
        )}
      </Popup>
    </div>
  );
};

ManageOffers.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  refreshConfirm: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  modalOffer: PropTypes.object,
  defaultOffer: PropTypes.object,
  offerModalIsOpen: PropTypes.bool,
  closeOfferModal: PropTypes.func.isRequired,
  openOfferModal: PropTypes.func.isRequired,
  offerTemplates: PropTypes.array,
  venuesOptions: PropTypes.array,
  onManageLineupInit: PropTypes.func.isRequired,
  fetchOfferData: PropTypes.func.isRequired,
  refreshPerformer: PropTypes.func.isRequired,
  offerPerformer: PropTypes.object,
  setOfferPerformer: PropTypes.func.isRequired,
  acceptOffer: PropTypes.func.isRequired,
  declineOffer: PropTypes.func.isRequired,
  offers: PropTypes.array,
  offerChanged: PropTypes.func.isRequired,
  updateOfferArtist: PropTypes.func.isRequired,
  refreshConfirmOffers: PropTypes.func.isRequired,
  deleteOffer: PropTypes.func.isRequired,
  venueSelected: PropTypes.object,
  performers: PropTypes.array,
  confirmPageSection: PropTypes.object,
  fetchPermissionSet: PropTypes.func.isRequired,
  updateConfirmPageSection: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array,
  possibleMatchingOffers: PropTypes.array,
  dismissMatchingOffers: PropTypes.func.isRequired,
  acceptMatchingOffer: PropTypes.func.isRequired
};

export default ManageOffers;
