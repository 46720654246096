import React from "react";

import PillContainer from "./PillContainer";
import { useCampaignContext } from "./CampaignContainer";

const campaignNames = {
  CAMPAIGN_TEST: "campaign.test",
  COLLECT_ANNOUNCE_INTEREST: "Collect Announce Interest",
  COLLECT_WAITLIST_INTEREST: "Collect Waitlist Interest at Sellout",
  ABANDON_CART: "Abandon Cart",
  KNOW_BEFORE_YOU_GO: "Know Before You Go",
  POST_EVENT_FOLLOW_UP: "Post-Event Follow-Up",
  NEW_EVENT_ANNOUNCE: "New Event Announce",
  NEW_EVENT_ON_SALE: "New Event On-Sale",
  NEW_EVENT_PRE_SALE: "New Event Pre-Sale",
};

const containerStyle = {
  background: "white",
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  fontSize: 12,
  gap: 10,
  padding: 20,
  height: "100%",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
};

const detailHeaderStyle = {
  color: "#333333",
  opacity: 0.5,
};

const detailVariableStyle = {
  background: "white",
  border: "1px solid #E5E5E5",
  borderRadius: 5,
  color: "#333333",
  fontWeight: 600,
  marginBottom: 15,
  marginTop: 5,
  padding: "7px 11px",
  width: "fit-content",
};

const workflowContainerStyle = {
  display: "flex",
  columnGap: 4,
  flexDirection: "row",
  flexWrap: "wrap",
  textWrap: "wrap",
}

const WorkflowDetails = () => {
  const { campaign } = useCampaignContext();

  const campaignTrigger = () => {
    switch(campaign.campaign_template.name) {
      case campaignNames.CAMPAIGN_TEST:
        return "Test";
      case campaignNames.COLLECT_ANNOUNCE_INTEREST:
        return "User Signup for Announce List";
      case campaignNames.COLLECT_WAITLIST_INTEREST:
        return "User Signup for Waitlist";
      case campaignNames.ABANDON_CART:
        return "Abandon Cart";
      case campaignNames.KNOW_BEFORE_YOU_GO:
        return "User Placed Order";
      case campaignNames.POST_EVENT_FOLLOW_UP:
        return "Post-Event Follow-Up";
      case campaignNames.NEW_EVENT_ANNOUNCE:
        return "New Event Announced";
      case campaignNames.NEW_EVENT_ON_SALE:
        return "New Event On-Sale";
      case campaignNames.NEW_EVENT_PRE_SALE:
        return "New Event Pre-Sale";
      default:
        return null;
    }
  };

  const workflowChildren = () => {
    switch(campaign.campaign_template.name) {
      case campaignNames.CAMPAIGN_TEST:
        return [
          <div>
            TEST
          </div>
        ];
      case campaignNames.COLLECT_ANNOUNCE_INTEREST:
        return [
          <strong>When Sales Start</strong>,
          <PillContainer
            label={"Email 1"}
            background={"#1A82C4"}
            fontSize={10}
            padding={2}
          />,
          <div>will be sent to all fans that signed up for the Announce List.</div>
        ];
      case campaignNames.COLLECT_WAITLIST_INTEREST:
        return [
          <strong>When a Show Sells Out</strong>,
          <div>users will be able to sign up for a waitlist if additional tickets become available.</div>
        ];
      case campaignNames.ABANDON_CART:
        return [
          <div><strong>45 minutes</strong> after a cart is abandoned</div>,
          <PillContainer
            label={"Email 1"}
            background={"#1A82C4"}
            fontSize={10}
            padding={2}
          />,
          <div>will be sent to the fan who abandoned the cart.</div>,
        ];
      case campaignNames.KNOW_BEFORE_YOU_GO:
        return [
          <strong>3 days before an event</strong>,
          <PillContainer
            label={"Email 1"}
            background={"#1A82C4"}
            fontSize={10}
            padding={2}
          />,
          <div>will be sent to all fans in that have purchased a ticket to the event.</div>,
        ];
      case campaignNames.POST_EVENT_FOLLOW_UP:
        return [
          <strong>2 days after an event</strong>,
          <PillContainer
            label={"Email 1"}
            background={"#1A82C4"}
            fontSize={10}
            padding={2}
          />,
          <div>will be sent to all fans in that attended the event.</div>,
        ];
      case campaignNames.NEW_EVENT_ANNOUNCE:
        return [
          <strong>When an event is announced</strong>,
          <PillContainer
            label={"Email 1"}
            background={"#1A82C4"}
            fontSize={10}
            padding={2}
          />,
          <div>will be sent to all fans in the <strong>segment</strong> of potentially interested fans.</div>,
        ];
      case campaignNames.NEW_EVENT_ON_SALE:
        return [
          <strong>When Sales Start</strong>,
          <PillContainer
            label={"Email 1"}
            background={"#1A82C4"}
            fontSize={10}
            padding={2}
          />,
          <div>will be sent to all fans in the <strong>segment</strong> of potentially interested fans.</div>,
        ];
      case campaignNames.NEW_EVENT_PRE_SALE:
        return [
          <strong>When Pre-Sales Start</strong>,
          <PillContainer
            label={"Email 1"}
            background={"#1A82C4"}
            fontSize={10}
            padding={2}
          />,
          <div>will be sent to all fans in the <strong>segment</strong> of potentially interested fans.</div>,
        ];
      default:
        return null;
      }
  };

  return (
    <div style={containerStyle}>
      <div>
        <div style={detailHeaderStyle}>
          Trigger
        </div>
        <div>
          This campaign will fire <strong>every time</strong> the following event occurs:
        </div>
        <div style={detailVariableStyle}>
          {campaignTrigger()}
        </div>
      </div>
      <div>
        <div style={detailHeaderStyle}>
          Workflow
        </div>
        <div style={workflowContainerStyle}>
          {workflowChildren().map((item, index) => (
            <div key={index}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkflowDetails;
