// Simple example of a React "smart" component

import { connect } from 'react-redux';
import GlobalAssetCenter from '../components/GlobalAssetCenter';
import * as actions from '../actions/globalAssetCenterActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  csrfToken: state.csrfToken,
  currentUser: state.currentUser,
  team: state.team,
  folder: state.folder,
  guestTeamMemberships: state.guestTeamMemberships,
  promotersWithPermission: state.promotersWithPermission,
  searchTerm: state.searchTerm,
  teamRootFolder: state.teamRootFolder
});

const mapDispatchToProps = (dispatch) => {
  return {
    folderChanged: (folder) => dispatch(actions.folderChanged(folder)),
    searchTermChanged: (searchTerm) => dispatch(actions.searchTermChanged(searchTerm))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(GlobalAssetCenter);
