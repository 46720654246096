// Simple example of a React "smart" component

import { connect } from 'react-redux';
import FollowUpModal from '../components/FollowUpModal';
import * as actions from '../actions/followUpModalActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  followUp: state.followUp,
  team: state.team,
  dataTableEl: state.dataTableEl,
  csrfToken: state.csrfToken,
  canChangeArtist: state.canChangeArtist,
  onClose: state.onClose,
  onCreate: state.onCreate
});

const mapDispatchToProps = (dispatch) => {
  return {
    followUpModalClosed: (onClose) => dispatch(actions.followUpModalClosed(dispatch, onClose)),
    followUpArtistChanged: (artist) => dispatch(actions.followUpArtistChanged(artist)),
    followUpRemindAtChanged: (date) => dispatch(actions.followUpRemindAtChanged(date)),
    followUpNoteChanged: (note) => dispatch(actions.followUpNoteChanged(note)),
    submitFollowUpForm: (team, followUp, csrfToken, dataTableEl, onClose, onCreate) => dispatch(actions.submitFollowUpForm(dispatch, team, followUp, csrfToken, dataTableEl, onClose, onCreate))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(FollowUpModal);
