import React from "react";
import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";

const containerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 15,
};

const rowStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 5,
  alignItems: "center",
};

const columnStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 5,
};

const labelStyle = {
  fontSize: 14,
  color: "#333333",
  fontWeight: 700,
  marginBottom: 8,
};

const fontCopyStyle = {
  fontSize: 14,
  color: "#333333",
  opacity: 0.5,
};

const inputStyle = {
  width: "40px",
  height: "40px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  backgroundColor: "white",
};

const ColorSelect = () => {
  const { formState, setFormState } = useMarketingSettingsContext();

  return (
    <div>
      <div style={labelStyle}>Colors</div>
      <div style={containerStyle}>
        <div style={columnStyle}>
          <div style={fontCopyStyle}>Primary</div>
          <div style={rowStyle}>
            <input
              type="color"
              value={formState.primary_color_hex || "#000000"}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  primary_color_hex: e.target.value,
                }));
              }}
              style={inputStyle}
            />
            <div style={fontCopyStyle}>{formState.primary_color_hex || "#000000"}</div>
          </div>
        </div>
        <div style={columnStyle}>
          <div style={fontCopyStyle}>Secondary</div>
          <div style={rowStyle}>
            <input
              type="color"
              value={formState.secondary_color_hex || "#000000"}
              onChange={(e) => {
                setFormState((prev) => ({
                  ...prev,
                  secondary_color_hex: e.target.value,
                }));
              }}
              style={inputStyle}
            />
            <div style={fontCopyStyle}>{formState.secondary_color_hex || "#000000"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorSelect;