export class GlobalCurrency {
  static LOCALES = {
    "USD": "en-US",
    "EUR": "fr-FR",
    "JPY": "ja-JP",
    "GBP": "en-GB",
    "AUD": "en-AU",
    "CAD": "en-CA",
    "CHF": "de-CH",
    "CNY": "zh-CN"
  }

  constructor(rate, symbol) {
    this.rate = rate;
    this.symbol = symbol;
  }

  convert(amount) {
    return (amount * this.rate);
  }

  revert(amount) {
    return (amount / this.rate);
  }

  formatted(amount) {
    let converted = this.convert(amount);
    let locale = GlobalCurrency.LOCALES[this.symbol];
    let formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: this.symbol,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return formatter.format(converted);
  }
}
