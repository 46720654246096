import { combineReducers } from 'redux';

import {
  PIPELINE_SLIDING_PANE_PIPELINE_CHANGED,
  FOLLOW_UP_CHANGED,
  CREATE_CALENDAR_EVENT_FROM_PIPELINE,
  CLOSE_CALENDAR_EVENT_MODAL,
  OFFER_CHANGED,
  SUBMITTING_FORM_CHANGED
} from '../constants/pipelineSlidingPaneConstants';

const pipeline = (state = {}, action) => {
  switch (action.type) {
    case PIPELINE_SLIDING_PANE_PIPELINE_CHANGED:
      return action.pipeline;
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const followUp = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_CHANGED:
      return action.followUp;
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const calendarEventArtists = (state = [], action) => {
  switch (action.type) {
    case CREATE_CALENDAR_EVENT_FROM_PIPELINE:
      return [action.pipeline.artist];
    case CLOSE_CALENDAR_EVENT_MODAL:
      return [];
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CALENDAR_EVENT_FROM_PIPELINE:
      return Object.assign({}, state, {
        pipeline: action.pipeline
      });
    case CLOSE_CALENDAR_EVENT_MODAL:
      return {};
    default:
      return state;
  }
};

const holdGroup = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CALENDAR_EVENT_FROM_PIPELINE:
      return Object.assign({}, state, {
        pipeline: action.pipeline
      });
    case CLOSE_CALENDAR_EVENT_MODAL:
      return {};
    default:
      return state;
  }
};

const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueSelected = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offer = (state = {}, action) => {
  switch (action.type) {
    case OFFER_CHANGED:
      return action.offer;
    default:
      return state;
  }
};

const defaultOffer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerTemplates = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const submittingForm = (state = false, action) => {
  switch (action.type) {
    case SUBMITTING_FORM_CHANGED:
      return action.submittingForm;
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pipelineSlidingPaneReducer = combineReducers({
  pipeline,
  currentUser,
  team,
  csrfToken,
  followUp,
  dataTableEl,
  calendarEventArtists,
  confirm,
  holdGroup,
  venuesOptions,
  venueSelected,
  offer,
  defaultOffer,
  offerTemplates,
  onClose,
  submittingForm,
  venueOwnerships
});

export default pipelineSlidingPaneReducer;
