import PropTypes from 'prop-types';
import React from 'react';

const axios = require('axios').default;
import { toast } from 'react-toastify';
import Popup from "reactjs-popup";
import RichEditor from '../../RichEditor/components/RichEditor';
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import Select from 'react-select'

const modalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "1140px",
  "padding": "15px"
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "175px",
  "padding": "0px"
}

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const createNoteTemplate = (csrfToken, team, openNote, successCallback) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  axios.post(`/teams/${team.id}/note_templates`, {
    note_template: {
      title: openNote.title,
      body: openNote.body || ""
    }
  })
    .then(response => {
      successCallback(response.data);
    })
    .catch((error) => {
      toast.error(error.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
};

const updateNoteTemplate = (csrfToken, team, openNote, successCallback) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  axios.patch(`/teams/${team.id}/note_templates/${openNote.note_template_id}`, {
    note_template: {
      title: openNote.title,
      body: openNote.body || ""
    }
  })
    .then(response => {
      successCallback(response.data);
    })
    .catch((error) => {
      toast.error(error.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
};

const deleteNoteTemplate = (csrfToken, team, noteTemplate, successCallback) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  axios.delete(`/teams/${team.id}/note_templates/${noteTemplate.id}`)
    .then((data) => {
      successCallback(data);
    })
    .catch((error) => {
      toast.error(error.response.data.join(", "), {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
};

const formatOptionLabel = ({ value, label }, {context}, onClick) => (
  <div className="d-flex justify-content-between budget-template-option">
    <p className="mb-0 text-overflow-ellipsis"
       title={label}>
      {label}
    </p>
    {context === "menu" ? (
      <a href="#"
         onClick={
           (e) => {
             e.preventDefault();
             e.stopPropagation();

             if(window.confirm("Are you sure? Delete \"" + label + "\"?")){
               onClick();
             }
           }
         }
         className="text-white">
        <i className="fas fa-trash"></i>
      </a>
    ) : null}
  </div>
);

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "#F9F9F9",
    zIndex: "999",
    width: "155px",
    marginBottom: "-10px"
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#1982C4'
    }
  },
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "#F9F9F9",
      border: "none",
      boxShadow: 'none'
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#F9F9F9",
      fontWeight: "bold"
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      fontWeight: "bold",
      color: "#1982C4"
    };
  },
  indicatorSeparator: (styles, { data }) => {
    return {
      ...styles,
      display: "none"
    };
  },
  indicatorsContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#F9F9F9",
      color: "#1982C4",
      fontWeight: "bold",
      fontSize: "16px",
      ':hover': {
        cursor: "pointer"
      }
    };
  },
  menu: (provided, state) => ({
    ...provided,
    width: "230px"
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'white' : '#333333',
    background: state.isFocused ? '#1982C4' : 'white',
    fontWeight: state.isFocused ? 'bold' : 'normal',
    padding: "0px",
    "&:hover": {
      color: "white",
      background: "#1982C4",
      fontWeight: "bold"
    }
  }),
  dropdownIndicator: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#F9F9F9",
      color: "#1982C4",
      fontWeight: "bold",
      "&:hover": {
        color: "#1982C4"
      }
    };
  }
};

const canSubmitForm = (note, original, isLoading) => {
  if(isLoading){
    return false;
  }

  var noteBody;
  if(note.editorState){
    noteBody = stateToHTML(note.editorState.getCurrentContent());
  }

  var originalNoteBody;
  if(original.editorState){
    originalNoteBody = stateToHTML(original.editorState.getCurrentContent());
  }

  return (
    note
      && note.title
      && note.title.length > 0
      && (
        !note.id
          || note.title !== original.title
          || noteBody !== originalNoteBody
          || note.note_template_id != original.note_template_id
      )
  );
}

const canSubmitTemplate = (note) => {
  return (
    note
      && note.title
      && note.title.length > 0
  );
}

const ManageNotes = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  openNote,
  openNoteChanged,
  fetchNewNote,
  closeOpenNote,
  openNoteTitleChanged,
  openNoteBodyChanged,
  createNote,
  notes,
  noteTemplates,
  noteTemplatesChanged,
  deleteNote,
  fetchNoteToEdit,
  updateNote,
  openNoteOriginal,
  openNoteLoading,
  fetchNoteToPreview,
  disableOpenNotePreviewMode,
  fetchPermissionSet,
  noteUpdated
}) => (
  <div>
    <div className="card-body">
      <div className="row pb-3">
        <div className="col">
          <h5 className="strong">Notes</h5>
        </div>
        {currentUser.can_manage_notes && notes.length > 0 ? (
          <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
            <a href="#"
               onClick={
                 (e) => {
                   e.preventDefault();
                   fetchNewNote(csrfToken, team);
                 }
               }
               className="btn btn-external btn-sm">
              <strong>New Note</strong>
            </a>
          </div>
        ) : null }
      </div>
      {notes.length === 0 ? (
        <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 order-md-2 text-left text-md-center">
                <i className="fal fa-file-alt"
                   style={{"fontSize": "120px", "color": "rgba(25, 130, 196, .5)"}}></i>
              </div>
              <div className="col-12 col-md-6 col-lg-8 order-md-1">
                <h5>Add a Note</h5>
                <p className="text-muted">
                  Notes are a place for you to keep track of event details, to-do lists, and internal communications.
                </p>
                {currentUser.can_manage_notes ? (
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         fetchNewNote(csrfToken, team);
                       }
                     }
                     className="btn btn-primary">
                    <strong>New Note</strong>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          {notes.map((note, index) => (
            <div className="col-12 col-md-6 pb-3" key={index}>
              <div onClick={
                    (e) => {
                      e.preventDefault();
                      fetchNoteToPreview(csrfToken, team, note.id);
                    }
                  }
                  className="card rounded-0 h-100 note-section-card">
                <div className="card-body" style={{"padding": "15px 10px"}}>
                  <div className="row">
                    <div className="col">
                      <strong className="mb-2 d-inline-block">
                        {note.title}
                        {note.permission_set_id ? (
                          <i className="ml-2 far fa-lock small text-danger"></i>
                        ) : null}
                      </strong>
                    </div>
                    <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
                      <Popup arrow={false}
                             offsetY={5}
                             position="bottom right"
                             contentStyle={popupContentStyle}
                             onOpen={
                               (e) => {
                                 e.stopPropagation();
                                 e.preventDefault();
                               }
                             }
                             trigger={open => (
                               <a href="#"
                                  className="btn btn-external btn-sm note-card-inline-menu">
                                 <i className="far fa-ellipsis-v"></i>
                               </a>
                             )} >
                        {close => (
                          <div className='row text-left'>
                            <div className="col-12">
                              <ul className="list-group offer-inline-menu">
                                {currentUser.can_manage_notes ? (
                                  <li className="list-group-item">
                                    <a href="#"
                                       onClick={
                                         (e) => {
                                           e.preventDefault();
                                           close();
                                           fetchNoteToEdit(csrfToken, team, note.id);
                                         }
                                       }
                                       className="text-muted">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="fas fa-pencil"></i>
                                      </span>
                                      <span className="text-dark">
                                        Edit
                                      </span>
                                    </a>
                                  </li>
                                ) : null}
                                <li className="list-group-item">
                                  <a href="#"
                                     onClick={
                                       (e) => {
                                         e.preventDefault();
                                         close();
                                         fetchNoteToPreview(csrfToken, team, note.id);
                                       }
                                     }
                                     className="text-muted">
                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                      <i className="fas fa-search"></i>
                                    </span>
                                    <span className="text-dark">
                                      Preview
                                    </span>
                                  </a>
                                </li>
                                {currentUser.role.name === "Administrator" ? (
                                  <li className="list-group-item">
                                    <a href="#"
                                       onClick={
                                         (e) => {
                                           e.preventDefault();
                                           close();

                                           var permissionSetToFetch;
                                           if(note.permission_set_id){
                                             permissionSetToFetch = {
                                               id: note.permission_set_id
                                             };
                                           } else {
                                             permissionSetToFetch = {
                                               element_id: note.id,
                                               element_type: "Note"
                                             };
                                           }

                                           fetchPermissionSet(team, permissionSetToFetch, (permissionSet) => {
                                             var updated = Object.assign({}, note, {permission_set_id: permissionSet.id});
                                             noteUpdated(updated);
                                           });
                                         }
                                       }
                                       className="text-muted">
                                      <span className="d-inline-block text-center" style={{width: "28px"}}>
                                        <i className="far fa-user-lock"></i>
                                      </span>
                                      <span className="text-dark">
                                        Set Permissions
                                      </span>
                                    </a>
                                  </li>
                                ) : null}
                                {currentUser.can_manage_notes ? (
                                  <li className="list-group-item delete-offer-li">
                                    <Popup
                                        trigger={
                                          <a href="#" className="text-danger">
                                            <span className="d-inline-block text-center" style={{width: "28px"}}>
                                              <i className="fas fa-trash"></i>
                                            </span>
                                            Delete
                                          </a>
                                        }
                                        modal
                                        onOpen={
                                          (e) => {
                                            e.preventDefault();
                                          }
                                        }
                                        contentStyle={deleteModalContentStyle}
                                        closeOnDocumentClick
                                      >
                                      {closeDeletePopup => (
                                        <div>
                                          <div className="row">
                                            <div className="col">
                                              <div className="row">
                                                <div className="col">
                                                  <h5 style={{"marginBottom": "16px"}}>
                                                    <strong>Delete note?</strong>
                                                  </h5>
                                                </div>
                                                <div className="col-xs-auto">
                                                  <a href="#"
                                                     className="text-dark"
                                                     style={{"paddingRight": "15px"}}
                                                     onClick={(e) => {e.preventDefault(); close();}} >
                                                    <i className="fa fa-times"></i>
                                                  </a>
                                                </div>
                                              </div>
                                              <p>Are you sure you want to remove this note?</p>
                                              <div className="text-right">
                                                <a href="#"
                                                   onClick={
                                                     (e) => {
                                                       e.preventDefault();
                                                       close();
                                                     }
                                                   }>
                                                  Cancel
                                                </a>
                                                <a href="#"
                                                   className="btn btn-danger ml-3"
                                                   onClick={
                                                     (e) => {
                                                       e.preventDefault();
                                                       close();
                                                       closeDeletePopup();
                                                       deleteNote(csrfToken, team, note);
                                                     }
                                                   }>
                                                  Remove
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </div>
                  </div>
                  <div className="text-muted note-body"
                       dangerouslySetInnerHTML={{__html: note.sanitized_body}}>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
    <Popup
      open={(Object.keys(openNote).length > 0)}
      contentStyle={modalContentStyle}
      closeOnDocumentClick={false}
      className={"popup-modal"}
      onClose={() => closeOpenNote()}
    >
      <div className="popup-modal-container">
        <form onSubmit={
          (e) => {
            e.preventDefault();

            if(canSubmitForm(openNote, openNoteOriginal, openNoteLoading)){
              if(openNote.id){
                updateNote(csrfToken, team, openNote);
              } else {
                createNote(csrfToken, team, confirm, openNote);
              }
            }
          }
        }>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6 small">
                  {currentUser.can_manage_note_templates && !openNote.previewMode ? (
                    <div className="col-xs-auto d-flex align-items-center">
                      {noteTemplates.length > 0 ? (
                        <Select
                          styles={selectStyles}
                          className="mr-4"
                          placeholder={"Select a template"}
                          components={{ ClearIndicator:() => null }}
                          options={noteTemplates.map(template => ({ value: template.id, label: template.title }))}
                          value={noteTemplates
                            .map(template => ({ value: template.id, label: template.title }))
                            .find(template => (template.value === openNote.note_template_id))
                          }
                          formatOptionLabel={
                            (data, meta) => {
                              const noteTemplate = noteTemplates.find(template => (template.id === data.value));

                              return formatOptionLabel(data, meta, () => {
                                deleteNoteTemplate(csrfToken, team, noteTemplate, (data) => {
                                  openNoteChanged({
                                    ...openNote,
                                    note_template_id: null
                                  });

                                  noteTemplatesChanged([
                                    ...noteTemplates.filter(template => (template.id !== noteTemplate.id))
                                  ]);

                                  toast.success("Note template deleted", {
                                    position: toast.POSITION.TOP_CENTER,
                                    draggable: false,
                                    closeOnClick: true,
                                    autoClose: 5000,
                                    hideProgressBar: true
                                  });
                                });
                              });
                            }
                          }
                          onChange={selected => {
                            const template = noteTemplates.find(template => (template.id === selected.value));

                            if(template){
                              openNoteChanged({
                                ...openNote,
                                note_template_id: template.id,
                                title: template.title,
                                body: template.body,
                                editorState: EditorState.createWithContent(stateFromHTML(template.body) || "")
                              });
                            }
                          }}
                        />
                      ) : null}
                      {openNote.note_template_id ? (
                        <button
                          className="mt-2 mr-2 btn btn-sm btn-external small"
                          disabled={!canSubmitTemplate(openNote)}
                          onClick={e => {
                            e.preventDefault();

                            updateNoteTemplate(csrfToken, team, openNote, (data) => {
                              noteTemplatesChanged(
                                noteTemplates.map(template => {
                                  if(template.id === data.id){
                                    return data;
                                  } else {
                                    return template;
                                  }
                                }).sort((a, b) => {
                                  return a.title.localeCompare(b.title);
                                })
                              );

                              toast.success("Note template updated", {
                                position: toast.POSITION.TOP_CENTER,
                                draggable: false,
                                closeOnClick: true,
                                autoClose: 5000,
                                hideProgressBar: true
                              });
                            });
                          }}
                        >
                          Update Template
                        </button>
                      ) : null}
                      <button
                        className="mt-2 btn btn-sm btn-external small"
                        disabled={!canSubmitTemplate(openNote)}
                        onClick={e => {
                          e.preventDefault();

                          createNoteTemplate(csrfToken, team, openNote, (data) => {
                            noteTemplatesChanged(
                              [
                                ...noteTemplates,
                                data
                              ].sort((a, b) => {
                                return a.title.localeCompare(b.title);
                              })
                            );

                            openNoteChanged({
                              ...openNote,
                              note_template_id: data.id
                            });

                            toast.success("Note template created", {
                              position: toast.POSITION.TOP_CENTER,
                              draggable: false,
                              closeOnClick: true,
                              autoClose: 5000,
                              hideProgressBar: true
                            });
                          });
                        }}
                      >
                        Save New Template
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="col-6 text-right">
                  {openNoteLoading ? (
                    <img className="img-fluid" src="/uploading-loading.gif" style={{width: "16px"}} />
                  ) : null}
                  {!openNote.previewMode ? (
                    <button type="submit"
                            disabled={!canSubmitForm(openNote, openNoteOriginal, openNoteLoading)}
                            className="btn btn-primary ml-2">
                       Save
                    </button>
                  ) : null}
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         closeOpenNote();
                       }
                     }
                     className="text-muted ml-4">
                    <i className="fal fa-times"></i>
                  </a>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-12">
                  <div className="form-group mb-0">
                    <input type="text"
                           autoFocus={true}
                           disabled={openNote.previewMode}
                           style={{'fontSize': "24px", 'color': "#333333"}}
                           className="form-control no-border strong shadow-none pl-0 pb-0"
                           value={openNote.title || ""}
                           onChange={
                             (e) => {
                               openNoteTitleChanged(e.target.value);
                             }
                           }
                           placeholder="Untitled" />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <table className="table table-borderless table-sm mb-0" style={{"fontSize": "10px", tableLayout: "fixed"}}>
                    <tbody>
                      <tr>
                        <td>
                          <span className="text-muted">
                            Date Created
                          </span>
                        </td>
                        <td>
                          {openNote.human_created_at}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-muted">
                            Last Updated
                          </span>
                        </td>
                        <td>
                          {openNote.human_updated_at}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-muted">
                            Created By
                          </span>
                        </td>
                        <td>
                          {openNote.team_membership ?
                            (openNote.team_membership.name)
                          : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-12 pt-3">
                  {openNote.previewMode ? (
                    <div className="card mb-0">
                      <div className="card-body">
                        {currentUser.can_manage_notes ? (
                          <a href="#"
                             style={
                               {
                                 'position': 'absolute',
                                 'right': '5px',
                                 'top': '5px',
                                 'borderRadius': '8px'
                               }
                             }
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 disableOpenNotePreviewMode();
                               }
                             }
                             className="btn btn-external btn-sm">
                            <i className="fas fa-pencil"></i>
                          </a>
                        ) : null}
                        <div style={{'minHeight': '320px'}}
                             dangerouslySetInnerHTML={{__html: openNote.sanitized_body}}>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <RichEditor
                      editorState={openNote.editorState}
                      editorStyle={{"minHeight": "320px"}}
                      onEditorStateChange={
                        (editorState) => {
                          const noteBody = stateToHTML(editorState.getCurrentContent());

                          const updated = {
                            ...openNote,
                            body: noteBody,
                            editorState: editorState
                          };

                          openNoteChanged(updated);
                        }
                      } />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Popup>
  </div>
);

ManageNotes.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  openNote: PropTypes.object,
  openNoteChanged: PropTypes.func.isRequired,
  fetchNewNote: PropTypes.func.isRequired,
  closeOpenNote: PropTypes.func.isRequired,
  openNoteTitleChanged: PropTypes.func.isRequired,
  openNoteBodyChanged: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  notes: PropTypes.array,
  noteTemplates: PropTypes.array,
  noteTemplatesChanged: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  fetchNoteToEdit: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  openNoteOriginal: PropTypes.object,
  openNoteLoading: PropTypes.bool,
  fetchNoteToPreview: PropTypes.func.isRequired,
  disableOpenNotePreviewMode: PropTypes.func.isRequired,
  fetchPermissionSet: PropTypes.func.isRequired,
  noteUpdated: PropTypes.func.isRequired
};

export default ManageNotes;
