import PropTypes from 'prop-types';
import React from 'react';

import NewInvitationForm from './NewInvitationForm';
import TeamManageableInlineForm from './TeamManageableInlineForm';
import TeamManageableTableRow from './TeamManageableTableRow';
import { ToastContainer } from 'react-toastify';

const TeamManager = ({
  venueOwnerships,
  teamMemberships,
  pendingInvitations,
  addingNewInvitation,
  addTeamMemberClicked,
  newInvitation,
  cancelNewInvitation,
  newInvitationFirstNameChanged,
  newInvitationLastNameChanged,
  newInvitationEmailChanged,
  roles,
  newInvitationRoleIdChanged,
  newInvitationVenueOwnershipClicked,
  csrfToken,
  createInvitation,
  team,
  newInvitationEnableAllVenues,
  newInvitationEnableAllVenuesClicked,
  newInvitationDisableAllVenuesClicked,
  teamManageableToEdit,
  editTeamManageable,
  cancelEditTeamManageable,
  deleteTeamManageable,
  teamManageableToEditUpdateFirstName,
  teamManageableToEditUpdateLastName,
  teamManageableToEditUpdateEmail,
  teamManageableToEditUpdateRoleId,
  teamManageableToEditVenueOwnershipClicked,
  teamManageableToEditEnableAllVenues,
  teamManageableToEditEnableAllVenuesClicked,
  teamManageableToEditDisableAllVenuesClicked,
  submitUpdateTeamManageable,
  resetPasswordForEmail,
  newInvitationCompanyChanged,
  teamManageableToEditCompanyChanged
}) => (
  <div className="row">
    <ToastContainer />
    <div className="col-12">
      <div className="card-body pb-0">
        <div className="row">
          <div className="col">
            <h5><strong>Team Management</strong></h5>
            <p className="text-muted">Add and remove users to your team.</p>
          </div>
          {!addingNewInvitation ? (
            <div className="col-xs-auto px-3">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     addTeamMemberClicked();
                   }
                 }
                 className="btn btn-primary">
                Add Team Member
              </a>
            </div>
          ) : null}
        </div>
      </div>
      {addingNewInvitation ? (
        <NewInvitationForm invitation={newInvitation}
                           cancel={cancelNewInvitation}
                           roles={roles}
                           team={team}
                           newInvitationEnableAllVenues={newInvitationEnableAllVenues}
                           venueOwnerships={venueOwnerships}
                           createInvitation={createInvitation}
                           csrfToken={csrfToken}
                           newInvitationLastNameChanged={newInvitationLastNameChanged}
                           newInvitationEmailChanged={newInvitationEmailChanged}
                           newInvitationRoleIdChanged={newInvitationRoleIdChanged}
                           newInvitationVenueOwnershipClicked={newInvitationVenueOwnershipClicked}
                           newInvitationEnableAllVenuesClicked={newInvitationEnableAllVenuesClicked}
                           newInvitationDisableAllVenuesClicked={newInvitationDisableAllVenuesClicked}
                           newInvitationCompanyChanged={newInvitationCompanyChanged}
                           newInvitationFirstNameChanged={newInvitationFirstNameChanged} />
      ) : null}
      <div className="row">
        <div className="col">
          <table className="table vertical-middle" style={{"width": "100%"}}>
            <colgroup>
              <col span={1} style={{"width": "30%"}} />
              <col span={1} style={{"width": "20%"}} />
              <col span={1} style={{"width": "20%"}} />
              <col span={1} style={{"width": "30%"}} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" style={{"paddingLeft": "20px"}}>Name</th>
                <th scope="col">Role</th>
                <th scope="col">Venue Access</th>
                <th scope="col" style={{"paddingRight": "20px"}}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {(teamMemberships.concat(pendingInvitations)).sort((a, b) => a.name.localeCompare(b.name)).map((teamManageable, index) => (
                teamManageable.currentlyEditing ? (
                  <TeamManageableInlineForm teamManageable={teamManageableToEdit}
                                            originalTeamManageable={teamManageable}
                                            cancelEditTeamManageable={cancelEditTeamManageable}
                                            roles={roles}
                                            venueOwnerships={venueOwnerships}
                                            teamManageableToEditUpdateFirstName={teamManageableToEditUpdateFirstName}
                                            teamManageableToEditUpdateLastName={teamManageableToEditUpdateLastName}
                                            teamManageableToEditUpdateEmail={teamManageableToEditUpdateEmail}
                                            teamManageableToEditUpdateRoleId={teamManageableToEditUpdateRoleId}
                                            teamManageableToEditVenueOwnershipClicked={teamManageableToEditVenueOwnershipClicked}
                                            teamManageableToEditEnableAllVenues={teamManageableToEditEnableAllVenues}
                                            teamManageableToEditEnableAllVenuesClicked={teamManageableToEditEnableAllVenuesClicked}
                                            teamManageableToEditCompanyChanged={teamManageableToEditCompanyChanged}
                                            teamManageableToEditDisableAllVenuesClicked={teamManageableToEditDisableAllVenuesClicked}
                                            submitUpdateTeamManageable={submitUpdateTeamManageable}
                                            csrfToken={csrfToken}
                                            team={team}
                                            key={index} />
                ) : (
                  <TeamManageableTableRow teamManageable={teamManageable}
                                          editTeamManageable={editTeamManageable}
                                          deleteTeamManageable={deleteTeamManageable}
                                          resetPasswordForEmail={resetPasswordForEmail}
                                          csrfToken={csrfToken}
                                          team={team}
                                          key={index} />
                )
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

TeamManager.propTypes = {
  venueOwnerships: PropTypes.array.isRequired,
  teamMemberships: PropTypes.array,
  pendingInvitations: PropTypes.array,
  addingNewInvitation: PropTypes.bool,
  addTeamMemberClicked: PropTypes.func.isRequired,
  newInvitation: PropTypes.object,
  cancelNewInvitation: PropTypes.func.isRequired,
  newInvitationFirstNameChanged: PropTypes.func.isRequired,
  newInvitationLastNameChanged: PropTypes.func.isRequired,
  newInvitationEmailChanged: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  newInvitationRoleIdChanged: PropTypes.func.isRequired,
  newInvitationVenueOwnershipClicked: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  createInvitation: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  newInvitationEnableAllVenues: PropTypes.bool,
  newInvitationEnableAllVenuesClicked: PropTypes.func.isRequired,
  newInvitationDisableAllVenuesClicked: PropTypes.func.isRequired,
  teamManageableToEdit: PropTypes.object,
  editTeamManageable: PropTypes.func.isRequired,
  cancelEditTeamManageable: PropTypes.func.isRequired,
  deleteTeamManageable: PropTypes.func.isRequired,
  teamManageableToEditUpdateFirstName: PropTypes.func.isRequired,
  teamManageableToEditUpdateLastName: PropTypes.func.isRequired,
  teamManageableToEditUpdateEmail: PropTypes.func.isRequired,
  teamManageableToEditUpdateRoleId: PropTypes.func.isRequired,
  teamManageableToEditVenueOwnershipClicked: PropTypes.func.isRequired,
  teamManageableToEditEnableAllVenues: PropTypes.bool,
  teamManageableToEditEnableAllVenuesClicked: PropTypes.func.isRequired,
  teamManageableToEditDisableAllVenuesClicked: PropTypes.func.isRequired,
  submitUpdateTeamManageable: PropTypes.func.isRequired,
  resetPasswordForEmail: PropTypes.func.isRequired,
  newInvitationCompanyChanged: PropTypes.func.isRequired,
  teamManageableToEditCompanyChanged: PropTypes.func.isRequired
};

export default TeamManager;
