import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Popup from "reactjs-popup";
import NooraButton from './NooraButton';

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "320px",
  "padding": "0px",
  "overflow": "hidden",
  "zIndex": "999"
}

const UserMenu = ({
  csrfToken,
  team,
  currentUser,
  destroyUserSession,
  teamMemberships,
  teamMenuOpen,
  teamMenuOpenChanged,
  switchCurrentTeam
}) => {
  const currentTeamMembership = teamMemberships
    .find(teamMembership => teamMembership.current);

  return (
    <div style={{
            "alignItems": "center",
            "borderLeft": "1px solid #dadada",
            "display": "flex",
            "marginRight": "15px",
          }}>
      <NooraButton csrfToken={csrfToken} />
      <Popup arrow={false}
            offsetY={13}
            position="bottom right"
            contentStyle={popupContentStyle}
            onOpen={
              (e) => {
                e.preventDefault();
              }
            }
            trigger={open => (
              <a href="#"
                  className="text-dark d-flex align-items-center text-decoration-none"
                  onClick={
                    (e) => {
                      e.preventDefault();
                    }
                  }>
                <img src={currentUser.avatar_thumb_url}
                    className="rounded-circle top-right-avatar mr-0" />
                {teamMemberships.length > 1 ? (
                  <strong className='text-dark d-inline-block text-truncate'
                          style={{
                            "marginLeft": "15px",
                            "maxWidth": "150px"
                          }}>
                    {currentTeamMembership.team_name}
                  </strong>
                ) : null}
                <i className="fas fa-caret-down"
                  style={{"marginLeft": "15px"}}></i>
              </a>
            )} >
        {close => (
          <div className="row m-0">
            {teamMemberships.length > 1 ? (
              <div className="col-12"
                    style={{
                      "borderBottom": "1px solid #d8d8d8",
                      "padding": "8px 15px"
                    }}>
                <div className='row position-relative'>
                  <div className='col d-flex'>
                    <div style={{"flexGrow": "1"}}>
                      <p className='text-muted mb-0' style={{"fontSize": "10px"}}>
                        TEAM
                      </p>
                      <p className='text-dark mb-0 text-truncate'
                          style={{"fontSize": "14px", "marginTop": "-2px", "maxWidth": "200px"}}>
                        <strong>{currentTeamMembership.team_name}</strong>
                      </p>
                    </div>
                    <div className='d-flex align-items-center'>
                      <a href="#"
                          onClick={
                            (e) => {
                              e.preventDefault();
                              teamMenuOpenChanged(!teamMenuOpen);
                            }
                          }
                          className='text-dark stretched-link'>
                        {teamMenuOpen ? (
                          <i className="far fa-angle-down"></i>
                        ) : (
                          <i className="far fa-angle-right"></i>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
                {teamMenuOpen ? (
                  <div className='row' style={{"marginTop": "4px", "marginBottom": "2px"}}>
                    <div className='col-12 p-0'
                          style={{"maxHeight": "170px", "overflowY": "auto"}}>
                      <div className="list-group">
                        {teamMemberships.sort((a, b) => a.team_name.localeCompare(b.team_name)).map((teamMembership, index) => (
                          <a key={index}
                              href="#"
                              onClick={
                                (e) => {
                                  e.preventDefault();

                                  if (teamMembership.current) {
                                    return false;
                                  }

                                  switchCurrentTeam(csrfToken, teamMembership.team_id);
                                }
                              }
                              style={{
                                "padding": "5px 15px",
                              }}
                              className={"list-group-item list-group-item-action border-0 rounded-0 text-truncate " + (teamMembership.current ? "text-primary" : "text-dark")}>
                            {teamMembership.current ? (
                              <i className="fas small fa-check fa-fw" style={{"marginRight": "8px"}}></i>
                            ) : (
                              <i className="fas small fa-fw" style={{"marginRight": "8px"}}></i>
                            )}
                            {teamMembership.team_name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="col-12 p-0">
              <div className="list-group" style={{"padding": "5px 0px"}}>
                <a href="/users/edit"
                   style={{"padding": "6px 15px"}}
                   className="list-group-item list-group-item-action border-0 rounded-0 text-dark">
                  <i className="fas fa-user-circle fa-fw" style={{"marginRight": "18px"}}></i>
                  My Profile
                </a>
                {currentUser.admin ? (
                  <a href="/admin"
                      style={{"padding": "6px 15px"}}
                      className="list-group-item list-group-item-action border-0 rounded-0 text-dark">
                    <i className="fas fa-server fa-fw" style={{"marginRight": "18px"}}></i>
                    Admin
                  </a>
                ) : null}
                <a href="#"
                   style={{"padding": "6px 15px"}}
                   onClick={
                      (e) => {
                        e.preventDefault();
                        destroyUserSession(csrfToken);
                      }
                   }
                   className="list-group-item list-group-item-action border-0 rounded-0 text-danger">
                  <i className="fas fa-power-off fa-fw" style={{"marginRight": "18px"}}></i>
                  Log Out
                </a>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  )
};

UserMenu.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  destroyUserSession: PropTypes.func.isRequired,
  teamMemberships: PropTypes.array.isRequired,
  teamMenuOpen: PropTypes.bool,
  teamMenuOpenChanged: PropTypes.func.isRequired,
  switchCurrentTeam: PropTypes.func.isRequired
};

export default UserMenu;
