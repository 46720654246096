/* eslint-disable import/prefer-default-export */

import {
  ADD_TEAM_MEMBER_CLICKED,
  CANCEL_NEW_INVITATION,
  NEW_INVITATION_FIRST_NAME_CHANGED,
  NEW_INVITATION_LAST_NAME_CHANGED,
  NEW_INVITATION_EMAIL_CHANGED,
  NEW_INVITATION_ROLE_ID_CHANGED,
  NEW_INVITATION_COMPANY_CHANGED,
  NEW_INVITATION_VENUE_OWNERSHIP_CLICKED,
  NEW_INVITATION_CREATION_SUCCESS,
  NEW_INVITATION_ERRORS_CHANGED,
  NEW_INVITATION_ENABLE_ALL_VENUES_CLICK,
  NEW_INVITATION_DISABLE_ALL_VENUES_CLICK,
  EDIT_TEAM_MANAGEABLE_CLICKED,
  EDIT_TEAM_MANAGEABLE_CANCELED,
  TEAM_MANAGEABLE_DELETED,
  TEAM_MANAGEABLE_TO_EDIT_FIRST_NAME_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_LAST_NAME_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_EMAIL_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_ROLE_ID_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_VENUE_OWNERSHIP_CLICKED,
  TEAM_MANAGEABLE_TO_EDIT_ENABLE_ALL_VENUES_CLICK,
  TEAM_MANAGEABLE_TO_EDIT_DISABLE_ALL_VENUES_CLICK,
  TEAM_MANAGEABLE_TO_EDIT_UPDATED,
  TEAM_MANAGEABLE_TO_EDIT_ERRORS_CHANGED,
  TEAM_MANAGEABLE_TO_EDIT_COMPANY_CHANGED
} from '../constants/teamManagerConstants';

const axios = require('axios').default;

export const addTeamMemberClicked = () => ({
  type: ADD_TEAM_MEMBER_CLICKED
});

export const cancelNewInvitation = () => ({
  type: CANCEL_NEW_INVITATION
});

export const newInvitationFirstNameChanged = (firstName) => ({
  type: NEW_INVITATION_FIRST_NAME_CHANGED,
  firstName
});

export const newInvitationLastNameChanged = (lastName) => ({
  type: NEW_INVITATION_LAST_NAME_CHANGED,
  lastName
});

export const newInvitationEmailChanged = (email) => ({
  type: NEW_INVITATION_EMAIL_CHANGED,
  email
});

export const newInvitationRoleIdChanged = (roleId) => ({
  type: NEW_INVITATION_ROLE_ID_CHANGED,
  roleId
});

export const newInvitationVenueOwnershipClicked = (venueOwnershipId) => ({
  type: NEW_INVITATION_VENUE_OWNERSHIP_CLICKED,
  venueOwnershipId
});

export const createInvitation = (dispatch, csrfToken, venueOwnerships, invitation, team) => {
  return dispatch => {
    dispatch(newInvitationErrorsChanged([]));
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/admin_invitations", {
        invitation: {
          type: "Invitations::TeamMembership",
          first_name: invitation.firstName,
          last_name: invitation.lastName,
          email: invitation.email,
          role_id: invitation.roleId,
          venue_ownership_ids: invitation.venueOwnershipIds,
          company: invitation.company
        }
      })
      .then(({ data }) => {
        dispatch(newInvitationCreationSuccess(data));
      })
      .catch((error) => {
        dispatch(newInvitationErrorsChanged(error.response.data));
      });
  };
};

export const newInvitationCreationSuccess = (invitation) => ({
  type: NEW_INVITATION_CREATION_SUCCESS,
  invitation
});

export const newInvitationErrorsChanged = (errors) => ({
  type: NEW_INVITATION_ERRORS_CHANGED,
  errors
});

export const newInvitationEnableAllVenuesClicked = (venueOwnerships) => ({
  type: NEW_INVITATION_ENABLE_ALL_VENUES_CLICK,
  venueOwnerships
});

export const newInvitationDisableAllVenuesClicked = () => ({
  type: NEW_INVITATION_DISABLE_ALL_VENUES_CLICK
});

export const editTeamManageable = (teamManageable) => ({
  type: EDIT_TEAM_MANAGEABLE_CLICKED,
  teamManageable
});

export const cancelEditTeamManageable = (teamManageable) => ({
  type: EDIT_TEAM_MANAGEABLE_CANCELED,
  teamManageable
});

export const deleteTeamManageable = (dispatch, csrfToken, team, teamManageable, closeModal) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var deleteUrl;
    if(teamManageable.type_for_ui === "Invitations::TeamMembership"){
      deleteUrl = "/teams/" + team.id + "/admin_invitations/" + teamManageable.id;
    } else {
      deleteUrl = "/teams/" + team.id + "/team_memberships/" + teamManageable.id;
    }

    return axios.delete(deleteUrl)
      .then(({ data }) => {
        dispatch(teamManageableDeleted(data));
        closeModal();
      });
  };
};

export const teamManageableDeleted = (teamManageable) => ({
  type: TEAM_MANAGEABLE_DELETED,
  teamManageable
});

export const teamManageableToEditUpdateFirstName = (firstName) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_FIRST_NAME_CHANGED,
  firstName
});

export const teamManageableToEditUpdateLastName = (lastName) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_LAST_NAME_CHANGED,
  lastName
});

export const teamManageableToEditUpdateEmail = (email) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_EMAIL_CHANGED,
  email
});

export const teamManageableToEditUpdateRoleId = (roleId) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_ROLE_ID_CHANGED,
  roleId
});

export const teamManageableToEditVenueOwnershipClicked = (venueOwnershipId) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_VENUE_OWNERSHIP_CLICKED,
  venueOwnershipId
});

export const teamManageableToEditEnableAllVenuesClicked = (venueOwnerships) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_ENABLE_ALL_VENUES_CLICK,
  venueOwnerships
});

export const teamManageableToEditDisableAllVenuesClicked = () => ({
  type: TEAM_MANAGEABLE_TO_EDIT_DISABLE_ALL_VENUES_CLICK
});

export const submitUpdateTeamManageable = (dispatch, csrfToken, team, teamManageable) => {
  dispatch(teamManageableToEditErrorsChanged([]));

  var updateUrl;
  var params;

  if(teamManageable.type_for_ui === "Invitations::TeamMembership"){
    updateUrl = "/teams/" + team.id + "/admin_invitations/" + teamManageable.id;
    params    = {
      invitation: {
        first_name: teamManageable.first_name,
        last_name: teamManageable.last_name,
        email: teamManageable.email,
        role_id: teamManageable.role_id,
        venue_ownership_ids: teamManageable.venue_ownership_ids,
        company: teamManageable.company
      }
    }
  } else {
    updateUrl = "/teams/" + team.id + "/team_memberships/" + teamManageable.id;
    params    = {
      team_membership: {
        first_name: teamManageable.first_name,
        last_name: teamManageable.last_name,
        email: teamManageable.email,
        role_id: teamManageable.role_id,
        venue_ownership_ids: teamManageable.venue_ownership_ids,
        company: teamManageable.company
      }
    }
  }

  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch(updateUrl, params)
      .then(({ data }) => {
        dispatch(teamManageableToEditUpdated(data));
      })
      .catch((error) => {
        dispatch(teamManageableToEditErrorsChanged(error.response.data));
      });
  };
};

export const teamManageableToEditUpdated = (teamManageable) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_UPDATED,
  teamManageable
});

export const teamManageableToEditErrorsChanged = (errors) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_ERRORS_CHANGED,
  errors
});

export const resetPasswordForEmail = (dispatch, csrfToken, teamMembership, team) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/team_memberships/" + teamMembership.id + "/reset_password")
      .then(({ data }) => {
        // Nothing...
      })
  };
};

export const newInvitationCompanyChanged = (company) => ({
  type: NEW_INVITATION_COMPANY_CHANGED,
  company
});

export const teamManageableToEditCompanyChanged = (company) => ({
  type: TEAM_MANAGEABLE_TO_EDIT_COMPANY_CHANGED,
  company
});
