import React from "react";

import CampaignsTab from "./CampaignsTab";
import { PROMOTION_MENU_ITEM } from "../constants/confirmTicketingConstants";

const headerStyle = {
  fontSize: "22px",
  fontWeight: "strong"
};

const footerStyle = {
  width: "100%"
};

const buttonContainerStyle = {
  height: "130px"
};

const Automations = ({
  csrfToken,
  team,
  confirm,
  activeMenuItemChanged,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <h3 style={headerStyle}>
          <strong>
            Automations
          </strong>
        </h3>
        <CampaignsTab
          csrfToken={csrfToken}
          team={team}
          confirm={confirm}
        />
      </div>
      <div className="row" style={footerStyle}>
        <div className="col-12 d-flex justify-content-end align-items-end" style={buttonContainerStyle}>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();
                  activeMenuItemChanged(PROMOTION_MENU_ITEM);
                }
              }
              className={"btn btn-primary"}>
            <strong>Next</strong>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Automations;
