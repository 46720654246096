/* eslint-disable import/prefer-default-export */

export const BUYERS_CHANGED = 'BUYERS_CHANGED';
export const PROMOTERS_CHANGED = 'PROMOTERS_CHANGED';
export const CONFIRM_CHANGED = 'CONFIRM_CHANGED';
export const CONFIRM_SAVED = 'CONFIRM_SAVED';
export const EVENT_DETAILS_CHANGED = 'EVENT_DETAILS_CHANGED';
export const HOLD_GROUP_HOLD_POSITION_UPDATED = 'HOLD_GROUP_HOLD_POSITION_UPDATED';
export const HOLD_GROUP_HOLD_REMOVED = 'HOLD_GROUP_HOLD_REMOVED';
export const HOLD_GROUP_CALENDAR_DATE_CLICKED = 'HOLD_GROUP_CALENDAR_DATE_CLICKED';
export const HOLD_GROUP_HOLD_AVAILABILITY_UPDATED = 'HOLD_GROUP_HOLD_AVAILABILITY_UPDATED';
export const PROMOTER_DELETED = 'PROMOTER_DELETED';
