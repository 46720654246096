import React, { useState } from "react";

import { useSegmentEditContext } from "./SegmentEditContainer";
import DataDrivenSegmentForm from "./DataDrivenSegmentForm";

const headerStyle = {
  color: "#666",
  paddingBottom: "10px",
};

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const SegmentEdit = () => {
  const {
    csrfToken,
    team,
    segment,
    setSegment,
  } = useSegmentEditContext();

  const [fanFilterGroupId, setFanFilterGroupId] = useState(segment.fan_filter_group?.id || 0);

  return (
    <div>
      <div style={headerStyle}>
        Segments / {segment.name}
      </div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="rounded border p-4"
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            {segment.type === "Segments::DataDriven" ? (
              <div style={{ marginTop: 10 }}>
                <DataDrivenSegmentForm
                  csrfToken={csrfToken}
                  team={team}
                  segment={segment}
                  setSegment={setSegment}
                  fanFilterGroupId={fanFilterGroupId}
                  setFanFilterGroupId={setFanFilterGroupId}
                />
              </div>
            ) : null}
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "250px" }}
            >
              <a href={`/teams/${team.id}/segments/${segment.id}`} style={backButtonStyle}>
                Back
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SegmentEdit;
