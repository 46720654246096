import React from "react";

import ErrorsContainer from "../../BeefreeTemplates/ErrorsContainer";
import { useActionContext } from "./ActionContainer";
import ActionDropdown from "./ActionDropdown";

const tabMenuStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const tabSelectStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const tabOptionStyle = {
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  padding: "15px 25px",
};

const tabRightStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "15px",
};

const ActionTabHeader = () => {
  const {
    tab,
    setTab,
    isEmailTemplate,
  } = useActionContext();

  return (
    <div style={tabMenuStyle}>
      <div style={tabSelectStyle}>
        <div
          style={{
            ...tabOptionStyle,
            color: tab === "content" ? "#1982C4" : "black",
            borderBottom: tab === "content" ? "2px solid #1982C4" : "none",
          }}
          onClick={() => setTab("content")}
        >
          Content
        </div>
        {!isEmailTemplate ? null : (
          <div
            style={{
              ...tabOptionStyle,
              color: tab === "preview" ? "#1982C4" : "black",
              borderBottom: tab === "preview" ? "2px solid #1982C4" : "none",
            }}
            onClick={() => setTab("preview")}
          >
            Preview
          </div>
        )}
      </div>
      <div style={tabRightStyle}>
        <ErrorsContainer templatableContext={useActionContext} />
        <ActionDropdown />
      </div>
    </div>
  );
};

export default ActionTabHeader;
