import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import TagManagerApp from '../../TagManager/startup/TagManagerApp';

import { ToastContainer, toast } from 'react-toastify';

const ArtistDetails = ({
  csrfToken,
  team,
  currentUser,
  artist,
  artistChanged,
  tagManagerOpen,
  tagManagerOpenChanged,
  tagManagerFilter,
  tagManagerFilterChanged,
  getActiveTags,
  getAvailableTags,
  availableTags,
  availableTagsChanged,
}) => {
  return (
    <React.Fragment>
      <ToastContainer />
      {currentUser.can_read_tagged_items ? (
        <div className="card-body pt-2 pb-0">
          <div className="row">
            <div className="col-12">
              <TagManagerApp
                csrfToken={csrfToken}
                team={team}
                currentUser={currentUser}
                scope="Artist"
                title={`Tags for ${artist.name}`}
                taggable={artist}
              />
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
};

ArtistDetails.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  artist: PropTypes.object.isRequired,
  artistChanged: PropTypes.func.isRequired,
  tagManagerOpen: PropTypes.bool,
  tagManagerOpenChanged: PropTypes.func.isRequired,
  tagManagerFilter: PropTypes.string,
  tagManagerFilterChanged: PropTypes.func.isRequired,
  getActiveTags: PropTypes.func.isRequired,
  getAvailableTags: PropTypes.func.isRequired,
  availableTags: PropTypes.array,
  availableTagsChanged: PropTypes.func.isRequired,
};

export default ArtistDetails;
