import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import PageFrame from './PageFrame';
import ProfitAndLossTable from './ProfitAndLossTable';

const ProfitAndLoss = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  showPageFrame,
  incomeItems,
  incomeTotal,
  incomeEstimateTotal,
  incomeForecastTotal,
  expenseItems,
  expenseTotal,
  expenseEstimateTotal,
  expenseForecastTotal,
  profitLoss,
  profitLossEstimate,
  profitLossForecast,
  ticketsSold,
  ticketsSoldForecast,
}) => {
  return (
    <PageFrame
      csrfToken={csrfToken}
      currentUser={currentUser}
      confirm={confirm}
      showPageFrame={showPageFrame}
      team={team}
    >
      <ProfitAndLossTable
        confirm={confirm}
        incomeItems={incomeItems}
        incomeTotal={incomeTotal}
        incomeEstimateTotal={incomeEstimateTotal}
        incomeForecastTotal={incomeForecastTotal}
        expenseItems={expenseItems}
        expenseTotal={expenseTotal}
        expenseEstimateTotal={expenseEstimateTotal}
        expenseForecastTotal={expenseForecastTotal}
        profitLoss={profitLoss}
        profitLossEstimate={profitLossEstimate}
        profitLossForecast={profitLossForecast}
        ticketsSold={ticketsSold}
        ticketsSoldForecast={ticketsSoldForecast}
        />
    </PageFrame>
  );
};

ProfitAndLoss.propTypes = {
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  showPageFrame: PropTypes.bool.isRequired,
  incomeItems: PropTypes.array.isRequired,
  incomeTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  incomeEstimateTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  incomeForecastTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expenseItems: PropTypes.array.isRequired,
  expenseTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expenseEstimateTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expenseForecastTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  profitLoss: PropTypes.string.isRequired,
  profitLossEstimate: PropTypes.string.isRequired,
  profitLossForecast: PropTypes.string.isRequired,
  ticketsSold: PropTypes.string.isRequired,
  ticketsSoldForecast: PropTypes.string.isRequired,
};

export default ProfitAndLoss;
