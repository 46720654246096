/* eslint-disable import/prefer-default-export */

import {
  CONFIRM_CHANGED,
  DATA_TABLE_EL_CHANGED,
  REFUND_CHANGED,
  ALL_ORDER_IDS_CHANGED,
  SUBMITTING_REFUND_CHANGED,
  CREATING_REFUND_CHANGED,
  BUILDING_REFUND_CHANGED
} from '../constants/bulkRefundConstants';

const axios = require('axios').default;
import { toast } from 'react-toastify';
var _ = require('lodash');

import React, { useEffect } from 'react';
var ReactDOM = require('react-dom');

import $ from 'jquery';
require('datatables.net-bs4');

export const confirmChanged = (confirm) => ({
  type: CONFIRM_CHANGED,
  confirm
});

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const refundChanged = (refund) => ({
  type: REFUND_CHANGED,
  refund
});

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export const renderDataTableRows = (dispatch, dataTableEl, refund, csrfToken, team, confirm, buildBulkRefund, refundChanged) => {
  return dispatch => {
    var table = $(dataTableEl).DataTable();

    table.rows().every( function (rowIdx, tableLoop, rowLoop) {
      var data = this.data();
      var row = table.row(rowIdx).node();
      let order = JSON.parse(decodeHtml(data.json));
      var mountReact = row.getElementsByClassName("bulk-refund-order-mount-react")[0];

      ReactDOM.render((
        <div className="custom-control custom-checkbox custom-checkbox-table">
          <input type="checkbox"
                 className="custom-control-input"
                 checked={refund.order_ids.includes(order.id)}
                 onChange={
                   (e) => {
                     var orderIds = [...refund.order_ids];

                     if (orderIds.includes(order.id)) {
                       orderIds = orderIds.filter((id) => id !== order.id);
                     } else {
                       orderIds.push(order.id);
                     }

                     var updated = Object.assign({}, refund, {
                       order_ids: orderIds
                     });

                     refundChanged(updated);
                     buildBulkRefund(csrfToken, team, confirm, updated)
                   }
                 }
                 id={"refund-order-" + order.id} />
          <label className="custom-control-label"
                 htmlFor={"refund-order-" + order.id}>
            &nbsp;
          </label>
        </div>
      ), mountReact);
    });
  };
};

export const renderDataTableHeader = (dispatch, dataTableEl, allOrderIds, refund, refundChanged, csrfToken, team, confirm, buildBulkRefund) => {
  return dispatch => {
    var mountReact = dataTableEl
      .getElementsByClassName("mount-bulk-refund-order-header-checkbox")[0];
    var allSelected = _.isEmpty(_.xor(allOrderIds, refund.order_ids));

    ReactDOM.render((
      <div className="custom-control custom-checkbox custom-checkbox-table">
        <input type="checkbox"
               className="custom-control-input"
               disabled={allOrderIds.length === 0}
               checked={allSelected}
               onChange={
                 (e) => {
                   var updated;

                   if(allSelected){
                     updated = Object.assign({}, refund, {order_ids: []});
                   } else {
                     updated = Object.assign({}, refund, {order_ids: allOrderIds});
                   }

                   refundChanged(updated);
                   buildBulkRefund(csrfToken, team, confirm, updated)
                 }
               }
               id="bulk-refund-order-select-all" />
        <label className="custom-control-label"
               htmlFor="bulk-refund-order-select-all">
          &nbsp;
        </label>
      </div>
    ), mountReact);
  };
};

export const allOrderIdsChanged = (allOrderIds) => ({
  type: ALL_ORDER_IDS_CHANGED,
  allOrderIds
});

export const fetchAllOrderIds = (dispatch, team, confirm) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/confirms/" + confirm.id + "/bulk_refunds/new.json", {
        params: {
          select_all_ids: true
        }
      })
      .then(({ data }) => {
        dispatch(allOrderIdsChanged(data));
      })
  };
};

export const buildBulkRefund = (dispatch, csrfToken, team, confirm, refund) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(buildingRefundChanged(true));

    return axios.post("/teams/" + team.id + "/confirms/" + confirm.id + "/bulk_refunds/build", {
        refund: {
          order_ids: refund.order_ids
        }
      })
      .then(({ data }) => {
        var updated = Object.assign({}, refund, data);
        dispatch(refundChanged(updated));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      }).then(() => {
        dispatch(buildingRefundChanged(false));
      });
  };
};

export const createBulkRefund = (dispatch, csrfToken, team, confirm, refund) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(creatingRefundChanged(true));

    return axios.post("/teams/" + team.id + "/confirms/" + confirm.id + "/bulk_refunds", {
        refund: {
          order_ids: refund.order_ids
        }
      })
      .then(({ data }) => {
        var updated = Object.assign({}, refund, data);
        dispatch(refundChanged(updated));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .then(function () {
        dispatch(creatingRefundChanged(false));
      });
  };
};

export const confirmRefund = (dispatch, csrfToken, team, confirm, refund) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(submittingRefundChanged(true));

    return axios.post("/teams/" + team.id + "/confirms/" + confirm.id + "/bulk_refunds/" + refund.id + "/confirm", {
        refund: {
          financial_connection_account_id: refund.financial_connection_account_id
        }
      })
      .then(({ data }) => {
        dispatch(refundChanged(data));

        toast.success("Refund in progress...", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .then(function () {
        dispatch(submittingRefundChanged(false));
      });
  };
};

export const submittingRefundChanged = (submittingRefund) => ({
  type: SUBMITTING_REFUND_CHANGED,
  submittingRefund
});

export const creatingRefundChanged = (creatingRefund) => ({
  type: CREATING_REFUND_CHANGED,
  creatingRefund
});

export const buildingRefundChanged = (buildingRefund) => ({
  type: BUILDING_REFUND_CHANGED,
  buildingRefund
});
