import PropTypes from 'prop-types';
import React from 'react';

import Iframe from 'react-iframe'
import SlidingPane from "react-sliding-pane";
import ShowHideContent from '../../ShowHideContent/components/ShowHideContent';
import Popup from "reactjs-popup";

import InlineMenu from './InlineMenu';
import FollowUpModalApp from '../../FollowUpModal/startup/FollowUpModalApp';
import EventFormApp from '../../EventForm/startup/EventFormApp';
import OfferV2ModalApp from '../../OfferV2Modal/startup/OfferV2ModalApp';
import PipelineEvent from '../../PipelineEvent/components/PipelineEvent';

import $ from 'jquery';
require('datatables.net-bs4');

import {
  setGonTimeZoneFromVenue
} from '../../../shared/timeZoneLogic';

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "493px",
  "padding": "20px 15px"
}

const offerModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "0"
}

const eventFormModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "594px",
  "padding": "0px"
}

const PipelineSlidingPane = ({
  pipeline,
  pipelineChanged,
  currentUser,
  team,
  csrfToken,
  followUp,
  followUpChanged,
  fetchNewFollowUp,
  dataTableEl,
  calendarEventArtists,
  confirm,
  holdGroup,
  submitNewEventForm,
  venuesOptions,
  venueSelected,
  createCalendarEventFromPipeline,
  closeCalendarEventModal,
  offer,
  defaultOffer,
  offerTemplates,
  offerChanged,
  fetchNewOffer,
  onClose,
  fetchEditFollowUp,
  buyers,
  promoters,
  selectedBuyer,
  selectedPromoter,
  submittingForm,
  doorsTimeDefault,
  eventEndTimeDefault,
  userChangedDoorsTime,
  userChangedEventEndTime,
  venueOwnerships
}) => (
  <div>
    <SlidingPane
      isOpen={Object.keys(pipeline).length > 0}
      className="sliding-pane-container"
      width={"480px"}
      hideHeader={true}
      onRequestClose={() => {
        pipelineChanged({});
        onClose();
      }}
    >
      <div className="row">
        <div className="col-12">
          <div className="card no-border shadow-2 mb-0">
            <div className="card-header hero-image"
                 style={
                   (pipeline && pipeline.artist && pipeline.artist.image_url) ? (
                     {"height": "290px", "borderTopRightRadius": "0", "backgroundImage": "linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.75)), url('" + pipeline.artist.image_url + "')"}
                   ) : (
                     {"height": "290px", "borderTopRightRadius": "0", "background": "#3b81bf"}
                   )
                 } >
              <div className="row">
                <div className="col">
                  <strong className="text-white"
                        style={{"fontSize": "24px"}}>
                    {pipeline.artist ? pipeline.artist.name : ""}
                  </strong>
                  <div className="pt-3">
                    <span className="badge badge-light small"
                          style={{"borderRadius": "24px", "padding": "6px 12px"}}>
                      {pipeline.titleized_state}
                    </span>
                  </div>
                </div>
                <div className="col-xs-auto" style={{"paddingRight": "15px"}}>
                  <a href="#"
                     style={{"fontSize": "24px"}}
                     className="text-white"
                     onClick={(e) => {
                       e.preventDefault();
                       pipelineChanged({});
                     }} >
                    <i className="fa fa-times"></i>
                  </a>
                </div>
              </div>
              {pipeline && pipeline.artist ? (
                <div className="bottom-lead">
                  <p className="mb-0 small">
                    <a className="text-white"
                       href={"/artists/" + pipeline.artist.permalink}>
                      View Artist Details <i className="fal fa-angle-right"></i>
                    </a>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row py-3" style={{"margin": "0"}}>
            <div className="col">
              <strong style={{"fontSize": "20px"}}>
                Activity
              </strong>
            </div>
            <InlineMenu pipeline={pipeline}
                        fetchNewFollowUp={fetchNewFollowUp}
                        team={team}
                        createCalendarEventFromPipeline={createCalendarEventFromPipeline}
                        fetchNewOffer={fetchNewOffer}
                        defaultOffer={defaultOffer}
                        fetchEditFollowUp={fetchEditFollowUp}
                        currentUser={currentUser} />
          </div>
          <div className="row pb-3" style={{"margin": "0px 0px 0px 7px"}}>
            <div className="col-12">
              {(pipeline.pipeline_events || []).map((pipelineEvent, index) => (
                <PipelineEvent key={index}
                               index={index}
                               pipelineEvents={(pipeline.pipeline_events || [])}
                               pipelineEvent={pipelineEvent}
                               team={team} />
              ))}
            </div>
          </div>
        </div>
      </div>
      {pipeline && pipeline.artist ? (
        <div className="row mx-0"
             style={{
               "position": "fixed",
               "bottom": "0"
             }}>
          <div className="col-12 py-2"
               style={{
                 "background": "white",
                 "borderBottomLeftRadius": "8px"
               }}>
            <a href={"/artists/" + pipeline.artist.permalink + "?history=true"}>
              <small className="strong">
                View your full history with {pipeline.artist.name} <i className="fas fa-angle-right pl-1"></i>
              </small>
            </a>
          </div>
        </div>
      ) : null}
    </SlidingPane>
    <Popup
      open={Object.keys(followUp).length > 0}
      contentStyle={modalContentStyle}
      onClose={() => followUpChanged({})}
    >
      <FollowUpModalApp followUp={followUp}
                        team={team}
                        csrfToken={csrfToken}
                        dataTableEl={dataTableEl}
                        onCreate={
                          () => {
                            pipelineChanged({});

                            if(Object.keys(dataTableEl).length > 0){
                              $(dataTableEl).DataTable().ajax.reload();
                            }
                          }
                        }
                        onClose={
                          () => {
                            pipelineChanged({});
                            followUpChanged({});
                          }
                        }
                        canChangeArtist={pipeline.state === "follow_up"} />
    </Popup>
    <Popup
      open={Object.keys(offer).length > 0}
      contentStyle={offerModalContentStyle}
      closeOnDocumentClick={false}
      className={"offer-modal"}
      onClose={
        () => {
          offerChanged({});
          pipelineChanged({});

          if(Object.keys(dataTableEl).length > 0){
            $(dataTableEl).DataTable().ajax.reload();
          }
        }
      }
    >
      <OfferV2ModalApp currentUser={currentUser}
                       csrfToken={csrfToken}
                       onClose={
                         () => {
                           offerChanged({});
                           pipelineChanged({});

                           if(Object.keys(dataTableEl).length > 0){
                             $(dataTableEl).DataTable().ajax.reload();
                           }
                         }
                       }
                       venuesOptions={venuesOptions}
                       venueSelected={venueSelected}
                       venueOwnerships={venueOwnerships}
                       selectedArtist={offer.artistPreview}
                       offer={{create_calendar_event: true}}
                       pipeline={offer.pipeline}
                       team={team} />
    </Popup>
    <Popup
      open={calendarEventArtists.length > 0}
      contentStyle={eventFormModalContentStyle}
      className={"popup-modal"}
      closeOnDocumentClick={false}
      onOpen={
        () => {
          setGonTimeZoneFromVenue(venueOwnerships, venueSelected.value);
        }
      }
      onClose={() => closeCalendarEventModal()}
    >
      <div className="popup-modal-container">
        <EventFormApp formTitle={"New Event"}
                      confirm={confirm}
                      holdGroup={holdGroup}
                      currentUser={currentUser}
                      formButtonLabel={"Create Event"}
                      buyers={buyers}
                      promoters={promoters}
                      selectedBuyer={selectedBuyer}
                      selectedPromoter={selectedPromoter}
                      calendarEventTitle={
                        calendarEventArtists.map((artist) => artist.name).join(" & ")
                      }
                      onSuccess={
                        () => {
                          closeCalendarEventModal();
                          pipelineChanged({});

                          if(Object.keys(dataTableEl).length > 0){
                            $(dataTableEl).DataTable().ajax.reload();
                          }
                        }
                      }
                      onClose={
                        () => {
                          closeCalendarEventModal();
                        }
                      }
                      csrfToken={csrfToken}
                      team={team}
                      venuesOptions={venuesOptions}
                      venueSelected={venueSelected}
                      onVenueChange={
                        (venue) => {
                          setGonTimeZoneFromVenue(venueOwnerships, venue.value);
                        }
                      }
                      calendarEventArtists={calendarEventArtists}
                      submittingForm={submittingForm}
                      submitEventForm={submitNewEventForm}
                      doorsTimeDefault={doorsTimeDefault}
                      eventEndTimeDefault={eventEndTimeDefault}
                      userChangedDoorsTime={userChangedDoorsTime}
                      userChangedEventEndTime={userChangedEventEndTime} />
      </div>
    </Popup>
  </div>
);

PipelineSlidingPane.propTypes = {
  pipeline: PropTypes.object.isRequired,
  pipelineChanged: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  followUp: PropTypes.object,
  followUpChanged: PropTypes.func.isRequired,
  fetchNewFollowUp: PropTypes.func.isRequired,
  dataTableEl: PropTypes.object,
  calendarEventArtists: PropTypes.array,
  confirm: PropTypes.object,
  holdGroup: PropTypes.object,
  submitNewEventForm: PropTypes.func.isRequired,
  venuesOptions: PropTypes.array,
  venueSelected: PropTypes.object,
  createCalendarEventFromPipeline: PropTypes.func.isRequired,
  closeCalendarEventModal: PropTypes.func.isRequired,
  offer: PropTypes.object,
  defaultOffer: PropTypes.object,
  offerTemplates: PropTypes.array,
  offerChanged: PropTypes.func.isRequired,
  fetchNewOffer: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  fetchEditFollowUp: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  promoters: PropTypes.array,
  selectedBuyer: PropTypes.object,
  selectedPromoter: PropTypes.object,
  submittingForm: PropTypes.bool,
  venueOwnerships: PropTypes.array,
  userChangedDoorsTime: PropTypes.bool,
  userChangedEventEndTime: PropTypes.bool,
};

export default PipelineSlidingPane;
