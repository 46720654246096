import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';

import PromoCodeForm from './PromoCodeForm';

const axios = require("axios").default;

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const PromoCodesTab = ({
  csrfToken,
  confirm,
  showActivities,
  team,
  ticketTypes
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [promoCodeToCopy, setPromoCodeToCopy] = useState({});
  const [promoCodeToEdit, setPromoCodeToEdit] = useState({});
  const [promoCodes, setPromoCodes] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const getPromoCodes = (confirm, page) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.get("/teams/" + team.id + "/promo_codes", {
        params: {
          confirm_id: confirm.id,
          page: page,
          per_page: perPage
        }
      })
      .then(data => {
        const { data: { collection, total_entries } } = data;
        setIsLoading(false);
        setTotalEntries(total_entries);
        setTotalPages(Math.ceil(total_entries / perPage));
        setPromoCodes(collection);
      })
      .catch(err => {
        setIsLoading(false);
        setPromoCodes([]);
      });
  };

  const deletePromoCode = (promoCode) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    axios.delete("/teams/" + team.id + "/promo_codes/" + promoCode.id)
      .then(() => {
        if(promoCodes.length === 1) {
          getPromoCodes(confirm, 1);
          setCurrentPage(1);
        } else {
          getPromoCodes(confirm, currentPage);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };

  const clearForm = () => {
    setIsEditing(false);
    setPromoCodeToEdit({});
    setPromoCodeToCopy({});
    getPromoCodes(confirm, currentPage);
  };

  const setIsOpen = (open) => {
    setIsEditing(open);
  };

  useEffect(() => {
    setIsLoading(true);
    getPromoCodes(confirm, currentPage);
  }, [currentPage, perPage])

  return (
    <>
      {promoCodes.length === 0 ? (
        <div className="row">
          <div className="col-12 mt-5">
            {
              isLoading ? (
                <div className="text-center">
                  <img src="/uploading-loading.gif"
                        style={{width: "50px"}} />
                </div>
              ) : (
                <>
                  <div className='d-flex justify-content-center'>
                    <div className="d-flex justify-content-center align-items-center"
                        style={{
                          "width": "80px",
                          "height": "80px",
                          "borderRadius": "50%",
                          "background": "#f4f9fc",
                          "border": "5px solid #c1dff4"
                        }}>
                      <i style={{"fontSize": "66px", "marginTop": "8px"}}
                        className="fal fa-percentage text-primary"></i>
                    </div>
                  </div>
                  <div className='text-center mt-3'>
                    <p className="mb-2"
                      style={{"fontSize": "16px"}}>
                      <strong>No promo codes yet</strong>
                    </p>
                    <p className="small">
                      Use promo codes to provide special<br />
                      discounts on tickets.
                    </p>
                    <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          setIsEditing(true);
                        }
                      }
                      className="btn btn-primary">
                      <strong>Add Promo Code</strong>
                    </a>
                  </div>
                </>
              )
            }
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12 mt-4">
            <div className="text-right">
              <a href="#"
                style={{
                  "borderWidth": "1px",
                  "borderRadius": "4px"
                }}
                onClick={
                  (e) => {
                    e.preventDefault();
                    setIsEditing(true);
                  }
                }
                className="btn btn-outline-primary small">
                <strong>Add a Code</strong>
              </a>
            </div>
            <table className="table mb-0 mt-4 table-responsive vertical-middle table-border-bottom-1"
                  style={{tableLayout: "fixed", "fontSize": "14px"}}>
              <colgroup>
                <col span={1} style={{"width": "15%"}} />
                <col span={1} style={{"width": "20%"}} />
                <col span={1} style={{"width": "15%"}} />
                <col span={1} style={{"width": "20%"}} />
                <col span={1} style={{"width": "25%"}} />
                <col span={1} style={{"width": "5%"}} />
              </colgroup>
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>CODE TYPE</th>
                  <th>DISCOUNT</th>
                  <th>USES</th>
                  <th>STATUS</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {promoCodes.map((promoCode, index) =>
                  <tr key={index}>
                    <td>{promoCode.name}</td>
                    <td>Applies Discount</td>
                    <td>
                      {typeof(promoCode.percentage) === "number" ? (
                        promoCode.percentage + "%"
                      ) : (
                        formatter.format(promoCode.amount)
                      )}
                    </td>
                    <td>
                      {promoCode.number_claimed + "/" + (promoCode.unlimited ? "Unlimited" : promoCode.limit)}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-inline-block mr-2"
                            style={{
                              "width": "5px",
                              "height": "5px",
                              "borderRadius": "50%",
                              "background": (promoCode.active ? "#91c347" : "#FF595E")
                            }}>
                        </div>
                        {promoCode.active ? "Active" : "Inactive"}
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="text-muted">
                          {promoCode.active ? (
                            "Ends " + promoCode.human_end_time
                          ) : promoCode.past ? (
                            "Ended " + promoCode.human_end_time
                          ) : (
                            "Starts " + promoCode.human_start_time
                          )}
                        </span>
                      </div>
                    </td>
                    <td>
                      <Popup arrow={false}
                            offsetY={5}
                            position="bottom right"
                            contentStyle={popupContentStyle}
                            onOpen={
                              (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }
                            }
                            trigger={open => (
                              <a href="#"
                                  onClick={
                                    (e) => {
                                      e.preventDefault();
                                    }
                                  }
                                  className="text-muted">
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                            )} >
                        {close => (
                          <div className='row text-left'>
                            <div className="col-12">
                              <ul className="list-group offer-inline-menu">
                                <li className="list-group-item px-3 pt-3 pb-2">
                                  <a href="#"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        close();
                                        setPromoCodeToEdit(promoCode);
                                        setIsEditing(true);
                                      }
                                    }>
                                    <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                                      <i className="fas fa-pencil fa-fw"></i>
                                    </span>
                                    <span className="text-dark ml-3">
                                      Edit
                                    </span>
                                  </a>
                                </li>
                                <li className="list-group-item px-3 py-2">
                                  <a href="#"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        close();
                                        setPromoCodeToCopy(promoCode)
                                        setIsEditing(true);
                                      }
                                    }>
                                    <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                                      <i className="far fa-copy fa-fw"></i>
                                    </span>
                                    <span className="text-dark ml-3">
                                      Copy
                                    </span>
                                  </a>
                                </li>
                                <li className="list-group-item pb-3 px-3 pt-2">
                                  <a href="#"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        close();
                                        deletePromoCode(promoCode);
                                      }
                                    }
                                    className="text-danger">
                                    <span className="d-inline-block text-center" style={{width: "28px"}}>
                                      <i className="far fa-trash fa-fw"></i>
                                    </span>
                                    <span className="ml-3">
                                      Delete
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div
              style={{
                "display": "flex",
                "justifyContent": "space-between",
                "padding": "15px",
                "width": "100%",
              }}
            >
              {totalPages > 1 ? (
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalEntries}
                  pageRangeDisplayed={5}
                  itemClass="page-item"
                  linkClass="page-link bg-white"
                  innerClass="pagination small"
                  hideFirstLastPages={true}
                  prevPageText={"←"}
                  nextPageText={"→"}
                  onChange={
                    (page) => {
                      setCurrentPage(page);
                    }
                  }
                />
              ) : <div/>}
              <div>
                <label htmlFor="show-per-page"
                       className="text-muted mb-1"
                       style={{"fontSize": "14px"}}
                >
                  Show:
                </label>
                <select className="show-per-page"
                        style={{
                          "backgroundColor": "transparent",
                          "border": "0px"
                        }}
                        value={perPage}
                        id="show-per-page"
                        onChange={
                          (e) => {
                            const perPageNumber = parseInt(e.target.value, 10)
                            setPerPage(perPageNumber);
                          }
                        }
                >
                  {[10, 25, 50, 100].map((value, index) => (
                    <option key={index} value={value}>
                      {value} Rows
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      <PromoCodeForm
        confirm={confirm}
        csrfToken={csrfToken}
        isOpen={isEditing}
        setIsOpen={setIsOpen}
        promoCodeToCopy={promoCodeToCopy}
        promoCodeToEdit={promoCodeToEdit}
        onClose={clearForm}
        showActivities={showActivities}
        team={team}
        ticketTypes={ticketTypes}
      />
    </>
  );
}

PromoCodesTab.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  showActivities: PropTypes.array,
  team: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array,
};

export default PromoCodesTab;
