// Simple example of a React "smart" component

import { connect } from 'react-redux';
import PipelineSlidingPane from '../components/PipelineSlidingPane';
import * as actions from '../actions/pipelineSlidingPaneActionCreators';
import * as eventFormActions from '../../EventForm/actions/eventFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  pipeline: state.pipelineSlidingPane.pipeline,
  currentUser: state.pipelineSlidingPane.currentUser,
  team: state.pipelineSlidingPane.team,
  csrfToken: state.pipelineSlidingPane.csrfToken,
  followUp: state.pipelineSlidingPane.followUp,
  dataTableEl: state.pipelineSlidingPane.dataTableEl,
  calendarEventArtists: state.pipelineSlidingPane.calendarEventArtists,
  confirm: state.pipelineSlidingPane.confirm,
  holdGroup: state.pipelineSlidingPane.holdGroup,
  venuesOptions: state.pipelineSlidingPane.venuesOptions,
  venueSelected: state.pipelineSlidingPane.venueSelected,
  offer: state.pipelineSlidingPane.offer,
  defaultOffer: state.pipelineSlidingPane.defaultOffer,
  offerTemplates: state.pipelineSlidingPane.offerTemplates,
  onClose: state.pipelineSlidingPane.onClose,
  buyers: state.eventForm.buyers,
  promoters: state.eventForm.promoters,
  selectedBuyer: state.eventForm.selectedBuyer,
  selectedPromoter: state.eventForm.selectedPromoter,
  submittingForm: state.pipelineSlidingPane.submittingForm,
  doorsTimeDefault: state.eventForm.doorsTimeDefault,
  eventEndTimeDefault: state.eventForm.eventEndTimeDefault,
  userChangedDoorsTime: state.eventForm.userChangedDoorsTime,
  userChangedEventEndTime: state.eventForm.userChangedEventEndTime,
  venueOwnerships: state.pipelineSlidingPane.venueOwnerships
});

const mapDispatchToProps = (dispatch) => {
  return {
    pipelineChanged: (pipeline) => dispatch(actions.pipelineChanged(pipeline)),
    followUpChanged: (followUp) => dispatch(actions.followUpChanged(followUp)),
    fetchNewFollowUp: (team, pipeline) => dispatch(actions.fetchNewFollowUp(dispatch, team, pipeline)),
    submitNewEventForm: (csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => dispatch(eventFormActions.submitNewEventForm(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer)),
    createCalendarEventFromPipeline: (pipeline) => dispatch(actions.createCalendarEventFromPipeline(pipeline)),
    closeCalendarEventModal: () => dispatch(actions.closeCalendarEventModal()),
    offerChanged: (offer) => dispatch(actions.offerChanged(offer)),
    fetchNewOffer: (team, pipeline, defaultOffer) => dispatch(actions.fetchNewOffer(dispatch, team, pipeline, defaultOffer)),
    fetchEditFollowUp: (team, followUp) => dispatch(actions.fetchEditFollowUp(dispatch, team, followUp)),
    userChangedDoorsTimeChanged: (userChangedDoorsTime) => dispatch(eventFormActions.userChangedDoorsTimeChanged(userChangedDoorsTime)),
    userChangedEventEndTimeChanged: (userChangedEventEndTime) => dispatch(eventFormActions.userChangedDoorsTimeChanged(userChangedEventEndTime)),
    applyTimeDefaults: (confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime) => dispatch(eventFormActions.applyTimeDefaults(dispatch, confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime)),
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(PipelineSlidingPane);
