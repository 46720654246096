/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { toast } from 'react-toastify';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import {EditorState} from 'draft-js';

import $ from 'jquery';
require('datatables.net-bs4');

import {
  PREVIEW_STATE,
  OFFER_VENUE_CHANGED,
  OFFER_PRIMARY_ARTIST_CHANGED,
  OFFER_START_DATE_CHANGED,
  OFFER_DEAL_TERM_LINE_ITEM_ADDED,
  OFFER_DEAL_TERM_LINE_ITEM_REMOVED,
  OFFER_DEAL_TERM_LINE_ITEM_LABEL_CHANGED,
  OFFER_DEAL_TERM_LINE_ITEM_DESCRIPTION_CHANGED,
  OFFER_ADDITIONAL_TERMS_CHANGED,
  OFFER_CONTACT_DETAILS_CHANGED,
  OFFER_PERFORMER_REMOVED,
  OFFER_AGENT_NAME_CHANGED,
  OFFER_AGENT_EMAIL_CHANGED,
  OFFER_TEMPLATE_NAME_CHANGED,
  SELECTED_OFFER_TEMPLATE_CHANGED,
  OFFER_ARTIST_SUGGESTION_ADDED,
  OFFER_CALENDAR_EVENT_CHANGED,
  OFFER_PERFORMER_SELECTED,
  OFFER_PERFORMER_UNSELECTED,
  OFFER_START_TIME_CHANGED,
  OFFER_FRONT_END_STATE_CHANGED,
  OFFER_EMAIL_BODY_CHANGED,
  OFFER_LAST_SENT_TO_CHANGED,
  OFFER_CALENDAR_EVENT_PERFORMERS_CHANGED,
  APPLY_OFFER_TEMPLATE,
  NEW_OFFER_TEMPLATE_CREATED,
  OFFER_ARTIST_PREVIEW_CHANGED,
  OFFER_CHANGED,
  OFFER_ERRORS_CHANGED,
  OFFER_EVENT_NAME_CHANGED,
  OFFER_DOLLAR_OR_PERCENTAGE_OPERATOR_CHANGED,
  OFFER_DOLLAR_AMOUNT_CHANGED,
  OFFER_UPSIDE_PERCENTAGE_CHANGED,
  HIDDEN_FILE_UPLOAD_INPUT_EL_CHANGED,
  ATTACHMENT_ADDED,
  ATTACHMENT_REMOVED
} from '../constants/offerModalConstants';

export const updateOfferVenue = (venue) => ({
  type: OFFER_VENUE_CHANGED,
  venue
});

export const updateOfferPrimaryArtist = (artist) => ({
  type: OFFER_PRIMARY_ARTIST_CHANGED,
  artist
});

export const updateOfferStartDate = (date) => ({
  type: OFFER_START_DATE_CHANGED,
  date
});

export const updateOfferEventName = (eventName) => ({
  type: OFFER_EVENT_NAME_CHANGED,
  eventName
});

export const addOfferDealTermLineItem = () => ({
  type: OFFER_DEAL_TERM_LINE_ITEM_ADDED
});

export const removeOfferDealTermLineItem = (index) => ({
  type: OFFER_DEAL_TERM_LINE_ITEM_REMOVED,
  index
});

export const updateOfferDealTermLineItemLabel = (index, label) => ({
  type: OFFER_DEAL_TERM_LINE_ITEM_LABEL_CHANGED,
  index,
  label
});

export const updateOfferDealTermLineItemDescription = (index, description) => ({
  type: OFFER_DEAL_TERM_LINE_ITEM_DESCRIPTION_CHANGED,
  index,
  description
});

export const updateOfferAdditionalTerms = (editorState) => ({
  type: OFFER_ADDITIONAL_TERMS_CHANGED,
  editorState
});

export const updateOfferContactDetails = (editorState) => ({
  type: OFFER_CONTACT_DETAILS_CHANGED,
  editorState
});

export const updateOfferRemovePerformer = () => ({
  type: OFFER_PERFORMER_REMOVED
});

export const updateOfferAgentName = (agentName) => ({
  type: OFFER_AGENT_NAME_CHANGED,
  agentName
});

export const updateOfferAgentEmail = (agentEmail) => ({
  type: OFFER_AGENT_EMAIL_CHANGED,
  agentEmail
});

export const updateOfferTemplateName = (offerTemplateName) => ({
  type: OFFER_TEMPLATE_NAME_CHANGED,
  offerTemplateName
});

export const selectOfferTemplate = (offerTemplate) => ({
  type: SELECTED_OFFER_TEMPLATE_CHANGED,
  offerTemplate
});

export const addArtistSuggestion = (artistSuggestion, team, user) => ({
  type: OFFER_ARTIST_SUGGESTION_ADDED,
  artistSuggestion,
  team,
  user
});

export const offerCalendarEventChanged = (calendarEvent) => ({
  type: OFFER_CALENDAR_EVENT_CHANGED,
  calendarEvent
});

export const offerPerformerSelected = (performer) => ({
  type: OFFER_PERFORMER_SELECTED,
  performer
});

export const offerPerformerUnselected = () => ({
  type: OFFER_PERFORMER_UNSELECTED
});

export const offerStartTimeChanged = (startTime) => ({
  type: OFFER_START_TIME_CHANGED,
  startTime
});

export const updateOfferFrontEndState = (state) => ({
  type: OFFER_FRONT_END_STATE_CHANGED,
  state
});

export const updateOfferEmailBody = (emailBody) => ({
  type: OFFER_EMAIL_BODY_CHANGED,
  emailBody
});

export const updateOfferLastSentTo = (lastSentTo) => ({
  type: OFFER_LAST_SENT_TO_CHANGED,
  lastSentTo
});

export const offerCalendarEventPerformersChanged = (performers) => ({
  type: OFFER_CALENDAR_EVENT_PERFORMERS_CHANGED,
  performers
});

export const applyOfferTemplate = (offerTemplate) => ({
  type: APPLY_OFFER_TEMPLATE,
  offerTemplate
});

export const newOfferTemplateCreated = (offerTemplate) => ({
  type: NEW_OFFER_TEMPLATE_CREATED,
  offerTemplate
});

export const updateOfferArtistPreview = (artist, offer) => ({
  type: OFFER_ARTIST_PREVIEW_CHANGED,
  artist,
  offer
});

export const updateOfferData = (offer) => ({
  type: OFFER_CHANGED,
  offer
});

export const updateOfferErrors = (errors) => ({
  type: OFFER_ERRORS_CHANGED,
  errors
});

export const fetchCalendarEventPerformers = (dispatch, team, calendarEventId, offer) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/performers", {
        params: {
          performable_type: "CalendarEvent",
          performable_id: calendarEventId
        }
      })
      .then(({ data }) => {
        dispatch(offerCalendarEventPerformersChanged(data));
      })
  };
};

export const fetchOfferTemplate = (dispatch, team, offerTemplateId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/offer_templates/" + offerTemplateId)
      .then(({ data }) => {
        var offerTemplate = serverOfferTemplateJSONToLocal(data);
        dispatch(applyOfferTemplate(offerTemplate));
      })
  };
};

const htmlToEditorState = (html) => {
  var state = stateFromHTML(html);
  return EditorState.createWithContent(state);
}

const serverOfferTemplateJSONToLocal = (data) => {
  return {
    additionalTerms: htmlToEditorState(data.additional_terms || ""),
    contactDetails: htmlToEditorState(data.contact_details || ""),
    dealTermLineItems: data.deal_term_line_items
  };
};

export const submitOfferForm = (dispatch, csrfToken, team, offer) => {
  dispatch(updateOfferErrors([]));

  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    const offerParams = localOfferToServerFormat(offer, team);

    if(offer.id){
      return axios.patch("/teams/" + team.id + "/offers/" + offer.id, {
          offer: offerParams
        })
        .then(({ data }) => {
          dispatch(updateOfferData(serverJSONToLocal(data)));
          dispatch(updateOfferFrontEndState(PREVIEW_STATE));
        })
        .catch((error) => {
          dispatch(updateOfferErrors(error.response.data));
        });
    } else {
      return axios.post("/teams/" + team.id + "/offers", {
          offer: offerParams
        })
        .then(({ data }) => {
          dispatch(updateOfferData(serverJSONToLocal(data)));
          dispatch(updateOfferFrontEndState(PREVIEW_STATE));

          if(offer.rowTableEl){
            $(offer.rowTableEl).DataTable().ajax.reload();
          }
        })
        .catch((error) => {
          dispatch(updateOfferErrors(error.response.data));
        });
    }
  };
};

const fieldToParam = (field) => {
  var param;

  if(field){
    if(typeof(field) === "string"){
      param = parseFloat(field.replace(/,/g, ''));
    } else {
      param = field;
    }
  } else {
    param = "";
  }

  return param;
}

const localOfferToServerFormat = (offer, team) => {
  var additionalTerms;
  if(offer.additionalTerms){
    additionalTerms = stateToHTML(offer.additionalTerms.getCurrentContent());
  }

  var contactDetails;
  if(offer.contactDetails){
    contactDetails = stateToHTML(offer.contactDetails.getCurrentContent());
  }

  var upsidePercentage = fieldToParam(offer.upside_percentage);
  var dollarAmount = fieldToParam(offer.dollar_amount);

  var attachments = (offer.attachments || []);
  var attachmentIds = attachments.map((attachment) => attachment.id);

  var params =  {
    venue_id: (offer.venueSelected ? offer.venueSelected.id : null),
    start_date: offer.startDate,
    start_time_with_wrong_date: offer.startTime,
    event_name: offer.eventName,
    additional_terms: additionalTerms,
    contact_details: contactDetails,
    create_calendar_event: (offer.createCalendarEvent === true),
    agent_name: offer.agentName,
    agent_email: offer.agentEmail,
    last_sent_to: offer.lastSentTo,
    calendar_event_id: (offer.calendarEvent ? offer.calendarEvent.value : null),
    performer_id: (offer.performer ? offer.performer.id : null),
    dollar_amount: dollarAmount,
    upside_percentage: upsidePercentage,
    dollar_or_percentage_operator: offer.dollar_or_percentage_operator,
    attachment_ids: attachmentIds,
    deal_term_line_items_attributes: offer.dealTermLineItems.map((dealTermLineItem) => {
      return {
        id: dealTermLineItem.id,
        label: dealTermLineItem.label,
        description: dealTermLineItem.description,
        _destroy: dealTermLineItem._destroy
      };
    })
  };

  if(!offer.id){
    if(offer.pipeline){
      params.update_pipeline_attributes = {
        id: offer.pipeline.id,
        state: "offer"
      };
    } else {
      params.pipeline_attributes = {
        team_id: team.id,
        state: "offer"
      };
    }
  }

  if(offer.primaryPerformer){
    if(offer.primaryPerformer.artist){
      params.primary_performer_attributes = {
        id: offer.primaryPerformer.id,
        artistable_id: offer.primaryPerformer.artist.value,
        artistable_type: "Artist",
        _destroy: offer.primaryPerformer._destroy
      };
    } else {
      params.primary_performer_attributes = {
        id: offer.primaryPerformer.id,
        artist_suggestion_attributes: offer.primaryPerformer.artistSuggestion,
        _destroy: offer.primaryPerformer._destroy
      };
    }
  }

  return params;
};

export const serverJSONToLocal = (data) => {
  var transformed = {
    id: data.id,
    state: data.state,
    eventName: data.event_name,
    agentName: data.agent_name,
    agentEmail: data.agent_email,
    lastSentTo: data.last_sent_to,
    emailBody: data.email_body,
    calendarEvent: data.calendar_event,
    performer: data.performer,
    startDate: parseDateTime(data.start_date_midday),
    additionalTerms: htmlToEditorState(data.additional_terms || ""),
    contactDetails: htmlToEditorState(data.contact_details || ""),
    dealTermLineItems: data.deal_term_line_items,
    team_membership: data.team_membership,
    human_created_at: data.human_created_at,
    created_at: data.created_at,
    artist_or_event_name: data.artist_or_event_name,
    dollar_amount: data.dollar_amount_with_two_decimals,
    upside_percentage: data.upside_percentage,
    dollar_or_percentage_operator: data.dollar_or_percentage_operator,
    attachments: data.attachments,
    ui_version: data.ui_version
  };

  if(data.venue){
    transformed.venueSelected = {
      id: data.venue.id,
      name: data.venue.name
    };
  }

  if(transformed.calendarEvent){
    transformed.calendarEvent.value = transformed.calendarEvent.id;
    transformed.calendarEvent.label = transformed.calendarEvent.option_label;
    transformed.calendarEventPerformers = transformed.calendarEvent.performers;
  }

  if(data.primary_performer){
    if(data.primary_performer.artistable_type === "Artist"){
      transformed.primaryPerformer = {
        id: data.primary_performer.id,
        artist: {
          value: data.primary_performer.artistable.id,
          label: data.primary_performer.artistable.name
        }
      };
      transformed.artistPreview = data.primary_performer.artistable;
    } else {
      transformed.primaryPerformer = {
        id: data.primary_performer.id,
        artistSuggestion: data.primary_performer.artistable
      };
    }
  }

  return transformed;
};

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

export const fetchArtistData = (dispatch, artistId, offer) => {
  return dispatch => {
    return axios.get("/artists/" + artistId + ".json")
      .then(({ data }) => {
        dispatch(updateOfferArtistPreview(data, offer));
      })
  };
};

const offerTemplateParams = (offer, offerTemplateName) => {
  var additionalTerms;
  if(offer.additionalTerms){
    additionalTerms = stateToHTML(offer.additionalTerms.getCurrentContent());
  }

  var contactDetails;
  if(offer.contactDetails){
    contactDetails = stateToHTML(offer.contactDetails.getCurrentContent());
  }

  return {
    name: offerTemplateName,
    additional_terms: additionalTerms,
    contact_details: contactDetails,
    deal_term_line_items_attributes: offer.dealTermLineItems.map((dealTermLineItem) => {
      return {
        id: dealTermLineItem.id,
        label: dealTermLineItem.label,
        description: dealTermLineItem.description,
        _destroy: dealTermLineItem._destroy
      };
    })
  };
};

export const createOfferTemplate = (dispatch, csrfToken, team, offer, offerTemplateName) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offer_templates", {
        offer_template: offerTemplateParams(offer, offerTemplateName)
      })
      .then(({ data }) => {
        toast.success("Offer template saved", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });

        dispatch(newOfferTemplateCreated(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const sendOffer = (dispatch, csrfToken, team, offer, closeModal) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offers/" + offer.id + "/send_offer", {
        offer: {
          email_body: offer.emailBody,
          last_sent_to: offer.lastSentTo
        }
      })
      .then(({ data }) => {
        closeModal();
      })
  };
};

export const acceptOfferFromModal = (dispatch, team, offerId, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offers/" + offerId + "/accept")
      .then(({ data }) => {
        dispatch(updateOfferData(serverJSONToLocal(data)));
        dispatch(updateOfferFrontEndState(PREVIEW_STATE));
      })
  };
};

export const declineOfferFromModal = (dispatch, team, offerId, csrfToken) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post("/teams/" + team.id + "/offers/" + offerId + "/decline")
      .then(({ data }) => {
        dispatch(updateOfferData(serverJSONToLocal(data)));
        dispatch(updateOfferFrontEndState(PREVIEW_STATE));
      })
  };
};

export const updateOfferDollarOrPercentageOperator = (operator) => ({
  type: OFFER_DOLLAR_OR_PERCENTAGE_OPERATOR_CHANGED,
  operator
});

export const updateOfferDollarAmount = (amount) => ({
  type: OFFER_DOLLAR_AMOUNT_CHANGED,
  amount
});

export const updateOfferUpsidePercentage = (percentage) => ({
  type: OFFER_UPSIDE_PERCENTAGE_CHANGED,
  percentage
});

export const hiddenFileUploadInputElChanged = (el) => ({
  type: HIDDEN_FILE_UPLOAD_INPUT_EL_CHANGED,
  el
});

export const uploadAttachment = (dispatch, csrfToken, team, file) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var params = new FormData();
    params.append("attachment[file]", file);

    return axios.post("/teams/" + team.id + "/attachments", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        dispatch(attachmentAdded(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const attachmentAdded = (attachment) => ({
  type: ATTACHMENT_ADDED,
  attachment
});

export const attachmentRemoved = (attachment) => ({
  type: ATTACHMENT_REMOVED,
  attachment
});
