import React, { useState } from "react";
import axios from "axios";

import DataDrivenSegmentForm from "./DataDrivenSegmentForm";
import { useSegmentNewContext } from "./SegmentNewContainer";
import { toastError } from "../../../shared/toastHelper";

const titleStyle = {
  color: "#333333",
  fontSize: 16,
  fontWeight: 600,
};

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const SegmentNew = () => {
  const {
    csrfToken,
    team,
    segment,
    setSegment,
  } = useSegmentNewContext();
  const [fanFilterGroupId, setFanFilterGroupId] = useState(segment.fan_filter_group?.id || 0);

  const createSegment = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/segments`, { segment: segment })
      .then((data) => {
        if (segment.type === "Segments::Manual") {
          window.location.href = `/teams/${team.id}/segments/${data["data"]["id"]}`;
        } else {
          if (fanFilterGroupId) {
            axios.put(`/teams/${team.id}/fan_filter_groups/${fanFilterGroupId}`, {
              fan_filter_group: { segment_id: data["data"]["id"] }
            })
              .then(() => {
                window.location.href = `/teams/${team.id}/segments/${data["data"]["id"]}`;
              })
              .catch((error) => {
                const errors = error.response.data;
                toastError(errors.join(", "));
              });
          } else {
            window.location.href = `/teams/${team.id}/segments/${data["data"]["id"]}/edit`;
          }
        }
      })
      .catch((error) => {
        const errors = error.response.data;
        toastError(errors.join(", "));
      });
  };

  return (
    <div>
      <div className="mt-3 mb-2">
        <div>
          <div style={titleStyle}>
            New Segment
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="rounded border p-4"
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <div style={{ width: "400px" }}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={segment.name}
                onChange={(e) => setSegment({...segment, name: e.target.value})}
              />
            </div>
            <div className="mt-3">
              <label htmlFor="broadcastType">Choose a broadcast type</label>
              <div className="d-flex flex-wrap">
                <button
                  type="button"
                  className="d-flex align-items-center mr-4 mb-4 p-3"
                  style={{
                    width: "300px",
                    minHeight: "96px",
                    backgroundColor: segment.type === "Segments::Manual" ? "#eef7fd" : "#fff",
                    border: segment.type === "Segments::Manual" ? "2px solid #1982C4" : "2px solid #ccc",
                    borderRadius: "10px",
                  }}
                  onClick={() => setSegment({...segment, type: "Segments::Manual"})}
                >
                  <div className="ml-3 text-left">
                    <p className="mb-0" style={{ fontSize: "16px" }}>
                      <strong>
                        Manual
                      </strong>
                    </p>
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      Upload a CSV.
                    </p>
                  </div>
                </button>
                <button
                  type="button"
                  className="d-flex align-items-center mr-4 mb-4 p-3"
                  style={{
                    width: "300px",
                    minHeight: "96px",
                    backgroundColor: segment.type === "Segments::DataDriven" ? "#eef7fd" : "#fff",
                    border: segment.type === "Segments::DataDriven" ? "2px solid #1982C4" : "2px solid #ccc",
                    borderRadius: "10px",
                  }}
                  onClick={() => setSegment({...segment, type: "Segments::DataDriven"})}
                >
                  <div className="ml-3 text-left">
                    <p className="mb-0" style={{ fontSize: "16px" }}>
                      <strong>
                        Data driven
                      </strong>
                    </p>
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      Build from attributes about events or fans.
                    </p>
                  </div>
                </button>
              </div>
            </div>
            {segment.type === "Segments::DataDriven" ? (
              <DataDrivenSegmentForm 
                csrfToken={csrfToken}
                team={team}
                segment={segment}
                setSegment={setSegment}
                fanFilterGroupId={fanFilterGroupId}
                setFanFilterGroupId={setFanFilterGroupId}
              />
            ) : null}
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "250px" }}
            >
              <a href={`/teams/${team.id}/segments`} style={backButtonStyle}>
                Back
              </a>
              <button
                type="button"
                className="ml-2 btn btn-primary"
                onClick={e => {
                  e.preventDefault();
                  createSegment();
                }}
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SegmentNew;
