import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const axios = require("axios");
const qs = require("qs");

const subheaderStyle = {
  fontSize: "12px"
};

const campaignRowStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const campaignLeftHandStyle = {
  marginTop: "10px",
  marginBottom: "10px",
  maxWidth: "350px",
};

const campaignNameStyle = {
  fontSize: "14px",
  fontWeight: "900",
};

const campaignDescriptionStyle = {
  color: "rgb(153, 153, 153)",
  fontSize: "12px",
};

const hrStyle = {
  borderTop: "1px solid rgb(230, 230, 230)",
};

const CampaignsTab = ({
  csrfToken,
  team,
  confirm,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignOverrides, setCampaignOverrides] = useState([]);

  const getCampaigns = () => {
    const queryString = qs.stringify({
      q: {
        venue_ownership_id_eq: confirm.venue_ownership.id,
      }
    })

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.get("/teams/" + team.id + "/campaigns.json?" + queryString)
      .then(({data}) => {
        setCampaigns(data);
        getCampaignOverrides();
      })
      .catch(_error => {
        toast.error("Could not load campaigns, please try again", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
        setIsLoading(false);
      });
  };

  const getCampaignOverrides = () => {
    const queryString = qs.stringify({
      q: {
        overridable_id_eq: confirm.id,
        overridable_type_eq: "Confirm",
      }
    });

    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.get("/teams/" + team.id + "/campaign_overrides?" + queryString)
      .then(({data}) => {
        setCampaignOverrides(data);
        setIsLoading(false);
      })
      .catch(_error => {
        toast.error("Could not load campaign overrides, please try again", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
        setIsLoading(false);
      });
  };

  const createCampaignOverride = (campaign_id) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post("/teams/" + team.id + "/campaign_overrides", {
      overridable_id: confirm.id,
      overridable_type: "Confirm",
      campaign_id: campaign_id,
      disabled: true,
    })
      .then(({data}) => {
        setCampaignOverrides([...campaignOverrides, data]);
        setIsSubmitting(false);
      })
      .catch(_error => {
        toast.error("Could not disable campaign, please try again", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
        setIsSubmitting(false);
      });
  };

  const toggleCampaignOverride = (override) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put("/teams/" + team.id + "/campaign_overrides/" + override.id, {
      disabled: !override.disabled,
    })
      .then(({data}) => {
        setCampaignOverrides(campaignOverrides.map(campaignOverride => {
          if (campaignOverride.id === data.id) {
            return data;
          } else {
            return campaignOverride;
          }
        }));
        setIsSubmitting(false);
      })
      .catch(_error => {
        toast.error("Could not toggle campaign, please try again", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
        setIsSubmitting(false);
      });
  };

  const isCampaignEnabled = (campaign_id) => {
    const override = campaignOverrides.find(override => override.campaign_id === campaign_id);

    if (override) {
      return !override.disabled;
    } else {
      return true;
    }
  };

  const toggleCampaign = (campaign_id) => {
    setIsSubmitting(true);

    const override = campaignOverrides.find(override => override.campaign_id === campaign_id);

    if (override) {
      toggleCampaignOverride(override);
    } else {
      createCampaignOverride(campaign_id);
    }
  };

  useEffect(() => { getCampaigns(); }, []);

  return (
    <div>
      <div style={subheaderStyle}>
        Enable automatic campaigns for this event.
      </div>
      {isLoading ? (
        <div>
          Loading...
        </div>
      ) : (
      <div>
        {campaigns.map((campaign, index) => {
          return (
            <div key={campaign.id}>
              <div
                style={campaignRowStyle}
              >
                <div style={campaignLeftHandStyle}>
                  <div style={campaignNameStyle}>
                    {campaign.campaign_template.name}
                  </div>
                  <div style={campaignDescriptionStyle}>
                    {campaign.campaign_template.description}
                  </div>
                </div>
                <div className="custom-control custom-switch mt-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={isCampaignEnabled(campaign.id)}
                    onChange={() => isSubmitting || toggleCampaign(campaign.id)}
                    id={`campaign-${campaign.id}-enabled`}
                  />
                  <label
                    style={{
                      cursor: isSubmitting ? "not-allowed" : "pointer",
                    }}
                    className="custom-control-label text-dark"
                    htmlFor={`campaign-${campaign.id}-enabled`}
                  />
                </div>
              </div>
              {index !== (campaigns.length - 1) ? (
                <hr style={hrStyle} />
              ) : null}
            </div>
          );
        })}
      </div>
      )}
    </div>
  );
};

export default CampaignsTab;
