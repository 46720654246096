export class SettlementMath {
  constructor(budget, isSettling) {
    this.isSettling = (isSettling || false);
    this.budget = Object.assign({}, budget);
  }

  calculate() {
    this.ticketsSection = this.calculateSettlementSection({
      items: this.buildTicketItems()
    });

    this.revenueSection = this.calculateSettlementSection({
      items: this.buildRevenueItems()
    });

    this.beforeAdjustedDeductionsSection = this.calculateSettlementSection({
      items: this.buildBeforeAdjustedDeductionsItems()
    });

    this.showSummary = this.calculateNetBoxOfficeReceipts();

    this.expensesSection = this.calculateSettlementSection({
      items: this.buildExpensesItems()
    });

    this.showSummary = this.calculateShowNetAfterExpenses();

    this.finalExpensesSection = this.calculateSettlementSection({
      items: this.buildFinalExpensesItems()
    });

    this.showSummary = this.calculateShowSummary();

    this.additionsSection = this.calculateSettlementSection({
      items: this.buildAdditionsItems()
    });

    this.deductionsSection = this.calculateSettlementSection({
      items: this.buildDeductionsItems()
    });

    this.artistEarnings = this.calculateArtistEarnings();
    this.artistEarningsForecast = this.calculateArtistEarningsForecast();

    this.showSummary = this.calculateVenueNetPotential();
    this.showSummary = this.calculateVenueNetPotentialForecast();

    return this;
  }

  // private

  parseMaskedInput(number) {
    if(typeof number === 'string') {
      return parseFloat(number.replace(/,/g, ''));
    } else {
      return parseFloat(number);
    }
  }

  zeroMinimum(number) {
    return number > 0 ? number : 0;
  }

  numberOfPaidTickets() {
    return this.ticketsSection.items
      .filter((item) => item.calculatedLeftHand > 0.00)
      .filter((item) => this.shouldShow(item))
      .reduce((summed, item) => {
        return summed + item.calculatedRightHand;
      }, 0);
  }

  numberOfPaidTicketsForecast() {
    return this.ticketsSection.items
      .filter((item) => item.calculatedLeftHandForecast > 0.00)
      .filter((item) => this.shouldShow(item))
      .reduce((summed, item) => {
        return summed + item.calculatedRightHandForecast;
      }, 0);
  }

  buildTicketItems() {
    var items = this.budget.ticket_line_items;

    items = items.map((item) => {
      if(item.lift_amount && item.lift_amount > 0.00) {
        var amountAfterLift = this.zeroMinimum(
          item.left_hand - item.lift_amount
        );

        var amountAfterLiftForecast = this.zeroMinimum(
          item.left_hand_forecast - item.lift_amount
        );

        return Object.assign({}, item, {
          left_hand: amountAfterLift,
          left_hand_forecast: amountAfterLiftForecast
        });
      } else {
        return item;
      }
    });

    if(this.isSettling){
      items = items.concat(
        this.budget.settlement_line_items
          .filter((sli) => sli.section === "Tickets")
          .map((item) => {
            return Object.assign({}, item, {
              left_hand: item.amount,
              operator: "*",
              right_hand: item.quantity,
              show_in_offer: true,
              overridable_id: item.id,
              overridable_type: "SettlementLineItem",
              calc_type: "fixed"
            });
          })
      );
    }

    return this.postProcessSectionItems(items, "Revenue");
  }

  buildRevenueItems() {
    var items = this.budget.income_line_items;

    items = items.map((item) => {
      if(item.lift_amount && item.lift_amount > 0.00) {
        var amountAfterLift = this.zeroMinimum(
          item.left_hand - item.lift_amount
        );

        var amountAfterLiftForecast = this.zeroMinimum(
          item.left_hand_forecast - item.lift_amount
        );

        return Object.assign({}, item, {
          left_hand: amountAfterLift,
          left_hand_forecast: amountAfterLiftForecast
        });
      } else {
        return item;
      }
    });

    if(this.isSettling){
      items = items.concat(
        this.budget.settlement_line_items
          .filter((sli) => sli.section === "Revenue")
          .map((item) => {
            return Object.assign({}, item, {
              left_hand: item.amount,
              operator: "*",
              right_hand: item.quantity,
              show_in_offer: true,
              overridable_id: item.id,
              overridable_type: "SettlementLineItem",
              calc_type: "fixed"
            });
          })
      );
    }

    return this.postProcessSectionItems(items, "Revenue");
  }

  shouldShow(item) {
    if (this.isSettling) {
      return !!item.settlementVisibility
        ? item.settlementVisibility.visible
        : item.show_in_offer;
    } else {
      return item.show_in_offer;
    }
  }

  buildBeforeAdjustedDeductionsItems() {
    var items = this.budget.before_adjusted_deductions_line_items;

    items = items.concat(
      this.budget.ticket_line_items
        .filter((item) => item.taxes && item.taxes > 0.00)
        .filter((item) => this.shouldShow(item))
        .map((item) => {
          var taxDescription = "Taxes";

          if(item.tax_description && item.tax_description.length > 0) {
            taxDescription = item.tax_description;
          }

          return {
            name: `${item.name} - ${taxDescription}`,
            left_hand: item.taxes,
            operator: "*",
            right_hand: item.right_hand,
            show_in_offer: true,
            overridable_id: item.overridable_id,
            overridable_type: "ManualTax",
            calc_type: "fixed"
          }
        })
    );

    if(this.isSettling){
      items = items.concat(
        this.budget.settlement_line_items
          .filter((sli) => sli.section === "Before Adjusted Deductions")
          .map((item) => {
            return Object.assign({}, item, {
              left_hand: item.amount,
              operator: "*",
              right_hand: item.quantity,
              show_in_offer: true,
              overridable_id: item.id,
              overridable_type: "SettlementLineItem",
              calc_type: "fixed"
            });
          })
      );
    }

    return this.postProcessSectionItems(items, "Before Adjusted Deductions");
  }

  buildFinalExpensesItems() {
    var items = this.budget.expense_line_items.filter( item => {
      return item.calc_type === "percentage_of_net"
          || item.calc_type === "percentage_of_expenses";
    });

    return this.postProcessSectionItems(items, "Expenses");
  }

  buildExpensesItems() {
    var items = this.budget.expense_line_items.filter( item => {
      return item.calc_type !== "percentage_of_net"
          && item.calc_type !== "percentage_of_expenses";
    });

    if(this.isSettling){
      items = items.concat(
        (this.budget.cached_sibling_headliner_budgets || [])
          .map((sibling) => {
            var budgetGuarantee = sibling.dollar_amount ?
              this.parseMaskedInput(sibling.dollar_amount) : 0.00;

            return {
              name: sibling.artist_or_event_name,
              left_hand: budgetGuarantee,
              operator: "*",
              right_hand: 1,
              show_in_offer: true,
              overridable_id: sibling.id,
              overridable_type: "Budget",
              calc_type: "fixed"
            }
          })
      );

      items = items.concat(
        this.budget.finance_items
          .filter((fi) => fi.on_settlement)
          .filter((fi) => !fi.is_income)
          .filter((fi) => ["Show Expense", "Show Expense + Buyout"].includes(fi.expense_type))
          .map((fi) => fi.finance_line_items).flat()
          .map((fli) => {
            return {
              name: fli.description,
              left_hand: fli.actual,
              operator: "*",
              right_hand: 1,
              show_in_offer: true,
              overridable_id: fli.id,
              overridable_type: "FinanceLineItem",
              calc_type: "fixed"
            }
          })
      );

      items = items.concat(
        this.budget.settlement_line_items
          .filter((sli) => sli.section === "Expenses")
          .map((item) => {
            return Object.assign({}, item, {
              left_hand: item.amount,
              operator: "*",
              right_hand: item.quantity,
              show_in_offer: true,
              overridable_id: item.id,
              overridable_type: "SettlementLineItem",
              calc_type: "fixed"
            });
          })
      );
    }

    return this.postProcessSectionItems(items, "Expenses");
  }

  buildAdditionsItems() {
    var items = [];
    var paidTicketsCount = this.numberOfPaidTickets();

    items = items.concat(
      this.budget.budget_bonuses
        .filter((b) => b.ticket_count_requirement)
        .filter((b) => b.dollar_amount)
        .filter((b) => b.dollar_or_percentage_operator === "CASH")
        .filter((b) => {
          var ticketCountRequirement = typeof b.ticket_count_requirement === 'string'
            ? parseFloat(b.ticket_count_requirement.replace(/,/g, ''))
            : b.ticket_count_requirement;

          return ticketCountRequirement <= paidTicketsCount
        })
        .sort((a, b) => a.ticket_count_requirement - b.ticket_count_requirement)
        .map((b) => {
          return {
            name: "Bonus for " + b.ticket_count_requirement + " tickets",
            left_hand: this.parseMaskedInput(b.dollar_amount),
            operator: "*",
            right_hand: 1,
            show_in_offer: true,
            overridable_id: b.overridable_id,
            overridable_type: b.overridable_type,
            calc_type: "fixed"
          }
        })
    )

    this.budget.is_headliner && (items = items.concat(
      this.budget.ticket_line_items
        .filter((item) => item.lift_amount && item.lift_amount > 0.00)
        .map((item) => {
          return {
            name: item.name,
            left_hand: item.lift_amount,
            left_hand_forecast: item.lift_amount,
            operator: "*",
            right_hand: item.right_hand,
            right_hand_forecast: item.right_hand_forecast,
            show_in_offer: true,
            overridable_id: item.overridable_id,
            overridable_type: item.overridable_type,
            calc_type: "fixed",
            lift: true
          }
        })
    ));

    if(this.isSettling){
      items = items.concat(
        this.budget.finance_items
          .filter((fi) => fi.on_settlement)
          .filter((fi) => {
            return (
              fi.is_income
                || fi.expense_type === "Show Expense + Buyout"
            );
          })
          .map((fi) => fi.finance_line_items).flat()
          .map((fli) => {
            return {
              name: fli.description,
              left_hand: fli.actual,
              operator: "*",
              right_hand: 1,
              show_in_offer: true,
              overridable_id: fli.id,
              overridable_type: "FinanceLineItem",
              calc_type: "fixed"
            }
          })
      )

      items = items.concat(
        this.budget.settlement_line_items
          .filter((sli) => sli.section === "Additions")
          .map((item) => {
            return Object.assign({}, item, {
              left_hand: item.amount,
              operator: "*",
              right_hand: item.quantity,
              show_in_offer: true,
              overridable_id: item.id,
              overridable_type: "SettlementLineItem",
              calc_type: "fixed"
            });
          })
      );
    }

    return this.postProcessSectionItems(items, "Additions");
  }

  buildDeductionsItems() {
    var items = [];

    if(this.isSettling){
      items = items.concat(
        this.budget.deposits
          .filter(deposit => deposit.paid)
          .map(deposit => {
            return {
              name: "Deposit",
              left_hand: deposit.amount,
              operator: "*",
              right_hand: 1,
              show_in_offer: true,
              overridable_id: deposit.id,
              overridable_type: "Deposit",
              calc_type: "fixed"
            }
          })
      );

      items = items.concat(
        this.budget.finance_items
          .filter((fi) => fi.on_settlement)
          .filter((fi) => !fi.is_income)
          .filter((fi) => fi.expense_type === "Deduction")
          .map((fi) => fi.finance_line_items).flat()
          .map((fli) => {
            return {
              name: fli.description,
              left_hand: fli.actual,
              operator: "*",
              right_hand: 1,
              show_in_offer: true,
              overridable_id: fli.id,
              overridable_type: "FinanceLineItem",
              calc_type: "fixed"
            }
          })
      );

      items = items.concat(
        this.budget.settlement_line_items
          .filter((sli) => sli.section === "Deductions")
          .map((item) => {
            return Object.assign({}, item, {
              left_hand: item.amount,
              operator: "*",
              right_hand: item.quantity,
              show_in_offer: true,
              overridable_id: item.id,
              overridable_type: "SettlementLineItem",
              calc_type: "fixed"
            });
          })
      );
    }

    return this.postProcessSectionItems(items, "Deductions");
  }

  calculateSettlementSection(section) {
    var items = section.items
      .map((item) => {
        var calculated = this.calculateLineItem(item);
        return Object.assign(item, calculated);
      });

    var leftTotal = items
      .filter((item) => this.shouldShow(item))
      .reduce((summed, item) => {
        return summed + item.calculatedLeftHand;
      }, 0);

    var rightTotal = items
      .filter((item) => this.shouldShow(item))
      .reduce((summed, item) => {
        return summed + item.calculatedRightHand;
      }, 0);

    var total = items
      .filter((item) => this.shouldShow(item))
      .reduce((summed, item) => {
        return summed + item.calculatedTotal;
      }, 0);

    var leftForecastTotal = items
      .reduce((summed, item) => {
        return summed + item.calculatedLeftHandForecast;
      }, 0);

    var rightForecastTotal = items
      .reduce((summed, item) => {
        return summed + item.calculatedRightHandForecast;
      }, 0);

    var forecastTotal = items
      .reduce((summed, item) => {
        return summed + item.calculatedForecastTotal;
      }, 0);

    return {
      items: items,
      leftTotal: leftTotal,
      rightTotal: rightTotal,
      total: total,
      leftForecastTotal: leftForecastTotal,
      rightForecastTotal: rightForecastTotal,
      forecastTotal: forecastTotal
    }
  }

  calculateLineItem(item) {
    let calculatedTotal;
    let calculatedForecastTotal;

    let calculatedLeftHand = (
      item.left_hand ? this.parseMaskedInput(item.left_hand) : 0.00
    );

    let calculatedLeftHandForecast = (
      item.left_hand_forecast ? this.parseMaskedInput(item.left_hand_forecast) : 0.00
    );

    let calculatedRightHand;
    let calculatedRightHandForecast;

    switch(item.calc_type) {
      case "fixed":
        calculatedRightHand = item.right_hand ? this.parseMaskedInput(item.right_hand) : 0.00;
        calculatedRightHandForecast = item.right_hand_forecast ? this.parseMaskedInput(item.right_hand_forecast) : 0.00;
        break;
      case "per_tickets_sold":
        calculatedRightHand = this.numberOfPaidTickets();
        calculatedRightHandForecast = this.numberOfPaidTicketsForecast();
        break;
      case "percentage_of_gross":
        calculatedRightHand = this.ticketsSection.total + this.revenueSection.total;
        calculatedRightHandForecast = this.ticketsSection.forecastTotal + this.revenueSection.forecastTotal;
        break;
      case "percentage_of_net_gross":
        calculatedRightHand = this.showSummary.netBoxOfficeReceipts;
        calculatedRightHandForecast = this.showSummary.netBoxOfficeReceiptsForecast;
        break;
      case "percentage_of_net":
        calculatedRightHand = this.showSummary.showNetAfterExpenses;
        calculatedRightHandForecast = this.showSummary.showNetAfterExpensesForecast;
        break;
      case "percentage_of_expenses":
        calculatedRightHand = this.expensesSection.total;
        calculatedRightHandForecast = this.expensesSection.forecastTotal;
        break;
      default:
        throw `Unknown calc_type (${item.calc_type}) while calculating line item total`;
    }

    // calculatedRightHand differs from calculatedLeftHand in that it does not always pull
    // from the item.right_hand property. It sometimes derived based on the calc_type instead.
    // If the item has a settlementOverride, we should use the right_hand property as the
    // calculatedRightHand value because rigth_hand has already been adjusted by the override.
    if(item.settlementOverride) {
      calculatedRightHand = this.parseMaskedInput(item.right_hand);
    }

    switch(item.operator) {
      case "+":
        calculatedTotal = (calculatedLeftHand + calculatedRightHand);
        calculatedForecastTotal = (calculatedLeftHandForecast + calculatedRightHandForecast);
        break;
      case "-":
        calculatedTotal = (calculatedLeftHand - calculatedRightHand);
        calculatedForecastTotal = (calculatedLeftHandForecast - calculatedRightHandForecast);
        break;
      case "*":
        calculatedTotal = (calculatedLeftHand * calculatedRightHand);
        calculatedForecastTotal = (calculatedLeftHandForecast * calculatedRightHandForecast);
        break;
      case "of":
        calculatedTotal = Math.round(calculatedLeftHand * calculatedRightHand) / 100;
        calculatedForecastTotal = Math.round(calculatedLeftHandForecast * calculatedRightHandForecast) / 100;
        break;
      case "/":
        calculatedTotal = (calculatedLeftHand / calculatedRightHand);
        calculatedForecastTotal = (calculatedLeftHandForecast / calculatedRightHandForecast);
        break;
      default:
        throw "Unknown operator while calculating line item total";
    }

    return {
      calculatedLeftHand: calculatedLeftHand,
      calculatedRightHand: calculatedRightHand,
      calculatedTotal: calculatedTotal,
      calculatedLeftHandForecast: calculatedLeftHandForecast,
      calculatedRightHandForecast: calculatedRightHandForecast,
      calculatedForecastTotal: calculatedForecastTotal
    };
  }

  calculateNetBoxOfficeReceipts() {
    var netBoxOfficeReceipts = (
      this.ticketsSection.total
        + this.revenueSection.total
        - this.beforeAdjustedDeductionsSection.total
    );

    var netBoxOfficeReceiptsForecast = (
      this.ticketsSection.forecastTotal
        + this.revenueSection.forecastTotal
        - this.beforeAdjustedDeductionsSection.forecastTotal
    );

    return {
      ...this.showSummary,
      netBoxOfficeReceipts: netBoxOfficeReceipts,
      netBoxOfficeReceiptsForecast: netBoxOfficeReceiptsForecast,
    }
  }

  calculateShowNetAfterExpenses() {
    var showNetAfterExpenses = (
      this.showSummary.netBoxOfficeReceipts
        - this.expensesSection.total
    );

    var showNetAfterExpensesForecast = (
      this.showSummary.netBoxOfficeReceiptsForecast
        - this.expensesSection.forecastTotal
    );

    return {
      ...this.showSummary,
      showNetAfterExpenses: showNetAfterExpenses,
      showNetAfterExpensesForecast: showNetAfterExpensesForecast
    }
  }

  calculateShowSummary() {
    var showNetAfterFinalExpenses = (
      this.showSummary.showNetAfterExpenses
        - this.finalExpensesSection.total
    );

    var showNetAfterFinalExpensesForecast = (
      this.showSummary.showNetAfterExpensesForecast
        - this.finalExpensesSection.forecastTotal
    );

    var artistGuarantee = this.budget.dollar_amount ?
      this.parseMaskedInput(this.budget.dollar_amount) : 0.00;

    var promoterProfit = 0.00;
    if(this.budget.promoter_profit) {
      var allExpenses = this.expensesSection.total + this.finalExpensesSection.total + artistGuarantee;
      promoterProfit = Math.round(allExpenses * this.budget.promoter_profit_percentage) / 100.0;
    }

    var promoterProfitForecast = 0.00;
    if(this.budget.promoter_profit) {
      var allExpensesForecast = this.expensesSection.forecastTotal + this.finalExpensesSection.forecastTotal + artistGuarantee;
      promoterProfitForecast = Math.round(allExpensesForecast * this.budget.promoter_profit_percentage) / 100.0;
    }

    var showNetAfterPromoterProfit = (
      showNetAfterFinalExpenses
        - promoterProfit
    );

    var showNetAfterPromoterProfitForecast = (
      showNetAfterFinalExpensesForecast
        - promoterProfitForecast
    );

    var splitPoint = (
      artistGuarantee
        + this.expensesSection.total
        + this.finalExpensesSection.total
        + promoterProfit
    );

    var splitPointForecast = (
      artistGuarantee
        + this.expensesSection.forecastTotal
        + this.finalExpensesSection.forecastTotal
        + promoterProfitForecast
    );

    var subtractFromShowNet = (
      this.budget.dollar_or_percentage_operator === 'AND' ?
        artistGuarantee : 0.00
    );

    var toBeSharedAmount = this.zeroMinimum(
      showNetAfterPromoterProfit
        - subtractFromShowNet
    );

    var toBeSharedAmountForecast = this.zeroMinimum(
      showNetAfterPromoterProfitForecast
        - subtractFromShowNet
    );

    var upsidePercentage =
      this.finalUpsidePercentage(this.numberOfPaidTickets());

    var artistPercentage = (
      (upsidePercentage / 100.0)
        * toBeSharedAmount
    );

    var upsidePercentageForecast =
      this.finalUpsidePercentage(this.numberOfPaidTicketsForecast());

    var artistPercentageForecast = (
      (upsidePercentageForecast / 100.0)
        * toBeSharedAmountForecast
    );

    return {
      ...this.showSummary,
      expenses: this.expensesSection.total,
      showNetAfterFinalExpenses: showNetAfterFinalExpenses,
      showNetAfterFinalExpensesForecast: showNetAfterFinalExpensesForecast,
      promoterProfit: promoterProfit,
      promoterProfitForecast: promoterProfitForecast,
      showNetAfterPromoterProfit: showNetAfterPromoterProfit,
      showNetAfterPromoterProfitForecast: showNetAfterPromoterProfitForecast,
      splitPoint: splitPoint,
      splitPointForecast: splitPointForecast,
      toBeSharedAmount: toBeSharedAmount,
      toBeSharedAmountForecast: toBeSharedAmountForecast,
      artistGuarantee: artistGuarantee,
      artistPercentage: artistPercentage,
      artistPercentageForecast: artistPercentageForecast,
      upsidePercentage: upsidePercentage
    }
  }

  calculateArtistEarnings() {
    var artistGuaranteeAndOrPercentage;

    if(this.budget.dollar_or_percentage_operator === 'AND') {
      artistGuaranteeAndOrPercentage = (
        this.showSummary.artistGuarantee
          + this.showSummary.artistPercentage
      )
    } else {
      artistGuaranteeAndOrPercentage = (
        this.showSummary.artistGuarantee >= this.showSummary.artistPercentage ?
          this.showSummary.artistGuarantee : this.showSummary.artistPercentage
      )
    }

    this.artistTotalDue = this.zeroMinimum(
      artistGuaranteeAndOrPercentage
        + this.additionsSection.total
        - this.deductionsSection.total
    );

    this.ticketsToBreakeven = this.calculateTicketsToBreakeven();

    return {
      artistGuaranteeAndOrPercentage: artistGuaranteeAndOrPercentage,
      totalAdditions: this.additionsSection.total,
      totalDeductions: this.deductionsSection.total,
      artistTotalDue: this.artistTotalDue,
      ticketsToBreakeven: this.ticketsToBreakeven.averagedBreakeven
    }
  }

  calculateArtistEarningsForecast() {
    var artistGuaranteeAndOrPercentage;

    if(this.budget.dollar_or_percentage_operator === 'AND') {
      artistGuaranteeAndOrPercentage = (
        this.showSummary.artistGuarantee
          + this.showSummary.artistPercentageForecast
      )
    } else {
      artistGuaranteeAndOrPercentage = (
        this.showSummary.artistGuarantee >= this.showSummary.artistPercentageForecast ?
          this.showSummary.artistGuarantee : this.showSummary.artistPercentageForecast
      )
    }

    this.artistTotalDueForecast = this.zeroMinimum(
      artistGuaranteeAndOrPercentage
        + this.additionsSection.forecastTotal
        - this.deductionsSection.forecastTotal
    );

    this.ticketsToBreakevenForecast = this.calculateTicketsToBreakevenForecast();

    return {
      artistGuaranteeAndOrPercentage: artistGuaranteeAndOrPercentage,
      totalAdditions: this.additionsSection.forecastTotal,
      totalDeductions: this.deductionsSection.forecastTotal,
      artistTotalDue: this.artistTotalDueForecast,
      ticketsToBreakeven: this.ticketsToBreakevenForecast.averagedBreakeven
    }
  }

  calculateVenueNetPotential() {
    var venueNetPotential = (
      this.showSummary.showNetAfterFinalExpenses
        - this.artistEarnings.artistTotalDue
    );

    return {
      ...this.showSummary,
      venueNetPotential: venueNetPotential
    }
  }

  calculateVenueNetPotentialForecast() {
    var venueNetPotentialForecast = (
      this.showSummary.showNetAfterFinalExpensesForecast
        - this.artistEarningsForecast.artistTotalDue
    );

    return {
      ...this.showSummary,
      venueNetPotentialForecast: venueNetPotentialForecast
    }
  }

  finalUpsidePercentage(paidTicketsCount) {
    var qualifyingBonus = this.budget.budget_bonuses
      .filter((b) => b.upside_percentage)
      .filter((b) => b.ticket_count_requirement)
      .filter((b) => b.dollar_or_percentage_operator === "PERCENTAGE")
      .filter((b) => b.ticket_count_requirement <= paidTicketsCount)
      .sort((a, b) => a.ticket_count_requirement - b.ticket_count_requirement)
      .pop();

    if(qualifyingBonus) {
      return this.parseMaskedInput(qualifyingBonus.upside_percentage);
    } else {
      return this.budget.upside_percentage
        ? this.parseMaskedInput(this.budget.upside_percentage) : 0.00;
    }
  }

  postProcessSectionItems(items, section) {
    return items.map((item) => {
      item = Object.assign({}, item, {
        section: section
      });

      if (this.isSettling) {
        var settlementOverride = this.budget.settlement_overrides
          .find((o) => {
            return (
              o.overridable_id === item.overridable_id
                && o.overridable_type === item.overridable_type
                && o.section === item.section
                && parseFloat(o.amount) === parseFloat(item.left_hand)
            );
          });

        settlementOverride && (item = Object.assign({}, item, {
          name: settlementOverride.new_name,
          left_hand: parseFloat(settlementOverride.new_amount),
          right_hand: settlementOverride.new_quantity,
          settlementOverride: settlementOverride
        }));

        var originalAmount = settlementOverride ? settlementOverride.amount : item.left_hand;

        var settlementVisibility = this.budget.settlement_visibilities
          .find((o) => {
            return (
              o.settleable_id === item.overridable_id
                && o.settleable_type === item.overridable_type
                && o.section === item.section
                && parseFloat(o.amount) === parseFloat(originalAmount)
            );
          });

          settlementVisibility && (item = Object.assign({}, item, {
            settlementVisibility: settlementVisibility
          }));
      }

      return item;
    })
  }

  calculateTicketsToBreakeven() {
    var totalExpenses = (
      this.showSummary.artistGuarantee
        + this.expensesSection.total
        + this.finalExpensesSection.total
        + this.additionsSection.total
    );

    var breakevenByIncomeItem = this.ticketsSection.items
      .filter((item) => item.calculatedLeftHand > 0.00)
      .filter((item) => item.calculatedRightHand > 0.00)
      .map((item) => {
        return {
          item: item,
          breakeven: Math.ceil(totalExpenses / item.calculatedLeftHand)
        }
      });

    var totalBreakevens = breakevenByIncomeItem.reduce((summed, item) => {
      return summed + item.breakeven;
    }, 0);

    var averagedBreakeven;
    if(breakevenByIncomeItem.length > 0){
      averagedBreakeven = Math.ceil(totalBreakevens / breakevenByIncomeItem.length);
    } else {
      averagedBreakeven = undefined;
    }

    return {
      totalExpenses: totalExpenses,
      breakevenByIncomeItem: breakevenByIncomeItem,
      totalBreakevens: totalBreakevens,
      averagedBreakeven: averagedBreakeven
    }
  }

  calculateTicketsToBreakevenForecast() {
    var totalExpenses = (
      this.showSummary.artistGuarantee
        + this.expensesSection.forecastTotal
        + this.finalExpensesSection.forecastTotal
        + this.additionsSection.forecastTotal
    );

    var breakevenByIncomeItem = this.ticketsSection.items
      .filter((item) => item.calculatedLeftHandForecast > 0.00)
      .filter((item) => item.calculatedRightHandForecast > 0.00)
      .map((item) => {
        return {
          item: item,
          breakeven: Math.ceil(totalExpenses / item.calculatedLeftHandForecast)
        }
      });

    var totalBreakevens = breakevenByIncomeItem.reduce((summed, item) => {
      return summed + item.breakeven;
    }, 0);

    var averagedBreakeven;
    if(breakevenByIncomeItem.length > 0){
      averagedBreakeven = Math.ceil(totalBreakevens / breakevenByIncomeItem.length);
    } else {
      averagedBreakeven = undefined;
    }

    return {
      totalExpenses: totalExpenses,
      breakevenByIncomeItem: breakevenByIncomeItem,
      totalBreakevens: totalBreakevens,
      averagedBreakeven: averagedBreakeven
    }
  }
}
