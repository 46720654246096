import { combineReducers } from 'redux';

import {
  CLOSE_MODAL,
  CREATE_CALENDAR_EVENT_CHANGED,
  EVENT_NAME_CHANGED,
  VENUE_ID_CHANGED,
  START_TIME_CHANGED,
  PRIMARY_ARTIST_CHANGED,
  CALENDAR_EVENT_CHANGED,
  PERFORMERS_CHANGED,
  PERFORMER_ID_CHANGED,
  SELECTED_ARTIST_CHANGED,
  CLEAR_SELECTED_ARTIST,
  EVENT_TEMPLATE_CHANGED,
} from '../constants/offerV2ModalConstants';

export const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const onClose = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const offer = (state = {}, action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return {};
    case CREATE_CALENDAR_EVENT_CHANGED:
      return Object.assign({}, state, {create_calendar_event: action.createCalendarEvent});
    case EVENT_NAME_CHANGED:
      return Object.assign({}, state, {event_name: action.name});
    case EVENT_TEMPLATE_CHANGED:
      return Object.assign({}, state, {event_template: action.eventTemplate});
    case VENUE_ID_CHANGED:
      return Object.assign({}, state, {venue_id: action.venueId});
    case START_TIME_CHANGED:
      return Object.assign({}, state, {start_time: action.date});
    case PRIMARY_ARTIST_CHANGED:
      return Object.assign({}, state, {
        primary_artist: action.artist,
        event_name: action.artist.label
      });
    case CLEAR_SELECTED_ARTIST:
      return Object.assign({}, state, {
        primary_artist: null,
        event_name: ""
      });
    case CALENDAR_EVENT_CHANGED:
      return Object.assign({}, state, {
        calendar_event_id: (action.calendarEvent && action.calendarEvent.value ? action.calendarEvent.value : null),
        performer_id: null
      });
    case PERFORMER_ID_CHANGED:
      return Object.assign({}, state, {performer_id: action.performerId});
    default:
      return state;
  }
};

export const venueSelected = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const calendarEvent = (state = {}, action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return {};
    case CALENDAR_EVENT_CHANGED:
      return (action.calendarEvent ? action.calendarEvent : {});
    default:
      return state;
  }
};

export const performers = (state = [], action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return [];
    case CALENDAR_EVENT_CHANGED:
      return [];
    case PERFORMERS_CHANGED:
      return action.performers;
    default:
      return state;
  }
};

export const selectedArtist = (state = {}, action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return {};
    case SELECTED_ARTIST_CHANGED:
      return action.artist;
    case CLEAR_SELECTED_ARTIST:
      return {};
    default:
      return state;
  }
};

export const pipeline = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerV2ModalReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  venuesOptions,
  onClose,
  offer,
  venueSelected,
  calendarEvent,
  performers,
  selectedArtist,
  pipeline,
  venueOwnerships
});

export default offerV2ModalReducer;
