import { combineReducers } from 'redux';

import {
  SELECTED_VENUE_OWNERSHIP_CHANGED,
  SELECTED_VENUE_OWNERSHIP_EDITOR_STATE_CHANGED,
  VENUE_OWNERSHIP_UPDATED
} from '../constants/ticketingSettingsConstants';

import {stateFromHTML} from 'draft-js-import-html';
import {EditorState} from 'draft-js';

const convertToEditorState = (venueOwnership) => {
  var needsConverted = (
    !venueOwnership.ticket_confirmation_info
      || typeof(venueOwnership.ticket_confirmation_info) === "string"
  );

  if(!needsConverted){
    return venueOwnership;
  }

  var html = (venueOwnership.ticket_confirmation_info || "");
  var editorState = stateFromHTML(html);

  return Object.assign({}, venueOwnership, {
    ticket_confirmation_info: EditorState.createWithContent(editorState)
  });
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch (action.type) {
    case VENUE_OWNERSHIP_UPDATED:
      return [...state].map((vo) => {
        if(vo.id === action.venueOwnership.id){
          return action.venueOwnership;
        } else {
          return vo;
        }
      })
    default:
      return state;
  }
};

const selectedVenueOwnership = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_VENUE_OWNERSHIP_EDITOR_STATE_CHANGED:
      return Object.assign({}, state, {ticket_confirmation_info: action.editorState});
    case SELECTED_VENUE_OWNERSHIP_CHANGED:
      return convertToEditorState(action.venueOwnership);
    case VENUE_OWNERSHIP_UPDATED:
      return convertToEditorState(action.venueOwnership);
    default:
      return convertToEditorState(state);
  }
};

const maxDescriptorLength = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const ticketingSettingsReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  venueOwnerships,
  selectedVenueOwnership,
  maxDescriptorLength,
});

export default ticketingSettingsReducer;
