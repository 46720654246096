/* eslint-disable import/prefer-default-export */

const axios = require('axios').default;
import { EVENT_PERMALINK_UPDATED, EVENT_UPDATED } from '../constants/dataTableWithSidebarViewConstants';

export const fetchEventData = (dispatch, permalink) => {
  dispatch(updateEventPermalink(permalink));

  return dispatch => {
    return axios.get("/events/" + permalink + ".json")
      .then(({ data }) => {
        dispatch(updateEvent(data));
      });
  };
};

export const updateEventPermalink = (permalink) => ({
  type: EVENT_PERMALINK_UPDATED,
  permalink: permalink
});

export const updateEvent = (event) => ({
  type: EVENT_UPDATED,
  event: event
});
