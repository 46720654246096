import PropTypes from 'prop-types';
import React from 'react';

const canSubmitNewInvitation = (invitation, roles) => {
  var role = roles.find((r) => r.id === invitation.roleId);

  if(!role){
    return false;
  }

  if(role.name === "Promoter"){
    return (
      invitation.firstName
        && invitation.lastName
        && invitation.email
        && invitation.roleId
        && invitation.company
    );
  } else {
    return (
      invitation.firstName
        && invitation.lastName
        && invitation.email
        && invitation.roleId
    );
  }
}

const NewInvitationForm = ({ invitation, cancel, newInvitationFirstNameChanged, newInvitationLastNameChanged, newInvitationEmailChanged, roles, newInvitationRoleIdChanged, venueOwnerships, newInvitationVenueOwnershipClicked, createInvitation, csrfToken, team, newInvitationEnableAllVenues, newInvitationEnableAllVenuesClicked, newInvitationDisableAllVenuesClicked, newInvitationCompanyChanged }) => (
  <div className="card-body">
    <div className="row">
      <div className="col">
        <form onSubmit={
          (e) => {
            if(canSubmitNewInvitation(invitation, roles)){
              createInvitation(csrfToken, venueOwnerships, invitation, team);
            }

            e.preventDefault();
          }
        }>
          <div className="form-row">
            <div className="col-12 col-sm-6 col-md-3">
              <input type="text"
                     className="form-control my-2"
                     defaultValue={invitation.firstName}
                     onChange={
                       (e) => {
                         newInvitationFirstNameChanged(e.target.value);
                       }
                     }
                     placeholder="First name..." />
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <input type="text"
                     className="form-control my-2"
                     defaultValue={invitation.lastName}
                     onChange={
                       (e) => {
                         newInvitationLastNameChanged(e.target.value);
                       }
                     }
                     placeholder="Last name..." />
            </div>
            <div className="col-12 col-md-6">
              <input type="email"
                     className="form-control my-2"
                     defaultValue={invitation.email}
                     onChange={
                       (e) => {
                         newInvitationEmailChanged(e.target.value);
                       }
                     }
                     placeholder="Email address..." />
            </div>
          </div>
          <div className="form-row pt-3">
            <div className="col-12">
              <table className="table table-sm table-borderless">
                <thead>
                  <tr>
                    <th colSpan={2} className="strong text-muted pl-0">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {roles.map((role, index) => (
                    <tr key={index}>
                      <td className="p-0">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input"
                                 type="radio"
                                 id={"new-invitation-role-" + role.id}
                                 checked={role.id === invitation.roleId}
                                 onChange={
                                   (e) => {
                                     newInvitationRoleIdChanged(role.id);
                                   }
                                 }
                                 name="new-invitation-role" />
                          <label className="mb-0" htmlFor={"new-invitation-role-" + role.id}>
                            <strong>
                              {role.display_name}
                            </strong>
                          </label>
                        </div>
                      </td>
                      <td className="p-0">
                        <label className="mb-0 text-muted" htmlFor={"new-invitation-role-" + role.id}>
                          {role.description}
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {roles.find((r) => r.id == invitation.roleId && r.name === "Promoter") ? (
            <div className="form-row">
              <div className="col-12 col-md-6 col-lg-4">
                <input type="text"
                       className="form-control form-control-sm"
                       style={{"borderRadius": "8px"}}
                       value={invitation.company || ""}
                       onChange={
                         (e) => {
                           newInvitationCompanyChanged(e.target.value);
                         }
                       }
                       placeholder="Company..." />
              </div>
            </div>
          ) : null}
          <div className="form-row pt-3">
            <div className="col-12">
              <table className="table table-sm table-borderless" style={{tableLayout: "fixed"}}>
                <thead>
                  <tr>
                    <th className="strong text-muted pl-0">Venue</th>
                    <th className="strong text-muted">
                      Access
                      &nbsp;
                      {newInvitationEnableAllVenues ? (
                        <a href="#"
                           onClick={
                             (e) => {
                               e.preventDefault();
                               newInvitationEnableAllVenuesClicked(venueOwnerships);
                             }
                           }
                           className="text-muted">
                          <u>Enable All</u>
                        </a>
                      ) : (
                        <a href="#"
                           onClick={
                             (e) => {
                               e.preventDefault();
                               newInvitationDisableAllVenuesClicked();
                             }
                           }
                           className="text-muted">
                          <u>Disable All</u>
                        </a>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {venueOwnerships.map((venueOwnership, index) => (
                    <tr key={index}>
                      <td className="p-0">
                        <label htmlFor={"new-invitation-venue-ownership-" + venueOwnership.id} >
                          <strong>{venueOwnership.venueable.name}</strong>
                        </label>
                      </td>
                      <td className="p-0">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox"
                                 checked={
                                   ((invitation.venueOwnershipIds && invitation.venueOwnershipIds.includes(venueOwnership.id)) ? true : false)
                                 }
                                 id={"new-invitation-venue-ownership-" + venueOwnership.id}
                                 onChange={
                                   (e) => {
                                     newInvitationVenueOwnershipClicked(venueOwnership.id);
                                   }
                                 }
                                 className="custom-control-input" />
                          <label className="custom-control-label"
                                 htmlFor={"new-invitation-venue-ownership-" + venueOwnership.id} >
                            &nbsp;
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {invitation.errors && invitation.errors.length > 0 ? (
            <div className="form-group">
              <div className="p-3 mb-2 bg-danger text-white rounded">
                <ul className="mb-0 no-bullets">
                  {invitation.errors.map((error, key) =>
                    <li key={key}>{error}</li>
                  )}
                </ul>
              </div>
            </div>
          ) : null}
          <div className="form-row pt-3">
            <div className="col-12 text-right">
              <a href="#"
                 onClick={
                   (e) => {
                     e.preventDefault();
                     cancel();
                   }
                 } >
                <strong>Cancel</strong>
              </a>
              <button type="submit"
                      disabled={!canSubmitNewInvitation(invitation, roles)}
                      className="btn btn-primary ml-3">
                Add Team Member
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

NewInvitationForm.propTypes = {
  invitation: PropTypes.object,
  cancel: PropTypes.func.isRequired,
  newInvitationFirstNameChanged: PropTypes.func.isRequired,
  newInvitationLastNameChanged: PropTypes.func.isRequired,
  newInvitationEmailChanged: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  newInvitationRoleIdChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array.isRequired,
  newInvitationVenueOwnershipClicked: PropTypes.func.isRequired,
  createInvitation: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  newInvitationEnableAllVenues: PropTypes.bool,
  newInvitationEnableAllVenuesClicked: PropTypes.func.isRequired,
  newInvitationDisableAllVenuesClicked: PropTypes.func.isRequired,
  newInvitationCompanyChanged: PropTypes.func.isRequired
};

export default NewInvitationForm;
