import PropTypes from 'prop-types';
import React from 'react';

const axios = require('axios').default;
import AsyncSelect from 'react-select/async';

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const debouncedFetch = _.debounce((inputValue, callback) => {
  new Promise(resolve => {
    axios.get('/artists/search', {
        params: {
          name: inputValue
        }
      })
      .then(function (response) {
        var newOptions = response.data.map(function(data){
          return {
            object: { artistable: data },
            value: data.id,
            label: data.name,
            permalink: data.permalink,
          };
        });

        callback(newOptions);
      })
  });
}, 250);

const ArtistDetail = ({
  offer,
  offerChanged,
  performer,
}) => {
  const selected = performer.id === offer.performer?.id;

  const disabled = (
    performer.offer
      && performer.offer.id
      && performer.offer.id !== offer.id
  );

  return (
    <div className={`col-12 p-2 mt-2 selectable-card ${selected ? "selected" : null} ${disabled ? "disabled " : null}`}>
      <div
        className="row"
        onClick={(e) => {
          e.preventDefault();

          if(!selected && !disabled) {
            offerChanged({
              ...offer,
              savePerformer: true,
              performer: {
                ...performer,
                option: {
                  value: performer.id,
                  label: performer.artistable.name,
                }
              }
            });
          }
        }}
      >
        <div className="col-xs-auto d-flex" style={{'paddingLeft': '20px'}}>
          <div className="custom-control custom-radio align-self-center">
            <input type="radio"
                   checked={selected}
                   onChange={() => {}}
                   disabled={disabled}
                   id={"offer-selected-artist"}
                   className="custom-control-input" />
            <label className="custom-control-label"
                   htmlFor={"offer-selected-artist"}>
              &nbsp;
            </label>
          </div>
        </div>
        <div className="col-xs-auto" style={{'paddingLeft': '15px'}}>
          <img src={performer.artistable?.image_thumb_url_for_json}
               className="img-fluid rounded-circle mr-2 less-opacity-when-disabled"
               style={{"height": "60px", "width": "60px"}} />
        </div>
        <div className="col less-opacity-when-disabled">
          <h5 className="mb-0">
            <strong>
              {performer.artistable?.name}
            </strong>
          </h5>
          {performer.artistable?.primary_agent ? (
            <span>
              <p className='mb-0 small'>{performer.artistable?.primary_agent.name}</p>
              <p className='mb-0 text-muted small'>{performer.artistable?.primary_agent.email}</p>
            </span>
          ) : (
            <span>
              <p className='mb-0 text-muted small'>Agent info needed.</p>
            </span>
          )}
        </div>
        {disabled ? (
          <div className="col-xs-auto d-flex" style={{'paddingRight': '15px'}}>
            <div className="align-self-center">
              <span className="badge badge-secondary p-2" style={{"fontSize": "12px"}}>
                <strong>Offer Exists</strong>
              </span>
            </div>
          </div>
        ) : null}
        {selected ? (
          <div className="col-xs-auto" style={{'paddingRight': '15px'}}>
            <a
              href='#'
              className='mt-3 d-inline-block'
              onClick={e => {
                e.cancelBubble = true;
                e.stopPropagation();
                e.preventDefault();

                offerChanged({
                  ...offer,
                  performer: null
                });
              }}
              style={{'color': '#1982C4'}}
              >
              <i className="fa fa-times-circle"></i>
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );

}

const AttachArtistToOffer = ({
  csrfToken,
  currentUser,
  team,
  confirmPerformers,
  offer,
  offerChanged,
  close,
  handleAttachArtist,
}) => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <h5 className="mb-2">
                <div className="strong">Link Artist to Offer</div>
              </h5>
              <p
                className="mb-3 text-muted"
                style={{fontSize: "16px"}}
              >
                Link this offer to an artist.
              </p>
            </div>
            <div className="col-xs-auto">
              <a
                href="#"
                className="text-dark"
                style={{"paddingRight": "15px"}}
                onClick={e => {
                  e.preventDefault();
                  close();
                }}
              >
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {offer.performer ? (
                <ArtistDetail
                  offer={offer}
                  offerChanged={offerChanged}
                  performer={offer.performer}
                />
              ) : (
                <AsyncSelect
                  cacheOptions
                  styles={selectStyles}
                  menuPortalTarget={document.querySelector('body')}
                  placeholder="Search for an artist"
                  defaultOptions
                  id="offer-primary-artist"
                  onChange={option => {
                    offerChanged({
                      ...offer,
                      savePerformer: true,
                      performer: {
                        ...option.object,
                        option: {
                          value: option.value,
                          label: option.label,
                        }
                      }
                    })
                  }}
                  value={offer.performer?.option}
                  loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback)}
                />
              )}
            </div>
          </div>
          {!offer.performer && confirmPerformers.length > 0 ? (
            <div className="mt-3 row">
              <div className="col">
                <span className="text-muted text-uppercase small">
                  Choose an artist
                </span>
                {confirmPerformers.map((performer, index) => (
                  <ArtistDetail
                    key={index}
                    offer={offer}
                    offerChanged={offerChanged}
                    performer={performer}
                  />
                ))}
              </div>
            </div>
          ) : null}
          <div className="mt-4 row">
            <div className="col text-right">
              <a
                href="#"
                className="btn text-primary"
                onClick={e => {
                  e.preventDefault();

                  close();
                }}
              >
                Cancel
              </a>
              <a
                href="#"
                className="ml-2 btn btn-primary"
                onClick={e => {
                  e.preventDefault();

                  handleAttachArtist(offer);

                  offerChanged({
                    ...offer,
                    savePerformer: false
                  })
                }}
              >
                Attach
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AttachArtistToOffer.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  confirmPerformers: PropTypes.array.isRequired,
  offer: PropTypes.object.isRequired,
  offerChanged: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  handleAttachArtist: PropTypes.func.isRequired,
};

export default AttachArtistToOffer;
