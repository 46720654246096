import React, { createContext } from 'react';
import { Provider } from 'react-redux';

import configureStore from '../store/webOrderFormStore';
import WebOrderFormContainer from '../containers/WebOrderFormContainer';
import ActionCable from "actioncable";

const CableApp = {};
CableApp.cable = ActionCable.createConsumer('/cable');
const ActionCableContext = createContext();

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const WebOrderFormApp = (props) => (
  <Provider store={configureStore(props)}>
    <ActionCableContext.Provider value={CableApp}>
      <WebOrderFormContainer />
    </ActionCableContext.Provider>
  </Provider>
);

export default WebOrderFormApp;
export { ActionCableContext };
