// Simple example of a React "smart" component

import { connect } from 'react-redux';
import ArtistRepresentation from '../components';

const mapStateToProps = (state) => ({
  agencies: state.agencies,
  csrfToken: state.csrfToken,
  artistId: state.artistId,
});

export default connect(mapStateToProps, null)(ArtistRepresentation);
