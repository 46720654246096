import { combineReducers } from 'redux';
import {
  ADDING_ACCOUNT_CHANGED,
  AUTHORIZED_PAGE_DELETED
} from '../constants/socialManagerConstants';

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const addingAccount = (state = false, action) => {
  switch (action.type) {
    case ADDING_ACCOUNT_CHANGED:
      return action.addingAccount;
    default:
      return state;
  }
};

const twitterProviderId = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const facebookProviderId = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onepostPublicKey = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const authorizedPages = (state = [], action) => {
  switch (action.type) {
    case AUTHORIZED_PAGE_DELETED:
      return state.filter((authorizedPage) => {
        return authorizedPage.id !== action.authorizedPage.id;
      });
    default:
      return state;
  }
};

const socialManagerReducer = combineReducers({ csrfToken, team, addingAccount, twitterProviderId, facebookProviderId, onepostPublicKey, authorizedPages });

export default socialManagerReducer;
