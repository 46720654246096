import { combineReducers } from 'redux';
import { TYPED_SEARCH_TERM_UPDATED, SEARCHED_SEARCH_TERM_UPDATED, FILTER_UPDATED, CLEAR_FILTER_VALUES, DATA_TABLE_EL_CHANGED } from '../constants/tableViewConstants';

const title = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const searchPlaceholder = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const typedSearchTerm = (state = '', action) => {
  switch (action.type) {
    case TYPED_SEARCH_TERM_UPDATED:
      return action.text;
    default:
      return state;
  }
};

const searchedSearchTerm = (state = '', action) => {
  switch (action.type) {
    case SEARCHED_SEARCH_TERM_UPDATED:
      return action.text;
    default:
      return state;
  }
};

const dataSource = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const columns = (state = [], action) => {
  switch (action.type) {
    case FILTER_UPDATED:
      if (action.filter.columns) {
        return action.filter.columns[action.value];
      } else {
        return state;
      }
    default:
      return state;
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const filters = (state = [], action) => {
  switch (action.type) {
    case CLEAR_FILTER_VALUES:
      return state.map((item, index) => {
        return Object.assign({}, item, {value: []})
      })
    case FILTER_UPDATED:
      return state.map((item, index) => {
        if (item === action.filter) {
          var arrayValue = Array(action.value).flat();
          var newItem = Object.assign({}, item, {value: arrayValue});

          return newItem;
        }

        return item;
      })
    default:
      return state;
  }
};

const tableClassNames = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csvDataSource = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const dataTableEl = (state = {}, action) => {
  switch (action.type) {
    case DATA_TABLE_EL_CHANGED:
      return action.el;
    default:
      return state;
  }
};

const rowCallback = (state = () => {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const layout = (state = "standard", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tableViewReducer = combineReducers({
  title,
  searchPlaceholder,
  typedSearchTerm,
  searchedSearchTerm,
  dataSource,
  columns,
  order,
  filters,
  tableClassNames,
  csvDataSource,
  dataTableEl,
  rowCallback,
  layout
});

export default tableViewReducer;
