/* eslint-disable import/prefer-default-export */

export const CLOSE_MODAL = 'CLOSE_MODAL';

export const CREATE_CALENDAR_EVENT_CHANGED = 'CREATE_CALENDAR_EVENT_CHANGED';
export const EVENT_NAME_CHANGED = 'EVENT_NAME_CHANGED';
export const EVENT_TEMPLATE_CHANGED = 'EVENT_TEMPLATE_CHANGED';
export const VENUE_ID_CHANGED = 'VENUE_ID_CHANGED';
export const START_TIME_CHANGED = 'START_TIME_CHANGED';
export const PRIMARY_ARTIST_CHANGED = 'PRIMARY_ARTIST_CHANGED';
export const PERFORMER_ID_CHANGED = 'PERFORMER_ID_CHANGED';

export const CALENDAR_EVENT_CHANGED = 'CALENDAR_EVENT_CHANGED';
export const PERFORMERS_CHANGED = 'PERFORMERS_CHANGED';

export const SELECTED_ARTIST_CHANGED = 'SELECTED_ARTIST_CHANGED';
export const CLEAR_SELECTED_ARTIST = 'CLEAR_SELECTED_ARTIST';
