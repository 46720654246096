import { createStore, applyMiddleware } from 'redux';
import ShowActivitySettingsReducer from '../reducers/ShowActivitySettingsReducer';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

const configureStore = (railsProps) => (
  createStore(ShowActivitySettingsReducer, railsProps, applyMiddleware(createDebounce(), thunk))
);

export default configureStore;
