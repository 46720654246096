import { combineReducers } from 'redux';
import {
  SELECTED_ARTIST_CHANGED,
  POTENTIAL_ARTIST_FAN_DATA_CHANGED,
  IS_LOADING_POTENTIAL_ARTIST_FAN_DATA_CHANGED,
  IS_LOADING_GEO_COORDINATES_CHANGED,
  GEO_COORDINATES_CHANGED,
  IS_LOADING_TOP_GENRES_CHANGED,
  TOP_GENRES_CHANGED
} from '../constants/fanDashboardConstants';

const csrfToken = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const topFans = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const lastMinuteBuyers = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const topGenres = (state = [], action) => {
  switch (action.type) {
    case TOP_GENRES_CHANGED:
      return action.topGenres;
    default:
      return state;
  }
};

const geoCoordinates = (state = [], action) => {
  switch (action.type) {
    case GEO_COORDINATES_CHANGED:
      return action.geoCoordinates;
    default:
      return state;
  }
};

const selectedArtist = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_ARTIST_CHANGED:
      return action.selectedArtist;
    default:
      return state;
  }
};

const potentialArtistFanData = (state = {}, action) => {
  switch (action.type) {
    case POTENTIAL_ARTIST_FAN_DATA_CHANGED:
      return action.potentialArtistFanData;
    default:
      return state;
  }
};

const isLoadingPotentialArtistFanData = (state = true, action) => {
  switch (action.type) {
    case IS_LOADING_POTENTIAL_ARTIST_FAN_DATA_CHANGED:
      return action.isLoadingPotentialArtistFanData;
    default:
      return state;
  }
};

const isLoadingGeoCoordinates = (state = true, action) => {
  switch (action.type) {
    case IS_LOADING_GEO_COORDINATES_CHANGED:
      return action.isLoadingGeoCoordinates;
    default:
      return state;
  }
};

const isLoadingTopGenres = (state = true, action) => {
  switch (action.type) {
    case IS_LOADING_TOP_GENRES_CHANGED:
      return action.isLoadingTopGenres;
    default:
      return state;
  }
};

const fanDashboardReducer = combineReducers({
  csrfToken,
  team,
  currentUser,
  topFans,
  lastMinuteBuyers,
  topGenres,
  geoCoordinates,
  selectedArtist,
  potentialArtistFanData,
  isLoadingPotentialArtistFanData,
  isLoadingGeoCoordinates,
  isLoadingTopGenres
});

export default fanDashboardReducer;
