import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlidingPane from "react-sliding-pane";

import DataTable from '../../DataTable/components/DataTable';
import EventSidebarView from './EventSidebarView';

const mainContentStyle = (browser) => {
  return {
    "paddingRight": (browser.lessThan.medium ? "15px" : "12px")
  }
}

const sideContentStyle = (browser) => {
  return {
    "paddingLeft": (browser.lessThan.medium ? "15px" : "12px")
  }
}

const DataTableWithSidebarView = ({ browser, closeEventModal, title, dataSource, order, columns, eventPermalink, fetchEventData, event, displayShowMore, isEventViewSliding }) => {
  const [showMore, setShowMore] = useState(true)

  const onClickShow = () => {
    setShowMore(!showMore);
  }

  const eventView = () => {
    return (
      <>
        {Object.keys(event).length > 0 && <>
          <div style={sideContentStyle(browser)} className={isEventViewSliding ? "col-12 order-md-12 p-0" : "col-12 col-md-6 col-lg-4 order-md-12"}>
            <EventSidebarView event={event} closeEventModal={closeEventModal} />
          </div>
        </>}
      </>
    )
  }

  return (
    <div>
      {(!displayShowMore || !showMore) && (
        <div className="row">
          {isEventViewSliding ? (
            <SlidingPane
              isOpen={Object.keys(event).length > 0}
              className="sliding-pane-container"
              width={"480px"}
              hideHeader={true}
              onRequestClose={() => {
                closeEventModal();
              }}
            >
              { eventView() }
            </SlidingPane>
          ) : (
            <>
              { eventView() }
            </>
          )}

          <div style={mainContentStyle(browser)} className={isEventViewSliding || Object.keys(event).length === 0 ? "col-12" : "col-12 col-md-6 col-lg-8 order-md-1"}>
            <DataTable title={title}
                      dataSource={dataSource}
                      detailTable={isEventViewSliding}
                      columns={columns}
                      tableClassNames="rows-clickable"
                      onRowClick={(e, data) => {fetchEventData(data.permalink);}}
                      onInitComplete={(settings, json) => {
                        if(!isEventViewSliding && json.data.length > 0){
                          fetchEventData(json.data[0].permalink);
                        }
                      }}
                      order={order} />
          </div>
        </div>
      )}
      {displayShowMore && (
        <div className="row col-12">
          <button className="btn btn-link btn-sm normal-text pl-0" onClick={onClickShow}>
            {showMore ? 'Show' : 'Hide'} All Show Data
          </button>
        </div>
      )}
    </div>
  )
};

DataTableWithSidebarView.defaultProps = {
  displayShowMore: false,
  isEventViewSliding: false,
};

DataTableWithSidebarView.propTypes = {
  browser: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string.isRequired,
  order: PropTypes.array,
  columns: PropTypes.array.isRequired,
  eventPermalink: PropTypes.string,
  event: PropTypes.object,
  fetchEventData: PropTypes.func.isRequired,
  updateEvent: PropTypes.func,
  displayShowMore: PropTypes.bool,
  isEventViewSliding: PropTypes.bool,
};

export default DataTableWithSidebarView;
