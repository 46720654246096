import React, { useState } from "react";
import Popup from "reactjs-popup";
import axios from "axios";

import { toastSuccess, toastError } from "../../../../shared/toastHelper";
import { useMarketingSettingsContext } from "../MarketingSettingsIndexContainer";

const popupContentStyle = {
  border: "none",
  borderRadius: "8px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  padding: "0px",
  width: "fit-content",
};

const searchInputStyle = {
  border: "1px solid #EEEEEE",
  width: "100%",
  padding: "5px",
};

const overlayStyle = {
  padding: 30,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: 500,
};

const footerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: "10px",
};

const cancelButtonStyle = {
  border: "1px solid #B2DBF5",
  borderRadius: "5px",
  color: "#1982C4",
  cursor: "pointer",
  padding: "5px 10px",         
};

const SendTestOverlay = ({
  venueOwnership,
  sendDomain,
}) => {
  const {
    testModalOpen,
    setTestModalOpen,
    user,
    team,
    csrfToken,
  } = useMarketingSettingsContext();

  const [testInputValue, setTestInputValue] = useState(user.email);

  const sendTest = (emails) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/venue_ownerships/${venueOwnership.id}/send_domains/${sendDomain.id}/test`, {
      emails: emails,
    })
      .then((response) => {
        toastSuccess(response.data.message);
        setTestModalOpen(false);
      })
      .catch((error) => {
        toastError(error.message);
        setTestModalOpen(false);
      });
  };

  const sendTestStyle = {
    background: "#1982C4",
    borderRadius: "5px",
    color: "white",
    cursor: !!testInputValue ? "pointer" : "not-allowed",
    opacity: !!testInputValue ? 1 : 0.5,
    padding: "5px 10px",
  };

  return (
    <Popup arrow={false}
      contentStyle={popupContentStyle}
      open={testModalOpen}
      closeOnDocumentClick
      onClose={() => setTestModalOpen(false)}
    >
      <div style={overlayStyle}>
        <div>
          <strong>Test Email</strong>
        </div>
        <div>
          Send a test email to...
        </div>
        <input
          type="text"
          placeholder="Example: b@xyz.com, a+test@xyz.com"
          style={searchInputStyle}
          value={testInputValue}
          onChange={(e) => setTestInputValue(e.target.value)}  
        />
        <div style={footerStyle}>
          <div style={cancelButtonStyle} onClick={() => setTestModalOpen(false)}
          >
            Cancel
          </div>
          <div style={sendTestStyle} onClick={() => !!testInputValue && sendTest(testInputValue)}>
            Send Test
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default SendTestOverlay;
