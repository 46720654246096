import React, { useEffect, useState, useRef } from "react";
import Flatpickr from "react-flatpickr";
import Tippy from '@tippyjs/react';

import { formInputContainerStyle, formInputStyle } from "./FanFilterGroupForm";

const dateTimePicker = (value, onClose) => {
  return <Flatpickr
    options={{
      enableTime: true,
      dateFormat: "M j, Y \\a\\t h:i K",
    }}
    placeholder={"Select a date"}
    value={value}
    onClose={onClose}
    style={{
      border: "none",
      width: "100%",
    }}
  />;
};

const filterTypeOptions = [
  "Fan Attribute",
];

const datePickerContainerStyle = {
  marginRight: 8,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "white",
  width: 250,
  height: 40,
  borderRadius: 8,
  padding: "0 10px",
  border: "1px solid #ccc",
};

const calendarIconStyle = {
  fontSize: "14px",
  color: "#1982C4"
};

const UserDataForm = (props) => {
  const {
    fanFilter,
    updateFanFilter,
  } = props;

  const mountedRef = useRef(false);

  const [valueInputType, setValueInputType] = useState("none");

  const supportedAttributes = fanFilter.filter_param_options.fan_attribute || [];
  const supportedConditions = fanFilter.filter_param_options.condition || [];

  const userDataType = fanFilter.filter_params.user_data_type || "";
  const fanAttribute = fanFilter.filter_params.fan_attribute || "";
  const condition = fanFilter.filter_params.condition || "";
  const values = fanFilter.filter_params.values || [""];

  const [valueOne, setValueOne] = useState(values[0]);

  useEffect(() => {
    if (!fanFilter.filter_params.condition) {
      setValueInputType("none");
    } else if (fanFilter.filter_params.condition === "is before" || fanFilter.filter_params.condition === "is after") {
      setValueInputType("singledate");
    } else if (fanFilter.filter_params.condition === "is between") {
      setValueInputType("multidate");
    } else {
      setValueInputType("text");
    }
  }, [fanFilter.filter_params.condition]);

  useEffect(() => {
    if (valueOne === undefined) return;

    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }

    const debounceUpdateValueOne = _.debounce(() => {
      updateFanFilterValueOne(valueOne);
    }, 500);

    debounceUpdateValueOne();

    return () => {
      debounceUpdateValueOne.cancel();
    };
  }, [valueOne]);

  const updateFanFilterValueOne = (value) => {
    let newValues = fanFilter.filter_params.values || [];
    newValues[0] = value;

    updateFanFilter({
      filter_params: {
        ...fanFilter.filter_params,
        values: newValues
      }
    });
  };

  const updateFanFilterValueTwo = (value) => {
    let newValues = fanFilter.filter_params.values;
    newValues[1] = value;

    updateFanFilter({
      filter_params: {
        ...fanFilter.filter_params,
        values: newValues
      }
    });
  };

  return (
    <>
      <div style={formInputContainerStyle}>
        <select
          value={userDataType}
          onChange={(e) => updateFanFilter({
            filter_params: {
              user_data_type: e.target.value,
            },
          })}
          style={formInputStyle}
        >
          {filterTypeOptions.map((filterType, i) => (
            <option key={i} value={filterType}>{filterType}</option>
          ))}
        </select>
      </div>
      <div style={formInputContainerStyle}>
        <select
          value={fanAttribute}
          onChange={(e) => updateFanFilter({
            filter_params: {
              ...fanFilter.filter_params,
              fan_attribute: e.target.value,
              condition: "",
              values: [""],
            },
          })}
          style={{
            ...formInputStyle,
            border: fanAttribute ? "1px solid #ccc" : "1px solid #FF595E",
            color: fanAttribute ? "#333" : "#757575",
          }}
        >
          <option value="" disabled>
            {userDataType === "Fan Attribute" ? "fan attribute" : "event attribute"}
          </option>
          {supportedAttributes.map((fanAttribute, i) => (
            <option key={i} value={fanAttribute}>{fanAttribute}</option>
          ))}
        </select>
      </div>
      <div style={formInputContainerStyle}>
        <select
          value={condition || ""}
          onChange={(e) => {
            updateFanFilter({
              filter_params: {
                ...fanFilter.filter_params,
                condition: e.target.value,
                values: [""],
              },
            });
            setValueOne(undefined);
          }}
          onClick={(e) => { !fanAttribute && e.preventDefault(); }}
          style={{
            ...formInputStyle,
            border: condition ? "1px solid #ccc" : "1px solid #FF595E",
            color: condition ? "#333" : "#757575",
            cursor: fanAttribute ? "pointer" : "not-allowed",
          }}
        >
          <option value="" disabled>
            Select a condition
          </option>
          {supportedConditions.map((condition, i) => (
            <option key={i} value={condition}>{condition}</option>
          ))}
        </select>
      </div>
      {valueInputType === "none" ? null : valueInputType === "multidate" ? (
        <>
          <div style={{
            ...datePickerContainerStyle,
            border: values[0] ? "1px solid #ccc" : "1px solid #FF595E",
          }}>
            <div className="pr-2" style={calendarIconStyle}>
              <i className="far fa-calendar"></i>
            </div>
            {dateTimePicker(values[0], (dates) => updateFanFilterValueOne(dates[0]))}
          </div>
          <div style={formInputContainerStyle}>and</div>
          <div style={{
            ...datePickerContainerStyle,
            border: values[1] ? "1px solid #ccc" : "1px solid #FF595E",
          }}>
            <div className="pr-2" style={calendarIconStyle}>
              <i className="far fa-calendar"></i>
            </div>
            {dateTimePicker(values[1], (dates) => updateFanFilterValueTwo(dates[0]))}
          </div>
        </>
      ) : valueInputType === "singledate" ? (
        <div style={{
          ...datePickerContainerStyle,
          border: values[0] ? "1px solid #ccc" : "1px solid #FF595E",
        }}>
          <div className="pr-2" style={calendarIconStyle}>
            <i className="far fa-calendar"></i>
          </div>
          {dateTimePicker(values[0], (dates) => updateFanFilterValueOne(dates[0]))}
        </div>
      ) : (
        <div style={formInputContainerStyle}>
          <input
            disabled={!condition}
            type="text"
            value={valueOne || ""}
            onChange={(e) => setValueOne(e.target.value)}
            placeholder="value"
            style={{
              ...formInputStyle,
              border: valueOne ? "1px solid #ccc" : "1px solid #FF595E",
              color: valueOne ? "#333" : "#757575",
              cursor: condition ? "pointer" : "not-allowed",
            }}
          />
        </div>
      )}
      {fanFilter.filter_validation_errors.length ? (
        <Tippy content={fanFilter.filter_validation_errors.join(" ")}>
          <i className="far fa-circle-exclamation" style={{color: "#FF595E" }}/>
        </Tippy>
      ) : null}
    </>
  );
};

export default UserDataForm;
