import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import Popup from 'reactjs-popup';

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "width": "400px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "padding": "0px",
}

class ReadMore extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      expanded: false,
      expandedModal: false,
      truncated: false
    };

    this.handleTruncate = this.handleTruncate.bind(this);
    this.toggleLines = this.toggleLines.bind(this);
  }

  handleTruncate(truncated) {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated
      });
    }
  }

  toggleLines(event) {
    event.preventDefault();

    if(!this.props.openModal){
      this.setState({
        expanded: !this.state.expanded
      });
    }else{
      this.setState(prevState => ({
        expandedModal: !prevState.expandedModal
      }));
    }

  }

  closeModal = ()=>{
    this.setState({
      expandedModal:false
    })
  }

  render() {
    const {
      content,
      more,
      less,
      lines,
      title
    } = this.props;

    const {
      expanded,
      expandedModal,
      truncated
    } = this.state;

    return (
      <div>
        <Truncate
          lines={!expanded && lines}
          ellipsis={(
            <span>... <a href='#' onClick={this.toggleLines}>{more}</a></span>
          )}
          onTruncate={this.handleTruncate} >
            <div dangerouslySetInnerHTML={{__html: content}}></div>
        </Truncate>
        {!truncated && expanded && (
          <span> <a href='#' onClick={this.toggleLines}>{less}</a></span>
        )}
        <Popup
          open={expandedModal}
          contentStyle={modalContentStyle}
          closeOnDocumentClick={true}
          onClose={this.closeModal}
        >
          <>
            <div className='d-flex justify-content-between pl-4 pr-4 pt-2'>
              <h3 className='font-700 large-text'>{title}</h3>
              <a href="#"
                  style={{"fontSize": "24px"}}
                  className="text-dark"
                  onClick={this.toggleLines} >
                <i className="fal fa-times"></i>
              </a>
            </div>
            <div className='p-4' style={{"width": "400px", "height": "400px", "overflowY":"auto"}}>
              <div dangerouslySetInnerHTML={{__html: content}}></div>
            </div>
          </>
        </Popup>
      </div>
    );
  }
}

ReadMore.defaultProps = {
  lines: 3,
  more: 'Read more',
  less: 'Show less',
  openModal: false,
  title: ''
};

ReadMore.propTypes = {
  content: PropTypes.string.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  openModal: PropTypes.bool,
  title: PropTypes.string
};

export default ReadMore;
