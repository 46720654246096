/* eslint-disable import/prefer-default-export */

import {
  SETTLEMENT_LINE_ITEM_ADDED,
  SETTLEMENT_LINE_ITEM_REMOVED,
  SETTLEMENT_LINE_ITEM_CHANGED,
  ITEM_TO_EDIT_CHANGED,
  SETTLEMENT_OVERRIDE_ADDED,
  SETTLEMENT_OVERRIDE_REMOVED,
  SETTLEMENT_OVERRIDE_CHANGED,
  BUDGET_CHANGED,
  EDITED_DROP_COUNT_CHANGED_CHANGED,
  EDITED_COMP_COUNT_CHANGED_CHANGED,
  EDITED_KILL_COUNT_CHANGED_CHANGED,
  IS_SAVING_CHANGED,
  OFFER_CHANGED
} from '../constants/settlementConstants';

const axios = require('axios').default;

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

export const createSettlementLineItem = (dispatch, csrfToken, team, params) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.post("/teams/" + team.id + "/settlement_line_items", {
        settlement_line_item: params
      })
      .then(({ data }) => {
        dispatch(settlementLineItemAdded(data));

        var itemToEdit = Object.assign({}, data, {
          overridable_type: "SettlementLineItem",
          overridable_id: data.id
        });

        dispatch(itemToEditChanged(itemToEdit));
      })
      .catch((error) => {
        console.error(error);
        alert("Your changes could not be saved. Please refresh the page and try again.");
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const settlementLineItemAdded = (settlementLineItem) => ({
  type: SETTLEMENT_LINE_ITEM_ADDED,
  settlementLineItem
});

export const deleteSettlementLineItem = (dispatch, csrfToken, team, settlementLineItem) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.delete("/teams/" + team.id + "/settlement_line_items/" + settlementLineItem.id)
      .then(({ data }) => {
        dispatch(settlementLineItemRemoved(data));
      })
      .catch((error) => {
        console.error(error);
        alert("Your changes could not be saved. Please refresh the page and try again.");
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const settlementLineItemRemoved = (settlementLineItem) => ({
  type: SETTLEMENT_LINE_ITEM_REMOVED,
  settlementLineItem
});

export const settlementLineItemChanged = (settlementLineItem) => ({
  type: SETTLEMENT_LINE_ITEM_CHANGED,
  settlementLineItem
});

export const updateSettlementLineItem = (dispatch, csrfToken, team, settlementLineItem) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.patch("/teams/" + team.id + "/settlement_line_items/" + settlementLineItem.id, {
        settlement_line_item: {
          name: (settlementLineItem.name || ""),
          amount: (settlementLineItem.amount ? parseFloatFromString(settlementLineItem.amount) : ""),
          quantity: (settlementLineItem.quantity ? parseFloatFromString(settlementLineItem.quantity) : "")
        }
      })
      .then(({ data }) => {
        dispatch(settlementLineItemChanged(data));
        dispatch(itemToEditChanged({}));
      })
      .catch((error) => {
        console.error(error);
        alert("Your changes could not be saved. Please refresh the page and try again.");
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const itemToEditChanged = (itemToEdit) => ({
  type: ITEM_TO_EDIT_CHANGED,
  itemToEdit
});

export const submitSettlementOverrideForm = (dispatch, csrfToken, team, offerId, itemToEdit) => {
  return dispatch => {
    if(itemToEdit.settlementOverride){
      dispatch(updateSettlementOverride(dispatch, csrfToken, team, itemToEdit, itemToEdit.settlementOverride));
    } else {
      dispatch(createSettlementOverride(dispatch, csrfToken, team, offerId, itemToEdit));
    }
  };
};

export const createSettlementOverride = (dispatch, csrfToken, team, offerId, itemToEdit) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    var params = {
      offer_id: offerId,
      overridable_type: itemToEdit.overridable_type,
      overridable_id: itemToEdit.overridable_id,
      section: itemToEdit.section,
      amount: itemToEdit.calculatedLeftHand,
      new_quantity: (itemToEdit.quantity ? parseFloatFromString(itemToEdit.quantity) : 0),
      new_amount: (itemToEdit.amount ? parseFloatFromString(itemToEdit.amount) : 0),
      new_name: itemToEdit.name
    };

    return axios.post("/teams/" + team.id + "/settlement_overrides", {
        settlement_override: params
      })
      .then(({ data }) => {
        dispatch(settlementOverrideAdded(data));
        dispatch(itemToEditChanged({}));
      })
      .catch((error) => {
        console.error(error);
        alert("Your changes could not be saved. Please refresh the page and try again.");
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const settlementOverrideAdded = (settlementOverride) => ({
  type: SETTLEMENT_OVERRIDE_ADDED,
  settlementOverride
});

export const deleteSettlementOverride = (dispatch, csrfToken, team, settlementOverride) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.delete("/teams/" + team.id + "/settlement_overrides/" + settlementOverride.id)
      .then(({ data }) => {
        dispatch(settlementOverrideRemoved(data));
        dispatch(itemToEditChanged({}));
      })
      .catch((error) => {
        console.error(error);
        alert("Your changes could not be saved. Please refresh the page and try again.");
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const settlementOverrideRemoved = (settlementOverride) => ({
  type: SETTLEMENT_OVERRIDE_REMOVED,
  settlementOverride
});

export const updateSettlementOverride = (dispatch, csrfToken, team, itemToEdit, settlementOverride) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    var params = {
      new_quantity: (itemToEdit.quantity ? parseFloatFromString(itemToEdit.quantity) : 0),
      new_amount: (itemToEdit.amount ? parseFloatFromString(itemToEdit.amount) : 0),
      new_name: itemToEdit.name
    };

    return axios.patch("/teams/" + team.id + "/settlement_overrides/" + settlementOverride.id, {
        settlement_override: params
      })
      .then(({ data }) => {
        dispatch(settlementOverrideChanged(data));
        dispatch(itemToEditChanged({}));
      })
      .catch((error) => {
        console.error(error);
        alert("Your changes could not be saved. Please refresh the page and try again.");
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };
};

export const settlementOverrideChanged = (settlementOverride) => ({
  type: SETTLEMENT_OVERRIDE_CHANGED,
  settlementOverride
});

export const budgetChanged = (budget) => ({
  type: BUDGET_CHANGED,
  budget
});

export const editedDropCountChanged = (editedDropCount) => ({
  type: EDITED_DROP_COUNT_CHANGED_CHANGED,
  editedDropCount
});

export const editedCompCountChanged = (editedCompCount) => ({
  type: EDITED_COMP_COUNT_CHANGED_CHANGED,
  editedCompCount
});

export const editedKillCountChanged = (editedKillCount) => ({
  type: EDITED_KILL_COUNT_CHANGED_CHANGED,
  editedKillCount
});

export const saveOverrides = (dispatch, csrfToken, team, offerId, budget, overrides) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    dispatch(isSavingChanged(true));

    return axios.patch("/teams/" + team.id + "/offers/" + offerId, {
        offer: {
          ...overrides
        }
      })
      .then(({ data }) => {
        var updated = {
          ...budget,
          drop_count_override: data.drop_count_override,
          comp_count_override: data.comp_count_override,
          kill_count_override: data.kill_count_override
        };

        dispatch(budgetChanged(updated));
        dispatch(editedDropCountChanged(null));
        dispatch(editedCompCountChanged(null));
        dispatch(editedKillCountChanged(null));
      })
      .catch((error) => {
        alert("Your changes could not be saved. Please refresh the page and try again.");
      })
      .finally(() => {
        dispatch(isSavingChanged(false));
      });
  };

};

export const isSavingChanged = (isSaving) => ({
  type: IS_SAVING_CHANGED,
  isSaving
});

export const offerChanged = (offer) => ({
  type: OFFER_CHANGED,
  offer
});
