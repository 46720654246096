import PropTypes from 'prop-types';
import React from 'react';

import SecondColumn from './SecondColumn';
import CalendarEventInterestForm from './CalendarEventInterestForm';

const SoldOut = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  promoCode,
  ticketReservation,
  discountedTicketTypePrice,
  addOns,
  seatsIOSelectedObjects,
  discountedAddOnPrice,
  visibleAddOns,
  nextStepIsChooseAddOns,
  goToAddOns,
  postToParent,
  ticketReservationChanged,
  ticketInsurance,
  waitlistEnabled,
  nextStepIsChooseDonation,
  goToDonation,
  showHeader
}) => (
  <div>
    {showHeader ? (
      <div className="row">
        <div className="col-12">
          <p className="mb-1 mt-1 text-dark"
            style={{"fontSize": "24px"}}>
            <strong>{confirm.title}</strong>
          </p>
          {confirm.support_artist_names && confirm.support_artist_names.length > 0 ? (
            <p className="mb-1"
              style={{"fontSize": "16px", "color": "#686868"}}>
              with {confirm.support_artist_names}
            </p>
          ) : null}
          <p className="mb-1"
            style={{"color": "#686868"}}>
            {confirm.human_date_and_time_with_day_of_week}
          </p>
          {confirm.venue.has_non_country_address_info ? (
            <p className="mb-1"
              style={{"color": "#686868"}}>
              {confirm.venue.address_from_components}
            </p>
          ) : null}
        </div>
      </div>
    ) : null}
    {waitlistEnabled ? (
      <CalendarEventInterestForm
        confirm={confirm}
        csrfToken={csrfToken}
        interestType="Waitlist"
      />
    ) : (<div className="row mt-5">
      <div className="col-12 col-md-6 col-lg-8 mb-3">
        <div className="card border-0 h-100"
            style={{"background": "#f9f9f9"}}>
          <div className="card-body d-flex align-items-center justify-content-center">
            <div className="text-center">
              <p className="mb-2"
                style={{"fontSize": "16px"}}>
                <strong>Sold out!</strong>
              </p>
              <p className="mb-0">
                This event has sold out.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3 order-first order-md-last">
        <SecondColumn confirm={confirm}
                      ticketTypes={ticketTypes}
                      promoCode={promoCode}
                      discountedTicketTypePrice={discountedTicketTypePrice}
                      discountedAddOnPrice={discountedAddOnPrice}
                      addOns={addOns}
                      seatsIOSelectedObjects={seatsIOSelectedObjects}
                      ticketInsurance={ticketInsurance}
                      ticketReservation={ticketReservation} />
      </div>
    </div>)}
    {nextStepIsChooseAddOns || nextStepIsChooseDonation ? (
      <div className="row pb-3"
           style={{"paddingTop": "5px"}}>
        <div className="col-12 col-md-6 col-lg-8">
          <div className="row">
            <div className="col d-flex align-items-center">
              <p className="mb-0"
                 style={{"fontSize": "14px", "color": "#686868"}}>
                Powered by
                <a href="https://www.opendate.io/"
                   target="_blank">
                  <img src="/opendate_logo.png"
                       className="ml-2"
                       style={{"width": "88px"}} />
                </a>
              </p>
            </div>
            <div className="col text-right">
              <button type="submit"
                      onClick={
                        (e) => {
                          e.preventDefault();

                          if(nextStepIsChooseAddOns){
                            goToAddOns(ticketReservation, postToParent, ticketReservationChanged);
                          } else {
                            goToDonation();
                          }
                        }
                      }
                      className="btn btn-danger">
                <strong>Continue</strong>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 order-first order-md-last">
          &nbsp;
        </div>
      </div>
    ) : (
      <div className="row"
           style={{
             "paddingTop": "111px",
             "paddingBottom": "72px"
           }}>
        <div className="col d-flex align-items-center">
          <p className="mb-0"
             style={{"fontSize": "14px", "color": "#686868"}}>
            Powered by
            <a href="https://www.opendate.io/"
               target="_blank">
              <img src="/opendate_logo.png"
                   className="ml-2"
                   style={{"width": "88px"}} />
            </a>
          </p>
        </div>
      </div>
    )}
  </div>
);

SoldOut.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  ticketReservation: PropTypes.object,
  discountedTicketTypePrice: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  seatsIOSelectedObjects: PropTypes.array,
  discountedAddOnPrice: PropTypes.func.isRequired,
  visibleAddOns: PropTypes.array,
  nextStepIsChooseAddOns: PropTypes.bool,
  goToAddOns: PropTypes.func.isRequired,
  postToParent: PropTypes.func.isRequired,
  ticketReservationChanged: PropTypes.func.isRequired,
  ticketInsurance: PropTypes.object,
  waitlistEnabled: PropTypes.bool,
  nextStepIsChooseDonation: PropTypes.bool,
  goToDonation: PropTypes.func.isRequired,
  showHeader: PropTypes.bool
};

export default SoldOut;
