import React, { createContext, useState, useContext, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import axios from "axios";

import ActionShow from "./ActionShow";
import { parseLiquid } from "../../BeefreeTemplates/ErrorsContainer";
import { toastSuccess, toastError } from "../../../../shared/toastHelper";

const ActionContext = createContext();

export function useActionContext() {
  return useContext(ActionContext);
}

const ActionProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
    venueOwnership,
    campaign,
    action,
    beefreeToken,
    beefreeUid,
    sampleEventIds,
    sendDomain,
  } = props;

  const isEmailTemplate = action.type === "CampaignActions::Email";
  const sendTestUrl = `/teams/${team.id}/campaigns/${campaign.id}/campaign_actions/${action.id}/test`;

  const [sampleFanId, setSampleFanId] = useState(props.sampleFanId);
  const [tab, setTab] = useState("content");
  const [body, setBody] = useState(action.body);
  const [campaignState, setCampaignState] = useState(campaign.state);
  const [parsedBody, setParsedBody] = useState("");
  const [beefreeTemplate, setBeefreeTemplate] = useState(action.beefree_template);
  const [template, setTemplate] = useState(action.beefree_template?.template);
  const [processedTemplate, setProcessedTemplate] = useState(template);
  const [errors, setErrors] = useState([]);
  const [selectedEventIndex, setSelectedEventIndex] = useState(1);
  const [sampleEventId, setSampleEventId] = useState(sampleEventIds[selectedEventIndex - 1]);
  const [sampleData, setSampleData] = useState({event: {}, fan: {}});
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [subject, setSubject] = useState(action.subject);

  const updateAction = (requestBody) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put(`/teams/${team.id}/campaigns/${campaign.id}/campaign_actions/${action.id}`, requestBody)
      .then(() => toastSuccess("Campaign updated successfully"))
      .catch(() => toastError("Could not save campaign, please try again"));
  };

  useEffect(() => {
    if (!!sampleFanId && !!sampleEventId) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.get(`/teams/${team.id}/campaigns/${campaign.id}/sample_event_data`, {
        params: {
          fan_id: sampleFanId,
          event_id: sampleEventId
        }
      })
        .then(({ data }) => {
          setSampleData(data);
        })
        .catch((_error) => {
          toastError("Could not fetch sample data, please try again");
        });
    }
  }, [sampleFanId, sampleEventId]);

  useEffect(() => {
    setSampleEventId(sampleEventIds[selectedEventIndex - 1])
  }, [selectedEventIndex]);

  const value = {
    csrfToken,
    user,
    team,
    venueOwnership,
    campaign,
    action,
    beefreeToken,
    beefreeUid,
    isEmailTemplate,
    sendTestUrl,
    sampleEventIds,
    sampleFanId,
    setSampleFanId,
    tab,
    setTab,
    body,
    setBody,
    campaignState,
    setCampaignState,
    parsedBody,
    setParsedBody,
    template,
    setTemplate,
    errors,
    setErrors,
    selectedEventIndex,
    setSelectedEventIndex,
    sampleEventId,
    setSampleEventId,
    sampleData,
    setSampleData,
    testModalOpen,
    setTestModalOpen,
    parseLiquid,
    updateAction,
    subject,
    setSubject,
    sendDomain,
    beefreeTemplate,
    setBeefreeTemplate,
    processedTemplate,
    setProcessedTemplate,
  };

  return (
    <ActionContext.Provider value={value}>
      {props.children}
    </ActionContext.Provider>
  );
};

const ActionContainer = (props) => (
  <ActionProvider {...props}>
    <ToastContainer />
    <ActionShow />
  </ActionProvider>
);

export default ActionContainer;
