/* eslint-disable import/prefer-default-export */

import {
  DATA_TABLE_EL_CHANGED,
  OUTREACH_EXISTS_CHANGED,
  OFFER_MODAL_OPENED,
  OFFER_MODAL_CLOSED,
  FOLLOW_UP_CHANGED,
  NEW_OUTREACH_CHANGED,
  CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED,
  CLOSE_CALENDAR_EVENT_MODAL,
  OFFER_CHANGED,
  PIPELINE_CHANGED
} from '../constants/outreachManagerConstants';

import {
  serverJSONToLocal
} from '../../OfferModal/actions/offerModalActionCreators';

import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

const axios = require('axios').default;
import { toast } from 'react-toastify';

import $ from 'jquery';
require('datatables.net-bs4');

export const dataTableElChanged = (el) => ({
  type: DATA_TABLE_EL_CHANGED,
  el
});

export const deleteOutreach = (dispatch, team, outreach, csrfToken, rowTableEl) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/outreaches/" + outreach.id)
      .then(({ data }) => {
        $(rowTableEl).DataTable().ajax.reload((json) => {
          dispatch(outreachExistsChanged(json.recordsTotal > 0))
        });
      });
  };
};

export const fetchNewFollowUp = (dispatch, team, outreach, rowTableEl) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/follow_ups/new")
      .then(({ data }) => {
        var followUp = Object.assign({}, data, {
          last_contact_id: outreach.id,
          last_contact_type: "Outreach",
          artist: outreach.artist,
          pipeline: outreach.pipeline,
          rowTableEl: rowTableEl
        });

        dispatch(followUpChanged(followUp));
      });
  };
};

export const fetchNewOutreach = (dispatch, team) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/outreaches/new")
      .then(({ data }) => {
        var outreach = Object.assign({}, data, {
          body: EditorState.createEmpty()
        });

        dispatch(newOutreachChanged(outreach));
      })
  };
};

export const outreachExistsChanged = (outreachesExists) => ({
  type: OUTREACH_EXISTS_CHANGED,
  outreachesExists
})

export const offerModalOpened = () => ({
  type: OFFER_MODAL_OPENED
})

export const offerModalClosed = (dispatch, defaultOffer) => {
  return dispatch => {
    var deepCopy = JSON.parse(JSON.stringify(defaultOffer));

    dispatch(updateOfferData(deepCopy));
    dispatch(broadcastOfferCloseModal());
  };
};

export const broadcastOfferCloseModal = () => ({
  type: OFFER_MODAL_CLOSED
});

export const onInit = (dispatch, defaultOffer) => {
  return dispatch => {
    var deepCopy = JSON.parse(JSON.stringify(defaultOffer));
    dispatch(updateOfferData(deepCopy));
  }
};

export const fetchNewOffer = (dispatch, team, outreach, defaultOffer, rowTableEl) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/offers/new", {
        params: {
          "offer[primary_performer_attributes][artistable_id]": outreach.artist_id,
          "offer[primary_performer_attributes][artistable_type]": "Artist"
        }
      })
      .then(({ data }) => {
        var serverOffer = serverJSONToLocal(data);
        var offer = Object.assign({}, serverOffer, defaultOffer, {
          eventName: serverOffer.artist_or_event_name,
          rowTableEl: rowTableEl,
          pipeline: outreach.pipeline
        });

        dispatch(updateOfferData(offer));
        dispatch(offerModalOpened());
      });
  };
};

export const followUpChanged = (followUp) => ({
  type: FOLLOW_UP_CHANGED,
  followUp
});

export const newOutreachChanged = (outreach) => ({
  type: NEW_OUTREACH_CHANGED,
  outreach
});

export const createCalendarEventFromOutreachClicked = (outreach, rowTableEl, artists) => ({
  type: CREATE_CALENDAR_EVENT_FROM_OUTREACH_CLICKED,
  outreach,
  rowTableEl,
  artists
});

export const closeCalendarEventModal = () => ({
  type: CLOSE_CALENDAR_EVENT_MODAL
});

export const updateOfferData = (offer) => ({
  type: OFFER_CHANGED,
  offer
});

export const fetchPipeline = (dispatch, team, pipelineId) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/pipelines/" + pipelineId)
      .then(({ data }) => {
        dispatch(pipelineChanged(data));
      })
  };
};

export const pipelineChanged = (pipeline) => ({
  type: PIPELINE_CHANGED,
  pipeline
});
