import PropTypes from 'prop-types';
import React from 'react';

var _ = require('lodash');
import AsyncCreatableSelect from 'react-select/async-creatable';
const axios = require('axios').default;
import Popup from "reactjs-popup";
import InputMask from "react-input-mask";

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "260px",
  "padding": "0px",
  "zIndex": "1999"
}

const canAddCalendarEventContact = (calendarEventContacts) => {
  return !calendarEventContacts.some((calendarEventContact) => {
    return calendarEventContact.id === undefined;
  });
}

const hasManuallyAddedCalendarEventContacts = (calendarEventContacts) => {
  return calendarEventContacts.some((calendarEventContact) => {
    return !calendarEventContact.auto_added;
  });
}

const canSubmitCalendarEventContactForm = (calendarEventContact) => {
  var noPhoneOrValidPhone = (
    calendarEventContact
      && calendarEventContact.contact
      && (
        !calendarEventContact.contact.phone
          || calendarEventContact.contact.phone.length === 0
          || calendarEventContact.contact.phone.replace(/[^0-9]/g, '').length === 10
      )
  )

  return (
    calendarEventContact
      && calendarEventContact.contact
      && calendarEventContact.contact.name
      && calendarEventContact.contact.name.length > 0
      && noPhoneOrValidPhone
  );
}

const formatOptionLabel = ({ value, label, title_email_preview }) => {
  return (
    <div>
      <div style={{"fontSize": "12px"}}>{label}</div>
      {title_email_preview && title_email_preview.length > 0 ? (
        <div className="text-muted" style={{"fontSize": "10px"}}>
          {title_email_preview}
        </div>
      ) : null}
    </div>
  );
};

const debouncedFetch = _.debounce((inputValue, callback, team, calendarEventContacts) => {
  var omitContactIds = calendarEventContacts.filter((calendarEventContact) => {
      return calendarEventContact.contact;
    })
    .map((calendarEventContact) => {
      return calendarEventContact.contact.id;
    });

  var contactPromise = axios.get('/teams/' + team.id + '/contacts/autocomplete', {
      params: {
        name: inputValue,
        omit_ids: omitContactIds
      }
    })
    .then(function (response) {
      return response.data.map(function(data){
        return Object.assign({}, data, {value: data.id, label: data.name, type: "Contact"});
      });
    })

  var agentPromise = axios.get('/agents/autocomplete', {
      params: {
        name: inputValue,
        team_id: team.id
      }
    })
    .then(function (response) {
      return response.data.map(function(data){
        return Object.assign({}, data, {value: data.id, label: data.name, type: "Agent"});
      });
    })

  Promise.all([contactPromise, agentPromise]).then((values) => {
    var combined = values.flat().sort(function (a, b) {
      return a.name.localeCompare(b.name);
    }).slice(0, 20);

    callback(combined);
  });
}, 250);

const ManageContacts = ({
  csrfToken,
  team,
  confirm,
  currentUser,
  calendarEventContacts,
  createCalendarEventContactFromContactId,
  deleteCalendarEventContact,
  addCalendarEventContact,
  removeUnsavedCalendarEventContact,
  editCalendarEventContact,
  cancelEditCalendarEventContact,
  updateCalendarEventContactName,
  updateCalendarEventContactEmail,
  updateCalendarEventContactPhone,
  updateCalendarEventContactContactType,
  updateCalendarEventContactTitle,
  updateCalendarEventContactCompanyName,
  submitCalendarEventContactForm,
  editUnsavedCalendarEventContact,
  originalCalendarEventContact,
  createCalendarEventContactFromAgentId,
  updateCalendarEventContact,
  confirmInvitationChanged,
  emailsToInviteChanged,
  confirmInvitationsAndTeamMemberships
}) => (
  <div className="card no-border shadow-2">
    <div className="card-body px-md-3">
      <h5 className="strong">Contacts</h5>
      {calendarEventContacts.length > 0 ? (
        <div className="row">
          <div className="col-12">
            <table className="table table-borderless">
              <colgroup>
                <col span={1} style={{"width": "90%"}} />
                <col span={1} style={{"width": "10%"}} />
              </colgroup>
              <tbody>
                {calendarEventContacts.map((calendarEventContact, index) => (
                  calendarEventContact.isEditing ? (
                    <tr key={index}>
                      <td colSpan={2} className="p-0">
                        <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
                          <div className="card-body px-2">
                            <div className="row">
                              <div className="col">
                                <form onSubmit={
                                  (e) => {
                                    e.preventDefault();

                                    if(canSubmitCalendarEventContactForm(calendarEventContact)){
                                      submitCalendarEventContactForm(csrfToken, team, confirm, calendarEventContact, index);
                                    }
                                  }
                                }>
                                  <div className="form-row">
                                    <div className="col">
                                      <div className="form-row">
                                        <div className="col-12">
                                          <input type="text"
                                                 value={calendarEventContact.contact.name || ""}
                                                 onChange={
                                                   (e) => {
                                                     updateCalendarEventContactName(index, e.target.value);
                                                   }
                                                 }
                                                 style={{'borderRadius': '8px'}}
                                                 className="form-control form-control-sm"
                                                 placeholder="Name" />
                                        </div>
                                      </div>
                                      <div className="form-row pt-2">
                                        <div className="col-6">
                                          <input type="email"
                                                 value={calendarEventContact.contact.email || ""}
                                                 onChange={
                                                   (e) => {
                                                     updateCalendarEventContactEmail(index, e.target.value);
                                                   }
                                                 }
                                                 style={{'borderRadius': '8px'}}
                                                 className="form-control form-control-sm"
                                                 placeholder="Email Address" />
                                        </div>
                                        <div className="col-6">
                                          <InputMask mask="(999) 999-9999"
                                                     onChange={
                                                       (e) => {
                                                         updateCalendarEventContactPhone(index, e.target.value);
                                                       }
                                                     }
                                                     style={{'borderRadius': '8px'}}
                                                     className="form-control form-control-sm"
                                                     placeholder="Phone Number"
                                                     alwaysShowMask={false}
                                                     value={calendarEventContact.contact.phone || ""} />
                                        </div>
                                      </div>
                                      <div className="form-row pt-2">
                                        <div className="col-12">
                                          <select className="custom-select custom-select-sm"
                                                  onChange={
                                                    (e) => {
                                                      updateCalendarEventContactContactType(index, e.target.value);
                                                    }
                                                  }
                                                  value={calendarEventContact.contact.contact_type || ""}>
                                            <option value="">Contact Type</option>
                                            <option value="Agent">Agent</option>
                                            <option value="Manager">Manager</option>
                                            <option value="Venue">Venue</option>
                                            <option value="Tour Manager">Tour Manager</option>
                                            <option value="Marketing">Marketing</option>
                                            <option value="Other">Other</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-row pt-2">
                                        <div className="col-6">
                                          <input type="text"
                                                 value={calendarEventContact.contact.title || ""}
                                                 onChange={
                                                   (e) => {
                                                     updateCalendarEventContactTitle(index, e.target.value);
                                                   }
                                                 }
                                                 style={{'borderRadius': '8px'}}
                                                 className="form-control form-control-sm"
                                                 placeholder="Title" />
                                        </div>
                                        <div className="col-6">
                                          <input type="text"
                                                 value={calendarEventContact.contact.company_name || ""}
                                                 onChange={
                                                   (e) => {
                                                     updateCalendarEventContactCompanyName(index, e.target.value);
                                                   }
                                                 }
                                                 style={{'borderRadius': '8px'}}
                                                 className="form-control form-control-sm"
                                                 placeholder="Company" />
                                        </div>
                                      </div>
                                      <div className="form-row pt-2">
                                        <div className="col-12 text-right">
                                          <button type="submit"
                                                  disabled={!canSubmitCalendarEventContactForm(calendarEventContact)}
                                                  style={{'borderRadius': '8px'}}
                                                  className="btn btn-primary btn-sm">
                                            Save Contact
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xs-auto">
                                      <a href='#'
                                         className="mt-1 d-inline-block"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             cancelEditCalendarEventContact(originalCalendarEventContact);
                                           }
                                         }
                                         style={{"color": "#91bddc"}}>
                                        <i className="fas fa-times-circle"></i>
                                      </a>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : calendarEventContact.id ? (
                    <tr key={index}>
                      <td className="pl-0">
                        <strong>
                          {calendarEventContact.contact.name}
                          {calendarEventContact.send_daily_ticket_updates ? (
                            <i className="far fa-envelope text-primary ml-2"
                               title="Receiving daily ticket counts"></i>
                          ) : null}
                        </strong>
                        {calendarEventContact.contact.title_at_company_name.length > 0 ? (
                          <div>
                            {calendarEventContact.contact.title_at_company_name}
                          </div>
                        ) : null}
                        {calendarEventContact.contact.email_with_phone.length > 0 ? (
                          <div className="text-muted">
                            {calendarEventContact.contact.email_with_phone}
                          </div>
                        ) : null}
                      </td>
                      <td className="pr-0 text-right" style={{"verticalAlign": "middle"}}>
                        {currentUser.can_manage_calendar_event_contacts ? (
                          <Popup arrow={false}
                                 offsetY={5}
                                 position="bottom right"
                                 contentStyle={popupContentStyle}
                                 onOpen={
                                   (e) => {
                                     e.preventDefault();
                                   }
                                 }
                                 trigger={open => (
                                   <a href="#"
                                      className="btn btn-external btn-sm note-card-inline-menu">
                                     <i className="far fa-ellipsis-v"></i>
                                   </a>
                                 )} >
                            {close => (
                              <div className='row text-left'>
                                <div className="col-12">
                                  <ul className="list-group offer-inline-menu">
                                    {confirm.type === "Confirm" ? (
                                      <>
                                        {currentUser.can_create_invitation && calendarEventContact.contact.email && calendarEventContact.contact.email.length > 0 && !confirmInvitationsAndTeamMemberships.map((d) => d.email.toLowerCase()).includes(calendarEventContact.contact.email.toLowerCase()) ? (
                                          <li className="list-group-item">
                                            <a href={"#"}
                                              onClick={
                                                (e) => {
                                                  e.preventDefault();
                                                  close();

                                                  var option = Object.assign({}, calendarEventContact.contact, {
                                                    value: calendarEventContact.contact.email
                                                  });

                                                  emailsToInviteChanged([option]);
                                                  confirmInvitationChanged({team: team});
                                                }
                                              }
                                              className="text-dark">
                                              <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                                                <i className="fas fa-share"></i>
                                              </span>
                                              Share this Page
                                            </a>
                                          </li>
                                        ) : null}
                                        {calendarEventContact.contact.email && calendarEventContact.contact.email.length > 0 ? (
                                          <li className="list-group-item">
                                            <a href="#"
                                              onClick={
                                                (e) => {
                                                  e.preventDefault();
                                                  close();

                                                  let params = {
                                                    send_daily_ticket_updates: !calendarEventContact.send_daily_ticket_updates
                                                  };
                                                  updateCalendarEventContact(csrfToken, team, calendarEventContact, params, index);
                                                }
                                              }
                                              className="text-muted">
                                              <span className="d-inline-block text-center" style={{width: "28px"}}>
                                                <i className={"far fa-envelope " + (calendarEventContact.send_daily_ticket_updates ? "text-primary" : "text-muted")}></i>
                                              </span>
                                              <span className="text-dark">
                                                {calendarEventContact.send_daily_ticket_updates ? (
                                                  <span>Remove from daily ticket counts</span>
                                                ) : (
                                                  <span>Add to daily ticket counts</span>
                                                )}
                                              </span>
                                            </a>
                                          </li>
                                        ) : null}
                                      </>
                                    ) : null}
                                    <li className="list-group-item">
                                      <a href="#"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             close();
                                             editCalendarEventContact(index, calendarEventContact, originalCalendarEventContact);
                                           }
                                         }
                                         className="text-muted">
                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                          <i className="fas fa-pencil"></i>
                                        </span>
                                        <span className="text-dark">
                                          Edit Contact
                                        </span>
                                      </a>
                                    </li>
                                    <li className="list-group-item delete-offer-li">
                                      <a href="#"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             close();
                                             deleteCalendarEventContact(csrfToken, team, calendarEventContact);
                                           }
                                         }
                                         className="text-danger">
                                        <span className="d-inline-block text-center" style={{width: "28px"}}>
                                          <i className="fas fa-times-circle"></i>
                                        </span>
                                        Remove
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </Popup>
                        ) : null}
                      </td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td colSpan={2} className="p-0">
                        <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
                          <div className="card-body px-2">
                            <div className="row">
                              <div className="col">
                                <form>
                                  <div className="form-row">
                                    <div className="col">
                                      <AsyncCreatableSelect cacheOptions
                                                   styles={selectStyles}
                                                   placeholder={"Enter contact"}
                                                   menuPortalTarget={document.querySelector('body')}
                                                   defaultOptions
                                                   formatOptionLabel={formatOptionLabel}
                                                   formatCreateLabel={
                                                     (label) => ("Create \"" + label + "\"")
                                                   }
                                                   onCreateOption={
                                                     (label) => {
                                                       editUnsavedCalendarEventContact(label, index, originalCalendarEventContact);
                                                     }
                                                   }
                                                   onChange={
                                                     (option) => {
                                                       if(option.type === "Contact"){
                                                         createCalendarEventContactFromContactId(csrfToken, team, confirm, option.id, index);
                                                       } else if (option.type === "Agent") {
                                                         createCalendarEventContactFromAgentId(csrfToken, team, confirm, option.id, index);
                                                       }
                                                     }
                                                   }
                                                   loadOptions={(inputValue, callback) => debouncedFetch(inputValue, callback, team, calendarEventContacts)} />
                                    </div>
                                    <div className="col-xs-auto">
                                      <a href='#'
                                         className="mt-1 d-inline-block"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             removeUnsavedCalendarEventContact();
                                           }
                                         }
                                         style={{"color": "#91bddc"}}>
                                        <i className="fas fa-times-circle"></i>
                                      </a>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {currentUser.can_manage_calendar_event_contacts && calendarEventContacts.length > 0 && hasManuallyAddedCalendarEventContacts(calendarEventContacts) && canAddCalendarEventContact(calendarEventContacts) ? (
        <div className="row">
          <div className="col-12">
            <a href="#" onClick={
              (e) => {
                e.preventDefault();
                addCalendarEventContact(originalCalendarEventContact);
              }
            }>
              <i className="fas fa-plus-circle"></i>
              &nbsp;
              Add Contact
            </a>
          </div>
        </div>
      ) : null}
      {calendarEventContacts.length === 0 || !hasManuallyAddedCalendarEventContacts(calendarEventContacts) ? (
        <div className="row pt-3">
          <div className="col-12">
            <div className="card no-border mb-0" style={{'background': '#f4f9fc'}}>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 text-center">
                    <i className="far fa-users"
                       style={{"fontSize": "60px", "color": "rgba(25, 130, 196, .5)"}}></i>
                    <h5 className="pt-2">Add Contacts</h5>
                    <p className="text-muted">
                      Add important contacts so that you can quickly reach out when you need to.
                    </p>
                    {currentUser.can_manage_calendar_event_contacts ? (
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             addCalendarEventContact(originalCalendarEventContact);
                           }
                         }
                         style={{'borderRadius': '8px'}}
                         className="btn btn-primary btn-sm">
                        <strong>Add Contacts</strong>
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

ManageContacts.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  calendarEventContacts: PropTypes.array,
  createCalendarEventContactFromContactId: PropTypes.func.isRequired,
  deleteCalendarEventContact: PropTypes.func.isRequired,
  addCalendarEventContact: PropTypes.func.isRequired,
  removeUnsavedCalendarEventContact: PropTypes.func.isRequired,
  editCalendarEventContact: PropTypes.func.isRequired,
  cancelEditCalendarEventContact: PropTypes.func.isRequired,
  updateCalendarEventContactName: PropTypes.func.isRequired,
  updateCalendarEventContactEmail: PropTypes.func.isRequired,
  updateCalendarEventContactPhone: PropTypes.func.isRequired,
  updateCalendarEventContactContactType: PropTypes.func.isRequired,
  updateCalendarEventContactTitle: PropTypes.func.isRequired,
  updateCalendarEventContactCompanyName: PropTypes.func.isRequired,
  submitCalendarEventContactForm: PropTypes.func.isRequired,
  editUnsavedCalendarEventContact: PropTypes.func.isRequired,
  originalCalendarEventContact: PropTypes.object,
  createCalendarEventContactFromAgentId: PropTypes.func.isRequired,
  updateCalendarEventContact: PropTypes.func.isRequired,
  confirmInvitationChanged: PropTypes.func.isRequired,
  emailsToInviteChanged: PropTypes.func.isRequired,
  confirmInvitationsAndTeamMemberships: PropTypes.array
};

export default ManageContacts;
