import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const canSubmitForm = (originalTeamMembership, teamMembership) => {
  var teamMembershipHasChanges = (
    originalTeamMembership.send_daily_activity_summary !== teamMembership.send_daily_activity_summary
      || originalTeamMembership.send_weekly_activity_summary !== teamMembership.send_weekly_activity_summary
      || originalTeamMembership.send_daily_ticket_counts !== teamMembership.send_daily_ticket_counts
      || originalTeamMembership.send_weekly_ticket_counts !== teamMembership.send_weekly_ticket_counts
      || originalTeamMembership.send_event_updated_notifications !== teamMembership.send_event_updated_notifications
      || originalTeamMembership.send_inbound_submission_notifications !== teamMembership.send_inbound_submission_notifications
      || originalTeamMembership.send_financial_notifications !== teamMembership.send_financial_notifications
  );

  var userHasChanges = (
    originalTeamMembership.user.send_new_shared_tickets !== teamMembership.user.send_new_shared_tickets
      || originalTeamMembership.user.send_upcoming_events !== teamMembership.user.send_upcoming_events
      || originalTeamMembership.user.send_news_and_announcements !== teamMembership.user.send_news_and_announcements
  );

  return (
    teamMembershipHasChanges
      || userHasChanges
  );
}

const EmailNotificationSettings = ({
  csrfToken,
  currentUser,
  team,
  teamMembership,
  teamMembershipChanged,
  originalTeamMembership,
  updateTeamMembership
}) => (
  <div className="row">
    <div className="col-12">
      <form onSubmit={
              (e) => {
                e.preventDefault();

                if(!canSubmitForm(originalTeamMembership, teamMembership)){
                  return false;
                }

                updateTeamMembership(csrfToken, team, teamMembership);
              }
            }>
        <div className="row"
             style={{"marginBottom": "26px"}}>
          <div className="col-12">
            <p className="mb-0" style={{"fontSize": "16px"}}>
              <strong>Notification Preferences</strong>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="mb-0" style={{"fontSize": "14px"}}>
              <strong>Attending Events</strong>
            </p>
            <p className="mb-0" style={{"fontSize": "14px"}}>
              News & updates from Opendate about events you may be interested in.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <table className="table vertical-middle table-bordered-bottom"
                   style={{
                     tableLayout: "fixed",
                     marginTop: "14px"
                   }}>
              <colgroup>
                <col span={1} style={{"width": "40%"}} />
                <col span={1} style={{"width": "60%"}} />
              </colgroup>
              <tbody className="small">
                <tr className="border-top-0">
                  <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                    <div className="form-check">
                      <input className="form-check-input"
                             type="checkbox"
                             style={{"transform": "scale(1.1)"}}
                             checked={teamMembership.user.send_new_shared_tickets}
                             onChange={
                               (e) => {
                                 var updatedUser = Object.assign({}, teamMembership.user, {
                                   send_new_shared_tickets: e.target.checked
                                 });

                                 var updated = Object.assign({}, teamMembership, {
                                   user: updatedUser
                                 });

                                 teamMembershipChanged(updated);
                               }
                             }
                             id="send-new-shared-tickets" />
                      <label className="form-check-label ml-2"
                             style={{"marginTop": "2px"}}
                             htmlFor="send-new-shared-tickets">
                        <strong>New Shared Ticket</strong>
                      </label>
                    </div>
                  </td>
                  <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                    <p className="mb-0 text-overflow-ellipsis text-right" title={"Notification when someone shares a ticket with you."}>
                      Notification when someone shares a ticket with you.
                    </p>
                  </td>
                </tr>
                <tr className="border-top-0">
                  <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                    <div className="form-check">
                      <input className="form-check-input"
                             type="checkbox"
                             style={{"transform": "scale(1.1)"}}
                             checked={teamMembership.user.send_upcoming_events}
                             onChange={
                               (e) => {
                                 var updatedUser = Object.assign({}, teamMembership.user, {
                                   send_upcoming_events: e.target.checked
                                 });

                                 var updated = Object.assign({}, teamMembership, {
                                   user: updatedUser
                                 });

                                 teamMembershipChanged(updated);
                               }
                             }
                             id="send-upcoming-events" />
                      <label className="form-check-label ml-2"
                             style={{"marginTop": "2px"}}
                             htmlFor="send-upcoming-events">
                        <strong>Upcoming Event</strong>
                      </label>
                    </div>
                  </td>
                  <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                    <p className="mb-0 text-overflow-ellipsis text-right" title={"Reminders about events I’m going to."}>
                      Reminders about events I’m going to.
                    </p>
                  </td>
                </tr>
                <tr className="border-top-0">
                  <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                    <div className="form-check">
                      <input className="form-check-input"
                             type="checkbox"
                             style={{"transform": "scale(1.1)"}}
                             checked={teamMembership.user.send_news_and_announcements}
                             onChange={
                               (e) => {
                                 var updatedUser = Object.assign({}, teamMembership.user, {
                                   send_news_and_announcements: e.target.checked
                                 });

                                 var updated = Object.assign({}, teamMembership, {
                                   user: updatedUser
                                 });

                                 teamMembershipChanged(updated);
                               }
                             }
                             id="send-news-and-announcements" />
                      <label className="form-check-label ml-2"
                             style={{"marginTop": "2px"}}
                             htmlFor="send-news-and-announcements">
                        <strong>News & Announcements</strong>
                      </label>
                    </div>
                  </td>
                  <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                    <p className="mb-0 text-overflow-ellipsis text-right" title={"Updates about Opendate features and announcements."}>
                      Updates about Opendate features and announcements.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {team.setup_complete && teamMembership.receives_email_notifications ? (
          <React.Fragment>
            <div className="row"
                 style={{"marginTop": "24px"}}>
              <div className="col-12">
                <p className="mb-0" style={{"fontSize": "14px"}}>
                  <strong>Venue Manager</strong>
                </p>
                <p className="mb-0" style={{"fontSize": "14px"}}>
                  Updates from Opendate about events you are organizing.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="table vertical-middle table-bordered-bottom"
                       style={{
                         tableLayout: "fixed",
                         marginTop: "14px"
                       }}>
                  <colgroup>
                    <col span={1} style={{"width": "40%"}} />
                    <col span={1} style={{"width": "60%"}} />
                  </colgroup>
                  <tbody className="small">
                    <tr className="border-top-0">
                      <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <div className="form-check">
                          <input className="form-check-input"
                                 type="checkbox"
                                 style={{"transform": "scale(1.1)"}}
                                 checked={teamMembership.send_daily_activity_summary}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, teamMembership, {
                                       send_daily_activity_summary: e.target.checked
                                     });

                                     teamMembershipChanged(updated);
                                   }
                                 }
                                 id="send-daily-activity-summary" />
                          <label className="form-check-label ml-2"
                                 style={{"marginTop": "2px"}}
                                 htmlFor="send-daily-activity-summary">
                            <strong>Daily Activity Summary</strong>
                          </label>
                        </div>
                      </td>
                      <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <p className="mb-0 text-overflow-ellipsis text-right" title={"Daily recap of the previous days holds and confirms in Opendate."}>
                          Daily recap of the previous days holds and confirms in Opendate.
                        </p>
                      </td>
                    </tr>
                    <tr className="border-top-0">
                      <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <div className="form-check">
                          <input className="form-check-input"
                                 type="checkbox"
                                 style={{"transform": "scale(1.1)"}}
                                 checked={teamMembership.send_weekly_activity_summary}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, teamMembership, {
                                       send_weekly_activity_summary: e.target.checked
                                     });

                                     teamMembershipChanged(updated);
                                   }
                                 }
                                 id="send-weekly-activity-summary" />
                          <label className="form-check-label ml-2"
                                 style={{"marginTop": "2px"}}
                                 htmlFor="send-weekly-activity-summary">
                            <strong>Weekly Activity Summary</strong>
                          </label>
                        </div>
                      </td>
                      <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <p className="mb-0 text-overflow-ellipsis text-right" title={"Weekly recap of the previous weeks holds and confirms in Opendate."}>
                          Weekly recap of the previous weeks holds and confirms in Opendate.
                        </p>
                      </td>
                    </tr>
                    <tr className="border-top-0">
                      <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <div className="form-check">
                          <input className="form-check-input"
                                 type="checkbox"
                                 style={{"transform": "scale(1.1)"}}
                                 checked={teamMembership.send_daily_ticket_counts}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, teamMembership, {
                                       send_daily_ticket_counts: e.target.checked
                                     });

                                     teamMembershipChanged(updated);
                                   }
                                 }
                                 id="send-daily-ticket-counts" />
                          <label className="form-check-label ml-2"
                                 style={{"marginTop": "2px"}}
                                 htmlFor="send-daily-ticket-counts">
                            <strong>Daily Ticket Counts</strong>
                          </label>
                        </div>
                      </td>
                      <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <p className="mb-0 text-overflow-ellipsis text-right" title={"Daily recap of ticket counts for all current on-sale shows."}>
                          Daily recap of ticket counts for all current on-sale shows.
                        </p>
                      </td>
                    </tr>
                    <tr className="border-top-0">
                      <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <div className="form-check">
                          <input className="form-check-input"
                                 type="checkbox"
                                 style={{"transform": "scale(1.1)"}}
                                 checked={teamMembership.send_weekly_ticket_counts}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, teamMembership, {
                                       send_weekly_ticket_counts: e.target.checked
                                     });

                                     teamMembershipChanged(updated);
                                   }
                                 }
                                 id="send-weekly-ticket-counts" />
                          <label className="form-check-label ml-2"
                                 style={{"marginTop": "2px"}}
                                 htmlFor="send-weekly-ticket-counts">
                            <strong>Weekly Ticket Counts</strong>
                          </label>
                        </div>
                      </td>
                      <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <p className="mb-0 text-overflow-ellipsis text-right" title={"Weekly recap of ticket counts for all current on-sale shows."}>
                          Weekly recap of ticket counts for all current on-sale shows.
                        </p>
                      </td>
                    </tr>
                    <tr className="border-top-0">
                      <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <div className="form-check">
                          <input className="form-check-input"
                                 type="checkbox"
                                 style={{"transform": "scale(1.1)"}}
                                 checked={teamMembership.send_event_updated_notifications}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, teamMembership, {
                                       send_event_updated_notifications: e.target.checked
                                     });

                                     teamMembershipChanged(updated);
                                   }
                                 }
                                 id="event-updated-notifications" />
                          <label className="form-check-label ml-2"
                                 style={{"marginTop": "2px"}}
                                 htmlFor="event-updated-notifications">
                            <strong>Event Updated Notification</strong>
                          </label>
                        </div>
                      </td>
                      <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <p className="mb-0 text-overflow-ellipsis text-right" title={"Notifies you when an event is updated."}>
                          Notifies you whenever someone on your team creates or updates an event.
                        </p>
                      </td>
                    </tr>
                    <tr className="border-top-0">
                      <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <div className="form-check">
                          <input className="form-check-input"
                                 type="checkbox"
                                 style={{"transform": "scale(1.1)"}}
                                 checked={teamMembership.send_inbound_submission_notifications}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, teamMembership, {
                                      send_inbound_submission_notifications: e.target.checked
                                     });

                                     teamMembershipChanged(updated);
                                   }
                                 }
                                 id="inbound-submission-notifications" />
                          <label className="form-check-label ml-2"
                                 style={{"marginTop": "2px"}}
                                 htmlFor="inbound-submission-notifications">
                            <strong>Inbound Submission Notification</strong>
                          </label>
                        </div>
                      </td>
                      <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <p className="mb-0 text-overflow-ellipsis text-right" title={"Notifies you when an event is updated."}>
                          Notifies you whenever an Inbound Submission is received.
                        </p>
                      </td>
                    </tr>
                    <tr className="border-top-0">
                      <td className="pl-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <div className="form-check">
                          <input className="form-check-input"
                                 type="checkbox"
                                 style={{"transform": "scale(1.1)"}}
                                 checked={teamMembership.send_financial_notifications}
                                 onChange={
                                   (e) => {
                                     var updated = Object.assign({}, teamMembership, {
                                      send_financial_notifications: e.target.checked
                                     });

                                     teamMembershipChanged(updated);
                                   }
                                 }
                                 id="send-financial-notifications" />
                          <label className="form-check-label ml-2"
                                 style={{"marginTop": "2px"}}
                                 htmlFor="send-financial-notifications">
                            <strong>Financial Notifications</strong>
                          </label>
                        </div>
                      </td>
                      <td className="pr-0" style={{"paddingTop": "14px", "paddingBottom": "14px"}}>
                        <p className="mb-0 text-overflow-ellipsis text-right" title={"Notifies you when an event is updated."}>
                          Notifies you when important financial activities need attention
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div className="row"
             style={{"marginTop": "12px"}}>
          <div className="col-12 align-items-center">
            <button type="submit"
                    style={{
                      "fontSize": "14px",
                      "borderRadius": "4px",
                      "padding": "7px 12px"
                    }}
                    disabled={!canSubmitForm(originalTeamMembership, teamMembership)}
                    className="btn btn-primary btn-sm">
              <strong>
                Save Changes
              </strong>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
);

EmailNotificationSettings.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  teamMembership: PropTypes.object.isRequired,
  teamMembershipChanged: PropTypes.func.isRequired,
  originalTeamMembership: PropTypes.object.isRequired,
  updateTeamMembership: PropTypes.func.isRequired
};

export default EmailNotificationSettings;
