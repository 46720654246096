import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Pagination from 'react-js-pagination';
import Popup from "reactjs-popup";
import SlidingPane from "react-sliding-pane";
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select"

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

import Flatpickr from "react-flatpickr";
import DatePicker from "react-datepicker";

const axios = require("axios").default;

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #e6e6e6"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const parseDateTime = (dateTime) => {
  if (dateTime){
    if (typeof(dateTime) === "string") {
      var dateObj = new Date(dateTime);
      return dateObj;
    } else {
      return dateTime;
    }
  }
}

const datesAreOnSameDay = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear()
      && first.getMonth() === second.getMonth()
      && first.getDate() === second.getDate()
  );
};

const timeOnDate = (date, time) => {
  date = parseDateTime(date);
  time = parseDateTime(time);

  if(!date || !time){
    return null;
  }

  var month = date.getUTCMonth() + 1;
  var day   = date.getUTCDate();
  var year  = date.getUTCFullYear();

  var copiedTime = new Date(time.getTime());
  copiedTime.setFullYear(year, month, day);

  return copiedTime;
};

const canSubmitPromoCodeForm = (promoCode) => {
  var amount;
  if(promoCode.amount_two_decimals){
    amount = parseFloatFromString(promoCode.amount_two_decimals);
  }

  var percentage;
  if(promoCode.percentage){
    percentage = parseFloatFromString(promoCode.percentage);
  }

  var startDate = parseDateTime(promoCode.start_date_midday);
  var startTime = timeOnDate(startDate, promoCode.start_time_with_wrong_date);
  var endDate   = parseDateTime(promoCode.end_date_midday);
  var endTime   = timeOnDate(endDate, promoCode.end_time_with_wrong_date);

  return (
    promoCode
      && promoCode.name
      && startDate
      && startTime
      && endDate
      && endTime
      && (startDate <= endDate || datesAreOnSameDay(startDate, endDate))
      && startTime <= endTime
      && (
        (typeof(amount) === 'number' && amount >= 0)
          || (typeof(percentage) === 'number' && percentage >= 0 && percentage <= 100)
      )
  );
};

const filterPassedTime = (time, startDate, startTime, endDate) => {
  var startMonth = startDate.getUTCMonth() + 1;
  var startDay   = startDate.getUTCDate();
  var startYear  = startDate.getUTCFullYear();

  var endMonth = endDate.getUTCMonth() + 1;
  var endDay   = endDate.getUTCDate();
  var endYear  = endDate.getUTCFullYear();

  var sameDate = (
    startMonth === endMonth
      && startDay === endDay
      && startYear === endYear
  );

  if(!sameDate){
    return true;
  }

  var timeOption = new Date(time);
  var copiedStartTime = new Date(startTime.getTime());

  timeOption.setFullYear(startYear, startMonth, startDay);
  copiedStartTime.setFullYear(startYear, startMonth, startDay);

  return timeOption > copiedStartTime;
};

const UniversalPromoCodeManager = ({
  csrfToken,
  team,
  currentUser,
  paginatedPromoCodes,
  search,
  ticketTypeTemplates,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10);
  const [promoCodeForForm, setPromoCodeForForm] = useState({});
  const [promoCodes, setPromoCodes] = useState(paginatedPromoCodes.collection);
  const [totalEntries, setTotalEntries] = useState(paginatedPromoCodes.total_entries);
  const [totalPages, setTotalPages] = useState(Math.ceil(paginatedPromoCodes.total_entries / perPage));

  const getPromoCodes = (page, perPage) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.get("/teams/" + team.id + "/universal_promo_codes?format=json", {
        params: {
          page: page,
          per_page: perPage,
          search: search,
        }
      })
      .then(data => {
        const { data: { collection, total_entries } } = data;
        setCurrentPage(page);
        setTotalEntries(total_entries)
        setTotalPages(Math.ceil(total_entries / perPage))
        setPromoCodes(collection);
      })
      .catch(err => {
        setPromoCodes([]);
      });
  };

  const fetchNewUniversalPromoCode = (csrfToken, team, promoCodeToCopy) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var url = "/teams/" + team.id + "/universal_promo_codes/new";
    if(promoCodeToCopy){
      url = (url + "?copy_from_id=" + promoCodeToCopy.id);
    }

    axios.get(url)
      .then(({ data }) => {
        setPromoCodeForForm(data);
      });
  };

  const fetchUniversalPromoCodeForEdit = (csrfToken, team, promoCode) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.get("/teams/" + team.id + "/universal_promo_codes/" + promoCode.id + "/edit")
      .then(({ data }) => {
        setPromoCodeForForm(data);
      });
  };

  const deleteUniversalPromoCode = (csrfToken, team, promoCode) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.delete("/teams/" + team.id + "/universal_promo_codes/" + promoCode.id)
      .then(() => {
        if (promoCodes.length === 1) {
          getPromoCodes(1, perPage);
        } else {
          getPromoCodes(currentPage, perPage);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };

  const createUniversalPromoCode = (csrfToken, team, promoCode) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.post("/teams/" + team.id + "/universal_promo_codes", {
        promo_code: {
          name: promoCode.name,
          amount: (promoCode.amount_two_decimals ? parseFloatFromString(promoCode.amount_two_decimals) : null),
          percentage: (promoCode.percentage ? parseFloatFromString(promoCode.percentage) : null),
          start_date_midday: promoCode.start_date_midday,
          start_time_with_wrong_date: promoCode.start_time_with_wrong_date,
          end_date_midday: promoCode.end_date_midday,
          end_time_with_wrong_date: promoCode.end_time_with_wrong_date,
          unlimited: (promoCode.limit < 1),
          limit: promoCode.limit || 0,
          limit_per_event: promoCode.limit_per_event || 0,
          applies_to_ticket_types: promoCode.applies_to_ticket_types,
          applies_to_add_ons: promoCode.applies_to_add_ons,
          reveal_hidden_tickets: promoCode.reveal_hidden_tickets,
          ticket_type_ids: promoCode.ticket_type_ids,
        }
      })
      .then(() => {
        const lastPage = Math.ceil((totalEntries + 1) / perPage);
        setPromoCodeForForm({});
        getPromoCodes(lastPage, perPage);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };

  const updateUniversalPromoCode = (csrfToken, team, promoCode) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.patch("/teams/" + team.id + "/universal_promo_codes/" + promoCode.id, {
        promo_code: {
          name: promoCode.name,
          amount: (promoCode.amount_two_decimals ? parseFloatFromString(promoCode.amount_two_decimals) : null),
          percentage: (promoCode.percentage ? parseFloatFromString(promoCode.percentage) : null),
          start_date_midday: promoCode.start_date_midday,
          start_time_with_wrong_date: promoCode.start_time_with_wrong_date,
          end_date_midday: promoCode.end_date_midday,
          end_time_with_wrong_date: promoCode.end_time_with_wrong_date,
          unlimited: (promoCode.limit < 1),
          limit: promoCode.limit || 0,
          limit_per_event: promoCode.limit_per_event || 0,
          applies_to_ticket_types: promoCode.applies_to_ticket_types,
          applies_to_add_ons: promoCode.applies_to_add_ons,
          reveal_hidden_tickets: promoCode.reveal_hidden_tickets,
          ticket_type_ids: promoCode.ticket_type_ids,
        }
      })
      .then(() => {
        setPromoCodeForForm({});
        getPromoCodes(currentPage, perPage);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };

  return(
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="card border-0 shadow-2">
            <div className='card-body'>
              <p style={{'fontSize': '20px'}}>
                <strong>
                  Promo Codes&nbsp;
                  <span className='text-muted'>({totalEntries})</span>
                </strong>
              </p>
            </div>
            {promoCodes.length === 0 ? (
              <div className="card-body text-center"
                  style={{"paddingBottom": "456px"}}>
                <div className="dot d-flex justify-content-center align-items-center"
                    style={{
                      "margin": "44px auto 10px auto",
                      "width": "88px",
                      "height": "88px",
                      "background": "#F3F9FC",
                      "borderRadius": "50%",
                      "border": "4px solid #BBE0F6"
                    }}>
                  <i className="fas fa-flag-alt text-primary" style={{"fontSize": "42px"}}></i>
                </div>
                <p className="mb-1"
                  style={{
                    "fontSize": "16px"
                  }}>
                  <strong>Universal Promo Codes</strong>
                </p>
                <p className="small mb-3"
                  style={{
                    "maxWidth": "245px",
                    "margin": "0 auto"
                  }}>
                  Set up promo codes that will work across all of your events.
                </p>
                <a href="#"
                  className="btn btn-primary"
                  style={{
                    "borderRadius": "4px",
                    "fontSize": "14px"
                  }}
                  onClick={
                    (e) => {
                      e.preventDefault();
                      fetchNewUniversalPromoCode(csrfToken, team);
                    }
                  }>
                  <strong>Add Promo Code</strong>
                </a>
              </div>
            ) : (
              <div className="card-body"
                  style={{"padding": "24px 23.5px"}}>
                <div className="row">
                  <div className="col-12 text-right">
                    <a href="#"
                      className="btn btn-outline-primary btn-lg small"
                      style={{
                        "borderRadius": "4px",
                        "borderWidth": "1px"
                      }}
                      onClick={
                        (e) => {
                          e.preventDefault();
                          fetchNewUniversalPromoCode(csrfToken, team);
                        }
                      }>
                      <strong>Add a Code</strong>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="table vertical-middle table-border-bottom-1"
                          style={{
                            "tableLayout": "fixed",
                            "fontSize": "14px",
                            "marginTop": "7px"
                          }}>
                      <colgroup>
                        <col span={1} style={{"width": "15%"}} />
                        <col span={1} style={{"width": "20%"}} />
                        <col span={1} style={{"width": "15%"}} />
                        <col span={1} style={{"width": "20%"}} />
                        <col span={1} style={{"width": "25%"}} />
                        <col span={1} style={{"width": "5%"}} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>CODE TYPE</th>
                          <th>DISCOUNT</th>
                          <th>USES</th>
                          <th>STATUS</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {promoCodes.map((promoCode, index) =>
                          <tr key={index}>
                            <td>{promoCode.name}</td>
                            <td>Applies Discount</td>
                            <td>
                              {typeof(promoCode.percentage) === "number" ? (
                                promoCode.percentage + "%"
                              ) : (
                                formatter.format(promoCode.amount)
                              )}
                            </td>
                            <td>
                              {promoCode.number_claimed + "/" + (promoCode.unlimited ? "Unlimited" : promoCode.limit)}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-inline-block mr-2"
                                    style={{
                                      "width": "5px",
                                      "height": "5px",
                                      "borderRadius": "50%",
                                      "background": (promoCode.active ? "#91c347" : "#FF595E")
                                    }}>
                                </div>
                                {promoCode.active ? "Active" : "Inactive"}
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="text-muted">
                                  {promoCode.active ? (
                                    "Ends " + promoCode.human_end_time
                                  ) : promoCode.past ? (
                                    "Ended " + promoCode.human_end_time
                                  ) : (
                                    "Starts " + promoCode.human_start_time
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <Popup arrow={false}
                                    offsetY={5}
                                    position="bottom right"
                                    contentStyle={popupContentStyle}
                                    onOpen={
                                      (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }
                                    }
                                    trigger={open => (
                                      <a href="#"
                                          onClick={
                                            (e) => {
                                              e.preventDefault();
                                            }
                                          }
                                          className="text-muted">
                                        <i className="fas fa-ellipsis-v"></i>
                                      </a>
                                    )} >
                                {close => (
                                  <div className='row text-left'>
                                    <div className="col-12">
                                      <ul className="list-group offer-inline-menu">
                                        <li className="list-group-item px-3 pt-3 pb-2">
                                          <a href="#"
                                            onClick={
                                              (e) => {
                                                e.preventDefault();
                                                close();
                                                fetchUniversalPromoCodeForEdit(csrfToken, team, promoCode);
                                              }
                                            }>
                                            <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                                              <i className="fas fa-pencil fa-fw"></i>
                                            </span>
                                            <span className="text-dark ml-3">
                                              Edit
                                            </span>
                                          </a>
                                        </li>
                                        <li className="list-group-item px-3 py-2">
                                          <a href="#"
                                            onClick={
                                              (e) => {
                                                e.preventDefault();
                                                close();
                                                fetchNewUniversalPromoCode(csrfToken, team, promoCode);
                                              }
                                            }>
                                            <span className="d-inline-block text-center text-muted" style={{width: "28px"}}>
                                              <i className="far fa-copy fa-fw"></i>
                                            </span>
                                            <span className="text-dark ml-3">
                                              Copy
                                            </span>
                                          </a>
                                        </li>
                                        <li className="list-group-item pb-3 px-3 pt-2">
                                          <a href="#"
                                            onClick={
                                              (e) => {
                                                e.preventDefault();
                                                close();
                                                deleteUniversalPromoCode(csrfToken, team, promoCode);
                                              }
                                            }
                                            className="text-danger">
                                            <span className="d-inline-block text-center" style={{width: "28px"}}>
                                              <i className="far fa-trash fa-fw"></i>
                                            </span>
                                            <span className="ml-3">
                                              Delete
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <p className="mb-0 small text-dark">
                      <i className="fas fa-info-circle text-primary mr-2"></i>
                      To create a promo code for a specific event, head to the ticketing module for that event.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            "display": "flex",
            "justifyContent": "space-between",
            "padding": "15px",
            "width": "100%",
          }}
        >
          {totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={perPage}
              totalItemsCount={totalEntries}
              pageRangeDisplayed={5}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination small"
              hideFirstLastPages={true}
              prevPageText={"←"}
              nextPageText={"→"}
              onChange={
                (page) => {
                  getPromoCodes(page, perPage);
                }
              }
            />
          ) : <div/>}
          <div>
            <label htmlFor="show-per-page"
                   className="text-muted mb-1"
                   style={{"fontSize": "14px"}}
            >
              Show:
            </label>
            <select className="show-per-page"
                    style={{
                      "backgroundColor": "transparent",
                      "border": "0px"
                    }}
                    value={perPage}
                    id="show-per-page"
                    onChange={
                      (e) => {
                        const perPageNumber = parseInt(e.target.value, 10)
                        setPerPage(perPageNumber);
                        getPromoCodes(1, perPageNumber);
                      }
                    }
            >
              {[10, 25, 50, 100].map((value, index) => (
                <option key={index} value={value}>
                  {value} Rows
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <SlidingPane
        isOpen={Object.keys(promoCodeForForm).length > 0}
        className="sliding-pane-container"
        width={"525px"}
        hideHeader={true}
        onRequestClose={() => {
          setPromoCodeForForm({});
        }}
      >
        <React.Fragment>
          <div className="row m-0 py-4"
              style={{"borderBottom": "2px solid #fafafa"}}>
            <div className="col pl-4">
              <h3 className="mb-0"
                  style={{"fontSize": "20px"}}>
                <strong>Add Promo Code</strong>
              </h3>
            </div>
            <div className="col-xs-auto"
                style={{"paddingRight": "39px"}}>
              <a href="#"
                className="text-muted"
                style={{"fontSize": "22px"}}
                onClick={
                  (e) => {
                    e.preventDefault();
                    setPromoCodeForForm({});
                  }
                }>
                <i className="fal fa-times"></i>
              </a>
            </div>
          </div>
          <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(!canSubmitPromoCodeForm(promoCodeForForm)){
                      return false;
                    }

                    if(promoCodeForForm.id){
                      updateUniversalPromoCode(csrfToken, team, promoCodeForForm);
                    } else {
                      createUniversalPromoCode(csrfToken, team, promoCodeForForm);
                    }
                  }
                }>
            <div className="row m-0">
              <div className="col-12 pt-3">
                <div className="form-group">
                  <label className="text-muted mb-1"
                        htmlFor="promo-code-name"
                        style={{"fontSize": "14px"}}>
                    Code Name
                    <span className="text-danger">&nbsp;*</span>
                  </label>
                  <MaskedInput
                    style={{"textTransform": "uppercase"}}
                    mask={
                      () => {
                        return Array.from(Array(100).keys())
                          .map((i) => /[a-zA-Z0-9@\.\+\-\_\~]/);
                      }
                    }
                    className="form-control form-control-slim"
                    guide={false}
                    id="promo-code-name"
                    onBlur={() => {}}
                    value={promoCodeForForm.name || ""}
                    onChange={(e) => {
                      var newName = e.target.value.toUpperCase();
                      var updated = Object.assign({}, promoCodeForForm, {name: newName});

                      setPromoCodeForForm(updated);
                    }}
                  />
                </div>
                <div className="form-row">
                  <div className="col">
                    <div className="form-group mb-2">
                      <div className="custom-control custom-checkbox custom-checkbox-table">
                        <input type="checkbox"
                              className="custom-control-input"
                              style={{"border": "1px solid #e6e6e6"}}
                              checked={promoCodeForForm.applies_to_ticket_types || false}
                              onChange={
                                (e) => {
                                  var applyToTicketTypes = e.target.checked;
                                  var params = {
                                    applies_to_ticket_types: applyToTicketTypes
                                  };

                                  if(!applyToTicketTypes){
                                    params = Object.assign({}, params, {
                                      reveal_hidden_tickets: false,
                                      ticket_type_ids: []
                                    });
                                  }

                                  var updated = Object.assign({}, promoCodeForForm, params);
                                  setPromoCodeForForm(updated);
                                }
                              }
                              id="promo-code-applies-to-ticket-types" />
                        <label className="custom-control-label"
                              style={{"fontSize": "16px"}}
                              htmlFor="promo-code-applies-to-ticket-types">
                          Applies to Tickets
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {promoCodeForForm.applies_to_ticket_types ? (
                  <div className="form-row" style={{"marginLeft": "12px"}}>
                    <div className="col">
                      <div className="form-group mb-2">
                        <div className="custom-control custom-checkbox custom-checkbox-table">
                          <input
                            type="checkbox"
                            id="promo-code-reveal-hidden-tickets"
                            className="custom-control-input"
                            style={{"border": "1px solid #e6e6e6"}}
                            checked={promoCodeForForm.reveal_hidden_tickets || false}
                            onChange={e => {
                              setPromoCodeForForm({
                                ...promoCodeForForm,
                                reveal_hidden_tickets: e.target.checked,
                                ticket_type_ids: []
                              });
                            }} />
                          <label
                            className="custom-control-label"
                            style={{"fontSize": "16px"}}
                            htmlFor="promo-code-reveal-hidden-tickets"
                          >
                            Reveals hidden templated tickets during checkout
                          </label>
                        </div>
                      </div>
                      {promoCodeForForm.reveal_hidden_tickets ? (
                        <div className="form-group mb-2">
                          <Select
                            styles={selectStyles}
                            placeholder={"Select templated ticket(s)"}
                            isMulti={true}
                            options={
                              ticketTypeTemplates.map(tt => ({ value: tt.id, label: tt.name }))
                            }
                            value={
                              ticketTypeTemplates
                                .map(tt => ({ value: tt.id, label: tt.name }))
                                .filter(tt => promoCodeForForm.ticket_type_ids.includes(tt.value))
                            }
                            onChange={selected => {
                              var ticketTypeIds = (selected || []).map(tt => tt.value);

                              setPromoCodeForForm({
                                ...promoCodeForForm,
                                ticket_type_ids: ticketTypeIds
                              });
                            }} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="form-row">
                  <div className="col">
                    <div className="form-group mb-2">
                      <div className="custom-control custom-checkbox custom-checkbox-table">
                        <input type="checkbox"
                              className="custom-control-input"
                              style={{"border": "1px solid #e6e6e6"}}
                              checked={promoCodeForForm.applies_to_add_ons || false}
                              onChange={
                                (e) => {
                                  var updated = Object.assign({}, promoCodeForForm, {
                                    applies_to_add_ons: e.target.checked
                                  });

                                  setPromoCodeForForm(updated);
                                }
                              }
                              id="promo-code-applies-to-add-ons" />
                        <label className="custom-control-label"
                              style={{"fontSize": "16px"}}
                              htmlFor="promo-code-applies-to-add-ons">
                          Applies to Add-ons
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col">
                    <p style={{"fontSize": "16px"}}>
                      <strong>Quantity Available</strong>
                    </p>
                    <div className="form-group">
                      <label className="text-muted mb-1"
                        htmlFor="promo-code-available"
                        style={{"fontSize": "14px"}}>
                        Total number available
                      </label>
                      <MaskedInput
                        id="promo-code-available"
                        className="form-control form-control-slim"
                        value={promoCodeForForm.limit || ""}
                        mask={createNumberMask({
                          prefix: "",
                          decimalLimit: 0
                        })}
                        onChange={e => {
                          var updated = {...promoCodeForForm}
                          var blank = (e.target.value === "")

                          updated.limit = blank ? 0 : e.target.value;
                          updated.unlimited = blank;

                          setPromoCodeForForm(updated);
                        }} />
                      <span className="text-muted small">Leave field blank for unlimited.</span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                        htmlFor="promo-code-available"
                        style={{"fontSize": "14px"}}>
                        Number available per event
                      </label>
                      <MaskedInput
                        id="promo-code-available-per-event"
                        className="form-control form-control-slim"
                        value={promoCodeForForm.limit_per_event || ""}
                        mask={createNumberMask({
                          prefix: "",
                          decimalLimit: 0
                        })}
                        onChange={e => {
                          setPromoCodeForForm({
                            ...promoCodeForForm,
                            limit_per_event: e.target.value === "" ? 0 : e.target.value
                          });
                        }} />
                      <span className="text-muted small">Leave field blank for unlimited.</span>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col">
                    <p style={{"fontSize": "16px"}}>
                      <strong>Discount</strong>
                    </p>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                            htmlFor="promo-code-amount"
                            style={{"fontSize": "14px"}}>
                        Discount Amount
                      </label>
                      <CurrencyInput className="form-control form-control-slim"
                                    maskOptions={{
                                      prefix: "",
                                      integerLimit: null
                                    }}
                                    placeholder="$"
                                    value={promoCodeForForm.amount_two_decimals || ""}
                                    onChange={
                                      (e) => {
                                        var updated = Object.assign({}, promoCodeForForm, {
                                          amount_two_decimals: e.target.value,
                                          percentage: null
                                        });

                                        setPromoCodeForForm(updated);
                                      }
                                    }
                                    id="promo-code-amount" />
                    </div>
                  </div>
                  <div className="col-xs-auto d-flex align-items-center justify-content-center">
                    <div style={{"fontSize": "16px"}}>
                      OR
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="text-muted mb-1"
                            htmlFor="promo-code-percentage"
                            style={{"fontSize": "14px"}}>
                        &nbsp;
                      </label>
                      <MaskedInput className={"form-control form-control-slim " + (parseFloatFromString(promoCodeForForm.percentage || "") > 100 ? "highlight" : "")}
                                  mask={createNumberMask({
                                    prefix: "",
                                    decimalLimit: 0
                                  })}
                                  id="promo-code-percentage"
                                  placeholder="%"
                                  onChange={
                                    (e) => {
                                      var updated = Object.assign({}, promoCodeForForm, {
                                        percentage: e.target.value,
                                        amount_two_decimals: null
                                      });

                                      setPromoCodeForForm(updated);
                                    }
                                  }
                                  value={typeof(promoCodeForForm.percentage) !== "undefined" ? promoCodeForForm.percentage : ""} />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <p style={{"fontSize": "16px"}}>
                      <strong>Promo Code Starts</strong>
                    </p>
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="promo-code-start-date"
                                className="text-muted mb-1"
                                style={{"fontSize": "14px"}}>
                            Start Date
                            <span className="text-danger">&nbsp;*</span>
                          </label>
                          <Flatpickr
                            options={{
                              dateFormat: "M j, Y"
                            }}
                            placeholder={"Select date"}
                            className={"form-control ticketing-date-picker form-control-flatpickr"}
                            value={parseDateTime(promoCodeForForm.start_date_midday)}
                            onChange={dates => {
                              var startDate = parseDateTime(dates[0]);
                              var startTime = parseDateTime(promoCodeForForm.start_time_with_wrong_date);
                              var endDate   = parseDateTime(promoCodeForForm.end_date_midday);
                              var endTime   = parseDateTime(promoCodeForForm.end_time_with_wrong_date);

                              var attrs = {
                                start_date_midday: startDate
                              };

                              if(endDate && endDate < startDate){
                                endDate = "";
                                attrs = Object.assign({}, attrs, {
                                  end_date_midday: endDate
                                });
                              }

                              if(startDate && startTime && endDate && endTime){
                                var startTimeCorrectDate = timeOnDate(startDate, startTime);
                                var endTimeCorrectDate = timeOnDate(endDate, endTime);

                                if(endTimeCorrectDate < startTimeCorrectDate){
                                  attrs = Object.assign({}, attrs, {end_time_with_wrong_date: ""});
                                }
                              }

                              var updated = Object.assign({}, promoCodeForForm, attrs);
                              setPromoCodeForForm(updated);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="promo-code-start-time"
                                className="text-muted mb-1"
                                style={{"fontSize": "14px"}}>
                            Start Time
                            <span className="text-danger">&nbsp;*</span>
                          </label>
                          <DatePicker
                            className="form-control ticketing-date-picker"
                            selected={parseDateTime(promoCodeForForm.start_time_with_wrong_date)}
                            placeholderText={"Select time"}
                            onChange={(time) => {
                              var startDate = parseDateTime(promoCodeForForm.start_date_midday);
                              var startTime = parseDateTime(time);
                              var endDate   = parseDateTime(promoCodeForForm.end_date_midday);
                              var endTime   = parseDateTime(promoCodeForForm.end_time_with_wrong_date);

                              var attrs = {
                                start_time_with_wrong_date: startTime
                              };

                              if(startDate && endDate && endTime){
                                var startTimeCorrectDate = timeOnDate(startDate, startTime);
                                var endTimeCorrectDate = timeOnDate(endDate, endTime);

                                if(endTimeCorrectDate < startTimeCorrectDate){
                                  attrs = Object.assign({}, attrs, {end_time_with_wrong_date: ""});
                                }
                              }

                              var updated = Object.assign({}, promoCodeForForm, attrs);
                              setPromoCodeForForm(updated);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <p style={{"fontSize": "16px"}}>
                      <strong>Promo Code Ends</strong>
                    </p>
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="promo-code-end-date"
                                className="text-muted mb-1"
                                style={{"fontSize": "14px"}}>
                            Expiration Date
                            <span className="text-danger">&nbsp;*</span>
                          </label>
                          <Flatpickr
                            options={{
                              dateFormat: "M j, Y",
                              minDate: (promoCodeForForm.start_date_midday ? (
                                parseDateTime(promoCodeForForm.start_date_midday).setHours(0,0,0,0)
                              ) : null)
                            }}
                            disabled={!promoCodeForForm.start_date_midday}
                            placeholder={"Select date"}
                            className={"form-control ticketing-date-picker form-control-flatpickr " + (!promoCodeForForm.start_date_midday ? "form-control-disabled" : "")}
                            value={parseDateTime(promoCodeForForm.end_date_midday)}
                            onChange={dates => {
                              var startDate = parseDateTime(promoCodeForForm.start_date_midday);
                              var startTime = parseDateTime(promoCodeForForm.start_time_with_wrong_date);
                              var endDate   = parseDateTime(dates[0]);
                              var endTime   = parseDateTime(promoCodeForForm.end_time_with_wrong_date);

                              var attrs = {
                                end_date_midday: endDate
                              };

                              if(startDate && startTime && endTime){
                                var startTimeCorrectDate = timeOnDate(startDate, startTime);
                                var endTimeCorrectDate = timeOnDate(endDate, endTime);

                                if(endTimeCorrectDate < startTimeCorrectDate){
                                  attrs = Object.assign({}, attrs, {end_time_with_wrong_date: ""});
                                }
                              }

                              var updated = Object.assign({}, promoCodeForForm, attrs);
                              setPromoCodeForForm(updated);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="promo-code-end-time"
                                className="text-muted mb-1"
                                style={{"fontSize": "14px"}}>
                            Expiration Time
                            <span className="text-danger">&nbsp;*</span>
                          </label>
                          <DatePicker
                            className={"form-control ticketing-date-picker " + (!promoCodeForForm.end_date_midday || !promoCodeForForm.start_time_with_wrong_date ? "form-control-disabled" : "")}
                            selected={parseDateTime(promoCodeForForm.end_time_with_wrong_date)}
                            placeholderText={"Select time"}
                            disabled={!promoCodeForForm.end_date_midday || !promoCodeForForm.start_time_with_wrong_date}
                            onChange={(time) => {
                              var updated = Object.assign({}, promoCodeForForm, {
                                end_time_with_wrong_date: time
                              });

                              setPromoCodeForForm(updated);
                            }}
                            filterTime={
                              (time) => {
                                return filterPassedTime(
                                  time,
                                  parseDateTime(promoCodeForForm.start_date_midday),
                                  parseDateTime(promoCodeForForm.start_time_with_wrong_date),
                                  parseDateTime(promoCodeForForm.end_date_midday)
                                );
                              }
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 text-right my-4">
                    <a href="#"
                      onClick={
                        (e) => {
                          e.preventDefault();
                          setPromoCodeForForm({});
                        }
                      }
                      className="btn btn-outline-primary">
                      <strong>Cancel</strong>
                    </a>
                    <button type="submit"
                            disabled={!canSubmitPromoCodeForm(promoCodeForForm)}
                            className="btn btn-primary ml-3">
                      <strong>Save</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </React.Fragment>
      </SlidingPane>
    </div>
  );
};

UniversalPromoCodeManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  paginatedPromoCodes: PropTypes.object.isRequired,
  search: PropTypes.string,
  ticketTypeTemplates: PropTypes.array.isRequired,
};

export default UniversalPromoCodeManager;
